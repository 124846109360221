import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logout } from "../../redux/actions/logoutAction";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";

import BoxHead from "../../components/BoxHead";
import InputText from "../../components/InputText";
import InputGroupText from "../../components/InputGroupText";
import InputSelect from "../../components/InputSelect";
import DiseaseInvest_Step1 from "../../components/DiseaseInvest_Step1";
import DiseaseInvest_Step2 from "../../components/DiseaseInvest_Step2";
import DiseaseInvest_Step3 from "../../components/DiseaseInvest_Step3";
import DiseaseInvest_Step4 from "../../components/DiseaseInvest_Step4";
import DiseaseInvest_Step5 from "../../components/DiseaseInvest_Step5";
import DiseaseInvest_Step6 from "../../components/DiseaseInvest_Step6";
import DiseaseInvest_Step7 from "../../components/DiseaseInvest_Step7";
import DiseaseInvest_Step8 from "../../components/DiseaseInvest_Step8";
import InputMask from "../../components/InputMask";
import Breadcrumb from "../../components/Breadcrumb";
import ModalOrganizes from "../../components/Modal.Organizes";
import ModalOutbreakSearch from "../../components/Modal.OutbreakSearch";
import ModalFarmSearch from "../../components/Modal.FarmSearch";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function Disease_Investigation_Save(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t, i18n } = useTranslation(["common", "footer"]);

  const initialForm = {
    dataAllById: {},
    mode: location.pathname.includes("save") ? "notGetByObId" : "",
    farmId: "",
    outbreakId: "",
    outbreakCode: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    caseCode: "",
    homeNo: "",
    phone: "",
    name: "",
    orgId: "",
    orgName: "",

    modeGet: "default",

    step1: {},
    step2: {},
    step3: [],
    step4: {},
    step5: {},
    step6: {},
    step7: {},
    step8: {},
  };
  const [form, setForm] = useState(initialForm);

  const initShowCaseIdSearch = false;
  const [showCaseIdSearch, setShowCaseIdSearch] =
    useState(initShowCaseIdSearch);

  const initShowOrgModal = false;
  const [showOrgModal, setShowOrgModal] = useState(initShowOrgModal);

  const initShowOutbreakSearch = false;
  const [showOutbreakSearch, setShowOutbreakSearch] = useState(
    initShowOutbreakSearch
  );

  const initShowFarmSearch = false;
  const [showFarmSearch, setShowFarmSearch] = useState(initShowFarmSearch);

  const initialFormJson = {
    animalId: {},
    diseaseTypeId: {},
    husbandry: {},
    animalPurpose: {},
    vaccineType: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);
  useMasterData("province", provinceList, setProvinceList);
  // =================

  // หน่วยงาน
  const [organizesSelectValue, setOrganizesSelectValue] = useState([]);
  const [organizesList, setOrganizesList] = useState([]);
  const [activeTab, setActiveTab] = useState("pills-history-tab");

  useEffect(() => {
    getMasterJson();
    getProvince();
    getOrganizes();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("save") && form.outbreakId) {
      getOutbreakById(form.outbreakId);
    } else if (location.pathname.includes("save") && props.match.params.id) {
      getOutbreakById(props.match.params.id);
    }
  }, [
    user,
    form.outbreakId,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
  ]);

  useEffect(() => {
    if (
      location.pathname.includes("edit") ||
      location.pathname.includes("duplicate")
    ) {
      getDiseaseInvestigationById(props.match.params.id);
    }
  }, [
    user,
    location.pathname,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
  ]);

  const getMasterJson = async () => {
    const params = [
      "animalType",
      "diseaseType",
      "husbandry",
      "animalPurpose",
      "vaccineType",
    ];

    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          animalId: data.animalType,
          diseaseTypeId: data.diseaseType,
          husbandry: data.husbandry,
          animalPurpose: data.animalPurpose,
          vaccineType: data.vaccineType,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOrganizes = async (id) => {
    try {
      const response = await API.getOrganizes("", "", "", 1);
      const data = await response.data;
      if (response.status == 200) {
        let organizesArray = [];
        data.content.forEach((element) => {
          organizesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setOrganizesList(organizesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, provinceId: value.value, zipcode: "" });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, districtId: value.value, zipcode: "" });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value, zipcode: value.zipcode });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  //========================

  const getOutbreakById = async (id) => {
    let dataRes;
    try {
      const response = await API.getOutbreakById(id);
      if (response.status === 200) {
        dataRes = response.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.fm01 && dataById.fm01.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.fm01.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length === 0) {
          await getDistricts(dataById.fm01.provinceId);
        }
      }

      if (dataById.fm01 && dataById.fm01.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.fm01.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length === 0) {
          await getSubDistricts(dataById.fm01.amphurId);
        }
      }

      if (dataById.fm01 && dataById.fm01.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.fm01.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length === 0) {
          await getVillage(dataById.fm01.tambonId);
        }
      }

      if (dataById.fm01 && dataById.fm01.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.fm01.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.fm01 && dataById.fm01.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.fm01.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      setForm({
        ...form,
        mode: "add",
        dataAllById: dataById,
        outbreakCode: dataById.outbreak.obCode,
        farmId: dataById.fm01.farmId,
        caseCode: dataById.fm01.caseCode,

        provinceId: dataById.fm01.provinceId,
        districtId: dataById.fm01.amphurId,
        subDistrictId: dataById.fm01.tambonId,
        villageId: dataById.fm01.mooId,
        homeNo: dataById.fm01.address,

        phone: dataById.fm01.ownerTel,
        name: dataById.fm01.ownerName,
        orgId: dataById.fm01.orgId,
        orgName: dataById.fm01.orgName,

        step1: {
          step1_fm02: {
            q11: "y",
            q11MoveDate: "",
            q11SymptomDate: "",
            q11IntoDate: "",
          },
        },
      });
    }
  };
  //=============================
  const getDiseaseInvestigationById = async (id) => {
    let dataRes;
    try {
      const response = location.pathname.includes("duplicate")
        ? await API.getCopyDiseaseInvest(id)
        : await API.getDiseaseInvestigationById(id);
      if (response.status == 200) {
        dataRes = await response.data;
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.fm01 && dataById.fm01.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.fm01.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.fm01.provinceId);
        }
      }

      if (dataById.fm01 && dataById.fm01.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.fm01.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.fm01.amphurId);
        }
      }

      if (dataById.fm01 && dataById.fm01.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.fm01.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.fm01.tambonId);
        }
      }

      if (dataById.fm01 && dataById.fm01.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.fm01.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.fm01 && dataById.fm01.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.fm01.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      let step6_file = [];
      if (dataById.fm02File && dataById.fm02File.length > 0) {
        dataById.fm02File.map((item, i) => {
          step6_file.push({
            id: item.id,
            file: item.url,
            fileName: item.name,
            detail_file: item.description,
            date_disease: item.date,
          });
        });
      }

      let step7_file = [];
      if (dataById.fm02FileOther && dataById.fm02FileOther.length > 0) {
        dataById.fm02FileOther.map((item, i) => {
          step7_file.push({
            id: item.id,
            file: item.url,
            fileName: item.name,
            detail_file: item.description,
            date_disease: item.date,
          });
        });
      }

      // let step3Movement1;
      // if (dataById.fm02Movement && dataById.fm02Movement.length > 0) {
      //   step3Movement1 = dataById.fm02Movement[0];
      // }

      // let step3Movement2;
      // if (dataById.fm02Movement && dataById.fm02Movement.length > 0) {
      //   step3Movement2 = dataById.fm02Movement[1];
      // }

      // let step3Movement3;
      // if (dataById.fm02Movement && dataById.fm02Movement.length > 0) {
      //   step3Movement3 = dataById.fm02Movement[2];
      // }

      // let step3Movement4;
      // if (dataById.fm02Movement && dataById.fm02Movement.length > 0) {
      //   step3Movement4 = dataById.fm02Movement[3];
      // }

      // let step3Movement5;
      // if (dataById.fm02Movement && dataById.fm02Movement.length > 0) {
      //   step3Movement5 = dataById.fm02Movement[4];
      // }

      // let step3Movement6;
      // if (dataById.fm02Movement && dataById.fm02Movement.length > 0) {
      //   step3Movement6 = dataById.fm02Movement[5];
      // }

      // let step3Movement7;
      // if (dataById.fm02Movement && dataById.fm02Movement.length > 0) {
      //   step3Movement7 = dataById.fm02Movement[6];
      // }

      if (dataById) {
        setForm({
          ...form,
          dataAllById: dataById,

          modeGet: "getById",
          farmId: dataById.fm01.farmId,
          outbreakId: dataById.outbreak.id,
          outbreakCode: location.pathname.includes("edit")
            ? dataById.outbreak.obCode
            : "",
          caseCode: location.pathname.includes("edit")
            ? dataById.fm01.caseCode
            : "",
          provinceId: dataById.fm01.provinceId,
          districtId: dataById.fm01.amphurId,
          subDistrictId: dataById.fm01.tambonId,
          villageId: dataById.fm01.mooId,
          homeno: dataById.fm01.address,
          phone: dataById.fm01.ownerTel,
          name: dataById.fm01.ownerName,
          orgId: dataById.fm01.orgId,
          orgName: dataById.fm01.orgName,

          step1: {
            // step1_fm02: dataById.fm02,
            step1_fm02: {
              q11: dataById.fm02 && dataById.fm02.q11,
              q11MoveDate:
                dataById.fm02 &&
                dataById.fm02.q11MoveDate &&
                dataById.fm02.q11 == "n"
                  ? dataById.fm02.q11MoveDate
                  : "",
              q11SymptomDate:
                dataById.fm02 && dataById.fm02.q11SymptomDate
                  ? dataById.fm02.q11SymptomDate
                  : "",
              q11IntoDate:
                dataById.fm02 && dataById.fm02.q11IntoDate
                  ? dataById.fm02.q11IntoDate
                  : "",
            },
            data_anmTouch: dataById.fm02AnimalTouch,
            data_humanTouch: dataById.fm02HumanTouch,
            chooseTab1: dataById.fm02SymptomGeneral,
            chooseTab2: dataById.fm02SymptomRabies,
            chooseTab3: dataById.fm02SymptomEia,
          },

          step2: {
            step2_fm02Env: dataById.fm02Env,
            data_insectProtect: dataById.fm02InsectProtect,
            data_rabies: dataById.fm02Rabies,
          },

          step3: dataById.fm02Movement,

          step4: {
            report_from: dataById.fm02.q41,
            verify: dataById.fm02.q41LabApprove,
            day_last_time: dataById.fm02.q41LastDate,
            day_calm_disease: dataById.fm02.q41EndDate,
            number: dataById.fm02.q41ExamNo,
            disease: dataById.fm02.q41Disease,
          },

          step5: {
            step5_analyse: dataById.fm02Analyse,
            // step5_analyse: [],
          },

          step6: {
            data_file: step6_file,
            checkAddFile: false,
          },

          step7: {
            data_file: step7_file,
            checkAddFile: false,
          },

          step8: {
            opinion: dataById.fm02.q8Opinion,
          },
        });
      }
    }
  };
  console.log("form8", form.step3);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "/passive_surv/disease_investigation", name: "กคร.2" },
    {
      path: "active",
      name: "บันทึกการสอบสวนทางระบาดวิทยาของการเกิดโรคระบาดสัตว์",
    },
  ];

  const setOrganizes = (data) => {
    setForm({ ...form, orgId: data.value, orgName: data.label });
  };

  const toSaveDiseaseInvest = async () => {
    if (form.mode == "notGetByObId") {
      MySwal.fire({
        text: "กรุณาเลือกเลข Outbreak ID",
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
    } else {
      const dataById = form.dataAllById;
      const dataStep1 = form.step1;
      const dataStep2 = form.step2;
      const dataStep3 = form.step3;
      const dataStep4 = form.step4;
      const dataStep5 = form.step5;
      const dataStep6 = form.step6;
      const dataStep7 = form.step7;
      const dataStep8 = form.step8;

      let step1_anmTouch = [];
      if (dataStep1.data_anmTouch && dataStep1.data_anmTouch.length > 0) {
        dataStep1.data_anmTouch.map((item, i) => {
          step1_anmTouch.push({
            id: item.id ? item.id : "",
            animalName: item.animalName,
            animalTypeId: item.animalTypeId,
            quarantine: item.quarantine,
            touchDate: item.touchDate ? helper.getDate(item.touchDate) : "",
            touchDisease: item.touchDisease,
            vaccination: item.vaccination,
            vaccinationDate: item.vaccinationDate
              ? helper.getDate(item.vaccinationDate)
              : "",
          });
        });
      }

      let step1_humanTouch = [];
      if (dataStep1.data_humanTouch && dataStep1.data_humanTouch.length > 0) {
        dataStep1.data_humanTouch.map((item, i) => {
          step1_humanTouch.push({
            id: item.id ? item.id : "",
            name: item.name,
            tel: item.tel,
            touchDate: item.touchDate ? helper.getDate(item.touchDate) : "",
            touchDisease: item.touchDisease,
            vaccination: item.vaccination,
            // "vaccinationDate": item.vaccinationDate ? helper.getDate(item.vaccinationDate) : ""
          });
        });
      }

      let step1_symptomGeneral = [];
      if (dataStep1.chooseTab1 && dataStep1.chooseTab1.length > 0) {
        dataStep1.chooseTab1.map((item, i) => {
          step1_symptomGeneral.push({
            tabId: item.tabId,
            fm02SymptomsId:
              item.fm02SymptomsId == "a" ? "" : item.fm02SymptomsId,
            other: item.other,
          });
        });
      }

      let step1_symptomRabies = [];
      if (dataStep1.chooseTab2 && dataStep1.chooseTab2.length > 0) {
        dataStep1.chooseTab2.map((item, i) => {
          step1_symptomRabies.push({
            tabId: item.tabId,
            fm02SymptomsId:
              item.fm02SymptomsId == "b" ? "" : item.fm02SymptomsId,
            other: item.other,
          });
        });
      }

      let step1_symptomEia = [];
      if (dataStep1.chooseTab3 && dataStep1.chooseTab3.length > 0) {
        dataStep1.chooseTab3.map((item, i) => {
          step1_symptomEia.push({
            tabId: item.tabId,
            fm02SymptomsId:
              item.fm02SymptomsId == "c" ? "" : item.fm02SymptomsId,
            other: item.other,
            degreeFever: item.degreeFever,
            look: item.look,
          });
        });
      }

      const insectTableStep2 = [];
      if (
        dataStep2.data_insectProtect &&
        dataStep2.data_insectProtect.length > 0
      ) {
        dataStep2.data_insectProtect.map((item, i) => {
          insectTableStep2.push({
            id: item.id ? item.id : "",
            actionProtectionId: item.actionProtectionId,
            operateDate: item.operateDate
              ? helper.getDate(item.operateDate)
              : "",
            result: item.on_target_name,
          });
        });
      }

      const rabiesTableStep2 = [];
      if (dataStep2.data_rabies && dataStep2.data_rabies.length > 0) {
        dataStep2.data_rabies.map((item, i) => {
          rabiesTableStep2.push({
            id: item.id ? item.id : "",
            placeTypesId: item.locationTypeId,
            name: item.location_name,
            animalTypeId: item.animalTypeId,
            animalQty: item.number,
          });
        });
      }

      const analyseStep5 = [];
      if (dataStep5.data_analyse && dataStep5.data_analyse.length > 0) {
        dataStep5.data_analyse.map((item, i) => {
          analyseStep5.push({
            id: item.id ? item.id : "",
            analyseId: item.titleId,
            description: item.detail,
            orderIndex: 1,
          });
        });
      }

      const investFilesStep6 = [];
      if (dataStep6.data_file && dataStep6.data_file.length > 0) {
        dataStep6.data_file.map((item, i) => {
          if (item.id) {
          } else {
            investFilesStep6.push({
              name: item.fileName,
              description: item.detail_file,
              date: item.date_file
                ? helper.getDate(item.date_file)
                : location.pathname.includes("duplicate")
                ? helper.getDate(item.date_disease)
                : "",
              data: location.pathname.includes("duplicate") ? "" : item.file,
            });
            if (location.pathname.includes("duplicate")) {
              investFilesStep6[0]["url"] = item.file;
            }
          }
        });
      }

      const otherFilesStep7 = [];
      if (dataStep7.data_file && dataStep7.data_file.length > 0) {
        dataStep7.data_file.map((item, i) => {
          if (item.id) {
          } else {
            otherFilesStep7.push({
              name: item.fileName,
              description: item.detail_file,
              date: item.date_file
                ? helper.getDate(item.date_file)
                : location.pathname.includes("duplicate")
                ? helper.getDate(item.date_disease)
                : "",
              data: location.pathname.includes("duplicate") ? "" : item.file,
            });
            if (location.pathname.includes("duplicate")) {
              otherFilesStep7[0]["url"] = item.file;
            }
          }
        });
      }

      const delInsect = [];
      if (dataStep2.del_insect && dataStep2.del_insect.length > 0) {
        dataStep2.del_insect.map((item) => {
          delInsect.push(item);
        });
      }

      const delRabies = [];
      if (dataStep2.del_rabies && dataStep2.del_rabies.length > 0) {
        dataStep2.del_rabies.map((item) => {
          delRabies.push(item);
        });
      }

      const delAnalyse = [];
      if (dataStep5.del_analyse && dataStep5.del_analyse.length > 0) {
        dataStep5.del_analyse.map((item) => {
          delAnalyse.push(item);
        });
      }

      const delFile = [];
      if (dataStep6.del_file && dataStep6.del_file.length > 0) {
        dataStep6.del_file.map((item) => {
          delFile.push(item);
        });
      }

      if (dataStep7.del_file && dataStep7.del_file.length > 0) {
        dataStep7.del_file.map((item) => {
          delFile.push(item);
        });
      }

      const step3Obj = [];
      for (let i = 0; i < dataStep3.length; i++) {
        step3Obj.push({
          carrierTypeId: dataStep3[i].carrierTypeId,
          moveStatus: dataStep3[i].moveStatus,
          purpose: dataStep3[i].purpose,
          moveDate: dataStep3[i].moveDate
            ? helper.getDate(dataStep3[i].moveDate)
            : "",
          fromFarm: dataStep3[i].fromFarm,
          toFarm: dataStep3[i].toFarm,
          fromTxt: dataStep3[i].fromTxt,
          toTxt: dataStep3[i].toTxt,
          remark: dataStep3[i].remark,
        });
      }

      const data = {
        outbreak: {
          obCode: dataById.outbreak.obCode,
        },
        fm01: {
          // "id": dataById.fm01.id,
          caseCode: dataById.fm01.caseCode,
          address: dataById.fm01.address,
          amphurId: dataById.fm01.amphurId,
          animalAround5km: dataById.fm01.animalAround5km,
          animalTypeId: dataById.fm01.animalTypeId,
          diseaseTypeId: dataById.fm01.diseaseTypeId,
          dlaId: dataById.fm01.dlaId,
          dlaName: dataById.fm01.dlaNam,
          farmId: dataById.fm01.farmId,
          firstAnimSymptomDate: dataById.fm01.firstAnimSymptomDate,
          foundDiseaseDate: dataById.fm01.foundDiseaseDate,
          humanDeadAmt: dataById.fm01.humanDeadAmt,
          humanSickAmt: dataById.fm01.humanSickAmt,
          humanTouchAmt: dataById.fm01.humanTouchAmt,
          informBy: dataById.fm01.informBy,
          informOther: dataById.fm01.informOther,
          informId: dataById.informId,
          mooId: dataById.fm01.mooId,
          obId: dataById.fm01.obId,
          orgId: dataById.fm01.orgId,
          ownerName: dataById.fm01.ownerName,
          ownerTel: dataById.fm01.ownerTel,
          provinceId: dataById.fm01.provinceId,
          statusId: dataById.fm01.statusId,
          symptomId: dataById.fm01.symptomId,
          tambonId: dataById.fm01.tambonId,
        },
        fm02: {
          id: dataById.fm02 ? dataById.fm02.id : "",
          fm01Id: dataById.fm02 ? dataById.fm02.fm01Id : "",
          q11: dataStep1.step1_fm02.q11 ? dataStep1.step1_fm02.q11 : "y",
          q11MoveDate: dataStep1.step1_fm02.q11MoveDate,
          q11SymptomDate: dataStep1.step1_fm02.q11SymptomDate,
          q11IntoDate: dataStep1.step1_fm02.q11IntoDate,
          // q41: location.pathname.includes("duplicate")
          //   ? dataStep4.report_from
          //   : dataStep1.step1_fm02.q41,
          // q41Disease: location.pathname.includes("duplicate")
          // ? dataStep4.disease
          // : dataStep1.step1_fm02.q41Disease,
          q41: dataStep4.report_from ?? "n",
          q41Disease: dataStep4.disease ?? "",
          q41LastDate: dataStep4.day_last_time
            ? helper.getDate(dataStep4.day_last_time)
            : "",
          q41EndDate: dataStep4.day_calm_disease
            ? helper.getDate(dataStep4.day_calm_disease)
            : "",
          q41ExamNo: dataStep4.number ?? "",
          q41LabApprove: dataStep4.verify ?? "n",
          q8Opinion: dataStep8.opinion,
        },
        fm02AnimalTouch: step1_anmTouch,
        deleteAnimalTouch: dataStep1.del_anmTouch,
        fm02HumanTouch: step1_humanTouch,
        deleteHumanTouch: dataStep1.del_humanTouch,
        fm02SymptomGeneral: step1_symptomGeneral,
        fm02SymptomRabies: step1_symptomRabies,
        fm02SymptomEia: step1_symptomEia,
        fm02Env: {
          id: dataById.fm02Env ? dataById.fm02Env.id : "",
          fm02Id: dataById.fm02 ? dataById.fm02.id : "",
          q21Farm: dataStep2.farm ? "y" : "n",
          q21Zoo: dataStep2.zoo ? "y" : "n",
          q21Corral: dataStep2.corral ? "y" : "n",
          q21Stray: dataStep2.corral ? "y" : "n",
          q21Minor: dataStep2.stray ? "y" : "n",
          q21Naturally: dataStep2.naturally ? "y" : "n",
          q21Other: dataStep2.text_other,
          q22Swamp: dataStep2.swamp ? "y" : "n",
          q22Canal: dataStep2.canal ? "y" : "n",
          q22Papa: dataStep2.papa ? "y" : "n",
          q22Groundwater: dataStep2.groundwater ? "y" : "n",
          q22Other: dataStep2.text_other_2 ? "y" : "",
          q23Hot: dataStep2.hot ? "y" : "n",
          q23Cool: dataStep2.cool ? "y" : "n",
          q23Humid: dataStep2.humid ? "y" : "n",
          q23Windy: dataStep2.windy ? "y" : "n",
          q23Rain: dataStep2.rain ? "y" : "n",
          q23Logged: dataStep2.logged ? "y" : "n",
          q23Flood: dataStep2.flood ? "y" : "n",
          q23Other: dataStep2.text_other_3 ? "y" : "",
          q24: dataStep2.distance,
          q24Distance: dataStep2.distance_text,
          q25: dataStep2.distance_2,
          q25Approve: dataStep2.approve,
          q25Distance: dataStep2.abattoir,
          q26: dataStep2.disease_area,
          q26Distance: dataStep2.distance_3_text,
          q27Delicatessen: dataStep2.delicatessen ? "y" : "n",
          q27Cook: dataStep2.cook ? "y" : "n",
          q27Garbage: dataStep2.garbage ? "y" : "n",
          q27Other: dataStep2.text_other_4 ? "y" : "",
          q28: dataStep2.vector,
          q28Other: dataStep2.text_vector,
          q29: dataStep2.water_source,
        },
        fm02InsectProtect: insectTableStep2,
        deleteFm02InsectProtect: delInsect,
        fm02Rabies: rabiesTableStep2,
        deleteFm02Rabies: delRabies,

        fm02Movement: step3Obj,
        fm02Analyse: analyseStep5,
        deleteFm02Analyse: delAnalyse,
        fm02File: investFilesStep6,
        fm02FileOther: otherFilesStep7,
        deleteFm02File: delFile,
      };
      // จะส่งเข้า api ปิด return ก่อน
      // return;

      MySwal.fire({
        text: `ต้องการบันทึก`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (value) => {
        if (value.isConfirmed) {
          try {
            const response = await API.addDiseaseInvestigation(data);
            if (response.status == 200) {
              MySwal.fire({
                text: location.pathname.includes("save")
                  ? `สร้าง "กคร.2" สำเร็จ`
                  : location.pathname.includes("duplicate")
                  ? `คัดลอก "กคร.2" สำเร็จ`
                  : `แก้ไข "กคร.2" สำเร็จ`,
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then(async (result) => {
                history.push(`/passive_surv/disease_investigation`);
              });
            }
          } catch (error) {
            if (error.response && error.response.status == 401) {
              dispatch(logout({ history }));
            } else {
              MySwal.fire({
                text: location.pathname.includes("save")
                  ? `สร้าง "กคร.2" ไม่สำเร็จ`
                  : location.pathname.includes("duplicate")
                  ? `คัดลอก "กคร.2" ไม่สำเร็จ`
                  : `แก้ไข "กคร.2" ไม่สำเร็จ`,
                icon: "warning",
                confirmButtonText: "ตกลง",
              });
            }
            console.log("error", error, data);
          }
        }
      });
    }
  };

  return (
    <div className=" ">
      <div className="px-4 bg-white d-flex flex-wrap">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2 ">
          <Link to={`/passive_surv/disease_investigation`}>
            <button className="btn btn-danger">ยกเลิก</button>
          </Link>
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-success"
            onClick={() => toSaveDiseaseInvest()}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`edit`)
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxHead title="ตัวกรอง" classLabel="">
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="d-flex align-items-center">
                    <InputText
                      type="text"
                      title="เลข Outbreak ID"
                      idName="outbreakCode"
                      value={form.outbreakCode}
                      star={true}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      disabled
                      handleChange={(value) =>
                        setForm({ ...form, outbreakCode: value })
                      }
                    />
                    <button
                      className="btn btn-add mt-auto mb-3 ml-2"
                      onClick={() => setShowOutbreakSearch(true)}
                    >
                      <img
                        className=" icon-btn "
                        src="/svg/btn-edit.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                </Col>

                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="d-flex align-items-center">
                    <InputText
                      type="text"
                      title="เลข case ID"
                      idName="caseCode"
                      value={form.caseCode}
                      star={false}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      disabled
                      handleChange={(value) =>
                        setForm({ ...form, caseCode: value })
                      }
                    />
                    <button
                      className="btn btn-add mt-auto mb-3 ml-2 "
                      onClick={() => setShowCaseIdSearch(true)}
                    >
                      <img
                        className="icon-btn "
                        src="/svg/btn-edit.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={4} sm={12} xs={12}>
                  <label className="mb-0 normal">หน่วยงานที่แจ้งโรค </label>
                  <div className="d-flex align-items-center">
                    {form.orgName ? (
                      <>
                        <button
                          className="btn btn-primary pt-2 px-3"
                          onClick={() => setShowOrgModal(true)}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="ml-2">{form.orgName}</div>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary p-2 d-flex align-items-center"
                        onClick={() => setShowOrgModal(true)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        <p className="mb-0 ml-2">{t("search")}</p>
                      </button>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputGroupText
                    type="text"
                    title="รหัสฟาร์ม"
                    imgSrc={"/svg/search.svg"}
                    imgAlt="search-icon"
                    idName="farmId"
                    value={form.farmId}
                    star={false}
                    placeholder="ค้นหาฟาร์ม"
                    classLabel="normal"
                    disabled={true}
                    handleChange={(value) =>
                      setForm({ ...form, farmId: value })
                    }
                  />
                </Col>

                <Col lg={2} md={3} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    <button
                      className="btn btn-darkblue w-100"
                      onClick={() =>
                        history.push("/farm_registration/save-data")
                      }
                    >
                      <img
                        className="mr-2 mb-1 "
                        src={"/svg/menu_5.svg"}
                        alt="menu-icon"
                      />
                      ขึ้นทะเบียนฟาร์ม
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ชื่อเจ้าของสัตว์"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled={true}
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputMask
                    mask="99-9999-9999"
                    title="หมายเลขโทรศัพท์"
                    idName="phone"
                    value={form.phone}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled={true}
                    handleChange={(value) =>
                      setForm({ ...form, phone: value.replace(/-/g, "") })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="บ้านเลขที่"
                    idName="homeNo"
                    value={form.homeNo}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled={true}
                    handleChange={(value) =>
                      setForm({ ...form, homeNo: value })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    disabled={true}
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    disabled={true}
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    disabled={true}
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="หมู่ที่"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="villageId"
                    classLabel="normal"
                    disabled={true}
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({ ...form, villageId: value.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxHead>

          <div className="pt-3 container-fixed-footer ml-0">
            <ul
              className="nav nav-pills mb-3 font14"
              id="pills-tab"
              role="tablist"
            >
              <li
                className="nav-item "
                onClick={() => setActiveTab("pills-history-tab")}
              >
                <a
                  className="nav-link active "
                  id="pills-history-tab"
                  data-toggle="pill"
                  href="#pills-history"
                  role="tab"
                  aria-controls="pills-history"
                  aria-selected="true"
                >
                  1.ประวัติสัตว์ป่วย
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-environ-tab")}
              >
                <a
                  className="nav-link "
                  id="pills-environ-tab"
                  data-toggle="pill"
                  href="#pills-environ"
                  role="tab"
                  aria-controls="pills-environ"
                  aria-selected="false"
                >
                  2. สภาพแวดล้อม
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-cause-tab")}
              >
                <a
                  className="nav-link "
                  id="pills-cause-tab"
                  data-toggle="pill"
                  href="#pills-cause"
                  role="tab"
                  aria-controls="pills-cause"
                  aria-selected="false"
                >
                  3. ปัจจัยเสี่ยงและสาเหตุการเกิดโรค
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-disease-tab")}
              >
                <a
                  className="nav-link "
                  id="pills-disease-tab"
                  data-toggle="pill"
                  href="#pills-disease"
                  role="tab"
                  aria-controls="pills-disease"
                  aria-selected="false"
                >
                  4. ประวัติการเกิดโรค(ในพื้นที่)ครั้งล่าสุด
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-analyze-tab")}
              >
                <a
                  className="nav-link "
                  id="pills-analyze-tab"
                  data-toggle="pill"
                  href="#pills-analyze"
                  role="tab"
                  aria-controls="pills-analyze"
                  aria-selected="false"
                >
                  5. วิเคราะห์ข้อมูลการสอบสวนโรคข้างต้น
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-invest-tab")}
              >
                <a
                  className="nav-link "
                  id="pills-invest-tab"
                  data-toggle="pill"
                  href="#pills-invest"
                  role="tab"
                  aria-controls="pills-invest"
                  aria-selected="false"
                >
                  6. แนบไฟล์บันทึกรายงานสอบสวนโรค
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-file-tab")}
              >
                <a
                  className="nav-link "
                  id="pills-file-tab"
                  data-toggle="pill"
                  href="#pills-file"
                  role="tab"
                  aria-controls="pills-file"
                  aria-selected="false"
                >
                  7. แนบไฟล์อื่นๆ
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-comment-tab")}
              >
                <a
                  className="nav-link "
                  id="pills-comment-tab"
                  data-toggle="pill"
                  href="#pills-comment"
                  role="tab"
                  aria-controls="pills-comment"
                  aria-selected="false"
                >
                  8. ความคิดเห็นเพิ่มเติมจากเจ้าหน้าที่
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-history"
                role="tabpanel"
                aria-labelledby="pills-history-tab"
              >
                {form.modeGet == "default" && (
                  <DiseaseInvest_Step1
                    save={(data) => {
                      setForm({ ...form, step1: data });
                    }}
                    modeGet={form.modeGet}
                    dataRes={form.dataAllById}
                  />
                )}

                {form.modeGet == "getById" && (
                  <DiseaseInvest_Step1
                    save={(data) => {
                      setForm({ ...form, step1: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step1}
                    dataRes={form.dataAllById}
                  />
                )}
              </div>

              <div
                className="tab-pane fade"
                id="pills-environ"
                role="tabpanel"
                aria-labelledby="pills-environ-tab"
              >
                <div className="bg-white">
                  {form.modeGet == "default" && (
                    <DiseaseInvest_Step2
                      save={(data) => {
                        setForm({ ...form, step2: data });
                      }}
                      modeGet={form.modeGet}
                    />
                  )}
                  {form.modeGet == "getById" && (
                    <DiseaseInvest_Step2
                      save={(data) => {
                        setForm({ ...form, step2: data });
                      }}
                      modeGet={form.modeGet}
                      dataAll={form.step2}
                    />
                  )}
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-cause"
                role="tabpanel"
                aria-labelledby="pills-cause-tab"
              >
                <div className=" bg-white ">
                  {form.modeGet == "default" && (
                    <DiseaseInvest_Step3
                      save={(data) => {
                        setForm({ ...form, step3: data });
                      }}
                      modeGet={form.modeGet}
                    />
                  )}

                  {form.modeGet == "getById" && (
                    <DiseaseInvest_Step3
                      save={(data) => {
                        setForm({ ...form, step3: data });
                      }}
                      modeGet={form.modeGet}
                      dataAll={form.step3}
                    />
                  )}
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-disease"
                role="tabpanel"
                aria-labelledby="pills-disease-tab"
              >
                <div className=" bg-white ">
                  {form.modeGet == "default" && (
                    <DiseaseInvest_Step4
                      save={(data) => {
                        setForm({ ...form, step4: data });
                      }}
                      modeGet={form.modeGet}
                    />
                  )}

                  {form.modeGet == "getById" && (
                    <DiseaseInvest_Step4
                      save={(data) => {
                        setForm({ ...form, step4: data });
                      }}
                      modeGet={form.modeGet}
                      dataAll={form.step4}
                    />
                  )}
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-analyze"
                role="tabpanel"
                aria-labelledby="pills-analyze-tab"
              >
                {form.modeGet == "default" && (
                  <DiseaseInvest_Step5
                    save={(data) => {
                      setForm({ ...form, step5: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step5}
                  />
                )}

                {form.modeGet == "getById" && (
                  <DiseaseInvest_Step5
                    save={(data) => {
                      setForm({ ...form, step5: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step5}
                  />
                )}
              </div>

              <div
                className="tab-pane fade"
                id="pills-invest"
                role="tabpanel"
                aria-labelledby="pills-invest-tab"
              >
                {form.modeGet == "default" && (
                  <DiseaseInvest_Step6
                    save={(data) => {
                      setForm({ ...form, step6: data });
                    }}
                    modeGet={form.modeGet}
                  />
                )}

                {form.modeGet == "getById" && (
                  <DiseaseInvest_Step6
                    save={(data) => {
                      setForm({ ...form, step6: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step6}
                  />
                )}
              </div>

              <div
                className="tab-pane fade"
                id="pills-file"
                role="tabpanel"
                aria-labelledby="pills-file-tab"
              >
                {form.modeGet == "default" && (
                  <DiseaseInvest_Step7
                    save={(data) => {
                      setForm({ ...form, step7: data });
                    }}
                    modeGet={form.modeGet}
                  />
                )}

                {form.modeGet == "getById" && (
                  <DiseaseInvest_Step7
                    save={(data) => {
                      setForm({ ...form, step7: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step7}
                  />
                )}
              </div>

              <div
                className="tab-pane fade"
                id="pills-comment"
                role="tabpanel"
                aria-labelledby="pills-comment-tab"
              >
                {form.modeGet == "default" && (
                  <DiseaseInvest_Step8
                    save={(data) => {
                      setForm({ ...form, step8: data });
                    }}
                    modeGet={form.modeGet}
                  />
                )}

                {form.modeGet == "getById" && (
                  <DiseaseInvest_Step8
                    save={(data) => {
                      setForm({ ...form, step8: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step8}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {showOutbreakSearch && (
          <ModalOutbreakSearch
            show={showOutbreakSearch}
            mode={form.modeOutbreak}
            close={() => setShowOutbreakSearch(false)}
            save={(value) =>
              setForm({
                ...form,
                outbreakCode: value.obCode,
                outbreakId: value.obId,
              })
            }
          />
        )}
        {showFarmSearch && (
          <ModalFarmSearch
            show={showFarmSearch}
            mode={form.modeFarmSearch}
            close={() => setShowFarmSearch(false)}
            save={(value) => setForm({ ...form, farmId: value })}
          />
        )}
        {showOrgModal && (
          <ModalOrganizes
            show={showOrgModal}
            close={() => setShowOrgModal(false)}
            toSaveOrg={(data) => setOrganizes(data)}
          />
        )}
      </div>
    </div>
  );
}

export default Disease_Investigation_Save;
