import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { logout } from "../../redux/actions/logoutAction";
import * as API from "../../utils/apis";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useLocation } from "react-router-dom";
import ActionPlan_Step1 from "../../components/Action_Plan/ActionPlan_Step1";
import ActionPlan_Step2 from "../../components/Action_Plan/ActionPlan_Step2";
import ActionPlan_Step3 from "../../components/Action_Plan/ActionPlan_Step3";
const MySwal = withReactContent(Swal);

function Disease_Record(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);

  const initialForm = {
    modeGet: "default",

    step1: {},
    step2: {},
    step3: {},
    addAnimal: [],
  };

  const [form, setForm] = useState(initialForm);
  const [onSave, setOnSave] = useState(false);
  const [checkSave, setCheckSave] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      getActionPlanById(props.match.params.id);
    }
  }, [user, location.pathname]);

  useEffect(() => {
    if (onSave) {
      toSave();
    }
  }, [onSave]);

  const checkOnSave = (value) => {
    if (value === false) {
      setCheckSave(false);
    } else {
      setOnSave(value);
    }
  };

  const getActionPlanById = async (id) => {
    let dataRes;
    try {
      const response = await API.getActionPlanById(id);
      if (response.status === 200) {
        dataRes = await response.data;
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      let step2_file = [];
      if (dataById.actionPlanFiles && dataById.actionPlanFiles.length > 0) {
        dataById.actionPlanFiles.map((item, i) => {
          step2_file.push({
            id: item.id,
            file: item.url,
            fileName: item.name,
            detail_file: item.description,
            date_disease: item.date,
          });
        });
      }

      let step3Dtl = [];
      if (dataById.actionPlanDtl && dataById.actionPlanDtl.length > 0) {
        dataById.actionPlanDtl.map((item, i) => {
          step3Dtl.push({
            id: item.id,
            countyId: item.zoneId,
            countyName: item.zoneName,
            number: item.targetAmount,
            details: item.orgRemark,
            provinceId: item.provinceId,
            districtId: item.amphurId,
            subDistrictId: item.tambonId,
            villageId: item.mooId,
          });
        });
      }

      let step1_dataAnimal = [];
      if (dataById.actionAnimalType && dataById.actionAnimalType.length > 0) {
        dataById.actionAnimalType.map((item, i) => {
          step1_dataAnimal.push({
            id: item.id,
            animal: { value: item.animaltypeId, label: item.name },
            save: false,
          });
        });
      }

      if (dataById) {
        setForm({
          ...form,
          modeGet: "getById",
          step1: {
            fiscalYear: dataById.actionPlan.year,
            namep: dataById.actionPlan.projectName,
            animalTypeId: dataById.actionPlan.animalTypeId,
            details: dataById.actionPlan.description,
            objec: dataById.actionPlan.purpose,
            datep: dataById.actionPlan.startDate,
            todate: dataById.actionPlan.endDate,
            statusId: dataById.actionPlan.statusId,
          },
          addAnimal: step1_dataAnimal,
          step2: {
            data_file: step2_file,
            checkAddFile: false,
          },

          step3: {
            data_setarea: step3Dtl,
          },
        });
      }
    }
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/active_surv/action_plan", name: "การเฝ้าระวังเชิงรับ" },
    { path: "/active_surv/action_plan", name: "แผนการดำเนินงาน" },
    { path: "active", name: "บันทึกแผนการดำเนินงาน" },
  ];

  const toSave = () => {
    setCheckSave(true);
    if (onSave) {
      const dataStep1 = form.step1;
      const dataStep2 = form.step2;
      const dataStep3 = form.step3;
      const dataStep1Animal = form.addAnimal;

      const filesStep2 = [];
      if (dataStep2.data_file && dataStep2.data_file.length > 0) {
        dataStep2.data_file.map((item, i) => {
          if (item.id) {
          } else {
            filesStep2.push({
              id: item.id ? item.id : "",
              name: item.fileName,
              description: item.detail_file,
              date: item.date_file ? helper.getDate(item.date_file) : "",
              data: item.file,
            });
          }
        });
      }

      const dataAnimal = [];
      if (dataStep1Animal && dataStep1Animal.length > 0) {
        dataStep1Animal.map((item, i) => {
          dataAnimal.push({
            id: item.id ? item.id : "",
            animaltypeId: item.animal.value,
          });
        });
      }

      const dataSetarea = [];
      if (dataStep3.data_setarea && dataStep3.data_setarea.length > 0) {
        dataStep3.data_setarea.map((item, i) => {
          dataSetarea.push({
            id: item.id ? item.id : "",
            zoneId: item.countyId,
            provinceId: item.provinceId,
            amphurId: item.districtId,
            tambonId: item.subDistrictId,
            mooId: item.villageId,
            orgRemark: item.details,
            targetAmount: item.number,
          });
        });
      }

      const delFile = [];
      if (dataStep2.del_file && dataStep2.del_file.length > 0) {
        dataStep2.del_file.map((item) => {
          delFile.push(item);
        });
      }

      const delSetarea = [];
      if (dataStep3.del_setarea && dataStep3.del_setarea.length > 0) {
        dataStep3.del_setarea.map((item) => {
          delSetarea.push(item);
        });
      }

      const data = {
        actionPlan: {
          year: dataStep1.fiscalYear,
          projectName: dataStep1.namep,
          animalTypeId: dataStep1.animalTypeId,
          description: dataStep1.details,
          purpose: dataStep1.objec,
          startDate: dataStep1.datep ? helper.getDate(dataStep1.datep) : "",
          endDate: dataStep1.todate ? helper.getDate(dataStep1.todate) : "",
          statusId: dataStep1.statusId,
        },
        actionPlanFiles: filesStep2,
        deleteFiles: delFile,
        actionPlanDtl: dataSetarea,
        deleteDtl: delSetarea,
        actionAnimaltype: dataAnimal,
      };
      MySwal.fire({
        text: `ต้องการบันทึก`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (value) => {
        if (value.isConfirmed) {
          try {
            const response = location.pathname.includes("/disease_record")
              ? await API.addActionPlan(data)
              : await API.updateActionPlan(props.match.params.id, data);
            if (response.status === 200) {
              MySwal.fire({
                text: location.pathname.includes("/disease_record")
                  ? `สร้าง "บันทึกการเกิดโรคระบาดสัตว์เบื้องต้น" สำเร็จ`
                  : `แก้ไข "บันทึกการเกิดโรคระบาดสัตว์เบื้องต้น" สำเร็จ`,
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then(async (result) => {
                history.push(`/active_surv/action_plan`);
              });
            }
          } catch (error) {
            const mgeError = error?.response?.data.errors[0];
            if (error.response && error.response.status === 401) {
              dispatch(logout({ history }));
            }
            console.log("error", error);
            MySwal.fire({
              text: mgeError,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
        }
      });
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-danger"
            onClick={() => history.push("/active_surv/action_plan")}
          >
            ยกเลิก
          </button>
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-success"
            onClick={() => {
              toSave();
            }}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`active_surv/action_plan/edit`)
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item ">
              <a
                className="nav-link active"
                id="pills-home-tab"
                data-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                1.ข้อมูลทั่วไป
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                2.แนบไฟล์
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="pills-contact-tab"
                data-toggle="pill"
                href="#pills-contact"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                3. เป้าหมายพื้นที่
              </a>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              {form.modeGet == "default" && (
                <ActionPlan_Step1
                  save={(data, addAnimal) => {
                    setForm({ ...form, step1: data, addAnimal: addAnimal });
                  }}
                  modeGet={form.modeGet}
                  onSave={onSave}
                  checkOnSave={(value) => checkOnSave(value)}
                  checkSave={checkSave}
                />
              )}

              {form.modeGet == "getById" && (
                <ActionPlan_Step1
                  save={(data, addAnimal) => {
                    setForm({ ...form, step1: data, addAnimal: addAnimal });
                  }}
                  modeGet={form.modeGet}
                  dataAll={form.step1}
                  animalData={form.addAnimal}
                  onSave={onSave}
                  checkOnSave={(value) => checkOnSave(value)}
                  checkSave={checkSave}
                />
              )}
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              {form.modeGet == "default" && (
                <ActionPlan_Step2
                  save={(data) => {
                    setForm({ ...form, step2: data });
                  }}
                  modeGet={form.modeGet}
                />
              )}

              {form.modeGet == "getById" && (
                <ActionPlan_Step2
                  save={(data) => {
                    setForm({ ...form, step2: data });
                  }}
                  modeGet={form.modeGet}
                  dataAll={form.step2}
                />
              )}
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              {form.modeGet == "default" && (
                <ActionPlan_Step3
                  save={(data) => {
                    setForm({ ...form, step3: data });
                  }}
                  modeGet={form.modeGet}
                />
              )}

              {form.modeGet == "getById" && (
                <ActionPlan_Step3
                  save={(data) => {
                    setForm({ ...form, step3: data });
                  }}
                  modeGet={form.modeGet}
                  dataAll={form.step3}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Disease_Record;
