import React, { useState, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BoxCard from "../../components/BoxCard";
import { Row, Col, Form, Table } from "react-bootstrap";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import DataTableNodata from "../../components/DataTableNodata";
import * as API from "../../utils/apis";
import withReactContent from "sweetalert2-react-content";
import { useLocation } from "react-router-dom";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

function Assess_Risk_Save(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);

  const initialForm = {
    disease: "",

    name: "",
    description: "",
    tambonWeight: "",
    statusId: "",
    report_from: "n",

    dataDisease: [],
    delDisease: [],
    checkAddDisease: false,
    checkEditDisease: false,

    modeEditTable1: false,
    modeEditTable2: false,
  };
  const [form, setForm] = useState(initialForm);

  const initialFormDisease = {
    evFormDisease: "",
    evFormDiseaseName: "",
  };
  const [formDisease, setFormDisease] = useState(initialFormDisease);

  const initialFormJson = {
    diseaseTypeId: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initInvalidMsg = {
    evFormDisease: "",
    description: "",
    name: "",
    tambonWeight: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const [onSave, setOnSave] = useState(false);
  const [checkSave, setCheckSave] = useState(false);

  // Select ชนิดโรค
  const [diseaseTypeSelectValue, setDiseaseTypeSelectValue] = useState([]);
  const [diseaseTypeList, setDiseaseTypeList] = useState([]);

  // Select สถานะ
  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    {
      value: 1,
      label: "ใช้งาน",
    },
    {
      value: 2,
      label: "ไม่ได้ใช้งาน",
    },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  useEffect(async () => {
    await getMasterJson();
    await getDiseaseType();
  }, []);

  useEffect(async () => {
    if (location.pathname.includes("edit")) {
      getAssessById(props.match.params.id);
    }
  }, [user, location.pathname, diseaseTypeList.length > 0]);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/assess_risk/list-assess-risk", name: "ประเมินความเสี่ยง" },
    { path: "active", name: "บันทึกชุดประเมินความเสี่ยง" },
  ];

  const getAssessById = async (id) => {
    dispatch(loading(true));
    let dataRes;
    try {
      const response = await API.getAssessById(id);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
    // console.log("dataRes", dataRes)

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.evFormDtl && dataById.evFormDtl.statusId) {
        let statusSelectValue = await selectStatusList.find(
          (item) => item.value == dataById.evFormDtl.statusId
        );
        setStatusSelectValue(statusSelectValue);
      }

      const listDisease = [];
      if (dataById.evFormDisease && dataById.evFormDisease.length > 0) {
        dataById.evFormDisease.map((item, i) => {
          let evFormDiseaseName;
          diseaseTypeList.filter((itemD) => {
            if (itemD.value == item) {
              evFormDiseaseName = itemD.label;
            }
          });

          listDisease.push({
            evFormDisease: item,
            evFormDiseaseName: evFormDiseaseName,
          });
        });
      }

      setForm({
        ...form,
        mode: "edit",
        name: dataById.evFormDtl.name,
        description: dataById.evFormDtl.description,
        report_from: dataById.evFormDtl.flgLocation,
        tambonWeight:
          dataById.evFormDtl.flgLocation == "y"
            ? dataById.evFormDtl.tambonWeight
            : "",
        statusId: dataById.evFormDtl.statusId,

        dataDisease: listDisease,
      });
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDisease("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.diseaseTypeName,
          });
        });
        await setDiseaseTypeList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMasterJson = async () => {
    const params = await ["diseaseType"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          diseaseTypeId: data.diseaseType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const toSaveDisease = () => {
    if (validate()) {
      const dataDs = form;
      const listDisease = [];
      if (dataDs.dataDisease && dataDs.dataDisease.length > 0) {
        dataDs.dataDisease.map((item, i) => {
          if (item.id) {
          } else {
            listDisease.push(item.evFormDisease);
          }
        });
      }

      const data = {
        evFormDtl: {
          name: form.name,
          description: form.description,
          flgLocation: form.report_from,
          tambonWeight: form.tambonWeight,
          statusId: form.statusId,
        },
        evFormDisease: listDisease,
      };

      MySwal.fire({
        text: `ต้องการบันทึกใช่หรือไม่`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (value) => {
        if (value.isConfirmed) {
          try {
            const response = location.pathname.includes("save")
              ? await API.addDiseaseSave(data)
              : await API.updateAssess(props.match.params.id, data);
            if (response.status == 200) {
              MySwal.fire({
                text: location.pathname.includes("save")
                  ? `สร้าง "บันทึกข้อมูล" สำเร็จ`
                  : `แก้ไข "บันทึกข้อมูล" สำเร็จ`,
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then(async (result) => {
                history.push(`/assess_risk/list-assess-risk`);
              });
            }
          } catch (error) {
            dispatch(loading(false));
            if (error.response && error.response.status == 401) {
              dispatch(logout({ history }));
            } else {
              const textError = error.response?.data?.errors;
              const textAlert = location.pathname.includes("save")
                ? `สร้าง "บันทึกข้อมูล" ไม่สำเร็จ`
                : `แก้ไข "บันทึกข้อมูล" ไม่สำเร็จ`;
              MySwal.fire({
                title: textAlert,
                text: textError,
                icon: "warning",
                confirmButtonText: "ตกลง",
              });
            }
            console.log("error", error, data);
          }
        } else {
          dispatch(loading(false));
        }
      });
    }
  };

  const diseaseSave = (data, type) => {
    if (validateFormDisease()) {
      let arrayDisease = [...form.dataDisease];
      if (type == "add") {
        arrayDisease.push({
          evFormDisease: formDisease.evFormDisease,
          evFormDiseaseName: formDisease.evFormDiseaseName,
        });
        setForm({
          ...form,
          dataDisease: arrayDisease,
          checkAddDisease: false,
          modeEditTable2: false,
        });
      } else if (type == "edit") {
        data.checkEditDisease = false;
        data.evFormDisease = formDisease.evFormDisease;
        data.evFormDiseaseName = formDisease.evFormDiseaseName;
        setForm({ ...form, checkAddDisease: false, modeEditTable2: false });
      }
    }
    console.log("Form", form);
  };

  const editDisease = (item) => {
    item.checkEditDisease = true;
    setForm({ ...form });
    setFormDisease({
      ...formDisease,
      evFormDisease: item.evFormDisease,
      evFormDiseaseName: item.evFormDiseaseName,
    });

    setDiseaseTypeSelectValue([
      { value: item.evFormDisease, label: item.evFormDiseaseName },
    ]);
  };

  const DelFile = (index) => {
    form.dataDisease.splice(index, 1);
    setForm({ ...form, dataDisease: form.dataDisease });
  };

  const toDisease = () => {
    setForm({ ...form, checkAddDisease: true });
    setFormDisease({ ...formDisease, evFormDisease: "" });
    setDiseaseTypeSelectValue([]);
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;
    if (form.report_from == "y" && form.tambonWeight == "") {
      addInvalid("tambonWeight", "กรุณากรอกข้อมูล");
      validated = false;
    }

    if (form.name == "") {
      addInvalid("name", "กรุณากรอกชื่อชุดประเมิน");
      validated = false;
    }

    if (form.description == "") {
      addInvalid("description", "กรุณากรอกรายละเอียด");
      validated = false;
    }

    return validated;
  };

  const validateFormDisease = () => {
    let validated = true;
    if (formDisease.evFormDisease == "") {
      addInvalid("evFormDisease", "กรุณากรอกข้อมูล");
      validated = false;
    }

    return validated;
  };

  const renderAddDisease = () => {
    return (
      <>
        <tr>
          <td>
            <InputSelect
              star={false}
              placeholder="กรุณาเลือก"
              idName="evFormDisease"
              classFormGroup="mb-0"
              selectValue={diseaseTypeSelectValue}
              optionsList={diseaseTypeList}
              handleChange={(value) => {
                setFormDisease({
                  ...formDisease,
                  evFormDisease: value.value,
                  evFormDiseaseName: value.label,
                });
                setDiseaseTypeSelectValue([value]);
              }}
              handleInvalid={() => removeInvalid("evFormDisease")}
              invalid={invalid.evFormDisease}
            />
          </td>
          <td className="text-center">
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                diseaseSave(formDisease, "add");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </td>
        </tr>
      </>
    );
  };

  const renderTableDisease = (data, index) => {
    return (
      <tr key={index}>
        <td className="fitwidth">
          {data.checkEditDisease ? (
            <InputSelect
              star={false}
              placeholder="กรุณาเลือก"
              idName="evFormDisease"
              classFormGroup="mb-0"
              selectValue={diseaseTypeSelectValue}
              optionsList={diseaseTypeList}
              handleSelectValue={(value) =>
                setDiseaseTypeSelectValue([
                  { value: value.value, label: value.label },
                ])
              }
              handleChange={(value) => {
                setFormDisease({
                  ...formDisease,
                  evFormDisease: value.value,
                  evFormDiseaseName: value.label,
                });
              }}
            />
          ) : (
            data.evFormDiseaseName
          )}
        </td>
        <td className="text-center">
          {data.checkEditDisease ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                diseaseSave(data, "edit");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => editDisease(data)}
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button className="btn btn-del" onClick={() => DelFile(index)}>
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <div className="pl-lg-4 pl-md-4 pl-sm-2 bg-white d-flex flex-wrap align-items-center ml30">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-danger"
            onClick={() => history.push("/assess_risk/list-assess-risk")}
          >
            ยกเลิก
          </button>
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-success"
            onClick={() => {
              toSaveDisease();
            }}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`assess_risk/list-assess-risk/edit`)
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ข้อมูลทั่วไป">
            <div>
              <Row className="mb-2 ">
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่อชุดประเมินความเสี่ยง"
                    idName="name"
                    value={form.name}
                    star={true}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, name: value })}
                    handleInvalid={() => removeInvalid("name")}
                    invalid={invalid.name}
                  />
                </Col>
              </Row>

              <Row className="mb-2 ">
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="รายละเอียด"
                    idName="description"
                    value={form.description}
                    star={true}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, description: value })
                    }
                    handleInvalid={() => removeInvalid("description")}
                    invalid={invalid.description}
                  />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col
                  lg={3}
                  md={3}
                  sm={6}
                  xs={6}
                  className="d-flex align-items-center"
                >
                  <p className="bold mb-0">ชนิดโรค </p>
                </Col>

                <Col
                  lg={3}
                  md={3}
                  sm={6}
                  xs={6}
                  className="d-flex justify-content-end"
                >
                  <button
                    className="btn btn-add text-white"
                    onClick={() => toDisease()}
                  >
                    <i className="fas fa-plus mr-1"></i> เพิ่ม
                  </button>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md={6} xs={12} className="d-flex justify-content-end">
                  <div className="table-responsive pb-3 ">
                    <Table className="table table-striped table-bordered my-2 ">
                      <thead>
                        <tr>
                          <th scope="col">ชนิดโรค</th>
                          <th className="text-center" scope="col">
                            จัดการ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.dataDisease && form.dataDisease.length > 0
                          ? form.dataDisease.map((item, i) => {
                              return renderTableDisease(item, i);
                            })
                          : null}
                        {form.checkAddDisease &&
                          renderAddDisease(form.dataDisease)}
                        {form.dataDisease &&
                          form.dataDisease.length == 0 &&
                          !form.checkAddDisease && (
                            <DataTableNodata colSpan={2} />
                          )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col
                  lg={2}
                  md={2}
                  sm={12}
                  className="d-flex align-items-center"
                >
                  <p className="mb-0">มีการให้คะแนนตามตำบล : </p>
                </Col>

                <Col lg={10} md={10} sm={12} className="w-100">
                  <div className="d-flex align-items-center flex-wrap ">
                    <div className="d-flex">
                      <Form.Check
                        inline
                        type="radio"
                        name="report_from"
                        id="other"
                        value={"y"}
                        checked={form.report_from == "y"}
                        label="มี Weight ของตำบล"
                        onChange={(e) => {
                          setForm({ ...form, report_from: e.target.value });
                        }}
                      />
                      <div>
                        <InputText
                          type="text"
                          title=""
                          idName="tambonWeight"
                          value={form.tambonWeight}
                          star={true}
                          placeholder="โปรดระบุ"
                          classLabel="normal"
                          classFormGroup="mb-0"
                          disabled={form.report_from != "y"}
                          handleChange={(value) => {
                            setForm({ ...form, tambonWeight: value });
                          }}
                          handleInvalid={() => removeInvalid("tambonWeight")}
                          invalid={invalid.tambonWeight}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-wrap ">
                    <div className="d-flex">
                      <Form.Check
                        inline
                        type="radio"
                        name="report_from"
                        id="other"
                        value={"n"}
                        checked={form.report_from == "n"}
                        label="ไม่มี"
                        onChange={(e) => {
                          setForm({
                            ...form,
                            report_from: e.target.value,
                            tambonWeight: "",
                          });
                          removeInvalid("tambonWeight");
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard classNameBox="mt-3" title="สถานะ">
            <Col lg={3} md={6} sm={12} xs={12}>
              <InputSelect
                title="สถานะ"
                star={false}
                placeholder="กรุณาเลือก"
                idName="statusId"
                classLabel="normal"
                selectValue={statusSelectValue}
                optionsList={statusList}
                handleSelectValue={(value) =>
                  setStatusSelectValue([
                    { value: value.value, label: value.label },
                  ])
                }
                handleChange={(value) =>
                  setForm({ ...form, statusId: value.value })
                }
              />
            </Col>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Assess_Risk_Save;
