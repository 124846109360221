import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";
import { useDispatch, useSelector } from "react-redux";
import BoxCard from "../../components/BoxCard";
import InputSelect from "../../components/InputSelect";
import InputText from "../../components/InputText";
import { Row, Col } from "react-bootstrap";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import UploadImage from "../../components/UploadImage";
import ModalEditAnimal from "../../components/Modal.EditAnimal";
import { loading } from "../../redux/actions/loadingAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useLocation } from "react-router-dom";

const MySwal = withReactContent(Swal);

function KeepSample_Step1({
  save,
  dataAll,
  modeGet,
  buildingList,
  diseasesListData,
  animalListData,
  resultsList,
  immuneOList,
  immuneAList,
  immuneA1List,
  arrayFiles,
  sampleFile,
  checkOnSave,
  checkSave,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const initInvalidMsg = {
    sendSampleMethodId: "",
    labId: "",
    purposeSampleId: "",
    diseaseTypeId: "",
  };

  const initSampleForm = {
    id: "",
    sampleNo: "",
    sampleKeepTypeId: "",
    sampleAmt: "",
    keepDate: "",
    save: true,
  };

  const initAnimalForm = {
    id: "",
    animalCode: "",
    name: "",
    resultsStatus: "",
    immuneO: "",
    immuneA: "",
    immuneA1: "",
    sampleBarnAnimalDtl: [initSampleForm],
  };

  const initBarnForm = {
    id: "",
    barnId: [],
    animalTypeId: [],
    animalAmt: 1,
    limsCaseId: "",
    sampleBarnAnimal: [initAnimalForm],
  };

  const [barn, setBarn] = useState([]);
  const [vaccination, setVaccination] = useState([]);
  const [dataFile, setDataFile] = useState(
    modeGet === "getById" ? arrayFiles : []
  );
  const [delFile, setDelFile] = useState([]);

  const initialForm = {
    indexDtl: 0,
    barn: modeGet === "getById" ? dataAll.sampleBarn : [],
    del_bran: [],
    checkForm: false,
    keepSampleDate: modeGet === "getById" ? dataAll.sample?.keepSampleDate : "",
    sendSampleDate: modeGet === "getById" ? dataAll.sample?.sendSampleDate : "",
    sendSampleMethodId:
      modeGet === "getById" ? dataAll.sample?.sendSampleMethodId : "",
    sendSampleMethodName: "",
    remark: modeGet === "getById" ? dataAll.sample?.remark : "",
    labId: modeGet === "getById" ? dataAll.sample?.labId : "",
    labName: "",
    purposeSampleId:
      modeGet === "getById" ? dataAll.sample?.purposeSampleId : "",
    purposeSampleName: "",
    diseaseTypeId: modeGet === "getById" ? dataAll.sample?.diseaseTypeId : "",
    diseaseTypeName: "",

    data_file: modeGet === "getById" ? dataAll.sampleFiles : [],
    del_file: [],

    // แก้ไขสัตว์
    data_setAnimal: modeGet === "getById" ? dataAll.sampleAnimal : [],
    modeSetAnimal: "add",
    dataAnimal: "",
    dataIndex: "",
    //==============
  };
  const [form, setForm] = useState(initialForm);

  const initialFormFile = {
    id: "",
    url: "",
    data: "",
    description: "",
    date: "",
    name: "",
    size: "",
    save: true,
  };
  // const [formFile, setFormFile] = useState(initialFormFile);

  const initialFormJson = {
    animalId: {},
    husbandry: {},
    animalPurpose: {},
    vaccineType: {},
    keepName: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const [showAddSetAnimal, setShowAddSetAnimal] = useState(false);

  const [sendSampleSelectValue, setSendSampleSelectValue] = useState([]);
  const [sendSampleList, setSendSampleList] = useState([]);

  const [labIdSelectValue, setLabIdSelectValue] = useState([]);
  const [labIdList, setLabIdList] = useState([]);

  const [purposeSelectValue, setPurposeSelectValue] = useState([]);
  const [purposeList, setPurposeList] = useState([]);

  const [sampleKeepTypeIdList, setSampleKeepTypeIdList] = useState([]);

  const [diseaseTypeSelectValue, setDiseaseTypeSelectValue] = useState([]);
  const [diseaseTypeList, setDiseaseTypeList] = useState([]);

  const [houseSelectValue, setHouseSelectValue] = useState([]);
  const [houseList, setHouseList] = useState([]);

  const [invalid, setInvalid] = useState(initInvalidMsg);

  useEffect(async () => {
    await getSampleType();
    await getSampleSend();
    await getLab();
    await getSamplePurpose();
    getMasterJson();
    toSaveStep1();
  }, []);
  useEffect(() => {
    if (modeGet === "getById") {
      getDataById();
    }
    if (modeGet === "default") {
      setForm(initialForm);
    }
  }, [purposeList.length > 0]);

  useEffect(() => {
    toSaveStep1();
  }, [
    form.keepSampleDate,
    form.sendSampleDate,
    form.sendSampleMethodId,
    form.remark,
    form.labId,
    form.purposeSampleId,
    form.diseaseTypeId,
    form.data_file,
    form.del_file,
  ]);

  useEffect(() => {
    setForm({ ...form, del_file: delFile });
    toSaveFile();
  }, [delFile]);

  useEffect(() => {
    toSaveStep1();
  }, [form.barn]);

  useEffect(() => {
    if (checkSave) {
      toValidate();
    }
  }, [checkSave]);

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if (form.sendSampleMethodId === "") {
      addInvalid("sendSampleMethodId", "โปรดระบุวิธีการส่งตัวอย่าง");
      validated = false;
    }

    if (form.labId === "") {
      addInvalid("labId", "โปรดระบุสถานที่ส่งตัวอย่าง");
      validated = false;
    }

    if (form.purposeSampleId === "") {
      addInvalid("purposeSampleId", "โปรดระบุวัตถุประสงค์ในการส่งตัวอย่าง");
      validated = false;
    }

    if (form.diseaseTypeId === "") {
      addInvalid("diseaseTypeId", "โปรดระบุชนิดโรคที่สงสัย");
      validated = false;
    }

    return validated;
  };

  const toValidate = () => {
    if (validate()) {
      save(form);
      checkOnSave(true);
    } else {
      checkOnSave(false);
    }
  };

  const toSaveStep1 = () => {
    save(form);
  };

  const toSaveBarn = async () => {
    let dataBarn = JSON.parse(JSON.stringify(barn));
    if (dataBarn.length > 0) {
      dataBarn.map((barnElement, index) => {
        if (barnElement.sampleBarnAnimal.length !== 0) {
          if (barnElement.barnId && barnElement.barnId.length > 0) {
            barnElement.barnId = barnElement.barnId[0]?.value;
          }
          if (barnElement.animalTypeId && barnElement.animalTypeId.length > 0) {
            barnElement.animalTypeId = barnElement.animalTypeId[0]?.value;
          }
          if (
            barnElement.sampleBarnAnimal &&
            barnElement.sampleBarnAnimal.length > 0
          ) {
            const sampleBarnAnimal = barnElement.sampleBarnAnimal;
            sampleBarnAnimal.map((animalElement) => {
              if (animalElement.immuneO) {
                animalElement.immuneO = animalElement.immuneO[0]?.label;
              }
              if (animalElement.immuneA && animalElement.immuneA.length > 0) {
                animalElement.immuneA = animalElement.immuneA[0]?.label;
              }
              if (animalElement.immuneA1 && animalElement.immuneA1.length > 0) {
                animalElement.immuneA1 = animalElement.immuneA1[0]?.label;
              }

              if (
                animalElement.resultsStatus &&
                animalElement.resultsStatus.length > 0
              ) {
                animalElement.resultsStatus =
                  animalElement.resultsStatus[0]?.value;
              }

              const sampleDtl = animalElement.sampleBarnAnimalDtl;
              sampleDtl.map((sampleElement) => {
                if (
                  sampleElement.sampleKeepTypeId &&
                  sampleElement.sampleKeepTypeId.length > 0
                ) {
                  sampleElement.sampleKeepTypeId =
                    sampleElement.sampleKeepTypeId[0]?.value;
                }

                if (sampleElement.keepDate) {
                  sampleElement.keepDate = helper.getDate(
                    sampleElement.keepDate
                  );
                }
              });
            });
          }
        }
      });
    }
    setForm({ ...form, barn: dataBarn });
    save(form);
  };

  const toSaveFile = () => {
    let newFilesForm = [];
    let dataFileJson = JSON.parse(JSON.stringify(dataFile));
    if (dataFileJson.length > 0) {
      dataFileJson.map((fileElement) => {
        newFilesForm.push({
          id: fileElement.id ? fileElement.id : "",
          name: fileElement.name,
          description: fileElement.description,
          data: fileElement.data,
        });
      });
    }
    setForm({ ...form, data_file: newFilesForm, del_file: delFile });
    toSaveStep1();
  };

  const getSampleType = async () => {
    try {
      const response = await API.getSampleType();
      const data = await response.data;
      if (response.status == 200) {
        let sampleArray = [];
        data.content.forEach((element) => {
          sampleArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setSampleKeepTypeIdList(sampleArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSampleSend = async () => {
    try {
      const response = await API.getSampleSend("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let sampleSendArray = [];
        data.content.forEach((element) => {
          sampleSendArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setSendSampleList(sampleSendArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLab = async () => {
    try {
      const response = await API.getLab("","1");
      const data = await response.data;
      if (response.status == 200) {
        let labArray = [];
        data.content.forEach((element) => {
          labArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setLabIdList(labArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSamplePurpose = async () => {
    try {
      const response = await API.getSamplePurpose(1, "");
      const data = await response.data;
      if (response.status == 200) {
        let samplePurposeArray = [];
        data.content.forEach((element) => {
          samplePurposeArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setPurposeList(samplePurposeArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMasterJson = async () => {
    const params = [
      "animalType",
      "husbandry",
      "animalPurpose",
      "vaccineType",
      "keepType",
    ];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          animalId: data.animalType,
          husbandry: data.husbandry,
          animalPurpose: data.animalPurpose,
          vaccineType: data.vaccineType,
          keepName: data.keepType,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataById = async () => {
    const sampleBarn = JSON.parse(
      JSON.stringify(
        dataAll?.sampleBarn ? dataAll?.sampleBarn : { message: "err" }
      )
    );
    let barn_Step1 = [];
    if (sampleBarn && sampleBarn.length > 0) {
      await sampleBarn.map(async (itemBarn, indexBarn) => {
        if (itemBarn.barnId) {
          itemBarn.barnId = [
            buildingList.find((itemBuilding) => {
              return itemBuilding.value === itemBarn.barnId;
            }),
          ];
        }

        if (itemBarn.animalTypeId) {
          itemBarn.animalTypeId = [
            animalListData.find((itemAnimal) => {
              return itemAnimal.value === itemBarn.animalTypeId;
            }),
          ];
        }

        if (itemBarn.sampleBarnAnimal && itemBarn.sampleBarnAnimal.length > 0) {
          itemBarn.sampleBarnAnimal.map((itemAnimal, indexAnimal) => {
            let emptyObject = { value: "", label: "-" };
            if (itemAnimal.resultsStatus) {
              itemAnimal.resultsStatus = [
                resultsList.find((itemResult) => {
                  return Number(itemResult.value) === itemAnimal.resultsStatus;
                }),
              ];
            } else {
              itemAnimal.resultsStatus = [emptyObject];
            }

            if (itemAnimal.immuneA) {
              itemAnimal.immuneA = [
                immuneAList.find((itemImmune) => {
                  return itemImmune.label === itemAnimal.immuneA;
                }),
              ];
            } else {
              itemAnimal.immuneA = [emptyObject];
            }

            if (itemAnimal.immuneA1) {
              itemAnimal.immuneA1 = [
                immuneA1List.find((itemImmune) => {
                  return itemAnimal.immuneA1
                    ? emptyObject
                    : itemImmune.label === itemAnimal.immuneA1;
                }),
              ];
            } else {
              itemAnimal.immuneA1 = [emptyObject];
            }

            if (itemAnimal.immuneO) {
              itemAnimal.immuneO = [
                immuneOList.find((itemImmune) => {
                  return itemAnimal.immuneA1
                    ? emptyObject
                    : itemImmune.label === itemAnimal.immuneO;
                }),
              ];
            } else {
              itemAnimal.immuneO = [emptyObject];
            }

            if (
              itemAnimal.sampleBarnAnimalDtl &&
              itemAnimal.sampleBarnAnimalDtl.length > 0
            ) {
              itemAnimal.sampleBarnAnimalDtl.map((itemDtl, indexDtl) => {
                let check = [];
                if (itemDtl.sampleKeepTypeId) {
                  itemDtl.sampleKeepTypeId = [
                    sampleKeepTypeIdList.find((itemSampleKeepType) => {
                      return (
                        itemSampleKeepType.value === itemDtl.sampleKeepTypeId
                      );
                    }),
                  ];
                } else {
                  itemDtl.sampleKeepTypeId = [emptyObject];
                }

                itemDtl.save = false;
                itemDtl.edit = false;
              });
            }
          });
        }
        barn_Step1.push(itemBarn);
      });

      setBarn(sampleBarn);
      // console.log("sampleBarn", sampleBarn);
    }
    if (form.purposeSampleId) {
      let purposeSelect = purposeList.find(
        (item) => item.value == form.purposeSampleId
      );
      setPurposeSelectValue(purposeSelect);
    }

    if (form.sendSampleMethodId) {
      let sendSampleSelect = sendSampleList.find(
        (item) => item.value == form.sendSampleMethodId
      );
      setSendSampleSelectValue(sendSampleSelect);
    }

    if (form.diseaseTypeId) {
      let diseaseTypeSelect = diseasesListData.find(
        (item) => item.value == form.diseaseTypeId
      );
      setDiseaseTypeSelectValue(diseaseTypeSelect);
    }

    if (form.labId) {
      let labIdSelect = labIdList.find((item) => item.value == form.labId);
      setLabIdSelectValue(labIdSelect);
    }
  };

  const getBarnByLims = async (barnId, limsCaseId, index) => {
    const res_test = {
      barnId: 1730,
      limsCaseId: "R64A00282",
      testMethod: null,
      sampleBarnAnimal: [
        {
          id: null,
          animalCode: "หมายเลขสัตว์",
          sampleBarnId: null,
          sampleId: null,
          name: "ตัวที่ 1",
          resultsStatus: null,
          immuneO: null,
          immuneA: null,
          immuneA1: null,
          sampleBarnAnimalDtl: [],
        },
        {
          id: null,
          animalCode: "หมายเลขสัตว์2",
          sampleBarnId: null,
          sampleId: null,
          name: "ตัวที่ 2",
          resultsStatus: null,
          immuneO: null,
          immuneA: null,
          immuneA1: null,
          sampleBarnAnimalDtl: [
            {
              id: null,
              sampleBarnAnimalId: null,
              keepDate: null,
              sampleAmt: 1,
              sampleKeepTypeId: 1,
              sampleNo: null,
            },
            {
              id: null,
              sampleBarnAnimalId: null,
              keepDate: null,
              sampleAmt: 1,
              sampleKeepTypeId: 2,
              sampleNo: null,
            },
          ],
        },
      ],
      result: [],
    };

    try {
      const response = await API.getBarnByLims(barnId, limsCaseId);
      const data = await response.data;
      if (response.status == 200) {
        getDataByLims(data, index);
      }
    } catch (error) {
      MySwal.fire({
        text: "ไม่พบเลขเคสรับตัวอย่าง LIMS ",
        icon: "warning",
        showCloseButton: true,
        confirmButtonText: "ตกลง",
      });
      console.log(error);
    }

    getDataByLims(res_test, index); // ถ้า api 200 แล้วลบทิ้ง เอาไว้เทสเฉยๆ

    toSaveBarn();
  };

  const getDataByLims = (data, index) => {
    // console.log("getDataByLims", data);
    if (checkBarnLims(data.barnId, form.barn)) {
      // console.log("success getDataByLims", form.barn[index]);
      // let nameHouse;
      // buildingList.filter((itemH) => {
      //     if (itemH.value == data.barnId) {
      //         nameHouse = itemH.label;
      //     }
      // });
      // form.barn[index].houseSelectValue = [{ value: data.barnId, label: nameHouse }]; // ใช้เช็คจาก index ที่เรากดแทน คล้ายๆ แบบ edit
      // form.barn[index].barnAnimal = data.sampleBarnAnimal.map((itemAnimal, indexAnimal) => {
      //     let resultsTypeName;
      //     resultsList.filter((itemR) => {
      //         if (itemR.value == itemAnimal.resultsStatus) {
      //             resultsTypeName = itemR.label;
      //         }
      //     });
      //     let immuneOTypeId;
      //     immuneOList.filter((itemO) => {
      //         if (itemO.label == itemAnimal.immuneO) {
      //             immuneOTypeId = itemO.value;
      //         }
      //     });
      //     let immuneATypeId;
      //     immuneAList.filter((itemA) => {
      //         if (itemA.label == itemAnimal.immuneA) {
      //             immuneATypeId = itemA.value;
      //         }
      //     });
      //     let immuneA1TypeId;
      //     immuneA1List.filter((itemA1) => {
      //         if (itemA1.label == itemAnimal.immuneA1) {
      //             immuneA1TypeId = itemA1.value;
      //         }
      //     });
      //     return {
      //         id: itemAnimal.id || "",
      //         animalCode: itemAnimal.animalCode || "",
      //         name: itemAnimal.name || "",
      //         resultsTypeId: itemAnimal.resultsStatus || "",
      //         resultsTypeName: resultsTypeName || "",
      //         resultsSelectValue: [{ value: itemAnimal.resultsStatus, label: resultsTypeName }],
      //         dataResultsList: [],
      //         immuneOTypeId: immuneOTypeId || "", //immuneOTypeId เช็คว่ามีมั้ย ไม่มีก็ใส่ ""
      //         immuneOTypeName: itemAnimal.immuneO || "", //itemAnimal.immuneO เช็คว่ามีมั้ย ไม่มีก็ใส่ ""
      //         immuneOSelectValue: [{ value: immuneOTypeId, label: itemAnimal.immuneO }], // [{ value: immuneOTypeId, label: itemAnimal.immuneO }] เช็คว่ามีมั้ย ไม่มีก็ใส่ []
      //         dataimmuneOList: [],
      //         immuneATypeId: immuneATypeId || "",
      //         immuneATypeName: itemAnimal.immuneA || "",
      //         immuneASelectValue: [{ value: immuneATypeId, label: itemAnimal.immuneA }],
      //         dataimmuneAList: [],
      //         immuneA1TypeId: immuneA1TypeId || "",
      //         immuneA1TypeName: itemAnimal.immuneA1 || "",
      //         immuneA1SelectValue: [{ value: immuneA1TypeId, label: itemAnimal.immuneA1 }],
      //         immuneA1List: [],
      //         dataBarnAnimalDtl: itemAnimal.sampleBarnAnimalDtl.map((itemDtl, indexDtl) => {
      //             return {
      //                 id: itemDtl.id || "",
      //                 sampleNo: itemDtl.sampleNo || "",
      //                 sampleKeepTypeId: itemDtl.sampleKeepTypeId || "",
      //                 sampleKeepTypeName: formJson.keepName[itemDtl.sampleKeepTypeId],
      //                 sampleAmt: itemDtl.sampleAmt || "",
      //                 keepDate: itemDtl.keepDate || "",
      //             }
      //         }),
      //     }
      // })
      // setForm({
      //     ...form,
      //     // id: "",
      //     idHouse: data.barnId || "",
      //     nameHouse: nameHouse || "",
      //     animalTypeId: "",
      //     animalTypeName: "",
      //     number: "",
      //     caseLims: data.limsCaseId || "",
      //     houseSelectValue: [{ value: data.barnId, label: nameHouse }],
      //     houseList: [],
      //     animalTypeSelectValue: [],
      //     animalTypeList: [],
      //     result: data.result,
      //     // testMethod
      // })
      // ยังไม่เสร็จ
      // อันนี้เอา ฟิลด์ของ form มาหมดเลย ลองไปเช็คข้อมูลจาก api อีกทีว่ามีตัวแปรอะไรบ้าง แต่ตัวแปร(ซ้ายมือ)ต้องมี ตัวแปร(ขวา)ตัวไหนไม่มีจาก api ก็ไม่ต้องใส่ ให้ใส่ว่าง "" หรือ [] ไปแทน
    }
  };

  const checkBarnLims = (barnId, listBarn) => {
    let checkBarn = false;
    if (listBarn && listBarn.length > 0) {
      listBarn.map((item, i) => {
        if (item.idHouse === barnId) {
          checkBarn = true;
        }
      });
    }
    return checkBarn;
  };

  //แก้ไขสัตว์

  const setAnimalEdit = (item, index) => {
    setShowAddSetAnimal(true);
    setForm({
      ...form,
      modeSetAnimal: "edit",
      dataAnimal: item,
      dataIndex: index,
    });
  };

  const delSetAnimal = (index) => {
    form.data_setAnimal.splice(index, 1);
    setForm({ ...form, data_setAnimal: form.data_setAnimal });
  };

  const addSetAnimal = (data) => {
    let arraySetAnimal = [...form.data_setAnimal];
    arraySetAnimal.push(data);
    setForm({ ...form, data_setAnimal: arraySetAnimal });
  };

  const saveEditAnimal = (data, index) => {
    if (form.data_setAnimal && form.data_setAnimal.length > 0) {
      form.data_setAnimal.map((animal, a) => {
        if (a === index) {
          Object.assign(animal, data);
        }
      });
      setForm({ ...form });
    }
  };

  const renderTableFile = (itemFile, indexFile) => {
    return (
      <React.Fragment key={"item-sample-" + indexFile + itemFile.name}>
        <tr key={"table-sample-" + indexFile + itemFile.name}>
          <td>{indexFile + 1}</td>
          <td>
            {itemFile.save ? (
              <UploadImage
                title=" "
                classLabel="normal mt-2"
                star={false}
                type="fileName"
                typeSave="base64"
                onChange={(name, file) => {
                  itemFile.data = file;
                  itemFile.name = name;
                  setDataFile([...dataFile]);
                  toSaveFile();
                }}
                id="imgfile"
                position="left"
                files={
                  itemFile.name == null || itemFile.name == ""
                    ? ""
                    : itemFile.name
                }
              />
            ) : (
              <span
                className={itemFile.id ? "link" : ""}
                onClick={() => {
                  itemFile.id
                    ? window.open(itemFile.url, "_blank").focus()
                    : console.log("");
                }}
              >
                {itemFile.name}
              </span>
            )}
          </td>

          <td>
            {itemFile.save ? (
              <InputText
                classFormGroup="mb-0 "
                type="text"
                idName="count"
                value={itemFile.description ? itemFile.description : ""}
                star={false}
                placeholder="โปรดระบุ"
                handleChange={(value) => {
                  itemFile.description = value;
                  setDataFile([...dataFile]);
                  toSaveFile();
                }}
              />
            ) : itemFile.description ? (
              itemFile.description
            ) : (
              "-"
            )}
          </td>

          <td>{itemFile.save ? "" : helper.getDateFormat(itemFile.date)}</td>
          <td className="text-center">
            {itemFile.save ? (
              <button
                className="btn btn-success  pt-1 px-2"
                onClick={async () => {
                  itemFile.save = false;
                  itemFile.date = new Date().getTime();
                  setDataFile([...dataFile]);
                  toSaveFile();
                }}
              >
                <img
                  className="img-fluid icon-check"
                  src="/svg/btn-check.svg"
                  alt="check"
                />
              </button>
            ) : (
              <button
                className="btn btn-del "
                onClick={() => {
                  let delFileArray = [...delFile];
                  delFileArray.push(itemFile.id);
                  dataFile.splice(indexFile, 1);

                  setDataFile([...dataFile]);
                  setDelFile(delFileArray);
                }}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            )}
          </td>
        </tr>
      </React.Fragment>
    );
  };

  const renderTableSetAnimal = (data, index) => {
    return (
      <tr className="text-center" key={"animal-data" + index + data.animalName}>
        <td>{data.limsCaseId || "-"}</td>
        <td>{formJson.keepName[data.sampleKeepTypeId]}</td>
        <td>{formJson.animalId[data.animalTypeId]}</td>
        <td>{data.nid}</td>
        <td>{data.animalName}</td>
        <td>
          {data.ageYear && `${data.ageYear} ปี`}{" "}
          {data.ageMonth && `${data.ageMonth} เดือน`}
        </td>
        <td>
          {data.sex === "m"
            ? "ตัวผู้"
            : data.sex === "f"
            ? "ตัวเมีย"
            : "ไม่ทราบ"}
        </td>
        <td>{data.species}</td>
        <td className="d-flex justify-content-center ">
          <button
            className="btn btn-add mr-2 "
            onClick={() => setAnimalEdit(data, index)}
          >
            <img
              className="img-fluid icon-btn"
              src="/svg/btn-edit.svg"
              alt="edit"
            />
          </button>
          <button
            className="btn btn-del mr-2 "
            onClick={() => delSetAnimal(index)}
          >
            <img
              className="img-fluid icon-btn "
              src="/svg/btn-del.svg"
              alt="del"
            />
          </button>
        </td>
      </tr>
    );
  };

  const addDataFile = () => {
    const data = dataFile.map((item) => {
      return { ...item };
    });
    setDataFile([...dataFile, initialFormFile]);
    toSaveFile();
  };

  const addBarn = async () => {
    const data = barn.map((item) => {
      return { ...item, edit: true };
    });
    setBarn([...data, initBarnForm]);
    toSaveBarn();
  };

  const addAnimal = (barnIndex) => {
    const dataBarn = barn[barnIndex];
    dataBarn.sampleBarnAnimal.push(initAnimalForm);
    dataBarn.animalAmt = dataBarn.sampleBarnAnimal.length;
    barn[barnIndex] = { ...dataBarn, edit: true };
    setBarn([...barn]);
    toSaveBarn();
  };

  const addItem = (barnIndex, animalIndex) => {
    const dataBarn = barn[barnIndex];
    const dataAnimal = dataBarn.sampleBarnAnimal[animalIndex];
    dataAnimal.sampleBarnAnimalDtl.push(initSampleForm);
    barn[barnIndex].sampleBarnAnimal[animalIndex] = {
      ...dataAnimal,
      edit: true,
    };
    setBarn([...barn]);
    toSaveBarn();
  };

  const deleteAnimal = (barnIndex, animalIndex) => {
    const dataBarn = barn[barnIndex];
    dataBarn.sampleBarnAnimal.splice(animalIndex, 1);
    dataBarn.animalAmt = dataBarn.sampleBarnAnimal.length;
    setBarn([...barn]);
    toSaveBarn();
  };

  const deleteItem = (barnIndex, animalIndex, sampleIndex) => {
    const dataBarn = barn[barnIndex];
    const dataAnimal = dataBarn.sampleBarnAnimal[animalIndex];
    dataAnimal.sampleBarnAnimalDtl.splice(sampleIndex, 1);
    setBarn([...barn]);
    toSaveBarn();
  };

  const buttonAddBarn = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button className="btn btn-add text-white" onClick={() => addBarn()}>
          <i className="fas fa-plus mr-1 "></i>เพิ่มโรงเรือน
        </button>
      </div>
    );
  };

  const buttonAddAnimal = (barnIndex) => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => addAnimal(barnIndex)}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มสัตว์
        </button>
      </div>
    );
  };

  const buttonAddItem = (barnIndex, animalIndex) => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => addItem(barnIndex, animalIndex)}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const renderItem = (indexBarn, indexAnimal, itemSample, indexSample) => {
    return (
      <tr
        key={`item-${indexBarn}${indexAnimal}${indexSample}`}
        className="text-center"
      >
        <td>{indexSample + 1}</td>
        <td>
          {itemSample.save ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="text"
              idName={`sampleNo_${indexSample}`}
              value={itemSample.sampleNo ? itemSample.sampleNo : ""}
              star={false}
              placeholder="โปรดระบุ"
              handleChange={(value) => {
                itemSample.sampleNo = value;
                setVaccination([...vaccination]);
                toSaveBarn();
              }}
            />
          ) : itemSample.sampleNo ? (
            itemSample.sampleNo
          ) : (
            "-"
          )}
        </td>
        <td>
          {itemSample.save ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              title=""
              star={false}
              placeholder="กรุณาเลือก"
              idName={`sampleType_${indexSample}`}
              classLabel="normal"
              selectValue={itemSample.sampleKeepTypeId}
              optionsList={sampleKeepTypeIdList}
              handleChange={(value) => {
                itemSample.sampleKeepTypeId = [value];
                setVaccination([...vaccination]);
                toSaveBarn();
              }}
            />
          ) : itemSample.sampleKeepTypeId.length > 0 ? (
            itemSample.sampleKeepTypeId.find((p, j) => j == 0)?.label
          ) : (
            sampleKeepTypeIdList.find(
              (q, k) => q.value == itemSample.sampleKeepTypeId
            )?.label
          )}
        </td>
        <td>
          {itemSample.save ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="number"
              idName={`sampleAmout_${indexSample}`}
              value={itemSample.sampleAmt ? itemSample.sampleAmt : ""}
              star={false}
              placeholder="โปรดระบุ"
              min="0"
              handleChange={(value) => {
                itemSample.sampleAmt = value;
                setVaccination([...vaccination]);
                toSaveBarn();
              }}
            />
          ) : itemSample.sampleAmt ? (
            itemSample.sampleAmt
          ) : (
            "-"
          )}
        </td>
        <td>
          {itemSample.save ? (
            <InputDatePicker
              classFormGroup="mb-0"
              title=""
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={itemSample.keepDate}
              star={false}
              handleChange={(value) => {
                //to unix format
                let unixTime = new Date(value).getTime();
                //to label format
                itemSample.keepDate = unixTime;
                setVaccination([...vaccination]);
                toSaveBarn();
              }}
            />
          ) : (
            helper.getDate(itemSample.keepDate) || "-"
          )}
        </td>
        <td className="text-center">
          {itemSample.save ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={() => {
                itemSample.save = false;
                const data = vaccination.map((item) => {
                  return { ...item, edit: false };
                });
                setVaccination([...data]);
                toSaveBarn();
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => {
                  itemSample.save = true;
                  setVaccination([...vaccination]);
                  toSaveBarn();
                }}
                disabled={itemSample.edit}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button
                className="btn btn-del "
                onClick={() => deleteItem(indexBarn, indexAnimal, indexSample)}
                disabled={itemSample.edit}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  const renderBarn = (itemBarn, indexBarn) => {
    // console.log("indexBarn", itemBarn);
    return (
      <div key={"barn" + indexBarn + itemBarn.barnId}>
        <Col key={indexBarn} className="pl-0 pr-0">
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="d-flex justify-content-end "
          >
            <div className="pt-sm-1 pt-md-3 pt-lg-3 ">
              <button
                className="btn btn-del "
                onClick={() => {
                  barn.splice(indexBarn, 1);
                  toSaveBarn();
                }}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </div>
          </Col>
          <Col lg={12} md={12} sm={12} className="border mt-3">
            <Row>
              <Col
                lg={2}
                md={2}
                sm={12}
                xs={12}
                className="d-flex align-items-center"
              >
                <div className="pt-sm-1 pt-md-3 pt-lg-3  w-100">
                  <InputSelect
                    title="โรงเรือน"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={itemBarn.barnId[0]}
                    optionsList={buildingList}
                    handleChange={(value) => {
                      itemBarn.barnId = [value];
                      toSaveBarn();
                    }}
                  />
                </div>
              </Col>

              <Col
                lg={2}
                md={2}
                sm={12}
                xs={12}
                className="d-flex align-items-center"
              >
                <div className="pt-sm-1 pt-md-3 pt-lg-3  w-100">
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={itemBarn.animalTypeId[0]}
                    optionsList={animalListData}
                    handleChange={(value) => {
                      itemBarn.animalTypeId = [value];
                      toSaveBarn();
                    }}
                  />
                </div>
              </Col>

              <Col
                lg={2}
                md={2}
                sm={12}
                xs={12}
                className="d-flex align-items-center"
              >
                <div className="pt-sm-1 pt-md-3 pt-lg-3 w-100">
                  <InputText
                    type="number"
                    title="จำนวนสัตว์ (ตัว)"
                    idName="number"
                    star={false}
                    min="0"
                    value={
                      itemBarn.sampleBarnAnimal
                        ? itemBarn.sampleBarnAnimal.length
                        : 0
                    }
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled={true}
                    handleChange={(value) => {
                      //
                    }}
                  />
                </div>
              </Col>

              <Col
                lg={2}
                md={2}
                sm={12}
                xs={12}
                className="d-flex align-items-center"
              >
                <div className="pt-sm-1 pt-md-3 pt-lg-3 w-100">
                  <InputText
                    type="text"
                    title="เลขเคสรับตัวอย่าง LIMS"
                    idName="caseLims"
                    star={false}
                    value={itemBarn.limsCaseId ? itemBarn.limsCaseId : ""}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => {
                      itemBarn.limsCaseId = value;
                      setBarn([...barn]);
                      toSaveBarn();
                    }}
                  />
                </div>
              </Col>

              <Col
                lg={2}
                md={2}
                sm={12}
                xs={12}
                className="d-flex align-items-center mt-2"
              >
                <div className="pt-sm-1 pt-md-3 pt-lg-3 w-100  ">
                  {/* getBarnByLims parameter ตัวสุดท้ายเป็น demo ไม่ต้องส่งก็ได้ หรือจะส่งเป็ฯ 1, 0 เพื่อดึงข้อมูล mock-up */}
                  <button
                    className="btn btn-darkblue w-100 "
                    onClick={() =>
                      getBarnByLims(indexBarn, itemBarn.limsCaseId, 1)
                    }
                  >
                    <img
                      className="mr-2 mb-1 "
                      src={"/svg/put.svg"}
                      alt="menu-icon"
                    />
                    ดึงข้อมูล
                  </button>
                </div>
              </Col>

              <Col
                lg={2}
                md={2}
                sm={12}
                xs={12}
                className="d-flex align-items-center pr-0"
              >
                <p className="pt-sm-1 pt-md-3 pt-lg-3 text-gray mb-2 font14 ">
                  *กรณีส่งตัวอย่างผ่านระบบ LIMS ให้ระบุเลขเคสรับตัวอย่าง
                  จากเอกสาร รายงานผลการวิเคราะห์ ทดสอบ และชันสูตรโรคสัตว์
                </p>
              </Col>
            </Row>
            <Row>
              <Col>{buttonAddAnimal(indexBarn)}</Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="py-2">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {itemBarn.sampleBarnAnimal &&
                      itemBarn.sampleBarnAnimal.length > 0 &&
                      itemBarn.sampleBarnAnimal.map(
                        (itemAnimal, indexAnimal) => {
                          return (
                            <li
                              className="nav-item tab "
                              key={"barn" + indexBarn + "-animal" + indexAnimal}
                            >
                              <a
                                className={
                                  indexAnimal + 1 == 1
                                    ? "nav-link active"
                                    : "nav-link "
                                }
                                id={`barn${indexBarn + 1}animal${
                                  indexAnimal + 1
                                }-tab`}
                                data-toggle="tab"
                                href={`#barn${indexBarn + 1}animal${
                                  indexAnimal + 1
                                }`}
                                role="tab"
                                aria-controls={`barn${indexBarn + 1}animal${
                                  indexAnimal + 1
                                }`}
                                aria-selected={
                                  indexAnimal + 1 == 1 ? "true" : "false"
                                }
                              >
                                {itemAnimal.name
                                  ? itemAnimal.name
                                  : `ตัวที่ ${indexAnimal + 1}`}{" "}
                                <img
                                  className="mb-1 ml-2"
                                  src={"/svg/btn-x.svg"}
                                  alt="menu-icon"
                                  onClick={() =>
                                    deleteAnimal(indexBarn, indexAnimal)
                                  }
                                />
                              </a>
                            </li>
                          );
                        }
                      )}
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    {itemBarn.sampleBarnAnimal &&
                      itemBarn.sampleBarnAnimal.length > 0 &&
                      itemBarn.sampleBarnAnimal.map(
                        (itemAnimal, indexAnimal) => {
                          const dataBarn = barn[indexBarn];
                          const dataAnimal =
                            dataBarn.sampleBarnAnimal[indexAnimal];

                          return (
                            <div
                              key={"barn" + indexBarn + "-animal" + indexAnimal}
                              className={
                                indexAnimal + 1 == 1
                                  ? "tab-pane fade show active card-tab"
                                  : "tab-pane fade show  card-tab"
                              }
                              id={`barn${indexBarn + 1}animal${
                                indexAnimal + 1
                              }`}
                              role="tabpanel"
                              aria-labelledby={`animal${indexAnimal + 1}-tab`}
                            >
                              <Row>
                                <Col lg={2} md={2} sm={12}>
                                  <InputText
                                    type="text"
                                    title="หมายเลขประจำตัวสัตว์"
                                    idName={"animalCode"}
                                    star={false}
                                    value={
                                      itemAnimal.animalCode
                                        ? itemAnimal.animalCode
                                        : ""
                                    }
                                    placeholder="โปรดระบุ"
                                    classLabel="normal"
                                    handleChange={(value) => {
                                      dataAnimal.animalCode = value;
                                      barn[indexBarn].sampleBarnAnimal[
                                        indexAnimal
                                      ] = { ...dataAnimal };
                                      setBarn([...barn]);
                                      toSaveBarn();
                                    }}
                                  />
                                </Col>

                                <Col lg={2} md={2} sm={12} xs={12}>
                                  <InputSelect
                                    classFormGroup="mb-0"
                                    title="ผลตรวจรายตัว"
                                    star={false}
                                    placeholder="กรุณาเลือก"
                                    idName="title"
                                    classLabel="normal"
                                    selectValue={itemAnimal.resultsStatus[0]}
                                    optionsList={resultsList}
                                    handleChange={(value) => {
                                      itemAnimal.resultsStatus = [value];
                                      toSaveBarn();
                                    }}
                                  />
                                </Col>

                                <Col
                                  lg={2}
                                  md={3}
                                  sm={6}
                                  className="d-flex justify-content-end align-items-center "
                                >
                                  <div>
                                    <h6 className="">ภูมิคุ้มกัน</h6>
                                  </div>
                                </Col>

                                <Col className="bg-gray3">
                                  <Row>
                                    <Col
                                      lg={4}
                                      md={4}
                                      sm={12}
                                      xs={12}
                                      className="d-flex justify-content-between"
                                    >
                                      <div className="d-flex align-items-center pt-sm-1 pt-md-3 pt-lg-3 w-100">
                                        <h6 className="mb-0 mr-2">โอ(O)</h6>
                                        <InputSelect
                                          classFormGroup="w-100 mb-0"
                                          title=""
                                          star={false}
                                          placeholder="กรุณาเลือก"
                                          idName="title"
                                          classLabel="normal"
                                          selectValue={itemAnimal.immuneO[0]}
                                          optionsList={immuneOList}
                                          handleChange={(value) => {
                                            itemAnimal.immuneO = [value];
                                            toSaveBarn();
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col
                                      lg={4}
                                      md={4}
                                      sm={12}
                                      xs={12}
                                      className="d-flex justify-content-between"
                                    >
                                      <div className="d-flex align-items-center pt-sm-1 pt-md-3 pt-lg-3 w-100">
                                        <h6 className="mb-0 mr-2">เอ(A)</h6>
                                        <InputSelect
                                          classFormGroup="w-100 mb-0"
                                          title=""
                                          star={false}
                                          placeholder="กรุณาเลือก"
                                          idName="title"
                                          classLabel="normal"
                                          selectValue={itemAnimal.immuneA[0]}
                                          optionsList={immuneAList}
                                          handleChange={(value) => {
                                            itemAnimal.immuneA = [value];
                                            toSaveBarn();
                                          }}
                                        />
                                      </div>
                                    </Col>

                                    <Col
                                      lg={4}
                                      md={4}
                                      sm={12}
                                      xs={12}
                                      className="d-flex justify-content-between"
                                    >
                                      <div className="d-flex align-items-center pt-sm-1 pt-md-3 pt-lg-3 w-100">
                                        <h6 className="mb-0 mr-2">
                                          เอเชียวัน(A1)
                                        </h6>
                                        <InputSelect
                                          classFormGroup="w-100 mb-0"
                                          title=""
                                          star={false}
                                          placeholder="กรุณาเลือก"
                                          idName="title"
                                          classLabel="normal"
                                          selectValue={itemAnimal.immuneA1[0]}
                                          optionsList={immuneA1List}
                                          handleChange={(value) => {
                                            itemAnimal.immuneA1 = [value];
                                            toSaveBarn();
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col>
                                  {buttonAddItem(indexBarn, indexAnimal)}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3 mt-3">
                                    <table className="table table-striped sameWidth table-bordered my-2 ">
                                      <thead>
                                        <tr className="text-center">
                                          <th scope="col">ลำดับ</th>
                                          <th scope="col">หมายเลขตัวอย่าง</th>
                                          <th scope="col">
                                            ชนิดตัวอย่างที่เก็บ
                                          </th>
                                          <th scope="col">จำนวนตัวอย่าง</th>
                                          <th scope="col">
                                            วันที่เก็บตัวอย่าง
                                          </th>
                                          <th scope="col">จัดการ</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {itemAnimal.sampleBarnAnimalDtl &&
                                        itemAnimal.sampleBarnAnimalDtl.length >
                                          0 ? (
                                          itemAnimal.sampleBarnAnimalDtl.map(
                                            (itemSample, indexSample) => {
                                              if (
                                                !itemSample.sampleBarnAnimalId
                                              ) {
                                                itemSample.sampleBarnAnimalId =
                                                  itemAnimal.id;
                                              }
                                              return renderItem(
                                                indexBarn,
                                                indexAnimal,
                                                itemSample,
                                                indexSample
                                              );
                                            }
                                          )
                                        ) : (
                                          <DataTableNodata colSpan={6} />
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Col>
      </div>
    );
  };

  return (
    <div className=" bg-white p-4 ">
      <BoxCard title="1. การเก็บตัวอย่าง" classNameBox="">
        <Row>
          <Col lg={3} md={3} sm={12} xs={12}>
            <InputDatePicker
              title="วันที่เก็บตัวอย่าง"
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={form.keepSampleDate}
              star={false}
              handleChange={(value) => {
                setForm({ ...form, keepSampleDate: value });
              }}
            />
          </Col>
          <Col lg={3} md={3} sm={12} xs={12}>
            <InputDatePicker
              title="วันที่ส่งตัวอย่าง"
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={form.sendSampleDate}
              star={false}
              handleChange={(value) => {
                setForm({ ...form, sendSampleDate: value });
              }}
            />
          </Col>
          <Col lg={3} md={3} sm={12} xs={12}>
            <InputSelect
              title="วิธีการส่งตัวอย่าง"
              star={true}
              placeholder="กรุณาเลือก"
              idName="title"
              classLabel="normal"
              selectValue={sendSampleSelectValue}
              optionsList={sendSampleList}
              handleChange={(value) => {
                setSendSampleSelectValue([value]);
                setForm({
                  ...form,
                  sendSampleMethodId: value.value,
                  sendSampleMethodName: value.label,
                });
              }}
              handleInvalid={() => removeInvalid("sendSampleMethodId")}
              invalid={invalid.sendSampleMethodId}
            />
          </Col>

          <Col lg={3} md={3} sm={12} xs={12}>
            <InputText
              type="text"
              title="หมายเหตุ"
              idName="remark"
              value={form.remark ? form.remark : ""}
              star={false}
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) => {
                setForm({ ...form, remark: value, checkShow: true });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={3} sm={12} xs={12}>
            <InputSelect
              title="สถานที่ส่งตัวอย่าง"
              star={true}
              placeholder="กรุณาเลือก"
              idName="title"
              classLabel="normal"
              selectValue={labIdSelectValue}
              optionsList={labIdList}
              handleChange={(value) => {
                setLabIdSelectValue([value]);
                setForm({ ...form, labId: value.value, labName: value.label });
              }}
              handleInvalid={() => removeInvalid("labId")}
              invalid={invalid.labId}
            />
          </Col>

          <Col lg={3} md={3} sm={12} xs={12}>
            <InputSelect
              title="วัตถุประสงค์ในการส่งตัวอย่าง"
              star={true}
              placeholder="กรุณาเลือก"
              idName="title"
              classLabel="normal"
              selectValue={purposeSelectValue}
              optionsList={purposeList}
              handleChange={(value) => {
                setPurposeSelectValue([value]);
                setForm({
                  ...form,
                  purposeSampleId: value.value,
                  purposeSampleName: value.label,
                });
              }}
              handleInvalid={() => removeInvalid("purposeSampleId")}
              invalid={invalid.purposeSampleId}
            />
          </Col>

          <Col lg={3} md={3} sm={12} xs={12}>
            <InputSelect
              title="ชนิดโรคที่สงสัย"
              star={true}
              placeholder="กรุณาเลือก"
              idName="title"
              classLabel="normal"
              selectValue={diseaseTypeSelectValue}
              optionsList={diseasesListData}
              handleChange={(value) => {
                setDiseaseTypeSelectValue([value]);
                setForm({
                  ...form,
                  diseaseTypeId: value.value,
                  diseaseTypeName: value.label,
                });
              }}
              handleInvalid={() => removeInvalid("diseaseTypeId")}
              invalid={invalid.diseaseTypeId}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-between mt-4 ">
          <div className=" d-flex align-items-end">
            <p className="bold font14 mb-1">1.1 ข้อมูลรายฟาร์ม / โรงเรือน</p>
          </div>
          <div>{buttonAddBarn()}</div>
        </div>
        <hr />
        {barn?.length > 0 ? (
          barn.map((item, i) => {
            // console.log("barn", barn);
            return renderBarn(item, i);
          })
        ) : (
          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              className="border text-center font14 mt-3 py-3"
            >
              ยังไม่มีการเพิ่มข้อมูลโรงเรือน กรุณาเพิ่มข้อมูล
            </Col>
          </Row>
        )}
        <p className="bold font14 mt-4">1.2 ข้อมูลรายตัว</p>
        <hr />
        <div className="d-flex justify-content-end ">
          <div>
            <button
              className="btn btn-add text-white"
              onClick={() => {
                setShowAddSetAnimal(true);
                setForm({ ...form, modeSetAnimal: "add" });
                toSaveStep1();
              }}
            >
              <i className="fas fa-plus mr-1 "></i>เพิ่มตัวอย่าง
            </button>
          </div>
        </div>
        <div className="table-responsive pb-3">
          <table className="table table-striped table-bordered my-2 ">
            <thead>
              <tr className="text-center">
                <th scope="col">เลขเคสรับตัวอย่าง LIMS</th>
                <th scope="col">ชนิดตัวอย่างที่เก็บ</th>
                <th scope="col">ชนิดสัตว์</th>
                <th scope="col">รหัสสัตว์</th>
                <th scope="col">ชื่อ</th>
                <th scope="col">อายุ</th>
                <th scope="col">เพศ</th>
                <th scope="col">พันธุ์</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              {form.data_setAnimal && form.data_setAnimal.length > 0 ? (
                form.data_setAnimal.map((item, i) => {
                  return renderTableSetAnimal(item, i);
                })
              ) : (
                <DataTableNodata colSpan={9} />
              )}
            </tbody>
          </table>
        </div>
        <p className="bold font14 mt-4">1.3 แนบไฟล์การบันทึกข้อมูล</p>
        <hr />
        <div className="d-flex justify-content-end ">
          <div>
            <button
              className="btn btn-add text-white"
              onClick={() => addDataFile()}
            >
              <i className="fas fa-plus mr-1 "></i>เพิ่มไฟล์
            </button>
          </div>
        </div>
        <div className="table-responsive pb-3">
          <table className="table table-striped table-bordered my-2 ">
            <thead>
              <tr className="text-center">
                <th scope="col">ลำดับ</th>
                <th scope="col">อัปโหลดไฟล์</th>
                <th scope="col">คำอธิบาย</th>
                <th scope="col">วันที่อัปโหลด</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              {dataFile && dataFile.length > 0 ? (
                dataFile.map((item, i) => {
                  return renderTableFile(item, i);
                })
              ) : (
                <DataTableNodata colSpan={5} />
              )}
            </tbody>
          </table>
        </div>
      </BoxCard>
      {showAddSetAnimal && (
        <ModalEditAnimal
          dataHouseList={buildingList}
          show={showAddSetAnimal}
          mode={form.modeSetAnimal}
          close={() => setShowAddSetAnimal(false)}
          dataAnimal={form.dataAnimal}
          dataIndex={form.dataIndex}
          save={(value, index) => {
            if (form.modeSetAnimal == "add") {
              addSetAnimal(value);
            } else {
              saveEditAnimal(value, index);
            }
          }}
        />
      )}
    </div>
  );
}

export default KeepSample_Step1;
