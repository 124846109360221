import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector} from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";

function ModalDocCategories({ show, close, save, mode, idEdit }) {
    const user = useSelector(state => state.login.result);
    const initialForm = {
        doc_id: "",
        name: "",
        menuId: "",
        status: "",

        createDate: "",
        updateDate: "",
        createByName: "",
        updateByName: "",

        checkShow: false
    };
    const initInvalidMsg = {
        menuId: "",
        name: "",
        status: "",
    };
    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];

    const dispatch = useDispatch();
    const history = useHistory();

    const [form, setForm] = useState(initialForm);
    const [invalid, setInvalid] = useState(initInvalidMsg);
    const [statusSelectValue, setStatusSelectValue] = useState([]);
    const [statusList, setStatusList] = useState(selectStatusList);
    const [menuSelectValue, setMenuSelectValue] = useState([])
    const [menuList, setMenuList] = useState([])

    useEffect(async () => {
        if (mode === "add") {
            await setForm(initialForm)
            setStatusSelectValue([])
            setMenuSelectValue([])
        }
    }, [mode])

    useEffect(async () => {
        if (mode === "edit" && !form.checkShow) {
            await getDocById(idEdit)
        }
    }, [mode, idEdit, show, form.name, form.status, form.menuId])

    useEffect(async () => {
        await getMenu()
    }, [])

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;
        if (form.name === "") {
            addInvalid("name", "โปรดระบุชื่อหมวดหมู่เอกสาร");
            validated = false;
        }

        if (form.status === "") {
            addInvalid("status", "กรุณาเลือกสถานะ");
            validated = false;
        }

        if (form.menuId === "") {
            addInvalid("menu", "กรุณาเลือกเมนู");
            validated = false;
        }

        return validated;
    };

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setStatusSelectValue([])
        setMenuSelectValue([])
    }

    const toConfirm = async () => {
        if (validate()) {
            await save({ doc: form, mode: mode });
            handleClose();
        }
    };

    const getMenu = async () => {
        try {
            const response = await API.getMenu("", "", 1);
            const data = await response.data;
            if (response.status === 200) {
                let menuArray = [];
                data.content.forEach(element => {
                    menuArray.push({
                        value: element.id, label: element.name,
                    });
                });
                await setMenuList(menuArray);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getDocById = async (id) => {
        let dataDoc;
        try {
            const response = await API.getDocById(id);
            const data = await response.data;
            if (response.status === 200) {
                dataDoc = data;
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }))
            }
            console.log(error)
        }

        if (dataDoc.statusId) {
            let statusSelect = await statusList.find(item => item.value === dataDoc.statusId);
            setStatusSelectValue(statusSelect);
        }

        if (dataDoc.menuId) {
            let menuSelect = await menuList.find(item => item.value === dataDoc.menuId);
            setMenuSelectValue(menuSelect);
        }

        setForm({
            ...form,
            doc_id: dataDoc.id,
            name: dataDoc.name,
            menuId: dataDoc.menuId,
            status: dataDoc.statusId,
            createDate: dataDoc.createDate,
            updateDate: dataDoc.updateDate,
            createByName: dataDoc.createByName,
            updateByName: dataDoc.updateByName,
        })
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => { handleClose() }}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่มหมวดหมู่เอกสาร</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={4} md={6} sm={12} xs={12}>
                                    <InputText
                                        type="text"
                                        title="ชื่อหมวดหมู่เอกสาร"
                                        star={false}
                                        idName="name"
                                        value={form.name}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) =>
                                            setForm({ ...form, name: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("name")}
                                        invalid={invalid.name}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="เมนู" star={false}
                                        placeholder="กรุณาเลือก"
                                        idName="title"
                                        classLabel="normal"
                                        selectValue={menuSelectValue}
                                        optionsList={menuList}
                                        handleChange={(value) => {
                                            setMenuSelectValue([value])
                                            setForm({ ...form, menuId: value.value, checkShow: true })
                                        }}
                                        handleInvalid={() => removeInvalid("menu")}
                                        invalid={invalid.menu}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false}
                                        placeholder="กรุณาเลือก"
                                        idName="title"
                                        classLabel="normal"
                                        selectValue={statusSelectValue}
                                        optionsList={statusList}
                                        handleSelectValue={(value) =>
                                            setStatusSelectValue([{ value: value.value, label: value.label },])}
                                        handleChange={(value) =>
                                            setForm({ ...form, status: value.value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("status")}
                                        invalid={invalid.status}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={3} sm={6} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mr-4">วันที่บันทึกข้อมูล</p>
                                    </div>
                                </Col>
                                <Col lg={10} md={9} sm={6} xs={12}>
                                    <div className="text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mb-0">{form.createDate ? helper.momentDate(form.createDate, "th", "short") : '-'}</p>
                                        <p>{form.createByName ? form.createByName : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={3} sm={6} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                                        <p className="mr-3" >วันที่ปรับปรุงข้อมูล</p>
                                    </div>
                                </Col>
                                <Col lg={10} md={9} sm={6} xs={12}>
                                    <div className="text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mb-0">{form.updateDate ? helper.momentDate(form.updateDate, "th", "short") : '-'}</p>
                                        <p>{form.updateByName ? form.updateByName : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                </div>
                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalDocCategories
