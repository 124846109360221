import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTableNodata from "../../../components/DataTableNodata";
import DataTable from "../../../components/DataTable";
import ModalSubDistrict from "../../../components/Data_Management/Modal.SubDistrict";
import { useMasterData } from "../../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function SubDistrict() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t, i18n } = useTranslation(["common", "footer"]);
  const statusCheck = useSelector((state) => state.statusCheck);
  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/master-data/animal-type", name: "Master Data" },
    { path: "active", name: "ตำบล" },
  ];

  const initialForm = {
    subdistrictId: "",
    subdistrictName: "",
    status: "",

    idEdit: "",
    mode: "",
    editForm: {},

    zipcode: "",

    districtId: "",
    districtName: "",

    provinceId: "",
    provinceName: "",

    search: "",
    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
        width: "50px",
      },
      ,
      {
        sortBy: "name",
        name: "รายชื่อตำบล",
        sortFlg: true,
      },

      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },

      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);
  //province
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  //district
  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  //sub-district
  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);
  //filter
  const [listSubDistrict, setListSubDistrict] = useState([]);
  //modal
  const [showDistrict, setShowDistrict] = useState(false);

  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);
  const [isClearSearch, setIsClearSearch] = useState(false);

  useMasterData("province", provinceList, setProvinceList);

  useEffect(() => {
    getProvinces();
  }, []);

  useEffect(() => {}, [form.provinceId, provinceSelectValue]);
  //filter page
  useEffect(() => {
    getSubDistrict();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getSubDistrict = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getSubDistricts(
        form.subdistrictId,
        form.districtId,
        form.status,
        form.page,
        form.limit,
        "",
        "orderIndex",
        form.sortDir
      );
      const data = await response.data;
      if (response.status == 200) {
        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListSubDistrict(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusCheck && statusCheck.length > 0) {
      statusCheck.map((item, index) => {
        if (item.id == idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-4 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };
  //Optional Filter
  //set select "province value"
  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      districtId: "",
      subdistrictId: "",
    });
    getDistrictsSelectList(value.value);
  };
  //set select "district value"
  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, districtId: value.value, subdistrictId: "" });
    getSubDistrictsByDistrictId(value.value);
  };
  //set select "district value"
  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subdistrictId: value.value });
  };

  //load "province value"
  const getProvinces = async () => {
    try {
      const response = await API.getProvince(
        "",
        1,
        form.provinceId,
        1,
        100,
        form.provinceName
      );
      const data = await response?.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //load "district value"
  const getDistrictsSelectList = async (id) => {
    try {
      const response = await API.getDistricts("", id, "1");
      const data = await response?.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //load "subdistrict value"
  const getSubDistrictsByDistrictId = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, "1");
      const data = await response?.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearSearch = () => {
    setForm(initialForm);
    setSubDistrictSelectValue([]);
    setDistrictSelectValue([]);
    setProvinceSelectValue([]);
    setStatusSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonAddSubDistrict = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          disabled={user?.userGroup.mcreate === "n"}
          onClick={() => toAddDistrict()}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูลตำบล
        </button>
      </div>
    );
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          {t("clear")}
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const toAddDistrict = () => {
    setForm({ ...form, mode: "add", idEdit: "" });
    setShowDistrict(true);
  };

  const editModalDistrict = (item) => {
    setForm({ ...form, mode: "edit", idEdit: item.id, editForm: item });
    setShowDistrict(true);
  };

  const delSubDistrict = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบ `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteSubDistricts(id);
          if (response.status === 200) {
            MySwal.fire({
              text: `ลบสำเร็จ `,
              icon: "success",
              confirmButtonText: "ตกลง",
            });
            await getSubDistrict();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const addSaveSubDistrict = (data) => {
    const dataSubDistrict = {
      amphurId: data.SubDt.district_id,
      latitude: data.SubDt.latitude,
      longitude: data.SubDt.longitude,
      statusId: data.SubDt.status,
      name: data.SubDt.subdistrict_name_en,
      nameEn: data.SubDt.subdistrict_name_en,
      zipcode: data.SubDt.zipcode,
    };

    MySwal.fire({
      text: `ต้องการบันทึก `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      console.log("data", data);
      if (result.value) {
        try {
          const response =
            data.mode === "add"
              ? await API.saveSubDistricts(dataSubDistrict)
              : await API.updateSubDistricts(
                  data.SubDt.subdistrict_id,
                  dataSubDistrict
                );
          if (response.status === 200) {
            await getSubDistrict();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTableSubDistrict = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td>{item.name}</td>
          <td className="text-center">
            {item.statusId ? getStatusColor(item.statusId) : "-"}
          </td>

          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editModalDistrict(item)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delSubDistrict(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={4} />;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="จังหวัด"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="province"
                  classLabel="normal"
                  selectValue={provinceSelectValue}
                  optionsList={provinceList}
                  handleSelectValue={(value) => selectValueProvince(value)}
                  handleChange={(value) =>
                    setForm({ ...form, provinceId: value.value })
                  }
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="อำเภอ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={districtSelectValue}
                  optionsList={districtList}
                  handleSelectValue={(value) => selectValueDistrict(value)}
                  handleChange={(value) =>
                    setForm({ ...form, districtId: value.value })
                  }
                />
              </Col>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ตำบล"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={subDistrictSelectValue}
                  optionsList={subDistrictList}
                  handleSelectValue={(value) => selectValueSubDistrict(value)}
                  handleChange={(value) =>
                    setForm({ ...form, subdistrictId: value.value })
                  }
                />
              </Col>

              <Col lg={3} md={6} sm={12} xs={12}>
                <InputSelect
                  title="สถานะ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleSelectValue={(value) =>
                    setStatusSelectValue([
                      { value: value.value, label: value.label },
                    ])
                  }
                  handleChange={(value) =>
                    setForm({ ...form, status: value.value })
                  }
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="รายการข้อมูลตำบล"
            classNameBox="mt-4"
            headRight={buttonAddSubDistrict()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTableSubDistrict(listSubDistrict)}
            </DataTable>
          </BoxCard>
          {showDistrict && (
            <ModalSubDistrict
              show={showDistrict}
              close={() => setShowDistrict(false)}
              save={(value) => addSaveSubDistrict(value)}
              mode={form.mode}
              idEdit={form.idEdit}
              editForm={form.editForm}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default SubDistrict;
