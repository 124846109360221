import React, { useState, useEffect } from 'react'
import { Pagination, Dropdown, Form } from "react-bootstrap";

function Paginations({ totalPage, page, getPage }) {
    page = (page) ? page : 1;
    totalPage = (totalPage) ? totalPage : 1;
    const [pageList, setPageList] = useState([]);

    useEffect(async () => {
        calcPagination();

    }, [page, totalPage])

    const paginationCurrent = (current, last, delta = 2) => {
        let left = (current + 1) - delta,
            right = current + delta + 1,
            range = [];

        if (right > last) {
            right = last;
            left = last - delta * 2;
            left = left < 1 ? 1 : left;
        }

        if (left <= 1) {
            left = 1;
            right = Math.min(delta * 2 + 1, last);
        }

        if (left <= 3) {
            for (let i = 1; i < left; i++) {
                range.push(i);
            }
        } else {
            range.push(1);
            range.push("n");
        }

        for (let i = left; i <= right; i++) {
            range.push(i);
        }

        if (right >= last - 2) {
            for (let i = right + 1; i <= last; i++) {
                range.push(i);
            }
        } else {
            range.push("n");
            range.push(last);
        }

        return range;
    }

    const calcPagination = () => {
        setPageList(paginationCurrent(page - 1, totalPage));
    }

    const renderPagination = () => {
        return pageList.map((item, index) => {
            if (item == 'n') {
                return <Pagination.Ellipsis disabled key={index} />
            } else if (item == Number(page)) {
                return <Pagination.Item active key={index}>{item}</Pagination.Item>
            } else {
                return <Pagination.Item onClick={e => changePage(item)} key={index}>{item}</Pagination.Item>
            }
        })
    }

    const changePage = (p) => {
        // console.log("page", p);
        if (p < 1) {
            p = 1;
        } else if (p > totalPage) {
            p = (totalPage);
        }
        getPage(p);
    }

    return (
        <div className="d-flex justify-content-end">
            <Pagination>

                {/* <Pagination.First disabled={(page == 1) ? true : false} onClick={() => changePage(1)}>
                    หน้าแรก
                </Pagination.First> */}
                <Pagination.Prev disabled={(page == 1) ? true : false} onClick={() => changePage(page - 1)}>
                    ก่อนหน้า
                </Pagination.Prev>
                {
                    renderPagination()
                }
                <Pagination.Next disabled={(page == (totalPage)) ? true : false} onClick={() => changePage(Number(page) + 1)}>
                    ถัดไป
                </Pagination.Next>
                {/* <Pagination.Last disabled={(page == (totalPage)) ? true : false} onClick={e => changePage(totalPage)}>
                    สุดท้าย
                </Pagination.Last> */}

            </Pagination>

        </div>
    )
}

export default Paginations;
