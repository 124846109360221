import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import GoogleMap from "../components/GoogleMap";
import InputText from "../components/InputText";

function ModalMap({ show, close, save, modeMap, lat, lon }) {
  const initialForm = {
    latitude: "",
    longitude: "",
    pinMap: false,
  };
  const [form, setForm] = useState(initialForm);

  const initInvalidMsg = {
    latitude: "",
    longitude: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  useEffect(() => {
    // if (modeMap == "edit") {
    setForm({ ...form, latitude: lat, longitude: lon });
    // }
  }, [lat, lon]);

  useEffect(() => {}, [form.latitude, form.longitude]);

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if (form.latitude == "") {
      addInvalid("latitude", "กรุณาระบุพิกัดละติจูด");
      validated = false;
    }

    if (form.longitude == "") {
      addInvalid("longitude", "กรุณาระบุพิกัดลองจิจูด");
      validated = false;
    }

    return validated;
  };

  const toSave = () => {
    if (validate()) {
      save(form);
      handleClose();
    }
  };

  const handleClose = () => {
    close();
    setInvalid(initInvalidMsg);
    setForm(initialForm);
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header closeButton className="pb-2 underline">
        <Modal.Title className="mt-2">
          <h5>
            <strong>แผนที่</strong> 
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="google-map mb-2">
          {modeMap == "edit" && show && (
            <GoogleMap
              mapMode="addPin"
              location={{
                lat: form.latitude == "" ? Number(lat) : Number(form.latitude),
                lng:
                  form.longitude == "" ? Number(lon) : Number(form.longitude),
              }}
              getLocation={(value) => {
                setForm({
                  ...form,
                  pinMap: true,
                  latitude: value.lat,
                  longitude: value.lng,
                });
                removeInvalid("latitude");
                removeInvalid("longitude");
              }}
            />
          )}
          {modeMap == "add" &&
            show &&
            (form.latitude === "" || form.latitude) &&
            (form.longitude === "" || form.longitude) && (
              <GoogleMap
                mapMode="addPin"
                location={{
                  lat:
                    form.latitude == "" ? Number(lat) : Number(form.latitude),
                  lng:
                    form.longitude == "" ? Number(lon) : Number(form.longitude),
                }}
                getLocation={(value) => {
                  setForm({
                    ...form,
                    pinMap: true,
                    latitude: value.lat,
                    longitude: value.lng,
                  });
                  removeInvalid("latitude");
                  removeInvalid("longitude");
                }}
              />
            )}
        </div>

        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="mr-2">
              <InputText
                type="number"
                title="ละติจูด"
                idName="latitude"
                value={form.latitude}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) => setForm({ ...form, latitude: value })}
                handleInvalid={() => removeInvalid("latitude")}
                invalid={invalid.latitude}
              />
            </div>
            <div className="mr-2">
              <InputText
                type="number"
                title="ลองจิจูด"
                idName="longitude"
                value={form.longitude}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) => setForm({ ...form, longitude: value })}
                handleInvalid={() => removeInvalid("longitude")}
                invalid={invalid.longitude}
              />
            </div>
          </div>

          <div className="mt-auto pb-3">
            <button
              className="btn btn-success px-4 mr-2"
              onClick={() => toSave()}
            >
              บันทึก
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalMap;
