import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import InputDatePicker from "./InputDatePicker";
import BoxCard from "./BoxCard";
import { useTranslation } from "react-i18next";
import * as API from "../utils/apis";
import { loading } from "../redux/actions/loadingAction";

function ModalAddAnimal({
  show,
  close,
  save,
  buidingList,
  mode,
  dataEdit,
  farmId,
}) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common"]);

  const initialForm = {
    animalTypeName: "",
    animalTypeId: "",
    animalId: "",
    status_id: "1",
    date_dead: "",
    cause_death_type: "1",
    cause_death_type_other: "",

    name: "",
    gender: "",
    genderType: "",
    house_buiding: buidingList.length > 0 ? buidingList[0].label : "",
    house_buiding_id: buidingList.length > 0 ? buidingList[0].value : "",
    age_year: "",
    age_month: "",
    no_animal_ear: "",
    no_microchip: "",
    breed: "",
    purpose_raising: "",
    purpose_name: "",
    no_nid: "",
    style_raising: "",
    style_raising_name: "",
    id: "",
    fm01Id: "",

    is_vaccine: "2",
    choose_disease: [],

    checkVaccine: false,
  };
  const [form, setForm] = useState(initialForm);

  const initInvalidMsg = {
    animalTypeName: "",
    gender: "",
    house_buiding: "",
    age_year: "",
    age_month: "",
    choose_disease: "",
    name: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const initAnimalSelectValue = [];
  const [animalSelectValue, setAnimalSelectValue] = useState(
    initAnimalSelectValue
  );
  const selectAnimalList = [];
  const [animalList, setAnimalList] = useState(selectAnimalList);

  const initGenderSelectValue = [];
  const [genderSelectValue, setGenderSelectValue] = useState(
    initGenderSelectValue
  );
  const selectGenderList = [
    { value: "m", label: "ตัวผู้" },
    { value: "f", label: "ตัวเมีย" },
    { value: "n", label: "ไม่ทราบ" },
  ];
  const [genderList, setGenderList] = useState(selectGenderList);

  const initBuidingSelectValue = [buidingList.length > 0 ? buidingList[0] : []];
  const [buidingSelectValue, setBuidingSelectValue] = useState(
    initBuidingSelectValue
  );

  const initPurposeRaisingSelectValue = [];
  const [purposeRaisingSelectValue, setPurposeRaisingSelectValue] = useState(
    initPurposeRaisingSelectValue
  );
  const selectPurposeRaisingList = [];
  const [purposeRaisingList, setPurposeRaisingList] = useState(
    selectPurposeRaisingList
  );

  const initStyleRaisingSelectValue = [];
  const [styleRaisingSelectValue, setStyleRaisingSelectValue] = useState(
    initStyleRaisingSelectValue
  );
  const selectStyleRaisingList = [];
  const [styleRaisingList, setStyleRaisingList] = useState(
    selectStyleRaisingList
  );

  const initDiseaseSelectValue = [];
  const [diseaseSelectValue, setDiseaseSelectValue] = useState(
    initDiseaseSelectValue
  );
  const selectDiseaseList = [];
  const [diseaseList, setDiseaseList] = useState(selectDiseaseList);

  useEffect(async () => {
    await getAnimal();
    // await getDiseaseType()
    await getHusbandry();
    await getAnimalPurpose();
  }, []);

  useEffect(async () => {
    if (mode == "edit" && !form.checkVaccine) {
      getDataAnimalEdit(dataEdit);
    }
  }, [
    mode,
    dataEdit,
    diseaseList,
    animalList,
    styleRaisingList,
    purposeRaisingList,
  ]);

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getHusbandry = async () => {
    try {
      const response = await API.getHusbandry();
      const data = await response.data;
      if (response.status === 200) {
        let styleRaisingArray = [];
        data.content.forEach((element) => {
          styleRaisingArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setStyleRaisingList(styleRaisingArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimalPurpose = async () => {
    try {
      const response = await API.getAnimalPurpose(1);
      const data = await response.data;
      if (response.status === 200) {
        let animalPurposeArray = [];
        data.content.forEach((element) => {
          animalPurposeArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setPurposeRaisingList(animalPurposeArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async (idAnimal) => {
    try {
      const response = await API.getDiseaseType(idAnimal);
      const data = await response.data;
      if (response.status === 200) {
        let diseaseTypeArray = [];
        data.content.forEach((element) => {
          diseaseTypeArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseaseList(diseaseTypeArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVaccineType = async (animalTypeId, diseaseTypeId, index) => {
    try {
      const response = await API.getVaccineType(animalTypeId, diseaseTypeId);
      const data = await response.data;
      if (response.status === 200) {
        let vaccineTypeArray = [];
        data.content.forEach((element) => {
          vaccineTypeArray.push({
            value: element.id,
            label: element.name,
          });
        });

        if (index >= 0 && vaccineTypeArray.length > 0) {
          form.choose_disease[index].vaccineTypeList = vaccineTypeArray;
        }
        setForm({ ...form });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataAnimalEdit = (data) => {
    const dataAnm = data.data;
    if (dataAnm.animalTypeId) {
      let animalSelect = animalList.find(
        (item) => item.value === dataAnm.animalTypeId
      );
      setAnimalSelectValue(animalSelect);
    }

    setGenderSelectValue([
      { value: dataAnm.genderType, label: dataAnm.gender },
    ]);
    setBuidingSelectValue([
      { value: dataAnm.house_buiding, label: dataAnm.house_buiding },
    ]);

    if (dataAnm.purpose_raising) {
      let purposeSelect = purposeRaisingList.find(
        (item) => item.value === dataAnm.purpose_raising
      );
      setPurposeRaisingSelectValue(purposeSelect);
    }

    if (dataAnm.style_raising) {
      let raisingSelect = styleRaisingList.find(
        (item) => item.value === dataAnm.style_raising
      );
      setStyleRaisingSelectValue(raisingSelect);
    }

    let diseaseSelect = [];
    if (dataAnm.choose_disease && dataAnm.choose_disease.length > 0) {
      dataAnm.choose_disease.map(async (vaccine, v) => {
        diseaseSelect.push({
          diseaseTypeName: vaccine.diseaseTypeName,
          diseaseTypeId: vaccine.diseaseTypeId,
          last_date: vaccine.last_date,
          lotNo: vaccine.lotNo,
          operator: vaccine.operator,
          other_vaccine: vaccine.other_vaccine,
          had_disease: vaccine.had_disease,
          vaccineTypeId: vaccine.vaccineTypeId,
          vaccineTypeSelectValue: [
            { value: vaccine.vaccineTypeId, label: vaccine.vaccineTypeName },
          ],
          vaccineTypeList: [],
        });

        diseaseList.forEach((item, i) => {
          if (item.value === vaccine.diseaseTypeId) {
            diseaseList.splice(i, 1);
          }
        });

        if (dataAnm.animalTypeId && vaccine.diseaseTypeId) {
          await getVaccineType(dataAnm.animalTypeId, vaccine.diseaseTypeId, v);
        }
        setDiseaseList(diseaseList);
      });
    }

    setForm({
      ...form,
      type: mode,
      index: data.index,
      animalTypeName: dataAnm.animalTypeName,
      animalTypeId: dataAnm.animalTypeId,
      animalId: dataAnm.animalId,
      status_id: dataAnm.status_id,
      date_dead: dataAnm.date_dead ? dataAnm.date_dead : "",
      cause_death_type: dataAnm.cause_death_type,
      cause_death_type_other: dataAnm.cause_death_type_other,
      name: dataAnm.name,
      gender: dataAnm.gender,
      genderType: dataAnm.genderType,
      house_buiding: dataAnm.house_buiding,
      house_buiding_id: dataAnm.house_buiding_id,
      age_year: dataAnm.age_year,
      age_month: dataAnm.age_month,
      no_animal_ear: dataAnm.no_animal_ear,
      no_microchip: dataAnm.no_microchip,
      breed: dataAnm.breed,
      purpose_raising: dataAnm.purpose_raising,
      purpose_name: dataAnm.purpose_name,
      no_nid: dataAnm.no_nid,
      style_raising: dataAnm.style_raising,
      style_raising_name: dataAnm.style_raising_name,
      is_vaccine: dataAnm.is_vaccine,
      choose_disease: diseaseSelect,
      id: dataAnm.id,
      fm01Id: dataAnm.fm01Id,
    });
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if (form.name === "") {
      addInvalid("name", "กรุณาระบุชื่อสัตว์");
      validated = false;
    }

    if (form.animalTypeName === "") {
      addInvalid("animalTypeName", "กรุณาเลือกชนิดสัตว์");
      validated = false;
    }

    if (form.gender === "") {
      addInvalid("gender", "กรุณาเลือกเพศ");
      validated = false;
    }

    if (form.house_buiding === "") {
      addInvalid("house_buiding", "กรุณาเลือกโรงเรือน");
      validated = false;
    }

    if (form.age_year === "") {
      addInvalid("age_year", "กรุณาระบุอายุปี");
      validated = false;
    }

    if (form.age_month === "") {
      addInvalid("age_month", "กรุณาระบุอายุเดือน");
      validated = false;
    }

    if (form.age_month > 11) {
      addInvalid("age_month", "เดือนสามารถกรอกได้ 0-11");
      validated = false;
    }

    // if (form.is_vaccine === "") {
    //     addInvalid("is_vaccine", "กรุณาเลือกการรับวัคซีน");
    //     validated = false;
    // }

    if (form.is_vaccine === 1) {
      if (form.choose_disease.length === 0) {
        addInvalid("choose_disease", "กรุณาเลือกชนิดโรค");
        validated = false;
      }
    }
    console.log("validated", validated);
    return validated;
  };

  const toSave = async () => {
    if (validate()) {
      if (form.choose_disease && form.choose_disease.length > 0) {
        form.choose_disease.map((item, i) => {
          if (item.vaccineTypeId == "" || item.vaccineTypeId == null) {
            form.choose_disease.splice(i, 1);
          }
        });
      }

      save(form);
      handleClose();
    }
  };

  const selectValueAnimal = (value) => {
    setAnimalSelectValue([value]);
    setForm({
      ...form,
      animalTypeId: value.value,
      animalTypeName: value.label,
    });
    getDiseaseType(value.value);
  };

  const chooseDisease = async (value) => {
    await form.choose_disease.push({
      diseaseTypeName: value.label,
      diseaseTypeId: value.value,
      last_date: "",
      lotNo: "",
      operator: "",
      other_vaccine: "",
      had_disease: "",
      vaccineTypeId: "",
      vaccineTypeName: "",
      vaccineTypeSelectValue: [],
      vaccineTypeList: [],
    });

    diseaseList.forEach((item, i) => {
      if (item.label == value.label) {
        diseaseList.splice(i, 1);
      }
    });

    setForm({ ...form, choose_disease: form.choose_disease });
    setDiseaseList(diseaseList);

    form.choose_disease.filter(async (item, i) => {
      if (item.diseaseTypeId === value.value) {
        await getVaccineType(form.animalTypeId, value.value, i);
      }
    });
  };

  const removeDisease = (index, name, value) => {
    if (form.choose_disease && form.choose_disease.length > 0) {
      form.choose_disease.splice(index, 1);

      diseaseList.push({ value: value, label: name });

      setForm({ ...form, choose_disease: form.choose_disease });
      setDiseaseList(diseaseList);
    }
  };

  const handleClose = () => {
    close();
    setInvalid(initInvalidMsg);
    setForm(initialForm);
    setAnimalSelectValue([]);
    setGenderSelectValue([]);
    setBuidingSelectValue([]);
    setPurposeRaisingSelectValue([]);
    setDiseaseSelectValue([]);
  };
  console.log("form", form);
  return (
    <Modal show={show} size="lg" onHide={() => handleClose()} centered>
      <Modal.Header closeButton className="pb-2 underline">
        <Modal.Title>
          <h5>
            <strong>{t("save_data_animal")}</strong>
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <BoxCard title={t("data_animal")}>
          <Row>
            <Col lg={4} md={4} sm={12}>
              <InputSelect
                title={t("animal_type")}
                classFormGroup="mb-0 w-100"
                star={true}
                placeholder="กรุณาเลือก"
                idName="animalTypeName"
                classLabel="normal"
                selectValue={animalSelectValue}
                optionsList={animalList}
                handleChange={(value) => {
                  selectValueAnimal(value);
                  form.checkVaccine = true;
                  setForm({
                    ...form,
                    animalTypeId: value.value,
                    animalTypeName: value.label,
                  });
                }}
                handleInvalid={() => removeInvalid("animalTypeName")}
                invalid={invalid.animalTypeName}
              />
            </Col>
            <Col lg={4} md={4} sm={12}>
              <InputText
                type="text"
                title={t("animal_id")}
                idName="animalId"
                value={form.animalId}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                disabled={form.animalTypeName == "" ? true : false}
                handleChange={(value) => setForm({ ...form, animalId: value })}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={12} sm={12} className="d-flex">
              <div className="d-flex">
                <p className="mb-0">{t("status")} : </p>
                <Form.Group className="ml-3">
                  <Form.Check
                    type="radio"
                    name="status_id"
                    id="be_alive"
                    value={1}
                    checked={form.status_id == 1}
                    label={t("be_alive")}
                    onChange={(e) => {
                      setForm({ ...form, status_id: e.target.value });
                    }}
                  />
                  <Form.Check
                    type="radio"
                    name="status_id"
                    id="sick"
                    value={2}
                    checked={form.status_id == 2}
                    label={t("sick")}
                    onChange={(e) => {
                      setForm({ ...form, status_id: e.target.value });
                    }}
                  />
                  <Form.Check
                    type="radio"
                    name="status_id"
                    id="move_out"
                    value={3}
                    checked={form.status_id == 3}
                    label={t("move_out")}
                    onChange={(e) => {
                      setForm({ ...form, status_id: e.target.value });
                    }}
                  />
                  <Form.Check
                    type="radio"
                    name="status_id"
                    id="dead"
                    value={4}
                    checked={form.status_id == 4}
                    label={t("dead")}
                    onChange={(e) => {
                      setForm({ ...form, status_id: e.target.value });
                    }}
                  />
                </Form.Group>
              </div>
            </Col>

            <Col lg={9} md={12} sm={12} className="mt-auto">
              <div className="show-lg">
                <br />
                <br />
                <br />
              </div>
              <div className="d-flex align-items-center">
                <p className="mb-0">{t("date_dead")}</p>
                <div className="ml-2 w-50">
                  <InputDatePicker
                    classFormGroup="mb-0"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.date_dead}
                    controls={["date"]}
                    star={false}
                    disabled={form.status_id == 4 ? false : true}
                    handleChange={(value) =>
                      setForm({ ...form, date_dead: value })
                    }
                  />
                </div>
              </div>
              <Row className="mt-2">
                <Col
                  lg={3}
                  md={12}
                  sm={12}
                  className="d-flex align-items-center"
                >
                  <p className="mb-0">{t("cause_death")}</p>
                </Col>
                <Col>
                  <Form.Group className="mb-0 d-flex align-items-center w-100">
                    <Form.Check
                      inline
                      type="radio"
                      name="cause_death_type"
                      id="unknown"
                      value={1}
                      checked={form.cause_death_type == "1"}
                      disabled={form.status_id == "4" ? false : true}
                      label={t("unknown")}
                      onChange={(e) => {
                        setForm({ ...form, cause_death_type: e.target.value });
                      }}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      name="cause_death_type"
                      id="kill"
                      value={2}
                      checked={form.cause_death_type == "2"}
                      disabled={form.status_id == "4" ? false : true}
                      label={t("kill")}
                      onChange={(e) => {
                        setForm({ ...form, cause_death_type: e.target.value });
                      }}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      name="cause_death_type"
                      id="die"
                      value={3}
                      checked={form.cause_death_type == "3"}
                      disabled={form.status_id == "4" ? false : true}
                      label={t("die")}
                      onChange={(e) => {
                        setForm({ ...form, cause_death_type: e.target.value });
                      }}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      name="cause_death_type"
                      id="other"
                      value={4}
                      checked={form.cause_death_type == "4"}
                      disabled={form.status_id == "4" ? false : true}
                      label={t("other")}
                      onChange={(e) => {
                        setForm({ ...form, cause_death_type: e.target.value });
                      }}
                    />
                    <InputText
                      classFormGroup="mb-0 w-25"
                      type="text"
                      idName="cause_death_type_other"
                      value={form.cause_death_type_other}
                      star={false}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      disabled={form.cause_death_type == "4" ? false : true}
                      handleChange={(value) =>
                        setForm({ ...form, cause_death_type_other: value })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg={3} md={6} sm={12}>
              <InputText
                type="text"
                title={t("first_name")}
                idName="name"
                value={form.name}
                star={true}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) => setForm({ ...form, name: value })}
                handleInvalid={() => removeInvalid("name")}
                invalid={invalid.name}
              />
            </Col>

            <Col lg={3} md={6} sm={12}>
              <InputSelect
                title={t("gender")}
                classFormGroup="mb-0 w-100"
                star={true}
                placeholder="กรุณาเลือก"
                idName="gender"
                classLabel="normal"
                selectValue={genderSelectValue}
                optionsList={genderList}
                handleChange={(value) => {
                  setGenderSelectValue([value]);
                  setForm({
                    ...form,
                    gender: value.label,
                    genderType: value.value,
                  });
                }}
                handleInvalid={() => removeInvalid("gender")}
                invalid={invalid.gender}
              />
            </Col>

            <Col lg={3} md={6} sm={12}>
              <InputSelect
                title={t("house_buiding")}
                classFormGroup="mb-0 w-100"
                star={true}
                placeholder="กรุณาเลือก"
                idName="house_buiding"
                classLabel="normal"
                selectValue={buidingSelectValue}
                optionsList={buidingList}
                handleChange={(value) => {
                  setBuidingSelectValue([value]);
                  setForm({
                    ...form,
                    house_buiding: value.label,
                    house_buiding_id: value.value,
                  });
                }}
                handleInvalid={() => removeInvalid("house_buiding")}
                invalid={invalid.house_buiding}
              />
            </Col>

            <Col lg={3} md={6} sm={12}>
              <div className="d-flex align-items-center">
                <InputText
                  classFormGroup="mb-0"
                  type="text"
                  title={t("age")}
                  idName="age_year"
                  value={form.age_year}
                  star={true}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) => {
                    if (/^[0-9]*$/.test(value)) {
                      setForm({ ...form, age_year: value });
                    }
                  }}
                  handleInvalid={() => removeInvalid("age_year")}
                  invalid={invalid.age_year ? true : false}
                />
                <p className="mb-0 font14 mt-4 mx-1">{t("year")}</p>
                <div className="mt-auto">
                  <InputText
                    classFormGroup="mb-0"
                    type="text"
                    idName="age_month"
                    value={form.age_month}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => {
                      if (/^[0-9]*$/.test(value)) {
                        setForm({ ...form, age_month: value });
                      }
                    }}
                    handleInvalid={() => removeInvalid("age_month")}
                    invalid={invalid.age_month ? true : false}
                  />
                </div>
                <p className="mb-0 font14 mt-4 mx-1">{t("month")}</p>
              </div>
              <div className="invalid-error">{invalid.age_year}</div>
              <div className="invalid-error">{invalid.age_month}</div>
            </Col>

            <Col lg={3} md={6} sm={12}>
              <InputText
                title={t("no_animal_ear")}
                type="text"
                idName="no_animal_ear"
                value={form.no_animal_ear}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) =>
                  setForm({ ...form, no_animal_ear: value })
                }
              />
            </Col>

            <Col lg={3} md={6} sm={12}>
              <InputText
                title={t("no_microchip")}
                type="text"
                idName="no_microchip"
                value={form.no_microchip}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) =>
                  setForm({ ...form, no_microchip: value })
                }
              />
            </Col>

            <Col lg={3} md={6} sm={12}>
              <InputText
                title={t("breed")}
                type="text"
                idName="breed"
                value={form.breed}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) => setForm({ ...form, breed: value })}
              />
            </Col>

            <Col lg={3} md={6} sm={12}>
              <InputSelect
                title={t("purpose_raising")}
                star={false}
                placeholder="กรุณาเลือก"
                idName="purpose_raising"
                classLabel="normal"
                selectValue={purposeRaisingSelectValue}
                optionsList={purposeRaisingList}
                handleChange={(value) => {
                  setPurposeRaisingSelectValue([value]);
                  setForm({
                    ...form,
                    purpose_raising: value.value,
                    purpose_name: value.label,
                  });
                }}
              />
            </Col>

            <Col lg={3} md={6} sm={12}>
              <InputText
                title={t("no_nid")}
                classFormGroup="mb-0"
                type="text"
                idName="no_nid"
                value={form.no_nid}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) => setForm({ ...form, no_nid: value })}
              />
            </Col>

            <Col lg={3} md={6} sm={12}>
              <InputSelect
                title={t("style_raising")}
                star={false}
                placeholder="กรุณาเลือก"
                idName="style_raising"
                classLabel="normal"
                selectValue={styleRaisingSelectValue}
                optionsList={styleRaisingList}
                handleChange={(value) => {
                  setStyleRaisingSelectValue([value]);
                  setForm({
                    ...form,
                    style_raising: value.value,
                    style_raising_name: value.label,
                  });
                }}
              />
            </Col>
          </Row>
        </BoxCard>

        <BoxCard title={t("vaccination")} star={true} classNameBox="mt-3">
          <Row>
            <Col lg={2} md={6} sm={12}>
              <p className="mb-0">{t("vaccination")} : </p>
            </Col>
            <Col lg={10} md={6} sm={12}>
              <div className="mb-2">
                <Form.Group className="d-flex w-100 mb-0">
                  <Form.Check
                    inline
                    type="radio"
                    name="is_vaccine"
                    id="ever_received"
                    value={1}
                    checked={form.is_vaccine == 1}
                    label={t("ever_received")}
                    onChange={(e) => {
                      form.checkVaccine = true;
                      setForm({ ...form, is_vaccine: e.target.value });
                      setInvalid({ ...invalid, is_vaccine: false });
                    }}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="is_vaccine"
                    id="never_received"
                    value={2}
                    checked={form.is_vaccine == 2}
                    label={t("never_received")}
                    onChange={(e) => {
                      form.checkVaccine = true;
                      setForm({
                        ...form,
                        is_vaccine: e.target.value,
                        choose_disease: [],
                      });
                      setInvalid({ ...invalid, is_vaccine: false });
                      getDiseaseType(form.animalTypeId);
                    }}
                  />
                </Form.Group>
                {invalid.is_vaccine ? (
                  <div className="invalid-error">{invalid.is_vaccine}</div>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={2} md={12} sm={12} className="d-flex align-items-center">
              <div className="mb-0" disabled={form.is_vaccine == 2}>
                {t("choose_disease")} :{" "}
              </div>
            </Col>
            <Col
              lg={10}
              md={12}
              sm={12}
              className="d-flex flex-wrap align-items-center"
            >
              <InputSelect
                classFormGroup="mb-1 w-30 mr-3"
                star={true}
                placeholder="กรุณาเลือก"
                idName="choose_disease"
                classLabel="normal"
                disabled={form.is_vaccine == 2}
                selectValue={diseaseSelectValue}
                optionsList={diseaseList}
                handleChange={(value) => {
                  setDiseaseSelectValue([]);
                  chooseDisease(value);
                }}
              />

              <div className="d-flex flex-wrap">
                {form.choose_disease && form.choose_disease.length > 0
                  ? form.choose_disease.map((item, i) => (
                      <div key={i} className="d-flex my-1">
                        <div className="font14 box-disease">
                          {item.diseaseTypeName}
                        </div>
                        <div
                          className="font14 box-disease-del mr-2"
                          onClick={() =>
                            removeDisease(
                              i,
                              item.diseaseTypeName,
                              item.diseaseTypeId
                            )
                          }
                        >
                          x
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </Col>
          </Row>

          {form.choose_disease && form.choose_disease.length > 0
            ? form.choose_disease.map((item, i) => (
                <Row key={i} className="mt-3">
                  <Col
                    lg={2}
                    md={12}
                    sm={12}
                    className="d-flex align-items-center justify-content-lg-end justify-content-md-start justify-content-sm-start"
                  >
                    {item.diseaseTypeName}
                  </Col>
                  <Col lg={10} md={12} sm={12}>
                    <div className="border px-2 pt-2 pb-3">
                      <Row>
                        <Col lg={4} md={12} sm={12} className="">
                          <InputSelect
                            title="ชนิดวัคซีน"
                            classFormGroup="mb-1"
                            star={false}
                            placeholder="กรุณาเลือก"
                            idName="vaccine_type"
                            classLabel="normal"
                            selectValue={
                              form.choose_disease[i].vaccineTypeSelectValue
                            }
                            optionsList={form.choose_disease[i].vaccineTypeList}
                            handleChange={(value) => {
                              form.choose_disease[i].vaccineTypeSelectValue =
                                value;
                              form.choose_disease[i].vaccineTypeId =
                                value.value;
                              form.choose_disease[i].vaccineTypeName =
                                value.label;
                              form.checkVaccine = true;
                              setForm({ ...form });
                            }}
                          />
                        </Col>
                        <Col lg={4} md={12} sm={12} className="">
                          <p className="mb-0 mr-3"></p>
                          <InputDatePicker
                            title={t("last_date")}
                            classFormGroup="mb-0 ml-1"
                            classLabel="normal"
                            idName={`last_date${i}`}
                            placeholder="วัน/เดือน/ปี"
                            value={form.choose_disease[i].last_date}
                            star={false}
                            handleChange={(value) => {
                              form.choose_disease[i].last_date = value;
                              setForm({ ...form });
                            }}
                          />
                        </Col>
                        <Col lg={4} md={12} sm={12} className="">
                          <InputText
                            title="Lot No."
                            classFormGroup="mb-0"
                            type="text"
                            idName={`lotNo${i}`}
                            value={form.choose_disease[i].lotNo}
                            star={false}
                            placeholder="โปรดระบุ"
                            classLabel="normal"
                            handleChange={(value) => {
                              form.choose_disease[i].lotNo = value;
                              setForm({ ...form });
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col lg={3} md={12} sm={12}>
                          {t("vaccine_operator")} :
                        </Col>
                        <Col
                          lg={9}
                          md={12}
                          sm={12}
                          className="d-flex align-items-center flex-wrap"
                        >
                          <Form.Check
                            inline
                            type="radio"
                            name={`operator${i}`}
                            id={`livestock_officers${i}`}
                            value={1}
                            checked={form.choose_disease[i].operator == 1 || ""}
                            label={t("livestock_officers")}
                            onChange={(e) => {
                              form.choose_disease[i].other_vaccine = "";
                              form.choose_disease[i].operator = e.target.value;
                              setForm({ ...form });
                            }}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            name={`operator${i}`}
                            id={`farmer${i}`}
                            value={2}
                            checked={form.choose_disease[i].operator == 2}
                            label={t("farmer")}
                            onChange={(e) => {
                              form.choose_disease[i].other_vaccine = "";
                              form.choose_disease[i].operator = e.target.value;
                              setForm({ ...form });
                            }}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            name={`operator${i}`}
                            id={`livestock_volunteer${i}`}
                            value={3}
                            checked={form.choose_disease[i].operator == 3}
                            label={t("livestock_volunteer")}
                            onChange={(e) => {
                              form.choose_disease[i].other_vaccine = "";
                              form.choose_disease[i].operator = e.target.value;
                              setForm({ ...form });
                            }}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            name={`operator${i}`}
                            id={`animal_hospital${i}`}
                            value={5}
                            checked={form.choose_disease[i].operator == 5}
                            label="สถานพยาบาลสัตว์"
                            onChange={(e) => {
                              form.choose_disease[i].other_vaccine = "";
                              form.choose_disease[i].operator = e.target.value;
                              setForm({ ...form });
                            }}
                          />
                          <div className="d-flex">
                            <Form.Check
                              inline
                              type="radio"
                              name={`operator${i}`}
                              id={`other_vaccine${i}`}
                              value={4}
                              checked={form.choose_disease[i].operator == 4}
                              label={t("other")}
                              onChange={(e) => {
                                form.choose_disease[i].other_vaccine = "";
                                form.choose_disease[i].operator =
                                  e.target.value;
                                setForm({ ...form });
                              }}
                            />

                            <InputText
                              classFormGroup="mb-0 w-50"
                              type="text"
                              idName={`other_vaccine${i}`}
                              value={form.choose_disease[i].other_vaccine}
                              star={false}
                              placeholder="โปรดระบุ"
                              classLabel="normal"
                              disabled={
                                form.choose_disease[i].operator == 4
                                  ? false
                                  : true
                              }
                              handleChange={(value) => {
                                form.choose_disease[i].other_vaccine = value;
                                setForm({ ...form });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col lg={3} md={12} sm={12}>
                          {t("animal_had_disease")} :
                        </Col>
                        <Col
                          lg={9}
                          md={12}
                          sm={12}
                          className="d-flex align-items-center"
                        >
                          <Form.Check
                            inline
                            type="radio"
                            name={`had_disease${i}`}
                            id={`ever${i}`}
                            value={1}
                            checked={form.choose_disease[i].had_disease == 1}
                            label={t("ever")}
                            onChange={(e) => {
                              form.choose_disease[i].had_disease =
                                e.target.value;
                              setForm({ ...form });
                            }}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            name={`had_disease${i}`}
                            id={`never${i}`}
                            value={2}
                            checked={form.choose_disease[i].had_disease == 2}
                            label={t("never")}
                            onChange={(e) => {
                              form.choose_disease[i].had_disease =
                                e.target.value;
                              setForm({ ...form });
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              ))
            : ""}
        </BoxCard>

        <div className="mr-2 mt-3 mb-2">
          <div className="text-right py-2">
            <button
              className="btn btn-danger px-4 mr-2"
              onClick={() => handleClose()}
            >
              {t("cancel")}
            </button>
            <button className="btn btn-success px-4" onClick={() => toSave()}>
              {t("save")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalAddAnimal;
