import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Routes from "./route/Routes";
import "./assets/css/style.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/adminlte.min.css";
import "./assets/css/overlayScrollbars.min.css";
import Loader from "./components/loader/Loader";
function App() {

  return (
    <div className="font-fam">
      <Routes />
    </div>
  );
}

export default App;
