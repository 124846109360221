import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import BoxCard from "../../components/BoxCard";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import InputSelect from "../../components/InputSelect";
import InputText from "../../components/InputText";
import DataTableNodata from "../../components/DataTableNodata";
import DataTable from "../../components/DataTable";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

function Risk_Assessment_Items() {
  const user = useSelector((state) => state.login.result);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["common", "footer"]);

  const initialForm = {
    formId: "",
    questName: "",
    diseaseTypeId: "",
    groupId: "",
    statusId: "",
    questNo: "",
    statusName: "",
    updateDate: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
      },
      {
        sortBy: "nameList",
        name: "ชื่อชุด",
        sortFlg: true,
      },

      {
        sortBy: "group",
        name: "กลุ่ม",
        sortFlg: true,
      },

      {
        sortBy: "status",
        name: "คำถาม",
        sortFlg: true,
      },

      {
        sortBy: "status",
        name: "สถานะ",
        sortFlg: true,
      },

      {
        sortBy: "updateDate",
        name: "ปรับปรุงล่าสุด",
        sortFlg: true,
      },

      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],

    statusActionId: [
      {
        id: 1,
        color: "#3FC071",
        status: "ใช้งาน",
      },
      {
        id: 2,
        color: "#FF482F",
        status: "ไม่ได้ใช้งาน",
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  // ชุดประเมินความเสี่ยง
  const [riskSelectValue, setRiskSelectValue] = useState([]);
  const [riskList, setRiskList] = useState([]);

  // Select ชนิดโรค
  const [diseaseTypeSelectValue, setDiseaseTypeSelectValue] = useState([]);
  const [diseaseTypeList, setDiseaseTypeList] = useState([]);

  // Select กลุ่มข้อมูล
  const [dataGroupSelectValue, setDataGroupSelectValue] = useState([]);
  const [dataGroupList, setDataGroupList] = useState([]);

  // Select สถานะ
  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    {
      value: 1,
      label: "ใช้งาน",
    },
    {
      value: 2,
      label: "ไม่ใช้งาน",
    },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  const [listAction, setListAction] = useState([]);
  const [isClearSearch, setIsClearSearch] = useState(false);

  useEffect(() => {
    getDiseaseList();
    getDisease();
    getGroupData();
  }, []);

  useEffect(async () => {
    await getRiskAssessment();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/assess_risk/list-assess-risk", name: "ประเมินความเสี่ยง" },
    { path: "active", name: "รายการประเมินความเสี่ยง" },
  ];

  const getDiseaseList = async () => {
    try {
      const response = await API.getDiseaseList();
      const data = await response.data;
      if (response.status == 200) {
        let diseaseListArray = [];
        data.content.forEach((element) => {
          diseaseListArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setRiskList(diseaseListArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDisease = async () => {
    try {
      const response = await API.getDisease("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.diseaseTypeName,
          });
        });
        await setDiseaseTypeList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGroupData = async () => {
    try {
      const response = await API.getGroupData();
      const data = await response.data;
      if (response.status == 200) {
        let groupArray = [];
        data.content.forEach((element) => {
          groupArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setDataGroupList(groupArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setRiskSelectValue([]);
    setDiseaseTypeSelectValue([]);
    setDataGroupSelectValue([]);
    setStatusSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const buttonAdd = () => {
    return (
      <>
        <button
          className="btn btn-info"
          disabled={user?.userGroup.mcreate === "n"}
          onClick={() =>
            history.push("/assess_risk/risk-assessment-items/save")
          }
        >
          <a className="text-white">
            <i className="fas fa-plus mr-2"></i>
            เพิ่มคำถาม
          </a>
        </button>
      </>
    );
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type == "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type == "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (form.statusActionId && form.statusActionId.length > 0) {
      form.statusActionId.map((item, index) => {
        if (item.id == idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-2 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getRiskAssessment = async () => {
    const params = await {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",

      formId: form.formId ? form.formId : "",
      diseaseTypeId: form.diseaseTypeId ? form.diseaseTypeId : "",
      groupId: form.groupId ? form.groupId : "",
      statusId: form.statusId ? form.statusId : "",
      questName: form.questName ? form.questName : "",
    };
    try {
      const response = await API.getRiskAssessment(params);
      const data = await response.data;
      if (response.status == 200) {
        await setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        await setListAction(data.content);
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const viewDetails = (id) => {
    history.push(`/assess_risk/risk-assessment-items/edit/${id}`);
  };

  const deleteAssessment = (id) => {
    MySwal.fire({
      text: `ยืนยันการลบข้อมูล`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteAssessment(id);
          if (response.status == 200) {
            await getRiskAssessment();
          }
        } catch (error) {
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td>{item.formName ? item.formName : "-"}</td>
          <td>{item.groupName ? item.groupName : "-"}</td>
          <td>{item.questName ? item.questName : "-"}</td>
          <td className="text-center">
            {item.statusName ? getStatusColor(item.statusId) : "-"}
          </td>
          <td className="text-center">
            {item.updateDate
              ? helper.fullDateTh(item.updateDate, "th", "long")
              : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => deleteAssessment(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="7" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div>
              <Row className="mb-2 pl-2 ">
                <Col lg={4} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชุดประเมินความเสี่ยง"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={riskSelectValue}
                    optionsList={riskList}
                    handleSelectValue={(value) =>
                      setRiskSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, formId: value.value })
                    }
                  />
                </Col>

                <Col lg={4} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่อคำถาม"
                    idName=" namep"
                    value={form.questName}
                    star={false}
                    placeholder="กรุณาระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, questName: value })
                    }
                  />
                </Col>

                <Col lg={4} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดโรค"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={diseaseTypeSelectValue}
                    optionsList={diseaseTypeList}
                    handleSelectValue={(value) =>
                      setDiseaseTypeSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, diseaseTypeId: value.value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="กลุ่มข้อมูล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={dataGroupSelectValue}
                    optionsList={dataGroupList}
                    handleSelectValue={(value) =>
                      setDataGroupSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, groupId: value.value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={statusList}
                    handleSelectValue={(value) =>
                      setStatusSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, statusId: value.value })
                    }
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการประเมินความเสี่ยง"
            headRight={buttonAdd()}
          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTable(listAction)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Risk_Assessment_Items;
