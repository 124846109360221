import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { loading } from "../../redux/actions/loadingAction";
import { logout } from "../../redux/actions/logoutAction";

import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "../../components/DataTable";
import {
  useMasterData,
  useOptionsListYears,
  useCurrentYear,
} from "../../components/hooks/useMasterData";
import ButtonReportLink from "../../components/ButtonReportLink";

import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";

const MySwal = withReactContent(Swal);

function Vaccinate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation(["common", "footer"]);
  const user = useSelector((state) => state.login.result);

  const search = useLocation().search;
  const obCode = new URLSearchParams(search).get("obCode");

  const initialForm = {
    typeUser: "1",
    agency: "",

    dateBegin: "",
    dateEnd: "",
    outbreakId: obCode ? obCode : "",
    animalTypeId: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    zoneId: "",
    caseCode: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
      },
      {
        sortBy: "caseCode",
        name: "case",
        sortFlg: true,
      },
      {
        sortBy: "id",
        name: "วันที่ปฏิบัติงาน",
        sortFlg: false,
      },
      {
        sortBy: "id",
        name: "ชนิดสัตว์ (จำนวนที่ฉีดวัคซีน/จำนวนทั้งหมด)	",
        sortFlg: false,
      },
      {
        sortBy: "animalVaccineAmt",
        name: "รวม(จำนวนที่ฉีดวัคซีน/จำนวนทั้งหมด)",
        sortFlg: true,
      },
      {
        sortBy: "dldStaffName1",
        name: "ชื่ออาสาฯผู้ปฏิบัติงาน",
        sortFlg: true,
      },
      {
        sortBy: "dldStaffName2",
        name: "ชื่อสัตวแพทย์ผู้ควบคุม",
        sortFlg: true,
      },

      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listVaccinate, setListVaccinate] = useState([]);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [isClearSearch, setIsClearSearch] = useState(false);

  // =================

  // select  เขตปศุสัตว์ ชนิดสัตว์

  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState([]);
  const [zoneLivestockList, setZoneLivestockList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);
  // =================
  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );

  const optionsListYear = useOptionsListYears();
  const currentYearValue = useCurrentYear();
  const [yearFilterList, setYearFilterList] = useState(currentYearValue);

  useEffect(() => {
    // getProvince();
    getAnimal();
    getZoneLivestock();
  }, []);

  useEffect(() => {
    getVaccinate();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getVaccinate = async () => {
    const params = {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",
      obCode: form.outbreakId ? form.outbreakId : "",
      caseCode: form.caseCode ? form.caseCode : "",
      dateBegin: form.dateBegin ? helper.getDate(form.dateBegin) : "",
      dateEnd: form.dateEnd ? helper.getDate(form.dateEnd) : "",
      zoneId: form.zoneId ? form.zoneId : "",
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
      animalTypeId: form.animalTypeId ? form.animalTypeId : "",
      statusId: form.status ? form.status : "",
      onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
    };

    dispatch(loading(true));
    try {
      const response = await API.getVaccinate(params);

      const data = await response.data;
      // console.log("params---getVaccinate--🐳🐳", params, response);

      if (response.status == 200) {
        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListVaccinate(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };
  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock();
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince();
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, "1");
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, districtId: value.value });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value });
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "title", name: "กคร.5" },
    { path: "active", name: "แผน/ผลการฉีดวัคซีนป้องกันโรคระบาดสัตว์ (กคร.5)" },
  ];

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setDistrictList([]);
    setSubDistrictList([]);
    setAnimalSelectValue([]);
    setZoneLivestockSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const getFilter = (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const buttonSearch = () => {
    return (
      <>
        <ButtonReportLink path={"vaccinate"} linkSize={1} params={{
          obCode: form.outbreakId ? form.outbreakId : "",
          caseCode: form.caseCode ? form.caseCode : "",
          dateBegin: form.dateBegin ? helper.getDate(form.dateBegin) : "",
          dateEnd: form.dateEnd ? helper.getDate(form.dateEnd) : "",
          zoneId: form.zoneId ? form.zoneId : "",
          provinceId: form.provinceId ? form.provinceId : "",
          amphurId: form.districtId ? form.districtId : "",
          tambonId: form.subDistrictId ? form.subDistrictId : "",
          animalTypeId: form.animalTypeId ? form.animalTypeId : "",
          statusId: form.status ? form.status : "",
          onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
        }}/>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const buttonAdd = () => {
    return (
      <button
        className="btn btn-info text-white "
        disabled={user?.userGroup?.mcreate === "n"}
        onClick={() => history.push(`/passive_surv/vaccinate/save`)}
      >
        <i className="fas fa-plus mr-1 "></i>เพิ่มการฉีดวัคซีน
      </button>
    );
  };

  const viewDetails = (id) => {
    history.push(`/passive_surv/vaccinate/edit/${id}`);
  };

  const duplicate = (id) => {
    history.push(`/passive_surv/vaccinate/duplicate/${id}`);
  };

  const deleteVac = (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบแผน/ผลการฉีดวัคซีนป้องกันโรคระบาดสัตว์ (กคร.5)`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteVaccinate(id);
          if (response.status == 200) {
            await getVaccinate();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTable = (data) => {
    console.log("data--🐳renderTable🐳", data);
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item.caseCode ? item.caseCode : "-"}</td>
          <td>
            {item.operateDateFrom || item.operateDateTo
              ? `${
                  item.operateDateFrom
                    ? helper.getDateFormat(item.operateDateFrom)
                    : "-"
                } ถึง ${
                  item.operateDateTo
                    ? helper.getDateFormat(item.operateDateTo)
                    : "-"
                }`
              : "-"}
          </td>
          <td>
            {item.animalType && item.animalType.length > 0
              ? item.animalType.map((animal, a) => {
                  return (
                    <p className="mb-0" key={a}>
                      {animal.animalTypeName}: {animal.vaccineAmt}/
                      {animal.totalAmt}{" "}
                    </p>
                  );
                })
              : "-"}
          </td>
          <td>
            {item.animalType && item.animalType.length > 0
              ? item.animalType.map((animal, a) => {
                  return (
                    <p className="mb-0" key={a}>
                      {animal.vaccineAmt}/{animal.totalAmt}{" "}
                    </p>
                  );
                })
              : "-"}
          </td>

          <td>{item.dldStaffName1 || "-"}</td>
          <td>{item.dldStaffName2 || "-"}</td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>

              <button
                className="btn btn-del mr-2 "
                onClick={() => deleteVac(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>

              <button
                className="btn btn-copy mr-2 "
                onClick={() => duplicate(item.id)}
                disabled={user?.userGroup.mcopy === "n"}
              >
                <img className="icon-btn " src="/svg/btn-copy.svg" alt="copy" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="7" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ปี"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="year"
                    classLabel="normal"
                    selectValue={yearFilterList}
                    optionsList={optionsListYear}
                    handleChange={(value) => setYearFilterList(value)}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เลข Outbreak ID"
                    idName="outbreakId"
                    value={form.outbreakId}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, outbreakId: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เลข case ID"
                    idName="caseCode"
                    value={form.caseCode}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, caseCode: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่ปฏิบัติงาน"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateBegin}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, dateBegin: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateEnd}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, dateEnd: value })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขตปศุสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={zoneLivestockSelectValue}
                    optionsList={zoneLivestockList}
                    handleChange={(value) => {
                      setZoneLivestockSelectValue(value);
                      setForm({ ...form, zoneId: value.value });
                      getProvince(value.value);
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => {
                      selectValueProvince(value);
                      setForm({ ...form, provinceId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => {
                      selectValueDistrict(value);
                      setForm({ ...form, districtId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => {
                      selectValueSubDistrict(value);
                      setForm({ ...form, subDistrictId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={animalSelectValue}
                    optionsList={animalList}
                    handleChange={(value) => {
                      setAnimalSelectValue([value]);
                      setForm({ ...form, animalTypeId: value.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>
          <BoxCard
            classNameBox="mt-3"
            title="รายการ กคร.5"
            headRight={buttonAdd()}

          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTable(listVaccinate)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Vaccinate;
