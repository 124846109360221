import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";

import { Row, Col } from "react-bootstrap";

import * as helper from "../../../utils/helper";
import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import InputText from "../../../components/InputText";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTable from "../../../components/DataTable";
import DataTableNodata from "../../../components/DataTableNodata";
import InputDatePicker from "../../../components/InputDatePicker";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useMasterData } from "../../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function Epidemic_Zone() {
  const user = useSelector((state) => state.login.result);
  const statusDisease = useSelector((state) => state.statusDisease);
  const dispatch = useDispatch();
  const history = useHistory();

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/banners-relations",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/web-settings/news", name: "ตั้งค่าเวปไซต์" },
    { path: "active", name: "ประกาศเขตโรคระบาด" },
  ];

  const initialForm = {
    year: "",
    dateBegin: "",
    dateEnd: "",
    annouceTypeId: "",
    orgId: "",
    annouceCode: "",
    animalTypeId: "",
    diseaseTypeId: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    impression: "",
    data_file: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
        width: "50px",
      },
      {
        sortBy: "orgName",
        name: "หน่วยงานที่ประกาศ",
        sortFlg: true,
      },
      {
        sortBy: "annouceTypeName",
        name: "ประกาศ",
        sortFlg: false,
      },

      {
        sortBy: "animalTypeName",
        name: "ชนิดสัตว์",
        sortFlg: true,
      },
      {
        sortBy: "diseaseTypeName",
        name: "ชนิดโรคระบาด",
        sortFlg: true,
      },
      {
        sortBy: "startDate",
        name: "วันที่ประกาศ",
        sortFlg: true,
      },
      {
        sortBy: "stopDate",
        name: "วันที่สิ้นสุดประกาศ",
        sortFlg: true,
      },
      {
        sortBy: "statusId",
        name: "การแสดงผล",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listAnnouceZone, setListAnnouceZone] = useState([]);
  const [isClearSearch, setIsClearSearch] = useState(false);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [annouceTypeIdSelectValue, setAnnouceTypeIdSelectValue] = useState([]);
  const [annouceTypeIdList, setAnnouceTypeIdList] = useState([]);

  const [yearSelectValue, setYearSelectValue] = useState([]);
  const [yearList, setYearList] = useState([]);

  const [diseaseTypeIdSelectValue, setDiseaseTypeIdSelectValue] = useState([]);
  const [diseaseTypeIdList, setDiseaseTypeIdList] = useState([]);

  const [impressionSelectValue, setImpressionSelectValue] = useState([]);
  const selectImpressionList = [
    { value: 1, label: "แสดงผล" },
    { value: 2, label: "ไม่แสดงผล" },
  ];
  const [impressionList, setImpressionList] = useState(selectImpressionList);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  useMasterData("province", provinceList, setProvinceList);

  // =================

  useEffect(() => {
    getProvince();
    getAnimal();
    getDiseaseType();
    getAnnouce();
    getAnnouceYear();
  }, []);

  useEffect(() => {
    getAnnouceEpidemicZone();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType("", "", 1, 100, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseaseTypeIdList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnnouce = async () => {
    try {
      const response = await API.getAnnouce("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let annouceArray = [];
        data.content.forEach((element) => {
          annouceArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnnouceTypeIdList(annouceArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnnouceYear = async () => {
    try {
      const response = await API.getAnnouceYear();
      const data = await response?.data;
      if (response.status === 200) {
        let yearArray = [];
        data.forEach((element) => {
          yearArray.push({
            value: element,
            label: element,
          });
        });
        setYearList(yearArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, districtId: value.value });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value });
  };

  const getAnnouceEpidemicZone = async () => {
    const params = {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",

      year: form.year ? form.year : "",
      dateBegin: form.dateBegin ? helper.getDate(form.dateBegin) : "",
      dateEnd: form.dateEnd ? helper.getDate(form.dateEnd) : "",
      annouceTypeId: form.annouceTypeId ? form.annouceTypeId : "",
      orgId: form.orgId ? form.orgId : "",
      annouceCode: form.annouceCode ? form.annouceCode : "",
      animalTypeId: form.animalTypeId ? form.animalTypeId : "",
      diseaseTypeId: form.diseaseTypeId ? form.diseaseTypeId : "",
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
    };

    try {
      const response = await API.getAnnouceEpidemicZone(params);

      const data = await response?.data;
      if (response.status === 200) {
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListAnnouceZone(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setAnimalSelectValue([]);
    setAnnouceTypeIdSelectValue([]);
    setYearSelectValue([]);
    setDiseaseTypeIdSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setImpressionSelectValue([]);
    setDistrictList([]);
    setSubDistrictList([]);
    setIsClearSearch(!isClearSearch);
  };
  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const delAnnouce = (id) => {};

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const getEpidemicById = async (id, statusViewId) => {
    let dataRes;
    try {
      const response = await API.getEpidemicById(id);
      if (response.status === 200) {
        addSaveEpdemic(response.data, statusViewId);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const addSaveEpdemic = (data, statusViewId) => {
    let dataOutbreak = [];
    let outBreak = data.annouceOutbreaks;
    if (outBreak && outBreak.length > 0) {
      outBreak.map((item, i) => {
        dataOutbreak.push({
          id: item.id,
          obId: item.obId,
        });
      });
    }

    let data_annouceArea = [];
    let annouceArea = data.annouceArea;
    if (annouceArea && annouceArea.length > 0) {
      annouceArea.map((item, i) => {
        data_annouceArea.push({
          id: item.id,
          provinceId: item.provinceId,
          amphurId: item.amphurId,
          tambonId: item.tambonId,
          mooId: item.mooId,
          latitude: item.latitude,
          longitude: item.longitude,
          radius: item.radius,
        });
      });
    }

    let data_animal = [];
    let annouceAnimals = data.annouceAnimals;
    if (annouceAnimals && annouceAnimals.length > 0) {
      annouceAnimals.map((item, i) => {
        data_animal.push({
          id: item.id,
          animalTypeId: item.animalTypeId,
        });
      });
    }

    let data_annouceFiles = [];
    let annouceFiles = data.annouceFiles;
    if (annouceFiles && annouceFiles.length > 0) {
      annouceFiles.map((item, i) => {
        data_annouceFiles.push({
          id: item.id,
          name: item.fileName,
          data: item.file,
          description: item.detail_file,
        });
      });
    }

    const dataEpid = {
      outbreak: {
        obCode: data.outbreak.obCode,
      },
      fm01: {
        caseCode: data.fm01.caseCode,
        address: data.fm01.address,
        amphurId: data.fm01.amphurId,
        animalAround5km: data.fm01.animalAround5km,
        animalTypeId: data.fm01.animalTypeId,
        diseaseTypeId: data.fm01.diseaseTypeId,
        dlaId: data.fm01.dlaId,
        dlaName: data.fm01.dlaNam,
        farmId: data.fm01.farmId,
        firstAnimSymptomDate: data.fm01.firstAnimSymptomDate,
        foundDiseaseDate: data.fm01.foundDiseaseDate,
        humanDeadAmt: data.fm01.humanDeadAmt,
        humanSickAmt: data.fm01.humanSickAmt,
        humanTouchAmt: data.fm01.humanTouchAmt,
        informBy: data.fm01.informBy,
        informOther: data.fm01.informOther,
        informId: data.informId,
        mooId: data.fm01.mooId,
        obId: data.fm01.obId,
        orgId: data.fm01.orgId,
        ownerName: data.fm01.ownerName,
        ownerTel: data.fm01.ownerTel,
        provinceId: data.fm01.provinceId,
        statusId: data.fm01.statusId,
        symptomId: data.fm01.symptomId,
        tambonId: data.fm01.tambonId,
      },
      annouce: {
        id: data.annouce.id,
        statusId: data.annouce.statusId,
        annouceTypeId: data.annouce.annouceTypeId,
        startDate: data.annouce.startDate,
        stopDate: data.annouce.stopDate,
        diseaseTypeId: data.annouce.diseaseTypeId,
        orgId: data.annouce.orgId,
        annouceName: data.annouce.annouceName,
        annoucePosition: data.annouce.annoucePosition,
        annouceCode: data.annouce.annouceCode,
        statusView: statusViewId,
      },
      annouceOutbreaks: dataOutbreak,
      deleteOutbreaks: [],
      annouceAnimals: data_animal,
      deleteAnimals: [],
      annouceArea: data_annouceArea,
      deleteArea: [],
      annouceFiles: data_annouceFiles,
      deleteFiles: [],
    };

    MySwal.fire({
      text: "ต้องการเปลี่ยนการแสดงผล",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.addEpidemicZone(dataEpid);
          if (response.status === 200) {
            await getAnnouceEpidemicZone();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusDisease && statusDisease.length > 0) {
      statusDisease.map((item, index) => {
        if (item.id === idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color, color: item.fontColor }}
                className="box-status font14 px-3 text-center"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td>
            {item.orgName ? item.orgName : "-"}
            <p className="font14 mb-0">({item.obCode})</p>
          </td>
          <td>
            {item.annouceTypeName ? item.annouceTypeName : "-"}
            <p className="font14 mb-0">
              ชื่อผู้ประกาศ : {item.annouceName ? item.annouceName : "-"}
            </p>
            <p className="font14 mb-0">
              ตำแหน่งผู้ประกาศ :{" "}
              {item.annoucePosition ? item.annoucePosition : "-"}
            </p>
          </td>
          <td>{item.animalTypeName ? item.animalTypeName : "-"}</td>
          <td>{item.diseaseTypeName ? item.diseaseTypeName : "-"}</td>
          <td className="text-center">
            {item.startDate
              ? helper.momentDate(item.startDate, "th", "short")
              : "-"}
            {item.statusId ? getStatusColor(item.statusId) : "-"}
          </td>
          <td>
            {item.stopDate
              ? helper.momentDate(item.stopDate, "th", "short")
              : "-"}
          </td>
          <td>
            {item.statusView == 1 ? (
              <div className="d-flex justify-content-center">
                <button className="btn btn-show mr-2" disabled>
                  แสดง
                </button>
                <button
                  className="btn btn-none"
                  onClick={() => getEpidemicById(item.id, 2)}
                >
                  ไม่แสดง
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-none mr-2"
                  onClick={() => getEpidemicById(item.id, 1)}
                >
                  แสดง
                </button>
                <button className="btn btn-notshow" disabled>
                  ไม่แสดง
                </button>
              </div>
            )}
          </td>
          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() =>
                  history.push(`/passive_surv/epidemic_zone/edit/${item.id}`)
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delAnnouce(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={9} />;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ปีงบประมาณ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={yearSelectValue}
                  optionsList={yearList}
                  handleChange={(value) => {
                    setYearSelectValue([value]);
                    setForm({ ...form, year: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="วันที่ประกาศ"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateBegin}
                  star={false}
                  maxDate={true}
                  handleChange={(value) =>
                    setForm({ ...form, dateBegin: value })
                  }
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateEnd}
                  // maxDate={form.dateBegin}
                  minDate={form.dateBegin}
                  maxDate={"afterSelectDate"}
                  star={false}
                  handleChange={(value) => setForm({ ...form, dateEnd: value })}
                />
              </Col>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ประเภทประกาศ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={annouceTypeIdSelectValue}
                  optionsList={annouceTypeIdList}
                  handleChange={(value) => {
                    setAnnouceTypeIdSelectValue([value]);
                    setForm({ ...form, annouceTypeId: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputText
                  type="text"
                  title="หน่วยงานที่ประกาศ"
                  idName="orgId"
                  value={form.orgId}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) =>
                    setForm({ ...form, orgId: value, checkShow: true })
                  }
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputText
                  type="text"
                  title="เลขที่หนังสือ"
                  idName="annouceCode"
                  value={form.annouceCode}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) =>
                    setForm({ ...form, annouceCode: value, checkShow: true })
                  }
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ชนิดสัตว์"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={animalSelectValue}
                  optionsList={animalList}
                  handleChange={(value) => {
                    setAnimalSelectValue([value]);
                    setForm({ ...form, animalTypeId: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ชนิดโรคสัตว์"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={diseaseTypeIdSelectValue}
                  optionsList={diseaseTypeIdList}
                  handleChange={(value) => {
                    setDiseaseTypeIdSelectValue([value]);
                    setForm({ ...form, diseaseTypeId: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="จังหวัด"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="province"
                  classLabel="normal"
                  selectValue={provinceSelectValue}
                  optionsList={provinceList}
                  handleChange={(value) => {
                    selectValueProvince(value);
                    setForm({ ...form, provinceId: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="อำเภอ/เขต"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={districtSelectValue}
                  optionsList={districtList}
                  handleChange={(value) => {
                    selectValueDistrict(value);
                    setForm({ ...form, districtId: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ตำบล/แขวง"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="subDistrict"
                  classLabel="normal"
                  selectValue={subDistrictSelectValue}
                  optionsList={subDistrictList}
                  handleChange={(value) => {
                    selectValueSubDistrict(value);
                    setForm({ ...form, subDistrictId: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="การแสดงผล"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={impressionSelectValue}
                  optionsList={impressionList}
                  handleChange={(value) => {
                    setImpressionSelectValue([value]);
                    setForm({ ...form, impression: value.value });
                  }}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard title="รายการประกาศเขตโรคระบาด" classNameBox="mt-4">
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTable(listAnnouceZone)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Epidemic_Zone;
