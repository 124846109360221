import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InputText from "./InputText";
import InputDatePicker from "./InputDatePicker";
import BoxCard from "./BoxCard";
import { Row, Col, Form } from "react-bootstrap";

function DiseaseInvest_Step4({ save, dataAll, modeGet }) {
  const user = useSelector((state) => state.login.result);
  const initialForm = {
    report_from: "n",
    verify: "n",
    day_last_time: "",
    day_calm_disease: "",
    number: "",
    disease: "",
  };
  const [form, setForm] = useState(initialForm);

  useEffect(async () => {
    await toSave();
  }, [form]);

  useEffect(async () => {
    if (modeGet == "getById") {
      await getDataById();
    }
  }, [user]);

  const getDataById = () => {
    setForm(dataAll);
  };

  const toSave = () => {
    save(form);
  };

  return (
    <div className=" bg-white p-4 ">
      <BoxCard title="4.1 สัตว์ฝูงที่เกิดโรคเคยมีการระบาดของโรคนี้มาก่อนหรือไม">
        <Row>
          <Col>
            <Row>
              <Col lg={2} md={2} sm={6} className="mt-3">
                <div className="">
                  <Form.Check
                    inline
                    type="radio"
                    name="report_from"
                    id="isreport_from"
                    value={"n"}
                    checked={form.report_from == "n"}
                    label="ไม่เคย"
                    onChange={(e) => {
                      setForm({ ...form, report_from: e.target.value });
                      toSave();
                    }}
                  />
                </div>
                <div>
                  <Form.Check
                    inline
                    type="radio"
                    name="report_from"
                    id="notreport_from"
                    value={"y"}
                    checked={form.report_from == "y"}
                    label="เคยเกิดโรคมาก่อน"
                    onChange={(e) => {
                      setForm({ ...form, report_from: e.target.value });
                      toSave();
                    }}
                  />
                </div>
              </Col>
              <Col lg={10} md={12} sm={12} className="w-100">
                <Row className="mt-4">
                  <Col lg={6} md={8} sm={6}>
                    <div className="d-flex align-items-center">
                      <p className="mt-3 mr-2">เกิดโรคครั้งสุดท้ายเมื่อ </p>
                      <InputDatePicker
                        classFormGroup="mb-0"
                        classLabel="normal"
                        placeholder="วัน/เดือน/ปี"
                        value={form.day_last_time}
                        star={false}
                        disabled={form.report_from == "y" ? false : true}
                        handleChange={(value) => {
                          setForm({ ...form, day_last_time: value });
                          toSave();
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={8} sm={6}>
                    <div className="d-flex align-items-center">
                      <p className="mt-3 mr-2">วันที่โรคสงบ </p>
                      <InputDatePicker
                        classFormGroup="mb-0"
                        classLabel="normal"
                        placeholder="วัน/เดือน/ปี"
                        value={form.day_calm_disease}
                        star={false}
                        disabled={form.report_from == "y" ? false : true}
                        handleChange={(value) => {
                          setForm({ ...form, day_calm_disease: value });
                          toSave();
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12} sm={12} className="w-100">
                    <p className="mt-3 mb-0">
                      มีการตรวจยืนยันทางห้องปฏิบัติการหรือไม่
                    </p>
                  </Col>
                  <Col lg={12} md={12} sm={6} className="mt-3">
                    <div className="">
                      <Form.Check
                        inline
                        type="radio"
                        name="verify"
                        id="isverify"
                        value={"n"}
                        checked={
                          !form.report_from || form.report_from === "n"
                            ? ""
                            : form.verify === "n"
                        }
                        label="ไม่มี"
                        onChange={(e) => {
                          setForm({ ...form, verify: e.target.value });
                          toSave();
                        }}
                        disabled={!form.report_from || form.report_from === "n"}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={2} md={2} sm={6} className="mt-3">
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        name="verify"
                        id="notverify"
                        value={"y"}
                        checked={form.verify == "y"}
                        label="มี"
                        onChange={(e) => {
                          setForm({ ...form, verify: e.target.value });
                          toSave();
                        }}
                        disabled={!form.report_from || form.report_from === "n"}
                      />
                    </div>
                  </Col>
                  <Col lg={10} md={10} sm={12}>
                    <Row className=" mt-2">
                      <Col lg={6} md={8} sm={6}>
                        <div className="d-flex align-items-center">
                          <p className=" mr-2">ระบุชนิดโรค </p>
                          <InputText
                            type="text"
                            title=""
                            idName="disease"
                            value={form.disease}
                            star={false}
                            disabled={form.verify == "y" ? false : true}
                            placeholder="ระบุ"
                            classLabel="normal"
                            handleChange={(value) => {
                              setForm({ ...form, disease: value });
                              toSave();
                            }}
                          />
                        </div>
                      </Col>

                      <Col lg={6} md={8} sm={6}>
                        <div className="d-flex align-items-center">
                          <p className="mr-2">เลขที่ตัวอย่าง </p>
                          <InputText
                            type="text"
                            title=""
                            idName="number"
                            value={form.number}
                            star={false}
                            disabled={form.verify == "y" ? false : true}
                            placeholder="ระบุ"
                            classLabel="normal"
                            handleChange={(value) => {
                              setForm({ ...form, number: value });
                              toSave();
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </BoxCard>
    </div>
  );
}

export default DiseaseInvest_Step4;
