import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as helper from "../utils/helper";
import * as API from "../utils/apis";
import { Modal, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import withReactContent from 'sweetalert2-react-content';
import { useTranslation } from 'react-i18next';
import BoxCard from "./BoxCard"
import InputDatePicker from "./InputDatePicker";
import { logout } from "../redux/actions/logoutAction";
import { loading } from "../redux/actions/loadingAction";
import ModalInstructions from "./Modal.Instructions";
import styled from 'styled-components'
import Swal from 'sweetalert2';
const MySwal = withReactContent(Swal)

const Label = styled.div`
    background: #00AF50;
    border-radius: 4px;
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    padding: 2px 7px 4px 6px;
`


function ModalAssessmen({ show, close, save, mode, idAssess }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.login.result)
    const { t } = useTranslation(["common", "footer"]);

    const initialForm = {
        farmName: "",
        assessment_date: "",
        score: "",
        modeSee: "",
        idSee: "",
        fileUrl: "",
        provinceName: "",
        amphurName: "",
        tambonName: "",
        homeNo: "",
        mooName: "",
        resultData: [],

        checkShow: false,

        statusCheckId: [
            {
                id: 0,
                color: "#00AF50",
                status: "ไม่มีความเสี่ยง"
            },
            {
                id: 1,
                color: "#70AD46",
                status: "ต่ำมาก"
            },

            {
                id: 2,
                color: "#25B881",
                status: "ต่ำ"
            },

            {
                id: 3,
                color: "#FFC107",
                status: "ปานกลาง"
            },

            {
                id: 4,
                color: "#ED7D31",
                status: "สูง"
            },

            {
                id: 5,
                color: "#FE0000",
                status: "สูงมาก"
            },

        ]


    };
    const [form, setForm] = useState(initialForm);

    const initShowInstructions = false;
    const [showInstructions, setShowInstructions] = useState(initShowInstructions)

    const downloadImage = () => {
        // window.open(form.fileUrl, '_blank').focus();
        MySwal.fire({
            text: `ต้องการดาวน์โหลดภาพผลการประเมิน`,
            icon: "question",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: "ยกเลิก",
            confirmButtonText: "ตกลง",
        }).then(async (result) => {
            if (result.value) {
                window.open(form.fileUrl, '_blank').focus();
            }
        })
    }

    useEffect(async () => {
        if (mode === "edit" && !form.checkShow) {
            await getRiskLevelById(idAssess)
        }
    }, [mode, idAssess, show, form.farmName, form.assessment_date, form.score])

    const getRiskLevelById = async (id) => {
        let dataById;
        try {
            const response = await API.getRiskLevelById(id);
            const data = await response.data;
            if (response.status === 200) {
                dataById = data;
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }))

            }
            console.log(error)
        }

        let resultArray = []
        if (dataById.risk && dataById.risk.length > 0) {
            dataById.risk.map((item, i) => {
                resultArray.push({
                    id: item.id,
                    diseaseTypeId: item.diseaseTypeId,
                    diseaseTypeName: item.diseaseTypeName,
                    score: item.score,
                    riskLevel: item.riskLevel,
                    levelName: item.levelName,
                    adviceFarm: item.adviceFarm,
                    adviceBiological: item.adviceBiological

                })
            })
        }


        setForm({
            ...form,
            farmName: dataById.farmName,
            assessment_date: dataById.updateDate,
            provinceName: dataById.provinceName,
            amphurName: dataById.amphurName,
            tambonName: dataById.tambonName,
            homeNo: dataById.homeNo,
            mooName: dataById.mooName,
            score: dataById.score,
            fileUrl: dataById.fileUrl,
            resultData: resultArray,
        })
        // console.log("form7", form);
    }

    const getStatusColor = (idStatus) => {
        let statusColor;
        if (form.statusCheckId && form.statusCheckId.length > 0) {
            form.statusCheckId.map((item, index) => {
                if (item.id == idStatus) {
                    statusColor = (
                        <div className="d-flex justify-content-center">
                            <div style={{ background: item.color }} className="box-status3 font14 px-4 text-center text-white"><span>{item.status}</span></div>
                        </div>
                    )
                }
            })
        }
        return statusColor;
    }



    const handleClose = () => {
        close()
        setForm(initialForm)
    }

    const instructions = (id) => {
        setForm({ ...form, modeSee: "edit", idSee: id })
        setShowInstructions(true)
    }


    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => { handleClose() }}
            centered
        >
            <Modal.Header closeButton className="pb-2 underline">
                <Modal.Title className="mt-2">
                    <h5><strong>ผลการประเมิน</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <Row >
                    <Col lg={3} md={3} sm={12} xs={12}>
                        <InputText
                            title="ชื่อฟาร์ม"
                            type="text"
                            idName="farmName"
                            value={form.farmName}
                            star={false}
                            disabled
                            placeholder="โปรดระบุ"
                            classLabel="normal"
                            handleChange={(value) =>
                                setForm({ ...form, farmName: value })
                            }
                        />
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <InputDatePicker
                            title="วันที่ประเมิน"
                            classLabel="normal"
                            placeholder="วัน/เดือน/ปี"
                            value={form.assessment_date}
                            star={false}
                            disabled
                            handleChange={(value) =>
                                setForm({ ...form, assessment_date: value })
                            }

                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <div className="d-flex align-items-center ">
                            <p className="mr-2">ที่อยู่:</p>
                            <p>บ้านเลขที่ {form.homeNo} หมู่ที่ {form.homeNo} ต.{form.tambonName} อ.{form.amphurName} จ.{form.provinceName}</p>
                        </div>
                    </Col>
                </Row>
                <BoxCard title="ผลการประเมิน" classNameBox="mt-3">
                    {form.resultData.length > 0 && form.resultData.map((item, index) => {
                        return < Row >

                            <Col lg={3} md={3} sm={12}>
                                <div className="d-flex align-items-center mt-3">
                                    <p>{item.diseaseTypeName}</p>
                                </div>
                            </Col>
                            <Col lg={9} md={9} sm={12}>
                                <div className="d-flex align-items-center flex-wrap mt-2">
                                    <div className="d-flex ml-3">
                                        <p className="mt-2 mr-2">คะแนน</p>
                                        <InputText
                                            disabled
                                            type="number"
                                            title=""
                                            idName="score"
                                            value={item.score || 0}
                                            star={false}
                                            classLabel="normal"
                                        />
                                    </div>
                                </div>

                                <div className="d-flex align-items-center flex-wrap mt-2 ml-3 ">
                                    <div className="d-flex flex-wrap mr-3">
                                        <h6 className="mr-3  mt-2">ระดับความเสี่ยง</h6>
                                        <div className="d-flex align-items-center ">
                                            {item.riskLevel ? getStatusColor(item.riskLevel) : <Label>{item.levelName}</Label>}
                                        </div>
                                    </div>
                                    {item.riskLevel >= 3 ?
                                        <div className="d-flex flex-wrap ">
                                            <div className="d-flex">
                                                <button className="btn btn-darkblue mr-2 " onClick={() => instructions()}>
                                                    ดูคำแนะนำ
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }

                                </div>

                            </Col>
                        </Row>

                    })
                    }

                </BoxCard>

                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-download " onClick={() => { downloadImage() }} disabled={user?.userGroup.mexport === "n"}>
                            <img className="mr-2 mb-1 " src={"/svg/download.svg"} alt="menu-icon" />
                            ดาวน์โหลดภาพผลการประเมิน
                        </button>
                    </div>
                </div>

            </Modal.Body>

            {showInstructions &&
                <ModalInstructions show={showInstructions} close={() => setShowInstructions(false)}
                    modeSee={form.modeSee} idSee={idAssess} />
            }


        </Modal >
    )
}


export default ModalAssessmen
