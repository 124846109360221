import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { logout } from "../../redux/actions/logoutAction";
import { useDispatch, useSelector } from "react-redux";

import * as API from "../../utils/apis";
import * as helper from "../../utils/helper";

import * as FileSaver from "file-saver";

import Breadcrumb from "../../components/Breadcrumb";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import DataTable from "../../components/DataTable";
import DataTableNodata from "../../components/DataTableNodata";
import InputDatePicker from "../../components/InputDatePicker";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function GFMSumByProvince() {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.login.result);
    const initialForm = {
        certificateCode: "",
        farmName: "",
        address: "",
        farmerName: "",
        startDateBegin: "",
        startDateEnd: "",
        endDateBegin: "",
        endDateEnd: "",
        gfmFarmerTypeId: "",
        statusId: "",
        provinceId: "",
        amphurId: "",
        tambonId: "",
        farmerType: "",

        limit: 10,
        page: 1,
        totalPages: 1,
        sortDir: "DESC",
        sortBy: "id",
        totalElements: 0,
        numberOfElements: 0,

        headTitle: [
            {
                name: "ลำดับ",
                sortFlg: false,
                width: "50px",
            },
            {
                sortBy: "certificateCode",
                name: "เลขที่ใบรับรอง",
                sortFlg: true,
            },
            {
                sortBy: "farmName",
                name: "ชื่อฟาร์ม",
                sortFlg: true,
            },
            {
                sortBy: "address",
                name: "ที่อยู่ฟาร์ม",
                sortFlg: true,
            },
            {
                sortBy: "farmerName",
                name: "เจ้าของฟาร์ม",
                sortFlg: true,
            },
            {
                sortBy: "startDate",
                name: " วันที่รับรอง",
                sortFlg: true,
            },
            {
                sortBy: "endDate",
                name: "วันที่หมดอายุ",
                sortFlg: true,
            },
            {
                sortBy: "animalTypeName",
                name: "ชนิดสัตว์",
                sortFlg: true,
            },
            {
                sortBy: "farmerTypeName",
                name: "ประเภทเกษตรกร",
                sortFlg: true,
            },
            {
                sortBy: "statusId",
                name: "สถานะ",
                sortFlg: true,
            },
            {
                name: "จัดการ",
                sortFlg: false,
            },
        ],
    };

    const [gfmList, setGfmList] = useState([]);

    const [farmerTypeSelectValue, setFarmerTypeSelectValue] = useState([]);
    const [farmerTypeList, setFarmerTypeList] = useState([]);

    const [farmerCateTypeSelectValue, setFarmerCateTypeSelectValue] = useState([]);

    const farmerCateTypeListInit = [
        { value: 1, label: "รายย่อย" },
        { value: 2, label: "รายเล็ก" },
        { value: 3, label: "รายกลาง" },
        { value: 4, label: "รายใหญ่" },
    ];

    const [farmerCateTypeList, setFarmerCateTypeList] = useState(farmerCateTypeListInit);

    // select จังหวัด ตำบล อำเภอ หมู่บ้าน
    const [provinceSelectValue, setProvinceSelectValue] = useState([]);
    const [provinceList, setProvinceList] = useState([]);

    const [districtSelectValue, setDistrictSelectValue] = useState([]);
    const [districtList, setDistrictList] = useState([]);

    const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
    const [subDistrictList, setSubDistrictList] = useState([]);

    const [form, setForm] = useState(initialForm);
    const [isClearSearch, setIsClearSearch] = useState(false);

    useMasterData("province", provinceList, setProvinceList);

    // =================

    const [statusSelectValue, setStatusSelectValue] = useState([]);
    const selectStatusList = [
        { value: 1, label: "รับรอง" },
        { value: 2, label: "ต่ออายุ" },
        { value: 3, label: "หมดอายุ" },
    ];
    const [statusList, setStatusList] = useState(selectStatusList);

    const breadcrumbPath = [
        { path: "/home", name: "หน้าหลัก" },
        { path: "/gfm", name: "GFM" },
        { path: "active", name: "รายการรับรองฟาร์ม GFM" },
    ];

    useEffect(() => {
        getProvince();
        getFarmerType();
    }, []);

    useEffect(() => {
        getGFM();
    }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

    const getGFM = async () => {
        try {
            const response = await API.getGFM(
                form.farmName,
                form.farmerName,
                form.certificateCode,
                form.startDateBegin ? helper.getDate(form.startDateBegin) : "",
                form.startDateEnd ? helper.getDate(form.startDateEnd) : "",
                form.statusId,
                form.endDateBegin ? helper.getDate(form.endDateBegin) : "",
                form.endDateEnd ? helper.getDate(form.endDateEnd) : "",
                form.gfmFarmerTypeId,
                form.gfmFarmerCateTypeId,
                form.provinceId,
                form.amphurId,
                form.tambonId,
                form.farmerType,
                form.page,
                form.limit,
                form.sortBy,
                form.sortDir
            );
            const data = await response?.data;
            if (response.status === 200) {
                let GfmArray = [];
                data.content.forEach((element) => {
                    GfmArray.push({
                        value: element.id,
                        label: element.name,
                    });
                });

                setForm({
                    ...form,
                    totalPages: data.totalPages,
                    totalElements: data.totalElements,
                    numberOfElements: data.numberOfElements,
                });
                setGfmList(data.content);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }));
            }
            console.log(error);
        }
    };

    const getFarmerType = async () => {
        try {
            const response = await API.getFarmerType("", 1);
            const data = await response?.data;
            if (response.status == 200) {
                let farmerType = [];
                data.content.forEach((element) => {
                    farmerType.push({
                        //waiting for label
                        value: element.id,
                        label: element.animalTypeName,
                    });
                });
                setFarmerTypeList(farmerType);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getProvince = async () => {
        try {
            const response = await API.getProvince("", 1);
            const data = await response?.data;
            if (response.status == 200) {
                let provincesArray = [];
                data.content.forEach((element) => {
                    provincesArray.push({
                        value: element.id,
                        label: element.name,
                    });
                });
                setProvinceList(provincesArray);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getDistricts = async (id) => {
        try {
            const response = await API.getDistricts("", id, 1);
            const data = await response?.data;
            if (response.status == 200) {
                let districtArray = [];
                data.content.forEach((element) => {
                    districtArray.push({
                        value: element.id,
                        label: element.name,
                    });
                });
                setDistrictList(districtArray);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getSubDistricts = async (id) => {
        try {
            const response = await API.getSubDistricts("", id, 1);
            const data = await response?.data;
            if (response.status == 200) {
                let subDistrictArray = [];
                data.content.forEach((element) => {
                    subDistrictArray.push({
                        value: element.id,
                        label: element.name,
                    });
                });
                setSubDistrictList(subDistrictArray);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getStatusColor = (idStatus) => {
        let colorStatus, nameStatus;
        if (idStatus) {
            if (idStatus == 1) {
                colorStatus = "#3FC071";
                nameStatus = "รับรอง";
            } else if (idStatus == 2) {
                colorStatus = "#3FC071";
                nameStatus = "ต่ออายุ";
            } else if (idStatus == 3) {
                colorStatus = "#FF482F";
                nameStatus = "หมดอายุ";
            } else {
                nameStatus = "-";
            }
            return (
                <div
                    style={{ background: colorStatus }}
                    className="box-status font14 px-2 text-center"
                >
                    <span>{nameStatus}</span>
                </div>
            );
        } else {
            return <div>-</div>;
        }
    };

    const selectValueProvince = (value) => {
        setProvinceSelectValue([{ value: value.value, label: value.label }]);
        setDistrictSelectValue([]);
        setSubDistrictSelectValue([]);
        setForm({ ...form, provinceId: value.value, chooseSelect: true });
        getDistricts(value.value);
    };

    const selectValueDistrict = (value) => {
        setDistrictSelectValue([{ value: value.value, label: value.label }]);
        setSubDistrictSelectValue([]);
        setForm({ ...form, amphurId: value.value, chooseSelect: true });
        getSubDistricts(value.value);
    };

    const selectValueSubDistrict = (value) => {
        setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
        setForm({ ...form, tambonId: value.value, chooseSelect: true });
    };

    const /* The above code is a comment in JavaScript. It is not doing anything, but it is used to
    provide information or explanations about the code to other developers. */
    toDownload = async () => {
        try {
            let params = ""

            if (form.farmName) {
                params +=  "&farm_name=" + form.farmName
            }
            if (form.farmerName) {
                params +=  "&farm_owner=" + form.farmerName
            }
            if (form.certificateCode) {
                params +=  "&cret_id=" + form.certificateCode
            }
            if (form.startDateBegin) {
                params +=  "&start_date1=" +  helper.getDate(form.startDateBegin)
            }
            if (form.startDateEnd) {
                params +=  "&start_date2=" +  helper.getDate(form.startDateEnd)
            }
            if (form.endDateBegin) {
                params +=  "&end_date1=" + helper.getDate(form.endDateBegin)
            }
            if (form.endDateEnd) {
                params +=  "&end_date2=" + helper.getDate(form.endDateEnd)
            }
            if (form.gfmFarmerTypeId != undefined && form.gfmFarmerTypeId != "") {
                params +=  "&animal_type_id=" + form.farmerName
            }
            if (form.gfmFarmerCateTypeId != undefined && form.gfmFarmerCateTypeId != "") {
                params +=  "&gfm_farmer_type_id=" + form.farmerName
            }
            if (form.provinceId) {
                params +=  "&province_id=" + form.provinceId
            }
            if (form.amphurId) {
                params +=  "&amphur_id=" + form.amphurId
            }
            if (form.tambonId) {
                params +=  "&tambon_id=" + form.tambonId
            }
            const url = "https://esmartsur.dld.go.th/jasperserver/rest_v2/reports/reports/esurv/Report_GFM_Sum_By_Province.xlsx?j_username=jasperadmin&j_password=jasperadmin" + params
            console.log(url);
            window.open(url, "_blank");
    } catch (error) {
        console.log(error);
    }
};

const toUpload = async (file) => {
    try {
        let formData = new FormData();
        formData.append("file", file);

        const response = await API.importGfmTemplate(formData);
        if (response.status == 200) {
            MySwal.fire({
                icon: "success",
                type: "success",
                confirmButtonText: "ตกลง",
                text: "นำเข้าฟอร์มสำเร็จ",
            });
        }
    } catch (error) {
        console.log(error);
    }
};

const _handleImageChange = (e) => {
    e.preventDefault();
    const fileTypeArray = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    let file = e.target.files[0];
    if (file) {
        if (fileTypeArray.includes(file.type)) {
            if (file.size < 1000000) {
                toUpload(file);
            }
        }
    }
};

const onInputClick = (e) => {
    e.target.value = "";
};

const buttonAddGFM = () => {
    return (
        <>
            <button
                className="btn btn-download text-white mr-2 fw-400 cursor-p"
                onClick={() => {
                    toDownload();
                }}
                disabled={user?.userGroup.mexport === "n"}
            >
                <img
                    className="icon-btn mr-2 mb-1"
                    src="/svg/download.svg"
                    alt="menu-icon"
                />
                ดาวน์โหลดฟอร์ม
            </button>

            <label
                className={`btn btn-report text-white mr-2 mt-2 fw-400 ${user?.userGroup.mcreate === "n" ? "disabled" : ""
                    }`}
                htmlFor="exampleFormControlFile1"
            >
                <img className="mr-2 mb-1" src={"/svg/up.svg"} alt="menu-icon" />
                นำเข้าฟอร์ม
            </label>
            <input
                type="file"
                onClick={onInputClick}
                className="form-control-file"
                id="exampleFormControlFile1"
                hidden
                disabled={user?.userGroup.mcreate === "n"}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(e) => {
                    _handleImageChange(e);
                }}
            />

            <button
                className="btn btn-info normal mr-2 fw-400 cursor-p"
                onClick={() => history.push("/gfm/save")}
                disabled={user?.userGroup.mcreate === "n"}
            >
                <i className="fas fa-plus mr-2"></i>
                เพิ่มข้อมูล
            </button>
        </>
    );
};

const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
};

const clearSearch = () => {
    setForm(initialForm);
    setFarmerCateTypeSelectValue([])
    setStatusSelectValue([]);
    setFarmerTypeSelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictList([]);
    setDistrictList([]);
    setSubDistrictSelectValue([]);
    setIsClearSearch(!isClearSearch);
};

const buttonSearch = () => {
    return (
        <>
         <button
                    className="btn  text-white mr-2 fw-400 cursor-p" style={{"background": "#20639B"}}
                    onClick={() => {
                        toDownload();
                    }}
                    disabled={user?.userGroup.mexport === "n"}
                >
                    <img
                        className="icon-btn mr-2 mb-1"
                        src="/svg/download.svg"
                        alt="menu-icon"
                    />
                    ดาวน์โหลดฟอร์ม
                </button>

            <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
                ล้างข้อมูล
            </button>

            <button className="btn btn-success" onClick={() => toSearch()}>
                ค้นหา
            </button>
        </>
    );
};

const getFilter = (type, isChange1, isChange2) => {
    if (type === "page") {
        setForm({
            ...form,
            page: isChange1,
        });
    } else if (type === "limit") {
        setForm({
            ...form,
            limit: isChange1,
            page: 1,
        });
    } else {
        setForm({
            ...form,
            sortBy: isChange1,
            sortDir: isChange2,
            page: 1,
        });
    }
};

const editGFM = (id) => {
    history.push(`/gfm/edit/${id}`);
};

const delGFM = async (id) => {
    MySwal.fire({
        text: `ยืนยันที่จะลบข้อมูล`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
    }).then(async (result) => {
        if (result.value) {
            try {
                const response = await API.deleteGFM(id);
                if (response.status === 200) {
                    await getGFM();
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    dispatch(logout({ history }));
                } else {
                    MySwal.fire({
                        text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
                        icon: "warning",
                        confirmButtonText: "ตกลง",
                    });
                }
                console.log("error", error);
            }
        }
    });
};
const alertDate = (certificateCode) => {
    MySwal.fire({
        html: (
            <>
                <img src="/svg/warning.svg" width="70" height="70"></img>
                <br /> <br />
                ใบรับรองหมายเลข <b>{certificateCode}</b> <br />
                จะหมดอายุในอีก <b className="text-danger">60</b> วัน
            </>
        ),
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
    }).then(async (result) => {
        history.push("/gfm");
    });
};

const condExpireDate = (endDate, certificateCode) => {
    let day = 1000 * 3600 * 24 * 60;
    let date = Date.now();
    if (endDate - date <= day) {
        return (
            <img
                className="icon-btn ml-2 cursor-p"
                src="/svg/warning.svg"
                onClick={() => alertDate(certificateCode)}
            />
        );
    }
};

const renderTableGFM = (data) => {
    if (data.length > 0) {
        return data.map((item, i) => (
            <tr key={i} className="text-center">
                <td>{form.limit * (form.page - 1) + (i + 1)}</td>
                <td>{item?.certificateCode || "-"}</td>
                <td>{item?.farmName || "-"}</td>
                <td>{item?.address || "-"}</td>
                <td>{item?.farmerName || "-"}</td>
                <td>{item?.startDateName || "-"}</td>
                <td>{item?.endDateName || "-"}</td>
                <td>{item?.animalTypeName || "-"}</td>
                <td>{item?.farmerTypeName || "-"}</td>
                <td>
                    <div className="d-flex justify-content-center">
                        {getStatusColor(item?.statusId) || "-"}
                        {condExpireDate(item.endDate, item.certificateCode) || ""}
                    </div>
                </td>
                <td>
                    <div className="d-flex justify-content-center ">
                        <button
                            className="btn btn-add mr-2 "
                            onClick={() => editGFM(item.id)}
                            disabled={
                                user?.userGroup.mupdate === "n" &&
                                user?.userGroup.mview === "n"
                            }
                        >
                            <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
                        </button>
                        <button
                            className="btn btn-del mr-2 "
                            onClick={() => delGFM(item.id)}
                            disabled={user?.userGroup.mdelete === "n"}
                        >
                            <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
                        </button>
                    </div>
                </td>
            </tr>
        ));
    } else {
        return <DataTableNodata colSpan={11} />;
    }
};

return (
    <div className=" ">
        <div className="pl-4 bg-white ml30 mr-auto p-2">
            <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="bg-gray-light">
            <div className="px-4 pt-3 container-fixed-footer">
                <BoxCard
                    classNameBox=""
                    title="ค้นหาข้อมูลฟาร์ม"
                    headRight={buttonSearch()}
                >
                    <Row>
                        <Col lg={4} md={4} sm={6} xs={6}>
                            <InputText
                                title="ชื่อฟาร์ม"
                                star={false}
                                placeholder="กรุณาระบุ"
                                classLabel="normal"
                                value={form.farmName}
                                handleChange={(value) =>
                                    setForm({ ...form, farmName: value })
                                }
                            />
                        </Col>

                        <Col lg={4} md={4} sm={6} xs={6}>
                            <InputText
                                title="ชื่อเจ้าของฟาร์ม"
                                star={false}
                                placeholder="กรุณาระบุ"
                                classLabel="normal"
                                value={form.farmerName}
                                handleChange={(value) =>
                                    setForm({ ...form, farmerName: value })
                                }
                            />
                        </Col>

                        <Col lg={2} md={4} sm={6} xs={6}>
                            <InputText
                                title="เลขที่ใบรับรอง"
                                star={false}
                                placeholder="กรุณาระบุ"
                                classLabel="normal"
                                value={form.certificateCode}
                                handleChange={(value) =>
                                    setForm({ ...form, certificateCode: value })
                                }
                                maxLength={13}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={2} md={4} sm={6} xs={6}>
                            <InputDatePicker
                                title="วันที่ได้รับการรับรอง"
                                classLabel="normal"
                                placeholder="วัน/เดือน/ปี"
                                star={false}
                                value={form.startDateBegin}
                                handleChange={(value) =>
                                    setForm({ ...form, startDateBegin: value })
                                }
                            />
                        </Col>

                        <Col lg={2} md={4} sm={6} xs={6}>
                            <InputDatePicker
                                title="ถึงวันที่"
                                classLabel="normal"
                                placeholder="วัน/เดือน/ปี"
                                star={false}
                                value={form.startDateEnd}
                                handleChange={(value) =>
                                    setForm({ ...form, startDateEnd: value })
                                }
                            />
                        </Col>

                        <Col lg={2} md={4} sm={6} xs={6}>
                            <InputSelect
                                title="สถานะ"
                                star={false}
                                placeholder="กรุณาเลือก"
                                idName="statusId"
                                classLabel="normal"
                                selectValue={statusSelectValue}
                                optionsList={statusList}
                                handleChange={(value) => {
                                    setForm({ ...form, statusId: value.value });
                                    setStatusSelectValue(value);
                                }}
                            />
                        </Col>

                        <Col lg={2} md={4} sm={6} xs={6}>
                            <InputDatePicker
                                title="วันที่ใบรับรองหมดอายุ"
                                classLabel="normal"
                                placeholder="วัน/เดือน/ปี"
                                star={false}
                                value={form.endDateBegin}
                                handleChange={(value) =>
                                    setForm({ ...form, endDateBegin: value })
                                }
                            />
                        </Col>

                        <Col lg={2} md={4} sm={6} xs={6}>
                            <InputDatePicker
                                title="ถึงวันที่"
                                classLabel="normal"
                                placeholder="วัน/เดือน/ปี"
                                star={false}
                                value={form.endDateEnd}
                                handleChange={(value) =>
                                    setForm({ ...form, endDateEnd: value })
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={2} md={4} sm={6} xs={6}>
                            <InputSelect
                                title="ชนิดสัตว์"
                                star={false}
                                placeholder="กรุณาเลือก"
                                idName="gfmFarmerTypeId"
                                classLabel="normal"
                                selectValue={farmerTypeSelectValue}
                                optionsList={farmerTypeList}
                                handleChange={(value) => {
                                    setFarmerTypeSelectValue([value]);
                                    setForm({ ...form, gfmFarmerTypeId: value.value });
                                }}
                            />
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={6}>
                            <InputSelect
                                title="ประเภทเกษตรกร"
                                star={false}
                                placeholder="กรุณาเลือก"
                                idName="gfmFarmerCateTypeId"
                                classLabel="normal"
                                selectValue={farmerCateTypeSelectValue}
                                optionsList={farmerCateTypeList}
                                handleChange={(value) => {
                                    setFarmerCateTypeSelectValue([value]);
                                    setForm({ ...form, farmerType: value.value });
                                }}
                            />
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={6}>
                            <InputSelect
                                title="จังหวัด"
                                star={false}
                                placeholder="กรุณาเลือก"
                                idName="provinceId"
                                classLabel="normal"
                                selectValue={provinceSelectValue}
                                optionsList={provinceList}
                                handleChange={(value) => selectValueProvince(value)}
                            />
                        </Col>

                        <Col lg={2} md={4} sm={6} xs={6}>
                            <InputSelect
                                title="อำเภอ"
                                star={false}
                                placeholder="กรุณาเลือก"
                                idName="districtId"
                                classLabel="normal"
                                selectValue={districtSelectValue}
                                optionsList={districtList}
                                handleChange={(value) => selectValueDistrict(value)}
                            />
                        </Col>

                        <Col lg={2} md={4} sm={6} xs={6}>
                            <InputSelect
                                title="ตำบล"
                                star={false}
                                placeholder="กรุณาเลือก"
                                idName="subDistrictId"
                                classLabel="normal"
                                selectValue={subDistrictSelectValue}
                                optionsList={subDistrictList}
                                handleChange={(value) => selectValueSubDistrict(value)}
                            />
                        </Col>
                    </Row>
                </BoxCard>

                {/* <BoxCard
                    title="รายการรับรองฟาร์ม GFM"
                    classNameBox="mt-4"
                    headRight={buttonAddGFM()}
                >
                    <DataTable
                        headColumns={form.headTitle}
                        totalPages={form.totalPages}
                        page={form.page}
                        limit={form.limit}
                        sortDir={form.sortDir}
                        sortBy={form.sortBy}
                        getLimit={(limit) => getFilter("limit", limit)}
                        getFilter={(sortBy, sortDir) =>
                            getFilter("filter", sortBy, sortDir)
                        }
                        getPage={(page) => getFilter("page", page)}
                        totalItems={form.totalElements}
                        numberOfItems={form.numberOfElements}
                        isClear={isClearSearch}
                    >
                        {renderTableGFM(gfmList)}
                    </DataTable>
                </BoxCard> */}
            </div>
        </div>
    </div>
);
}

export default GFMSumByProvince;
