import React from "react";
import { Carousel, Container, Row, Col, Form } from "react-bootstrap";

function BoxHead({ classNameBox, title, children, classLabel, headRight, star }) {
  return (
    <div className={`box-head ${classNameBox}`}>
      <div className="px-3 pt-3 pb-2">{children}</div>
    </div>
  );
}
export default BoxHead;
