import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as helper from "../../../utils/helper";
import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTable from "../../../components/DataTable";
import DataTableNodata from "../../../components/DataTableNodata";
import InputDatePicker from "../../../components/InputDatePicker";
import ModalContactDetails from "../../../components/Data_Management/Modal.ContactDetails";

const MySwal = withReactContent(Swal);

function Contact_Details() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);
  const history = useHistory();

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/web-settings/news", name: "ตั้งค่าเวปไซต์" },
    { path: "active", name: "ข้อมูลติดต่อสอบถาม" },
  ];

  const initialForm = {
    contactId: "",
    createDate: "",
    toDate: "",
    status: "",
    dateBegin: "",
    dateEnd: "",

    idEdit: "",
    mode: "",

    search: "",
    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
        width: "50px",
      },
      {
        sortBy: "title",
        name: "หัวข้อที่ต้องการติดต่อ",
        sortFlg: true,
      },
      {
        sortBy: "detail",
        name: "รายละเอียด",
        sortFlg: true,
      },
      {
        sortBy: "fname",
        name: "ข้อมูลการติดต่อ",
        sortFlg: false,
      },
      {
        sortBy: "updateDate",
        name: "วันที่ติดต่อสอบถาม",
        sortFlg: true,
      },

      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },

      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],

    statusCheckId: [
      {
        id: 2,
        color: "#3FC071",
        status: "รับทราบ",
      },
      {
        id: 1,
        color: "#FF482F",
        status: "ยังไม่มีการดำเนินการ",
      },
    ],
  };

  const [form, setForm] = useState(initialForm);

  const [listContact, setListContact] = useState([]);

  const [showContact, setShowContact] = useState(false);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const selectStatusList = [
    { value: 2, label: "รับทราบ" },
    { value: 1, label: "ยังไม่มีการดำเนินการ" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);
  const [isClearSearch, setIsClearSearch] = useState(false);

  useEffect(() => {
    getContact();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getContact = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getContact(
        form.dateBegin ? helper.getDate(form.dateBegin) : "",
        form.dateEnd ? helper.getDate(form.dateEnd) : "",
        form.contactId,
        form.status,
        form.page,
        form.limit,
        form.search,
        form.sortBy,
        form.sortDir
      );
      const data = await response.data;
      if (response.status == 200) {
        let contactArray = [];
        data.content.forEach((element) => {
          contactArray.push({
            value: element.id,
            label: element.name,
          });
        });

        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListContact(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (form.statusCheckId && form.statusCheckId.length > 0) {
      form.statusCheckId.map((item, index) => {
        if (item.id == idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status2 font14 px-4 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const addSaveContact = (data) => {
    const dataContact = {
      fname: data.contact.fname,
      lname: data.contact.lname,
      tel: data.contact.phone,
      email: data.contact.email,
      title: data.contact.data_contact,
      detail: data.contact.detail_contact,
      statusId: data.contact.status,
    };

    MySwal.fire({
      text: `ต้องการบันทึก `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      try {
        const response = await API.updateContact(
          data.contact.contact_id,
          dataContact
        );
        if (response.status == 200) {
          await getContact();
        }
      } catch (error) {
        dispatch(loading(false));
        if (error.response && error.response.status == 401) {
          dispatch(logout({ history }));
        } else {
          MySwal.fire({
            text: "บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
            icon: "warning",
            confirmButtonText: "ตกลง",
          });
        }
        console.log("error", error);
      }
    });
  };

  const delContact = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบ `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteContact(id);
          if (response.status == 200) {
            await getContact();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setStatusSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const editModalContact = (id) => {
    setForm({ ...form, mode: "edit", idEdit: id });
    setShowContact(true);
  };

  const renderTableContact = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i}>
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item.title}</td>
          <td>{item.detail}</td>
          <td>
            <p className="font14 mb-0">
              ชื่อผู้ประกาศ : {`${item.fname} ${item.lname}`}{" "}
            </p>
            <p className="font14 mb-0">
              หมายเลขโทรศัพท์ : {item.tel ? item.tel : "-"}
            </p>
            <p className="font14 mb-0">
              อีเมล์ : {item.email ? item.email : "-"}
            </p>
          </td>
          <td>
            <p className="font14 mb-0">
              วันที่ :{" "}
              {item.updateDate
                ? helper.momentDate(item.updateDate, "th", "short")
                : "-"}
            </p>
            <p className="font14 mb-0">
              เวลา :{" "}
              {item.updateDate ? helper.getTime(item.updateDate, "th") : "-"}
            </p>
          </td>
          <td className="text-center">
            {item.statusId ? getStatusColor(item.statusId) : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editModalContact(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delContact(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={7} />;
    }
  };
  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="สถานะ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleChange={(value) => {
                    setStatusSelectValue([value]);
                    setForm({ ...form, status: value.value, checkShow: true });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="วันที่สร้างเนื้อหา"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateBegin}
                  star={false}
                  handleChange={(value) =>
                    setForm({ ...form, dateBegin: value })
                  }
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateEnd}
                  star={false}
                  handleChange={(value) => setForm({ ...form, dateEnd: value })}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard title="รายการข้อมูลติดต่อสอบถาม" classNameBox="mt-4">
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTableContact(listContact)}
            </DataTable>
          </BoxCard>
          {showContact && (
            <ModalContactDetails
              show={showContact}
              close={() => setShowContact(false)}
              save={(value) => addSaveContact(value)}
              mode={form.mode}
              idEdit={form.idEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default Contact_Details;
