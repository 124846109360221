import React, { useState, useEffect } from "react";
import * as API from "../utils/apis";
import { useSelector } from 'react-redux';
import BoxCard from "./BoxCard";
import InputSelect from "./InputSelect";
import InputText from "./InputText";
import { Row, Col } from "react-bootstrap";

function DiseaseInvest_Step5({ save, dataAll, modeGet }) {
    const user = useSelector(state => state.login.result)

    const initialForm = {
        data_analyse: modeGet == "default" ? [
            {
                titleId: "",
                titleName: "",
                detail: "",
                analyseSelectValue: [],
                analyseList: []
            }]
            : [],
        del_analyse: [],
        checkForm: false,
    };
    const [form, setForm] = useState(initialForm);


    useEffect(async () => {
        await toSave()
    }, [form])

    useEffect(async () => {
        if (modeGet == "getById") {
            await getDataById();
        }
        if (modeGet == "default") {
            await pushToDefault();
        }
    }, [user, modeGet])

    const toSave = () => {
        save(form)
    }

    const getAnalyse = async (index, analyseId) => {
        try {
            const response = await API.getAnalyse(1);
            const data = await response.data;

            if (response.status == 200) {
                let analyseArray = [];
                data.content.forEach(element => {
                    analyseArray.push({
                        value: element.id, label: element.name,
                    });
                });

                if (index >= 0 && analyseArray.length > 0) {
                    form.data_analyse[index].analyseList = analyseArray;
                    if (analyseId) {
                        analyseArray.filter((itemA) => {
                            if (itemA.value == analyseId) {
                                form.data_analyse[index].analyseSelectValue = itemA;
                            }
                        });
                    }
                }

                setForm({ ...form })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const pushToDefault = async () => {
        if (modeGet == "default") {
            form.data_analyse.filter(async (item, i) => {
                await getAnalyse(i)
            })
        }
    }

    const getDataById = () => {
        if (dataAll && dataAll.step5_analyse && dataAll.step5_analyse.length > 0) {
            dataAll.step5_analyse.map(async (item, i) => {
                let titleName;
                form.data_analyse.push({
                    id: item.id,
                    titleId: item.analyseId,
                    titleName: titleName,
                    detail: item.description,
                    analyseSelectValue: [],
                    analyseList: []
                })
                await getAnalyse(i, item.analyseId)
            })
            setForm({ ...form, data_analyse: form.data_analyse })
        }
    }


    const toAddData = async () => {
        await form.data_analyse.push({
            titleId: "",
            titleName: "",
            detail: "",
            analyseSelectValue: [],
            analyseList: []
        });

        setForm({ ...form, data_analyse: form.data_analyse })

        form.data_analyse.filter(async (item, i) => {
            await getAnalyse(i)
        })
    }

    const removeDataAnalyse = (i, item) => {
        let delAnalyse = []
        if (form.data_analyse && form.data_analyse.length > 0) {
            form.data_analyse.splice(i, 1);
            if (item.id) {
                delAnalyse.push(item.id)
            }
            setForm({ ...form, data_analyse: form.data_analyse, del_analyse: delAnalyse })
        }
    }

    const buttonAddData = () => {
        return (
            <div className="d-flex justify-content-end ">
                <button className="btn btn-add text-white" onClick={() => toAddData()}
                >
                    <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
                </button>
            </div>
        );
    };

    return (
        <div className=" bg-white p-4 ">
            <BoxCard title="5. วิเคราะห์ข้อมูลการสอบสวนโรคข้างต้น" classNameBox="" headRight={buttonAddData()}>
                <div>
                    <p className="font14">เมื่อวิเคราะห์ข้อมูลการสอบสวนโรคข้างต้น รวมทั้งพยานหลักฐานทั้งที่เป็นพยานเอกสารและพยานบุคคล แล้วมีความเห็นว่าการเกิดโรคระบาดครั้งนี้ คาดว่ามีสาเหตุหลักและปัจจัยเสริมในการเกิดโรคจาก</p>
                </div>

                {form.data_analyse && form.data_analyse.length > 0 ?
                    form.data_analyse.map((item, i) => (
                        <Col key={i} lg={12} md={12} sm={12} className="border mt-3">
                            <Row>
                                <Col lg={2} md={2} sm={2} xs={12} className="d-flex align-items-center pr-0">
                                    <p className="pt-sm-1 pt-md-3 pt-lg-3 ">ข้อ{i + 1}</p>
                                </Col>
                                <Col lg={3} md={3} sm={3} xs={3} className="d-flex align-items-center">
                                    <div className="pt-sm-1 pt-md-3 pt-lg-3  w-100">
                                        <InputSelect
                                            title="" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                            selectValue={form.data_analyse[i].analyseSelectValue}
                                            optionsList={form.data_analyse[i].analyseList}
                                            handleChange={(value) => {
                                                form.data_analyse[i].analyseSelectValue = value;
                                                form.data_analyse[i].titleId = value.value;
                                                form.data_analyse[i].titleName = value.label;
                                                setForm({ ...form })
                                                toSave()
                                            }}
                                        />
                                    </div>
                                </Col>

                                <Col lg={7} md={7} sm={7} xs={7} className="d-flex justify-content-end ">
                                    <div className="pt-sm-1 pt-md-3 pt-lg-3 ">
                                        <button className="btn btn-del " onClick={() => removeDataAnalyse(i, item)}>
                                            <img className="img-fluid icon-btn " src="/svg/btn-del.svg" alt="del" />
                                        </button>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={2} md={2} sm={12} className="w-100">
                                    <p className="pt-sm-1 pt-md-3 pt-lg-">คำอธิบาย</p>
                                </Col>
                                <Col lg={10} md={10} sm={12} className="d-flex align-items-center">
                                    <div className="pt-sm-1 pt-md-3 pt-lg-3 w-100">
                                        <InputText type="text" as="textarea" rows={6} title="" idName="opinion"
                                            star={false}
                                            value={form.data_analyse[i].detail}
                                            placeholder="โปรดระบุ" classLabel="normal"
                                            handleChange={(value) => {
                                                form.data_analyse[i].detail = value;
                                                setForm({ ...form })
                                                toSave()
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ))
                    : <Col lg={12} md={12} sm={12} className="border text-center font14 mt-3 py-3">ยังไม่มีการวิเคราะห์ข้อมูล กรุณาเพิ่มข้อมูล</Col>
                }
            </BoxCard>
        </div>
    )
}

export default DiseaseInvest_Step5
