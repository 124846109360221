import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import InputDatePicker from "../InputDatePicker";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";

import UploadFileArray from "../../components/UploadFileArray";
import ReactQuill from 'react-quill'

import '../../assets/css/style.css'
import 'react-quill/dist/quill.snow.css'


function ModalNews({ show, close, save, mode, idEdit }) {
    const user = useSelector(state => state.login.result);
    const initialForm = {
        news_id: "",
        description: "",
        publishDate: "",
        title: "",
        newCategoryId: "",
        todate: "",
        status: "",

        createDate: "",
        updateDate: "",
        createByName: "",
        updateByName: "",

        checkShow: false,

        filesPreview: [],
        filesDelete: [], //ลบในหน้า ui
        filesChooseDel: [], //ลบเพื่อส่งไป api
    };
    const initInvalidMsg = {
        status: "",
        description: "",
    };
    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];
    const initStatusSelectValue = [];
    const initCategoriesIdSelectValue = [];

    const dispatch = useDispatch();
    const history = useHistory();

    const [form, setForm] = useState(initialForm);
    const [invalid, setInvalid] = useState(initInvalidMsg);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [categoriesIdSelectValue, setCategoriesIdSelectValue] = useState(initCategoriesIdSelectValue)
    const selectCategoriesIdList = [];
    const [categoriesIdList, setCategoriesList] = useState(selectCategoriesIdList)
    const [statusSelectValue, setStatusSelectValue] = useState(initStatusSelectValue);

    const [statusList, setStatusList] = useState(selectStatusList);

    useEffect(async () => {
        if (mode === "add") {
            setForm(initialForm)
            setStatusSelectValue([])
        }
    }, [mode])

    useEffect(async () => {
        await getNewsCategories()
    }, [])

    useEffect(async () => {
        if (mode === "edit" && !form.checkShow) {
            await getNewsById(idEdit)
        }
    }, [mode, idEdit, show, form.title, form.newCategoryId, form.description, form.publishDate, form.status, form.createDate])

    const getNewsById = async (id) => {
        try {
            const response = await API.getNewsById(id);
            const dataRes = await response.data;
            if (response.status === 200) {
                if (dataRes) {
                    getDataToForm(dataRes)
                }
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }))

            }
            console.log(error)
        }
    }

    const getDataToForm = async (dataNews) => {

        if (dataNews.news.newCategoryId) {
            let newCategorySelect = await categoriesIdList.find(item => item.value === dataNews.news.newCategoryId);
            setCategoriesIdSelectValue(newCategorySelect);
        }

        if (dataNews.news.statusId) {
            let statusSelect = await statusList.find(item => item.value === dataNews.news.statusId);
            setStatusSelectValue(statusSelect);
        }

        const arrayFile = [];
        if (dataNews.newsFiles && dataNews.newsFiles.length > 0) {
            dataNews.newsFiles.map((item, i) => {
                arrayFile.push({
                    "id": item.id ? item.id : "",
                    "name": item.name,
                    "base64": item.url,

                })
            })
        }

        setForm({
            ...form,
            news_id: dataNews.news.id,
            title: dataNews.news.title,
            newCategoryId: dataNews.news.newCategoryId,
            publishDate: dataNews.news.publishDate,
            description: dataNews.news.description,
            status: dataNews.news.statusId,
            createDate: dataNews.news.createDate,
            updateDate: dataNews.news.updateDate,
            createByName: dataNews.news.createByName,
            updateByName: dataNews.news.updateByName,
            filesPreview: arrayFile,
        })
    }

    const getNewsCategories = async () => {
        try {
            const response = await API.getNewsCategories("", "", 1);
            const data = await response.data;
            if (response.status === 200) {
                let categoryArray = [];
                data.content.forEach(element => {
                    categoryArray.push({
                        value: element.id, label: element.name,
                    });
                });
                await setCategoriesList(categoryArray);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const validate = () => {
        let validated = true;

        if (form.title === "") {
            addInvalid("title", "กรุณาระบุหัวข้อ");
            validated = false;
        }

        if (form.newCategoryId === "") {
            addInvalid("newCategoryId", "กรุณาเลือกหัวข้อ");
            validated = false;
        }
        if (form.publishDate === "") {
            addInvalid("publishDate", "กรุณาเลือกวันที่");
            validated = false;
        }
        if (form.description === "") {
            addInvalid("description", "กรุณาระบุรายละเอียด");
            validated = false;
        }

        if (form.status === "") {
            addInvalid("status", "กรุณาเลือกสถานะ");
            validated = false;
        }

        return validated;
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setStatusSelectValue([])
    }

    const toConfirm = async () => {
        if (validate()) {
            await save({ news: form, mode: mode });
            handleClose();
        }
    };

    const modules = {
        toolbar: [
            ["bold", "italic", "underline"],
            [{ align: "center", }],
            [{ list: "ordered" }],
            ["link", "image",],
            ["clear"],
        ],
    };

    const formats = [
        "bold",
        "italic",
        "underline",
        'align',
        "list",
        "link",
        "image",
        "clear",
    ];

    const checkDeleteImg = (list, value) => {
        let check = false;
        list.map((item) => {
            if (item.checked_id === value) {
                check = true;
            }
        })
        return check;
    }

    const removeImage = async () => {
        let delFile = [];

        if (form.filesPreview && form.filesPreview.length > 0) {
            form.filesPreview.map(async (item, i) => {
                if (checkDeleteImg(form.filesDelete, i + 1)) {
                    await form.filesPreview.splice(i, form.filesDelete.length);
                }
            })
        }

        if (form.filesDelete && form.filesDelete.length > 0) {
            form.filesDelete.map(async (item, i) => {
                if (item.id) {
                    delFile.push(item)
                }
            })
        }
        setForm({ ...form, filesPreview: form.filesPreview, filesChooseDel: delFile, filesDelete: [] })
    }

    const handleSelectDeleteAll = () => {
        setIsCheckAll(!isCheckAll);
        let chooseAll = [];
        if (form.filesPreview.length > 0) {
            form.filesPreview.map((item, i) => {
                chooseAll.push({
                    checked_id: i + 1,
                    id: item.id ? item.id : ""
                })
            })
        }
        if (isCheckAll) {
            chooseAll = [];
        }
        setForm({ ...form, filesDelete: chooseAll })
    }

    const selectImgDel = async (e, imgId) => {
        let arrayChoose = []
        if (e.target.checked) {
            arrayChoose = [...form.filesDelete, {
                checked_id: e.target.id,
                id: imgId ? imgId : ""
            }];
            setForm({ ...form, filesDelete: arrayChoose })
        } else {
            if (form.filesDelete.length > 0) {
                form.filesDelete.forEach((item, i) => {
                    if (item.checked_id === e.target.id) {
                        form.filesDelete.splice(i, 1);
                    }
                    setIsCheckAll(!isCheckAll);
                })
                setForm({ ...form, filesDelete: form.filesDelete })
            }
        }
    }

    const renderImages = (dataFiles) => {
        return dataFiles.map((item, index) => {
            return (
                <Col key={index} lg={4} md={6} sm={12} xs={12} className="mt-1">
                    <Form.Check
                        className="check-img"
                        type="checkbox"
                        name={`img${index + 1}`}
                        id={index + 1}
                        onChange={(e) => selectImgDel(e, item.id)}
                        checked={!!form.filesDelete.find(check => check.checked_id === index + 1)}
                    />
                    <label htmlFor={index + 1} className="img-fixed">
                        <img src={item.base64} alt={item.name} className="img-fixed cursor-p" />
                    </label>
                </Col>
            )
        })
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => { handleClose() }}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่มข่าวประชาสัมพันธ์</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className="nav-item tab">
                            <a className="nav-link" id="picture-tab" data-toggle="tab" href="#picture" role="tab" aria-controls="picture" aria-selected="false">รูปภาพ</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText
                                        type="text"
                                        title="หัวข้อ"
                                        idName="title"
                                        star={false}
                                        value={form.title}
                                        placeholder="กรุณาระบุ" classLabel="normal"
                                        handleChange={(value) =>
                                            setForm({ ...form, title: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("title")}
                                        invalid={invalid.title}
                                    />
                                </Col>

                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="หมวดหมู่"
                                        star={false}
                                        placeholder="กรุณาเลือก"
                                        idName="newCategoryId"
                                        classLabel="normal"
                                        selectValue={categoriesIdSelectValue}
                                        optionsList={categoriesIdList}
                                        handleChange={(value) => {
                                            setCategoriesIdSelectValue([value])
                                            setForm({ ...form, newCategoryId: value.value, checkShow: true })
                                        }}
                                        handleInvalid={() => removeInvalid("newCategoryId")}
                                        invalid={invalid.newCategoryId}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputDatePicker
                                        title="วันที่เริ่มเผยแพร่"
                                        classLabel="normal"
                                        placeholder="วัน/เดือน/ปี"
                                        value={form.publishDate}
                                        star={false}
                                        handleChange={(value) =>
                                            setForm({ ...form, publishDate: value })
                                        }
                                        handleInvalid={() => removeInvalid("publishDate")}
                                        invalid={invalid.publishDate}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <span className=" mt-3 ">รายละเอียด</span>
                                    <ReactQuill
                                        title="รายละเอียด"
                                        placeholder="ระบุรายละเอียด"
                                        value={form.description || ''}
                                        modules={modules}
                                        formats={formats}
                                        onChange={(value) =>
                                            setForm({ ...form, description: value, checkShow: true })
                                        }
                                        handleInvalid={() => removeInvalid("description")}
                                        invalid={invalid.description}
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="tab-pane fade card-tab" id="picture" role="tabpanel" aria-labelledby="picture-tab">
                            <div>
                                <p className="mt-2 bold">อัปโหลดรูปภาพ</p>
                            </div>

                            <div className="d-flex justify-content-between">
                                <UploadFileArray topicFiles={form.topicFiles} filesPreview={form.filesPreview} typeSave="base64"
                                    onChange={(files, i) => setForm({ ...form, filesPreview: files, filesDelete: [] })}
                                />

                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="checkbox"
                                        name="selectDelAll"
                                        id="selectDelAll"
                                        label="เลือกทั้งหมด"
                                        className="mr-2"
                                        onChange={() => handleSelectDeleteAll()}
                                        checked={isCheckAll}
                                    />
                                    <button className="btn btn-danger" onClick={() => removeImage()}>
                                        <img className="img-fluid icon-btn " src="/svg/btn-del.svg" alt="del" />
                                    </button>
                                </div>
                            </div>

                            <div className="mt-3 mx-2">
                                <Row>
                                    {
                                        (form.filesPreview.length > 0) ?
                                            renderImages(form.filesPreview)
                                            :
                                            <Col className="border py-3 text-center font14">
                                                <span className="">ไม่มีรูป</span>
                                            </Col>
                                    }
                                </Row>
                            </div>

                        </div>

                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                        selectValue={statusSelectValue} optionsList={statusList}
                                        handleSelectValue={(value) => setStatusSelectValue([{ value: value.value, label: value.label },])}
                                        handleChange={(value) => setForm({ ...form, status: value.value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("status")} invalid={invalid.status}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={3} sm={6} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mr-4">วันที่บันทึกข้อมูล</p>
                                    </div>
                                </Col>
                                <Col lg={10} md={9} sm={6} xs={12}>
                                    <div className="text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mb-0">{form.createDate ? helper.momentDate(form.createDate, "th", "short") : '-'}</p>
                                        <p>{form.createByName ? form.createByName : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={3} sm={6} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                                        <p className="mr-3" >วันที่ปรับปรุงข้อมูล</p>
                                    </div>
                                </Col>
                                <Col lg={10} md={9} sm={6} xs={12}>
                                    <div className="text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mb-0">{form.updateDate ? helper.momentDate(form.updateDate, "th", "short") : '-'}</p>
                                        <p>{form.updateByName ? form.updateByName : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalNews
