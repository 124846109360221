import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect } from 'react-router-dom';
import SecureRoute from "../utils/SecureRoute";
import Login from '../pages/Login';
import Home from '../pages/Home';
import Disease_Report from '../pages/Passive_Surve/Disease_Report';
import PageNotFound from '../pages/PageNotFound';
import Farm_Registration from '../pages/Farm_Regis/Farm_Registration';
import SaveData from '../pages/Farm_Regis/SaveData';

import Farm_registration_Report from '../pages/Farm_Regis/Farm_registration_Report';
import Action_Plan from '../pages/Active_Surve/Action_Plan';
import Disease_Record_Active from '../pages/Active_Surve/Disease_Record';
import Save_Project from '../pages/Active_Surve/Save_Project';
import Record_Result from '../pages/Active_Surve/Record_Result';
import Test_Results from '../pages/Active_Surve/Test_Results';
import Immune_Level from '../pages/Active_Surve/Immune_Level';
import Prevalence from '../pages/Active_Surve/Prevalence';
import Keep_Sample_Save from '../pages/Active_Surve/Keep_Sample_Save';
import AddDiseaseSurveillance from '../pages/Active_Surve/AddDiseaseSurveillance'
import Gfm from '../pages/GFM/CertificationFarm';
import SaveGFM from '../pages/GFM/SaveCertificationFarm';
import AssessRisk_Result from '../pages/Risk_Assessment/AssessRisk_Result';
import AssessRisk_Result_Save from '../pages/Risk_Assessment/AssessRisk_Result_Save';
import AssessRisk_Report from '../pages/Risk_Assessment/AssessRisk_Report';
import Assess_Risk_Result from '../pages/Risk_Assessment/AssessRisk_Result_Score';

import ZeroReport from '../pages/Zero_Report/Report'

import GFMReport from '../pages/GFM/GFMReport';
import GFMFarmer from '../pages/GFM/GFMFarmer';
import GFMSumByFarmType from '../pages/GFM/GFMSumByFarmType';
import GFMSumByProvince from '../pages/GFM/GFMSumByProvince';

// import Gfm from '../pages/Gfm';
import Assess_Risk from '../pages/Risk_Assessment/Assess_Risk';
import Assess_Risk_Save from '../pages/Risk_Assessment/Assess_Risk_Save';
import Risk_Assessment_Items from '../pages/Risk_Assessment/Risk_Assessment_Item';
import Risk_Assessment_Items_Save from '../pages/Risk_Assessment/Risk_Assessment_Item_Save';

import Statistics from '../pages/Statistics';
import Disease_Statement from '../pages/Passive_Surve/Disease_Statement';
import Disease_Report_Save from '../pages/Passive_Surve/Disease_Report_Save';
import Outbreak from '../pages/Passive_Surve/Outbreak';
import Outbreak_Save from '../pages/Passive_Surve/Outbreak_Save';
import Disease_Statement_Save from '../pages/Passive_Surve/Disease_Statement_Save';
import Disease_Investigation from '../pages/Passive_Surve/Disease_Investigation';
import Disease_Investigation_Save from '../pages/Passive_Surve/Disease_Investigation_Save';
import Disease_Control from '../pages/Passive_Surve/Disease_Control';
import Disease_Control_Save from '../pages/Passive_Surve/Disease_Control_Save';
import Disease_Control_Report from '../pages/Passive_Surve/Disease_Control_Report';
import Vaccinate from '../pages/Passive_Surve/Vaccinate';
import Vaccinate_Save from '../pages/Passive_Surve/Vaccinate_Save'
import Keep_Example from '../pages/Passive_Surve/Keep_Example';
import Keep_Example_Save from '../pages/Passive_Surve/Keep_Example_Save';
import Epidemic_Zone from '../pages/Passive_Surve/Epidemic_Zone';
import Epidemic_Zone_Save from '../pages/Passive_Surve/Epidemic_Zone_Save';
import Report_daily from '../pages/Passive_Surve/Report_daily';

import DiseaseInformsReport from '../pages/Passive_Surve/Disease_Informs_Report'
import Outbreak_Report from '../pages/Passive_Surve/Outbreak_Report';
import Epidemic_Zone_Report from '../pages/Passive_Surve/Epidemic_Zone_Report';
import Disease_Statement_Report from '../pages/Passive_Surve/Disease_Statement_Report';

import Banners_Relations from '../pages/Data_Management/Web_Settings/Banners_Relations';
import News from '../pages/Data_Management/Web_Settings/News';
import Warning from '../pages/Data_Management/Web_Settings/Warning';
import DataManageEpidemicZone from '../pages/Data_Management/Web_Settings/Epidemic_Zone';
import Document from '../pages/Data_Management/Web_Settings/Document';
import Contact_Details from '../pages/Data_Management/Web_Settings/Contact_Details';
import System from '../pages/Data_Management/Web_Settings/System';
import Doc_Categories from '../pages/Data_Management/Web_Settings/Doc_Categories';
import News_Categories from '../pages/Data_Management/Web_Settings/News_Categories';
import User from '../pages/Data_Management/User/Users';
import SaveUser from '../pages/Data_Management/User/Save_Users';
import UserGroup from '../pages/Data_Management/User/User_Group';
import UserGroupSave from '../pages/Data_Management/User/User_Group_Save';
import UserView from '../pages/Data_Management/User/User_View';
import UserViewSave from '../pages/Data_Management/User/User_View_Save';

import Disease_Type from '../pages/Data_Management/Master_Data/Disease_Type';
import Animal_Type from '../pages/Data_Management/Master_Data/Animal_Type';
import Test_Method from '../pages/Data_Management/Master_Data/Test_Method';
import Sample_Type from '../pages/Data_Management/Master_Data/Sample_Type';
import Symptom from '../pages/Data_Management/Master_Data/Symptom';
import MenuSystem from '../pages/Data_Management/Master_Data/MenuSystem'
import Lab from '../pages/Data_Management/Master_Data/Lab';
import Positions from '../pages/Data_Management/Master_Data/Positions';
import Organizes from '../pages/Data_Management/Master_Data/Organizes';
import Title from '../pages/Data_Management/Master_Data/Title';
import Animal_Purpose from '../pages/Data_Management/Master_Data/Animal_Purpose';
import Sample_Purpose from '../pages/Data_Management/Master_Data/Sample_Purpose';
import Annouce from '../pages/Data_Management/Master_Data/Annouce';
import GFMFarmerType from '../pages/Data_Management/Master_Data/GFMFarmerType';
import Analyse from '../pages/Data_Management/Master_Data/Analyse';
import Sample_Send from '../pages/Data_Management/Master_Data/Sample_Send';
import Provinces from '../pages/Data_Management/Master_Data/Provinces';
import District from '../pages/Data_Management/Master_Data/District';
import SubDistrict from '../pages/Data_Management/Master_Data/SubDistrict';
import Dlas from '../pages/Data_Management/Master_Data/DLAS';
import ZoneLiveStock from '../pages/Data_Management/Master_Data/ZoneLiveStock';
import Vaccine_Type from '../pages/Data_Management/Master_Data/Vaccine_Type';
import Risk from '../pages/Data_Management/Master_Data/Risk';
import Husbandry from '../pages/Data_Management/Master_Data/Husbandry'
import AnimalPlace from '../pages/Data_Management/Master_Data/AnimalPlace'
import FarmCertificate from '../pages/Data_Management/Master_Data/Farm_Cert';
import Access from '../pages/Data_Management/Report/Access';
import Transaction from '../pages/Data_Management/Report/Transaction'
import Poultry_System from '../pages/Poultry_System'
import MapEx from '../pages/example/MapEx'

import Loader from "../components/loader/Loader";
import Menu from "../components/Menu";
import Header from "../components/Header";
import HeaderHome from "../components/HeaderHome";

import LineCallback from "../pages/LineCallback";

import { useLocation } from "react-router-dom";
import AssessRisk_Result_Score from '../pages/Risk_Assessment/AssessRisk_Result_Score';


const checkPath = (location) => {
    return location.pathname != '/login' && location.pathname != '/home' && location.pathname != '/linecallback';
}

const Routers = (props) => {
    const location = useLocation();
    const login = useSelector(state => state.login);

    const { loading } = useSelector((state) => state.loading);

    useEffect(() => {
    }, [])

    return (
        <>
            {login != false && location.pathname == '/home' && <HeaderHome />}
            {login != false && checkPath(location) && <Header />}
            {login != false && checkPath(location) && <Menu />}
            <div className={`${checkPath(location) ? "content-wrapper" : ""}`}>
                <Loader loading={loading} />
                <Switch>
                    <Route exact path="/" component={() => <Redirect to="/login" />} />
                    <Route exact path="/login" component={Login} />
                    {/* <Route exact path="/linecallback" component={LineCallback} /> */}

                    <SecureRoute exact path="/home" component={Home} />
                    {/* รายงาน Zero Report */}
                    <SecureRoute exact path="/zero_report/report" component={ZeroReport} />
                    {/* <SecureRoute exact path="/zero_report/save-data" component={ReportSaveData} />
                    <SecureRoute exact path="/zero_report/edit/:id" component={ReportSaveData} /> */}

                    {/* เฝ้าระวังเชิงรับ */}
                    <SecureRoute exact path="/passive_surv/disease_report" component={Disease_Report} />
                    <SecureRoute exact path="/passive_surv/disease_statement" component={Disease_Statement} />
                    <SecureRoute exact path="/passive_surv/disease_report/savedisease_report" component={Disease_Report_Save} />
                    <SecureRoute exact path="/passive_surv/disease_report/edit/:id" component={Disease_Report_Save} />

                    <SecureRoute exact path="/passive_surv/outbreak" component={Outbreak} />
                    <SecureRoute exact path="/passive_surv/outbreak/save" component={Outbreak_Save} />
                    <SecureRoute exact path="/passive_surv/outbreak/edit/:id" component={Outbreak_Save} />
                    <SecureRoute exact path="/passive_surv/outbreak/duplicate/:id" component={Outbreak_Save} />
                    <SecureRoute exact path="/passive_surv/outbreak/from-disease/:diseaseId" component={Outbreak_Save} />
                    <SecureRoute exact path="/passive_surv/outbreak/farm/:farmId" component={Outbreak_Save} />

                    <SecureRoute exact path="/passive_surv/disease_statement/save/:obCode/farm/:farmId" component={Disease_Statement_Save} />
                    <SecureRoute exact path="/passive_surv/disease_statement/save" component={Disease_Statement_Save} />
                    <SecureRoute exact path="/passive_surv/disease_statement/edit/:id" component={Disease_Statement_Save} />
                    <SecureRoute exact path="/passive_surv/disease_statement/duplicate/:id" component={Disease_Statement_Save} />


                    <SecureRoute exact path="/passive_surv/disease_control" component={Disease_Control} />
                    <SecureRoute exact path="/passive_surv/disease_control/edit/:id" component={Disease_Control_Save} />
                    <SecureRoute exact path="/passive_surv/disease_control/save" component={Disease_Control_Save} />
                    <SecureRoute exact path="/passive_surv/disease_control/save/:id" component={Disease_Control_Save} />
                    <SecureRoute exact path="/passive_surv/disease_control/duplicate/:id" component={Disease_Control_Save} />


                    <SecureRoute exact path="/passive_surv/disease_investigation" component={Disease_Investigation} />
                    <SecureRoute exact path="/passive_surv/disease_investigation/save" component={Disease_Investigation_Save} />
                    <SecureRoute exact path="/passive_surv/disease_investigation/save/:id" component={Disease_Investigation_Save} />
                    <SecureRoute exact path="/passive_surv/disease_investigation/edit/:id" component={Disease_Investigation_Save} />
                    <SecureRoute exact path="/passive_surv/disease_investigation/duplicate/:id" component={Disease_Investigation_Save} />

                    <SecureRoute exact path="/passive_surv/vaccinate" component={Vaccinate} />
                    <SecureRoute exact path="/passive_surv/vaccinate/save" component={Vaccinate_Save} />
                    <SecureRoute exact path="/passive_surv/vaccinate/edit/:id" component={Vaccinate_Save} />
                    <SecureRoute exact path="/passive_surv/vaccinate/save/:id" component={Vaccinate_Save} />
                    <SecureRoute exact path="/passive_surv/vaccinate/duplicate/:id" component={Vaccinate_Save} />
                    <SecureRoute exact path="/passive_surv/keep-example" component={Keep_Example} />
                    <SecureRoute exact path="/passive_surv/keep-example/save" component={Keep_Example_Save} />
                    <SecureRoute exact path="/passive_surv/keep-example/save/:id" component={Keep_Example_Save} />
                    <SecureRoute exact path="/passive_surv/keep-example/edit/:id" component={Keep_Example_Save} />
                    <SecureRoute exact path="/passive_surv/keep-example/duplicate/:id" component={Keep_Example_Save} />

                    <SecureRoute exact path="/passive_surv/epidemic_zone" component={Epidemic_Zone} />
                    <SecureRoute exact path="/passive_surv/epidemic_zone/save" component={Epidemic_Zone_Save} />
                    <SecureRoute exact path="/passive_surv/epidemic_zone/save/:id" component={Epidemic_Zone_Save} />
                    <SecureRoute exact path="/passive_surv/epidemic_zone/edit/:id" component={Epidemic_Zone_Save} />

                    <SecureRoute exact path="/passive_surv/report/disease-informs" component={DiseaseInformsReport} />
                    <SecureRoute exact path="/passive_surv/report/outbreak" component={Outbreak_Report} />
                    <SecureRoute exact path="/passive_surv/report/epidemic_zone" component={Epidemic_Zone_Report} />
                    <SecureRoute exact path="/passive_surv/report/disease-statement" component={Disease_Statement_Report} />
                    <SecureRoute exact path="/passive_surv/report/disease-control" component={Disease_Control_Report} />

                    <SecureRoute exact path="/passive_surv/report/daily" component={Report_daily} />


                    {/* เฝ้าระวังเชิงรุก */}
                    <SecureRoute exact path="/active_surv/action_plan" component={Action_Plan} />
                    <SecureRoute exact path="/active_surv/action_plan/disease_record" component={Disease_Record_Active} />
                    <SecureRoute exact path="/active_surv/action_plan/edit/:id" component={Disease_Record_Active} />

                    <SecureRoute exact path="/active_surv/save-project/add-disease-surveillance/:id" component={AddDiseaseSurveillance} />
                    <SecureRoute exact path="/active_surv/save-project" component={Save_Project} />
                    <SecureRoute exact path="/active_surv/save-project/keep-sample/save/:id" component={Keep_Sample_Save} />
                    <SecureRoute exact path="/active_surv/save-project/keep-sample/edit/:id/sample/:sampleId" component={Keep_Sample_Save} />


                    <SecureRoute exact path="/active_surv/report/record_result" component={Record_Result} />
                    <SecureRoute exact path="/active_surv/report/test_results" component={Test_Results} />
                    <SecureRoute exact path="/active_surv/report/immune_level" component={Immune_Level} />
                    <SecureRoute exact path="/active_surv/report/prevalence" component={Prevalence} />


                    {/* ขึ้นทะเบียนฟาร์ม */}
                    <SecureRoute exact path="/farm_registration" component={Farm_Registration} />
                    <SecureRoute exact path="/farm_registration/save-data" component={SaveData} />
                    <SecureRoute exact path="/farm_registration/edit/:id" component={SaveData} />

                    <SecureRoute exact path="/farm_registration/farm_registration_report" component={Farm_registration_Report} />

                    {/* แบบประเมินความเสี่ยง*/}
                    <SecureRoute exact path="/assess_risk/assess-risk-result" component={AssessRisk_Result} />
                    <SecureRoute exact path="/assess_risk/assess-risk-result/save" component={AssessRisk_Result_Save} />
                    <SecureRoute exact path="/assess_risk/assess-risk-result/edit/:id" component={AssessRisk_Result_Save} />
                    <SecureRoute exact path="/assess_risk/report/risk" component={AssessRisk_Report} />
                    <SecureRoute exact path="/assess_risk/assess-risk-result/score/:id" component={AssessRisk_Result_Score} />


                    {/* GFM */}
                    <SecureRoute exact path="/gfm/report/gfm-report" component={GFMReport} />
                    <SecureRoute exact path="/gfm/report/gfm-farmer" component={GFMFarmer} />
                    <SecureRoute exact path="/gfm/report/gfm-sum-farmType" component={GFMSumByFarmType} />
                    <SecureRoute exact path="/gfm/report/gfm-sum-province" component={GFMSumByProvince} />

                    <SecureRoute exact path="/gfm" component={Gfm} />
                    <SecureRoute exact path="/gfm/save" component={SaveGFM} />
                    <SecureRoute exact path="/gfm/edit/:id" component={SaveGFM} />

                    {/* ประเมินความเสี่ยง */}
                    <SecureRoute exact path="/assess_risk/list-assess-risk" component={Assess_Risk} />
                    <SecureRoute exact path="/assess_risk/list-assess-risk/edit/:id" component={Assess_Risk_Save} />
                    <SecureRoute exact path="/assess_risk/list-assess-risk/save" component={Assess_Risk_Save} />
                    <SecureRoute exact path="/assess_risk/risk-assessment-items" component={Risk_Assessment_Items} />
                    <SecureRoute exact path="/assess_risk/risk-assessment-items/save" component={Risk_Assessment_Items_Save} />
                    <SecureRoute exact path="/assess_risk/risk-assessment-items/save/:id" component={Risk_Assessment_Items_Save} />
                    <SecureRoute exact path="/assess_risk/risk-assessment-items/edit/:id" component={Risk_Assessment_Items_Save} />

                    <SecureRoute exact path="/statistics" component={Statistics} />
                    <SecureRoute exact path="/poultry_system" component={Poultry_System} />

                    {/* บริหารข้อมูลส่วนกลาง */}
                    <SecureRoute exact path="/data-manage/web-settings/banners-relations" component={Banners_Relations} />
                    <SecureRoute exact path="/data-manage/web-settings/news" component={News} />
                    <SecureRoute exact path="/data-manage/web-settings/warning" component={Warning} />
                    <SecureRoute exact path="/data-manage/web-settings/epidemic-zone" component={DataManageEpidemicZone} />
                    <SecureRoute exact path="/data-manage/web-settings/document" component={Document} />
                    <SecureRoute exact path="/data-manage/web-settings/contact-details" component={Contact_Details} />
                    <SecureRoute exact path="/data-manage/web-settings/system" component={System} />
                    <SecureRoute exact path="/data-manage/web-settings/doc-categories" component={Doc_Categories} />
                    <SecureRoute exact path="/data-manage/web-settings/news-categories" component={News_Categories} />

                    <SecureRoute exact path="/data-manage/master-data/disease-type" component={Disease_Type} />
                    <SecureRoute exact path="/data-manage/master-data/animal-type" component={Animal_Type} />
                    <SecureRoute exact path="/data-manage/master-data/test-method" component={Test_Method} />
                    <SecureRoute exact path="/data-manage/master-data/sample-type" component={Sample_Type} />
                    <SecureRoute exact path="/data-manage/master-data/symptom" component={Symptom} />
                    <SecureRoute exact path="/data-manage/master-data/menu-system" component={MenuSystem} />
                    <SecureRoute exact path="/data-manage/master-data/lab" component={Lab} />
                    <SecureRoute exact path="/data-manage/master-data/positions" component={Positions} />
                    <SecureRoute exact path="/data-manage/master-data/organizes" component={Organizes} />
                    <SecureRoute exact path="/data-manage/master-data/title" component={Title} />
                    <SecureRoute exact path="/data-manage/master-data/animal-purpose" component={Animal_Purpose} />
                    <SecureRoute exact path="/data-manage/master-data/sample-purpose" component={Sample_Purpose} />
                    <SecureRoute exact path="/data-manage/master-data/sample-type" component={Sample_Type} />
                    <SecureRoute exact path="/data-manage/master-data/annouce" component={Annouce} />
                    <SecureRoute exact path="/data-manage/master-data/farmer-type" component={GFMFarmerType} />
                    <SecureRoute exact path="/data-manage/master-data/analyse" component={Analyse} />
                    <SecureRoute exact path="/data-manage/master-data/sample-send" component={Sample_Send} />
                    <SecureRoute exact path="/data-manage/master-data/vaccine-type" component={Vaccine_Type} />
                    <SecureRoute exact path="/data-manage/master-data/zone-livestock" component={ZoneLiveStock} />
                    <SecureRoute exact path="/data-manage/master-data/provinces" component={Provinces} />
                    <SecureRoute exact path="/data-manage/master-data/district" component={District} />
                    <SecureRoute exact path="/data-manage/master-data/subdistrict" component={SubDistrict} />
                    <SecureRoute exact path="/data-manage/master-data/dlas" component={Dlas} />
                    <SecureRoute exact path="/data-manage/master-data/farm-certificate" component={FarmCertificate} />
                    <SecureRoute exact path="/data-manage/master-data/risk" component={Risk} />
                    <SecureRoute exact path="/data-manage/master-data/husbandry" component={Husbandry} />
                    <SecureRoute exact path="/data-manage/master-data/animalplace" component={AnimalPlace} />

                    <SecureRoute exact path="/data-manage/users/users" component={User} />
                    <SecureRoute exact path="/data-manage/users/users/save-user" component={SaveUser} />
                    <SecureRoute exact path="/data-manage/users/users/edit/:id" component={SaveUser} />

                    <SecureRoute exact path="/data-manage/users/group-user" component={UserGroup} />
                    <SecureRoute exact path="/data-manage/users/group-user/save-user-group" component={UserGroupSave} />
                    <SecureRoute exact path="/data-manage/users/group-user/edit/:id" component={UserGroupSave} />

                    <SecureRoute exact path="/data-manage/users/view-user" component={UserView} />
                    <SecureRoute exact path="/data-manage/users/view-user/save-user-view" component={UserViewSave} />
                    <SecureRoute exact path="/data-manage/users/view-user/edit/:id" component={UserViewSave} />

                    <SecureRoute exact path="/data-manage/report/access" component={Access} />
                    <SecureRoute exact path="/data-manage/report/transaction" component={Transaction} />

                    {/* Example */}
                    <SecureRoute exact path="/passive_surv/map-ex" component={MapEx} />
                    <Route path="*" exact={true} component={PageNotFound} />

                </Switch>
            </div>
        </>

    )
}

export default Routers
