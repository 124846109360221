import * as actionTypes from "./notifyTypes";

export const getDiseaseInformNotify = (payload) => {
  return { type: actionTypes.DISEASE_INFORM, payload };
};

export const getDiseaseReportNotify = (payload) => {
  return { type: actionTypes.DISEASE_REPORT, payload };
};

