import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { checkLogin } from '../redux/actions/loginAction';
import { logout } from "../redux/actions/logoutAction";
import { Navbar, Nav, Container, Dropdown, Row, Col } from 'react-bootstrap';

export default function HeaderHome() {
    const user = useSelector(state => state.login.result);
    useEffect(async () => {
        await dispatch(checkLogin())
    }, [])

    const store = useSelector(state => state);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation(["home", "footer"]);
    const history = useHistory();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    const toLogout = () => {
        dispatch(logout({ history }));
    };

    return (
        <Navbar expand="lg" sticky="top" className="header-nav  shadow-nav" >
            <Container className=" d-flex justify-content-between ">
                <Col lg={7} md={12} sm={12} className="d-flex align-items-center justify-content-between">

                    <div className="d-flex mt-auto mb-1 align-items-center">
                        <img src={"/svg/logo.svg"} alt="logo" />
                        <div className="">
                            <p className="font14 mb-0 text-white ml-1" >ระบบเฝ้าระวังโรคสัตว์ </p>
                            <p className="font10 mb-0 text-white ml-1" >กรมปศุสัตว์ </p>
                        </div>
                    </div>

                    <div>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    </div>
                </Col>
                <div className="">
                    <Navbar.Collapse id="basic-navbar-nav" className="flex-column-reverse">
                        <div className="d-flex flex-column d-md-block w-100"></div>
                        <ul className="navbar-nav ml-autob ml-3 ">
                            <li className="nav-item dropdown">
                                <a className="nav-link d-flex align-items-center" data-toggle="dropdown" href="#">
                                    <span className="mr-3 text-white font12">{user && user.profile.firstName} {user && user.profile.lastName}</span>
                                    <img className="mr-3 user-profile border" src={user && user.profile.profileImgUrl ? user.profile.profileImgUrl : "/images/profile-user.png"} alt="logo" />
                                    <img src={"/svg/icon-nav.svg"} alt="logo" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                    <a className="dropdown-item" onClick={() => toLogout()}>
                                        <div className="media">
                                            ออกจากระบบ
                                        </div>
                                    </a>
                                </div>
                            </li>
                        </ul>

                    </Navbar.Collapse>
                </div>
            </Container>

        </Navbar>

    );
}




