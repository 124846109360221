import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin } from "../redux/actions/loginAction";
import { logout } from "../redux/actions/logoutAction";
import { useHistory } from "react-router-dom";

export default function Header() {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);
  const history = useHistory();

  useEffect(async () => {
    await dispatch(checkLogin());
  }, []);

  const toLogout = () => {
    dispatch(logout({ history }));
  };
  return (
    <nav className="main-header border-header navbar navbar-expand navbar-white navbar-light header-h-detail">
      {/* Left navbar links */}
      <ul className="navbar-nav cursor-p">
        <li className="nav-item ">
          <a
            className="nav-link d-flex align-items-center"
            data-widget="pushmenu"
            role="button"
          >
            <i className="fas fa-bars" />
          </a>
        </li>
      </ul>

      <h4 className="mb-1 ml-2 text-bold ">
        {/* ขึ้นทะเบียนฟาร์ม */}
        {location.pathname == "/farm_registration" ? "รายการฟาร์ม" : ""}
        {location.pathname == "/farm_registration/save-data"
          ? "บันทึกข้อมูลฟาร์ม"
          : ""}
        {location.pathname.includes("/farm_registration/edit")
          ? "แก้ไขข้อมูลฟาร์ม"
          : ""}
        {location.pathname == "/farm_registration/farm_registration_report"
          ? "การขึ้นทะเบียนฟาร์ม"
          : ""}

        {/* เฝ้าระวังเชิงรับ */}
        {location.pathname == "/passive_surv/disease_report"
          ? "รายการแจ้งพบ - สงสัยโรคระบาดสัตว์"
          : ""}
        {location.pathname == "/passive_surv/disease_report/savedisease_report"
          ? "บันทึกการแจ้งพบ - สงสัยโรคระบาดสัตว์"
          : ""}
        {location.pathname.includes("/passive_surv/disease_report/edit")
          ? "แก้ไขการแจ้งพบ - สงสัยโรคระบาดสัตว์"
          : ""}
        {location.pathname == "/passive_surv/outbreak" ? "รายการ Outbreak" : ""}
        {location.pathname == "/passive_surv/outbreak/save"
          ? "บันทึกข้อมูล Outbreak"
          : ""}
        {location.pathname.includes("/passive_surv/outbreak/duplicate")
          ? "บันทึกข้อมูล Outbreak"
          : ""}
        {location.pathname.includes("/passive_surv/outbreak/from-disease")
          ? "บันทึกข้อมูล Outbreak"
          : ""}
        {location.pathname.includes("/passive_surv/outbreak/edit")
          ? "แก้ไขข้อมูล Outbreak"
          : ""}

        {location.pathname == "/passive_surv/disease_statement"
          ? "การเกิดโรคระบาดสัตว์เบื้องต้น (กคร.1/รก.1)"
          : ""}
        {location.pathname.includes("/passive_surv/disease_statement/save")
          ? "บันทึกการเกิดโรคระบาดสัตว์เบื้องต้น"
          : ""}
        {location.pathname.includes("/passive_surv/disease_statement/edit")
          ? "แก้ไขการเกิดโรคระบาดสัตว์เบื้องต้น"
          : ""}
        {location.pathname.includes("/passive_surv/disease_statement/duplicate")
          ? "บันทึกการเกิดโรคระบาดสัตว์เบื้องต้น"
          : ""}

        {location.pathname == "/passive_surv/disease_control"
          ? "ภาวการณ์ระบาดของโรค/การควบคุมโรค (กคร.3 /สคส.1)"
          : ""}
        {location.pathname.includes("/passive_surv/disease_control/save")
          ? "บันทึกภาวการณ์ระบาดของโรค/การควบคุมโรค (กคร.3 /สคส.1)"
          : ""}
        {location.pathname.includes("/passive_surv/disease_control/duplicate")
          ? "บันทึกภาวการณ์ระบาดของโรค/การควบคุมโรค (กคร.3 /สคส.1)"
          : ""}
        {location.pathname.includes("/passive_surv/disease_control/edit")
          ? "แก้ไขภาวการณ์ระบาดของโรค"
          : ""}

        {location.pathname == "/passive_surv/disease_investigation"
          ? "สอบสวนทางระบาดวิทยาของการเกิดโรคระบาดสัตว์"
          : ""}
        {location.pathname.includes("/passive_surv/disease_investigation/save")
          ? "บันทึกการสอบสวนทางระบาดวิทยาของการเกิดโรคระบาดสัตว์"
          : ""}
        {location.pathname.includes("/passive_surv/disease_investigation/edit")
          ? "แก้ไขการสอบสวนทางระบาดวิทยาของการเกิดโรคระบาดสัตว์"
          : ""}
        {location.pathname.includes(
          "/passive_surv/disease_investigation/duplicate"
        )
          ? "บันทึกการสอบสวนทางระบาดวิทยาของการเกิดโรคระบาดสัตว์"
          : ""}

        {location.pathname == "/passive_surv/vaccinate"
          ? "แผน/ผลการฉีดวัคซีนป้องกันโรคระบาดสัตว์ (กคร.5)"
          : ""}
        {location.pathname.includes("/passive_surv/vaccinate/save")
          ? "บันทึกแผน/ผลการฉีดวัคซีนป้องกันโรคระบาดสัตว์ (กคร.5)"
          : ""}
        {location.pathname.includes("/passive_surv/vaccinate/duplicate")
          ? "บันทึกแผน/ผลการฉีดวัคซีนป้องกันโรคระบาดสัตว์ (กคร.5)"
          : ""}
        {location.pathname.includes("/passive_surv/vaccinate/edit")
          ? "แก้ไขการฉีดวัคซีน"
          : ""}

        {location.pathname == "/passive_surv/keep-example"
          ? "รายการการทดสอบโรค (เก็บตัวอย่าง) และผลการตรวจทางห้องปฏิบัติการ"
          : ""}
        {location.pathname.includes("/passive_surv/keep-example/edit")
          ? "แก้ไขการทดสอบโรค (เก็บตัวอย่าง) และผลการตรวจทางห้องปฏิบัติการ"
          : ""}
        {location.pathname.includes("/passive_surv/keep-example/duplicate")
          ? "บันทึกการทดสอบโรค (เก็บตัวอย่าง) และผลการตรวจทางห้องปฏิบัติการ"
          : ""}
        {location.pathname.includes("/passive_surv/keep-example/save")
          ? "บันทึกการทดสอบโรค (เก็บตัวอย่าง) และผลการตรวจทางห้องปฏิบัติการ"
          : ""}

        {location.pathname == "/passive_surv/epidemic_zone"
          ? "รายการประกาศเขตโรคระบาด"
          : ""}
        {location.pathname.includes("/passive_surv/epidemic_zone/save")
          ? "เพิ่มรายการข้อมูลการประกาศเขตโรคระบาดสัตว์"
          : ""}
        {location.pathname.includes("/passive_surv/epidemic_zone/edit")
          ? "แก้ไขข้อมูลการประกาศเขตโรคระบาดสัตว์"
          : ""}

        {location.pathname == "/passive_surv/report/daily"
          ? "รายงาน B99RPT08-รายงานภาพรวมรายวัน"
          : ""}
        {location.pathname == "/passive_surv/report/disease-statement"
          ? "รายงาน B99RPT03-การรายงานโรค"
          : ""}
        {location.pathname == "/passive_surv/report/disease-informs"
          ? "รายงาน B99RPT01-การแจ้งโรค"
          : ""}
        {location.pathname == "/passive_surv/report/outbreak"
          ? "รายงาน B99RPT02-Outbreak"
          : ""}
        {location.pathname == "/passive_surv/report/epidemic_zone"
          ? "B99RPT04-รายงานวิเคราะห์เชิงพื้นที่"
          : ""}
        {location.pathname == "/passive_surv/report/disease-control"
          ? "B99RPT05-รายงานควบคุมโรค"
          : ""}

        {/* เฝ้าระวังเชิงรุก */}
        {location.pathname == "/active_surv/action_plan"
          ? "รายการแผนการดำเนินงาน"
          : ""}
        {location.pathname == "/active_surv/action_plan/disease_record"
          ? "บันทึกแผนการดำเนินงาน"
          : ""}
        {location.pathname.includes("/active_surv/action_plan/edit")
          ? "แก้ไขเกิดโรคระบาดสัตว์เบื้องต้น"
          : ""}
        {location.pathname == "/active_surv/save-project"
          ? "รายการบันทึกข้อมูลตามโครงการ"
          : ""}
        {location.pathname.includes(
          "/active_surv/save-project/keep-sample/save"
        )
          ? "เพิ่ม (ปรับปรุง) รายการข้อมูลการเฝ้าระวังโรค"
          : ""}
        {location.pathname.includes(
          "/active_surv/save-project/keep-sample/edit"
        )
          ? "แก้ไข (ปรับปรุง) รายการข้อมูลการเฝ้าระวังโรค"
          : ""}
        {location.pathname.includes(
          "/active_surv/save-project/add-disease-surveillance"
        )
          ? "เพิ่ม (ปรับปรุง) รายการข้อมูลการเฝ้าระวังโรค"
          : ""}

        {location.pathname == "/active_surv/report/record_result"
          ? "รายงาน C03RPT01-การบันทึกผล"
          : ""}
        {location.pathname == "/active_surv/report/test_results"
          ? "รายงาน C03RPT02-ผลการทดสอบโรค"
          : ""}
        {location.pathname == "/active_surv/report/immune_level"
          ? "รายงาน C03RPT03-ระดับภูมิคุ้มกัน"
          : ""}
        {location.pathname == "/active_surv/report/prevalence"
          ? "รายงาน C03RPT04-ความชุกตามชนิดสัตว์และพื้นที่"
          : ""}

        {location.pathname == "/active_surveillance"
          ? "รายการแผนการดำเนินงาน"
          : ""}

        {/* แบบประเมินความเสี่ยง */}
        {location.pathname == "/assess_risk/list-assess-risk"
          ? "รายการชุดประเมินความเสี่ยง"
          : ""}
        {location.pathname == "/assess_risk/assess-risk-result"
          ? "รายการผลการประเมินความเสี่ยง"
          : ""}
        {location.pathname == "/assess_risk/assess-risk-result/save"
          ? "บันทึกผลการประเมินความเสี่ยง"
          : ""}
        {location.pathname.includes("/assess_risk/assess-risk-result/edit")
          ? "แก้ไขผลการประเมินความเสี่ยง"
          : ""}
        {location.pathname.includes("/assess_risk/assess-risk-result/score")
          ? "บันทึกผลการประเมินความเสี่ยง"
          : ""}
        {location.pathname == "/assess_risk/report/risk"
          ? "รายงาน D99RPT01-ประเมินความเสี่ยงฟาร์ม"
          : ""}

        {location.pathname.includes("/assess_risk/list-assess-risk/edit")
          ? "บันทึกชุดประเมินความเสี่ยง"
          : ""}
        {location.pathname == "/assess_risk/list-assess-risk/save"
          ? "บันทึกชุดประเมินความเสี่ยง"
          : ""}
        {location.pathname == "/assess_risk/risk-assessment-items"
          ? "รายการประเมินความเสี่ยง"
          : ""}
        {location.pathname.includes("/assess_risk/risk-assessment-items/edit")
          ? "บันทึกคำถาม"
          : ""}
        {location.pathname.includes("/assess_risk/risk-assessment-items/save")
          ? "บันทึกคำถาม"
          : ""}

        {/* GFM */}
        {location.pathname == "/gfm" ? "รายการรับรองฟาร์ม GFM" : ""}
        {location.pathname == "/gfm/save" ? "บันทึกการรับรองฟาร์ม GFM" : ""}
        {location.pathname.includes("/gfm/edit")
          ? "แก้ไขการรับรองฟาร์ม GFM"
          : ""}
        {location.pathname == "/gfm/report/gfm-report"
          ? "รายงาน G99RPT01-ฟาร์ม GFM"
          : ""}

        {/* บริหารข้อมูลส่วนกลาง */}
        {location.pathname == "/data-manage/web-settings/banners-relations"
          ? "ป้ายประชาสัมพันธ์"
          : ""}
        {location.pathname == "/data-manage/web-settings/news"
          ? "ข่าวประชาสัมพันธ์"
          : ""}
        {location.pathname == "/data-manage/web-settings/warning"
          ? "เตือนภัยโรคระบาด"
          : ""}
        {location.pathname == "/data-manage/web-settings/epidemic-zone"
          ? "ประกาศเขตโรคระบาด"
          : ""}
        {location.pathname == "/data-manage/web-settings/document"
          ? "เอกสารที่เกี่ยวข้อง"
          : ""}
        {location.pathname == "/data-manage/web-settings/contact-details"
          ? "ข้อมูลติดต่อสอบถาม"
          : ""}
        {location.pathname == "/data-manage/web-settings/system"
          ? "ระบบที่เกียวข้อง"
          : ""}
        {location.pathname == "/data-manage/web-settings/news-categories"
          ? "หมวดหมู่ข่าวสาร"
          : ""}
        {location.pathname == "/data-manage/web-settings/doc-categories"
          ? "หมวดหมู่เอกสาร"
          : ""}
        {location.pathname == "/data-manage/master-data/animal-type"
          ? "ชนิดสัตว์"
          : ""}
        {location.pathname == "/data-manage/master-data/disease-type"
          ? "ชนิดโรค"
          : ""}
        {location.pathname == "/data-manage/master-data/test-method"
          ? "วิธีการทดสอบ"
          : ""}
        {location.pathname == "/data-manage/master-data/sample-type"
          ? "ชนิดตัวอย่าง"
          : ""}
        {location.pathname == "/data-manage/master-data/symptom"
          ? "อาการป่วย"
          : ""}
        {location.pathname == "/data-manage/master-data/menu-system"
          ? "เมนู"
          : ""}
        {location.pathname == "/data-manage/master-data/lab"
          ? "ห้องปฏิบัติการ"
          : ""}
        {location.pathname == "/data-manage/master-data/positions"
          ? "ตำแหน่งเจ้าหน้าที่"
          : ""}
        {location.pathname == "/data-manage/master-data/organizes"
          ? "หน่วยงานกรมปศุสัตว์"
          : ""}
        {location.pathname == "/data-manage/master-data/title"
          ? "คำนำหน้าชื่อ"
          : ""}
        {location.pathname == "/data-manage/master-data/animal-purpose"
          ? "วัตถุประสงค์การเลี้ยงสัตว์"
          : ""}
        {location.pathname == "/data-manage/master-data/sample-purpose"
          ? "วัตถุประสงค์การเก็บตัวอย่าง"
          : ""}
        {location.pathname == "/data-manage/master-data/annouce"
          ? "ประเภทประกาศโรคระบาด"
          : ""}
        {location.pathname == "/data-manage/master-data/farmer-type"
          ? "ประเภทเกษตรกรมาตรฐาน GFM"
          : ""}
        {location.pathname == "/data-manage/master-data/analyse"
          ? "วิเคราะห์ข้อมูลการสอบสวนโรค"
          : ""}
        {location.pathname == "/data-manage/master-data/sample-send"
          ? "วิธีการส่งตัวอย่าง"
          : ""}
        {location.pathname == "/data-manage/master-data/vaccine-type"
          ? "ชนิดวัคซีน"
          : ""}
        {location.pathname == "/data-manage/master-data/zone-livestock"
          ? "เขต"
          : ""}
        {location.pathname == "/data-manage/master-data/provinces"
          ? "จังหวัด"
          : ""}
        {location.pathname == "/data-manage/master-data/district"
          ? "อำเภอ"
          : ""}
        {location.pathname == "/data-manage/master-data/subdistrict"
          ? "ตำบล"
          : ""}
        {location.pathname == "/data-manage/master-data/dlas"
          ? "องค์กรปกครองส่วนท้องถิ่น"
          : ""}
        {location.pathname == "/data-manage/master-data/farm-certificate"
          ? "ประเภทมาตรฐานฟาร์ม"
          : ""}
        {location.pathname == "/data-manage/master-data/risk"
          ? "เกณฑ์ผลการประเมินความเสี่ยง"
          : ""}
        {location.pathname == "/data-manage/master-data/husbandry"
          ? "ลักษณะการเลี้ยง"
          : ""}
        {location.pathname == "/data-manage/master-data/animalplace"
          ? "สถานที่อยู่สัตว์"
          : ""}

        {location.pathname == "/data-manage/users/users"
          ? "รายการผู้ใช้งาน"
          : ""}
        {location.pathname == "/data-manage/users/users/save-user"
          ? "บันทึกข้อมูลผู้ใช้งาน"
          : ""}
        {location.pathname.includes("/data-manage/users/users/edit")
          ? "แก้ไขข้อมูลผู้ใช้งาน"
          : ""}
        {location.pathname == "/data-manage/users/group-user"
          ? "รายการกลุ่มผู้ใช้งาน"
          : ""}
        {location.pathname == "/data-manage/users/group-user/save-user-group"
          ? "บันทึกข้อมูลกลุ่มผู้ใช้งาน"
          : ""}
        {location.pathname.includes("/data-manage/users/group-user/edit")
          ? "แก้ไขข้อมูลกลุ่มผู้ใช้งาน"
          : ""}
        {location.pathname == "/data-manage/users/view-user"
          ? "รายการสิทธิการดูข้อมูล"
          : ""}
        {location.pathname == "/data-manage/users/view-user/save-user-view"
          ? "บันทึกสิทธิการดูข้อมูล"
          : ""}
        {location.pathname.includes("/data-manage/users/view-user/edit")
          ? "แก้ไขสิทธิการดูข้อมูล"
          : ""}

        {location.pathname == "/data-manage/report/access"
          ? "รายงาน F99RPT01-การเข้าใช้งาน"
          : ""}
        {location.pathname == "/data-manage/report/transaction"
          ? "รายงาน F99RPT02-การปรับปรุงข้อมูล"
          : ""}
      </h4>

      <ul className="navbar-nav ml-auto px-4 ">
        <div className="d-flex align-items-center">
          <span className="mr-2">
            {user && user.profile.firstName} {user && user.profile.lastName}
          </span>
          <img
            className="mr-3 user-profile border"
            src={
              user && user.profile.profileImgUrl
                ? user.profile.profileImgUrl
                : "/images/profile-user.png"
            }
            alt="profile"
          />
        </div>
        <li className="nav-item dropdown ">
          <a
            className="nav-link d-flex px-0 pt"
            data-toggle="dropdown"
            href="#"
          >
            <i className="fas fa-bars" />
          </a>

          <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
            <a className="dropdown-item" onClick={() => toLogout()}>
              <div className="media cursor-p">ออกจากระบบ</div>
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}
