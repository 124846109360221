/*global google*/ //<-- ห้ามลบ
import React, { useState, useEffect } from "react";

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
let map,
  marker,
  markers = [],
  infowindow,
  place = [];

function GoogleMap({ mapMode, location, getLocation, locationName, zoom }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`;
    script.async = true;
    window.document.body.appendChild(script);
    script.addEventListener("load", () => {
      initMap();
    });
  }, []);

  useEffect(() => {
    setLocation();
  }, [location]);

  const initMap = () => {
    if (mapMode == "show") {
      let center;
      if (location.lat != 0 && location.lng != 0) {
        center = location;
      } else {
        center = { lat: 13.756, lng: 100.501 };
      }
      map = new google.maps.Map(document.getElementById("gmap"), {
        zoom: zoom ? zoom : 12,
        center: center,
      });
      marker = new google.maps.Marker({
        position: center,
        map: map,
        draggable: false,
        title: locationName,
      });
    } else if (mapMode == "addPin") {
      let center;
      if (location.lat != 0 && location.lng != 0) {
        center = location;
      } else {
        center = { lat: 13.756, lng: 100.501 };
      }

      map = new google.maps.Map(document.getElementById("gmap"), {
        zoom: zoom ? zoom : 12,
        center: center,
      });
      marker = new google.maps.Marker({
        position: center,
        map: map,
        draggable: true,
        title: locationName,
      });

      if (getLocation) {
        google.maps.event.addListener(marker, "dragend", function () {
          getLocation({
            lat: this.getPosition().lat(),
            lng: this.getPosition().lng(),
          });
        });
      }

      map.addListener("click", async (e) => {
        getLocation({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });
        addMarker(e.latLng);
      });
    }
  };

  const addMarker = (location) => {
    if (marker) {
      marker.setPosition(location);
    } else {
      marker = new google.maps.Marker({
        position: location,
        map: map,
      });
    }
  };

  const setLocation = async () => {
    let center;
    if (location.lat != 0 && location.lng != 0) {
      center = location;
    } else {
      center = { lat: 13.756, lng: 100.501 };
    }
    if (map) {
      // map.setCenter(center);
      marker.setPosition(center);
    }
  };

  return <div id="gmap" className="h-100"></div>;
}

export default GoogleMap;
