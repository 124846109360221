import React from "react";

function BoxCard({
  classNameBox,
  title,
  children,
  classLabel,
  headRight,
  star,
  headRightReport,
}) {
  return (
    <div className={`border-card ${classNameBox ? classNameBox : ""}`}>
      <div className="box-card border-card-title px-3 py-2 d-flex justify-content-between align-items-center">
        <div>
          <span className={`bold ${classLabel}`}>{title}</span>{" "}
          {star ? <span className="text-danger">*</span> : ""}
        </div>
        <div>
          {headRightReport ? headRightReport : ""}
          {headRight ? headRight : ""}
        </div>
      </div>
      <div className="px-3 py-2 bg-white">{children}</div>
    </div>
  );
}
export default BoxCard;
