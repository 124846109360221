import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InputText from "./InputText";
import InputMask from "./InputMask";
import withReactContent from "sweetalert2-react-content";
import BoxCard from "./BoxCard";
import { logout } from "../redux/actions/logoutAction";
import { loading } from "../redux/actions/loadingAction";
import * as API from "../utils/apis";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ModalAlarmStep6 from "./Modal.AlarmStep6";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

const Label = styled.div`
  background: #ff482f;
  border-radius: 4px;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 2px 7px 4px 6px;
`;

const Label2 = styled.div`
  background: #3fc071;
  border-radius: 4px;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 2px 7px 4px 6px;
`;

function OutbreakStep6({ save, dataAll, modeGet, obId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlarmModal, setShowAlarmModal] = useState(false);
  const user = useSelector((state) => state.login.result);

  const initialForm = {
    alarm: "",
    transfer: "",
    quarantine: "",
    period: "",
    evidence: "",
    treatment_animal: "",
    bury: "",
    burn: "",
    throw_away: "",
    send_kill: "",
    disinfect: "",
    other_disinfect: "",
    vaccine: "",
    drop_anti_insect: "",
    inject_anti_insect: "",
    deal_anti_insect: "",
    public_relations: "",

    warningId: "",
    mode: "",
    farm_amt: "",
  };

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    if (modeGet == "getById" && !form.chooseSelect) {
      getDataById();
    }
  }, [user]);

  const getDataById = async () => {
    if (dataAll) {
      setForm({
        ...form,
        warningId: dataAll.warningId,
        alarm: dataAll.alarm,
        transfer: dataAll.transfer,
        quarantine: dataAll.quarantine,
        period: dataAll.period,
        evidence: dataAll.evidence,
        treatment_animal: dataAll.treatment_animal,
        bury: dataAll.bury,
        burn: dataAll.burn,
        throw_away: dataAll.throw_away,
        send_kill: dataAll.send_kill,
        disinfect: dataAll.disinfect,
        other_disinfect: dataAll.other_disinfect,
        vaccine: dataAll.vaccine,
        drop_anti_insect: dataAll.drop_anti_insect,
        inject_anti_insect: dataAll.inject_anti_insect,
        deal_anti_insect: dataAll.deal_anti_insect,
        public_relations: dataAll.public_relations,
        farm_amt: dataAll.farm_amt,
      });
    }
  };

  useEffect(async () => {
    await toSave();
  }, [form]);

  const toSave = () => {
    save(form);
  };

  const addSaveWarning = (data) => {
    const fileAdd = [];
    const delFile = [];

    if (data.warning.filesPreview && data.warning.filesPreview.length > 0) {
      data.warning.filesPreview.map((item, i) => {
        if (item.id) {
        } else {
          fileAdd.push({
            id: item.id ? item.id : "",
            name: item.name,
            data: item.base64.replace("data:", "").replace(/^.+,/, ""),
          });
        }
      });
    }

    if (data.warning.filesChooseDel && data.warning.filesChooseDel.length > 0) {
      data.warning.filesChooseDel.map((item) => {
        if (item.id) {
          delFile.push(item.id);
        }
      });
    }

    const dataWarning = {
      warning: {
        id: data.warning.warning_id ? data.warning.warning_id : "",
        obId: obId ? obId : "",
        title: data.warning.title ? data.warning.title : "",
        description: data.warning.description ? data.warning.description : "",
        statusId: data.warning.status,
      },
      warningFiles: fileAdd,
      deleteFiles: delFile,
    };

    MySwal.fire({
      text: data.mode == "add" ? "ต้องการบันทึก" : "ต้องการเปลี่ยนการแสดงผล",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.saveWarning(dataWarning);
          if (response.status == 200) {
            MySwal.fire({
              html:
                data.mode == "add" ? (
                  <>
                    บันทึกการเฝ้าระวังและเตือนภัยสำเร็จ <br />{" "}
                    สถานะจะเปลี่ยนแปลงก็ต่อเมื่อท่านบันทึก Outbreak
                    เรียบร้อยแล้ว
                  </>
                ) : (
                  "แก้ไขสำเร็จ"
                ),
              icon: "success",
              confirmButtonText: "ตกลง",
            });
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const toAddWarning = () => {
    setForm({ ...form, mode: "add" });
    setShowAlarmModal(true);
  };

  const editModalWarning = (id) => {
    setForm({ ...form, mode: "edit" });
    setShowAlarmModal(true);
  };

  return (
    <div className="p-4 bg-white ">
      <BoxCard classNameBox="" title="6.1 การดำเนินการราย Outbreak">
        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className="mr-2 ">1.</p>
              <div className="ml-3">
                <p>การเฝ้าระวังและเตือนภัย</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12} className="d-flex ">
            <div className="d-flex align-items-center mt-2 ml-3">
              {form.warningId ? (
                <Label2>ดำเนินการแล้ว</Label2>
              ) : (
                <Label>ยังไม่ดำเนินการ</Label>
              )}
              <div className="d-flex justify-content-end ">
                {form.warningId ? (
                  <button
                    className="btn btn-add text-white d-flex align-items-center ml-2"
                    onClick={() => editModalWarning()}
                  >
                    <img
                      className="img-fluid icon-btn"
                      src="/svg/btn-edit.svg"
                      alt="edit"
                    />
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-add text-white ml-2"
                      onClick={() => toAddWarning()}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className="mr-2 ">2.</p>
              <div className="ml-3">
                <p>ควบคุมการเคลื่อนย้าย </p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center mt-2 ml-3">
              <p className="mr-3">รายละเอียด</p>
              <InputText
                type="text"
                title=""
                idName="transfer"
                value={form.transfer}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) => {
                  setForm({ ...form, transfer: value });
                  toSave();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className=" mr-2 ">3.</p>
              <div className="ml-3">
                <p>การกักสัตว์ป่วย</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2">
              <div className="d-flex ml-3">
                <p className="mt-2 mr-2">กักสัตว์ (ตัว)</p>
                <InputMask
                  mask="999999999"
                  type="number"
                  title=""
                  idName="quarantine"
                  value={form.quarantine}
                  star={false}
                  placeholder="ระบุจำนวน"
                  classLabel="normal"
                  handleChange={(value) => {
                    setForm({ ...form, quarantine: value });
                    toSave();
                  }}
                />
              </div>

              <div className="d-flex ml-3">
                <p className="mt-2 mr-2">ระยะเวลา (วัน)</p>
                <InputMask
                  mask="999999999"
                  type="number"
                  title=""
                  idName="evidence"
                  value={form.evidence}
                  star={false}
                  placeholder="ระบุจำนวน"
                  classLabel="normal"
                  handleChange={(value) => {
                    setForm({ ...form, evidence: value });
                    toSave();
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className="mr-2 ">4.</p>
              <div className="ml-3">
                <p>การรักษาสัตว์ป่วย</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-3">จำนวน (ตัว)</p>
              <InputMask
                mask="999999999"
                type="number"
                title=""
                idName="treatment_animal"
                value={form.treatment_animal}
                star={false}
                placeholder="ระบุจำนวน"
                classLabel="normal"
                handleChange={(value) => {
                  setForm({ ...form, treatment_animal: value });
                  toSave();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className=" mr-2 ">5.</p>
              <div className="ml-3">
                <p>การทำลายสัตว์ป่วย</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <div className="d-flex flex-wrap mr-3">
                <p className="mt-2 mr-2">ฝังกลบ (ตัว)</p>
                <div className="d-flex ">
                  <p className="mt-2 mr-2">จำนวน</p>
                  <InputMask
                    mask="999999999"
                    type="number"
                    title=""
                    idName="bury"
                    value={form.bury}
                    star={false}
                    placeholder="ระบุจำนวน"
                    classLabel="normal"
                    handleChange={(value) => {
                      setForm({ ...form, bury: value });
                      toSave();
                    }}
                  />
                </div>
              </div>

              <div className="d-flex flex-wrap">
                <p className="mt-2 mr-5">เผา (ตัว)</p>
                <div className="d-flex ">
                  <p className="mt-2 mr-2">จำนวน</p>
                  <InputMask
                    mask="999999999"
                    type="number"
                    title=""
                    idName="burn"
                    value={form.burn}
                    star={false}
                    placeholder="ระบุจำนวน"
                    classLabel="normal"
                    handleChange={(value) => {
                      setForm({ ...form, burn: value });
                      toSave();
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center flex-wrap mt-2 ml-3 ">
              <div className="d-flex flex-wrap mr-3">
                <p className="mt-2 mr-3">คัดทิ้ง (ตัว)</p>
                <div className="d-flex">
                  <p className="mt-2 mr-2">จำนวน</p>
                  <InputMask
                    mask="999999999"
                    type="number"
                    title=""
                    idName="throw_away"
                    value={form.throw_away}
                    star={false}
                    placeholder="ระบุจำนวน"
                    classLabel="normal"
                    handleChange={(value) => {
                      setForm({ ...form, throw_away: value });
                      toSave();
                    }}
                  />
                </div>
              </div>

              <div className="d-flex flex-wrap ">
                <p className="mt-2 mr-3">ส่งโรงฆ่า (ตัว)</p>
                <div className="d-flex">
                  <p className="mt-2 mr-2">จำนวน</p>
                  <InputMask
                    mask="999999999"
                    type="number"
                    title=""
                    idName="send_kill"
                    value={form.send_kill}
                    star={false}
                    placeholder="ระบุจำนวน"
                    classLabel="normal"
                    handleChange={(value) => {
                      setForm({ ...form, send_kill: value });
                      toSave();
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center  mt-3">
              <p className=" mr-2 ">6.</p>
              <div className="ml-3">
                <p>การทำความสะอาดและฆ่าเชื้อ</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p>วิธีทำลาย</p>
              <div className="d-flex flex-wrap ml-4">
                <p className="mt-2 mr-2">1.พ่นยาฆ่าเชื้อทำลายเชื้อโรค</p>
                <p className="mt-2 mr-2">จำนวน (ราย)</p>
                <InputMask
                  mask="999999999"
                  type="number"
                  title=""
                  idName="disinfect"
                  value={form.disinfect}
                  star={false}
                  placeholder="ระบุจำนวน"
                  classLabel="normal"
                  handleChange={(value) => {
                    setForm({ ...form, disinfect: value });
                    toSave();
                  }}
                />
              </div>
            </div>

            <div className="d-flex align-items-center mt-2 ml-5">
              <div className="d-flex flex-wrap ml-5">
                <p className="mt-2 mr-2">2.อื่นๆ</p>
                <InputText
                  type="text"
                  title=""
                  idName="other_disinfect"
                  value={form.other_disinfect}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) => {
                    setForm({ ...form, other_disinfect: value });
                    toSave();
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className=" mr-2 ">7.</p>
              <div className="ml-3">
                <p>การฉีดวัคซีน</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-3">จำนวน (ตัว)</p>
              <InputMask
                mask="999999999"
                type="number"
                title=""
                idName="vaccine"
                value={form.vaccine}
                star={false}
                placeholder="ระบุจำนวน"
                classLabel="normal"
                handleChange={(value) => {
                  setForm({ ...form, vaccine: value });
                  toSave();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className=" mr-2 ">8.</p>
              <div className="ml-3">
                <p>หยดหรือราดยาป้องกันแมลง</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-3">จำนวน (ตัว)</p>
              <InputMask
                mask="999999999"
                type="number"
                title=""
                idName="drop_anti_insect"
                value={form.drop_anti_insect}
                star={false}
                placeholder="ระบุจำนวน"
                classLabel="normal"
                handleChange={(value) => {
                  setForm({ ...form, drop_anti_insect: value });
                  toSave();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center  mt-3">
              <p className=" mr-2 ">9.</p>
              <div className="ml-3">
                <p>พ่นยาป้องกันแมลง</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-3"> จำนวน (ตัว)</p>
              <InputMask
                mask="999999999"
                type="number"
                title=""
                idName="inject_anti_insect"
                value={form.inject_anti_insect}
                star={false}
                placeholder="ระบุจำนวน"
                classLabel="normal"
                handleChange={(value) => {
                  setForm({ ...form, inject_anti_insect: value });
                  toSave();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className=" mr-2 ">10.</p>
              <div className="ml-3">
                <p>แจกยากำจัดแมลงแก่เกษตรกร</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-2">จำนวน (ราย)</p>
              <InputMask
                mask="999999999"
                type="number"
                title=""
                idName="deal_anti_insect"
                value={form.deal_anti_insect}
                star={false}
                placeholder="ระบุจำนวน"
                classLabel="normal"
                handleChange={(value) => {
                  setForm({ ...form, deal_anti_insect: value });
                  toSave();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center  mt-3">
              <p className=" mr-2 ">11.</p>
              <div className="ml-3">
                <p>ประชาสัมพันให้ความรู้แก่เกษตรกร</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-2"> จำนวน (ราย)</p>
              <InputMask
                mask="999999999"
                type="number"
                title=""
                idName="public_relations"
                value={form.public_relations}
                star={false}
                placeholder="ระบุจำนวน"
                classLabel="normal"
                handleChange={(value) => {
                  setForm({ ...form, public_relations: value });
                  toSave();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center  mt-3">
              <p className=" mr-2 ">12.</p>
              <div className="ml-3">
                <p>จำนวนฟาร์ม</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-2"> จำนวน (ราย)</p>
              <InputMask
                type="number"
                title=""
                idName="farm_amt"
                value={form.farm_amt}
                star={false}
                disabled={true}
                classLabel="normal"
              />
            </div>
          </Col>
        </Row>

        {showAlarmModal && (
          <ModalAlarmStep6
            show={showAlarmModal}
            close={() => setShowAlarmModal(false)}
            save={(value) => addSaveWarning(value, "modal")}
            mode={form.mode}
            idEdit={dataAll.warningId}
          />
        )}
      </BoxCard>
    </div>
  );
}

export default OutbreakStep6;
