import React, { useState, useEffect } from "react";

import { useSelector } from 'react-redux';
import BoxCard from "./BoxCard";
import InputText from "./InputText";
import { Row, Col } from "react-bootstrap";

function DiseaseInvest_Step8({ save, dataAll, modeGet }) {
    const user = useSelector(state => state.login.result)
    const initialForm = {
        opinion: "",
    };
    const [form, setForm] = useState(initialForm);

    useEffect(async () => {
        await toSave()
    }, [form])

    useEffect(async () => {
        if (modeGet == "getById") {
            await getDataById();
        }
    }, [user])

    const getDataById = () => {
        setForm(dataAll)
    }

    const toSave = () => {
        save(form)
    }

    return (
        <div className=" bg-white p-4 ">
            <BoxCard title="8. ความคิดเห็นเพิ่มเติม">
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} >
                        <InputText type="text" as="textarea" rows={6} title="" idName="opinion" value={form.opinion} star={false}
                            placeholder="โปรดระบุ" classLabel="normal"
                            handleChange={(value) => {
                                setForm({ ...form, opinion: value })
                                toSave()
                            }}
                        />
                    </Col>
                </Row>
            </BoxCard>
        </div>
    )
}

export default DiseaseInvest_Step8
