import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Row, Col, Form } from "react-bootstrap";
import InputSelect from "./InputSelect";
import InputDatePicker from "./InputDatePicker";
import Select from 'react-select';
import { useMasterData } from "./hooks/useMasterData";
import * as API from "../utils/apis";
import helpers from "pdfmake-thai/src/helpers";
import Swal from "sweetalert2";
// import { logout } from "../../redux/actions/logoutAction";
import { useDispatch, useSelector } from "react-redux";
import * as helper from "../utils/helper";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
import userEvent from "@testing-library/user-event";

const MySwal = withReactContent(Swal);

function ModalZeroReport({ show, close, save, user, modeMap, zeroReportId, diseaseLists, zoneLists }) {

  const dispatch = useDispatch();
  const history = useHistory();

  const initialForm = {
    user: "",
    province: "",
  };
  const [form, setForm] = useState(initialForm);
  // const [provinceList, setProvinceList] = useState([]);
  const initInvalidMsg = {
    latitude: "",
    longitude: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);
  // useMasterData("province", provinceList, setProvinceList);

  const initDiseaseSelectValue = [{value:[]}];
  const [diseaseSelectValue, setDiseaseSelectValue] = useState(
    initDiseaseSelectValue
  );

  const inintZoneFilterSelectValue = [];
  const [zoneFilterSelectValue, setZoneFilterSelectValue] = useState(inintZoneFilterSelectValue);
  const [listZoneFilter, setListZoneFilter] = useState([]);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  // set value for default selection
  // const [diseaseSelectValue, setDiseaseSelectValue] = useState([
  //   { value: [] },
  //   { value: [] },
  //   { value: [] },
  // ]);
  const selectDiseaseList = [];
  const [diseaseList, setDiseaseList] = useState(selectDiseaseList);

  useEffect( async () => {
    // if (modeMap == "edit") {
      
    setForm({ ...form, user: user});

    setListZoneFilter(zoneLists)
      setDiseaseList(diseaseLists);
      if (modeMap == "edit") {
        await getZeroReportDetail(zeroReportId);
      }
    // await getDiseaseType();
    // await getZone();

  }, [user]);

  // useEffect(() => {
  //   if (modeMap == "edit") {
  //     listZoneFilter.map((item) => {
  //       if (item.value == form.zone) {
  //         setZoneFilterSelectValue([item]);
  //       }
  //     }
  //     );
  //   }
  // }, [listZoneFilter]);

  const getZeroReportDetail = async (id) => {
    try {

        const response = await API.getZeroReportDetail(id);
        const data = await response?.data;
        if (response.status === 200) {
          
          zoneLists.map((item) => {
            if  (item.value == data.zeroReport.zoneId) {
              setZoneFilterSelectValue(item);
              getProvince(item.value, data.zeroReport.provinceId);
            }
          });

          const diseaseSelect = [];
          data.zeroReportDiseaseList.map((item) => {
            diseaseSelect.push({
              value: item.diseaseTypeId,
              label: item.name,
            })
          })

          handleChange(diseaseSelect, 0);

          setForm({ ...form, id: data.zeroReport.id, createId:data.zeroReport.createId, createDate: data.zeroReport.createDate, reportDate: data.zeroReport.zeroreportDate, zone: data.zeroReport.zoneId, province: data.zeroReport.provinceId })

          
          // setForm({...form, reportDate: data.zeroReport.zeroreportDate})
          // provinceList.map((item) => {
          //   if  (item.value == data.zeroReport.provinceId) {
          //     setProvinceSelectValue(item);
          //   }
          // });
        }
    } catch (error) {
        // if (error.response && error.response.status === 401) {
        //     dispatch(logout({ history }));
        // }
        console.log(error);
    }
};

const updateZeroReport = async () => {
  try {


    // const dd = diseaseSelectValue[0].value;

    const dd = [];
    // diseaseList.filter((obj) => diseaseSelectValue[0].value.includes(obj.value))
    if (diseaseSelectValue[0].value && diseaseSelectValue[0].value.length > 0) {
      diseaseSelectValue[0].value.map((item) => {
        const param = {
          id : "",
          zeroReportId : form.id,
          diseaseTypeId : item,
        }
        dd.push(param);
      });
    }

    const params = { zeroReport : {
      id : form.id,
      zoneId : form.zone ? form.zone : "",
      provinceId : form.province ? form.province : "",
      zeroreportDate : form.reportDate ? helper.getDate(form.reportDate) : "",
      createId : form.createId ? form.createId : "",
      createDate : form.createDate ? helper.getDate(form.createDate) : "",
      updateId : form.updateId ? form.updateId : "",
      updateDate : form.updateDate ? helper.getDate(form.updateDate) : "",
      statusId: 1,
      
      
    },
    zeroReportDiseaseList : dd
  };
      
      const response = await API.updateZeroReport(params);
      const data = await response?.data;
      if (response.status === 200) {
        MySwal.fire({
          text: `แก้ไข "บันทึกการรายงานโรค" สำเร็จ`,
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then(async (result) => {
          save(form);
          handleClose();
        });
      }
  } catch (error) {
      // if (error.response && error.response.status === 401) {
      //     dispatch(logout({ history }));
      // }
      console.log(error);
  }
};



  const addZeroReport = async () => {
    try {


      // const dd = diseaseSelectValue[0].value;

      const dd = [];
      if (diseaseSelectValue[0].value && diseaseSelectValue[0].value.length > 0) {
        diseaseSelectValue[0].value.map((item) => {
          const param = {
            id : "",
            zeroReportId : "",
            diseaseTypeId : item,
          }
          dd.push(param);
        });
      }

      const params = { zeroReport : {
        id : "",
        zoneId : form.zone ? form.zone : "",
        provinceId : form.province ? form.province : "",
        zeroreportDate : form.reportDate ? helper.getDate(form.reportDate) : "",
        
      },
      zeroReportDiseaseList : dd
    };
        
        const response = await API.addZeroReport(params);
        const data = await response?.data;
        if (response.status === 200) {
          MySwal.fire({
            text: `สร้าง "บันทึกการรายงานโรค" สำเร็จ`,
            icon: "success",
            confirmButtonText: "ตกลง",
          }).then(async (result) => {
            save(form);
            handleClose();
          });
        }
    } catch (error) {
        // if (error.response && error.response.status === 401) {
        //     dispatch(logout({ history }));
        // }
        console.log(error);
    }
};

  const getZone = async (id) => {
    try {
      const response = await API.getZone();
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        // let userrrr = user
        if  (user != null) {
          let userZone = user.userView.viewArea.zone_id
          let data2 = [];
          userZone.forEach((element) => {
            data.content.forEach((element2) => {
              if (element == element2.id) {
                data2.push({
                  value: element2.id,
                  label: element2.name,
                });
              }
              
            });
            
          });
          data2 = data2.sort((a,b) => (a.value > b.value) ? 1 : -1);
          setListZoneFilter(data2);
          
          // setZoneFilterSelectValue([data2[0]])
          // setForm({ ...form, zone: data2[0].value });
          getProvince(data2[0].value, null);
        } else {
          setListZoneFilter(villageArray);
          // setZoneFilterSelectValue([villageArray[0]])
          // setForm({ ...form, zone: villageArray[0].value });
          getProvince(villageArray[0].value, null);
          
        }

        if  (modeMap == "edit") {
          villageArray.map((item) => {
            if (item.value == form.zone) {
              setZoneFilterSelectValue(item);
            }
          })
          
        }

        // setIsClearSearch(!isClearSearch);
        
        // setZoneFilterSelectValue([villageArray[0]])
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (zone, selectId) => {
    console.log(zone);
    try {
      const response = await API.getProvince(zone, 1);
      const data = await response.data;
      // console.log(data.content);
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          if (user != null) {
            var userGroupId = user.userGroup.id;
            if(userGroupId == 6) {
                var arrList = user.userView.viewArea.province_id
                arrList.forEach((element2) => {
                    if (element.id == element2) {
                        provincesArray.push({
                            value: element.id,
                            label: element.name,
                        });
                    }
                });
            } else {
                provincesArray.push({
                    value: element.id,
                    label: element.name,
                });
            }
        } else {
            provincesArray.push({
                value: element.id,
                label: element.name,
            });
        }
        });
        setProvinceList(provincesArray);
        if (selectId != null) {
          provincesArray.map((item) => {
            if (item.value == selectId) {
              setProvinceSelectValue(item);
            }
          })
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType();
      const data = await response.data;
      if (response.status === 200) {
        let diseaseTypeArray = [];
        data.content.forEach((element) => {
          diseaseTypeArray.push({
            value: element.id,
            label: element.name,
          });
        });
        
        setDiseaseList(diseaseTypeArray);

        // if (modeMap == "edit") {
        //   setDiseaseSelectValue(
        //     diseaseSelectValue.map((item) => {
        //       return diseaseSelectValue.indexOf(item) === no
        //         ? { value: Array.isArray(e) ? e.map((x) => x.value) : [] }
        //         : item;
        //     })
        //   );
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {}, [form.latitude, form.longitude]);

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if (form.province === "") {
      addInvalid("province", "กรุณาระบุจังหวัด");
      validated = false;
    }

    if (form.zone === "") {
      addInvalid("zone", "กรุณาระบุปศุสัตว์จังหวัด");
      validated = false;
    }

    if (form.reportDate === "" || form.reportDate === undefined) {
      addInvalid("reportDate", "กรุณาระบุวันที่รายงาน");
      validated = false;
    }

    return validated;
  };

  const toSave = () => {
    if (validate()) {
      if  (modeMap == "edit") {
        updateZeroReport();
      } else {
        addZeroReport();
      }
      
      // save(form);
      // handleClose();
    }
  };

  const handleClose = () => {
    close();
    setInvalid(initInvalidMsg);
    setForm(initialForm);
  };

  // handle onChange event of the dropdown
  const handleChange = (e, no) => {
    setDiseaseSelectValue(
      diseaseSelectValue.map((item) => {
        return diseaseSelectValue.indexOf(item) === no
          ? { value: Array.isArray(e) ? e.map((x) => x.value) : [] }
          : item;
      })
    );
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header closeButton className="pb-2 underline">
        <Modal.Title className="mt-2">
          <h5>
            <strong>รายงาน Zero Report</strong> 
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
      <div className="mt-2 px-2">
              <Row className="mb-2 ">
              <Col lg={8} md={6} sm={8} xs={8}>
              <Form.Label className={`normal mb-1`}> ชนิดโรค</Form.Label>
                  <Select
                    // defaultValue={[colourOptions[2], colourOptions[3]]}
                    selectMultiple={true}
                    isMulti
                    // name="colors"
                    // options={}

                    className="basic-multi-select"
                    placeholder="กรุณาเลือก"
                    // value={diseaseSelectValue}
                    options={diseaseList}
                    value={diseaseList.filter((obj) => diseaseSelectValue[0].value.includes(obj.value))} // set selected values
                    onChange={(event) => handleChange(event, 0)}
                    // onChange={(value) => {
                    //   setDiseaseSelectValue(value);
                    //   // setForm({ ...form, province: value.value });
                    // }}
                  // handleInvalid={() => removeInvalid("diseaseList")}
                  // invalid={invalid.diseaseList}
                  />
                </Col>
              </Row>
              <Row className="mb-2 ">
                <Col lg={4} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="สำนักงานปศุสัตว์เขต"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="zone"
                    classLabel="normal"
                    selectValue={zoneFilterSelectValue}
                    optionsList={listZoneFilter}
                    handleChange={(value) => {
                      setZoneFilterSelectValue([value]);
                      getProvince(value.value, null);
                      setForm({ ...form, zone: value.value });
                    }}
                  />
                </Col>
                <Col lg={4} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => {
                      setProvinceSelectValue(value);
                      setForm({ ...form, province: value.value });
                    }}
                    handleInvalid={() => removeInvalid("province")}
                    invalid={invalid.province}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={4} sm={6} xs={6}>
                  <InputDatePicker
                    title="วัน/เวลาที่รายงาน"
                    classLabel="normal"
                    placeholder="วัน/เวลาที่รายงาน"
                    value={form.reportDate}
                    star={true}
                    // minDate={ new Date().setDate( new Date().getDate() - 3)}
                    handleChange={(value) =>

                      setForm({ ...form, reportDate: value })
                    }
                    handleInvalid={() => removeInvalid("reportDate")}
                    invalid={invalid.reportDate}
                  />
                </Col>
              </Row>
              <Row >
                <div style={{ 'border-radius': '10px', 'background': '#F0F3F5', 'padding': '20px'}}>
                ** ส่งรายงานทุกวันกรณีไม่พบสัตว์ปีกป่วยตายตามนิยามโรคไข้หวัดนกตัดรอบรายงานสรุปประจำวันที่ 16.00 น. (ส่งช้าได้ไม่เกิน 3 วัน)<br/>** รายงานไม่พบโรค ASF FMD LSD PPR สัปดาห์ละครั้ง โดยให้รายงานไม่เกิน 16.00 น. ของทุกวันจันทร์ <br/>** โรคอเมริกันฟาล์วบรูด และโรคยูโรเปียนฟาล์วบรูด เดือนละครั้งทุกวันที่ 1 ของเดือน ก่อนเวลา 24.00 น
                </div>
              </Row>
              <div className="mt-auto py-3" style={{"text-align": "end"}}>
              <button
            className="btn px-4 btn-sm btn-danger mr-2 cursor-p"
            onClick={()=>handleClose()}>
            ยกเลิก
          </button>
            <button
              className="btn btn-sm btn-success px-4 mr-2"
              onClick={() => toSave()}
            >
              บันทึก
            </button>
          </div>
            </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalZeroReport;
