import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { loading } from "../../redux/actions/loadingAction";
import * as helper from "../../utils/helper";
import { Row, Col, Form } from "react-bootstrap";

import pdfMake from "pdfmake-thai/build/pdfmake";
import pdfFonts from "pdfmake-thai/build/vfs_fonts";

import BoxCard from "../../components/BoxCard";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "../../components/DataTable";
import * as API from "../../utils/apis";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useMasterData } from "../../components/hooks/useMasterData";

import Pdf from "react-to-pdf";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Record_Result() {
  const ref = React.createRef();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "footer"]);
  const user = useSelector((state) => state.login.result);
  const [listRecordResult, setRecordResult] = useState([]);

  // select จังหวัด อำเภอ
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  // select เขตปศุสัตว์
  const [zoneLivestockList, setZoneLivestockList] = useState([]);
  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState([]);

  const [fileType, setFileType] = useState("excel");
  const fileName = "C03RPT01-RecordResultReport_" + helper.getDate(new Date());

  const [isClearSearch, setIsClearSearch] = useState(false);

  // =================

  // select  ปีงบประมาณ  โครงการ   ชนิดสัตว์
  const [fiscalYearSelectValue, setFiscalYearSelectValue] = useState([]);
  const [fiscalYearList, setFiscalYearList] = useState([]);

  const [projectSelectValue, setProjectSelectValue] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);
  // =================

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/active_surv/action_plan", name: "การเฝ้าระวังเชิงรุก" },
    { path: "/active_surv/report/record_result", name: "รายงาน" },
    { path: "active", name: "C03RPT01-การบันทึกผล" },
  ];

  pdfMake.fonts = {
    THSarabunNew: {
      normal: "THSarabunNew.ttf",
      bold: "THSarabunNew-Bold.ttf",
      italics: "THSarabunNew-Italic.ttf",
      bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
  };

  const initialForm = {
    year: "",
    projectName: "",
    animalTypeId: "",
    animalTypeName: "",

    id: "",
    provinceId: "",
    amphurId: "",
    tambonId: "",
    mooId: "",
    address: "",
    soi: "",
    zoneName: "",
    zoneId: "",
    provinceName: "",
    amphurName: "",
    tambonName: "",
    mooName: "",
    startDate: "",
    endDate: "",
    startDateName: "",
    endDateName: "",
    targetAmount: "",
    collectedAmount: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        name: "ลำดับ",
        width: "50px",
        rowSpan: 2,
      },
      {
        sortBy: "year",
        name: "ปีงบประมาณ",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "projectName",
        name: "โครงการ",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "animalTypeName",
        name: "ชนิดสัตว์",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        name: "สถานที่เก็บข้อมูล",
        sortFlg: false,
        colSpan: 5,
        childHead: [
          {
            sortBy: "zoneName",
            name: "เขต",
            sortFlg: true,
          },
          {
            sortBy: "provinceId",
            name: "จังหวัด",
            sortFlg: true,
          },
          {
            sortBy: "amphurId",
            name: "อำเภอ",
            sortFlg: true,
          },
          {
            sortBy: "tambonId",
            name: "ตำบล",
            sortFlg: true,
          },
          {
            sortBy: "address",
            name: "ที่อยู่",
            sortFlg: true,
          },
        ],
      },
      {
        sortBy: "targetAmount",
        name: "จำนวนข้อมูล(บันทึก/เป้า)",
        sortFlg: true,
        rowSpan: 2,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );

  useEffect(() => {
    getActionPlanYear();
    getActionProject();
    getRecordResultReport();
    getAnimal();
    // getProvince();
    getZoneLivestock();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getRecordResultReport = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getRecordResultReport(
        form.startDate ? helper.getDate(form.startDate) : "",
        form.endDate ? helper.getDate(form.endDate) : "",
        form.zoneId,
        form.provinceId,
        form.amphurId,
        form.year,
        form.projectName,
        form.animalTypeId,
        form.sortBy,
        form.sortDir,
        form.page,
        form.limit
      );
      const data = await response?.data;
      if (response.status == 200) {
        setRecordResult(data.content);
        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRecordResultoExport = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getRecordResultReport(
        form.startDate ? helper.getDate(form.startDate) : "",
        form.endDate ? helper.getDate(form.endDate) : "",
        form.zoneId,
        form.provinceId,
        form.amphurId,
        form.year,
        form.projectName,
        form.animalTypeId,
        form.sortBy,
        form.sortDir,
        1,
        form.totalElements
      );
      const data = await response?.data;
      if (response.status == 200) {
        let recordResultArray = [];
        data.content.forEach((element) => {
          recordResultArray.push({
            id: element.id,
            year: element.year,
            projectName: element.projectName,
            provinceId: element.provinceId,
            amphurId: element.amphurId,
            tambonId: element.tambonId,
            mooId: element.mooId,
            address: element.address,
            soi: element.soi,
            zoneName: element.zoneName,
            provinceName: element.provinceName,
            amphurName: element.amphurName,
            tambonName: element.tambonName,
            mooName: element.mooName,
            animalTypeId: element.animalTypeId,
            animalTypeName: element.animalTypeName,
            targetAmount: element.targetAmount,
            collectedAmount: element.collectedAmount,
            startDate: element.startDate,
            endDate: element.endDate,
            startDateName: element.startDateName,
            endDateName: element.endDateName,
          });
        });
        downloadRecord(recordResultArray, data.content);
        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActionProject = async () => {
    try {
      const response = await API.getActionProject();
      const data = await response.data;
      if (response.status == 200) {
        let projectArray = [];
        data.forEach((element) => {
          projectArray.push({
            value: element,
            label: element,
          });
        });
        setProjectList(projectArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActionPlanYear = async () => {
    try {
      const response = await API.getActionPlanYear();
      const data = await response.data;
      if (response.status == 200) {
        let yearArray = [];
        data.forEach((element) => {
          yearArray.push({
            value: element,
            label: element,
          });
        });
        setFiscalYearList(yearArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setZoneLivestockSelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setDistrictList([]);
    setProjectSelectValue([]);
    setFiscalYearSelectValue([]);
    setAnimalSelectValue([]);
    setFileType("excel");
    setIsClearSearch(!isClearSearch);
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const buttonSearch = () => {
    return (
      <>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const buttonExport = () => {
    return (
      <>
        <button
          className="btn btn-report"
          onClick={() => getRecordResultoExport()}
          disabled={user?.userGroup.mexport === "n"}
        >
          <a className="text-white">
            <img
              className="nav-icon"
              src={"/svg/report.svg"}
              alt="export-icon"
            />{" "}
            ออกรายงาน
          </a>
        </button>
      </>
    );
  };

  const convertListToExcelTable = (objArray) => {
    let excelTable = [];
    let dataAmt = "จำนวนข้อมูล(บันทึก/เป้า)";
    objArray.map((element, index) => {
      excelTable.push({
        ลำดับ: index + 1,
        ปีงบประมาณ: element.year,
        โครงการ: element.projectName,
        ชนิดสัตว: element.animalTypeName,
        เขต์: element.zoneName,
        จังหวัด: element.provinceName,
        อำเภอ: element.amphurName,
        ตำบล: element.tambonName,
        ที่อยู่: element.mooName,
      });
      excelTable[index][dataAmt] =
        element.collectedAmount + "/" + element.targetAmount;
    });
    return excelTable;
  };

  const convertToCSV = (objArray) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let headCol = "";
    let dataRow = "";
    let key = Object.keys(array[0]);
    key.forEach((item) => {
      if (headCol === "") {
        headCol = item;
      } else {
        if (item != "") {
          headCol += ",";
          headCol += item;
        }
      }
    });
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") {
          line += ",";
        }
        line += array[i][index];
      }
      dataRow += line + "\r\n";
    }
    return headCol + "\r\n" + dataRow;
  };

  const convertToExcel = (objArray) => {
    const workSheet = XLSX.utils.json_to_sheet(objArray);
    const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    return XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  };

  const printPDF = (fileName, dataListForPDF) => {
    let listRecordPdf = [];
    if (dataListForPDF && dataListForPDF.length > 0) {
      dataListForPDF.map((item, i) => {
        listRecordPdf.push([
          i + 1,
          item.year,
          item.projectName,
          item.animalTypeName,
          item.zoneName,
          item.provinceName,
          item.amphurName,
          item.tambonName,
          item.mooName,
          item.collectedAmount + "/" + item.targetAmount,
        ]);
      });
    }

    let bodyArray = [
      [
        {
          text: "ลำดับ",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "ปีงบประมาณ",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "โครงการ",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "ชนิดสัตว์",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "สถานที่เก็บข้อมูล",
          style: "tableHeader",
          alignment: "center",
          colSpan: 5,
        },
        {},
        {},
        {},
        {},
        {
          text: "จำนวนข้อมูล (บันทึก/เป้า)",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
      ],
      [
        {},
        {},
        {},
        {},
        { text: "เขต", style: "tableHeader", alignment: "center" },
        { text: "จังหวัด", style: "tableHeader", alignment: "center" },
        { text: "อำเภอ", style: "tableHeader", alignment: "center" },
        { text: "ตำบล", style: "tableHeader", alignment: "center" },
        { text: "ที่อยู่", style: "tableHeader", alignment: "center" },
        {},
      ],
    ];

    listRecordPdf.map((item, i) => {
      bodyArray.push(listRecordPdf[i]);
    });

    let docDefinition = {
      pageMargins: [5, 5, 5, 5],
      content: [
        {
          style: "tableExample",
          table: {
            widths: [
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
            ],
            headerRows: 1,
            body: bodyArray,
          },
        },
      ],
      defaultStyle: {
        fontSize: 12,
        font: "THSarabunNew",
      },
    };
    pdfMake.createPdf(docDefinition).download(fileName + ".pdf");
  };

  const downloadRecord = (dataList, dataListForPDF) => {
    let type = "";
    if (fileType === "excel") {
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = convertToExcel(convertListToExcelTable(dataList));
      const data = new Blob([excelBuffer], { type: type });
      FileSaver.saveAs(data, fileName + ".xlsx");
    }
    if (fileType === "csv") {
      type = "text/csv;charset=utf-8";
      const csv = convertToCSV(convertListToExcelTable(dataList));
      const csvData = new Blob([csv], { type: type });
      FileSaver.saveAs(csvData, fileName + ".csv");
    }
    if (fileType === "pdf") {
      printPDF(fileName, dataListForPDF);
    }
  };

  const checkExport = (value) => {
    if (fileType === value) {
      return true;
    } else {
      return false;
    }
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td className="text-center">{item.year}</td>
          <td className="text-center">{item.projectName}</td>
          <td className="text-center">{item.animalTypeName}</td>
          <td className="text-center">{item.zoneName}</td>
          <td className="text-center">{item.provinceName}</td>
          <td className="text-center">{item.amphurName}</td>
          <td className="text-center">{item.tambonName}</td>
          <td className="text-center">{item.mooName}</td>
          <td className="text-center">
            {item.collectedAmount}/{item.targetAmount}
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="10" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่บันทึกข้อมูล"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.startDate}
                    star={false}
                    handleChange={(value) => {
                      let sDate = new Date(value);
                      setForm({ ...form, startDate: sDate.getTime() });
                    }}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.endDate}
                    star={false}
                    handleChange={(value) => {
                      let eDate = new Date(value);
                      setForm({ ...form, endDate: eDate.getTime() });
                    }}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ปีงบประมาณ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={fiscalYearSelectValue}
                    optionsList={fiscalYearList}
                    handleChange={(value) => {
                      setFiscalYearSelectValue([value]);
                      setForm({ ...form, year: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="โครงการ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={projectSelectValue}
                    optionsList={projectList}
                    handleChange={(value) => {
                      setProjectSelectValue([value]);
                      setForm({ ...form, projectName: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={animalSelectValue}
                    optionsList={animalList}
                    handleChange={(value) => {
                      setAnimalSelectValue([value]);
                      setForm({ ...form, animalTypeId: value.value });
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขตปศุสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={zoneLivestockSelectValue}
                    optionsList={zoneLivestockList}
                    handleChange={(value) => {
                      setZoneLivestockSelectValue([value]);
                      setForm({ ...form, zoneId: value.value });
                      getProvince(value.value);
                    }}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => {
                      setDistrictSelectValue(value);
                      setForm({ ...form, amphurId: value.value });
                    }}
                  />
                </Col>

                <Col>
                  <Col lg={3} md={6} sm={12}>
                    <label style={{ fontWeight: "normal" }}>เลือกรูปแบบ</label>
                  </Col>
                  <Col lg={9} md={9} sm={12} className="w-100">
                    <div className="d-flex mt-2 ">
                      <label style={{ fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="excel"
                          name="data-export"
                          checked={checkExport("excel")}
                          onChange={() => setFileType("excel")}
                        />{" "}
                        EXCEL
                      </label>
                      <div className="ml-2">
                        <label style={{ fontWeight: "normal" }}>
                          <input
                            type="radio"
                            value="pdf"
                            name="data-export"
                            checked={checkExport("pdf")}
                            onChange={() => setFileType("pdf")}
                          />{" "}
                          PDF
                        </label>
                      </div>

                      <div className="ml-2">
                        <label style={{ fontWeight: "normal" }}>
                          <input
                            type="radio"
                            value="csv"
                            name="data-export"
                            checked={checkExport("csv")}
                            onChange={() => setFileType("csv")}
                          />{" "}
                          CSV
                        </label>
                      </div>
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการข้อมูลรายงาน C03RPT01-การบันทึกผล"
            headRight={buttonExport()}
          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
              id={"data-table"}
              id={"render-data-table"}
              reference={ref}
            >
              {renderTable(listRecordResult)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}
export default Record_Result;
