import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import * as API from "../utils/apis";
import { login } from '../redux/actions/loginAction';
import HeaderHome from "../components/HeaderHome";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal)

function LineCallback(props) {
    const history = useHistory();
    const { params } = props.match
    const dispatch = useDispatch();

    // console.log("props LineCallback", props);

    useEffect(() => {
        
        // console.log("params query", params);
        if (params && params.code) {
            // lineAccessToken();
        }
    }, [])

    const lineAccessToken = () => {
        // console.log("query params", params);
        if (params.code && params.state) {
            API
                .getLineAccessToken(params.code)
                .then((response) => {
                    // console.log("get access token", response);
                    lineGetInformation(response.data);
                })
                .catch((error) => {
                    console.log(error.response);
                    // MySwal.fire({
                    //   title: "Error",
                    //   icon: "warning",
                    //   confirmButtonText: "ตกลง",
                    //   text: error.response.data.error_description,
                    // }).then((res) => {
                    //   if (res.value) {
                    //     router.push("/");
                    //   }
                    // });
                    history.push("/");
                });
        }
    };

    const lineGetInformation = (data) => {
        API
            .getLineInformation(data.id_token)
            .then((response) => {
                // console.log("get info", response);
                checkSocial(response.data.sub, response.data, "line");
            })
            .catch((error) => {
                console.log(error.response);
                MySwal.fire({
                    title: "Error",
                    icon: "warning",
                    confirmButtonText: "ตกลง",
                    text: error.response.data.error_description,
                }).then((res) => {
                    if (res.value) {
                        history.push("/login");
                    }
                });
                history.push("/login");
            });
    };

    const checkSocial = async (socialId, profile, socialType) => {
        let response;
        try {
            response = await API.checkLine(socialId);
            const data = await response.data;
            if (response.status == 200) {
                if (socialType == 'line') {
                    if (data?.data.isLine) {
                        loginSocial(socialId, socialType)
                    } else {
                        history.push("/login");
                    }
                }

                console.log("check Line", data);
                // dispatch(login(data))
                // dispatch(modal_login(false))
            }
        } catch (error) {
            console.log("error", error);
            const mgeError = error?.response?.data.errors[0]
            MySwal.fire({
                text: mgeError,
                icon: 'warning',
                confirmButtonText: 'ตกลง'
            })
        }
    };

    const loginSocial = async (socialId, socialType) => {
        let response;
        const socialData = {
            "socialId": socialId
        }
        try {
            if (socialType === "line") {
                response = await API.loginLine(socialData);
            }
            const data = await response.data;
            if (response.status == 200) {
                dispatch(login({ data, history }))
                // dispatch(login(data))
                // dispatch(modal_login(false))
            }
        } catch (error) {
            const mgeError = error?.response?.data.errors[0]
            MySwal.fire({
                text: mgeError,
                icon: 'warning',
                confirmButtonText: 'ตกลง'
            })
        }
    }

    return (
        <div>
            <HeaderHome />
            <section className="py-3">
                <Container className="text-center">
                    loading . . .
                </Container>
            </section>
        </div>
    )
}

export default LineCallback
