import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col, Table } from 'react-bootstrap';

import * as API from "../../utils/apis";
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";

function ModalMenuSystem({ show, close, save, mode, idEdit }) {
    const user = useSelector(state => state.login.result);
    const initialForm = {
        menuId: "",
        moduleId: "",
        menuName: "",
        url: "",
        icon: "",
        orderIndex: "",
        statusId: "",

        createDate: "",
        updateDate: "",
        createByName: "",
        updateByName: "",

        checkShow: false
    };
    const initInvalidMsg = {
        moduleId: "",
        name: "",
        status: "",
        url: "",
    };
    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];

    const dispatch = useDispatch();
    const history = useHistory();

    const [form, setForm] = useState(initialForm);

    const [invalid, setInvalid] = useState(initInvalidMsg);

    //==========Module
    const [moduleSelectValue, setModuleSelectValue] = useState([])
    const [moduleList, setModuleList] = useState([])

    const [statusSelectValue, setStatusSelectValue] = useState([]);
    const [statusList, setStatusList] = useState(selectStatusList);

    useEffect(() => {
        if (mode === "add") {
            setForm(initialForm)
            setStatusSelectValue([])
        }
    }, [mode])

    useEffect(() => {
        if ((form.moduleId && form.moduleId === "")|| form.moduleId === "") {
            getModule()
        }
    }, [form.moduleId])

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;
        if (form.moduleId === "") {
            addInvalid("moduleId", "โปรดระบุ Module");
            validated = false;
        }
        if (form.menuName === "") {
            addInvalid("name", "โปรดระบุชื่อ Menu");
            validated = false;
        }
        if (form.url === "") {
            addInvalid("url", "โปรดระบุ URL");
            validated = false;
        }
        if (form.statusId === "") {
            addInvalid("status", "โปรดระบุสถานะ");
            validated = false;
        }
        return validated;
    };

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setModuleSelectValue([])
        setStatusSelectValue([])
    }

    const toConfirm = async () => {
        if (validate()) {
            await save({ menu: form, mode: mode });
        }
    };

    const getMenuById = async (id, moduleArray) => {
        let dataMenu;
        try {
            const response = await API.getMenuById(id);
            const data = await response?.data;
            if (response?.status === 200) {
                dataMenu = data;
            }
        } catch (error) {
            if (error?.response && error?.response?.status === 401) {
                dispatch(logout({ history }))
            }
            console.log(error)
        }

        if (dataMenu.statusId) {
            let statusSelect = statusList.find(item => item.value === dataMenu?.statusId);
            setStatusSelectValue(statusSelect);
        }

        if (dataMenu.moduleId) {
            let moduleSelect = moduleArray.find((item) => item.value === dataMenu?.moduleId)
            setModuleSelectValue(moduleSelect)
        }
        setForm({
            ...form,
            menuId: dataMenu.id,
            menuName: dataMenu.name,
            moduleId: dataMenu.moduleId,
            url: dataMenu.url,
            icon: dataMenu.icon,
            statusId: dataMenu.statusId,
            orderIndex: dataMenu.orderIndex?dataMenu.orderIndex:"",

            createDate: dataMenu.createDate,
            updateDate: dataMenu.updateDate,
            createByName: dataMenu.createByName,
            updateByName: dataMenu.updateByName,
        })
    }

    const getModule = async () => {
        try {
            const response = await API.getModule();
            const data = await response?.data;
            if (response.status === 200) {
                let moduleArray = [];
                data.content.forEach(element => {
                    moduleArray.push({
                        value: element.id, label: element.name,
                    });
                });
                setModuleList(moduleArray);

                if (mode === "edit" && !form?.checkShow) {
                    getMenuById(idEdit, moduleArray)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => handleClose()}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่ม/แก้ไขเมนู</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="โมดูล"
                                        star={true}
                                        placeholder="กรุณาเลือก"
                                        idName="moduleId"
                                        classLabel="normal"
                                        selectValue={moduleSelectValue}
                                        optionsList={moduleList}
                                        handleChange={(value) => {
                                            setModuleSelectValue(value)
                                            setForm({ ...form, moduleId: value.value })
                                        }}
                                        handleInvalid={() => removeInvalid("moduleId")}
                                        invalid={invalid.moduleId}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="ชื่อเมนู" idName="name" value={form.menuName} star={false}
                                        star={true}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, menuName: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("name")}
                                        invalid={invalid.name}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="ลำดับ" idName="order-index" value={form.orderIndex} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => {
                                            const pattern = /^\d+$/;
                                            if (pattern.test(value) || !value) {
                                                setForm({ ...form, orderIndex: value, checkShow: true })
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="URL" idName="url" value={form.url} star={false}
                                        star={true}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, url: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("url")} invalid={invalid.url}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                        star={true}
                                        selectValue={statusSelectValue} optionsList={statusList}
                                        handleChange={(value) => {
                                            setStatusSelectValue(value)
                                            setForm({ ...form, statusId: value.value, checkShow: true })
                                        }}
                                        handleInvalid={() => removeInvalid("status")} invalid={invalid.status}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Table>
                                    <tr>
                                        <td>
                                            <div className="d-flex align-items-left text-gray font12">
                                                วันที่บันทึกข้อมูล
                                            </div>
                                        </td>
                                        <td>
                                            <div className="align-items-left text-gray font12">
                                                {form.createDate ? helper.momentDateTime(form.createDate, "th", "short") + " " + helper.getTime(form.createDate, "th") : '-'}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <div className="align-items-left text-gray font12">
                                                {form.createByName || '-'}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="align-items-left text-gray font12">
                                                วันที่ปรับปรุงข้อมูล
                                            </div>
                                        </td>
                                        <td>
                                            <div className="align-items-left text-gray font12">
                                                {form.updateDate ? helper.momentDateTime(form.updateDate, "th", "short") + " " + helper.getTime(form.updateDate, "th") : '-'}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <div className="align-items-left text-gray font12">
                                                {form.updateByName || '-'}
                                            </div>
                                        </td>
                                    </tr>
                                </Table>
                            </Row>
                        </div>
                    </div>
                </div>

                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalMenuSystem
