/*global google*/
import React, { useEffect, useState } from 'react'

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
let map,
  marker,
  circle;

export default function MapRadius({ location = { lat: 13.756, lng: 100.501 }, radius = 0, getLocation }) {

  const [locationMap, setLocationMap] = useState(location)
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`;
    script.async = true;
    window.document.body.appendChild(script);
    script.addEventListener("load", () => {
      initMap();
    });
  }, [])

  useEffect(() => {
    if (map) {
      initRadius()
    }
  }, [radius])

  useEffect(() => {
    if (map) {
      addMarker(location)
    }
  }, [location])

  const setLocation = (val) => {
    getLocation(val)
    setLocationMap(val)
  }

  const initRadius = () => {
    if (google) {

      if (circle) {
        circle.setMap(null);
      }

      circle = new google.maps.Circle({
        map: map,
        radius: parseInt(radius) * 1000 || 0,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#000000",
        fillOpacity: 0.2,
      });
      circle.bindTo('center', marker, 'position');
    }
  }

  const initMap = () => {
    map = new google.maps.Map(document.getElementById("map"), {
      zoom: 12,
      center: locationMap,
    });

    marker = new google.maps.Marker({
      map: map,
      position: locationMap,
      draggable: true,
    });

    google.maps.event.addListener(marker, "dragend", function (e) {
      setLocation({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
    });

    map.addListener("click", async (e) => {
      setLocation({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
      addMarker(e.latLng);
    });
  }

  const addMarker = (location) => {
    if (marker) {
      marker.setPosition(location);
      map.setCenter(location)
    } else {
      marker = new google.maps.Marker({
        position: location,
        map: map
      });
    }
  }

  return (
    <div id="map" className="h-100"></div>
  )
}
