import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import renderHTML from "react-render-html";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as helper from "../../../utils/helper";
import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTable from "../../../components/DataTable";
import DataTableNodata from "../../../components/DataTableNodata";
import InputDatePicker from "../../../components/InputDatePicker";
import ModalWarning from "../../../components/Data_Management/Modal.Warning";

const MySwal = withReactContent(Swal);

function Warning() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/web-settings/news", name: "ตั้งค่าเวปไซต์" },
    { path: "active", name: "เตือนภัยโรคระบาด" },
  ];

  const initialForm = {
    warning_id: "",
    fullName: "",
    tel: "",
    description: "",
    title: "",
    foundDiseaseDat: "",
    createDate: "",
    statusId: "",
    toDate: "",

    idEdit: "",
    mode: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        name: "ลำดับ",
        width: "50px",
      },
      {
        sortBy: "title",
        name: "หัวข้อ",
        sortFlg: true,
      },
      {
        sortBy: "description",
        name: "รายละเอียด",
        sortFlg: false,
      },
      {
        sortBy: "fullName",
        name: "ข้อมูลผู้แจ้ง",
        sortFlg: true,
      },
      {
        sortBy: "foundDiseaseDate",
        name: "วันที่พบโรค",
        sortFlg: true,
      },
      {
        sortBy: "createDate",
        name: "วันที่แจ้ง",
        sortFlg: true,
      },
      {
        sortBy: "statusId",
        name: "การแสดงผล",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [showWarning, setShowWarning] = useState(false);

  const [listWarning, setListWarning] = useState([]);

  const [isClearSearch, setIsClearSearch] = useState(false);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const selectStatusList = [
    { value: 1, label: "แสดง" },
    { value: 2, label: "ไม่แสดง" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  useEffect(() => {
    getWarning();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getWarning = async () => {
    let dateBegin = "";
    let dateEnd = "";
    if (form.createDate) {
      dateBegin = helper.getDate(form.createDate);
    }
    if (form.toDate) {
      dateEnd = helper.getDate(form.toDate);
    }
    dispatch(loading(true));
    try {
      const response = await API.getWarning(
        form.statusId,
        dateBegin,
        dateEnd,
        form.sortBy,
        form.sortDir,
        form.page,
        form.limit
      );
      const data = await response?.data;
      if (response.status === 200) {
        let warningArray = [];
        data.content.forEach((element) => {
          warningArray.push({
            value: element.id,
            label: element.name,
          });
        });

        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListWarning(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const addSaveWarning = (data, type) => {
    const fileAdd = [];
    const delFile = [];

    if (type === "modal") {
      if (data.warning.filesPreview && data.warning.filesPreview.length > 0) {
        data.warning.filesPreview.map((item, i) => {
          if (item.id) {
          } else {
            fileAdd.push({
              id: item.id || "",
              name: item.name,
              data: item.base64.replace("data:", "").replace(/^.+,/, ""),
            });
          }
        });
      }

      if (
        data.warning.filesChooseDel &&
        data.warning.filesChooseDel.length > 0
      ) {
        data.warning.filesChooseDel.map((item) => {
          if (item.id) {
            delFile.push(item.id);
          }
        });
      }
    }

    const dataWarning = {
      warning: {
        id:
          type === "modal"
            ? data.warning.warning_id
              ? data.warning.warning_id
              : ""
            : data.id
            ? data.id
            : "",
        obId: "",
        title:
          type === "modal"
            ? data.warning.title
              ? data.warning.title
              : ""
            : data.title
            ? data.title
            : "",
        description:
          type === "modal"
            ? data.warning.description
              ? data.warning.description
              : ""
            : data.description
            ? data.description
            : "",
        statusId:
          type === "table"
            ? data.statusId === 1
              ? 2
              : 1
            : data.warning.status,
      },
      warningFiles: fileAdd,
      deleteFiles: delFile,
    };

    MySwal.fire({
      text: (type = "modal" ? "ต้องการเปลี่ยนการแสดงผล" : "ต้องการบันทึก"),
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.saveWarning(dataWarning);
          if (response.status === 200) {
            await getWarning();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const delWarning = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบ `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteWarning(id);
          if (response.status === 200) {
            await getWarning();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const buttonAddWarning = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddWarning()}
          disabled={user?.userGroup.mcreate === "n"}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มเตือนภัยโรคระบาด
        </button>
      </div>
    );
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const toAddWarning = () => {
    setForm({ ...form, mode: "add", idEdit: "" });
    setShowWarning(true);
  };

  const editModalWarning = (id) => {
    setForm({ ...form, mode: "edit", idEdit: id });
    setShowWarning(true);
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setStatusSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const renderTableWarning = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td>
            <p className="font14 mb-0">{item.title ? item.title : "-"}</p>
          </td>
          <td className="text-left p-wrap2">
            {renderHTML(item.description) ? renderHTML(item.description) : "-"}
          </td>
          <td>
            {item.foundDiseaseDate
              ? helper.momentDate(item.foundDiseaseDate, "th", "short")
              : "-"}
          </td>
          <td>
            {item.createDate
              ? helper.momentDate(item.createDate, "th", "short")
              : "-"}
          </td>
          <td>
            <p className="font14 mb-0">
              ผู้แจ้ง : {item.fullName ? item.fullName : "-"}
            </p>
            <p className="font14 mb-0">
              {" "}
              เบอร์โทร: {item.tel ? item.tel : "-"}
            </p>
          </td>
          <td>
            {item.statusId == 1 ? (
              <div className="d-flex justify-content-center">
                <button className="btn btn-show mr-2" disabled>
                  แสดง
                </button>
                <button
                  className="btn btn-none"
                  onClick={() => addSaveWarning(item, "table")}
                >
                  ไม่แสดง
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-none mr-2"
                  onClick={() => addSaveWarning(item, "table")}
                >
                  แสดง
                </button>
                <button className="btn btn-notshow" disabled>
                  ไม่แสดง
                </button>
              </div>
            )}
          </td>

          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editModalWarning(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delWarning(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={8} />;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={3} md={6} sm={12} xs={12}>
                <InputSelect
                  title="การแสดงผล"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleChange={(value) => {
                    setForm({
                      ...form,
                      statusId: value.value,
                      checkShow: true,
                    });
                    setStatusSelectValue([value]);
                  }}
                />
              </Col>

              <Col lg={2} md={2} sm={6} xs={6}>
                <InputDatePicker
                  title="วันที่สร้างเนื้อหา"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.createDate}
                  star={false}
                  handleChange={(value) =>
                    setForm({ ...form, createDate: value })
                  }
                />
              </Col>

              <Col mlg={2} md={2} sm={6} xs={6}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.toDate}
                  star={false}
                  handleChange={(value) => setForm({ ...form, toDate: value })}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="รายการข้อมูลเตือนภัยโรคระบาด"
            classNameBox="mt-4"
            headRight={buttonAddWarning()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTableWarning(listWarning)}
            </DataTable>
          </BoxCard>
          {showWarning && (
            <ModalWarning
              show={showWarning}
              close={() => setShowWarning(false)}
              save={(value) => addSaveWarning(value, "modal")}
              mode={form.mode}
              idEdit={form.idEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Warning;
