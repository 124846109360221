import React from 'react'
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function Breadcrumb({ data }) {
    const router = useLocation();

    const renderBreadcrumb = (data) => {
        if (data.length > 0) {
            return data.map((item, index) => {
                if (item.path == 'active') {
                    return <li className="breadcrumb-item active font14" aria-current="page" key={index}>{item.name}</li>
                } if (item.path == 'title') {
                    return <li className="breadcrumb-item text-muted font14" aria-current="page" key={index}>{item.name}</li>
                } else {
                    return <li className="breadcrumb-item font14 " key={index}>
                        <Link to={item.path}>
                            <span className="text-muted">{item.name}</span>
                        </Link>
                    </li>

                }
            });
        }
    }
    return (
        <ol className="breadcrumb mb-0 px-0">
            {
                renderBreadcrumb(data)
            }
            <style jsx="true" global="true">{`
                    .breadcrumb {
                        background-color: unset;
                    }
                    .breadcrumb a {
                        color: #000000 !important;
                    }
                    .breadcrumb-item.active {
                        color: #13A0D1 !important;
                    }
                    .breadcrumb-item.title {
                        color: #000000 !important;
                    }
                `}</style>
        </ol>
    )
}

export default Breadcrumb
