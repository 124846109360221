import React, { useState, useEffect } from 'react';



function FooterHome() {
    return (
        <footer >
            <div className="footer-nav text-center my-3" >
                <a>ศูนย์สารสนเทศเพื่อเฝ้าระวังและควบคุมโรคระบาดสัตว์ สำนักควบคุม ป้องกัน และบำบัดโรคสัตว์ V.1.0</a>
            </div>
        </footer>
    )
}


export default FooterHome

