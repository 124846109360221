import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { logout } from "../../redux/actions/logoutAction";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import { useLocation } from "react-router-dom";

import * as API from "../../utils/apis";
import * as helper from "../../utils/helper";

import Breadcrumb from "../../components/Breadcrumb";
import DataTableNodata from "../../components/DataTableNodata";
import BoxCard from "../../components/BoxCard";
import BoxHead from "../../components/BoxHead";
import DataTable from "../../components/DataTable";

import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

function AddDiseaseSurveillance(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);
  const { params } = props.match;

  const initialForm = {
    year: "",
    projectName: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        name: "ลำดับ",
        sortFlg: false,
      },
      {
        name: "ปีงบประมาณ",
        sortFlg: false,
      },
      {
        name: "โครงการ",
        sortFlg: false,
      },
      {
        name: "ข้อมูลพื้นที่",
        sortFlg: false,
      },
      {
        name: "จังหวัด",
        sortFlg: false,
      },
      {
        name: "จำนวนสัตว์ (ตัว)",
        sortFlg: false,
      },
      {
        name: "ผลตรวจ",
        sortFlg: false,
      },
      {
        name: "วันที่รายงาน (วันที่เก็บตัวอย่าง)",
        sortFlg: false,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [sampleArray, setSampleArray] = useState([]);
  const [dtl, setDtl] = useState({});

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/active_surv/action_plan", name: "การเฝ้าระวังเชิงรุก" },
    { path: "/active_surv/action_plan", name: "แผนการดำเนินงาน" },
    { path: "active", name: "เพิ่ม (ปรับปรุง) รายการข้อมูลการเฝ้าระวังโรค" },
  ];

  useEffect(() => {
    if (location.pathname.includes("add-disease-surveillance")) {
      getDTLById(params.id);
    }
  }, [location.pathname]);

  const getDTLById = async (id) => {
    try {
      const response = await API.getDTLById(id);
      const data = await response?.data;
      if (response.status === 200) {
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setSampleArray(data.sample);
        setDtl(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getFilter = (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const editDiseaseSurv = (id) => {
    history.push(
      `/active_surv/save-project/keep-sample/edit/${params.id}/sample/${id}`
    );
  };

  const buttonUpload = () => {
    return (
      <button
        className="btn btn-info mr-2 fw-400"
        onClick={() =>
          history.push(
            `/active_surv/save-project/keep-sample/save/${params.id}`
          )
        }
        disabled={user?.userGroup.mcreate === "n"}
      >
        <a className="text-white">
          <i className="fas fa-plus mr-2"></i>
          เพิ่มรายการ
        </a>
      </button>
    );
  };

  const getTestStatusColor = (idStatus) => {
    let colorStatus, nameStatus;
    if (idStatus) {
      if (idStatus == "1") {
        colorStatus = "#FF482F";
        nameStatus = "พบโรค";
      } else if (idStatus == "2") {
        colorStatus = "#3FC071";
        nameStatus = "ไม่พบโรค";
      } else if (idStatus == "3") {
        colorStatus = "#FF482F";
        nameStatus = "รอผลการตรวจ";
      } else {
        nameStatus = "-";
      }
      return (
        <div
          style={{
            background: colorStatus,
            color: "#FFF",
            borderRadius: "4px",
            height: "24px",
            padding: "2px",
          }}
          className="box-status font14 px-2 text-center"
        >
          <span className="mt-1">{nameStatus}</span>
        </div>
      );
    } else {
      return <div>-</div>;
    }
  };

  const delDiseaseSurv = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบข้อมูล`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          // const response = await API.deleteSaveProject(id);
          const response = await API.deleteKeepSample(params.id, id);
          if (response.status === 200) {
            await getDTLById(params.id);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTableDiseaseSurveillance = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i}>
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item?.year || "-"}</td>
          <td className="text-center font14">{item?.projectName || "-"}</td>
          <td className="font14">
            <div className="mb-0 ">
              {item?.mooName || ""} {item.tambonName || ""}{" "}
            </div>
            <div>{item.provinceName || ""}</div>
          </td>
          <td>{item?.provinceName || ""}</td>
          <td className="text-center">{item?.animalAmt || ""}</td>
          <td className="font14 text-center disease-wrap ">
            <div className="text-center">
              ส่งผล{" "}
              {item.sendSampleDate
                ? helper.fullDateTh(item.sendSampleDate, "th", "long")
                : "-"}
            </div>
            <div className="d-flex justify-content-center">
              {getTestStatusColor(item?.testStatusId)}
            </div>
          </td>
          <td className="text-center">
            <div className="font16">
              {helper.fullDateTh(item.createDate, "th", "long")}
            </div>
            <div className="font14">
              (เก็บ ตย. {helper.fullDateTh(item.keepSampleDate, "th", "long")})
            </div>
          </td>
          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editDiseaseSurv(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delDiseaseSurv(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={9} />;
    }
  };
  return (
    <>
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxHead>
            <div className="pl-2 pt-2">
              <Row className="mb-0">
                <Col>
                  <p className="font16 bold">เป้าหมาย</p>
                </Col>
              </Row>

              <Row>
                <Col lg={4} md={6} sm={12} xs={12}>
                  <div className="d-flex w-100">
                    <p className="font16 bold w-35">ปีงบประมาณ</p>
                    <p className="font16 text-gray">{dtl.year}</p>
                  </div>
                </Col>

                <Col lg={4} md={6} sm={12} xs={12}>
                  <div className="d-flex">
                    <p className="font16 bold w-35">โครงการ</p>
                    <p className="font16 text-gray">{dtl.projectName}</p>
                  </div>
                </Col>

                <Col lg={4} md={6} sm={12} xs={12}>
                  <div className="d-flex">
                    <p className="font16 bold w-35">พื้นที่</p>
                    <p className="font16 text-gray">
                      อ. {dtl.amphurName} จ. {dtl.provinceName}
                    </p>
                  </div>
                </Col>

                <Col lg={4} md={6} sm={12} xs={12}>
                  <div className="d-flex">
                    <p className="font16 bold w-35">ชนิดสัตว์</p>
                    <p className="font16 text-gray">{dtl.animalTypeName}</p>
                  </div>
                </Col>

                <Col lg={4} md={6} sm={12} xs={12}>
                  <div className="d-flex">
                    <p className="font16 bold w-35">จำนวน</p>
                    <p className="font16 text-gray mr-5">
                      {sampleArray.length}
                    </p>
                    <p className="font16 bold">ตัวอย่าง</p>
                  </div>
                </Col>
              </Row>
            </div>
          </BoxHead>

          <BoxCard
            classNameBox="mt-4"
            title="รายการการเพิ่ม (ปรับปรุง) รายการข้อมูลการเฝ้าระวังโรค"
            headRight={buttonUpload()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              // isClear={isClearSearch}
            >
              {renderTableDiseaseSurveillance(sampleArray)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </>
  );
}

export default AddDiseaseSurveillance;
