import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BoxHead from "../../components/BoxHead";
import InputText from "../../components/InputText";
import InputGroupText from "../../components/InputGroupText";
import InputSelect from "../../components/InputSelect";
import OutbreakStep1 from "../../components/OutbreakStep1";
import OutbreakStep2 from "../../components/OutbreakStep2";
import OutbreakStep3 from "../../components/OutbreakStep3";
import OutbreakStep4 from "../../components/OutbreakStep4";
import OutbreakStep5 from "../../components/OutbreakStep5";
import OutbreakStep6 from "../../components/OutbreakStep6";
import OutbreakStep7 from "../../components/OutbreakStep7";
import OutbreakStep8 from "../../components/OutbreakStep8";
import ModalOrganizes from "../../components/Modal.Organizes";
import InputMask from "../../components/InputMask";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import * as API from "../../utils/apis";
import withReactContent from "sweetalert2-react-content";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import { checkLogin } from "../../redux/actions/loginAction";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ModalOutbreakSearch from "../../components/Modal.OutbreakSearch";
import ModalFarmSearch from "../../components/Modal.FarmSearch";
import { useMasterData } from "../../components/hooks/useMasterData";
const MySwal = withReactContent(Swal);

function Save_Outbreak(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t, i18n } = useTranslation(["common", "footer"]);

  const initialForm = {
    dataAllById: {},

    farmId: "",
    outbreakId: "",
    outbreakCode: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    caseCode: "",
    homeno: "",
    phone: "",
    name: "",
    orgId: "",
    orgName: "",

    addSymptoms: [],
    delSymptoms: [],
    step1: {},
    step2: {},
    step3: {},
    step4: {},
    step5: {},
    step6: {},
    step7: {},
    step8: {},

    getDataAll: {
      disease: {},
      byId: {},
    },

    modeGet: "default",

    data_Outbreak: [],
    modeOutbreak: "add",

    data_farm: [],
    modeFarmSearch: "add",

    chooseSelect: false,
  };
  const [form, setForm] = useState(initialForm);

  const initialFormJson = {
    animalId: {},
    diseaseTypeId: {},
    husbandry: {},
    animalPurpose: {},
    vaccineType: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initInvalidMsg = {
    orgId: "",
    farmId: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const initShowOutbreakSearch = false;
  const [showOutbreakSearch, setShowOutbreakSearch] = useState(
    initShowOutbreakSearch
  );

  const initShowFarmSearch = false;
  const [showFarmSearch, setShowFarmSearch] = useState(initShowFarmSearch);

  const initShowCaseIdSearch = false;
  const [showCaseIdSearch, setShowCaseIdSearch] =
    useState(initShowCaseIdSearch);

  const initShowOrgModal = false;
  const [showOrgModal, setShowOrgModal] = useState(initShowOrgModal);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  // ==================

  const initOrganizesSelectValue = [];
  const [organizesSelectValue, setOrganizesSelectValue] = useState(
    initOrganizesSelectValue
  );
  const selectOrganizesList = [];
  const [organizesList, setOrganizesList] = useState(selectOrganizesList);
  const [houseList, setHouseList] = useState([]);

  const [activeTab, setActiveTab] = useState("pills-data-tab");
  const [dataByFarm, setDataByFarm] = useState({});

  const [checkEditAnimal, setCheckEditAnimal] = useState(false);

  useMasterData("province", provinceList, setProvinceList);

  useEffect(() => {
    getMasterJson();
    getProvince();
  }, [activeTab]);

  useEffect(async () => {
    await dispatch(checkLogin());
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes("edit") ||
      location.pathname.includes("duplicate")
    ) {
      getOutbreakById(props.match.params.id);

      if (location?.search.includes("pills-outbreak")) {
        setActiveTab("pills-outbreak-tab"); //check tab
      }
    }

    if (location.pathname.includes("from-disease")) {
      getReportDiseaseById(props.match.params.diseaseId);
    }
  }, [form.provinceId, form.districtId]);

  useEffect(() => {
    if (
      (location.pathname.includes("save") ||
        location.pathname.includes("from-disease")) &&
      !form.chooseSelect &&
      form.farmId
    ) {
      getFromFarmById(form.farmId);
    }
  }, [user, form.farmId]);

  const getMasterJson = async () => {
    const params = await [
      "animalType",
      "diseaseType",
      "husbandry",
      "animalPurpose",
      "vaccineType",
    ];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status === 200) {
        setFormJson({
          ...formJson,
          animalId: data.animalType,
          diseaseTypeId: data.diseaseType,
          husbandry: data.husbandry,
          animalPurpose: data.animalPurpose,
          vaccineType: data.vaccineType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFarmById = async (id) => {
    try {
      const response = await API.getFarmRegistrationById(id);
      const dataRes = await response.data;
      if (response.status === 200) {
        let arrayHouseList = [];
        if (dataRes && dataRes.barn.length > 0) {
          dataRes.barn.forEach((house) =>
            arrayHouseList.push({ value: house.id, label: house.name })
          );
        }
        setHouseList(arrayHouseList);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getReportDiseaseById = async (id) => {
    let dataRes;
    try {
      const response = await API.getReportDiseaseById(id);
      if (response.status == 200) {
        dataRes = await response.data;
        dispatch(loading(false));
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;
      if (dataByFarm?.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataByFarm?.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        // if (districtList.length == 0) {
        await getDistricts(dataByFarm?.provinceId);
        // }
      }

      if (dataByFarm?.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataByFarm?.amphurId
        );
        setDistrictSelectValue(districtSelect);
        // if (subDistrictList.length == 0) {
        await getSubDistricts(dataByFarm?.amphurId);
        // }
      }

      if (dataByFarm?.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataByFarm?.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        // if (villageList.length == 0) {
        await getVillage(dataByFarm?.tambonId);
        // }
      }

      if (dataByFarm?.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataByFarm?.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      let symptomData = [];
      let data = dataById.informSymptoms;
      if (data && data.length > 0) {
        data.map((item, i) => {
          symptomData.push({
            id: item.id ? item.id : "",
            symptom: { value: item.symptomId, label: item.symptomName, other: item.symptomOther},
            save: false,
          });
        });
      }

      setForm({
        ...form,
        dataAllById: dataById,

        modeGet: "getDisease",
        orgName: user && user.orgName ? user.orgName : "",
        orgId: user && user.profile.orgId ? user.profile.orgId : "",
        provinceId: dataByFarm?.provinceId,
        districtId: dataByFarm?.amphurId,
        subDistrictId: dataByFarm?.tambonId,
        villageId: dataByFarm?.mooId,
        addSymptoms: symptomData,
        step1: {
          address: "",
          animalAround5km: dataById.animalAmt,
          animalId: dataById.animalTypeIds,
          animal_sick: "",
          animal_sickId: dataById.symptomId,
          checkSelect: false,
          checkToSave: false,
          chooseSelect: false,
          date_found: dataById.foundDiseaseDate,
          date_found_first: "",
          diseaseTypeId: "",
          districtId: dataById.amphurId,
          governmentName: dataById.dlaName,
          homeno: dataById.address,
          humanDead: "",
          humanSick: "",
          humanTouch: "",
          localGovernId: dataById.dlaId,
          other_text: dataById.remark,
          provinceId: dataById.provinceId,
          report_from: dataById.informById ? dataById.informById : "",
          subDistrictId: dataById.tambonId,
          villageId: dataById.mooId,
        },
      });
    }
  };

  const getFromFarmById = async (id) => {
    dispatch(loading(true));
    let dataRes;
    try {
      const response = await API.getFarmRegistrationById(id);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.farm && dataById.farm.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.farm.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        // if (districtList.length == 0) {
        await getDistricts(dataById.farm.provinceId);
        // }
      }

      if (dataById.farm && dataById.farm.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.farm.amphurId
        );
        setDistrictSelectValue(districtSelect);
        // if (subDistrictList.length == 0) {
        await getSubDistricts(dataById.farm.amphurId);
        // }
      }

      if (dataById.farm && dataById.farm.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.farm.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        // if (villageList.length == 0) {
        await getVillage(dataById.farm.tambonId);
        // }
      }

      if (dataById.farm && dataById.farm.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.farm.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      const houseArray = [];
      let vaccineArray;
      if (dataById.barn && dataById.barn.length > 0) {
        dataById.barn.map((barns, b) => {
          houseArray.push({
            barnId: barns.id,
            name: barns.name,
            desc: barns.animalType.map((type, t) => {
              return {
                animalTypeId: type.animalTypeId,
                animalTypeName: formJson.animalId[type.animalTypeId],
                totalAmt: type.amount,
                level1Id: "",
                level2Id: "",
                level3Id: "",
                checkEdit: false,
              };
            }),
            animals: barns.animals.map((animal, a) => {
              if (barns.id == animal.barnId) {
                if (animal.vaccine && animal.vaccine.length > 0) {
                  vaccineArray = animal.vaccine.map((vaccine, v) => {
                    return {
                      diseaseTypeName:
                        formJson.diseaseTypeId[vaccine.diseaseTypeId],
                      diseaseTypeId: vaccine.diseaseTypeId,
                      last_date: vaccine.vaccineDate,
                      lotNo: vaccine.lotNo,
                      operator: vaccine.injectBy,
                      other_vaccine: vaccine.injectOther,
                      had_disease: vaccine.diseaseHistory,
                      vaccineTypeId: vaccine.vaccineTypeId,
                      vaccineTypeName:
                        formJson.vaccineType[vaccine.vaccineTypeId],
                    };
                  });
                }

                return {
                  animalTypeName: formJson.animalId[animal.animalTypeId],
                  animalTypeId: animal.animalTypeId,
                  animalId: animal.animalCode,
                  date_dead: animal.deadDate,
                  status_id: animal.statusId,
                  cause_death_type: animal.deadCause,
                  cause_death_type_other: animal.deadReason,
                  name: animal.name,
                  genderType: animal.sex,
                  gender:
                    animal.sex === "m"
                      ? "ตัวผู้"
                      : animal.sex === "f"
                      ? "ตัวเมีย"
                      : "ไม่ทราบ",
                  house_buiding: barns.name,
                  age_year: animal.ageYear,
                  age_month: animal.ageMonth,
                  no_nid: animal.nid,
                  no_microchip: animal.microchip,
                  breed: animal.species,
                  purpose_raising: animal.purposeId,
                  purpose_name: formJson.animalPurpose[animal.purposeId],
                  no_nid: animal.nidCode,
                  no_animal_ear: animal.nid,
                  style_raising: animal.husbandryId,
                  style_raising_name: formJson.husbandry[animal.husbandryId],
                  is_vaccine: animal.vaccinationStatus,
                  choose_disease: vaccineArray,
                };
              }
            }),
          });
        });
      }

      let arrayHouseList = [];
      if (houseArray && houseArray.length > 0) {
        houseArray.forEach((house) =>
          arrayHouseList.push({ value: house.barnId, label: house.name })
        );
      }

      if (location.pathname.includes("from-disease")) {
        setDataByFarm(dataById);
        setForm({
          ...form,
          dataAllById: dataById,
          modeGet: "getDisease",
          farmId: dataById.farm.id,
          provinceId: dataById.farm.provinceId,
          districtId: dataById.farm.amphurId,
          subDistrictId: dataById.farm.tambonId,
          villageId: dataById.farm.mooId,
          homeno: dataById.farm.homeNo,

          phone: dataById.farmer.mobile,
          name: `${dataById.farmer.firstName} ${dataById.farmer.lastName}`,
        });
      } else {
        setForm({
          ...form,
          dataAllById: dataById,

          modeGet: "farmById",
          farmId: dataById.farm.id,
          orgName: user && user.orgName ? user.orgName : "",
          orgId: user && user.profile.orgId ? user.profile.orgId : "",
          provinceId: dataById.farm.provinceId,
          districtId: dataById.farm.amphurId,
          subDistrictId: dataById.farm.tambonId,
          villageId: dataById.farm.mooId,
          homeno: dataById.farm.homeNo,

          phone: dataById.farmer.mobile,
          name: `${dataById.farmer.firstName} ${dataById.farmer.lastName}`,

          step1: {
            provinceId: dataById.farm.provinceId,
            districtId: dataById.farm.amphurId,
            subDistrictId: dataById.farm.tambonId,
            villageId: dataById.farm.mooId,
            homeno: dataById.farm.homeNo,
            localGovernId: dataById.farm.dlaId,
            governmentName: dataById.farm.dlaName,
          },

          step2: {
            house: houseArray,
            houseList: arrayHouseList,
            res_animals: [],
            list_animals: [],
          },
        });
      }

      dispatch(loading(false));
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      zipcode: "",
      districtId: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      districtId: value.value,
      zipcode: "",
      subDistrictId: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      subDistrictId: value.value,
      zipcode: value.zipcode,
      villageId: "",
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const compareBarnsId = (list, value) => {
    let check = false;
    list.forEach((barns, b) => {
      if (barns.barnId === value) {
        check = true;
      }
    });
    return check;
  };

  const mapBarnList = (barn, barnId, barnName) => {
    let barnList = [];
    if (compareBarnsId(barn, barnId)) {
      barn.map((item, i) => {
        if (item.barnId === barnId) {
          return barnList.push({
            id: item.id,
            idHouse: item.barnId,
            nameHouse: barnName,
            animalTypeId: item.animalTypeId,
            animalTypeName: formJson.animalId[item.animalTypeId],
            vaccineTypeId: item.vaccineTypeId,
            vaccineTypeName: formJson.vaccineType[item.vaccineTypeId],
            vaccine_date: item.vaccineDate,
            lot_no: item.lotNo,
            injected_staff: item.injectStaffAmt,
            injected_owner: item.injectOwnerAmt,
            number_animal_vaccine:
              Number(item.injectStaffAmt) + Number(item.injectOwnerAmt),
            number_animal_herd: item.totalAmt,
            belly_animal: item.pregnantAmt,
            more_than: item.less4monthAmt,
            not_can_catch: item.notCatchAmt,
            other: item.otherAmt,
            animal_age: item.ageVaccine,
          });
        }
      });
    }
    return barnList;
  };

  const checkAnimalType = (list) => {
    let check = false;
    if (list.length > 0) {
      check = true;
    }
    return check;
  };

  const getFarmInDisease = async (farmId, dataById) => {
    let dataRes;
    try {
      const response = await API.getFarmRegistrationById(farmId);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    // if (dataById.fm01 && dataById.fm01.farmId) {
    //   getFarmById(dataById.fm01.farmId)
    // }

    if (dataById.fm01 && dataById.fm01.provinceId) {
      let provinceSelect = await provinceList.find(
        (item) => item.value === dataById.fm01.provinceId
      );

      setProvinceSelectValue(provinceSelect);

      if (districtList.length === 0) {
        await getDistricts(dataById.fm01.provinceId);
      }
    }

    if (dataById.fm01 && dataById.fm01.amphurId) {
      let districtSelect = await districtList.find(
        (item) => item.value === dataById.fm01.amphurId
      );
      setDistrictSelectValue(districtSelect);
      if (subDistrictList.length === 0) {
        await getSubDistricts(dataById.fm01.amphurId);
      }
    }

    if (dataById.fm01 && dataById.fm01.tambonId) {
      let subDistrictSelect = await subDistrictList.find(
        (item) => item.value === dataById.fm01.tambonId
      );
      setSubDistrictSelectValue(subDistrictSelect);

      if (villageList.length === 0) {
        await getVillage(dataById.fm01.tambonId);
      }
    }

    if (dataById.fm01 && dataById.fm01.mooId) {
      let villageSelect = await villageList.find(
        (item) => item.value === dataById.fm01.mooId
      );
      setVillageSelectValue(villageSelect);
    }

    if (dataById.fm01 && dataById.fm01.orgId) {
      let organizesSelect = await organizesList.find(
        (item) => item.value === dataById.fm01.orgId
      );
      setOrganizesSelectValue(organizesSelect);
    }

    const houseArray = [];
    const animalRes = [];
    console.log("dataRes", dataRes);
    let vaccineArray;
    if (dataRes && dataRes.barn && dataRes.barn.length > 0) {
      console.log("dataRes.barn", dataRes.barn);
      dataRes.barn.map((barns, b) => {
        houseArray.push({
          barnId: barns.id,
          name: barns.name,
          desc: barns.animalType.map((type, t) => {
            return {
              animalTypeId: type.animalTypeId,
              animalTypeName: formJson.animalId[type.animalTypeId],
              totalAmt: type.amount,
              level1Id: "",
              level2Id: "",
              level3Id: "",
              checkEdit: false,
            };
          }),
          animals: [],
        });
      });
    }

    console.log("houseArray", houseArray);
    if (
      dataById.fm01Response &&
      dataById.fm01Response.fm0102 &&
      dataById.fm01Response.fm0102.barns
    ) {
      const barn = dataById.fm01Response.fm0102.barns;
      if (barn && barn.length > 0) {
        barn.map((barns, b) => {
          if (
            dataById.fm01Response &&
            dataById.fm01Response.fm0102 &&
            dataById.fm01Response.fm0102.animalResponse
          ) {
            let animal_res = dataById.fm01Response.fm0102.animalResponse;
            if (compareBarnsId(animal_res, barns.id)) {
              if (animal_res && animal_res.length > 0) {
                animal_res.map((animal, a) => {
                  animalRes.push({
                    id: animal.id,
                    fm01Id: animal.fm01Id,
                    animalTypeName: formJson.animalId[animal.animalTypeId],
                    animalTypeId: animal.animalTypeId,
                    animalId: animal.animalCode,
                    date_dead: animal.deadDate,
                    status_id: animal.statusId,
                    cause_death_type: animal.deadCause,
                    cause_death_type_other: animal.deadReason,
                    name: animal.name,
                    genderType: animal.sex,
                    gender:
                      animal.sex === "m"
                        ? "ตัวผู้"
                        : animal.sex === "f"
                        ? "ตัวเมีย"
                        : "ไม่ทราบ",
                    house_buiding: barns.name,
                    house_buiding_id: animal.barnId,
                    age_year: animal.ageYear,
                    age_month: animal.ageMonth,
                    no_nid: animal.nid,
                    no_microchip: animal.microchip,
                    breed: animal.species,
                    purpose_raising: animal.purposeId,
                    purpose_name: formJson.animalPurpose[animal.purposeId],
                    no_nid: animal.nidCode,
                    no_animal_ear: animal.nid,
                    style_raising: animal.husbandryId,
                    style_raising_name: formJson.husbandry[animal.husbandryId],
                    is_vaccine: animal.vaccinationStatus,
                  });
                });
              }
            }
          }
        });
      }
    }

    let arrayHouseList = [];
    if (houseArray && houseArray.length > 0) {
      houseArray.forEach((house) =>
        arrayHouseList.push({ value: house.barnId, label: house.name })
      );
    }

    let file_step5 = [];
    if (dataById.fm0103Response && dataById.fm0103Response.files) {
      if (
        dataById.fm0103Response.files &&
        dataById.fm0103Response.files.length > 0
      ) {
        dataById.fm0103Response.files.map((item, i) => {
          file_step5.push({
            name: item.name,
            url: item.url,
          });
        });
      }
    }

    let step7_file = [];
    if (dataById.outbreakFiles && dataById.outbreakFiles.length > 0) {
      dataById.outbreakFiles.map((item, i) => {
        step7_file.push({
          id: item.id ? item.id : "",
          name: item.name,
          description: item.description,
          data: item.data,
          url: item.url,
        });
      });
    }

    let animalStep8 = [];
    let houseArrayStep8 = [];
    if (dataById.fm05Response && dataById.fm05Response.barns) {
      if (houseList && houseList.length > 0) {
        if (
          dataById.fm05Response.barns &&
          dataById.fm05Response.barns.length > 0
        ) {
          let barn = dataById.fm05Response.barns;
          houseList.map((barns, b) => {
            houseArrayStep8.push({
              barnId: barns.value,
              name: barns.label,
              desc: mapBarnList(barn, barns.value, barns.label),
            });
          });
        }

        if (dataById.fm05Response.animals && dataById.fm05Response.animals) {
          let animal_res = dataById.fm05Response.animals;

          if (animal_res && animal_res.length > 0) {
            animal_res.map((animal, a) => {
              animalStep8.push({
                id: animal.id,
                animalTypeName: formJson.animalId[animal.animalTypeId],
                animalTypeId: animal.animalTypeId,
                animalId: animal.animalCode,
                date_dead: animal.deadDate,
                status_id: animal.statusId,
                cause_death_type: animal.deadCause,
                cause_death_type_other: animal.deadReason,
                name: animal.name,
                genderType: animal.sex,
                gender:
                  animal.sex === "m"
                    ? "ตัวผู้"
                    : animal.sex === "f"
                    ? "ตัวเมีย"
                    : "ไม่ทราบ",
                vaccineTypeName: formJson.vaccineType[animal.vaccineTypeId],
                animalId: animal.animalCode,
                vaccineTypeId: animal.vaccineTypeId,
                lotNo: animal.lotNo,
                house_buiding: houseList.map((house) => {
                  if (house.value === animal.barnId) {
                    return house.label;
                  }
                }),
                house_buiding_id: animal.barnId,
                animalId: animal.animalCode,
                age_year: animal.ageYear,
                age_month: animal.ageMonth,
                no_nid: animal.nid,
                no_microchip: animal.microchip,
                breed: animal.species,
                purpose_raising: animal.purposeId,
                purpose_name: formJson.animalPurpose[animal.purposeId],
                no_nid: animal.nidCode,
                no_animal_ear: animal.nid,
                style_raising: animal.husbandryId,
                style_raising_name: formJson.husbandry[animal.husbandryId],
                is_vaccine: animal.vaccinationStatus,
              });
            });
          }
        }
      }
    }

    let step1_symptomData = [];
    if (dataById.fm01Symptoms && dataById.fm01Symptoms.length > 0) {
      dataById.fm01Symptoms.map((item, i) => {
        step1_symptomData.push({
          id: item.id ? item.id : "",
          symptom: { value: item.symptomId, label: item.symptomName, other: item.symptomOther },
          save: false,
        });
      });
    }

    if (dataById.fm01 && dataById.outbreak) {
      setForm({
        ...form,
        dataAllById: dataById,

        modeGet: "getById",
        farmId: dataById.fm01.farmId,
        outbreakCode: dataById.outbreak.obCode,
        caseCode: dataById.fm01.caseCode,
        provinceId: dataById.fm01.provinceId,
        districtId: dataById.fm01.amphurId,
        subDistrictId: dataById.fm01.tambonId,
        villageId: dataById.fm01.mooId,
        homeno: dataById.fm01.address,
        phone: dataById.fm01.ownerTel,
        name: dataById.fm01.ownerName,
        orgId: dataById.fm01.orgId,
        orgName: dataById.fm01.orgName,

        step1: {
          provinceId: dataById.fm01?.provinceId,
          districtId: dataById.fm01?.amphurId,
          subDistrictId: dataById.fm01?.tambonId,
          villageId: dataById.fm01?.mooId,
          homeno: dataById.fm01?.address,
          localGovernId: dataById?.fm01.dlaId,
          governmentName: dataById?.fm01.dlaName,

          animalId: dataById.fm01?.animalTypeId,
          diseaseTypeId: dataById.fm01?.diseaseTypeId,
          animal_sickId: dataById.fm01?.symptomId,
          date_found: dataById.fm01.foundDiseaseDate
            ? dataById.fm01.foundDiseaseDate
            : "",
          date_found_first: dataById.fm01.firstAnimSymptomDate
            ? dataById.fm01.firstAnimSymptomDate
            : "",
          report_from: dataById.fm01.informBy ? dataById.fm01.informBy : "",
          other_text: dataById.fm01.remark ? dataById.fm01.remark : "",

          humanSick: dataById.fm01.humanSickAmt
            ? dataById.fm01.humanSickAmt
            : "",
          humanDead: dataById.fm01.humanDeadAmt
            ? dataById.fm01.humanDeadAmt
            : "",
          humanTouch: dataById.fm01.humanTouchAmt
            ? dataById.fm01.humanTouchAmt
            : "",
          animalAround5km: dataById.fm01.animalAround5km
            ? dataById.fm01.animalAround5km
            : "",
        },
        addSymptoms: step1_symptomData,
        step2: {
          house: houseArray,
          houseList: arrayHouseList,
          res_animals: animalRes,
        },
        step5: {
          quarantine:
            dataById.fm0103Response && dataById.fm0103Response.detention
              ? dataById.fm0103Response.detention
              : "",
          period:
            dataById.fm0103Response && dataById.fm0103Response.detentionDay
              ? dataById.fm0103Response.detentionDay
              : "",
          evidence:
            dataById.fm0103Response && dataById.fm0103Response.files
              ? dataById.fm0103Response.files
              : [],
          treatment_animal:
            dataById.fm0103Response && dataById.fm0103Response.treatment
              ? dataById.fm0103Response.treatment
              : "",
          bury:
            dataById.fm0103Response && dataById.fm0103Response.destruction1
              ? dataById.fm0103Response.destruction1
              : "",
          burn:
            dataById.fm0103Response && dataById.fm0103Response.destruction2
              ? dataById.fm0103Response.destruction2
              : "",
          throw_away:
            dataById.fm0103Response && dataById.fm0103Response.destruction3
              ? dataById.fm0103Response.destruction3
              : "",
          send_kill:
            dataById.fm0103Response && dataById.fm0103Response.destruction4
              ? dataById.fm0103Response.destruction4
              : "",
          disinfect:
            dataById.fm0103Response && dataById.fm0103Response.cleaning
              ? dataById.fm0103Response.cleaning
              : "",
          other_disinfect:
            dataById.fm0103Response && dataById.fm0103Response.cleaningOther
              ? dataById.fm0103Response.cleaningOther
              : "",
          vaccine:
            dataById.fm0103Response && dataById.fm0103Response.vaccination
              ? dataById.fm0103Response.vaccination
              : "",
          drop_anti_insect:
            dataById.fm0103Response && dataById.fm0103Response.antiInsect1
              ? dataById.fm0103Response.antiInsect1
              : "",
          inject_anti_insect:
            dataById.fm0103Response && dataById.fm0103Response.antiInsect2
              ? dataById.fm0103Response.antiInsect2
              : "",
          deal_anti_insect:
            dataById.fm0103Response && dataById.fm0103Response.medicine
              ? dataById.fm0103Response.medicine
              : "",
          public_relations:
            dataById.fm0103Response && dataById.fm0103Response.educate
              ? dataById.fm0103Response.educate
              : "",
        },
        step6: {
          warningId:
            dataById.outbreak06Response && dataById.outbreak06Response.warningId
              ? dataById.outbreak06Response.warningId
              : "",
          transfer:
            dataById.outbreak06Response &&
            dataById.outbreak06Response.obMovement
              ? dataById.outbreak06Response.obMovement
              : "",
          quarantine:
            dataById.outbreak06Response && dataById.outbreak06Response.detention
              ? dataById.outbreak06Response.detention
              : "",
          evidence:
            dataById.outbreak06Response &&
            dataById.outbreak06Response.detentionDay
              ? dataById.outbreak06Response.detentionDay
              : "",
          treatment_animal:
            dataById.outbreak06Response && dataById.outbreak06Response.treatment
              ? dataById.outbreak06Response.treatment
              : "",
          bury:
            dataById.outbreak06Response &&
            dataById.outbreak06Response.destruction1
              ? dataById.outbreak06Response.destruction1
              : "",
          burn:
            dataById.outbreak06Response &&
            dataById.outbreak06Response.destruction2
              ? dataById.outbreak06Response.destruction2
              : "",
          throw_away:
            dataById.outbreak06Response &&
            dataById.outbreak06Response.destruction3
              ? dataById.outbreak06Response.destruction3
              : "",
          send_kill:
            dataById.outbreak06Response &&
            dataById.outbreak06Response.destruction4
              ? dataById.outbreak06Response.destruction4
              : "",
          disinfect:
            dataById.outbreak06Response && dataById.outbreak06Response.cleaning
              ? dataById.outbreak06Response.cleaning
              : "",
          other_disinfect:
            dataById.outbreak06Response &&
            dataById.outbreak06Response.cleaningOther
              ? dataById.outbreak06Response.cleaningOther
              : "",
          vaccine:
            dataById.outbreak06Response &&
            dataById.outbreak06Response.vaccination
              ? dataById.outbreak06Response.vaccination
              : "",
          drop_anti_insect:
            dataById.outbreak06Response &&
            dataById.outbreak06Response.antiInsect1
              ? dataById.outbreak06Response.antiInsect1
              : "",
          inject_anti_insect:
            dataById.outbreak06Response &&
            dataById.outbreak06Response.antiInsect2
              ? dataById.outbreak06Response.antiInsect2
              : "",
          deal_anti_insect:
            dataById.outbreak06Response && dataById.outbreak06Response.medicine
              ? dataById.outbreak06Response.medicine
              : "",
          public_relations:
            dataById.outbreak06Response && dataById.outbreak06Response.educate
              ? dataById.outbreak06Response.educate
              : "",
              farm_amt: dataById.outBreak.farmAmt ? dataById.outBreak.farmAmt : 0,
        },
        step7: {
          data_file: step7_file,
          checkAddFile: false,
        },
        step8: {
          house: houseArrayStep8,
          res_animals: animalStep8,
          checkGetData: true,
          idVaccince:
            dataById.fm05Response && dataById.fm05Response.fm05.id
              ? dataById.fm05Response.fm05.id
              : "",
        },
      });
    }
  };

  const getOutbreakById = async (id) => {
    let dataRes;
    let response;
    try {
      response = location.pathname.includes("edit")
        ? await API.getOutbreakById(id)
        : await API.getCopyOutbreakById(id);
      if (response.status === 200) {
        dataRes = await response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (response && dataRes) {
      const dataById = dataRes;

      if (dataById.fm01 && dataById.fm01.farmId) {
        getFarmById(dataById.fm01.farmId);
      }

      if (dataById.fm01 && dataById.fm01.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value === dataById.fm01.provinceId
        );

        setProvinceSelectValue(provinceSelect);

        if (districtList.length === 0) {
          await getDistricts(dataById.fm01.provinceId);
        }
      }

      if (dataById.fm01 && dataById.fm01.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value === dataById.fm01.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length === 0) {
          await getSubDistricts(dataById.fm01.amphurId);
        }
      }

      if (dataById.fm01 && dataById.fm01.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value === dataById.fm01.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length === 0) {
          await getVillage(dataById.fm01.tambonId);
        }
      }

      if (dataById.fm01 && dataById.fm01.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value === dataById.fm01.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.fm01 && dataById.fm01.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value === dataById.fm01.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      const houseArray = [];
      const animalRes = [];

      if (
        dataById.fm01Response &&
        dataById.fm01Response.fm0102 &&
        dataById.fm01Response.fm0102.barns
      ) {
        const barn = dataById.fm01Response.fm0102.barns;
        if (barn && barn.length > 0) {
          barn.map((barns, b) => {
            if (checkAnimalType(barns.animalType)) {
              houseArray.push({
                barnId: barns.id,
                name: barns.name,
                desc: barns.animalType.map((type, t) => {
                  return {
                    id: type.id,
                    animalTypeId: type.animalTypeId,
                    animalTypeName: formJson.animalId[type.animalTypeId],
                    totalAmt: type.totalAmt,
                    sickAmt: type.sickAmt,
                    deadAmt: type.deadAmt,
                    sickDeadAmt: type.sickDeadAmt,
                    checkEdit: false,
                  };
                }),
                animals: [],
              });
              console.log("if", dataById.fm01?.farmId);
            } else {
              getFarmInDisease(dataById.fm01?.farmId, dataById);
              console.log("else", dataById.fm01?.farmId);
            }

            // ส่วน animalResponse ตอนเอาสัตว์รายตัวมาโชว์
            if (
              dataById.fm01Response &&
              dataById.fm01Response.fm0102 &&
              dataById.fm01Response.fm0102.animalResponse
            ) {
              let animal_res = dataById.fm01Response.fm0102.animalResponse;
              if (compareBarnsId(animal_res, barns.id)) {
                if (animal_res && animal_res.length > 0) {
                  animal_res.map((animal, a) => {
                    animalRes.push({
                      id: animal.id,
                      fm01Id: animal.fm01Id,
                      animalTypeName: formJson.animalId[animal.animalTypeId],
                      animalTypeId: animal.animalTypeId,
                      animalId: animal.animalCode,
                      date_dead: animal.deadDate,
                      status_id: animal.statusId,
                      cause_death_type: animal.deadCause,
                      cause_death_type_other: animal.deadReason,
                      name: animal.name,
                      genderType: animal.sex,
                      gender:
                        animal.sex === "m"
                          ? "ตัวผู้"
                          : animal.sex === "f"
                          ? "ตัวเมีย"
                          : "ไม่ทราบ",
                      house_buiding: barns.name,
                      house_buiding_id: animal.barnId,
                      age_year: animal.ageYear,
                      age_month: animal.ageMonth,
                      no_nid: animal.nid,
                      no_microchip: animal.microchip,
                      breed: animal.species,
                      purpose_raising: animal.purposeId,
                      purpose_name: formJson.animalPurpose[animal.purposeId],
                      no_nid: animal.nidCode,
                      no_animal_ear: animal.nid,
                      style_raising: animal.husbandryId,
                      style_raising_name:
                        formJson.husbandry[animal.husbandryId],
                      is_vaccine: animal.vaccinationStatus,
                    });
                  });
                }
              }
            }
          });
        }
      }

      let arrayHouseList = [];
      if (houseArray && houseArray.length > 0) {
        houseArray.forEach((house) =>
          arrayHouseList.push({ value: house.barnId, label: house.name })
        );
      }

      let file_step5 = [];
      if (dataById.fm0103Response && dataById.fm0103Response.files) {
        if (
          dataById.fm0103Response.files &&
          dataById.fm0103Response.files.length > 0
        ) {
          dataById.fm0103Response.files.map((item, i) => {
            file_step5.push({
              name: item.name,
              url: item.url,
            });
          });
        }
      }

      let step7_file = [];
      if (dataById.outbreakFiles && dataById.outbreakFiles.length > 0) {
        dataById.outbreakFiles.map((item, i) => {
          step7_file.push({
            id: item.id ? item.id : "",
            name: item.name,
            description: item.description,
            data: item.data,
            url: item.url,
          });
        });
      }

      let animalStep8 = [];
      let houseArrayStep8 = [];
      if (dataById.fm05Response && dataById.fm05Response.barns) {
        if (houseList && houseList.length > 0) {
          if (
            dataById.fm05Response.barns &&
            dataById.fm05Response.barns.length > 0
          ) {
            let barn = dataById.fm05Response.barns;
            houseList.map((barns, b) => {
              houseArrayStep8.push({
                barnId: barns.value,
                name: barns.label,
                desc: mapBarnList(barn, barns.value, barns.label),
              });
            });
          }

          if (dataById.fm05Response.animals && dataById.fm05Response.animals) {
            let animal_res = dataById.fm05Response.animals;

            if (animal_res && animal_res.length > 0) {
              animal_res.map((animal, a) => {
                animalStep8.push({
                  id: animal.id,
                  animalTypeName: formJson.animalId[animal.animalTypeId],
                  animalTypeId: animal.animalTypeId,
                  animalId: animal.animalCode,
                  date_dead: animal.deadDate,
                  status_id: animal.statusId,
                  cause_death_type: animal.deadCause,
                  cause_death_type_other: animal.deadReason,
                  name: animal.name,
                  genderType: animal.sex,
                  gender:
                    animal.sex === "m"
                      ? "ตัวผู้"
                      : animal.sex === "f"
                      ? "ตัวเมีย"
                      : "ไม่ทราบ",
                  vaccineTypeName: formJson.vaccineType[animal.vaccineTypeId],
                  animalId: animal.animalCode,
                  vaccineTypeId: animal.vaccineTypeId,
                  lotNo: animal.lotNo,
                  house_buiding: houseList.map((house) => {
                    if (house.value === animal.barnId) {
                      return house.label;
                    }
                  }),
                  house_buiding_id: animal.barnId,
                  animalId: animal.animalCode,
                  age_year: animal.ageYear,
                  age_month: animal.ageMonth,
                  no_nid: animal.nid,
                  no_microchip: animal.microchip,
                  breed: animal.species,
                  purpose_raising: animal.purposeId,
                  purpose_name: formJson.animalPurpose[animal.purposeId],
                  no_nid: animal.nidCode,
                  no_animal_ear: animal.nid,
                  style_raising: animal.husbandryId,
                  style_raising_name: formJson.husbandry[animal.husbandryId],
                  is_vaccine: animal.vaccinationStatus,
                });
              });
            }
          }
        }
      }

      let step1_symptomData = [];
      if (dataById.fm01Symptoms && dataById.fm01Symptoms.length > 0) {
        dataById.fm01Symptoms.map((item, i) => {
          step1_symptomData.push({
            id: item.id ? item.id : "",
            symptom: { value: item.symptomId, label: item.symptomName, other: item.symptomOther },
            save: false,
          });
        });
      }
      console.log("step1_symptomData", step1_symptomData);

      if (dataById.fm01 && dataById.outbreak) {
        setForm({
          ...form,
          dataAllById: dataById,

          modeGet: "getById",
          farmId: dataById.fm01.farmId,
          outbreakCode: dataById.outbreak.obCode,
          caseCode: dataById.fm01.caseCode,
          provinceId: dataById.fm01.provinceId,
          districtId: dataById.fm01.amphurId,
          subDistrictId: dataById.fm01.tambonId,
          villageId: dataById.fm01.mooId,
          homeno: dataById.fm01.address,
          phone: dataById.fm01.ownerTel,
          name: dataById.fm01.ownerName,
          orgId: dataById.fm01.orgId,
          orgName: dataById.fm01.orgName,

          step1: {
            provinceId: dataById.fm01?.provinceId,
            districtId: dataById.fm01?.amphurId,
            subDistrictId: dataById.fm01?.tambonId,
            villageId: dataById.fm01?.mooId,
            homeno: dataById.fm01?.address,
            localGovernId: dataById?.fm01.dlaId,
            governmentName: dataById?.fm01.dlaName,

            animalId: dataById.fm01?.animalTypeId,
            diseaseTypeId: dataById.fm01?.diseaseTypeId,
            animal_sickId: dataById.fm01?.symptomId,
            date_found: dataById.fm01.foundDiseaseDate
              ? dataById.fm01.foundDiseaseDate
              : "",
            date_found_first: dataById.fm01.firstAnimSymptomDate
              ? dataById.fm01.firstAnimSymptomDate
              : "",
            report_from: dataById.fm01.informBy ? dataById.fm01.informBy : "",
            other_text: dataById.fm01.remark ? dataById.fm01.remark : "",

            humanSick: dataById.fm01.humanSickAmt
              ? dataById.fm01.humanSickAmt
              : "",
            humanDead: dataById.fm01.humanDeadAmt
              ? dataById.fm01.humanDeadAmt
              : "",
            humanTouch: dataById.fm01.humanTouchAmt
              ? dataById.fm01.humanTouchAmt
              : "",
            animalAround5km: dataById.fm01.animalAround5km
              ? dataById.fm01.animalAround5km
              : "",
          },
          addSymptoms: step1_symptomData,
          step2: {
            house: houseArray,
            houseList: arrayHouseList,
            res_animals: animalRes,
          },
          step5: {
            quarantine:
              dataById.fm0103Response && dataById.fm0103Response.detention
                ? dataById.fm0103Response.detention
                : "",
            period:
              dataById.fm0103Response && dataById.fm0103Response.detentionDay
                ? dataById.fm0103Response.detentionDay
                : "",
            evidence:
              dataById.fm0103Response && dataById.fm0103Response.files
                ? dataById.fm0103Response.files
                : [],
            treatment_animal:
              dataById.fm0103Response && dataById.fm0103Response.treatment
                ? dataById.fm0103Response.treatment
                : "",
            bury:
              dataById.fm0103Response && dataById.fm0103Response.destruction1
                ? dataById.fm0103Response.destruction1
                : "",
            burn:
              dataById.fm0103Response && dataById.fm0103Response.destruction2
                ? dataById.fm0103Response.destruction2
                : "",
            throw_away:
              dataById.fm0103Response && dataById.fm0103Response.destruction3
                ? dataById.fm0103Response.destruction3
                : "",
            send_kill:
              dataById.fm0103Response && dataById.fm0103Response.destruction4
                ? dataById.fm0103Response.destruction4
                : "",
            disinfect:
              dataById.fm0103Response && dataById.fm0103Response.cleaning
                ? dataById.fm0103Response.cleaning
                : "",
            other_disinfect:
              dataById.fm0103Response && dataById.fm0103Response.cleaningOther
                ? dataById.fm0103Response.cleaningOther
                : "",
            vaccine:
              dataById.fm0103Response && dataById.fm0103Response.vaccination
                ? dataById.fm0103Response.vaccination
                : "",
            drop_anti_insect:
              dataById.fm0103Response && dataById.fm0103Response.antiInsect1
                ? dataById.fm0103Response.antiInsect1
                : "",
            inject_anti_insect:
              dataById.fm0103Response && dataById.fm0103Response.antiInsect2
                ? dataById.fm0103Response.antiInsect2
                : "",
            deal_anti_insect:
              dataById.fm0103Response && dataById.fm0103Response.medicine
                ? dataById.fm0103Response.medicine
                : "",
            public_relations:
              dataById.fm0103Response && dataById.fm0103Response.educate
                ? dataById.fm0103Response.educate
                : "",
          },
          step6: {
            warningId:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.warningId
                ? dataById.outbreak06Response.warningId
                : "",
            transfer:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.obMovement
                ? dataById.outbreak06Response.obMovement
                : "",
            quarantine:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.detention
                ? dataById.outbreak06Response.detention
                : "",
            evidence:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.detentionDay
                ? dataById.outbreak06Response.detentionDay
                : "",
            treatment_animal:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.treatment
                ? dataById.outbreak06Response.treatment
                : "",
            bury:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.destruction1
                ? dataById.outbreak06Response.destruction1
                : "",
            burn:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.destruction2
                ? dataById.outbreak06Response.destruction2
                : "",
            throw_away:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.destruction3
                ? dataById.outbreak06Response.destruction3
                : "",
            send_kill:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.destruction4
                ? dataById.outbreak06Response.destruction4
                : "",
            disinfect:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.cleaning
                ? dataById.outbreak06Response.cleaning
                : "",
            other_disinfect:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.cleaningOther
                ? dataById.outbreak06Response.cleaningOther
                : "",
            vaccine:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.vaccination
                ? dataById.outbreak06Response.vaccination
                : "",
            drop_anti_insect:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.antiInsect1
                ? dataById.outbreak06Response.antiInsect1
                : "",
            inject_anti_insect:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.antiInsect2
                ? dataById.outbreak06Response.antiInsect2
                : "",
            deal_anti_insect:
              dataById.outbreak06Response &&
              dataById.outbreak06Response.medicine
                ? dataById.outbreak06Response.medicine
                : "",
            public_relations:
              dataById.outbreak06Response && dataById.outbreak06Response.educate
                ? dataById.outbreak06Response.educate
                : "",
                farm_amt: dataById.outbreak.farmAmt ? dataById.outbreak.farmAmt  : 0,
          },
          step7: {
            data_file: step7_file,
            checkAddFile: false,
          },
          step8: {
            house: houseArrayStep8,
            res_animals: animalStep8,
            checkGetData: true,
            idVaccince:
              dataById.fm05Response && dataById.fm05Response.fm05.id
                ? dataById.fm05Response.fm05.id
                : "",
          },
        });
      }
      setCheckEditAnimal(false);
    }
  };

  const saveNewOutbreak = async () => {
    if (form.outbreakCode === "" && !location.pathname.includes("save")) {
      MySwal.fire({
        text: "กรุณาเลือกเลข Outbreak ID",
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
    } else if (validate()) {
      const data = {
        outbreak: {
          obCode: "",
          statusId: 1,
        },
        fm01: {
          orgId: form.orgId,
          diseaseTypeId: "",
          farmId: form.farmId,
          ownerName: form.name,
          ownerTel: form.phone,
          address: form.homeno,
          mooId: form.villageId,
          tambonId: form.subDistrictId,
          amphurId: form.districtId,
          provinceId: form.provinceId,
        },
      };
      dispatch(loading(true));
      try {
        const response = await API.addNewOutBreak(data);
        const dataRes = response.data.data;

        if (response.status === 200) {
          dispatch(loading(false));
          MySwal.fire({
            text: `บันทึก "Outbreak" สำเร็จ`,
            icon: "success",
            confirmButtonText: "ตกลง",
          }).then(async (result) => {
            if (result.value) {
              if (dataRes.outbreak) {
                let outBreak = dataRes.outbreak;
                history.push(
                  // `/passive_surv/outbreak/edit/${outBreak.id}`
                  `/passive_surv/outbreak`
                );
              }
            }
          });
        }
      } catch (error) {
        dispatch(loading(false));
        if (error.response && error.response.status === 401) {
          dispatch(logout({ history }));
        } else if (error.response && error.response.status === 400) {
          MySwal.fire({
            text: "บันทึกไม่สำเร็จ กรุณากรอกข้อมูลให้ครบถ้วน",
            icon: "warning",
            confirmButtonText: "ตกลง",
          });
        } else {
          MySwal.fire({
            text: "บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
            icon: "warning",
            confirmButtonText: "ตกลง",
          });
        }
        console.log("error-api addNewOutBreak-", error, data);
      }
    }
  };

  const saveDiseaseStatement = async () => {
    const dataStep1 = form.step1;
    const dataStep2 = form.step2;
    // const dataStep1Symptoms = form.addSymptoms;
    // const delSymptomsData = form.delSymptoms;

    // const symptomsDel = []
    // if (delSymptomsData && delSymptomsData.length > 0) {
    //   delSymptomsData.map(item => {
    //     symptomsDel.push(item)
    //   })
    // }

    // const dataSymptoms = []
    // if (dataStep1Symptoms && dataStep1Symptoms.length > 0) {
    //   dataStep1Symptoms.map((item, i) => {
    //     dataSymptoms.push({
    //       id: item.id ? item.id : "",
    //       symptomId: item.symptom.value,
    //     })

    //   })
    // }

    const barnsStep2 = [];
    if (dataStep2.house && dataStep2.house.length > 0) {
      dataStep2.house.map((item, i) => {
        let animalType;
        let animalsArray;
        if (item.desc && item.desc.length > 0) {
          animalType = item.desc.map((desc, d) => {
            return {
              id: desc.id ? desc.id : "",
              animalTypeId: desc.animalTypeId,
              totalAmt: desc.totalAmt,
              sickAmt: desc.sickAmt,
              deadAmt: desc.deadAmt,
              sickDeadAmt: desc.sickDeadAmt,
            };
          });
        } else {
          animalType = [];
        }

        if (item.animals && item.animals.length > 0) {
          animalsArray = item.animals.map((animal, a) => {
            let vaccine;
            if (animal.choose_disease && animal.choose_disease.length > 0) {
              vaccine = animal.choose_disease.map((disease, d) => {
                return {
                  id: "",
                  diseaseTypeId: disease.diseaseTypeId,
                  vaccineTypeId: disease.vaccineTypeId,
                  vaccineDate: disease.last_date
                    ? helper.getDate(disease.last_date)
                    : "",
                  lotNo: disease.lotNo,
                  injectBy: disease.operator,
                  injectOther: disease.other_vaccine,
                  diseaseHistory: disease.had_disease,
                };
              });
            }

            return {
              id: "",
              animalTypeId: animal.animalTypeId,
              animalCode: animal.animalId,
              statusId: animal.status_id,
              deadDate: animal.date_dead,
              deadCause: animal.cause_death_type,
              deadReason: animal.cause_death_type_other,
              name: animal.name,
              sex: animal.genderType,
              ageYear: animal.age_year,
              ageMonth: animal.age_month,
              nid: animal.no_animal_ear,
              microchip: animal.no_microchip,
              species: animal.breed,
              purposeId: animal.purpose_raising,
              nidCode: animal.no_nid,
              husbandryId: animal.style_raising,
              vaccinationStatus: animal.is_vaccine,
              vaccine: vaccine ? vaccine : [],
            };
          });
        }

        barnsStep2.push({
          id: item.barnId,
          farmId: form.farmId,
          orderIndex: i + 1,
          name: item.name,
          totalAmt: "",
          animalType: animalType,
          animals: animalsArray ? animalsArray : [],
        });
      });
    }

    const listAnimalChoose = [];
    if (dataStep2.res_animals && dataStep2.res_animals.length > 0) {
      dataStep2.res_animals.map((item, i) => {
        listAnimalChoose.push(item.id);
      });
    }

    const delAnimal = [];
    if (dataStep2.del_animals && dataStep2.del_animals.length > 0) {
      dataStep2.del_animals.map((item) => {
        delAnimal.push(item);
      });
    }

    const data = {
      outbreak: {
        obCode: form.outbreakCode,
      },
      fm01: {
        caseCode: form.caseCode,
        address: dataStep1.homeno,
        amphurId: dataStep1.districtId,
        animalAround5km: dataStep1.animalAround5km,
        animalTypeId: dataStep1.animalId,
        diseaseTypeId: dataStep1.diseaseTypeId,
        dlaId: dataStep1.localGovernId,
        dlaName: dataStep1.governmentName,
        farmId: form.farmId,
        firstAnimSymptomDate: dataStep1.date_found
          ? helper.getDate(dataStep1.date_found)
          : "",
        foundDiseaseDate: dataStep1.date_found_first
          ? helper.getDate(dataStep1.date_found_first)
          : "",
        humanDeadAmt: dataStep1.humanDead,
        humanSickAmt: dataStep1.humanSick,
        humanTouchAmt: dataStep1.humanTouch,
        informBy: dataStep1.report_from,
        informOther: dataStep1.other_text,
        informId: null,
        mooId: dataStep1.villageId,
        obId: form.outbreakId,
        orgId: form.orgId,
        ownerName: form.name,
        ownerTel: form.phone,
        provinceId: dataStep1.provinceId,
        statusId: dataStep1.statusId,
        symptomId: dataStep1.animal_sick,
        tambonId: dataStep1.subDistrictId,
      },
      // fm01Symptoms: dataSymptoms,
      fm0102: {
        barns: barnsStep2,
        animals: listAnimalChoose,
      },
      deleteAnimals: delAnimal,
      // deleteSymptom: symptomsDel,
    };

    // return;
    try {
      const response = await API.addDiseaseStatement(data);
      if (response.status == 200) {
        saveEditOutbreak(props.match.params.id);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      } else {
        MySwal.fire({
          text: "บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
      }
      console.log("error", error);
    }
  };

  const saveEditOutbreak = async (idOutbreak) => {
    const dataById = form.dataAllById;
    const dataStep1 = form.step1;
    const dataStep6 = form.step6;
    const dataStep7 = form.step7;
    const dataStep1Symptoms = form.addSymptoms;
    const delSymptomsData = form.delSymptoms;

    const symptomsDel = [];
    if (delSymptomsData && delSymptomsData.length > 0) {
      delSymptomsData.map((item) => {
        symptomsDel.push(item);
      });
    }

    const dataSymptoms = [];
    if (dataStep1Symptoms && dataStep1Symptoms.length > 0) {
      dataStep1Symptoms.map((item, i) => {
        dataSymptoms.push({
          id: item.id ? item.id : "",
          symptomId: item.symptom.value,
          symptomOther: (item.symptom.other !== undefined) ? item.symptom.other : ""
        });
      });
    }

    const FilesStep7 = [];
    if (dataStep7.data_file && dataStep7.data_file.length > 0) {
      dataStep7.data_file.map((item, i) => {
        if (item.id) {
        } else {
          FilesStep7.push({
            id: item.id ? item.id : "",
            name: item.name,
            description: item.description,
            date: item.date_file ? helper.getDate(item.date_file) : "",
            data: item.file,
          });
        }
      });
    }

    const delFile = [];
    if (dataStep7.del_file && dataStep7.del_file.length > 0) {
      dataStep7.del_file.map((item) => {
        delFile.push(item);
      });
    }

    const data = {
      outbreak: {
        informId: null,
        asId: null,
        farmAmt: null,
        fm01Id: dataById.fm01 ? dataById.fm01.id : "",
        obCode: location.pathname.includes("duplicate")
          ? ""
          : dataById.outbreak
          ? dataById.outbreak.obCode
          : "",
        statusId: 1,
        stdMovementId: null,
        stdWarningId: null,
      },
      fm01: {
        caseCode: location.pathname.includes("duplicate")
          ? ""
          : dataById.fm01
          ? dataById.fm01.caseCode
          : "",
        address: dataStep1.homeno,
        amphurId: dataStep1.districtId,
        animalAround5km: dataStep1.animalAround5km,
        animalTypeId: dataStep1.animalId,
        diseaseTypeId: dataStep1.diseaseTypeId,
        dlaId: dataStep1.localGovernId,
        dlaName: dataStep1.governmentName,
        farmId: dataById.fm01 ? dataById.fm01.farmId : "",
        firstAnimSymptomDate: dataStep1.date_found
          ? helper.getDate(dataStep1.date_found)
          : "",
        foundDiseaseDate: dataStep1.date_found_first
          ? helper.getDate(dataStep1.date_found_first)
          : "",
        humanDeadAmt: dataStep1.humanDead,
        humanSickAmt: dataStep1.humanSick,
        humanTouchAmt: dataStep1.humanTouch,
        informBy: dataStep1.report_from,
        informOther: dataStep1.other_text,
        informId: null,
        mooId: dataStep1.villageId,
        obId: idOutbreak,
        orgId: dataById.fm01 ? dataById.fm01.orgId : "",
        ownerName: form.name,
        ownerTel: form.phone,
        provinceId: dataStep1.provinceId,
        statusId: dataStep1.statusId,
        symptomId: dataStep1.animal_sick,
        tambonId: dataStep1.subDistrictId,
      },
      fm01Symptoms: dataSymptoms,
      outbreak06: {
        obMovement: dataStep6.transfer,
        detention: dataStep6.quarantine,
        detentionDay: dataStep6.evidence,
        treatment: dataStep6.treatment_animal,
        destruction1: dataStep6.bury,
        destruction2: dataStep6.burn,
        destruction3: dataStep6.throw_away,
        destruction4: dataStep6.send_kill,
        cleaning: dataStep6.disinfect,
        cleaningOther: dataStep6.other_disinfect,
        vaccination: dataStep6.vaccine,
        antiInsect1: dataStep6.drop_anti_insect,
        antiInsect2: dataStep6.inject_anti_insect,
        educate: dataStep6.public_relations,
        medicine: dataStep6.deal_anti_insect,
      },
      outbreakFiles: FilesStep7,
      deleteOutbreakFiles: delFile,
      deleteSymptom: symptomsDel,
    };

    dispatch(loading(true));
    try {
      
      const response = await API.addNewOutBreak(data);
      if (response.status === 200) {
        dispatch(loading(false));
        MySwal.fire({
          text: location.pathname.includes("duplicate")
            ? `คัดลอก "Outbreak" สำเร็จ`
            : `แก้ไข "Outbreak" สำเร็จ`,
          icon: "success",
          confirmButtonText: "ตกลง",
        }).then(async (result) => {
          if (result.value) {
            history.push("/passive_surv/outbreak");
          }
        });
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      } else {
        MySwal.fire({
          text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
      }
      console.log("error", error);
    }
  };

  const saveOutbreakFromDisease = async () => {
    if (validate()) {
      const dataStep1 = form.step1;
      const dataById = form.dataAllById;
      const dataStep1Symptoms = form.addSymptoms;
      const delSymptomsData = form.delSymptoms;

      const symptomsDel = [];
      if (delSymptomsData && delSymptomsData.length > 0) {
        delSymptomsData.map((item) => {
          symptomsDel.push(item);
        });
      }

      const dataSymptoms = [];
      if (dataStep1Symptoms && dataStep1Symptoms.length > 0) {
        dataStep1Symptoms.map((item, i) => {
          dataSymptoms.push({
            id: item.id ? item.id : "",
            symptomId: item.symptom.value,
            symptomOther: (item.symptom.other !== undefined) ? item.symptom.other : ""
          });
        });
      }

      const data = {
        outbreak: {
          informId: props.match.params.diseaseId,
          obCode: "",
          statusId: 1,
        },
        fm01: {
          caseCode: "",
          address: dataStep1.homeno,
          amphurId: dataStep1.districtId,
          animalAround5km: dataStep1.animalAround5km,
          animalTypeId: dataStep1.animalId,
          diseaseTypeId: dataStep1.diseaseTypeId,
          dlaId: dataStep1.localGovernId,
          dlaName: dataStep1.governmentName,
          farmId: form.farmId,
          firstAnimSymptomDate: dataStep1.date_found
            ? helper.getDate(dataStep1.date_found)
            : "",
          foundDiseaseDate: dataStep1.date_found_first
            ? helper.getDate(dataStep1.date_found_first)
            : "",
          humanDeadAmt: dataStep1.humanDead,
          humanSickAmt: dataStep1.humanSick,
          humanTouchAmt: dataStep1.humanTouch,
          informBy: dataStep1.report_from,
          informId: null,
          mooId: dataStep1.villageId,
          obId: "",
          orgId: form.orgId,
          ownerName: form.name,
          ownerTel: form.phone,
          provinceId: dataStep1.provinceId,
          statusId: dataStep1.statusId,
          symptomId: "",
          tambonId: dataStep1.subDistrictId,
        },
        fm01Symptoms: dataSymptoms,
        deleteSymptom: symptomsDel,
      };

      try {
        const response = await API.addNewOutBreak(data);
        console.log("response-addNewOutBreak=-", response, data);
        if (response.status === 200) {
          dispatch(loading(false));
          MySwal.fire({
            text: `บันทึก "Outbreak" สำเร็จ`,
            icon: "success",
            confirmButtonText: "ตกลง",
          }).then(async (result) => {
            if (result.value) {
              history.push("/passive_surv/outbreak");
            }
          });
        }
      } catch (error) {
        dispatch(loading(false));
        if (error.response && error.response.status === 401) {
          dispatch(logout({ history }));
        } else {
          MySwal.fire({
            text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
            icon: "warning",
            confirmButtonText: "ตกลง",
          });
        }
        console.log("error-->", error, data);
      }
    }
  };

  const toCancel = () => {
    history.push("/passive_surv/outbreak");
  };

  const setOrganizes = (data) => {
    setForm({ ...form, orgId: data.value, orgName: data.label });
    removeInvalid("orgId");
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "/passive_surv/outbreak", name: "Outbreak" },
    { path: "title", name: "รายการ Outbreak" },
  ];

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if (form.orgId == "" || form.orgId == null || form.orgId == undefined) {
      addInvalid("orgId", "กรุณาเลือกหน่วยงานที่สังกัด");
      validated = false;
    }
    if (form.farmId === "") {
      addInvalid("farmId", "กรุณาเลือกรหัสฟาร์ม");
      validated = false;
    }
    return validated;
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2">
          <Link to={`/passive_surv/outbreak`}>
            <button className="btn btn-danger" onClick={() => toCancel()}>
              ยกเลิก
            </button>
          </Link>
        </div>

        <div className="p-2">
          <button
            className="btn btn-success"
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`passive_surv/outbreak/edit`)
            }
            onClick={() => {
              console.log(
                "location.pathname.--🐧",
                location.pathname,
                form.outbreakId
              );
              if (location.pathname.includes("save") && !form.outbreakId) {
                saveNewOutbreak();
              } else if (location.pathname.includes("from-disease")) {
                saveOutbreakFromDisease();
              } else if (location.pathname.includes("edit")) {
                saveDiseaseStatement();
              } else if (location.pathname.includes("duplicate")) {
                saveEditOutbreak(props.match.params.id);
              }
            }}
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxHead>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="d-flex align-items-center">
                    <InputText
                      type="text"
                      title="เลข Outbreak ID"
                      idName="outbreakCode"
                      value={form.outbreakCode}
                      star={true}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      disabled
                      handleChange={(value) =>
                        setForm({ ...form, outbreakCode: value })
                      }
                    />

                    <div className="mt-auto mb-3 ml-2">
                      <button
                        disabled={location.pathname.includes("save")}
                        className="btn btn-add "
                        onClick={() => setShowOutbreakSearch(true)}
                      >
                        <img
                          className="icon-btn "
                          src="/svg/btn-edit.svg"
                          alt="edit"
                        />
                      </button>
                    </div>
                  </div>
                </Col>

                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="d-flex align-items-center">
                    <InputText
                      type="text"
                      title="เลข case ID"
                      idName="caseCode"
                      value={form.caseCode}
                      star={false}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      disabled
                      handleChange={(value) =>
                        setForm({ ...form, caseCode: value })
                      }
                    />

                    <div className="mt-auto mb-3 ml-2">
                      <button
                        disabled={location.pathname.includes("save")}
                        className="btn btn-add "
                        onClick={() => setShowCaseIdSearch(true)}
                      >
                        <img
                          className="icon-btn "
                          src="/svg/btn-edit.svg"
                          alt="edit"
                        />
                      </button>
                    </div>
                  </div>
                </Col>

                <Col lg={2} md={4} sm={12} xs={12}>
                  <label className="mb-0 normal">หน่วยงานที่แจ้งโรค </label>
                  <div className="d-flex align-items-center">
                    {form.orgName ? (
                      <>
                        <button
                          className="btn btn-primary pt-2 px-3"
                          onClick={() => setShowOrgModal(true)}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="ml-2">{form.orgName}</div>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary p-2 d-flex align-items-center"
                        onClick={() => setShowOrgModal(true)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        <p className="mb-0 ml-2">{t("search")}</p>
                      </button>
                    )}
                  </div>
                  {invalid.orgId ? (
                    <div className="invalid-error">{invalid.orgId}</div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={3} sm={6} xs={6}>
                  <div onClick={() => {
                    setShowFarmSearch(true)
                    setForm({ ...form, modeFarmSearch: form.modeFarmSearch })
                    }
                    }>
                    <InputGroupText
                      type="text"
                      title="รหัสฟาร์ม"
                      imgSrc={"/svg/search.svg"}
                      imgAlt="search-icon"
                      idName="farmId"
                      value={form.farmId}
                      star={false}
                      placeholder="ค้นหาฟาร์ม"
                      classLabel="normal"
                      handleChange={(value) =>
                        setForm({ ...form, farmId: value })
                      }
                      handleInvalid={() => removeInvalid("farmId")}
                      invalid={invalid.farmId}
                    />
                  </div>
                </Col>

                <Col lg={2} md={3} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    <button
                      className="btn btn-darkblue w-100"
                      onClick={() =>
                        history.push("/farm_registration/save-data")
                      }
                    >
                      <img
                        className="mr-2 mb-1 "
                        src={"/svg/menu_5.svg"}
                        alt="menu-icon"
                      />
                      ขึ้นทะเบียนฟาร์ม
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputText
                    disabled
                    type="text"
                    title="ชื่อเจ้าของสัตว์"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputMask
                    disabled
                    mask="99-9999-9999"
                    title="หมายเลขโทรศัพท์"
                    idName="phone"
                    value={form.phone}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, phone: value.replace(/-/g, "") })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="บ้านเลขที่"
                    idName="homeno"
                    value={form.homeno}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, homeno: value })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    disabled
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    disabled
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    disabled
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    disabled
                    title="หมู่ที่"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="villageId"
                    classLabel="normal"
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({ ...form, villageId: value.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxHead>

          <div
            className="pt-3 container-fixed-footer ml-0"
            disabled={
              location.pathname.includes("outbreak/save") ? true : false
            }
          >
            <ul
              className="nav nav-pills mb-3 font14"
              id="pills-tab"
              role="tablist"
            >
              <li
                className="nav-item "
                onClick={() => setActiveTab("pills-data-tab")}
              >
                <a
                  className={`nav-link ${
                    location?.search.includes("pills-outbreak") ? "" : "active"
                  } `}
                  id="pills-data-tab"
                  data-toggle="pill"
                  href="#pills-data"
                  role="tab"
                  aria-controls="pills-data"
                  aria-selected={
                    location?.search.includes("pills-outbreak")
                      ? "false"
                      : "true"
                  }
                >
                  1.ข้อมูลทั่วไป
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-animal-tab")}
                disabled={
                  location.pathname.includes("from-disease") ||
                  location.pathname.includes("duplicate")
                    ? true
                    : false
                }
              >
                <a
                  className="nav-link "
                  id="pills-animal-tab"
                  data-toggle="pill"
                  href="#pills-animal"
                  role="tab"
                  aria-controls="pills-animal"
                  aria-selected="false"
                >
                  2.ข้อมูลสัตว์
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-ex-tab")}
                disabled={
                  location.pathname.includes("from-disease") ||
                  location.pathname.includes("duplicate")
                    ? true
                    : false
                }
              >
                <a
                  className="nav-link "
                  id="pills-ex-tab"
                  data-toggle="pill"
                  href="#pills-ex"
                  role="tab"
                  aria-controls="pills-ex"
                  aria-selected="false"
                >
                  3.ข้อมูลการเก็บตัวอย่าง
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-control-tab")}
                disabled={
                  location.pathname.includes("from-disease") ||
                  location.pathname.includes("duplicate")
                    ? true
                    : false
                }
              >
                <a
                  className="nav-link "
                  id="pills-control-tab"
                  data-toggle="pill"
                  href="#pills-control"
                  role="tab"
                  aria-controls="pills-control"
                  aria-selected="false"
                >
                  4. ภาวะการณ์และควบคุมโรค
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-carryout-tab")}
                disabled={
                  location.pathname.includes("from-disease") ||
                  location.pathname.includes("duplicate")
                    ? true
                    : false
                }
              >
                <a
                  className="nav-link "
                  id="pills-carryout-tab"
                  data-toggle="pill"
                  href="#pills-carryout"
                  role="tab"
                  aria-controls="pills-carryout"
                  aria-selected="false"
                >
                  5.การดำเนินการ
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-outbreak-tab")}
                disabled={
                  location.pathname.includes("from-disease") ||
                  location.pathname.includes("duplicate")
                    ? true
                    : false
                }
              >
                <a
                  className={`nav-link ${
                    location?.search.includes("pills-outbreak") ? "active" : ""
                  } `}
                  id="pills-outbreak-tab"
                  data-toggle="pill"
                  href="#pills-outbreak"
                  role="tab"
                  aria-controls="pills-outbreak"
                  aria-selected={
                    location?.search.includes("pills-outbreak") ||
                    location.pathname.includes("duplicate")
                      ? "true"
                      : "false"
                  }
                >
                  6.การดำเนินการราย Outbreak
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-file-tab")}
                disabled={
                  location.pathname.includes("from-disease") ||
                  location.pathname.includes("duplicate")
                    ? true
                    : false
                }
              >
                <a
                  className="nav-link "
                  id="pills-file-tab"
                  data-toggle="pill"
                  href="#pills-file"
                  role="tab"
                  aria-controls="pills-file"
                  aria-selected="false"
                >
                  7.แนบไฟล์
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => setActiveTab("pills-vaccinate-tab")}
                disabled={
                  location.pathname.includes("from-disease") ||
                  location.pathname.includes("duplicate")
                    ? true
                    : false
                }
              >
                <a
                  className="nav-link"
                  id="pills-vaccinate-tab"
                  data-toggle="pill"
                  href="#pills-vaccinate"
                  role="tab"
                  aria-controls="pills-vaccinate"
                  aria-selected="false"
                >
                  8.การฉีดวัคซีน
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className={`tab-pane fade ${
                  location?.search.includes("pills-outbreak")
                    ? ""
                    : "show active"
                } `}
                id="pills-data"
                role="tabpanel"
                aria-labelledby="pills-data-tab"
              >
                {form.modeGet === "default" && (
                  <OutbreakStep1
                    provinceLists={provinceList || []}
                    save={(data, addSymptoms, delSymptoms) => {
                      setForm({
                        ...form,
                        step1: data,
                        addSymptoms: addSymptoms,
                        delSymptoms: delSymptoms,
                      });
                    }}
                    activeTab={activeTab}
                    modeGet={form.modeGet}
                  />
                )}
                {form.modeGet === "getDisease" ? (
                  <OutbreakStep1
                    provinceLists={provinceList || []}
                    save={(data, addSymptoms, delSymptoms) => {
                      setForm({
                        ...form,
                        step1: data,
                        addSymptoms: addSymptoms,
                        delSymptoms: delSymptoms,
                      });
                    }}
                    activeTab={activeTab}
                    modeGet={form.modeGet}
                    dataAll={form.step1}
                    symptomsData={form.addSymptoms}
                  />
                ) : (
                  ""
                )}
                {form.modeGet === "getById" ? (
                  <OutbreakStep1
                    provinceLists={provinceList || []}
                    save={(data, addSymptoms, delSymptoms) => {
                      setForm({
                        ...form,
                        step1: data,
                        addSymptoms: addSymptoms,
                        delSymptoms: delSymptoms,
                      });
                    }}
                    activeTab={activeTab}
                    modeGet={form.modeGet}
                    dataAll={form.step1}
                    symptomsData={form.addSymptoms}
                  />
                ) : (
                  ""
                )}
                {form.modeGet === "farmById" ? (
                  <OutbreakStep1
                    provinceLists={provinceList || []}
                    save={(data, addSymptoms, delSymptoms) => {
                      setForm({
                        ...form,
                        step1: data,
                        addSymptoms: addSymptoms,
                        delSymptoms: delSymptoms,
                      });
                    }}
                    activeTab={activeTab}
                    modeGet={form.modeGet}
                    dataAll={form.step1}
                    symptomsData={form.addSymptoms}
                  />
                ) : (
                  ""
                )}
              </div>

              <div
                className="tab-pane fade"
                id="pills-animal"
                role="tabpanel"
                aria-labelledby="pills-animal-tab"
              >
                <div className=" bg-white ">
                  {form.modeGet === "default" && (
                    <OutbreakStep2
                      save={(data) => {
                        setForm({ ...form, step2: data });
                      }}
                      activeTab={activeTab}
                      modeGet={form.modeGet}
                    />
                  )}
                  {form.modeGet === "getDisease" ? (
                    <OutbreakStep2
                      save={(data) => {
                        setForm({ ...form, step2: data });
                      }}
                      activeTab={activeTab}
                      modeGet={form.modeGet}
                      dataAll={form.step2}
                      farmId={form.farmId}
                    />
                  ) : (
                    ""
                  )}
                  {form.modeGet === "getById" ? (
                    <OutbreakStep2
                      save={(data) => {
                        setForm({ ...form, step2: data });
                      }}
                      activeTab={activeTab}
                      modeGet={form.modeGet}
                      dataAll={form.step2}
                      farmId={form.farmId}
                      checkEditAnimal={checkEditAnimal}
                      getDiseaseById={() => {
                        setCheckEditAnimal(true);
                        getOutbreakById(props.match.params.id);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {form.modeGet === "farmById" ? (
                    <OutbreakStep2
                      save={(data) => {
                        setForm({ ...form, step2: data });
                      }}
                      activeTab={activeTab}
                      modeGet={form.modeGet}
                      dataAll={form.step2}
                      farmId={form.farmId}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-ex"
                role="tabpanel"
                aria-labelledby="pills-ex-tab"
              >
                <div className=" bg-white ">
                  <OutbreakStep3
                    save={(data) => {
                      setForm({ ...form, step3: data });
                    }}
                    activeTab={activeTab}
                    modeGet={form.modeGet}
                    caseCode={form.caseCode}
                    obCode={form.outbreakCode}
                  />
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-control"
                role="tabpanel"
                aria-labelledby="pills-control-tab"
              >
                <OutbreakStep4
                  save={(data) => {
                    setForm({ ...form, step4: data });
                  }}
                  activeTab={activeTab}
                  modeGet={form.modeGet}
                  obId={props.match.params.id}
                  obCode={form.outbreakCode}
                />
              </div>

              <div
                className="tab-pane fade"
                id="pills-carryout"
                role="tabpanel"
                aria-labelledby="pills-carryout-tab"
              >
                <div className=" bg-white ">
                  {form.modeGet === "default" && (
                    <OutbreakStep5
                      save={(data) => {
                        setForm({ ...form, step5: data });
                      }}
                      activeTab={activeTab}
                      modeGet={form.modeGet}
                      dataAll={form.step5}
                    />
                  )}
                  {form.modeGet === "getById" ? (
                    <OutbreakStep5
                      save={(data) => {
                        setForm({ ...form, step5: data });
                      }}
                      activeTab={activeTab}
                      modeGet={form.modeGet}
                      dataAll={form.step5}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div
                className={`tab-pane fade ${
                  location?.search.includes("pills-outbreak")
                    ? "show active"
                    : ""
                } `}
                id="pills-outbreak"
                role="tabpanel"
                aria-labelledby="pills-outbreak-tab"
              >
                <div className=" bg-white ">
                  {form.modeGet == "default" && (
                    <OutbreakStep6
                      obId={props.match.params.id}
                      save={(data) => {
                        setForm({ ...form, step6: data });
                      }}
                      activeTab={activeTab}
                      modeGet={form.modeGet}
                      dataAll={form.step6}
                    />
                  )}
                  {form.modeGet == "getById" ? (
                    <OutbreakStep6
                      obId={props.match.params.id}
                      save={(data) => {
                        setForm({ ...form, step6: data });
                      }}
                      activeTab={activeTab}
                      modeGet={form.modeGet}
                      dataAll={form.step6}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-file"
                role="tabpanel"
                aria-labelledby="pills-file-tab"
              >
                {form.modeGet === "default" && (
                  <OutbreakStep7
                    save={(data) => {
                      setForm({ ...form, step7: data });
                    }}
                    activeTab={activeTab}
                    modeGet={form.modeGet}
                    dataAll={form.step7}
                  />
                )}
                {form.modeGet === "getById" ? (
                  <OutbreakStep7
                    save={(data) => {
                      setForm({ ...form, step7: data });
                    }}
                    activeTab={activeTab}
                    modeGet={form.modeGet}
                    dataAll={form.step7}
                  />
                ) : (
                  ""
                )}
              </div>

              <div
                className="tab-pane fade"
                id="pills-vaccinate"
                role="tabpanel"
                aria-labelledby="pills-vaccinate-tab"
              >
                {form.modeGet === "default" && (
                  <OutbreakStep8
                    formData={form}
                    save={(data) => {
                      setForm({ ...form, step8: data });
                    }}
                    activeTab={activeTab}
                    modeGet={form.modeGet}
                    farmId={form.farmId}
                    obId={props.match.params.id}
                  />
                )}
                {form.modeGet === "getById" ? (
                  <OutbreakStep8
                    formData={form}
                    save={(data) => {
                      setForm({ ...form, step8: data });
                    }}
                    activeTab={activeTab}
                    modeGet={form.modeGet}
                    farmId={form.farmId}
                    obId={props.match.params.id}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        {showOutbreakSearch && (
          <ModalOutbreakSearch
            show={showOutbreakSearch}
            mode={form.modeOutbreak}
            close={() => setShowOutbreakSearch(false)}
            save={(value) =>
              setForm({
                ...form,
                outbreakCode: value.obCode,
                outbreakId: value.obId,
              })
            }
          />
        )}
        {showFarmSearch && (
          <ModalFarmSearch
            show={showFarmSearch}
            mode={form.modeFarmSearch}
            close={() => setShowFarmSearch(false)}
            save={(value) => {
              setForm({ ...form, farmId: value });
              removeInvalid("farmId");
            }}
          />
        )}
        {showOrgModal && (
          <ModalOrganizes
            show={showOrgModal}
            close={() => setShowOrgModal(false)}
            toSaveOrg={(data) => setOrganizes(data)}
          />
        )}
      </div>
    </div>
  );
}
export default Save_Outbreak;
