import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { loading } from "../../redux/actions/loadingAction";
import * as helper from "../../utils/helper";
import { Row, Col, Form } from "react-bootstrap";

import pdfMake from "pdfmake-thai/build/pdfmake";
import pdfFonts from "pdfmake-thai/build/vfs_fonts";

import BoxCard from "../../components/BoxCard";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "../../components/DataTable";
import * as API from "../../utils/apis";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useMasterData } from "../../components/hooks/useMasterData";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Immune_Level() {
  const user = useSelector((state) => state.login.result);
  const ref = React.createRef();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "footer"]);

  const heading = [
    ['ลำดับ', 'ปีงบประมาณ', 'โครงการ', 'ชนิดสัตว์', 'สถานที่เก็บข้อมูล', '', '', '', 'จำนวนตัวอย่าง', 'ระดับภูมิคุ้มกัน', '', '', '', '', ''],
    ['', '', '', '', 'เขต', 'จังหวัด', 'อำเภอ', 'ตำบล', '', 'Type O', '', 'Type A', '', 'Type Asian 1', ''],
    ['', '', '', '', '', '', '', '', '', '< 80 (ตัว)', '>= 80 (ตัว)', '< 80 (ตัว)', '>= 80 (ตัว)', '< 80 (ตัว)', '>= 80 (ตัว)']
  ];
  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 2, c: 0 } },
    { s: { r: 0, c: 1 }, e: { r: 2, c: 1 } },
    { s: { r: 0, c: 2 }, e: { r: 2, c: 2 } },
    { s: { r: 0, c: 3 }, e: { r: 2, c: 3 } },
    { s: { r: 0, c: 4 }, e: { r: 0, c: 7 } },
    { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
    { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
    { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },
    { s: { r: 1, c: 7 }, e: { r: 2, c: 7 } },
    { s: { r: 0, c: 8 }, e: { r: 2, c: 8 } },
    { s: { r: 0, c: 9 }, e: { r: 0, c: 14 } },
    { s: { r: 1, c: 9 }, e: { r: 1, c: 10 } },
    { s: { r: 1, c: 11 }, e: { r: 1, c: 12 } },
    { s: { r: 1, c: 13 }, e: { r: 1, c: 14 } },
  ];

  const initialForm = {
    year: "",
    projectName: "",
    dateBegin: "",
    dateEnd: "",
    provinceId: "",
    diseaseTypeId: "",
    diseaseTypeName: "",
    sendSampleMethodId: "",
    sendSampleMethodName: "",
    immType: "O",
    zoneId: "",
    zoneName: "",
    amphurId: "",
    amphurName: "",
    provinceName: "",
    animalTypeId: "",
    animalTypeName: "",
    immunity: "",
    sampleAmt: "",
    resultPlus: "",
    resultMinus: "",
    resultUnknown: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        name: "ลำดับ",
        width: "50px",
        rowSpan: 3,
      },
      {
        sortBy: "year",
        name: "ปีงบประมาณ",
        sortFlg: true,
        rowSpan: 3,
      },

      {
        sortBy: "projectName",
        name: "โครงการ",
        sortFlg: true,
        rowSpan: 3,
      },

      {
        sortBy: "animalTypeName",
        name: "ชนิดสัตว์",
        sortFlg: true,
        rowSpan: 3,
      },
      {
        name: "สถานที่เก็บข้อมูล",
        sortFlg: false,
        colSpan: 4,
        //
        childHead: [
          {
            sortBy: "zoneName",
            name: "เขต",
            sortFlg: true,
            rowSpan: 2,
          },
          {
            sortBy: "provinceId",
            name: "จังหวัด",
            sortFlg: true,
            rowSpan: 2,
          },
          {
            sortBy: "amphurId",
            name: "อำเภอ",
            sortFlg: true,
            rowSpan: 2,
          },
          {
            sortBy: "tambonId",
            name: "ตำบล",
            sortFlg: true,
            rowSpan: 2,
          },
        ],
      },
      {
        sortBy: "targetAmount",
        name: "จำนวนตัวอย่าง",
        sortFlg: true,
        rowSpan: 3,
      },
      {
        name: "ระดับภูมิคุ้มกัน",
        sortFlg: false,
        colSpan: 6,
        childHead: [
          {
            sortBy: "zoneName",
            name: "type O",
            sortFlg: false,
            colSpan: 2,
            childHeadDetail: [
              {
                sortBy: "zoneName",
                name: "< 80 (ตัว)",
                sortFlg: false,
              },
              {
                sortBy: "provinceId",
                name: ">= 80(ตัว)",
                sortFlg: false,
              },
            ],
          },
          {
            sortBy: "provinceId",
            name: "type A",
            sortFlg: false,
            colSpan: 2,
            childHeadDetail: [
              {
                sortBy: "zoneName",
                name: "< 80 (ตัว)",
                sortFlg: false,
              },
              {
                sortBy: "provinceId",
                name: ">= 80(ตัว)",
                sortFlg: false,
              },
            ],
          },
          {
            sortBy: "amphurId",
            name: "typeAsian1",
            sortFlg: false,
            colSpan: 2,
            childHeadDetail: [
              {
                sortBy: "zoneName",
                name: "< 80 (ตัว)",
                sortFlg: false,
              },
              {
                sortBy: "provinceId",
                name: ">= 80(ตัว)",
                sortFlg: false,
              },
            ],
          },
        ],
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  // select จังหวัด ตำบล
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  // =================

  // select  ปีงบประมาณ  โครงการ ชนิดสัตว์ เขต ชนิดโรค วิธีการทดสอบ ชนิดระดับภูมิคุ้มกัน
  const [fiscalYearSelectValue, setFiscalYearSelectValue] = useState([]);
  const [fiscalYearList, setFiscalYearList] = useState([]);

  const [projectSelectValue, setProjectSelectValue] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const [AnimalSelectValue, setAnimalSelectValue] = useState([]);
  const [AnimalList, setAnimalList] = useState([]);

  const [zoneSelectValue, setZoneSelectValue] = useState([]);
  const [zoneList, setZoneList] = useState([]);

  const [diseaseSelectValue, setDiseaseSelectValue] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);

  const [testSelectValue, setTestSelectValue] = useState([]);
  const [testList, setTestList] = useState([]);

  const [levelSelectValue, setLevelSelectValue] = useState([
    { value: "O", label: "O" },
  ]);
  const selectLevelList = [
    { value: "O", label: "O" },
    { value: "A", label: "A" },
    { value: "A1", label: "A1" },
  ];
  const [levelList, setLevelList] = useState(selectLevelList);

  // select file type to download
  const [fileType, setFileType] = useState("excel");
  const fileName = "C03RPT03-ImmuneLevel_" + helper.getDate(new Date());

  const [isClearSearch, setIsClearSearch] = useState(false);

  const [listImmuneLevel, setListImmuneLevel] = useState([]);

  // =================

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/active_surv/action_plan", name: "การเฝ้าระวังเชิงรุก" },
    { path: "title", name: "รายงาน" },
    { path: "active", name: "C03RPT03-ระดับภูมิคุ้มกัน" },
  ];

  pdfMake.fonts = {
    THSarabunNew: {
      normal: "THSarabunNew.ttf",
      bold: "THSarabunNew-Bold.ttf",
      italics: "THSarabunNew-Italic.ttf",
      bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
  };
  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData("zoneLivestock", zoneList, setZoneList, provinceByZoneId);

  useEffect(() => {
    getActionPlanYear();
    getActionProject();
    getAnimal();
    getZoneLivestock();
    // getProvince();
    getDiseaseType();
    getTestMethod();
    getImmuneLevelReport();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getImmuneLevelReport = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getImmuneLevelReport(
        form.dateBegin ? helper.getDate(form.dateBegin) : "",
        form.dateEnd ? helper.getDate(form.dateEnd) : "",
        form.zoneId,
        form.provinceId,
        form.amphurId,
        form.year,
        form.projectName,
        form.animalTypeId,
        form.diseaseTypeId,
        form.sendSampleMethodId,
        form.immType,
        form.sortBy,
        form.sortDir,
        form.page,
        form.limit
      );
      const data = await response?.data;
      // console.log("response-getImmuneLevelReport-🐧🐧", response);
      if (response.status == 200) {
        setListImmuneLevel(data.content);
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getImmuneLevelToExport = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getImmuneLevelReport(
        form.dateBegin ? helper.getDate(form.dateBegin) : "",
        form.dateEnd ? helper.getDate(form.dateEnd) : "",
        form.zoneId,
        form.provinceId,
        form.amphurId,
        form.year,
        form.projectName,
        form.animalTypeId,
        form.diseaseTypeId,
        form.sendSampleMethodId,
        form.immType,
        form.sortBy,
        form.sortDir,
        form.page,
        form.totalElements
      );
      const data = await response?.data;
      // console.log("response-export - getImmuneLevelReport-🐧🐧", data);
      if (response.status == 200) {
        let immuneLevelArray = [];
        data.content.forEach((element) => {
          immuneLevelArray.push({
            id: element.id,
            year: element.year,
            projectName: element.projectName,
            provinceId: element.provinceId,
            amphurId: element.amphurId,
            tambonId: element.tambonId,
            zoneName: element.zoneName,
            provinceName: element.provinceName,
            amphurName: element.amphurName,
            tambonName: element.tambonName || "",
            animalTypeId: element.animalTypeId,
            animalTypeName: element.animalTypeName || "",
            sampleAmt: element.sampleAmt,
            resultPlus: element.resultPlus,
            resultMinus: element.resultMinus,
            resultUnknown: element.resultUnknown,
            immunity: element.immunity,
            diseaseTypeId: element.diseaseTypeId,
            sendSampleMethodId: element.sendSampleMethodId,
            dateBegin: element.dateBegin,
            dateEnd: element.dateEnd,
            typeOLess80: element.typeO?.less80,
            typeOGreater80: element.typeO?.greater80,
            typeALess80: element.typeA?.less80,
            typeAGreater80: element.typeA?.greater80,
            typeA1Less80: element.typeA1?.less80,
            typeA1Greater80: element.typeA1?.greater80,
          });
        });
        downloadRecord(immuneLevelArray, data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setFiscalYearSelectValue([]);
    setProjectSelectValue([]);
    setAnimalSelectValue([]);
    setZoneSelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setDistrictList([]);
    setDiseaseSelectValue([]);
    setTestSelectValue([]);
    setLevelSelectValue([{ value: "O", label: "O" }]);
    setFileType("excel");
    setIsClearSearch(!isClearSearch);
  };

  const getActionPlanYear = async () => {
    try {
      const response = await API.getActionPlanYear();
      const data = await response.data;
      if (response.status === 200) {
        let yearArray = [];
        data.forEach((element) => {
          yearArray.push({
            value: element,
            label: element,
          });
        });
        setFiscalYearList(yearArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActionProject = async () => {
    try {
      const response = await API.getActionProject();
      const data = await response.data;
      if (response.status == 200) {
        let projectArray = [];
        data.forEach((element) => {
          projectArray.push({
            value: element,
            label: element,
          });
        });
        await setProjectList(projectArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType("", "", "1", "100", 1);
      const data = await response.data;
      if (response.status === 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseaseList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTestMethod = async () => {
    try {
      const response = await API.getTestMethod(1, "");
      const data = await response.data;
      if (response.status === 200) {
        let testMethodArray = [];
        data.content.forEach((element) => {
          testMethodArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setTestList(testMethodArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueZone = (value) => {
    setZoneSelectValue([{ value: value.value, label: value.label }]);
    setProvinceSelectValue([]);
    setForm({ ...form, zoneName: value.value });
    getProvince(value.value);
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      amphurId: value.value,
      zipcode: "",
      chooseSelect: true,
    });
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type == "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type == "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const buttonSearch = () => {
    return (
      <>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          {t("clear")}
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const buttonExport = () => {
    return (
      <>
        <button
          className="btn btn-report"
          onClick={() => getImmuneLevelToExport()}
          disabled={user?.userGroup.mexport === "n"}
        >
          <a className="text-white">
            <img
              className="nav-icon"
              src={"/svg/report.svg"}
              alt="export-icon"
            />{" "}
            ออกรายงาน
          </a>
        </button>
      </>
    );
  };

  const convertListToExcelTable = (objArray) => {
    let excelTable = [];
    let year = "ปีงบประมาณ";
    let project = "โครงการ";
    let animalType = "ชนิดสัตว์";
    let zone = "เขต";
    let province = "จังหวัด";
    let amphur = "อำเภอ";
    let tambon = "ตำบล";
    let immu = "ระดับภูมิคุ้มระดับภูมิคุ้มกันเอเชียวัน (A1)กัน";
    let dataNum1 = " จำนวนตัวอย่าง(ตัว)";
    let dataNum2 = " ผลบวก (+)(ตัว)";
    let dataNum3 = " ผลลบ (-)(ตัว)";
    let dataNum4 = " ตรวจไม่ได้(ตัว)";

    let sampleAmt = " จำนวนตัวอย่าง",
    typeOLess80 = "type O < 80 (ตัว)",
    typeOGreater80 = "type O >= 80 (ตัว)",
    typeALess80 = "type A < 80 (ตัว)",
    typeAGreater80 = "type A >= 80 (ตัว)",
    typeA1Less80 = "type A1 < 80 (ตัว)",
    typeA1Greater80 = "type A1 >= 80 (ตัว)";

    // objArray.map((element, index) => {
    //   excelTable.push({
    //     ลำดับ: index + 1,
    //   });
    //   excelTable[index][year] = element.year;
    //   excelTable[index][project] = element.projectName;
    //   excelTable[index][animalType] = element.animalTypeName;
    //   excelTable[index][zone] = element.zoneName;
    //   excelTable[index][province] = element.provinceName;
    //   excelTable[index][amphur] = element.amphurName;
    //   excelTable[index][tambon] = element.tambonName;
    //   // excelTable[index][immu] = element.immunity;
    //   // excelTable[index][dataNum1] = element.sampleAmt;
    //   // excelTable[index][dataNum2] = element.resultPlus;
    //   // excelTable[index][dataNum3] = element.resultMinus;
    //   // excelTable[index][dataNum4] = element.resultUnknown;

    //   excelTable[index][sampleAmt] = element.sampleAmt;
    //   excelTable[index][typeOLess80] = element.typeOLess80;
    //   excelTable[index][typeOGreater80] = element.typeOGreater80;
    //   excelTable[index][typeALess80] = element.typeALess80;
    //   excelTable[index][typeAGreater80] = element.typeAGreater80;
    //   excelTable[index][typeA1Less80] = element.typeA1Less80;
    //   excelTable[index][typeA1Greater80] = element.typeA1Greater80;
    // });

    // return excelTable;

    return objArray.map((element, index) => {
      return {
        index: index + 1,
        year: element.year,
        project: element.projectName,
        animalType: element.animalTypeName,
        zone: element.zoneName,
        province: element.provinceName,
        amphur: element.amphurName,
        tambon: element.tambonName,
        sampleAmt: element.sampleAmt,
        typeOLess80: element.typeOLess80,
        typeOGreater80: element.typeOGreater80,
        typeALess80: element.typeALess80,
        typeAGreater80: element.typeAGreater80,
        typeA1Less80:  element.typeA1Less80,
        typeA1Greater80: element.typeA1Greater80,
      }
    });
  };

  const convertToCSV = (objArray) => {
    // let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    // let headCol = "";
    // let dataRow = "";
    // let key = Object.keys(array[0]);
    // key.forEach((item) => {
    //   if (headCol === "") {
    //     headCol = item;
    //   } else {
    //     if (item != "") {
    //       headCol += ",";
    //       headCol += item;
    //     }
    //   }
    // });
    // for (let i = 0; i < array.length; i++) {
    //   let line = "";
    //   for (let index in array[i]) {
    //     if (line != "") {
    //       line += ",";
    //     }
    //     line += array[i][index];
    //   }
    //   dataRow += line + "\r\n";
    // }
    // return headCol + "\r\n" + dataRow;
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet([]);
    workSheet["!merges"] = merge;
    XLSX.utils.sheet_add_aoa(workSheet, heading);
    XLSX.utils.sheet_add_json(workSheet, objArray, { origin: 'A4', skipHeader: true });
    XLSX.utils.book_append_sheet(workBook, workSheet);
    XLSX.utils.sheet_to_csv(workSheet);
    return XLSX.write(workBook, { bookType: "csv", type: "array" });
  };

  const convertToExcel = (objArray) => {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet([]);
    workSheet["!merges"] = merge;
    XLSX.utils.sheet_add_aoa(workSheet, heading);
    XLSX.utils.sheet_add_json(workSheet, objArray, { origin: 'A4', skipHeader: true });
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
    return XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  };

  const printPDF = (fileName, dataListForPdf) => {
    const headTitle = [
      [{text: 'ลำดับ', rowSpan: 3, style: 'tableHeader', alignment: 'center'},  { text: "ปีงบประมาณ", rowSpan: 3,style: 'tableHeader', alignment: 'center'}, { text: "โครงการ", rowSpan: 3, style: 'tableHeader', alignment: 'center'}, { text: "ชนิดสัตว์", rowSpan: 3, style: 'tableHeader', alignment: 'center'},  { text: 'สถานที่เก็บข้อมูล', colSpan: 4, style:"header", style: 'tableHeader', alignment: 'center'}, "", "", "", {text: 'จำนวนตัวอย่าง', rowSpan: 3, style: 'tableHeader', alignment: 'center'}, {text: 'ระดับภูมิคุ้มกัน', colSpan: 6, style: 'tableHeader', alignment: 'center'}, '', '', '', '', ''],
      ['', '', '', '', {text: 'เขต', rowSpan: 2, style: 'tableHeader', alignment: 'center'}, {text: 'จังหวัด', rowSpan: 2, style: 'tableHeader', alignment: 'center'}, {text: 'อำเภอ', rowSpan: 2, style: 'tableHeader', alignment: 'center'},{text: 'ตำบล', rowSpan: 2, style: 'tableHeader', alignment: 'center'}, '', {text: 'Type O', colSpan: 2, style: 'tableHeader', alignment: 'center'}, '', {text: 'Type A', colSpan: 2, style: 'tableHeader', alignment: 'center'}, '', {text: 'Type Asian 1', colSpan: 2, style: 'tableHeader', alignment: 'center'}, ''],
      ['', '', '', '', '', '', '', '', '', {text: '< 80 (ตัว)', style: 'tableHeader', alignment: 'center'}, {text: '>= 80 (ตัว)', style: 'tableHeader', alignment: 'center'}, {text: '< 80 (ตัว)', style: 'tableHeader', alignment: 'center'}, {text: '>= 80 (ตัว)', style: 'tableHeader', alignment: 'center'}, {text: '< 80 (ตัว)', style: 'tableHeader', alignment: 'center'}, {text: '>= 80 (ตัว)', style: 'tableHeader', alignment: 'center'}]
    ];
    // if (form.headTitle && form.headTitle.length > 0) {
    //   form.headTitle.map((item, i) => {
    //     headTitle.push(item.name);
    //   });
    // }

    let listImmunePdf = [];
    if (dataListForPdf && dataListForPdf.length > 0) {
      dataListForPdf.map((item, i) => {
        listImmunePdf.push([
          i + 1,
          item.year,
          item.projectName,
          item.animalTypeName || "-",
          // item.immunity ? item.immunity : 0,
          item.zoneName || "-",
          item.provinceName || "-",
          item.amphurName || "-",
          item.tambonName || "-",
          { text: item.sampleAmt ? item.sampleAmt : 0, alignment: 'right'}, 
          // item.resultPlus ? item.resultPlus : 0,
          // item.resultMinus ? item.resultMinus : 0,
          // item.resultUnknown ? item.resultUnknown : 0,
          { text:item.typeO?.less80 ? item.typeO?.less80 : 0, alignment: 'right'}, 
          { text:item.typeO?.greater80 ? item.typeO?.greater80 : 0, alignment: 'right'}, 
          { text:item.typeA?.less80 ? item.typeA?.less80 : 0, alignment: 'right'}, 
          { text:item.typeA?.greater80 ? item.typeA?.greater80 : 0, alignment: 'right'}, 
          { text:item.typeA1?.less80 ? item.typeA1?.less80 : 0, alignment: 'right'}, 
          { text:item.typeA1?.greater80 ? item.typeA1?.greater80 : 0, alignment: 'right'}
        ]);
      });
    }

    let bodyArray = [...headTitle];
    listImmunePdf.map((item, i) => {
      bodyArray.push(listImmunePdf[i]);
    });
    let docDefinition = {
      pageOrientation: 'landscape',
      // pageMargins: [ 20, 40],
      content: [
        {
          style: "tableExample",
          headerRows: 3,
          table: {
            heights: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
            widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
            body: bodyArray,
          },
        },
      ],
      defaultStyle: {
        fontSize: 14,
        font: "THSarabunNew",
      },
    };
    pdfMake.createPdf(docDefinition).download(fileName + ".pdf");
  };

  const downloadRecord = (dataList, dataListForPdf) => {
    let type = "";
    if (fileType === "excel") {
      type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = convertToExcel(convertListToExcelTable(dataList));
      const data = new Blob([excelBuffer], { type: type });
      FileSaver.saveAs(data, fileName + ".xlsx");
    }
    if (fileType === "csv") {
      type = "text/csv;charset=utf-8";
      const csv = convertToCSV(convertListToExcelTable(dataList));
      const csvData = new Blob([csv], { type: type });
      FileSaver.saveAs(csvData, fileName + ".csv");
    }
    if (fileType === "pdf") {
      printPDF(fileName, dataListForPdf);
    }
  };

  const checkExport = (value) => {
    if (fileType === value) {
      return true;
    } else {
      return false;
    }
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td className="text-center">{helper.rowNumber(i, form.page, form.limit)}</td>
          <td className="text-center">{item.year}</td>
          <td className="text-center">{item.projectName}</td>
          <td className="text-center">{item.animalTypeName || "-"}</td>
          <td className="text-center">{item.zoneName || "-"}</td>
          <td className="text-center">{item.provinceName || "-"}</td>
          <td className="text-center">{item.amphurName || "-"}</td>
          <td className="text-center">{item.tambonName || "-"}</td>
          <td className="text-center">{item.sampleAmt}</td>
          <td className="text-center">{item.typeO?.less80}</td>
          <td className="text-center">{item.typeO?.greater80}</td>
          <td className="text-center">{item.typeA?.less80}</td>
          <td className="text-center">{item.typeA?.greater80}</td>
          <td className="text-center">{item.typeA1?.less80}</td>
          <td className="text-center">{item.typeA1?.greater80}</td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="15" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่บันทึกข้อมูล"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateBegin}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, dateBegin: value })
                    }
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateEnd}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, dateEnd: value })
                    }
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ปีงบประมาณ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={fiscalYearSelectValue}
                    optionsList={fiscalYearList}
                    handleChange={(value) => {
                      setFiscalYearSelectValue(value);
                      setForm({ ...form, year: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="โครงการ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={projectSelectValue}
                    optionsList={projectList}
                    handleChange={(value) => {
                      setProjectSelectValue(value);
                      setForm({ ...form, projectName: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={AnimalSelectValue}
                    optionsList={AnimalList}
                    handleChange={(value) => {
                      setAnimalSelectValue(value);
                      setForm({
                        ...form,
                        animalTypeId: value.value,
                        animalTypeName: value.label,
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขต"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="zone"
                    classLabel="normal"
                    selectValue={zoneSelectValue}
                    optionsList={zoneList}
                    handleChange={(value) => {
                      selectValueZone(value);
                      setForm({ ...form, zoneId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="provinceId"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => {
                      selectValueProvince(value);
                      setForm({ ...form, provinceId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => {
                      selectValueDistrict(value);
                      setForm({ ...form, amphurId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดโรค"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="diseaseTypeId"
                    classLabel="normal"
                    selectValue={diseaseSelectValue}
                    optionsList={diseaseList}
                    handleChange={(value) => {
                      setDiseaseSelectValue(value);
                      setForm({
                        ...form,
                        diseaseTypeId: value.value,
                        diseaseTypeName: value.label,
                      });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="วิธีการทดสอบ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="sendSampleMethodId"
                    classLabel="normal"
                    selectValue={testSelectValue}
                    optionsList={testList}
                    handleChange={(value) => {
                      setTestSelectValue(value);
                      setForm({
                        ...form,
                        sendSampleMethodId: value.value,
                        sendSampleMethodName: value.label,
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                {/* <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดระดับภูมิคุ้มกัน"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="immType"
                    classLabel="normal"
                    selectValue={levelSelectValue}
                    optionsList={levelList}
                    handleChange={(value) => {
                      setLevelSelectValue(value);
                      setForm({ ...form, immType: value.value });
                    }}
                  />
                </Col> */}
                <Col>
                  <Col lg={3} md={6} sm={12}>
                    <label style={{ fontWeight: "normal" }}>เลือกรูปแบบ</label>
                  </Col>
                  <Col lg={9} md={9} sm={12} className="w-100">
                    <div className="d-flex mt-1 ">
                      <label style={{ fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="excel"
                          name="data-export"
                          checked={checkExport("excel")}
                          onChange={() => setFileType("excel")}
                        />{" "}
                        EXCEL
                      </label>
                      <div className="ml-3">
                        <label style={{ fontWeight: "normal" }}>
                          <input
                            type="radio"
                            value="pdf"
                            name="data-export"
                            checked={checkExport("pdf")}
                            onChange={() => setFileType("pdf")}
                          />{" "}
                          PDF
                        </label>
                      </div>

                      <div className="ml-3">
                        <label style={{ fontWeight: "normal" }}>
                          <input
                            type="radio"
                            value="csv"
                            name="data-export"
                            checked={checkExport("csv")}
                            onChange={() => setFileType("csv")}
                          />{" "}
                          CSV
                        </label>
                      </div>
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการข้อมูลรายงาน C03RPT03-ระดับภูมิคุ้มกัน"
            headRight={buttonExport()}
          >
            <div>
              ระดับภูมิกันต่อชนิดโรค {form.diseaseTypeName || "-"} ชนิดสัตว์{" "}
              {form.animalTypeName || "-"} วิธีตรวจ{" "}
              {form.sendSampleMethodName || "-"}
            </div>
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
              // id={"data-table"}
              id={"render-data-table"}
              reference={ref}
            >
              {renderTable(listImmuneLevel)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Immune_Level;
