import React from 'react'
import * as helper from "../utils/helper";

function DataTableInfo({ page, limit, itemCount, totalItem }) {
    return (
        <>
            <h6 className="font14 mb-0">
                {`
                    แสดงข้อมูล
                    ${helper.rowNumber(0, Number(page), Number(limit))}
                    ถึง
                    ${helper.rowNumber(Number(itemCount) - 1, Number(page), Number(limit))}
                    จากทั้งหมด
                    ${totalItem}
                   รายการ
                `}
            </h6>
        </>
    )
}

export default DataTableInfo
