import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import BoxCard from "./BoxCard";
import { Form } from "react-bootstrap";
import ModalFarmSearch from "./Modal.FarmSearch";
import InputGroupText from "./InputGroupText";
import DataTableNodata from "./DataTableNodata";
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import InputDatePicker from "./InputDatePicker";
import InputMask from "./InputMask";
import * as API from "../utils/apis";
import moment from 'moment';
import 'moment/locale/th';

function DiseaseInvest_Step3({ save, dataAll, modeGet }) {

    const user = useSelector(state => state.login.result)

    const initialForm = {
        disease_movement: [],
        save: true,
        farmOriginAndDestSwitch: true,
        farmOriginIndex: 0,
        farmOriginObcode: "",
        farmDestinationIndex: 0,
        farmDestinationObcode: "",
    };

    
    const [form, setForm] = useState(initialForm);
    const [showFarmSearch3, setShowFarmSearch3] = useState(false);

    const [addDisease, setAddDisease] = useState([]);

    const [carrierTypeSelectValue, setCarrierTypeSelectValue] = useState([]);
  const [carrierTypeList, setCarrierTypeList] = useState([]);
  
  const initPassStateSelectValue = [];
  const [passStateSelectValue, setPassStateSelectValue] = useState(
    initPassStateSelectValue
  );
  const selectPassStateList = [
    {
      value: "In",
      label: "เข้า",
    },
    {
      value: "Out",
      label: "ออก",
    },
    {
      value: "Pass",
      label: "ผ่าน",
    },
  ];

  const [passStateList, setPassStateList] = useState(selectPassStateList);

    // useEffect(async () => {
    //     await toSave()
    // }, [form])

    useEffect(() => {
       getCarrierType()
    }, []);

    useEffect(() => {
      
        if (modeGet == "getById") {
            getDataById();
            addDisease.map((item, i) => {
                getFarm(item.fromFarm, item, "fromFarm")
                getFarm(item.toFarm, item, "toFarm")
                
            })
            
          }
        
    }, [user, carrierTypeList])


    

    const getDataById = async () => {
      await dataAll.map((item, i) => {
        carrierTypeList.forEach((carrierObj) => {
          if  (item.carrierTypeId == carrierObj.value) {
            item.carrierTypeObj = carrierObj;
          }
        })

        passStateList.forEach((element) => {
          if  (item.moveStatus == element.value) {
            item.moveStatusObj = element;
          }
        })
      })

      setAddDisease(dataAll)
      
    }

    const getFarm = async (farmId, item, state) => {

      try {
        const response = await API.getFarmRegistrationById(farmId)
        const data = response.data
        if (response.status == 200) {
          if  (state == "fromFarm") {
            item.farmOriginObj = data.farmer
          } else {
            item.farmDestinationObj = data.farmer
          }
          
          setAddDisease([...addDisease])
        }
      } catch (error) {
        console.log(error);
      }

    }

    const toSave = () => {
        save(addDisease)
    }

    const selectValueCarrierType = (value) => {
      setCarrierTypeSelectValue([{ value: value.value, label: value.label }]); 
    };

    const getCarrierType = async () => {

      try {
        const response = await API.getCarrierType();
        const data = await response.data;
        // console.log("params---getOutbreak--🐳🐳", params, response);
  
        if (response.status == 200) {
          let objArray = [];
            data.content.forEach((element) => {
              objArray.push({
                value: element.id,
                label: element.name,
              });
            });
            setCarrierTypeList(objArray);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const toAddDisease = () => {
        const data = addDisease.map((item) => {
          return { ...item, edit: true };
        });
        setAddDisease([...data, { disease_movement: [], save: true }]);
      };

    const buttonAdd = () => {
        return (
          <div className="d-flex justify-content-end ">
            <button
              className="btn btn-add text-white"
                onClick={() => toAddDisease()}
            >
              <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
            </button>
          </div>
        );
      };

      const renderDiseaseTable = (item, i) => {
        return (
          <tr key={i} className="">
            <td className="text-center">{i + 1}</td>
            <td>
              {item.save ? (
                <InputSelect
                  classFormGroup="mr-0"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName={`carrierType_${i}`}
                  classLabel="normal"
                  selectValue={item.carrierTypeObj}
                  optionsList={carrierTypeList}
                  handleChange={(value) => {
                    item.carrierTypeId = value.value
                    item.carrierTypeObj = value
                    selectValueCarrierType(value)
                    setAddDisease([...addDisease])
                  }
                  }
                />
              ) : (
                <span className="text-nowrap">{item.carrierTypeObj?.label}</span>
              )}
            </td>
            <td>
              {item.save ? (
                <InputSelect
                  classFormGroup="mr-0"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName={`moveStatus_${i}`}
                  classLabel="normal"
                  selectValue={item.moveStatusObj}
                  optionsList={passStateList}
                  handleChange={(value) => {
                    item.moveStatus = value.value
                    item.moveStatusObj = value
                    setPassStateSelectValue([value]);
                    setAddDisease([...addDisease])
                  }}
                />
              ) : (
                <span className="text-nowrap">{item.moveStatusObj?.label}</span>
              )}
            </td>
            <td>
              {item.save ? (
                <InputDatePicker
                  classFormGroup="mr-0"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName={`moveDate_${i}`}
                  classLabel="normal"
                  value={item.moveDate}

                  handleChange={(value) => {
                    item.moveDate = value;
                    setAddDisease([...addDisease])
                  }}
                />
              ) : (
                <span className="text-nowrap">{ item.moveDate ? moment(new Date(item.moveDate)).local('th').format('DD-MM-YYYY') : ""}</span>
              )}
            </td>
            <td>
              {item.save ? (
                <InputText
                  classFormGroup="mr-0"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName={`purpose_${i}`}
                  classLabel="normal"
                  value={item.purpose}
                  handleChange={(value) => {
                    item.purpose = value
                    setAddDisease([...addDisease])
                    
                  }}
                />
              ) : (
                <span className="text-nowrap">{item.purpose}</span>
              )}
            </td>
            <td>
              {item.save ? (
                <div className="d-flex">
                    <InputText
                    
                  classFormGroup="mr-0"
                  star={false}
                  disabled={true}
                  value={ item.fromFarm ? "(" + item.fromFarm + ")" + item.farmOriginObj?.firstName + " " + item.farmOriginObj?.lastName : ""
                  }
                  placeholder="กรุณาเลือก"
                  idName={`formFarm_${i}`}
                  classLabel="normal"
                  handleChange={(value) => {

                  }}
                />&nbsp;&nbsp;
                    <button
                  className="btn btn-add btn-sm text-white h-100"
                  onClick={() => {
                    setForm({ ...form, farmOriginIndex: i, farmOriginAndDestSwitch: true });
                    setShowFarmSearch3(true);
                    //setForm({ ...form, codeOb: item.obCode });
                  }}
                >
                  <i className="fa fa-search" aria-hidden="true"></i>
                </button>
                </div>
                
              ) : (
                <span className="text-nowrap">{item.fromFarm ? "(" + item.fromFarm + ")" + item.farmOriginObj?.firstName + " " + item.farmOriginObj?.lastName : ""}</span>
              )}
              
            </td>
            <td>
              {item.save ? (
                <InputText
                  classFormGroup="mr-0"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName={`fromTxt_${i}`}
                  classLabel="normal"
                  value={item.fromTxt}
                  handleChange={(value) => {
                    item.fromTxt = value;
                    setAddDisease([...addDisease])
                  }}
                />
              ) : (
                <span className="text-nowrap">{item.fromTxt}</span>
              )}
            </td>
            <td>
              {item.save ? (
                <div className="d-flex">
                    <InputText
                  classFormGroup="mr-0"
                  star={false}
                  disabled={true}
                  value={ item.toFarm ? "(" + item.toFarm + ")" + item.farmDestinationObj?.firstName + " " + item.farmDestinationObj?.lastName : ""
                  }
                  placeholder="กรุณาเลือก"
                  idName={`toFarm_${i}`}
                  classLabel="normal"
                  handleChange={(value) => {

                  }}
                />&nbsp;&nbsp;
                    <button
                  className="btn btn-add btn-sm text-white h-100"
                  onClick={() => {
                    setForm({ ...form, farmDestinationIndex: i, farmOriginAndDestSwitch: false })
                    setShowFarmSearch3(true);
                  }}
                >
                  <i className="fa fa-search" aria-hidden="true"></i>
                </button>
                </div>
                
              ) : (
                <span className="text-nowrap">{ item.toFarm ? "(" + item.toFarm + ")" + item.farmDestinationObj?.firstName + " " + item.farmDestinationObj?.lastName : ""
              }</span>
              )}
              
            </td>
            <td>
              {item.save ? (
                <InputText
                  classFormGroup="mr-0"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName={`toTxt_${i}`}
                  classLabel="normal"
                  value={item.toTxt}
                  handleChange={(value) => {
                    item.toTxt = value;
                    setAddDisease([...addDisease])
                    
                  }}
                />
              ) : (
                <span className="text-nowrap">{item.toTxt}</span>
              )}
            </td>
            <td>
              {item.save ? (
                <InputText
                  classFormGroup="mr-0"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName={`remark_${i}`}
                  classLabel="normal"
                  value={item.remark}
                  handleChange={(value) => {
                    item.remark = value
                    setAddDisease([...addDisease])
                  }}
                />
              ) : (
                <span className="text-nowrap">{item.remark}</span>
              )}
            </td>
            <td className="text-center">
              {item.save ? (
                <button
                  className="btn btn-success pt-1 px-2"
                  onClick={() => {
                    item.save = false;
                    const data = addDisease.map((item) => {
                       return { ...item, edit: false };
                    });
                    setAddDisease([...addDisease])
                    toSave()
                  }}
                >
                  <img
                    className="img-fluid icon-check"
                    src="/svg/btn-check.svg"
                    alt="check"
                  />
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-add mr-2"
                    onClick={() => {
                       item.save = true;
                       setAddDisease([...addDisease])
                    }}
                  >
                    <img
                      className="img-fluid icon-btn"
                      src="/svg/btn-edit.svg"
                      alt="edit"
                    />
                  </button>
                  <button
                    className="btn btn-del "
                    onClick={() => {
                       addDisease.splice(i, 1);
                       setAddDisease([...addDisease])
                       toSave()
                    }}
                  >
                    <img
                      className="img-fluid icon-btn "
                      src="/svg/btn-del.svg"
                      alt="del"
                    />
                  </button>
                </>
              )}
            </td>
          </tr>
        );
      };

      const addFarmOrigin = (value) => {
        addDisease[form.farmOriginIndex].fromFarm = value.id
        addDisease[form.farmOriginIndex].farmOriginObj = value
      }

      const addFarmDest = (value) => {
        addDisease[form.farmDestinationIndex].toFarm = value.id
        addDisease[form.farmDestinationIndex].farmDestinationObj = value
      }

    return (
        <div className=" bg-white p-4 ">
            <BoxCard title="3.1 การเคลื่อนย้าย (มีการเคลื่อนย้ายพาหะนำโรค เข้า-ออก หรือผ่านพื้นที่เกิดโรคก่อนเกิดโรคระบาดและคาดว่าเป็นสาเหตุการเกิดโรค)" headRight={buttonAdd()} > 
                <div className="table-responsive pb-3">
                    <table className="table top fitwidth table-striped table-bordered min-h-25" >
                        <thead>
                            <tr>
                                <th scope="col" rowSpan="2">ลำดับ</th>
                                <th scope="col" rowSpan="2">ชนิดของพาหะนำโรค</th>
                                <th scope="col" rowSpan="2">ย้ายเข้า / ออก</th>
                                <th scope="col" rowSpan="2">วันที่ที่เคลื่อนย้ายมา</th>
                                <th scope="col" rowSpan="2">วัตถุประสงค์ที่เคลื่อนย้าย</th>
                                <th scope="col" colSpan="2">ต้นทาง</th>
                                <th scope="col" colSpan="2">ปลายทาง</th>
                                <th scope="col" rowSpan="2">หมายเหตุ</th>
                                <th scope="col" rowSpan="2">จัดการ</th>
                            </tr>
                            <tr>
                                <th scope="col">ฟาร์มต้นทาง</th>
                                <th scope="col">ต้นทาง(ระบุสถานที่)</th>
                                <th scope="col">ฟาร์มปลายทาง</th>
                                <th scope="col">ปลายทาง(ระบุสถานที่)</th>
                            </tr>
                        </thead>
                        <tbody className="font14 text-left">
                                {addDisease?.length > 0 ? (
                                addDisease.map((item, i) => {
                                    return renderDiseaseTable(item, i);
                                })
                            ) : (
                                <DataTableNodata colSpan={11} />
                            )}
                        </tbody>
                    </table>
                </div>
            </BoxCard>
                        {showFarmSearch3 && (
                    <ModalFarmSearch
                    show={showFarmSearch3}
                    mode={"formDiseaseInvestStep3"}
                    obCode={form.farmOriginObcode}
                    close={() => setShowFarmSearch3(false)}
                    save={(value) => 
                      form.farmOriginAndDestSwitch ? addFarmOrigin(value) : addFarmDest(value)
                    }
                    />
                )}
        </div>
    )
}

export default DiseaseInvest_Step3
