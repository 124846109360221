import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InputText from "./InputText";
import InputMask from "./InputMask";
import InputSelect from "./InputSelect";
import InputDatePicker from "./InputDatePicker";
import ModalVaccineDate from "./Modal.VaccineDate";
import DataTableNodata from "./DataTableNodata";
import BoxCard from "./BoxCard";
import * as helper from "../utils/helper";
import * as API from "../utils/apis";
import { Row, Col, Form } from "react-bootstrap";
import { loading } from "../redux/actions/loadingAction";

function DiseaseInvest_Step1({ save, dataAll, modeGet, dataRes }) {
  console.log("dataRes", dataRes);
  const user = useSelector((state) => state.login.result);

  const initialForm = {
    step1_fm02: {
      q11: "y",
      q11MoveDate: "",
      q11SymptomDate: "",
      q11IntoDate: "",
    },
    modeEditTable1: false,
    modeEditTable2: false,

    chooseTab1: [],
    ps_other1: "",
    ps_other1_text: "",
    indexOtherTab1: "",

    chooseTab2: [],
    ps_other2: "",
    ps_other2_text: "",
    indexOtherTab2: "",

    chooseTab3: [],
    ps_other2: "",
    ps_other3_text: "",
    indexFeverTab3: "",
    indexOtherTab3: "",

    headModal: "",
    typeVcDate: "",
    checkAddAnmTouch: false,
    data_anmTouch: [],
    del_anmTouch: [],

    checkAddHumanTouch: false,
    data_humanTouch: [],
    del_humanTouch: [],

    chooseSelect: false,

    chooseLook: "",
    animal_look: [
      {
        id: 1,
        src: "/images/look1.jpg",
      },
      {
        id: 2,
        src: "/images/look2.jpg",
      },
      {
        id: 3,
        src: "/images/look3.jpg",
      },
      {
        id: 4,
        src: "/images/look4.jpg",
      },
      {
        id: 5,
        src: "/images/look5.jpg",
      },
      {
        id: 6,
        src: "/images/look6.jpg",
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [isCheckAllTab1, setIsCheckAllTab1] = useState(false);
  const [isCheckAllTab2, setIsCheckAllTab2] = useState(false);
  const [isCheckAllTab3, setIsCheckAllTab3] = useState(false);

  const [showVaccineDate, setShowVaccineDate] = useState(false);

  const [psSymptomsTab1, setPsSymptomsTab1] = useState([]);
  const [psSymptomsTab2, setPsSymptomsTab2] = useState([]);
  const [psSymptomsTab3, setPsSymptomsTab3] = useState([]);

  const initialAnmTouch = {
    animalName: "",
    animalTypeId: "",
    animalTypeName: "",
    quarantine: "",
    quarantineId: "",
    touchDate: "",
    touchDisease: "",
    touchDiseaseId: "",
    vaccination: "",
    vaccinationId: "",
    vaccinationDate: "",
  };
  const [formAnmTouch, setFormAnmTouch] = useState(initialAnmTouch);

  const initialHumanTouch = {
    name: "",
    tel: "",
    touchDate: "",
    touchDisease: "",
    touchDiseaseId: "",
    vaccination: "",
    vaccinationId: "",
    vaccinationDate: "",
  };
  const [formHumanTouch, setFormHumanTouch] = useState(initialHumanTouch);

  const initInvalidMsg = {
    // คนสัตว์ผัสเชื้อ
    touchDateA: "",

    // คนสัมผัสเชื้อ
    touchDateH: "",
    name: "",
    tel: "",
    touchDiseaseH: "",
    vaccinationH: "",
    vaccinationDateH: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const initAnimalSelectValue = [];
  const [animalSelectValue, setAnimalSelectValue] = useState(
    initAnimalSelectValue
  );
  const selectAnimalList = [];
  const [animalList, setAnimalList] = useState(selectAnimalList);

  const initQuarantineSelectValue = [];
  const [quarantineSelectValue, setQuarantineSelectValue] = useState(
    initQuarantineSelectValue
  );
  const selectQuarantineList = [
    { value: 1, label: "กัก" },
    { value: 2, label: "ไม่กัก" },
  ];
  const [quarantineList, setQuarantineList] = useState(selectQuarantineList);

  const initTouchDiseaseSelectValue = [];
  const [touchDiseaseSelectValue, setTouchDiseaseSelectValue] = useState(
    initTouchDiseaseSelectValue
  );
  const selectTouchDiseaseList = [
    { value: 1, label: "ถูกกัด" },
    { value: 2, label: "สัมผัสน้ำลาย" },
    { value: 3, label: "อากาศ" },
    { value: 4, label: "ไม่ทราบ" },
  ];
  const [touchDiseaseList, setTouchDiseaseList] = useState(
    selectTouchDiseaseList
  );

  const initHumanTouchDSelectValue = [];
  const [humanTouchDSelectValue, setHumanTouchDSelectValue] = useState(
    initHumanTouchDSelectValue
  );
  const selectHumanTouchDList = [
    { value: 1, label: "ถูกกัด" },
    { value: 2, label: "สัมผัสน้ำลาย" },
    { value: 3, label: "อากาศ" },
    { value: 4, label: "ไม่ทราบ" },
  ];
  const [humanTouchDList, setHumanTouchDList] = useState(selectHumanTouchDList);

  const initVaccineAnmSelectValue = [];
  const [vaccineAnmSelectValue, setVaccineAnmSelectValue] = useState(
    initVaccineAnmSelectValue
  );
  const selectVaccineAnmList = [
    { value: 1, label: "ฉีด 4x4" },
    { value: 2, label: "ฉีดอื่นๆ" },
    { value: 3, label: "ไม่ฉีด" },
    { value: 4, label: "ไม่ทราบ" },
  ];
  const [vaccineAnmList, setVaccineAnmList] = useState(selectVaccineAnmList);

  const initVaccineHumanSelectValue = [];
  const [vaccineHumanSelectValue, setVaccineHumanSelectValue] = useState(
    initVaccineHumanSelectValue
  );
  const selectVaccineHumanList = [
    { value: 1, label: "ฉีดวัคซีน" },
    { value: 2, label: "ไม่ฉีดวัคซีน" },
    { value: 3, label: "ไม่ทราบ" },
  ];
  const [vaccineHumanList, setVaccineHumanList] = useState(
    selectVaccineHumanList
  );

  useEffect(() => {
    getPsSymptoms();
    getAnimal();
  }, []);

  useEffect(() => {
    if (modeGet == "getById" && !form.chooseSelect) {
      getDataById();
    } else if (modeGet == "default") {
      getFirstAnimById();
    }
  }, [user, modeGet, animalList, dataRes]);

  useEffect(async () => {
    toSave();
  }, [form, modeGet]);

  const toSave = () => {
    save(form);
  };

  const getPsSymptoms = async () => {
    try {
      const response = await API.getPsSymptoms();
      const data = await response.data;
      if (response.status == 200) {
        let tab1 = [];
        let tab2 = [];
        let tab3 = [];
        data.content.map((item, i) => {
          if (item.tabId == 1) {
            tab1.push(item);
          }
          if (item.tabId == 2) {
            tab2.push(item);
          }
          if (item.tabId == 3) {
            tab3.push(item);
          }
        });

        tab1.splice(tab1.length - 1, 1, { id: "a", name: "อื่นๆ", tabId: 1 });
        tab2.splice(tab2.length - 1, 1, { id: "b", name: "อื่นๆ", tabId: 1 });
        tab3.splice(tab3.length - 1, 1, { id: "c", name: "อื่นๆ", tabId: 1 });

        setPsSymptomsTab1(tab1);
        setPsSymptomsTab2(tab2);
        setPsSymptomsTab3(tab3);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFirstAnimById = async () => {
    setForm({
      ...form,
      step1_fm02: {
        q11SymptomDate:
          dataRes.fm01 && dataRes.fm01.firstAnimSymptomDate
            ? dataRes.fm01.firstAnimSymptomDate
            : "",
      },
    });
  };

  const getDataById = async () => {
    let step1_anmTouch = [];
    if (dataAll.data_anmTouch && dataAll.data_anmTouch.length > 0) {
      dataAll.data_anmTouch.map((item, i) => {
        let animalTypeName;
        animalList.filter((itemA) => {
          if (itemA.value == item.animalTypeId) {
            animalTypeName = itemA.label;
          }
        });
        let touchDiseaseId;
        touchDiseaseList.filter((itemT) => {
          if (itemT.label == item.touchDisease) {
            touchDiseaseId = itemT.value;
          }
        });
        let quarantineId;
        quarantineList.filter((itemQ) => {
          if (itemQ.label == item.quarantine) {
            quarantineId = itemQ.value;
          }
        });

        let vaccinationId;
        vaccineAnmList.filter((itemV) => {
          if (itemV.label == item.vaccination) {
            vaccinationId = itemV.value;
          }
        });

        step1_anmTouch.push({
          id: item.id,
          animalName: item.animalName,
          animalTypeId: item.animalTypeId,
          animalTypeName: animalTypeName,
          quarantine: item.quarantine,
          quarantineId: quarantineId,
          touchDate: item.touchDate ? item.touchDate : "",
          touchDisease: item.touchDisease,
          touchDiseaseId: touchDiseaseId,
          vaccination: item.vaccination,
          vaccinationId: vaccinationId,
          vaccinationDate:
            vaccinationId == 1 || vaccinationId == 2
              ? item.vaccinationDate
              : "",
        });
      });
    }

    let step1_humanTouch = [];
    if (dataAll.data_humanTouch && dataAll.data_humanTouch.length > 0) {
      dataAll.data_humanTouch.map((item, i) => {
        let touchDiseaseId;
        humanTouchDList.filter((itemT) => {
          if (itemT.label == item.touchDisease) {
            touchDiseaseId = itemT.value;
          }
        });

        let vaccinationId;
        vaccineHumanList.filter((itemV) => {
          if (itemV.label == item.vaccination) {
            vaccinationId = itemV.value;
          }
        });

        step1_humanTouch.push({
          id: item.id,
          name: item.name,
          tel: item.tel,
          touchDate: item.touchDate ? item.touchDate : "",
          touchDisease: item.touchDisease,
          touchDiseaseId: touchDiseaseId,
          vaccination: item.vaccination,
          vaccinationId: vaccinationId,
          vaccinationDate:
            vaccinationId == 1 || vaccinationId == 2
              ? item.vaccinationDate
              : "",
        });
      });
    }

    let step1_chooseTab1 = [];
    let indexOtherTab1;
    if (dataAll.chooseTab1 && dataAll.chooseTab1.length > 0) {
      dataAll.chooseTab1.map((item, i) => {
        step1_chooseTab1.push({
          id: item.id,
          tabId: item.tabId,
          fm02SymptomsId:
            item.fm02SymptomsId == null ? "a" : item.fm02SymptomsId,
          other: item.other ? item.other : "",
        });

        if (item.fm02SymptomsId == null) {
          indexOtherTab1 = i;
        }
      });
    }

    let step1_chooseTab2 = [];
    let indexOtherTab2;
    if (dataAll.chooseTab2 && dataAll.chooseTab2.length > 0) {
      dataAll.chooseTab2.map((item, i) => {
        step1_chooseTab2.push({
          id: item.id,
          tabId: item.tabId,
          fm02SymptomsId:
            item.fm02SymptomsId == null ? "b" : item.fm02SymptomsId,
          other: item.other ? item.other : "",
        });

        if (item.fm02SymptomsId == null) {
          indexOtherTab2 = i;
        }
      });
    }

    let step1_chooseTab3 = [];
    let indexOtherTab3;
    let indexFeverTab3;
    let chooseLook;
    if (dataAll.chooseTab3 && dataAll.chooseTab3.length > 0) {
      dataAll.chooseTab3.map((item, i) => {
        step1_chooseTab3.push({
          id: item.id,
          tabId: item.tabId,
          fm02SymptomsId:
            item.fm02SymptomsId == null ? "c" : item.fm02SymptomsId,
          other: item.other ? item.other : "",
          degreeFever: item.degreeFever ? item.degreeFever : "",
        });
        chooseLook = item.look ? item.look : "";

        if (item.fm02SymptomsId === null) {
          indexOtherTab3 = i;
        }

        if (item.fm02SymptomsId === 32) {
          indexFeverTab3 = i;
        }
      });
    }

    setForm({
      ...form,
      step1_fm02: {
        q11: dataAll.step1_fm02 && dataAll.step1_fm02.q11,
        q11MoveDate:
          dataAll.step1_fm02 &&
          dataAll.step1_fm02.q11MoveDate &&
          dataAll.step1_fm02.q11 == "n"
            ? dataAll.step1_fm02.q11MoveDate
            : "",
        q11SymptomDate:
          dataAll.step1_fm02 && dataAll.step1_fm02.q11SymptomDate
            ? dataAll.step1_fm02.q11SymptomDate
            : "",
        q11IntoDate:
          dataAll.step1_fm02 && dataAll.step1_fm02.q11IntoDate
            ? dataAll.step1_fm02.q11IntoDate
            : "",
      },
      data_anmTouch: step1_anmTouch,
      data_humanTouch: step1_humanTouch,
      chooseTab1: step1_chooseTab1,
      indexOtherTab1: indexOtherTab1,
      chooseTab2: step1_chooseTab2,
      indexOtherTab2: indexOtherTab2,
      chooseTab3: step1_chooseTab3,
      indexOtherTab3: indexOtherTab3,
      indexFeverTab3: indexFeverTab3,
      chooseLook: chooseLook,
    });
  };

  const handleSelectAllTab1 = (e) => {
    setIsCheckAllTab1(!isCheckAllTab1);

    let chooseAll = [];
    if (psSymptomsTab1.length > 0) {
      psSymptomsTab1.map((item, i) => {
        chooseAll.push({
          tabId: 1,
          fm02SymptomsId: item.id,
          other: "",
        });
      });
    }

    if (isCheckAllTab1) {
      chooseAll = [];
    }

    setForm({ ...form, chooseTab1: chooseAll });
  };

  const setChooseOneTab1 = (e) => {
    let arrayChoose = [];
    if (e.target.checked) {
      arrayChoose = [
        ...form.chooseTab1,
        {
          tabId: 1,
          fm02SymptomsId: e.target.id,
          other: "",
        },
      ];
      setForm({ ...form, chooseTab1: arrayChoose });
      toSave();
    } else {
      if (form.chooseTab1.length > 0) {
        form.chooseTab1.forEach((item, i) => {
          if (item.fm02SymptomsId == e.target.id) {
            form.chooseTab1.splice(i, 1);
          }
        });
        setForm({ ...form, chooseTab1: form.chooseTab1 });
        toSave();
      }
    }
  };

  const setTextOtherTab1 = (value) => {
    if (form.chooseTab1 && form.chooseTab1.length > 0) {
      form.chooseTab1.map((item, i) => {
        if (item.fm02SymptomsId == "a") {
          form.chooseTab1[i].other = value;
          setForm({ ...form, indexOtherTab1: i });
        }
      });
    }
  };

  const handleSelectAllTab2 = (e) => {
    setIsCheckAllTab2(!isCheckAllTab2);

    let chooseAll = [];
    if (psSymptomsTab2.length > 0) {
      psSymptomsTab2.map((item, i) => {
        chooseAll.push({
          tabId: 1,
          fm02SymptomsId: item.id,
          other: "",
        });
      });
    }

    if (isCheckAllTab2) {
      chooseAll = [];
    }

    setForm({ ...form, chooseTab2: chooseAll });
  };

  const setChooseOneTab2 = (e) => {
    let arrayChoose = [];
    if (e.target.checked) {
      arrayChoose = [
        ...form.chooseTab2,
        {
          tabId: 2,
          fm02SymptomsId: e.target.id,
          other: "",
        },
      ];
      setForm({ ...form, chooseTab2: arrayChoose });
      toSave();
    } else {
      if (form.chooseTab2.length > 0) {
        form.chooseTab2.forEach((item, i) => {
          if (item.fm02SymptomsId == e.target.id) {
            form.chooseTab2.splice(i, 1);
          }
        });
        setForm({ ...form, chooseTab2: form.chooseTab2 });
        toSave();
      }
    }
  };

  const setTextOtherTab2 = (value) => {
    if (form.chooseTab2 && form.chooseTab2.length > 0) {
      form.chooseTab2.map((item, i) => {
        if (item.fm02SymptomsId == "b") {
          form.chooseTab2[i].other = value;
          setForm({ ...form, indexOtherTab2: i });
        }
      });
    }
  };

  const handleSelectAllTab3 = () => {
    setIsCheckAllTab3(!isCheckAllTab3);

    let chooseAll = [];
    if (psSymptomsTab3.length > 0) {
      psSymptomsTab3.map((item, i) => {
        chooseAll.push({
          tabId: 1,
          fm02SymptomsId: item.id,
          other: "",
          degreeFever: "",
          look: form.chooseLook ? form.chooseLook : "",
        });
      });
    }

    if (isCheckAllTab3) {
      chooseAll = [];
    }

    setForm({ ...form, chooseTab3: chooseAll });
  };

  const setChooseOneTab3 = (e) => {
    let arrayChoose = [];
    if (e.target.checked) {
      arrayChoose = [
        ...form.chooseTab3,
        {
          tabId: 3,
          fm02SymptomsId: e.target.id,
          other: "",
          degreeFever: "",
          look: form.chooseLook ? form.chooseLook : "",
        },
      ];
      setForm({ ...form, chooseTab3: arrayChoose });
      toSave();
    } else {
      if (form.chooseTab3.length > 0) {
        form.chooseTab3.forEach((item, i) => {
          if (item.fm02SymptomsId == e.target.id) {
            form.chooseTab3.splice(i, 1);
          }
        });
        setForm({ ...form, chooseTab3: form.chooseTab3 });
        toSave();
      }
    }
  };

  const setTextOtherTab3 = (value) => {
    if (form.chooseTab3 && form.chooseTab3.length > 0) {
      form.chooseTab3.map((item, i) => {
        if (item.fm02SymptomsId == "c") {
          form.chooseTab3[i].other = value;
          setForm({ ...form, indexOtherTab3: i });
        }
      });
    }
  };

  const setTextDegreeFeverTab3 = (value) => {
    if (form.chooseTab3 && form.chooseTab3.length > 0) {
      form.chooseTab3.map((item, i) => {
        if (item.fm02SymptomsId == "32") {
          form.chooseTab3[i].degreeFever = value;
          setForm({ ...form, indexFeverTab3: i });
        }
      });
    }
  };

  const setChooseLookTab3 = (value) => {
    if (form.chooseTab3 && form.chooseTab3.length > 0) {
      form.chooseTab3.map((item, i) => {
        form.chooseTab3[i].look = value;
      });
    }
    setForm({ ...form, chooseLook: value });
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  // 1.3 ข้อมูลสัตว์สัมผัสเชื้อ
  const toAddAnmTouch = () => {
    setForm({ ...form, checkAddAnmTouch: true });
    setAnimalSelectValue([]);
    setFormAnmTouch({
      ...formAnmTouch,
      animalName: "",
      animalTypeId: "",
      animalTypeName: "",
      quarantine: "",
      touchDate: "",
      touchDisease: "",
      touchDiseaseId: "",
      vaccination: "",
      vaccinationDate: "",
    });
  };

  const buttonAddAnmTouch = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddAnmTouch()}
          disabled={form.checkAddAnmTouch || form.modeEditTable1}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const saveAnmTouch = (itemAnm, type) => {
    let arrayAnmTouch = [...form.data_anmTouch];
    if (type == "add") {
      arrayAnmTouch.push({
        checkEditAnmTouch: false,
        animalName: formAnmTouch.animalName,
        animalTypeId: formAnmTouch.animalTypeId,
        animalTypeName: formAnmTouch.animalTypeName,
        quarantine: formAnmTouch.quarantine,
        quarantineId: formAnmTouch.quarantineId,
        touchDate: formAnmTouch.touchDate,
        touchDisease: formAnmTouch.touchDisease,
        touchDiseaseId: formAnmTouch.touchDiseaseId,
        vaccination: formAnmTouch.vaccination,
        vaccinationId: formAnmTouch.vaccinationId,
        vaccinationDate: formAnmTouch.vaccinationDate,
      });
      setForm({
        ...form,
        data_anmTouch: arrayAnmTouch,
        checkAddAnmTouch: false,
        modeEditTable1: false,
      });
    } else if (type == "edit") {
      itemAnm.checkEditAnmTouch = false;
      itemAnm.animalName = formAnmTouch.animalName;
      itemAnm.animalTypeId = formAnmTouch.animalTypeId;
      itemAnm.animalTypeName = formAnmTouch.animalTypeName;
      itemAnm.quarantine = formAnmTouch.quarantine;
      itemAnm.quarantineId = formAnmTouch.quarantineId;
      itemAnm.touchDate = formAnmTouch.touchDate;
      itemAnm.touchDisease = formAnmTouch.touchDisease;
      itemAnm.touchDiseaseId = formAnmTouch.touchDiseaseId;
      itemAnm.vaccination = formAnmTouch.vaccination;
      itemAnm.vaccinationId = formAnmTouch.vaccinationId;
      itemAnm.vaccinationDate = formAnmTouch.vaccinationDate;

      setForm({ ...form, checkAddAnmTouch: false, modeEditTable1: false });
    }

    toSave();
  };

  const setVaccineAnmToForm = (value) => {
    if (value.value == 1 || value.value == 2) {
      setShowVaccineDate(true);
      setForm({ ...form, headModal: value.label, typeVcDate: "animal" });
      setFormAnmTouch({
        ...formAnmTouch,
        vaccination: value.label,
        vaccinationId: value.value,
      });
    } else {
      setFormAnmTouch({
        ...formAnmTouch,
        vaccination: value.label,
        vaccinationId: value.value,
        vaccinationDate: "",
      });
    }
  };

  const addVaccineAnmDate = (value) => {
    setFormAnmTouch({ ...formAnmTouch, vaccinationDate: value });
  };

  const delAnmTouch = (item, index) => {
    let delAnmTouch = [];
    form.data_anmTouch.splice(index, 1);
    if (item.id) {
      delAnmTouch.push(item.id);
    }
    setForm({
      ...form,
      data_anmTouch: form.data_anmTouch,
      del_anmTouch: delAnmTouch,
    });
  };

  const editAnmTouch = (item) => {
    item.checkEditAnmTouch = true;
    setForm({ ...form, modeEditTable1: true });
    setFormAnmTouch({
      ...formAnmTouch,
      animalName: item.animalName,
      animalTypeId: item.animalTypeId,
      animalTypeName: item.animalTypeName,
      quarantine: item.quarantine,
      quarantineId: item.quarantineId,
      touchDate: item.touchDate,
      touchDisease: item.touchDisease,
      touchDiseaseId: item.touchDiseaseId,
      vaccination: item.vaccination,
      vaccinationId: item.vaccinationId,
      vaccinationDate: item.vaccinationDate,
    });

    setTouchDiseaseSelectValue([
      { value: item.touchDiseaseId, label: item.touchDisease },
    ]);
    setAnimalSelectValue([
      { value: item.animalTypeId, label: item.animalTypeName },
    ]);
    setQuarantineSelectValue([
      { value: item.quarantineId, label: item.quarantine },
    ]);
    setVaccineAnmSelectValue([
      { value: item.vaccinationId, label: item.vaccination },
    ]);
  };

  const renderAnimalTouch = (item, index) => {
    return (
      <tr key={index} className="text-center">
        <td>{index + 1}</td>
        <td>
          {item.checkEditAnmTouch ? (
            <InputDatePicker
              classFormGroup="mb-0"
              title=""
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={formAnmTouch.touchDate}
              star={false}
              handleChange={(value) =>
                setFormAnmTouch({ ...formAnmTouch, touchDate: value })
              }
            />
          ) : item.touchDate ? (
            helper.getDateFormat(item.touchDate)
          ) : (
            "-"
          )}
        </td>

        <td>
          {item.checkEditAnmTouch ? (
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName="animalTypeName"
              classLabel="normal"
              selectValue={animalSelectValue}
              optionsList={animalList}
              handleChange={(value) => {
                setAnimalSelectValue([value]);
                setFormAnmTouch({
                  ...formAnmTouch,
                  animalTypeName: value.label,
                  animalTypeId: value.value,
                });
              }}
            />
          ) : item.animalTypeName ? (
            item.animalTypeName
          ) : (
            "-"
          )}
        </td>

        <td>
          {item.checkEditAnmTouch ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="text"
              idName="animalName"
              value={formAnmTouch.animalName}
              star={false}
              placeholder="กรุณากรอก"
              handleChange={(value) =>
                setFormAnmTouch({ ...formAnmTouch, animalName: value })
              }
            />
          ) : item.animalName ? (
            item.animalName
          ) : (
            "-"
          )}
        </td>

        <td>
          {item.checkEditAnmTouch ? (
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName="touchDisease"
              classLabel="normal"
              selectValue={touchDiseaseSelectValue}
              optionsList={touchDiseaseList}
              handleChange={(value) => {
                setTouchDiseaseSelectValue([value]);
                setFormAnmTouch({
                  ...formAnmTouch,
                  touchDisease: value.label,
                  touchDiseaseId: value.value,
                });
              }}
            />
          ) : item.touchDisease ? (
            item.touchDisease
          ) : (
            "-"
          )}
        </td>

        <td>
          {item.checkEditAnmTouch ? (
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName="quarantine"
              classLabel="normal"
              selectValue={quarantineSelectValue}
              optionsList={quarantineList}
              handleChange={(value) => {
                setQuarantineSelectValue([value]);
                setFormAnmTouch({
                  ...formAnmTouch,
                  quarantine: value.label,
                  quarantineId: value.value,
                });
              }}
            />
          ) : item.quarantine ? (
            item.quarantine
          ) : (
            "-"
          )}
        </td>

        <td>
          {item.checkEditAnmTouch ? (
            <div className="d-flex align-items-center">
              <InputSelect
                classFormGroup="mb-0 w-100"
                star={false}
                placeholder="กรุณาเลือก"
                idName="vaccination"
                classLabel="normal"
                selectValue={vaccineAnmSelectValue}
                optionsList={vaccineAnmList}
                handleChange={(value) => {
                  setVaccineAnmSelectValue([value]);
                  setVaccineAnmToForm(value);
                }}
              />
              {formAnmTouch.vaccinationDate ? (
                <div className="ml-2">
                  [{helper.getDateFormat(formAnmTouch.vaccinationDate)}]
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <>
              {item.vaccination ? item.vaccination : "-"}
              {item.vaccinationDate
                ? `[${helper.getDateFormat(item.vaccinationDate)}]`
                : ""}
            </>
          )}
        </td>

        <td className="text-center">
          {item.checkEditAnmTouch ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                saveAnmTouch(item, "edit");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => editAnmTouch(item)}
                disabled={form.modeEditTable1 || form.checkAddAnmTouch}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button
                className="btn btn-del "
                onClick={() => delAnmTouch(item, index)}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  const renderNewAnimalTouch = () => {
    return (
      <React.Fragment>
        <tr>
          <td className="text-center">
            {form.data_anmTouch && form.data_anmTouch.length + 1}
          </td>
          <td>
            <InputDatePicker
              classFormGroup="mb-0"
              title=""
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={formAnmTouch.touchDate}
              star={false}
              handleChange={(value) =>
                setFormAnmTouch({ ...formAnmTouch, touchDate: value })
              }
            />
          </td>

          <td className="fitwidth">
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName="animalTypeName"
              classLabel="normal"
              selectValue={animalSelectValue}
              optionsList={animalList}
              handleChange={(value) => {
                setAnimalSelectValue([value]);
                setFormAnmTouch({
                  ...formAnmTouch,
                  animalTypeName: value.label,
                  animalTypeId: value.value,
                });
              }}
            />
          </td>

          <td>
            <InputText
              classFormGroup="mb-0 w-100"
              type="text"
              idName="animalName"
              value={formAnmTouch.animalName}
              star={false}
              placeholder="กรุณากรอก"
              handleChange={(value) =>
                setFormAnmTouch({ ...formAnmTouch, animalName: value })
              }
            />
          </td>

          <td>
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName="touchDisease"
              classLabel="normal"
              selectValue={touchDiseaseSelectValue}
              optionsList={touchDiseaseList}
              handleChange={(value) => {
                setTouchDiseaseSelectValue([value]);
                setFormAnmTouch({
                  ...formAnmTouch,
                  touchDisease: value.label,
                  touchDiseaseId: value.value,
                });
              }}
            />
          </td>

          <td>
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName="quarantine"
              classLabel="normal"
              selectValue={quarantineSelectValue}
              optionsList={quarantineList}
              handleChange={(value) => {
                setQuarantineSelectValue([value]);
                setFormAnmTouch({
                  ...formAnmTouch,
                  quarantine: value.label,
                  quarantineId: value.value,
                });
              }}
            />
          </td>

          <td>
            <div className="d-flex align-items-center">
              <InputSelect
                classFormGroup="mb-0 w-100"
                star={false}
                placeholder="กรุณาเลือก"
                idName="vaccination"
                classLabel="normal"
                selectValue={vaccineAnmSelectValue}
                optionsList={vaccineAnmList}
                handleChange={(value) => {
                  setVaccineAnmSelectValue([value]);
                  setVaccineAnmToForm(value);
                }}
              />
              {formAnmTouch.vaccinationDate ? (
                <div className="ml-2">
                  [{helper.getDateFormat(formAnmTouch.vaccinationDate)}]
                </div>
              ) : (
                ""
              )}
            </div>
          </td>

          <td className="text-center">
            <button
              className="btn btn-success  pt-1 px-2"
              onClick={async () => {
                saveAnmTouch(formAnmTouch, "add");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  // ==================== 1.4 ข้อมูลคนสัมผัสเชื้อ
  const validateHuman = () => {
    let validated = true;

    if (formHumanTouch.touchDate == "") {
      addInvalid("touchDateH", "กรุณาระบุ");
      validated = false;
    }

    if (formHumanTouch.name == "") {
      addInvalid("name", "กรุณาระบุ");
      validated = false;
    }

    if (formHumanTouch.tel == "") {
      addInvalid("tel", "กรุณาระบุ");
      validated = false;
    }

    if (formHumanTouch.touchDisease == "") {
      addInvalid("touchDiseaseH", "กรุณาระบุ");
      validated = false;
    }

    if (formHumanTouch.vaccination == "") {
      addInvalid("vaccinationH", "กรุณาระบุ");
      validated = false;
    }

    if (formHumanTouch.vaccination == 1) {
      if (formHumanTouch.vaccinationDate == "") {
        addInvalid("vaccinationDateH", "กรุณาระบุวันที่ฉีดวัคซีน");
        validated = false;
      }
    }

    return validated;
  };

  const toAddHumanTouch = () => {
    setForm({ ...form, checkAddHumanTouch: true });
    setHumanTouchDSelectValue([]);
    setVaccineHumanSelectValue([]);
    setFormHumanTouch({
      ...formHumanTouch,
      name: "",
      tel: "",
      touchDate: "",
      touchDisease: "",
      touchDiseaseId: "",
      vaccination: "",
      vaccinationId: "",
      vaccinationDate: "",
    });
  };

  const buttonAddHumanTouch = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddHumanTouch()}
          disabled={form.checkAddHumanTouch ? true : false}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const saveHumanTouch = (itemH, type) => {
    if (validateHuman()) {
      let data_humanTouch = [...form.data_humanTouch];
      if (type == "add") {
        data_humanTouch.push({
          checkEditHumanTouch: false,
          name: formHumanTouch.name,
          tel: formHumanTouch.tel,
          touchDate: formHumanTouch.touchDate,
          touchDisease: formHumanTouch.touchDisease,
          touchDiseaseId: formHumanTouch.touchDiseaseId,
          vaccination: formHumanTouch.vaccination,
          vaccinationId: formHumanTouch.vaccinationId,
          vaccinationDate: formHumanTouch.vaccinationDate,
        });
        setForm({
          ...form,
          data_humanTouch: data_humanTouch,
          checkAddHumanTouch: false,
          modeEditTable2: false,
        });
      } else if (type == "edit") {
        itemH.checkEditHumanTouch = false;
        itemH.name = formHumanTouch.name;
        itemH.tel = formHumanTouch.tel;
        itemH.touchDate = formHumanTouch.touchDate;
        itemH.touchDisease = formHumanTouch.touchDisease;
        itemH.touchDiseaseId = formHumanTouch.touchDiseaseId;
        itemH.vaccination = formHumanTouch.vaccination;
        itemH.vaccinationId = formHumanTouch.vaccinationId;
        itemH.vaccinationDate = formHumanTouch.vaccinationDate;

        setForm({ ...form, checkAddHumanTouch: false, modeEditTable2: false });
      }

      toSave();
    }
  };

  const setVaccineHumanToForm = (value) => {
    if (value.value == 1) {
      setShowVaccineDate(true);
      setForm({ ...form, headModal: value.label, typeVcDate: "human" });
      setFormHumanTouch({
        ...formHumanTouch,
        vaccination: value.label,
        vaccinationId: value.value,
      });
    } else {
      setFormHumanTouch({
        ...formHumanTouch,
        vaccination: value.label,
        vaccinationId: value.value,
        vaccinationDate: "",
      });
    }
  };

  const addVaccineHumanDate = (value) => {
    setFormHumanTouch({ ...formHumanTouch, vaccinationDate: value });
  };

  const delHumanTouch = (item, index) => {
    let delHumanTouch = [];
    form.data_humanTouch.splice(index, 1);
    if (item.id) {
      delHumanTouch.push(item.id);
    }
    setForm({
      ...form,
      data_humanTouch: form.data_humanTouch,
      del_humanTouch: delHumanTouch,
    });
  };

  const editHumanTouch = (item) => {
    item.checkEditHumanTouch = true;
    setForm({ ...form, modeEditTable2: true });
    setFormHumanTouch({
      ...formHumanTouch,
      name: item.name,
      tel: item.tel,
      touchDate: item.touchDate,
      touchDisease: item.touchDisease,
      touchDiseaseId: item.touchDiseaseId,
      vaccination: item.vaccination,
      vaccinationId: item.vaccinationId,
      vaccinationDate: item.vaccinationDate,
    });

    setHumanTouchDSelectValue([
      { value: item.touchDiseaseId, label: item.touchDisease },
    ]);
    setVaccineHumanSelectValue([
      { value: item.vaccinationId, label: item.vaccination },
    ]);
  };

  const renderHumanTouch = (item, index) => {
    return (
      <tr key={index} className="text-center">
        <td>{index + 1}</td>
        <td>
          {item.checkEditHumanTouch ? (
            <InputDatePicker
              classFormGroup="mb-0"
              title=""
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={formHumanTouch.touchDate}
              star={false}
              handleChange={(value) =>
                setFormHumanTouch({ ...formHumanTouch, touchDate: value })
              }
            />
          ) : item.touchDate ? (
            helper.getDateFormat(item.touchDate)
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.checkEditHumanTouch ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="text"
              idName="name"
              value={formHumanTouch.name}
              star={false}
              placeholder="ระบุ"
              handleChange={(value) =>
                setFormHumanTouch({ ...formHumanTouch, name: value })
              }
            />
          ) : item.name ? (
            item.name
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.checkEditHumanTouch ? (
            <InputMask
              mask="9999999999"
              idName="tel"
              placeholder="ระบุ"
              classFormGroup="mb-0"
              value={formHumanTouch.tel}
              star={false}
              classLabel="normal"
              handleChange={(value) =>
                setFormHumanTouch({ ...formHumanTouch, tel: value })
              }
            />
          ) : item.tel ? (
            item.tel
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.checkEditHumanTouch ? (
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName="touchDiseaseH"
              classLabel="normal"
              selectValue={humanTouchDSelectValue}
              optionsList={humanTouchDList}
              handleChange={(value) => {
                setHumanTouchDSelectValue([value]);
                setFormHumanTouch({
                  ...formHumanTouch,
                  touchDisease: value.label,
                  touchDiseaseId: value.value,
                });
              }}
            />
          ) : item.touchDisease ? (
            item.touchDisease
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.checkEditHumanTouch ? (
            <div className="d-flex align-items-center">
              <InputSelect
                classFormGroup="mb-0 w-100"
                star={false}
                placeholder="กรุณาเลือก"
                idName="vaccinationH"
                classLabel="normal"
                selectValue={vaccineHumanSelectValue}
                optionsList={vaccineHumanList}
                handleChange={(value) => {
                  setVaccineHumanSelectValue([value]);
                  setVaccineHumanToForm(value);
                }}
              />
              {formHumanTouch.vaccinationDate ? (
                <div className="ml-2">
                  [{helper.getDateFormat(formHumanTouch.vaccinationDate)}]
                </div>
              ) : null}
            </div>
          ) : (
            <>
              {item.vaccination ? item.vaccination : "-"}
              {item.vaccinationDate
                ? `[${helper.getDateFormat(item.vaccinationDate)}]`
                : ""}
            </>
          )}
        </td>
        <td>
          {item.checkEditHumanTouch ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                saveHumanTouch(item, "edit");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => editHumanTouch(item)}
                disabled={form.modeEditTable2}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button
                className="btn btn-del "
                onClick={() => delHumanTouch(item, index)}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  const renderNewHumanTouch = () => {
    return (
      <React.Fragment>
        <tr>
          <td className="text-center">
            {form.data_humanTouch && form.data_humanTouch.length + 1}
          </td>
          <td>
            <InputDatePicker
              classFormGroup="mb-0"
              title=""
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={formHumanTouch.touchDate}
              star={false}
              handleChange={(value) =>
                setFormHumanTouch({ ...formHumanTouch, touchDate: value })
              }
              handleInvalid={() => removeInvalid("touchDateH")}
              invalid={invalid.touchDateH ? true : false}
            />
          </td>

          <td>
            <InputText
              classFormGroup="mb-0 w-100"
              type="text"
              idName="name"
              value={formHumanTouch.name}
              star={false}
              placeholder="ระบุ"
              handleChange={(value) =>
                setFormHumanTouch({ ...formHumanTouch, name: value })
              }
              handleInvalid={() => removeInvalid("name")}
              invalid={invalid.name ? true : false}
            />
          </td>

          <td>
            <InputMask
              mask="9999999999"
              idName="tel"
              placeholder="ระบุ"
              classFormGroup="mb-0"
              value={formHumanTouch.tel}
              star={false}
              classLabel="normal"
              handleChange={(value) =>
                setFormHumanTouch({ ...formHumanTouch, tel: value })
              }
              handleInvalid={() => removeInvalid("tel")}
              invalid={invalid.tel ? true : false}
            />
          </td>

          <td>
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName="touchDiseaseH"
              classLabel="normal"
              selectValue={humanTouchDSelectValue}
              optionsList={humanTouchDList}
              handleChange={(value) => {
                setHumanTouchDSelectValue([value]);
                setFormHumanTouch({
                  ...formHumanTouch,
                  touchDisease: value.label,
                  touchDiseaseId: value.value,
                });
              }}
              handleInvalid={() => removeInvalid("touchDiseaseH")}
              invalid={invalid.touchDiseaseH ? true : false}
            />
          </td>

          <td>
            <div className="d-flex align-items-center">
              <InputSelect
                classFormGroup="mb-0 w-100"
                star={false}
                placeholder="กรุณาเลือก"
                idName="vaccinationH"
                classLabel="normal"
                selectValue={vaccineHumanSelectValue}
                optionsList={vaccineHumanList}
                handleChange={(value) => {
                  setVaccineHumanSelectValue([value]);
                  setVaccineHumanToForm(value);
                }}
                handleInvalid={() => removeInvalid("vaccinationH")}
                invalid={invalid.vaccinationH ? true : false}
              />
              {formHumanTouch.vaccinationDate ? (
                <div className="ml-2">
                  [{helper.getDateFormat(formHumanTouch.vaccinationDate)}]
                </div>
              ) : null}
            </div>
            {invalid.vaccinationDateH ? (
              <div className="invalid-error">{invalid.vaccinationDateH}</div>
            ) : (
              ""
            )}
          </td>

          <td className="text-center">
            <button
              className="btn btn-success  pt-1 px-2"
              onClick={async () => {
                saveHumanTouch(formHumanTouch, "add");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  return (
    <div className="bg-white p-4">
      <BoxCard title="1.1 ประวัติสัตว์ป่วย" classNameBox="mb-3">
        <div className="my-2">
          <div className="d-flex align-items-center mt-2">
            <p className="mb-0 mr-2">วันที่สอบสวน</p>
            <InputDatePicker
              title=""
              classFormGroup="mb-0"
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={form.step1_fm02.q11IntoDate}
              star={false}
              handleChange={(value) => {
                setForm({
                  ...form,
                  step1_fm02: {
                    q11: form.step1_fm02.q11,
                    q11MoveDate: form.step1_fm02.q11MoveDate,
                    q11SymptomDate: form.step1_fm02.q11SymptomDate,
                    q11IntoDate: value,
                  },
                });
                toSave();
              }}
            />
          </div>
          <div className="mt-2">
            <Form.Check
              inline
              type="radio"
              name="areaAnt"
              id="areaAnt"
              value={"y"}
              checked={form.step1_fm02.q11 === "y" ? true : false}
              label="เป็นสัตว์ที่อยู่ในพื้นที่"
              onChange={(e) => {
                setForm({
                  ...form,
                  step1_fm02: {
                    q11: e.target.value,
                    q11MoveDate: "",
                    q11SymptomDate: form.step1_fm02.q11SymptomDate,
                    q11IntoDate: form.step1_fm02.q11IntoDate,
                  },
                });
                toSave();
              }}
            />
          </div>
          <div className="d-flex ">
            <Form.Check
              inline
              type="radio"
              name="areaAnt"
              id="moveAnt"
              value={"n"}
              checked={form.step1_fm02.q11 == "n"}
              label="เป็นสัตว์ที่เคลื่อนย้ายมาใหม่"
              onChange={(e) => {
                setForm({
                  ...form,
                  step1_fm02: {
                    q11: e.target.value,
                    q11MoveDate: form.step1_fm02.q11MoveDate,
                    q11SymptomDate: form.step1_fm02.q11SymptomDate,
                    q11IntoDate: form.step1_fm02.q11IntoDate,
                  },
                });
                toSave();
              }}
            />
            <div className="d-flex align-items-center">
              <p className="mb-0 mr-2">เมื่อวันที่</p>
              <InputDatePicker
                title=""
                classFormGroup="mb-0"
                classLabel="normal"
                placeholder="วัน/เดือน/ปี"
                value={form.step1_fm02.q11MoveDate}
                star={false}
                disabled={form.step1_fm02.q11 == "y"}
                handleChange={(value) => {
                  setForm({
                    ...form,
                    step1_fm02: {
                      q11: form.step1_fm02.q11,
                      q11MoveDate: value,
                      q11SymptomDate: form.step1_fm02.q11SymptomDate,
                      q11IntoDate: form.step1_fm02.q11IntoDate,
                    },
                  });
                  toSave();
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center mt-2">
            <p className="mb-0 mr-2">วันที่สัตว์แสดงอาการ</p>
            <InputDatePicker
              title=""
              classFormGroup="mb-0"
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={form.step1_fm02.q11SymptomDate}
              star={false}
              handleChange={(value) => {
                setForm({
                  ...form,
                  step1_fm02: {
                    q11: form.step1_fm02.q11,
                    q11MoveDate: form.step1_fm02.q11MoveDate,
                    q11SymptomDate: value,
                    q11IntoDate: form.step1_fm02.q11IntoDate,
                  },
                });
                toSave();
              }}
            />
          </div>
        </div>
      </BoxCard>

      <BoxCard title="1.2 อาการผิดปกติ" classNameBox="mb-3">
        <div className="py-2">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item tab">
              <a
                className="nav-link active"
                id="disease-tab"
                data-toggle="tab"
                href="#disease"
                role="tab"
                aria-controls="data"
                aria-selected="true"
              >
                โรคทั่วไป
              </a>
            </li>
            <li className="nav-item tab">
              <a
                className="nav-link"
                id="rabies-tab"
                data-toggle="tab"
                href="#rabies"
                role="tab"
                aria-controls="rabies"
                aria-selected="false"
              >
                Rabies
              </a>
            </li>
            <li className="nav-item tab">
              <a
                className="nav-link"
                id="eia-tab"
                data-toggle="tab"
                href="#eia"
                role="tab"
                aria-controls="eia"
                aria-selected="false"
              >
                EIA/AHS/Sentinel
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active card-tab"
              id="disease"
              role="tabpanel"
              aria-labelledby="disease-tab"
            >
              <Form.Check
                type="checkbox"
                name="selectAllTab1"
                id="selectAllTab1"
                label="เลือกทั้งหมด"
                onChange={handleSelectAllTab1}
                checked={isCheckAllTab1}
              />

              <Row>
                {psSymptomsTab1 &&
                  psSymptomsTab1.length > 0 &&
                  psSymptomsTab1.map((itemA, a) => (
                    <Col key={a} lg={3} md={3} sm={6}>
                      <div className="d-flex align-items-center ">
                        <Form.Check
                          className="mr-2"
                          type="checkbox"
                          name={`symptom_tab1_${itemA.id}`}
                          id={itemA.id}
                          label={itemA.name}
                          onChange={(e) => setChooseOneTab1(e)}
                          checked={
                            !!form.chooseTab1.find(
                              (checkA) => checkA.fm02SymptomsId == itemA.id
                            )
                          }
                        />

                        {itemA.id == "a" && (
                          <InputText
                            type="text"
                            title=""
                            idName="ps_other1_text"
                            placeholder="ระบุ"
                            classLabel="normal"
                            classFormGroup="mb-0"
                            star={false}
                            value={
                              form.chooseTab1[form.indexOtherTab1]
                                ? form.chooseTab1[form.indexOtherTab1].other
                                : ""
                            }
                            disabled={
                              form.chooseTab1.find(
                                (check) => check.fm02SymptomsId == itemA.id
                              )
                                ? false
                                : true
                            }
                            handleChange={(value) => {
                              setTextOtherTab1(value);
                              toSave();
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  ))}
              </Row>
            </div>
            <div
              className="tab-pane fade card-tab"
              id="rabies"
              role="tabpanel"
              aria-labelledby="rabies-tab"
            >
              <Form.Check
                type="checkbox"
                name="selectAllTab2"
                id="selectAllTab2"
                label="เลือกทั้งหมด"
                onChange={handleSelectAllTab2}
                checked={isCheckAllTab2}
              />

              <Row>
                {psSymptomsTab2 &&
                  psSymptomsTab2.length > 0 &&
                  psSymptomsTab2.map((itemB, b) => (
                    <Col key={b} lg={3} md={3} sm={6}>
                      <div className="d-flex align-items-center ">
                        <Form.Check
                          className="mr-2"
                          type="checkbox"
                          name={`symptom_tab2_${itemB.id}`}
                          id={itemB.id}
                          label={itemB.name}
                          onChange={(e) => setChooseOneTab2(e)}
                          checked={
                            !!form.chooseTab2.find(
                              (checkB) => checkB.fm02SymptomsId == itemB.id
                            )
                          }
                        />

                        {itemB.id == "b" && (
                          <InputText
                            type="text"
                            title=""
                            idName="ps_other2_text"
                            placeholder="ระบุ"
                            classLabel="normal"
                            classFormGroup="mb-0"
                            star={false}
                            value={
                              form.chooseTab2[form.indexOtherTab2]
                                ? form.chooseTab2[form.indexOtherTab2].other
                                : ""
                            }
                            disabled={
                              form.chooseTab2.find(
                                (check) => check.fm02SymptomsId == itemB.id
                              )
                                ? false
                                : true
                            }
                            handleChange={(value) => {
                              setTextOtherTab2(value);
                              toSave();
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  ))}
              </Row>
            </div>

            <div
              className="tab-pane fade card-tab"
              id="eia"
              role="tabpanel"
              aria-labelledby="eia-tab"
            >
              <Form.Check
                type="checkbox"
                name="selectAllTab3"
                id="selectAllTab3"
                label="เลือกทั้งหมด"
                onChange={() => handleSelectAllTab3()}
                checked={isCheckAllTab3}
              />

              <Row>
                {psSymptomsTab3 &&
                  psSymptomsTab3.length > 0 &&
                  psSymptomsTab3.map((itemC, c) => (
                    <Col key={c} lg={3} md={3} sm={6}>
                      <div className="d-flex align-items-center ">
                        <Form.Check
                          className="mr-2"
                          type="checkbox"
                          name={`symptom_Tab3_${itemC.id}`}
                          id={itemC.id}
                          label={itemC.name}
                          onChange={(e) => setChooseOneTab3(e)}
                          checked={
                            !!form.chooseTab3.find(
                              (checkC) => checkC.fm02SymptomsId == itemC.id
                            )
                          }
                        />

                        {itemC.id == "32" && (
                          <InputText
                            type="text"
                            title=""
                            idName="ps_fever_text"
                            placeholder="ระบุ"
                            classLabel="normal"
                            classFormGroup="mb-0"
                            star={false}
                            value={
                              form.chooseTab3[form.indexFeverTab3]
                                ? form.chooseTab3[form.indexFeverTab3]
                                    .degreeFever
                                : ""
                            }
                            disabled={
                              form.chooseTab3.find(
                                (checkC) => checkC.fm02SymptomsId == itemC.id
                              )
                                ? false
                                : true
                            }
                            handleChange={(value) => {
                              setTextDegreeFeverTab3(value);
                              toSave();
                            }}
                          />
                        )}

                        {itemC.id == "c" && (
                          <InputText
                            type="text"
                            title=""
                            idName="ps_other3_text"
                            placeholder="ระบุ"
                            classLabel="normal"
                            classFormGroup="mb-0"
                            star={false}
                            value={
                              form.chooseTab3[form.indexOtherTab3]
                                ? form.chooseTab3[form.indexOtherTab3].other
                                : ""
                            }
                            disabled={
                              form.chooseTab3.find(
                                (checkC) => checkC.fm02SymptomsId == itemC.id
                              )
                                ? false
                                : true
                            }
                            handleChange={(value) => {
                              setTextOtherTab3(value);
                              toSave();
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  ))}
              </Row>
              <div className="mt-3">
                <p className="bold mb-0">ลักษณะสัตว์</p>
                <Row>
                  {form.animal_look &&
                    form.animal_look.length > 0 &&
                    form.animal_look.map((item, i) => (
                      <Col key={i} lg={6} md={6} sm={12}>
                        <Form.Check
                          className="m-0 p-0"
                          type="radio"
                          name={`look${i}`}
                          id={`look${i}`}
                          value={item.id}
                          checked={form.chooseLook == item.id}
                          onChange={(e) => {
                            setChooseLookTab3(e.target.value);
                          }}
                        />

                        <label htmlFor={`look${i}`}>
                          <img
                            src={item.src}
                            alt="look"
                            className="img-fluid cursor-p"
                          />
                        </label>
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </BoxCard>

      {/* <BoxCard
        title="1.3 ข้อมูลสัตว์สัมผัสเชื้อ"
        classNameBox="mb-3"
        headRight={buttonAddAnmTouch()}
      >
        <div className="">
          <table className="table table-striped sameWidth table-bordered my-2 ">
            <thead>
              <tr className="text-center">
                <th scope="col">ลำดับ</th>
                <th scope="col">วันที่</th>
                <th scope="col">ชนิดสัตว์</th>
                <th scope="col">ชื่อสัตว์/ตำหนิ</th>
                <th scope="col">การสัมผัสเชื้อ</th>
                <th scope="col">การกักกันสัตว์</th>
                <th scope="col">ฉีดวัคซีน</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              {form.data_anmTouch && form.data_anmTouch.length > 0
                ? form.data_anmTouch.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        {" "}
                        {renderAnimalTouch(item, i)}
                      </React.Fragment>
                    );
                  })
                : null}
              {form.checkAddAnmTouch && renderNewAnimalTouch()}
              {form.data_anmTouch &&
                form.data_anmTouch.length == 0 &&
                !form.checkAddAnmTouch && <DataTableNodata colSpan={8} />}
            </tbody>
          </table>
        </div>
      </BoxCard>

      <BoxCard
        title="1.4 ข้อมูลคนสัมผัสเชื้อ (Rabies/AHS)"
        headRight={buttonAddHumanTouch()}
      >
        <div className="">
          <table className="table table-striped sameWidth table-bordered my-2 ">
            <thead>
              <tr className="text-center">
                <th scope="col">ลำดับ</th>
                <th scope="col">วันที่</th>
                <th scope="col">ชื่อ นามสกุล</th>
                <th scope="col">เบอร์โทรศัพท์</th>
                <th scope="col">การสัมผัสเชื้อ</th>
                <th scope="col">ฉีดวัคซีน</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              {form.data_humanTouch && form.data_humanTouch.length > 0
                ? form.data_humanTouch.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        {" "}
                        {renderHumanTouch(item, i)}
                      </React.Fragment>
                    );
                  })
                : null}
              {form.checkAddHumanTouch && renderNewHumanTouch()}
              {form.data_humanTouch &&
                form.data_humanTouch.length == 0 &&
                !form.checkAddHumanTouch && <DataTableNodata colSpan={7} />}
            </tbody>
          </table>
        </div>
      </BoxCard> */}

      <ModalVaccineDate
        show={showVaccineDate}
        close={() => setShowVaccineDate(false)}
        headModal={form.headModal}
        save={(data) => {
          if (form.typeVcDate == "animal") {
            addVaccineAnmDate(data);
          } else {
            addVaccineHumanDate(data);
          }
        }}
      />
    </div>
  );
}

export default DiseaseInvest_Step1;
