import * as actionTypes from "../actions/loadingTypes";

export const initialState = [
    {
        id: 1,
        idOb: 4,
        color: "#CBCBCB",
        status: "แจ้งโรค",
        fontColor: "#000",
    },
    {
        id: 2,
        idOb: 5,
        color: "#20A8D8",
        status: "รับแจ้ง",
        fontColor: "#FFFFFF",
    },
    {
        id: 3,
        idOb: 2,
        color: "#F94343",
        status: "เฝ้าระวัง",
        fontColor: "#FFFFFF",
    },
    {
        id: 4,
        idOb: 1,
        color: "#FF8C00",
        status: "ควบคุมโรค",
        fontColor: "#FFFFFF",
    },
    {
        id: 5,
        idOb: 3,
        color: "#25B881",
        status: "โรคสงบ",
        fontColor: "#FFFFFF",
    },
]

export default (state = initialState) => {
    return state;
};