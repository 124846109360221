import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkProvinceList,
  checkZoneLivestockList,
} from "../../redux/actions/masterDataAction";
import range from "lodash/range";
import getYear from "date-fns/getYear";

const useMasterData = (
  masterDataType,
  optionList,
  setOptionList,
  provinceByZoneId
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkProvinceList());
    dispatch(checkZoneLivestockList());
  }, []);

  const provinceMaster = useSelector((state) => state.masterData.province);
  const zoneLivestockMaster = useSelector(
    (state) => state.masterData.zoneLivestock
  );

  const dataMasterList =
    masterDataType === "zoneLivestock"
      ? zoneLivestockMaster
      : masterDataType === "province" && provinceByZoneId
      ? provinceByZoneId === true
        ? []
        : provinceByZoneId === null
        ? provinceMaster
        : provinceByZoneId
      : masterDataType === "province" && !provinceByZoneId
      ? provinceMaster
      : [];
  if (!dataMasterList) return;
  if (dataMasterList?.length === 0) return;
  if (dataMasterList?.length === optionList?.length) return;

  setOptionList(dataMasterList);
};

const useOptionsListYears = () => {
  const years = range(1980, getYear(new Date()) + 10, 1);
  let dataValue = [];
  if (years?.length > 0) {
    years.map((item) => {
      dataValue.push({ value: item + 543, label: item + 543 + "" });
    });
  }
  return dataValue;
};

const useCurrentYear = () => {
  const currentYear = new Date().getFullYear() + 543;
  let dataValue = { value: currentYear, label: currentYear };
  return dataValue;
};

export { useMasterData, useOptionsListYears, useCurrentYear };
