import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import withReactContent from "sweetalert2-react-content";
import { useLocation } from "react-router-dom";
import { Row, Col, Form, Table } from "react-bootstrap";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import Swal from "sweetalert2";

import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";

import BoxHead from "../../components/BoxHead";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputGroupText from "../../components/InputGroupText";
import InputSelect from "../../components/InputSelect";
import InputMask from "../../components/InputMask";
import InputDatePicker from "../../components/InputDatePicker";
import Breadcrumb from "../../components/Breadcrumb";
import ModalOrganizes from "../../components/Modal.Organizes";
import ModalAnimalByOne from "../../components/Modal.AnimalByOne";
import DataTableNodata from "../../components/DataTableNodata";
import ModalOutbreakSearch from "../../components/Modal.OutbreakSearch";
import ModalFarmSearch from "../../components/Modal.FarmSearch";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function Vaccinate_Save(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t, i18n } = useTranslation(["common", "footer"]);

  const initialForm = {
    checkAddData: false,

    farmId: "",
    outbreakId: "",
    outbreakCode: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    caseCode: "",
    homeNo: "",
    phone: "",
    name: "",
    orgId: "",
    orgName: "",

    operateDateFrom: "",
    operateDateTo: "",
    dldStaffName1: "",
    dldStaffName2: "",
    vaccineActionAreas: "1",

    modeEditTable1: false,
    checkEditVacFarm: false,
    house: [],
    houseList: [],
    delBarn: [],
    barns: [],
    chooseSelect: false,

    // ข้อมูลสัตว์
    dataAnimalEdit: "",
    modeAnimal: "add",

    animalAll: [], // สัตว์ทั้งหมดในฟาร์มนี้ ไปเช็คกับตอนเลือกจาก modal
    listAnimals: [], // list เพื่อส่งไป api
    res_animals: [], // list เพื่อโชว์
    del_animals: [], // array id สัตว์ที่ลบ

    del_rabies: [],
    checkAddAniaml: false,
    checkEdit: false,
    indexNew: "",
    modeEditTable2: false,

    fm01: {},
  };
  const [form, setForm] = useState(initialForm);

  const initialFormDesc = {
    idHouse: "",
    nameHouse: "",
    animalTypeId: "",
    animalTypeName: "",
    vaccineTypeId: "",
    vaccineTypeName: "",
    vaccine_date: "",
    lot_no: "",
    injected_staff: "",
    injected_owner: "",
    number_animal_vaccine: "",
    number_animal_herd: "",
    belly_animal: "",
    more_than: "",
    not_can_catch: "",
    other: "",
    animal_age: "",
  };
  const [formDesc, setFormDesc] = useState(initialFormDesc);

  const initialAnimal = {
    vaccine_id: "",
    vaccine_name: "",
    lotNo: "",
  };
  const [formAnimal, setFormAnimal] = useState(initialAnimal);

  const initialFormJson = {
    animalId: {},
    diseaseTypeId: {},
    husbandry: {},
    animalPurpose: {},
    vaccineType: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initInvalidMsg = {
    nameHouse: "",
    animalTypeName: "",

    lotNo: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const initShowCaseIdSearch = false;
  const [showCaseIdSearch, setShowCaseIdSearch] =
    useState(initShowCaseIdSearch);

  const initShowOrgModal = false;
  const [showOrgModal, setShowOrgModal] = useState(initShowOrgModal);

  const initShowOutbreakSearch = false;
  const [showOutbreakSearch, setShowOutbreakSearch] = useState(
    initShowOutbreakSearch
  );

  const initShowFarmSearch = false;
  const [showFarmSearch, setShowFarmSearch] = useState(initShowFarmSearch);

  const [checkHouseList, setCheckHouseList] = useState(false);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  useMasterData("province", provinceList, setProvinceList);

  // =================

  const [houseSelectValue, setHouseSelectValue] = useState([]);
  const [houseList, setHouseList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [organizesSelectValue, setOrganizesSelectValue] = useState([]);
  const [organizesList, setOrganizesList] = useState([]);

  const [vaccineTypeSelectValue, setVaccineTypeSelectValue] = useState([]);
  const [vaccineTypeList, setVaccineTypeList] = useState([]);

  const initShowAnmByOne = false;
  const [showAnmByOne, setShowAnmByOne] = useState(initShowAnmByOne);

  const [vaccineSelectValue, setVaccineSelectValue] = useState([]);
  const [vaccineList, setVaccineList] = useState([]);

  const initFarmVaccinate = {
    barn: [],
    animalType: [],
    vaccineType: [],
    vaccineDate: "",
    lotNo: "",
    vaccinated: {
      injectStaffAmt: "",
      injectOwnerAmt: "",
    },
    totalAmt: "",
    allVaccinatedAmt: "",
    nonVaccinated: {
      pregnantAmt: "",
      less4monthAmt: "",
      notCatchAmt: "",
      otherAmt: "",
    },
    ageVaccine: "",

    save: true,
  };

  const [farmVaccination, setFarmVaccination] = useState([]);
  const [deleteBarn, setDeleteBarn] = useState([]);
  const [disableButton, setDisableButton] = useState([]);

  // =================

  useEffect(async () => {
    await getMasterJson();
    await getProvince();
    await getAnimal();
  }, []);

  useEffect(async () => {
    if (
      location.pathname.includes("edit") ||
      location.pathname.includes("duplicate")
    ) {
      getVaccinateById(props.match.params.id);
    }
  }, [
    user,
    location.pathname,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
    checkHouseList,
  ]);

  useEffect(async () => {
    if (location.pathname.includes("save") && form.outbreakId) {
      getOutbreakById(form.outbreakId);
    } else if (location.pathname.includes("save") && props.match.params.id) {
      getOutbreakById(props.match.params.id);
    }
  }, [
    user,
    form.outbreakId,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
  ]);

  const getMasterJson = async () => {
    const params = await [
      "animalType",
      "diseaseType",
      "husbandry",
      "animalPurpose",
      "vaccineType",
    ];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          animalId: data.animalType,
          diseaseTypeId: data.diseaseType,
          husbandry: data.husbandry,
          animalPurpose: data.animalPurpose,
          vaccineType: data.vaccineType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id);
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOutbreakById = async (id) => {
    dispatch(loading(true));
    let dataRes;
    try {
      const response = await API.getOutbreakById(id);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
    if (dataRes) {
      const dataById = dataRes;

      if (dataById.fm01 && dataById.fm01.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.fm01.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.fm01.provinceId);
        }
      }

      if (dataById.fm01 && dataById.fm01.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.fm01.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.fm01.amphurId);
        }
      }

      if (dataById.fm01 && dataById.fm01.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.fm01.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.fm01.tambonId);
        }
      }

      if (dataById.fm01 && dataById.fm01.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.fm01.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.fm01 && dataById.fm01.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.fm01.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      const houseArray = [];
      if (dataById.fm01Response && dataById.fm01Response.fm0102) {
        if (
          dataById.fm01Response.fm0102 &&
          dataById.fm01Response.fm0102.barns.length > 0
        ) {
          let barn = dataById.fm01Response.fm0102.barns;
          barn.map((barns, b) => {
            houseArray.push({
              barnId: barns.id,
              name: barns.name,
              desc: [],
            });
          });
        }
      }

      let arrayHouseList = [];
      if (houseArray && houseArray.length > 0) {
        houseArray.forEach((house) =>
          arrayHouseList.push({ value: house.barnId, label: house.name })
        );
      }
      setHouseList(arrayHouseList);
      setForm({
        ...form,
        mode: "add",
        dataAllById: dataById,
        farmId: dataById.fm01.farmId,
        caseCode: dataById.fm01.caseCode,

        provinceId: dataById.fm01.provinceId,
        districtId: dataById.fm01.amphurId,
        subDistrictId: dataById.fm01.tambonId,
        villageId: dataById.fm01.mooId,
        homeNo: dataById.fm01.address,

        phone: dataById.fm01.ownerTel,
        name: dataById.fm01.ownerName,
        orgId: dataById.fm01.orgId,
        orgName: dataById.fm01.orgName,

        house: houseArray,

        fm01: dataById.fm01,
        checkAddData: true,

        outbreakCode: dataById.outbreak.obCode,
      });
    }
  };

  const getFarmById = async (id) => {
    try {
      const response = await API.getFarmRegistrationById(id);
      const dataRes = await response.data;
      if (response.status == 200) {
        let arrayHouseList = [];
        if (dataRes && dataRes.barn.length > 0) {
          dataRes.barn.forEach((house) =>
            arrayHouseList.push({ value: house.id, label: house.name })
          );
        }
        setHouseList(arrayHouseList);
        setCheckHouseList(true);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getVaccinateById = async (id) => {
    let dataRes;
    try {
      const response = location.pathname.includes("edit")
        ? await API.getVaccinateById(id)
        : await API.getCopyVaccinateById(id);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;
      if (dataById.fm01 && dataById.fm01.farmId) {
        getFarmById(dataById.fm01.farmId);
      }

      if (dataById.fm01 && dataById.fm01.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.fm01.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.fm01.provinceId);
        }
      }

      if (dataById.fm01 && dataById.fm01.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.fm01.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.fm01.amphurId);
        }
      }

      if (dataById.fm01 && dataById.fm01.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.fm01.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.fm01.tambonId);
        }
      }

      if (dataById.fm01 && dataById.fm01.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.fm01.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.fm01 && dataById.fm01.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.fm01.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      const houseArray = [];
      const barns = [];

      if (dataById.barns && dataById.barns.length > 0) {
        dataById.barns.map((item, i) => {
          let barnId;
          if (item.barnId) {
            barnId = item.barnId
              ? houseList.find((itemB) => {
                  return itemB.value === item.barnId;
                })
              : { value: null, label: "-" };
          }

          barns.push({
            id: item.id,
            barn: barnId,
            animalType: {
              value: item.animalTypeId,
              label: formJson.animalId[item.animalTypeId],
            },
            vaccineType: [
              {
                value: item.vaccineTypeId,
                label: formJson.vaccineType[item.vaccineTypeId],
              },
            ],
            vaccineDate: item.vaccineDate,
            lotNo: item.lotNo,
            vaccinated: {
              injectStaffAmt: item.injectStaffAmt,
              injectOwnerAmt: item.injectOwnerAmt,
            },
            totalAmt: item.totalAmt,
            sumAmt: Number(item.injectStaffAmt) + Number(item.injectOwnerAmt),
            nonVaccinated: {
              pregnantAmt: item.pregnantAmt,
              less4monthAmt: item.less4monthAmt,
              notCatchAmt: item.notCatchAmt,
              otherAmt: item.otherAmt,
            },
            ageVaccine: item.ageVaccine,
            save: false,
            edit: false,
          });
        });
        setFarmVaccination(barns);
      }

      const animalRes = [];
      if (dataById.animals && dataById.animals.length > 0) {
        dataById.animals.map((animal, a) => {
          animalRes.push({
            id: animal.id,
            animalTypeName: formJson.animalId[animal.animalTypeId],
            animalTypeId: animal.animalTypeId,
            animalId: animal.animalCode,
            date_dead: animal.deadDate,
            status_id: animal.statusId,
            cause_death_type: animal.deadCause,
            cause_death_type_other: animal.deadReason,
            name: animal.name,
            genderType: animal.sex,
            gender:
              animal.sex === "m"
                ? "ตัวผู้"
                : animal.sex === "f"
                ? "ตัวเมีย"
                : "ไม่ทราบ",
            vaccineTypeName: formJson.vaccineType[animal.vaccineTypeId],
            animalId: animal.animalCode,
            vaccineTypeId: animal.vaccineTypeId,
            lotNo: animal.lotNo,
            house_buiding: houseList.map((house) => {
              if (house.value == animal.barnId) {
                return house.label;
              }
            }),
            house_buiding_id: animal.barnId,
            animalId: animal.animalCode,
            age_year: animal.ageYear,
            age_month: animal.ageMonth,
            no_microchip: animal.microchip,
            breed: animal.species,
            purpose_raising: animal.purposeId,
            purpose_name: formJson.animalPurpose[animal.purposeId],
            no_nid: animal.nidCode,
            no_animal_ear: animal.nid,
            style_raising: animal.husbandryId,
            style_raising_name: formJson.husbandry[animal.husbandryId],
            is_vaccine: animal.vaccinationStatus,
          });
        });
      }

      setForm({
        ...form,
        mode: "edit",
        dataAllById: dataById,
        outbreakCode: dataById.outbreak.obCode,
        farmId: dataById.fm01.farmId,
        caseCode: dataById.fm01.caseCode,

        provinceId: dataById.fm01.provinceId,
        districtId: dataById.fm01.amphurId,
        subDistrictId: dataById.fm01.tambonId,
        villageId: dataById.fm01.mooId,
        homeNo: dataById.fm01.address,

        phone: dataById.fm01.ownerTel,
        name: dataById.fm01.ownerName,
        orgId: dataById.fm01.orgId,
        orgName: dataById.fm01.orgName,

        operateDateFrom: dataById.fm05.operateDateFrom,
        operateDateTo: dataById.fm05.operateDateTo,
        dldStaffName1: dataById.fm05.dldStaffName1,
        dldStaffName2: dataById.fm05.dldStaffName2,
        vaccineActionAreas: dataById.fm05.vaccineActionAreas,
        vaccineObjective: dataById.fm05.vaccineObjective,
        house: houseArray,
        res_animals: animalRes,
        barns: barns,

        checkAddData: houseArray.length == 0 ? true : false,
      });
    }
  };

  const buttonAddAnimal = () => {
    return (
      <button
        className="btn btn-add text-white"
        onClick={() => {
          setShowAnmByOne(true);
        }}
      >
        <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูลสัตว์รายตัว
      </button>
    );
  };

  const compareAnmId = (list, value) => {
    let check = false;
    list.forEach((animal, a) => {
      if (animal.id == value) {
        check = true;
      }
    });
    return check;
  };

  const checkAnimal = (list, item) => {
    let checkArry = false;
    if (list?.length > 0) {
      list.forEach((animal, a) => {
        if (animal.id == item) {
          checkArry = true;
        }
      });
    }
    if (checkArry) {
      MySwal.fire({
        text: "กรุณาเลือกสัตว์ตัวใหม่ ในรายการมีสัตว์ที่เลือกไว้แล้ว",
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
      return false;
    } else {
      return true;
    }
  };

  const addAnimalByOne = (data) => {
    if (data.animals && data.animals.length > 0) {
      let dataList = [];

      data.anmChooseList.map((chooseList, c) => {
        data.animals.map((animal, a) => {
          if (chooseList == animal.id) {
            const dataArr = {
              id: animal?.id ?? "",
              house_buiding: animal.house_buiding,
              house_buiding_id: animal.house_buiding_id,
              animalTypeId: animal.animalTypeId,
              animalId: animal.animalId ? animal.animalId : "-",
              name: animal.name,
              genderType: animal.genderType,
              age_year: animal.age_year,
              age_month: animal.age_month,
              vaccineTypeId: animal.vaccineTypeId,
              lotNo: animal.lotNo,
            };
            if (dataList.length > 0) {
              dataList.map((list) => {
                if (list.id !== animal.id) {
                  if (dataList?.length < data.anmChooseList?.length) {
                    dataList.push(dataArr);
                  }
                }
              });
            } else {
              if (form.res_animals?.length > 0) {
                dataList.push(...form.res_animals, dataArr);
              } else {
                dataList.push(dataArr);
              }
            }
          }
          if (checkAnimal(form.res_animals, chooseList)) {
            setForm({ ...form, res_animals: dataList });
          }
        });
      });
    }
  };

  const animalEdit = async (item, index) => {
    item.checkEdit = true;
    setForm({ ...form, modeEditTable2: true });
    setFormAnimal({
      ...formAnimal,
      vaccine_id: item.vaccineTypeId,
      vaccine_name: formJson.vaccineType[item.vaccineTypeId],
      lotNo: item.lotNo,
    });
    setVaccineSelectValue([
      {
        value: item.vaccineTypeId,
        label: formJson.vaccineType[item.vaccineTypeId],
      },
    ]);
    if (item.animalTypeId) {
      await getVaccineType(item.animalTypeId, "table2");
    }
  };

  const validateAnimal = () => {
    let validated = true;
    // console.log("validateAnimal", invalid);
    if (formAnimal.lotNo == "") {
      addInvalid("lotNo", "กรุณากรอกเลข Lot No.");
      validated = false;
    }

    return validated;
  };

  const animalSave = (item, type) => {
    if (validateAnimal()) {
      item.checkEdit = false;
      item.vaccineTypeId = formAnimal.vaccine_id;
      item.vaccineTypeName = formAnimal.vaccine_name;
      item.lotNo = formAnimal.lotNo;
      setForm({ ...form, checkAddAniaml: false, modeEditTable2: false });
    }
  };

  const animalRemove = (index, animalTypeId, lotNo) => {
    if (form.res_animals && form.res_animals.length > 0) {
      form.res_animals.map((animal, a) => {
        if (lotNo == animal.lotNo && animalTypeId == animal.animalTypeId) {
          form.res_animals.splice(index, 1);
          if (animal.animalTypeId) {
            form.del_animals.push(animal.id);
          }
          setForm({ ...form, del_animals: form.del_animals });
        }
      });
    }
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "/passive_surv/vaccinate", name: "ฉีดวัคซีน" },
    {
      path: "title",
      name: "บันทึกแผน/ผลการฉีดวัคซีนป้องกันโรคระบาดสัตว์ (กคร.5)",
    },
  ];

  const toCancel = () => {
    history.push("/passive_surv/vaccinate");
  };

  const getVaccineType = async (id, type) => {
    try {
      const response = await API.getVaccineType(id);
      const data = await response.data;
      if (response.status == 200) {
        let vaccineArray1 = [];
        let vaccineArray2 = [];
        if (type == "table1") {
          data.content.forEach((element) => {
            vaccineArray1.push({
              value: element.id,
              label: element.name,
            });
          });
          setVaccineTypeList(vaccineArray1);
          return vaccineArray1;
        } else {
          data.content.forEach((element) => {
            vaccineArray2.push({
              value: element.id,
              label: element.name,
            });
          });
          setVaccineList(vaccineArray2);
          return vaccineArray2;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const selectAnimal = async (value) => {
    setAnimalSelectValue([{ value: value?.value, label: value?.label }]);
    setVaccineSelectValue([]);
    setFormDesc({
      ...formDesc,
      animalTypeId: value?.value,
      animalTypeName: value?.label,
    });
    let vaccArray = await getVaccineType(value?.value, "table1");
    return vaccArray;
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, provinceId: value.value, zipcode: "" });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, districtId: value.value, zipcode: "" });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value, zipcode: value.zipcode });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const setOrganizes = (data) => {
    setForm({ ...form, orgId: data.value, orgName: data.label });
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const addFarm = () => {
    const data = farmVaccination.map((item) => {
      return { ...item, edit: true };
    });
    setFarmVaccination([...data, initFarmVaccinate]);
  };

  const checkAddNew = () => {
    return farmVaccination.find((item) => {
      return item.save == true;
    });
  };

  const buttonAddFarmItem = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => addFarm()}
          disabled={checkAddNew()}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const toSaveVac = async () => {
    if (!form.outbreakCode) {
      MySwal.fire({
        text: "กรุณาเลือกเลข Outbreak ID",
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
    } else {
      let houseArray = [];
      if (farmVaccination && farmVaccination.length > 0) {
        farmVaccination.map((item, i) => {
          houseArray.push({
            id: location.pathname.includes("edit") && item.id ? item.id : "",
            barnId: item.barn ? item.barn.value : "",
            animalTypeId: item.animalType ? item.animalType.value : "",
            vaccineTypeId:
              item.vaccineType.length > 0 ? item.vaccineType[0].value : "",
            vaccineDate: item.vaccineDate ? item.vaccineDate : "",
            lotNo: item.lotNo ? item.lotNo : "",
            injectStaffAmt: item.vaccinated.injectStaffAmt
              ? item.vaccinated.injectStaffAmt
              : "",
            injectOwnerAmt: item.vaccinated.injectOwnerAmt
              ? item.vaccinated.injectOwnerAmt
              : "",
            totalAmt: item.totalAmt ? item.totalAmt : "",
            pregnantAmt: item.nonVaccinated.pregnantAmt
              ? item.nonVaccinated.pregnantAmt
              : "",
            less4monthAmt: item.nonVaccinated.less4monthAmt
              ? item.nonVaccinated.less4monthAmt
              : "",
            notCatchAmt: item.nonVaccinated.notCatchAmt
              ? item.nonVaccinated.notCatchAmt
              : "",
            otherAmt: item.nonVaccinated.otherAmt
              ? item.nonVaccinated.otherAmt
              : "",
            ageVaccine: item.ageVaccine ? item.ageVaccine : "",
          });
        });
      }

      const animalRes = [];
      if (form.res_animals && form.res_animals.length > 0) {
        form.res_animals.map((item, i) => {
          animalRes.push({
            id: location.pathname.includes("edit") && item.id ? item.id : "",
            barnId: item.house_buiding_id,
            animalTypeId: item.animalTypeId,
            animalCode: item.animalId ? item.animalId : "-",
            name: item.name,
            sex: item.genderType,
            ageYear: item.age_year,
            ageMonth: item.age_month,
            vaccineTypeId: item.vaccineTypeId,
            lotNo: item.lotNo,
          });
        });
      }

      const delAnimalsRes = [];
      if (form.del_animals && form.del_animals.length > 0) {
        form.del_animals.map((item) => {
          if (location.pathname.includes("edit")) {
            delAnimalsRes.push(item);
          }
        });
      }

      const data = {
        outbreak: {
          obCode: form.outbreakCode,
        },
        fm01: {
          id: "",
          caseCode: form.caseCode,
          address: form.homeNo,
          amphurId: form.districtId,
          animalAround5km: form.fm01.animalAround5km || "",
          animalTypeId: form.fm01.animalTypeId || "",
          diseaseTypeId: form.fm01.diseaseTypeId || "",
          dlaId: form.fm01.dlaId,
          dlaName: form.fm01.dlaName,
          farmId: form.farmId,
          firstAnimSymptomDate: form.fm01.firstAnimSymptomDate
            ? helper.getDate(form.fm01.firstAnimSymptomDate)
            : "",
          foundDiseaseDate: form.fm01.foundDiseaseDate
            ? helper.getDate(form.fm01.foundDiseaseDate)
            : "",
          humanDeadAmt: form.fm01.humanDeadAmt || "",
          humanSickAmt: form.fm01.humanSickAmt || "",
          humanTouchAmt: form.fm01.humanTouchAmt || "",
          informBy: form.fm01.informBy || "",
          informOther: form.fm01.informOther || "",
          informId: form.fm01.informId || "",
          mooId: form.villageId,
          obId: form.outbreakId,
          orgId: form.orgId,
          ownerName: form.name,
          ownerTel: form.phone,
          provinceId: form.provinceId,
          statusId: 1,
          symptomId: form.fm01.symptomId,
          tambonId: form.subDistrictId,
        },
        fm05: {
          id: "",
          operateDateFrom: form.operateDateFrom
            ? helper.getDate(form.operateDateFrom)
            : "",
          operateDateTo: form.operateDateTo
            ? helper.getDate(form.operateDateTo)
            : "",
          dldStaffName1: form.dldStaffName1,
          dldStaffName2: form.dldStaffName2,
          vaccineActionAreas: form.vaccineActionAreas,
          vaccineObjective: form.vaccineObjective,
          statusId: 1,
        },
        barns: houseArray,
        animals: animalRes,
        deleteBarns: deleteBarn,
        deleteAnimals: delAnimalsRes,
      };
      // return
      MySwal.fire({
        text: `ต้องการบันทึก`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (value) => {
        if (value.isConfirmed) {
          try {
            const response = await API.addVaccinate(data);
            if (response.status == 200) {
              MySwal.fire({
                text: location.pathname.includes("edit")
                  ? `แก้ไข "บันทึกแผน/ผลการฉีดวัคซีนป้องกันโรคระบาดสัตว์ (กคร.5)" สำเร็จ`
                  : `สร้าง "บันทึกแผน/ผลการฉีดวัคซีนป้องกันโรคระบาดสัตว์ (กคร.5)" สำเร็จ`,
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then(async (result) => {
                history.push(`/passive_surv/vaccinate`);
              });
            }
          } catch (error) {
            dispatch(loading(false));
            console.log(error);
            const mgeError = error?.response?.data.errors[0];
            if (mgeError.includes("lotNo")) {
              MySwal.fire({
                text: "3.การฉีดวัคซีนรายตัว กรุณากรอกเลข Lot No.",
                icon: "warning",
                confirmButtonText: "ตกลง",
              });
            }
            if (error.response && error.response.status == 401) {
              dispatch(logout({ history }));
            } else {
              const textError = error.response?.data?.errors;
              const textAlert = location.pathname.includes("edit")
                ? `แก้ไข "บันทึกแผน/ผลการฉีดวัคซีนป้องกันโรคระบาดสัตว์ (กคร.5)" ไม่สำเร็จ`
                : `สร้าง "บันทึกแผน/ผลการฉีดวัคซีนป้องกันโรคระบาดสัตว์ (กคร.5)" ไม่สำเร็จ`;
              MySwal.fire({
                title: textAlert,
                text: textError,
                icon: "warning",
                confirmButtonText: "ตกลง",
              });
            }
          }
        } else {
          dispatch(loading(false));
        }
      });
    }
  };

  const renderTableAnimal = (data, indexAnm) => {
    return (
      <tr key={indexAnm} className="">
        <td>{data.house_buiding}</td>
        <td>{formJson.animalId[data.animalTypeId]}</td>
        <td>{data.animalId ? data.animalId : "-"}</td>
        <td>{data.name ? data.name : "-"}</td>
        <td>
          {data.genderType === "f"
            ? "ตัวเมีย"
            : data.genderType === "m"
            ? "ตัวผู้"
            : "ไม่ทราบ"}
        </td>
        <td>
          {data.age_year && `${data.age_year} ปี`}{" "}
          {data.age_month && `${data.age_month} เดือน`}
        </td>

        <td>
          {data.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="locationTypeName"
              classLabel="normal"
              selectValue={vaccineSelectValue}
              optionsList={vaccineList}
              handleChange={(value) => {
                setVaccineSelectValue([value]);
                setFormAnimal({
                  ...formAnimal,
                  vaccine_name: value.label,
                  vaccine_id: value.value,
                });
              }}
            />
          ) : data.vaccineTypeId ? (
            formJson.vaccineType[data.vaccineTypeId]
          ) : (
            "-"
          )}
        </td>

        <td>
          {data.checkEdit ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="text"
              idName="count"
              value={formAnimal.lotNo}
              star={false}
              placeholder="กรุณากรอก"
              handleChange={(value) =>
                setFormAnimal({ ...formAnimal, lotNo: value })
              }
              handleInvalid={() => {
                removeInvalid("lotNo");
              }}
              invalid={invalid.lotNo}
            />
          ) : data.lotNo ? (
            data.lotNo
          ) : (
            "-"
          )}
        </td>
        <td>
          <div className="d-flex justify-content-center ">
            {data.checkEdit ? (
              <button
                className="btn btn-success pt-1 px-2"
                onClick={async () => {
                  animalSave(data, indexAnm);
                }}
              >
                <img
                  className="icon-check"
                  src="/svg/btn-check.svg"
                  alt="check"
                />
              </button>
            ) : (
              <>
                <button
                  className="btn btn-add mr-2"
                  onClick={() => animalEdit(data, indexAnm)}
                  disabled={form.modeEditTable2}
                >
                  <img
                    className="icon-btn"
                    src="/svg/btn-edit.svg"
                    alt="edit"
                  />
                </button>
                <button
                  className="btn btn-del mr-2"
                  onClick={() =>
                    animalRemove(indexAnm, data.animalTypeId, data.lotNo)
                  }
                >
                  <img className="icon-btn" src="/svg/btn-del.svg" alt="del" />
                </button>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  };
  useEffect(() => {
    if (farmVaccination?.length > 0) {
      let dataDisable = [];
      farmVaccination.map((item, i) => {
        const totalAmt = parseInt(item.totalAmt);
        const sumAmt = parseInt(item.sumAmt);

        if (sumAmt > totalAmt) {
          dataDisable.push({ disableButton: true });
        } else {
          dataDisable.push({ disableButton: false });
        }
      });
      setDisableButton(dataDisable);
    }
  }, [farmVaccination]);

  const renderRowFarm = (item, i) => {
    return (
      <tr key={i} className="text-center">
        <td>
          {item.save ? (
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName={`house_${i}`}
              classLabel="normal"
              selectValue={item.barn}
              optionsList={houseList}
              handleChange={(value) => {
                item.barn = value;
                setHouseSelectValue(value);
                setFarmVaccination([...farmVaccination]);
              }}
            />
          ) : (
            item.barn?.label
          )}
        </td>
        <td>
          {item.save ? (
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName={`animaltype_${i}`}
              classLabel="normal"
              selectValue={item.animalType}
              optionsList={animalList}
              handleChange={(value) => {
                item.animalType = value;
                item.vaccineType = [];
                selectAnimal(value);
                setFarmVaccination([...farmVaccination]);
              }}
            />
          ) : (
            item.animalType?.label
          )}
        </td>
        <td>
          {item.save ? (
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName={`vaccineType_${i}`}
              classLabel="normal"
              selectValue={item.vaccineType}
              optionsList={vaccineTypeList}
              handleChange={(value) => {
                item.vaccineType = [value];
                setVaccineSelectValue(value);
                setFarmVaccination([...farmVaccination]);
              }}
            />
          ) : item.vaccineType.length > 0 ? (
            item.vaccineType.find((p, j) => j === 0)?.label
          ) : (
            vaccineTypeList.find((q, k) => q.value === item.vaccineType)?.label
          )}
        </td>
        <td>
          {item.save ? (
            <InputDatePicker
              classFormGroup="mb-0"
              title=""
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={item.vaccineDate}
              star={false}
              handleChange={(value) => {
                item.vaccineDate = new Date(value).getTime();
                setFarmVaccination([...farmVaccination]);
              }}
              minDate={form.operateDateFrom ? form.operateDateFrom : true}
              maxDate={form.operateDateTo ? form.operateDateTo : true}
            />
          ) : item.vaccineDate ? (
            helper.getDate(item.vaccineDate)
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.save ? (
            <InputText
              classFormGroup="mb-0"
              type="text"
              idName={`lotNo_${i}`}
              value={item.lotNo}
              star={false}
              placeholder="โปรดระบุ"
              handleChange={(value) => {
                item.lotNo = value;
                setFarmVaccination([...farmVaccination]);
              }}
            />
          ) : (
            item.lotNo || "-"
          )}
        </td>
        <td>
          {item.save ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="number"
              idName={`allVaccinatedAmt_${i}`}
              value={item.totalAmt}
              star={false}
              placeholder="ระบุจำนวน"
              min={item.sumAmt ? item.sumAmt + 1 : 0}
              handleChange={(value) => {
                item.totalAmt = value;
                setFarmVaccination([...farmVaccination]);
              }}
            />
          ) : (
            item.totalAmt || "-"
          )}
        </td>
        <td>
          {item.save ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="number"
              idName={`injected_staff_${i}`}
              value={item.vaccinated.injectStaffAmt}
              star={false}
              placeholder="ระบุจำนวน"
              min="0"
              handleChange={(value) => {
                var sum = Number(item.vaccinated.injectOwnerAmt || 0) + Number(value || 0)
                if  (sum <= item.totalAmt) {
                  item.vaccinated.injectStaffAmt = value;
                  item.sumAmt =
                    Number(item.vaccinated.injectStaffAmt) +
                    Number(item.vaccinated.injectOwnerAmt || 0);
                  setFarmVaccination([...farmVaccination]);
                }
              }}
            />
          ) : (
            item.vaccinated.injectStaffAmt || "-"
          )}
        </td>
        <td>
          {item.save ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="number"
              idName={`injected_owner_${i}`}
              value={item.vaccinated.injectOwnerAmt}
              star={false}
              placeholder="ระบุจำนวน"
              min="0"
              handleChange={(value) => {
                var sum = Number(item.vaccinated.injectStaffAmt || 0) + Number(value || 0)
                if  (sum <= item.totalAmt) {
                  item.vaccinated.injectOwnerAmt = value;
                  item.sumAmt =
                    Number(item.vaccinated.injectStaffAmt || 0) +
                    Number(item.vaccinated.injectOwnerAmt);
                  setFarmVaccination([...farmVaccination]);
                }
                
              }}
            />
          ) : (
            item.vaccinated.injectOwnerAmt || "-"
          )}
        </td>
        <td>
          {item.save ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="number"
              idName={`vaccinatedAnimalAmt_${i}`}
              value={item.sumAmt}
              star={false}
              placeholder="ระบุจำนวน"
              min="0"
              disabled={true}
              handleChange={(value) => {
                item.sumAmt = value;
                setFarmVaccination([...farmVaccination]);
              }}
            />
          ) : (
            item.sumAmt || "-"
          )}
        </td>
        <td>
          {item.save ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="number"
              idName={`pregnantAmt_${i}`}
              value={item.nonVaccinated.pregnantAmt}
              star={false}
              placeholder="ระบุจำนวน"
              min="0"
              handleChange={(value) => {
                item.nonVaccinated.pregnantAmt = value;
                setFarmVaccination([...farmVaccination]);
              }}
            />
          ) : (
            item.nonVaccinated.pregnantAmt || "-"
          )}
        </td>
        <td>
          {item.save ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="number"
              idName={`less4monthAmt_${i}`}
              value={item.nonVaccinated.less4monthAmt}
              star={false}
              placeholder="ระบุจำนวน"
              min="0"
              handleChange={(value) => {
                item.nonVaccinated.less4monthAmt = value;
                setFarmVaccination([...farmVaccination]);
              }}
            />
          ) : (
            item.nonVaccinated.less4monthAmt || "-"
          )}
        </td>
        <td>
          {item.save ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="number"
              idName={`notCatchAmt_${i}`}
              value={item.nonVaccinated.notCatchAmt}
              star={false}
              placeholder="ระบุจำนวน"
              min="0"
              handleChange={(value) => {
                item.nonVaccinated.notCatchAmt = value;
                setFarmVaccination([...farmVaccination]);
              }}
            />
          ) : (
            item.nonVaccinated.notCatchAmt || "-"
          )}
        </td>
        <td>
          {item.save ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="number"
              idName={`otherAmt_${i}`}
              value={item.nonVaccinated.otherAmt}
              star={false}
              placeholder="ระบุจำนวน"
              min="0"
              handleChange={(value) => {
                item.nonVaccinated.otherAmt = value;
                setFarmVaccination([...farmVaccination]);
              }}
            />
          ) : (
            item.nonVaccinated.otherAmt || "-"
          )}
        </td>
        <td>
          {item.save ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="number"
              idName={`ageVaccine_${i}`}
              value={item.ageVaccine}
              star={false}
              placeholder="ระบุจำนวน"
              min="0"
              handleChange={(value) => {
                item.ageVaccine = value;
                setFarmVaccination([...farmVaccination]);
              }}
            />
          ) : (
            item.ageVaccine || "-"
          )}
        </td>
        <td className="text-center">
          {item.save ? (
            <button
              className="btn btn-success pt-1 px-2"
              disabled={disableButton[i]?.disableButton}
              onClick={() => {
                item.save = false;
                const data = farmVaccination.map((item) => {
                  return { ...item, edit: false };
                });
                setFarmVaccination([...data]);
              }}
            >
              <img
                className="icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => {
                  item.save = true;
                  setFarmVaccination([...farmVaccination]);
                  selectAnimal(item.animalType[0]);
                }}
                disabled={item.edit}
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del "
                onClick={() => {
                  const data = [...deleteBarn];
                  data.push(farmVaccination[i].id);
                  setDeleteBarn(data);

                  farmVaccination.splice(i, 1);
                  setFarmVaccination([...farmVaccination]);
                }}
                disabled={item.edit}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </>
          )}
          {disableButton[i]?.disableButton && (
            <div className="invalid-error">
              กรุณากรอกสัตว์ร่วมฝูงมากกว่ารวมจำนวนสัตว์ที่ฉีดวัคซีน
            </div>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <div className="pl-4 bg-white ml30 d-flex">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2 ">
          <Link to={`/passive_surv/vaccinate`}>
            <button className="btn btn-danger" onClick={() => toCancel()}>
              ยกเลิก
            </button>
          </Link>
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-success"
            onClick={() => toSaveVac()}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`edit`)
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxHead title="ตัวกรอง" classLabel="">
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="d-flex align-items-center">
                    <InputText
                      type="text"
                      title="เลข Outbreak ID"
                      idName="outbreakCode"
                      value={form.outbreakCode}
                      star={true}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      disabled
                      handleChange={(value) =>
                        setForm({ ...form, outbreakCode: value })
                      }
                    />
                    <button
                      className="btn btn-add mt-auto mb-3 ml-2"
                      onClick={() => setShowOutbreakSearch(true)}
                    >
                      <img
                        className="icon-btn "
                        src="/svg/btn-edit.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                </Col>

                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="d-flex align-items-center">
                    <InputText
                      type="text"
                      title="เลข case ID"
                      idName="caseCode"
                      value={form.caseCode}
                      star={false}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      disabled
                      handleChange={(value) =>
                        setForm({ ...form, caseCode: value })
                      }
                    />
                    <button
                      className="btn btn-add mt-auto mb-3 ml-2 "
                      onClick={() => setShowCaseIdSearch(true)}
                    >
                      <img
                        className="icon-btn "
                        src="/svg/btn-edit.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={4} sm={12} xs={12}>
                  <label className="mb-0 normal">หน่วยงานที่แจ้งโรค </label>
                  <div className="d-flex align-items-center">
                    {form.orgName ? (
                      <>
                        <button
                          className="btn btn-primary pt-2 px-3"
                          onClick={() => setShowOrgModal(true)}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="ml-2">{form.orgName}</div>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary p-2 d-flex align-items-center"
                        onClick={() => setShowOrgModal(true)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        <p className="mb-0 ml-2">{t("search")}</p>
                      </button>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={3} sm={6} xs={6}>
                  <div>
                    <InputGroupText
                      type="text"
                      title="รหัสฟาร์ม"
                      imgSrc={"/svg/search.svg"}
                      imgAlt="search-icon"
                      idName="farmId"
                      value={form.farmId}
                      star={false}
                      placeholder="ค้นหาฟาร์ม"
                      classLabel="normal"
                      handleChange={(value) =>
                        setForm({ ...form, farmId: value })
                      }
                      disabled
                    />
                  </div>
                </Col>

                <Col lg={2} md={3} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    <button
                      className="btn btn-darkblue w-100"
                      onClick={() =>
                        history.push("/farm_registration/save-data")
                      }
                    >
                      <img
                        className="mr-2 mb-1 "
                        src={"/svg/menu_5.svg"}
                        alt="menu-icon"
                      />
                      ขึ้นทะเบียนฟาร์ม
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ชื่อเจ้าของสัตว์"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputMask
                    mask="99-9999-9999"
                    title="หมายเลขโทรศัพท์"
                    idName="phone"
                    value={form.phone}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, phone: value.replace(/-/g, "") })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="บ้านเลขที่"
                    idName="homeNo"
                    value={form.homeNo}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, homeNo: value })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    disabled={true}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    disabled={true}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    disabled={true}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="หมู่ที่"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="villageId"
                    classLabel="normal"
                    disabled={true}
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({ ...form, villageId: value.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxHead>

          <BoxCard classNameBox="mt-3" title="1. การฉีดวัคซีน">
            <Row className="mb-2 pl-2  ">
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="วันที่ปฏิบัติงาน"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.operateDateFrom}
                  star={false}
                  handleChange={(value) =>
                    setForm({ ...form, operateDateFrom: value })
                  }
                  maxDate={true}
                />
              </Col>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.operateDateTo}
                  star={false}
                  handleChange={(value) =>
                    setForm({ ...form, operateDateTo: value })
                  }
                  minDate={form.operateDateFrom}
                  maxDate={"afterSelectDate"}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputText
                  type="text"
                  title="ชื่ออาสาฯ ผู้ปฏิบัติงาน"
                  idName="dldStaffName1"
                  value={form.dldStaffName1}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) =>
                    setForm({ ...form, dldStaffName1: value })
                  }
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputText
                  type="text"
                  title="ชื่อสัตวแพทย์ผู้ควบคุม"
                  idName="dldStaffName2"
                  value={form.dldStaffName2}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) =>
                    setForm({ ...form, dldStaffName2: value })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col lg={2} md={2} sm={12} className="d-flex align-items-center">
                <p className="mb-0">วัตถุประสงค์การฉีดวัคซีน : </p>
              </Col>
              <Col lg={10} md={10} sm={12} className="w-100">
                <div className="d-flex align-items-center flex-wrap ">
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      name="vaccineObjective"
                      id="isVaccineObjective"
                      value={1}
                      checked={form.vaccineObjective == 1}
                      label="เพื่อป้องกันโรค (รอบรณรงค์)"
                      onChange={(e) => {
                        setForm({
                          ...form,
                          vaccineObjective: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mr-2">
                    <Form.Check
                      inline
                      type="radio"
                      name="vaccineObjective"
                      id="notVaccineObjective"
                      value={2}
                      checked={form.vaccineObjective == 2}
                      label="เพื่อควบคุมโรค"
                      onChange={(e) => {
                        setForm({
                          ...form,
                          vaccineObjective: e.target.value,
                        });
                      }}
                    />
                  </div>
                  
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={2} md={2} sm={12} className="d-flex align-items-center">
                <p className="mb-0">พื้นที่การฉีดวัคซีน : </p>
              </Col>
              <Col lg={10} md={10} sm={12} className="w-100">
                <div className="d-flex align-items-center flex-wrap ">
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      name="vaccineActionAreas"
                      id="isVaccineActionAreas"
                      value={1}
                      checked={form.vaccineActionAreas == 1}
                      label="พื้นที่ไม่เกิดโรค"
                      onChange={(e) => {
                        setForm({
                          ...form,
                          vaccineActionAreas: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mr-2">
                    <Form.Check
                      inline
                      type="radio"
                      name="vaccineActionAreas"
                      id="notVaccineActionAreas"
                      value={2}
                      checked={form.vaccineActionAreas == 2}
                      label="รอบพื้นที่เกิดโรค"
                      onChange={(e) => {
                        setForm({
                          ...form,
                          vaccineActionAreas: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mr-2">
                    <Form.Check
                      inline
                      type="radio"
                      name="vaccineActionAreas"
                      id="animal"
                      value={3}
                      checked={form.vaccineActionAreas == 3}
                      label="สัตว์เคลื่อนย้าย"
                      onChange={(e) => {
                        setForm({
                          ...form,
                          vaccineActionAreas: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="2. การฉีดวัคซีนรายฟาร์ม"
            headRight={buttonAddFarmItem()}
          >
            <div className="table-responsive-2">
              <Table className="table fitwidth table-striped table-bordered font14">
                <thead>
                  <tr className="text-center">
                    <th scope="col" rowSpan={2}>
                      โรงเรือน
                    </th>
                    <th scope="col" rowSpan={2}>
                      ชนิดสัตว์
                    </th>
                    <th scope="col" rowSpan={2}>
                      ชนิดวัคซีน
                    </th>
                    <th scope="col" rowSpan={2}>
                      วันที่ฉีดวัคซีน
                    </th>
                    <th scope="col" rowSpan={2}>
                      Lot No.
                    </th>
                    <th scope="col" rowSpan={2}>
                      จำนวนทั้งหมด(สัตว์ร่วมฝูง)
                    </th>
                    <th scope="col" colSpan={2}>
                      จำนวนสัตว์ที่ฉีดวัคซีน{" "}
                    </th>
                    <th scope="col" rowSpan={2}>
                      รวมจำนวนสัตว์ที่ฉีดวัคซีน
                    </th>
                    <th scope="col" colSpan={4}>
                      จำนวนสัตว์ที่ไม่ได้รับวัคซีน (จำนวนตัว)
                    </th>
                    <th scope="col" rowSpan={2}>
                      อายุสัตว์ที่ทำวัคซีน (สัปดาห์)
                    </th>
                    <th scope="col" rowSpan={2}>
                      จัดการ
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">ฉีดโดย จนท</th>
                    <th scope="col">ฉีดโดยเจ้าของ</th>
                    <th scope="col">ท้อง</th>
                    <th scope="col"> {"<"} 4 เดือน</th>
                    <th scope="col">จับไม่ได้</th>
                    <th scope="col">อื่นๆ</th>
                  </tr>
                </thead>
                <tbody>
                  {farmVaccination && farmVaccination.length > 0 ? (
                    farmVaccination.map((item, i) => {
                      return renderRowFarm(item, i);
                    })
                  ) : (
                    <DataTableNodata colSpan={15} />
                  )}
                </tbody>
              </Table>
            </div>
          </BoxCard>

          <BoxCard
            title="3.การฉีดวัคซีนรายตัว"
            classNameBox="mt-3 "
            headRight={buttonAddAnimal()}
          >
            <div className="table-responsive">
              <Table className="table table-striped table-bordered my-2">
                <thead>
                  <tr className="">
                    <th scope="col">{t("house_buiding")}</th>
                    <th scope="col">ชนิดสัตว์</th>
                    <th scope="col">หมายเลข</th>
                    <th scope="col">ชื่อ</th>
                    <th scope="col">เพศ</th>
                    <th scope="col">อายุ</th>
                    <th scope="col">ชนิดวัคซีน</th>
                    <th scope="col">Lot No.</th>
                    <th scope="col-2" className="text-center">
                      จัดการ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {form.res_animals && form.res_animals.length > 0 ? (
                    form.res_animals.map((animal, a) => {
                      return renderTableAnimal(animal, a);
                    })
                  ) : (
                    <DataTableNodata colSpan={9} />
                  )}
                </tbody>
              </Table>
            </div>
          </BoxCard>
        </div>
      </div>

      {showOutbreakSearch && (
        <ModalOutbreakSearch
          show={showOutbreakSearch}
          mode={form.modeOutbreak}
          close={() => setShowOutbreakSearch(false)}
          save={(value) =>
            setForm({
              ...form,
              outbreakCode: value.obCode,
              outbreakId: value.obId,
            })
          }
        />
      )}
      <ModalFarmSearch
        show={showFarmSearch}
        mode={form.modeFarmSearch}
        close={() => setShowFarmSearch(false)}
        save={(value) => setForm({ ...form, farmId: value })}
      />
      {showOrgModal && (
        <ModalOrganizes
          show={showOrgModal}
          close={() => setShowOrgModal(false)}
          toSaveOrg={(data) => setOrganizes(data)}
        />
      )}

      {showAnmByOne && (
        <ModalAnimalByOne
          show={showAnmByOne}
          close={() => setShowAnmByOne(false)}
          buidingList={houseList}
          farmId={form.farmId}
          save={(data) => {
            addAnimalByOne(data);
          }}
          mode="vaccine"
        />
      )}
    </div>
  );
}

export default Vaccinate_Save;
