import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loading } from "../redux/actions/loadingAction";
import BoxCard from "./BoxCard";
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import ModalAddAnimal from "./Modal.AddAnimal";
import { useTranslation } from "react-i18next";
import * as API from "../utils/apis";
import * as helper from "../utils/helper";

function ModalAnimalByOne({ show, close, save, buidingList, farmId, mode }) {
  const { t, i18n } = useTranslation(["common", "footer"]);
  const dispatch = useDispatch();

  const initialForm = {
    animals: [],

    house_buiding_id: "",
    animalTypeId: "",
    animalTypeCode: "",
    name: "",
    statusId: "",

    anmChooseList: [],
    animals_res: [], //ใช้กับบันทึกวัคซีน

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    modeAnimal: "",
  };
  const [form, setForm] = useState(initialForm);

  const initialFormJson = {
    vaccineType: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initShowAddAnimal = false;
  const [showAddAnimal, setShowAddAnimal] = useState(initShowAddAnimal);

  const initAnimalSelectValue = [];
  const [animalSelectValue, setAnimalSelectValue] = useState(
    initAnimalSelectValue
  );
  const selectAnimalList = [];
  const [animalList, setAnimalList] = useState(selectAnimalList);

  const initBuidingSelectValue = [];
  const [buidingSelectValue, setBuidingSelectValue] = useState(
    initBuidingSelectValue
  );

  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);
  const [anmChooseListCheck, setAnmChooseListCheck] = useState("");

  useEffect(async () => {
    await getAnimal();
    await getMasterJson();
  }, []);

  useEffect(async () => {
    if (mode == "vaccine") {
      await getAnimalVaccineFarmById();
    } else {
      await getAnimalFarmById();
    }
  }, [show]);

  const getAnimalFarmById = async () => {
    const params = await {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",

      farmId: farmId ? farmId : "",
      barnId: form.house_buiding_id ? form.house_buiding_id : "",
      animalTypeId: form.animalTypeId ? form.animalTypeId : "",
      animalCode: form.animalTypeCode ? form.animalTypeCode : "",
      name: form.name ? form.name : "",
      statusId: form.status ? form.status : "",
    };

    let dataRes;
    try {
      const response = await API.getAnimalFarmById(params);
      const data = await response.data;
      if (response.status == 200) {
        dataRes = data.content;
      }
    } catch (error) {
      console.log(error);
    }

    let animalList = [];
    if (dataRes && dataRes.length > 0) {
      dataRes.map((item, i) => {
        animalList.push({
          id: item.id,
          house_buiding: item.barnName,
          house_buiding_id: item.barnId,
          animalTypeName: item.animalTypeName,
          animalTypeId: item.animalTypeId,
          name: item.name,
          genderType: item.sex,
          gender:
            item.sex === "m"
              ? "ตัวผู้"
              : item.sex === "f"
              ? "ตัวเมีย"
              : "ไม่ทราบ",
          age_year: item.ageYear,
          age_month: item.ageMonth,
        });
      });
      setForm({ ...form, animals: animalList, animals_res: dataRes });
    } else {
      setForm({ ...form, animals: animalList, animals_res: dataRes });
    }
  };

  const getAnimalVaccineFarmById = async () => {
    const params = await {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",

      farmId: farmId ? farmId : "",
      barnId: form.house_buiding_id ? form.house_buiding_id : "",
      animalTypeId: form.animalTypeId ? form.animalTypeId : "",
      animalCode: form.animalTypeCode ? form.animalTypeCode : "",
      name: form.name ? form.name : "",
      statusId: form.status ? form.status : "",
    };

    let dataRes;
    try {
      const response = await API.getAnimalFarmById(params);
      const data = await response.data;
      if (response.status == 200) {
        dataRes = data.content;
      }
    } catch (error) {
      console.log(error);
    }

    let animalList = [];
    if (dataRes && dataRes.length > 0) {
      dataRes.map((item, i) => {
        if (item.vaccine && item.vaccine.length > 0) {
          item.vaccine.map((itemV, v) => {
            animalList.push({
              id: item.id,
              house_buiding: item.barnName,
              house_buiding_id: item.barnId,
              animalTypeName: item.animalTypeName,
              animalTypeId: item.animalTypeId,
              name: item.name,
              genderType: item.sex,
              gender:
                item.sex === "m"
                  ? "ตัวผู้"
                  : item.sex === "f"
                  ? "ตัวเมีย"
                  : "ไม่ทราบ",
              age_year: item.ageYear,
              age_month: item.ageMonth,
              animalId: item.animalCode,
              vaccineTypeId: itemV.vaccineTypeId,
              lotNo: itemV.lotNo,
              checkEdit: false,
            });
          });
        } else {
          animalList.push({
            id: item.id,
            house_buiding: item.barnName,
            house_buiding_id: item.barnId,
            animalTypeName: item.animalTypeName,
            animalTypeId: item.animalTypeId,
            name: item.name,
            genderType: item.sex,
            gender:
              item.sex === "m"
                ? "ตัวผู้"
                : item.sex === "f"
                ? "ตัวเมีย"
                : "ไม่ทราบ",
            age_year: item.ageYear,
            age_month: item.ageMonth,
            animalId: item.animalCode,
            vaccineTypeId: "",
            lotNo: "",
            checkEdit: false,
          });
        }
      });
      setForm({ ...form, animals: animalList, animals_res: dataRes });
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMasterJson = async () => {
    const params = await ["vaccineType"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          vaccineType: data.vaccineType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const toSearch = () => {
    getAnimalFarmById();
  };

  const toSave = async () => {
    await save(form);
    getAnimalFarmById();
    handleClose();
  };

  const addAnimal = async (data) => {
    if (form.modeAnimal == "addByOne") {
      let vaccine;
      if (data.choose_disease && data.choose_disease.length > 0) {
        vaccine = data.choose_disease.map((disease, d) => {
          return {
            diseaseTypeId: disease.diseaseTypeId,
            vaccineTypeId: disease.vaccineTypeId,
            vaccineDate: disease.last_date
              ? helper.getDate(disease.last_date)
              : "",
            lotNo: disease.lotNo,
            injectBy: disease.operator,
            injectOther: disease.other_vaccine,
            diseaseHistory: disease.had_disease,
          };
        });
      }
      const dataAddAnimal = {
        farmId: farmId,
        animals: [
          {
            barnId: data.house_buiding_id,
            animalTypeId: data.animalTypeId,
            animalCode: data.animalId,
            statusId: 1,
            deadDate: data.date_dead,
            deadCause: data.cause_death_type,
            deadReason: data.cause_death_type_other,
            name: data.name,
            sex: data.genderType,
            ageYear: data.age_year,
            ageMonth: data.age_month,
            nid: data.no_animal_ear,
            microchip: data.no_microchip,
            species: data.breed,
            purposeId: data.purpose_raising,
            nidCode: data.no_nid,
            husbandryId: data.style_raising,
            vaccinationStatus: data.is_vaccine,
            vaccine: vaccine ? vaccine : [],
          },
        ],
      };

      try {
        const response = await API.postFarmAddAnimal(dataAddAnimal);
        if (response.status === 200) {
          if (mode == "vaccine") {
            await getAnimalVaccineFarmById();
          } else {
            await getAnimalFarmById();
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleClose = () => {
    close();
    setForm(initialForm);
    setBuidingSelectValue([]);
    setAnimalSelectValue([]);
    setStatusSelectValue([]);
  };

  const buttonAddAnimal = () => {
    return (
      <button
        className="btn btn-add text-white"
        onClick={() => {
          setShowAddAnimal(true);
          setForm({ ...form, modeAnimal: "addByOne" });
        }}
      >
        <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูลสัตว์
      </button>
    );
  };

  useEffect(() => {
    if (anmChooseListCheck?.length) {
      if (form?.anmChooseList?.length < anmChooseListCheck?.length) {
        let dataList = [];

        anmChooseListCheck.map((item) => {
          if (form.anmChooseList.includes(item.id)) {
            dataList = [...form.anmChooseList];
          } else {
            dataList = [...form.anmChooseList, item.id];
          }
        });
        setForm({ ...form, anmChooseList: dataList });
      } else if (form?.anmChooseList?.length > anmChooseListCheck?.length) {
        let dataArr = [];

        form.anmChooseList.map((item) => {
          anmChooseListCheck.map((anm) => {
            if (anm.id === item) {
              dataArr.push(item);
            }
          });
        });
        setForm({ ...form, anmChooseList: dataArr });
      }
    }
  }, [anmChooseListCheck]);

  const setChooseAnm = (e, indexAnm) => {
    if (anmChooseListCheck?.length > 0) {
      const dataAnm = [...anmChooseListCheck];
      dataAnm.map((item, idx) => {
        if (item.id === e.target.id && item.index === indexAnm) {
          item.checked = e.target.checked;
          dataAnm.splice(idx, 1);

          setAnmChooseListCheck(dataAnm);
        } else {
          setAnmChooseListCheck([
            ...anmChooseListCheck,
            {
              index: indexAnm,
              id: e.target.id,
              checked: e.target.checked,
            },
          ]);
        }
      });
    } else {
      setAnmChooseListCheck([
        {
          index: indexAnm,
          id: e.target.id,
          checked: e.target.checked,
        },
      ]);
    }
  };

  const renderTableAnimal = (data, indexAnm) => {
    return (
      <tr key={indexAnm} className="">
        <td>
          <label htmlFor={data.id} className="normal mb-0 cursor-p">
            {data.house_buiding || "-"}
          </label>
        </td>
        <td>
          <label htmlFor={data.id} className="normal mb-0 cursor-p">
            {data.animalTypeName || "-"}
          </label>
        </td>
        <td>
          <label htmlFor={data.id} className="normal mb-0 cursor-p">
            {data.animalId || "-"}
          </label>
        </td>
        <td>
          <label htmlFor={data.id} className="normal mb-0 cursor-p">
            {data.name || "-"}
          </label>
        </td>
        <td>
          <label htmlFor={data.id} className="normal mb-0 cursor-p">
            {data.gender || "-"}
          </label>
        </td>
        <td>
          <label htmlFor={data.id} className="normal mb-0 cursor-p">
            {data.age_year && `${data.age_year} ปี`}{" "}
            {data.age_month && `${data.age_month} เดือน`}
          </label>
        </td>
        {mode == "vaccine" && (
          <td>{formJson.vaccineType[data.vaccineTypeId] || "-"}</td>
        )}
        {mode == "vaccine" && <td>{data.lotNo || "-"}</td>}
        <td>
          <div className="d-flex justify-content-center ">
            <Form.Check
              inline
              type="checkbox"
              className="m-0 p-0"
              name="anm_id"
              id={data.id}
              value={data.id}
              onChange={(e) => setChooseAnm(e, indexAnm)}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <Modal show={show} size="lg" onHide={() => handleClose(false)} centered>
      <Modal.Header closeButton className="pb-2 underline">
        <Modal.Title>
          <h5>
            <strong>เพิ่มข้อมูลสัตว์รายตัว</strong>
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BoxCard
          title="ค้นหาสัตว์รายตัว"
          classNameBox="my-3"
          headRight={buttonAddAnimal()}
        >
          <Row className="py-2">
            <Col lg={2} md={4} sm={6}>
              <InputSelect
                title={t("house_buiding")}
                classFormGroup="mb-0 w-100"
                star={false}
                placeholder="กรุณาเลือก"
                idName="house_buiding"
                classLabel="normal"
                selectValue={buidingSelectValue}
                optionsList={buidingList}
                handleChange={(value) => {
                  setBuidingSelectValue([value]);
                  setForm({
                    ...form,
                    house_buiding: value.label,
                    house_buiding_id: value.value,
                  });
                }}
              />
            </Col>
            <Col lg={2} md={4} sm={6}>
              <InputSelect
                title={t("animal_type")}
                classFormGroup="mb-0 w-100"
                star={false}
                placeholder="กรุณาเลือก"
                idName="animalTypeName"
                classLabel="normal"
                selectValue={animalSelectValue}
                optionsList={animalList}
                handleChange={(value) => {
                  setAnimalSelectValue([value]);
                  setForm({
                    ...form,
                    animalTypeId: value.value,
                    animalTypeName: value.label,
                  });
                }}
              />
            </Col>
            <Col lg={2} md={4} sm={6}>
              <InputText
                classFormGroup="mb-0"
                type="text"
                title={"รหัสสัตว์"}
                idName="animalTypeCode"
                value={form.animalTypeCode}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) =>
                  setForm({ ...form, animalTypeCode: value })
                }
              />
            </Col>
            <Col lg={2} md={4} sm={6}>
              <InputText
                classFormGroup="mb-0"
                type="text"
                title={"ชื่อ"}
                idName="name"
                value={form.name}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) => setForm({ ...form, name: value })}
              />
            </Col>
            <Col lg={2} md={4} sm={6}>
              <InputSelect
                title={t("status")}
                classFormGroup="mb-0 w-100"
                star={false}
                placeholder="กรุณาเลือก"
                idName="status"
                classLabel="normal"
                selectValue={statusSelectValue}
                optionsList={statusList}
                handleChange={(value) => {
                  setStatusSelectValue([value]);
                  setForm({ ...form, status: value.value });
                }}
              />
            </Col>
            <Col lg={2} md={4} sm={6} className="d-flex mt-auto">
              <div className="mt-2">
                <button
                  className="btn btn-success px-5"
                  onClick={() => toSearch()}
                >
                  {t("search")}
                </button>
              </div>
            </Col>
          </Row>
        </BoxCard>

        <div className="table-responsive">
          <table className="table sameWidth table-striped table-bordered">
            <thead>
              <tr className="">
                <th scope="col">{t("house_buiding")}</th>
                <th scope="col">ชนิดสัตว์</th>
                <th scope="col">หมายเลข</th>
                <th scope="col">ชื่อ</th>
                <th scope="col">เพศ</th>
                <th scope="col">อายุ</th>
                {mode == "vaccine" && <th scope="col">ชนิดวัคซีน</th>}
                {mode == "vaccine" && <th scope="col">Lot No.</th>}
                <th scope="col" className="text-center">
                  จัดการ
                </th>
              </tr>
            </thead>
            <tbody>
              {form.animals &&
                form.animals.length > 0 &&
                form.animals.map((item, i) => (
                  <React.Fragment key={i}>
                    {renderTableAnimal(item, i)}
                  </React.Fragment>
                ))}

              {form.animals && form.animals.length > 0 ? (
                ""
              ) : (
                <td
                  scope="col"
                  colSpan={mode == "vaccine" ? 9 : 7}
                  className="text-center font14"
                >
                  {t("no_data")}
                </td>
              )}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-end p-2">
          <button className="btn btn-success px-4" onClick={() => toSave()}>
            บันทึก
          </button>
        </div>
      </Modal.Body>

      {showAddAnimal && (
        <ModalAddAnimal
          show={showAddAnimal}
          mode={form.modeAnimal}
          dataEdit={form.dataAnimalEdit}
          farmId={farmId}
          close={() => setShowAddAnimal(false)}
          save={(value) => {
            if (form.modeAnimal == "addByOne") {
              addAnimal(value);
            }
          }}
          buidingList={buidingList}
        />
      )}
    </Modal>
  );
}

export default ModalAnimalByOne;
