import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import { useHistory } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputText from "../../../components/InputText";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTableNodata from "../../../components/DataTableNodata";
import DataTable from "../../../components/DataTable";
import ModalMenuSystem from "../../../components/Data_Management/Modal.MenuSystem";

const MySwal = withReactContent(Swal);

function MenuSystem() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/master-data/animal-type", name: "Master Data" },
    { path: "active", name: "เมนู" },
  ];

  const initialForm = {
    //data to save
    menuId: "",
    createBy: "",
    createDate: "",
    icon: "",
    moduleName: "",
    menuName: "",
    orderIndex: 1,
    statusId: "",
    updateBy: "",
    updateDate: "",
    url: "",

    idEdit: "",
    mode: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "orderIndex",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "orderIndex",
        name: "ลำดับ",
        width: "50px",
        sortFlg: true,
      },
      {
        sortBy: "moduleId",
        name: "โมดูล",
        sortFlg: true,
      },
      {
        sortBy: "name",
        name: "ชื่อเมนู",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };

  const [form, setForm] = useState(initialForm);
  //Menu
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [isClearSearch, setIsClearSearch] = useState(false);

  useEffect(() => {
    getMenu();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getMenu = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getMenu(
        "",
        "",
        "",
        form.page,
        form.limit,
        form.menuName,
        form.sortBy,
        form.sortDir,
        form.moduleName
      );
      const data = await response?.data;
      let menuArray = [];
      if (response.status === 200) {
        // console.log("menu", data)
        data.content.filter((element) => {
          menuArray.push({
            value: element.id,
            moduleName: element.moduleName,
            label: element.name,
            statusId: element.statusId,
          });
        });
        setMenuList(menuArray);
        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getFilter = (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const buttonAddMenu = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddMenu()}
          disabled={user?.userGroup.mcreate === "n"}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่ม
        </button>
      </div>
    );
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setIsClearSearch(!isClearSearch);
  };
  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const toAddMenu = () => {
    setForm({ ...form, mode: "add", idEdit: "" });
    setShowMenu(true);
  };

  const editModalMenu = (id) => {
    setForm({ ...form, mode: "edit", idEdit: id });
    setShowMenu(true);
  };

  const delMenu = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบ `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteMenu(id);
          if (response?.status === 200) {
            MySwal.fire({
              text: `ลบสำเร็จ`,
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then(async (result) => {
              await getMenu();
            });
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const addSaveMenu = (data) => {
    const dataMenu = {
      moduleId: data.menu.moduleId,
      name: data.menu.menuName,
      url: data.menu.url,
      icon: data.menu.icon ? data.menu.icon : "empty.png",
      orderIndex: data.menu.orderIndex ? data.menu.orderIndex : "",
      statusId: data.menu.statusId,
    };

    MySwal.fire({
      text: `ต้องการบันทึก `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response =
            data.mode === "add"
              ? await API.saveMenu(dataMenu)
              : await API.updateMenu(data.menu.menuId, dataMenu);
          if (response?.status === 200) {
            setShowMenu(false);
            await getMenu();
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTableMenu = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i + item.label + item.moduleName} className="text-center">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item.moduleName || "-"}</td>
          <td>{item.label || "-"}</td>
          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editModalMenu(item.value)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delMenu(item.value)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={4} />;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputText
                  type="text"
                  title="โมดูล"
                  idName="title"
                  value={form.moduleName}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) => {
                    setForm({ ...form, moduleName: value });
                  }}
                />
              </Col>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputText
                  type="text"
                  title="ชื่อเมนู"
                  idName="title"
                  value={form.menuName}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) => {
                    setForm({ ...form, menuName: value });
                  }}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="รายการเมนู"
            classNameBox="mt-4"
            headRight={buttonAddMenu()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTableMenu(menuList)}
            </DataTable>
          </BoxCard>
          {showMenu && (
            <ModalMenuSystem
              show={showMenu}
              close={() => setShowMenu(false)}
              save={(value) => addSaveMenu(value)}
              mode={form.mode}
              idEdit={form.idEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default MenuSystem;
