import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";

import ModalMap from "../../components/Modal.Map";
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { useMasterData } from "../../components/hooks/useMasterData";

import * as API from "../../utils/apis";

function ModalProvinces({ show, close, save, mode, idEdit }) {
  const user = useSelector((state) => state.login.result);
  const initialForm = {
    provinceName: "",
    provinceNameEn: "",
    provinceId: "",
    status: "",
    createDate: "",
    updateDate: "",

    latitude: "",
    longitude: "",
    modeMap: "add",

    zone: "",
    zoneId: "",

    limit: 100,
    page: 1,
    geoId: "",
    geoName: "",
    sortDir: "DESC",
    sortBy: "id",

    checkShow: false,
  };
  const initInvalidMsg = {
    province_name: "",
    province_name_en: "",
    status: "",
    geo: "",
    zone: "",
  };
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const initStatusSelectValue = [];

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const initShowMap = false;
  const [showMap, setShowMap] = useState(initShowMap);

  const [form, setForm] = useState(initialForm);
  const [invalid, setInvalid] = useState(initInvalidMsg);
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const [statusList, setStatusList] = useState(selectStatusList);

  const initGeoIdSelectValue = [];
  const [geoIdSelectValue, setGeoIdSelectValue] =
    useState(initGeoIdSelectValue);
  const selectGeoIdList = [];
  const [geoIdList, setGeoList] = useState(selectGeoIdList);

  const initZoneLivestockIdSelectValue = [];
  const [zoneLivestockIdSelectValue, setZoneLivestockIdSelectValue] = useState(
    initZoneLivestockIdSelectValue
  );
  const selectZoneLivestockIdList = [];
  const [zoneLivestockIdList, setZoneLivestockList] = useState(
    selectZoneLivestockIdList
  );

  useMasterData("zoneLivestock", zoneLivestockIdList, setZoneLivestockList);

  useEffect(() => {
    if (mode === "add") {
      setForm(initialForm);
      setStatusSelectValue([]);
      setGeoIdSelectValue([]);
      setZoneLivestockIdSelectValue([]);
    }
  }, [mode]);

  useEffect(() => {
    getGeography();
    getZoneLivestock();
  }, []);

  useEffect(() => {
    if (mode === "edit" && form.provinceId !== idEdit && show) {
      getProvincesById(idEdit);
    }
  }, [show, geoIdList, zoneLivestockIdList]);

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if (form.provinceName === "") {
      addInvalid("province_name", "โปรดระบุชื่อจังหวัดภาษาไทย");
      validated = false;
    }

    if (form.provinceNameEn === "") {
      addInvalid("province_name_en", "โปรดระบุชื่อจังหวัดภาษาอังกฤษ");
      validated = false;
    }

    if (form.geoId === "") {
      addInvalid("geo", "โปรดระบุข้อมูลภูมิภาค");
      validated = false;
    }

    if (form.zoneId === "") {
      addInvalid("zone", "โปรดระบุข้อมูลเขตปศุสัตว์");
      validated = false;
    }

    if (form.status === "") {
      addInvalid("status", "กรุณาเลือกสถานะ");
      validated = false;
    }

    return validated;
  };

  const handleClose = () => {
    close();
    setInvalid(initInvalidMsg);
    setForm(initialForm);
    setStatusSelectValue([]);
  };

  const toConfirm = async () => {
    if (validate()) {
      await save({ provinces: form, mode: mode });
    }
  };

  const getProvincesById = async (id) => {
    let dataPrvn;
    try {
      const response = await API.getProvinceById(id);
      const data = await response?.data;
      if (response?.status === 200) {
        dataPrvn = data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response?.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (mode === "edit" && dataPrvn.statusId) {
      let statusSelect = await statusList.find(
        (item) => item.value === dataPrvn.statusId
      );
      let geoSelect = await geoIdList.find(
        (item) => item.value === dataPrvn.geoId
      );
      let zoneSelect = await zoneLivestockIdList.find(
        (item) => item.value === dataPrvn.zoneId
      );

      setStatusSelectValue(statusSelect);
      setGeoIdSelectValue(geoSelect);
      setZoneLivestockIdSelectValue(zoneSelect);
    }

    setForm({
      ...form,
      provinceId: dataPrvn.id,
      provinceName: dataPrvn.name,
      provinceNameEn: dataPrvn.nameEn,
      status: dataPrvn.statusId,
      geoId: dataPrvn.geoId,
      zoneId: dataPrvn.zoneId,
      latitude: dataPrvn.latitude,
      longitude: dataPrvn.longitude,
      createDate: dataPrvn.createDate,
      updateDate: dataPrvn.updateDate,
    });
  };

  const toMap = () => {
    setShowMap(true);
    if (location.pathname.includes("edit")) {
      setForm({ ...form, modeMap: "edit" });
    } else {
      setForm({ ...form, modeMap: "add" });
    }
  };

  const getGeography = async () => {
    try {
      const response = await API.getGeography(
        form.geoId,
        form.geoStatusId,
        "",
        form.sortBy,
        form.sortDir,
        form.page,
        form.limit
      );
      const data = await response.data;
      if (response.status === 200) {
        let geoArray = [];
        data.content.forEach((element) => {
          geoArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setGeoList(geoArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock();
      const data = await response.data;
      if (response.status === 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal show={show} size="lg" onHide={() => handleClose()} centered>
      <Modal.Header closeButton className="pb-2 ">
        <Modal.Title className="mt-2">
          <h5>
            <strong>เพิ่มข้อมูลจังหวัด</strong>
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <div className="">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item tab">
              <a
                className="nav-link active"
                id="data-tab"
                data-toggle="tab"
                href="#data"
                role="tab"
                aria-controls="data"
                aria-selected="true"
              >
                ข้อมูลพื้นฐาน
              </a>
            </li>
            <li
              className={
                invalid.status ? "nav-item-invalid tab" : "nav-item tab"
              }
            >
              <a
                className="nav-link"
                id="status-tab"
                data-toggle="tab"
                href="#status"
                role="tab"
                aria-controls="status"
                aria-selected="false"
              >
                สถานะ
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active card-tab"
              id="data"
              role="tabpanel"
              aria-labelledby="data-tab"
            >
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่อจังหวัดภาษาไทย"
                    idName="province_name"
                    value={form.provinceName}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, provinceName: value, checkShow: true })
                    }
                    handleInvalid={() => removeInvalid("province_name")}
                    invalid={invalid.province_name}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่อจังหวัดภาษาอังกฤษ"
                    idName="province_name_en"
                    value={form.provinceNameEn}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({
                        ...form,
                        provinceNameEn: value,
                        checkShow: true,
                      })
                    }
                    handleInvalid={() => removeInvalid("province_name_en")}
                    invalid={invalid.province_name_en}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ภูมิภาค"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="geo"
                    classLabel="normal"
                    selectValue={geoIdSelectValue}
                    optionsList={geoIdList}
                    handleChange={(value) => {
                      setGeoIdSelectValue([
                        { value: value.value, label: value.label },
                      ]);
                      setForm({ ...form, geoId: value.value });
                    }}
                    handleInvalid={() => removeInvalid("geo")}
                    invalid={invalid.geo}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขตปศุสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="zone"
                    classLabel="normal"
                    selectValue={zoneLivestockIdSelectValue}
                    optionsList={zoneLivestockIdList}
                    handleChange={(value) => {
                      setZoneLivestockIdSelectValue([
                        { value: value.value, label: value.label },
                      ]);
                      setForm({ ...form, zoneId: value.value });
                    }}
                    handleInvalid={() => removeInvalid("zone")}
                    invalid={invalid.zone}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6} sm={12} xs={12}>
                  <p className="mb-0">
                    {form.latitude && `ละติจูด : ${form.latitude}`}
                  </p>
                  <p>{form.longitude && ` ลองจิจูด : ${form.longitude}`}</p>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6} sm={12} xs={12}>
                  <button
                    className="btn btn-sky d-flex align-items-center"
                    onClick={() => toMap()}
                  >
                    <img className="img-fluid mr-1" src="/svg/pin.svg" alt="" />
                    เลือกแผนที่
                  </button>
                </Col>
              </Row>
              {showMap && (
                <ModalMap
                  show={showMap}
                  modeMap={form.modeMap}
                  lat={form.latitude}
                  lon={form.longitude}
                  close={() => setShowMap(false)}
                  save={(value) =>
                    setForm({
                      ...form,
                      latitude: value.latitude,
                      longitude: value.longitude,
                    })
                  }
                />
              )}
            </div>
            <div
              className="tab-pane fade card-tab"
              id="status"
              role="tabpanel"
              aria-labelledby="status-tab"
            >
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={statusList}
                    handleChange={(value) => {
                      setStatusSelectValue([
                        { value: value.value, label: value.label },
                      ]);
                      setForm({
                        ...form,
                        status: value.value,
                        checkShow: true,
                      });
                    }}
                    handleInvalid={() => removeInvalid("status")}
                    invalid={invalid.status}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                    <p className="mr-4">วันที่บันทึกข้อมูล</p>
                    <p>
                      {form.createDate
                        ? helper.momentDate(form.createDate, "th", "short")
                        : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                    <p className="mr-3">วันที่ปรับปรุงข้อมูล</p>
                    <p>
                      {form.updateDate
                        ? helper.momentDate(form.updateDate, "th", "short")
                        : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="mr-2 mt-3 mb-2">
          <div className="text-right py-2">
            <button
              className="btn btn-danger px-4 mr-2"
              onClick={() => handleClose()}
            >
              ยกเลิก
            </button>
            <button
              className="btn btn-success px-4"
              onClick={() => toConfirm()}
              disabled={user?.userGroup.mupdate === "n" && mode === "edit"}
            >
              บันทึก
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalProvinces;
