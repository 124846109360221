import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as API from "../../../utils/apis";
import * as helper from "../../../utils/helper";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import InputText from "../../../components/InputText";
import DataTable from "../../../components/DataTable";
import DataTableNodata from "../../../components/DataTableNodata";
import InputDatePicker from "../../../components/InputDatePicker";
import { useMasterData } from "../../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function User() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/users/users", name: "ผู้ใช้งาน" },
    { path: "active", name: "รายการผู้ใช้งาน" },
  ];

  const initialForm = {
    title: "",
    name: "",
    lastName: "",
    tel: "",
    email: "",
    userGroupId: "",
    userTypeId: "",
    userViewId: "",
    statusId: "",
    dateBegin: "",
    dateEnd: "",
    provinceId: "",
    amphurId: "",
    tambonId: "",
    mooId: "",

    testUpdate: "n",
    testView: "y",

    idEdit: "",
    mode: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
      },
      {
        sortBy: "firstName",
        name: "ชื่อ-สกุล",
        sortFlg: true,
      },
      {
        sortBy: "email",
        name: "อีเมล",
        sortFlg: true,
      },
      {
        sortBy: "pid",
        name: "เลขบัตรประชาชน",
        sortFlg: true,
      },
      {
        sortBy: "userTypeId",
        name: "ประเภทผู้ใช้งาน",
        sortFlg: true,
      },
      {
        sortBy: "userGroupId",
        name: "กลุ่มผู้ใช้งาน",
        sortFlg: true,
      },
      {
        sortBy: "userViewId",
        name: "สิทธิการดูแลข้อมูล",
        sortFlg: true,
      },
      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },
      {
        sortBy: "createDate",
        name: "วันที่สร้างข้อมูล",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };

  const [form, setForm] = useState(initialForm);

  const initialFormJson = {
    title: {},
    userGroup: {},
    userType: {},
    userView: {},
  };

  const [formJson, setFormJson] = useState(initialFormJson);

  const [listUsers, setListUsers] = useState([]);

  const [isClearSearch, setIsClearSearch] = useState(false);

  const [userGroupSelectValue, setUserGroupSelectValue] = useState([]);
  const [userGrouplist, setUserGroupList] = useState([]);

  const [userTypeSelectValue, setUserTypeSelectValue] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const [statusList, setStatusList] = useState([]);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  useMasterData("province", provinceList, setProvinceList);

  useEffect(() => {
    getMasterJson();
    getProvince();
    getUserGroup();
    getUserType();
    getUserStatus();
  }, []);

  useEffect(() => {
    getUsers();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getUsers = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getUsers(
        form.dateBegin ? helper.getDate(form.dateBegin) : "",
        form.dateEnd ? helper.getDate(form.dateEnd) : "",
        form.provinceId,
        form.amphurId,
        form.tambonId,
        form.mooId,
        form.title,
        form.name,
        form.lastName,
        form.tel,
        form.email,
        form.userGroupId,
        form.userTypeId,
        form.userViewId,
        form.statusId,
        form.search,
        form.page,
        form.limit,
        form.sortBy,
        form.sortDir
      );
      const data = await response?.data;
      if (response.status === 200) {
        let usersArray = [];
        data.content.forEach((element) => {
          usersArray.push({
            value: element.id,
            label: element.name,
          });
        });

        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListUsers(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getUserGroup = async () => {
    try {
      const response = await API.getUserGroup("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let userGroupArray = [];
        data.content.forEach((element) => {
          userGroupArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setUserGroupList(userGroupArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserType = async () => {
    try {
      const response = await API.getUserType("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let userTypeArray = [];
        data.content.forEach((element) => {
          userTypeArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setUserTypeList(userTypeArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserStatus = async () => {
    try {
      const response = await API.getUserStatus();
      const data = await response?.data;
      if (response.status === 200) {
        let statusArray = [];
        data.content.forEach((element) => {
          statusArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setStatusList(statusArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMasterJson = async () => {
    const params = await ["title", "userGroup", "userType", "userView"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response?.data;
      if (response.status === 200) {
        setFormJson({
          ...formJson,
          title: data.title,
          userGroup: data.userGroup,
          userType: data.userType,
          userView: data.userView,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getStatusColor = (idStatus) => {
    let nameStatus;
    let colorStatus;
    if (idStatus) {
      if (idStatus === 1) {
        colorStatus = "#3FC071";
        nameStatus = "ยืนยันข้อมูล";
      } else if (idStatus === 2) {
        colorStatus = "#FFC107";
        nameStatus = "รอการยืนยัน";
      } else {
        colorStatus = "#FFC107";
        nameStatus = "กำลังดำเนินการ";
      }
      return (
        <div
          className="box-status font14 text-center"
          style={{ background: colorStatus }}
        >
          {nameStatus}
        </div>
      );
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, amphurId: value.value });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, tambonId: value.value });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        headTitle: form.headTitle,
        sortBy: form.sortBy,
        sortDir: form.sortDir,
        page: isChange1,
        limit: form.limit,
      });
    } else if (type === "limit") {
      setForm({
        headTitle: form.headTitle,
        sortBy: form.sortBy,
        sortDir: form.sortDir,
        page: form.page,
        limit: isChange1,
        dateBegin: form.dateBegin,
        dateEnd: form.dateEnd,
        provinceId: form.provinceId,
        amphurId: form.amphurId,
        tambonId: form.tambonId,
        mooId: form.mooId,
        title: form.title,
        name: form.name,
        lastName: form.lastName,
        tel: form.tel,
        email: form.email,
        userGroupId: form.userGroupId,
        userTypeId: form.userTypeId,
        userViewId: form.userViewId,
        statusId: form.statusId,
      });
    } else if (type === "filter") {
      setForm({
        headTitle: form.headTitle,
        sortBy: isChange1,
        sortDir: isChange2,
        page: form.page,
        limit: form.limit,
      });
    }
  };

  const buttonAddUser = (id) => {
    return (
      <button
        className="btn btn-info "
        disabled={user?.userGroup.mcreate === "n"}
        onClick={() => history.push(`/data-manage/users/users/save-user`)}
      >
        <div className="text-white">
          <i className="fas fa-plus mr-1 "></i>เพิ่มผู้ใช้งาน
        </div>
      </button>
    );
  };

  const delUsers = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบข้อมูลผู้ใช้งาน`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteUser(id);
          if (response.status === 200) {
            await getUsers();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const editUsers = (id) => {
    history.push(`/data-manage/users/users/edit/${id}`);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setUserTypeSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setUserGroupSelectValue([]);
    setDistrictList([]);
    setSubDistrictList([]);
    setVillageList([]);
    setStatusSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const renderTableUsers = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="text-center">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td>
            <p className="mb-0">
              {formJson.title[item.titleId]}
              {item.firstName} {item.lastName}
            </p>
            <p className="mb-0">({item.tel})</p>
          </td>
          <td>{item.email ? item.email : "-"}</td>
          <td>{item.pid ? item.pid : "-"}</td>
          <td>
            {formJson.userType[item.userTypeId]
              ? formJson.userType[item.userTypeId]
              : "-"}
          </td>
          <td>
            {formJson.userGroup[item.userGroupId]
              ? formJson.userGroup[item.userGroupId]
              : "-"}
          </td>
          <td>
            {formJson.userView[item.userViewId]
              ? formJson.userView[item.userViewId]
              : "-"}
          </td>
          <td>{item.statusId ? getStatusColor(item.statusId) : "-"}</td>
          <td>
            {item.createDate
              ? helper.momentDate(item.createDate, "th", "short")
              : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editUsers(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delUsers(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={10} />;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={2} md={4} sm={6} xs={6}>
                <InputDatePicker
                  title="วันที่สร้างเนื้อหา"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateBegin}
                  star={false}
                  handleChange={(value) =>
                    setForm({ ...form, dateBegin: value })
                  }
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateEnd}
                  star={false}
                  handleChange={(value) => setForm({ ...form, dateEnd: value })}
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputText
                  type="text"
                  title="ชื่อ นามสกุล"
                  star={false}
                  value={form.name}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) => setForm({ ...form, name: value })}
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputText
                  type="text"
                  title="เบอร์โทรศัพท์"
                  star={false}
                  idName="tel"
                  value={form.tel}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) => setForm({ ...form, tel: value })}
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputText
                  type="text"
                  title="อีเมล"
                  star={false}
                  idName="email"
                  value={form.email}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) => setForm({ ...form, email: value })}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={2} md={4} sm={6} xs={6}>
                <InputSelect
                  title="จังหวัด"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={provinceSelectValue}
                  optionsList={provinceList}
                  handleChange={(value) => {
                    selectValueProvince(value);
                  }}
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputSelect
                  title="อำเภอ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={districtSelectValue}
                  optionsList={districtList}
                  handleChange={(value) => selectValueDistrict(value)}
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputSelect
                  title="ตำบล"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={subDistrictSelectValue}
                  optionsList={subDistrictList}
                  handleChange={(value) => selectValueSubDistrict(value)}
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputSelect
                  title="หมู่บ้าน"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={villageSelectValue}
                  optionsList={villageList}
                  handleChange={(value) => {
                    setVillageSelectValue([value]);
                    setForm({ ...form, mooId: value.value });
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={2} md={4} sm={6}>
                <InputSelect
                  title="ประเภทผู้ใช้งาน"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="userType"
                  classLabel="normal"
                  selectValue={userTypeSelectValue}
                  optionsList={userTypeList}
                  handleChange={(value) => {
                    setForm({ ...form, userTypeId: value.value });
                    setUserTypeSelectValue([value]);
                  }}
                />
              </Col>
              <Col lg={2} md={4} sm={6}>
                <InputSelect
                  title="กลุ่มผู้ใช้งาน"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={userGroupSelectValue}
                  optionsList={userGrouplist}
                  handleChange={(value) => {
                    setForm({ ...form, userGroupId: value.value });
                    setUserGroupSelectValue(value);
                  }}
                />
              </Col>

              <Col lg={2} md={4} sm={6}>
                <InputSelect
                  title="สถานะ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleChange={(value) => {
                    setForm({ ...form, statusId: value.value });
                    setStatusSelectValue([value]);
                  }}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="รายการผู้ใช้งาน"
            classNameBox="mt-4"
            headRight={buttonAddUser()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTableUsers(listUsers)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default User;
