import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import { Row, Col, Form } from "react-bootstrap";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import * as API from "../../utils/apis";

import BoxCard from "../../components/BoxCard";
import InputSelect from "../../components/InputSelect";
import InputMask from "../../components/InputMask";
import InputText from "../../components/InputText";
import InputDatePicker from "../../components/InputDatePicker";
import Breadcrumb from "../../components/Breadcrumb";
import MapDiseaseZone from "../../components/googleMap/MapDiseaseZone";
import ModalTableFarmBuffer from "../../components/EpidemicZone/Modal.TableFarmBuffer";

function Epidemic_Zone_Report() {
  const initialBufferZone = {
    // Buffer Zone
    lat: 14.384072628283658,
    lng: 100.55795047023376,
  };
  const [bufferZone, setBufferZone] = useState(initialBufferZone);

  const [bufferZoneRadiusChoose, setBufferZoneRadiusChoose] = useState("");
  const [bufferZoneRadius, setBufferZoneRadius] = useState(1);

  const [farmsBufferZone, setfarmsBufferZone] = useState([]);

  const [farmsBufferZoneReport, setfarmsBufferZoneReport] = useState([]);

  const initialFarm = {
    // ที่ตั้งฟาร์ม
    farm_provinceId: "",
    farm_districtId: "",
    farm_subDistrictId: "",
    farm_dateFrom: "",
    farm_dateTo: "",
    farm_animalTypeId: "",
  };
  const [farm, setFarm] = useState(initialFarm);

  const [farmLocationReport, setFarmLocationReport] = useState([]);

  const initialDisease = {
    // จุดเกิดโรค
    disease_provinceId: "",
    disease_districtId: "",
    disease_subDistrictId: "",
    disease_dateFrom: "",
    disease_dateTo: "",
    disease_choose: [],
  };
  const [disease, setDisease] = useState(initialDisease);

  const [diseaseRadiusChoose, setDiseaseRadiusChoose] = useState("");
  const [diseaseRadius, setDiseaseRadius] = useState(1);

  const [diseaseZoneReport, setDiseaseZoneReport] = useState([]);

  const initialFarmRisk = {
    // ประเมินความเสี่ยงฟาร์ม
    farmRisk_dateFrom: "",
    farmRisk_dateTo: "",
    farmRisk_disease: [],
    farmRisk_level: [],
  };
  const [farmRisk, setFarmRisk] = useState(initialFarmRisk);

  const [farmRiskReport, setFarmRiskReport] = useState([]);

  const initialRisk = {
    // พื้นที่ความเสี่ยง
    risk_disease: [],
  };
  const [risk, setRisk] = useState(initialRisk);
  const [riskAreaOpacity, setRiskAreaOpacity] = useState(50);

  const [riskAreaReport, setRiskAreaReport] = useState([]);

  // ที่ตั้งฟาร์ม
  const [provinceFarmSelectValue, setProvinceFarmSelectValue] = useState([]);
  const [provinceFarmList, setProvinceFarmList] = useState([]);

  const [districtFarmSelectValue, setDistrictFarmSelectValue] = useState([]);
  const [districtFarmList, setDistrictFarmList] = useState([]);

  const [subDistrictFarmSelectValue, setSubDistrictFarmSelectValue] = useState(
    []
  );
  const [subDistrictFarmList, setSubDistrictFarmList] = useState([]);

  // จุดเกิดโรค
  const [provinceDiseaseSelectValue, setProvinceDiseaseSelectValue] = useState(
    []
  );
  const [provinceDiseaseList, setProvinceDiseaseList] = useState([]);

  const [districtDiseaseSelectValue, setDistrictDiseaseSelectValue] = useState(
    []
  );
  const [districtDiseaseList, setDistrictDiseaseList] = useState([]);

  const [subDistrictDiseaseSelectValue, setSubDistrictDiseaseSelectValue] =
    useState([]);
  const [subDistrictDiseaseList, setSubDistrictDiseaseList] = useState([]);

  // ======
  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [diseaseList, setDiseaseList] = useState([]);

  const [showTableFarmBuffer, setShowTableFarmBuffer] = useState(false);

  const [dataAnimalList, setDataAnimalList] = useState([]);

  const riskLevelList = [
    {
      id: 0,
      name: "ไม่มีความเสี่ยง",
      color: "#22fd35",
      img: "/svg/risk_pin_1.svg",
    },
    {
      id: 1,
      name: "ต่ำมาก",
      color: "#244418",
      img: "/svg/risk_pin_2.svg",
    },
    {
      id: 2,
      name: "ต่ำ",
      color: "#619d4c",
      img: "/svg/risk_pin_3.svg",
    },
    {
      id: 3,
      name: "ปานกลาง",
      color: "#fffc38",
      img: "/svg/risk_pin_4.svg",
    },
    {
      id: 4,
      name: "สูง",
      color: "#fe8c23",
      img: "/svg/risk_pin_5.svg",
    },
    {
      id: 5,
      name: "สูงมาก",
      color: "#fd0013",
      img: "/svg/risk_pin_6.svg",
    },
  ];

  const [isOpen, setIsopen] = useState(false);

  const [isOnBufferZone, setIsOnBufferZone] = useState(false);
  const [isOnFarm, setIsOnFarm] = useState(false);
  const [isOnDisease, setIsOnDisease] = useState(false);
  const [isOnFarmRisk, setIsOnFarmRisk] = useState(false);
  const [isOnRisk, setIsOnRisk] = useState(false);

  const [isCheckAllDisease, setIsCheckAllDisease] = useState(false);
  const [isCheckAllDiseaseRisk, setIsCheckAllDiseaseRisk] = useState(false);
  const [isCheckAllRisk, setIsCheckAllRisk] = useState(false);

  useEffect(async () => {
    await getAnimal();
    await getProvince("all");
    await getDiseaseType();
  }, []);

  useEffect(async () => {
    if (!showTableFarmBuffer) {
      setfarmsBufferZoneReport([]);
    }
  }, [showTableFarmBuffer]);

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        animalArray.splice(0, 0, { value: "", label: "เลือกทั้งหมด" });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType("", "", "1", "100", 1);
      const data = await response?.data;
      if (response.status == 200) {
        setDiseaseList(data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (type) => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        provincesArray.splice(0, 0, { value: "", label: "เลือกทั้งหมด" });
        if (type === "farm") {
          setProvinceFarmList(provincesArray);
        } else if (type === "disease") {
          setProvinceDiseaseList(provincesArray);
        } else {
          setProvinceFarmList(provincesArray);
          setProvinceDiseaseList(provincesArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id, type) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        districtArray.splice(0, 0, { value: "", label: "เลือกทั้งหมด" });
        if (type == "farm") {
          setDistrictFarmList(districtArray);
        } else if (type === "disease") {
          setDistrictDiseaseList(districtArray);
        } else {
          setDistrictFarmList(districtArray);
          setDistrictDiseaseList(districtArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id, type) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        subDistrictArray.splice(0, 0, { value: "", label: "เลือกทั้งหมด" });
        if (type === "farm") {
          setSubDistrictFarmList(subDistrictArray);
        } else if (type === "disease") {
          setSubDistrictDiseaseList(subDistrictArray);
        } else {
          setSubDistrictFarmList(subDistrictArray);
          setSubDistrictDiseaseList(subDistrictArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleSidebar = () => {
    window.$(document).ready(function () {
      window.$('[data-widget="pushmenu"]').PushMenu("init");
    });
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  // =================== selectValue ที่ตั้งฟาร์ม

  const selectValueProvinceFarm = (value) => {
    setProvinceFarmSelectValue([value]);
    setDistrictFarmSelectValue([]);
    setSubDistrictFarmSelectValue([]);
    setFarm({ ...farm, farm_provinceId: value.value });
    getDistricts(value.value, "farm");
  };

  const selectValueDistrictFarm = (value) => {
    setDistrictFarmSelectValue([value]);
    setSubDistrictFarmSelectValue([]);
    setFarm({ ...farm, farm_districtId: value.value });
    getSubDistricts(value.value, "farm");
  };

  const selectValueSubDistrictFarm = (value) => {
    setSubDistrictFarmSelectValue([value]);
    setFarm({ ...farm, farm_subDistrictId: value.value });
  };

  // =================== selectValue จุดเกิดโรค
  const selectValueProvinceDisease = (value) => {
    setProvinceDiseaseSelectValue([value]);
    setDistrictDiseaseSelectValue([]);
    setSubDistrictDiseaseSelectValue([]);
    setDisease({ ...disease, disease_provinceId: value.value });
    getDistricts(value.value, "disease");
  };

  const selectValueDistrictDisease = (value) => {
    setDistrictDiseaseSelectValue([value]);
    setSubDistrictDiseaseSelectValue([]);
    setDisease({ ...disease, disease_districtId: value.value });
    getSubDistricts(value.value, "disease");
  };

  const selectValueSubDistrictDisease = (value) => {
    setSubDistrictDiseaseSelectValue([value]);
    setDisease({ ...disease, disease_subDistrictId: value.value });
  };

  const selectDiseaseAll = () => {
    setIsCheckAllDisease(!isCheckAllDisease);
    let chooseAll = [];
    if (diseaseList.length > 0) {
      diseaseList.map((item, i) => {
        chooseAll.push(item.id);
      });
    }

    if (isCheckAllDisease) {
      chooseAll = [];
    }
    setDisease({ ...disease, disease_choose: chooseAll });
  };

  const setSelectDisease = (e) => {
    let arrayChoose = [];
    if (e.target.checked) {
      arrayChoose = [...disease.disease_choose, e.target.value];
      setDisease({ ...disease, disease_choose: arrayChoose });
      if (arrayChoose.length === diseaseList.length) {
        setIsCheckAllDisease(true);
      }
    } else {
      if (disease.disease_choose.length > 0) {
        disease.disease_choose.forEach((item, i) => {
          if (item == e.target.value) {
            disease.disease_choose.splice(i, 1);
          }
          setIsCheckAllDisease(false);
        });
        setDisease({ ...disease, disease_choose: disease.disease_choose });
      }
    }
  };

  const riskSelectDiseaseAll = (e) => {
    setIsCheckAllDiseaseRisk(!isCheckAllDiseaseRisk);
    let chooseAll = [];
    if (diseaseList.length > 0) {
      diseaseList.map((item, i) => {
        chooseAll.push(item.id);
      });
    }

    if (isCheckAllDiseaseRisk) {
      chooseAll = [];
    }
    setFarmRisk({ ...farmRisk, farmRisk_disease: chooseAll });
  };

  const setSelectDiseaseRisk = (e) => {
    let arrayChoose = [];
    if (e.target.checked) {
      arrayChoose = [...farmRisk.farmRisk_disease, e.target.value];
      setFarmRisk({ ...farmRisk, farmRisk_disease: arrayChoose });
      if (arrayChoose.length === diseaseList.length) {
        setIsCheckAllDiseaseRisk(true);
      }
    } else {
      if (farmRisk.farmRisk_disease.length > 0) {
        farmRisk.farmRisk_disease.forEach((item, i) => {
          if (item == e.target.value) {
            farmRisk.farmRisk_disease.splice(i, 1);
          }
          setIsCheckAllDiseaseRisk(false);
        });
        setFarmRisk({
          ...farmRisk,
          farmRisk_disease: farmRisk.farmRisk_disease,
        });
      }
    }
  };

  const setSelectRiskLevel = (e) => {
    let arrayChoose = [];
    if (e.target.checked) {
      arrayChoose = [...farmRisk.farmRisk_level, e.target.value];
      setFarmRisk({ ...farmRisk, farmRisk_level: arrayChoose });
    } else {
      if (farmRisk.farmRisk_level.length > 0) {
        farmRisk.farmRisk_level.forEach((item, i) => {
          if (item == e.target.value) {
            farmRisk.farmRisk_level.splice(i, 1);
          }
        });
        setFarmRisk({ ...farmRisk, farmRisk_level: farmRisk.farmRisk_level });
      }
    }
  };

  const selectRiskAll = (e) => {
    setIsCheckAllRisk(!isCheckAllRisk);
    let chooseAll = [];
    if (diseaseList.length > 0) {
      diseaseList.map((item, i) => {
        chooseAll.push(item.id);
      });
    }

    if (isCheckAllRisk) {
      chooseAll = [];
    }
    setRisk({ ...risk, risk_disease: chooseAll });
  };

  const setSelectRisk = (e) => {
    let arrayChoose = [];
    if (e.target.checked) {
      arrayChoose = [...risk.risk_disease, e.target.value];
      setRisk({ ...risk, risk_disease: arrayChoose });
      if (arrayChoose.length === diseaseList.length) {
        setIsCheckAllRisk(true);
      }
    } else {
      if (risk.risk_disease.length > 0) {
        risk.risk_disease.forEach((item, i) => {
          if (item == e.target.value) {
            risk.risk_disease.splice(i, 1);
          }
          setIsCheckAllRisk(false);
        });
        setRisk({ ...risk, risk_disease: risk.risk_disease });
      }
    }
  };

  const getZoneReport = async () => {
    // console.log("isOnBufferZone", isOnBufferZone);
    // console.log("isOnFarm", isOnFarm);
    // console.log("isOnDisease", isOnDisease);
    // console.log("isOnFarmRisk", isOnFarmRisk)
    // console.log("isOnRisk", isOnRisk)

    if (isOnBufferZone) {
      const params = {
        latitude: bufferZone.lat,
        longitude: bufferZone.lng,
        radius: bufferZoneRadius,
        radius1: "y",
        radius3: "n",
        radius5: "n",
        radius10: "n",
        radiusUnlimit: "n",
      };

      if (bufferZoneRadius >= 3) {
        params.radius3 = "y";
      } else {
        params.radius3 = "n";
      }

      if (bufferZoneRadius >= 5) {
        params.radius5 = "y";
      } else {
        params.radius5 = "n";
      }

      if (bufferZoneRadius >= 10) {
        params.radius10 = "y";
      } else {
        params.radius10 = "n";
      }

      if (bufferZoneRadius > 10) {
        params.radiusUnlimit = "y";
      } else {
        params.radiusUnlimit = "n";
      }

      try {
        const response = await API.getZoneReportFarmAll(params);
        const data = await response.data;
        if (response.status === 200) {
          // console.log("get farm location all", data.radius1.concat(data.radius3, data.radius5, data.radius10) );
          setfarmsBufferZone(
            data.radius1.concat(
              data.radius3,
              data.radius5,
              data.radius10,
              data.radiusUnlimit
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (isOnFarm) {
      const params = {
        provinceId: farm.farm_provinceId,
        amphurId: farm.farm_districtId,
        tambonId: farm.farm_subDistrictId,
        dateBegin: farm.farm_dateFrom ? helper.getDate(farm.farm_dateFrom) : "",
        dateEnd: farm.farm_dateTo ? helper.getDate(farm.farm_dateTo) : "",
        anitmalTypeId: farm.farm_animalTypeId,
        page: 1,
        limit: 10000,
      };
      try {
        const response = await API.getZoneReportFarm(params);
        const data = await response.data;
        if (response.status === 200) {
          let dataAnimalArray = [];
          if (data.content && data.content.length > 0) {
            data.content.map((item, i) => {
              let newAnimalType = [];
              if (item.animalType && item.animalType.length > 0) {
                item.animalType.map((itemA, indexA) => {
                  if (newAnimalType.length > 0) {
                    let has = false;
                    let indexHas = "";
                    let hasAnimalAmt = 0;
                    newAnimalType.map((newAt, indexAt) => {
                      if (newAt.animalTypeId == itemA.animalTypeId) {
                        has = true;
                        indexHas = indexAt;
                        hasAnimalAmt = itemA.animalAmt;
                      }
                    });
                    if (has) {
                      newAnimalType[indexHas].animalAmt =
                        Number(newAnimalType[indexHas].animalAmt) +
                        Number(hasAnimalAmt);
                    } else {
                      newAnimalType.push(itemA);
                    }
                  } else {
                    newAnimalType.push(itemA);
                  }
                });
              }
              dataAnimalArray.push({
                id: item.id,
                farmName: item.farmName,
                farmerName: item.farmerName,
                latitude: item.latitude,
                longitude: item.longitude,
                phone: item.phone,
                animalType: newAnimalType,
              });
              // console.log("newAnimalType", newAnimalType);
            });
          }
          // console.log("get Farm Location Report", dataAnimalArray);
          setFarmLocationReport(dataAnimalArray);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (isOnDisease) {
      let diseaseSearch = [];
      if (disease.disease_choose?.length > 0) {
        diseaseSearch = disease.disease_choose.map((item, i) => {
          // console.log("item diseaseSearch", item);
          // diseaseSearch = diseaseSearch + `${item},`
          return item;
        });
      }
      const params = {
        provinceId: disease.disease_provinceId,
        amphurId: disease.disease_districtId,
        tambonId: disease.disease_subDistrictId,
        dateBegin: disease.disease_dateFrom
          ? helper.getDate(disease.disease_dateFrom)
          : "",
        dateEnd: disease.disease_dateTo
          ? helper.getDate(disease.disease_dateTo)
          : "",
        search: diseaseSearch.join(","),
        page: 1,
        limit: 10000,
      };
      try {
        const response = await API.getZoneReportDisease(params);
        const data = await response.data;
        if (response.status === 200) {
          // console.log("getZoneReportDisease", data);
          setDiseaseZoneReport(data.content);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (isOnFarmRisk) {
      let diseaseSearch = [];
      if (farmRisk.farmRisk_disease && farmRisk.farmRisk_disease.length > 0) {
        diseaseSearch = farmRisk.farmRisk_disease.map((item, i) => {
          // diseaseSearch = diseaseSearch + `${item},`
          return item;
        });
      }

      let riskSearch = [];
      if (farmRisk.farmRisk_level && farmRisk.farmRisk_level.length > 0) {
        riskSearch = farmRisk.farmRisk_level.map((item, i) => {
          // riskSearch = riskSearch + `${item},`
          return item;
        });
      }

      const params = {
        dateBegin: farmRisk.farmRisk_dateFrom
          ? helper.getDate(farmRisk.farmRisk_dateFrom)
          : "",
        dateEnd: farmRisk.farmRisk_dateTo
          ? helper.getDate(farmRisk.farmRisk_dateTo)
          : "",
        search: diseaseSearch.join(","),
        risk: riskSearch.join(","),
        page: 1,
        limit: 10000,
      };
      try {
        const response = await API.getZoneReportFarmRisk(params);
        const data = await response.data;
        if (response.status === 200) {
          // console.log("getZoneReportFarmRisk", data.content);
          mapZoneReportFarmRiskScore(data.content);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (isOnRisk) {
      let diseaseSearch = [];
      if (risk.risk_disease && risk.risk_disease.length > 0) {
        diseaseSearch = risk.risk_disease.map((item, i) => {
          // diseaseSearch = diseaseSearch + `${item},`;
          return item;
        });
      }
      const params = {
        search: diseaseSearch.join(","),
        page: 1,
        limit: 10000,
      };
      try {
        const response = await API.getZoneReportRiskArea(params);
        const data = await response.data;
        if (response.status === 200) {
          // console.log("getZoneReportRiskArea", data);
          setRiskAreaReport(data.content);
        }
      } catch (error) {
        console.log(error);
      }
    }

    toggleSidebar();
  };

  const getZoneBufferReport = async (data) => {
    if (data.length > 0) {
      const formData = {
        farmIds: data.map((item) => {
          return item.id;
        }),
      };
      try {
        const response = await API.getZoneReportFarmBuffer(formData);
        const data = await response.data;
        if (response.status === 200) {
          data.map((itemReport, index) => {
            farmsBufferZone.map((itemI, i) => {
              if (itemReport.id == itemI.id) {
                itemReport.distances = itemI.distances;
              }
            });
          });
          setfarmsBufferZoneReport(data);
          setShowTableFarmBuffer(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const mapZoneReportFarmRiskScore = (datas) => {
    setFarmRiskReport(
      datas.map((data) => {
        data.avgRiskLevel = 0;
        if (data.diseaseScore.length > 0) {
          data.avgRiskLevel = Math.round(
            data.diseaseScore
              .map((d) => d.riskLevel)
              .reduce((prev, next) => prev + next, 0) / data.diseaseScore.length
          );
        }
        return data;
      })
    );
  };

  const cancelSearch = () => {
    setBufferZone(initialBufferZone);
    setBufferZoneRadius(1);
    setFarm(initialFarm);
    setDiseaseRadius(1);
    setDisease(initialDisease);
    setFarmRisk(initialFarmRisk);
    setRisk(initialRisk);

    setProvinceFarmSelectValue([]);
    setDistrictFarmSelectValue([]);
    setSubDistrictFarmSelectValue([]);
    setAnimalSelectValue([]);

    setProvinceDiseaseSelectValue([]);
    setDistrictDiseaseSelectValue([]);
    setSubDistrictDiseaseSelectValue([]);

    setIsOnBufferZone(false);
    setIsOnFarm(false);
    setIsOnDisease(false);
    setIsOnFarmRisk(false);
    setIsOnRisk(false);

    setIsCheckAllDisease(false);
    setIsCheckAllDiseaseRisk(false);
    setIsCheckAllRisk(false);

    toggleSidebar();
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "title", name: "รายงาน" },
    { path: "active", name: "B99RPT04-รายงานวิเคราะห์เชิงพื้นที่" },
  ];

  const buttonSlide = () => {
    return (
      <img
        src="/svg/warning-slide.svg"
        className="cursor-p icon-check"
        onClick={() => toggleSidebar()}
        alt="warning-slide"
      />
    );
  };

  const toggleBufferZone = () => {
    if (isOnBufferZone) {
      setfarmsBufferZone([]);
    }
    setIsOnBufferZone(!isOnBufferZone);
  };

  const onOff_bufferZone = () => {
    return (
      <div className="toggle-button-cover" onClick={() => toggleBufferZone()}>
        <div className="button r" id="button-onoff">
          <input
            type="checkbox"
            className="checkbox"
            defaultChecked={isOnBufferZone}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </div>
      </div>
    );
  };

  const toggleFarm = () => {
    if (isOnFarm) {
      setFarmLocationReport([]);
      // setFarm(initialFarm)
      // setProvinceFarmSelectValue([])
      // setDistrictFarmSelectValue([])
      // setSubDistrictFarmSelectValue([])
      // setAnimalSelectValue([])
    }
    setIsOnFarm(!isOnFarm);
  };

  const onOff_farm = () => {
    return (
      <div className="toggle-button-cover" onClick={() => toggleFarm()}>
        <div className="button r" id="button-onoff">
          <input
            type="checkbox"
            className="checkbox"
            defaultChecked={isOnFarm}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </div>
      </div>
    );
  };

  const toggleDisease = () => {
    if (isOnDisease) {
      setDiseaseZoneReport([]);
      // setDisease(initialDisease)
      // setProvinceDiseaseSelectValue([])
      // setDistrictDiseaseSelectValue([])
      // setSubDistrictDiseaseSelectValue([])
    }
    setIsOnDisease(!isOnDisease);
  };

  const onOff_disease = () => {
    return (
      <div className="toggle-button-cover" onClick={() => toggleDisease()}>
        <div className="button r" id="button-onoff">
          <input
            type="checkbox"
            className="checkbox"
            defaultChecked={isOnDisease}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </div>
      </div>
    );
  };

  const toggleFarmRisk = () => {
    if (isOnFarmRisk) {
      setFarmRiskReport([]);
      // setFarmRisk(initialFarmRisk)
    }
    setIsOnFarmRisk(!isOnFarmRisk);
  };

  const onOff_farmRisk = () => {
    return (
      <div className="toggle-button-cover" onClick={() => toggleFarmRisk()}>
        <div className="button r" id="button-onoff">
          <input
            type="checkbox"
            className="checkbox"
            defaultChecked={isOnFarmRisk}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </div>
      </div>
    );
  };

  const toggleRisk = () => {
    if (isOnRisk) {
      // setRisk(initialRisk);
      setRiskAreaReport([]);
    }
    setIsOnRisk(!isOnRisk);
  };

  const onOff_risk = () => {
    return (
      <div className="toggle-button-cover" onClick={() => toggleRisk()}>
        <div className="button r" id="button-onoff">
          <input
            type="checkbox"
            className="checkbox"
            defaultChecked={isOnRisk}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </div>
      </div>
    );
  };

  const nameFarm = () => {
    return (
      <>
        ที่ตั้งฟาร์ม <img className="" src="/svg/pin_sky.svg" />
      </>
    );
  };

  const risk_level = (item, e) => {
    if (item.id == "0")
      return (
        <>
          <img className="img-fluid" src={item.img} /> {item.name}
        </>
      );
    if (item.id == "1")
      return (
        <>
          <img className="img-fluid" src={item.img} /> {item.name}
        </>
      );
    if (item.id == "2")
      return (
        <>
          <img className="img-fluid" src={item.img} /> {item.name}
        </>
      );
    if (item.id == "3")
      return (
        <>
          <img className="img-fluid" src={item.img} /> {item.name}
        </>
      );
    if (item.id == "4")
      return (
        <>
          <img className="img-fluid" src={item.img} /> {item.name}
        </>
      );
    if (item.id == "5")
      return (
        <>
          <img className="img-fluid" src={item.img} /> {item.name}
        </>
      );
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 py-3 container-fixed-footer">
          <BoxCard
            title="รายงานวิเคราะห์เชิงพื้นที่"
            classLabel=""
            headRight={buttonSlide()}
          >
            <div className="disease-map mb-2">
              <MapDiseaseZone
                mapMode="show"
                location={{ lat: bufferZone.lat, lng: bufferZone.lng }}
                bufferZoneRadius={isOnBufferZone ? bufferZoneRadius : 0}
                farmBufferZoneLocation={farmsBufferZone}
                farmLocationReport={farmLocationReport}
                diseaseZoneRadius={isOnDisease ? diseaseRadius : 0}
                diseaseZoneReport={diseaseZoneReport}
                riskLevelIcon={riskLevelList}
                farmRiskReport={farmRiskReport}
                riskAreaReport={riskAreaReport}
                riskAreaOpacity={riskAreaOpacity}
                getLocation={(value) => {
                  setBufferZone(value);
                }}
                getFilterFarmLocation={(value) => {
                  // console.log("getFilterFarmLocation", value);
                  getZoneBufferReport(value);
                }}
              />
            </div>
          </BoxCard>
        </div>
      </div>

      <div className="content-right">
        <div
          className={`sidebar-right ${
            isOpen == true ? "active mt-5" : " mt-5"
          }`}
        >
          <div className="sd-body mt-3">
            <div className="border-bottom py-2">
              <div className="d-flex justify-content-between px-3">
                <div>เพิ่มเติม</div>
                <div>
                  <img
                    src="/svg/warning-slide.svg"
                    className="cursor-p icon-btn"
                    onClick={() => toggleSidebar()}
                    alt="warning-slide"
                  />
                </div>
              </div>
            </div>
            <div className="px-3">
              <BoxCard
                title="Buffer Zone จากจุดกำหนดเอง"
                classNameBox="mt-3"
                headRight={onOff_bufferZone()}
              >
                <div>
                  <span>
                    *เลือก{" "}
                    <img
                      className="img-fluid"
                      src="/svg/pin_b.svg"
                      alt="pinB"
                    />{" "}
                    ไปที่ตำแหน่งที่ต้องการ หรือระบุ ละติจูด ลองจิจูด
                  </span>
                  <Row className="mt-2">
                    <Col lg={3} md={6} sm={12}>
                      <InputText
                        type="text"
                        title="ละติจูด"
                        star={false}
                        idName="tel"
                        value={bufferZone.lat}
                        placeholder="โปรดระบุ"
                        classLabel="normal"
                        handleChange={(value) =>
                          setBufferZone({ ...bufferZone, lat: Number(value) })
                        }
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <InputText
                        type="text"
                        title="ลองจิจูด"
                        star={false}
                        idName="tel"
                        value={bufferZone.lng}
                        placeholder="โปรดระบุ"
                        classLabel="normal"
                        handleChange={(value) =>
                          setBufferZone({ ...bufferZone, lng: Number(value) })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2} md={2} sm={12}>
                      รัศมีรอบจุด
                    </Col>
                    <Col lg={10} md={10} sm={12}>
                      <Form.Check
                        type="radio"
                        name="radius"
                        id="radius_1km"
                        value={1}
                        checked={bufferZoneRadiusChoose == 1}
                        label="รัศมี 1 กม."
                        onChange={(e) => {
                          setBufferZoneRadiusChoose("1");
                          setBufferZoneRadius(Number(e.target.value));
                        }}
                      />
                      <Form.Check
                        type="radio"
                        name="radius"
                        id="radius_3km"
                        value={3}
                        checked={bufferZoneRadiusChoose == 3}
                        label="รัศมี 3 กม."
                        onChange={(e) => {
                          setBufferZoneRadiusChoose("3");
                          setBufferZoneRadius(Number(e.target.value));
                        }}
                      />
                      <Form.Check
                        type="radio"
                        name="radius"
                        id="radius_5km"
                        value={5}
                        checked={bufferZoneRadiusChoose == 5}
                        label="รัศมี 5 กม."
                        onChange={(e) => {
                          setBufferZoneRadiusChoose("5");
                          setBufferZoneRadius(Number(e.target.value));
                        }}
                      />
                      <Form.Check
                        type="radio"
                        name="radius"
                        id="radius_10km"
                        value={10}
                        checked={bufferZoneRadiusChoose == 10}
                        label="รัศมี 10 กม."
                        onChange={(e) => {
                          setBufferZoneRadiusChoose("10");
                          setBufferZoneRadius(Number(e.target.value));
                        }}
                      />

                      <div className="d-flex align-items-center">
                        <Form.Check
                          type="radio"
                          name="radius"
                          id="radius_other"
                          value={"other"}
                          checked={bufferZoneRadiusChoose == "other"}
                          label=""
                          onChange={(e) => {
                            setBufferZoneRadiusChoose("other");
                          }}
                        />
                        <label
                          htmlFor="radius_other"
                          className="normal d-flex align-items-center mb-0"
                        >
                          กำหนดรัศมี
                          <InputMask
                            mask="999"
                            disabled={bufferZoneRadiusChoose !== "other"}
                            type="text"
                            title=""
                            star={false}
                            idName="radius_other_input"
                            value={bufferZoneRadius}
                            placeholder="โปรดระบุ"
                            classLabel="normal"
                            classFormGroup="mb-0 w-25 mx-2"
                            handleChange={(value) =>
                              setBufferZoneRadius(Number(value))
                            }
                          />
                          กม.
                        </label>
                      </div>
                    </Col>
                  </Row>
                </div>
              </BoxCard>

              <BoxCard
                title={nameFarm()}
                classNameBox="mt-3"
                headRight={onOff_farm()}
              >
                <Row>
                  <Col lg={2} md={4} sm={12}>
                    <span>เลือกพื้นที่</span>
                  </Col>
                  <Col lg={10} md={8} sm={12}>
                    <Row>
                      <Col lg={5} md={6} sm={12}>
                        <InputSelect
                          title=""
                          star={false}
                          placeholder="ทุกจังหวัด"
                          idName="provinceFarm"
                          classLabel="normal"
                          selectValue={provinceFarmSelectValue}
                          optionsList={provinceFarmList}
                          handleChange={(value) => {
                            selectValueProvinceFarm(value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5} md={6} sm={12}>
                        <InputSelect
                          title=""
                          star={false}
                          placeholder="ทุกอำเภอ"
                          idName="districtFarm"
                          classLabel="normal"
                          selectValue={districtFarmSelectValue}
                          optionsList={districtFarmList}
                          handleChange={(value) =>
                            selectValueDistrictFarm(value)
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5} md={6} sm={12}>
                        <InputSelect
                          title=""
                          star={false}
                          placeholder="ทุกตำบล"
                          idName="subDistrictFarm"
                          classLabel="normal"
                          selectValue={subDistrictFarmSelectValue}
                          optionsList={subDistrictFarmList}
                          handleChange={(value) =>
                            selectValueSubDistrictFarm(value)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col lg={2} md={4} sm={12}>
                    <h6 className="mt-2 mb-0">ช่วงเวลา</h6>
                  </Col>
                  <Col lg={10} md={8} sm={12}>
                    <Row>
                      <Col lg={5} md={6} sm={12}>
                        <InputDatePicker
                          classFormGroup="mb-0"
                          classLabel="normal"
                          placeholder="วัน/เดือน/ปี"
                          value={farm.farm_dateFrom}
                          star={false}
                          handleChange={(value) => {
                            setFarm({ ...farm, farm_dateFrom: value });
                          }}
                        />
                      </Col>
                      <Col
                        lg={1}
                        md={1}
                        sm={1}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span>ถึง</span>
                      </Col>
                      <Col lg={5} md={6} sm={12}>
                        <InputDatePicker
                          classFormGroup="mb-0"
                          classLabel="normal"
                          placeholder="วัน/เดือน/ปี"
                          value={farm.farm_dateTo}
                          star={false}
                          handleChange={(value) => {
                            setFarm({ ...farm, farm_dateTo: value });
                          }}
                        />
                      </Col>
                    </Row>
                    <span className="font12 text-gray">
                      *หากไม่เลือกช่วงเวลาจะแสดงย้อนหลังวันที่ปัจจุบัน 1 ปี
                    </span>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={2} md={2} sm={12}>
                    ชนิดสัตว์
                  </Col>
                  <Col lg={10} md={8} sm={12}>
                    <Row>
                      <Col lg={5} md={6} sm={12}>
                        <InputSelect
                          title=""
                          star={false}
                          placeholder="สัตว์ทั้งหมด"
                          idName="subDistrict"
                          classLabel="normal"
                          selectValue={animalSelectValue}
                          optionsList={animalList}
                          handleChange={(value) => {
                            setAnimalSelectValue(value);
                            setFarm({
                              ...farm,
                              farm_animalTypeId: value.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </BoxCard>

              <BoxCard
                title="จุดเกิดโรค"
                classNameBox="mt-3"
                headRight={onOff_disease()}
              >
                <Row>
                  <Col lg={2} md={4} sm={12}>
                    <span>เลือกพื้นที่</span>
                  </Col>
                  <Col lg={10} md={8} sm={12}>
                    <Row>
                      <Col lg={5} md={6} sm={12}>
                        <InputSelect
                          title=""
                          star={false}
                          placeholder="ทุกจังหวัด"
                          idName="provinceDisease"
                          classLabel="normal"
                          selectValue={provinceDiseaseSelectValue}
                          optionsList={provinceDiseaseList}
                          handleChange={(value) => {
                            selectValueProvinceDisease(value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5} md={6} sm={12}>
                        <InputSelect
                          title=""
                          star={false}
                          placeholder="ทุกอำเภอ"
                          idName="districtDisease"
                          classLabel="normal"
                          selectValue={districtDiseaseSelectValue}
                          optionsList={districtDiseaseList}
                          handleChange={(value) =>
                            selectValueDistrictDisease(value)
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={5} md={6} sm={12}>
                        <InputSelect
                          title=""
                          star={false}
                          placeholder="ทุกตำบล"
                          idName="subDistrictDisease"
                          classLabel="normal"
                          selectValue={subDistrictDiseaseSelectValue}
                          optionsList={subDistrictDiseaseList}
                          handleChange={(value) =>
                            selectValueSubDistrictDisease(value)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={2} md={2} sm={12}>
                    รัศมีรอบจุด
                  </Col>
                  <Col lg={10} md={10} sm={12}>
                    <Form.Check
                      type="radio"
                      name="di_radius"
                      id="di_radius_1km"
                      value={1}
                      checked={diseaseRadiusChoose == 1}
                      label="รัศมี 1 กม."
                      onChange={(e) => {
                        setDiseaseRadiusChoose("1");
                        setDiseaseRadius(Number(e.target.value));
                      }}
                    />
                    <Form.Check
                      type="radio"
                      name="di_radius"
                      id="di_radius_3km"
                      value={3}
                      checked={diseaseRadiusChoose == 3}
                      label="รัศมี 3 กม."
                      onChange={(e) => {
                        setDiseaseRadiusChoose("3");
                        setDiseaseRadius(Number(e.target.value));
                      }}
                    />
                    <Form.Check
                      type="radio"
                      name="di_radius"
                      id="di_radius_5km"
                      value={5}
                      checked={diseaseRadiusChoose == 5}
                      label="รัศมี 5 กม."
                      onChange={(e) => {
                        setDiseaseRadiusChoose("5");
                        setDiseaseRadius(Number(e.target.value));
                      }}
                    />
                    <Form.Check
                      type="radio"
                      name="di_radius"
                      id="di_radius_10km"
                      value={10}
                      checked={diseaseRadiusChoose == 10}
                      label="รัศมี 10 กม."
                      onChange={(e) => {
                        setDiseaseRadiusChoose("10");
                        setDiseaseRadius(Number(e.target.value));
                      }}
                    />

                    <div className="d-flex align-items-center">
                      <Form.Check
                        type="radio"
                        name="di_radius"
                        id="disease_other"
                        value={"other"}
                        checked={diseaseRadiusChoose == "other"}
                        label=""
                        onChange={(e) => {
                          setDiseaseRadiusChoose("other");
                        }}
                      />
                      <label
                        htmlFor="disease_other"
                        className="normal d-flex align-items-center mb-0"
                      >
                        กำหนดรัศมี
                        <InputMask
                          mask="999"
                          disabled={diseaseRadiusChoose !== "other"}
                          type="text"
                          title=""
                          star={false}
                          idName="disease_other_input"
                          value={diseaseRadius}
                          placeholder="โปรดระบุ"
                          classLabel="normal"
                          classFormGroup="mb-0 w-25 mx-2"
                          handleChange={(value) =>
                            setDiseaseRadius(Number(value))
                          }
                        />
                        กม.
                      </label>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col lg={2} md={4} sm={12}>
                    <h6 className="mt-2 mb-0">ช่วงเวลา</h6>
                  </Col>
                  <Col lg={10} md={8} sm={12}>
                    <Row>
                      <Col lg={5} md={6} sm={12}>
                        <InputDatePicker
                          classFormGroup="mb-0"
                          classLabel="normal"
                          placeholder="วัน/เดือน/ปี"
                          value={disease.disease_dateFrom}
                          star={false}
                          handleChange={(value) => {
                            setDisease({ ...disease, disease_dateFrom: value });
                          }}
                        />
                      </Col>
                      <Col
                        lg={1}
                        md={1}
                        sm={1}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span>ถึง</span>
                      </Col>
                      <Col lg={5} md={6} sm={12}>
                        <InputDatePicker
                          classFormGroup="mb-0"
                          classLabel="normal"
                          placeholder="วัน/เดือน/ปี"
                          value={disease.disease_dateTo}
                          star={false}
                          handleChange={(value) => {
                            setDisease({ ...disease, disease_dateTo: value });
                          }}
                        />
                      </Col>
                    </Row>
                    <span className="font12 text-gray">
                      *หากไม่เลือกช่วงเวลาจะแสดงย้อนหลังวันที่ปัจจุบัน 1 ปี
                    </span>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col lg={2} md={2} sm={12}>
                    โรค
                  </Col>
                  <Col lg={10} md={10} sm={12}>
                    <Form.Check
                      type="checkbox"
                      name="selectDiseaseAll"
                      id="selectDiseaseAll"
                      label="แสดงทั้งหมด"
                      className="mr-2"
                      onChange={() => selectDiseaseAll()}
                      checked={isCheckAllDisease}
                    />
                    <div className="scrollbar-disease">
                      {/* <div className="scrolbar-disease2"> */}
                      {diseaseList &&
                        diseaseList.length > 0 &&
                        diseaseList.map((item, i) => (
                          <div key={i} className="mt-2">
                            <Form.Check
                              type="checkbox"
                              name={`disease_${item.id}`}
                              id={`disease_${item.id}`}
                              value={item.id}
                              label={item.name}
                              className="mr-2"
                              onChange={(e) => setSelectDisease(e)}
                              checked={
                                !!disease.disease_choose.find(
                                  (check) => check == item.id
                                )
                              }
                            />
                          </div>
                        ))}
                      {/* </div> */}
                    </div>
                  </Col>
                </Row>
              </BoxCard>

              <BoxCard
                title="ข้อมูลวิเคราะห์ผลประเมินความเสี่ยงฟาร์ม"
                classNameBox="mt-3"
                headRight={onOff_farmRisk()}
              >
                <Row>
                  <Col lg={2} md={4} sm={12}>
                    <h6 className="mt-2 mb-0">ช่วงเวลา</h6>
                  </Col>
                  <Col lg={10} md={8} sm={12}>
                    <Row>
                      <Col lg={5} md={6} sm={12}>
                        <InputDatePicker
                          classFormGroup="mb-0"
                          classLabel="normal"
                          placeholder="วัน/เดือน/ปี"
                          value={farmRisk.farmRisk_dateFrom}
                          star={false}
                          handleChange={(value) => {
                            setFarmRisk({
                              ...farmRisk,
                              farmRisk_dateFrom: value,
                            });
                          }}
                        />
                      </Col>
                      <Col
                        lg={1}
                        md={1}
                        sm={1}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span>ถึง</span>
                      </Col>
                      <Col lg={5} md={6} sm={12}>
                        <InputDatePicker
                          classFormGroup="mb-0"
                          classLabel="normal"
                          placeholder="วัน/เดือน/ปี"
                          value={farmRisk.farmRisk_dateTo}
                          star={false}
                          handleChange={(value) => {
                            setFarmRisk({
                              ...farmRisk,
                              farmRisk_dateTo: value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <span className="font12 text-gray">
                      *หากไม่เลือกช่วงเวลาจะแสดงย้อนหลังวันที่ปัจจุบัน 1 ปี
                    </span>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={2} md={2} sm={12}>
                    โรค
                  </Col>
                  <Col lg={10} md={10} sm={12}>
                    <Form.Check
                      type="checkbox"
                      name="riskSelectDiseaseAll"
                      id="riskSelectDiseaseAll"
                      label="แสดงทั้งหมด"
                      className="mr-2"
                      onChange={(e) => riskSelectDiseaseAll(e)}
                      checked={isCheckAllDiseaseRisk}
                    />
                    <div className="scrollbar-disease">
                      {diseaseList &&
                        diseaseList.length > 0 &&
                        diseaseList.map((item, i) => (
                          <div key={i} className="mt-2">
                            <Form.Check
                              type="checkbox"
                              name={`disease_risk_${item.id}`}
                              id={`disease_risk_${item.id}`}
                              value={item.id}
                              label={item.name}
                              className="mr-2"
                              onChange={(e) => setSelectDiseaseRisk(e)}
                              checked={
                                !!farmRisk.farmRisk_disease.find(
                                  (check) => check == item.id
                                )
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={2} md={2} sm={12}>
                    ระดับความเสี่ยง
                  </Col>
                  <Col lg={10} md={10} sm={12}>
                    {riskLevelList &&
                      riskLevelList.length > 0 &&
                      riskLevelList.map((item, i) => (
                        <Form.Check
                          key={i}
                          type="checkbox"
                          name={`risk_level_${item.id}`}
                          id={`risk_level_${item.id}`}
                          value={item.id}
                          className="mb-1"
                          label={risk_level(item)}
                          onChange={(e) => setSelectRiskLevel(e)}
                          checked={
                            !!farmRisk.farmRisk_level.find(
                              (check) => check == item.id
                            )
                          }
                        />
                      ))}
                  </Col>
                </Row>
              </BoxCard>

              <BoxCard
                title="พื้นที่ความเสี่ยง"
                classNameBox="mt-3"
                headRight={onOff_risk()}
              >
                <div className="scrollbar-disease">
                  <Form.Check
                    type="checkbox"
                    name="selectRiskAll"
                    id="selectRiskAll"
                    label="แสดงทั้งหมด"
                    className="mr-2"
                    onChange={(e) => selectRiskAll(e)}
                    checked={isCheckAllRisk}
                  />
                  {diseaseList &&
                    diseaseList.length > 0 &&
                    diseaseList.map((item, i) => (
                      <Form.Check
                        key={i}
                        type="checkbox"
                        name={`risk_${item.id}`}
                        id={`risk_${item.id}`}
                        value={item.id}
                        label={item.name}
                        className="mr-2"
                        onChange={(e) => setSelectRisk(e)}
                        checked={
                          !!risk.risk_disease.find((check) => check == item.id)
                        }
                      />
                    ))}
                </div>
                <div className="mt-3">
                  <span>ระดับความโปร่งแสง</span>
                  <div className="d-flex align-items-center">
                    <Slider
                      className="my-2 mr-3"
                      min={0}
                      max={100}
                      step={10}
                      // dots
                      trackStyle={{
                        backgroundColor: "#4a90e2",
                      }}
                      value={riskAreaOpacity}
                      onChange={(v) => {
                        setRiskAreaOpacity(v);
                      }}
                    />
                    <span>{riskAreaOpacity}%</span>
                  </div>
                </div>
              </BoxCard>
            </div>

            <div className="p-3 d-flex justify-content-end">
              <button
                className="btn btn-danger px-4 mr-2"
                onClick={() => cancelSearch()}
              >
                ยกเลิก
              </button>
              <button
                className="btn btn-success px-4"
                onClick={() => getZoneReport()}
              >
                แสดงข้อมูล
              </button>
            </div>
          </div>
        </div>
        <div
          className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
          onClick={() => toggleSidebar()}
        ></div>
      </div>

      {showTableFarmBuffer && (
        <ModalTableFarmBuffer
          show={showTableFarmBuffer}
          farmsBufferZoneReport={farmsBufferZoneReport}
          close={() => setShowTableFarmBuffer(false)}
        />
      )}
    </div>
  );
}

export default Epidemic_Zone_Report;
