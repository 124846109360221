import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
import { loading } from "../../redux/actions/loadingAction";
import { logout } from "../../redux/actions/logoutAction";

import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";

import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "../../components/DataTable";
import {
  useMasterData,
  useOptionsListYears,
  useCurrentYear,
} from "../../components/hooks/useMasterData";
import ButtonReportLink from "../../components/ButtonReportLink";

const MySwal = withReactContent(Swal);

function Disease_Control() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["common", "footer"]);
  const user = useSelector((state) => state.login.result);

  const search = useLocation().search;
  const obCode = new URLSearchParams(search).get("obCode");

  const initialForm = {
    typeUser: "1",
    agency: "",

    dateBegin: "",
    dateEnd: "",
    outbreakId: obCode ? obCode : "",
    animalTypeId: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    zoneId: "",
    caseCode: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
        rowSpan: 2,
      },
      {
        sortBy: "caseCode",
        name: "case",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "dateBegin",
        name: "วันที่ปฏิบัติงาน",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "animalTypeId",
        name: "ชนิดสัตว์",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "animal",
        name: "จำนวนสัตว์กลุ่มเสี่ยงในฝูง",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        name: "จำนวนสัตว์ป่วย (ตัว)",
        sortFlg: false,
        colSpan: 5,
        childHead: [
          {
            sortBy: "1",
            name: "ป่วยแรกเกิดโรค",
            sortFlg: true,
          },
          {
            sortBy: "2",
            name: "ป่วยใหม่ครั้งนี้",
            sortFlg: true,
          },
          {
            sortBy: "3",
            name: "ป่วยสะสมทั้งหมด",
            sortFlg: true,
          },
          {
            sortBy: "4",
            name: "หายป่วย",
            sortFlg: true,
          },
          {
            sortBy: "5",
            name: "ป่วยคงเหลือ",
            sortFlg: true,
          },
        ],
      },
      {
        name: "จำนวนสัตว์ตาย (ตัว)",
        sortFlg: false,
        colSpan: 2,
        childHead: [
          {
            sortBy: "6",
            name: "ตายแรกเกิดโรค/ตายในครั้งนี้",
            sortFlg: true,
          },
          {
            sortBy: "7",
            name: "ตายทั้งหมดสะสม",
            sortFlg: true,
          },
        ],
      },
      {
        sortBy: "dateEnd",
        name: "วันที่สัตว์ตัวสุดท้ายป่วย",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        name: "จัดการ",
        sortFlg: false,
        rowSpan: 2,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listDiseaseControl, setListDiseaseControl] = useState([]);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [isClearSearch, setIsClearSearch] = useState(false);

  // =================

  // select  เขตปศุสัตว์ ชนิดสัตว์
  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState([]);
  const [zoneLivestockList, setZoneLivestockList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);
  // =================
  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );

  const optionsListYear = useOptionsListYears();
  const currentYearValue = useCurrentYear();
  const [yearFilterList, setYearFilterList] = useState(currentYearValue);

  useEffect(() => {
    axios
      .all([getAnimal(true), getProvince(true), getZoneLivestock(true)])
      .then(
        axios.spread((...responses) => {
          setAnimalList(mapDataToSelect(responses[0].data.content));
          setProvinceList(mapDataToSelect(responses[1].data.content));
          setZoneLivestockList(mapDataToSelect(responses[2].data.content));
        })
      );
  }, []);

  useEffect(() => {
    getDiseaseControl();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getDiseaseControl = async () => {
    const params = {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",

      obCode: form.outbreakId ? form.outbreakId : "",
      caseCode: form.caseCode ? form.caseCode : "",
      dateBegin: form.dateBegin ? helper.getDate(form.dateBegin) : "",
      dateEnd: form.dateEnd ? helper.getDate(form.dateEnd) : "",
      zoneId: form.zoneId ? form.zoneId : "",
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
      animalTypeId: form.animalTypeId ? form.animalTypeId : "",
      onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
    };

    dispatch(loading(true));
    try {
      const response = await API.getDiseaseControl(params);
      const data = await response.data;
      // console.log("params---getDiseaseControl--🐳🐳", params, response);

      if (response.status == 200) {
        dispatch(loading(false));
        await setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        await setListDiseaseControl(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const mapDataToSelect = (data) => {
    return data.map((element) => {
      return { value: element.id, label: element.name };
    });
  };

  const getZoneLivestock = async (onload = false) => {
    if (onload) {
      return API.getZoneLivestock();
    } else {
      try {
        const response = await API.getZoneLivestock("", 1);
        const data = await response.data;
        if (response.status == 200) {
          setZoneLivestockList(mapDataToSelect(data.content));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getAnimal = async (onload = false) => {
    if (onload) {
      return API.getAnimal("", 1);
    } else {
      try {
        const response = await API.getAnimal("", 1);
        const data = await response.data;
        if (response.status == 200) {
          setAnimalList(mapDataToSelect(data.content));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getProvince = async (onload = false) => {
    if (onload) {
      return API.getProvince("", 1);
    } else {
      try {
        const response = await API.getProvince("", 1);
        const data = await response.data;
        if (response.status == 200) {
          setProvinceList(mapDataToSelect(data.content));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getProvinces = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;

      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;

      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setDistrictList([]);
    setSubDistrictSelectValue([]);
    setSubDistrictList([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setSubDistrictList([]);
    setForm({ ...form, districtId: value.value });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value });
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    // { path: "title", name: "Follow up" },
    { path: "title", name: "กคร.3/ สคส.1" },
    { path: "active", name: "ภาวการณ์ระบาดของโรค/การควบคุมโรค (กคร.3 /สคส.1)" },
  ];

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setAnimalSelectValue([]);
    setZoneLivestockSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <ButtonReportLink path={"disease_control"} linkSize={1} params={{
          obCode: form.outbreakId ? form.outbreakId : "",
          caseCode: form.caseCode ? form.caseCode : "",
          dateBegin: form.dateBegin ? helper.getDate(form.dateBegin) : "",
          dateEnd: form.dateEnd ? helper.getDate(form.dateEnd) : "",
          zoneId: form.zoneId ? form.zoneId : "",
          provinceId: form.provinceId ? form.provinceId : "",
          amphurId: form.districtId ? form.districtId : "",
          tambonId: form.subDistrictId ? form.subDistrictId : "",
          animalTypeId: form.animalTypeId ? form.animalTypeId : "",
          onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
        }}/>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const buttonAdd = () => {
    return (
      <button
        className="btn btn-info text-white"
        disabled={user?.userGroup.mcreate === "n"}
        onClick={() => history.push(`/passive_surv/disease_control/save`)}
      >
        <i className="fas fa-plus mr-1 "></i>เพิ่มการควบคุมโรค
      </button>
    );
  };

  const getFilter = (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const viewDetails = (id) => {
    history.push(`/passive_surv/disease_control/edit/${id}`);
  };

  const deleteDisease = (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบรายการควบคุมโรคนี้`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteDiseaseControl(id);
          if (response.status == 200) {
            await getDiseaseControl();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const duplicate = (id) => {
    MySwal.fire({
      text: `ยืนยันการคัดลอกข้อมูล`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        history.push(`/passive_surv/disease_control/duplicate/${id}`);
      }
    });
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item.caseCode ? item.caseCode : "-"}</td>
          <td>
            {item.operateDate ? helper.getDateFormat(item.operateDate) : "-"}
          </td>
          <td>{item.animalTypeName}</td>
          <td>{item.totalAmt}</td>
          <td>{item.firstSick ? item.firstSick : "0"}</td>
          <td>{item.sickDeadAmt ? item.sickDeadAmt : "0"}</td>
          <td>{item.sickAmt ? item.sickAmt : "0"}</td>
          <td>{item.recoveryAmt ? item.recoveryAmt : "0"}</td>
          <td>{item.remainSick ? item.remainSick : "0"}</td>
          <td>{item.firstDeadAmt ? item.firstDeadAmt : "0"}</td>
          <td>{item.deadAmt ? item.deadAmt : "0"}</td>
          <td>
            <span>{helper.momentDate(item.lastDate, "th", "short")}</span>
          </td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => deleteDisease(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
              <button
                className="btn btn-copy"
                onClick={() => duplicate(item.id)}
                disabled={user?.userGroup.mcopy === "n"}
              >
                <img className="icon-btn " src="/svg/btn-copy.svg" alt="copy" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={14} />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ปี"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="year"
                    classLabel="normal"
                    selectValue={yearFilterList}
                    optionsList={optionsListYear}
                    handleChange={(value) => setYearFilterList(value)}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เลข Outbreak ID"
                    idName="outbreakId"
                    value={form.outbreakId}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, outbreakId: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เลข case ID"
                    idName="caseCode"
                    value={form.caseCode}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, caseCode: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่ปฏิบัติงาน"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateBegin}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, dateBegin: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateEnd}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, dateEnd: value })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขตปศุสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={zoneLivestockSelectValue}
                    optionsList={zoneLivestockList}
                    handleChange={(value) => {
                      setZoneLivestockSelectValue([value]);
                      setForm({ ...form, zoneId: value.value });
                      getProvinces(value.value);
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={animalSelectValue}
                    optionsList={animalList}
                    handleChange={(value) => {
                      setAnimalSelectValue([value]);
                      setForm({ ...form, animalTypeId: value.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการ กคร.3/ สคส.1"
            headRight={buttonAdd()}

          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTable(listDiseaseControl)}
            </DataTable>
          </BoxCard>

          <div className="mt-4 px-2 font12 text-gray">
            <p className="mb-0">
              สัดส่วนการป่วยคิดเป็นร้อยละ (สัตว์ป่วยสะสม/สัตว์ทั้งหมด) X 100 -
              78.87{" "}
            </p>
            <p className="">
              สัดส่วนการตายคิดเป็นร้อยละ (สัตว์ตายสะสม/สัตว์ทั้งหมด) X 100 - 0
            </p>

            <p className="mb-0">
              หมายเหตุ: สำรวจในพื้นที่รัศมี ๕ กิโลเมตร รอบจุดเกิดโรค
              และอาจขยายวงหากมีหลักฐานทางระบาดวิทยาที่เชื่อมโยงในพื้นที่
            </p>
            <p className="mb-0">ป่วย = ป่วย + ตาย</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Disease_Control;
