import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";
import UploadImage from "../../components/UploadImage";

import '../../assets/css/style.css'


function ModalBanners({ show, close, save, mode, idEdit }) {
    const user = useSelector(state => state.login.result);
    const initialForm = {
        pictureFile: "",
        pictureName: "",
        checkNewPic: false,
        banners_name: "",
        createDate: "",
        toDate: "",
        status: "",

        createDate: "",
        updateDate: "",
        createByName: "",
        updateByName: "",
        checkShow: false
    };
    const initInvalidMsg = {
        status: "",
    };
    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];
    const initStatusSelectValue = [];

    const dispatch = useDispatch();
    const history = useHistory();

    const [form, setForm] = useState(initialForm);
    const [invalid, setInvalid] = useState(initInvalidMsg);
    const [statusSelectValue, setStatusSelectValue] = useState(initStatusSelectValue);
    const [statusList, setStatusList] = useState(selectStatusList);

    useEffect(async () => {
        if (mode === "add") {
            setForm(initialForm)
            setStatusSelectValue([])
        }
    }, [mode])

    useEffect(async () => {
        if (mode === "edit" && !form.checkShow) {
            await getBannersById(idEdit)
        }
    }, [idEdit, form.banners_name, form.status])

    const getBannersById = async (id) => {
        let dataBns;
        try {
            const response = await API.getBannersById(id);
            const dataRes = await response.data;
            if (response.status === 200) {
                dataBns = dataRes;
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }))
            }
            console.log(error)
        }

        if (dataBns.statusId) {
            let statusSelect = await statusList.find(item => item.value === dataBns.statusId);
            setStatusSelectValue(statusSelect);
        }

        setForm({
            ...form,
            pictureFile: dataBns.url,
            status: dataBns.statusId,
            banners_id: dataBns.id,
            banners_name: dataBns.name,
            createDate: dataBns.createDate,
            updateDate: dataBns.updateDate,
            createByName: dataBns.createByName,
            updateByName: dataBns.updateByName,
        })
    }

    const validate = () => {
        let validated = true;

        if (form.banners_name === "") {
            addInvalid("banners_name", "โปรดระบุหัวข้อ");
            validated = false;
        }

        if (form.status === "") {
            addInvalid("status", "กรุณาเลือกสถานะ");
            validated = false;
        }

        return validated;
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setStatusSelectValue([])

    }

    const toConfirm = async () => {
        if (validate()) {
            await handleClose();
            await save({ banners: form, mode: mode });
        }
    };

    const getPicture = async (name, file) => {
        setForm({ ...form, pictureFile: file, pictureName: name, checkNewPic: true })
    }

    const deleteImgPicture = async () => {
        setForm({ ...form, pictureFile: "", pictureName: "" })
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => { handleClose() }}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่มข่าวประชาสัมพันธ์</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <InputText type="text" title="หัวข้อ" value={form.banners_name} idName="banners_name" star={true}
                                    placeholder="โปรดระบุ" classLabel="normal"
                                    handleChange={(value) =>
                                        setForm({ ...form, banners_name: value, checkShow: true })}
                                    handleInvalid={() => removeInvalid("")} invalid={invalid.banners_name}
                                />
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                                <UploadImage title="อัปโหลดรูปภาพ" classLabel="bold" star={false} type="img" typeSave="base64"
                                    onChange={(name, file) => getPicture(name, file)} id="pictureFile" position="top"
                                    deleteImg={() => deleteImgPicture()}
                                    files={(form.pictureFile === null || form.pictureFile === "") ? "" : form.pictureFile} />
                            </Col>
                        </div>
                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                        selectValue={statusSelectValue} optionsList={statusList}
                                        handleSelectValue={(value) => setStatusSelectValue([{ value: value.value, label: value.label },])}
                                        handleChange={(value) => setForm({ ...form, status: value.value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("status")} invalid={invalid.status}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={3} sm={6} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mr-4">วันที่บันทึกข้อมูล</p>
                                    </div>
                                </Col>
                                <Col lg={10} md={9} sm={6} xs={12}>
                                    <div className="text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mb-0">{form.createDate ? helper.momentDate(form.createDate, "th", "short") : '-'}</p>
                                        <p>{form.createByName ? form.createByName : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={3} sm={6} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                                        <p className="mr-3" >วันที่ปรับปรุงข้อมูล</p>
                                    </div>
                                </Col>
                                <Col lg={10} md={9} sm={6} xs={12}>
                                    <div className="text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mb-0">{form.updateDate ? helper.momentDate(form.updateDate, "th", "short") : '-'}</p>
                                        <p>{form.updateByName ? form.updateByName : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalBanners
