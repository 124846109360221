import React, { useState, useEffect } from "react";
import * as API from "../utils/apis";
import { Modal, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function ModalEditAnimal({
  show,
  close,
  save,
  dataHouseList,
  mode,
  dataAnimal,
  dataIndex,
  dataRes,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);
  const { t } = useTranslation(["common", "footer"]);

  const initialForm = {
    limsCaseId: "",
    sampleKeepTypeId: "",
    sampleKeepTypeName: "",
    animalTypeName: "",
    animalTypeId: "",
    sampleAmt: "",

    animalName: "",
    sex: "",
    genderType: "",
    house_buiding: "",
    barnId: "",
    ageYear: "",
    ageMonth: "",
    microchip: "",
    species: "",
    nid: "",
    id: "",
  };
  const [form, setForm] = useState(initialForm);

  const initInvalidMsg = {
    animalTypeName: "",
    sex: "",
    house_buiding: "",
    age_year: "",
    age_month: "",
    choose_disease: "",
    nid: "",
    microchip: "",
    animalName: "",
    sampleKeepTypeName: "",
    sampleAmt: "",

    resultsStatus: "",
    resultsTypeName: "",
    immuneOTypeId: "",
    immuneOTypeName: "",
    immuneATypeId: "",
    immuneATypeName: "",
    immuneA1TypeId: "",
    immuneA1TypeName: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const initGenderSelectValue = [];
  const [genderSelectValue, setGenderSelectValue] = useState(
    initGenderSelectValue
  );
  const selectGenderList = [
    { value: "m", label: "ตัวผู้" },
    { value: "f", label: "ตัวเมีย" },
    { value: "n", label: "ไม่ทราบ" },
  ];
  const [genderList, setGenderList] = useState(selectGenderList);

  const [buidingSelectValue, setBuidingSelectValue] = useState([]);
  const [houseList, setHouseList] = useState([]);

  const [sampleKeepTypeIdSelectValue, setSmpleKeepTypeIdSelectValue] = useState(
    []
  );
  const [sampleKeepTypeIdList, setSmpleKeepTypeIdList] = useState([]);

  const [resultsSelectValue, setResultsSelectValue] = useState([]);
  const selectResultsList = [
    { value: "1", label: "ตรวจไม่ได้" },
    { value: "2", label: "ผลบวก(+)" },
    { value: "3", label: "ผลลบ(-)" },
  ];
  const [dataResultsList, setResultsList] = useState(selectResultsList);

  const [immuneOSelectValue, setImmuneOSelectValue] = useState([]);
  const selectImmuneOList = [
    { value: "1", label: "< 40" },
    { value: "2", label: "40" },
    { value: "3", label: "80" },
    { value: "4", label: "160" },
    { value: "5", label: "320" },
    { value: "6", label: "640" },
    { value: "7", label: "1280" },
    { value: "8", label: "2560" },
    { value: "9", label: "5120" },
    { value: "10", label: "> 5120" },
  ];
  const [dataImmuneOList, setImmuneOList] = useState(selectImmuneOList);

  const [immuneASelectValue, setImmuneASelectValue] = useState([]);
  const selectImmuneAList = [
    { value: "1", label: "< 40" },
    { value: "2", label: "40" },
    { value: "3", label: "80" },
    { value: "4", label: "160" },
    { value: "5", label: "320" },
    { value: "6", label: "640" },
    { value: "7", label: "1280" },
    { value: "8", label: "2560" },
    { value: "9", label: "5120" },
    { value: "10", label: "> 5120" },
  ];
  const [dataImmuneAList, setImmuneAList] = useState(selectImmuneOList);

  const [immuneA1SelectValue, setImmuneA1SelectValue] = useState([]);
  const selectImmuneA1List = [
    { value: "1", label: "< 40" },
    { value: "2", label: "40" },
    { value: "3", label: "80" },
    { value: "4", label: "160" },
    { value: "5", label: "320" },
    { value: "6", label: "640" },
    { value: "7", label: "1280" },
    { value: "8", label: "2560" },
    { value: "9", label: "5120" },
    { value: "10", label: "> 5120" },
  ];
  const [dataImmuneA1List, setImmuneA1List] = useState(selectImmuneA1List);

  useEffect(async () => {
    await getAnimal();
    await getSampleType();
  }, []);

  useEffect(async () => {
    if (mode === "edit") {
      await getDataById(dataAnimal);
      console.log(dataAnimal);
    }
  }, [user, mode, dataAnimal, show, animalList, sampleKeepTypeIdList]);

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSampleType = async () => {
    try {
      const response = await API.getSampleType(1, "");
      const data = await response.data;
      if (response.status === 200) {
        let sampleArray = [];
        data.content.forEach((element) => {
          sampleArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setSmpleKeepTypeIdList(sampleArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimalByLims = async (limsCaseId, index) => {
    const res_test = {
      id: null,
      ageMonth: "1",
      ageYear: "1",
      animalName: "ดำ",
      animalTypeId: 11000000,
      barnId: 1378,
      sampleId: 79,
      limsCaseId: "R64A00282",
      microchip: "112233",
      nid: "1001001",
      sampleAmt: 3,
      sampleKeepTypeId: 1,
      sex: "f",
      species: "พันธุ์ก",
      resultsStatus: 2,
      immuneO: "< 40",
      immuneA: "> 5120",
      immuneA1: "320",
      testMethod: "",
    };

    try {
      const response = await API.getAnimalByLims(limsCaseId);
      const data = await response.data;
      if (response.status === 200) {
        getAnimalData(data, index);
      }
    } catch (error) {
      MySwal.fire({
        text: "ไม่พบเลขเคสรับตัวอย่าง LIMS ",
        icon: "warning",
        showCloseButton: true,
        confirmButtonText: "ตกลง",
      });
      console.log(error);
    }
    // getAnimalData(res_test, index)
  };

  const getAnimalData = (dataAnimal) => {
    if (dataAnimal) {
      if (dataAnimal.sampleKeepTypeId) {
        let sampleKeepTypeIdSelect = sampleKeepTypeIdList.find(
          (item) => item.value === dataAnimal.sampleKeepTypeId
        );
        setSmpleKeepTypeIdSelectValue(sampleKeepTypeIdSelect);
      }

      if (dataAnimal.animalTypeId) {
        let animalSelect = animalList.find(
          (item) => item.value === dataAnimal.animalTypeId
        );
        setAnimalSelectValue(animalSelect);
      }

      if (dataAnimal.sex) {
        let genderSelect = genderList.find(
          (item) => item.value === dataAnimal.sex
        );
        setGenderSelectValue(genderSelect);
      }

      if (dataAnimal.barnId) {
        let houseSelect = dataHouseList.find(
          (item) => item.value === dataAnimal.barnId
        );
        setBuidingSelectValue(houseSelect);
      }

      if (dataAnimal.resultsTypeName) {
        let resultsSelect = dataResultsList.find(
          (item) => item.label == dataAnimal.resultsTypeName
        );
        setResultsSelectValue(resultsSelect);
      }

      if (dataAnimal.immuneO) {
        let immuneOSelect = dataImmuneOList.find(
          (item) => item.label === dataAnimal.immuneO
        );
        setImmuneOSelectValue(immuneOSelect);
      }

      if (dataAnimal.immuneA) {
        let immuneASelect = dataImmuneAList.find(
          (item) => item.label === dataAnimal.immuneA
        );
        setImmuneASelectValue(immuneASelect);
      }

      if (dataAnimal.immuneA1) {
        let immuneA1Select = dataImmuneA1List.find(
          (item) => item.label === dataAnimal.immuneA1
        );
        setImmuneA1SelectValue(immuneA1Select);
      }

      console.log();

      setForm({
        ...form,
        limsCaseId: dataAnimal.limsCaseId || "",
        barnId: dataAnimal.barnId || "",
        house_buiding: dataAnimal.house_buiding || "",
        animalTypeId: dataAnimal.animalTypeId || "",
        animalTypeName: dataAnimal.animalTypeName || "",
        sampleKeepTypeName: dataAnimal.sampleKeepTypeName || "",
        sampleKeepTypeId: dataAnimal.sampleKeepTypeId || "",
        sampleAmt: dataAnimal.sampleAmt || "",
        animalName: dataAnimal.animalName || "",
        genderType:
          dataAnimal.sex === "m"
            ? "ตัวผู้"
            : dataAnimal.sex === "f"
            ? "ตัวเมีย"
            : "ไม่ทราบ",
        sex: dataAnimal.sex || "",
        species: dataAnimal.species || "",
        ageYear: dataAnimal.ageYear || "",
        ageMonth: dataAnimal.ageMonth || "",
        nid: dataAnimal.nid || "",
        microchip: dataAnimal.microchip || "",
        resultsStatus: dataAnimal.resultsStatus || "",
        resultsTypeName: dataAnimal.resultsTypeName || "",
        immuneOTypeId: dataAnimal.immuneOTypeId || "",
        immuneOTypeName: dataAnimal.immuneO || "",
        immuneATypeId: dataAnimal.immuneATypeId || "",
        immuneATypeName: dataAnimal.immuneA || "",
        immuneA1TypeId: dataAnimal.immuneA1TypeId || "",
        immuneA1TypeName: dataAnimal.immuneA1 || "",
        //   testMethod"
        // sampleId
      });
    }
  };

  const getDataById = async () => {
    if (dataAnimal) {
      if (dataAnimal.sampleKeepTypeId) {
        let sampleKeepTypeIdSelect = sampleKeepTypeIdList.find(
          (item) => item.value === dataAnimal.sampleKeepTypeId
        );
        setSmpleKeepTypeIdSelectValue(sampleKeepTypeIdSelect);
      }

      if (dataAnimal.animalTypeId) {
        let animalSelect = animalList.find(
          (item) => item.value === dataAnimal.animalTypeId
        );
        setAnimalSelectValue(animalSelect);
      }

      if (dataAnimal.sex) {
        let genderSelect = genderList.find(
          (item) => item.value === dataAnimal.sex
        );
        setGenderSelectValue(genderSelect);
      }

      if (dataAnimal.barnId) {
        console.log("dataAnimal.house_buiding_id", dataAnimal.barnId);
        console.log("houseSelect", dataHouseList);
        let houseSelect = dataHouseList.find(
          (item) => item.value === dataAnimal.barnId
        );
        setBuidingSelectValue(houseSelect);
      }

      if (dataAnimal.resultsStatus) {
        let resultsSelect = dataResultsList.find(
          (item) => item.value == dataAnimal.resultsStatus
        );
        setResultsSelectValue(resultsSelect);
      }

      if (dataAnimal.immuneO) {
        let immuneOSelect = dataImmuneOList.find(
          (item) => item.label === dataAnimal.immuneO
        );
        setImmuneOSelectValue(immuneOSelect);
      }

      if (dataAnimal.immuneA) {
        let immuneASelect = dataImmuneAList.find(
          (item) => item.label === dataAnimal.immuneA
        );
        setImmuneASelectValue(immuneASelect);
      }

      if (dataAnimal.immuneA1) {
        let immuneA1Select = dataImmuneA1List.find(
          (item) => item.label === dataAnimal.immuneA1
        );
        setImmuneA1SelectValue(immuneA1Select);
      }
      setForm({
        ...form,
        id: dataAnimal.id,
        limsCaseId: dataAnimal.limsCaseId,
        barnId: dataAnimal.barnId,
        house_buiding: dataAnimal.house_buiding,
        animalTypeId: dataAnimal.animalTypeId,
        animalTypeName: dataAnimal.animalTypeName,
        sampleKeepTypeName: dataAnimal.sampleKeepTypeName,
        sampleKeepTypeId: dataAnimal.sampleKeepTypeId,
        sampleAmt: dataAnimal.sampleAmt,
        animalName: dataAnimal.animalName,
        genderType: dataAnimal.genderType,
        sex: dataAnimal.sex,
        species: dataAnimal.species,
        ageYear: dataAnimal.ageYear,
        ageMonth: dataAnimal.ageMonth,
        nid: dataAnimal.nid,
        microchip: dataAnimal.microchip,
        resultsStatus: dataAnimal.resultsStatus,
        resultsTypeName: dataAnimal.resultsTypeName,
        immuneOTypeId: dataAnimal.immuneOTypeId,
        immuneOTypeName: dataAnimal.immuneO,
        immuneATypeId: dataAnimal.immuneATypeId,
        immuneATypeName: dataAnimal.immuneA,
        immuneA1TypeId: dataAnimal.immuneA1TypeId,
        immuneA1TypeName: dataAnimal.immuneA1,
      });
    }
  };

  const handleClose = () => {
    close();
    setForm(initialForm);
    setBuidingSelectValue([]);
    setSmpleKeepTypeIdSelectValue([]);
    setAnimalSelectValue([]);
    setGenderSelectValue([]);
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;
    // console.log('form :>> ', form);
    if (form.animalTypeName === "") {
      addInvalid("animalTypeName", "กรุณาเลือกชนิดสัตว์");
      validated = false;
    }

    if (form.sex === "") {
      addInvalid("sex", "กรุณาเลือกเพศ");
      validated = false;
    }

    if (form.house_buiding === "") {
      addInvalid("house_buiding", "กรุณาเลือกโรงเรือน");
      validated = false;
    }

    if (form.ageYear === "") {
      addInvalid("age_year", "กรุณาระบุอายุปี");
      validated = false;
    }

    if (form.ageMonth === "") {
      addInvalid("age_month", "กรุณาระบุอายุเดือน");
      validated = false;
    }

    if (form.sampleKeepTypeName === "") {
      addInvalid("sampleKeepTypeName", "ระบุตัวอย่างที่เก็บ");
      validated = false;
    }

    if (form.sampleAmt === "") {
      addInvalid("sampleAmt", "ระบุจำนวน");
      validated = false;
    }

    if (form.nid === "") {
      addInvalid("nid", "ระบุหมายเลขประจำตัวสัตว์");
      validated = false;
    }

    if (form.microchip === "") {
      addInvalid("microchip", "ระบุหมายเลขไมโครชิพ");
      validated = false;
    }

    if (form.animalName === "") {
      addInvalid("animalName", "ระบุชื่อ");
      validated = false;
    }

    return validated;
  };

  const toSave = async () => {
    console.log("chekcFOrm", form);
    if (validate()) {
      handleClose();
      await save(form, dataIndex);
    }
  };

  const toSearch = () => {};

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header closeButton className="pb-2 underline">
        <Modal.Title className="mt-2">
          <h5>
            <strong>เพิ่ม/แก้ไขข้อมูลสัตว์รายตัว99</strong>
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Row>
          <Col lg={2} md={4} sm={6} className="d-flex ">
            <p className="mt-2">รายละเอียดสัตว์</p>
          </Col>
          {/* <Col lg={2} md={4} sm={6} className="">
            <div className="">
              <button className="btn btn-success " onClick={() => toSearch()}>
                <img
                  className="mr-2 mb-1 "
                  src={"/svg/search2.svg"}
                  alt="menu-icon"
                />
                ค้นหาสัตว์
              </button>
            </div>
          </Col> */}
        </Row>
        <Row className="mt-4">
          <Col lg={3} md={3} sm={12} xs={12}>
            <InputText
              title="เลขเคสรับตัวอย่าง LIMS"
              type="text"
              idName="limsCaseId"
              value={form.limsCaseId}
              star={false}
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) => setForm({ ...form, limsCaseId: value })}
            />
          </Col>
          <Col
            lg={2}
            md={2}
            sm={12}
            xs={12}
            className="d-flex align-items-center"
          >
            <div className="pt-sm-1 pt-md-3 pt-lg-3 w-100  ">
              <button
                className="btn btn-darkblue w-100 "
                disabled={form.limsCaseId === ""}
                onClick={async () => {
                  getAnimalByLims(form.limsCaseId);
                }}
              >
                <img className="mr-2 " src={"/svg/put.svg"} alt="menu-icon" />
                ดึงข้อมูล
              </button>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <p className="pt-sm-1 pt-md-3 pt-lg-3 text-gray mb-2 font12 ">
              *กรณีส่งตัวอย่างผ่านระบบ LIMS ให้ระบุเลขเคสรับตัวอย่าง จากเอกสาร
              รายงานผลการวิเคราะห์ ทดสอบ และชันสูตรโรคสัตว์
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={3} md={3} sm={12}>
            <InputSelect
              title={t("house_buiding")}
              classFormGroup="mb-0 w-100"
              star={true}
              placeholder="กรุณาเลือก"
              idName="house_buiding"
              classLabel="normal"
              selectValue={buidingSelectValue}
              optionsList={dataHouseList}
              handleChange={(value) => {
                setBuidingSelectValue(value);
                setForm({
                  ...form,
                  house_buiding: value.label,
                  barnId: value.value,
                });
              }}
              handleInvalid={() => removeInvalid("house_buiding")}
              invalid={invalid.house_buiding}
            />
          </Col>

          <Col lg={3} md={3} sm={12}>
            <InputSelect
              title={t("animal_type")}
              classFormGroup="mb-0 w-100"
              star={true}
              placeholder="กรุณาเลือก"
              idName="animalTypeName"
              classLabel="normal"
              selectValue={animalSelectValue}
              optionsList={animalList}
              handleChange={(value) => {
                setAnimalSelectValue(value);
                setForm({
                  ...form,
                  animalTypeId: value.value,
                  animalTypeName: value.label,
                });
              }}
              handleInvalid={() => removeInvalid("animalTypeName")}
              invalid={invalid.animalTypeName}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={3} md={3} sm={12} xs={12}>
            <InputSelect
              title="ตัวอย่างที่เก็บ"
              classFormGroup="mb-0 w-100"
              star={true}
              placeholder="กรุณาเลือก"
              idName="sampleKeepTypeName"
              classLabel="normal"
              selectValue={sampleKeepTypeIdSelectValue}
              optionsList={sampleKeepTypeIdList}
              handleChange={(value) => {
                setSmpleKeepTypeIdSelectValue(value);
                setForm({
                  ...form,
                  sampleKeepTypeId: value.value,
                  sampleKeepTypeName: value.label,
                });
              }}
              handleInvalid={() => removeInvalid("sampleKeepTypeName")}
              invalid={invalid.sampleKeepTypeName}
            />
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <InputText
              title="จำนวน"
              type="number"
              idName="sampleAmt"
              value={form.sampleAmt}
              star={true}
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) => setForm({ ...form, sampleAmt: value })}
              handleInvalid={() => removeInvalid("sampleAmt")}
              invalid={invalid.sampleAmt}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={3} md={6} sm={12}>
            <InputText
              type="text"
              title={t("first_name")}
              idName="animalName"
              value={form.animalName}
              star={true}
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) => setForm({ ...form, animalName: value })}
              handleInvalid={() => removeInvalid("animalName")}
              invalid={invalid.animalName}
            />
          </Col>

          <Col lg={3} md={6} sm={12}>
            <InputSelect
              title={t("gender")}
              classFormGroup="mb-0 w-100"
              star={true}
              placeholder="กรุณาเลือก"
              idName="sex"
              classLabel="normal"
              selectValue={genderSelectValue}
              optionsList={genderList}
              handleChange={(value) => {
                setGenderSelectValue([value]);
                setForm({ ...form, sex: value.value, genderType: value.label });
              }}
              handleInvalid={() => removeInvalid("sex")}
              invalid={invalid.sex}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <InputText
              title={t("breed")}
              type="text"
              idName="species"
              value={form.species}
              star={false}
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) => setForm({ ...form, species: value })}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={3} md={6} sm={12}>
            <div className="d-flex align-items-center">
              <InputText
                classFormGroup="mb-0"
                type="number"
                title={t("age")}
                idName="age_year"
                value={form.ageYear}
                star={true}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) => {
                  if (value < 0) {
                    return;
                  }
                  setForm({ ...form, ageYear: value });
                }}
                handleInvalid={() => removeInvalid("age_year")}
                // invalid={invalid.age_year ? true : false}
              />
              <p className="mb-0 font14 mt-4 mx-1">{t("year")}</p>
              <div className="mt-auto">
                <InputText
                  classFormGroup="mb-0"
                  type="number"
                  idName="age_month"
                  value={form.ageMonth}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) => {
                    if (value < 0) {
                      return;
                    }
                    setForm({ ...form, ageMonth: value });
                  }}
                  handleInvalid={() => removeInvalid("age_month")}
                />
              </div>
              <p className="mb-0 font14 mt-4 mx-1">{t("month")}</p>
            </div>
            <Row>
              <Col>
                <div className="invalid-error">{invalid.age_year}</div>
              </Col>
              <Col>
                <div className="invalid-error">{invalid.age_month}</div>
              </Col>
            </Row>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <InputText
              title="หมายเลขประจำตัวสัตว์"
              classFormGroup="mb-0"
              type="text"
              idName="nid"
              value={form.nid}
              star={true}
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) => setForm({ ...form, nid: value })}
              handleInvalid={() => removeInvalid("nid")}
              invalid={invalid.nid}
            />
          </Col>

          <Col lg={3} md={6} sm={12}>
            <InputText
              title={t("no_microchip")}
              type="text"
              idName="microchip"
              value={form.microchip}
              star={true}
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) => setForm({ ...form, microchip: value })}
              handleInvalid={() => removeInvalid("microchip")}
              invalid={invalid.microchip}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <InputSelect
              title="ผลตรวจรายตัว"
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="resultsTypeName"
              classLabel="normal"
              selectValue={resultsSelectValue}
              optionsList={dataResultsList}
              handleChange={(value) => {
                setResultsSelectValue(value);
                setForm({
                  ...form,
                  resultsStatus: value.value,
                  resultsTypeName: value.label,
                });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={2} md={3} sm={6} className="mt-3">
            <div>
              <h6 className="">ภูมิคุ้มกัน</h6>
            </div>
          </Col>
        </Row>
        <Row className=" bg-gray3">
          <Col
            lg={4}
            md={4}
            sm={12}
            className="d-flex justify-content-between "
          >
            <div className="d-flex align-items-center pt-sm-1 pt-md-3 pt-lg-3 w-100 ">
              <h6 className="mb-0 mr-2">โอ(O)</h6>
              <InputSelect
                title=""
                classFormGroup="mb-0 w-100"
                star={false}
                placeholder="กรุณาเลือก"
                idName="immuneOTypeName"
                classLabel="normal"
                selectValue={immuneOSelectValue}
                optionsList={dataImmuneOList}
                handleChange={(value) => {
                  setImmuneOSelectValue(value);
                  setForm({
                    ...form,
                    immuneOTypeId: value.value,
                    immuneO: value.label,
                  });
                }}
              />
            </div>
          </Col>

          <Col lg={4} md={4} sm={12} className="d-flex justify-content-between">
            <div className="d-flex align-items-center pt-sm-1 pt-md-3 pt-lg-3 w-100">
              <h6 className="mb-0 mr-2">เอ(A)</h6>
              <InputSelect
                title=""
                classFormGroup="mb-0 w-100"
                star={false}
                placeholder="กรุณาเลือก"
                idName="immuneATypeName"
                classLabel="normal"
                selectValue={immuneASelectValue}
                optionsList={dataImmuneAList}
                handleChange={(value) => {
                  setImmuneASelectValue(value);
                  setForm({
                    ...form,
                    immuneATypeId: value.value,
                    immuneA: value.label,
                  });
                }}
              />
            </div>
          </Col>

          <Col lg={4} md={4} sm={12} className="d-flex justify-content-between">
            <div className="d-flex align-items-center pt-sm-1 pt-md-3 pt-lg-3 w-100">
              <h6 className="mb-0 mr-2">เอเชียวัน(A1)</h6>
              <InputSelect
                title=""
                classFormGroup="mb-0 w-100"
                star={false}
                placeholder="กรุณาเลือก"
                idName="immuneA1TypeName"
                classLabel="normal"
                selectValue={immuneA1SelectValue}
                optionsList={dataImmuneA1List}
                handleChange={(value) => {
                  setImmuneA1SelectValue(value);
                  setForm({
                    ...form,
                    immuneA1TypeId: value.value,
                    immuneA1: value.label,
                  });
                }}
              />
            </div>
          </Col>
        </Row>

        <div className="mr-2 mt-3 mb-2">
          <div className="text-right py-2">
            <button
              className="btn btn-danger px-4 mr-2"
              onClick={() => handleClose()}
            >
              ยกเลิก
            </button>
            <button className="btn btn-success px-4" onClick={() => toSave()}>
              บันทึก
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalEditAnimal;
