import React, { useState, useEffect } from "react";
import BoxCard from "../BoxCard";
import { Row, Col } from "react-bootstrap";
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import MapRadius from "../googleMap/MapRadius";
import DataTableNodata from "../DataTableNodata";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useMasterData } from "../../components/hooks/useMasterData";

import * as API from "../../utils/apis";

const MySwal = withReactContent(Swal);

function AddEpidemicZone({ formData, save, back, zoneActionType, dataEdit }) {
  const initialForm = {
    groupZone: [],
    checkAddZone: false,

    provinceId: "",
    provinceName: "",
    amphurId: "",
    amphurName: "",
    tambonId: "",
    tambonName: "",
    mooId: "",
    mooName: "",
    latitude: "",
    longitude: "",
    radius: "",
  };
  const [form, setForm] = useState(initialForm);

  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  useMasterData("province", provinceList, setProvinceList);

  useEffect(() => {
    getProvince();
    getToShow();
  }, []);

  useEffect(() => {
    if (zoneActionType === "edit") {
      setDataEdit();
    }
  }, [provinceList]);

  useEffect(() => {
    toSave();
  }, [form]);

  const setDataEdit = () => {
    setProvinceSelectValue([
      { value: dataEdit.provinceId, label: dataEdit.provinceName },
    ]);
    setDistrictSelectValue([
      { value: dataEdit.amphurId, label: dataEdit.amphurName },
    ]);
    setSubDistrictSelectValue([
      { value: dataEdit.tambonId, label: dataEdit.tambonName },
    ]);
    setVillageSelectValue([{ value: dataEdit.mooId, label: dataEdit.mooName }]);

    getDistricts(dataEdit.provinceId);
    getSubDistricts(dataEdit.amphurId);
    getVillage(dataEdit.tambonId);

    if (provinceList.length) {
      let provinceData = provinceList.find(
        (item) => item.value === dataEdit.provinceId
      );
      setForm({
        ...form,
        radius: dataEdit.radius,
        groupZone: formData.groupZone,
        latitude: provinceData.latitude,
        longitude: provinceData.longitude,
        provinceId: dataEdit.provinceId,
        provinceName: dataEdit.provinceName,
        amphurId: dataEdit.amphurId,
        amphurName: dataEdit.amphurName,
        tambonId: dataEdit.tambonId,
        tambonName: dataEdit.tambonName,
        mooId: dataEdit.mooId,
        mooName: dataEdit.mooName,
      });
    }
  };

  const toSave = () => {
    save(form.groupZone);
  };

  const getToShow = () => {
    setForm({ ...form, groupZone: formData.groupZone });
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince();
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
            latitude: element.latitude,
            longitude: element.longitude,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id);
      const data = await response.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
            latitude: element.latitude,
            longitude: element.longitude,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id);
      const data = await response.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            latitude: element.latitude,
            longitude: element.longitude,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id);
      const data = await response.data;
      if (response.status === 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
            latitude: element.latitude,
            longitude: element.longitude,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      provinceName: value.label,
      latitude: value.latitude,
      longitude: value.longitude,
      amphurId: "",
      amphurName: "",
      tambonId: "",
      tambonName: "",
      mooId: "",
      mooName: "",
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      amphurId: value.value,
      amphurName: value.label,
      latitude: value.latitude,
      longitude: value.longitude,
      tambonId: "",
      tambonName: "",
      mooId: "",
      mooName: "",
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      tambonId: value.value,
      tambonName: value.label,
      latitude: value.latitude,
      longitude: value.longitude,
      mooId: "",
      mooName: "",
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const addEpidZoneToTable = () => {
    if (!form.provinceId) {
      MySwal.fire({
        text: "กรุณาเลือกจังหวัด",
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
    } else {
      form.groupZone.push({
        id: "",
        provinceId: form.provinceId,
        provinceName: form.provinceName,
        amphurId: form.amphurId,
        amphurName: form.amphurName,
        tambonId: form.tambonId,
        tambonName: form.tambonName,
        mooId: form.mooId,
        mooName: form.mooName,
        latitude: form.latitude,
        longitude: form.longitude,
        radius: form.radius,
      });
      setForm({
        ...form,
        groupZone: form.groupZone,
        provinceId: "",
        provinceName: "",
        amphurId: "",
        amphurName: "",
        tambonId: "",
        tambonName: "",
        mooId: "",
        mooName: "",
        latitude: "",
        longitude: "",
        radius: "",
      });

      setProvinceSelectValue([]);
      setDistrictSelectValue([]);
      setSubDistrictSelectValue([]);
      setVillageSelectValue([]);
      setDistrictList([]);
      setSubDistrictList([]);
      setVillageList([]);

      toSave();
    }
  };

  const editEpidZoneToTable = () => {
    let data = {
      id: dataEdit.id || "",
      provinceId: form.provinceId,
      provinceName: form.provinceName,
      amphurId: form.amphurId,
      amphurName: form.amphurName,
      tambonId: form.tambonId,
      tambonName: form.tambonName,
      mooId: form.mooId,
      mooName: form.mooName,
      latitude: form.latitude,
      longitude: form.longitude,
      radius: form.radius,
    };

    form.groupZone[dataEdit.positionZoneEdit] = data;

    back(false);
  };

  const toBack = () => {
    if (zoneActionType === "edit") {
      form.groupZone[dataEdit.positionZoneEdit] = dataEdit;
    }
    back(false);
  };

  const buttonAddEpidZone = () => {
    return (
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-add text-white mr-2"
          onClick={() => toBack()}
        >
          กลับ
        </button>
        {zoneActionType === "add" ? (
          <button
            className="btn btn-add text-white d-flex align-items-center"
            onClick={() => addEpidZoneToTable()}
          >
            <>
              {" "}
              <i className="fas fa-plus mr-1 "></i> เพิ่มพื้นที่
            </>
          </button>
        ) : (
          <button
            className="btn btn-add text-white d-flex align-items-center"
            onClick={() => editEpidZoneToTable()}
          >
            <>
              {" "}
              <img
                className="img-fluid icon-btn"
                src="/svg/btn-edit.svg"
                alt="edit"
              />{" "}
              แก้ไขพื้นที่
            </>
          </button>
        )}
      </div>
    );
  };

  const renderTableZone = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td className="fitwidth">{item.provinceName || "-"}</td>
        <td className="fitwidth">{item.amphurName || "-"}</td>
        <td className="fitwidth">{item.tambonName || "-"}</td>
        <td className="fitwidth">{item.mooName || "-"}</td>
      </tr>
    );
  };

  return (
    <>
      <BoxCard
        classNameBox=""
        title="ลงข้อมูล พื้นที่เขตโรคระบาดสัตว์"
        headRight={buttonAddEpidZone()}
      >
        <Row>
          <Col lg={2} md={6} sm={6} xs={6}>
            <InputSelect
              title="จังหวัด"
              star={true}
              placeholder="กรุณาเลือก"
              idName="provinceId"
              classLabel="normal"
              selectValue={provinceSelectValue}
              optionsList={provinceList}
              handleChange={(value) => selectValueProvince(value)}
            />
          </Col>

          <Col lg={2} md={6} sm={6} xs={6}>
            <InputSelect
              title="อำเภอ"
              star={false}
              placeholder="กรุณาเลือก"
              idName="amphurId"
              classLabel="normal"
              selectValue={districtSelectValue}
              optionsList={districtList}
              handleChange={(value) => selectValueDistrict(value)}
            />
          </Col>
          <Col lg={2} md={2} sm={6} xs={6}>
            <InputSelect
              title="ตำบล"
              star={false}
              placeholder="กรุณาเลือก"
              idName="tambonId"
              classLabel="normal"
              selectValue={subDistrictSelectValue}
              optionsList={subDistrictList}
              handleChange={(value) => selectValueSubDistrict(value)}
            />
          </Col>
          <Col lg={2} md={2} sm={6} xs={6}>
            <InputSelect
              title="หมู่ที่"
              star={false}
              placeholder="กรุณาเลือก"
              idName="mooId"
              classLabel="normal"
              selectValue={villageSelectValue}
              optionsList={villageList}
              handleChange={(value) => {
                setVillageSelectValue([value]);
                setForm({ ...form, mooId: value.value, mooName: value.label });
              }}
            />
          </Col>
        </Row>

        <div className="text-gray mt-3 ml-1">
          <p className="mb-0 font14">
            1. กรณีเลือกพื้นที่ระดับจังหวัด ไม่ต้องระบุหมู่ที่ อำเภอเลือกไม่ระบุ
            และตำบลเลือกไม่ระบุ
          </p>
          <p className="mb-0 font14">
            2. กรณีเลือกพื้นที่ระดับอำเภอ ไม่ต้องระบุหมู่ที่ และตำบลเลือกไม่ระบุ
          </p>
          <p className="mb-0 font14">
            3. กรณีเลือกพื้นที่ระดับตำบล ไม่ต้องระบุหมู่ที่{" "}
          </p>
        </div>
      </BoxCard>

      <BoxCard classNameBox="mt-4" title="ค้นหาจากรัศมี">
        <div className="d-flex my-2">
          <h6 className="ml-1 mr-3 mt-2">กำหนดรัศมีโรคระบาด</h6>
          <InputText
            star={false}
            placeholder="โปรดระบุ"
            idName="radius"
            value={form.radius}
            handleChange={(value) => {
              const pattern = /^\d+$/;
              if (pattern.test(value) || !value) {
                setForm({ ...form, radius: value });
              }
            }}
          />
          <h6 className="ml-3 mt-2">กิโลเมตร</h6>
        </div>
        <div className="google-map">
          <MapRadius
            radius={form.radius}
            location={{
              lat: form.latitude ? Number(form.latitude) : 13.756,
              lng: form.longitude ? Number(form.longitude) : 100.501,
            }}
            getLocation={(value) => {
              setForm({ ...form, latitude: value.lat, longitude: value.lng });
            }}
          ></MapRadius>
        </div>
      </BoxCard>

      {zoneActionType === "add" && (
        <BoxCard classNameBox="mt-4" title="พื้นที่เขตโรคระบาดสัตว์">
          <table className="table table-striped table-bordered my-2 ">
            <thead className="text-center">
              <tr>
                <th scope="col" className="order-width">
                  ลำดับ
                </th>
                <th scope="col">จังหวัด</th>
                <th scope="col">อำเภอ/เขต</th>
                <th scope="col">ตำบล/แขวง</th>
                <th scope="col">หมู่บ้าน</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {form.groupZone && form.groupZone.length > 0
                ? form.groupZone.map((item, i) => {
                    return renderTableZone(item, i);
                  })
                : ""}
              {form.groupZone && form.groupZone.length === 0 && (
                <DataTableNodata colSpan={6} />
              )}
            </tbody>
          </table>
        </BoxCard>
      )}
    </>
  );
}

export default AddEpidemicZone;
