import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import DataTable from "../../components/DataTable";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";
import { logout } from "../../redux/actions/logoutAction";
import withReactContent from "sweetalert2-react-content";
import ButtonReportLink from "../../components/ButtonReportLink";

import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

function Action_Plan() {
  const user = useSelector((state) => state.login.result);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["common", "footer"]);

  const initialForm = {
    typeUser: "1",
    fiscalYear: "",
    project: "",
    dated: "",
    todate: "",
    namep: "",
    status: "",
    animal: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        name: "ลำดับ",
        sortFlg: false,
        width: "50px",
      },
      {
        sortBy: "year",
        name: "ปีงบประมาณ",
        sortFlg: true,
      },

      {
        sortBy: "projectName",
        name: "โครงการ",
        sortFlg: true,
      },
      {
        sortBy: "id",
        name: "วัตถุประสงค์",
        sortFlg: true,
      },

      {
        sortBy: "dateBegin",
        name: "ช่วงเวลาดำเนินการ",
        sortFlg: true,
      },

      {
        sortBy: "updateName",
        name: "วันที่ปรับปรุงข้อมูล",
        sortFlg: true,
      },

      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },

      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],

    statusActionId: [
      {
        id: 1,
        color: "#FFC107",
        status: "กำลังดำเนินการ",
      },
      {
        id: 2,
        color: "#FF482F",
        status: "สิ้นสุดโครงการ",
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listAction, setListAction] = useState([]);

  // select  ปีงบประมาณ  โครงการ สถานะ  ชนิดสัตว์
  const initFiscalYearSelectValue = [];
  const [fiscalYearSelectValue, setFiscalYearSelectValue] = useState(
    initFiscalYearSelectValue
  );
  const selectFiscalYearList = [];
  const [fiscalYearList, setFiscalYearList] = useState(selectFiscalYearList);

  const initProjectSelectValue = [];
  const [projectSelectValue, setProjectSelectValue] = useState(
    initProjectSelectValue
  );
  const selectProjectList = [];
  const [projectList, setProjectList] = useState(selectProjectList);

  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    {
      value: 1,
      label: "กำลังดำเนิน",
    },
    {
      value: 2,
      label: "สิ้นสุดโครงการ",
    },
  ];

  const [statusList, setStatusList] = useState(selectStatusList);
  const [isClearSearch, setIsClearSearch] = useState(false);

  const initAnimalSelectValue = [];
  const [AnimalSelectValue, setAnimalSelectValue] = useState(
    initAnimalSelectValue
  );
  const selectAnimalList = [];
  const [AnimalList, setAnimalList] = useState(selectAnimalList);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/active_surv/action_plan", name: "การเฝ้าระวังเชิงรุก" },
    { path: "title", name: "แผนการดำเนินงาน" },
    { path: "active", name: "รายการแผนการดำเนินงาน" },
  ];

  useEffect(async () => {
    await getActionPlanYear();
    await getActionProject();
    await getAnimal();
  }, []);

  useEffect(async () => {
    await getActionPlan();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getActionPlanYear = async () => {
    try {
      const response = await API.getActionPlanYear("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let yearArray = [];
        data.forEach((element) => {
          yearArray.push({
            value: element,
            label: element,
          });
        });
        await setFiscalYearList(yearArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActionProject = async () => {
    try {
      const response = await API.getActionProject("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let projectArray = [];
        data.forEach((element) => {
          projectArray.push({
            value: element,
            label: element,
          });
        });
        await setProjectList(projectArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActionPlan = async () => {
    const params = await {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",
      year: form.fiscalYear ? form.fiscalYear : "",
      projectName: form.project ? form.project : "",
      dateBegin: form.dated ? helper.getDate(form.dated) : "",
      dateEnd: form.todate ? helper.getDate(form.todate) : "",
      search: form.namep ? helper.getDate(form.namep) : "",
      statusId: form.status ? form.status : "",
      animalTypeId: form.animal ? form.animal : "",
    };
    try {
      const response = await API.getActionPlan(params);

      const data = await response.data;
      if (response.status == 200) {
        await setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        await setListAction(data.content);
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setFiscalYearSelectValue([]);
    setProjectSelectValue([]);
    setStatusSelectValue([]);
    setAnimalSelectValue([]);

    setIsClearSearch(!isClearSearch);
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type == "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type == "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (form.statusActionId && form.statusActionId.length > 0) {
      form.statusActionId.map((item, index) => {
        if (item.id == idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-2 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const buttonSearch = () => {
    return (
      <>
        <ButtonReportLink path={"action_plan"} params={{
          year: form.fiscalYear ? form.fiscalYear : "",
          projectName: form.project ? form.project : "",
          dateBegin: form.dated ? helper.getDate(form.dated) : "",
          dateEnd: form.todate ? helper.getDate(form.todate) : "",
          search: form.namep ? helper.getDate(form.namep) : "",
          statusId: form.status ? form.status : "",
          animalTypeId: form.animal ? form.animal : "",
        }}/>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const buttonAdd = () => {
    return (
      <button
        className="btn btn-info "
        disabled={user?.userGroup.mcreate === "n"}
        onClick={() => history.push(`/active_surv/action_plan/disease_record`)}
      >
        <div className="fas fa-plus mr-1 "></div>เพิ่มรายการ
      </button>
    );
  };

  const viewDetails = (id) => {
    history.push(`/active_surv/action_plan/edit/${id}`);
  };

  const deleteAction = (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบรายการแผนการดำเนินงาน`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteActionPlan(id);
          if (response.status == 200) {
            await getActionPlan();
          }
        } catch (error) {
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td className="text-center">{item.year}</td>
          <td>
            <p className="font14 mb-0">{item.projectName}</p>
          </td>
          <td>{item.purpose} </td>
          <td>
            <p className="font14 mb-0">
              {item.startDate
                ? helper.fullDateTh(item.startDate, "th", "long")
                : "-"}
            </p>
            <p className="font14 mb-0">
              {item.endDate
                ? helper.fullDateTh(item.endDate, "th", "long")
                : "-"}
            </p>
          </td>
          <td className="text-center">
            {item.updateDate
              ? helper.fullDateTh(item.updateDate, "th", "long")
              : "-"}
          </td>
          <td className="text-center">
            {item.statusId ? getStatusColor(item.statusId) : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => deleteAction(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="8" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ปีงบประมาณ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={fiscalYearSelectValue}
                    optionsList={fiscalYearList}
                    handleSelectValue={(value) =>
                      setFiscalYearSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, fiscalYear: value.value })
                    }
                  />
                </Col>

                <Col lg={4} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="โครงการ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={projectSelectValue}
                    optionsList={projectList}
                    handleSelectValue={(value) =>
                      setProjectSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, project: value.value })
                    }
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ระหว่างวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dated}
                    maxDate={true}
                    star={false}
                    handleChange={(value) => setForm({ ...form, dated: value })}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.todate}
                    star={false}
                    minDate={form.dated}
                    maxDate={"afterSelectDate"}
                    handleChange={(value) =>
                      setForm({ ...form, todate: value })
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={statusList}
                    handleSelectValue={(value) =>
                      setStatusSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, status: value.value })
                    }
                  />
                </Col>

                <Col lg={4} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ค้นหา"
                    idName=" namep"
                    value={form.namep}
                    star={false}
                    placeholder="ชื่อโครงการ/วัตถุประสงค์"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, namep: value })}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={AnimalSelectValue}
                    optionsList={AnimalList}
                    handleSelectValue={(value) =>
                      setAnimalSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, animal: value.value })
                    }
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการแผนการดำเนินงาน"
            headRight={buttonAdd()}

          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTable(listAction)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}
export default Action_Plan;
