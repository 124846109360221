import * as actionTypes from "../actions/masterDataTypes";

export const initialState = {
  province: [],
  zoneLivestock: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.PROVINCE_SUCCESS:
      return {
        ...state,
        province: payload,
      };
    case actionTypes.PROVINCE_FAILED:
      return { ...state, province: [] };

    case actionTypes.ZONE_LIVESTOCK_SUCCESS:
      return {
        ...state,
        zoneLivestock: payload,
      };
    case actionTypes.ZONE_LIVESTOCK_FAILED:
      return { ...state, zoneLivestock: [] };

    default:
      return state;
  }
};
