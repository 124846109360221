import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import LineChart from "./LineChart";
import BoxCard from "./BoxCard";
import ReactToPrint from "react-to-print";
import * as helper from "../utils/helper";

function Report_Daily_Info(dataAll) {
  const [dataFarmDaily, setDataFarmDaily] = useState([]);
  const [animalTypeDaily, setAnimalTypeDaily] = useState([]);
  const [provinceAlert, setProvinceAlert] = useState([]);
  const [resultDaily, setResultDaily] = useState([]);
  const [graph, setGraph] = useState([]);
  const pdfRef = useRef(null);

  useEffect(() => {
    setData(dataAll.dataAll);
  }, [dataAll]);

  const initialForm = {
    date_from: "",
    date_to: "",
    diseaseTypeId: "",
    unknownAmt: "",
    plusAmt: "",
    minusAmt: "",
    percent: "",
  };
  const [form, setForm] = useState(initialForm);

  const setData = (data) => {
    if (Object.keys(data).length > 0) {
      let dataFarmDailyArray = [];
      let dataAnimalDailyArray = [];
      let dataProvinceAlertArray = [];

      if (data.farm.length > 0) {
        data.farm.forEach((element) => {
          dataFarmDailyArray.push({
            id: element.id,
            animalTypeId: element.animalTypeId,
            provinceId: element.provinceId,
            amphurId: element.amphurId,
            tambonId: element.tambonId,
            mooId: element.mooId,
            vaccineAmt: element.vaccineAmt,
            sickAmt: element.sickAmt,
            deadAmt: element.deadAmt,
            feedAmt: element.feedAmt,
            animalTypeName: element.animalTypeName,
            provinceName: element.provinceName,
            amphurName: element.amphurName,
            tambonName: element.tambonName,
            mooName: element.mooName,
            statusName: element.statusName,
            sickDate: element.sickDate,
          });
        });
        setDataFarmDaily(dataFarmDailyArray);
      }

      data.animalType.forEach((element) => {
        dataAnimalDailyArray.push({
          animalTypeId: element.animalTypeId,
          reportAmt: element.reportAmt,
          sickAmt: element.sickAmt,
          animalTypeName: element.animalTypeName,
        });
      });
      setAnimalTypeDaily(dataAnimalDailyArray);

      data.province.forEach((element) => {
        dataProvinceAlertArray.push(
          `${element.name} (${element.amt1}/${element.amt2}) `
        );
      });
      setProvinceAlert(dataProvinceAlertArray);
      setForm({
        ...form,
        unknownAmt: data.result.unknownAmt,
        plusAmt: data.result.plusAmt,
        minusAmt: data.result.minusAmt,
        percent: data.last1Year.percent,
      });
      setResultDaily(
        `ผลบวก (${data.result.plusAmt ? data.result.plusAmt : 0}) ผลลบ (${
          data.result.minusAmt ? data.result.minusAmt : 0
        }) ตรวจไม่ได้ (${data.result.unknownAmt ? data.result.unknownAmt : 0})`
      );
      setGraph(data.graph);
    }
  };

  const renderDataFarmDaily = (item, index) => {
    return (
      <tr key={`data-daily-${index}`} className="tr-report">
        <td className="td-report sub-width-sm">{index + 1}</td>
        <td className="td-report">{helper.getDateFormatTH(item.sickDate)}</td>
        <td className="td-report">{item.animalTypeName}</td>
        <td className="td-report">{item.provinceName}</td>
        <td className="td-report">{item.amphurName}</td>
        <td className="td-report">{item.tambonName}</td>

        <td className="td-report">{item.mooName}</td>
        <td className="td-report">{item.vaccineAmt}</td>
        <td className="td-report sub-width-sm">{item.sickAmt}</td>
        <td className="td-report sub-width-sm">{item.deadAmt}</td>
        <td className="td-report sub-width-sm">{item.feedAmt}</td>
        <td className="td-report">{item.statusName}</td>
      </tr>
    );
  };

  const renderDataAnimalDaily = (item, index) => {
    return (
      <tr key={`data-found-${index}`} className="tr-report">
        <td className="td-report">{item.animalTypeName}</td>
        <td className="td-report">{item.reportAmt}</td>
        <td className="td-report">{item.sickAmt}</td>
      </tr>
    );
  };

  const getPositiveProvince = () => {
    let text = [];
    const prov = dataAll.dataAll.last1Year?.province;
    if (prov && prov.length > 0) {
      prov.map((item, index) => {
        text.push(`${item.name} (${item.amt})`);
      });
    } else {
      text.push("(-)");
    }
    return text.toString();
  };

  const sumSample = () => {
    let label = Object.keys(dataAll.dataAll.graph);
    let lastYearLabel = label[label.length - 2];
    let lastYear = dataAll.dataAll.graph[`${lastYearLabel}`];
    let lastYearValue = Object.values(lastYear);
    return lastYearValue.reduce((a, b) => a + b, 0);
  };

  const getConclusion = (animal) => {
    let animalConclusion = [];
    let countPlusAmt = 0;
    animal.map((item, index) => {
      countPlusAmt = countPlusAmt + 1;
      animalConclusion.push(`${item.name} (${item.amt}) `);
    });

    return `มีการส่งตัวอย่างทั้งหมด ${sumSample()} ตัวอย่าง พบผลบวกทั้งหมด ${countPlusAmt} ตัวอย่าง (${
      form.percent
    }%) โดย 10 จังหวัดที่มีผลบวกสูงสุดได้แก่\n ${getPositiveProvince()}
        ชนิดสัตว์ที่พบโรคได้แก่ ${animalConclusion.toString() || "(-)"}`;
  };

  return (
    <div style={{ padding: "4% 5%" }} ref={pdfRef}>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="borderwidth-head d-flex justify-content-center bold">
                <img
                  style={{ width: "150px", height: "150px" }}
                  id="img-logo"
                  src="/DLD-Logo.png"
                />
                <span className="text-lg mt-4">
                  <div className="mb-3">
                    กลุ่มระบาดวิทยาทางสัตวแพทย์สํานักควบคุมป้องกันและบําบัดโรคสัตว์กรมปศุสัตว์
                  </div>
                  <div>โทรศัพท์ 02-6534412 อีเมล vetepidem@dld.go.th</div>
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="d-flex justify-content-center bold">
                <span className="text-md mt-5">
                  สถานการณ์อาการ{dataAll.dataAll.diseaseTypeName} ประจําวันที่{" "}
                  {helper.getTodayTh()}
                </span>
              </div>
              <div className="d-flex justify-content-center mt-5">
                <span className="text-md">
                  วันที่{" "}
                  {dataAll.dataAll.dateFrom
                    ? helper.getDateFormatTH(dataAll.dataAll.dateFroms)
                    : "-"}{" "}
                  ถึง{" "}
                  {dataAll.dataAll.dateTo
                    ? helper.getDateFormatTH(dataAll.dataAll.dateTo)
                    : "-"}
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="mt-5 mb-2">ตารางแสดงสถานการณ์โรคประจําวัน</div>
              <div className="text-md d-flex justify-content-center">
                <table className="table-report">
                  <thead>
                    <tr className="tr-report">
                      <th className="th-report width-sm" rowSpan="2">
                        ลำดับ
                      </th>
                      <th className="th-report" rowSpan="2">
                        วันที่เริ่มป่วย
                      </th>
                      <th className="th-report" rowSpan="2">
                        ชนิดสัตว์
                      </th>
                      <th className="th-report" colSpan="4">
                        สถานที่
                      </th>
                      <th className="th-report" colSpan="4">
                        จำนวนสัตว์ (ตัว)
                      </th>
                      <th className="th-report" rowSpan="2">
                        ผลตรวจ
                      </th>
                    </tr>
                    <tr className="tr-report">
                      <td className="sub-th td-report">จังหวัด</td>
                      <td className="sub-th td-report">อำเภอ</td>
                      <td className="sub-th td-report">ตำบล</td>
                      <td className="sub-th td-report">หมู่บ้าน</td>
                      <td className="sub-th td-report">ประวัติวัคซีน</td>
                      <td className="sub-th td-report sub-width-sm">ป่วย</td>
                      <td className="sub-th td-report sub-width-sm">ตาย</td>
                      <td className="sub-th td-report sub-width-sm">เสี่ยง</td>
                    </tr>
                  </thead>
                  <tbody>
                    {dataFarmDaily.length > 0 ? (
                      dataFarmDaily.map((item, index) => {
                        return renderDataFarmDaily(item, index);
                      })
                    ) : (
                      <tr className="tr-report">
                        <td className="td-report" colSpan="12">
                          ไม่มีข้อมูล
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 mb-5">
            <Col lg={7} md={12} sm={12}>
              <div className="mt-3" style={{ height: "30%" }}>
                <div
                  className="d-flex justify-content-center"
                  style={{ width: "100%", height: "60%" }}
                >
                  <div
                    className="text-md borderbox-1 text-center"
                    style={{
                      width: "30%",
                      borderRight: "none",
                      backgroundColor: "#ddd",
                    }}
                  >
                    <p className="bold">
                      {" "}
                      จังหวัดที่สถานะโรคยังไม่สงบ(ควบคุม / เฝ้าระวัง)
                    </p>
                  </div>
                  <div className="text-md borderbox-1" style={{ width: "70%" }}>
                    {provinceAlert.length > 0 ? provinceAlert : "ไม่มีข้อมูล"}
                  </div>
                </div>
                <div
                  className="d-flex justify-content-center mt-2"
                  style={{ width: "100%", height: "40%" }}
                >
                  <div
                    className="text-md borderbox-1 bold text-center"
                    style={{
                      width: "30%",
                      borderRight: "none",
                      backgroundColor: "#ddd",
                    }}
                  >
                    รายงานผลตรวจทางห้องปฏิบัติการ
                  </div>
                  <div className="text-md borderbox-1" style={{ width: "70%" }}>
                    {resultDaily.length > 0 ? resultDaily : "ไม่มีข้อมูล"}
                  </div>
                </div>
              </div>

              <div style={{ height: "70%" }}>
                <div className="bold text-md mt-5 mb-3">
                  กราฟแสดงการระบาดของโรค (เปลี่ยบเทียบ 5 ปีย้อนหลัง)
                </div>
                <div className="mb-4" id="img-graph">
                  <LineChart dataGraph={graph} name={"จำนวนตัวอย่าง"} />
                </div>
              </div>
            </Col>
            <Col lg={5} md={12} sm={12}>
              <div className="text-md mt-3">
                <table className="table-report">
                  <thead>
                    <tr className="tr-report">
                      <th className="th-report" colSpan="3">
                        ชนิดสัตว์ที่พบโรค (ตามรายงาน กคร.1 / รก.1) 5 อันดับ{" "}
                      </th>
                    </tr>
                    <tr className="tr-report">
                      <td className="sub-th td-report">ชนิดสัตว์</td>
                      <td className="sub-th td-report">จํานวนครั้งที่รายงาน</td>
                      <td className="sub-th td-report">
                        จํานวนสัตว์ป่วยสะสม (ตัว)
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {animalTypeDaily.length > 0 ? (
                      animalTypeDaily.map((item, index) => {
                        return renderDataAnimalDaily(item, index);
                      })
                    ) : (
                      <tr className="tr-report">
                        <td className="td-report" colSpan="3">
                          ไม่มีข้อมูล
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="mt-2 text-md borderbox-2">
                <p className="bold mt-3"> สรุปสถานการณ์ย้อนหลัง 1 ปี</p>
                <p className="normal mb-3">
                  {animalTypeDaily.length > 0
                    ? getConclusion(dataAll.dataAll.last1Year.animalType)
                    : "ไม่มีข้อมูล"}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Report_Daily_Info;
