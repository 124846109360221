import * as actionTypes from "../actions/notifyTypes";

export const initialState = {
  result: null,
  isFetching: false,
  error: false,
};

export default (state = initialState, { type, payload }) => {  
  switch (type) {
     case actionTypes.DISEASE_INFORM_FETCHING:
      return { ...state, result: null, isFetching: true, error: false };

    case actionTypes.DISEASE_INFORM_SUCCESS:
      return { ...state, result: payload, isFetching: false, error: false };

    case actionTypes.DISEASE_INFORM_FAILED:
      return { ...state, result: null, isFetching: false, error: true };

    default:
      return state;
  }
};
