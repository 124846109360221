import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as helper from "../../utils/helper";
import { Link } from "react-router-dom";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import InputMask from "../../components/InputMask";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "../../components/DataTable";
import ModalAssessment from "../../components/Modal.Assessment";
import * as API from "../../utils/apis";
import withReactContent from "sweetalert2-react-content";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import DataTableNodata from "../../components/DataTableNodata";
import Swal from "sweetalert2";
import { Row, Col } from "react-bootstrap";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function AssessRisk_Result() {
  const { t, i18n } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const initialForm = {
    farmId: "",
    idCard: "",
    name: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    assessment_date: "",
    risk_assessment: "",
    to_date: "",
    phone: "",

    idAssess: "",
    mode: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
      },
      {
        sortBy: "id",
        name: "ชื่อชุด",
        sortFlg: true,
      },
      {
        sortBy: "id",
        name: "ข้อมูลฟาร์ม",
        sortFlg: true,
      },
      {
        sortBy: "id",
        name: "ผลการประเมิน",
        sortFlg: true,
      },
      {
        sortBy: "id",
        name: "ผู้ประเมิน",
        sortFlg: true,
      },
      {
        sortBy: "id",
        name: "ปรับปรุงล่าสุด",
        sortFlg: true,
      },

      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const initialFormJson = {
    province: {},
    district: {},
    subDistrict: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const [listRiskAssessment, setListRiskAssessment] = useState([]);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  const [riskSelectValue, setRiskSelectValue] = useState([]);
  const [riskList, setRiskList] = useState([]);

  const initShowAssessRiskResult = false;
  const [showAssessRiskResult, setShowAssessRiskResult] = useState(
    initShowAssessRiskResult
  );

  const [isClearSearch, setIsClearSearch] = useState(false);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/assess_risk/list-assess-risk", name: "ประเมินความเสี่ยง" },
    { path: "active", name: "รายการผลการประเมินความเสี่ยง" },
  ];

  useMasterData("province", provinceList, setProvinceList);

  useEffect(() => {
    getMasterJson();
    getProvince();
    getAssessRiskKit();
  }, []);

  useEffect(async () => {
    await getAssessRiskResult();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getAssessRiskResult = async () => {
    const params = await {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",
      formId: form.risk_assessment ? form.risk_assessment : "",
      farmCode: form.farmId ? form.farmId : "",
      dateBegin: form.assessment_date
        ? helper.getDate(form.assessment_date)
        : "",
      dateEnd: form.to_date ? helper.getDate(form.to_date) : "",
      name: form.name ? helper.getDate(form.name) : "",
      pid: form.idCard ? form.idCard : "",
      tel: form.phone ? form.phone : "",
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
      mooId: form.villageId ? form.villageId : "",
    };
    try {
      const response = await API.getAssessRiskResult(params);

      const data = await response.data;
      if (response.status == 200) {
        await setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        await setListRiskAssessment(data.content);
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getMasterJson = async () => {
    const params = await ["province", "amphur", "tambon"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          province: data.province,
          district: data.amphur,
          subDistrict: data.tambon,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getAssessRiskKit = async () => {
    try {
      const response = await API.getAssessRiskKit();
      const data = await response.data;
      if (response.status == 200) {
        let riskArray = [];
        data.content.forEach((element) => {
          riskArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setRiskList(riskArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      zipcode: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      districtId: value.value,
      zipcode: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      subDistrictId: value.value,
      zipcode: value.zipcode,
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type == "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type == "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setDistrictList([]);
    setSubDistrictList([]);
    setVillageList([]);
    setRiskSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          {t("clear")}
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const buttonAdd = () => {
    return (
      <button
        className="btn btn-info "
        disabled={user?.userGroup.mcreate === "n"}
        onClick={() => history.push(`/assess_risk/assess-risk-result/save`)}
      >
        {" "}
        <span className="text-white">
          <i className="fas fa-plus mr-1 "></i>เพิ่มผลการประเมินความเสี่ยง
        </span>
      </button>
    );
  };

  const viewDetails = (id) => {
    history.push(`/assess_risk/assess-risk-result/edit/${id}`);
  };

  const delResult = (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบรายการผลการประเมินความเสี่ยง?`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteAssessRiskResult(id);
          if (response.status == 200) {
            await getAssessRiskResult();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const assessRiskResult = (id) => {
    setForm({ ...form, mode: "edit", idAssess: id });
    setShowAssessRiskResult(true);
  };

  const renderTablereportFarm = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item.formName}</td>
          <td>
            <h6 className="font14 mb-0 bold">รหัส : {item.farmCode}</h6>
            <h6 className="font14 mb-0">
              {" "}
              {`บ้านเลขที่ ${item.homeNo} ตำบล ${item.tambonName} อำเภอ ${item.amphurName} จังหวัด ${item.provinceName}`}
            </h6>
          </td>
          <td className="text-center">
            <h6 className="font14 mb-0 ">{item.levelName}</h6>
            <h6 className="font14 mb-0"> ({item.riskLevel})</h6>
          </td>
          <td className="text-center">
            <h6 className="font14 mb-0 ">{item.farmerName}</h6>
            <h6 className="font14 mb-0"> (ปศอ. {item.zoneName})</h6>
          </td>
          <td className="text-center">
            <p className="font14 mb-0">
              {" "}
              {item.updateDate
                ? helper.momentDate(item.updateDate, "th", "short")
                : "-"}
            </p>
            <p className="font14 mb-0">
              {" "}
              {item.updateDate ? helper.getTime(item.updateDate, "th") : "-"}
            </p>
          </td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-darkblue mr-2 "
                onClick={() => assessRiskResult(item.id)}
                disabled={
                  user?.userGroup.mexport === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                ผลการประเมิน
              </button>
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delResult(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={7} />;
    }
  };

  return (
    <div>
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <Row className="mb-2 pl-2 ">
              <Col lg={3} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ชุดประเมินความเสี่ยง"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={riskSelectValue}
                  optionsList={riskList}
                  handleChange={(value) => {
                    setRiskSelectValue([value]);
                    setForm({ ...form, risk_assessment: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputText
                  type="text"
                  title="รหัสฟาร์ม"
                  idName="farmId"
                  value={form.farmId}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) => setForm({ ...form, farmId: value })}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="วันที่ประเมินความเสี่ยง"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.assessment_date}
                  star={false}
                  handleChange={(value) =>
                    setForm({ ...form, assessment_date: value })
                  }
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.to_date}
                  star={false}
                  handleChange={(value) => setForm({ ...form, to_date: value })}
                />
              </Col>
            </Row>

            <Row className="mb-2 pl-2  ">
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputText
                  type="text"
                  title="เจ้าของฟาร์ม"
                  idName="name"
                  value={form.name}
                  star={false}
                  placeholder="ชื่อ นามสกุล"
                  classLabel="normal"
                  handleChange={(value) => setForm({ ...form, name: value })}
                />
              </Col>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputMask
                  mask="9-9999-99999-99-9"
                  title="บัตรประชาชน"
                  idName="idCard"
                  value={form.idCard}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) =>
                    setForm({ ...form, idCard: value.replace(/-/g, "") })
                  }
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputMask
                  mask="99-9999-9999"
                  title={t("phone")}
                  idName="phone"
                  value={form.phone}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) =>
                    setForm({ ...form, phone: value.replace(/-/g, "") })
                  }
                />
              </Col>
            </Row>

            <Row className="mb-2 pl-2">
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="จังหวัด"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="provinceId"
                  classLabel="normal"
                  selectValue={provinceSelectValue}
                  optionsList={provinceList}
                  handleChange={(value) => {
                    selectValueProvince(value);
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="อำเภอ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="districtId"
                  classLabel="normal"
                  selectValue={districtSelectValue}
                  optionsList={districtList}
                  handleChange={(value) => selectValueDistrict(value)}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ตำบล"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="subDistrictId"
                  classLabel="normal"
                  selectValue={subDistrictSelectValue}
                  optionsList={subDistrictList}
                  handleChange={(value) => selectValueSubDistrict(value)}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="หมู่บ้าน"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="villageId"
                  classLabel="normal"
                  selectValue={villageSelectValue}
                  optionsList={villageList}
                  handleChange={(value) => {
                    setVillageSelectValue([value]);
                    setForm({ ...form, villageId: value.value });
                  }}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการประเมินความเสี่ยง"
            headRight={buttonAdd()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTablereportFarm(listRiskAssessment)}
            </DataTable>
          </BoxCard>
        </div>
      </div>

      {showAssessRiskResult && (
        <ModalAssessment
          show={showAssessRiskResult}
          close={() => setShowAssessRiskResult(false)}
          // save={(value) => addSaveContact(value)}
          mode={form.mode}
          idAssess={form.idAssess}
        />
      )}
    </div>
  );
}

export default AssessRisk_Result;
