import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as helper from "../../../utils/helper";
import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTable from "../../../components/DataTable";
import DataTableNodata from "../../../components/DataTableNodata";
import InputDatePicker from "../../../components/InputDatePicker";
import ModalNews from "../../../components/Data_Management/Modal.News";

const MySwal = withReactContent(Swal);

function News() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);
  const history = useHistory();
  const statusCheck = useSelector((state) => state.statusCheck);
  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/web-settings/news", name: "ตั้งค่าเวปไซต์" },
    { path: "active", name: "ข่าวประชาสัมพันธ์" },
  ];

  const initialForm = {
    title: "",
    description: "",
    newCategoryId: "",
    newCategoryName: "",
    dateBegin: "",
    dateEnd: "",
    status: "",

    idEdit: "",
    mode: "",

    search: "",
    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: true,
      },
      {
        sortBy: "title",
        name: "หัวข้อ",
        sortFlg: true,
      },
      {
        sortBy: "newCategoryName",
        name: "หมวดหมู่",
        sortFlg: true,
      },

      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },
      {
        sortBy: "createDate",
        name: "วันที่สร้างข้อมูล",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listNews, setListNews] = useState([]);

  const [showNews, setShowNews] = useState(false);

  const [categoriesIdSelectValue, setCategoriesIdSelectValue] = useState([]);
  const [categoriesIdList, setCategoriesList] = useState([]);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);
  const [isClearSearch, setIsClearSearch] = useState(false);

  useEffect(() => {
    getNews();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  useEffect(() => {
    getNewsCategories();
  }, []);

  const getNewsCategories = async () => {
    try {
      const response = await API.getNewsCategories("", "", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let categoryArray = [];
        data.content.forEach((element) => {
          categoryArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setCategoriesList(categoryArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNews = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getNews(
        form.title,
        form.newCategoryId,
        form.description,
        form.dateBegin ? helper.getDate(form.dateBegin) : "",
        form.status,
        form.dateEnd ? helper.getDate(form.dateEnd) : "",
        form.page,
        form.limit,
        form.search,
        form.sortBy,
        form.sortDir
      );
      const data = await response?.data;
      if (response.status === 200) {
        let newsArray = [];
        data.content.forEach((element) => {
          newsArray.push({
            id: element.id,
            title: element.title,
            statusId: element.statusId,
            createDate: element.createDate,
            newCategoryName: element.newCategoryName,
          });
        });

        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListNews(newsArray);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusCheck && statusCheck.length > 0) {
      statusCheck.map((item, index) => {
        if (item.id === idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-4 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const buttonAddNews = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddNews()}
          disabled={user?.userGroup.mcreate === "n"}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข่าวประชาสัมพันธ์
        </button>
      </div>
    );
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setStatusSelectValue([]);
    setCategoriesIdSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const addSaveNews = (data) => {
    const fileAdd = [];
    if (data.news.filesPreview && data.news.filesPreview.length > 0) {
      data.news.filesPreview.map((item, i) => {
        if (item.id) {
        } else {
          fileAdd.push({
            id: item.id || "",
            name: item.name,
            data: item.base64.replace("data:", "").replace(/^.+,/, ""),
          });
        }
      });
    }

    const delFile = [];
    if (data.news.filesChooseDel && data.news.filesChooseDel.length > 0) {
      data.news.filesChooseDel.map((item) => {
        if (item.id) {
          delFile.push(item.id);
        }
      });
    }

    const dataNews = {
      news: {
        id: data.news.news_id || "",
        title: data.news.title,
        newCategoryId: data.news.newCategoryId,
        publishDate: data.news.publishDate
          ? helper.getDate(data.news.publishDate)
          : "",
        description: data.news.description,
        statusId: data.news.status,
        link_1: "",
        link_2: "",
        link_3: "",
      },
      newsFiles: fileAdd,
      deleteFiles: delFile,
    };

    MySwal.fire({
      text: `ต้องการบันทึก `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.saveNews(dataNews);
          if (response.status === 200) {
            await getNews();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const delNews = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบ `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteNews(id);
          if (response.status === 200) {
            await getNews();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const toAddNews = () => {
    setForm({ ...form, mode: "add", idEdit: "" });
    setShowNews(true);
  };

  const editModalNews = (id) => {
    setForm({ ...form, mode: "edit", idEdit: id });
    setShowNews(true);
  };

  const getNewsCategoryName = (id) => {
    let categoryName = "";
    if (categoriesIdList && categoriesIdList.length > 0) {
      categoriesIdList.map((item, i) => {
        if (item.value === id) {
          categoryName = item.label;
        }
      });
    }
    return categoryName;
  };

  const renderTableNews = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item.title}</td>
          <td>{item.newCategoryName || "-"}</td>
          <td>{getStatusColor(item.statusId)}</td>
          <td>
            {item.createDate
              ? helper.momentDate(item.createDate, "th", "short")
              : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editModalNews(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delNews(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={6} />;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="หมวดหมู่"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={categoriesIdSelectValue}
                  optionsList={categoriesIdList}
                  handleChange={(value) => {
                    setCategoriesIdSelectValue(value);
                    setForm({
                      ...form,
                      newCategoryId: value.value,
                      newCategoryName: value.label,
                    });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="สถานะ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleChange={(value) => {
                    setStatusSelectValue([value]);
                    setForm({ ...form, status: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="วันที่เริ่มเผยแพร่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateBegin}
                  star={false}
                  handleChange={(value) =>
                    setForm({ ...form, dateBegin: value })
                  }
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateEnd}
                  star={false}
                  handleChange={(value) => setForm({ ...form, dateEnd: value })}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="รายการข่าวประชาสัมพันธ์"
            classNameBox="mt-4"
            headRight={buttonAddNews()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTableNews(listNews)}
            </DataTable>
          </BoxCard>
          {showNews && (
            <ModalNews
              show={showNews}
              close={() => setShowNews(false)}
              save={(value) => addSaveNews(value)}
              mode={form.mode}
              idEdit={form.idEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default News;
