import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import InputDatePicker from "./InputDatePicker";
import DataTableNodata from "./DataTableNodata";
import BoxCard from "./BoxCard";
import * as API from "../utils/apis";
import { Row, Col, Form } from "react-bootstrap";
import { useMasterData } from "../components/hooks/useMasterData";

function OutbreakStep1({ save, dataAll, modeGet, symptomsData }) {
  const user = useSelector((state) => state.login.result);

  const initialForm = {
    chooseSelect: false,
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    homeno: "",
    address: "",
    localGovernId: "",
    governmentName: "",

    diseaseTypeId: "",
    animalId: "",
    animal_sick: "",
    date_found: "",
    date_found_first: "",
    report_from: "",
    other_text: "",

    humanSick: "",
    humanDead: "",
    humanTouch: "",
    animalAround5km: "",

    checkSelect: false,
    checkToSave: false,

    //อาการป่วย
    // del_symptom: [],
    symptom: [],
    save: true,
  };
  const [form, setForm] = useState(initialForm);

  //จังหวัด
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  const [localGovernSelectValue, setLocalGovernSelectValue] = useState([]);
  const [localGovernList, setLocalGovernList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [diagnosisSelectValue, setDiagnosisSelectValue] = useState([]);
  const [diagnosisList, setDiagnosisList] = useState([]);

  const [animalSickSelectValue, setAnimalSickSelectValue] = useState([]);
  const [animalSickList, setAnimalSickList] = useState([]);

  const [addSymptoms, setAddSymptoms] = useState([]);
  const [delSymptoms, setDelSymptoms] = useState([]);

  useMasterData("province", provinceList, setProvinceList);

  useEffect(async () => {
    await getAnimal();
    // await getAnimalSymptom()
    await getProvince();
    await getLocalGovern();
  }, []);

  useEffect(async () => {
    if (modeGet == "getDisease" && !form.chooseSelect) {
      await getDataById();
    }
    if (modeGet == "getById" && !form.chooseSelect) {
      await getDataById();
    }
    if (modeGet == "dsGetById" && !form.chooseSelect) {
      await getDataById();
    }
    if (modeGet == "farmById" && !form.chooseSelect) {
      await getDataById();
    }
  }, [
    user,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
    form.animalId,
    diagnosisList,
  ]);

  useEffect(() => {
    toSave();
  }, [form, modeGet, delSymptoms]);

  const toSave = () => {
    save(form, addSymptoms, delSymptoms);
    console.log("addSymptoms ----👻👻", addSymptoms);
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async (idAnimal) => {
    try {
      const response = await API.getDiseaseType(idAnimal, "", "1", "100", 1);
      const data = await response.data;
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setDiagnosisList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimalSymptom = async (animalId) => {
    try {
      const response = await API.getAnimalSymptom(animalId, 1);
      const data = await response.data;
      if (response.status == 200) {
        let symptomArray = [];
        data.content.forEach((element) => {
          symptomArray.push({
            value: element.id,
            label: element.name,
            other: element.other,
          });
        });
        symptomArray.push({
          value: 0,
          label: "อื่นๆ",
        });
        await setAnimalSickList(symptomArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLocalGovern = async () => {
    try {
      const response = await API.getLocalGovern("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let localGovernArray = [];
        data.content.forEach((element) => {
          localGovernArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setLocalGovernList(localGovernArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataById = async () => {
    if (dataAll) {
      if (dataAll.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataAll.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataAll.provinceId);
        }
      }

      if (dataAll.districtId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataAll.districtId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataAll.districtId);
        }
      }

      if (dataAll.subDistrictId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataAll.subDistrictId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataAll.subDistrictId);
        }
      }

      if (dataAll.villageId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataAll.villageId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataAll.localGovernId) {
        let localGovernSelect = localGovernList.find(
          (item) => item.value == dataAll.localGovernId
        );
        setLocalGovernSelectValue(localGovernSelect);
      }

      if (dataAll.animalId) {
        let animalSelect = await animalList.find(
          (item) => item.value == dataAll.animalId
        );
        setAnimalSelectValue(animalSelect);
        await getAnimalSymptom(dataAll.animalId);

        if (diagnosisList.length == 0) {
          getDiseaseType(dataAll.animalId);
        }
      }

      if (dataAll.animal_sickId) {
        let symptomSelect = await animalSickList.find(
          (item) => item.value == dataAll.animal_sickId
        ); 
        setAnimalSickSelectValue(symptomSelect);
      }

      if (dataAll.diseaseTypeId) {
        let diseaseSelect = await diagnosisList.find(
          (item) => item.value == dataAll.diseaseTypeId
        );
        setDiagnosisSelectValue(diseaseSelect);
      }
      if (symptomsData && symptomsData.length > 0) {
        symptomsData.map((item, i) => {
          if (item.symptom.value == 0) {
            item.symptom.label = "อื่นๆ"
          }  
        });
      }
      setAddSymptoms(symptomsData);
      setForm({
        ...form,
        provinceId: dataAll?.provinceId || "",
        districtId: dataAll?.districtId || "",
        subDistrictId: dataAll?.subDistrictId || "",
        villageId: dataAll?.villageId || "",
        homeno: dataAll?.homeno || "",
        localGovernId: dataAll?.localGovernId || "",
        governmentName: dataAll?.governmentName || "",

        animalId: dataAll?.animalId || "",
        diseaseTypeId: dataAll?.diseaseTypeId || "",
        animal_sickId: dataAll?.animal_sickId || "",
        date_found: dataAll.date_found ? dataAll.date_found : "",
        date_found_first: dataAll.date_found_first
          ? dataAll.date_found_first
          : "",
        report_from: dataAll.report_from ? dataAll.report_from : "",
        other_text: dataAll.other_text ? dataAll.other_text : "",

        humanSick: dataAll.humanSick ? dataAll.humanSick : "",
        humanDead: dataAll.humanDead ? dataAll.humanDead : "",
        humanTouch: dataAll.humanTouch ? dataAll.humanTouch : "",
        animalAround5km: dataAll.animalAround5km ? dataAll.animalAround5km : "",
      });
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      zipcode: "",
      districtId: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      districtId: value.value,
      zipcode: "",
      subDistrictId: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      subDistrictId: value.value,
      zipcode: value.zipcode,
      villageId: "",
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const selectValueAnimal = (value) => {
    setAnimalSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      animalId: value.value,
      diseaseTypeId: "",
      chooseSelect: true,
    });
    setDiagnosisSelectValue([]);
    getDiseaseType(value.value);
  };

  const checkAddSymptomNew = () => {
    return addSymptoms.find((item) => {
      return item.save == true;
    });
  };

  const toAddSymptom = () => {
    
    const data = addSymptoms.map((item) => {  
      return { ...item, edit: true };
    });
    setAddSymptoms([...data, { symptom: [], save: true }]);
  };

  const buttonAddSymptom = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddSymptom()}
          disabled={checkAddSymptomNew() || !form.animalId}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const renderSymtomsTable = (item, i) => {
    return (
      <tr key={i} className="">
        <td>{i + 1}</td>
        <td>
        {item.save ? (
            <InputSelect
              classFormGroup="mr-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName={`Symptom_${i}`}
              classLabel="normal"
              selectValue={item.symptom}
              optionsList={animalSickList}
              handleChange={(value) => {
                item.symptom = value;
                setAnimalSickSelectValue(value);
                setAddSymptoms([...addSymptoms]);

                
              }}
            />
          ) : (
            <span className="text-nowrap">{item.symptom?.label}</span>
          )}
          {item.save ? (
            <InputText
              classFormGroup="mr-0"
              disabled={ item.symptom.value == 0 ? false : true }
              star={false}
              value={item.symptom.other}
              placeholder="ระบุ"
              idName={`SymptomOther_${i}`}
              classLabel="normal"
              handleChange={(value) => {
                item.symptom.other = value
                setAddSymptoms([...addSymptoms]);
              }}
            />
          ) : (
            <span className="text-nowrap">{item.symptom.value == 0 ? "(" + item.symptom.other + ")" : "" }</span>
          )}
          
        </td>
        <td className="text-center">
          {item.save ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={() => {
                item.save = false;
                const data = addSymptoms.map((item) => {
                  return { ...item, edit: false };
                });
                setAddSymptoms([...data]);
                toSave();
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => {
                  item.save = true;
                  setAddSymptoms([...addSymptoms]);
                }}
                disabled={item.edit}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button
                className="btn btn-del "
                onClick={() => {
                  let delSymptomsArray = [...delSymptoms];
                  delSymptomsArray.push(item.id);
                  addSymptoms.splice(i, 1);
                  setAddSymptoms([...addSymptoms]);
                  setDelSymptoms(delSymptomsArray);
                  toSave();
                }}
                disabled={item.edit}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className=" p-4 bg-white ">
      <BoxCard classNameBox="" title="จุดที่พบ">
        <Row>
          <Col lg={3} md={4} sm={6}>
            <InputText
              type="text"
              title="บ้านเลขที่"
              idName="homeno"
              value={form.homeno}
              star={false}
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) => {
                setForm({ ...form, homeno: value });
                toSave();
              }}
            />
          </Col>

          <Col lg={3} md={4} sm={6}>
            <InputText
              type="text"
              title="ที่อยู่"
              idName="address"
              value={form.address}
              star={false}
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) => {
                setForm({ ...form, address: value });
                toSave();
              }}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col lg={3} md={4} sm={6} xs={6}>
            <InputSelect
              title="จังหวัด"
              star={false}
              placeholder="กรุณาเลือก"
              idName="provinceId"
              classLabel="normal"
              selectValue={provinceSelectValue}
              optionsList={provinceList}
              handleChange={(value) => {
                selectValueProvince(value);
                toSave();
              }}
            />
          </Col>

          <Col lg={3} md={4} sm={6}>
            <InputSelect
              title="อำเภอ"
              star={false}
              placeholder="กรุณาเลือก"
              idName="title"
              classLabel="normal"
              selectValue={districtSelectValue}
              optionsList={districtList}
              handleChange={(value) => {
                selectValueDistrict(value);
                toSave();
              }}
            />
          </Col>

          <Col lg={3} md={4} sm={6}>
            <InputSelect
              title="ตำบล"
              star={false}
              placeholder="กรุณาเลือก"
              idName="subDistrict"
              classLabel="normal"
              selectValue={subDistrictSelectValue}
              optionsList={subDistrictList}
              handleChange={(value) => {
                selectValueSubDistrict(value);
                toSave();
              }}
            />
          </Col>

          <Col lg={3} md={4} sm={6}>
            <InputSelect
              title="หมู่ที่"
              star={false}
              placeholder="กรุณาเลือก"
              idName="village"
              classLabel="normal"
              selectValue={villageSelectValue}
              optionsList={villageList}
              handleChange={(value) => {
                setVillageSelectValue([value]);
                setForm({ ...form, villageId: value.value });
                toSave();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={4} sm={6} xs={6}>
            <InputSelect
              title="องค์กรปกครองส่วนท้องถิ่น"
              star={false}
              placeholder="กรุณาเลือก"
              idName="localGovernId"
              classLabel="normal"
              selectValue={localGovernSelectValue}
              optionsList={localGovernList}
              handleChange={(value) => {
                setLocalGovernSelectValue([value]);
                setForm({ ...form, localGovernId: value.value });
                toSave();
              }}
            />
          </Col>

          <Col lg={3} md={4} sm={6} xs={6}>
            <InputText
              type="text"
              title="ท้องถิ่น"
              idName="governmentName"
              value={form.governmentName}
              star={false}
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) => {
                setForm({ ...form, governmentName: value });
                toSave();
              }}
            />
          </Col>
        </Row>
      </BoxCard>

      <BoxCard classNameBox="mt-3" title="วินิจฉัยเบื้องต้น">
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <InputSelect
              title="ชนิดสัตว์"
              star={false}
              placeholder="กรุณาเลือก"
              idName="animalId"
              classLabel="normal"
              selectValue={animalSelectValue}
              optionsList={animalList}
              handleChange={(value) => {
                selectValueAnimal(value);
                getAnimalSymptom(value.value);
                toSave();
              }}
            />
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <InputSelect
              title="การวินิจฉัยเบื้องต้น(ชนิดโรคที่สงสัย)"
              star={false}
              placeholder="กรุณาเลือก"
              idName="diagnosis"
              classLabel="normal"
              selectValue={diagnosisSelectValue}
              optionsList={diagnosisList}
              handleChange={(value) => {
                setDiagnosisSelectValue([value]);
                setForm({ ...form, diseaseTypeId: value.value });
                toSave();
              }}
            />
          </Col>
          {/* 
          <Col lg={4} md={4} sm={12} xs={12}>
            <InputSelect
              title="อาการของสัตว์ที่ป่วย"
              star={false}
              placeholder="กรุณาเลือก"
              idName="animalSick"
              classLabel="normal"
              selectValue={animalSickSelectValue}
              optionsList={animalSickList}
              handleChange={(value) => {
                setAnimalSickSelectValue([value])
                setForm({ ...form, animal_sick: value.value })
                toSave()
              }}
            />
          </Col> */}

          <BoxCard
            classNameBox="mt-2 w-50"
            title="อาการของสัตว์ที่ป่วย"
            headRight={buttonAddSymptom()}
          >
            <div className="">
              <table className="table table-striped sameWidth table-bordered my-2 ">
                <thead>
                  <tr className="text-center">
                    <th scope="col">ลำดับ</th>
                    <th scope="col">อาการ</th>
                    <th scope="col">จัดการ</th>
                  </tr>
                </thead>
                <tbody>
                  {addSymptoms?.length > 0 ? (
                    addSymptoms.map((item, i) => {
                      return renderSymtomsTable(item, i);
                    })
                  ) : (
                    <DataTableNodata colSpan={3} />
                  )}
                </tbody>
              </table>
            </div>
          </BoxCard>
        </Row>
        <Row className="mt-4">
          <Col lg={4} md={4} sm={12} xs={12}>
            <InputDatePicker
              title="วันที่พบโรค"
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={form.date_found}
              star={false}
              handleChange={(value) => {
                setForm({ ...form, date_found: value });
                toSave();
              }}
            />
          </Col>

          <Col lg={4} md={5} sm={12} xs={12}>
            <InputDatePicker
              title="วันที่เกษตรกรพบสัตว์แสดงอาการตัวแรก"
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={form.date_found_first}
              star={false}
              handleChange={(value) => {
                setForm({ ...form, date_found_first: value });
                toSave();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={2} md={2} sm={12} className="d-flex align-items-center">
            <p className="mb-0">รับรายงานโรคจาก: </p>
          </Col>
          <Col lg={10} md={10} sm={12} className="w-100">
            <div className="d-flex align-items-center flex-wrap ">
              <div>
                <Form.Check
                  inline
                  type="radio"
                  name="report_from"
                  id="animal_owner"
                  value={1}
                  checked={form.report_from == 1}
                  label="เจ้าของสัตว์"
                  onChange={(e) => {
                    setForm({ ...form, report_from: e.target.value });
                    toSave();
                  }}
                />
              </div>
              <div>
                <Form.Check
                  inline
                  type="radio"
                  name="report_from"
                  id="headman"
                  value={2}
                  checked={form.report_from == 2}
                  label="กำนันหรือผู้ใหญ่บ้าน"
                  onChange={(e) => {
                    setForm({ ...form, report_from: e.target.value });
                    toSave();
                  }}
                />
              </div>
              <div>
                <Form.Check
                  inline
                  type="radio"
                  name="report_from"
                  id="volunteer"
                  value={3}
                  checked={form.report_from == 3}
                  label="อาสาสมัครป้องกันโรคประจำหมู่บ้านหรือตำบล"
                  onChange={(e) => {
                    setForm({ ...form, report_from: e.target.value });
                    toSave();
                  }}
                />
              </div>

              <div className="d-flex">
                <Form.Check
                  inline
                  type="radio"
                  name="report_from"
                  id="other"
                  value={4}
                  checked={form.report_from == 4}
                  label="อื่นๆ"
                  onChange={(e) => {
                    setForm({ ...form, report_from: e.target.value });
                    toSave();
                  }}
                />
                <div>
                  <InputText
                    type="text"
                    title=""
                    idName="other_text"
                    value={form.other_text}
                    star={false}
                    placeholder="ระบุ"
                    classLabel="normal"
                    disabled={form.report_from == 4 ? false : true}
                    classFormGroup="mb-0"
                    handleChange={(value) => {
                      setForm({ ...form, other_text: value });
                      toSave();
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </BoxCard>

      <BoxCard classNameBox="mt-3 " title="กรณีเป็นโรคติดต่อระหว่างสัตว์และคน">
        <Row>
          <Col lg={2} md={4} sm={12}>
            <InputText
              type="number"
              title="มีผู้ป่วย(คน)"
              idName="humanSick"
              value={form.humanSick}
              star={false}
              placeholder="ระบุจำนวน"
              classLabel="normal"
              min="0"
              handleChange={(value) => {
                setForm({ ...form, humanSick: value });
                toSave();
              }}
            />
          </Col>
          <Col lg={2} md={4} sm={12}>
            <InputText
              type="number"
              title="เสียชีวิต(คน)"
              idName="humanDead"
              value={form.humanDead}
              star={false}
              placeholder="ระบุจำนวน"
              classLabel="normal"
              min="0"
              handleChange={(value) => {
                setForm({ ...form, humanDead: value });
                toSave();
              }}
            />
          </Col>
          <Col lg={3} md={4} sm={12}>
            <InputText
              type="number"
              title="คาดว่ามีผู้สัมผัสโรค(คน)"
              idName="humanTouch"
              value={form.humanTouch}
              star={false}
              placeholder="ระบุจำนวน"
              classLabel="normal"
              min="0"
              handleChange={(value) => {
                setForm({ ...form, humanTouch: value });
                toSave();
              }}
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <InputText
              type="number"
              title="จำนวนสัตว์ในพื้นที่ 5ก.ม.รอบจุดเกิดโรค(ตัว)"
              idName="animalAround5km"
              value={form.animalAround5km}
              star={false}
              placeholder="ระบุจำนวน"
              classLabel="normal"
              min="0"
              handleChange={(value) => {
                setForm({ ...form, animalAround5km: value });
                toSave();
              }}
            />
          </Col>
        </Row>
      </BoxCard>
    </div>
  );
}

export default OutbreakStep1;
