import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import BoxCard from "../../components/BoxCard";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import InputSelect from "../../components/InputSelect";
import InputText from "../../components/InputText";
import DataTableNodata from "../../components/DataTableNodata";
import DataTable from "../../components/DataTable";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";
import { logout } from "../../redux/actions/logoutAction";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import * as FileSaver from "file-saver";

const MySwal = withReactContent(Swal);

function Assess_Risk() {
  const user = useSelector((state) => state.login.result);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["common", "footer"]);

  const initialForm = {
    nameDisease: "",
    disease: "",
    status: "",

    id: "",
    name: "",
    diseaseTypeName: "",
    useAmt: "",
    unUseAmt: "",
    statusName: "",
    updateDate: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
      },
      {
        sortBy: "nameList",
        name: "ชื่อชุด",
        sortFlg: true,
      },

      {
        sortBy: "diseaseName",
        name: "ชนิดโรค",
        sortFlg: true,
      },
      {
        sortBy: "question",
        name: "คำถาม (ใช้งาน/ทั้งหมด)",
        sortFlg: true,
      },

      {
        sortBy: "status",
        name: "สถานะ",
        sortFlg: true,
      },

      {
        sortBy: "updateDate",
        name: "ปรับปรุงล่าสุด",
        sortFlg: true,
      },

      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],

    statusActionId: [
      {
        id: 1,
        color: "#3FC071",
        status: "ใช้งาน",
      },
      {
        id: 2,
        color: "#FF482F",
        status: "ไม่ได้ใช้งาน",
      },
    ],
  };
  const [form, setForm] = useState(initialForm);
  // console.log("form", form);

  const [listAction, setListAction] = useState([]);
  const [isClearSearch, setIsClearSearch] = useState(false);

  // Select ชนิดโรค
  const [diseaseTypeSelectValue, setDiseaseTypeSelectValue] = useState([]);
  const [diseaseTypeList, setDiseaseTypeList] = useState([]);

  // Select สถานะ
  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    {
      value: 1,
      label: "ใช้งาน",
    },
    {
      value: 2,
      label: "ไม่ได้ใช้งาน",
    },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  useEffect(() => {
    getDisease();
  }, []);

  useEffect(() => {
    getDiseaseList();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/assess_risk/list-assess-risk", name: "ประเมินความเสี่ยง" },
    { path: "active", name: "รายการชุดประเมินความเสี่ยง" },
  ];

  const getDisease = async () => {
    try {
      const response = await API.getDisease("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.diseaseTypeName,
          });
        });
        await setDiseaseTypeList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseList = async () => {
    const params = await {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",

      search: form.nameDisease ? form.nameDisease : "",
      diseaseTypeId: form.disease ? form.disease : "",
      statusId: form.status ? form.status : "",

      name: form.name ? form.name : "",
      diseaseTypeName: form.diseaseTypeName ? form.diseaseTypeName : "",
      useAmt: form.useAmt ? form.useAmt : "",
      unUseAmt: form.unUseAmt ? form.unUseAmt : "",
      statusName: form.statusName ? form.statusName : "",
      updateDate: form.updateDate ? helper.getDate(form.updateDate) : "",
    };
    try {
      const response = await API.getDiseaseList(params);
      const data = await response.data;
      if (response.status == 200) {
        await setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        await setListAction(data.content);
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setDiseaseTypeSelectValue([]);
    setStatusSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };
  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const toDownload = async () => {
    try {
      const fileName = "SCORE-SubDistrictly_";
      const response = await API.getDownloadScore();
      const data = await response.data;
      if (response.status == 200) {
        let url = data.data.url;

        let res = await fetch(url);
        let dt = await res.blob();
        let metadata = {
          type: "xlsx",
        };
        let today = new Date();
        let time =
          "_" +
          today.getHours() +
          "-" +
          today.getMinutes() +
          "-" +
          today.getSeconds();
        let file = new File(
          [dt],
          fileName + helper.getDate(today) + time + ".xlsx",
          metadata
        );
        FileSaver.saveAs(file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toUpload = async (file) => {
    try {
      let formData = new FormData();
      formData.append("file", file);

      const response = await API.getUploadScore(formData);
      const data = await response.data;
      if (response.status == 200) {
        MySwal.fire({
          icon: "success",
          type: "success",
          confirmButtonText: "ตกลง",
          text: "บันทึกคะแนนรายตำบลสำเร็จ",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleImageChange = (e) => {
    e.preventDefault();
    const fileTypeArray = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    // let reader = new window.FileReader();
    let file = e.target.files[0];
    // console.log("toUpload", file);

    if (file) {
      if (fileTypeArray.includes(file.type)) {
        if (file.size < 1000000) {
          // reader.onloadend = () => {
          //     setForm({ ...form, file: file, imagePreviewUrl: reader.result });
          // };
          // reader.readAsDataURL(file);
          // console.log("file", file);
          toUpload(file);
        } else {
          // MySwal.fire({
          //     icon: "warning",
          //     type: "warning",
          //     confirmButtonText: "ตกลง",
          //     text: "กรุณาเลือกไฟล์ที่มีขนาดไม่เกิน 10 MB",
          // }).then(result => {
          //     if (result.value) {
          //         reader.onloadend = () => {
          //             setForm({ ...form, file: "", imagePreviewUrl: "", check: false });
          //         };
          //     }
          // })
        }
      }
    }
  };

  const onInputClick = (e) => {
    e.target.value = "";
  };

  const buttonAdd = () => {
    return (
      <>
        <button
          className="btn btn-download text-white mr-2 fw-400 cursor-p"
          onClick={() => {
            toDownload();
          }}
          disabled={user?.userGroup.mexport === "n"}
        >
          <img
            className="icon-btn mr-2 mb-1"
            src="/svg/download.svg"
            alt="menu-icon"
          />
          ดาวน์โหลดคะแนนรายตำบล
        </button>

        <label
          className={`btn btn-report text-white mr-2 mt-2 fw-400 ${
            user?.userGroup.mcreate === "n" ? "disabled" : ""
          }`}
          htmlFor="exampleFormControlFile1"
        >
          <img className="mr-2 mb-1" src={"/svg/up.svg"} alt="menu-icon" />
          อัปโหลดคะแนนรายตำบล
        </label>
        <input
          type="file"
          className="form-control-file"
          id="exampleFormControlFile1"
          hidden
          disabled={user?.userGroup.mcreate === "n"}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={(e) => {
            _handleImageChange(e);
          }}
          onClick={onInputClick}
        />

        <button
          className="btn btn-info normal fw-400 cursor-p"
          onClick={() => history.push("/assess_risk/list-assess-risk/save")}
          disabled={user?.userGroup.mcreate === "n"}
        >
          <i className="fas fa-plus mr-2"></i>
          เพิ่มชุดประเมินความเสี่ยง
        </button>
      </>
    );
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type == "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type == "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (form.statusActionId && form.statusActionId.length > 0) {
      form.statusActionId.map((item, index) => {
        if (item.id == idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-2 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const viewDetails = (id) => {
    history.push(`/assess_risk/list-assess-risk/edit/${id}`);
  };

  const deleteAction = (id) => {
    MySwal.fire({
      text: `ยืนยันการลบข้อมูล`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteAssess(id);
          if (response.status == 200) {
            await getDiseaseList();
          }
        } catch (error) {
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const addQuestion = (id) => {
    history.push(`/assess_risk/risk-assessment-items/save/${id}`);
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item.name ? item.name : "-"}</td>
          <td>{item.diseaseTypeName ? item.diseaseTypeName : "-"}</td>
          <td className="text-center">
            {item.useAmt} / {item.unUseAmt}
          </td>
          <td className="text-center">
            {item.statusName ? getStatusColor(item.statusId) : "-"}
          </td>
          <td className="text-center">
            {item.updateDate
              ? helper.fullDateTh(item.updateDate, "th", "long")
              : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => deleteAction(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
              <button
                className="btn btn-report text-white"
                onClick={() => {
                  addQuestion(item.id);
                }}
                disabled={user?.userGroup.mcreate === "n"}
              >
                <a>+ เพิ่มคำถาม</a>
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="7" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={4} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่อชุดประเมินความเสี่ยง"
                    idName=" namep"
                    value={form.nameDisease}
                    star={false}
                    placeholder="กรุณาระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, nameDisease: value })
                    }
                  />
                </Col>

                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดโรค"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={diseaseTypeSelectValue}
                    optionsList={diseaseTypeList}
                    handleSelectValue={(value) =>
                      setDiseaseTypeSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, disease: value.value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={statusList}
                    handleSelectValue={(value) =>
                      setStatusSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, status: value.value })
                    }
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการแผนการดำเนินงาน"
            headRight={buttonAdd()}
          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTable(listAction)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Assess_Risk;
