import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";
import UploadImage from "../../components/UploadImage";

import '../../assets/css/style.css'

function ModalDocument({ show, close, save, mode, idEdit }) {
    const user = useSelector(state => state.login.result);
    const initialForm = {
        documentId: "",
        documentName: "",
        docCategoryId: "",
        checkNewPic: false,
        orderIndex: "",
        statusId: "",
        file: "",
        fileName: "",

        checkShow: false,

        filesDelete: [], //ลบในหน้า ui
        filesChooseDel: [], //ลบเพื่อส่งไป api
    };
    const initInvalidMsg = {
        status: "",
    };
    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];

    const dispatch = useDispatch();
    const history = useHistory();

    const [form, setForm] = useState(initialForm);
    const [invalid, setInvalid] = useState(initInvalidMsg);

    const [docCatList, setDocCatList] = useState([])
    const [docCatSelectValue, setDocCatSelectValue] = useState([])

    const initStatusSelectValue = [];
    const [statusSelectValue, setStatusSelectValue] = useState(initStatusSelectValue);
    const [statusList, setStatusList] = useState(selectStatusList);

    useEffect(async () => {
        getDocCat()
        if (mode === "add") {
            setForm(initialForm)
            setStatusSelectValue([])
        }
    }, [mode])

    useEffect(async () => {
        if (mode === "edit" && !form.checkShow) {
            await getDocumentById(idEdit)
        }
    }, [mode, idEdit, show, docCatList])

    const getDocCat = async () => {
        dispatch(loading(true))
        try {
            const response = await API.getDoc("", 1);
            const data = await response.data;
            if (response.status === 200) {
                let documentArray = [];
                data.content.forEach(element => {
                    documentArray.push({
                        value: element.id, label: element.menuName, name: element.name
                    });
                });

                dispatch(loading(false))
                setForm({ ...form, totalPages: data.totalPages, totalElements: data.totalElements, numberOfElements: data.numberOfElements })
                setDocCatList(documentArray);
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }))
            }
            console.log(error)
        }
    }

    const getDocumentById = async (id) => {
        try {
            const response = await API.getDocumentById(id);
            const dataRes = await response.data;
            if (response.status === 200) {
                if (dataRes) {
                    getDataToForm(dataRes)
                }
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }))
            }
            console.log(error)
        }
    }

    const getDataToForm = async (data) => {

        if (data.statusId) {
            let statusSelect = statusList.find(item => item.value === data.statusId);
            setStatusSelectValue(statusSelect);
        }
        console.log("docCatList", docCatList);
        if (data.docCategoryId) {
            let docCategorySelect = docCatList.find(item => item.value === data.docCategoryId);
            setDocCatSelectValue(docCategorySelect);
        }

        const arrayFile = [];
        if (data.documentFiles && data.documentFiles.length > 0) {
            data.documentFiles.map((item, i) => {
                arrayFile.push({
                    "id": item.id ? item.id : "",
                    "name": item.name,
                    "base64": item.url,
                })
            })
        }

        setForm({
            ...form,
            documentId: data.id,
            documentName: data.name,
            docCategoryId: data.docCategoryId,
            statusId: data.statusId,
            orderIndex: "1",
            file: data.url,
            fileName: data.url
        })
    }

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setStatusSelectValue([])
        setDocCatSelectValue([])
    }

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;

        if (form.statusId === "") {
            addInvalid("status", "กรุณาเลือกสถานะ");
            validated = false;
        }

        if (form.title === "") {
            addInvalid("title", "โปรดระบุหัวข้อ");
            validated = false;
        }

        if (form.description === "") {
            addInvalid("description", "โปรดระบุรายละเอียด");
            validated = false;
        }

        return validated;
    };

    const toConfirm = async () => {
        if (validate()) {
            await save({ document: form, mode: mode });
            handleClose();
        }
    };

    const getFile = (name, file) => {
        setForm({ ...form, file: file, fileName: name, checkNewPic: true })
    }

    const deleteFile = async () => {
        setForm({ ...form, file: "", fileName: "" })
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => { handleClose() }}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่มเอกสารที่เกี่ยวข้อง</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">เพิ่มเอกสาร</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={4} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="หมวดหมู่เอกสาร" star={false}
                                        placeholder="กรุณาเลือก"
                                        idName="title"
                                        classLabel="bold"
                                        selectValue={docCatSelectValue}
                                        optionsList={docCatList}
                                        handleChange={(value) => {
                                            setDocCatSelectValue([value])
                                            setForm({ ...form, docCategoryId: value.value })
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="ชื่อหัวข้อ" idName="fileName" value={form.documentName} star={false}
                                        placeholder="โปรดระบุ" classLabel="bold"
                                        handleChange={(value) => setForm({ ...form, documentName: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("fileName")}
                                        invalid={invalid.documentName}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={6} sm={12} xs={12}>
                                    <UploadImage title="อัปโหลดเอกสาร" classLabel="bold mt-2" star={false} type="fileName" typeSave="base64"
                                        onChange={(name, file) => getFile(name, file)} id="imgfile" position="top"
                                        deleteImg={() => deleteFile()} urlFile={form.file}
                                        files={(form.fileName === null || form.fileName === "") ? "" : form.fileName} />
                                </Col>
                            </Row>
                        </div>
                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false}
                                        placeholder="กรุณาเลือก"
                                        idName="title"
                                        classLabel="bold"
                                        selectValue={statusSelectValue}
                                        optionsList={statusList}
                                        handleChange={(value) => {
                                            setStatusSelectValue(value)
                                            setForm({ ...form, statusId: value.value })
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mr-4">วันที่บันทึกข้อมูล</p>
                                        <p>{form.createDate ? helper.momentDate(form.createDate, "th", "short") : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                                        <p className="mr-3" >วันที่ปรับปรุงข้อมูล</p>
                                        <p>{form.updateDate ? helper.momentDate(form.updateDate, "th", "short") : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                </div>
                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalDocument
