import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as helper from "../utils/helper";
import * as API from "../utils/apis";
import { Modal, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import { useTranslation } from 'react-i18next';
import BoxCard from "./BoxCard"
import InputDatePicker from "./InputDatePicker";
import { logout } from "../redux/actions/logoutAction";
import { loading } from "../redux/actions/loadingAction";


function ModalInstructions({ show, close, modeSee, idSee }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.login.result)
    const { t } = useTranslation(["common", "footer"]);

    const initialForm = {
        farmName: "",
        assessment_date: "",
        score: "",

        resultData: [],

        checkShow: false,


    };
    const [form, setForm] = useState(initialForm);

    const initShowInstructions = false;
    const [showInstructions, setShowInstructions] = useState(initShowInstructions)



    useEffect(async () => {
        if (modeSee === "edit" && !form.checkShow) {
            await getRiskLevelById(idSee)
        }
    }, [modeSee, idSee, show, form.farmName, form.assessment_date, form.score])



    const getRiskLevelById = async (id) => {
        let dataById;
        try {
            const response = await API.getRiskLevelById(id);
            const data = await response.data;
            if (response.status === 200) {
                dataById = data;
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }))

            }
            console.log(error)
        }

        let resultArray = []
        if (dataById.risk && dataById.risk.length > 0) {
            dataById.risk.map((item, i) => {
                resultArray.push({
                    id: item.id,
                    diseaseTypeId: item.diseaseTypeId,
                    diseaseTypeName: item.diseaseTypeName,
                    score: item.score,
                    riskLevel: item.riskLevel,
                    levelName: item.levelName,
                    adviceFarm: item.adviceFarm,
                    adviceBiological: item.adviceBiological

                })
            })
        }


        setForm({
            ...form,
            farmName: dataById.farmName,
            assessment_date: dataById.updateDate,
            score: dataById.score,
            resultData: resultArray,
        })
        // console.log("form7", form);
    }

    const handleClose = () => {
        close()
        setForm(initialForm)
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => { handleClose() }}
            centered
        >
            <Modal.Header closeButton className="pb-2 underline">
                <Modal.Title className="mt-2">
                    <h5><strong>คำแนะนำ</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">

                <Row className="mt-4">
                    <div className="">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            {(form.resultData && form.resultData.length > 0) &&
                                form.resultData.map((itemK, k) => (

                                    <li className="nav-item tab " key={k}>
                                        <a className={k + 1 === 1 ? "nav-link active" : "nav-link "} id={`disease${k + 1}-tab`} data-toggle="tab" href={`#disease${k + 1}`}
                                            role="tab" aria-controls={`disease${k + 1}`} aria-selected={k + 1 === 1 ? "true" : "false"}>
                                            {itemK.diseaseTypeName}
                                        </a>
                                    </li>

                                ))
                            }

                        </ul>
                        <div className="tab-content" id="myTabContent">
                            {(form.resultData && form.resultData.length > 0) &&
                                form.resultData.map((itemResul, indexResul) => (
                                    <div key={indexResul} className={indexResul + 1 === 1 ? "tab-pane fade show active card-tab" : "tab-pane fade show  card-tab"} id={`disease${indexResul + 1}`}
                                        role="tabpanel" aria-labelledby={`disease${indexResul + 1}-tab`}>
                                        <BoxCard classLabel="" title="ลักษณะฟาร์ม" classNameBox="mt-3">
                                            {itemResul.adviceFarm}

                                        </BoxCard>

                                        <BoxCard  title="ความปลอดภัยทางชีวภาพเบื้องต้น" classNameBox="mt-4">
                                            {itemResul.adviceBiological}
                                        </BoxCard>
                                    </div>

                                ))
                            }
                        </div>

                    </div>

                </Row>

                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-success px-4" onClick={() => handleClose()}>เสร็จสิ้น</button>
                    </div>
                </div>


            </Modal.Body>


        </Modal >
    )
}

export default ModalInstructions
