import React, { useState, useEffect } from "react";
import * as helper from "../utils/helper";
import * as API from "../utils/apis";
import { useDispatch, useSelector } from 'react-redux';
import BoxCard from "./BoxCard";
import InputSelect from "./InputSelect";
import InputText from "./InputText";
import { Row, Col } from "react-bootstrap";
import InputDatePicker from "./InputDatePicker";
import DataTableNodata from './DataTableNodata'
import UploadImage from "./UploadImage";
import ModalEditAnimal from "./Modal.EditAnimal";
import { loading } from "../redux/actions/loadingAction";
import moment from 'moment';
import 'moment/locale/th';
import { Portal } from "react-overlays";

function KeepExample_Step2_1({ save, dataAll, modeGet, barnId, methodListData }) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.login.result)

    const initialForm = {
        idHouse: "",
        nameHouse: "",
        caseCode: "",
        animalTypeId: "",
        animalTypeName: "",
        barnId: "",

        data_setAnimal: [],
        barn: [],
        data_file: [],
        del_file: [],
        checkAddFile: false,
        checkAddFilePerAnimal: false,

    };
    const [form, setForm] = useState(initialForm);

    const initialFormFile = {
        url: "",
        file: "",
        detail_file: "",
        date_file: "",
        fileName: ""
    };
    const [formFile, setFormFile] = useState(initialFormFile);

    const initialFormFilePerAnimal = {
        url: "",
        ani_no: "",
        ani_name: "",
        ani_type: "",
        method_id: "",
        lims_case_no: "",
        response_file: "",
        fileName: ""
    };
    const [formFilePerAnimal, setFormFilePerAnimal] = useState(initialFormFilePerAnimal);

    const initialFormJson = {
        animalId: {},
        husbandry: {},
        animalPurpose: {},
        vaccineType: {},
        keepName: {},
    };
    const [formJson, setFormJson] = useState(initialFormJson);


    const [animalSelectValue, setAnimalSelectValue] = useState([])
    const [animalList, setAnimalList] = useState([])

    const [sampleListByAnimal, setSampleListByAnimal] = useState([]);
    const [sampleListByFarm, setSampleListByFarm] = useState([]);
    const [deleteSampleListByBarn, setDeleteSampleListByBarn] = useState([]);

    const initAniTypeSelectValue = [];
    const [aniTypeSelectValue, setAniTypeSelectValue] = useState(initAniTypeSelectValue);
  const [aniTypeList, setAniTypeList] = useState([]);

  const initMethodSelectValue = [];
    const [methodSelectValue, setMethodSelectValue] = useState(initMethodSelectValue);
  const [methodList, setMethodList] = useState([]);

    useEffect(async () => {
        // await getMasterJson()
        // await getAniType()
        // await getMethod()
    }, [])

    useEffect(() => {
      if  (methodListData.length > 0) {
        setMethodList(methodListData);
      }
      
      if (dataAll.length > 0) {
         setSampleListByFarm(dataAll);
      }

      form.barnId = barnId
            
            setForm({...form})
            // getDataById();

    }, [])
    // user, modeGet

    useEffect(async () => {
      if (methodList.length > 0) {
        sampleListByFarm.map((item, i) => {
            if (item.testMethod != null) {
              methodList.map((methodItem, i) => {
                    if (methodItem.value == item.testMethod) {
                        item.method = methodItem;
                    }
                })
            }
        })
        await setSampleListByFarm([...sampleListByFarm]);
      }
    }, [methodList])

    useEffect(async () => {
        await toSave()
    }, [form])

    const toSave = () => {
        save(form, barnId)
    }

    const CalendarContainer = ({ children }) => {
        const el = document.getElementById("calendar-portal");
      
        return <Portal container={el}>{children}</Portal>;
      };

    const getMasterJson = async () => {
        const params = await [
            "animalType",
            "husbandry",
            "animalPurpose",
            "vaccineType",
            "keepType"
        ];
        try {
            const response = await API.getMasterJson(params);
            const data = await response.data;
            if (response.status == 200) {
                setFormJson({
                    ...formJson,
                    animalId: data.animalType,
                    husbandry: data.husbandry,
                    animalPurpose: data.animalPurpose,
                    vaccineType: data.vaccineType,
                    keepName: data.keepType,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAniType = async () => {

        try {
          const response = await API.getAnimaltype();
          const data = await response.data;
          // console.log("params---getOutbreak--🐳🐳", params, response);
    
          if (response.status == 200) {
            let objArray = [];
              data.content.forEach((element) => {
                objArray.push({
                  value: element.id,
                  label: element.name,
                });
              });
              setAniTypeList(objArray);
          }
        } catch (error) {
          console.log(error);
        }
      };

      const getMethod = async () => {

        try {
          const response = await API.getTestMethod();
          const data = await response.data;
          // console.log("params---getOutbreak--🐳🐳", params, response);
    
          if (response.status == 200) {
            let objArray = [];
              data.content.forEach((element) => {
                objArray.push({
                  value: element.id,
                  label: element.name,
                });
              });
              setMethodList(objArray);
          }
        } catch (error) {
          console.log(error);
        }
      };


    const toAddFile = () => {
        setForm({ ...form, checkAddFile: true })
        setFormFile({ ...formFile, date_file: Date.now(), detail_file: "", file: "", fileName: "" })
    };

    // const toAddFilePerAnimal = () => {
    //     setForm({ ...form, checkAddFilePerAnimal: true })
    //     setFormFile({ ...formFilePerAnimal, ani_no: "", ani_name: "", ani_type: "", method_id: "", lims_case_no: "", response_file: "", fileName: ""})
    // };

    const toAddFilePerFarm = (item) => {
        const data = sampleListByFarm.map((item) => {
          return { ...item, edit: true };
        });
        // const data = form.barn.map((item) => {
        //     const data2 = item.sample.map((item) => {
        //         return  {...item, edit: true }
        //     });
        //     data2.push({});
        //     //return { ...item, edit: true };
        //   });
        setSampleListByFarm([...data, { disease_movement: [], save: true }]);
        //setForm({...form, barn : data})
      };

    const toAddFilePerAnimal = () => {
        const data = sampleListByAnimal.map((item) => {
          return { ...item, edit: true };
        });
        setSampleListByAnimal([...data, { disease_movement: [], save: true }]);
      };

    const removeImage = (index, item) => {
        let delFile = []
        form.data_file.splice(index, 1);
        if (item.id) {
            delFile.push(item.id)
        }
        setForm({ ...form, data_file: form.data_file, del_file: delFile });
    }

    const fileSave = (data) => {
        let arrayFile = [...form.data_file];
        arrayFile.push({
            file: data.file,
            fileName: data.fileName,
            detail_file: data.detail_file,
            date_file: Date.now()
        })
        setForm({ ...form, data_file: arrayFile, checkAddFile: false });
        toSave()
    }

    // const getDataById = async () => {
    //     const sampleTestFiles = dataAll.step2_TestFiles;

    //     let barn_Step1 = []
    //     if (dataAll.barn_Step1 && dataAll.barn_Step1.length > 0) {
    //         dataAll.barn_Step1.map(async (itemBarn, indexBarn) => {

    //             let arrayBuilding = [];
    //             try {
    //                 const response = await API.getFarmRegistrationById(farmId ? farmId.fm01.farmId : "");
    //                 const dataRes = await response?.data;
    //                 if (response.status == 200) {
    //                     let arrayHouseList = []
    //                     if (dataRes && dataRes.barn.length > 0) {
    //                         dataRes.barn.forEach((house =>
    //                             arrayBuilding.push({ value: house.id, label: house.name })
    //                         ))
    //                     }
    //                 }
    //             } catch (error) {
    //                 dispatch(loading(false))
    //                 if (error.response && error.response.status == 401) {
    //                     console.log(error)
    //                 }
    //             }

    //             let nameHouse;
    //             arrayBuilding.filter((itemH) => {
    //                 if (itemH.value == itemBarn.barnId) {
    //                     nameHouse = itemH.label;
    //                 }
    //             });

    //             let animalTypeName;
    //             animalListData.filter((itemA) => {
    //                 if (itemA.value == itemBarn.animalTypeId) {
    //                     animalTypeName = itemA.label;
    //                 }
    //             });

    //             barn_Step1.push({
    //                 id: itemBarn.id,
    //                 idHouse: itemBarn.barnId,
    //                 nameHouse: nameHouse,
    //                 animalTypeId: itemBarn.animalTypeId,
    //                 animalTypeName: animalTypeName,
    //                 number: itemBarn.animalAmt,
    //                 caseLims: itemBarn.limsCaseId,
    //                 houseSelectValue: [{ value: itemBarn.barnId, label: nameHouse }],
    //                 houseList: [],
    //                 animalTypeSelectValue: [{ value: itemBarn.animalTypeId, label: animalTypeName }],
    //                 animalTypeList: [],
    //                 result: itemBarn.result,
    //             })
    //         })
    //     }

    //     let step1Ex = [];
    //     if (dataAll.step1Ex && dataAll.step1Ex.length > 0) {
    //         dataAll.step1Ex.map((item, i) => {
    //             if (item.result && item.result.length > 0) {
    //                 item.result.map((itemR, indexR) => {
    //                     step1Ex.push({
    //                         idEx: item.id,
    //                         animalTypeId: item.animalTypeId,
    //                         name: item.animalName,
    //                         no_nid: item.nid,
    //                         result: itemR
    //                     })
    //                 })
    //             }
    //         })
    //     }

    //     let step2File = [];
    //     if (dataAll.step2File && dataAll.step2File.length > 0) {
    //         dataAll.step2File.map((item, i) => {
    //             step2File.push({
    //                 id: item.id,
    //                 file: item.url,
    //                 fileName: item.name,
    //                 detail_file: item.description,
    //                 date_disease: item.date,
    //             })
    //         })
    //     }

    //     console.log("step1Ex", step1Ex);

    //     setForm({
    //         ...form,
    //         data_file: step2File,
    //         barn: barn_Step1,
    //         data_setAnimal: step1Ex,
    //     })
    // }


    const renderTableFarm = (data, i) => {
        return (
            <tr key={i}  >
                <td>{i + 1}</td>
                <td>
                {data.save ? (<InputText
                            classFormGroup="mb-0 "
                            type="text"
                            idName={`licenseNo_${i}`}
                            value={data.limsLicenseNo ? data.limsLicenseNo : ""}
                            star={false}
                            placeholder="โปรดระบุ"
                            handleChange={(value) => {
                                    data.limsLicenseNo = value
                                    setSampleListByFarm([...sampleListByFarm])
                                }
                            }
                            />
                            ) : (
                                <span className="text-nowrap">{data.limsLicenseNo}</span>
                              )}
                </td>
                <td>
                {data.save ? (<InputText
                            classFormGroup="mb-0 "
                            type="text"
                            idName={`caseNo_${i}`}
                            value={data.limsCaseId ? data.limsCaseId : ""}
                            star={false}
                            placeholder="โปรดระบุ"
                            handleChange={(value) => {
                                    data.limsCaseId = value
                                    setSampleListByFarm([...sampleListByFarm])
                                }
                            }
                    />
                    ) : (
                        <span className="text-nowrap">{data.limsCaseId}</span>
                      )}
                </td>
                        
                        <td>
                        {data.save ? (
                            <InputSelect
                            classFormGroup="mb-0"
                            star={false}
                            placeholder="กรุณาเลือก"
                            idName={`method_${i}`}
                            classLabel="normal"
                            selectValue={data.method}
                            optionsList={methodList}
                            handleChange={(value) => {
                                data.method = value
                                data.testMethod = value.value
                                setMethodSelectValue([value])
                                setSampleListByFarm([...sampleListByFarm])
                            }}
                            />
                            ) : (
                                <span className="text-nowrap">{data.method ? data.method.label : ""}</span>
                              )}
                            </td>
                        <td>
                        {data.save ? (<InputText
                            classFormGroup="mb-0 "
                            type="text"
                            idName={`testResult_${i}`}
                            value={data.testResult ? data.testResult : ""}
                            star={false}
                            placeholder="โปรดระบุ"
                            handleChange={(value) => {
                                    data.testResult = value
                                    setSampleListByFarm([...sampleListByFarm])
                                }
                            }
                    />
                    ) : (
                        <span className="text-nowrap">{data.testResult}</span>
                      )}
                        </td>
                        <td>  {data.save ? (<InputDatePicker
                                classFormGroup="mb-0"
                                star={false}
                                placeholder="กรุณาเลือก"
                                idName={`testDate_${i}`}
                                classLabel="normal"
                                value={data.testDate}
                                // popperProps={{
                                //   positionFixed: true // use this to make the popper position: fixed
                                // }}
                                handleChange={(value) => {
                                    data.testDate = value;
                                    setSampleListByFarm([...sampleListByFarm])
                                }}
                                />
                                ) : (
                                    <span className="text-nowrap">{ data.testDate ? moment(new Date(data.testDate)).local('th').format('DD-MM-YYYY') : ""}</span>
                                  )}
                        </td>
                        <td>
                        {data.save ? (<UploadImage 
                                title=" " 
                                classLabel="mb-0" 
                                star={false} 
                                type="fileName" 
                                typeSave="base64"
                                onChange={(name, file) => {
                                    data.fileName = name
                                    data.fileData = file
                                    setSampleListByFarm([...sampleListByFarm])
                                }
                            } 
                            id={`imgfile_${i}`} 
                            position="left"
                            files={(data.fileName == null || data.fileName == "") ? "" : data.fileName} />
                            ) : (
                              <>
                                  {(data.docLink) ?
                                    (<span className="link"
                                        onClick={() => {
                                            data.docLink ? window.open(data.docLink, '_blank').focus() : window.open("", '_blank').focus()
                                        }}
                                    >
                                        {data.fileName || "-"}
                                    </span>)
                                    :
                                    (<span className="text-nowrap">{data.fileName}</span>)
                                }
                                </>
                              )}

                        </td>
                        <td className="text-center">
              {data.save ? (
                <button
                  className="btn btn-success pt-1 px-2"
                  onClick={() => {
                    data.save = false;
                    const itemList = sampleListByFarm.map((item) => {
                       return { ...item, edit: false };
                    });

                    data.sampleBarnId = barnId
                    
                    setSampleListByFarm([...sampleListByFarm])
                    setForm({...form, sampleListBarn : sampleListByFarm});
                  }}
                >
                  <img
                    className="img-fluid icon-check"
                    src="/svg/btn-check.svg"
                    alt="check"
                  />
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-add mr-2"
                    onClick={() => {
                       data.save = true;
                       setSampleListByFarm([...sampleListByFarm])
                    }}
                  >
                    <img
                      className="img-fluid icon-btn"
                      src="/svg/btn-edit.svg"
                      alt="edit"
                    />
                  </button>
                  <button
                    className="btn btn-del "
                    onClick={() => {
                       sampleListByFarm.splice(i, 1);
                       setSampleListByFarm([...sampleListByFarm])
                       deleteSampleListByBarn.push(data.id)
                        setDeleteSampleListByBarn([...deleteSampleListByBarn])
                        setForm({...form, sampleListBarn : sampleListByFarm, deleteSampleListBarn: deleteSampleListByBarn});
                       
                    }}
                  >
                    <img
                      className="img-fluid icon-btn "
                      src="/svg/btn-del.svg"
                      alt="del"
                    />
                  </button>
                </>
              )}
            </td>
            </tr>
        )
    }

    return (
        <div className="bg-white p-4 ">
            <Col className="border mt-3">
                        <div className="pl-0 pr-0">
                            <Row className="d-flex justify-content-between mt-3 pl-3 pr-3 pb-3">
                                <div className="d-flex text-gray font12 align-items-center">
                                    <p className="mb-0"></p>
                                </div>
                                <div className=" ">
                                    <button className="btn btn-add text-white" onClick={() => toAddFilePerFarm()}>
                                        <i className="fas fa-plus mr-1 "></i>เพิ่มไฟล์
                                    </button>
                                </div>
                            </Row>
                            <Row className="pb-3">
                                <div className="table-responsive">
                                    <table className="table  table-striped table-bordered my-2 text-cente">
                                        <thead>
                                            <tr className="text-center" >
                                                <th scope="col">ลำดับ</th>
                                                <th scope="col">License no</th>
                                                <th scope="col">เลขที่เคส</th>
                                                <th scope="col">วิธีการทดสอบ</th>
                                                <th scope="col">ผลการตรวจ</th>
                                                <th scope="col">วันที่ตอบผล</th>
                                                <th scope="col-2" >ใบตอบผล</th>
                                                <th scope="col">จัดการ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sampleListByFarm.length > 0 ? (
                                                    sampleListByFarm.map((sampleItem, index) => {
                                                        return renderTableFarm(sampleItem, index);
                                                    })
                                                    ) : (
                                                        <DataTableNodata colSpan={8} />
                                                    )
                                            }
                                                
                                        </tbody>
                                    </table>
                                </div>
                            </Row>
                        </div>
            </Col>
        </div>
    )
}

export default KeepExample_Step2_1
