import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { Modal, Row, Col, Table } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";

import InputText from "../InputText";
import InputSelect from "../InputSelect";

import * as API from "../../utils/apis";

function ModalRisk({ show, close, save, mode, idEdit }) {
    const user = useSelector(state => state.login.result);
    const dispatch = useDispatch();
    const history = useHistory();
    const initialForm = {
        riskId: "",
        diseaseTypeId: "",
        adviceFarm: "",
        adviceBiological: "",
        risk1: "",
        risk2: "",
        risk3: "",
        risk4: "",
        risk5: "",
        statusId: "",


        createBy: "",
        createDate: "",
        createByName: "",

        updateBy: "",
        updateDate: "",
        updateByName: "",
        limit: 100,
        page: 1,
        sortDir: "DESC",
        sortBy: "id",

        checkShow: false
    };
    const initInvalidMsg = {
        diseaseTypeId: "",
        status: "",
        data: "",
    };
    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];
    const initStatusSelectValue = [];

    const [form, setForm] = useState(initialForm);
    const [invalid, setInvalid] = useState(initInvalidMsg);
    const [statusSelectValue, setStatusSelectValue] = useState(initStatusSelectValue);
    const [statusList, setStatusList] = useState(selectStatusList);

    const [diseaseSelectValue, setDiseaseSelectValue] = useState([]);
    const [diseaseList, setDiseaseList] = useState([]);


    useEffect(() => {
        if (diseaseList && diseaseList.length <= 0) {
            getDiseaseType()
        }
    }, [form.diseaseTypeId])

    useEffect(() => {
        if (mode === "add") {
            setForm(initialForm)
            setStatusSelectValue([])
        }
    }, [mode])

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;
        let riskAll = "";
        let concatString = riskAll.concat(form.risk1, form.risk2, form.risk3, form.risk4, form.risk5)
        if (form.diseaseTypeId === "") {
            addInvalid("diseaseTypeId", "โปรดระบุชนิดโรค");
            validated = false;
        }
        if (concatString.length <= 0) {
            addInvalid("data", "ระบุคะแนนความเสี่ยงอย่างน้อย 1 ช่อง");
            validated = false;
        }

        if (form.statusId === "") {
            addInvalid("status", "โปรดระบุสถานะ");
            validated = false;
        }

        return validated;
    };

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setStatusSelectValue([])
    }

    const toConfirm = async () => {
        if (validate()) {
            if (mode == "add") {
                setForm({ ...form, createBy: user.profile.userTypeId, createByName: user.profile.firstName + " " + user.profile.lastName })
            } else {
                setForm({ ...form, updateBy: user.profile.userTypeId, updateByName: user.profile.firstName + " " + user.profile.lastName })
            }
            await save({ risk: form, mode: mode });
        }
    };

    const getDiseaseType = async () => {
        let dataDisease = [];
        try {
            const response = await API.getDiseaseType("", form.diseaseTypeId,);
            const data = await response?.data;
            if (response?.status === 200) {
                data.content.map((item) => {
                    dataDisease.push({
                        value: item.id,
                        label: item.name
                    });
                });
            }
            if (mode === "edit" && form.riskId !== idEdit && show) {
                getRiskById(idEdit, dataDisease)
            }
            setDiseaseList(dataDisease)
        } catch (error) {
            if (error.response && error.response?.status === 401) {
                dispatch(logout({ history }))
            }
            console.log(error)
        }
    };

    const getRiskById = async (id, diseaseArray) => {
        let dataRisk;
        try {
            const response = await API.getRiskById(id);
            const data = await response?.data;
            if (response?.status === 200) {
                dataRisk = data;
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response?.status === 401) {
                dispatch(logout({ history }))
            }
            console.log(error)
        }

        if (mode === "edit" && dataRisk.statusId) {
            let statusSelect = statusList.find(item => item.value === dataRisk.statusId);
            setStatusSelectValue(statusSelect);
        }

        if (mode === "edit" && dataRisk.diseaseTypeId) {

            let diseaseSelect = diseaseArray.find(item => item.value === dataRisk.diseaseTypeId);
            setDiseaseSelectValue(diseaseSelect);
        }

        setForm({
            ...form,
            riskId: dataRisk.id,
            diseaseTypeId: dataRisk.diseaseTypeId,
            adviceFarm: dataRisk.adviceFarm,
            adviceBiological: dataRisk.adviceBiological,
            risk1: dataRisk.risk1,
            risk2: dataRisk.risk2,
            risk3: dataRisk.risk3,
            risk4: dataRisk.risk4,
            risk5: dataRisk.risk5,
            statusId: dataRisk.statusId,

            createBy: dataRisk.createBy,
            createDate: dataRisk.createDate,
            createByName: dataRisk.createByName,

            updateBy: dataRisk.updateBy,
            updateDate: dataRisk.updateDate,
            updateByName: dataRisk.updateByName,
        })
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => handleClose()}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่ม/แก้ไขเกณฑ์ผลการประเมินความเสี่ยง</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={4} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="ชนิดโรค"
                                        star={true}
                                        placeholder="กรุณาเลือก"
                                        idName="diseaseTypeId"
                                        classLabel="normal"
                                        selectValue={diseaseSelectValue}
                                        optionsList={diseaseList}
                                        handleChange={(value) => {
                                            setDiseaseSelectValue([
                                                { value: value.value, label: value.label },
                                            ])
                                            setForm({ ...form, diseaseTypeId: value.value })
                                        }
                                        }
                                        handleInvalid={() => removeInvalid("diseaseTypeId")}
                                        invalid={invalid.diseaseTypeId}

                                    />
                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} style={{ marginLeft: '40px' }}>
                                    <InputText type="text" title="≤ ความเสี่ยงต่ำมาก" idName="risk1" value={form.risk1} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, risk1: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("data")}
                                        invalid={invalid.data}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="คำแนะนำลักษณะฟาร์ม" idName="adviceFarm" value={form.adviceFarm} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, adviceFarm: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("adviceFarm")}
                                    />
                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} style={{ marginLeft: '40px' }}>
                                    <InputText type="text" title="≤ ความเสี่ยงต่ำ" idName="risk2" value={form.risk2} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, risk2: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("data")}
                                        invalid={invalid.data}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="คำแนะนำความปลอดภัยทางชีวภาพ" idName="adviceBiological" value={form.adviceBiological} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, adviceBiological: value, checkShow: true })}
                                    />
                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} style={{ marginLeft: '40px' }}>
                                    <InputText type="text" title="≤ ความเสี่ยงปานกลาง" idName="risk3" value={form.risk3} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, risk3: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("data")}
                                        invalid={invalid.data}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} md={6} sm={12} xs={12}><div></div></Col>
                                <Col lg={4} md={6} sm={12} xs={12} style={{ marginLeft: '40px' }}>
                                    <InputText type="text" title="≤ ความเสี่ยงสูง" idName="risk4" value={form.risk4} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, risk4: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("data")}
                                        invalid={invalid.data}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} md={6} sm={12} xs={12}><div></div></Col>
                                <Col lg={4} md={6} sm={12} xs={12} style={{ marginLeft: '40px' }}>
                                    <InputText type="text" title="≤ ความเสี่ยงสูงมาก" idName="risk5" value={form.risk5} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, risk5: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("risdatak5")}
                                        invalid={invalid.data}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} md={6} sm={12} xs={12}><div></div></Col>
                                <Col lg={4} md={6} sm={12} xs={12} style={{ marginLeft: '40px' }}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                                        <p className="mr-3">* คะแนนเท่ากับ 0 ไม่มีความเสี่ยง</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                        star={true}
                                        selectValue={statusSelectValue} optionsList={statusList}
                                        handleChange={(value) => {
                                            setStatusSelectValue(value)
                                            setForm({ ...form, statusId: value.value, checkShow: true })
                                        }}
                                        handleInvalid={() => removeInvalid("status")} invalid={invalid.status}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={12} sm={12}>
                                    <table>
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-left text-gray font12">
                                                    วันที่บันทึกข้อมูล
                                                </div>
                                            </td>
                                            <td>
                                                <div className="align-items-left text-gray font12">
                                                    {form.createDate ? helper.momentDateTime(form.createDate, "th", "short") + " " + helper.getTime(form.createDate, "th") : '-'}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <div className="align-items-left text-gray font12">
                                                    {form.createByName || '-'}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="align-items-left text-gray font12">
                                                    วันที่ปรับปรุงข้อมูล
                                                </div>
                                            </td>
                                            <td>
                                                <div className="align-items-left text-gray font12">
                                                    {form.updateDate ? helper.momentDateTime(form.updateDate, "th", "short") + " " + helper.getTime(form.updateDate, "th") : '-'}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <div className="align-items-left text-gray font12">
                                                    {form.updateByName || '-'}
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalRisk
