import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { loading } from "../../redux/actions/loadingAction";
import { Row, Col, Form } from "react-bootstrap";
import pdfMake from "pdfmake-thai/build/pdfmake";
import pdfFonts from "pdfmake-thai/build/vfs_fonts";
import BoxCard from "../../components/BoxCard";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import InputText from "../../components/InputText";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "../../components/DataTable";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useMasterData } from "../../components/hooks/useMasterData";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Disease_Control_Report() {
  const ref = React.createRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);
  const history = useHistory();
  const { t } = useTranslation(["common", "footer"]);
  const statusDisease = useSelector((state) => state.statusDisease);

  const initialForm = {
    id: "",
    obCode: "",
    caseCode: "",
    provinceId: "",
    amphurId: "",
    tambonId: "",
    mooId: "",
    orgName: "",
    zoneId: "",
    provinceName: "",
    amphurName: "",
    tambonName: "",
    mooName: "",
    homeNo: "",
    dateBegin: "",
    dateEnd: "",
    animalTypeId: "",
    animalName: "",
    diseaseTypeId: "",
    diseaseTypeName: "",
    operateDate: "",
    totalAmt: "",
    farmAmt: "",
    sickAmtDate: "",
    deadAmtDate: "",
    deadAmt: "",
    sickAmtDateBack: "",
    sickAmtDateSelect: "",
    sickAmt: "",
    recoveryAmt: "",
    mathSick: "",
    avgSick: "",
    deadAmtDateSelec: "",
    deadAll: "",
    avgDead: "",

    foundDateBegin: "",
    foundDateEnd: "",
    createDateBegin: "",
    createDateEnd: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        name: "ลำดับ",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "obCode",
        name: "เลข Outbreak ID",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "operateDate",
        name: "วันที่รายงาน",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        name: "พื้นที่เกิดโรค",
        sortFlg: false,
        colSpan: 5,
        childHead: [
          {
            sortBy: "orgName",
            name: "เขตปศุสัตว์",
            sortFlg: true,
          },
          {
            sortBy: "provinceName",
            name: "จังหวัด",
            sortFlg: true,
          },
          {
            sortBy: "amphurName",
            name: "อำเภอ",
            sortFlg: true,
          },
          {
            sortBy: "tambonName",
            name: "ตำบล",
            sortFlg: true,
          },
          {
            sortBy: "mooName",
            name: "หมู่ที่/หมู่บ้าน",
            sortFlg: true,
          },
        ],
      },
      {
        sortBy: "animalName",
        name: "ชนิดสัตว์",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "totalAmt",
        name: "จำนวนสัตว์กลุ่มเสี่ยง(ร่วมฝูง)",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "farmAmt",
        name: "จำนวนเกษตรกร",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "sickAmtDate",
        name: "จำนวนเกษตรกรที่พบสัตว์ป่วยใหม่",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "deadAmtDate",
        name: "จำนวนเกษตรกรที่พบสัตว์ตายใหม่",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "deadAmt",
        name: "จำนวนเกษตรกรที่พบสัตว์ตายทั้งหมด",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        name: "จำนวนสัตว์ป่วย",
        sortFlg: false,
        colSpan: 6,
        childHead: [
          {
            sortBy: "sickAmtDateBack",
            name: "ป่วยแรกเกิดโรค",
            sortFlg: true,
          },
          {
            sortBy: "sickAmtDateSelect",
            name: "สัตว์ป่วยใหม่",
            sortFlg: true,
          },
          {
            sortBy: "sickAmt",
            name: "ป่วยทั้งหมดสะสม",
            sortFlg: true,
          },
          {
            sortBy: "recoveryAmt",
            name: "หายป่วย",
            sortFlg: true,
          },
          {
            sortBy: "mathSick",
            name: "ป่วยคงเหลือ",
            sortFlg: true,
          },
          {
            sortBy: "avgSick",
            name: "อัตราการป่วย",
            sortFlg: true,
          },
        ],
      },
      {
        name: "จำนวนสัตว์ตาย",
        sortFlg: false,
        colSpan: 2,
        childHead: [
          {
            sortBy: "deadAmtDateSelect",
            name: "สัตว์ตายใหม่",
            sortFlg: true,
          },
          {
            sortBy: "deadAll",
            name: "ตายทั้งหมดสะสม",
            sortFlg: true,
          },
        ],
      },
      {
        sortBy: "avgDead",
        name: "อัตราการตาย",
        sortFlg: true,
        rowSpan: 2,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listDiseaseControl, setDiseaseControl] = useState([]);

  // select จังหวัด อำเภอ
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  // select เขตปศุสัตว์
  const [zoneLivestockList, setZoneLivestockList] = useState([]);
  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState([]);

  const [fileType, setFileType] = useState("excel");
  const fileName = "C03RPT05-DiseaseControl" + helper.getDate(new Date());

  const [isClearSearch, setIsClearSearch] = useState(false);

  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );

  // =================

  //  ชนิดสัตว์
  const [diseaseSelectValue, setDiseaseSelectValue] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  // =================

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "title", name: "รายงาน" },
    { path: "active", name: "B99RPT05-รายงานควบคุมโรค" },
  ];

  pdfMake.fonts = {
    THSarabunNew: {
      normal: "THSarabunNew.ttf",
      bold: "THSarabunNew-Bold.ttf",
      italics: "THSarabunNew-Italic.ttf",
      bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
  };

  useEffect(() => {
    getDiseaseControlReport();
    // getProvince();
    getZoneLivestock();
    getAnimal();
    getDiseaseType();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getDiseaseControlReport = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getDiseaseControlReport(
        form.sortBy,
        form.sortDir,
        form.page,
        form.limit,
        form.zoneId,
        "",
        form.provinceId,
        form.amphurId,
        form.tambonId,
        form.diseaseTypeId,
        form.foundDiseaseDate,
        "",
        form.obCode,
        "",
        "",
        form.foundDateBegin ? helper.getDate(form.foundDateBegin) : "",
        form.foundDateEnd ? helper.getDate(form.foundDateEnd) : "",
        form.createDateBegin ? helper.getDate(form.createDateBegin) : "",
        form.createDateEnd ? helper.getDate(form.createDateEnd) : "",
        form.animalTypeId,
        form.caseCode
      );
      const data = await response?.data;
      if (response.status == 200) {
        setDiseaseControl(data.content);
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseControlToExport = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getDiseaseControlReport(
        form.sortBy,
        form.sortDir,
        1,
        form.totalElements
      );
      const data = await response?.data;
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            id: element.id,
            obCode: element.obCode,
            caseCode: element.caseCode,
            operateDate: element.operateDate,
            orgName: element.orgName,
            provinceName: element.provinceName,
            amphurName: element.amphurName,
            tambonName: element.tambonName,
            mooName: element.mooName,
            animalName: element.animalName,
            totalAmt: element.totalAmt,
            farmAmt: element.farmAmt,
            sickAmtDate: element.sickAmtDate,
            deadAmtDate: element.deadAmtDate,
            deadAmt: element.deadAmt,
            sickAmtDateBack: element.sickAmtDateBack,
            sickAmtDateSelect: element.sickAmtDateSelect,
            sickAmt: element.sickAmt,
            recoveryAmt: element.recoveryAmt,
            mathSick: element.mathSick,
            avgSick: element.avgSick,
            deadAmtDateSelect: element.deadAmtDateSelect,
            deadAll: element.deadAll,
            avgDead: element.avgDead,
          });
        });
        downloadRecord(diseaseArray, data.content);
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType("", "", "1", "100", 1);
      const data = await response.data;
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseaseList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setSubDistrictList([]);
    setForm({ ...form, amphurId: value.value, zipcode: "" });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, tambonId: value.value, zipcode: value.zipcode });
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setZoneLivestockSelectValue([]);
    setProvinceSelectValue([]);
    setAnimalSelectValue([]);
    setDistrictSelectValue([]);
    setDistrictList([]);
    setDistrictSelectValue([]);
    setDiseaseSelectValue([]);
    setFileType("excel");
    setIsClearSearch(!isClearSearch);
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const buttonSearch = () => {
    return (
      <>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const buttonExport = () => {
    return (
      <>
        <button
          className="btn btn-report"
          onClick={() => getDiseaseControlToExport()}
          disabled={user?.userGroup.mexport === "n"}
        >
          <a className="text-white">
            <img
              className="nav-icon"
              src={"/svg/report.svg"}
              alt="export-icon"
            />{" "}
            ออกรายงาน
          </a>
        </button>
      </>
    );
  };

  const convertListToExcelTable = (objArray) => {
    let excelTable = [];
    objArray.map((element, index) => {
      excelTable.push({
        ลำดับ: index + 1,
        เลขOutbreakID: element.obCode,
        วันที่รายงาน: helper.momentDate(element.operateDate, "th", "short"),
        เขตปศุสัตว์: element.orgName,
        จังหวัด: element.provinceName,
        อำเภอ: element.amphurName,
        ตำบล: element.tambonName,
      });
      excelTable[index]["หมู่ที่/หมู่บ้าน"] = element.mooName || "-";
      excelTable[index]["ชนิดสัตว์"] = element.animalName || "-";
      excelTable[index]["จำนวนสัตว์กลุ่มเสี่ยง(ร่วมฝูง)"] =
        element.totalAmt || "-";
      excelTable[index]["จำนวนเกษตรกร"] = element.farmAmt || "-";
      excelTable[index]["จำนวนเกษตรกรที่พบสัตว์ป่วยใหม่"] =
        element.sickAmtDate || "-";
      excelTable[index]["จำนวนเกษตรกรที่พบสัตว์ตายใหม่"] =
        element.deadAmtDate || "-";
      excelTable[index]["จำนวนเกษตรกรที่พบสัตว์ตายทั้งหมด"] =
        element.deadAmt || "-";
      excelTable[index]["ป่วยแรกเกิดโรค"] = element.sickAmtDateBack || "-";
      excelTable[index]["สัตว์ป่วยใหม่"] = element.sickAmtDateSelect || "-";
      excelTable[index]["ป่วยทั้งหมดสะสม"] = element.sickAmt || "-";
      excelTable[index]["หายป่วย"] = element.recoveryAmt || "-";
      excelTable[index]["ป่วยคงเหลือ"] = element.mathSick || "-";
      excelTable[index]["อัตราการป่วย"] = element.avgSick || "-";
      excelTable[index]["สัตว์ตายใหม่"] = element.deadAmtDateSelect || "-";
      excelTable[index]["ตายทั้งหมดสะสม"] = element.deadAll || "-";
      excelTable[index]["อัตราการตาย"] = element.avgDead || "-";
    });
    return excelTable;
  };

  const convertToCSV = (objArray) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let headCol = "";
    let dataRow = "";
    let key = Object.keys(array[0]);
    key.forEach((item) => {
      if (headCol === "") {
        headCol = item;
      } else {
        if (item != "") {
          headCol += ",";
          headCol += item;
        }
      }
    });
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") {
          line += ",";
        }
        line += array[i][index];
      }
      dataRow += line + "\r\n";
    }
    return headCol + "\r\n" + dataRow;
  };

  const convertToExcel = (objArray) => {
    const workSheet = XLSX.utils.json_to_sheet(objArray);
    const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    return XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  };

  const printPDF = (fileName, dataListForPDF) => {
    let listDiseasePdf = [];
    if (dataListForPDF && dataListForPDF.length > 0) {
      dataListForPDF.map((item, i) => {
        listDiseasePdf.push([
          i + 1,
          item.obCode,
          helper.momentDate(item.operateDate, "th", "short"),
          item.orgName,
          item.provinceName,
          item.amphurName,
          item.tambonName,
          item.mooName,
          item.animalName,
          item.totalAmt,
          item.farmAmt,
          item.sickAmtDate,
          item.deadAmtDate,
          item.deadAmt,
          item.sickAmtDateBack,
          item.sickAmtDateSelect,
          item.sickAmt,
          item.recoveryAmt,
          item.mathSick,
          item.avgSick,
          item.deadAmtDateSelect,
          item.deadAll,
          item.avgDead,
        ]);
      });
    }

    let bodyArray = [
      [
        {
          text: "ลำดับ",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "เลข Outbreak ID",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "วันที่รายงาน",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "พื้นที่เกิดโรค",
          style: "tableHeader",
          alignment: "center",
          colSpan: 5,
        },
        {},
        {},
        {},
        {},
        {
          text: "ชนิดสัตว์",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "จำนวนสัตว์กลุ่มเสี่ยง(ร่วมฝูง)",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "จำนวนเกษตรกร",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "จำนวนเกษตรกรที่พบสัตว์ป่วยใหม่",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "จำนวนเกษตรกรที่พบสัตว์ตายใหม่",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "จำนวนเกษตรกรที่พบสัตว์ตายทั้งหมด",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "จำนวนสัตว์ป่วย",
          style: "tableHeader",
          alignment: "center",
          colSpan: 6,
        },
        {},
        {},
        {},
        {},
        {},
        {
          text: "จำนวนสัตว์ตาย",
          style: "tableHeader",
          alignment: "center",
          colSpan: 2,
        },
        {},
        {
          text: "อัตราการตาย",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
      ],
      [
        {},
        {},
        {},
        { text: "เขตปศุสัตว์", style: "tableHeader", alignment: "center" },
        { text: "จังหวัด", style: "tableHeader", alignment: "center" },
        { text: "อำเภอ", style: "tableHeader", alignment: "center" },
        { text: "ตำบล", style: "tableHeader", alignment: "center" },
        { text: "หมู่ที่/หมู่บ้าน", style: "tableHeader", alignment: "center" },
        {},
        {},
        {},
        {},
        {},
        {},
        { text: "ป่วยแรกเกิดโรค", style: "tableHeader", alignment: "center" },
        { text: "สัตว์ป่วยใหม่", style: "tableHeader", alignment: "center" },
        { text: "ป่วยทั้งหมดสะสม", style: "tableHeader", alignment: "center" },
        { text: "หายป่วย", style: "tableHeader", alignment: "center" },
        { text: "ป่วยคงเหลือ", style: "tableHeader", alignment: "center" },
        { text: "อัตราการป่วย", style: "tableHeader", alignment: "center" },
        { text: "สัตว์ตายใหม่", style: "tableHeader", alignment: "center" },
        { text: "ตายทั้งหมดสะสม", style: "tableHeader", alignment: "center" },
        {},
      ],
    ];
    listDiseasePdf.map((item, i) => {
      bodyArray.push(listDiseasePdf[i]);
    });

    let docDefinition = {
      pageOrientation: "landscape",
      pageMargins: [5, 5, 5, 5],
      content: [
        {
          style: "tableExample",
          table: {
            widths: [
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
            ],
            headerRows: 1,
            body: bodyArray,
          },
        },
      ],
      defaultStyle: {
        fontSize: 6.5,
        font: "THSarabunNew",
      },
    };
    pdfMake.createPdf(docDefinition).download(fileName + ".pdf");
  };

  const downloadRecord = (dataList, dataListForPDF) => {
    let type = "";
    if (fileType === "excel") {
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = convertToExcel(convertListToExcelTable(dataList));
      const data = new Blob([excelBuffer], { type: type });
      FileSaver.saveAs(data, fileName + ".xlsx");
    }
    if (fileType === "csv") {
      type = "text/csv;charset=utf-8";
      const csv = convertToCSV(convertListToExcelTable(dataList));
      const csvData = new Blob([csv], { type: type });
      FileSaver.saveAs(csvData, fileName + ".csv");
    }
    if (fileType === "pdf") {
      printPDF(fileName, dataListForPDF);
    }
  };

  const checkExport = (value) => {
    if (fileType === value) {
      return true;
    } else {
      return false;
    }
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item.obCode}</td>
          <td>
            {item.operateDate
              ? helper.momentDate(item.operateDate, "th", "short")
              : "-"}
          </td>
          <td>{item.orgName}</td>
          <td>{item.provinceName}</td>
          <td>{item.amphurName}</td>
          <td>{item.tambonName}</td>
          <td>{item.mooName}</td>
          <td>{item.animalName}</td>
          <td>{item.totalAmt}</td>
          <td>{item.farmAmt}</td>
          <td>{item.sickAmtDate}</td>
          <td>{item.deadAmtDate}</td>
          <td>{item.deadAmt}</td>
          <td>{item.sickAmtDateBack}</td>
          <td>{item.sickAmtDateSelect}</td>
          <td>{item.sickAmt}</td>
          <td>{item.recoveryAmt}</td>
          <td>{item.mathSick}</td>
          <td>{item.avgSick}</td>
          <td>{item.deadAmtDateSelect}</td>
          <td>{item.deadAll}</td>
          <td>{item.avgDead}</td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="23" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เลข Outbreak ID"
                    idName="obCode"
                    value={form.obCode}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, obCode: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เลข case ID"
                    idName="caseCode"
                    value={form.caseCode}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, caseCode: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่ปฏิบัติงาน"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.foundDateBegin}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, foundDateBegin: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.foundDateEnd}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, foundDateEnd: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดโรค"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={diseaseSelectValue}
                    optionsList={diseaseList}
                    handleChange={(value) => {
                      setDiseaseSelectValue([value]);
                      setForm({ ...form, diseaseTypeId: value.value });
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขตปศุสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={zoneLivestockSelectValue}
                    optionsList={zoneLivestockList}
                    handleChange={(value) => {
                      setZoneLivestockSelectValue([value]);
                      setForm({ ...form, zoneId: value.value });
                      getProvince(value.value);
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={animalSelectValue}
                    optionsList={animalList}
                    handleChange={(value) => {
                      setAnimalSelectValue([value]);
                      setForm({ ...form, animalTypeId: value.value });
                    }}
                  />
                </Col>
              </Row>

              <div className="mb-2" style={{ fontWeight: "normal" }}>
                เลือกรูปแบบ
              </div>
              <Row>
                <Col lg={1} md={6} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="excel"
                      name="data-export"
                      checked={checkExport("excel")}
                      onChange={() => setFileType("excel")}
                    />{" "}
                    EXCEL
                  </label>
                </Col>

                <Col lg={1} md={6} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="pdf"
                      name="data-export"
                      checked={checkExport("pdf")}
                      onChange={() => setFileType("pdf")}
                    />{" "}
                    PDF
                  </label>
                </Col>

                <Col lg={1} md={6} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="csv"
                      name="data-export"
                      checked={checkExport("csv")}
                      onChange={() => setFileType("csv")}
                    />{" "}
                    CSV
                  </label>
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายงาน C03RPT05-รายงานภาวการณ์ระบาดของโรค (กคร.3 / สคส.1)"
            headRight={buttonExport()}
          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
              id={"data-table"}
              id={"render-data-table"}
              reference={ref}
            >
              {renderTable(listDiseaseControl)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Disease_Control_Report;
