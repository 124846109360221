import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import { useHistory } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTableNodata from "../../../components/DataTableNodata";
import DataTable from "../../../components/DataTable";
import ModalSampleType from "../../../components/Data_Management/Modal.SampleType";

const MySwal = withReactContent(Swal);

function Sample_Type() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);
  const history = useHistory();
  const statusCheck = useSelector((state) => state.statusCheck);
  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/master-data/animal-type", name: "Master Data" },
    { path: "active", name: "ชนิดตัวอย่าง" },
  ];

  const initialForm = {
    sampleTypeId: "",
    statusId: "",

    idEdit: "",
    mode: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
        width: "50px",
      },
      {
        sortBy: "name",
        name: "ชนิดตัวอย่าง",
        sortFlg: true,
      },
      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listSampleType, setListSampleType] = useState([]);

  const [showSampleType, setShowSampleType] = useState(false);

  const [sampleIdSelectValue, setSampleIdSelectValue] = useState([]);
  const [sampleIdList, setSampleIdList] = useState([]);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);
  const [isClearSearch, setIsClearSearch] = useState(false);

  useEffect(() => {
    getSampleType();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getSampleType = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getSampleType(
        form.statusId,
        form.sampleTypeId,
        form.page,
        form.limit,
        "",
        form.sortBy,
        form.sortDir,
        isClearSearch
      );
      const data = await response?.data;
      if (response.status === 200) {
        let sampleArray = [];
        data.content.forEach((element) => {
          sampleArray.push({
            value: element.id,
            label: element.name,
          });
        });

        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListSampleType(data.content);
        setSampleIdList(sampleArray);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusCheck && statusCheck.length > 0) {
      statusCheck.map((item) => {
        if (item.id === idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-4 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const buttonAddSample = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddSampleType()}
          disabled={user?.userGroup.mcreate === "n"}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มชนิดตัวอย่าง
        </button>
      </div>
    );
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };
  const clearSearch = () => {
    setForm(initialForm);
    setSampleIdSelectValue([]);
    setStatusSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const toAddSampleType = () => {
    setForm({ ...form, mode: "add", idEdit: "" });
    setShowSampleType(true);
  };

  const editModalSampleType = (id) => {
    setForm({ ...form, mode: "edit", idEdit: id });
    setShowSampleType(true);
  };

  const delSampleType = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบ `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteSampleType(id);
          if (response.status === 200) {
            MySwal.fire({
              text: `ลบสำเร็จ `,
              icon: "success",
              confirmButtonText: "ตกลง",
            });
            await getSampleType();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const addSaveSampleType = (data) => {
    const dataSampleType = {
      name: data.sample.sampleType_name,
      statusId: data.sample.status,
    };

    MySwal.fire({
      text: `ต้องการบันทึก `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response =
            data.mode === "add"
              ? await API.saveSampleType(dataSampleType)
              : await API.updateSampleType(
                  data.sample.sampleType_id,
                  dataSampleType
                );
          if (response.status === 200) {
            await getSampleType();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTableSampleType = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i}>
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td>{item.name || "-"}</td>
          <td className="text-center">
            {getStatusColor(item.statusId) || "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editModalSampleType(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delSampleType(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={4} />;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ชนิดตัวอย่าง"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={sampleIdSelectValue}
                  optionsList={sampleIdList}
                  handleChange={(value) => {
                    setSampleIdSelectValue(value);
                    setForm({ ...form, sampleTypeId: value.value });
                  }}
                />
              </Col>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="สถานะ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleChange={(value) => {
                    setStatusSelectValue(value);
                    setForm({ ...form, statusId: value.value });
                  }}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="รายการข้อมูลชนิดตัวอย่าง"
            classNameBox="mt-4"
            headRight={buttonAddSample()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTableSampleType(listSampleType)}
            </DataTable>
          </BoxCard>
          {showSampleType && (
            <ModalSampleType
              show={showSampleType}
              close={() => setShowSampleType(false)}
              save={(value) => addSaveSampleType(value)}
              mode={form.mode}
              idEdit={form.idEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Sample_Type;
