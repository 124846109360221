import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import InputMask from '../InputMask';
import { useTranslation } from 'react-i18next';
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";

function ModalContactDetails({ show, close, save, mode, idEdit }) {
    const user = useSelector(state => state.login.result);

    const initialForm = {
        fname: "",
        lname: "",
        phone: "",
        email: "",
        data_contact: "",
        detail_contact: "",
        contact_id: "",

        status: "",
        createDate: "",
        updateDate: "",

        checkShow: false
    };
    const initInvalidMsg = {
        status: "",
    };
    const selectStatusList = [
        { value: 1, label: "รับทราบ" },
        { value: 2, label: "ยังไม่มีการดำเนินการ" },
    ];

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation(["common"]);

    const [form, setForm] = useState(initialForm);
    const [invalid, setInvalid] = useState(initInvalidMsg);
    const [statusSelectValue, setStatusSelectValue] = useState([]);
    const [statusList, setStatusList] = useState(selectStatusList);

    useEffect(async () => {
        if (mode === "add") {
            await setForm(initialForm)
            setStatusSelectValue([])
        }
    }, [mode])

    useEffect(async () => {
        if (mode === "edit" && !form.checkShow) {
            await getNewsContactById(idEdit)
        }
    }, [mode, idEdit, show, form.fname, form.lname, form.phone, form.email, form.data_contac, form.detail_contact, form.status])

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;
        if (form.status === "") {
            addInvalid("status", "กรุณาเลือกสถานะ");
            validated = false;
        }
        return validated;
    };

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setStatusSelectValue([])
    }

    const toConfirm = async () => {
        if (validate()) {
            await save({ contact: form, mode: mode });
            handleClose();
        }
    };

    const getNewsContactById = async (id) => {
        let dataCtc;
        try {
            const response = await API.getNewsContactById(id);
            const data = await response.data;
            if (response.status === 200) {
                dataCtc = data;
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }))

            }
            console.log(error)
        }

        if (dataCtc.statusId) {
            let statusSelect = await statusList.find(item => item.value === dataCtc.statusId);
            setStatusSelectValue(statusSelect);
        }

        setForm({
            ...form,
            fname: dataCtc.fname,
            lname: dataCtc.lname,
            phone: dataCtc.tel,
            email: dataCtc.email,
            contact_id: dataCtc.id,
            data_contact: dataCtc.title,
            detail_contact: dataCtc.detail,
            status: dataCtc.statusId,
            createDate: dataCtc.createDate,
            updateDate: dataCtc.updateDate
        })
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => handleClose()}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>{t("data_contact")}</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div >
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={7} md={12} sm={12}>
                                    <p className="blod">{t("data_contact")}</p>
                                    <div className="ml-5">
                                        <InputText type="text" title={t("first_name")} idName="fname" value={form.fname} star={false}
                                            placeholder="โปรดระบุ" classLabel="normal" disabled={true}
                                            handleChange={(value) => setForm({ ...form, fname: value, checkShow: true })}
                                        />
                                        <InputText type="text" title={t("last_name")} idName="lname" value={form.lname} star={false}
                                            placeholder="โปรดระบุ" classLabel="normal" disabled={true}
                                            handleChange={(value) => setForm({ ...form, lname: value, checkShow: true })}
                                        />
                                        <Row>
                                            <Col lg={6} md={12} sm={12}>
                                                <InputMask
                                                    mask="99-9999-9999" title={t("phone")} idName="phone" value={form.phone} star={false}
                                                    placeholder="โปรดระบุ" classLabel="normal" disabled={true}
                                                    handleChange={(value) => setForm({ ...form, phone: value.replace(/-/g, ""), checkShow: true })}
                                                />
                                            </Col>
                                            <Col lg={6} md={12} sm={12}>
                                                <InputText type="text" title={t("email")} idName="email" value={form.email} star={false}
                                                    placeholder="โปรดระบุ" classLabel="normal" disabled={true}
                                                    handleChange={(value) => setForm({ ...form, email: value, checkShow: true })}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="my-4">
                                        <p className="blod">{t("data_contact2")}</p>
                                        <div className="ml-5">
                                            <InputText type="text" title={t("title_to_contact")} idName="data_contact" value={form.data_contact} star={false}
                                                placeholder="โปรดระบุ" classLabel="normal" disabled={true}
                                                handleChange={(value) => setForm({ ...form, data_contact: value, checkShow: true })}
                                            />
                                            <InputText type="text" title={t("detail_to_contact")} idName="detail_contact" value={form.detail_contact} star={false}
                                                placeholder="โปรดระบุ" classLabel="normal" as="textarea" rows={4} disabled={true}
                                                handleChange={(value) => setForm({ ...form, detail_contact: value, checkShow: true })}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={12} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                        selectValue={statusSelectValue} optionsList={statusList}
                                        handleChange={(value) => {
                                            setStatusSelectValue([value])
                                            setForm({ ...form, status: value.value, checkShow: true })
                                        }}
                                        handleInvalid={() => removeInvalid("status")} invalid={invalid.status}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={3} sm={6} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mr-4">วันที่บันทึกข้อมูล</p>
                                    </div>
                                </Col>
                                <Col lg={10} md={9} sm={6} xs={12}>
                                    <div className="text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mb-0">{form.createDate ? helper.momentDate(form.createDate, "th", "short") : '-'}</p>
                                        <p>{form.createByName ? form.createByName : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={3} sm={6} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                                        <p className="mr-3" >วันที่ปรับปรุงข้อมูล</p>
                                    </div>
                                </Col>
                                <Col lg={10} md={9} sm={6} xs={12}>
                                    <div className="text-gray mb-0 font12 ml-2">
                                        <p className="mb-0">{form.updateDate ? helper.momentDate(form.updateDate, "th", "short") : '-'}</p>
                                        <p>{form.updateByName ? form.updateByName : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                </div>
                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default ModalContactDetails
