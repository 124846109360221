import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { logout } from "../../../redux/actions/logoutAction";

import pdfMake from "pdfmake-thai/build/pdfmake";
import pdfFonts from "pdfmake-thai/build/vfs_fonts";

import { Row, Col, Form } from "react-bootstrap";

import * as helper from "../../../utils/helper";
import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import InputText from "../../../components/InputText";
import DataTable from "../../../components/DataTable";
import InputDatePicker from "../../../components/InputDatePicker";
import DataTableNodata from "../../../components/DataTableNodata";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useMasterData } from "../../../components/hooks/useMasterData";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Access() {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = React.createRef();
  const user = useSelector((state) => state.login.result);

  const pdfExportComponent = React.useRef(null);

  const initialForm = {
    dateBegin: "",
    dateEnd: "",
    zoneId: "",
    provinceId: "",
    amphurId: "",
    search: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        name: "ลำดับ",
        sortFlg: false,
      },
      {
        sortBy: "fullName",
        name: "ชื่อ นามสกุล",
        sortFlg: true,
      },
      {
        sortBy: "email",
        name: "อีเมล์",
        sortFlg: true,
      },
      {
        sortBy: "tel",
        name: "เบอร์โทรศัพท์",
        sortFlg: true,
      },
      {
        sortBy: "positionName",
        name: "ตำแหน่ง",
        sortFlg: true,
      },
      {
        sortBy: "userGroupName",
        name: "กลุ่ม",
        sortFlg: true,
      },
      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },
      {
        sortBy: "createDate",
        name: "วันที่เข้าใช้งานล่าสุด",
        sortFlg: true,
      },
      {
        sortBy: "ip",
        name: "IP Address",
        sortFlg: true,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  pdfMake.fonts = {
    Roboto: {
      normal: "Roboto-Regular.ttf",
      bold: "Roboto-Medium.ttf",
      italics: "Roboto-Italic.ttf",
      bolditalics: "Roboto-MediumItalic.ttf",
    },
    THSarabunNew: {
      normal: "THSarabunNew.ttf",
      bold: "THSarabunNew-Bold.ttf",
      italics: "THSarabunNew-Italic.ttf",
      bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
  };

  const [listAccess, setListAccess] = useState([]);
  const [listAccessReport, setAccessReportList] = useState([]);
  const [isClearSearch, setIsClearSearch] = useState(false);

  // select เขตปศุสัตว์
  const [zoneLivestockList, setZoneLivestockList] = useState([]);
  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState([]);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );

  // select file type to download
  const [fileType, setFileType] = useState("excel");
  const fileName = "F99RPT01-Access" + helper.getDate(new Date());

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/report/access", name: "รายงาน" },
    { path: "active", name: "F99RPT01-การเข้าใช้งาน" },
  ];

  useEffect(() => {
    // getProvince();
    getZoneLivestock();
  }, []);

  useEffect(async () => {
    await getUserAccess();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getUserAccess = async () => {
    try {
      const response = await API.getUserAccess(
        form.dateBegin ? helper.getDate(form.dateBegin) : "",
        form.dateEnd ? helper.getDate(form.dateEnd) : "",
        form.zoneId,
        form.provinceId,
        form.amphurId,
        form.search,
        form.sortBy,
        form.sortDir,
        form.page,
        form.limit
      );
      const data = await response?.data;
      if (response.status === 200) {
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListAccess(data.content);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getUserAccessToExport = async () => {
    try {
      const response = await API.getUserAccess(
        form.dateBegin ? helper.getDate(form.dateBegin) : "",
        form.dateEnd ? helper.getDate(form.dateEnd) : "",
        form.zoneId,
        form.provinceId,
        form.amphurId,
        form.search,
        form.sortBy,
        form.sortDir,
        1,
        form.totalElements
      );
      const data = await response?.data;
      if (response.status === 200) {
        let accessArray = [];
        data.content.forEach((element) => {
          accessArray.push({
            id: element.id,
            fullName: element.fullName,
            email: element.email,
            tel: element.tel,
            positionName: element.positionName,
            userGroupName: element.userGroupName,
            statusName: element.statusName,
            createDate: element.createDate,
            ip: element.ip,
          });
        });
        downloadRecord(accessArray, data.content);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFilter = (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setZoneLivestockSelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          ล้างข้อมูล
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const convertListToExcelTable = (objArray) => {
    let excelTable = [];
    objArray.map((element, index) => {
      excelTable.push({
        ลำดับ: index + 1,
        "ชื่อ นามสกุล": element.fullName,
        อีเมล์: element.email,
        เบอร์โทรศัพท์: element.tel,
        ตำแหน่ง: element.positionName,
        กลุ่ม: element.userGroupName,
        สถานะ: element.statusName,
        วันที่เข้าใช้งานล่าสุด:
          helper.fullDateTh(element.createDate) +
          " " +
          helper.getTimeAll(element.element, "th"),
        "IP Address": element.statusName,
      });
    });
    return excelTable;
  };

  const convertToExcel = (objArray) => {
    const workSheet = XLSX.utils.json_to_sheet(objArray);
    const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    return XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  };

  const convertToCSV = (objArray) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let headCol = "";
    let dataRow = "";
    let key = Object.keys(array[0]);
    key.forEach((item) => {
      if (headCol === "") {
        headCol = item;
      } else {
        if (item != "") {
          headCol += ",";
          headCol += item;
        }
      }
    });
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") {
          line += ",";
        }
        line += array[i][index];
      }
      dataRow += line + "\r\n";
    }
    return headCol + "\r\n" + dataRow;
  };

  const printPDF = (fileName, dataListForPDF) => {
    let headTitle = [];
    if (form.headTitle && form.headTitle.length > 0) {
      form.headTitle.map((item, i) => {
        headTitle.push(item.name);
      });
    }

    let listAccessPdf = [];
    if (dataListForPDF && dataListForPDF.length > 0) {
      dataListForPDF.map((item, i) => {
        listAccessPdf.push([
          i + 1,
          item.fullName,
          item.email,
          item.tel,
          item.positionName,
          item.userGroupName,
          item.statusName,
          helper.fullDateTh(item.createDate) +
            " " +
            helper.getTimeAll(item.createDate, "th"),
          item.ip,
        ]);
      });
    }
    let bodyContent = [headTitle];
    listAccessPdf.map((item, index) => {
      bodyContent.push(listAccessPdf[index]);
    });
    const cellWidhtHeight = [
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
    ];
    let docDefinition = {
      content: [
        {
          style: "tableExample",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            heights: cellWidhtHeight,
            widths: cellWidhtHeight,

            body: bodyContent,
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
        font: "THSarabunNew",
      },
    };
    pdfMake.createPdf(docDefinition).download(fileName + ".pdf");
  };

  const downloadRecord = (dataList, dataListForPDF) => {
    let type = "";
    if (fileType === "excel") {
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = convertToExcel(convertListToExcelTable(dataList));
      const data = new Blob([excelBuffer], { type: type });
      FileSaver.saveAs(data, fileName + ".xlsx");
    }
    if (fileType === "csv") {
      type = "text/csv;charset=utf-8";
      const csv = convertToCSV(convertListToExcelTable(dataList));
      const csvData = new Blob([csv], { type: type });
      FileSaver.saveAs(csvData, fileName + ".csv");
    }
    if (fileType === "pdf") {
      printPDF(fileName, dataListForPDF);
    }
  };

  const buttonExport = () => {
    return (
      <>
        <button
          className="btn btn-report"
          onClick={() => getUserAccessToExport()}
          disabled={user?.userGroup.mexport === "n"}
        >
          <a className="text-white">
            <img
              className="nav-icon"
              src={"/svg/report.svg"}
              alt="export-icon"
            />{" "}
            ออกรายงาน
          </a>
        </button>
      </>
    );
  };

  const getStatusColor = (idStatus) => {
    let nameStatus;
    let colorStatus;
    if (idStatus === -1) {
      colorStatus = "#FF482F";
      nameStatus = "ไม่ใช้งาน";
    } else if (idStatus === 1) {
      colorStatus = "#25B881";
      nameStatus = "ใช้งาน";
    } else if (idStatus === 2) {
      colorStatus = "#25B881";
      nameStatus = "รออนุมัติ";
    } else if (idStatus === -9) {
      colorStatus = "#FF482F";
      nameStatus = "ลบ";
    } else {
      colorStatus = "";
      nameStatus = "-";
    }
    return (
      <div
        className="box-status3 font14 text-center"
        style={{ background: colorStatus }}
      >
        {nameStatus}
      </div>
    );
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, amphurId: value.value });
  };

  const renderTableAccess = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="text-center font14">
          <td className="w-5">{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item?.fullName || "-"}</td>
          <td>{item?.email || "-"}</td>
          <td>{item?.tel || "-"}</td>
          <td>{item?.positionName || "-"}</td>
          <td>{item?.userGroupName || "-"}</td>
          <td>{getStatusColor(item?.statusId || "")}</td>
          <td>
            <p className="mb-0">
              {item.createDate ? helper.fullDateTh(item.createDate) : ""}
            </p>
            <p>
              {item.createDate ? helper.getTimeAll(item.createDate, "th") : ""}
            </p>
          </td>
          <td>{item?.ip || "-"}</td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={9} />;
    }
  };

  const checkExport = (value) => {
    if (fileType === value) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={2} md={4} sm={6} xs={6}>
                <InputDatePicker
                  title="วันที่บันทึกข้อมูล"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateBegin}
                  star={false}
                  handleChange={(value) =>
                    setForm({ ...form, dateBegin: value })
                  }
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateEnd}
                  star={false}
                  handleChange={(value) => setForm({ ...form, dateEnd: value })}
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputText
                  type="text"
                  title="ชื่อผู้ใช้งาน"
                  star={false}
                  value={form.search}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) => setForm({ ...form, search: value })}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={2} md={4} sm={6} xs={6}>
                <InputSelect
                  title="เขต"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={zoneLivestockSelectValue}
                  optionsList={zoneLivestockList}
                  handleChange={(value) => {
                    setZoneLivestockSelectValue(value);
                    setForm({ ...form, zoneId: value.value });
                    getProvince(value.value);
                  }}
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputSelect
                  title="จังหวัด"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={provinceSelectValue}
                  optionsList={provinceList}
                  handleChange={(value) => {
                    selectValueProvince(value);
                  }}
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputSelect
                  title="อำเภอ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={districtSelectValue}
                  optionsList={districtList}
                  handleChange={(value) => selectValueDistrict(value)}
                />
              </Col>

              <Col lg={4} md={9} sm={12} className="w-100">
                <label style={{ fontWeight: "normal" }}>เลือกรูปแบบ</label>
                <div className="d-flex mt-1 ">
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="excel"
                      name="data-export"
                      checked={checkExport("excel")}
                      onChange={() => setFileType("excel")}
                    />{" "}
                    EXCEL
                  </label>
                  <div className="ml-3">
                    <label style={{ fontWeight: "normal" }}>
                      <input
                        type="radio"
                        value="pdf"
                        name="data-export"
                        checked={checkExport("pdf")}
                        onChange={() => setFileType("pdf")}
                      />{" "}
                      PDF
                    </label>
                  </div>

                  <div className="ml-3">
                    <label style={{ fontWeight: "normal" }}>
                      <input
                        type="radio"
                        value="csv"
                        name="data-export"
                        checked={checkExport("csv")}
                        onChange={() => setFileType("csv")}
                      />{" "}
                      CSV
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายงาน F99RPT01-การเข้าใช้งาน"
            headRight={buttonExport()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
              id={"render-data-table"}
              reference={ref}
            >
              {renderTableAccess(listAccess)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Access;
