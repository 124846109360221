import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import DataTable from "./DataTable";
import BoxCard from "./BoxCard";
import DataTableNodata from "./DataTableNodata";
import * as API from "../utils/apis";
import * as helper from "../utils/helper";
import InputMask from "../components/InputMask";
import { loading } from "../redux/actions/loadingAction";
import { logout } from "../redux/actions/logoutAction";
import { useMasterData } from "../components/hooks/useMasterData";

function ModalFarmSearch({ show, close, save, mode, obCode }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);

  const initialForm = {
    chooseFarmObj: "",
    chooseFarmId: "",
    farmId: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    fname: "",
    lname: "",
    phone: "",
    idCard: "",
    framId: "",
    organizati_name: "",
    niti_name: "",

    farmer: "",
    legal_entity: "",
    agency: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: true,
      },
      {
        sortBy: "farmId",
        name: "รหัสฟาร์ม",
        sortFlg: true,
      },
      {
        sortBy: "firstName",
        name: "เจ้าของฟาร์ม",
        sortFlg: true,
      },
      {
        sortBy: "homeNo",
        name: "ที่อยู่",
        sortFlg: true,
      },
      {
        sortBy: "provinceId",
        name: "จังหวัด",
        sortFlg: true,
      },
      {
        sortBy: "updateDate",
        name: "วันที่ปรับปรุงข้อมูล",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const initialFormJson = {
    province: {},
    district: {},
    subDistrict: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const [listFarmRegistration, setListFarmRegistration] = useState([]);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  const [isClearSearch, setIsClearSearch] = useState(false);

  const inintZoneFilterSelectValue = [];
  const [zoneFilterSelectValue, setZoneFilterSelectValue] = useState(inintZoneFilterSelectValue);
  const [listZoneFilter, setListZoneFilter] = useState([]);

  // useMasterData("province", provinceList, setProvinceList);

  const initialized = useState(false)

  useEffect(async () => {
    
    if (!initialized.current) {
      initialized.current = true
      // await getProvince();
      await getMasterJson();
      await getZone();
    }
  }, [user]);

  useEffect(async () => {
    await getFarmRegistration();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getZone = async (id) => {
    try {
      const response = await API.getZone();
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        // let userrrr = user
        if  (user != null) {
          let userZone = user.userView.viewArea.zone_id
          let data2 = [];
          userZone.forEach((element) => {
            data.content.forEach((element2) => {
              if (element == element2.id) {
                data2.push({
                  value: element2.id,
                  label: element2.name,
                });
              }
              
            });
            
          });
          data2 = data2.sort((a,b) => (a.value > b.value) ? 1 : -1);
          setListZoneFilter(data2);
          setZoneFilterSelectValue([data2[0]])
          setForm({ ...form, zone: data2[0].value });
          getProvince(data2[0].value);
        } else {
          setListZoneFilter(villageArray);
          setZoneFilterSelectValue([villageArray[0]])
          setForm({ ...form, zone: villageArray[0].value });
          getProvince(villageArray[0].value);
        }

        

        setIsClearSearch(!isClearSearch);
        
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFarmRegistration = async () => {

    if (form.zone === undefined) {
      return
    }
    var idCard = form.idCard
    if  (idCard == null || idCard == "") {
      idCard = null
    }

    var phone = form.phone
    if  (phone == null || phone == "") {
      phone = null
    }

    const params = await {
      id: form.farmId ? form.farmId : "",
      updateDateBegin: form.recorddate ? helper.getDate(form.recorddate) : "",
      updateDateEnd: form.todate ? helper.getDate(form.todate) : "",
      firstName: form.fname ? form.fname : "",
      lastName: form.lname ? form.lname : "",
      pid: idCard,
      mobile: phone,
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
      mooId: form.villageId ? form.villageId : "",
      statusId: form.status ? form.status : "",

      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",
      zoneId: form.zone ? form.zone : "",
    };

    dispatch(loading(true));
    try {
      const response = await API.getFarmRegistration(params);
      const data = await response.data;

      if (response.status === 200) {
        await setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        await setListFarmRegistration(data.content);
        dispatch(loading(false));
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getMasterJson = async () => {
    const params = await ["province", "amphur", "tambon"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;

      if (response.status === 200) {
        setFormJson({
          ...formJson,
          province: data.province,
          district: data.amphur,
          subDistrict: data.tambon,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getProvince = async (zone) => {
    try {
      const response = await API.getProvince(zone, 1);
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setDistrictList([]);
    setSubDistrictSelectValue([]);
    setSubDistrictList([]);
    setVillageSelectValue([]);
    setVillageList([]);
    setForm({ ...form, provinceId: value.value, zipcode: "" });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setSubDistrictList([]);
    setVillageSelectValue([]);
    setVillageList([]);
    setForm({ ...form, districtId: value.value, zipcode: "" });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value, zipcode: value.zipcode });
    setVillageSelectValue([]);
    setVillageList([]);
    getVillage(value.value);
  };

  const handleClose = () => {
    close();
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setVillageSelectValue([]);
    setSubDistrictSelectValue([]);
  };

  const toSave = async () => {
    if (mode == "formOutbreak") {
      history.push(
        `/passive_surv/disease_statement/save/${obCode}/farm/${form.chooseFarmId}`
      );
    } else if (mode == "formDiseaseInvestStep3") {
      await save(form.chooseFarmObj);
    } else {
      await save(form.chooseFarmId);
    }
    handleClose();
  };
  const toSearch = async () => {
    getFarmRegistration();
  };
  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    // setStatusSelectValue([])
    // setDiseaseFoundSelectValue([])
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const renderTablereportFarm = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>
            <label htmlFor={`farm_id${i}`} className="normal mb-0 cursor-p">
              {item.id}
            </label>
          </td>
          <td>
            <label htmlFor={`farm_id${i}`} className="normal mb-0 cursor-p">
              {item.firstName} {item.lastName}
            </label>
          </td>
          <td>
            <label htmlFor={`farm_id${i}`} className="normal mb-0 cursor-p">
              {item.homeNo === null &&
                item.amphurId === null &&
                item.provinceId === null &&
                item.zipcode === null &&
                "-"}
              {`${item.homeNo} ต.${item.tambonName} อ.${item.amphurName} จ.${item.provinceName}`}
            </label>
          </td>
          <td>
            <label htmlFor={`farm_id${i}`} className="normal mb-0 cursor-p">
              {item.provinceId ? `จ.${item.provinceName}` : ""}
            </label>
          </td>
          <td>
            <label htmlFor={`farm_id${i}`} className="normal mb-0 cursor-p">
              {helper.momentDate(item.updateDate, "th", "short")}
            </label>
          </td>
          <td>
            <div className="d-flex justify-content-center">
              <Form.Check
                key={i}
                className="m-0 p-0"
                type="radio"
                name="farm_id"
                id={`farm_id${i}`}
                value={item.id}
                checked={parseInt(form.chooseFarmId) === parseInt(item.id)}
                onChange={(e) => {
                  setForm({ ...form, chooseFarmId: e.target.value, chooseFarmObj: item });
                }}
              />
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={8} />;
    }
  };

  const getPage = async (isPage) => {
    await setForm({
      id: form.farmId,
      updateDateBegin: "",
      updateDateEnd: "",
      name: form.fname ? form.fname : form.lname ? form.lname : "",
      pid: form.idCard,
      mobile: form.phone,
      provinceId: form.provinceId,
      amphurId: form.districtId,
      tambonId: form.subDistrictId,
      mooId: form.villageId,
      statusId: "",
      sortBy: "id",
      sortDir: "DESC",
      page: isPage,
      limit: form.limit,
    });
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      // await setForm({
      //   headTitle: form.headTitle,
      //   sortBy: form.sortBy,
      //   sortDir: form.sortDir,
      //   page: isChange1,
      //   limit: form.limit,
      // });
      await setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      // await setForm({
      //   headTitle: form.headTitle,
      //   sortBy: form.sortBy,
      //   sortDir: form.sortDir,
      //   page: form.page,
      //   limit: isChange1,
      // });
      await setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type === "filter") {
      // await setForm({
      //   headTitle: form.headTitle,
      //   sortBy: isChange1,
      //   sortDir: isChange2,
      //   page: form.page,
      //   limit: form.limit,
      // });
      await setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header closeButton className="pb-2 underline">
        <Modal.Title>
          <h5>
            <strong>ค้นหาฟาร์ม</strong>
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <BoxCard
          title="ตัวกรอง"
          classLabel=""
          classLabel=""
          headRight={buttonSearch()}
        >
          <Row className="mb-2 pl-2 ">
            <Col lg={3} md={6} sm={12} xs={12}>
              <InputText
                type="text"
                title="รหัสฟาร์ม"
                idName="รหัสฟาร์ม"
                value={form.farmId}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) => setForm({ ...form, farmId: value })}
              />
            </Col>
          </Row>

          <Row className="mb-2 pl-2 ">
            <Col lg={3} md={6} sm={12} xs={12}>
              <InputMask
                mask="9-9999-99999-99-9"
                title="รหัสประจำตัวประชาชน"
                idName="idCard"
                value={form.idCard}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) =>
                  setForm({ ...form, idCard: value.replace(/-/g, "") })
                }
              />
            </Col>
            <Col lg={3} md={6} sm={12} xs={12}>
              <InputText
                type="text"
                title="ชื่อ"
                idName="fname"
                value={form.fname}
                star={false}
                placeholder="ชื่อ"
                classLabel="normal"
                handleChange={(value) => setForm({ ...form, fname: value })}
              />
            </Col>

            <Col lg={3} md={6} sm={12} xs={12}>
              <InputText
                type="text"
                title="นามสกุล"
                idName="lname"
                value={form.lname}
                star={false}
                placeholder="นามสกุล"
                classLabel="normal"
                handleChange={(value) => setForm({ ...form, lname: value })}
              />
            </Col>
            <Col lg={3} md={6} sm={12} xs={12}>
              <InputMask
                mask="99-9999-9999"
                title="โทรศัพท์มือถือ"
                idName="phone"
                value={form.phone}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) =>
                  setForm({ ...form, phone: value.replace(/-/g, "") })
                }
              />
            </Col>
          </Row>
          <Row className="mb-2 pl-2 ">
            <Col lg={3} md={6} sm={12} xs={12}>
              <InputText
                type="text"
                title="ชื่อองค์กร/หน่วยงาน"
                idName="organizati_name"
                value={form.organizati_name}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) =>
                  setForm({ ...form, organizati_name: value })
                }
              />
            </Col>
            <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ปศุสัตว์เขต"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="status"
                    classLabel="normal"
                    selectValue={zoneFilterSelectValue}
                    optionsList={listZoneFilter}
                    handleChange={(value) => {
                      setZoneFilterSelectValue([value]);
                      getProvince(value.value);
                      setForm({ ...form, zone: value.value });
                    }}
                  />
                </Col>
          </Row>

          <Row className="mb-2 pl-2 ">
            <Col lg={3} md={6} sm={12} xs={12}>
              <InputSelect
                title="จังหวัด"
                star={false}
                placeholder="กรุณาเลือก"
                idName="province"
                classLabel="normal"
                selectValue={provinceSelectValue}
                optionsList={provinceList}
                handleChange={(value) => selectValueProvince(value)}
              />
            </Col>

            <Col lg={3} md={6} sm={12} xs={12}>
              <InputSelect
                title="อำเภอ"
                star={false}
                placeholder="กรุณาเลือก"
                idName="title"
                classLabel="normal"
                selectValue={districtSelectValue}
                optionsList={districtList}
                handleChange={(value) => selectValueDistrict(value)}
              />
            </Col>

            <Col lg={3} md={6} sm={12} xs={12}>
              <InputSelect
                title="ตำบล"
                star={false}
                placeholder="กรุณาเลือก"
                idName="subDistrict"
                classLabel="normal"
                selectValue={subDistrictSelectValue}
                optionsList={subDistrictList}
                handleChange={(value) => selectValueSubDistrict(value)}
              />
            </Col>

            <Col lg={3} md={6} sm={12} xs={12}>
              <InputSelect
                title="หมู่ที่"
                star={false}
                placeholder="กรุณาเลือก"
                idName="villageId"
                classLabel="normal"
                selectValue={villageSelectValue}
                optionsList={villageList}
                handleChange={(value) => {
                  setVillageSelectValue([value]);
                  setForm({ ...form, villageId: value.value });
                }}
              />
            </Col>
          </Row>
        </BoxCard>

        <BoxCard classNameBox="mt-3" title="รายการค้นหาฟาร์ม">
          <DataTable
            headColumns={form.headTitle}
            totalPages={form.totalPages}
            page={form.page}
            limit={form.limit}
            sortDir={form.sortDir}
            sortBy={form.sortBy}
            getLimit={(limit) => getFilter("limit", limit)}
            getFilter={(sortBy, sortDir) =>
              getFilter("filter", sortBy, sortDir)
            }
            getPage={(page) => getFilter("page", page)}
            totalItems={form.totalElements}
            numberOfItems={form.numberOfElements}
          >
            {renderTablereportFarm(listFarmRegistration)}
          </DataTable>
        </BoxCard>
        <div className="text-right py-2">
          <button
            className="btn btn-danger px-4 mr-2"
            onClick={() => handleClose()}
          >
            ยกเลิก
          </button>
          <button className="btn btn-success px-4" onClick={() => toSave()}>
            บันทึก
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalFarmSearch;
