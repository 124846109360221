import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as helper from "../../../utils/helper";
import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTable from "../../../components/DataTable";
import DataTableNodata from "../../../components/DataTableNodata";
import InputDatePicker from "../../../components/InputDatePicker";
import ModalDocument from "../../../components/Data_Management/Modal.Document";

const MySwal = withReactContent(Swal);

function Document() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);
  const history = useHistory();
  const statusCheck = useSelector((state) => state.statusCheck);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/web-settings/news", name: "ตั้งค่าเวปไซต์" },
    { path: "active", name: "เอกสารที่เกี่ยวข้อง" },
  ];

  const initialForm = {
    documentId: "",
    documentName: "",
    docCategoryId: "",
    docCategoryName: "",
    orderIndex: "",
    statusId: "",
    dateBegin: "",
    dateEnd: "",

    idEdit: "",
    mode: "",

    search: "",
    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
        width: "50px",
      },
      {
        sortBy: "docCategoryName",
        name: "หมวดหมู่",
        sortFlg: true,
      },
      {
        sortBy: "name",
        name: "หัวข้อ",
        sortFlg: true,
      },
      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },
      {
        sortBy: "createDate",
        name: "วันที่สร้างข้อมูล",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [showDocument, setShowDocument] = useState(false);

  const [docCatSelectValue, setDocCatSelectValue] = useState([]);
  const [docCatList, setDocCatList] = useState([]);

  const [listDocument, setListDocument] = useState([]);

  const [isClearSearch, setIsClearSearch] = useState(false);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  useEffect(() => {
    getDoc();
  }, []);

  useEffect(() => {
    getDocument();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusCheck && statusCheck.length > 0) {
      statusCheck.map((item) => {
        if (item.id === idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-4 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getDoc = async () => {
    try {
      const response = await API.getDoc("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let docCatArray = [];
        data.content.forEach((element) => {
          docCatArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDocCatList(docCatArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDocument = async () => {
    let dateBegin = "";
    let dateEnd = "";
    if (form.dateBegin) {
      dateBegin = helper.getDate(form.dateBegin);
    }
    if (form.dateEnd) {
      dateEnd = helper.getDate(form.dateEnd);
    }
    dispatch(loading(true));
    try {
      const response = await API.getDocument(
        form.documentId,
        form.docCategoryId,
        form.status,
        form.search,
        dateBegin,
        dateEnd,
        form.sortBy,
        form.sortDir,
        form.page,
        form.limit
      );
      const data = await response?.data;
      if (response.status === 200) {
        let documentArray = [];
        data.content.forEach((element) => {
          documentArray.push({
            value: element.id,
            label: element.name,
          });
        });

        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListDocument(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const addSaveDocument = (data) => {
    let fileAdd;
    if (data.document.checkNewPic) {
      fileAdd = {
        name: data.document.fileName,
        data: data.document.file,
      };
    } else {
      fileAdd = {};
    }

    const dataDocument = {
      name: data.document.documentName,
      docCategoryId: data.document.docCategoryId,
      orderIndex: "1",
      statusId: data.document.statusId,
      file: fileAdd,
    };

    MySwal.fire({
      text: `ต้องการบันทึก `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response =
            data.mode === "add"
              ? await API.saveDocument(dataDocument)
              : await API.updateDocument(
                  data.document.documentId,
                  dataDocument
                );
          if (response.status === 200) {
            await getDocument();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const delDocument = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบ `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteDocument(id);
          if (response.status === 200) {
            await getDocument();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const buttonAddDocument = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddDocument()}
          disabled={user?.userGroup.mcreate === "n"}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มเอกสารที่เกี่ยวข้อง
        </button>
      </div>
    );
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const toAddDocument = () => {
    setForm({ ...form, mode: "add", idEdit: "" });
    setShowDocument(true);
  };

  const editModalDocument = (id) => {
    setForm({ ...form, mode: "edit", idEdit: id });
    setShowDocument(true);
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setDocCatSelectValue([]);
    setStatusSelectValue([]);
    setForm(initialForm);
    setIsClearSearch(!isClearSearch);
  };

  const renderTableDocument = (data) => {
    let status = 1;
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item.docCategoryName}</td>
          <td>{item.name}</td>
          <td className="text-center">
            {item.statusId ? getStatusColor(item.statusId) : "-"}
          </td>
          <td className="text-center">
            {item.createDate
              ? helper.momentDate(item.createDate, "th", "short")
              : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editModalDocument(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delDocument(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={6} />;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="หมวดหมู่"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="docCat"
                  classLabel="normal"
                  selectValue={docCatSelectValue}
                  optionsList={docCatList}
                  handleChange={(value) => {
                    setDocCatSelectValue([value]);
                    setForm({
                      ...form,
                      docCategoryId: value.value,
                      docCategoryName: value.label,
                    });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="สถานะ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleChange={(value) => {
                    setStatusSelectValue([value]);
                    setForm({ ...form, status: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="วันที่สร้างเนื้อหา"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateBegin}
                  star={false}
                  handleChange={(value) =>
                    setForm({ ...form, dateBegin: value })
                  }
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateEnd}
                  star={false}
                  handleChange={(value) => setForm({ ...form, dateEnd: value })}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="รายการข้อมูลเอกสาร"
            classNameBox="mt-4"
            headRight={buttonAddDocument()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTableDocument(listDocument)}
            </DataTable>
          </BoxCard>
          {showDocument && (
            <ModalDocument
              show={showDocument}
              close={() => setShowDocument(false)}
              save={(value) => addSaveDocument(value, "modal")}
              mode={form.mode}
              idEdit={form.idEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Document;
