import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { loading } from "../../redux/actions/loadingAction";

import { Row, Col, Form } from "react-bootstrap";

import pdfMake from "pdfmake-thai/build/pdfmake";
import pdfFonts from "pdfmake-thai/build/vfs_fonts";

import BoxCard from "../../components/BoxCard";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "../../components/DataTable";
import { useMasterData } from "../../components/hooks/useMasterData";

import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Disease_Statement_Report() {
  const ref = React.createRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);
  const history = useHistory();
  const { t } = useTranslation(["common", "footer"]);
  const statusDisease = useSelector((state) => state.statusDisease);

  const initialForm = {
    id: "",
    obCode: "",
    caseCode: "",
    farmName: "",
    prefixName: "",
    firstName: "",
    lastName: "",
    mobile: "",
    homeNo: "",
    animalTypeName: "",
    fm02: "",
    fm03: "",
    fm05: "",
    fm06: "",
    provinceId: "",
    amphurId: "",
    tambonId: "",
    mooId: "",
    address: "",
    soi: "",
    orgName: "",
    zoneId: "",
    provinceName: "",
    amphurName: "",
    tambonName: "",
    mooName: "",
    homeNo: "",
    foundDiseaseDate: "",
    diseaseTypeId: "",
    diseaseTypeName: "",
    endDate: "",
    createDate: "",
    createPrefixName: "",
    createFirstName: "",
    createLastName: "",
    sendSample: "",
    sendSample: "",
    receiveSample: "",
    annouceStopDate: "",
    statusId: "",
    statusName: "",
    updateDate: "",

    foundDateBegin: "",
    foundDateEnd: "",
    createDateBegin: "",
    createDateEnd: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        name: "ลำดับ",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "caseCode",
        name: "Case",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "farmName",
        name: "ข้อมูลเกษตรกร / ฟาร์ม",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        name: "สถานที่พบโรค",
        sortFlg: false,
        colSpan: 4,
        childHead: [
          {
            sortBy: "provinceId",
            name: "จังหวัด",
            sortFlg: true,
          },
          {
            sortBy: "amphurId",
            name: "อำเภอ",
            sortFlg: true,
          },
          {
            sortBy: "tambonId",
            name: "ตำบล",
            sortFlg: true,
          },
          {
            sortBy: "address",
            name: "ที่อยู่",
            sortFlg: true,
          },
        ],
      },
      {
        sortBy: "annouceTypeName",
        name: "ชนิดโรค",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "annouceTypeName",
        name: "ชนิดสัตว์",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "annouceTypeName",
        name: "การรายงานโรค(กคร.1/รก.1)",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "annouceTypeName",
        name: "สอบสวนโรค(กคร. 2)",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "annouceTypeName",
        name: "ติดตามผลการระบาด(กคร. 3)",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "annouceTypeName",
        name: "การฉีดวัคซีน(กคร. 5)",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "annouceTypeName",
        name: "การดำเนินมาตรการ(กคร. 6)",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "annouceTypeName",
        name: "การเก็บตัวอย่าง / ผลห้องปฏิบัติการ",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "annouceTypeName",
        name: "สิ้นสุดโรค",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "annouceTypeName",
        name: "สถานะการดำเนินการ",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "annouceTypeName",
        name: "การรายงานโรค(กคร.1/รก.1)",
        sortFlg: true,
        rowSpan: 2,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listDisease, setDisease] = useState([]);

  // select จังหวัด อำเภอ
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  // select เขตปศุสัตว์
  const [zoneLivestockList, setZoneLivestockList] = useState([]);
  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState([]);

  const [fileType, setFileType] = useState("excel");
  const fileName = "C03RPT04-DiseaseStatement_" + helper.getDate(new Date());

  const [isClearSearch, setIsClearSearch] = useState(false);

  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );
  // =================

  //  ชนิดสัตว์

  const [diseaseSelectValue, setDiseaseSelectValue] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);
  // =================

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "title", name: "รายงาน" },
    { path: "active", name: "B99RPT03-การรายงานโรค" },
  ];

  pdfMake.fonts = {
    THSarabunNew: {
      normal: "THSarabunNew.ttf",
      bold: "THSarabunNew-Bold.ttf",
      italics: "THSarabunNew-Italic.ttf",
      bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
  };

  useEffect(() => {
    getDiseaseStatementReport();
    // getProvince();
    getZoneLivestock();
    getDiseaseType();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getDiseaseStatementReport = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getDiseaseStatementReport(
        form.sortBy,
        form.sortDir,
        form.page,
        form.limit,
        form.zoneId,
        "",
        form.provinceId,
        form.amphurId,
        "",
        form.diseaseTypeId,
        form.foundDiseaseDate,
        "",
        "",
        "",
        "",
        form.foundDateBegin ? helper.getDate(form.foundDateBegin) : "",
        form.foundDateEnd ? helper.getDate(form.foundDateEnd) : "",
        form.createDateBegin ? helper.getDate(form.createDateBegin) : "",
        form.createDateEnd ? helper.getDate(form.createDateEnd) : ""
      );
      const data = await response?.data;
      if (response.status == 200) {
        setDisease(data.content);
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseStatementToExport = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getDiseaseStatementReport(
        form.sortBy,
        form.sortDir,
        1,
        form.totalElements
      );
      const data = await response?.data;
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            id: element.id,
            obCode: element.obCode,
            caseCode: element.caseCode,
            farmNamee: element.farmName,
            prefixName: element.prefixName,
            firstName: element.firstName,
            lastName: element.lastName,
            mobile: element.mobile,
            fm02: element.fm02,
            fm03: element.fm03,
            fm05: element.fm05,
            fm06: element.fm06,
            createDate: element.createDate,
            createPrefixName: element.createPrefixName,
            createFirstName: element.createFirstName,
            createLastName: element.createLastName,
            provinceId: element.provinceId,
            amphurId: element.amphurId,
            tambonId: element.tambonId,
            mooId: element.mooId,
            address: element.address,
            soi: element.soi,
            orgName: element.orgName,
            provinceName: element.provinceName,
            amphurName: element.amphurName,
            tambonName: element.tambonName,
            mooName: element.mooName,
            homeNo: element.homeNo,
            diseaseName: element.diseaseName,
            animalTypeName: element.animalTypeName,
            sendSample: element.sendSample,
            receiveSample: element.receiveSampl,
            annouceStopDate: element.annouceStopDate,
            statusId: element.statusId,
            statusName: element.statusName,
            updateDate: element.updateDate,
            foundDiseaseDate: element.foundDiseaseDate,
          });
        });
        downloadRecord(diseaseArray, data.content);
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType("", "", "1", "100", 1);
      const data = await response.data;
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseaseList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setZoneLivestockSelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setDistrictList([]);
    setDiseaseSelectValue([]);
    setFileType("excel");
    setIsClearSearch(!isClearSearch);
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusDisease && statusDisease.length > 0) {
      statusDisease.map((item, index) => {
        if (item.id == idStatus) {
          statusColor = (
            <div
              style={{ background: item.color }}
              className="box-status font14 px-2 text-center"
            >
              <span>{item.status}</span>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getStatusName = (idStatus) => {
    let statusName;
    if (statusDisease && statusDisease.length > 0) {
      statusDisease.map((item, index) => {
        if (item.id == idStatus) {
          statusName = item.status;
        }
      });
    }
    return statusName;
  };

  const buttonSearch = () => {
    return (
      <>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const buttonExport = () => {
    return (
      <>
        <button
          className="btn btn-report"
          onClick={() => getDiseaseStatementToExport()}
          disabled={user?.userGroup.mexport === "n"}
        >
          <a className="text-white">
            <img
              className="nav-icon"
              src={"/svg/report.svg"}
              alt="export-icon"
            />{" "}
            ออกรายงาน
          </a>
        </button>
      </>
    );
  };

  const convertListToExcelTable = (objArray) => {
    let excelTable = [];
    let dataFm01 = "การรายงานโรค(กคร.1/รก.1)";
    let dataFm02 = "สอบสวนโรค(กคร. 2)";
    let dataFm03 = "ติดตามผลการระบาด(กคร. 3)";
    let dataFm05 = "การฉีดวัคซีน(กคร. 5)";
    let dataFm06 = "การดำเนินมาตรการ(กคร. 6)";
    let dataKeep = "การเก็บตัวอย่าง/ ผลห้องปฏิบัติการ";
    let dataStop = "สิ้นสุดโรค";
    let dataStatus = "สถานะการดำเนินการ";
    objArray.map((element, index) => {
      excelTable.push({
        ลำดับ: index + 1,
        case: element.caseCode,
        ข้อมูลเกษตรกร:
          element.farmName +
          element.prefixName +
          element.firstName +
          element.lastName +
          element.mobile,
        จังหวัด: element.provinceName,
        อำเภอ: element.amphurName,
        ตำบล: element.tambonName,
        ที่อยู่: element.homeNo + element.mooName,
        ชนิดโรค: element.diseaseName,
        ชนิดสัตว์: element.animalTypeName,
      });
      excelTable[index][dataFm01] =
        helper.momentDate(element.createDate, "th", "short") +
        " " +
        element.createPrefixName +
        element.createFirstName +
        " " +
        element.createLastName +
        " " +
        element.orgName;
      excelTable[index][dataFm02] = element.fm02
        ? "ดำเนินการแล้ว"
        : "ยังไม่ดำเนินการ";
      excelTable[index][dataFm03] = element.fm03
        ? "ดำเนินการแล้ว"
        : "ยังไม่ดำเนินการ";
      excelTable[index][dataFm05] = element.fm05
        ? "ดำเนินการแล้ว"
        : "ยังไม่ดำเนินการ";
      excelTable[index][dataFm06] = element.fm06
        ? "ดำเนินการแล้ว"
        : "ยังไม่ดำเนินการ";
      excelTable[index][dataKeep] =
        "ส่งผล:" +
        helper.momentDate(element.sendSample, "th", "short") +
        " " +
        "รับผล:" +
        helper.momentDate(element.receiveSample, "th", "short");
      excelTable[index][dataStop] = helper.momentDate(
        element.annouceStopDate,
        "th",
        "short"
      );
      excelTable[index][dataStatus] = getStatusName(element.statusId);
      excelTable[index]["การรายงานโรค กคร.1/รก.1"] =
        element.createPrefixName +
        element.createFirstName +
        " " +
        element.createLastName +
        " " +
        helper.momentDate(element.updateDate, "th", "short") +
        " " +
        helper.getTime(element.updateDate, "th");
    });
    return excelTable;
  };

  const convertToCSV = (objArray) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let headCol = "";
    let dataRow = "";
    let key = Object.keys(array[0]);
    key.forEach((item) => {
      if (headCol === "") {
        headCol = item;
      } else {
        if (item != "") {
          headCol += ",";
          headCol += item;
        }
      }
    });
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") {
          line += ",";
        }
        line += array[i][index];
      }
      dataRow += line + "\r\n";
    }
    return headCol + "\r\n" + dataRow;
  };

  const convertToExcel = (objArray) => {
    const workSheet = XLSX.utils.json_to_sheet(objArray);
    const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    return XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  };

  const printPDF = (fileName, dataListForPDF) => {
    let listDiseasePdf = [];
    if (dataListForPDF && dataListForPDF.length > 0) {
      dataListForPDF.map((item, i) => {
        listDiseasePdf.push([
          i + 1,
          item.caseCode,
          item?.farmName ||
            "-" +
              "\n" +
              item.prefixName +
              item.firstName +
              " " +
              item.lastName +
              "\n" +
              item.mobile,
          item.provinceName,
          item.amphurName,
          item.tambonName,
          item.homeNo + " " + item.mooName,
          item.diseaseName,
          item.animalTypeName,
          item.createDate +
            "\n" +
            item.createPrefixName +
            item.createFirstName +
            " " +
            item.createLastName +
            "\n" +
            "(" +
            item.orgName +
            ")",
          item.fm02 ? "ดำเนินการแล้ว" : "ยังไม่ดำเนินการ",
          item.fm03 ? "ดำเนินการแล้ว" : "ยังไม่ดำเนินการ",
          item.fm05 ? "ดำเนินการแล้ว" : "ยังไม่ดำเนินการ",
          item.fm06 ? "ดำเนินการแล้ว" : "ยังไม่ดำเนินการ",
          "ส่งผล:" +
            helper.momentDate(item.sendSample, "th", "short") +
            "\n" +
            "รับผล:" +
            helper.momentDate(item.receiveSample, "th", "short"),
          helper.momentDate(item.annouceStopDate, "th", "short"),
          getStatusName(item.statusId),
          item.createPrefixName +
            item.createFirstName +
            " " +
            item.createLastName +
            "\n" +
            helper.momentDate(item.updateDate, "th", "short"),
        ]);
      });
    }

    let bodyArray = [
      [
        {
          text: "ลำดับ",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        { text: "Case", style: "tableHeader", alignment: "center", rowSpan: 2 },
        {
          text: "ข้อมูลเกษตรกร / ฟาร์ม",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "สถานที่พบโรค",
          style: "tableHeader",
          alignment: "center",
          colSpan: 4,
        },
        {},
        {},
        {},
        {
          text: "ชนิดโรค",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "ชนิดสัตว์",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "การรายงานโรค(กคร.1/รก.1)",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "สอบสวนโรค(กคร. 2)",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "ติดตามผลการระบาด(กคร. 3)",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "การฉีดวัคซีน(กคร. 5)",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "การดำเนินมาตรการ(กคร. 6)",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "การเก็บตัวอย่าง / ผลห้องปฏิบัติการ",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "สิ้นสุดโรค",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "สถานะการดำเนินการ",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "การรายงานโรค(กคร.1/รก.1)",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
      ],
      [
        {},
        {},
        {},
        { text: "จังหวัด", style: "tableHeader", alignment: "center" },
        { text: "อำเภอ", style: "tableHeader", alignment: "center" },
        { text: "ตำบล", style: "tableHeader", alignment: "center" },
        { text: "ที่อยู่", style: "tableHeader", alignment: "center" },
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
    ];
    listDiseasePdf.map((item, i) => {
      bodyArray.push(listDiseasePdf[i]);
    });

    let docDefinition = {
      pageOrientation: "landscape",
      pageMargins: [5, 5, 5, 5],
      content: [
        {
          style: "tableExample",
          table: {
            widths: [
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
            ],
            headerRows: 1,
            body: bodyArray,
          },
        },
      ],
      defaultStyle: {
        fontSize: 7,
        font: "THSarabunNew",
      },
    };
    pdfMake.createPdf(docDefinition).download(fileName + ".pdf");
  };

  const downloadRecord = (dataList, dataListForPDF) => {
    let type = "";
    if (fileType === "excel") {
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = convertToExcel(convertListToExcelTable(dataList));
      const data = new Blob([excelBuffer], { type: type });
      FileSaver.saveAs(data, fileName + ".xlsx");
    }
    if (fileType === "csv") {
      type = "text/csv;charset=utf-8";
      const csv = convertToCSV(convertListToExcelTable(dataList));
      const csvData = new Blob([csv], { type: type });
      FileSaver.saveAs(csvData, fileName + ".csv");
    }
    if (fileType === "pdf") {
      printPDF(fileName, dataListForPDF);
    }
  };

  const checkExport = (value) => {
    if (fileType === value) {
      return true;
    } else {
      return false;
    }
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td className="text-center">{item.caseCode || "-"}</td>
          <td>
            <h6 className="mb-0">{item.farmName}</h6>
            <h6 className="mb-0">
              {item.prefixName} {item.firstName} {item.lastName}(
              {item.mobile || `-`})
            </h6>
          </td>
          <td className="text-center">{item.provinceName}</td>
          <td className="text-center">{item.amphurName}</td>
          <td className="text-center">{item.tambonName}</td>
          <td className="text-center">
            {item.homeNo} หมู่ที่ {item.mooName}
          </td>
          <td className="text-center disease-wrap">{item.diseaseName}</td>
          <td className="text-center">{item.animalTypeName || `-`}</td>
          <td className="text-center">
            <h6 className="mb-0">
              {helper.momentDate(item.createDate, "th", "short")}
            </h6>
            <h6 className="mb-0">
              {item.createPrefixName} {item.createFirstName}{" "}
              {item.createLastName}
            </h6>
            <h6 className="mb-0">({item.orgName})</h6>
          </td>
          <td className="text-center disease-wrap ">
            <div className="d-flex justify-content-center">
              {item?.fm02 ? (
                <div
                  style={{
                    background: "#25B881",
                    color: "#FFF",
                    borderRadius: "4px",
                    height: "24px",
                    padding: "2px",
                  }}
                  className="box-status font14 px-2 text-center"
                >
                  <span className="mt-1">ดำเนินการแล้ว</span>
                </div>
              ) : (
                <div
                  style={{
                    background: "#FF482F",
                    color: "#FFF",
                    borderRadius: "4px",
                    height: "24px",
                    padding: "2px",
                  }}
                  className="box-status font14 px-2 text-center"
                >
                  <span className="mt-1">ยังไม่ดำเนินการ</span>
                </div>
              )}
            </div>
          </td>
          <td className="text-center disease-wrap">
            <div className="d-flex justify-content-center">
              {item?.fm03 ? (
                <div
                  style={{
                    background: "#25B881",
                    color: "#FFF",
                    borderRadius: "4px",
                    height: "24px",
                    padding: "2px",
                  }}
                  className="box-status font14 px-2 text-center"
                >
                  <span className="mt-1">ดำเนินการแล้ว</span>
                </div>
              ) : (
                <div
                  style={{
                    background: "#FF482F",
                    color: "#FFF",
                    borderRadius: "4px",
                    height: "24px",
                    padding: "2px",
                  }}
                  className="box-status font14 px-2 text-center"
                >
                  <span className="mt-1">ยังไม่ดำเนินการ</span>
                </div>
              )}
            </div>
          </td>
          <td className="text-center disease-wrap">
            <div className="d-flex justify-content-center">
              {item?.fm05 ? (
                <div
                  style={{
                    background: "#25B881",
                    color: "#FFF",
                    borderRadius: "4px",
                    height: "24px",
                    padding: "2px",
                  }}
                  className="box-status font14 px-2 text-center"
                >
                  <span className="mt-1">ดำเนินการแล้ว</span>
                </div>
              ) : (
                <div
                  style={{
                    background: "#FF482F",
                    color: "#FFF",
                    borderRadius: "4px",
                    height: "24px",
                    padding: "2px",
                  }}
                  className="box-status font14 px-2 text-center"
                >
                  <span className="mt-1">ยังไม่ดำเนินการ</span>
                </div>
              )}
            </div>
          </td>
          <td className="text-center disease-wrap">
            <div className="d-flex justify-content-center">
              {item?.fm06 ? (
                <div
                  style={{
                    background: "#25B881",
                    color: "#FFF",
                    borderRadius: "4px",
                    height: "24px",
                    padding: "2px",
                  }}
                  className="box-status font14 px-2 text-center"
                >
                  <span className="mt-1">ดำเนินการแล้ว</span>
                </div>
              ) : (
                <div
                  style={{
                    background: "#FF482F",
                    color: "#FFF",
                    borderRadius: "4px",
                    height: "24px",
                    padding: "2px",
                  }}
                  className="box-status font14 px-2 text-center"
                >
                  <span className="mt-1">ยังไม่ดำเนินการ</span>
                </div>
              )}
            </div>
          </td>
          <td className="disease-wrap">
            {/* <div className=" d-flex justify-content-center flex-column"> */}
            <div
              style={{
                background: "#20639B",
                color: "#FFF",
                borderRadius: "4px",
                height: "24px",
                padding: "2px",
              }}
              className="box-status font14 px-2 text-center"
            >
              <span className="mt-1">ส่งผล</span>
            </div>
            <div>
              <span className="mt-1">
                {helper.momentDate(item.sendSample, "th", "short")}{" "}
              </span>
            </div>

            <div
              style={{
                background: "#20639B",
                color: "#FFF",
                borderRadius: "4px",
                height: "24px",
                padding: "2px",
              }}
              className="box-status font14 px-2 text-center"
            >
              <span className="mt-1">รับผล</span>
            </div>
            <div>
              <span className="mt-1">
                {helper.momentDate(item.receiveSampl, "th", "short")}{" "}
              </span>
            </div>

            {/* </div> */}
            {/* <div className="d-flex justify-content-center">
                           
                        </div> */}
          </td>
          <td className="text-center disease-wrap">
            {helper.momentDate(item.annouceStopDate, "th", "short")}
          </td>
          <td className="text-center disease-wrap">
            <div className="d-flex justify-content-center">
              {item.statusId ? getStatusColor(item.statusId) : "-"}
            </div>
          </td>
          <td className="text-center">
            <h6 className="mb-0">
              {item.createPrefixName} {item.createFirstName}{" "}
              {item.createLastName}
            </h6>
            <h6 className="mb-0">
              {item.updateDate
                ? helper.momentDate(item.updateDate, "th", "short")
                : "-"}{" "}
              {item.updateDate ? helper.getTime(item.updateDate, "th") : "-"}
            </h6>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="18" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่พบโรค"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.foundDateBegin}
                    star={false}
                    handleChange={(value) => {
                      let sDate = new Date(value);
                      setForm({ ...form, foundDateBegin: sDate.getTime() });
                    }}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.foundDateEnd}
                    star={false}
                    handleChange={(value) => {
                      let eDate = new Date(value);
                      setForm({ ...form, foundDateEnd: eDate.getTime() });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดโรค"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={diseaseSelectValue}
                    optionsList={diseaseList}
                    handleChange={(value) => {
                      setDiseaseSelectValue([value]);
                      setForm({ ...form, diseaseTypeId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขตปศุสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={zoneLivestockSelectValue}
                    optionsList={zoneLivestockList}
                    handleChange={(value) => {
                      setZoneLivestockSelectValue([value]);
                      setForm({ ...form, zoneId: value.value });
                      getProvince(value.value);
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => {
                      setDistrictSelectValue(value);
                      setForm({ ...form, amphurId: value.value });
                    }}
                  />
                </Col>
              </Row>

              <div className="mb-2" style={{ fontWeight: "normal" }}>
                เลือกรูปแบบ
              </div>
              <Row>
                <Col lg={1} md={6} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="excel"
                      name="data-export"
                      checked={checkExport("excel")}
                      onChange={() => setFileType("excel")}
                    />{" "}
                    EXCEL
                  </label>
                </Col>

                <Col lg={1} md={6} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="pdf"
                      name="data-export"
                      checked={checkExport("pdf")}
                      onChange={() => setFileType("pdf")}
                    />{" "}
                    PDF
                  </label>
                </Col>

                <Col lg={1} md={6} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="csv"
                      name="data-export"
                      checked={checkExport("csv")}
                      onChange={() => setFileType("csv")}
                    />{" "}
                    CSV
                  </label>
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายงาน C03RPT04-ความชุกตามชนิดสัตว์และพื้น"
            headRight={buttonExport()}
          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
              id={"data-table"}
              id={"render-data-table"}
              reference={ref}
            >
              {renderTable(listDisease)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}
export default Disease_Statement_Report;
