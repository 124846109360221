import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputText from "../../../components/InputText";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTableNodata from "../../../components/DataTableNodata";
import DataTable from "../../../components/DataTable";
import ModalRisk from "../../../components/Data_Management/Modal.Risk";

const MySwal = withReactContent(Swal);

function Risk() {
  const user = useSelector((state) => state.login.result);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation(["common", "footer"]);
  const statusCheck = useSelector((state) => state.statusCheck);
  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/master-data/animal-type", name: "Master Data" },
    { path: "active", name: "เกณฑ์ผลการประเมินความเสี่ยง" },
  ];

  const initialForm = {
    id: "",
    statusId: "",
    diseaseTypeId: "",
    diseaseTypeName: "",
    statusName: "",
    createDate: "",
    updateDate: "",

    idEdit: "",
    mode: "",

    search: "",
    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        name: "ลำดับ",
        width: "50px",
        sortFlg: false,
      },
      {
        sortBy: "diseaseType",
        name: "ชนิดโรค",
        sortFlg: false,
      },
      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listDiseaseType, setListDiseaseType] = useState([]);

  const [showRisk, setShowRisk] = useState(false);

  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);
  const [isClearSearch, setIsClearSearch] = useState(false);

  useEffect(() => {
    getRisk();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getRisk = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getRisk(
        form.diseaseTypeId,
        form.search,
        form.sortBy,
        form.sortDir,
        form.page,
        form.limit
      );
      const data = await response?.data;
      let arrayDiseaseType = [];
      if (response.status === 200) {
        dispatch(loading(false));
        data.content.map((element) => {
          arrayDiseaseType.push({
            id: element.id,
            diseaseTypeName: element.diseaseTypeName,
            statusId: element.statusId,
          });
        });
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListDiseaseType(arrayDiseaseType);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusCheck && statusCheck.length > 0) {
      statusCheck.map((item, index) => {
        if (item.id === idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-4 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const clearSearch = () => {
    setForm(initialForm);
    setIsClearSearch(!isClearSearch);
  };

  const buttonAddRisk = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          disabled={user?.userGroup.mcreate === "n"}
          onClick={() => toAddRisk()}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่ม
        </button>
      </div>
    );
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          {t("clear")}
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const toAddRisk = () => {
    setForm({ ...form, mode: "add", idEdit: "" });
    setShowRisk(true);
  };

  const editModalRisk = (id) => {
    setForm({ ...form, mode: "edit", idEdit: id });
    setShowRisk(true);
  };

  const delRisk = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบ `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteRisk(id);
          if (response.status === 200) {
            MySwal.fire({
              text: `ลบสำเร็จ `,
              icon: "success",
              confirmButtonText: "ตกลง",
            });
            await getRisk();
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const addSaveRisk = (data) => {
    const dataRisk = {
      diseaseTypeId: data.risk.diseaseTypeId,
      adviceFarm: data.risk.adviceFarm,
      adviceBiological: data.risk.adviceBiological,
      risk1: data.risk.risk1.length > 0 ? data.risk.risk1 : 0,
      risk2: data.risk.risk2.length > 0 ? data.risk.risk2 : 0,
      risk3: data.risk.risk3.length > 0 ? data.risk.risk3 : 0,
      risk4: data.risk.risk4.length > 0 ? data.risk.risk4 : 0,
      risk5: data.risk.risk5.length > 0 ? data.risk.risk5 : 0,
      statusId: data.risk.statusId,
    };

    MySwal.fire({
      text: `ต้องการบันทึก `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response =
            data.mode === "add"
              ? await API.saveRisk(dataRisk)
              : await API.updateRisk(data.risk.riskId, dataRisk);
          if (response.status === 200) {
            setShowRisk(false);
            await getRisk();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTableRisk = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i}>
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td>{item.diseaseTypeName}</td>
          <td>{getStatusColor(item.statusId) || "-"}</td>

          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editModalRisk(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delRisk(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={12} />;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={3} md={6} sm={12} xs={12}>
                <InputText
                  type="text"
                  title="ชนิดโรค"
                  idName="diseaseType"
                  value={form.search}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) => {
                    setForm({ ...form, search: value });
                  }}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="รายการเกณฑ์ผลการประเมินความเสี่ยง"
            classNameBox="mt-4"
            headRight={buttonAddRisk()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTableRisk(listDiseaseType)}
            </DataTable>
          </BoxCard>
          {showRisk && (
            <ModalRisk
              show={showRisk}
              close={() => setShowRisk(false)}
              save={(value) => addSaveRisk(value)}
              mode={form.mode}
              idEdit={form.idEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default Risk;
