import React, { useState, useEffect } from 'react';
import { useDispatch, } from 'react-redux';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { login } from '../redux/actions/loginAction';
import { Row, Col, InputGroup, Form } from 'react-bootstrap';
import * as helper from '../utils/helper';
import * as API from "../utils/apis";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { logout } from "../redux/actions/logoutAction";
import { loading } from "../redux/actions/loadingAction";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { LineLogin } from "reactjs-line-login";

const MySwal = withReactContent(Swal)


const Login = () => {
  const initialState = {
    username: "",
    password: ""
  }
  const [form, setForm] = useState(initialState);

  const initInvalidMsg = {
    username: "",
    password: "",
  }
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["home", "footer"]);

  const history = useHistory();
  useEffect(() => {
    checkToken()
  }, []);

  const checkToken = async () => {
    if (helper.getCookie("token")) {
      dispatch(loading(true));
      try {
        const response = await API.getProfile(helper.getCookie("token"));
        const data = await response.data;
        // console.log("response", response);
        if (response.status == 200) {
          dispatch(loading(false));
          if (data.userType?.id != 1) {
            const getToken = helper.getCookie("token").split(" ");
            dispatch(login({ data: {
              user: data,
              token: {
                tokenType: getToken[0],
                accessToken: getToken[1]
              }
            }, history }))
          } else {
            dispatch(logout({ history }))
            MySwal.fire({
              text: "ท่านไม่มีสิทธิ์เข้าถึง ระบบนี้สำหรับเจ้าหน้าที่",
              icon: 'warning',
              confirmButtonText: 'ตกลง'
            })
          }
        }
        
      } catch (error) {
        dispatch(loading(false));
        const mgeError = error?.response?.data.errors[0]
        MySwal.fire({
          text: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
      }
    }
  }

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  }

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  }

  const validate = () => {
    let validated = true;

    // console.log("validate", invalid);

    if (form.username == '') {
      addInvalid('username', "กรุณาระบุบัญชีผู้ใช้งาน");
      validated = false;
    }
    if (!helper.checkEmailFormat(form.username.trim())) {
      addInvalid('username', "กรุณาระบุอีเมลให้ถูกต้อง");
      validated = false;
    }
    if (form.password == '') {
      addInvalid('password', "กรุณากรอกรหัสผ่าน");
      validated = false;
    }

    return validated;
  }

  const toLogin = async (e) => {
    e.preventDefault();
    if (validate()) {
      let dataUser = {
        username: form.username,
        password: form.password
      }
      try {
        const response = await API.login(dataUser);
        const data = await response.data;
        //console.log("response", response);
        if (response.status == 200) {

          if (data.user?.userType.id != 1) {

            dispatch(login({ data, history }))
          } else {
            dispatch(logout({ history }))
            MySwal.fire({
              text: "ท่านไม่มีสิทธิ์เข้าถึง ระบบนี้สำหรับเจ้าหน้าที่",
              icon: 'warning',
              confirmButtonText: 'ตกลง'
            })
          }
          setForm(initialState);
        }
        
      } catch (error) {
        const mgeError = error?.response?.data.errors[0]
        MySwal.fire({
          text: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
      }
    }

  }

  const toWeb = (confirm, type, socialType) => {
    let path = "register"
    if (type === "forgot") {
      path = "forgotpass"
    }
    window.location.replace(process.env.REACT_APP_WEB_URL + `/?${path}=${confirm}&type=${type}&socialType=${socialType}`);
  }

  const checkSocial = async (socialId, profile, socialType) => {
    let response;
    try {
      if (socialType === 'facebook') {
        response = await API.checkFacebook(socialId);
      } else if (socialType === 'google') {
        response = await API.checkGoogle(socialId);
      }
      // console.log("response checkSocial", response);
      const data = await response.data;
      if (response.status == 200) {
        if (socialType == 'facebook') {
          if (data?.data.isFacebook) {
            loginSocial(socialId, socialType)
          } else {
            const mgeError = `ไม่พบบัญชีผู้ใช้ด้วย Facebook นี้ ทำการลงทะเบียนหรือไม่ ?`
            MySwal.fire({
              text: mgeError,
              icon: "warning",
              showCloseButton: true,
              showCancelButton: true,
              cancelButtonText: "ยกเลิก",
              confirmButtonText: "ตกลง",
            }).then(async (result) => {
              if (result.value) {
                toWeb(true, "login", "facebook");
              }
            })
          }
        }

        if (socialType == 'google') {
          if (data?.data.isGoogle) {
            loginSocial(socialId, socialType)
          } else {
            const mgeError = `ไม่พบบัญชีผู้ใช้ด้วย Gmail นี้ ทำการลงทะเบียนหรือไม่ ?`
            MySwal.fire({
              text: mgeError,
              icon: "warning",
              showCloseButton: true,
              showCancelButton: true,
              cancelButtonText: "ยกเลิก",
              confirmButtonText: "ตกลง",
            }).then(async (result) => {
              if (result.value) {
                toWeb(true, "login", "google");
              }
            })
          }
        }
        // console.log("check login", data);
      }
    } catch (error) {
      console.log("error", error);
      const mgeError = error?.response?.data.errors[0]
      MySwal.fire({
        text: mgeError,
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      })
    }
  };

  const loginFacebook = (res = "") => {
    // console.log("RESPONSE", process.env);
  }

  const responseFacebook = async (response) => {
    const { userID } = response;
    // console.log("responseFacebook", response);
    checkSocial(userID, response, "facebook");
  };

  const googleSuccess = async (response) => {
    // console.log("Google success", response);
    const { googleId, profileObj } = response;
    checkSocial(googleId, profileObj, "google");
  };

  const googleFailure = (response) => {
    console.log("Google Failure", response);
  };

  const lineAuthorize = () => {
    // console.log("lineAuthorize",);
    const lineState = process.env.REACT_APP_LINE_STATE ? process.env.REACT_APP_LINE_STATE : helper.getRandomString(40);
    const lineNonce = process.env.REACT_APP_LINE_NONCE;
    const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_LINE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_LINE_REDIRECT_URL}&state=${lineState}&scope=profile%20openid%20email&nonce=${lineNonce}`;
    // console.log("url lineAuthorize", url);
    window.location.href = `${url}`;
  };


  const loginSocial = async (socialId, socialType) => {
    let response;
    const socialData = {
      "socialId": socialId
    }
    try {
      if (socialType === "facebook") {
        response = await API.loginFacebook(socialData);
      }
      const data = await response.data;
      if (response.status == 200) {
        // console.log("200 loginSocial", data);
        if (data.user && data.user.userType && data.user.userType.id != 1) {
          dispatch(login({ data, history }))

        } else {
          dispatch(logout({ history }))
          MySwal.fire({
            text: "ท่านไม่มีสิทธิ์เข้าถึง ระบบนี้สำหรับเจ้าหน้าที่",
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
        }
      }
    } catch (error) {
      console.log("error", error);
      const mgeError = error?.response?.data.errors[0]
      MySwal.fire({
        text: mgeError,
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      })
    }
  }

  return (
    <div className="pt-5">
      <Row className="d-flex align-items-center justify-content-center mt-5">
        <Col lg={7} mb={7} sm={8} className="mt-3">
          <Row >

            <Col lg={6} md={6} sm={12} className="mb-3 p-3">
              <Form onSubmit={toLogin}>
                <div className="color-blueblack mb-3">
                  <h5><strong>Login</strong></h5>
                </div>
                <div className="form-group">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1"><img id="user-img" src={'/svg/user.svg'} alt="user-icon" /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      className={invalid.username ? "is-invalid" : ""}
                      type="text" name="username"
                      value={form.username}
                      onChange={(e) => {
                        setForm({ ...form, username: e.target.value })
                        removeInvalid('username')
                      }}
                    />
                  </InputGroup>
                  <div className="invalid-error">{invalid.username}</div>
                </div>
                <div className="form-group">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon2"><img src={'/svg/password.svg'} alt="password-icon" /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="basic-addon2"
                      className={invalid.password ? "is-invalid" : ""}
                      type="password" name="password"
                      value={form.password}
                      onChange={(e) => {
                        setForm({ ...form, password: e.target.value })
                        removeInvalid('password')
                      }}
                    />
                  </InputGroup>
                  <div className="invalid-error">{invalid.password}</div>
                </div>
                <div className="d-flex mb-3 justify-content-between align-items-center">
                  <button type="submit" className="btn btn-primary" >เข้าสู่ระบบ</button>

                  <div>
                    <a href="#" className="forgotpass color-blueblack mr-2" onClick={() => { toWeb(true, "forgot") }}><small><u>ลืมรหัสผ่าน</u></small></a>

                    <a href="#" className="forgotpass color-blueblack " onClick={() => { toWeb(true, "general") }}><small><u>สมัครสมาชิก</u></small></a>
                  </div>
                </div>
              </Form>
              {/* <div className="mt-4 text-center">
                <small className="color-blueblack"><strong>หรือเข้าสู่ระบบด้วย</strong></small>
              </div>
              <div className="text-center mt-1">
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_ID}
                  autoLoad={false}
                  textButton=" "
                  fields="id,email,name,first_name,last_name,picture "
                  cssClass="bg-white px-0 border-0"
                  icon={<img src={"/svg/facebook.svg"} alt="facebook" className="pointer-link i-login" />}
                  onClick={(res) => loginFacebook(res)}
                  callback={(res) => responseFacebook(res)}
                />

                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_ID}
                  onSuccess={(res) => googleSuccess(res)}
                  onFailure={(res) => googleFailure(res)}
                  cookiePolicy={"single_host_origin"}
                  render={(renderProps) => (
                    <img onClick={renderProps.onClick} src={"/svg/google.svg"} alt="google" className="pointer-link mx-3 i-login" />
                  )}
                />
                <img src={"/svg/line.svg"} alt="line" className="pointer-link i-login" onClick={() => { lineAuthorize() }} />
              </div> */}
            </Col>

            <Col lg={6} md={6} sm={12} className="bg-login d-flex align-items-start flex-column p-3 text-white">
              {/* <img className="w-100 " src={'/svg/login_1.svg'} alt="logo-regis" /> */}
              <div className=" text-left " >
                <h2 className="mt-3 mb-1">ระบบสารสนเทศ </h2>
                <h4>เพื่อการเฝ้าระวังโรคระบาดสัตว์</h4>
              </div>
              <div className="d-flex mt-auto mb-4 align-items-center" >
                <img className="mr-2" src={"/svg/logo.svg"} alt="logo" />
                <div>
                  <p className="font14 mb-0 text-white" >ระบบเฝ้าระวังโรคสัตว์ กรมปศุสัตว์ </p>
                </div>
              </div>
            </Col>

          </Row>
        </Col>
      </Row>
    </div >
  );

}

export default Login;
