import { combineReducers } from "redux";
import login from "./loginReducer";
import logout from "./logoutReducer";
import loading from "./loadingReducer";
import statusDisease from "./statusDiseaseReducer";
import statusCheck from "./statusCheck";
import diseaseReportNotify from "./diseaseReportNotifyReducer";
import diseaseInformNotify from "./diseaseInformNotifyReducer";
import menu from "./menuReducer";
import masterData from "./masterDataReducer";

export default combineReducers({
  login,
  logout,
  loading,
  statusDisease,
  statusCheck,
  diseaseInformNotify,
  diseaseReportNotify,
  menu,
  masterData,
});
