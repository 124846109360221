import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { logout } from "../../redux/actions/logoutAction";
import { useDispatch, useSelector } from "react-redux";

import * as API from "../../utils/apis";
import * as helper from "../../utils/helper";

import * as FileSaver from "file-saver";

import Breadcrumb from "../../components/Breadcrumb";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import DataTable from "../../components/DataTable";
import DataTableNodata from "../../components/DataTableNodata";
import InputDatePicker from "../../components/InputDatePicker";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useMasterData } from "../../components/hooks/useMasterData";
import { useLocation } from "react-router-dom";
import ModalZeroReport from "../../components/Modal.ZeroReport";
const MySwal = withReactContent(Swal);

function ZeroReport() {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.login.result);
    const initialForm = {
        certificateCode: "",
        farmName: "",
        address: "",
        farmerName: "",
        startDateBegin: "",
        startDateEnd: "",
        endDateBegin: "",
        endDateEnd: "",
        gfmFarmerTypeId: "",
        statusId: "",
        provinceId: "",
        amphurId: "",
        tambonId: "",
        farmerType: "",

        limit: 10,
        page: 1,
        totalPages: 1,
        sortDir: "DESC",
        sortBy: "id",
        totalElements: 0,
        numberOfElements: 0,

        headTitle: [
            {
                name: "ลำดับ",
                sortFlg: false,
                width: "50px",
            },
            {
                sortBy: "provinceName",
                name: "สำนักงานปศุสัตว์จังหวัด",
                sortFlg: true,
            },
            {
                sortBy: "zoneName",
                name: "สำนักงานปศุสัตว์เขต",
                sortFlg: true,
            },
            {
                sortBy: "zeroreportDate",
                name: "วัน/เวลา ที่รายงาน",
                sortFlg: true,
            },
            {
                sortBy: "diseases",
                name: "โรค",
                sortFlg: false,
            },
            {
                name: "จัดการ",
                sortFlg: false,
            },
            // {
            //     sortBy: "startDate",
            //     name: " วันที่รับรอง",
            //     sortFlg: true,
            // },
            // {
            //     sortBy: "endDate",
            //     name: "วันที่หมดอายุ",
            //     sortFlg: true,
            // },
            // {
            //     sortBy: "animalTypeName",
            //     name: "ชนิดสัตว์",
            //     sortFlg: true,
            // },
            // {
            //     sortBy: "farmerTypeName",
            //     name: "ประเภทเกษตรกร",
            //     sortFlg: true,
            // },
            // {
            //     sortBy: "statusId",
            //     name: "สถานะ",
            //     sortFlg: true,
            // },
            // {
            //     name: "จัดการ",
            //     sortFlg: false,
            // },
        ],
    };
    const [showModal, setShowModal] = useState(false);
    const [zeroReportList, setZeroReportList] = useState([]);

    // const [farmerTypeSelectValue, setFarmerTypeSelectValue] = useState([]);
    // const [farmerTypeList, setFarmerTypeList] = useState([]);

    // const [farmerCateTypeSelectValue, setFarmerCateTypeSelectValue] = useState([]);

    const farmerCateTypeListInit = [
        { value: 1, label: "รายย่อย" },
        { value: 2, label: "รายเล็ก" },
        { value: 3, label: "รายกลาง" },
        { value: 4, label: "รายใหญ่" },
    ];

    const [farmerCateTypeList, setFarmerCateTypeList] = useState(farmerCateTypeListInit);

    // select จังหวัด ตำบล อำเภอ หมู่บ้าน
    const [provinceSelectValue, setProvinceSelectValue] = useState([]);
    const [provinceList, setProvinceList] = useState([]);

    const inintZoneFilterSelectValue = [];
  const [zoneFilterSelectValue, setZoneFilterSelectValue] = useState(inintZoneFilterSelectValue);
  const [listZoneFilter, setListZoneFilter] = useState([]);

  const initDiseaseSelectValue = [];
  const [diseaseSelectValue, setDiseaseSelectValue] = useState(
    initDiseaseSelectValue
  );
  const selectDiseaseList = [];
  const [diseaseList, setDiseaseList] = useState(selectDiseaseList);

    // const [districtSelectValue, setDistrictSelectValue] = useState([]);
    // const [districtList, setDistrictList] = useState([]);

    // const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
    // const [subDistrictList, setSubDistrictList] = useState([]);

    const [form, setForm] = useState(initialForm);
    const [isClearSearch, setIsClearSearch] = useState(false);

    //useMasterData("province", provinceList, setProvinceList);

    // =================

    const [statusSelectValue, setStatusSelectValue] = useState([]);
    const selectStatusList = [
        { value: 1, label: "รับรอง" },
        { value: 2, label: "ต่ออายุ" },
        { value: 3, label: "หมดอายุ" },
    ];
    const [statusList, setStatusList] = useState(selectStatusList);

    const breadcrumbPath = [
        { path: "/home", name: "หน้าหลัก" },
        { path: "/zero_report/report", name: "รายงาน Zero Report" },
    ];
    const toAddEdit = () => {
        setShowModal(true)
        setForm({ ...form, modeReport: "add" });
        // if (location.pathname.includes("edit")) {
        //   setForm({ ...form, mode: "edit" });
        // } else {
        //   setForm({ ...form, mode: "add" });
        // }
    };
    useEffect(() => {
        // getProvince();
        if (user != null) {
            var userGroupId = user.userGroup.id;
            if(userGroupId == 6) {
                var arrList = user.userView.viewArea.province_id
                setForm({ ...form, province:  arrList[0], zone: user.userView.viewArea.zone_id[0]});
                // setIsClearSearch(!isClearSearch);
            }
        }
        
        getFarmerType();
        getZone()
        getDiseaseType();

    },[user]);

    useEffect(() => {
        if (user != null) {
            getZeroReport();
        }
        
    }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

    // const deleteZeroReport = async (id) => {
    //     try {
    //         const response = await API.delZeroReport(id);
    //         const data = await response?.data;
    //         if (response.status === 200) {
    //           setIsClearSearch(!isClearSearch);
    //         }
    //     } catch (error) {
    //         // if (error.response && error.response.status === 401) {
    //         //     dispatch(logout({ history }));
    //         // }
    //         console.log(error);
    //     }
    //   };

    const getDiseaseType = async (idAnimal) => {
        try {
            
          const response = await API.getDiseaseType(idAnimal,"","1","1000","1","","id","ASC","1");
          const data = await response.data;
          if (response.status === 200) {
            let diseaseTypeArray = [];
            data.content.forEach((element) => {
              diseaseTypeArray.push({
                value: element.id,
                label: element.name,
              });
            });
            setDiseaseList(diseaseTypeArray);
          }
        } catch (error) {
          console.log(error);
        }
      };

    const getZone = async (id) => {
        try {
          const response = await API.getZone();
          const data = await response.data;
          if (response.status == 200) {
            let villageArray = [];
            data.content.forEach((element) => {
              villageArray.push({
                value: element.id,
                label: element.name,
              });
            });
            // let userrrr = user
            if  (user != null) {
              let userZone = user.userView.viewArea.zone_id
              let data2 = [];
              var userGroupId = user.userGroup.id;
              if(userGroupId == 65) {
                data2.push({
                    value: "",
                    label: "ทั้งหมด",
                  });
                }
              
                        
              if  (user.userType.id == 7) {
                data.content.forEach((element2) => {
                    data2.push({
                        value: element2.id,
                        label: element2.name,
                      });
                  });
              } else {
                userZone.forEach((element) => {
                    data.content.forEach((element2) => {
                      if (element == element2.id) {
                        data2.push({
                          value: element2.id,
                          label: element2.name,
                        });
                      }
                      
                    });
                    
                  });
              }
              
              data2 = data2.sort((a,b) => (a.value > b.value) ? 1 : -1);
              setListZoneFilter(data2);
              setZoneFilterSelectValue([data2[0]])
              setForm({ ...form, zone: data2[0].value });
              getProvince(data2[0].value);
            } else {
              setListZoneFilter(villageArray);
              setZoneFilterSelectValue([villageArray[0]])
              setForm({ ...form, zone: villageArray[0].value });
              getProvince(villageArray[0].value);
              
            }
    
            // setIsClearSearch(!isClearSearch);
            
            // setZoneFilterSelectValue([villageArray[0]])
          }
        } catch (error) {
          console.log(error);
        }
      };

    const getZeroReport = async () => {
        try {
            if (form.zone === undefined) {
                return;
            }
            const response = await API.getZeroReport(
                form.zone,
                form.province,
                form.startDateBegin ? helper.getDate(form.startDateBegin) : "",
                form.startDateEnd ? helper.getDate(form.startDateEnd) : "",
                form.disease,
                form.page,
                form.limit,
                form.sortBy,
                form.sortDir
            );
            const data = await response?.data;
            if (response.status === 200) {
                // let ZeroReportArray = [];
                // data.content.forEach((element) => {
                //     ZeroReportArray.push({
                //         value: element.id,
                //         label: element.name,
                //     });
                // });

                setForm({
                    ...form,
                    totalPages: data.totalPages,
                    totalElements: data.totalElements,
                    numberOfElements: data.numberOfElements,
                });
                 setZeroReportList(data.content);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }));
            }
            console.log(error);
        }
    };

    const getFarmerType = async () => {
        try {
            const response = await API.getFarmerType("", 1);
            const data = await response?.data;
            if (response.status == 200) {
                let farmerType = [];
                data.content.forEach((element) => {
                    farmerType.push({
                        //waiting for label
                        value: element.id,
                        label: element.animalTypeName,
                    });
                });
                // setFarmerTypeList(farmerType);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getProvince = async (zone) => {
        try {
            const response = await API.getProvince(zone, 1);
            const data = await response?.data;
            if (response.status == 200) {
                let provincesArray = [];
                data.content.forEach((element) => {
                    if (user != null) {
                        var userGroupId = user.userGroup.id;
                        if(userGroupId == 6) {
                            var arrList = user.userView.viewArea.province_id
                            arrList.forEach((element2) => {
                                if (element.id == element2) {
                                    provincesArray.push({
                                        value: element.id,
                                        label: element.name,
                                    });
                                }
                            });
                        } else {
                            provincesArray.push({
                                value: element.id,
                                label: element.name,
                            });
                        }
                    } else {
                        provincesArray.push({
                            value: element.id,
                            label: element.name,
                        });
                    }
                    
                });
                
                var userGroupId = user.userGroup.id;
                if(userGroupId == 6) {
                    selectValueProvince(provincesArray[0]);
                    setForm({ ...form, province: provincesArray[0].value });
                }
                setProvinceList(provincesArray);
                
            }
        } catch (error) {
            console.log(error);
        }
    };

   

    const getStatusColor = (idStatus) => {
        let colorStatus, nameStatus;
        if (idStatus) {
            if (idStatus == 1) {
                colorStatus = "#3FC071";
                nameStatus = "รับรอง";
            } else if (idStatus == 2) {
                colorStatus = "#3FC071";
                nameStatus = "ต่ออายุ";
            } else if (idStatus == 3) {
                colorStatus = "#FF482F";
                nameStatus = "หมดอายุ";
            } else {
                nameStatus = "-";
            }
            return (
                <div
                    style={{ background: colorStatus }}
                    className="box-status font14 px-2 text-center"
                >
                    <span>{nameStatus}</span>
                </div>
            );
        } else {
            return <div>-</div>;
        }
    };

    const selectValueProvince = (value) => {
        setProvinceSelectValue([{ value: value.value, label: value.label }]);
        // setDistrictSelectValue([]);
        // setSubDistrictSelectValue([]);
        setForm({ ...form, provinceId: value.value, chooseSelect: true });
        // getDistricts(value.value);
    };

    // const selectValueDistrict = (value) => {
    //     setDistrictSelectValue([{ value: value.value, label: value.label }]);
    //     setSubDistrictSelectValue([]);
    //     setForm({ ...form, amphurId: value.value, chooseSelect: true });
    //     getSubDistricts(value.value);
    // };

    // const selectValueSubDistrict = (value) => {
    //     setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    //     setForm({ ...form, tambonId: value.value, chooseSelect: true });
    // };

    const toDownloadExcel = async () => {
        try {
            let params = ""

            if (form.zone) {
                params += "&zone_id=" + form.zone
            }
            if (form.province) {
                params += "&province_id=" + form.province
            }
            if (form.startDateBegin) {
                params += "&start_date=" + helper.getDate(form.startDateBegin)
            }
            if (form.startDateEnd) {
                params += "&end_date=" + helper.getDate(form.startDateEnd)
            }
            if (form.endDateBegin) {
                params += "&end_date1=" + helper.getDate(form.endDateBegin)
            }
            if (form.disease) {
                params += "&disease_type_id=" + form.disease
            }
            
            // console.log(params);
            const url = "https://esmartsur.dld.go.th/jasperserver/rest_v2/reports/reports/esurv/zero_report_excel.xlsx?j_username=jasperadmin&j_password=jasperadmin" + params
            console.log(url);
            window.open(url, "_blank");
            
        } catch (error) {
            console.log(error);
        }
    };

    const toDownload = async () => {
        try {
            let params = ""

            if (form.zone) {
                params += "&zone_id=" + form.zone
            }
            if (form.province) {
                params += "&province_id=" + form.province
            }
            if (form.startDateBegin) {
                params += "&start_date=" + helper.getDate(form.startDateBegin)
            }
            if (form.startDateEnd) {
                params += "&end_date=" + helper.getDate(form.startDateEnd)
            }
            if (form.endDateBegin) {
                params += "&end_date1=" + helper.getDate(form.endDateBegin)
            }
            if (form.disease) {
                params += "&disease_type=" + form.disease
            }
            // console.log(params);
            const url = "https://esmartsur.dld.go.th/jasperserver/rest_v2/reports/reports/esurv/zero_report.pdf?j_username=jasperadmin&j_password=jasperadmin" + params
            console.log(url);
            window.open(url, "_blank");
            
        } catch (error) {
            console.log(error);
        }
    };

    const toUpload = async (file) => {
        try {
            let formData = new FormData();
            formData.append("file", file);

            const response = await API.importGfmTemplate(formData);
            if (response.status == 200) {
                MySwal.fire({
                    icon: "success",
                    type: "success",
                    confirmButtonText: "ตกลง",
                    text: "นำเข้าฟอร์มสำเร็จ",
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

  
    const buttonAdd = () => {
        return (
            <>
                <button
                    className="btn btn-info normal mr-2 fw-400 cursor-p"
                    onClick={()=>toAddEdit()}
                    // onClick={() => history.push("/zero_report/save-data")}
                    disabled={user?.userGroup.mcreate === "n"}
                >
                    <i className="fas fa-plus mr-2"></i>
                    เพิ่มข้อมูล
                </button>
            </>
        );
    };
 
    const toSearch = () => {
        setForm({ ...form, page: 1 });
        setIsClearSearch(!isClearSearch);
    };

    const clearSearch = () => {
        // setForm(initialForm);
        // setFarmerCateTypeSelectValue([])
        // setStatusSelectValue([]);
        // setFarmerTypeSelectValue([]);
        // setProvinceSelectValue([]);
        // setDistrictSelectValue([]);
        // setSubDistrictList([]);
        // setDistrictList([]);
        // setSubDistrictSelectValue([]);
        
        if (user != null) {
            var userGroupId = user.userGroup.id;
            if(userGroupId != 6) {
                setProvinceSelectValue([]);
                setForm({...form, province: ""})
            } 
        } else {
            setProvinceSelectValue([]);
            // setForm({...form, province: null})
        }
 
        // setForm({...form, province: })
        
        
        // setZoneFilterSelectValue([]);
        setDiseaseSelectValue([]);
        setIsClearSearch(!isClearSearch);
    };

    const buttonSearch = () => {
        return (
            <div className="mr-2">
                <Row className="no-gutters">
                    <Col lg={4} md={6} sm={12} xs={12}>
                    <button
                    className="btn text-white mr-1 fw-400 cursor-p" style={{ "background": "#20639B" }}
                    onClick={() => {
                        toDownloadExcel();
                    }}
                    // disabled={user?.userGroup.mexport === "n"}
                >
                    <img
                        className="icon-btn mr-2 mb-1"
                        src="/svg/download.svg"
                        alt="menu-icon"
                    />
                    ดาวน์โหลด Excel
                </button></Col>
                    <span>
                    <button
                    className="btn  text-white mr-1 fw-400 cursor-p" style={{ "background": "#20639B" }}
                    onClick={() => {
                        toDownload();
                    }}
                    // disabled={user?.userGroup.mexport === "n"}
                >
                    <img
                        className="icon-btn mr-2 mb-1"
                        src="/svg/download.svg"
                        alt="menu-icon"
                    />
                    ดาวน์โหลด Zero Report
                </button>
                <br />
                <label style={{color:'#FF5733'}}>กรุณาระบุวันที่รายงานและชนิดโรค</label>
                    </span>
                    <Col lg={2} md={6} sm={12} xs={12} >
                    <button className="btn btn-danger" onClick={() => clearSearch()}>
                    ล้างข้อมูล
                </button>
                </Col>
                <Col lg={1} md={6} sm={12} xs={12}>
                    <button className="btn btn-success ml-1 mr-1" onClick={() => toSearch()}>
                    ค้นหา
                </button>
                </Col>
                </Row>
                
                
                
                
                
                
            </div>
        );
    };

    const getFilter = (type, isChange1, isChange2) => {
        if (type === "page") {
            setForm({
                ...form,
                page: isChange1,
            });
        } else if (type === "limit") {
            setForm({
                ...form,
                limit: isChange1,
                page: 1,
            });
        } else {
            setForm({
                ...form,
                sortBy: isChange1,
                sortDir: isChange2,
                page: 1,
            });
        }
    };

    const editGFM = (id) => {
        setShowModal(true)
        setForm({ ...form, modeReport: "edit", zeroReportId: id });
    };

    const printPdf = (id) => {
        window.open('https://esmartsur.dld.go.th/jasperserver/rest_v2/reports/reports/esurv/zero_report_doc.pdf?j_username=jasperadmin&j_password=jasperadmin&zero_report_id=' + id, '_blank', 'noreferrer');
    }

    const delGFM = async (id) => {
        MySwal.fire({
            text: `ยืนยันที่จะลบข้อมูล`,
            icon: "question",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: "ยกเลิก",
            confirmButtonText: "ตกลง",
        }).then(async (result) => {
            if (result.value) {
                try {
                    const response = await API.deleteZeroReport(id);
                    if (response.status === 200) {
                       setIsClearSearch(!isClearSearch);
                    }
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        dispatch(logout({ history }));
                    } else {
                        MySwal.fire({
                            text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
                            icon: "warning",
                            confirmButtonText: "ตกลง",
                        });
                    }
                    console.log("error", error);
                }
            }
        });
    };
    const alertDate = (certificateCode) => {
        MySwal.fire({
            html: (
                <>
                    <img src="/svg/warning.svg" width="70" height="70"></img>
                    <br /> <br />
                    ใบรับรองหมายเลข <b>{certificateCode}</b> <br />
                    จะหมดอายุในอีก <b className="text-danger">60</b> วัน
                </>
            ),
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: "ยกเลิก",
            confirmButtonText: "ตกลง",
        }).then(async (result) => {
            history.push("/gfm");
        });
    };

    const condExpireDate = (endDate, certificateCode) => {
        let day = 1000 * 3600 * 24 * 60;
        let date = Date.now();
        if (endDate - date <= day) {
            return (
                <img
                    className="icon-btn ml-2 cursor-p"
                    src="/svg/warning.svg"
                    onClick={() => alertDate(certificateCode)}
                />
            );
        }
    };

    

    const renderTableZeroReport = (data) => {
        if (data.length > 0) {
            return data.map((item, i) => (
                <tr key={i} className="text-center">
                    <td>{form.limit * (form.page - 1) + (i + 1)}</td>
                    <td>{item?.provinceName || "-"}</td>
                    <td>{item?.zoneName || "-"}</td>
                    <td>{helper.momentDate(item.zeroreportDate, "th", "short") || "-"}</td>
                    <td>{item?.diseases || "-"}</td>
                    <td>
                        <div className="d-flex justify-content-center ">
                            <button
                                className="btn btn-add mr-2 "
                                onClick={() => editGFM(item.id)}
                                disabled={
                                    user?.userGroup.mupdate === "n" &&
                                    user?.userGroup.mview === "n"
                                }
                            >
                                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
                            </button>
                            <button
                                className="btn btn-del mr-2 "
                                onClick={() => delGFM(item.id)}
                                disabled={user?.userGroup.mdelete === "n"}
                            >
                                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
                            </button>

                            <button
                                className="btn btn-report mr-2 "
                                onClick={() => printPdf(item.id)}
                                disabled={
                                    user?.userGroup.mupdate === "n" &&
                                    user?.userGroup.mview === "n"
                                }
                            >
                                <img className="icon-btn " src="/svg/report.svg" alt="export-icon" />
                            </button>
                        </div>
                    </td>
                </tr>
            ));
        } else {
            return <DataTableNodata colSpan={11} />;
        }
    };
 
    return (
        <div className=" ">
            <div className="pl-4 bg-white ml30 mr-auto p-2">
                <Breadcrumb data={breadcrumbPath} />
            </div>

            <div className="bg-gray-light">
                <div className="px-4 pt-3 container-fixed-footer">
                    <BoxCard
                        classNameBox=""
                        title="ตัวกรอง"
                        headRight={buttonSearch()}
                    >
                        {/* <Row>
                            <Col lg={4} md={4} sm={6} xs={6}>
                                <InputText
                                    title="ชื่อฟาร์ม"
                                    star={false}
                                    placeholder="กรุณาระบุ"
                                    classLabel="normal"
                                    value={form.farmName}
                                    handleChange={(value) =>
                                        setForm({ ...form, farmName: value })
                                    }
                                />
                            </Col>

                            <Col lg={4} md={4} sm={6} xs={6}>
                                <InputText
                                    title="ชื่อเจ้าของฟาร์ม"
                                    star={false}
                                    placeholder="กรุณาระบุ"
                                    classLabel="normal"
                                    value={form.farmerName}
                                    handleChange={(value) =>
                                        setForm({ ...form, farmerName: value })
                                    }
                                />
                            </Col>

                            <Col lg={2} md={4} sm={6} xs={6}>
                                <InputText
                                    title="เลขที่ใบรับรอง"
                                    star={false}
                                    placeholder="กรุณาระบุ"
                                    classLabel="normal"
                                    value={form.certificateCode}
                                    handleChange={(value) =>
                                        setForm({ ...form, certificateCode: value })
                                    }
                                    maxLength={13}
                                />
                            </Col>
                        </Row> */}

                        <Row>
                            <Col >
                                <InputSelect
                                    title="สำนักงานเขตปศุสัตว์"
                                    star={false}
                                    placeholder="กรุณาเลือก"
                                    // idName="provinceId"
                                    classLabel="normal"
                                selectValue={zoneFilterSelectValue}
                                optionsList={listZoneFilter}
                                handleChange={(value) => {
                                    setZoneFilterSelectValue([value]);
                                    setProvinceSelectValue([]);
                      getProvince(value.value);
                      setForm({ ...form, zone: value.value });
                                }
                                }
                                />
                            </Col>
                            <Col >
                                <InputSelect
                                    title="จังหวัด"
                                    star={false}
                                    placeholder="กรุณาเลือก"
                                    idName="provinceId"
                                    classLabel="normal"
                                    selectValue={provinceSelectValue}
                                    optionsList={provinceList}
                                    handleChange={(value) => {
                                        selectValueProvince(value);
                                        setForm({ ...form, province: value.value });
                                    }}
                                />
                            </Col>
                            <Col >
                                <InputDatePicker
                                    title="วันที่รายงาน"
                                    classLabel="normal"
                                    placeholder="วัน/เดือน/ปี"
                                    star={false}
                                    value={form.startDateBegin}
                                    handleChange={(value) =>
                                        setForm({ ...form, startDateBegin: value })
                                    }
                                />
                            </Col>

                            <Col >
                                <InputDatePicker
                                    title="ถึงวัน"
                                    classLabel="normal"
                                    placeholder="วัน/เดือน/ปี"
                                    star={false}
                                    value={form.startDateEnd}
                                    handleChange={(value) =>
                                        setForm({ ...form, startDateEnd: value })
                                    }
                                />
                            </Col>

                            <Col >
                                <InputSelect
                                    title="ชนิดโรค"
                                    star={false}
                                    placeholder="กรุณาเลือก"
                                    // idName="statusId"
                                    classLabel="normal"
                                selectValue={diseaseSelectValue}
                                optionsList={diseaseList}
                                handleChange={(value) => {
                                    // setForm({ ...form, disea: value.value });
                                    setDiseaseSelectValue(value);
                                    setForm({ ...form, disease: value.value });
                                }}
                                />
                            </Col>

                            {/* <Col lg={2} md={4} sm={6} xs={6}>
                                <InputDatePicker
                                    title="วันที่ใบรับรองหมดอายุ"
                                    classLabel="normal"
                                    placeholder="วัน/เดือน/ปี"
                                    star={false}
                                    value={form.endDateBegin}
                                    handleChange={(value) =>
                                        setForm({ ...form, endDateBegin: value })
                                    }
                                />
                            </Col> */}

                            {/* <Col lg={2} md={4} sm={6} xs={6}>
                                <InputDatePicker
                                    title="ถึงวันที่"
                                    classLabel="normal"
                                    placeholder="วัน/เดือน/ปี"
                                    star={false}
                                    value={form.endDateEnd}
                                    handleChange={(value) =>
                                        setForm({ ...form, endDateEnd: value })
                                    }
                                />
                            </Col> */}
                        </Row>
                        {/* <Row>
                            <Col lg={9} md={9} sm={6} className="w-100">
                                <label className="normal mb-0">เลือกรูปแบบ</label>
                                <div className="d-flex ">
                                    <div>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            name="typeRaising"
                                            id="isRaising"
                                            value={1}
                                            // disabled={form.typeFarm == 2}
                                            // checked={form.typeRaising == 1}
                                            label="Excel"
                                            onChange={(e) => {
                                                // setForm({
                                                //     ...form,
                                                //     typeRaising: e.target.value,
                                                // });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            name="typeRaising"
                                            id="notRaising"
                                            value={2}
                                            // disabled={form.typeFarm == 2}
                                            // checked={form.typeRaising == 2}
                                            label="PDF"
                                            onChange={(e) => {
                                                // setForm({
                                                //     ...form,
                                                //     typeRaising: e.target.value,
                                                // });
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col lg={2} md={4} sm={6} xs={6}>
                                <InputSelect
                                    title="ชนิดสัตว์"
                                    star={false}
                                    placeholder="กรุณาเลือก"
                                    idName="gfmFarmerTypeId"
                                    classLabel="normal"
                                    selectValue={farmerTypeSelectValue}
                                    optionsList={farmerTypeList}
                                    handleChange={(value) => {
                                        setFarmerTypeSelectValue([value]);
                                        setForm({ ...form, gfmFarmerTypeId: value.value });
                                    }}
                                />
                            </Col>
                            <Col lg={2} md={4} sm={6} xs={6}>
                                <InputSelect
                                    title="ประเภทเกษตรกร"
                                    star={false}
                                    placeholder="กรุณาเลือก"
                                    idName="gfmFarmerCateTypeId"
                                    classLabel="normal"
                                    selectValue={farmerCateTypeSelectValue}
                                    optionsList={farmerCateTypeList}
                                    handleChange={(value) => {
                                        setFarmerCateTypeSelectValue([value]);
                                        setForm({ ...form, farmerType: value.value });
                                    }}
                                />
                            </Col>
                            <Col lg={2} md={4} sm={6} xs={6}>
                                <InputSelect
                                    title="จังหวัด"
                                    star={false}
                                    placeholder="กรุณาเลือก"
                                    idName="provinceId"
                                    classLabel="normal"
                                    selectValue={provinceSelectValue}
                                    optionsList={provinceList}
                                    handleChange={(value) => selectValueProvince(value)}
                                />
                            </Col>

                            <Col lg={2} md={4} sm={6} xs={6}>
                                <InputSelect
                                    title="อำเภอ"
                                    star={false}
                                    placeholder="กรุณาเลือก"
                                    idName="districtId"
                                    classLabel="normal"
                                    selectValue={districtSelectValue}
                                    optionsList={districtList}
                                    handleChange={(value) => selectValueDistrict(value)}
                                />
                            </Col>

                            <Col lg={2} md={4} sm={6} xs={6}>
                                <InputSelect
                                    title="ตำบล"
                                    star={false}
                                    placeholder="กรุณาเลือก"
                                    idName="subDistrictId"
                                    classLabel="normal"
                                    selectValue={subDistrictSelectValue}
                                    optionsList={subDistrictList}
                                    handleChange={(value) => selectValueSubDistrict(value)}
                                />
                            </Col>
                        </Row> */}
                    </BoxCard>

                    <BoxCard
                        title="รายการ Zero Report (การเฝ้าระวังโรคทางอาการ ตามนิยาม)"
                        classNameBox="mt-4"
                        headRight={buttonAdd()}
                    >
                        <DataTable
                            headColumns={form.headTitle}
                            totalPages={form.totalPages}
                            page={form.page}
                            limit={form.limit}
                            sortDir={form.sortDir}
                            sortBy={form.sortBy}
                            getLimit={(limit) => getFilter("limit", limit)}
                            getFilter={(sortBy, sortDir) =>
                                getFilter("filter", sortBy, sortDir)
                            }
                            getPage={(page) => getFilter("page", page)}
                            totalItems={form.totalElements}
                            numberOfItems={form.numberOfElements}
                            isClear={isClearSearch}
                        >
                            {renderTableZeroReport(zeroReportList)}
                        </DataTable>
                    </BoxCard>
                </div>
                {showModal && (
          <ModalZeroReport
            show={showModal}
            modeMap={form.modeReport}
            zeroReportId= {form.zeroReportId}
            diseaseLists = {diseaseList}
            zoneLists={listZoneFilter}
            user={user}
            close={() => setShowModal(false)}
            save={(value) =>
                setIsClearSearch(!isClearSearch)
            }
          />
        )}
            </div>
        </div>
    );
}

export default ZeroReport;
