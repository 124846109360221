import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import { useTranslation } from "react-i18next";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";

import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import DataTableNodata from "../../components/DataTableNodata";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import Breadcrumb from "../../components/Breadcrumb";
import ModalOrganizes from "../../components/Modal.Organizes";
import DataTable from "../../components/DataTable";
import {
  useMasterData,
  useOptionsListYears,
  useCurrentYear,
} from "../../components/hooks/useMasterData";
import ButtonReportLink from "../../components/ButtonReportLink";

const MySwal = withReactContent(Swal);

function Disease_Investigation() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "footer"]);
  const user = useSelector((state) => state.login.result);
  const search = useLocation().search;
  const obCode = new URLSearchParams(search).get("obCode");
  const initialForm = {
    orgId: "",
    orgName: "",
    outbreakID: obCode ? obCode : "",
    case: "",
    name: "",
    dateF: "",
    to_dateF: "",
    dateR: "",
    to_dateR: "",
    zoneId: "",
    status: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    diseaseTypeId: "",
    foundDiseaseTypeId: "",
    animalId: "",
    organizes: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
      },
      {
        sortBy: "caseCode",
        name: "Case",
        sortFlg: true,
      },
      {
        sortBy: "item",
        name: "ครั้งที่",
        sortFlg: true,
      },
      {
        sortBy: "q11",
        name: "ชนิดสัตว์ป่วยตัวแรก",
        sortFlg: true,
      },
      {
        sortBy: "q11Date",
        name: "วันที่บันทึกข้อมูลการสอบสวนโรค",
        sortFlg: true,
      },
      {
        sortBy: "updateDate",
        name: "ปรับปรุงข้อมูลล่าสุด",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const initShowOrgModal = false;
  const [showOrgModal, setShowOrgModal] = useState(initShowOrgModal);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  // =================

  // select  หน่วยงานที่แจ้งโรค สถานะการดำเนินการ เขตปศุสัตว์ ชื่อโรคที่สงสัย ชื่อโรคที่พบ ชนิดสัตว์

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const [StatusList, setStatusList] = useState([]);

  const [listDiseaseInvestigation, setListDiseaseInvestigation] = useState([]);

  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState([]);
  const [zoneLivestockList, setZoneLivestockList] = useState([]);

  const [diseaseSuspectedSelectValue, setDiseaseSuspectedSelectValue] =
    useState([]);
  const [diseaseSuspectedList, setDiseaseSuspectedList] = useState([]);

  const [diseaseFoundSelectValue, setDiseaseFoundSelectValue] = useState([]);
  const [diseaseFoundList, setDiseaseFoundList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);
  // =================
  const [isClearSearch, setIsClearSearch] = useState(false);

  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );

  const optionsListYear = useOptionsListYears();
  const currentYearValue = useCurrentYear();
  const [yearFilterList, setYearFilterList] = useState(currentYearValue);

  // ================

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "title", name: "กคร.2" },
    { path: "active", name: "สอบสวนทางระบาดวิทยาของการเกิดโรคระบาดสัตว์" },
  ];

  useEffect(() => {
    // getProvince();
    getAnimal();
    getStatus();
    getZoneLivestock();
    getDiseaseType();
  }, []);

  useEffect(() => {
    getDiseaseInvestigation();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getDiseaseInvestigation = async () => {
    const params = {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",

      orgId: form.orgId ? form.orgId : "",
      obCode: form.outbreakID ? form.outbreakID : "",
      caseCode: form.case ? form.case : "",
      owner: form.name ? form.name : "",
      foundDateBegin: form.dateF ? helper.getDate(form.dateF) : "",
      foundDateEnd: form.to_dateF ? helper.getDate(form.to_dateF) : "",
      zoneId: form.zoneId ? form.zoneId : "",
      statusId: form.status ? form.status : "",
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
      diseaseTypeId: form.diseaseTypeId ? form.diseaseTypeId : "",
      foundDiseaseTypeId: form.foundDiseaseTypeId
        ? form.foundDiseaseTypeId
        : "",
      animalTypeId: form.animalId ? form.animalId : "",
      onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
    };

    try {
      const response = await API.getDiseaseInvestigation(params);

      const data = await response.data;
      // console.log("params---getDiseaseInvestigation--🐳🐳", params, response);

      if (response.status === 200) {
        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListDiseaseInvestigation(data.content);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType("", "", "1", "100", 1);
      const data = await response.data;
      if (response.status === 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseaseFoundList(diseaseArray);
        setDiseaseSuspectedList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = async () => {
    try {
      const response = await API.getStatus("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let statusArray = [];
        data.content.forEach((element) => {
          statusArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setStatusList(statusArray);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const selectValueProvince = (value) => {
    setProvinceSelectValue([value]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([value]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, districtId: value.value });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([value]);
    setForm({ ...form, subDistrictId: value.value });
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };
  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setSubDistrictList([]);
    setDistrictList([]);
    setAnimalSelectValue([]);
    setDiseaseFoundSelectValue([]);
    setDiseaseSuspectedSelectValue([]);
    setZoneLivestockSelectValue([]);
    setStatusSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <ButtonReportLink path={"disease_investigation"} linkSize={8} addIndex params={
          {
            orgId: form.orgId ? form.orgId : "",
            obCode: form.outbreakID ? form.outbreakID : "",
            caseCode: form.case ? form.case : "",
            owner: form.name ? form.name : "",
            foundDateBegin: form.dateF ? helper.getDate(form.dateF) : "",
            foundDateEnd: form.to_dateF ? helper.getDate(form.to_dateF) : "",
            zoneId: form.zoneId ? form.zoneId : "",
            statusId: form.status ? form.status : "",
            provinceId: form.provinceId ? form.provinceId : "",
            amphurId: form.districtId ? form.districtId : "",
            tambonId: form.subDistrictId ? form.subDistrictId : "",
            diseaseTypeId: form.diseaseTypeId ? form.diseaseTypeId : "",
            foundDiseaseTypeId: form.foundDiseaseTypeId
              ? form.foundDiseaseTypeId
              : "",
            animalTypeId: form.animalId ? form.animalId : "",
            onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
          }
        }/>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const setOrganizes = (data) => {
    setForm({ ...form, orgId: data.value, orgName: data.label });
  };

  const viewDetails = (id) => {
    history.push(`/passive_surv/disease_investigation/edit/${id}`);
  };

  const viewDuplicate = (id) => {
    history.push(`/passive_surv/disease_investigation/duplicate/${id}`);
  };

  const deleteDisease = (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบการสอบสวนโรคนี้`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteDiseaseInvestigation(id);
          if (response.status === 200) {
            await getDiseaseInvestigation();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const buttonAdd = () => {
    return (
      <button
        className="btn btn-info "
        disabled={user?.userGroup.mcreate === "n"}
        onClick={() => history.push(`/passive_surv/disease_investigation/save`)}
      >
        <div className="text-white">
          <i className="fas fa-plus mr-1 "></i>เพิ่มรายการสอบสวนโรค
        </div>
      </button>
    );
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item.caseCode || "-"}</td>
          <td>{item.item}</td>
          <td>{item.q11}</td>
          <td>{helper.momentDate(item.q11Date, "th", "short")}</td>
          <td>
            {item.updateName} <br />
            <span className="font12">
              {helper.momentDate(item.updateDate, "th", "short")}
            </span>
          </td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>

              <button
                className="btn btn-del mr-2 "
                onClick={() => deleteDisease(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>

              <button
                className="btn btn-copy mr-2 "
                onClick={() => viewDuplicate(item.id)}
                disabled={user?.userGroup.mcopy === "n"}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-copy.svg"
                  alt="copy"
                />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="14" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ปี"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="year"
                    classLabel="normal"
                    selectValue={yearFilterList}
                    optionsList={optionsListYear}
                    handleChange={(value) => setYearFilterList(value)}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <label className="mb-0 normal">หน่วยงานที่แจ้งโรค </label>
                  <div className="d-flex align-items-center">
                    {form.orgName ? (
                      <>
                        <button
                          className="btn btn-primary pt-2 px-3"
                          onClick={() => setShowOrgModal(true)}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="ml-2">{form.orgName}</div>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary p-2 d-flex align-items-center"
                        onClick={() => setShowOrgModal(true)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        <p className="mb-0 ml-2">{t("search")}</p>
                      </button>
                    )}
                  </div>
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เลข Outbreak ID"
                    idName="outbreakID"
                    value={form.outbreakID}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, outbreakID: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เลข case"
                    idName="case"
                    value={form.case}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, case: value })}
                  />
                </Col>

                <Col lg={4} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เจ้าของสัตว์"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="ชื่อ/นามสุกล/บัตรประชาชน/เบอร์โทรศัพท์"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่พบโรค"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateF}
                    star={false}
                    handleChange={(value) => setForm({ ...form, dateF: value })}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.to_dateF}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, to_dateF: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่รายงานโรค"
                    classLabel="normal"
                    star={false}
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateR}
                    handleChange={(value) => setForm({ ...form, dateR: value })}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.to_dateR}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, to_dateR: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขตปศุสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={zoneLivestockSelectValue}
                    optionsList={zoneLivestockList}
                    handleChange={(value) => {
                      setZoneLivestockSelectValue([value]);
                      setForm({ ...form, zoneId: value.value });
                      getProvince(value.value);
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะการดำเนินการ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={StatusList}
                    handleChange={(value) => {
                      setStatusSelectValue([value]);
                      setForm({ ...form, status: value.value });
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => {
                      selectValueProvince(value);
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชื่อโรคที่สงสัย"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={diseaseSuspectedSelectValue}
                    optionsList={diseaseSuspectedList}
                    handleChange={(value) => {
                      setDiseaseSuspectedSelectValue([value]);
                      setForm({ ...form, diseaseTypeId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชื่อโรคที่พบ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={diseaseFoundSelectValue}
                    optionsList={diseaseFoundList}
                    handleChange={(value) => {
                      setDiseaseFoundSelectValue([value]);
                      setForm({ ...form, foundDiseaseTypeId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={animalSelectValue}
                    optionsList={animalList}
                    handleChange={(value) => {
                      setAnimalSelectValue([value]);
                      setForm({ ...form, animalId: value.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการ กคร.2"
            headRight={buttonAdd()}
 
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTable(listDiseaseInvestigation)}
            </DataTable>
          </BoxCard>
        </div>
      </div>

      {showOrgModal && (
        <ModalOrganizes
          show={showOrgModal}
          close={() => setShowOrgModal(false)}
          toSaveOrg={(data) => setOrganizes(data)}
        />
      )}
    </div>
  );
}
export default Disease_Investigation;
