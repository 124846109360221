import React, { useState , useEffect } from 'react'
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

function UploadImageFile({ title, files, onChange, star, type, invalid, handleInvalid, typeSave, classLabel, position }) {


    const initialForm = {
        file: "",
        imagePreviewUrl: "",
        check: false
    }
    const [form, setForm] = useState(initialForm);

    useEffect(() => {
        setForm({...form, imagePreviewUrl: files})
    }, [files])

    const _handleImageChange = (e) => {
        e.preventDefault();
        const fileTypeArray = ['image/png', 'image/jpg', 'image/jpeg']
        let reader = new window.FileReader();
        let file = e.target.files[0];

        if (file) {
            if (fileTypeArray.includes(file.type)) {
                //console.log("file", file);
                if (file.size < 1000000) {
                    reader.onloadend = () => {
                        setForm({ ...form, file: file, imagePreviewUrl: reader.result });
                    };
                    reader.readAsDataURL(file);

                    onChange(file);
                } else {
                    MySwal.fire({
                        icon: "warning",
                        type: "warning",
                        confirmButtonText: "ตกลง",
                        text: "กรุณาเลือกไฟล์ที่มีขนาดไม่เกิน 10 MB",
                    }).then(result => {
                        if (result.value) {
                            reader.onloadend = () => {
                                setForm({ ...form, file: "", imagePreviewUrl: "", check: false });
                            };
                        }
                    })
                }
            }
        }

    }

    const delImg = () => {
        setForm({ ...form, file: "", imagePreviewUrl: "", check: false });
    }

    const renderImage = () => {
        // console.log("files", files);
        if (form.imagePreviewUrl) {
            if (type == "profile") {
                return (
                    <div>
                        <img className="img-fluid rounded-circle border" src={form.imagePreviewUrl} alt="profile" />
                        <img className="img-fluid border-0 icon-del cursor-p ml-1" src={"/svg/del.svg"} alt="del" onClick={() => delImg()} />
                    </div>
                )
            } else if (type == "fileName") {
                return (
                    <span className="mr-1">
                        {files.name}
                        <img className="border-0 icon-del cursor-p ml-1" src={"/svg/del.svg"} alt="del" onClick={() => delImg()} />
                    </span>
                )
            } else if (type == "img") {
                return (
                    <div className="d-flex align-items-center">
                        <img className="img-fluid border" src={form.imagePreviewUrl} alt="img-file" />
                        <img className="border-0 icon-del cursor-p ml-1" src={"/svg/del.svg"} alt="del" onClick={() => delImg()} />
                    </div>
                )
            }
        }
    }

    return (
        <div>
            <div className={position == "left" ? "d-flex align-items-center" : ""}>
                <p className={`${classLabel} mb-0`}>{title} <span className="text-danger">{star ? "*" : ""}</span></p>
                {position == "top" &&
                    <div className="previewComponent d-flex flex-wrap">
                        <div className={type == "profile" ? "imgPreview mb-3 mb-sm-0" : "imgPreviewFile mb-3 mb-sm-0"}>{renderImage()}</div>
                    </div>}

                {!form.check ? <div className="img-camera mt-2">
                    <div className="file">
                        <button className="btn btn-sm btn-file mr-1" >
                            <p className="mb-0">เลือกไฟล์</p>
                        </button>
                        <span className="font14 mt-2">(ไม่เกิน 10 MB)</span>
                        <input
                            type="file"
                            name="file"
                            accept="image/png,image/jpeg,image/jpg"
                            onChange={e => {
                                _handleImageChange(e)
                                if (handleInvalid) {
                                    handleInvalid()
                                }
                            }}
                        />
                    </div>
                </div>
                    : ""
                }

            </div>
            {position == "left" &&
                <div className="previewComponent d-flex flex-wrap">
                    <div className={type == "profile" ? "imgPreview mb-3 mb-sm-0" : "imgPreviewFile mb-3 mb-sm-0"}>{renderImage()}</div>
                </div>
            }
            {invalid ? <span className="invalid-error">{invalid}</span> : ""}

            <style jsx="true" global="true">{`
                .previewComponent {
                    display: flex;
                    flex-direction: row;
                }
                .previewComponent p {
                    margin-bottom: 0;
                }
                // .img-camera {
                //   margin-top: -30px;
                //   margin-left: 70px;
                // }
                .img-camera .file {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;

                }
                .img-camera .file:hover {
                    cursor: pointer!important;
                }
                .img-camera input {
                    position: absolute;
                    opacity: 0;
                    left: 0;
                    top: 0;
                    height: 100%;
                }

                .btn-file {
                    background: linear-gradient(180deg, #E3E3E3 0%, #F7F7F7 100%);
                    border: 1px solid #979797;
                    box-sizing: border-box;
                }

                .previewComponent .imgPreview {
                    text-align: center;
                    margin: 0;
                }
                .previewComponent .imgPreview svg {
                    text-align: center;
                    margin: 1rem 0;
                    font-size: 10rem;
                }
                .previewComponent .imgPreview img {
                    width: 6rem;
                    height: 6rem;
                    object-fit: cover;
                    object-position: 30% 10%;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                }
                .previewComponent .imgPreviewFile img {
                    width: 20rem;
                    height: 12rem;
                    object-fit: cover;
                    object-position: left center;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                }
                .icon-del {
                    width: 25px !important;
                    height: 25px !important;
                }
          `}</style>
        </div>
    )
}

export default UploadImageFile;
