import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import InputText from "./InputText";
import InputMask from "./InputMask";
import BoxCard from "./BoxCard";
import UploadFileArray from "./UploadFileArray";
import { Row, Col, Form } from "react-bootstrap";

function DiseaseReport_Step3({ save, dataAll, modeGet }) {
    const user = useSelector(state => state.login.result)

    const initialForm = {
        detention: "",
        detentionDay: "",
        treatment_animal: "",
        bury: "",
        burn: "",
        throw_away: "",
        send_kill: "",
        disinfect: "",
        destruction5:"",
        other_disinfect: "",
        vaccine: "",
        drop_anti_insect: "",
        inject_anti_insect: "",
        deal_anti_insect: "",
        public_relations: "",

        filesPreview: [],
        filesDelete: []

    };

    const [form, setForm] = useState(initialForm);

    useEffect(async () => {
        if (modeGet == "dsGetById") {
            await getDataById();
        }
    }, [user])

    useEffect(async () => {
        await toSave()
    }, [form])

    const toSave = () => {
        save(form)
    }

    const getDataById = () => {
        setForm(dataAll)
    }

    const removeImage = async (index, item) => {
        let delFile = []
        await form.filesPreview.splice(index, 1);
        if (item.id) {
            delFile.push(item.id)
        }
        setForm({ ...form, filesPreview: form.filesPreview, filesDelete: delFile })
    }

    const renderImages = (dataFiles) => {
        return dataFiles.map((item, index) => {
            return (
                <div key={index} className="mt-1">
                    {
                        item.id ?
                            <span className="link"
                                onClick={() => {
                                    item.id ? window.open(item.url, '_blank').focus() : window.open("", '_blank').focus()
                                }}
                            >
                                {item.name}
                            </span>
                            :
                            item.name
                    }

                    <img className="img-fluid border-0 icon-del cursor-p ml-1" src={"/svg/del.svg"} alt="del" onClick={() => removeImage(index, item)} />
                </div>
            )
        })
    }

    return (
        <div className=" p-4 bg-white ">
            <BoxCard classNameBox="mt-3" title="5.1 การดำเนินการ Outbreak (รวมการรายงานโรคที่อยู่ใน Outbreak)" >
                <Row className="my-2">
                    <Col lg={3} md={12} sm={12}>
                        <div className="d-flex align-items-center mt-3">
                            <p className=" mr-2 ">1.</p>
                            <div className="ml-3">
                                <p>การกักสัตว์ป่วย</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={9} md={12} sm={12} >
                        <div className="d-flex align-items-center flex-wrap mt-2 ">
                            <p className="mr-3 none-lg"></p>
                            <div className="d-flex ml-3">
                                <p className="mt-2 mr-2 mb-0">กักสัตว์ (ตัว)</p>
                                <InputMask mask="9999999" type="number" title="" idName="detention" value={form.detention} star={false}
                                    placeholder="ระบุจำนวน" classLabel="normal" classFormGroup="mb-0" min="0"
                                    handleChange={(value) => {
                                        setForm({ ...form, detention: value })
                                        toSave()
                                    }}
                                />
                            </div>

                            <div className="d-flex ml-3">
                                <p className="mr-3 none-lg"></p>
                                <p className="mt-2 mr-2 mb-0">ระยะเวลา (วัน)</p>
                                <InputMask mask="9999999" type="number" title="" idName="detentionDay" value={form.detentionDay} star={false}
                                    placeholder="ระบุจำนวน" classLabel="normal" classFormGroup="mb-0" min="0"
                                    handleChange={(value) => {
                                        setForm({ ...form, detentionDay: value })
                                        toSave()
                                    }}
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-center flex-wrap ">
                            <p className="mr-3 none-lg"></p>
                            <div className="ml-3">
                                <p className="mb-0">หลักฐานบันทึกสั่งกัก</p>
                            </div>
                            <div className="ml-2 mt-3 mb-3">

                                <UploadFileArray filesPreview={form.filesPreview} typeSave="base64" type="file"
                                    onChange={(files) => setForm({ ...form, filesPreview: files })}
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-center ">
                            <p className="mr-3 none-lg"></p>
                            <div className="ml-3 border w-75 font14 text-gray p-2">
                                {
                                    (form.filesPreview.length > 0) ?
                                        renderImages(form.filesPreview)
                                        :
                                        <div className="p-2">
                                            <span className="">ไม่มีไฟล์</span>
                                        </div>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col lg={3} md={12} sm={12}>
                        <div className="d-flex align-items-center mt-3">
                            <p className="mr-2 ">2.</p>
                            <div className="ml-3">
                                <p>การรักษาสัตว์ป่วย</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={9} md={12} sm={12} >
                        <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
                            <p className="mr-3 none-lg"></p>
                            <p className="mr-3 mb-0">จำนวน (ตัว)</p>
                            <InputMask mask="9999999" type="number" title="" idName="treatment_animal" value={form.treatment_animal} star={false}
                                placeholder="ระบุจำนวน" classLabel="normal" classFormGroup="mb-0" min="0"
                                handleChange={(value) => {
                                    setForm({ ...form, treatment_animal: value })
                                    toSave()
                                }}
                            />
                        </div>
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col lg={3} md={12} sm={12}>
                        <div className="d-flex align-items-center mt-3">
                            <p className=" mr-2 ">3.</p>
                            <div className="ml-3">
                                <p>การทำลายสัตว์ป่วย</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={9} md={12} sm={12} >
                        <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
                            <div className="d-flex mr-3">
                                <p className="mr-3 none-lg"></p>
                                <p className="mt-2 mr-2 mb-0">ฝังกลบ (ตัว)</p>
                                <div className="d-flex ">
                                    <p className="mt-2 mr-2 ml-3">จำนวน</p>
                                    <InputMask mask="9999999" type="number" title="" idName="bury" value={form.bury} star={false}
                                        placeholder="ระบุจำนวน" classLabel="normal" classFormGroup="mb-0" min="0"
                                        handleChange={(value) => {
                                            setForm({ ...form, bury: value })
                                            toSave()
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="d-flex ">
                                <p className="mr-3 none-lg"></p>
                                <p className="mt-2 mr-5 mb-0">เผา (ตัว)</p>
                                <div className="d-flex ">
                                    <p className="mt-2 mr-2">จำนวน</p>
                                    <InputMask mask="9999999" type="number" title="" idName="burn" value={form.burn} star={false}
                                        placeholder="ระบุจำนวน" classLabel="normal" classFormGroup="mb-0" min="0"
                                        handleChange={(value) => {
                                            setForm({ ...form, burn: value })
                                            toSave()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center flex-wrap mt-2 ml-3 ">
                            <div className="d-flex mr-3">
                                <p className="mr-3 none-lg"></p>
                                <p className="mt-2 mr-3 mb-0">คัดทิ้ง (ตัว)</p>
                                <div className="d-flex">
                                    <p className="mt-2 mr-2 ml-3">จำนวน</p>
                                    <InputMask mask="9999999" type="number" title="" idName="throw_away" value={form.throw_away} star={false}
                                        placeholder="ระบุจำนวน" classLabel="normal" classFormGroup="mb-0" min="0"
                                        handleChange={(value) => {
                                            setForm({ ...form, throw_away: value })
                                            toSave()
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="d-flex">
                                <p className="mr-3 none-lg"></p>
                                <p className="mt-2 mr-3 mb-0">ส่งโรงฆ่า (ตัว)</p>
                                <div className="d-flex">
                                    <p className="mt-2 mr-2 ">จำนวน</p>
                                    <InputMask mask="9999999" type="number" title="" idName="send_kill" value={form.send_kill} star={false}
                                        placeholder="ระบุจำนวน" classLabel="normal" classFormGroup="mb-0" min="0"
                                        handleChange={(value) => {
                                            setForm({ ...form, send_kill: value })
                                            toSave()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center mt-2 ml-3">
                            <div className="d-flex flex-wrap ">
                                <p className="mr-3 none-lg"></p>
                                <p className="mt-2 mr-2">อื่นๆ</p>
                                <InputText type="text" title="" idName="destruction5" value={form.destruction5} star={false}
                                    placeholder="ระบุ" classLabel="normal"
                                    handleChange={(value) => {
                                        setForm({ ...form, destruction5: value })
                                        toSave()
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col lg={3} md={12} sm={12}>
                        <div className="d-flex align-items-center mt-3">
                            <p className=" mr-2 ">4.</p>
                            <div className="ml-3">
                                <p>การทำความสะอาดและฆ่าเชื้อ</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} md={12} sm={12} >
                        <div className="d-flex align-items-center mt-3 flex-wrap">
                            <p className="mr-3 none-lg"></p>
                            <p className="ml-3">วิธีทำลาย</p>
                        </div>
                    </Col>
                    <Col lg={8} md={12} sm={12} >
                        <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
                            <div className="d-flex flex-wrap">
                                <p className="mr-3 none-lg"></p>
                                <p className="mt-2 mr-2">1. พ่นยาฆ่าเชื้อทำลายเชื้อโรค</p>
                                <p className="mt-2 mr-2">จำนวน (ราย)</p>
                                <InputMask mask="9999999" type="number" title="" idName="disinfect" value={form.disinfect} star={false}
                                    placeholder="ระบุจำนวน" classLabel="normal" min="0"
                                    handleChange={(value) => {
                                        setForm({ ...form, disinfect: value })
                                        toSave()
                                    }}
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-center mt-2 ml-3">
                            <div className="d-flex flex-wrap ">
                                <p className="mr-3 none-lg"></p>
                                <p className="mt-2 mr-2">2. อื่นๆ</p>
                                <InputText type="text" title="" idName="other_disinfect" value={form.other_disinfect} star={false}
                                    placeholder="ระบุ" classLabel="normal"
                                    handleChange={(value) => {
                                        setForm({ ...form, other_disinfect: value })
                                        toSave()
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col lg={3} md={3} sm={12}>
                        <div className="d-flex align-items-center mt-3">
                            <p className=" mr-2 ">5.</p>
                            <div className="ml-3">
                                <p>การฉีดวัคซีน</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={9} md={12} sm={12} >
                        <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
                            <p className="mr-3">จำนวน (ตัว)</p>
                            <InputMask mask="9999999" type="number" title="" idName="vaccine" value={form.vaccine} star={false}
                                placeholder="ระบุจำนวน" classLabel="normal" min="0"
                                handleChange={(value) => {
                                    setForm({ ...form, vaccine: value })
                                    toSave()
                                }}
                            />
                        </div>
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col lg={3} md={12} sm={12}>
                        <div className="d-flex align-items-center mt-3">
                            <p className=" mr-2 ">6.</p>
                            <div className="ml-3">
                                <p>หยดหรือราดยาป้องกันแมลง</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={9} md={12} sm={12} >
                        <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
                            <p className="mr-3">จำนวน (ตัว)</p>
                            <InputMask mask="9999999" type="number" title="" idName="drop_anti_insect" value={form.drop_anti_insect} star={false}
                                placeholder="ระบุจำนวน" classLabel="normal" min="0"
                                handleChange={(value) => {
                                    setForm({ ...form, drop_anti_insect: value })
                                    toSave()
                                }}
                            />
                        </div>
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col lg={3} md={12} sm={12}>
                        <div className="d-flex align-items-center  mt-3">
                            <p className=" mr-2 ">7.</p>
                            <div className="ml-3">
                                <p>พ่นยาป้องกันแมลง</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={9} md={12} sm={12} >
                        <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
                            <p className="mr-3"> จำนวน (ตัว)</p>
                            <InputMask mask="9999999" type="number" title="" idName="inject_anti_insect" value={form.inject_anti_insect} star={false}
                                placeholder="ระบุจำนวน" classLabel="normal" min="0"
                                handleChange={(value) => {
                                    setForm({ ...form, inject_anti_insect: value })
                                    toSave()
                                }}
                            />
                        </div>
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col lg={12} md={12} sm={12}>
                        <div className="d-flex align-items-center ">
                            <p className=" mr-2 ">8.</p>
                            <div className="ml-3 mb-3">
                                <Form.Check
                                    inline type="checkbox"
                                    name="deal_anti_insect"
                                    id="deal_anti_insect"
                                    value={1}
                                    checked={form.deal_anti_insect}
                                    label="แจกยากำจัดแมลงแก่เกษตรกร"
                                    onChange={e => {
                                        setForm({ ...form, deal_anti_insect: e.target.checked })
                                        toSave()
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col lg={12} md={12} sm={12}>
                        <div className="d-flex align-items-center ">
                            <p className=" mr-2 ">9.</p>
                            <div className="ml-3 mb-3">
                                <Form.Check
                                    inline type="checkbox"
                                    name="public_relations"
                                    id="public_relations"
                                    value={1}
                                    checked={form.public_relations}
                                    label="ประชาสัมพันธ์ให้ความรู้แก่เกษตรกร"
                                    onChange={e => {
                                        setForm({ ...form, public_relations: e.target.checked })
                                        toSave()
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </BoxCard>
        </div>
    )
}

export default DiseaseReport_Step3
