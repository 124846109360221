import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BoxCard from "../../components/BoxCard";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import DataTable from "../../components/DataTable";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";
import { logout } from "../../redux/actions/logoutAction";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useMasterData } from "../../components/hooks/useMasterData";
import * as FileSaver from "file-saver";
import ButtonReportLink from "../../components/ButtonReportLink";

const MySwal = withReactContent(Swal);

function Save_Project() {
  const user = useSelector((state) => state.login.result);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["common", "footer"]);

  const initialForm = {
    typeUser: "1",
    fiscalYear: "",
    project: "",
    dated: "",
    todate: "",
    namep: "",
    status: "",
    animal: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        name: "ลำดับ",
        sortFlg: false,
      },
      {
        sortBy: "year",
        name: "ปีงบประมาณ",
        sortFlg: true,
      },

      {
        sortBy: "projectName",
        name: "โครงการ",
        sortFlg: true,
      },
      {
        sortBy: "mooId",
        name: "ข้อมูลพื้นที่",
        sortFlg: true,
      },

      {
        sortBy: "provinceId",
        name: "จังหวัด",
        sortFlg: true,
      },

      {
        sortBy: "animalTypeId",
        name: "ชนิดสัตว์",
        sortFlg: true,
      },

      {
        sortBy: "targetAmount",
        name: "จำนวนข้อมูล(บันทึก/เป้า)",
        sortFlg: true,
      },

      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: false,
      },

      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],

    statusActionId: [
      {
        id: 1,
        color: "#25B881",
        status: "บันทึกข้อมูลแล้ว",
      },
      {
        id: 2,
        color: "#FFC107",
        status: "กำลังบันทึกข้อมูล",
      },
      {
        id: 3,
        color: "#FF482F",
        status: "ยังไม่ได้บันทึกข้อมูล",
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listSaveProject, setSaveProject] = useState([]);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  useMasterData("province", provinceList, setProvinceList);
  // =================

  // select  ปีงบประมาณ  โครงการ สถานะ  ชนิดสัตว์
  const [fiscalYearSelectValue, setFiscalYearSelectValue] = useState([]);
  const [fiscalYearList, setFiscalYearList] = useState([]);

  const [projectSelectValue, setProjectSelectValue] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    {
      value: 1,
      label: "บันทึกข้อมูลแล้ว",
    },
    {
      value: 2,
      label: "กำลังบันทึกข้อมูล",
    },
    {
      value: 3,
      label: "ยังไม่ได้บันทึกข้อมูล",
    },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [isClearSearch, setIsClearSearch] = useState(false);
  // =================

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/active_surv/action_plan", name: "การเฝ้าระวังเชิงรุก" },
    { path: "/active_surv/action_plan", name: "แผนการดำเนินงาน" },
    { path: "active", name: "รายการบันทึกข้อมูลตามโครงการ" },
  ];

  useEffect(async () => {
    await getActionPlanYear();
    await getActionProject();
    await getAnimal();
    await getProvince();
  }, []);

  useEffect(async () => {
    await getSaveProject();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getActionPlanYear = async () => {
    try {
      const response = await API.getActionPlanYear();
      const data = await response.data;
      if (response.status == 200) {
        let yearArray = [];
        data.forEach((element) => {
          yearArray.push({
            value: element,
            label: element,
          });
        });
        setFiscalYearList(yearArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActionProject = async () => {
    try {
      const response = await API.getActionProject("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let projectArray = [];
        data.forEach((element) => {
          projectArray.push({
            value: element,
            label: element,
          });
        });
        setProjectList(projectArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id);
      const data = await response.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      zipcode: "",
      districtId: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      districtId: value.value,
      zipcode: "",
      subDistrictId: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      subDistrictId: value.value,
      zipcode: value.zipcode,
      villageId: "",
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const getSaveProject = async () => {
    const params = await {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",
      year: form.fiscalYear ? form.fiscalYear : "",
      projectName: form.project ? form.project : "",
      dateBegin: form.dated ? helper.getDate(form.dated) : "",
      dateEnd: form.todate ? helper.getDate(form.todate) : "",
      search: form.namep ? helper.getDate(form.namep) : "",
      statusId: form.status ? form.status : "",
      animalTypeId: form.animal ? form.animal : "",
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
      mooId: form.villageId ? form.villageId : "",
    };
    try {
      const response = await API.getSaveProject(params);

      const data = await response.data;
      if (response.status == 200) {
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setSaveProject(data.content);
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setFiscalYearSelectValue([]);
    setProjectSelectValue([]);
    setStatusSelectValue([]);
    setAnimalSelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type == "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type == "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (form.statusActionId && form.statusActionId.length > 0) {
      form.statusActionId.map((item, index) => {
        if (item.id == idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-2 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const buttonSearch = () => {
    return (
      <>
        <ButtonReportLink path={"as_result"} linkSize={5} addIndex params={
          {
            year: form.fiscalYear ? form.fiscalYear : "",
            projectName: form.project ? form.project : "",
            dateBegin: form.dated ? helper.getDate(form.dated) : "",
            dateEnd: form.todate ? helper.getDate(form.todate) : "",
            search: form.namep ? helper.getDate(form.namep) : "",
            statusId: form.status ? form.status : "",
            animalTypeId: form.animal ? form.animal : "",
            provinceId: form.provinceId ? form.provinceId : "",
            amphurId: form.districtId ? form.districtId : "",
            tambonId: form.subDistrictId ? form.subDistrictId : "",
            mooId: form.villageId ? form.villageId : "",
          }
        }/>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const buttonUpload = () => {
    return (
      <>
        <button
          className="btn btn-download text-white mr-2"
          onClick={() => {
            toDownloadSample();
          }}
          disabled={user?.userGroup?.mexport === "n"}
        >
          <img
            className="mr-2 mb-1"
            src={"/svg/download.svg"}
            alt="menu-icon"
          />
          ดาวน์โหลดไฟล์ตัวอย่าง
        </button>

        <label
          className={`btn btn-report text-white mr-2 mt-2 fw-400 ${
            user?.userGroup?.mcreate === "n" ? "disabled" : ""
          }`}
          htmlFor="exampleFormControlFile1"
        >
          <img className="mr-2 mb-1" src={"/svg/up.svg"} alt="menu-icon" />
          อัปโหลดไฟล์ตัวอย่าง
        </label>
        <input
          type="file"
          className="form-control-file"
          id="exampleFormControlFile1"
          hidden
          disabled={user?.userGroup?.mcreate === "n"}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={(e) => {
            _handleFileChange(e);
          }}
        />
      </>
    );
  };

  const toDownloadSample = async () => {
    try {
      const fileName = "Disease-Surveillance-Form_";
      const response = await API.getSampleTemplate();
      const data = await response.data;
      if (response.status == 200) {
        let url = data.data.url;

        let res = await fetch(url);
        let dt = await res.blob();
        let metadata = {
          type: "xlsx",
        };
        let today = new Date();
        let time =
          "_" +
          today.getHours() +
          "-" +
          today.getMinutes() +
          "-" +
          today.getSeconds();
        let file = new File(
          [dt],
          fileName + helper.getDate(today) + time + ".xlsx",
          metadata
        );
        FileSaver.saveAs(file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleFileChange = (e) => {
    e.preventDefault();
    const fileTypeArray = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    let file = e.target.files[0];
    if (file) {
      if (fileTypeArray.includes(file.type)) {
        if (file.size < 1000000) {
          toUploadSample(file);
        } else {
          MySwal.fire({
            icon: "warning",
            type: "warning",
            confirmButtonText: "ตกลง",
            text: "ไฟล์มีขนาดใหญ่เกินไป",
          });
        }
      }
    }
  };

  const toUploadSample = async (file) => {
    try {
      let formData = new FormData();
      formData.append("file", file);

      const response = await API.importSampleTemplate(formData);
      if (response.status == 200) {
        MySwal.fire({
          icon: "success",
          type: "success",
          confirmButtonText: "ตกลง",
          text: "นำเข้าฟอร์มสำเร็จ",
        });
        getSaveProject();
      }
    } catch (error) {
      const mgeError = error.response.data.errors[0];
      MySwal.fire({
        text: mgeError ?? "นำเข้าฟอร์มไม่สำเร็จ",
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
    }
  };

  const viewDetails = (id) => {
    history.push(`/active_surv/save-project/add-disease-surveillance/${id}`);
  };

  const deleteAction = (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบรายการข้อมูลตามโครงการ`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteSaveProject(id);
          if (response.status == 200) {
            await getSaveProject();
          }
        } catch (error) {
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td className="text-center">{item.year}</td>
          <td>
            <p className="font14 mb-0">{item.projectName}</p>
          </td>
          <td>
            <h6 className="mb-0 ">
              หมู่บ้าน {item.mooName} ต.{item.tambonName} อ.{item.amphurName}
            </h6>
          </td>
          <td>
            <p className="font14 mb-0 text-center">{item.provinceName}</p>
          </td>
          <td className="text-center">{item.animalTypeName}</td>
          <td className="text-center">
            {item.collectedAmount}/{item.targetAmount}
          </td>
          <td className="text-center">
            {item.statusId ? getStatusColor(item.statusId) : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => deleteAction(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="9" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ปีงบประมาณ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={fiscalYearSelectValue}
                    optionsList={fiscalYearList}
                    handleSelectValue={(value) =>
                      setFiscalYearSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, fiscalYear: value.value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="โครงการ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={projectSelectValue}
                    optionsList={projectList}
                    handleSelectValue={(value) =>
                      setProjectSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, project: value.value })
                    }
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ระหว่างวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dated}
                    maxDate={true}
                    star={false}
                    handleChange={(value) => setForm({ ...form, dated: value })}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.todate}
                    star={false}
                    minDate={form.dated}
                    maxDate={"afterSelectDate"}
                    handleChange={(value) =>
                      setForm({ ...form, todate: value })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="หมู่ที่"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="villageId"
                    classLabel="normal"
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({ ...form, villageId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={animalSelectValue}
                    optionsList={animalList}
                    handleSelectValue={(value) =>
                      setAnimalSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, animal: value.value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={statusList}
                    handleSelectValue={(value) =>
                      setStatusSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, status: value.value })
                    }
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการข้อมูลตามโครงการ"
            headRight={buttonUpload()}

          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTable(listSaveProject)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Save_Project;
