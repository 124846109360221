import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export default function Menu(props) {
  const location = useLocation();
  const diseaseInformNotify = useSelector((state) => state.diseaseInformNotify);
  const diseaseReportNotify = useSelector((state) => state.diseaseReportNotify);
  const [informNotify, setInformNotify] = useState(0);
  const [reportNotify, setReportNotify] = useState(0);
  const menu = useSelector((state) => state.menu);
  const user = useSelector((state) => state.login);
  const [userMenusId, setUserMenusId] = useState([]);
  useEffect(() => {

    window.$(document).ready(function () {
      const trees = window.$('[data-widget="treeview"]');
      trees.Treeview("init");
      window.$("body").Layout();
      window.$('[data-widget="pushmenu"]').PushMenu("init");
      window.$('.nav-sidebar .nav-item .child').click(function () {
        if (window.$("body").width() < 991) {
          window.$('.sidebar-mini').removeClass('sidebar-open');
          window.$('.sidebar-mini').addClass('sidebar-closed sidebar-collapse');
        }
      });
    });
  }, []);

  useEffect(() => {
    setUserMenusId(
      user.result?.userMenu
        .filter((item, index) => user.result?.userMenu.indexOf(item) === index)
        .map((item) => item.id)
    );
  }, [user]);

  useEffect(() => {
    setInformNotify(diseaseInformNotify.result);
    setReportNotify(diseaseReportNotify.result);
  }, [diseaseInformNotify, diseaseReportNotify]);

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4 ">
      <ul className="navbar-nav ">
        <li className="nav-item">
          <Link
            to={`/home`}
            className="nav-link d-flex align-items-center brand-link "
            role="button"
          >
            <img className="logo-menu" src={"/svg/logo.svg"} alt="logo" />
            <p className="text-logo-3 ">ระบบเฝ้าระวังโรคสัตว์ </p>
            <p className="text-logo-4 "> กรมปศุสัตว์</p>
          </Link>
        </li>
      </ul>

      <div className="sidebar">
        <nav className="">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item mt-2">
              <Link to={`/home`} className="nav-link d-flex align-items-center">
                <img
                  className="nav-icon"
                  src={"/svg/menu_1.svg"}
                  alt="home-icon"
                />
                <p className="font14 mb-0 mt-1 text-white ">หน้าหลัก</p>
              </Link>
            </li>

            {location.pathname.includes("farm_registration") && (
              <>
                {userMenusId?.includes(63) && (
                  <li className="nav-item">
                    <Link to={"/farm_registration"}>
                      <div
                        className={`nav-link cursor-p ${!location.pathname.includes(
                          "/farm_registration/farm_registration_report"
                        )
                            ? "active-1"
                            : ""
                          }`}
                      >
                        <img
                          className="nav-icon"
                          src={"/svg/menu_2.svg"}
                          alt="menu-icon"
                        />
                        <p className="font14 mb-0 text-white ">
                          ขึ้นทะเบียนฟาร์ม
                        </p>
                      </div>
                    </Link>
                  </li>
                )}
                <li className="nav-item ">
                  <a
                    className={`nav-link cursor-p ${location.pathname.includes(
                      "/farm_registration/farm_registration_report"
                    )
                        ? "active-1"
                        : ""
                      }`}
                  >
                    <img
                      className="nav-icon"
                      src={"/svg/menu_5.svg"}
                      alt="menu-icon"
                    />
                    <p className="font14 mb-0 text-white ">รายงาน</p>
                  </a>
                  {userMenusId?.includes(64) && (
                    <ul className="nav nav-treeview ">
                      <li
                        className={
                          location.pathname.includes(
                            "/farm_registration/farm_registration_report"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/farm_registration/farm_registration_report`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            การขึ้นทะเบียนฟาร์ม
                          </p>
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              </>
            )}

            {location.pathname.includes("passive_surv") ? (
              <>
                <li className="nav-item">
                  <a
                    className={`nav-link cursor-p ${!location.pathname.includes("/passive_surv/report/")
                        ? "active-1"
                        : ""
                      }`}
                    title="เฝ้าระวังเชิงรับ"
                  >
                    <img
                      className="nav-icon"
                      src={"/svg/menu_3.svg"}
                      alt="menu-icon"
                    />
                    <p className="font14 mb-0 mt-1 text-white ">
                      เฝ้าระวังเชิงรับ
                    </p>
                  </a>

                  <ul className="nav nav-treeview ">
                    {userMenusId?.includes(9) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/passive_surv/disease_report"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/passive_surv/disease_report`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white d-flex flex-wrap">
                            <span className="d-flex no-wrap"><i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>แจ้งพบ - </span>
                            <span className="d-flex no-wrap">
                              สงสัยโรคระบาดสัตว์
                              <span className="notification">
                                <span className="badge ml-3">{informNotify}</span>
                              </span>
                            </span>

                          </p>
                        </Link>
                      </li>
                    )}

                    {userMenusId?.includes(10) && (
                      <li
                        className={
                          location.pathname.includes("/passive_surv/outbreak")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/passive_surv/outbreak`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            Outbreak
                          </p>
                        </Link>
                      </li>
                    )}

                    {userMenusId?.includes(11) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/passive_surv/disease_statement"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/passive_surv/disease_statement`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            กคร.1/รก.1
                            <span className="notification">
                              <span className="badge ml-3">{reportNotify}</span>
                            </span>
                          </p>

                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(15) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/passive_surv/keep-example"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/passive_surv/keep-example`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            เก็บตัวอย่าง/ผลตรวจ
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(12) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/passive_surv/disease_investigation"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/passive_surv/disease_investigation`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            กคร.2
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(13) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/passive_surv/disease_control"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/passive_surv/disease_control`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            กคร.3/ สคส.1
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(14) && (
                      <li
                        className={
                          location.pathname.includes("/passive_surv/vaccinate")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/passive_surv/vaccinate`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            กคร.5
                          </p>
                        </Link>
                      </li>
                    )}

                    {userMenusId?.includes(16) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/passive_surv/epidemic_zone"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/passive_surv/epidemic_zone`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex ">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ประกาศเขตโรคระบาด
                          </p>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li className="nav-item  ">
                  <a
                    className={`nav-link cursor-p ${location.pathname.includes("/passive_surv/report/")
                        ? "active-1"
                        : ""
                      }`}
                    title="เฝ้าระวังเชิงรับ"
                  >
                    <img
                      className="nav-icon"
                      src={"/svg/menu_5.svg"}
                      alt="menu-icon"
                    />
                    <p className="font14 mb-0 text-white ">รายงาน</p>
                  </a>

                  <ul className="nav nav-treeview mt-2">
                    {userMenusId?.includes(17) && (
                      <li
                        className={
                          location.pathname.includes("/disease-informs")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/passive_surv/report/disease-informs`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            การแจ้งโรค
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(18) && (
                      <li
                        className={
                          location.pathname.includes("/report/outbreak")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to="/passive_surv/report/outbreak"
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            Outbreak
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(19) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/report/disease-statement"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to="/passive_surv/report/disease-statement"
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            กคร.1/รก.1
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(20) && (
                      <li
                        className={
                          location.pathname.includes("/report/epidemic_zone")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to="/passive_surv/report/epidemic_zone"
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white d-flex ">
                            <i
                              className="fa fa-circle icon-dot"
                              aria-hidden="true"
                            ></i>
                            รายงาน <br />
                            วิเคราะห์เชิงพื้นที่
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(21) && (
                      <li
                        className={
                          location.pathname.includes("/report/daily")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to="/passive_surv/report/daily"
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot"
                              aria-hidden="true"
                            ></i>
                            รายงาน <br />
                            ภาพรวมรายวัน
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(86) && (
                      <li
                        className={
                          location.pathname.includes("/report/disease-control")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to="/passive_surv/report/disease-control"
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            รายงานควบคุมโรค
                          </p>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
            {/* sdasdas */}
            {location.pathname.includes("zero_report") ? (
              <>
                <li className="nav-item">
                  <a
                    className={`nav-link cursor-p ${!location.pathname.includes("/zero_report/report/")
                        ? "active-1"
                        : ""
                      }`}
                    title="รายงาน Zero Report"
                  >
                    <img
                      className="nav-icon"
                      src={"/svg/menu_3.svg"}
                      alt="menu-icon"
                    />
                    <p className="font14 mb-0 mt-1 text-white ">
                    รายงาน Zero Report
                    </p>
                  </a>
                </li>
              
              </>
            ) : (
              ""
            )}
            {/* sdasds */}
            {location.pathname.includes("active_surv") ? (
              <>
                <li className="nav-item">
                  <a
                    className={`nav-link cursor-p ${!location.pathname.includes("/active_surv/report/")
                        ? "active-1"
                        : ""
                      }`}
                    title="เฝ้าระวังเชิงรุก"
                  >
                    <img
                      className="nav-icon"
                      src={"/svg/menu_6.svg"}
                      alt="menu-icon"
                    />
                    <p className="font14 mb-0 mt-1 text-white ">
                      เฝ้าระวังเชิงรุก
                    </p>
                  </a>

                  <ul className="nav nav-treeview ">
                    {userMenusId?.includes(65) && (
                      <li
                        className={
                          location.pathname.includes("/active_surv/action_plan")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/active_surv/action_plan`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            แผนการดำเนินงาน
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(66) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/active_surv/save-project"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/active_surv/save-project`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            บันทึกข้อมูล
                          </p>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li className="nav-item  ">
                  <a
                    className={`nav-link cursor-p ${location.pathname.includes("/active_surv/report/")
                        ? "active-1"
                        : ""
                      }`}
                    title="รายงานเฝ้าระวังเชิงรุก"
                  >
                    <img
                      className="nav-icon"
                      src={"/svg/menu_5.svg"}
                      alt="menu-icon"
                    />
                    <p className="font14 mb-0 text-white ">รายงาน</p>
                  </a>

                  <ul className="nav nav-treeview ">
                    {userMenusId?.includes(5) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/active_surv/report/record_result"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/active_surv/report/record_result`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            การบันทึกผล
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(6) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/active_surv/report/test_results"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/active_surv/report/test_results`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex ">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ผลการทดสอบโรค
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(7) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/active_surv/report/immune_level"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/active_surv/report/immune_level`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ระดับภูมิคุ้มกัน
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(8) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/active_surv/report/prevalence"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/active_surv/report/prevalence`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot"
                              aria-hidden="true"
                            ></i>
                            ความชุกตาม
                            <br />
                            ชนิดสัตว์และพื้นที่
                          </p>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {location.pathname.includes("assess_risk") ? (
              <>
                <li className="nav-item">
                  <a
                    className={`nav-link cursor-p ${!location.pathname.includes("/assess_risk/report/")
                        ? "active-1"
                        : ""
                      }`}
                    title="ประเมินความเสี่ยง"
                  >
                    <img
                      className="nav-icon"
                      src={"/svg/assess_icon.svg"}
                      alt="menu-icon"
                    />
                    <p className="font14 mb-0 mt-1 text-white ">
                      ประเมินความเสี่ยง
                    </p>
                  </a>

                  <ul className="nav nav-treeview ">
                    {userMenusId?.includes(22) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/assess_risk/list-assess-risk"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/assess_risk/list-assess-risk`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ชุดประเมินความเสี่ยง
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(23) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/assess_risk/risk-assessment-items"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/assess_risk/risk-assessment-items`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            รายการประเมินความเสี่ยง
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(24) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/assess_risk/assess-risk-result"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/assess_risk/assess-risk-result`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ผลการประเมินความเสี่ยง
                          </p>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>

                <li className="nav-item  ">
                  <a
                    className={`nav-link cursor-p ${location.pathname.includes("/assess_risk/report/")
                        ? "active-1"
                        : ""
                      }`}
                    title="D99RPT01-ประเมินความเสี่ยงฟาร์ม"
                  >
                    <img
                      className="nav-icon"
                      src={"/svg/menu_5.svg"}
                      alt="menu-icon"
                    />
                    <p className="font14 mb-0 text-white ">รายงาน</p>
                  </a>

                  <ul className="nav nav-treeview ">
                    {userMenusId?.includes(25) && (
                      <li
                        className={
                          location.pathname.includes("/assess_risk/report/risk")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/assess_risk/report/risk`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ประเมินความเสี่ยงฟาร์ม
                          </p>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {location.pathname.includes("/gfm") && (
              <>
                {userMenusId?.includes(26) && (
                  <li className="nav-item">
                    <Link to={"/gfm"}>
                      <div
                        className={`nav-link cursor-p ${!location.pathname.includes("/gfm/report/")
                            ? "active-1"
                            : ""
                          }`}
                      >
                        <img
                          className="nav-icon"
                          src={"/svg/gene.svg"}
                          alt="gene-icon"
                        />
                        <p className="font14 mb-0 text-white ">GFM</p>
                      </div>
                    </Link>
                  </li>
                )}

                <li className="nav-item ">
                  <a
                    className={`nav-link cursor-p ${location.pathname.includes("/gfm/report/")
                        ? "active-1"
                        : ""
                      }`}
                  >
                    <img
                      className="nav-icon"
                      src={"/svg/menu_5.svg"}
                      alt="menu-icon"
                    />
                    <p className="font14 mb-0 text-white ">รายงาน</p>
                  </a>
                  <ul className="nav nav-treeview ">
                    {userMenusId?.includes(27) && (
                      <li className={
                        location.pathname.includes("/gfm/report/gfm-report")
                          ? "nav-item child active"
                          : "nav-item"
                      }
                      >
                        <Link
                          to={"/gfm/report/gfm-report"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ฟาร์ม GFM
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(27) && (
                      <li className={
                        location.pathname.includes("/gfm/report/gfm-farmer")
                          ? "nav-item child active"
                          : "nav-item"
                      }
                      >
                        <Link
                          to={"/gfm/report/gfm-farmer"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            เกษตรกร GFM
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(27) && (
                      <li className={
                        location.pathname.includes("/gfm/report/gfm-sum-farmType")
                          ? "nav-item child active"
                          : "nav-item"
                      }
                      >
                        <Link
                          to={"/gfm/report/gfm-sum-farmType"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            GFM ตามประเภทฟาร์ม
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(27) && (
                      <li className={
                        location.pathname.includes("/gfm/report/gfm-sum-province")
                          ? "nav-item child active"
                          : "nav-item"
                      }
                      >
                        <Link
                          to={"/gfm/report/gfm-sum-province"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            GFM ตามจังหวัด
                          </p>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </>
            )}

            {location.pathname.includes("data-manage") ? (
              <>
                <li className="nav-item">
                  <a
                    className={
                      location.pathname.includes("/web-settings")
                        ? "nav-link active-1 cursor-p"
                        : "nav-link cursor-p"
                    }
                    title="S06M01-ตั้งค่าเวปไซต์"
                  >
                    <img
                      className="nav-icon"
                      src={"/svg/setting.svg"}
                      alt="menu-icon"
                    />
                    <p className="font14 mb-0 mt-1 text-white ">
                      ตั้งค่าเวบไซต์
                    </p>
                  </a>

                  <ul className="nav nav-treeview ">
                    {userMenusId?.includes(28) && (
                      <li
                        className={
                          location.pathname == "/data-manage/web-settings/news"
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/web-settings/news"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ข่าวประชาสัมพันธ์
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(30) && (
                      <li
                        className={
                          location.pathname.includes("/web-settings/warning")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/web-settings/warning"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            เตือนภัยโรคระบาด
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(29) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/web-settings/epidemic-zone"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/web-settings/epidemic-zone"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ประกาศเขตโรคระบาด
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(31) && (
                      <li
                        className={
                          location.pathname.includes("/web-settings/document")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/web-settings/document"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            เอกสารที่เกี่ยวข้อง
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(32) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/web-settings/contact-details"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/web-settings/contact-details"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            รายละเอียดการติดต่อ
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(34) && (
                      <li
                        className={
                          location.pathname.includes("/web-settings/system")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/web-settings/system"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ระบบที่เกี่ยวข้อง
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(33) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/web-settings/banners-relations"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/web-settings/banners-relations"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ป้ายประชาสัมพันธ์
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(35) && (
                      <li
                        className={
                          location.pathname ==
                            "/data-manage/web-settings/news-categories"
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/web-settings/news-categories"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            หมวดหมู่ข่าวสาร
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(73) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/web-settings/doc-categories"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/web-settings/doc-categories"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            หมวดหมู่เอกสาร
                          </p>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>

                <li className="nav-item  ">
                  <a
                    className={
                      location.pathname.includes("/users")
                        ? "nav-link active-1 cursor-p"
                        : "nav-link cursor-p"
                    }
                    title="S06M03-Master Data"
                  >
                    <img
                      className="nav-icon fit"
                      src={"/svg/menu-user.svg"}
                      alt="menu-user-icon"
                    />
                    <p className="font14 mb-0 mt-1 text-white ">ผู้ใช้งาน</p>
                  </a>

                  <ul className="nav nav-treeview ">
                    {userMenusId?.includes(36) && (
                      <li
                        className={
                          location.pathname.includes("/users/users")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/users/users"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ผู้ใช้งาน
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(37) && (
                      <li
                        className={
                          location.pathname.includes("/users/group-user")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/users/group-user"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            กลุ่มผู้ใช้งาน
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(38) && (
                      <li
                        className={
                          location.pathname.includes("/users/view-user")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/users/view-user"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            สิทธิการดูข้อมูล
                          </p>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>

                <li className="nav-item  ">
                  <a
                    className={
                      location.pathname.includes("/master-data")
                        ? "nav-link active-1 cursor-p"
                        : "nav-link cursor-p"
                    }
                    title="S06M03-Master Data"
                  >
                    <img
                      className="nav-icon fit"
                      src={"/svg/master-data.svg"}
                      alt="menu-icon"
                    />
                    <p className="font14 mb-0 mt-1 text-white ">Master Data</p>
                  </a>

                  <ul className="nav nav-treeview ">
                    {userMenusId?.includes(39) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/animal-type")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/master-data/animal-type"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ชนิดสัตว์
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(40) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/master-data/disease-type"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/master-data/disease-type"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ชนิดโรค
                          </p>
                        </Link>
                      </li>
                    )}
                    {/* {userMenusId?.includes(41) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/test-method")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/test-method`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            วิธีการทดสอบ
                          </p>
                        </Link>
                      </li>
                    )} */}
                    {userMenusId?.includes(42) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/sample-type")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/sample-type`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ชนิดตัวอย่าง
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(43) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/symptom")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/master-data/symptom"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            อาการป่วย
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(44) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/menu-system")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/master-data/menu-system"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            เมนู
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(45) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/lab")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/lab`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ห้องปฏิบัติการ
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(46) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/positions")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/positions`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ตำแหน่งเจ้าหน้าที่
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(47) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/organizes")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/organizes`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            หน่วยงานกรมปศุสัตว์
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(48) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/title")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/title`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            คำนำหน้าชื่อ
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(49) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/master-data/animal-purpose"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/animal-purpose`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            วัตถุประสงค์ในการเลี้ยงสัตว์
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(50) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/master-data/sample-purpose"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/sample-purpose`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            วัตถุประสงค์การเก็บตัวอย่าง/ผลตรวจ
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(51) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/annouce")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/annouce`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ประเภทประกาศโรคระบาด
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(52) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/farmer-type")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/farmer-type`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ประเภทเกษตรกรมาตรฐาน GFM
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(53) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/analyse")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/analyse`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            วิเคราะห์ข้อมูลการสอบสวนโรค
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(55) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/sample-send")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/sample-send`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            วิธีการส่งตัวอย่าง
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(56) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/master-data/vaccine-type"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/vaccine-type`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ชนิดวัคซีน
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(57) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/master-data/zone-livestock"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/zone-livestock`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            เขตปศุสัตว์
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(58) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/provinces")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/provinces`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            จังหวัด
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(59) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/district")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/district`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            อำเภอ
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(60) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/subdistrict")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/subdistrict`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ตำบล
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(61) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/dlas")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/dlas`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            องค์กรปกครองส่วนท้องถิ่น
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(54) && (
                      <li
                        className={
                          location.pathname.includes(
                            "/master-data/farm-certificate"
                          )
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/farm-certificate`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ประเภทมาตฐานฟาร์ม
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(74) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/risk")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/risk`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            เกณฑ์ผลการประเมินความเสี่ยง
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(84) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/husbandry")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/husbandry`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            ลักษณะการเลี้ยง
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(85) && (
                      <li
                        className={
                          location.pathname.includes("/master-data/animalplace")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={`/data-manage/master-data/animalplace`}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            สถานที่อยู่สัตว์
                          </p>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>

                <li className="nav-item  ">
                  <a
                    className={
                      location.pathname.includes("/report")
                        ? "nav-link active-1 cursor-p"
                        : "nav-link cursor-p"
                    }
                    title="S06M04-รายงาน"
                  >
                    <img
                      className="nav-icon"
                      src={"/svg/menu_5.svg"}
                      alt="menu-icon"
                    />
                    <p className="font14 mb-0 mt-1 text-white ">รายงาน</p>
                  </a>

                  <ul className="nav nav-treeview ">
                    {userMenusId?.includes(75) && (
                      <li
                        className={
                          location.pathname.includes("access")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/report/access"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            การเข้าใช้งาน
                          </p>
                        </Link>
                      </li>
                    )}
                    {userMenusId?.includes(76) && (
                      <li
                        className={
                          location.pathname.includes("transaction")
                            ? "nav-item child active"
                            : "nav-item"
                        }
                      >
                        <Link
                          to={"/data-manage/report/transaction"}
                          className="nav-link child"
                        >
                          <p className="font14 mb-0 text-white  d-flex">
                            <i
                              className="fa fa-circle icon-dot-1"
                              aria-hidden="true"
                            ></i>
                            การปรับปรุงข้อมูล
                          </p>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
}
