import React, { useState, useEffect } from "react";
import * as helper from "../utils/helper";
import * as API from "../utils/apis";
import { useDispatch, useSelector } from 'react-redux';
import BoxCard from "./BoxCard";
import InputSelect from "./InputSelect";
import InputText from "./InputText";
import { Row, Col } from "react-bootstrap";
import InputDatePicker from "./InputDatePicker";
import DataTableNodata from './DataTableNodata'
import UploadImage from "./UploadImage";
import ModalEditAnimal from "./Modal.EditAnimal";
import { loading } from "../redux/actions/loadingAction";
import moment from 'moment';
import 'moment/locale/th';
import KeepExample_Step2_1 from "./KeepExample_Step2_1";

function KeepExample_Step2({ save, dataAll, modeGet, farmId, animalListData }) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.login.result)

    const initialForm = {
        idHouse: "",
        nameHouse: "",
        caseCode: "",
        animalTypeId: "",
        animalTypeName: "",

        data_setAnimal: [],
        barn: [],
        data_file: [],
        del_file: [],
        checkAddFile: false,
        checkAddFilePerAnimal: false,
        step2_barn: []

    };
    const [form, setForm] = useState(initialForm);

    const initialFormFile = {
        url: "",
        file: "",
        detail_file: "",
        date_file: "",
        fileName: ""
    };
    const [formFile, setFormFile] = useState(initialFormFile);

    const initialFormFilePerAnimal = {
        url: "",
        ani_no: "",
        ani_name: "",
        ani_type: "",
        method_id: "",
        lims_case_no: "",
        response_file: "",
        fileName: ""
    };
    const [formFilePerAnimal, setFormFilePerAnimal] = useState(initialFormFilePerAnimal);

    const initialFormJson = {
        animalId: {},
        husbandry: {},
        animalPurpose: {},
        vaccineType: {},
        keepName: {},
    };
    const [formJson, setFormJson] = useState(initialFormJson);


    const [animalSelectValue, setAnimalSelectValue] = useState([])
    const [animalList, setAnimalList] = useState([])

    const [sampleListByAnimal, setSampleListByAnimal] = useState([]);
    const [sampleListByFarm, setSampleListByFarm] = useState([]);
    const [deleteSampleListByAnimal, setDeleteSampleListByAnimal] = useState([]);

    const initAniTypeSelectValue = [];
    const [aniTypeSelectValue, setAniTypeSelectValue] = useState(initAniTypeSelectValue);
  const [aniTypeList, setAniTypeList] = useState([]);

  const initMethodSelectValue = [];
    const [methodSelectValue, setMethodSelectValue] = useState(initMethodSelectValue);
  const [methodList, setMethodList] = useState([]);

    useEffect(async () => {
        await getMasterJson()
        await getAniType()
        await getMethod()
        
    }, [])

    useEffect(() => {
        if (modeGet == "getById") {
            getDataById();
        }
    }, [])

    useEffect(() => {
        if (aniTypeList.length > 0) {
            sampleListByAnimal.map((item, i) => {
                if (item.aniTypeId != null) {
                    aniTypeList.map((aniItem, i) => {
                        if (aniItem.value == item.aniTypeId) {
                            item.aniType = aniItem;
                        }
                    })
                }
            })
        }

        if (methodList.length > 0) {
            sampleListByAnimal.map((item, i) => {
                if (item.testMethod != null) {
                    methodList.map((methodItem, i) => {
                        if (methodItem.value == item.testMethod) {
                            item.method = methodItem;
                        }
                    })
                }
            })
        }

    }, [aniTypeList,methodList,sampleListByAnimal, form])

    // user, modeGet

    useEffect(async () => {
        await toSave()
    }, [form])

    const toSave = () => {
        
        save(form)
    }

    const getMasterJson = async () => {
        const params = await [
            "animalType",
            "husbandry",
            "animalPurpose",
            "vaccineType",
            "keepType"
        ];
        try {
            const response = await API.getMasterJson(params);
            const data = await response.data;
            if (response.status == 200) {
                await setFormJson({
                    ...formJson,
                    animalId: data.animalType,
                    husbandry: data.husbandry,
                    animalPurpose: data.animalPurpose,
                    vaccineType: data.vaccineType,
                    keepName: data.keepType,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAniType = async () => {
        try {
          const response = await API.getAnimaltype();
          const data = await response.data;
          // console.log("params---getOutbreak--🐳🐳", params, response);
    
          if (response.status == 200) {
            let objArray = [];
              data.content.forEach((element) => {
                objArray.push({
                  value: element.id,
                  label: element.name,
                });
              });
              await setAniTypeList(objArray);
          }
        } catch (error) {
          console.log(error);
        }
      };

      const getMethod = async () => {
        try {
          const response = await API.getTestMethod();
          const data = await response.data;
          // console.log("params---getOutbreak--🐳🐳", params, response);
    
          if (response.status == 200) {
            let objArray = [];
              data.content.forEach((element) => {
                objArray.push({
                  value: element.id,
                  label: element.name,
                });
              });
              await setMethodList(objArray);
          }
        } catch (error) {
          console.log(error);
        }
      };


    const toAddFile = () => {
        setForm({ ...form, checkAddFile: true })
        setFormFile({ ...formFile, date_file: Date.now(), detail_file: "", file: "", fileName: "" })
    };

    // const toAddFilePerAnimal = () => {
    //     setForm({ ...form, checkAddFilePerAnimal: true })
    //     setFormFile({ ...formFilePerAnimal, ani_no: "", ani_name: "", ani_type: "", method_id: "", lims_case_no: "", response_file: "", fileName: ""})
    // };

    const toAddFilePerFarm = (item) => {
        // const data = sampleListByFarm.map((item) => {
        //   return { ...item, edit: true };
        // });
        const data = form.barn.map((item) => {
            const data2 = item.sample.map((item) => {
                return  {...item, edit: true }
            });
            data2.push({});
            //return { ...item, edit: true };
          });
        // setSampleListByFarm([...data, { disease_movement: [], save: true }]);
        setForm({...form, barn : data})
      };

    const toAddFilePerAnimal = () => {
        const data = sampleListByAnimal.map((item) => {
          return { ...item, edit: true };
        });
        setSampleListByAnimal([...data, { disease_movement: [], save: true }]);
      };

    const removeImage = (index, item) => {
        let delFile = []
        form.data_file.splice(index, 1);
        if (item.id) {
            delFile.push(item.id)
        }
        setForm({ ...form, data_file: form.data_file, del_file: delFile });
    }

    const fileSave = (data) => {
        let arrayFile = [...form.data_file];
        arrayFile.push({
            file: data.file,
            fileName: data.fileName,
            detail_file: data.detail_file,
            date_file: Date.now()
        })
        setForm({ ...form, data_file: arrayFile, checkAddFile: false });
        toSave()
    }

    const getDataById = async () => {
        const sampleTestFiles = dataAll.step2_TestFiles;

        let barn_Step1 = []
        if (dataAll.barn_Step1 && dataAll.barn_Step1.length > 0) {
            dataAll.barn_Step1.map(async (itemBarn, indexBarn) => {

                let arrayBuilding = [];
                try {
                    const response = await API.getFarmRegistrationById(farmId ? farmId.fm01.farmId : "");
                    const dataRes = await response?.data;
                    if (response.status == 200) {
                        let arrayHouseList = []
                        if (dataRes && dataRes.barn.length > 0) {
                            dataRes.barn.forEach((house =>
                                arrayBuilding.push({ value: house.id, label: house.name })
                            ))
                        }
                    }
                } catch (error) {
                    dispatch(loading(false))
                    if (error.response && error.response.status == 401) {
                        console.log(error)
                    }
                }

                let nameHouse;
                arrayBuilding.filter((itemH) => {
                    if (itemH.value == itemBarn.barnId) {
                        nameHouse = itemH.label;
                    }
                });

                let animalTypeName;
                animalListData.filter((itemA) => {
                    if (itemA.value == itemBarn.animalTypeId) {
                        animalTypeName = itemA.label;
                    }
                });

                barn_Step1.push({
                    id: itemBarn.id,
                    idHouse: itemBarn.barnId,
                    nameHouse: nameHouse,
                    animalTypeId: itemBarn.animalTypeId,
                    animalTypeName: animalTypeName,
                    number: itemBarn.animalAmt,
                    caseLims: itemBarn.limsCaseId,
                    houseSelectValue: [{ value: itemBarn.barnId, label: nameHouse }],
                    houseList: [],
                    animalTypeSelectValue: [{ value: itemBarn.animalTypeId, label: animalTypeName }],
                    animalTypeList: [],
                    result: itemBarn.result,
                })
            })
        }

        let step1Ex = [];
        if (dataAll.step1Ex && dataAll.step1Ex.length > 0) {
            dataAll.step1Ex.map((item, i) => {
                if (item.result && item.result.length > 0) {
                    item.result.map((itemR, indexR) => {
                        step1Ex.push({
                            idEx: item.id,
                            animalTypeId: item.animalTypeId,
                            name: item.animalName,
                            no_nid: item.nid,
                            result: itemR
                        })
                    })
                }
            })
        }

        let step2File = [];
        if (dataAll.step2File && dataAll.step2File.length > 0) {
            dataAll.step2File.map((item, i) => {
                step2File.push({
                    id: item.id,
                    file: item.url,
                    fileName: item.name,
                    detail_file: item.description,
                    date_disease: item.date,
                })
            })
        }

        dataAll.sampleListAnimal.map((item, i) => {
            if (item.aniTypeId != null) {
                aniTypeList.map((aniItem, i) => {
                    if (aniItem.value == item.aniTypeId) {
                        item.aniType = aniItem;
                    }
                })
            }
            if (item.testMethod != null) {
                methodList.map((methodItem, i) => {
                    if (methodItem.value == item.testMethod) {
                        item.method = methodItem;
                    }
                })
            }
        })

        setSampleListByAnimal(dataAll.sampleListAnimal)

        setForm({
            ...form,
            data_file: step2File,
            barn: barn_Step1,
            data_setAnimal: step1Ex,
            sampleListAnimal: dataAll.sampleListAnimal,
        })
    }

    const renderTableAnimal = (data, index) => {
        return (
            <tr key={index}  >
                <td>{index + 1}</td>
                <td>
                        {data.save ? (<InputText
                            classFormGroup="mb-0 "
                            type="text"
                            idName={`aniNo_${index}`}
                            value={data.aniNo ? data.aniNo : ""}
                            star={false}
                            placeholder="โปรดระบุ"
                            handleChange={(value) => {
                                    data.aniNo = value
                                    setSampleListByAnimal([...sampleListByAnimal])
                                }
                            }
                            
                            /> 
                            ) : (
                                <span className="text-nowrap">{data.aniNo}</span>
                              )}

                </td>
                <td>
                {data.save ? (<InputText
                            classFormGroup="mb-0 "
                            type="text"
                            idName={`aniName_${index}`}
                            value={data.aniName ? data.aniName : ""}
                            star={false}
                            placeholder="โปรดระบุ"
                            handleChange={(value) => {
                                    data.aniName = value
                                    setSampleListByAnimal([...sampleListByAnimal])
                                }
                            }
                    />
                    ) : (
                        <span className="text-nowrap">{data.aniName}</span>
                      )}
                </td>
                        <td>
                        {data.save ? (<InputSelect
                            classFormGroup="mb-0"
                            star={false}
                            placeholder="กรุณาเลือก"
                            idName={`aniType_${index}`}
                            classLabel="normal"
                            selectValue={data.aniType}
                            optionsList={aniTypeList}
                            handleChange={(value) => {
                                data.aniType = value
                                data.aniTypeId = value.value
                                setAniTypeSelectValue([value])
                                setSampleListByAnimal([...sampleListByAnimal])
                            }}
                            />
                            ) : (
                                <span className="text-nowrap">{data.aniType ? data.aniType.label : ""}</span>
                              )}
                        </td>
                        <td>
                        {data.save ? (<InputSelect
                            classFormGroup="mb-0"
                            star={false}
                            placeholder="กรุณาเลือก"
                            idName={`method_${index}`}
                            classLabel="normal"
                            selectValue={data.method}
                            optionsList={methodList}
                            handleChange={(value) => {
                                data.method = value
                                data.testMethod = value.value
                                setMethodSelectValue([value])
                                setSampleListByAnimal([...sampleListByAnimal])
                            }}
                            />
                            ) : (
                                <span className="text-nowrap">{data.method ? data.method.label : ""}</span>
                              )}
                            </td>
                        <td>
                        {data.save ? (<InputText
                            classFormGroup="mb-0 "
                            type="text"
                            idName={`limsCaseNo_${index}`}
                            value={data.limsCaseId ? data.limsCaseId : ""}
                            star={false}
                            placeholder="โปรดระบุ"
                            handleChange={(value) => {
                                    data.limsCaseId = value
                                    setSampleListByAnimal([...sampleListByAnimal])
                                }
                            }
                    />
                    ) : (
                        <span className="text-nowrap">{data.limsCaseId}</span>
                      )}
                        </td>
                        <td>  {data.save ? (<UploadImage 
                                title=" " 
                                classLabel="mb-0" 
                                star={false} 
                                type="fileName" 
                                typeSave="base64"
                                onChange={(name, file) => {
                                    data.fileName = name
                                    data.fileData = file
                                    setSampleListByAnimal([...sampleListByAnimal])
                                }
                            } 
                            id={`imgfile_${index}`} 
                            position="left"
                            files={(data.fileName == null || data.fileName == "") ? "" : data.fileName} />
                            ) : (
                                <>
                                {(data.docLink) ?
                                    (<span className="link"
                                        onClick={() => {
                                            data.docLink ? window.open(data.docLink, '_blank').focus() : window.open("", '_blank').focus()
                                        }}
                                    >
                                        {data.fileName || "-"}
                                    </span>)
                                    :
                                    (<span className="text-nowrap">{data.fileName}</span>)
                                }
            
                                </>
                              )}
                        </td>
                        <td className="text-center">
                        {data.save ? (
                <button
                  className="btn btn-success pt-1 px-2"
                  onClick={() => {
                    data.save = false;
                    const itemList = sampleListByAnimal.map((item) => {
                       return { ...item, edit: false };
                    });
                    setSampleListByAnimal([...sampleListByAnimal])
                    setForm({...form, sampleListAnimal : sampleListByAnimal});
                  }}
                >
                  <img
                    className="img-fluid icon-check"
                    src="/svg/btn-check.svg"
                    alt="check"
                  />
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-add mr-2"
                    onClick={() => {
                       data.save = true;
                       setSampleListByAnimal([...sampleListByAnimal])
                    }}
                  >
                    <img
                      className="img-fluid icon-btn"
                      src="/svg/btn-edit.svg"
                      alt="edit"
                    />
                  </button>
                  <button
                    className="btn btn-del "
                    onClick={() => {
                        sampleListByAnimal.splice(index, 1);
                        setSampleListByAnimal([...sampleListByAnimal])
                        deleteSampleListByAnimal.push(data.id)
                        setDeleteSampleListByAnimal([...deleteSampleListByAnimal])
                        setForm({...form, sampleListAnimal : sampleListByAnimal, deleteSampleListAnimal: deleteSampleListByAnimal});
                    }}
                  >
                    <img
                      className="img-fluid icon-btn "
                      src="/svg/btn-del.svg"
                      alt="del"
                    />
                  </button>
                </>
              )}
                    </td>
            </tr>
        )
           
    }

    const renderTableFile = (data, index) => {
        return (
            <tr key={index}  >
                <td>{index + 1}</td>
                <td>
                    {data.id ?
                        <span className="link"
                            onClick={() => {
                                data.id ? window.open(data.file, '_blank').focus() : window.open("", '_blank').focus()
                            }}
                        >
                            {data.fileName || "-"}
                        </span>
                        :
                        data.fileName
                    }

                </td>
                <td>{data.detail_file ? data.detail_file : "-"}</td>
                <td>{helper.getDateFormat(data.date_file)}</td>
                <td className="text-center">
                        <button className="btn btn-success  pt-1 px-2"
                            onClick={async () => {
                                fileSave(formFile)
                            }}>
                            <img className="img-fluid icon-check" src="/svg/btn-check.svg" alt="check" />
                        </button>
                    </td>
            </tr>
        )
    }

    const renderAddFile = () => {
        return (
            <>
                <tr>
                    <td>{form.data_file && (form.data_file.length + 1)}</td>
                    <td>
                        <UploadImage title=" " classLabel="normal mt-2" star={false} type="fileName" typeSave="base64"
                            onChange={(name, file) => setFormFile({ ...formFile, file: file, fileName: name })} id="imgfile" position="left"
                            files={(formFile.fileName == null || formFile.fileName == "") ? "" : formFile.fileName} />
                    </td>

                    <td >
                        <InputText
                            classFormGroup="mb-0 "
                            type="text"
                            idName="count"
                            value={formFile.detail_file ? formFile.detail_file : ""}
                            star={false}
                            placeholder="โปรดระบุ"
                            handleChange={(value) => setFormFile({ ...formFile, detail_file: value })}

                        />
                    </td>

                    <td>{helper.getDateFormat(formFile.date_file)}</td>
                    <td className="text-center">
                        <button className="btn btn-success  pt-1 px-2"
                            onClick={async () => {
                                fileSave(formFile)
                            }}>
                            <img className="img-fluid icon-check" src="/svg/btn-check.svg" alt="check" />
                        </button>
                    </td>
                </tr>
            </>
        )
    }


    return (
        <div className="bg-white p-4 ">
            <BoxCard classNameBox="mt-3" title="2. ผลการตรวจทางห้องปฏิบัติการ">
                <p className="bold font14">2.1  ผลการตรวจรายฟาร์ม/โรงเรือน</p>
                <hr />
                {form.barn && form.barn.length > 0 ?
                    form.barn.map((item, i) => (
                        <div key={i} className="pl-0 pr-0">
                            <Row className="border mt-3">
                                <Col lg={2} md={2} sm={12} xs={12} className="d-flex align-items-center">
                                    <div className="pt-sm-1 pt-md-3 pt-lg-3  w-100">
                                        <InputText type="text" title="โรงเรือน" idName="nameHouse"
                                            star={false} disabled={true}
                                            value={item.nameHouse ? item.nameHouse : ""}
                                            placeholder="โปรดระบุ" classLabel="normal"
                                        />
                                    </div>
                                </Col>

                                <Col lg={2} md={2} sm={12} xs={12} className="d-flex align-items-center">
                                    <div className="pt-sm-1 pt-md-3 pt-lg-3 w-100">
                                        <InputText type="text" title="เลขเคสรับตัวอย่าง LIMS" idName="caseLims"
                                            star={false} disabled={true}
                                            value={item.caseLims ? item.caseLims : ""}
                                            placeholder="โปรดระบุ" classLabel="normal"

                                        />
                                    </div>
                                </Col>

                                <Col lg={2} md={2} sm={12} xs={12} className="d-flex align-items-center">
                                    <div className="pt-sm-1 pt-md-3 pt-lg-3  w-100">
                                        <InputSelect
                                            title="ชนิดสัตว์" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                            selectValue={item.animalTypeSelectValue}
                                            optionsList={animalListData} disabled={true}

                                        />
                                    </div>
                                </Col>

                            </Row>

                            <KeepExample_Step2_1
                                        save={(data, barnId) => {
                                            
                                            if  (form.step2_barn.length > 0) {
                                                var elementPos = form.step2_barn.map(function(x) {return x.barnId; }).indexOf(barnId);
                                                if  (elementPos != -1) {
                                                    form.step2_barn.splice(elementPos, 1)
                                                    form.step2_barn.push(data)
                                                    setForm({ ...form, step2_barn: form.step2_barn });
                                                } else {
                                                    form.step2_barn.push(data)
                                                    setForm({ ...form});
                                                }
                                            } else {
                                                form.step2_barn.push(data)
                                                setForm({ ...form});
                                            }
 
                                        }}
                                        dataAll={item.result}
                                        barnId={item.id}
                                        methodListData={methodList}
                                    />
                        </div>
                    ))
                    : <div className="border text-center font14 mt-3 py-3">ไม่ได้ทำการส่งตัวอย่างผ่านระบบ LIMS</div>
                }

                <p className="bold font14 mt-3">2.2 ผลการตรวจรายตัว</p>
                <hr />
                <Col className="border mt-3 ">
                    <Row className="d-flex justify-content-between mt-3 pl-3 pr-3 pb-3">
                        <div className="d-flex text-gray font12 align-items-center">
                            <p className="mb-0"></p>
                        </div>
                        <div className=" ">
                            <button className="btn btn-add text-white" onClick={() => toAddFilePerAnimal()}>
                                <i className="fas fa-plus mr-1 "></i>เพิ่มไฟล์
                            </button>
                        </div>
                    </Row>
                    <Row className="p-2">
                        <div className="table-responsive">
                            <table className="table  table-striped table-bordered my-2 text-cente">
                                <thead>
                                    <tr className="text-center" >
                                        <th scope="col">ลำดับ</th>
                                        <th scope="col">รหัสสัตว์</th>
                                        <th scope="col">ชื่อ</th>
                                        <th scope="col">ชนิดสัตว์</th>
                                        <th scope="col">วิธีการทดสอบ</th>
                                        <th scope="col">เลขเคสรับตัวอย่าง LIMS</th>
                                        <th scope="col-2" >ใบตอบผล</th>
                                        <th scope="col">จัดการ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {renderTableAnimal(form.data_setAnimal)} */}
                                    {/* {form.data_setAnimal && form.data_setAnimal.length > 0 ?
                                        form.data_setAnimal.map((item, i) => {
                                            return renderTableAnimal(item, i)
                                        })
                                        : null
                                    }
                                    {form.data_setAnimal && form.data_setAnimal.length == 0 &&
                                        <DataTableNodata colSpan={8} />
                                    } */}
                                    {sampleListByAnimal?.length > 0 ? (
                                        sampleListByAnimal.map((item, i) => {
                                            return renderTableAnimal(item, i);
                                        })
                                    ) : (
                                        <DataTableNodata colSpan={8} />
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </Col>

                <p className="bold font14 mt-3">2.3 แนบไฟล์ผลห้องปฏิบัติการ</p>
                <hr />
                <Col className="border mt-3 ">
                    <Row className="d-flex justify-content-between mt-3 pl-3 pr-3 pb-3">
                        <div className="d-flex text-gray font12 align-items-center">
                            <p className="mb-0">*อัปโหลดไฟล์กรณีส่งผลและรับผลผ่านระบบ LIMS</p>
                        </div>
                        <div className=" ">
                            <button className="btn btn-add text-white" onClick={() => toAddFile()}
                                disabled={form.checkAddFile}
                            >
                                <i className="fas fa-plus mr-1 "></i>เพิ่มไฟล์
                            </button>
                        </div>
                    </Row>

                    <Row className=" pl-3 pr-3 pb-3">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered my-2 ">
                                <thead>
                                    <tr>
                                        <th scope="col">ลำดับ</th>
                                        <th scope="col">อัปโหลดไฟล์</th>
                                        <th scope="col">คำอธิบาย</th>
                                        <th scope="col">วันที่อัปโหลด</th>
                                        <th className="text-center" scope="col">จัดการ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {form.data_file && form.data_file.length > 0 ?
                                        form.data_file.map((item, i) => {
                                            return renderTableFile(item, i)
                                        })
                                        : null
                                    }
                                    {form.checkAddFile && renderAddFile(form.data_file)}
                                    {form.data_file && form.data_file.length == 0 && !form.checkAddFile &&
                                        <DataTableNodata colSpan={5} />
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </Col>

            </BoxCard>
        </div>
    )
}

export default KeepExample_Step2
