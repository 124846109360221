import React, { useState, useEffect } from "react";
import * as helper from "../utils/helper";
import { useSelector } from 'react-redux';
import BoxCard from "./BoxCard";
import InputText from "./InputText";
import InputDatePicker from "./InputDatePicker";
import UploadImage from "./UploadImage";
import DataTableNodata from "./DataTableNodata";
import Table from 'react-bootstrap/Table'

function DiseaseReport_Step4({ save, dataAll, modeGet }) {
  const user = useSelector(state => state.login.result)

  const initialForm = {
    data_file: [],
    del_file: [],
    checkAddFile: false,
  };
  const [form, setForm] = useState(initialForm);

  const initInvalidMsg = {
    date_disease: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const initialFormFile = {
    url: "",
    file: "",
    detail_file: "",
    date_disease: "",
    fileName: "",
  };
  const [formFile, setFormFile] = useState(initialFormFile);

  useEffect(async () => {
    if (modeGet == "dsGetById") {
      await getDataById();
    }
  }, [user])

  const getDataById = () => {
    setForm(dataAll)
  }

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };


  const validate = () => {
    let validated = true;

    if (formFile.date_disease == "") {
      addInvalid("date_disease", "กรุณาเลือกวันที่พบโรค");
      validated = false;
    }

    return validated;
  }

  const toAddFile = () => {
    setForm({ ...form, checkAddFile: true })
    setFormFile({ ...formFile, date_disease: "", detail_file: "", file: "", fileName: "" })
  };

  const removeImage = (index, item) => {
    let delFile = []
    form.data_file.splice(index, 1);
    if (item.id) {
      delFile.push(item.id)
    }
    setForm({ ...form, data_file: form.data_file, del_file: delFile });
  }

  const fileSave = (data) => {
    if (validate()) {
      let arrayFile = [...form.data_file];
      arrayFile.push({
        url: "",
        file: data.file,
        fileName: data.fileName,
        detail_file: data.detail_file,
        date_disease: data.date_disease,
      })
      setForm({ ...form, data_file: arrayFile, checkAddFile: false });
      toSave()
    }
  }

  const buttonAddFile = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button className="btn btn-add text-white" onClick={() => toAddFile()}
          disabled={form.checkAddFile}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มไฟล์
        </button>
      </div>
    );
  };


  const renderTableFile = (data, index) => {
    return (
      <tr key={index}  >
        <td>{index + 1}</td>
        <td>
          {
            data.id ?
              <span className="link"
                onClick={() => {
                  data.id ? window.open(data.file, '_blank').focus() : window.open("", '_blank').focus()
                }}
              >
                {data.fileName}
              </span>
              :
              data.fileName
          }

        </td>
        <td>{data?.detail_file}</td>
        <td>{helper.getDateFormat(data?.date_disease)}</td>
        <td className="text-center">
          <button className="btn btn-del " onClick={() => removeImage(index, data)}>
            <img className="img-fluid icon-btn " src="/svg/btn-del.svg" alt="del" />
          </button>
        </td>
      </tr>
    )
  }

  useEffect(async () => {
    await toSave()
  }, [form])

  const toSave = () => {
    save(form)
  }


  const renderAddFile = () => {
    return (
      <tr>
        <td>{form.data_file.length + 1}</td>
        <td>
          <UploadImage title=" " classLabel="normal mt-2" star={false} type="fileName" typeSave="base64" id="imgfile" position="left"
            files={(formFile.fileName == null || formFile.fileName == "") ? "" : formFile.fileName}
            onChange={(name, file) => { setFormFile({ ...formFile, file: file, fileName: name }) }}
          />
        </td>

        <td >
          <InputText
            classFormGroup="mb-0 "
            type="text"
            idName="count"
            value={formFile.detail_file}
            star={false}
            placeholder="โปรดระบุ"
            handleChange={(value) => setFormFile({ ...formFile, detail_file: value })}
          />
        </td>

        <td>
          <InputDatePicker
            classFormGroup="mb-0"
            classLabel="normal"
            placeholder="วัน/เดือน/ปี"
            value={formFile.date_disease}
            star={false}
            handleChange={(value) =>
              setFormFile({ ...formFile, date_disease: value })
            }
            handleInvalid={() => removeInvalid("date_disease")} invalid={invalid.date_disease}
          />
        </td>
        <td className="text-center">
          <button className="btn btn-success  pt-1 px-2"
            onClick={async () => {
              fileSave(formFile)
            }}>
            <img className="img-fluid icon-check" src="/svg/btn-check.svg" alt="check" />
          </button>
        </td>
      </tr>
    )

  }

  return (
    <div className=" bg-white p-4 ">
      <BoxCard title="ข้อมูลรอยโรค" classNameBox="" headRight={buttonAddFile()}>
        <div className="">
          <Table className="table table-striped table-bordered my-2 ">
            <thead>
              <tr>
                <th scope="col">ลำดับ</th>
                <th scope="col">อัปโหลดไฟล์</th>
                <th scope="col">คำอธิบาย</th>
                <th scope="col">วันที่พบรอยโรค</th>
                <th className="text-center" scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              {form.data_file && form.data_file.length > 0 ?
                form.data_file.map((item, i) => {
                  return renderTableFile(item, i)
                })
                : null
              }
              {form.checkAddFile && renderAddFile(form.data_file)}
              {form.data_file && form.data_file.length == 0 && !form.checkAddFile &&
                <DataTableNodata colSpan={5} />
              }
            </tbody>
          </Table>

        </div>
      </BoxCard>
    </div>
  )
}


export default DiseaseReport_Step4
