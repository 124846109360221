import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Row, Col } from "react-bootstrap";
import ModalMap from "../Modal.Map";
import { useLocation } from "react-router-dom";
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";
import { useMasterData } from "../../components/hooks/useMasterData";

function ModalDistrict({ show, close, save, mode, idEdit }) {
  const user = useSelector((state) => state.login.result);
  const initialForm = {
    district_name: "",
    district_name_en: "",
    district_id: "",
    status: "",
    createDate: "",
    updateDate: "",

    latitude: "",
    longitude: "",
    modeMap: "add",

    limit: 100,
    page: 1,
    provinceId: "",
    provinceName: "",
    provinceStatusId: "",
    sortDir: "DESC",
    sortBy: "id",

    checkShow: false,
  };
  const initInvalidMsg = {
    sample_name: "",
    status: "",
  };
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const initShowMap = false;
  const selectProvinceList = [];
  const initStatusSelectValue = [];
  const initProvinceIdSelectValue = [];

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showMap, setShowMap] = useState(initShowMap);
  const [form, setForm] = useState(initialForm);
  const [provinceList, setProvinceList] = useState(selectProvinceList);
  const [invalid, setInvalid] = useState(initInvalidMsg);
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const [statusList, setStatusList] = useState(selectStatusList);
  const [provinceIdSelectValue, setProvinceIdSelectValue] = useState(
    initProvinceIdSelectValue
  );

  useMasterData("province", provinceList, setProvinceList);

  useEffect(async () => {
    if (mode === "add") {
      setForm(initialForm);
      setStatusSelectValue([]);
      setProvinceIdSelectValue([]);
    }
  }, [mode]);

  useEffect(async () => {
    await getProvince();
  }, []);

  useEffect(async () => {
    if (mode === "edit" && !form.checkShow) {
      await getDistrictById(idEdit);
    }
  }, [mode, idEdit, show, form.province_id, form.status]);

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if (form.province_name === "") {
      addInvalid("province_name", "โปรดระบุข้อมูลจังหวัด");
      validated = false;
    }

    if (form.status === "") {
      addInvalid("status", "กรุณาเลือกสถานะ");
      validated = false;
    }

    return validated;
  };

  const handleClose = () => {
    close();
    setInvalid(initInvalidMsg);
    setForm(initialForm);
    setStatusSelectValue([]);
  };

  const toConfrim = async () => {
    if (validate()) {
      await save({ district: form, mode: mode });
      handleClose();
    }
  };

  const getDistrictById = async (id) => {
    let dataDstrc;
    try {
      const response = await API.getDistrictsById(id);
      const data = await response.data;
      if (response.status === 200) {
        dataDstrc = data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (mode === "edit" && dataDstrc.statusId) {
      let statusSelect = await statusList.find(
        (item) => item.value === dataDstrc.statusId
      );
      setStatusSelectValue(statusSelect);
      let provinceSelect = await provinceList.find(
        (item) => item.value === dataDstrc.provinceId
      );
      setProvinceIdSelectValue(provinceSelect);
    }

    setForm({
      ...form,
      district_id: dataDstrc.id,
      district_name: dataDstrc.name,
      district_name_en: dataDstrc.nameEn,
      provinceId: dataDstrc.provinceId,
      status: dataDstrc.statusId,
      createDate: dataDstrc.createDate,
      updateDate: dataDstrc.updateDate,
    });
  };

  const toMap = () => {
    setShowMap(true);
    if (location.pathname.includes("edit")) {
      setForm({ ...form, modeMap: "edit" });
    } else {
      setForm({ ...form, modeMap: "add" });
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince(
        "",
        "1",
        form.provinceId,
        form.page,
        "100",
        "",
        form.sortBy,
        form.sortDir
      );
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal show={show} size="lg" onHide={() => handleClose()} centered>
      <Modal.Header closeButton className="pb-2 ">
        <Modal.Title className="mt-2">
          <h5>
            <strong>เพิ่มข้อมูลอำเภอ</strong>
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <div className="">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item tab">
              <a
                className="nav-link active"
                id="data-tab"
                data-toggle="tab"
                href="#data"
                role="tab"
                aria-controls="data"
                aria-selected="true"
              >
                ข้อมูลพื้นฐาน
              </a>
            </li>
            <li
              className={
                invalid.status ? "nav-item-invalid tab" : "nav-item tab"
              }
            >
              <a
                className="nav-link"
                id="status-tab"
                data-toggle="tab"
                href="#status"
                role="tab"
                aria-controls="status"
                aria-selected="false"
              >
                สถานะ
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active card-tab"
              id="data"
              role="tabpanel"
              aria-labelledby="data-tab"
            >
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่ออำเภอภาษาไทย"
                    idName="district_name"
                    value={form.district_name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({
                        ...form,
                        district_name: value,
                        checkShow: true,
                      })
                    }
                    handleInvalid={() => removeInvalid("district_name")}
                    invalid={invalid.district_name}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่ออำเภอภาษาอังกฤษ"
                    idName="district_name_en"
                    value={form.district_name_en}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({
                        ...form,
                        district_name_en: value,
                        checkShow: true,
                      })
                    }
                    handleInvalid={() => removeInvalid("district_name_en")}
                    invalid={invalid.district_name_en}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={provinceIdSelectValue}
                    optionsList={provinceList}
                    handleSelectValue={(value) =>
                      setProvinceIdSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, provinceId: value.value })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <p className="mb-0">
                    {form.latitude && `ละติจูด : ${form.latitude}`}
                  </p>
                  <p>{form.longitude && ` ลองจิจูด : ${form.longitude}`}</p>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <button
                    className="btn btn-sky d-flex align-items-center"
                    onClick={() => toMap()}
                  >
                    <img className="img-fluid mr-1" src="/svg/pin.svg" alt="" />
                    เลือกแผนที่
                  </button>
                </Col>
              </Row>
              {showMap && (
                <ModalMap
                  show={showMap}
                  modeMap={form.modeMap}
                  lat={form.latitude}
                  lon={form.longitude}
                  close={() => setShowMap(false)}
                  save={(value) =>
                    setForm({
                      ...form,
                      latitude: value.latitude,
                      longitude: value.longitude,
                    })
                  }
                />
              )}
            </div>
            <div
              className="tab-pane fade card-tab"
              id="status"
              role="tabpanel"
              aria-labelledby="status-tab"
            >
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={statusList}
                    handleSelectValue={(value) =>
                      setStatusSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, status: value.value, checkShow: true })
                    }
                    handleInvalid={() => removeInvalid("status")}
                    invalid={invalid.status}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                    <p className="mr-4">วันที่บันทึกข้อมูล</p>
                    <p>
                      {form.createDate
                        ? helper.momentDate(form.createDate, "th", "short")
                        : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                    <p className="mr-3">วันที่ปรับปรุงข้อมูล</p>
                    <p>
                      {form.updateDate
                        ? helper.momentDate(form.updateDate, "th", "short")
                        : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="mr-2 mt-3 mb-2">
          <div className="text-right py-2">
            <button
              className="btn btn-danger px-4 mr-2"
              onClick={() => handleClose()}
            >
              ยกเลิก
            </button>
            <button
              className="btn btn-success px-4"
              disabled={user?.userGroup.mupdate === "n" && mode === "edit"}
              onClick={() => toConfrim()}
            >
              บันทึก
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalDistrict;
