import { all, takeEvery, put } from "redux-saga/effects";
import * as helper from "../../utils/helper";
import * as actionTypes from "../actions/notifyTypes";
import * as API from "../../utils/apis";
import { useSelector } from 'react-redux';

export default function* diseaseNotifySaga() {
  yield all([
    takeEvery(actionTypes.DISEASE_INFORM, watchDiseaseInform),
    takeEvery(actionTypes.DISEASE_REPORT, watchDiseaseReport),
  ]);
}

function* watchDiseaseInform({ payload }) {
  yield put({ type: actionTypes.DISEASE_INFORM_FETCHING });

  const params = {
    sortBy: "id",
    sortDir: "ASC",
    page: 1,
    infCode: "",
    person: "",
    foundDateBegin: "",
    foundDateEnd: "",
    createDateBegin: "",
    createDateEnd: "",
    provinceId: "",
    amphurId: "",
    tambonId: "",
    animalTypeIds: "",
    statusId: 1,
  };

  try {
    const response = yield API.getReportDisease(params);
    const data = yield response.data;
    if (response.status == 200) {
      let informNotify = data.content.length
      yield put({ type: actionTypes.DISEASE_INFORM_SUCCESS, payload: informNotify });
    } else {
      console.log("response===>", response);
    }
  } catch (e) {
    yield put({ type: actionTypes.DISEASE_INFORM_FAILED, payload: e });
  }
}

function* watchDiseaseReport(payload) {
  const params = {
    sortBy: "id",
    sortDir: "ASC",
    page: 1,
    orgId: "",
    obCode: "",
    caseCode: "",
    farmId: "",
    ownerName: "",
    foundDateBegin: "",
    foundDateEnd: "",
    createDateBegin: "",
    createDateEnd: "",
    zoneId: "",
    provinceId: "",
    amphurId: "",
    tambonId: "",
    diseaseTypeId: "",
    foundDiseaseTypeId: "",
    animalTypeId: "",
    statusId:1,
  };
  try {
    const response = yield API.getDiseaseStatement(params);
    const data = yield response.data;
    if (response.status == 200) {
      let reportNotify = data.content.length
      yield put({ type: actionTypes.DISEASE_REPORT_SUCCESS, payload: reportNotify });
    } else {
      console.log("response===>", response);
    }
  } catch (e) {
    yield put({ type: actionTypes.DISEASE_REPORT_FAILED, payload: e });
  }
}