import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";
import UploadFileArray from "../../components/UploadFileArray";
import ReactQuill from 'react-quill'

import '../../assets/css/style.css'
import 'react-quill/dist/quill.snow.css'

function ModalWarning({ show, close, save, mode, idEdit }) {
    const user = useSelector(state => state.login.result);
    const initialForm = {
        Warning_id: "",
        description: "",
        publishDate: "",
        title: "",
        status: "",
        createDate: "",
        updateDate: "",
        createByName: "",
        updateByName: "",

        checkShow: false,

        filesPreview: [],
        filesDelete: [], //ลบในหน้า ui
        filesChooseDel: [], //ลบเพื่อส่งไป api
    };
    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];
    const initInvalidMsg = {
        status: "",
        description: "",
        title: "",
    };
    const initStatusSelectValue = [];

    const dispatch = useDispatch();
    const history = useHistory();

    const [form, setForm] = useState(initialForm);
    const [invalid, setInvalid] = useState(initInvalidMsg);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [statusSelectValue, setStatusSelectValue] = useState(initStatusSelectValue);
    const [statusList, setStatusList] = useState(selectStatusList);

    useEffect(async () => {
        if (mode === "add") {
            setForm(initialForm)
            setStatusSelectValue([])
        }
    }, [mode])

    useEffect(async () => {
        if (mode === "edit" && !form.checkShow) {
            await getWarningById(idEdit)
        }
    }, [mode, idEdit, show, form.title, form.newCategoryId, form.description, form.publishDate, form.status])

    const getWarningById = async (id) => {
        try {
            const response = await API.getWarningById(id);
            const dataRes = await response.data;
            if (response.status === 200) {
                if (dataRes) {
                    getDataToForm(dataRes)
                }
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }))

            }
            console.log(error)
        }
    }

    const getDataToForm = async (dataWarning) => {

        if (dataWarning.warning.statusId) {
            let statusSelect = await statusList.find(item => item.value === dataWarning.warning.statusId);
            setStatusSelectValue(statusSelect);
        }

        const arrayFile = [];
        if (dataWarning.warningFiles && dataWarning.warningFiles.length > 0) {
            dataWarning.warningFiles.map((item, i) => {
                arrayFile.push({
                    "id": item.id ? item.id : "",
                    "name": item.name,
                    "base64": item.url,
                })
            })
        }

        setForm({
            ...form,
            warning_id: dataWarning.warning.id,
            title: dataWarning.warning.title,
            description: dataWarning.warning.description,
            status: dataWarning.warning.statusId,
            createDate: dataWarning.warning.createDate,
            updateDate: dataWarning.warning.updateDate,
            createByName: dataWarning.warning.createByName,
            updateByName: dataWarning.warning.updateByName,
            filesPreview: arrayFile,
        })
    }


    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setStatusSelectValue([])
    }

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;

        if (form.status === "") {
            addInvalid("status", "กรุณาเลือกสถานะ");
            validated = false;
        }

        if (form.title === "") {
            addInvalid("title", "โปรดระบุหัวข้อ");
            validated = false;
        }

        if (form.description == "" || form.description == "<p><br></p>") {
            addInvalid("description", "กรุณาระบุรายละเอียด");
            validated = false;
        }

        return validated;
    };

    const toConfirm = async () => {
        if (validate()) {
            await save({ warning: form, mode: mode });
            handleClose();
        }
    };

    const modules = {
        toolbar: [
            ["bold", "italic", "underline"],
            [{ align: "center", }],
            [{ list: "ordered" }],
            ["link", "image",],
            ["clear"],
        ],
    };

    const formats = [
        "bold",
        "italic",
        "underline",
        'align',
        "list",
        "link",
        "image",
        "clear",
    ];

    const checkDeleteImg = (list, value) => {
        let check = false;
        list.map((item, i) => {
            if (item.checked_id === value) {
                check = true;
            }
        })
        return check;
    }

    const removeImage = async () => {
        let delFile = [];

        if (form.filesPreview && form.filesPreview.length > 0) {
            form.filesPreview.map(async (item, i) => {
                if (checkDeleteImg(form.filesDelete, i + 1)) {
                    await form.filesPreview.splice(i, form.filesDelete.length);
                }
            })
        }

        if (form.filesDelete && form.filesDelete.length > 0) {
            form.filesDelete.map(async (item, i) => {
                if (item.id) {
                    delFile.push(item)
                }
            })
        }

        setForm({ ...form, filesPreview: form.filesPreview, filesChooseDel: delFile, filesDelete: [] })
    }

    const handleSelectDeleteAll = () => {
        setIsCheckAll(!isCheckAll);
        let chooseAll = [];
        if (form.filesPreview.length > 0) {
            form.filesPreview.map((item, i) => {
                chooseAll.push({
                    checked_id: i + 1,
                    id: item.id ? item.id : ""
                })
            })
        }
        if (isCheckAll) {
            chooseAll = [];
        }
        setForm({ ...form, filesDelete: chooseAll })
    }



    const selectImgDel = async (e, imgId) => {
        let arrayChoose = []
        if (e.target.checked) {
            arrayChoose = [...form.filesDelete, {
                checked_id: e.target.id,
                id: imgId ? imgId : ""
            }];
            setForm({ ...form, filesDelete: arrayChoose })
        } else {
            if (form.filesDelete.length > 0) {
                form.filesDelete.forEach((item, i) => {
                    if (item.checked_id === e.target.id) {
                        form.filesDelete.splice(i, 1);
                    }
                    setIsCheckAll(!isCheckAll);
                })
                setForm({ ...form, filesDelete: form.filesDelete })
            }
        }
    }

    const renderImages = (dataFiles) => {
        return dataFiles.map((item, index) => {
            return (
                <Col key={index} lg={4} md={6} sm={12} xs={12} className="mt-1">
                    <Form.Check
                        className="check-img"
                        type="checkbox"
                        name={`img${index + 1}`}
                        id={index + 1}
                        onChange={(e) => selectImgDel(e, item.id)}
                        checked={!!form.filesDelete.find(check => check.checked_id === index + 1)}
                    />
                    <label htmlFor={index + 1} className="img-fixed">
                        <img src={item.base64} alt={item.name} className="img-fixed cursor-p" />
                    </label>
                </Col>
            )
        })
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => { handleClose() }}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่มเตือนภัยโรคระบาด</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className="nav-item tab">
                            <a className="nav-link" id="picture-tab" data-toggle="tab" href="#picture" role="tab" aria-controls="picture" aria-selected="false">รูปภาพ</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText
                                        type="text"
                                        title="หัวข้อ"
                                        idName="title"
                                        star={false}
                                        value={form.title}
                                        placeholder="กรุณาระบุ" classLabel="normal"
                                        handleChange={(value) =>
                                            setForm({ ...form, title: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("title")}
                                        invalid={invalid.title}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <span className=" mt-3 ">รายละเอียด</span>
                                    <ReactQuill
                                        className={invalid.description ? "invalid-error" : ""}
                                        placeholder="ระบุรายละเอียด"
                                        value={form.description || ''}
                                        modules={modules}
                                        formats={formats}
                                        onChange={(value) => {
                                            setForm({ ...form, description: value, checkShow: true })
                                        }}
                                        handleInvalid={() => removeInvalid("description")}
                                    />
                                    {invalid.description ? <div className="invalid-error">{invalid.description}</div> : ""}
                                </Col>

                            </Row>
                        </div>

                        <div className="tab-pane fade card-tab" id="picture" role="tabpanel" aria-labelledby="picture-tab">
                            <div>
                                <p className="mt-2 bold">อัปโหลดรูปภาพ</p>
                            </div>

                            <div className="d-flex justify-content-between">
                                <UploadFileArray
                                    topicFiles={form.topicFiles}
                                    filesPreview={form.filesPreview}
                                    typeSave="base64"
                                    onChange={(files, i) =>
                                        setForm({ ...form, filesPreview: files, filesDelete: [] })
                                    }
                                />

                                <div className="d-flex align-items-center">
                                    <Form.Check
                                        type="checkbox"
                                        name="selectDelAll"
                                        id="selectDelAll"
                                        label="เลือกทั้งหมด"
                                        className="mr-2"
                                        onChange={() => handleSelectDeleteAll()}
                                        checked={isCheckAll}
                                    />
                                    <button className="btn btn-danger" onClick={() => removeImage()}>
                                        <img className="img-fluid icon-btn " src="/svg/btn-del.svg" alt="del" />
                                    </button>
                                </div>
                            </div>

                            <div className="mt-3 mx-2">
                                <Row>
                                    {
                                        (form.filesPreview.length > 0) ?
                                            renderImages(form.filesPreview)
                                            :
                                            <Col className="border py-3 text-center font14">
                                                <span className="">ไม่มีรูป</span>
                                            </Col>
                                    }
                                </Row>
                            </div>

                        </div>

                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false}
                                        placeholder="กรุณาเลือก"
                                        idName="title"
                                        classLabel="normal"
                                        selectValue={statusSelectValue}
                                        optionsList={statusList}
                                        handleChange={(value) => {
                                            setStatusSelectValue([value])
                                            setForm({ ...form, status: value.value, checkShow: true })
                                        }
                                        }
                                        handleInvalid={() => removeInvalid("status")}
                                        invalid={invalid.status}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={3} sm={6} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mr-4">วันที่บันทึกข้อมูล</p>
                                    </div>
                                </Col>
                                <Col lg={10} md={9} sm={6} xs={12}>
                                    <div className="text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mb-0">{form.createDate ? helper.momentDate(form.createDate, "th", "short") : '-'}</p>
                                        <p>{form.createByName ? form.createByName : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={3} sm={6} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                                        <p className="mr-3" >วันที่ปรับปรุงข้อมูล</p>
                                    </div>
                                </Col>
                                <Col lg={10} md={9} sm={6} xs={12}>
                                    <div className="text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mb-0">{form.updateDate ? helper.momentDate(form.updateDate, "th", "short") : '-'}</p>
                                        <p>{form.updateByName ? form.updateByName : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                </div>
                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalWarning
