import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BoxHead from "../../components/BoxHead";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputGroupText from "../../components/InputGroupText";
import InputSelect from "../../components/InputSelect";
import InputMask from "../../components/InputMask";
import KeepExample_Step1 from "../../components/KeepExample_Step1";
import KeepExample_Step2 from "../../components/KeepExample_Step2";
import InputDatePicker from "../../components/InputDatePicker";
import ModalOrganizes from "../../components/Modal.Organizes";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import * as API from "../../utils/apis";
import withReactContent from "sweetalert2-react-content";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import ModalOutbreakSearch from "../../components/Modal.OutbreakSearch";
import ModalFarmSearch from "../../components/Modal.FarmSearch";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function Keep_Example_Save(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t, i18n } = useTranslation(["common", "footer"]);

  const initialForm = {
    dataAllById: {},
    mode: location.pathname.includes("save") ? "notGetByObId" : "",
    checkAddData: false,
    farmId: "",
    outbreakId: "",
    outbreakCode: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    caseCode: "",
    homeNo: "",
    phone: "",
    name: "",
    orgId: "",
    orgName: "",
    test_results: "",
    test_results_name: "",
    diseases: "",
    diseasesName: "",
    note: "",

    modeGet: "default",

    step1: {},
    step2: {},

    fm01: {},
  };
  const [form, setForm] = useState(initialForm);

  const initialFormJson = {
    animalId: {},
    diseaseTypeId: {},
    husbandry: {},
    animalPurpose: {},
    vaccineType: {},
  };
  // const [formJson, setFormJson] = useState(initialFormJson);

  const initInvalidMsg = {
    nameHouse: "",
    animalTypeName: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const initShowCaseIdSearch = false;
  const [showCaseIdSearch, setShowCaseIdSearch] =
    useState(initShowCaseIdSearch);

  const initShowOrgModal = false;
  const [showOrgModal, setShowOrgModal] = useState(initShowOrgModal);

  const initShowOutbreakSearch = false;
  const [showOutbreakSearch, setShowOutbreakSearch] = useState(
    initShowOutbreakSearch
  );

  const initShowFarmSearch = false;
  const [showFarmSearch, setShowFarmSearch] = useState(initShowFarmSearch);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  useMasterData("province", provinceList, setProvinceList);

  // =================

  const [houseSelectValue, setHouseSelectValue] = useState([]);
  const [houseList, setHouseList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const initTestResultsSelectValue = [];
  const [testResultsTypeSelectValue, setTestResultsValue] = useState(
    initTestResultsSelectValue
  );
  const selectTestResultsList = [
    { value: "1", label: "พบโรค" },
    { value: "2", label: "ไม่พบโรค" },
  ];
  const [testResultsList, setTestResultsList] = useState(selectTestResultsList);

  const [diseasesSelectValue, setDiseasesSelectValue] = useState([]);
  const [diseasesList, setDiseasesList] = useState([]);

  const [organizesSelectValue, setOrganizesSelectValue] = useState([]);
  const [organizesList, setOrganizesList] = useState([]);

  const [resultsSelectValue, setResultsSelectValue] = useState([]);
  const selectResultsList = [
    { value: "1", label: "ตรวจไม่ได้" },
    { value: "2", label: "ผลบวก(+)" },
    { value: "3", label: "ผลลบ(-)" },
  ];
  const [dataResultsList, setResultsList] = useState(selectResultsList);

  const [immuneOSelectValue, setImmuneOSelectValue] = useState([]);
  const selectImmuneOList = [
    { value: "1", label: "< 40" },
    { value: "2", label: "40" },
    { value: "3", label: "80" },
    { value: "4", label: "160" },
    { value: "5", label: "320" },
    { value: "6", label: "640" },
    { value: "7", label: "1280" },
    { value: "8", label: "2560" },
    { value: "9", label: "5120" },
    { value: "10", label: "> 5120" },
  ];
  const [dataImmuneOList, setImmuneOList] = useState(selectImmuneOList);

  const [immuneASelectValue, setImmuneASelectValue] = useState([]);
  const selectImmuneAList = [
    { value: "1", label: "< 40" },
    { value: "2", label: "40" },
    { value: "3", label: "80" },
    { value: "4", label: "160" },
    { value: "5", label: "320" },
    { value: "6", label: "640" },
    { value: "7", label: "1280" },
    { value: "8", label: "2560" },
    { value: "9", label: "5120" },
    { value: "10", label: "> 5120" },
  ];
  const [dataImmuneAList, setImmuneAList] = useState(selectImmuneOList);

  const [immuneA1SelectValue, setImmuneA1SelectValue] = useState([]);
  const selectImmuneA1List = [
    { value: "1", label: "< 40" },
    { value: "2", label: "40" },
    { value: "3", label: "80" },
    { value: "4", label: "160" },
    { value: "5", label: "320" },
    { value: "6", label: "640" },
    { value: "7", label: "1280" },
    { value: "8", label: "2560" },
    { value: "9", label: "5120" },
    { value: "10", label: "> 5120" },
  ];
  const [dataImmuneA1List, setImmuneA1List] = useState(selectImmuneA1List);

  const [arrayFiles, setArrayFiles] = useState([]);

  const [onSave, setOnSave] = useState(false);
  const [checkSave, setCheckSave] = useState(false);

  const initInvalidMsgBorn = {
    barnId: false,
    animalTypeId: false,
    animalCode: false,
    sampleNo: false,
  };
  const [invalidBorn, setInvalidBorn] = useState(initInvalidMsgBorn);
  // =================

  useEffect(async () => {
    // await getMasterJson()
    await getProvince();
    await getAnimal();
    await getDiseaseType();
  }, []);

  useEffect(async () => {
    if (location.pathname.includes("save") && form.outbreakId) {
      getOutbreakById(form.outbreakId);
    } else if (location.pathname.includes("save") && props.match.params.id) {
      getOutbreakById(props.match.params.id);
    }
  }, [
    form.outbreakId,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
  ]);

  useEffect(async () => {
    if (
      location.pathname.includes("edit") ||
      location.pathname.includes("duplicate")
    ) {
      getSampleById(props.match.params.id);
    }
  }, [
    user,
    location.pathname,
    form.provinceId || form.districtId || form.subDistrictId || form.villageId,
  ]);

  useEffect(() => {
    if (onSave) {
      toSaveKeepEx();
    }
  }, [onSave]);

  const checkOnSave = async (value) => {
    if (value === false) {
      setCheckSave(false);
    } else {
      setOnSave(value);
    }
  };

  // const getMasterJson = async () => {
  //     const params = [
  //         "animalType",
  //         "diseaseType",
  //         "husbandry",
  //         "animalPurpose",
  //         "vaccineType",
  //     ];
  //     try {
  //         const response = await API.getMasterJson(params);
  //         const data = await response.data;
  //         if (response.status == 200) {
  //             setFormJson({
  //                 ...formJson,
  //                 animalId: data.animalType,
  //                 diseaseTypeId: data.diseaseType,
  //                 husbandry: data.husbandry,
  //                 animalPurpose: data.animalPurpose,
  //                 vaccineType: data.vaccineType,
  //             })
  //         }
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince();
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id);
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType();
      const data = await response.data;
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseasesList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOutbreakById = async (id) => {
    dispatch(loading(true));
    let dataRes;
    try {
      const response = await API.getOutbreakById(id);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.fm01 && dataById.fm01.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.fm01.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.fm01.provinceId);
        }
      }

      if (dataById.fm01 && dataById.fm01.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.fm01.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.fm01.amphurId);
        }
      }

      if (dataById.fm01 && dataById.fm01.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.fm01.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.fm01.tambonId);
        }
      }

      if (dataById.fm01 && dataById.fm01.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.fm01.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.fm01 && dataById.fm01.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.fm01.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      const houseArray = [];
      if (dataById.fm01Response && dataById.fm01Response.fm0102) {
        if (
          dataById.fm01Response.fm0102 &&
          dataById.fm01Response.fm0102.barns.length > 0
        ) {
          let barn = dataById.fm01Response.fm0102.barns;
          barn.map((barns, b) => {
            houseArray.push({
              barnId: barns.id,
              name: barns.name,
              desc: [],
            });
          });
        }
      }

      let arrayHouseList = [];
      if (houseArray && houseArray.length > 0) {
        houseArray.forEach((house) =>
          arrayHouseList.push({ value: house.barnId, label: house.name })
        );
      }
      setHouseList(arrayHouseList);
      setForm({
        ...form,
        mode: "add",
        dataAllById: dataById,
        outbreakCode: dataById.outbreak.obCode,
        farmId: dataById.fm01.farmId,
        caseCode: dataById.fm01.caseCode,

        provinceId: dataById.fm01.provinceId,
        districtId: dataById.fm01.amphurId,
        subDistrictId: dataById.fm01.tambonId,
        villageId: dataById.fm01.mooId,
        homeNo: dataById.fm01.address,

        phone: dataById.fm01.ownerTel,
        name: dataById.fm01.ownerName,
        orgId: dataById.fm01.orgId,
        orgName: dataById.fm01.orgName,

        house: houseArray,

        fm01: dataById.fm01,
        checkAddData: true,
      });
    }
  };

  const getFarmById = async (id) => {
    try {
      const response = await API.getFarmRegistrationById(id);
      const dataRes = await response.data;
      if (response.status == 200) {
        let arrayHouseList = [];
        if (dataRes && dataRes.barn.length > 0) {
          dataRes.barn.forEach((house) =>
            arrayHouseList.push({ value: house.id, label: house.name })
          );
        }
        setHouseList(arrayHouseList);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getSampleById = async (id) => {
    let dataRes;
    try {
      const response = location.pathname.includes("edit")
        ? await API.getSampleById(id)
        : await API.getCopySampleById(id);
      if (response.status == 200) {
        dataRes = await response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    let arrayFiles = [];
    if (dataRes.sampleFiles && dataRes.sampleFiles.length > 0) {
      dataRes.sampleFiles.map((item, i) => {
        arrayFiles.push({
          id: item.id,
          data: item.url,
          name: item.name,
          description: item.description,
          date: item.date,
          url: item.url,
          save: false,
        });
      });
    }
    setArrayFiles(arrayFiles);

    if (dataRes) {
      const dataById = dataRes;
      if (dataById.fm01 && dataById.fm01.farmId) {
        getFarmById(dataById.fm01.farmId);
      }

      if (dataById.fm01 && dataById.fm01.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.fm01.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.fm01.provinceId);
        }
      }

      if (dataById.fm01 && dataById.fm01.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.fm01.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.fm01.amphurId);
        }
      }

      if (dataById.fm01 && dataById.fm01.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.fm01.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.fm01.tambonId);
        }
      }

      if (dataById.fm01 && dataById.fm01.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.fm01.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.fm01 && dataById.fm01.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.fm01.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      if (dataById.sampleTest && dataById.sampleTest.diseaseTypeId) {
        let diseaseTypeIdSelect = await diseasesList.find(
          (item) => item.value == dataById.sampleTest.diseaseTypeId
        );
        setDiseasesSelectValue(diseaseTypeIdSelect);
      }

      if (dataById.sampleTest && dataById.sampleTest.statusId) {
        let testResultsSelect = testResultsList.find(
          (item) => item.value == dataById.sampleTest.statusId
        );
        setTestResultsValue(testResultsSelect);
      }

      if (dataById) {
        setForm({
          ...form,
          dataAllById: dataById,

          modeGet: "getById",
          farmId: dataById.fm01.farmId,
          outbreakId: dataById.outbreak.id,
          outbreakCode: dataById.outbreak.obCode,
          caseCode: dataById.fm01.caseCode,
          provinceId: dataById.fm01.provinceId,
          districtId: dataById.fm01.amphurId,
          subDistrictId: dataById.fm01.tambonId,
          villageId: dataById.fm01.mooId,
          homeNo: dataById.fm01.address,
          phone: dataById.fm01.ownerTel,
          name: dataById.fm01.ownerName,
          orgId: dataById.fm01.orgId,
          orgName: dataById.fm01.orgName,
          checkAddData: true,
          test_results: dataById.sampleTest.statusId,
          test_results_name: dataById.sampleTest.test_results_name,
          diseases: dataById.sampleTest.diseaseTypeId,
          diseasesName: dataById.sampleTest.diseasesName,
          note: dataById.sampleTest.remark,

          step1: dataRes,

          step2: {
            step1Ex: dataById.sampleAnimal,
            barn_Step1: dataById.sampleBarn,
            step2File: dataById.sampleTestFiles,
            sampleListAnimal: dataById.sampleResultAnimal,
          },
        });
      }
    }
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "/passive_surv/keep-example", name: "เก็บตัวอย่าง/ผลตรวจ" },
    {
      path: "title",
      name: "บันทึกการทดสอบโรค (เก็บตัวอย่าง) และผลการตรวจทางห้องปฏิบัติการ",
    },
  ];

  const toCancel = () => {
    history.push("/passive_surv/keep-example");
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, provinceId: value.value, zipcode: "" });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, districtId: value.value, zipcode: "" });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value, zipcode: value.zipcode });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const setOrganizes = (data) => {
    setForm({ ...form, orgId: data.value, orgName: data.label });
  };

  const toSaveKeepEx = async () => {
    if (form.mode == "notGetByObId") {
      MySwal.fire({
        text: "กรุณาเลือกเลข Outbreak ID",
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
    } else {
      setCheckSave(true);
    }

    if (onSave) {
      const dataById = form.dataAllById;
      const dataStep1 = form.step1;
      const dataStep2 = form.step2;
      const dataStep2barn = form.step2.step2_barn;

      // console.log("data-step1", dataStep1);

      // const barnStep1 = []
      // let barnAnimalStep1 = []
      let dataBarnStep1 = JSON.parse(JSON.stringify(dataStep1?.barn));
      if (dataBarnStep1 && dataBarnStep1.length > 0) {
        let brn = [];
        dataBarnStep1.map((itemBarn, indexBarn) => {
          if (
            itemBarn.sampleBarnAnimal &&
            itemBarn.sampleBarnAnimal.length > 0
          ) {
            let anim = [];
            itemBarn.sampleBarnAnimal.map((itemAnimal, indexAnimal) => {
              if (!itemAnimal.name && itemAnimal.name.length <= 0) {
                itemAnimal.name = "ตัวที่ " + (indexAnimal + 1);
              }
              if (
                itemAnimal.sampleBarnAnimalDtl &&
                itemAnimal.sampleBarnAnimalDtl.length > 0
              ) {
                let dtl = [];
                // console.log("dtl", itemAnimal.sampleBarnAnimalDtl);
                itemAnimal.sampleBarnAnimalDtl.forEach((itemDtl, indexDtl) => {
                  dtl.push({
                    id: itemDtl.id ? itemDtl.id : "",
                    keepDate: itemDtl.keepDate,
                    sampleAmt: itemDtl.sampleAmt,
                    sampleBarnAnimalId: itemDtl.sampleBarnAnimalId,
                    sampleKeepTypeId: itemDtl.sampleKeepTypeId,
                    sampleNo: itemDtl.sampleNo,
                  });
                });

                itemAnimal.sampleBarnAnimalDtl = dtl;
              }

              anim.push({
                id: itemAnimal.id ? itemAnimal.id : "",
                name: itemAnimal.name,
                animalCode: itemAnimal.animalCode,
                id: itemAnimal.id ? itemAnimal.id : "",
                immuneA: itemAnimal.immuneA,
                immuneA1: itemAnimal.immuneA1,
                immuneO: itemAnimal.immuneO,
                resultsStatus: itemAnimal.resultsStatus,
                sampleBarnAnimalDtl: itemAnimal.sampleBarnAnimalDtl,
              });
            });
            itemBarn.sampleBarnAnimal = anim;
          }
          brn.push({
            id: itemBarn.id ? itemBarn.id : "",
            animalAmt: itemBarn.animalAmt,
            animalTypeId: itemBarn.animalTypeId,
            barnId: itemBarn.barnId,
            limsCaseId: itemBarn.labId,
            sampleBarnAnimal: itemBarn.sampleBarnAnimal,
          });
        });
        dataBarnStep1 = brn;
      }

      let data_setAnimal = [];
      if (dataStep1.data_setAnimal && dataStep1.data_setAnimal.length > 0) {
        dataStep1.data_setAnimal.map((item, i) => {
          data_setAnimal.push({
            id: item.id ? item.id : "",
            limsCaseId: item.limsCaseId ? item.limsCaseId : "",
            barnId: item.barnId,
            animalTypeId: item.animalTypeId,
            sampleKeepTypeId: item.sampleKeepTypeId,
            sampleAmt: item.sampleAmt,
            animalName: item.animalName,
            sex: item.sex,
            species: item.species,
            ageYear: item.ageYear,
            ageMonth: item.ageMonth,
            nid: item.nid,
            microchip: item.microchip,
            resultsStatus: item.resultsStatus,
            immuneO: item.immuneO,
            immuneA: item.immuneA,
            immuneA1: item.immuneA1,
          });
        });
      }

      const sampleFilesStep1 = [];
      if (dataStep1.data_file && dataStep1.data_file.length > 0) {
        dataStep1.data_file.map((item, i) => {
          if (item.id) {
          } else {
            sampleFilesStep1.push({
              name: item.name,
              description: item.description,
              data: item.data,
            });
          }
        });
      }

      // console.log("sampleFilesStep1", sampleFilesStep1);

      const sampleTestFilesStep2 = [];
      if (dataStep2.data_file && dataStep2.data_file.length > 0) {
        dataStep2.data_file.map((item, i) => {
          if (item.id) {
          } else {
            sampleTestFilesStep2.push({
              name: item.fileName,
              description: item.detail_file,
              date: item.date_file ? helper.getDate(item.date_file) : "",
              data: item.file,
            });
          }
        });
      }

      // const sampleResultAnimalsFilesStep2 = [];
      // if (dataStep2.sampleListAnimal && dataStep2.sampleListAnimal.length > 0) {
      //   dataStep2.sampleListAnimal.map((item, i) => {
      //     if (item.id) {
      //     } else {
      //       sampleTestFilesStep2.push({
      //         name: item.fileName,
      //         description: item.detail_file,
      //         date: item.date_file ? helper.getDate(item.date_file) : "",
      //         data: item.file,
      //       });
      //     }
      //   });
      // }

      const delFile = [];
      if (dataStep1.del_file && dataStep1.del_file.length > 0) {
        dataStep1.del_file.map((item) => {
          delFile.push(item);
        });
      }

      const delTestFiles = [];
      if (dataStep2.del_file && dataStep2.del_file.length > 0) {
        dataStep2.del_file.map((item) => {
          delTestFiles.push(item);
        });
      }

      let data_setResultAnimal= [];
      if (dataStep2.sampleListAnimal && dataStep2.sampleListAnimal.length > 0) {
        dataStep2.sampleListAnimal.map((item, i) => {
          data_setResultAnimal.push({
            id: item.id ? item.id : "",
            docLink: item.docLink ? item.docLink : "",
            limsCaseId: item.limsCaseId ? item.limsCaseId : "",
            aniNo: item.aniNo ? item.aniNo : "",
            aniName: item.aniName ? item.aniName : "",
            aniTypeId: item.aniTypeId ? item.aniTypeId : "",
            sampleId: item.sampleId ? item.sampleId : "",
            limsLicenseNo: item.limsLicenseNo ? item.limsLicenseNo : "",
            testDate: item.testDate ? item.testDate : "",
            testResult: item.testResult ? item.testResult : "",
            testMethod: item.testMethod ? item.testMethod : "",
            fileSize: item.fileSize ? item.fileSize : "",
            fileName: item.fileName ? item.fileName : "",
            filePath: item.filePath ? item.filePath : "",
            fileData: item.fileData ? item.fileData : "",
          });
        });
      }

      let data_setResultBarn= [];
      let data_setResultDeleteBarn= [];
      if (dataStep2barn && dataStep2barn.length > 0) {
        dataStep2barn.map((itemMain, i) => {
          if  (itemMain.sampleListBarn != null) {
            itemMain.sampleListBarn.map((item, i) => {
            data_setResultBarn.push({
              id: item.id ? item.id : "",
              docLink: item.docLink ? item.docLink : "",
              limsCaseId: item.limsCaseId ? item.limsCaseId : "",
              sampleId: item.sampleId ? item.sampleId : "",
              limsLicenseNo: item.limsLicenseNo ? item.limsLicenseNo : "",
              sampleBarnId: item.sampleBarnId ? item.sampleBarnId : "",
              testDate: item.testDate ? item.testDate : "",
              testResult: item.testResult ? item.testResult : "",
              testMethod: item.testMethod ? item.testMethod : "",
              fileSize: item.fileSize ? item.fileSize : "",
              fileName: item.fileName ? item.fileName : "",
              filePath: item.filePath ? item.filePath : "",
              fileData: item.fileData ? item.fileData : "",
            });
          })}
          if (itemMain.deleteSampleListBarn != null) {
            itemMain.deleteSampleListBarn.map((item, i) => {
              data_setResultDeleteBarn.push(item);
          });
          }
          
        });
      }


      const data = {
        caseCode: dataById.fm01.caseCode,
        sample: {
          keepSampleDate: dataStep1.keepSampleDate
            ? helper.getDate(dataStep1.keepSampleDate)
            : "",
          sendSampleDate: dataStep1.sendSampleDate
            ? helper.getDate(dataStep1.sendSampleDate)
            : "",
          sendSampleMethodId: dataStep1.sendSampleMethodId,
          remark: dataStep1.remark,
          labId: dataStep1.labId,
          purposeSampleId: dataStep1.purposeSampleId,
          diseaseTypeId: dataStep1.diseaseTypeId,
        },
        sampleTest: {
          statusId: form.test_results,
          diseaseTypeId: form.diseases,
          remark: form.note,
        },
        sampleBarn: dataBarnStep1,
        sampleAnimal: data_setAnimal,
        sampleFiles: sampleFilesStep1,
        deleteSampleFiles: delFile,
        sampleTestFiles: sampleTestFilesStep2,
        deleteSampleTestFiles: delTestFiles,
        sampleResultAnimals: data_setResultAnimal,
        deleteSampleResultAnimals: dataStep2.deleteSampleListAnimal,
        sampleResultBarns: data_setResultBarn,
        deleteSampleResultBarns: data_setResultDeleteBarn,
      };
      // console.log("dataBarnStep1--🐞🐞", dataBarnStep1);
      let checkInvalidBorn = true;
      if (dataBarnStep1?.length > 0) {
        let dataValidBorn = {
          animalTypeId: false,
          barnId: false,
          animalCode: false,
          sampleNo: false,
        };
        dataBarnStep1.map((barn) => {
          if (barn?.animalTypeId?.length == 0) {
            dataValidBorn.animalTypeId = true;
            checkInvalidBorn = false;
          }
          if (barn?.barnId?.length == 0) {
            dataValidBorn.barnId = true;
            checkInvalidBorn = false;
          }
          if (barn?.sampleBarnAnimal?.length > 0) {
            barn.sampleBarnAnimal.map((item) => {
              if (item?.animalCode == "") {
                dataValidBorn.animalCode = true;
                checkInvalidBorn = false;
              }
              if (item?.sampleBarnAnimalDtl?.length > 0) {
                item.sampleBarnAnimalDtl.map((dtl) => {
                  if (dtl?.sampleNo == "") {
                    dataValidBorn.sampleNo = true;
                    checkInvalidBorn = false;
                  }
                });
              }
            });
          }
        });
        setInvalidBorn(dataValidBorn);
      }
      if (checkInvalidBorn) {
        MySwal.fire({
          text: `ต้องการบันทึก`,
          icon: "question",
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: "ยกเลิก",
          confirmButtonText: "ตกลง",
        }).then(async (value) => {
          if (value.isConfirmed) {
            try {
              const response =
                location.pathname.includes("save") ||
                location.pathname.includes("duplicate")
                  ? await API.addSample(data)
                  : await API.updateSample(props.match.params.id, data);
              if (response.status === 200) {
                MySwal.fire({
                  text:
                    location.pathname.includes("save") ||
                    location.pathname.includes("duplicate")
                      ? `สร้าง "รายการการทดสอบโรค (เก็บตัวอย่าง) และผลการตรวจทางห้องปฏิบัติการ" สำเร็จ`
                      : `แก้ไข "รายการการทดสอบโรค (เก็บตัวอย่าง) และผลการตรวจทางห้องปฏิบัติการ" สำเร็จ`,
                  icon: "success",
                  confirmButtonText: "ตกลง",
                }).then(async (result) => {
                  history.push(`/passive_surv/keep-example`);
                });
              }
            } catch (error) {
              if (error.response && error.response.status === 401) {
                dispatch(logout({ history }));
              } else if (error.response && error.response.status === 400) {
                let errorText = error?.response?.data?.errors;
                if (errorText == "Error sampleBarn duplicate barnId") {
                  errorText = "โรงเรือนต้องไม่ซ้ำกัน กรุณาลองใหม่อีกครั้ง";
                }
                MySwal.fire({
                  title: "บันทึกไม่สำเร็จ",
                  text: `Error: ${errorText}`,
                  icon: "warning",
                  confirmButtonText: "ตกลง",
                });
              } else {
                MySwal.fire({
                  text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
                  icon: "warning",
                  confirmButtonText: "ตกลง",
                });
              }
              console.log("error", error);
            }
          }
        });
      }
    }
  };

  return (
    <div>
      <div className="pl-4 bg-white ml30 d-flex">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2 ">
          <Link to={`/passive_surv/keep-example`}>
            <button className="btn btn-danger" onClick={() => toCancel()}>
              ยกเลิก
            </button>
          </Link>
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-success"
            onClick={() => toSaveKeepEx()}
            disabled={user?.userGroup.mupdate === "n"}
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxHead title="ตัวกรอง" classLabel="">
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="เลข Outbreak ID"
                    idName="outbreakCode"
                    value={form.outbreakCode}
                    star={true}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, outbreakCode: value })
                    }
                  />
                </Col>

                <Col lg={1} md={2} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    <button
                      className="btn btn-add "
                      onClick={() => setShowOutbreakSearch(true)}
                    >
                      <img
                        className="img-fluid icon-btn "
                        src="/svg/btn-edit.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="เลข case ID"
                    idName="caseCode"
                    value={form.caseCode}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, caseCode: value })
                    }
                  />
                </Col>

                <Col lg={1} md={2} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    {/* disabled={location.pathname.includes("outbreak/save")} */}
                    <button
                      className="btn btn-add "
                      onClick={() => setShowCaseIdSearch(true)}
                    >
                      <img
                        className="img-fluid icon-btn "
                        src="/svg/btn-edit.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <label className="mb-0 normal">หน่วยงานที่แจ้งโรค </label>
                  <div className="d-flex align-items-center">
                    {form.orgName ? (
                      <>
                        <button
                          className="btn btn-primary pt-2 px-3"
                          onClick={() => setShowOrgModal(true)}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="ml-2">{form.orgName}</div>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary p-2 d-flex align-items-center"
                        onClick={() => setShowOrgModal(true)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        <p className="mb-0 ml-2">{t("search")}</p>
                      </button>
                    )}
                  </div>
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <div>
                    <InputGroupText
                      type="text"
                      title="รหัสฟาร์ม"
                      imgSrc={"/svg/search.svg"}
                      imgAlt="search-icon"
                      idName="farmId"
                      value={form.farmId}
                      star={false}
                      placeholder="ค้นหาฟาร์ม"
                      classLabel="normal"
                      handleChange={(value) =>
                        setForm({ ...form, farmId: value })
                      }
                      disabled
                    />
                  </div>
                </Col>

                <Col lg={2} md={2} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    <button
                      className="btn btn-darkblue w-100"
                      onClick={() =>
                        history.push("/farm_registration/save-data")
                      }
                    >
                      <img
                        className="mr-2 mb-1 "
                        src={"/svg/menu_5.svg"}
                        alt="menu-icon"
                      />
                      ขึ้นทะเบียนฟาร์ม
                    </button>
                  </div>
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ชื่อเจ้าของสัตว์"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputMask
                    mask="99-9999-9999"
                    title="หมายเลขโทรศัพท์"
                    idName="phone"
                    value={form.phone}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, phone: value.replace(/-/g, "") })
                    }
                  />
                </Col>
                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="บ้านเลขที่"
                    idName="homeNo"
                    value={form.homeNo}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, homeNo: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    disabled={true}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    disabled={true}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    disabled={true}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputSelect
                    title="หมู่ที่"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="villageId"
                    classLabel="normal"
                    disabled={true}
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({ ...form, villageId: value.value });
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2 bg-gray2">
                <Col lg={2} md={3} sm={6}>
                  <p className="mb-0 bold">ผลการพิจารณาผลตรวจ :</p>
                </Col>
                <Col lg={2} md={2} sm={6}>
                  <InputSelect
                    title="ผลการตรวจ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    disabled={location.pathname.includes("save")}
                    selectValue={testResultsTypeSelectValue}
                    optionsList={testResultsList}
                    handleChange={(value) => {
                      setTestResultsValue(value);
                      setForm({
                        ...form,
                        test_results: value.value,
                        test_results_name: value.label,
                      });
                    }}
                  />
                </Col>

                <Col lg={2} md={2} sm={6}>
                  <InputSelect
                    title="ชนิดโรคที่พบ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    disabled={
                      location.pathname.includes("save") ||
                      form.test_results === 2
                    }
                    selectValue={diseasesSelectValue}
                    optionsList={diseasesList}
                    handleChange={(value) => {
                      setForm({
                        ...form,
                        diseases: value.value,
                        diseasesName: value.label,
                      });
                      setDiseasesSelectValue([value]);
                    }}
                  />
                </Col>

                <Col lg={2} md={2} sm={6}>
                  <InputText
                    type="text"
                    title="หมายเหตุ"
                    idName="note"
                    value={form.note}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled={
                      location.pathname.includes("save") ||
                      form.test_results === 2
                    }
                    handleChange={(value) => setForm({ ...form, note: value })}
                  />
                </Col>
              </Row>
            </div>
          </BoxHead>

          <div className="pt-3 container-fixed-footer ml-0">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item ">
                <a
                  className="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  1.การเก็บตัวอย่าง
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  2.ผลการตรวจทางห้องปฏิบัติการ
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {form.modeGet == "default" && (
                  <KeepExample_Step1
                    formData={form}
                    save={(data) => {
                      setForm({ ...form, step1: data });
                    }}
                    modeGet={form.modeGet}
                    diseasesListData={diseasesList}
                    buildingList={houseList}
                    animalListData={animalList}
                    resultsList={dataResultsList}
                    immuneOList={dataImmuneOList}
                    immuneAList={dataImmuneAList}
                    immuneA1List={dataImmuneA1List}
                    checkOnSave={(value) => checkOnSave(value)}
                    checkSave={checkSave}
                    invalidBorn={invalidBorn}
                    setInvalidBorn={(value) => setInvalidBorn(value)}
                  />
                )}
                {form.modeGet == "getById" && (
                  <KeepExample_Step1
                    formData={form}
                    save={(data) => {
                      setForm({ ...form, step1: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step1}
                    diseasesListData={diseasesList}
                    buildingList={houseList}
                    animalListData={animalList}
                    resultsList={dataResultsList}
                    immuneOList={dataImmuneOList}
                    immuneAList={dataImmuneAList}
                    immuneA1List={dataImmuneA1List}
                    arrayFiles={arrayFiles}
                    checkOnSave={(value) => checkOnSave(value)}
                    checkSave={checkSave}
                    invalidBorn={invalidBorn}
                    setInvalidBorn={(value) => setInvalidBorn(value)}
                  />
                )}
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                {form.modeGet == "default" && (
                  <KeepExample_Step2
                    save={(data) => {
                      setForm({ ...form, step2: data });
                    }}
                    modeGet={form.modeGet}
                  />
                )}

                {form.modeGet == "getById" && (
                  <KeepExample_Step2
                    save={(data) => {
                      setForm({ ...form, step2: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step2}
                    farmId={form.step1}
                    animalListData={animalList}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showOutbreakSearch && (
        <ModalOutbreakSearch
          show={showOutbreakSearch}
          mode={form.modeOutbreak}
          close={() => setShowOutbreakSearch(false)}
          save={(value) =>
            setForm({
              ...form,
              outbreakCode: value.obCode,
              outbreakId: value.obId,
            })
          }
        />
      )}
      <ModalFarmSearch
        show={showFarmSearch}
        mode={form.modeFarmSearch}
        close={() => setShowFarmSearch(false)}
        save={(value) => setForm({ ...form, farmId: value })}
      />

      {showOrgModal && (
        <ModalOrganizes
          show={showOrgModal}
          close={() => setShowOrgModal(false)}
          toSaveOrg={(data) => setOrganizes(data)}
        />
      )}
    </div>
  );
}

export default Keep_Example_Save;
