import React from 'react'
import { useTranslation } from 'react-i18next';

function DataTableNodata({colSpan}) {
    const { t, i18n } = useTranslation(["common"]);

    return (
        <tr>
            <td scope="col" colSpan={colSpan} className="text-center font14">{t("no_data")}</td>
        </tr>
    )
}

export default DataTableNodata
