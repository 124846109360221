import React, { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, InputGroup } from 'react-bootstrap';
import GoogleMap from "../components/GoogleMap";
import InputText from "../components/InputText";

function ModalAddHouse({ show, close, save }) {

    const initialForm = {
        house: "",
    };
    const [form, setForm] = useState(initialForm);

    const initInvalidMsg = {
        house: "",
    };
    const [invalid, setInvalid] = useState(initInvalidMsg);

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;

        if (form.house == "") {
            addInvalid("house", "กรุณากรอกชื่อโรงเรือน");
            validated = false;
        }

        return validated;
    };

    const toSave = async () => {
        if (validate()) {
            await save(form.house);
            handleClose("");
        }
    };

    const handleClose = (value) => {
        close(value)
        setInvalid(initInvalidMsg)
        setForm(initialForm)
    }

    return (
        <Modal
            show={show}
            size="md"
            onHide={() => handleClose(false)}
            centered
        >
            <Modal.Header closeButton className="pb-2 underline">
                <Modal.Title>
                    <h5><strong>บันทึกโรงเรือน</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                {/* <Form onSubmit={toSave}> */}
                <div className="mr-2 mb-2">
                    <InputText
                        type="text"
                        title="ชื่อโรงเรือน"
                        idName="house"
                        value={form.house}
                        star={true}
                        placeholder="โปรดระบุ"
                        classLabel="normal"
                        // onChange = {(value) => setForm(value.target.value.split('').filter((val1, index, arr) => index == 0 || val1 !== ' ' || arr[index-1] !== '').join(''))}
                        handleChange={(value) => setForm({ ...form, house: value.replace(/\s+/g, '')})}
                        handleInvalid={() => removeInvalid("house")}
                        invalid={invalid.house}
                    />

                    <div className="text-center py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose("cancel")}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toSave()}>บันทึก</button>
                    </div>
                </div>
                {/* </Form> */}

            </Modal.Body>
        </Modal>
    )
}

export default ModalAddHouse;
