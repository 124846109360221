import React from 'react'
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

function UploadFileArray({ onChange, filesPreview, type }) {

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const base64 = async (file) => {
        return await toBase64(file);
    };

    const handleFilesChange = async (files) => {
        let fileArray = [...filesPreview];

        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                await fileArray.push({
                    order: i,
                    name: files[i].name,
                    base64: await base64(files[i]),
                });
            }
            onChange(fileArray)
            // console.log("imagesPreview", fileArray);
        } else {
            onChange(fileArray)
        }
    }


    return (
        <div>
            <div className="choose-img">
                <div className="file ">
                    <button className={type =="file" ? "btn btn-sm btn-file btn-choose mr-1" : "btn btn-file btn-choose mr-1"} >
                        <p className="mb-0">เลือกไฟล์</p>
                    </button>
                    <span className="font14 mt-2">(ไม่เกิน 10 MB)</span>
                    <input multiple
                        type="file" name="file" id="imgfile"
                        accept={type == "file" ? ".pdf" : "image/png, image/jpeg"}
                        onChange={(e) => handleFilesChange(e.target.files)}
                    />
                </div>
            </div>

            <style jsx="true" global="true">{`
                .choose-img .file {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                }
                .choose-img .file:hover {
                    cursor: pointer !important;
                }
                .btn-file {
                    background: linear-gradient(180deg, #E3E3E3 0%, #F7F7F7 100%);
                    border: 1px solid #979797;
                    box-sizing: border-box;
                }
                .icon-del {
                    width: 25px !important;
                    height: 25px !important;
                }
          `}</style>
        </div>
    )
}

export default UploadFileArray
