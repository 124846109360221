import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";
import UploadImage from "../../components/UploadImage";

import '../../assets/css/style.css'

function ModalSystem({ show, close, save, mode, idEdit }) {
    const user = useSelector(state => state.login.result);
    const initialForm = {
        systemId: "",
        date: "",
        systemName: "",
        size: "",
        statusId: "",
        url: "",
        createBy: "",
        updateBy: "",
        createDate: "",
        updateDate: "",
        link: "",
        name: "",

        checkShow: false
    };
    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];
    const initInvalidMsg = {
        statusId: "",
        systemName: "",
        link: "",
    };
    const initStatusSelectValue = [];

    const dispatch = useDispatch();
    const history = useHistory();

    const [form, setForm] = useState(initialForm);
    const [invalid, setInvalid] = useState(initInvalidMsg);
    const [statusSelectValue, setStatusSelectValue] = useState(initStatusSelectValue);
    const [statusList, setStatusList] = useState(selectStatusList);

    useEffect(async () => {
        if (mode === "add") {
            setForm(initialForm)
            setStatusSelectValue([])
        }
        if (mode === "edit" && !form.checkShow) {
            setForm({...form, systemId: idEdit})
            await getSystemById(idEdit)
        }
    }, [mode])

    const getSystemById = async (id) => {
        let dataSystem;
        try {
            const response = await API.getSystemById(id);
            const dataRes = await response.data;
            if (response.status === 200) {
                dataSystem = dataRes;
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }))
            }
            console.log(error)
        }

        if (dataSystem) {
            if (dataSystem.statusId) {
                let statusSelect = await statusList.find(item => item.value === dataSystem.statusId);
                setStatusSelectValue(statusSelect);
            }

            setForm({
                ...form,
                "systemName": dataSystem.name,
                "link": dataSystem.link,
                "statusId": dataSystem.statusId,
                "url": dataSystem.url,
                "systemId": idEdit
            })
        }
    }

    const validate = () => {
        let validated = true;

        if (form.systemName === "") {
            addInvalid("systemName", "โปรดระบุหัวข้อ");
            validated = false;
        }

        if (form.link === "") {
            addInvalid("link", "กรุณาใส่ลิงค์ URL");
            validated = false;
        }

        if (form.statusId === "") {
            addInvalid("status", "กรุณาเลือกสถานะ");
            validated = false;
        }

        return validated;
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setStatusSelectValue([])
    }

    const toConfirm = async () => {
        if (validate()) {
            await save({ system: form, mode: mode });
            handleClose();
        }
    };

    const getPicture = async (name, file) => {
        setForm({ ...form, url: file, name: name })
    }

    const deleteImgPicture = async () => {
        setForm({ ...form, url: "", name: "" })
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => { handleClose() }}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่มระบบที่เกี่ยวข้อง</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className={invalid.statusId ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="statusId" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="ชื่อระบบ" value={form.systemName} idName="systemName" star={true}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) =>
                                            setForm({ ...form, systemName: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("")} invalid={invalid.systemName}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="ลิงค์  URL" value={form.link} idName="link" star={true}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) =>
                                            setForm({ ...form, link: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("")} invalid={invalid.link}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <UploadImage title="อัปโหลดรูปภาพ" classLabel="bold" star={false} type="img" typeSave="base64"
                                        onChange={(name, file) => getPicture(name, file)} id="pictureFile" position="top"
                                        deleteImg={() => deleteImgPicture()} imgLimitText="(ขนาด 80*180 px)"
                                        files={(form.url === null || form.url === "") ? "" : form.url} />
                                </Col>
                            </Row>
                        </div>

                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                        selectValue={statusSelectValue} optionsList={statusList}
                                        handleChange={(value) => {
                                            setForm({ ...form, statusId: value.value, checkShow: true })
                                            setStatusSelectValue([{ value: value.value, label: value.label },])
                                        }
                                        }
                                        handleInvalid={() => removeInvalid("status")} invalid={invalid.statusId}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mr-4">วันที่บันทึกข้อมูล</p>
                                        <p>{form.createDate ? helper.momentDate(form.createDate, "th", "short") : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                                        <p className="mr-3" >วันที่ปรับปรุงข้อมูล</p>
                                        <p>{form.updateDate ? helper.momentDate(form.updateDate, "th", "short") : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                </div>
                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalSystem
