import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";

function ModalAnimalPalce({ show, close, save, mode, idEdit }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.login.result);

    const initialForm = {
        place_name: "",
        place_id: "",
        status: "",
        createDate: "",
        updateDate: "",

        checkShow: false
    };
    const [form, setForm] = useState(initialForm);

    const initInvalidMsg = {
        place_name: "",
        status: "",
    };
    const [invalid, setInvalid] = useState(initInvalidMsg);

    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];
    const [statusList, setStatusList] = useState(selectStatusList);

    const initStatusSelectValue = [];
    const [statusSelectValue, setStatusSelectValue] = useState(initStatusSelectValue);

    useEffect(async () => {
        if (mode === "edit" && !form.checkShow) {
            await getAnimalPlaceById(idEdit)
        }
    }, [mode, idEdit, show, form.place_name, form.status])

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setStatusSelectValue([])
    }

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;
        if (form?.place_name === "") {
            addInvalid("place_name", "โปรดระบุสถานที่");
            validated = false;
        }
        if (form?.status === "") {
            addInvalid("status", "กรุณาเลือกสถานะ");
            validated = false;
        }
        return validated;
    };

    const getAnimalPlaceById = async (id) => {
        let dataPlace;
        try {
            const response = await API.getAnimalPlaceById(id);
            const data = await response?.data;
            if (response?.status === 200) {
                dataPlace = data;
            }
        } catch (error) {
            dispatch(loading(false))
            if (error?.response && error?.response?.status === 401) {
                dispatch(logout({ history }))
            }
            console.log(error)
        }

        if (dataPlace.statusId) {
            let statusSelect = await statusList.find(item => item.value === dataPlace?.statusId);
            setStatusSelectValue(statusSelect);
        }

        setForm({
            ...form,
            place_id: dataPlace.id,
            place_name: dataPlace.name,
            status: dataPlace.statusId,
            createDate: dataPlace.createDate,
            updateDate: dataPlace.updateDate
        })
    }

    const toConfirm = async () => {
        if (validate()) {
            await save({ place: form, mode: mode });
            handleClose();
        }
    };

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => handleClose()}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่มสถานที่อยู่สัตว์</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="ชื่อสถานที่" idName="place_name" value={form.place_name} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, place_name: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("place_name")}
                                        invalid={invalid.place_name}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                        selectValue={statusSelectValue} optionsList={statusList}
                                        handleSelectValue={(value) => setStatusSelectValue([{ value: value.value, label: value.label },])}
                                        handleChange={(value) => setForm({ ...form, status: value.value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("status")} invalid={invalid.status}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mr-4">วันที่บันทึกข้อมูล</p>
                                        <p>{form.createDate ? helper.momentDate(form.createDate, "th", "short") : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                                        <p className="mr-3" >วันที่ปรับปรุงข้อมูล</p>
                                        <p>{form.updateDate ? helper.momentDate(form.updateDate, "th", "short") : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalAnimalPalce
