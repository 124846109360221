import React, { useState, useEffect, createRef } from "react";
import { useScreenshot } from "use-react-screenshot";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BoxCard from "../../components/BoxCard";
import * as helper from "../../utils/helper";
import InputText from "../../components/InputText";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import * as API from "../../utils/apis";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import { useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import ModalInstructions from "../../components/Modal.Instructions";
import styled from "styled-components";

const Label = styled.div`
  background: #00af50;
  border-radius: 4px;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 2px 7px 4px 6px;
`;

function AssessRisk_Result_Score(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t } = useTranslation(["common", "footer"]);

  const initialForm = {
    farmName: "",
    assessment_date: "",
    score: "",
    modeSee: "",
    idSee: "",
    provinceName: "",
    amphurName: "",
    tambonName: "",
    homeNo: "",
    mooName: "",
    resultData: [],

    checkShow: false,

    statusCheckId: [
      {
        id: 0,
        color: "#00AF50",
        status: "ไม่มีความเสี่ยง",
      },
      {
        id: 1,
        color: "#70AD46",
        status: "ต่ำมาก",
      },

      {
        id: 2,
        color: "#25B881",
        status: "ต่ำ",
      },

      {
        id: 3,
        color: "#FFC107",
        status: "ปานกลาง",
      },

      {
        id: 4,
        color: "#ED7D31",
        status: "สูง",
      },

      {
        id: 5,
        color: "#FE0000",
        status: "สูงมาก",
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const initShowInstructions = false;
  const [showInstructions, setShowInstructions] =
    useState(initShowInstructions);

  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => takeScreenshot(ref.current);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/assess_risk/list-assess-risk", name: "ประเมินความเสี่ยง" },
    { path: "active", name: "บันทึกผลการประเมินความเสี่ยง" },
  ];

  useEffect(async () => {
    if (location.pathname.includes("score")) {
      await getRiskLevelById(props.match.params.id);
    }
  }, [
    user,
    location.pathname,
    form.farmName,
    form.assessment_date,
    form.score,
  ]);

  useEffect(async () => {
    if (location.pathname.includes("score")) {
      await takeScreenshot(ref.current);
    }
  }, [
    user,
    location.pathname,
    form.farmName,
    form.assessment_date,
    form.score,
  ]);

  const saveImg = async () => {
    if (image) {
      const data = {
        name: `assess-risk-result-${props.match.params.id}.png`,
        data: image.replace("data:", "").replace(/^.+,/, ""),
      };

      try {
        const response = await API.getUploadScoreById(
          props.match.params.id,
          data
        );
        if (response.status == 200) {
          history.push(`/assess_risk/assess-risk-result`);
        }
      } catch (error) {
        if (error.response && error.response.status == 401) {
          dispatch(logout({ history }));
        }
        console.log("error", error);
      }
    }
  };

  const getRiskLevelById = async (id) => {
    let dataById;
    try {
      const response = await API.getRiskLevelById(id);
      const data = await response.data;
      if (response.status === 200) {
        dataById = data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    let resultArray = [];
    if (dataById.risk && dataById.risk.length > 0) {
      dataById.risk.map((item, i) => {
        resultArray.push({
          id: item.id,
          diseaseTypeId: item.diseaseTypeId,
          diseaseTypeName: item.diseaseTypeName,
          score: item.score,
          riskLevel: item.riskLevel,
          levelName: item.levelName,
          adviceFarm: item.adviceFarm,
          adviceBiological: item.adviceBiological,
        });
      });
    }

    setForm({
      ...form,
      farmName: dataById.farmName,
      assessment_date: dataById.updateDate,
      provinceName: dataById.provinceName,
      amphurName: dataById.amphurName,
      tambonName: dataById.tambonName,
      homeNo: dataById.homeNo,
      mooName: dataById.mooName,
      score: dataById.score,
      resultData: resultArray,
    });
    // console.log("form7", form);
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (form.statusCheckId && form.statusCheckId.length > 0) {
      form.statusCheckId.map((item, index) => {
        if (item.id == idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status3 font14 px-4 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const instructions = (id) => {
    setForm({ ...form, modeSee: "edit", idSee: id });
    setShowInstructions(true);
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer" ref={ref}>
          <BoxCard title="3.ผลการประเมิน" classNameBox="mt-3">
            <Row>
              <Col lg={2} md={2} sm={12}>
                <div className="d-flex align-items-center mt-3">
                  <p className="mr-2">ชื่อฟาร์ม:</p>
                  <p>{form.farmName ? form.farmName : "-"}</p>
                </div>
              </Col>
              <Col lg={3} md={3} sm={12}>
                <div className="d-flex align-items-center mt-3">
                  <p className="mr-2">วันที่ประเมิน:</p>
                  <p>
                    {form.assessment_date
                      ? helper.momentDate(form.assessment_date, "th", "short")
                      : "-"}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className="d-flex align-items-center ">
                  <p className="mr-2">ที่อยู่:</p>
                  <p>
                    บ้านเลขที่ {form.homeNo} หมู่ที่ {form.homeNo} ต.
                    {form.tambonName} อ.{form.amphurName} จ.{form.provinceName}
                  </p>
                </div>
              </Col>
            </Row>
            {form.resultData.length > 0 &&
              form.resultData.map((item, index) => {
                return (
                  <Row key={index}>
                    <Col lg={3} md={3} sm={12}>
                      <div className="d-flex align-items-center mt-3">
                        <p>{item.diseaseTypeName}</p>
                      </div>
                    </Col>
                    <Col lg={9} md={9} sm={12}>
                      <div className="d-flex align-items-center flex-wrap mt-2">
                        <div className="d-flex ml-3">
                          <p className="mt-2 mr-2">คะแนน</p>
                          <InputText
                            disabled
                            type="number"
                            title=""
                            idName="score"
                            value={item.score || 0}
                            star={false}
                            classLabel="normal"
                          />
                        </div>
                      </div>

                      <div className="d-flex align-items-center flex-wrap mt-2 ml-3 ">
                        <div className="d-flex flex-wrap mr-3">
                          <h6 className="mr-3  mt-2">ระดับความเสี่ยง</h6>
                          <div className="d-flex align-items-center ">
                            {item.riskLevel ? (
                              getStatusColor(item.riskLevel)
                            ) : (
                              <Label>{item.levelName}</Label>
                            )}
                          </div>
                        </div>
                        {item.riskLevel >= 3 ? (
                          <div className="d-flex flex-wrap ">
                            <div className="d-flex">
                              <button
                                className="btn btn-darkblue mr-2 "
                                onClick={() => instructions()}
                              >
                                ดูคำแนะนำ
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                );
              })}

            <div className="mr-2 mt-3 mb-2">
              <div className="text-right py-2">
                <button
                  className="btn btn-success px-4"
                  style={{ marginBottom: "10px" }}
                  onClick={() => {
                    saveImg();
                  }}
                >
                  เสร็จสิ้น
                </button>
              </div>
            </div>
            {/* 
                        <img src={image} alt={'Screenshot'} /> */}
          </BoxCard>
        </div>

        {showInstructions && (
          <ModalInstructions
            show={showInstructions}
            close={() => setShowInstructions(false)}
            modeSee={form.modeSee}
            idSee={props.match.params.id}
          />
        )}
      </div>
    </div>
  );
}

export default AssessRisk_Result_Score;
