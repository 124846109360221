import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTable from "../../../components/DataTable";
import DataTableNodata from "../../../components/DataTableNodata";
import ModalSystem from "../../../components/Data_Management/Modal.System";

const MySwal = withReactContent(Swal);

function System() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const statusCheck = useSelector((state) => state.statusCheck);
  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/web-settings/news", name: "ตั้งค่าเวปไซต์" },
    { path: "active", name: "ระบบที่เกี่ยวข้อง" },
  ];

  const initialForm = {
    systemId: "",
    date: "",
    systemName: "",
    size: "",
    statusId: "",
    url: "",
    createBy: "",
    updateBy: "",
    createDate: "",
    updateDate: "",
    link: "",

    idEdit: "",
    mode: "",

    search: "",
    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        name: "ลำดับ",
        width: "50px",
        sortFlg: false,
      },
      {
        sortBy: "name",
        name: "ระบบ",
        sortFlg: true,
      },
      {
        sortBy: "link",
        name: "ลิงค์",
        sortFlg: true,
      },
      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [showSystem, setShowSystem] = useState(false);

  const [listSystem, setListSystem] = useState([]);

  const [isClearSearch, setIsClearSearch] = useState(false);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  useEffect(() => {
    getSystem();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusCheck && statusCheck.length > 0) {
      statusCheck.map((item) => {
        if (item.id === idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-4 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getSystem = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getSystem(
        form.systemId,
        form.statusId,
        form.search,
        form.sortBy,
        form.sortDir,
        form.page,
        form.limit
      );
      const data = await response?.data;
      if (response.status === 200) {
        let systemArray = [];
        data.content.forEach((element) => {
          systemArray.push({
            value: element.id,
            label: element.name,
          });
        });

        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListSystem(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const addSaveSystem = (data) => {
    let fileAdd;
    if (data.system.url && data.system.name) {
      fileAdd = {
        name: data.system.name,
        data: data.system.url,
      };
    } else {
      fileAdd = "";
    }

    let dataSystem; //เช็คจาก edit
    if (fileAdd) {
      //add form
      dataSystem = {
        name: data.system.systemName,
        statusId: data.system.statusId,
        file: fileAdd,
        link: data.system.link,
        url: data.system.url,
      };
    } else {
      //edit form
      dataSystem = {
        name: data.system.systemName,
        statusId: data.system.statusId,
        link: data.system.link,
      };
    }

    MySwal.fire({
      text: `ต้องการบันทึก `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      console.log("data", data);
      if (result.value) {
        try {
          const response =
            data.mode === "add"
              ? await API.saveSystem(dataSystem)
              : await API.updateSystem(data.system.systemId, dataSystem);
          if (response.status === 200) {
            await getSystem();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const delSystem = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบ `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteSystem(id);
          if (response.status === 200) {
            await getSystem();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const buttonAddSystem = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddSystem()}
          disabled={user?.userGroup.mcreate === "n"}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มระบบที่เกี่ยวข้อง
        </button>
      </div>
    );
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const toAddSystem = () => {
    setForm({ ...form, mode: "add", idEdit: "" });
    setShowSystem(true);
  };

  const editModalSystem = (id) => {
    setForm({ ...form, mode: "edit", idEdit: id });
    setShowSystem(true);
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setStatusSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const renderTableSystem = (data) => {
    let status = 1;
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td>{item.name}</td>
          <td>{item.link || "-"}</td>
          <td className="text-center">
            {item.statusId ? getStatusColor(item.statusId) : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editModalSystem(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delSystem(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={5} />;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={2} md={4} sm={12} xs={12}>
                <InputSelect
                  title="สถานะ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleChange={(value) => {
                    setStatusSelectValue(value);
                    setForm({ ...form, statusId: value.value });
                  }}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="รายการระบบที่เกี่ยวข้อง"
            classNameBox="mt-4"
            headRight={buttonAddSystem()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTableSystem(listSystem)}
            </DataTable>
          </BoxCard>
          {showSystem && (
            <ModalSystem
              show={showSystem}
              close={() => setShowSystem(false)}
              save={(value) => addSaveSystem(value, "modal")}
              mode={form.mode}
              idEdit={form.idEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default System;
