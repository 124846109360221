import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Row, Col } from "react-bootstrap";
import ModalMap from "../Modal.Map";
import { useLocation } from "react-router-dom";
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";
import { useMasterData } from "../../components/hooks/useMasterData";

function ModalSubDistrict({ show, close, save, mode, idEdit, editForm }) {
  const user = useSelector((state) => state.login.result);
  const initialForm = {
    subdistrict_name: "",
    subdistrict_name_en: "",
    subdistrict_id: "",
    zipcode: "",
    status: "",
    createDate: "",
    updateDate: "",

    latitude: "",
    longitude: "",
    modeMap: "add",
    zipcode: "",

    districtName: "",
    districtNameEn: "",
    districtId: "",

    limit: 100,
    page: 1,
    provinceId: "",
    provinceName: "",
    provinceStatusId: "",
    sortDir: "DESC",
    sortBy: "id",

    checkShow: false,
  };

  const initShowMap = false;

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showMap, setShowMap] = useState(initShowMap);
  const [form, setForm] = useState(initialForm);

  const initInvalidMsg = {
    sample_name: "",
    status: "",
  };

  const selectProvinceList = [];
  const [provinceList, setProvinceList] = useState(selectProvinceList);
  const selectDistrictList = [];
  const [districtList, setDistrictList] = useState(selectDistrictList);

  const initProvinceSelectValue = [];
  const [provinceSelectValue, setProvinceSelectValue] = useState(
    initProvinceSelectValue
  );

  const initDistrictSelectValue = [];
  const [districtSelectValue, setDistrictSelectValue] = useState(
    initDistrictSelectValue
  );

  const initZipcodeSelectValue = [];
  const [zipcodeSelectValue, setZipcodeSelectValue] = useState(
    initZipcodeSelectValue
  );

  const [invalid, setInvalid] = useState(initInvalidMsg);

  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  useMasterData("province", provinceList, setProvinceList);

  useEffect(async () => {
    if (mode === "add") {
      await setForm(initialForm);
      setStatusSelectValue([]);
    }
  }, [mode]);

  useEffect(async () => {
    await getProvince();
  }, []);

  useEffect(async () => {
    if (mode === "edit" && !form.checkShow) {
      await getSubDistrictsById(idEdit);
    }
  }, [mode, idEdit, show, form.province_id, form.status]);

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if (form.province_name === "") {
      addInvalid("province_name", "โปรดระบุข้อมูลจังหวัด");
      validated = false;
    }

    if (form.status === "") {
      addInvalid("status", "กรุณาเลือกสถานะ");
      validated = false;
    }

    return validated;
  };

  const handleClose = () => {
    close();
    setInvalid(initInvalidMsg);
    setForm(initialForm);
    setStatusSelectValue([]);
  };

  const toConfirm = async () => {
    if (validate()) {
      await save({ SubDt: form, mode: mode });
      handleClose();
    }
  };

  //set select "province value"
  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setZipcodeSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      districtId: "",
      subdistrict_id: "",
    });
    getDistrictsSelectList(value.value);
  };
  //set select "district value"
  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, districtId: value.value, subdistrict_id: "" });
  };

  //load "district value"
  const getDistrictsSelectList = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;

      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);

        if (editForm.amphurId && mode === "edit") {
          let districtSelect = districtArray.find(
            (item) => Number(item.value) === Number(editForm.amphurId)
          );
          setDistrictSelectValue(districtSelect);
          selectValueDistrict(districtSelect);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistrictsById = async (id) => {
    let dataSubDt;
    try {
      const response = await API.getSubDistrictsById(id);
      const data = await response.data;
      if (response.status === 200) {
        dataSubDt = data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataSubDt?.statusId) {
      let statusSelect = await statusList.find(
        (item) => item.value === dataSubDt?.statusId
      );
      setStatusSelectValue(statusSelect);
    }

    setForm({
      ...form,
      subdistrict_id: dataSubDt?.id,
      subdistrict_name: dataSubDt?.name,
      subdistrict_name_en: dataSubDt?.nameEn,
      district_id: dataSubDt?.amphurId,
      zipcode: dataSubDt?.zipcode,
      status: dataSubDt?.statusId,
      createDate: dataSubDt?.createDate,
      updateDate: dataSubDt?.updateDate,
      latitude: dataSubDt?.latitude,
      longitude: dataSubDt?.longitude,
    });
  };
  const toMap = () => {
    setShowMap(true);
    if (location.pathname.includes("edit")) {
      setForm({ ...form, modeMap: "edit" });
    } else {
      setForm({ ...form, modeMap: "add" });
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setProvinceList(provincesArray);

        if (editForm?.amphurId && mode === "edit") {
          let proveCode = editForm.amphurId;
          let pcode = proveCode.toString().slice(0, 2) + "000000";
          let selectProvince = provincesArray.find(
            (item) => Number(item.value) === Number(pcode)
          );
          setProvinceSelectValue(selectProvince);
          selectValueProvince(selectProvince);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal show={show} size="lg" onHide={() => handleClose()} centered>
      <Modal.Header closeButton className="pb-2 ">
        <Modal.Title className="mt-2">
          <h5>
            <strong>เพิ่มข้อมูลตำบล</strong>
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <div className="">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item tab">
              <a
                className="nav-link active"
                id="data-tab"
                data-toggle="tab"
                href="#data"
                role="tab"
                aria-controls="data"
                aria-selected="true"
              >
                ข้อมูลพื้นฐาน
              </a>
            </li>
            <li
              className={
                invalid.status ? "nav-item-invalid tab" : "nav-item tab"
              }
            >
              <a
                className="nav-link"
                id="status-tab"
                data-toggle="tab"
                href="#status"
                role="tab"
                aria-controls="status"
                aria-selected="false"
              >
                สถานะ
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active card-tab"
              id="data"
              role="tabpanel"
              aria-labelledby="data-tab"
            >
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่อตำบลภาษาไทย"
                    idName="subdistrict_name"
                    value={form.subdistrict_name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({
                        ...form,
                        subdistrict_name: value,
                        checkShow: true,
                      })
                    }
                    handleInvalid={() => removeInvalid("district_name")}
                    invalid={invalid.subdistrict_name}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่อตำบลภาษาอังกฤษ"
                    idName="subdistrict_name_en"
                    value={form.subdistrict_name_en}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({
                        ...form,
                        subdistrict_name_en: value,
                        checkShow: true,
                      })
                    }
                    handleInvalid={() => removeInvalid("subdistrict_name_en")}
                    invalid={invalid.subdistrict_name_en}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleSelectValue={(value) => selectValueProvince(value)}
                    handleChange={(value) =>
                      setForm({ ...form, provinceId: value.value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleSelectValue={(value) => selectValueDistrict(value)}
                    handleChange={(value) =>
                      setForm({ ...form, districtId: value.value })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="รหัสไปรษณีย์"
                    idName="zipcode"
                    value={form.zipcode}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, zipcode: value, checkShow: true })
                    }
                    handleInvalid={() => removeInvalid("zipcode")}
                    invalid={invalid.zipcode}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <p className="mb-0">
                    {form.latitude && `ละติจูด : ${form.latitude}`}
                  </p>
                  <p>{form.longitude && ` ลองจิจูด : ${form.longitude}`}</p>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <button
                    className="btn btn-sky d-flex align-items-center"
                    onClick={() => toMap()}
                  >
                    <img className="img-fluid mr-1" src="/svg/pin.svg" alt="" />
                    เลือกแผนที่
                  </button>
                </Col>
              </Row>
              {showMap && (
                <ModalMap
                  show={showMap}
                  modeMap={form.modeMap}
                  lat={form.latitude}
                  lon={form.longitude}
                  close={() => setShowMap(false)}
                  save={(value) =>
                    setForm({
                      ...form,
                      latitude: value.latitude,
                      longitude: value.longitude,
                    })
                  }
                />
              )}
            </div>
            <div
              className="tab-pane fade card-tab"
              id="status"
              role="tabpanel"
              aria-labelledby="status-tab"
            >
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={statusList}
                    handleSelectValue={(value) =>
                      setStatusSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, status: value.value, checkShow: true })
                    }
                    handleInvalid={() => removeInvalid("status")}
                    invalid={invalid.status}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                    <p className="mr-4">วันที่บันทึกข้อมูล</p>
                    <p>
                      {form.createDate
                        ? helper.momentDate(form.createDate, "th", "short")
                        : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                    <p className="mr-3">วันที่ปรับปรุงข้อมูล</p>
                    <p>
                      {form.updateDate
                        ? helper.momentDate(form.updateDate, "th", "short")
                        : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="mr-2 mt-3 mb-2">
          <div className="text-right py-2">
            <button
              className="btn btn-danger px-4 mr-2"
              onClick={() => handleClose()}
            >
              ยกเลิก
            </button>
            <button
              className="btn btn-success px-4"
              onClick={() => toConfirm()}
              disabled={user?.userGroup.mupdate === "n" && mode === "edit"}
            >
              บันทึก
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalSubDistrict;
