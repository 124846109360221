import React from "react";
import { Form, InputGroup } from "react-bootstrap";


function InputGroupText({ title, star, type, idName, value, placeholder, classLabel, handleChange, classFormGroup,
  invalid, handleInvalid, disabled, classInputGroup, classFormCon, imgSrc, imgAlt, classInputText, classInputPrepend,
}) {
  return (
    <Form.Group className={classFormGroup}>
      {title ? <Form.Label className={`${classLabel} mb-1`}>{title} <span className="text-danger">{star ? "*" : ""}</span></Form.Label> : ""}
      <InputGroup className={classInputGroup}>
        <InputGroup.Prepend className={classInputPrepend}>
          <InputGroup.Text className={classInputText} ><img src={imgSrc} alt={imgAlt} /></InputGroup.Text>
        </InputGroup.Prepend >
        <Form.Control
          type={type} id={idName} name={idName} className={invalid ? "is-invalid" : classFormCon ? classFormCon : ""}

          value={value} disabled={disabled ? true : false}
          placeholder={placeholder}
          onChange={e => {
            handleChange(e.target.value)
            if (handleInvalid) {
              handleInvalid()
            }
          }}
        />
      </InputGroup>
      {invalid ? <div className="invalid-error">{invalid}</div> : ""}
    </Form.Group>
  );
}

export default InputGroupText;
