import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Navbar, Nav, Container, Dropdown, Row, Col, Carousel } from 'react-bootstrap';

function Banner() {

    const user = useSelector(state => state.login);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation(["home", "footer"]);

    const [data, setDdata] = useState(false);

    return (
        <div id="main-slider" className=" ">
            <Carousel controls={false} indicators={false}>
                <Carousel.Item style={{ backgroundImage: 'url(/svg/index_1.svg)' }}>
                    <Container className="d-flex  align-items-center justify-content-center h-100 ">
                        <div className="text-center" >
                            <h1 className="text-logo-2 ">ระบบสารสนเทศเพื่อการเฝ้าระวังโรคระบาดสัตว์ <br />DLD e-Smart Surveillance</h1>
                            <h6 className="text-logo-2 mt-4">สำนักควบคุม ป้องกันและบำบัดโรคสัตว์ กรมปศุสัตว์ </h6>
                        </div>
                    </Container>
                </Carousel.Item>
            </Carousel>
        </div>
    );

}

export default Banner;