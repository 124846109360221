import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";
import { useDispatch, useSelector } from 'react-redux';
import BoxCard from "../BoxCard";
import InputSelect from "../InputSelect";
import InputText from "../InputText";
import { Row, Col } from "react-bootstrap";
import InputDatePicker from "../InputDatePicker";
import DataTableNodata from '../DataTableNodata'
import UploadImage from "../UploadImage";
import ModalEditAnimal from "../Modal.EditAnimal";
import { loading } from "../../redux/actions/loadingAction";

function KeepSample_Step2({ save, dataAll, modeGet, buildingList, animalListData }) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.login.result)

    const datatest2 = [
        {
            number: "1",
            animalId: "1001002",
            name: "ดำ",
            animalName: "โคนม",
            tast: "AGID",
            Lims: "64A00110",
        },
    ]

    const initialForm = {
        idHouse: "",
        nameHouse: "",
        caseCode: "",
        animalTypeId: "",
        animalTypeName: "",

        data_file: [],
        del_file: [],
        checkAddFile: false,

        barn: [],
        data_setAnimal: []
    };
    const [form, setForm] = useState(initialForm);

    const initialFormFile = {
        url: "",
        file: "",
        detail_file: "",
        date_file: "",
        fileName: ""
    };
    const [formFile, setFormFile] = useState(initialFormFile);

    const initialFormJson = {
        animalId: {},
    };
    const [formJson, setFormJson] = useState(initialFormJson);

    const [animalSelectValue, setAnimalSelectValue] = useState([])
    const [animalList, setAnimalList] = useState([])

    useEffect(async () => {
        await getMasterJson()
    }, [])

    useEffect(async () => {
        if (modeGet == "getById") {
            getDataById();
        }
    }, [user, modeGet])

    useEffect(async () => {
        toSave()
    }, [form])

    const toSave = () => {
        save(form)
    }

    const getMasterJson = async () => {
        const params = await [
            "animalType",
        ];
        try {
            const response = await API.getMasterJson(params);
            const data = await response.data;
            if (response.status == 200) {
                // console.log("getMasterJson", data);
                setFormJson({
                    ...formJson,
                    animalId: data.animalType,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }


    const toAddFile = () => {
        setForm({ ...form, checkAddFile: true })
        setFormFile({ ...formFile, date_file: Date.now(), detail_file: "", file: "", fileName: "" })
    };

    const removeImage = (index, item) => {
        let delFile = []
        form.data_file.splice(index, 1);
        if (item.id) {
            delFile.push(item.id)
        }
        setForm({ ...form, data_file: form.data_file, del_file: delFile });
    }

    const fileSave = (data) => {
        let arrayFile = [...form.data_file];
        arrayFile.push({
            file: data.file,
            fileName: data.fileName,
            detail_file: data.detail_file,
            date_file: Date.now()
        })
        setForm({ ...form, data_file: arrayFile, checkAddFile: false });
        toSave()
    }

    const getDataById = async () => {
        // console.log("buidingList tab 2", buidingList);

        let barn_Step2 = []
        if (dataAll.barn_Step1 && dataAll.barn_Step1.length > 0) {
            dataAll.barn_Step1.map((itemBarn, indexBarn) => {
                let nameHouse;
                buildingList.filter((itemH) => {
                    if (itemH.value == itemBarn.barnId) {
                        nameHouse = itemH.label;
                    }
                });

                let animalTypeName;
                animalListData.filter((itemA) => {
                    if (itemA.value == itemBarn.animalTypeId) {
                        animalTypeName = itemA.label;
                    }
                });

                barn_Step2.push({
                    id: itemBarn.id,
                    idHouse: itemBarn.barnId,
                    nameHouse: nameHouse,
                    animalTypeId: itemBarn.animalTypeId,
                    animalTypeName: animalTypeName,
                    number: itemBarn.animalAmt,
                    caseLims: itemBarn.limsCaseId,
                    houseSelectValue: [{ value: itemBarn.barnId, label: nameHouse }],
                    animalTypeSelectValue: [{ value: itemBarn.animalTypeId, label: animalTypeName }],
                    result: itemBarn.result
                })
            })
        }


        let step2Ex = [];
        if (dataAll.step1Ex && dataAll.step1Ex.length > 0) {
            dataAll.step1Ex.map((item, i) => {
                step2Ex.push({
                    animalTypeId: item.animalTypeId,
                    name: item.animalName,
                    no_nid: item.nid,
                    result: item.result
                })
            })
        }

        let step2File = [];
        if (dataAll.step2File && dataAll.step2File.length > 0) {
            dataAll.step2File.map((item, i) => {
                step2File.push({
                    id: item.id,
                    file: item.url,
                    fileName: item.name,
                    detail_file: item.description,
                    date_disease: item.date,
                })
            })
        }

        // console.log("step2Ex", step2Ex);

        setForm({
            ...form,
            data_file: step2File,
            barn: barn_Step2,
            data_setAnimal: step2Ex
        })
    }

    const renderTableFarm = (data) => {
        if (data.length > 0) {
            return data.map((item, index) => (
                <React.Fragment key={index}>
                    <tr className="text-center">
                        <td >{index + 1 || '-'}</td>
                        <td>{item.limsLicenseNo || '-'}</td>
                        <td>{item.limsCaseId || '-'}</td>
                        <td>{item.testMethod || '-'}</td>
                        <td>{item.testResult || '-'}</td>
                        <td>{item.testDate ? helper.getDateFormat(item.testDate) : '-'}</td>
                        <td>
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-report text-white mr-2 text-white" onClick={() => window.open(item.docLink, '_blank').focus()}>
                                    <p className="mb-0">ผลการทดสอบ</p>
                                </button>
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            ))
        }
    }

    const renderTableAnimal = (data) => {
        if (data.length > 0) {
            return data.map((item, index) => {
                // console.log(" item.result", item.result);
                return item.result.map((itemR, r) => (
                    <React.Fragment key={index}>
                        <tr className="text-center">
                            <td>{r + index + 1 || '-'}</td>
                            <td>{item.no_nid || '-'}</td>
                            <td>{item.name || '-'}</td>
                            <td>{item.animalTypeId ? formJson.animalId[item.animalTypeId] : '-'}</td>
                            <td>{itemR.testMethod || '-'}</td>
                            <td>{itemR.limsCaseId || '-'}</td>
                            <td>
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-report text-white mr-2 text-white" onClick={() => window.open(itemR.docLink, '_blank').focus()}>
                                        <p className="mb-0">ผลการทดสอบ</p>
                                    </button>
                                </div>
                            </td>
                        </tr>

                    </React.Fragment>
                ))
            })
        } else {
            return <DataTableNodata colSpan={7} />
        }
    }

    const renderTableFile = (data, index) => {
        return (
            <tr key={index}  >
                <td>{index + 1}</td>
                <td>
                {data.id ?
                        <span className="link"
                            onClick={() => {
                                data.id ? window.open(data.file, '_blank').focus() : window.open("", '_blank').focus()
                            }}
                        >
                            {data.fileName || "-"}
                        </span>
                        :
                        data.fileName
                    }
                </td>
                <td>{data.detail_file ? data.detail_file : "-"}</td>
                <td>{helper.getDateFormat(data.date_file)}</td>
                <td>
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-del " onClick={() => removeImage(index, data)}>
                            <img className="img-fluid icon-btn " src="/svg/btn-del.svg" alt="del" />
                        </button>
                    </div>
                </td>
            </tr>
        )
    }

    const renderAddFile = () => {
        return (
            <>
                <tr>
                    <td>{form.data_file && (form.data_file.length + 1)}</td>
                    <td>
                        <UploadImage title=" " classLabel="normal mt-2" star={false} type="fileName" typeSave="base64"
                            onChange={(name, file) => setFormFile({ ...formFile, file: file, fileName: name })} id="imgfile" position="left"
                            files={(formFile.fileName == null || formFile.fileName == "") ? "" : formFile.fileName} />
                    </td>

                    <td >
                        <InputText
                            classFormGroup="mb-0 "
                            type="text"
                            idName="count"
                            value={formFile.detail_file}
                            star={false}
                            placeholder="โปรดระบุ"
                            handleChange={(value) => setFormFile({ ...formFile, detail_file: value })}

                        />
                    </td>

                    <td>{helper.getDateFormat(formFile.date_file)}</td>
                    <td className="text-center">
                        <button className="btn btn-success  pt-1 px-2"
                            onClick={async () => {
                                fileSave(formFile)
                            }}>
                            <img className="img-fluid icon-check" src="/svg/btn-check.svg" alt="check" />
                        </button>
                    </td>
                </tr>
            </>
        )
    }


    return (
        <div className="bg-white p-4 ">
            <BoxCard classNameBox="mt-3" title="2. ผลการตรวจทางห้องปฏิบัติการ">
                <p className="bold font14">2.1  ผลการตรวจรายฟาร์ม/โรงเรือน</p>
                <hr />

                {form.barn && form.barn.length > 0 ?
                    form.barn.map((item, i) => (
                        <div key={i} className="border mt-3 ">
                            <Row className="mt-3 pl-2 " >
                                <Col lg={3} md={3} sm={6} xs={12}  >
                                    <InputText type="text" title="ชื่อโรงเรือน" idName="idHouse" value={item.nameHouse} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal" disabled
                                    />
                                </Col>
                                <Col lg={3} md={3} sm={6} xs={12} >
                                    <InputText type="text" title="เลขเคสรับตัวอย่าง LIMS" idName="caseCode" value={item.caseLims} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal" disabled
                                    />
                                </Col>

                                <Col lg={3} md={3} sm={6} xs={12} >
                                    <InputSelect
                                        title="ชนิดสัตว์" star={false} placeholder="กรุณาเลือก" idName="animalTypeName" disabled={true}
                                        classLabel="normal" selectValue={item.animalTypeSelectValue} optionsList={animalList}
                                    />
                                </Col>
                            </Row>

                            <div className=" pl-3 pr-3 pb-3">
                                <div className="table-responsive">
                                    <table className="table  table-striped table-bordered my-2 text-cente">
                                        <thead>
                                            <tr className="text-center" >
                                                <th scope="col">ลำดับ</th>
                                                <th scope="col">License no</th>
                                                <th scope="col">เลขที่เคส</th>
                                                <th scope="col">วิธีการทดสอบ</th>
                                                <th scope="col">ผลการตรวจ</th>
                                                <th scope="col">วันที่ตอบผล</th>
                                                <th scope="col" >ใบตอบผล</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderTableFarm(item.result)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    <div className="border mt-3 py-2 text-center">
                        ไม่ได้ทำการส่งตัวอย่างผ่านระบบ LIMS
                    </div>
                }


                <p className="bold font14 mt-3">2.2 ผลการตรวจรายตัว</p>
                <hr />
                <div className="border">
                    <Row className=" pl-3 pr-3 py-2">
                        <div className="table-responsive">
                            <table className="table  table-striped table-bordered my-2 text-cente">
                                <thead>
                                    <tr className="text-center" >
                                        <th scope="col">ลำดับ</th>
                                        <th scope="col">รหัสสัตว์</th>
                                        <th scope="col">ชื่อ</th>
                                        <th scope="col">ชนิดสัตว์</th>
                                        <th scope="col">วิธีการทดสอบ</th>
                                        <th scope="col">เลขเคสรับตัวอย่าง LIMS</th>
                                        <th scope="col-2" >ใบตอบผล</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderTableAnimal(form.data_setAnimal)}
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </div>

                <p className="bold font14 mt-3">2.3 แนบไฟล์ผลห้องปฏิบัติการ</p>
                <hr />
                <Col className="border mt-3 ">
                    <Row className="d-flex justify-content-between mt-3 pl-3 pr-3 pb-3">
                        <div className="text-gray font12">
                            <p >*อัปโหลดไฟล์กรณีส่งผลและรับผลผ่านระบบ LIMS</p>
                        </div>
                        <div className=" ">
                            <button className="btn btn-add text-white" onClick={() => toAddFile()}
                                disabled={form.checkAddFile}
                            >
                                <i className="fas fa-plus mr-1 "></i>เพิ่มไฟล์
                            </button>
                        </div>
                    </Row>

                    <Row className=" pl-3 pr-3 pb-3">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered my-2 ">
                                <thead>
                                    <tr>
                                        <th scope="col">ลำดับ</th>
                                        <th scope="col">อัปโหลดไฟล์</th>
                                        <th scope="col">คำอธิบาย</th>
                                        <th scope="col">วันที่อัปโหลด</th>
                                        <th className="text-center" scope="col">จัดการ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {form.data_file && form.data_file.length > 0 ?
                                        form.data_file.map((item, i) => {
                                            return renderTableFile(item, i)
                                        })
                                        : null
                                    }
                                    {form.checkAddFile && renderAddFile(form.data_file)}
                                    {form.data_file && form.data_file.length == 0 && !form.checkAddFile &&
                                        <DataTableNodata colSpan={5} />
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </Col>

            </BoxCard>
        </div>
    )
}

export default KeepSample_Step2
