import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../utils/apis";
import { useTranslation } from "react-i18next";
import { loading } from "../redux/actions/loadingAction";
import InputMask from "./InputMask";
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import DataTableNodata from "./DataTableNodata";
import BoxCard from "./BoxCard";
import ModalAddHouse from "./Modal.AddHouse";
import ModalAnimalByOne from "./Modal.AnimalByOne";
import ModalAddAnimal from "./Modal.AddAnimal";
import withReactContent from "sweetalert2-react-content";
import * as helper from "../utils/helper";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

function OutbreakStep2({
  save,
  dataAll,
  modeGet,
  farmId,
  getDiseaseById,
  checkEditAnimal,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "footer"]);
  const user = useSelector((state) => state.login.result);

  const initialForm = {
    house: [],
    houseList: [],
    animalAll: [], // สัตว์ทั้งหมดในฟาร์มนี้ ไปเช็คกับตอนเลือกจาก modal
    listAnimals: [], // list เพื่อส่งไป api
    res_animals: [], // list เพื่อโชว์
    del_animals: [], // array id สัตว์ที่ลบ
    nameTab: "",
    checkAddData: false,
    checkEdit: false,
    modeEditTable1: false,

    // ข้อมูลสัตว์
    dataAnimalEdit: "",
    modeAnimal: "add",

    chooseSelect: false,
  };
  const [form, setForm] = useState(initialForm);

  const initialFormDesc = {
    checkEdit: false,
    nameHouse: "",
    animalTypeId: "",
    animalTypeName: "",
    totalAmt: "",
    sickAmt: "",
    deadAmt: "",
    sickDeadAmt: "",
  };
  const [formDesc, setFormDesc] = useState(initialFormDesc);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const initialFormJson = {
    animalId: {},
    diseaseTypeId: {},
    husbandry: {},
    animalPurpose: {},
    vaccineType: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initInvalidMsg = {
    // houseDesc
    nameHouse: "",
    animalTypeName: "",
    totalAmt: "",
    sickAmt: "",
    deadAmt: "",
    sickDeadAmt: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const [houseSelectValue, setHouseSelectValue] = useState([]);
  const [houseList, setHouseList] = useState([]);

  const [showAddHouse, setShowAddHouse] = useState(false);

  const [showAnmByOne, setShowAnmByOne] = useState(false);

  const initShowAddAnimal = false;
  const [showAddAnimal, setShowAddAnimal] = useState(initShowAddAnimal);

  useEffect(() => {
    getMasterJson();
    getAnimal();
  }, []);

  useEffect(() => {
    // console.log("modeGet", modeGet);
    if (modeGet == "getById" && !form.chooseSelect) {
      getDataById();
    }
    if (modeGet == "dsGetById" && !form.chooseSelect) {
      getDataById();
    }
    if (modeGet == "farmById" && !form.chooseSelect) {
      getDataById();
    }
  }, [user, modeGet, farmId, dataAll?.house?.length, checkEditAnimal]);

  useEffect(() => {
    toSave();
  }, [form]);

  const toSave = () => {
    save(form);
  };

  const getMasterJson = async () => {
    const params = await [
      "animalType",
      "diseaseType",
      "husbandry",
      "animalPurpose",
      "vaccineType",
    ];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          animalId: data.animalType,
          diseaseTypeId: data.diseaseType,
          husbandry: data.husbandry,
          animalPurpose: data.animalPurpose,
          vaccineType: data.vaccineType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getDataById = async () => {
    await getAnimalFarmById();
  };

  const getAnimalFarmById = async () => {
    const params = await {
      limit: 1000,
      page: 1,
      totalPages: 1,
      sortDir: "DESC",
      sortBy: "id",

      farmId: farmId,
      barnId: "",
      animalTypeId: "",
      animalCode: "",
      name: "",
      statusId: "",
    };

    try {
      const response = await API.getAnimalFarmById(params);
      const data = await response.data;
      if (response.status == 200) {
        getDataToForm(data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataToForm = async (dataRes) => {
    let animalList = [];
    if (dataAll && dataAll.house.length > 0) {
      dataRes.map((item, i) => {
        animalList.push({
          id: item.id,
          house_buiding: item.barnName,
          house_buiding_id: item.barnId,
          animalTypeName: item.animalTypeName,
          animalTypeId: item.animalTypeId,
          name: item.name,
          genderType: item.sex,
          gender:
            item.sex === "m"
              ? "ตัวผู้"
              : item.sex === "f"
              ? "ตัวเมีย"
              : "ไม่ทราบ",
          age_year: item.ageYear,
          age_month: item.ageMonth,
        });
      });

      setForm({
        ...form,
        animalAll: animalList,
        house: dataAll.house,
        res_animals: dataAll.res_animals,
      });
      setHouseList(dataAll.houseList);
    }
  };
  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validateHouse = () => {
    let validated = true;

    console.log(
      "",
      Number(formDesc.deadAmt) + Number(formDesc.sickAmt),
      Number(formDesc.totalAmt)
    );
    if (
      Number(formDesc.deadAmt) + Number(formDesc.sickAmt) >
      Number(formDesc.totalAmt)
    ) {
      addInvalid("sickDeadAmt", "ป่วยและตาย ห้ามเกินจำนวนสัตว์ร่วมฝูง");
      validated = false;
    }

    if (formDesc.animalTypeName == "") {
      addInvalid("animalTypeName", "กรุณาเลือกชนิดสัตว์");
      validated = false;
    }
    console.log("addInvalid", invalid);
    return validated;
  };

  const buttonGroup = () => {
    return (
      <>
        <button
          className="btn btn-add text-white"
          onClick={() => setShowAddHouse(true)}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่ม{t("house_buiding")}
        </button>

        <button
          className="btn btn-add text-white ml-2"
          onClick={() => {
            addDescHouse();
            removeInvalid("sickDeadAmt");
          }}
          disabled={
            (form.house && form.house.length > 0 && !form.checkAddData) ||
            form.modeEditTable1
              ? false
              : true
          }
        >
          <i className="fas fa-plus mr-1"></i>เพิ่มข้อมูล
        </button>
      </>
    );
  };
  // disabled={(form.house && form.house.length > 0 && !form.checkAddData && formDesc.nameHouse != "" || form.modeEditTable1) ? false : true}
  const addDescHouse = (nameTab) => {
    setForm({ ...form, checkAddData: true });
    setHouseSelectValue([]);
    setAnimalSelectValue([]);
    setFormDesc({
      ...formDesc,
      nameHouse: nameTab,
      animalTypeName: "",
      totalAmt: "",
      sickAmt: "",
      deadAmt: "",
      sickDeadAmt: "",
    });
    toSave();
  };

  const buttonAddAnimal = () => {
    return (
      <button
        className="btn btn-add text-white"
        onClick={() => {
          if (farmId) {
            setShowAnmByOne(true);
          } else {
            MySwal.fire({
              text: "กรุณาเลือกรหัสฟาร์ม",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
        }}
      >
        <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูลสัตว์รายตัว
      </button>
    );
  };

  const clearFormDescSelectValue = () => {
    setHouseSelectValue([]);
    setAnimalSelectValue([]);
    setFormDesc({
      ...formDesc,
      count: "",
      nameHouse: "",
      animalTypeId: "",
      animalTypeName: "",
      totalAmt: "",
      sickAmt: "",
      deadAmt: "",
      sickDeadAmt: "",
    });
    toSave();
  };

  const delDesc = (index, name) => {
    MySwal.fire({
      text: "ยืนยันการลบข้อมูล",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then((result) => {
      if (result.value) {
        if (form.house && form.house.length > 0) {
          form.house.map((item, i) => {
            if (item.name == name) {
              item.desc.splice(index, 1);
              setForm({ ...form, house: form.house, checkAddData: false });
              clearFormDescSelectValue();
              toSave();
            }
          });
        }
      }
    });
  };

  const descEdit = async (item, idDes, name) => {
    item.checkEdit = true;
    setForm({
      ...form,
      modeEditTable1: true,
    });
    setFormDesc({
      ...formDesc,
      count: item.count,
      animalTypeId: item.animalTypeId,
      animalTypeName: formJson.animalId[item.animalTypeId],
      totalAmt: item.totalAmt,
      sickAmt: item.sickAmt,
      deadAmt: item.deadAmt,
      sickDeadAmt: item.sickDeadAmt,
    });
    setAnimalSelectValue([
      { value: item.animalTypeId, label: formJson.animalId[item.animalTypeId] },
    ]);
    setHouseSelectValue([{ value: name, label: name }]);
    // toSave()
  };

  const saveHouseDesc = async (index, type, itemDesc) => {
    if (validateHouse()) {
      let newBuilding = [...form.house];
      let houseDesc = newBuilding.desc ? [...newBuilding.desc] : [];

      houseDesc.push({
        checkEdit: false,
        animalTypeId: formDesc.animalTypeId,
        animalTypeName: formDesc.animalTypeName,
        totalAmt: formDesc.totalAmt,
        sickAmt: formDesc.sickAmt,
        deadAmt: formDesc.deadAmt,
        sickDeadAmt: Number(formDesc.deadAmt) + Number(formDesc.sickAmt),
      });

      if (type == "single") {
        if (newBuilding[index].desc.length > 0) {
          newBuilding[index].desc.push(houseDesc[0]);
          toSave();
        } else {
          newBuilding[index].desc = houseDesc;
          toSave();
        }
      } else if (type == "all") {
        if (form.house.length > 0) {
          form.house.map((item, i) => {
            if (item.name == form.nameHouse) {
              if (newBuilding[i].desc.length > 0) {
                newBuilding[i].desc.push(houseDesc[0]);
                toSave();
              } else {
                newBuilding[i].desc = houseDesc;
                toSave();
              }
            }
          });
        }
      } else if (type == "edit") {
        itemDesc.checkEdit = false;
        itemDesc.animalTypeId = formDesc.animalTypeId;
        itemDesc.animalTypeName = formDesc.animalTypeName;
        itemDesc.totalAmt = formDesc.totalAmt;
        itemDesc.sickAmt = formDesc.sickAmt;
        itemDesc.deadAmt = formDesc.deadAmt;
        itemDesc.sickDeadAmt =
          Number(formDesc.deadAmt) + Number(formDesc.sickAmt);
        setForm({ ...form });
        toSave();
      }
      setForm({ ...form, checkAddData: false, modeEditTable1: false });
      toSave();
    }
  };

  const addHouse = (value) => {
    let arrayHouse = [...form.house];
    let arrayHouseList = [...houseList];
    arrayHouse.push({ name: value, desc: [], animals: [] });
    arrayHouseList.push({ value: value, label: value });
    setForm({ ...form, house: arrayHouse, checkAddData: true });
    setFormDesc(initialFormDesc);
    setHouseList(arrayHouseList);
    toSave();
  };

  const compareAnmId = (list, value) => {
    let check = false;
    list.forEach((animal, a) => {
      if (animal.id == value) {
        check = true;
      }
    });
    return check;
  };

  const addAnimalByOne = (data) => {
    if (data.animals && data.animals.length > 0) {
      data.anmChooseList.map((chooseList, c) => {
        if (compareAnmId(data.animals, chooseList)) {
          data.animals.map((animal, a) => {
            if (animal.id == chooseList) {
              form.res_animals.push(animal);
              setForm({ ...form, listAnimals: data.anmChooseList });
            }
          });
        }
      });

      toSave();
    }
  };

  const saveEditAnimal = async (data) => {
    if (form.modeAnimal == "edit") {
      let vaccine;
      if (data.choose_disease && data.choose_disease.length > 0) {
        vaccine = data.choose_disease.map((disease, d) => {
          return {
            diseaseTypeId: disease.diseaseTypeId,
            vaccineTypeId: disease.vaccineTypeId,
            vaccineDate: disease.last_date
              ? helper.getDate(disease.last_date)
              : "",
            lotNo: disease.lotNo,
            injectBy: disease.operator,
            injectOther: disease.other_vaccine,
            diseaseHistory: disease.had_disease,
          };
        });
      }
      const dataEditAnimal = {
        farmId: farmId,
        animals: [
          {
            id: data.id ? data.id : "",
            fm01Id: data.fm01Id,
            barnId: data.house_buiding_id,
            animalTypeId: data.animalTypeId,
            animalCode: data.animalId,
            statusId: 1,
            deadDate: data.date_dead,
            deadCause: data.cause_death_type,
            deadReason: data.cause_death_type_other,
            name: data.name,
            sex: data.genderType,
            ageYear: data.age_year,
            ageMonth: data.age_month,
            nid: data.no_animal_ear,
            microchip: data.no_microchip,
            species: data.breed,
            purposeId: data.purpose_raising,
            nidCode: data.no_nid,
            husbandryId: data.style_raising,
            vaccinationStatus: data.is_vaccine,
            vaccine: vaccine ? vaccine : [],
          },
        ],
      };

      try {
        const response = await API.postFarmEditAnimal(dataEditAnimal);
        if (response.status === 200) {
          getDiseaseById();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const animalEdit = (data, index) => {
    setShowAddAnimal(true);
    setForm({
      ...form,
      modeAnimal: "edit",
      dataAnimalEdit: { data: data, index: index },
    });
  };

  const animalRemove = (index, animalTypeId) => {
    let delAnimals = [];
    if (form.res_animals && form.res_animals.length > 0) {
      form.res_animals.map((animal, a) => {
        if (animalTypeId == animal.animalTypeId) {
          form.res_animals.splice(index, 1);
          if (animal.id) {
            delAnimals.push(animal.id);
          }
          setForm({ ...form, del_animals: delAnimals });
          toSave();
        }
      });
    }
  };

  const renderTableForBuildAll = (data) => {
    let name = data.name;
    if (data.desc.length > 0) {
      return data.desc.map((item, i) => {
        return renderDesc(item, i, name);
      });
    }
  };

  const renderTableForBuildSingle = (data, name) => {
    if (data.length > 0) {
      return data.map((item, i) => {
        return renderDesc(item, i, name);
      });
    }
  };

  const totalAmtData = () => {
    let total = 0;
    if (form.house && form.house.length > 0) {
      form.house.map((itemH, h) => {
        if (itemH.desc && itemH.desc.length > 0) {
          itemH.desc.map((itemD, d) => {
            total = total + Number(itemD.totalAmt);
          });
        }
      });
    }
    return total || "-";
  };

  const totalAnmSick = () => {
    let total = 0;
    if (form.house && form.house.length > 0) {
      form.house.map((itemH, h) => {
        if (itemH.desc && itemH.desc.length > 0) {
          itemH.desc.map((itemD, d) => {
            total = total + Number(itemD.sickAmt);
          });
        }
      });
    }
    return total || "-";
  };

  const totalAnmDead = () => {
    let total = 0;
    if (form.house && form.house.length > 0) {
      form.house.map((itemH, h) => {
        if (itemH.desc && itemH.desc.length > 0) {
          itemH.desc.map((itemD, d) => {
            total = total + Number(itemD.deadAmt);
          });
        }
      });
    }
    return total || "-";
  };

  const totalAnmDeadSick = () => {
    let total = 0;
    if (form.house && form.house.length > 0) {
      form.house.map((itemH, h) => {
        if (itemH.desc && itemH.desc.length > 0) {
          itemH.desc.map((itemD, d) => {
            total = total + Number(itemD.sickDeadAmt);
          });
        }
      });
    }
    return total || "-";
  };

  const renderDesc = (item, index, name) => {
    return (
      <tr key={index} className="">
        <td>
          {item.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 "
              star={false}
              placeholder="กรุณาเลือก"
              idName="nameHouse"
              classLabel="normal"
              selectValue={houseSelectValue}
              optionsList={houseList}
              handleChange={(value) => {
                setHouseSelectValue([value]);
                setFormDesc({ ...formDesc, nameHouse: value.label });
                setForm({ ...form, nameHouse: value.label });
              }}
            />
          ) : (
            <span className="text-nowrap">{name}</span>
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="animalTypeName"
              classLabel="normal"
              selectValue={animalSelectValue}
              optionsList={animalList}
              handleChange={(value) => {
                setAnimalSelectValue([value]);
                setFormDesc({
                  ...formDesc,
                  animalTypeName: value.label,
                  animalTypeId: value.value,
                });
              }}
              handleInvalid={() => removeInvalid("animalTypeName")}
              invalid={invalid.animalTypeName}
            />
          ) : (
            formJson.animalId[item.animalTypeId]
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputMask
              mask="9999999"
              classFormGroup="mb-0"
              type="number"
              idName="totalAmt"
              value={formDesc.totalAmt}
              star={false}
              placeholder="ระบุจำนวน"
              handleChange={(value) =>
                setFormDesc({ ...formDesc, totalAmt: value })
              }
              min="0"
              handleInvalid={() => removeInvalid("totalAmt")}
              invalid={invalid.totalAmt}
            />
          ) : (
            item.totalAmt || "-"
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputMask
              mask="9999999"
              classFormGroup="mb-0"
              type="number"
              idName="sickAmt"
              value={formDesc.sickAmt}
              star={false}
              placeholder="ระบุจำนวน"
              handleChange={(value) =>
                setFormDesc({ ...formDesc, sickAmt: value })
              }
              min="0"
              handleInvalid={() => removeInvalid("sickAmt")}
              invalid={invalid.sickAmt}
            />
          ) : (
            item.sickAmt || "-"
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputMask
              mask="9999999"
              classFormGroup="mb-0"
              type="number"
              idName="deadAmt"
              value={formDesc.deadAmt}
              star={false}
              placeholder="ระบุจำนวน"
              handleChange={(value) =>
                setFormDesc({ ...formDesc, deadAmt: value })
              }
              min="0"
              handleInvalid={() => removeInvalid("deadAmt")}
              invalid={invalid.deadAmt}
            />
          ) : (
            item.deadAmt || "-"
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputText
              classFormGroup="mb-0"
              type="number"
              idName="sickDeadAmt"
              value={Number(formDesc.deadAmt) + Number(formDesc.sickAmt)}
              star={false}
              placeholder="ระบุจำนวน"
              handleChange={(value) =>
                setFormDesc({ ...formDesc, sickDeadAmt: value })
              }
              disabled
              handleInvalid={() => removeInvalid("sickDeadAmt")}
              invalid={invalid.sickDeadAmt}
            />
          ) : (
            item.sickDeadAmt || "-"
          )}
        </td>
        <td>
          <div className="d-flex justify-content-center">
            {item.checkEdit ? (
              <button
                className="btn btn-success pt-1 px-2"
                onClick={async () => {
                  saveHouseDesc(index, "edit", item);
                }}
              >
                <img
                  className="img-fluid icon-check"
                  src="/svg/btn-check.svg"
                  alt="check"
                />
              </button>
            ) : (
              <>
                <button
                  className="btn btn-add mr-2"
                  onClick={() => {
                    descEdit(item, item.id, name);
                    removeInvalid("sickDeadAmt");
                  }}
                  disabled={form.modeEditTable1 || form.checkAddData}
                >
                  <img
                    className=" icon-btn"
                    src="/svg/btn-edit.svg"
                    alt="edit"
                  />
                </button>
                <button
                  className="btn btn-del"
                  onClick={() => delDesc(index, name)}
                >
                  <img className=" icon-btn" src="/svg/btn-del.svg" alt="del" />
                </button>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  };

  const renderNewTable = (index, type) => {
    return (
      <tr className="">
        <td className="border-0 text-left">
          <div className="w-100">{type == "single" && form.nameTab}</div>
        </td>
        <td>
          <InputSelect
            classFormGroup="mb-0 w-100"
            star={false}
            placeholder="กรุณาเลือก"
            idName="animalTypeName"
            classLabel="normal"
            selectValue={animalSelectValue}
            optionsList={animalList}
            handleChange={(value) => {
              setAnimalSelectValue([
                { value: value.value, label: value.label },
              ]);
              setFormDesc({
                ...formDesc,
                animalTypeName: value.label,
                animalTypeId: value.value,
              });
            }}
            handleInvalid={() => removeInvalid("animalTypeName")}
            invalid={invalid.animalTypeName}
          />
        </td>
        <td>
          <InputMask
            mask="9999999"
            classFormGroup="mb-0"
            type="number"
            idName="totalAmt"
            value={formDesc.totalAmt}
            star={false}
            placeholder="ระบุจำนวน"
            handleChange={(value) =>
              setFormDesc({ ...formDesc, totalAmt: value })
            }
            min="0"
            handleInvalid={() => removeInvalid("totalAmt")}
            invalid={invalid.totalAmt}
          />
        </td>
        <td>
          <InputMask
            mask="9999999"
            classFormGroup="mb-0"
            type="number"
            idName="sickAmt"
            value={formDesc.sickAmt}
            star={false}
            placeholder="ระบุจำนวน"
            handleChange={(value) =>
              setFormDesc({ ...formDesc, sickAmt: value })
            }
            min="0"
            handleInvalid={() => removeInvalid("sickAmt")}
            invalid={invalid.sickAmt}
          />
        </td>
        <td>
          <InputMask
            mask="9999999"
            classFormGroup="mb-0"
            type="number"
            idName="deadAmt"
            value={formDesc.deadAmt}
            star={false}
            placeholder="ระบุจำนวน"
            handleChange={(value) =>
              setFormDesc({ ...formDesc, deadAmt: value })
            }
            min="0"
            handleInvalid={() => removeInvalid("deadAmt")}
            invalid={invalid.deadAmt}
          />
        </td>
        <td>
          <InputText
            classFormGroup="mb-0"
            type="number"
            idName="sickDeadAmt"
            value={Number(formDesc.deadAmt) + Number(formDesc.sickAmt)}
            star={false}
            placeholder="ระบุจำนวน"
            handleChange={(value) =>
              setFormDesc({ ...formDesc, sickDeadAmt: value })
            }
            disabled
            handleInvalid={() => removeInvalid("sickDeadAmt")}
            invalid={invalid.sickDeadAmt}
          />
        </td>
        <td className="">
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                saveHouseDesc(index, type);
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </div>
        </td>
      </tr>
    );
  };

  const renderTableAnimal = (data, indexAnm) => {
    return (
      <tr key={indexAnm} className="">
        <td>{data.house_buiding}</td>
        <td>{formJson.animalId[data.animalTypeId]}</td>
        <td>{data.animalId ? data.animalId : "-"}</td>
        <td>{data.name ? data.name : "-"}</td>
        <td>{data.gender ? data.gender : "-"}</td>
        <td>
          {data.age_year && `${data.age_year} ปี`}{" "}
          {data.age_month && `${data.age_month} เดือน`}
        </td>
        <td>
          <div className="d-flex justify-content-center ">
            <button
              className="btn btn-add mr-2 "
              onClick={() => animalEdit(data, indexAnm)}
            >
              <img
                className="img-fluid icon-btn"
                src="/svg/btn-edit.svg"
                alt="edit"
              />
            </button>
            <button
              className="btn btn-del mr-2"
              onClick={() => animalRemove(indexAnm, data.animalTypeId)}
            >
              <img
                className="img-fluid icon-btn"
                src="/svg/btn-del.svg"
                alt="del"
              />
            </button>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="p-4 bg-white ">
      <BoxCard
        classNameBox="m-2"
        title={t("house_buiding")}
        headRight={buttonGroup()}
      >
        <ul className="nav nav-tabs mt-2" id="myTab" role="tablist">
          <li className="nav-item tab">
            <a
              className="nav-link active"
              id="overview-tab"
              data-toggle="tab"
              href="#overview"
              role="tab"
              aria-controls="overview"
              aria-selected="true"
              onClick={() => {
                setFormDesc({
                  ...formDesc,
                  nameHouse: "",
                  totalAmt: "",
                  sickAmt: "",
                  deadAmt: "",
                  sickDeadAmt: "",
                });
                setHouseSelectValue([]);
              }}
            >
              ภาพรวม
            </a>
          </li>

          {form.house &&
            form.house.length > 0 &&
            form.house.map((item, k) => (
              <li className="nav-item tab" key={k}>
                <a
                  className="nav-link"
                  id={`house${k + 1}-tab`}
                  data-toggle="tab"
                  href={`#house${k + 1}`}
                  role="tab"
                  aria-controls={`house${k + 1}`}
                  aria-selected="false"
                  onClick={() => {
                    setFormDesc({
                      ...formDesc,
                      nameHouse: item.name,
                      totalAmt: "",
                      sickAmt: "",
                      deadAmt: "",
                      sickDeadAmt: "",
                    });
                    setForm({ ...form, nameTab: item.name });
                  }}
                >
                  {item.name}
                </a>
              </li>
            ))}
        </ul>
        <div className="tab-content mb-2" id="myTabContent">
          <div
            className="tab-pane fade show active card-tab"
            id="overview"
            role="tabpanel"
            aria-labelledby="overview-tab"
          >
            <table className="table sameWidth table-striped table-bordered my-2">
              <thead>
                <tr className="">
                  <th scope="col">{t("house_buiding")}</th>
                  <th scope="col">ชนิดสัตว์</th>
                  <th scope="col">จำนวนสัตว์ร่วมฝูง</th>
                  <th scope="col">จำนวนป่วย (ตัว) *ไม่รวมตาย*</th>
                  <th scope="col">จำนวนตาย (ตัว)</th>
                  <th scope="col">รวมป่วยและตาย (ตัว)</th>
                  <th scope="col" className="text-center">
                    จัดการ
                  </th>
                </tr>
              </thead>
              <tbody>
                {form.house &&
                  form.house.length > 0 &&
                  form.house.map((itemH, h) => {
                    if (itemH.desc && itemH.desc.length > 0) {
                      return (
                        <React.Fragment key={h}>
                          {renderTableForBuildAll(itemH)}
                        </React.Fragment>
                      );
                    }
                  })}

                <tr>
                  <td>
                    <span className="bold">รวม</span>
                  </td>
                  <td>
                    <span className="bold">-</span>
                  </td>
                  <td>
                    <span className="bold">{totalAmtData()}</span>
                  </td>
                  <td>
                    <span className="bold">{totalAnmSick()}</span>
                  </td>
                  <td>
                    <span className="bold">{totalAnmDead()}</span>
                  </td>
                  <td>
                    <span className="bold">{totalAnmDeadSick()}</span>
                  </td>
                  <td className="text-center">
                    <span className="bold">-</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {form.house &&
            form.house.length > 0 &&
            form.house.map((item, i) => (
              <div
                key={i}
                className="tab-pane fade card-tab"
                id={`house${i + 1}`}
                role="tabpanel"
                aria-labelledby={`house${i + 1}-tab`}
              >
                <table className="table sameWidth table-striped table-bordered my-2">
                  <thead>
                    <tr className="">
                      <th scope="col">{t("house_buiding")}</th>
                      <th scope="col">ชนิดสัตว์</th>
                      <th scope="col">จำนวนสัตว์ร่วมฝูง</th>
                      <th scope="col">จำนวนป่วย (ตัว) *ไม่รวมตาย*</th>
                      <th scope="col">จำนวนตาย (ตัว)</th>
                      <th scope="col">รวมป่วยและตาย (ตัว)</th>
                      <th scope="col" className="text-center">
                        จัดการ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.desc && item.desc.length > 0 && (
                      <React.Fragment key={i}>
                        {renderTableForBuildSingle(item.desc, item.name, i)}
                      </React.Fragment>
                    )}

                    {item.desc && item.desc.length == 0
                      ? renderNewTable(i, "single")
                      : form.checkAddData && renderNewTable(i, "single")}

                    {form.house && form.house.length == 0 && (
                      <DataTableNodata colSpan={7} />
                    )}
                  </tbody>
                </table>
              </div>
            ))}
        </div>
      </BoxCard>

      <BoxCard
        title="ข้อมูลสัตว์"
        classNameBox="mt-3 m-2"
        headRight={buttonAddAnimal()}
      >
        <table className="table sameWidth table-striped table-bordered my-2">
          <thead>
            <tr className="">
              <th scope="col">{t("house_buiding")}</th>
              <th scope="col">ชนิดสัตว์</th>
              <th scope="col">หมายเลข</th>
              <th scope="col">ชื่อ</th>
              <th scope="col">เพศ</th>
              <th scope="col">อายุ</th>
              <th scope="col-2" className="text-center">
                จัดการ
              </th>
            </tr>
          </thead>
          <tbody>
            {form.res_animals && form.res_animals.length > 0 ? (
              form.res_animals.map((animal, a) => (
                <React.Fragment key={a}>
                  {renderTableAnimal(animal, a)}
                </React.Fragment>
              ))
            ) : (
              <DataTableNodata colSpan={7} />
            )}
          </tbody>
        </table>
      </BoxCard>

      {showAddHouse && (
        <ModalAddHouse
          show={showAddHouse}
          close={() => setShowAddHouse(false)}
          save={(value) => addHouse(value)}
        />
      )}

      {showAnmByOne && (
        <ModalAnimalByOne
          show={showAnmByOne}
          close={() => setShowAnmByOne(false)}
          buidingList={houseList}
          barnsList={form.house}
          farmId={farmId}
          save={(data) => {
            addAnimalByOne(data);
          }}
        />
      )}

      {showAddAnimal && (
        <ModalAddAnimal
          show={showAddAnimal}
          mode={form.modeAnimal}
          dataEdit={form.dataAnimalEdit}
          close={() => setShowAddAnimal(false)}
          save={(value) => {
            if (form.modeAnimal == "edit") {
              saveEditAnimal(value);
            }
          }}
          buidingList={houseList}
        />
      )}
    </div>
  );
}

export default OutbreakStep2;
