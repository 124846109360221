import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as helper from "../utils/helper";
import { Form, Row, Col } from "react-bootstrap";
import InputText from "./InputText";
import InputMask from "./InputMask";
import InputSelect from "./InputSelect";
import DataTableNodata from "./DataTableNodata";
import InputDatePicker from "./InputDatePicker";
import BoxCard from "./BoxCard";
import * as API from "../utils/apis";

function DiseaseInvest_Step2({ save, dataAll, modeGet }) {
  const user = useSelector((state) => state.login.result);
  const dispatch = useDispatch();

  const initialForm = {
    farm: "",
    zoo: "",
    corral: "",
    stray: "",
    minor: "",
    naturally: "",
    other: "",
    text_other: "",

    swamp: "",
    canal: "",
    papa: "",
    groundwater: "",
    other_2: "",
    text_other_2: "",

    hot: "",
    cool: "",
    humid: "",
    windy: "",
    rain: "",
    logged: "",
    flood: "",
    other_3: "",
    text_other_3: "",

    distance: "n",
    distance_text: "",

    distance_2: "n",
    approve: "y",
    abattoir: "",

    disease_area: "n",
    distance_3_text: "",

    delicatessen: "",
    cook: "",
    garbage: "",
    other_4: "",
    text_other_4: "",

    vector: "n",
    text_vector: "",

    water_source: "n",

    modeEditTable1: false,
    modeEditTable2: false,

    data_insectProtect: [],
    del_insect: [],
    checkAddInsectPro: false,
    checkEdit: false,
    indexNew: "",

    data_rabies: [],
    del_rabies: [],
    checkAddRabies: false,
    indexNewRabies: "",
  };
  const [form, setForm] = useState(initialForm);

  const initialInsectPro = {
    actionProtectionId: "",
    actionProtectionName: "",
    operateDate: "",
    on_target_name: "",
  };
  const [formInsectPro, setFormInsectPro] = useState(initialInsectPro);

  const initialRabies = {
    locationTypeId: "",
    locationTypeName: "",
    location_name: "",
    animalTypeId: "",
    animalTypeName: "",
    number: "",
  };
  const [formRabies, setFormRabies] = useState(initialRabies);

  const initActionSelectValue = [];
  const [actionSelectValue, setActionSelectValue] = useState(
    initActionSelectValue
  );
  const selectActionList = [];
  const [actionList, setActionList] = useState(selectActionList);

  const initLocationTypeSelectValue = [];
  const [locationTypeSelectValue, setLocationTypeSelectValue] = useState(
    initLocationTypeSelectValue
  );
  const selectLocationTypeList = [];
  const [locationTypeList, setLocationTypeList] = useState(
    selectLocationTypeList
  );

  const initAnimalSelectValue = [];
  const [animalSelectValue, setAnimalSelectValue] = useState(
    initAnimalSelectValue
  );
  const selectAnimalList = [];
  const [animalList, setAnimalList] = useState(selectAnimalList);

  const initOntTrgetSelectValue = [];
  const [ontTrgetSelectValue, setOntTrgetSelectValue] = useState(
    initOntTrgetSelectValue
  );
  const selectOntTrgetList = [
    { value: 1, label: "สำเร็จบางส่วน" },
    { value: 2, label: "สำเร็จทั้งหมด" },
    { value: 3, label: "ไม่สำเร็จ" },
  ];
  const [ontTrgetList, setOntTrgetList] = useState(selectOntTrgetList);

  useEffect(async () => {
    await getAnimal();
    await getActionProtection();
    await getPlace();
  }, []);

  useEffect(async () => {
    if (modeGet == "getById") {
      await getDataById();
    }
  }, [user, modeGet, locationTypeList]);

  useEffect(async () => {
    await toSave();
  }, [form]);

  const toSave = () => {
    save(form);
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActionProtection = async () => {
    try {
      const response = await API.getActionProtection();
      const data = await response.data;
      if (response.status == 200) {
        let actionArray = [];
        data.content.forEach((element) => {
          actionArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setActionList(actionArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPlace = async () => {
    try {
      const response = await API.getPlace("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let locationArray = [];
        data.content.forEach((element) => {
          locationArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setLocationTypeList(locationArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataById = async () => {
    const fm02Env = dataAll.step2_fm02Env;
    let step2_insectProtec = [];
    if (dataAll.data_insectProtect && dataAll.data_insectProtect.length > 0) {
      dataAll.data_insectProtect.map((item, i) => {
        let actionProtectionName;
        actionList.filter((itemC) => {
          if (itemC.value == item.actionProtectionId) {
            actionProtectionName = itemC.label;
          }
        });

        let on_target_id;
        ontTrgetList.filter((itemO) => {
          if (itemO.label == item.result) {
            on_target_id = itemO.value;
          }
        });
        step2_insectProtec.push({
          id: item.id,
          actionProtectionId: item.actionProtectionId,
          actionProtectionName: actionProtectionName,
          operateDate: item.operateDate ? item.operateDate : "",
          on_target_name: item.result,
          on_target_id: on_target_id,
        });
      });
    }

    let step2_rabies = [];
    if (dataAll.data_rabies && dataAll.data_rabies.length > 0) {
      dataAll.data_rabies.map((item, i) => {
        let animalTypeName;
        animalList.filter((itemA) => {
          if (itemA.value == item.animalTypeId) {
            animalTypeName = itemA.label;
          }
        });
        let locationTypeName;
        locationTypeList.filter((itemL) => {
          if (itemL.value == item.placeTypesId) {
            locationTypeName = itemL.label;
          }
        });

        step2_rabies.push({
          id: item.id,
          locationTypeId: item.placeTypesId,
          locationTypeName: locationTypeName,
          location_name: item.name,
          animalTypeId: item.animalTypeId,
          animalTypeName: animalTypeName,
          number: item.animalQty,
        });
      });
    }

    await setForm({
      ...form,

      farm: fm02Env && fm02Env.q21Farm == "y" ? true : false,
      zoo: fm02Env && fm02Env.q21Zoo == "y" ? true : false,
      corral: fm02Env && fm02Env.q21Corral == "y" ? true : false,
      stray: fm02Env && fm02Env.q21Stray == "y" ? true : false,
      minor: fm02Env && fm02Env.q21Minor == "y" ? true : false,
      naturally: fm02Env && fm02Env.q21Naturally == "y" ? true : false,
      other: fm02Env && !fm02Env.q21Other ? false : true,
      text_other: fm02Env && fm02Env.q21Other,

      swamp: fm02Env && fm02Env.q22Swamp == "y" ? true : false,
      canal: fm02Env && fm02Env.q22Canal == "y" ? true : false,
      papa: fm02Env && fm02Env.q22Papa == "y" ? true : false,
      groundwater: fm02Env && fm02Env.q22Groundwater == "y" ? true : false,
      other_2: fm02Env && !fm02Env.q22Other ? false : true,
      text_other_2: fm02Env && fm02Env.q22Other,

      hot: fm02Env && fm02Env.q23Hot == "y" ? true : false,
      cool: fm02Env && fm02Env.q23Cool == "y" ? true : false,
      humid: fm02Env && fm02Env.q23Humid == "y" ? true : false,
      windy: fm02Env && fm02Env.q23Windy == "y" ? true : false,
      rain: fm02Env && fm02Env.q23Rain == "y" ? true : false,
      logged: fm02Env && fm02Env.q23Logged == "y" ? true : false,
      flood: fm02Env && fm02Env.q23Flood == "y" ? true : false,
      other_3: fm02Env && !fm02Env.q23Other ? false : true,
      text_other_3: fm02Env && fm02Env.q23Other,

      distance: fm02Env && fm02Env.q24,
      distance_text: fm02Env && fm02Env.q24Distance,

      distance_2: fm02Env && fm02Env.q25,
      approve: fm02Env && fm02Env.q25Approve,
      abattoir: fm02Env && fm02Env.q25Distance,

      disease_area: fm02Env && fm02Env.q26,
      distance_3_text: fm02Env && fm02Env.q26Distance,

      delicatessen: fm02Env && fm02Env.q27Delicatessen == "y" ? true : false,
      cook: fm02Env && fm02Env.q27Cook == "y" ? true : false,
      garbage: fm02Env && fm02Env.q27Garbage == "y" ? true : false,
      other_4: fm02Env && !fm02Env.q27Other ? false : true,
      text_other_4: fm02Env && fm02Env.q27Other,

      vector: fm02Env && fm02Env.q28,
      text_vector: fm02Env && fm02Env.q28Other,

      water_source: fm02Env && fm02Env.q29,

      data_insectProtect: step2_insectProtec,
      data_rabies: step2_rabies,
    });
  };

  const delInsectPro = (index, item) => {
    let delInsect = [];
    form.data_insectProtect.splice(index, 1);
    if (item.id) {
      delInsect.push(item.id);
    }
    setForm({
      ...form,
      data_insectProtect: form.data_insectProtect,
      del_insect: delInsect,
    });
  };

  const toAddInsectPro = () => {
    setForm({ ...form, checkAddInsectPro: true });
    setOntTrgetSelectValue([]);
    setActionSelectValue([]);
    setFormInsectPro({
      ...formInsectPro,
      actionProtectionId: "",
      actionProtectionName: "",
      operateDate: "",
      on_target_name: "",
      on_target_id: "",
    });
  };

  const insectProEdit = async (item, index) => {
    item.checkEdit = true;
    setForm({ ...form, modeEditTable1: true });
    setFormInsectPro({
      ...formInsectPro,
      actionProtectionId: item.actionProtectionId,
      actionProtectionName: item.actionProtectionName,
      operateDate: item.operateDate,
      on_target_name: item.on_target_name,
      on_target_id: item.on_target_id,
    });

    setActionSelectValue([
      { value: item.actionProtectionId, label: item.actionProtectionName },
    ]);
    setOntTrgetSelectValue([
      { value: item.on_target_id, label: item.on_target_name },
    ]);
  };

  const insectProSave = (item, type) => {
    let arrayInsectPro = [...form.data_insectProtect];
    if (type == "add") {
      arrayInsectPro.push({
        checkEdit: false,
        actionProtectionId: formInsectPro.actionProtectionId,
        actionProtectionName: formInsectPro.actionProtectionName,
        operateDate: formInsectPro.operateDate,
        on_target_name: formInsectPro.on_target_name,
        on_target_id: formInsectPro.on_target_id,
      });

      setForm({
        ...form,
        data_insectProtect: arrayInsectPro,
        checkAddInsectPro: false,
        modeEditTable1: false,
      });
    } else if (type == "edit") {
      item.checkEdit = false;
      item.actionProtectionId = formInsectPro.actionProtectionId;
      item.actionProtectionName = formInsectPro.actionProtectionName;
      item.operateDate = formInsectPro.operateDate;
      item.on_target_name = formInsectPro.on_target_name;
      item.on_target_id = formInsectPro.on_target_id;
      setForm({ ...form, checkAddInsectPro: false, modeEditTable1: false });
    }

    toSave();
  };

  const buttonAddInsectProtect = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddInsectPro()}
          disabled={form.checkAddInsectPro || form.modeEditTable1}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const toAddRabies = () => {
    setForm({ ...form, checkAddRabies: true });
    setLocationTypeSelectValue([]);
    setAnimalSelectValue([]);
    setFormRabies({
      ...formRabies,
      locationTypeId: "",
      locationTypeName: "",
      location_name: "",
      animalTypeId: "",
      animalTypeName: "",
      number: "",
    });
  };

  const delRabies = (index, item) => {
    let delRabies = [];
    form.data_rabies.splice(index, 1);
    if (item.id) {
      delRabies.push(item.id);
    }
    setForm({ ...form, data_rabies: form.data_rabies, del_rabies: delRabies });
  };

  const rabiesEdit = async (item, index) => {
    item.checkEditRabies = true;
    setForm({ ...form, modeEditTable2: true });
    setFormRabies({
      ...formRabies,
      locationTypeId: item.locationTypeId,
      locationTypeName: item.locationTypeName,
      location_name: item.location_name,
      animalTypeId: item.animalTypeId,
      animalTypeName: item.animalTypeName,
      number: item.number,
    });

    setLocationTypeSelectValue([
      { value: item.locationTypeId, label: item.locationTypeName },
    ]);
    setAnimalSelectValue([
      { value: item.animalTypeId, label: item.animalTypeName },
    ]);
  };

  const rabiesSave = (item, type) => {
    let arrayRabies = [...form.data_rabies];
    if (type == "add") {
      arrayRabies.push({
        checkEditRabies: false,
        locationTypeId: formRabies.locationTypeId,
        locationTypeName: formRabies.locationTypeName,
        location_name: formRabies.location_name,
        animalTypeId: formRabies.animalTypeId,
        animalTypeName: formRabies.animalTypeName,
        number: formRabies.number,
      });

      setForm({
        ...form,
        data_rabies: arrayRabies,
        checkAddRabies: false,
        modeEditTable2: false,
      });
    } else if (type == "edit") {
      item.checkEditRabies = false;
      item.locationTypeId = formRabies.locationTypeId;
      item.locationTypeName = formRabies.locationTypeName;
      item.location_name = formRabies.location_name;
      item.animalTypeId = formRabies.animalTypeId;
      item.animalTypeName = formRabies.animalTypeName;
      item.number = formRabies.number;

      setForm({ ...form, checkAddRabies: false, modeEditTable2: false });
    }

    toSave();
  };

  const buttonAddRabies = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddRabies()}
          disabled={form.modeEditTable2 || form.checkAddRabies}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const renderTableInsectProtect = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td className="fitwidth">
          {item.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="actionProtectionName"
              classLabel="normal"
              selectValue={actionSelectValue}
              optionsList={actionList}
              handleChange={(value) => {
                setActionSelectValue([value]);
                setFormInsectPro({
                  ...formInsectPro,
                  actionProtectionName: value.label,
                  actionProtectionId: value.value,
                });
              }}
            />
          ) : item.actionProtectionName ? (
            item.actionProtectionName
          ) : (
            "-"
          )}
        </td>

        <td>
          {item.checkEdit ? (
            <InputDatePicker
              classFormGroup="mb-0 w-100"
              title=""
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={formInsectPro.operateDate}
              star={false}
              handleChange={(value) =>
                setFormInsectPro({ ...formInsectPro, operateDate: value })
              }
            />
          ) : item.operateDate ? (
            helper.getDateFormat(item.operateDate)
          ) : (
            "-"
          )}
        </td>

        <td className="fitwidth">
          {item.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="on_target_name"
              classLabel="normal"
              selectValue={ontTrgetSelectValue}
              optionsList={ontTrgetList}
              handleChange={(value) => {
                setOntTrgetSelectValue([value]);
                setFormInsectPro({
                  ...formInsectPro,
                  on_target_name: value.label,
                  on_target_id: value.value,
                });
              }}
            />
          ) : item.on_target_name ? (
            item.on_target_name
          ) : (
            "-"
          )}
        </td>

        <td className="text-center">
          {item.checkEdit ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                insectProSave(item, "edit");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => insectProEdit(item, index)}
                disabled={form.modeEditTable1 || form.checkAddInsectPro}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button
                className="btn btn-del "
                onClick={() => delInsectPro(index, item)}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  const renderInsectProtect = () => {
    return (
      <React.Fragment>
        <tr>
          <td>
            {form.data_insectProtect && form.data_insectProtect.length + 1}
          </td>
          <td className="fitwidth">
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="actionProtectionName"
              classLabel="normal"
              selectValue={actionSelectValue}
              optionsList={actionList}
              handleChange={(value) => {
                setActionSelectValue([value]);
                setFormInsectPro({
                  ...formInsectPro,
                  actionProtectionName: value.label,
                  actionProtectionId: value.value,
                });
              }}
            />
          </td>

          <td>
            <InputDatePicker
              classFormGroup="mb-0 w-100"
              title=""
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={formInsectPro.operateDate}
              star={false}
              handleChange={(value) =>
                setFormInsectPro({ ...formInsectPro, operateDate: value })
              }
            />
          </td>

          <td className="fitwidth">
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="on_target_name"
              classLabel="normal"
              selectValue={ontTrgetSelectValue}
              optionsList={ontTrgetList}
              handleChange={(value) => {
                setOntTrgetSelectValue([value]);
                setFormInsectPro({
                  ...formInsectPro,
                  on_target_name: value.label,
                });
              }}
            />
          </td>

          <td className="text-center">
            <button
              className="btn btn-success  pt-1 px-2"
              onClick={async () => {
                insectProSave(formInsectPro, "add");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  //====================================

  const renderTableRabies = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td className="fitwidth">
          {item.checkEditRabies ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="locationTypeName"
              classLabel="normal"
              selectValue={locationTypeSelectValue}
              optionsList={locationTypeList}
              handleChange={(value) => {
                setLocationTypeSelectValue([value]);
                setFormRabies({
                  ...formRabies,
                  locationTypeName: value.label,
                  locationTypeId: value.value,
                });
              }}
            />
          ) : item.locationTypeName ? (
            item.locationTypeName
          ) : (
            "-"
          )}
        </td>

        <td className="">
          {item.checkEditRabies ? (
            <InputText
              classFormGroup="mb-0 w-100"
              type="text"
              idName="count"
              value={formRabies.location_name}
              star={false}
              placeholder="กรุณากรอก"
              handleChange={(value) =>
                setFormRabies({ ...formRabies, location_name: value })
              }
            />
          ) : item.location_name ? (
            item.location_name
          ) : (
            "-"
          )}
        </td>

        <td className="fitwidth">
          {item.checkEditRabies ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="animalTypeName"
              classLabel="normal"
              selectValue={animalSelectValue}
              optionsList={animalList}
              handleChange={(value) => {
                setAnimalSelectValue([value]);
                setFormRabies({
                  ...formRabies,
                  animalTypeName: value.label,
                  animalTypeId: value.value,
                });
              }}
            />
          ) : item.animalTypeName ? (
            item.animalTypeName
          ) : (
            "-"
          )}
        </td>

        <td>
          {item.checkEditRabies ? (
            <InputMask
              classFormGroup="mb-0 w-100"
              mask="999999999"
              type="number"
              title=""
              idName="count"
              value={formRabies.number}
              star={false}
              placeholder="ระบุจำนวน"
              classLabel="normal"
              handleChange={(value) =>
                setFormRabies({ ...formRabies, number: value })
              }
            />
          ) : item.number ? (
            item.number
          ) : (
            "-"
          )}
        </td>

        <td className="text-center">
          {item.checkEditRabies ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                rabiesSave(item, "edit");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => rabiesEdit(item, index)}
                disabled={form.modeEditTable2 || form.checkAddRabies}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button
                className="btn btn-del "
                onClick={() => delRabies(index, item)}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  const renderRabies = () => {
    return (
      <React.Fragment>
        <tr>
          <td>
            {form.data_insectProtect && form.data_insectProtect.length + 1}
          </td>
          <td className="fitwidth">
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="locationTypeName"
              classLabel="normal"
              selectValue={locationTypeSelectValue}
              optionsList={locationTypeList}
              handleChange={(value) => {
                setLocationTypeSelectValue([value]);
                setFormRabies({
                  ...formRabies,
                  locationTypeName: value.label,
                  locationTypeId: value.value,
                });
              }}
            />
          </td>

          <td>
            <InputText
              classFormGroup="mb-0 w-100"
              type="text"
              idName="count"
              value={formRabies.location_name}
              star={false}
              placeholder="กรุณากรอก"
              handleChange={(value) =>
                setFormRabies({ ...formRabies, location_name: value })
              }
            />
          </td>

          <td className="fitwidth">
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="animalTypeName"
              classLabel="normal"
              selectValue={animalSelectValue}
              optionsList={animalList}
              handleChange={(value) => {
                setAnimalSelectValue([value]);
                setFormRabies({
                  ...formRabies,
                  animalTypeName: value.label,
                  animalTypeId: value.value,
                });
              }}
            />
          </td>

          <td>
            <InputMask
              classFormGroup="mb-0 w-100"
              mask="999999999"
              type="number"
              title=""
              idName="count"
              value={formRabies.number}
              star={false}
              placeholder="ระบุจำนวน"
              classLabel="normal"
              handleChange={(value) =>
                setFormRabies({ ...formRabies, number: value })
              }
            />
          </td>

          <td className="text-center">
            <button
              className="btn btn-success  pt-1 px-2"
              onClick={async () => {
                rabiesSave(formRabies, "add");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </td>
        </tr>
      </React.Fragment>
    );
  };
  return (
    <div className=" bg-white p-4 ">
      <div className="">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item tab">
            <a
              className="nav-link active"
              id="data-tab"
              data-toggle="tab"
              href="#data"
              role="tab"
              aria-controls="data"
              aria-selected="true"
            >
              โรคทั่วไป
            </a>
          </li>
          <li className="nav-item tab">
            <a
              className="nav-link"
              id="status-tab"
              data-toggle="tab"
              href="#status"
              role="tab"
              aria-controls="status"
              aria-selected="false"
            >
              Rabies
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active card-tab"
            id="data"
            role="tabpanel"
            aria-labelledby="data-tab"
          >
            <BoxCard
              classNameBox="mt-3"
              title="2.1 ลักษณะการเลี้ยงสัตว์ป่วย (เลือกได้มากกว่า 1 ข้อ)"
            >
              <Row>
                <Col lg={12} md={12} sm={12} className="w-100">
                  <div className="d-flex align-items-center flex-wrap ">
                    <div>
                      <Form.Check
                        inline
                        type="checkbox"
                        name="farm"
                        id="farm"
                        checked={form.farm}
                        label="ฟาร์ม"
                        onChange={(e) => {
                          setForm({ ...form, farm: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>
                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="zoo"
                        id="zoo"
                        checked={form.zoo}
                        label="สวนสัตว์"
                        onChange={(e) => {
                          setForm({ ...form, zoo: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>
                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="corral"
                        id="corral"
                        checked={form.corral}
                        label="รายย่อยเลี้ยงในคอก"
                        onChange={(e) => {
                          setForm({ ...form, corral: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>
                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="stray"
                        id="stray"
                        checked={form.stray}
                        label="ไล่ต้อนเร่ร่อนหลายพื้นที่"
                        onChange={(e) => {
                          setForm({ ...form, stray: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>

                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="minor"
                        id="minor"
                        checked={form.minor}
                        label="รายย่อยต้อนหากินในพื้นที่"
                        onChange={(e) => {
                          setForm({ ...form, minor: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>

                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="naturally"
                        id="naturally"
                        checked={form.naturally}
                        label="ปล่อยหากินตามธรรมชาติ"
                        onChange={(e) => {
                          setForm({ ...form, naturally: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>

                    <div className="d-flex">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="other"
                        id="other"
                        checked={form.other}
                        label="อื่นๆ"
                        onChange={(e) => {
                          setForm({ ...form, other: e.target.checked });
                          toSave();
                        }}
                      />
                      <div className="mb-2">
                        <InputText
                          type="text"
                          title=" "
                          idName="text_other"
                          value={form.text_other}
                          star={false}
                          placeholder="ระบุ"
                          classLabel="normal"
                          disabled={form.other ? false : true}
                          handleChange={(value) => {
                            setForm({ ...form, text_other: value });
                            toSave();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </BoxCard>

            <BoxCard
              classNameBox="mt-3"
              title="2.2 แหล่งน้ำที่ใช้เลี้ยงสัตว์ในพื้นที่เกิดโรค (เลือกได้มากกว่า 1 ข้อ)"
            >
              <Row>
                <Col lg={12} md={12} sm={12} className="w-100">
                  <div className="d-flex align-items-center flex-wrap ">
                    <div>
                      <Form.Check
                        inline
                        type="checkbox"
                        name="swamp"
                        id="swamp"
                        checked={form.swamp}
                        label="หนอง/บึง"
                        onChange={(e) => {
                          setForm({ ...form, swamp: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>

                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="canal"
                        id="canal"
                        checked={form.canal}
                        label="แม่น้ำลำคลอง"
                        onChange={(e) => {
                          setForm({ ...form, canal: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>
                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="papa"
                        id="papa"
                        checked={form.papa}
                        label="น้ำประปา"
                        onChange={(e) => {
                          setForm({ ...form, papa: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>

                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="groundwater"
                        id="groundwater"
                        checked={form.groundwater}
                        label="น้ำบาดาล"
                        onChange={(e) => {
                          setForm({ ...form, groundwater: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>

                    <div className="d-flex ">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="other_2"
                        id="other_2"
                        checked={form.other_2}
                        label="อื่นๆ"
                        onChange={(e) => {
                          setForm({ ...form, other_2: e.target.checked });
                          toSave();
                        }}
                      />
                      <div className="mb-2">
                        <InputText
                          type="text"
                          title=" "
                          idName="text_other_2"
                          value={form.text_other_2}
                          star={false}
                          placeholder="ระบุ"
                          classLabel="normal"
                          disabled={form.other_2 ? false : true}
                          handleChange={(value) => {
                            setForm({ ...form, text_other_2: value });
                            toSave();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </BoxCard>

            <BoxCard
              classNameBox="mt-3"
              title="2.3 สภาวะแวดล้อมของพื้นที่เกิดโรค 2 สัปดาห์ ก่อนเกิดโรค (เลือกได้มากกว่า 1 ข้อ)"
            >
              <Row>
                <Col lg={12} md={12} sm={12} className="w-100">
                  <div className="d-flex align-items-center flex-wrap ">
                    <div>
                      <Form.Check
                        inline
                        type="checkbox"
                        name="hot"
                        id="hot"
                        checked={form.hot}
                        label="ร้อน"
                        onChange={(e) => {
                          setForm({ ...form, hot: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>
                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="cool"
                        id="cool"
                        checked={form.cool}
                        label="หนาว"
                        onChange={(e) => {
                          setForm({ ...form, cool: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>
                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="humid"
                        id="humid"
                        checked={form.humid}
                        label="ชื้น"
                        onChange={(e) => {
                          setForm({ ...form, humid: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>
                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="windy"
                        id="windy"
                        checked={form.windy}
                        label="ลมแรง"
                        onChange={(e) => {
                          setForm({ ...form, windy: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>

                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="rain"
                        id="rain"
                        checked={form.rain}
                        label="ฝนตก"
                        onChange={(e) => {
                          setForm({ ...form, rain: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>

                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="logged"
                        id="logged"
                        checked={form.logged}
                        label="มีการระบายน้ำไม่ดีทำให้พื้นที่มีน้ำขังหรือเฉอะแฉะ"
                        onChange={(e) => {
                          setForm({ ...form, logged: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>

                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="flood"
                        id="flood"
                        checked={form.flood}
                        label="มีน้ำท่วม"
                        onChange={(e) => {
                          setForm({ ...form, flood: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>

                    <div className="d-flex">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="other_3"
                        id="other_3"
                        checked={form.other_3}
                        label="อื่นๆ"
                        onChange={(e) => {
                          setForm({ ...form, other_3: e.target.checked });
                          toSave();
                        }}
                      />
                      <div className="mb-2">
                        <InputText
                          type="text"
                          title=" "
                          idName="text_other_3"
                          value={form.text_other_3}
                          star={false}
                          placeholder="ระบุ"
                          classLabel="normal"
                          disabled={form.other_3 ? false : true}
                          handleChange={(value) => {
                            setForm({ ...form, text_other_3: value });
                            toSave();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </BoxCard>

            <BoxCard
              classNameBox="mt-3"
              title="2.4 ในบริเวณที่เกิดโรคมีแหล่งรวมสัตว์(ตลาดนัดค้าสัตว์/แหล่งรวมสัตว์เพื่อส่งต่อ/อื่นๆ)ในรัศมี 10 กิโลเมตรหรือไม่"
            >
              <Row>
                <Col>
                  <Row>
                    <Col lg={1} md={2} sm={6} className="mt-3">
                      <div className="">
                        <Form.Check
                          inline
                          type="radio"
                          name="distance"
                          id="isdistance"
                          value={"n"}
                          checked={form.distance == "n"}
                          label="ไม่มี"
                          onChange={(e) => {
                            setForm({ ...form, distance: e.target.value });
                            // toSave()
                          }}
                        />
                      </div>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          name="distance"
                          id="notdistance"
                          value={"y"}
                          checked={form.distance == "y"}
                          label="มี"
                          onChange={(e) => {
                            setForm({ ...form, distance: e.target.value });
                            // toSave()
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={11} md={11} sm={12}>
                      <Row className="mt-4">
                        <div className="d-flex align-items-center">
                          <p className="mt-3 mr-2">
                            ระบุชื่อและระยะห่างจากพื้นที่เกิดโรค{" "}
                          </p>
                          <InputText
                            type="text"
                            title=""
                            idName="distance_text"
                            value={form.distance_text}
                            star={false}
                            placeholder="ระบุ"
                            classLabel="normal"
                            disabled={form.distance == "y" ? false : true}
                            classFormGroup="mb-0"
                            handleChange={(value) => {
                              setForm({ ...form, distance_text: value });
                              // toSave()
                            }}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BoxCard>

            <BoxCard
              classNameBox="mt-3"
              title="2.5 ในบริเวณที่เกิดโรคมีโรงฆ่าสัตว์ หรือแหล่งฆ่าสัตว์ในรัศมี 10 กิโลเมตร หรือไม่"
            >
              <Row>
                <Col>
                  <Row>
                    <Col lg={1} md={2} sm={6} className="mt-3">
                      <div className="">
                        <Form.Check
                          inline
                          type="radio"
                          name="distance_2"
                          id="isdistance_2"
                          value={"n"}
                          checked={form.distance_2 == "n"}
                          label="ไม่มี"
                          onChange={(e) => {
                            setForm({ ...form, distance_2: e.target.value });
                            // // toSave()
                          }}
                        />
                      </div>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          name="distance_2"
                          id="notdistance_2"
                          value={"y"}
                          checked={form.distance_2 == "y"}
                          label="มี"
                          onChange={(e) => {
                            setForm({ ...form, distance_2: e.target.value });
                            // toSave()
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={11} md={11} sm={12} className="w-100">
                      <Row className="mt-4 mr-2">
                        <Col lg={12} md={12} sm={12} className="mt-3">
                          <div className="">
                            <Form.Check
                              inline
                              type="radio"
                              name="approve"
                              id="isapprove"
                              value={"y"}
                              checked={form.approve == "y"}
                              disabled={form.distance_2 == "y" ? false : true}
                              label="ผ่านการรับรองของกรมปศุสัตว์"
                              onChange={(e) => {
                                setForm({ ...form, approve: e.target.value });
                                // toSave()
                              }}
                            />
                          </div>
                          <div>
                            <Form.Check
                              inline
                              type="radio"
                              name="approve"
                              id="notapprove"
                              value={"n"}
                              checked={form.approve == "n"}
                              disabled={form.distance_2 == "y" ? false : true}
                              label="ไม่ผ่านการรับรองของกรมปศุสัตว์"
                              onChange={(e) => {
                                setForm({ ...form, approve: e.target.value });
                                // toSave()
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className=" ml-0">
                        <div className="d-flex align-items-center">
                          <p className="mr-2">
                            ระบุชื่อโรงฆ่าสัตว์และระยะห่างจากพื้นที่เกิดโรค
                          </p>
                          <InputText
                            type="text"
                            title=""
                            idName="abattoir"
                            value={form.abattoir}
                            star={false}
                            placeholder="ระบุ"
                            classLabel="abattoir"
                            disabled={form.distance_2 == "y" ? false : true}
                            handleChange={(value) => {
                              setForm({ ...form, abattoir: value });
                              // toSave()
                            }}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BoxCard>

            <BoxCard
              classNameBox="mt-3"
              title="2.6 ในบริเวณที่เกิดโรคมีแหล่งรวมสัตว์ที่ไม่ใช่ตลาดนัดค้าสัตว์และโรงฆ่าสัตว์ในรัศมี 10 กิโลเมตร หรือไม่"
            >
              <Row>
                <Col>
                  <Row>
                    <Col lg={1} md={2} sm={6} className="mt-3">
                      <div className="">
                        <Form.Check
                          inline
                          type="radio"
                          name="disease_area"
                          id="isdisease_area"
                          value={"n"}
                          checked={form.disease_area == "n"}
                          label="ไม่มี"
                          onChange={(e) => {
                            setForm({ ...form, disease_area: e.target.value });
                            // toSave()
                          }}
                        />
                      </div>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          name="disease_area"
                          id="notdisease_area"
                          value={"y"}
                          checked={form.disease_area == "y"}
                          label="มี"
                          onChange={(e) => {
                            setForm({ ...form, disease_area: e.target.value });
                            // toSave()
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={11} md={11} sm={12}>
                      <Row className="mt-4">
                        <div className="d-flex ">
                          <p className=" mt-3 mr-2">
                            ระบุชื่อสถานที่ของแหล่งรวมสัตว์
                            วัตถุประสงค์ที่รวมสัตว์ และระยะห่างจากพื้นที่เกิดโรค{" "}
                          </p>
                          <InputText
                            type="text"
                            as="textarea"
                            rows={3}
                            title=""
                            idName="distance_3_text"
                            value={form.distance_3_text}
                            star={false}
                            placeholder="ระบุ"
                            classLabel="normal"
                            disabled={form.disease_area == "y" ? false : true}
                            classFormGroup="mb-0"
                            handleChange={(value) => {
                              setForm({ ...form, distance_3_text: value });
                              // toSave()
                            }}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BoxCard>

            <BoxCard
              classNameBox="mt-3"
              title="2.7 อาหารที่ใช้เลี้ยงสัตว์ในพื้นที่เกิดโรค (เลือกได้มากกว่า 1 ข้อ)"
            >
              <Row>
                <Col lg={12} md={12} sm={12} className="w-100">
                  <div className="d-flex align-items-center flex-wrap ">
                    <div>
                      <Form.Check
                        inline
                        type="checkbox"
                        name="delicatessen"
                        id="delicatessen"
                        checked={form.delicatessen}
                        label="อาหารสำเร็จรูป"
                        onChange={(e) => {
                          setForm({ ...form, delicatessen: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>

                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="cook"
                        id="cook"
                        checked={form.cook}
                        label="อาหารผสมเอง ระบุส่วนผสมหลักๆ"
                        onChange={(e) => {
                          setForm({ ...form, cook: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>
                    <div className="">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="garbage"
                        id="garbage"
                        checked={form.garbage}
                        label="เศษอาหารของคน ระบุชนิดและแหล่งที่มา"
                        onChange={(e) => {
                          setForm({ ...form, garbage: e.target.checked });
                          toSave();
                        }}
                      />
                    </div>

                    <div className="d-flex ">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="other_4"
                        id="other_4"
                        checked={form.other_4}
                        label="อื่นๆ"
                        onChange={(e) => {
                          setForm({ ...form, other_4: e.target.checked });
                          toSave();
                        }}
                      />
                      <div className="mb-2">
                        <InputText
                          type="text"
                          title=" "
                          idName="text_other_4"
                          value={form.text_other_4}
                          star={false}
                          placeholder="ระบุ"
                          classLabel="normal"
                          disabled={form.other_4 ? false : true}
                          handleChange={(value) => {
                            setForm({ ...form, text_other_4: value });
                            toSave();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </BoxCard>

            <BoxCard
              classNameBox="mt-3"
              title="2.8 ในบริเวณที่เกิดโรคพบการระบาดของแมลงหรือปรสิตที่เป็นสัตว์พานะนำโรคในสัตว์หรือไม่"
            >
              <Row>
                <Col>
                  <Row>
                    <Col lg={1} md={2} sm={6} className="mt-3">
                      <div className="">
                        <Form.Check
                          inline
                          type="radio"
                          name="vector"
                          id="isvector"
                          value={"n"}
                          checked={form.vector == "n"}
                          label="ไม่พบ"
                          onChange={(e) => {
                            setForm({ ...form, vector: e.target.value });
                            // toSave()
                          }}
                        />
                      </div>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          name="vector"
                          id="notvector"
                          value={"y"}
                          checked={form.vector == "y"}
                          label="พบ"
                          onChange={(e) => {
                            setForm({ ...form, vector: e.target.value });
                            // toSave()
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={11} md={11} sm={12}>
                      <Row className="mt-4">
                        <div className="d-flex align-items-center">
                          <p className="mt-3 mr-2">
                            ระบุชนิดของแมลงหรือปรสิตที่เป็นสัตว์พานะนำโรค{" "}
                          </p>
                          <InputText
                            type="text"
                            title=""
                            idName="text_vector"
                            value={form.text_vector}
                            star={false}
                            placeholder="ระบุ"
                            classLabel="normal"
                            disabled={form.vector == "y" ? false : true}
                            classFormGroup="mb-0"
                            handleChange={(value) => {
                              setForm({ ...form, text_vector: value });
                              // toSave()
                            }}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BoxCard>

            <BoxCard
              classNameBox="mt-3"
              title="2.9 มีการใช้แหล่งน้ำร่วมกับฟาร์มอื่นหรือไม่"
            >
              <Row>
                <Col>
                  <Row>
                    <Col lg={1} md={2} sm={6} className="my-2">
                      <div className="">
                        <Form.Check
                          inline
                          type="radio"
                          name="water_source"
                          id="iswater_source"
                          value={"n"}
                          checked={form.water_source == "n"}
                          label="ไม่มี"
                          onChange={(e) => {
                            setForm({ ...form, water_source: e.target.value });
                            // toSave()
                          }}
                        />
                      </div>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          name="water_source"
                          id="notwater_source"
                          value={"y"}
                          checked={form.water_source == "y"}
                          label="มี"
                          onChange={(e) => {
                            setForm({ ...form, water_source: e.target.value });
                            // toSave()
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BoxCard>

            <BoxCard
              classNameBox="mt-3"
              title="2.10 การป้องกันแมลงในพื้นที่"
              headRight={buttonAddInsectProtect()}
            >
              <div className="">
                <table className="table table-striped  table-bordered my-2">
                  <thead>
                    <tr>
                      <th scope="col">ลำดับ</th>
                      <th scope="col">การดำเนินการ</th>
                      <th scope="col">การดำเนินการ</th>
                      <th scope="col">สำเร็จตามเป้าหมาย</th>
                      <th scope="col">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.data_insectProtect &&
                    form.data_insectProtect.length > 0
                      ? form.data_insectProtect.map((item, i) => {
                          return (
                            <React.Fragment key={i}>
                              {" "}
                              {renderTableInsectProtect(item, i)}
                            </React.Fragment>
                          );
                        })
                      : null}
                    {form.checkAddInsectPro &&
                      renderInsectProtect(form.data_insectProtect)}
                    {form.data_insectProtect &&
                      form.data_insectProtect.length == 0 &&
                      !form.checkAddInsectPro && (
                        <DataTableNodata colSpan={5} />
                      )}
                  </tbody>
                </table>
              </div>
            </BoxCard>
          </div>
          <div
            className="tab-pane fade card-tab"
            id="status"
            role="tabpanel"
            aria-labelledby="status-tab"
          >
            <BoxCard
              classNameBox="mt-3"
              title="2.11 สถานที่ใกล้เคียง (Rabies)"
              headRight={buttonAddRabies()}
            >
              <div className="">
                <table className="table table-striped  table-bordered my-2 ">
                  <thead>
                    <tr>
                      <th scope="col">ลำดับ</th>
                      <th scope="col">ประเภทสถานที่</th>
                      <th scope="col">ชื่อสถานที่</th>
                      <th scope="col">ชนิดสัตว์</th>
                      <th scope="col">จำนวนสัตว์(ตัว)</th>
                      <th scope="col">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.data_rabies && form.data_rabies.length > 0
                      ? form.data_rabies.map((item, i) => {
                          return (
                            <React.Fragment key={i}>
                              {" "}
                              {renderTableRabies(item, i)}
                            </React.Fragment>
                          );
                        })
                      : null}
                    {form.checkAddRabies && renderRabies(form.data_rabies)}
                    {form.data_rabies &&
                      form.data_rabies.length == 0 &&
                      !form.checkAddRabies && <DataTableNodata colSpan={6} />}
                  </tbody>
                </table>
              </div>
            </BoxCard>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiseaseInvest_Step2;
