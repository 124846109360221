import join from 'url-join';
import store from "../redux/store";
import { loading } from "../redux/actions/loadingAction";
import * as helper from "../utils/helper";
const isAbsoluteURLRegex = /^(?:\w+:)\/\//

const checkLoadingRequest = ['amphurs', 'tambons', 'village'] //  can add more if need not loading in request
let numberOfAjaxCAllPending = 0;

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

export const requestHandler = request => {

  const accessToken = helper.sessionGet('token');
  const token = JSON.parse(accessToken)

  if (isHandlerEnabled(request)) {
    numberOfAjaxCAllPending++;
    if (request) {
      let mainUrl = request?.url.split('?')[0]
      let subUrl = mainUrl.split('/')

      if (!checkLoadingRequest.find(item => item === subUrl[subUrl.length - 1])) {
        store.dispatch(loading(true));
      }
    } else {
      store.dispatch(loading(true));
    }
  }

  if (!isAbsoluteURLRegex.test(request.url)) {
    request.url = join(process.env.REACT_APP_API_ENDPOINT, request.url);
  }

  if (accessToken) request.headers.Authorization = `${token.tokenType} ${token.accessToken}`;
  request.headers["OAuth"] = "DLD";
  request.timeout = 85000;
  return request;
};

export const successHandler = response => {

  if (isHandlerEnabled(response)) {
    numberOfAjaxCAllPending--;
    if (numberOfAjaxCAllPending === 0) {
      store.dispatch(loading(false));
      numberOfAjaxCAllPending = 0
    }
  }
  return response;
};

export const errorHandler = error => {
  if (isHandlerEnabled(error.config)) {
    numberOfAjaxCAllPending--;
    store.dispatch(loading(false));
  }
  return Promise.reject({ ...error });
};