import React, { useState, useEffect } from "react";
import * as helper from "../utils/helper";
import { useSelector } from 'react-redux';
import BoxCard from "./BoxCard";
import InputText from "./InputText";
import UploadImage from "./UploadImage";
import DataTableNodata from "../components/DataTableNodata";
import Swal from 'sweetalert2';

let delFile = []

function OutbreakStep7({ save, dataAll, modeGet }) {
  const user = useSelector(state => state.login.result)

  const initialForm = {
    data_file: [],
    del_file: [],
    checkAddFile: false,
  };
  const [form, setForm] = useState(initialForm);

  const initialFormFile = {
    url: "",
    file: "",
    description: "",
    date_file: "",
    name: ""
  };
  const [formFile, setFormFile] = useState(initialFormFile);

  useEffect(() => {
    if (modeGet === "getById") {
      getDataById();
    }
    if (modeGet === "dsGetById") {
      getDataById();
    }
  }, [user])

  const getDataById = () => {
    setForm(dataAll)
  }

  const toAddFile = () => {
    setForm({ ...form, checkAddFile: true })
    setFormFile({ ...formFile, date_file: Date.now(), description: "", file: "", name: "" })
  };

  const removeImage = (index, item) => {

    form.data_file.splice(index, 1);
    if (item.id) {
      delFile.push(item.id)
    }

    setForm({ ...form, data_file: form.data_file, del_file: delFile });
  }

  const fileSave = (data) => {
    if (data.file && data.name) {
      let arrayFile = [...form.data_file];
      arrayFile.push({
        file: data.file,
        name: data.name,
        description: data.description,
        date_file: Date.now()
      })

      // ส่งไปหน้า Outbreak_Save
      setForm({ ...form, data_file: arrayFile, checkAddFile: false });
      toSave()
    } else {
      Swal.fire(
        'พบข้อผิดพลาด',
        'กรุณาเลือกไฟล์',
        'error'
      )
    }
  }

  const buttonAddFile = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button className="btn btn-add text-white" onClick={() => toAddFile()}
          disabled={form.checkAddFile}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มไฟล์
        </button>
      </div>
    );
  };

  useEffect(async () => {
    await toSave()
  }, [form])

  const toSave = () => {
    save(form)
  }

  const renderTableFile = (data, index) => {
    return (
      <tr key={index}  >
        <td>{index + 1}</td>
        <td>
          {
            data.id ?
              <span className="link"
                onClick={() => {
                  data.id ? window.open(data.url, '_blank').focus() : window.open("", '_blank').focus()
                }}
              >
                {data.name || '-'}
              </span>
              :
              data.name
          }

        </td>
        <td>{data.description || '-'}</td>
        <td>{helper.getDateFormat(data.date_file)}</td>
        <td className="text-center">
          <button className="btn btn-del " onClick={() => removeImage(index, data)}>
            <img className="img-fluid icon-btn " src="/svg/btn-del.svg" alt="del" />
          </button>
        </td>
      </tr>
    )
  }

  const renderAddFile = () => {
    return (
      <>
        <tr>
          <td>{form.data_file && (form.data_file.length + 1)}</td>
          <td>
            <UploadImage title=" " classLabel="normal mt-2" star={false} type="fileName" typeSave="base64" isRequire
              onChange={(name, file) => setFormFile({ ...formFile, file: file, name: name })} id="imgfile" position="left"
              files={(formFile.name === null || formFile.name === "") ? "" : formFile.name} />
          </td>

          <td >
            <InputText
              classFormGroup="mb-0 "
              type="text"
              idName="count"
              value={formFile.description}
              star={false}
              placeholder="โปรดระบุ"
              handleChange={(value) => setFormFile({ ...formFile, description: value })}
            />
          </td>

          <td>{helper.getDateFormat(formFile.date_file)}</td>
          <td className="text-center">
            <button className="btn btn-success  pt-1 px-2"
              onClick={async () => {
                fileSave(formFile)
              }}>
              <img className="img-fluid icon-check" src="/svg/btn-check.svg" alt="check" />
            </button>
          </td>
        </tr>
      </>
    )
  }

  return (
    <div className=" bg-white p-4 ">
      <BoxCard title="แนบไฟล์" classNameBox="" headRight={buttonAddFile()}>
        <div className="table-responsive">
          <table className="table table-striped table-bordered my-2 ">
            <thead>
              <tr>
                <th scope="col">ลำดับ</th>
                <th scope="col">อัปโหลดไฟล์</th>
                <th scope="col">คำอธิบาย</th>
                <th scope="col">วันที่อัปโหลด</th>
                <th className="text-center" scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              {form.data_file && form.data_file.length > 0 ?
                form.data_file.map((item, i) => {
                  return <React.Fragment key={i} > {renderTableFile(item, i)}</React.Fragment>
                })
                : null
              }
              {form.checkAddFile && renderAddFile(form.data_file)}
              {form.data_file && form.data_file.length === 0 && !form.checkAddFile &&
                <DataTableNodata colSpan="5" />
              }
            </tbody>
          </table>
        </div>
      </BoxCard>
    </div>
  )
}

export default OutbreakStep7

