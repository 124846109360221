import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import MySwal from "sweetalert2";

import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import InputMask from "../../../components/InputMask";
import Breadcrumb from "../../../components/Breadcrumb";
import InputText from "../../../components/InputText";

function User_Group_Save(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/user/group-user", name: "กลุ่มผู้ใช้งาน" },
    { path: "active", name: "บันทึกข้อมูลกลุ่มผู้ใช้งาน" },
  ];

  const initialForm = {
    chooseSelect: false,
    //data to save
    name: "",
    description: "",
    statusId: "",
    mview: "n",
    mcreate: "n",
    mupdate: "n",
    mdelete: "n",
    mexport: "n",
    mcopy: "n",
    groupMenu: [],

    checkEdit: false,
    checkAddMenu: false,
    isOnAdding: true,
    isMenuAdding: false,
    defaultEditData: false,
  };
  const [form, setForm] = useState(initialForm);

  const initInvalidMsg = {
    name: "",
    description: "",
    name: "",
    description: "",
    statusId: "",
    mrole: "",
    groupMenu: "",
    moduleId: "",
    menuId: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const initialGroupMenu = {
    moduleId: "",
    menuId: "",
  };
  const [formGroupMenu, setFormGroupMenu] = useState(initialGroupMenu);
  const [menuSelectValue, setMenuSelectValue] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [menuListDefault, setMenuListDefault] = useState([]);

  const [moduleSelectValue, setModuleSelectValue] = useState([]);
  const [moduleList, setModuleList] = useState([]);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  // =================

  useEffect(async () => {
    getMenu();
    getModule();
  }, []);

  useEffect(async () => {
    if (location.pathname.includes("edit") && !form.chooseSelect) {
      getUserGroupById(props.match.params.id);
    }
  }, [moduleList, menuListDefault]);

  useEffect(async () => {
    let menuListFilter = menuList.filter(
      (item) => item.moduleId === moduleSelectValue.value
    );
    setMenuList(menuListFilter);
    if (!form.defaultEditData) {
      formGroupMenu["menuId"] = "";
    }
  }, [moduleSelectValue]);

  const getModule = async () => {
    try {
      const response = await API.getModule();
      const data = await response?.data;
      if (response.status === 200) {
        let moduleArray = [];
        data.content.forEach((element) => {
          moduleArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setModuleList(moduleArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMenu = async () => {
    try {
      const response = await API.getMenu("", "", "", "1", "1000");
      const data = await response?.data;
      if (response.status === 200) {
        let menuArray = [];
        data.content.forEach((element) => {
          menuArray.push({
            value: element.id,
            label: element.name,
            moduleId: element.moduleId,
          });
        });
        setMenuListDefault(menuArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserGroupById = async (id) => {
    dispatch(loading(true));
    try {
      const response = await API.getUserGroupById(id);
      const data = await response?.data;
      if (response.status === 200) {
        dispatch(loading(false));
        if (menuListDefault.length === 0) {
          getMenu();
        }
        let groupMenuArray = [];
        let moduleArray;
        let menuArray;
        if (moduleList.length > 0) {
          data.groupMenu.map((element) => {
            moduleArray = moduleList.filter(
              (item) => item.value === element.moduleId
            );
            menuArray = menuListDefault.filter((item) => {
              return (
                item.moduleId === element.moduleId &&
                item.value === element.menuId
              );
            });
            groupMenuArray.push({
              moduleId: moduleArray[0],
              menuId: menuArray[0],
            });
          });
        }
        setForm({
          ...form,
          name: data.name,
          description: data.description,
          mview: data.mview,
          mcreate: data.mcreate,
          mupdate: data.mupdate,
          mdelete: data.mdelete,
          mexport: data.mexport,
          mcopy: data.mcopy,
          statusId: data.statusId,
          groupMenu: groupMenuArray,
        });
        setStatusSelectValue(
          selectStatusList.filter((item) => item.value === data.statusId)
        );
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = (type, item = {}) => {
    let validated = true;

    if (type === "checkDuplicate") {
      //checkDup
      let observer = false;
      let groupMenu = form.groupMenu;
      groupMenu.forEach((element) => {
        observer = observer || element.menuId == item.menuId;
      });
      if (observer) {
        addInvalid("menuId", "รายการเมนูซ้ำ");
        validated = false;
      }
    }
    if (type === "checkAll") {
      if (form.name === "") {
        addInvalid("name", "กรุณากรอกชื่อกลุ่มผู้ใช้");
        validated = false;
      }

      if (form.description === "") {
        addInvalid("description", "กรุณากรอกรายละเอียด");
        validated = false;
      }

      if (form.statusId === "") {
        addInvalid("statusId", "กรุณาระบุสถานะ");
        validated = false;
      }
      if (
        form.mcreate === "n" &&
        form.mdelete === "n" &&
        form.mupdate === "n" &&
        form.mview === "n" &&
        form.mexport === "n" &&
        form.mcopy === "n"
      ) {
        addInvalid("mrole", "กรุณาระบุสิทธิการใช้งาน");
        validated = false;
      }
    }
    if (type === "checkTable" || type === "checkAll") {
      if (formGroupMenu.moduleId === "" && form.isMenuAdding) {
        addInvalid("moduleId", "กรุณาเลือก Module");
        validated = false;
      }
      if (formGroupMenu.menuId === "" && form.isMenuAdding) {
        addInvalid("menuId", "กรุณาเลือก Menu");
        validated = false;
      }
    }

    return validated;
  };

  //to save and cancel form-------------------------------------------
  const toCancel = () => {
    history.push("/data-manage/users/group-user");
  };

  const toSaveData = async () => {
    let response;
    //destructure
    let groupMenu = form.groupMenu;
    let menuObj = [];
    groupMenu.map((element) => {
      menuObj.push({
        userGroupId: user.userGroup.id,
        menuId: element.menuId.value,
        moduleId: element.menuId.moduleId,
      });
    });
    let formData = {
      name: form.name,
      description: form.description,
      statusId: form.statusId,
      mview: form.mview,
      mcreate: form.mcreate,
      mupdate: form.mupdate,
      mdelete: form.mdelete,
      mexport: form.mexport,
      mcopy: form.mcopy,
      groupMenu: menuObj,
    };

    MySwal.fire({
      text: `ต้องการบันทึก`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (value) => {
      if (value.isConfirmed) {
        try {
          response = location.pathname.includes(
            "/data-manage/users/group-user/save-user-group"
          )
            ? await API.saveUserGroup(formData)
            : await API.updateUserGroup(props.match.params.id, formData);
          if (response.status === 200) {
            dispatch(loading(false));
            MySwal.fire({
              text: "บันทึกกลุ่มผู้ใช้งานสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then((result) => {
              if (result.value) {
                history.push("/data-manage/users/group-user");
              }
            });
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response.status === 401) {
            console.log(error.response);
            dispatch(logout({ history }));
          }
        }
      }
    });
  };

  //to manage menu----------------------------------------------------
  const toConfirm = async () => {
    if (validate("checkAll")) {
      toSaveData();
    }
  };

  const delMenu = (index) => {
    form.groupMenu.splice(index, 1);
    setForm({ ...form, groupMenu: form.groupMenu });
  };

  const groupMenuEdit = async (item, index) => {
    item.checkEdit = true;
    setForm({ ...form, checkEdit: true, defaultEditData: true });
    selectModule(item.moduleId);
    setMenuSelectValue(item.menuId);
    setFormGroupMenu({
      ...formGroupMenu,
      moduleId: item.moduleId,
      menuId: item.menuId,
    });
  };
  const groupMenuSave = (item, type) => {
    //check duplicate
    let checkPass = validate("checkDuplicate", item);

    if (checkPass === true) {
      form["isMenuAdding"] = false;
      let arrayMenu = form.groupMenu;
      if (type === "add") {
        arrayMenu.push({
          checkEdit: false,
          moduleId: formGroupMenu.moduleId,
          menuId: formGroupMenu.menuId,
        });

        setForm({
          ...form,
          groupMenu: arrayMenu,
          checkEdit: false,
          checkAddMenu: false,
        });
      } else if (type === "edit") {
        item.checkEdit = false;
        item.moduleId = formGroupMenu.moduleId;
        item.menuId = formGroupMenu.menuId;
        setForm({ ...form, checkEdit: false, checkAddMenu: false });
      }

      if (form.checkAddMenu) {
        setModuleSelectValue([]);
        setMenuSelectValue([]);
        setFormGroupMenu(initialGroupMenu);
      }
    } else {
      //
    }
  };

  const selectModule = (value) => {
    setMenuSelectValue([]);
    setModuleSelectValue(value);
    setMenuList(menuListDefault);
  };

  const addRole = (value, role) => {
    let isChecked = value.target.checked;
    console.log("isChecked", isChecked);
    if (isChecked) {
      form[role] = "y";
      console.log("AAAA");
    } else {
      form[role] = "n";
      console.log("BBBB");
    }
  };
  const setCheck = (name) => {
    if (form[name] === "y") {
      return true;
    }
  };

  const toAddMenu = () => {
    setModuleSelectValue([]);
    setMenuSelectValue([]);
    setFormGroupMenu(initialGroupMenu);
    setForm({
      ...form,
      checkAddMenu: true,
      isOnAdding: false,
      isMenuAdding: true,
    });
  };

  const renderTableMenu = (item, index) => {
    return (
      <tr key={item + index}>
        <td>{index + 1}</td>
        <td className="fitwidth">
          {item.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="module"
              classLabel="normal"
              selectValue={moduleSelectValue}
              optionsList={moduleList}
              handleChange={(value) => {
                form["defaultEditData"] = false;
                selectModule(value);
                setFormGroupMenu({ ...formGroupMenu, moduleId: value });
              }}
              handleInvalid={() => removeInvalid("moduleId")}
              invalid={invalid.moduleId}
            />
          ) : item.moduleId ? (
            item.moduleId.label
          ) : (
            "-"
          )}
        </td>
        <td className="fitwidth">
          {item.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="menu"
              classLabel="normal"
              selectValue={menuSelectValue}
              optionsList={menuList}
              handleChange={(value) => {
                setMenuSelectValue(value);
                setFormGroupMenu({ ...formGroupMenu, menuId: value });
              }}
              handleInvalid={() => removeInvalid("menuId")}
              invalid={invalid.menuId}
            />
          ) : item.menuId ? (
            item.menuId.label
          ) : (
            "-"
          )}
        </td>

        <td className="text-center">
          {item.checkEdit ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                formGroupMenu.moduleId && formGroupMenu.menuId
                  ? groupMenuSave(item, "edit")
                  : validate("checkTable");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                disabled={form.checkEdit || form.checkAddMenu}
                onClick={() => {
                  form["isMenuAdding"] = true;
                  formGroupMenu["menuId"] = item.menuId;
                  groupMenuEdit(item, index);
                }}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button
                className="btn btn-del "
                onClick={() => delMenu(index)}
                disabled={form.checkEdit || form.checkAddMenu}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };
  const renderMenu = () => {
    return (
      <>
        <tr>
          <td>{form.groupMenu.length + 1}</td>
          <td className="fitwidth">
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="module"
              classLabel="normal"
              selectValue={moduleSelectValue}
              optionsList={moduleList}
              handleChange={(value) => {
                selectModule(value);
                setFormGroupMenu({ ...formGroupMenu, moduleId: value });
              }}
              handleInvalid={() => removeInvalid("moduleId")}
              invalid={invalid.moduleId}
            />
          </td>
          <td className="fitwidth">
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="menu"
              classLabel="normal"
              selectValue={menuSelectValue}
              optionsList={menuList}
              handleChange={(value) => {
                setMenuSelectValue(value);
                setFormGroupMenu({
                  ...formGroupMenu,
                  menuId: value ? value : {},
                });
              }}
              handleInvalid={() => removeInvalid("menuId")}
              invalid={invalid.menuId}
            />
          </td>

          <td className="text-center">
            <button
              className="btn btn-success  pt-1 px-2"
              onClick={async () => {
                formGroupMenu.menuId && formGroupMenu.moduleId
                  ? groupMenuSave(formGroupMenu, "add")
                  : validate("checkTable");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </td>
        </tr>
      </>
    );
  };

  const buttonAddMenu = () => {
    return (
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddMenu()}
          disabled={form.checkAddMenu || form.checkEdit}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูลเมนู
        </button>
      </div>
    );
  };

  return (
    <div>
      <div className="pl-lg-4 pl-md-4 pl-sm-2 bg-white d-flex flex-wrap align-items-center ml30">
        <div className="mr-auto p-2">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2">
          <button className="btn btn-danger" onClick={() => toCancel()}>
            ยกเลิก
          </button>

          <button
            className="btn btn-success mx-3"
            onClick={() => toConfirm()}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`/data-manage/users/group-user/edit`)
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="1. ข้อมูลทั่วไป" star={true}>
            <Row className="mb-2 ">
              <Col lg={2} md={4} sm={12} xs={12}>
                <InputText
                  type="text"
                  title="ชื่อกลุ่ม"
                  idName="name"
                  value={form.name}
                  star={true}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) => setForm({ ...form, name: value })}
                  handleInvalid={() => removeInvalid("name")}
                  invalid={invalid.name}
                />
              </Col>

              <Col lg={8} md={4} sm={12} xs={12}>
                <InputMask
                  title="รายละเอียด"
                  idName="description"
                  star={true}
                  value={form.description}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) =>
                    setForm({ ...form, description: value })
                  }
                  handleInvalid={() => removeInvalid("description")}
                  invalid={invalid.description}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard title="2. สิทธิการใช้งาน" classNameBox="mt-4" star={true}>
            {invalid.mrole ? (
              <div className="invalid-error">{invalid.mrole}</div>
            ) : (
              ""
            )}

            <Row className="mb-4 mt-4">
              <Col lg={1} md={2} sm={12}>
                <div className="justify-content-center">การเชื่อมโยง: </div>
              </Col>
              <Col lg={11} md={10} sm={12}>
                <div className="d-flex">
                  <div className="mr-4">
                    <input
                      type="checkbox"
                      id="mcreate"
                      name="checkboxAdd"
                      onClick={(value) => addRole(value, "mcreate")}
                      defaultChecked={setCheck("mcreate")}
                      invalid={invalid.mrole}
                      onChange={() => removeInvalid("mrole")}
                    />
                    <label htmlFor="mcreate" className="ml-2 normal">
                      เพิ่ม
                    </label>
                  </div>

                  <div className="mr-4">
                    <input
                      type="checkbox"
                      id="mdelete"
                      name="checkboxDelete"
                      onClick={(value) => addRole(value, "mdelete")}
                      defaultChecked={setCheck("mdelete")}
                      invalid={invalid.mrole}
                      onChange={() => removeInvalid("mrole")}
                    />
                    <label htmlFor="mdelete" className="ml-2 normal">
                      ลบ
                    </label>
                  </div>

                  <div className="mr-4">
                    <input
                      type="checkbox"
                      id="mupdate"
                      name="checkboxEdit"
                      onClick={(value) => addRole(value, "mupdate")}
                      defaultChecked={setCheck("mupdate")}
                      invalid={invalid.mrole}
                      onChange={() => removeInvalid("mrole")}
                    />
                    <label htmlFor="mupdate" className="ml-2 normal">
                      แก้ไข
                    </label>
                  </div>

                  <div className="mr-4">
                    <input
                      type="checkbox"
                      id="mview"
                      name="checkboxQuery"
                      onClick={(value) => addRole(value, "mview")}
                      defaultChecked={setCheck("mview")}
                      invalid={invalid.mrole}
                      onChange={() => removeInvalid("mrole")}
                    />
                    <label htmlFor="mview" className="ml-2 normal">
                      ดูข้อมูล
                    </label>
                  </div>

                  <div className="mr-4">
                    <input
                      type="checkbox"
                      id="mexport"
                      name="checkboxSendDoc"
                      onClick={(value) => addRole(value, "mexport")}
                      defaultChecked={setCheck("mexport")}
                      invalid={invalid.mrole}
                      onChange={() => removeInvalid("mrole")}
                    />
                    <label htmlFor="mexport" className="ml-2 normal">
                      ส่งออกรายงาน
                    </label>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      id="mcopy"
                      name="checkboxCopy"
                      onClick={(value) => addRole(value, "mcopy")}
                      defaultChecked={setCheck("mcopy")}
                      invalid={invalid.mrole}
                      onChange={() => removeInvalid("mrole")}
                    />
                    <label htmlFor="mcopy" className="ml-2 normal">
                      คัดลอก
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="3. สิทธิการใช้งาน Module"
            classNameBox="mt-4"
            headRight={buttonAddMenu()}
            star={true}
          >
            {invalid.menuId ? (
              <div className="invalid-error">{invalid.menuId}</div>
            ) : (
              ""
            )}
            <Row className="mb-4">
              <table className="table table-striped table-bordered my-4 ">
                <thead className="text-center">
                  <tr>
                    <th scope="col" className="order-width">
                      ลำดับ
                    </th>
                    <th scope="col">Module</th>
                    <th scope="col">Menu</th>
                    <th scope="col">จัดการ</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {form.groupMenu && form.groupMenu.length > 0
                    ? form.groupMenu.map((item, i) => {
                        return renderTableMenu(item, i);
                      })
                    : ""}
                  {form.checkAddMenu && renderMenu(form.groupMenu)}

                  {form.groupMenu &&
                    form.groupMenu.length == 0 &&
                    form.isOnAdding && (
                      <td
                        scope="col"
                        colSpan={15}
                        className="text-center font14"
                      >
                        ไม่มีข้อมูล
                      </td>
                    )}
                </tbody>
              </table>
            </Row>
          </BoxCard>

          <BoxCard>
            <Row>
              <Col lg={3} md={6} sm={12} xs={12}>
                <InputSelect
                  title="สถานะ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  star={true}
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleSelectValue={(value) =>
                    setStatusSelectValue([
                      { value: value.value, label: value.label },
                    ])
                  }
                  handleChange={(value) =>
                    setForm({ ...form, statusId: value.value })
                  }
                  handleInvalid={() => removeInvalid("statusId")}
                  invalid={invalid.statusId}
                />
              </Col>
            </Row>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default User_Group_Save;
