import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";

import * as API from "../../utils/apis";
import * as helper from "../../utils/helper";

import BoxCard from "../../components/BoxCard";
import InputSelect from "../../components/InputSelect";
import Breadcrumb from "../../components/Breadcrumb";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import ModalFarmSearch from "../../components/Modal.FarmSearch";
import InputText from "../../components/InputText";
import InputGroupText from "../../components/InputGroupText";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function SaveCertificationFarm(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);

  const initialForm = {
    minorValue: "",
    smallValue: "",
    mediumValue: "",
    delTable: [],

    farmId: "",
    farmerName: "",
    farmName: "",
    provinceId: "",
    amphurId: "",
    tambonId: "",
    mooId: "",
    soi: "",
    road: "",
    address: "",
    zipcode: "",

    animalTypeId: "",
    animalAmt: "",
    gfmFarmerTypeId: "",
    startDate: "",
    endDate: "",
    statusId: "",

    chooseSelect: false,
    checkAddGfm: false,
    checkEditGfm: false,
    modeEditTable: false,
    isOnAdding: true,
    isGfmAdding: false,
    gfmCertificate: [],
  };
  const [form, setForm] = useState(initialForm);

  const initialGfm = {
    certificateCode: "",
    animalTypeName: "",
    animalTypeId: "",
    animalAmt: "",
    gfmFarmerTypeId: "",
    gfmFarmerTypeName: "",
    farmerType: "",
    startDate: "",
    endDate: "",
    statusId: "",
    statusName: "",
  };
  const [formGfm, setFormGfm] = useState(initialGfm);
  const initInvalidMsg = {
    animalTypeId: "",
    animalAmt: "",
    startDate: "",
    statusId: "",
    farmId: "",
    certificateCode: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const [showFarmSearch, setShowFarmSearch] = useState(false);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const selectStatusList = [
    { value: 1, label: "รับรอง" },
    { value: 2, label: "ต่ออายุ" },
    { value: 3, label: "หมดอายุ" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  const [farmerTypeId, setFarmerTyieId] = useState([]);
  const [disAmt, setDisAmt] = useState(false);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/gfm", name: "GFM" },
    { path: "active", name: "บันทึกการรับรองฟาร์ม GFM" },
  ];

  useMasterData("province", provinceList, setProvinceList);

  useEffect(() => {
    getProvince();
    getAnimal();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("edit") && !form.chooseSelect) {
      getGFMById(props.match.params.id);
    }
  }, [location.pathname, form.provinceId]);

  useEffect(() => {
    if (
      location.pathname.includes("save") &&
      !form.chooseSelect &&
      form.farmId
    ) {
      getFarmById(form.farmId);
    }
  }, [form.farmId, form.provinceId, form.amphurId, form.tambonId, form.mooId]);

  const getFarmById = async (id) => {
    let dataFarm;
    try {
      const response = await API.getFarmRegistrationById(id);
      if (response.status == 200) {
        dataFarm = response?.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataFarm) {
      const dataById = dataFarm;
      if (dataById.farm && dataById.farm.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.farm.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.farm.provinceId);
        }
      }

      if (dataById.farm && dataById.farm.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.farm.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.farm.amphurId);
        }
      }

      if (dataById.farm && dataById.farm.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.farm.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.farm.tambonId);
        }
      }

      if (dataById.farm && dataById.farm.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.farm.mooId
        );
        setVillageSelectValue(villageSelect);
      }
    }

    setForm({
      ...form,
      farmerName:
        `${dataFarm.farmer.firstName} ${dataFarm.farmer.lastName}` || "-",
      farmName: dataFarm.farm.farmName || "-",
      provinceId: dataFarm.farm.provinceId || "-",
      amphurId: dataFarm.farm.amphurId || "-",
      tambonId: dataFarm.farm.tambonId || "-",
      mooId: dataFarm.farm.mooId || "-",
      road: dataFarm.farm.road || "-",
      soi: dataFarm.farm.soi || "-",
      address: dataFarm.farm.homeNo || "-",
      zipcode: dataFarm.farm.zipcode || "-",
    });
  };

  //======== get จังหวัด ตำบล อำเภอ หมู่บ้าน =======
  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response?.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response?.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response?.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response?.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //==============================================

  const getAnimal = async () => {
    try {
      // const response = await API.getAnimal("", 1);
      const response = await API.getGFMFarmerType();
      // console.log("response----👿👿", response);
      const data = await response?.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.animalTypeId,
            label: element.animalTypeName,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buttonAddData = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddGfm()}
          disabled={
            form.checkAddGfm ? true : false || form.checkEditGfm ? true : false
          }
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const toAddGfm = () => {
    setForm({ ...form, checkAddGfm: true });
    setStatusSelectValue([]);
    setAnimalSelectValue([]);
    setFormGfm({
      ...formGfm,
      animalTypeName: "",
      animalTypeId: "",
      animalAmt: "",
      gfmFarmerTypeId: "",
      gfmFarmerTypeName: "",
      farmerType: "",
      startDate: "",
      endDate: "",
      statusId: "",
      statusName: "",
    });
    setForm({
      ...form,
      checkAddGfm: true,
      isOnAdding: false,
      isGfmAdding: true,
    });
  };

  const getGFMFarmerType = async (animalTypeId) => {
    let data;
    let check = false;
    try {
      const response = await API.getGFMFarmerType("", animalTypeId);
      if (response.status == 200) {
        data = response.data;
        data.content.filter((element) => {
          if (
            element.animalTypeId === animalTypeId &&
            element.animalTypeId !== ""
          ) {
            setFarmerTyieId(element);
            check = check || true;
            setForm({
              ...form,
              minorValue: element.minorValue,
              smallValue: element.smallValue,
              mediumValue: element.mediumValue,
            });
          } else {
            check = check || false;
          }
        });
      }
      if (!check) {
        setDisAmt(true);
        MySwal.fire({
          html: (
            <div>
              ไม่มีชนิดสัตว์ที่คุณเลือกในประเภทเกษตรกร
              <br />
              กรุณาเพิ่มข้อมูลในส่วน<b>บริหารข้อมูลส่วนกลาง</b>
              <br />
              ก่อนบันทึกข้อมูล
            </div>
          ),
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: "ยกเลิก",
          confirmButtonText: "ตกลง",
        });
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getGFMById = async (id) => {
    let dataGfm;
    try {
      const response = await API.getGFMById(id);
      if (response.status === 200) {
        dataGfm = response.data;
      }
      // console.log("getGFMById-response-👽👽", response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataGfm) {
      if (dataGfm.gfmCertificate && dataGfm.gfmCertificate.statusId) {
        let statusSelect = statusList.find(
          (item) => item.value === dataGfm.statusId
        );
        setStatusSelectValue(statusSelect);
      }

      if (dataGfm.gfm && dataGfm.gfm.provinceId) {
        let provinceSelect = provinceList.find(
          (item) => item.value === dataGfm.gfm.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length === 0) {
          await getDistricts(dataGfm.gfm.provinceId);
        }
      }

      if (dataGfm.gfm && dataGfm.gfm.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value === dataGfm.gfm.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length === 0) {
          await getSubDistricts(dataGfm.gfm.amphurId);
        }
      }

      if (dataGfm.gfm && dataGfm.gfm.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value === dataGfm.gfm.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length === 0) {
          await getVillage(dataGfm.gfm.tambonId);
        }
      }

      if (dataGfm.gfm && dataGfm.gfm.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value === dataGfm.gfm.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataGfm.gfmCertificate && dataGfm.gfmCertificate.gfmFarmerTypeId) {
        getGFMFarmerType();
      }
    }

    let gfmArray = [];
    if (dataGfm?.gfmCertificate?.length > 0) {
      dataGfm.gfmCertificate.map((item) => {
        let animalTypeName;
        animalList.filter((itemA) => {
          if (itemA.value == item.animalTypeId) {
            animalTypeName = itemA.label;
          }
        });

        let statusName;
        statusList.filter((itemB) => {
          if (itemB.value == item.statusId) {
            statusName = itemB.label;
          }
        });

        if (item.gfmFarmerTypeId) {
          gfmArray.push({
            id: item.id,
            certificateCode: item.certificateCode,
            animalTypeId: item.animalTypeId,
            animalTypeName: animalTypeName,
            animalAmt: item.animalAmt,
            gfmFarmerTypeId: item.gfmFarmerTypeId,
            gfmFarmerTypeName: gfmFarmerTypeName(
              item.animalAmt,
              item.minorValue,
              item.smallValue,
              item.mediumValue
            ),
            farmerType: gfmFarmerTypeId(
              item.animalAmt,
              item.minorValue,
              item.smallValue,
              item.mediumValue
            ),
            startDate: item.startDate,
            endDate: item.endDate,
            statusId: item.statusId,
            statusName: statusName,
          });
        }
      });
    }
    setForm({
      ...form,
      farmId: dataGfm.gfm.farmId || "-",
      farmerName: dataGfm.gfm.farmerName || "-",
      farmName: dataGfm.gfm.farmName || "-",
      provinceId: dataGfm.gfm.provinceId || "-",
      amphurId: dataGfm.gfm.amphurId || "-",
      tambonId: dataGfm.gfm.tambonId || "-",
      mooId: dataGfm.gfm.mooId || "-",
      soi: dataGfm.gfm.soi || "-",
      road: dataGfm.farm.road || "-",
      address: dataGfm.gfm.address || "-",
      zipcode: dataGfm.farm.zipcode || "-",
      gfmCertificate: gfmArray || [],
    });
  };

  const gfmFarmerTypeName = (
    animalAmt,
    minorValue,
    smallValue,
    mediumValue
  ) => {
    let typeFarm;
    if (animalAmt && minorValue && smallValue && mediumValue) {
      if (animalAmt <= minorValue) {
        typeFarm = "รายย่อย";
      } else if (animalAmt > minorValue && animalAmt <= smallValue) {
        typeFarm = "รายเล็ก";
      } else if (animalAmt > smallValue && animalAmt <= mediumValue) {
        typeFarm = "รายกลาง";
      } else if (animalAmt > mediumValue) {
        typeFarm = "รายใหญ่";
      }
    }
    return typeFarm;
  };

  const gfmFarmerTypeId = (
    animalAmt,
    minorValue,
    smallValue,
    mediumValue
  ) => {
    let typeFarm;
    if (animalAmt && minorValue && smallValue && mediumValue) {
      if (animalAmt <= minorValue) {
        typeFarm = 1;
      } else if (animalAmt > minorValue && animalAmt <= smallValue) {
        typeFarm = 2;
      } else if (animalAmt > smallValue && animalAmt <= mediumValue) {
        typeFarm = 3;
      } else if (animalAmt > mediumValue) {
        typeFarm = 4;
      } else {
        typeFarm = "";
      }
    }
    return typeFarm;
  };

  const getStatusColor = (idStatus) => {
    let colorStatus, nameStatus;
    if (idStatus) {
      if (idStatus == 1) {
        colorStatus = "#3FC071";
        nameStatus = "รับรอง";
      } else if (idStatus == 2) {
        colorStatus = "#3FC071";
        nameStatus = "ต่ออายุ";
      } else if (idStatus == 3) {
        colorStatus = "#FF482F";
        nameStatus = "หมดอายุ";
      } else {
        nameStatus = "-";
      }
      return (
        <div
          style={{ background: colorStatus }}
          className="box-status font14 px-2 text-center"
        >
          <span>{nameStatus}</span>
        </div>
      );
    } else {
      return <div>-</div>;
    }
  };

  const condFarmerType = (animalAmt) => {
    let typeFarm;
    let typeFarmId;
    if (animalAmt && form.minorValue && form.smallValue && form.mediumValue) {
      if (animalAmt <= form.minorValue) {
        typeFarmId = 1;
        typeFarm = "รายย่อย";
      } else if (animalAmt > form.minorValue && animalAmt <= form.smallValue) {
        typeFarmId = 2;
        typeFarm = "รายเล็ก";
      } else if (animalAmt > form.smallValue && animalAmt <= form.mediumValue) {
        typeFarmId = 3;
        typeFarm = "รายกลาง";
      } else {
        typeFarm = "รายใหญ่";
      }
    } else if (formGfm === "") {
      typeFarmId = 1;
      typeFarm = "รายย่อย";
    }

    if (/^[0-9]*$/.test(animalAmt)) {
      setFormGfm({
        ...formGfm,
        animalAmt: animalAmt,
        gfmFarmerTypeName: typeFarm,
        farmerType: typeFarmId,
      });
    }
  };

  const saveGfm = async (itemGfm, type) => {
    let arrayGfm = [...form.gfmCertificate];
    try {
      const response = await API.checkDuplicateGfm(itemGfm?.certificateCode);
      if (response.data?.is_duplicate) {
        addInvalid("certificateCode", "เลขใบรับรองซ้ำ");
        return;
      } else {
        if (type === "add") {
          arrayGfm.push({
            checkEditGfm: false,
            certificateCode: formGfm.certificateCode,
            animalTypeId: formGfm.animalTypeId,
            animalTypeName: formGfm.animalTypeName,
            animalAmt: formGfm.animalAmt,
            gfmFarmerTypeId: farmerTypeId.id,
            gfmFarmerTypeName: formGfm.gfmFarmerTypeName,
            farmerType: formGfm.farmerType,
            startDate: formGfm.startDate,
            endDate: formGfm.endDate,
            statusId: formGfm.statusId,
            statusName: formGfm.statusName,
          });
          setForm({
            ...form,
            gfmCertificate: arrayGfm,
            checkAddGfm: false,
            modeEditTable: false,
            checkEditGfm: false,
          });
        } else if (type === "edit") {
          itemGfm.checkEditGfm = false;
          itemGfm.certificateCode = formGfm.certificateCode;
          itemGfm.animalTypeId = formGfm.animalTypeId;
          itemGfm.animalTypeName = formGfm.animalTypeName;
          itemGfm.animalAmt = formGfm.animalAmt;
          itemGfm.gfmFarmerTypeId = formGfm.gfmFarmerTypeId;
          itemGfm.gfmFarmerTypeName = formGfm.gfmFarmerTypeName;
          itemGfm.farmerType = formGfm.farmerType;
          itemGfm.startDate = formGfm.startDate;
          itemGfm.endDate = formGfm.endDate;
          itemGfm.statusId = formGfm.statusId;
          itemGfm.statusName = formGfm.statusName;
          setForm({
            ...form,
            checkAddGfm: false,
            modeEditTable: false,
            checkEditGfm: false,
          });
        }
      }
    } catch (error) {
      const textError = error.response?.data?.error;
      MySwal.fire({
        text: textError,
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
      console.log(error);
    }
  };

  const editGfm = (item) => {
    item.checkEditGfm = true;
    setForm({ ...form, checkEditGfm: true, modeEditTable: true });
    getGFMFarmerType(item.animalTypeId);
    setFormGfm({
      ...formGfm,
      certificateCode: item.certificateCode,
      animalTypeId: item.animalTypeId,
      animalTypeName: item.animalTypeName,
      animalAmt: item.animalAmt,
      gfmFarmerTypeId: item.gfmFarmerTypeId,
      gfmFarmerTypeName: item.gfmFarmerTypeName,
      farmerType: item.farmerType,
      startDate: item.startDate,
      endDate: item.endDate,
      statusId: item.statusId,
      statusName: item.statusName,
    });
    setAnimalSelectValue([
      { value: item.animalTypeId, label: item.animalTypeName },
    ]);
    setStatusSelectValue([{ value: item.statusId, label: item.statusName }]);
  };

  const delGfm = (index, item) => {
    let delData = [];
    form.gfmCertificate.splice(index, 1);
    if (item.id) {
      delData.push(item.id);
    }
    setForm({
      ...form,
      gfmCertificate: form.gfmCertificate,
      delTable: delData,
    });
  };

  const expireDate = (startDate) => {
    let expireDate = new Date(startDate);
    expireDate.setFullYear(expireDate.getFullYear() + 2);
    setFormGfm({ ...formGfm, startDate: startDate, endDate: expireDate });
  };

  const saveAddGFM = async () => {
    if (validate("checkAll")) {
      let response;
      let gfmCertificate = form.gfmCertificate;
      let gfmObj = [];
      gfmCertificate.map((item) => {
        gfmObj.push({
          id: item.id ? item.id : "",
          certificateCode: item.certificateCode,
          animalTypeId: item.animalTypeId,
          animalAmt: item.animalAmt,
          gfmFarmerTypeId: item.gfmFarmerTypeId,
          farmerType: item.farmerType,
          startDate: item.startDate ? helper.getDate(item.startDate) : "",
          endDate: item.endDate ? helper.getDate(item.endDate) : "",
          statusId: item.statusId,
        });
      });

      const delDataGfm = [];
      if (form.delTable && form.delTable.length > 0) {
        form.delTable.map((item) => {
          delDataGfm.push(item);
        });
      }

      const data = {
        gfm: {
          farmId: form.farmId,
          farmName: form.farmName,
          farmerName: form.farmerName,
          address: form.address,
          soi: form.soi,
          provinceId: form.provinceId,
          amphurId: form.amphurId,
          tambonId: form.tambonId,
          mooId: form.mooId,
        },
        gfmCertificate: gfmObj,
        deleteCertificate: delDataGfm,
      };

      MySwal.fire({
        text: `ต้องการบันทึก`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (value) => {
        if (value.isConfirmed) {
          try {
            response = location.pathname.includes("/save")
              ? await API.saveGFM(data)
              : await API.updateGFM(props.match.params.id, data);
            if (response.status === 200) {
              MySwal.fire({
                text: location.pathname.includes("/save")
                  ? "บันทึกข้อมูลการรับรองฟาร์ม GFM สำเร็จ"
                  : "แก้ไขข้อมูลการรับรองฟาร์ม GFM สำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then((result) => {
                if (result.value) {
                  history.push("/gfm");
                }
              });
            }
          } catch (error) {
            if (error.response.status === 401) {
              console.log(error.response);
              dispatch(logout({ history }));
            }
          }
        }
      });
    }
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = (params) => {
    let validated = true;

    if (params === "checkAll") {
      if (form.farmId === "") {
        addInvalid("farmId", "โปรดระบุรหัสฟาร์ม");
        validated = false;
      }
    }

    if (params === "checkTable" || params === "checkAll") {
      if (formGfm.animalTypeId === "" && form.isGfmAdding) {
        addInvalid("animalTypeId", "กรุณาเลือกเขตกรมปศุสัตว์");
        validated = false;
      }
      if (formGfm.animalAmt === "" && form.isGfmAdding) {
        addInvalid("animalAmt", "กรุณากรอกจำนวน");
        validated = false;
      }
      if (formGfm.startDate === "" && form.isGfmAdding) {
        addInvalid("startDate", "กรุณาเลือกวันที่รับรอง");
        validated = false;
      }
      if (formGfm.statusId === "" && form.isGfmAdding) {
        addInvalid("statusId", "กรุณาเลือกสถานะ");
        validated = false;
      }
      if (formGfm.certificateCode === "") {
        addInvalid("certificateCode", "กรุณากรอกเลขที่ใบรับรอง");
        validated = false;
      } else if (formGfm.certificateCode?.length < 13) {
        addInvalid("certificateCode", "กรุณากรอกเลขที่ใบรับรองให้ครบ 13 หลัก");
        validated = false;
      }
    }

    return validated;
  };

  const renderTableGfm = (item, index) => {
    return (
      <tr key={index} className="text-center">
        <td>{index + 1}</td>
        <td>
          {item.checkEditGfm ? (
            <InputText
              classFormGroup={
                invalid.certificateCode ===
                "กรุณากรอกเลขที่ใบรับรองให้ครบ 13 หลัก"
                  ? "pt-20 mb-0 w-100"
                  : "mb-0 w-100"
              }
              star={false}
              // disabled
              placeholder="เลขที่ใบรับรอง"
              classLabel="normal"
              value={formGfm.certificateCode}
              maxLength={13}
              handleChange={(value) => {
                item.certificateCode = value;
                setFormGfm({ ...formGfm, certificateCode: value })
              }
              }
              handleInvalid={() => removeInvalid("certificateCode")}
              invalid={invalid.certificateCode}
            />
          ) : item.certificateCode ? (
            item.certificateCode
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.checkEditGfm ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="animalTypeId"
              classLabel="normal"
              selectValue={animalSelectValue}
              optionsList={animalList}
              value={formGfm.animalTypeName}
              handleChange={(value) => {
                selectValueAnimal(value);
              }}
              handleInvalid={() => removeInvalid("animalTypeId")}
              invalid={invalid.animalTypeId}
            />
          ) : item.animalTypeName ? (
            item.animalTypeName
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.checkEditGfm ? (
            <InputText
              classFormGroup="mb-0 w-100"
              star={false}
              disabled={disAmt === true}
              placeholder="ระบุจำนวน"
              classLabel="normal"
              value={formGfm.animalAmt}
              handleChange={(value) => {
                condFarmerType(value);
              }}
              handleInvalid={() => removeInvalid("animalAmt")}
              invalid={invalid.animalAmt}
            />
          ) : item.animalAmt ? (
            item.animalAmt
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.checkEditGfm ? (
            <InputText
              classFormGroup="mb-0 w-100"
              star={false}
              disabled
              placeholder="ประเภทเกษตรกร"
              classLabel="normal"
              value={formGfm.gfmFarmerTypeName}
            />
          ) : item.gfmFarmerTypeName ? (
            item.gfmFarmerTypeName
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.checkEditGfm ? (
            <InputDatePicker
              classFormGroup="mb-0 w-100"
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={formGfm.startDate}
              star={false}
              handleChange={(value) => {
                expireDate(value);
              }}
              handleInvalid={() => removeInvalid("startDate")}
              invalid={invalid.startDate}
            />
          ) : item.startDate ? (
            helper.getDateFormat(item.startDate)
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.checkEditGfm ? (
            <InputDatePicker
              classFormGroup="mb-0 w-100"
              disabled
              classLabel="normal"
              placeholder="วัน/เดือน/ปี"
              value={formGfm.endDate}
              star={false}
            />
          ) : item.endDate ? (
            helper.getDateFormat(item.endDate)
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.checkEditGfm ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="statusId"
              classLabel="normal"
              selectValue={statusSelectValue}
              optionsList={statusList}
              value={formGfm.statusName}
              handleChange={(value) => {
                setFormGfm({
                  ...formGfm,
                  statusId: value.value,
                  statusName: value.label,
                });
                setStatusSelectValue(value);
              }}
              handleInvalid={() => removeInvalid("statusId")}
              invalid={invalid.statusId}
            />
          ) : item.statusId ? (
            getStatusColor(item.statusId)
          ) : (
            "-"
          )}
        </td>
        <td className="text-center">
          {item.checkEditGfm ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                saveGfm(item, "edit");
              }}
            >
              <img
                className="icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => editGfm(item)}
                disabled={form.modeEditTable || form.checkEditGfm === true}
              >
                <img className=" icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del "
                onClick={() => delGfm(index, item)}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  const renderNewTableGfm = () => {
    return (
      <tr className="text-center">
        <td className="text-center">{form.gfmCertificate.length + 1}</td>
        <td className="fitwidth">
          <InputText
            classFormGroup={
              invalid.certificateCode ===
              "กรุณากรอกเลขที่ใบรับรองให้ครบ 13 หลัก"
                ? "pt-20 mb-0 w-100"
                : "mb-0 w-100"
            }
            star={false}
            // disabled
            placeholder="เลขที่ใบรับรอง"
            classLabel="normal"
            maxLength={13}
            handleChange={(value) =>
              setFormGfm({ ...formGfm, certificateCode: value })
            }
            handleInvalid={() => removeInvalid("certificateCode")}
            invalid={invalid.certificateCode}
          />
        </td>
        <td className="fitwidth">
          <InputSelect
            classFormGroup="mb-0 w-100"
            star={false}
            placeholder="กรุณาเลือก"
            idName="animalTypeId"
            classLabel="normal"
            selectValue={animalSelectValue}
            optionsList={animalList}
            handleChange={(value) => {
              selectValueAnimal(value);
            }}
            handleInvalid={() => removeInvalid("animalTypeId")}
            invalid={invalid.animalTypeId}
          />
        </td>
        <td className="fitwidth">
          <InputText
            classFormGroup="mb-0 w-100"
            star={false}
            disabled={disAmt === true}
            placeholder="ระบุจำนวน"
            classLabel="normal"
            value={formGfm.animalAmt}
            handleChange={(value) => {
              condFarmerType(value);
            }}
            handleInvalid={() => removeInvalid("animalAmt")}
            invalid={invalid.animalAmt}
          />
        </td>
        <td className="fitwidth">
          <InputText
            classFormGroup="mb-0 w-100"
            star={false}
            disabled
            placeholder="ประเภทเกษตรกร"
            classLabel="normal"
            value={formGfm.gfmFarmerTypeName}
          />
        </td>
        <td className="fitwidth">
          <InputDatePicker
            classFormGroup="mb-0 w-100"
            classLabel="normal"
            placeholder="วัน/เดือน/ปี"
            value={formGfm.startDate}
            star={false}
            handleChange={(value) => {
              expireDate(value);
            }}
            handleInvalid={() => removeInvalid("startDate")}
            invalid={invalid.startDate}
          />
        </td>
        <td className="fitwidth">
          <InputDatePicker
            classFormGroup="mb-0 w-100"
            classLabel="normal"
            placeholder="วัน/เดือน/ปี"
            disabled
            value={formGfm.endDate}
            star={false}
          />
        </td>
        <td className="fitwidth">
          <InputSelect
            classFormGroup="mb-0 w-100"
            star={false}
            placeholder="กรุณาเลือก"
            idName="statusId"
            classLabel="normal"
            selectValue={statusSelectValue}
            optionsList={statusList}
            handleChange={(value) => {
              setFormGfm({
                ...formGfm,
                statusId: value.value,
                statusName: value.label,
              });
              setStatusSelectValue(value);
            }}
            handleInvalid={() => removeInvalid("statusId")}
            invalid={invalid.statusId}
          />
        </td>
        <td>
          <button
            className="btn btn-success  pt-1 px-2"
            onClick={async () => {
              if (validate("checkTable")) {
                saveGfm(formGfm, "add");
              }
            }}
          >
            <img
              className="img-fluid icon-check"
              src="/svg/btn-check.svg"
              alt="check"
            />
          </button>
        </td>
      </tr>
    );
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      zipcode: "",
      amphurId: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      amphurId: value.value,
      zipcode: "",
      tambonId: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      tambonId: value.value,
      zipcode: value.zipcode,
      mooId: "",
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const selectValueAnimal = (value) => {
    setAnimalSelectValue([{ value: value.value, label: value.label }]);
    setFormGfm({
      ...formGfm,
      animalTypeId: value.value,
      animalTypeName: value.label,
      animalAmt: "",
      gfmFarmerTypeName: "",
      farmerType: "",
    });
    getGFMFarmerType(value.value);
    setDisAmt(false);
  };

  return (
    <div className=" ">
      <div className="pl-lg-4 pl-md-4 pl-sm-2 bg-white d-flex flex-wrap align-items-center ml30">
        <div className="mr-auto p-2">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div>
          <button
            className="btn px-4 btn-sm btn-danger mr-2"
            onClick={() => history.push("/gfm")}
          >
            ยกเลิก
          </button>
          <button
            className="btn px-4 btn-sm btn-success mr-2"
            onClick={() => saveAddGFM()}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`gfm/edit`)
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard classNameBox="mb-4" title="ข้อมูลฟาร์ม">
            <Row className="mb-2 ">
              <Col lg={2} md={6} sm={6} xs={6}>
                <div onClick={() => setShowFarmSearch(true)}>
                  <InputGroupText
                    type="text"
                    title="รหัสฟาร์ม"
                    imgSrc={"/svg/search.svg"}
                    imgAlt="search-icon"
                    idName="farmId"
                    value={form.farmId}
                    star={true}
                    placeholder="ค้นหาฟาร์ม"
                    classLabel="normal"
                    disabled={location.pathname.includes("/edit")}
                    handleChange={(value) =>
                      setForm({ ...form, farmId: value })
                    }
                    invalid={invalid.farmId}
                  />
                </div>
              </Col>
              <Col lg={2} md={6} sm={6} xs={6} className="d-flex ">
                <div className="mt-auto mb-3 ">
                  <button
                    className="btn btn-darkblue w-100"
                    onClick={() => history.push("/farm_registration/save-data")}
                  >
                    <img
                      className="mr-2 mb-1 "
                      src={"/svg/menu_5.svg"}
                      alt="menu-icon"
                    />
                    ขึ้นทะเบียนฟาร์ม
                  </button>
                </div>
              </Col>
              <Col lg={2} md={6} sm={6} xs={6}>
                <InputText
                  type="text"
                  title="เจ้าของฟาร์ม"
                  idName="farmerName"
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  disabled
                  value={form.farmerName}
                  handleChange={(value) =>
                    setForm({ ...form, farmerName: value })
                  }
                />
              </Col>
              <Col lg={2} md={6} sm={6} xs={6}>
                <InputText
                  type="text"
                  title="ชื่อฟาร์ม"
                  idName="farmName"
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  disabled
                  value={form.farmName}
                  handleChange={(value) =>
                    setForm({ ...form, farmName: value })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col lg={2} md={6} sm={6} xs={6}>
                <InputText
                  type="text"
                  title="ที่อยู่ฟาร์มเลขที่"
                  idName="name"
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  disabled
                  value={form.address}
                  handleChange={(value) => setForm({ ...form, address: value })}
                />
              </Col>

              <Col lg={2} md={6} sm={6} xs={6}>
                <InputText
                  type="text"
                  title="หมู่บ้าน"
                  idName="mooId"
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  disabled
                  value={form.mooId}
                  handleChange={(value) => setForm({ ...form, mooId: value })}
                />
              </Col>

              <Col lg={2} md={6} sm={6} xs={6}>
                <InputText
                  type="text"
                  title="ถนน"
                  idName="road"
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  disabled
                  value={form.road}
                  handleChange={(value) => setForm({ ...form, road: value })}
                />
              </Col>

              <Col lg={2} md={6} sm={6} xs={6}>
                <InputText
                  type="text"
                  title="ซอย"
                  idName="soi"
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  disabled
                  value={form.soi}
                  handleChange={(value) => setForm({ ...form, soi: value })}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={2} md={6} sm={6} xs={6}>
                <InputSelect
                  title="จังหวัด"
                  star={false}
                  disabled={true}
                  placeholder="กรุณาเลือก"
                  idName="province"
                  classLabel="normal"
                  selectValue={provinceSelectValue}
                  optionsList={provinceList}
                  handleChange={(value) => selectValueProvince(value)}
                />
              </Col>
              <Col lg={2} md={6} sm={6} xs={6}>
                <InputSelect
                  title="อำเภอ"
                  star={false}
                  disabled={true}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={districtSelectValue}
                  optionsList={districtList}
                  handleChange={(value) => selectValueDistrict(value)}
                />
              </Col>

              <Col lg={2} md={6} sm={6} xs={6}>
                <InputSelect
                  title="ตำบล"
                  disabled={true}
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="subDistrict"
                  classLabel="normal"
                  selectValue={subDistrictSelectValue}
                  optionsList={subDistrictList}
                  handleChange={(value) => selectValueSubDistrict(value)}
                />
              </Col>

              <Col lg={2} md={6} sm={6} xs={6}>
                <InputText
                  type="text"
                  title="รหัสไปรษณีย์"
                  idName="zipcode"
                  star={false}
                  placeholder="กรุณาระบุ"
                  classLabel="normal"
                  disabled
                  value={form.zipcode}
                  handleChange={(value) => setForm({ ...form, zipcode: value })}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            classNameBox="mt-4 my-4"
            title="รายการประวัติใบรับรอง"
            headRight={buttonAddData()}
          >
            <div className="">
              <Table className="table table-striped sameWidth table-bordered my-2 ">
                <thead>
                  <tr className="text-center">
                    <th scope="col">ลำดับ</th>
                    <th scope="col">เลขที่ใบรับรอง</th>
                    <th scope="col">ชนิดสัตว์</th>
                    <th scope="col">จำนวน</th>
                    <th scope="col">ประเภทเกษตรกร</th>
                    <th scope="col">วันที่รับรอง</th>
                    <th scope="col">วันที่หมดอายุ</th>
                    <th scope="col">สถานะ</th>
                    <th scope="col">จัดการ</th>
                  </tr>
                </thead>
                <tbody>
                  {form.gfmCertificate && form.gfmCertificate.length > 0
                    ? form.gfmCertificate.map((item, i) => {
                        return renderTableGfm(item, i);
                      })
                    : null}
                  {form.checkAddGfm && renderNewTableGfm()}
                  {form.gfmCertificate &&
                    form.gfmCertificate.length == 0 &&
                    !form.checkAddGfm && <DataTableNodata colSpan={9} />}
                </tbody>
              </Table>
            </div>
          </BoxCard>
        </div>
      </div>
      {showFarmSearch && (
        <ModalFarmSearch
          show={showFarmSearch}
          mode={form.modeFarmSearch}
          close={() => setShowFarmSearch(false)}
          save={(value) => {
            setForm({ ...form, farmId: value});
            removeInvalid("farmId");
          }}
        />
      )}
    </div>
  );
}

export default SaveCertificationFarm;
