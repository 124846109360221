import React, { useState, useEffect } from 'react'
import { Modal, Form, Row, Col } from 'react-bootstrap';
import InputDatePicker from "./InputDatePicker";

function ModalVaccineDate({ show, close, save, headModal }) {

    const initialForm = {
        date: ""
    };
    const [form, setForm] = useState(initialForm);

    const initInvalidMsg = {
        date: ""
    };
    const [invalid, setInvalid] = useState(initInvalidMsg);

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;

        if (form.date == "") {
            addInvalid("date", "กรุณาระบุวันที่ฉีดวัคซีน");
            validated = false;
        }

        return validated;
    }

    const handleClose = () => {
        close()
        setForm(initialForm)
    }

    const toSave = async () => {
        if (validate()) {
            save(form.date);
            handleClose();
        }
    };

    return (
        <Modal
            show={show}
            size="sm"
            onHide={() => handleClose(false)}
            centered
        >
            <Modal.Header closeButton className="pb-2 border-bottom">
                <span className="bold">
                    {headModal}
                </span>
            </Modal.Header>
            <Modal.Body className="p-4">
                <div className="mb-2">
                    <InputDatePicker classFormGroup="mb-0" title="วันที่ฉีด" classLabel="normal" placeholder="วัน/เดือน/ปี"
                        value={form.date} star={false}
                        handleChange={(value) => setForm({ ...form, date: value })}
                        handleInvalid={() => removeInvalid("date")}
                        invalid={invalid.date}
                    />

                    <div className="mt-4 text-center">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toSave()}>ตกลง</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalVaccineDate
