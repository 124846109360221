import { all, takeEvery, put } from "redux-saga/effects";
import * as helper from "../../utils/helper";
import * as actionTypes from "../actions/masterDataTypes";

export default function* watchLoginSaga() {
  yield all([
    takeEvery(actionTypes.PROVINCE, watchProvince),
    takeEvery(actionTypes.PROVINCE_CHECK, watchCheckProvince),
    takeEvery(actionTypes.ZONE_LIVESTOCK, watchZoneLivestock),
    takeEvery(actionTypes.ZONE_LIVESTOCK_CHECK, watchCheckZoneLivestock),
  ]);
}

function* watchProvince(payload) {
  helper.sessionSave("province", payload.payload);
  try {
    yield put({
      type: actionTypes.PROVINCE_SUCCESS,
      payload: JSON.parse(payload.payload),
    });
  } catch (e) {
    yield put({ type: actionTypes.PROVINCE_FAILED, payload: e });
  }
}

function* watchCheckProvince() {
  const items = yield helper.sessionGet("province");
  try {
    if (items) {
      yield put({
        type: actionTypes.PROVINCE_SUCCESS,
        payload: JSON.parse(items),
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.PROVINCE_FAILED, payload: e });
  }
}

function* watchZoneLivestock(payload) {
  helper.sessionSave("zoneLivestock", payload.payload);
  try {
    yield put({
      type: actionTypes.ZONE_LIVESTOCK_SUCCESS,
      payload: JSON.parse(payload.payload),
    });
  } catch (e) {
    yield put({ type: actionTypes.ZONE_LIVESTOCK_FAILED, payload: e });
  }
}

function* watchCheckZoneLivestock() {
  const items = yield helper.sessionGet("zoneLivestock");
  try {
    if (items) {
      yield put({
        type: actionTypes.ZONE_LIVESTOCK_SUCCESS,
        payload: JSON.parse(items),
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.ZONE_LIVESTOCK_FAILED, payload: e });
  }
}
