import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { Modal, Row, Col, Table } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";

import ModalMap from "../../components/Modal.Map";
import InputText from "../InputText";
import InputSelect from "../InputSelect";

import * as API from "../../utils/apis";

function ModalFarmCertificate({ show, close, save, mode, idEdit }) {

    const initialForm = {
        farmCertId: "",
        name: "",
        statusId: "",


        createBy: "",
        createDate: "",
        createByName: "",

        updateBy: "",
        updateDate: "",
        updateByName: "",
        limit: 100,
        page: 1,
        sortDir: "DESC",
        sortBy: "id",

        checkShow: false
    };
    const initInvalidMsg = {
        diseaseTypeId: "",
        status: "",
        data: "",
    };
    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];
    const initStatusSelectValue = [];

    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.login.result)


    const [form, setForm] = useState(initialForm);
    const [invalid, setInvalid] = useState(initInvalidMsg);
    const [statusSelectValue, setStatusSelectValue] = useState(initStatusSelectValue);
    const [statusList, setStatusList] = useState(selectStatusList);

    const [diseaseSelectValue, setDiseaseSelectValue] = useState([]);
    const [diseaseList, setDiseaseList] = useState([]);


    useEffect(() => {
        if (mode === "add") {
            setForm(initialForm)
            setStatusSelectValue([])
        } else {
            setForm(initialForm)
            getFarmCertificateById(idEdit)
        }
    }, [mode])

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;

        if (form.statusId === "") {
            addInvalid("status", "โปรดระบุสถานะ");
            validated = false;
        }

        return validated;
    };

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setStatusSelectValue([])
    }

    const toConfirm = async () => {
        if (validate()) {
            await save({ farmCert: form, mode: mode });
        }
    };

    const getFarmCertificateById = async (id) => {
        let dataFarmCert;
        try {
            const response = await API.getFarmCertificateById(id);
            const data = await response?.data;
            if (response?.status === 200) {
                dataFarmCert = data;
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response?.status === 401) {
                dispatch(logout({ history }))
            }
            console.log(error)
        }

        if (mode === "edit" && dataFarmCert.statusId) {
            let statusSelect = statusList.find(item => item.value === dataFarmCert.statusId);
            setStatusSelectValue(statusSelect);
        }

        setForm({
            ...form,
            farmCertId: dataFarmCert.id,
            name: dataFarmCert.name,
            statusId: dataFarmCert.statusId,

            createBy: dataFarmCert.createBy,
            createDate: dataFarmCert.createDate,
            createByName: dataFarmCert.createByName,

            updateBy: dataFarmCert.updateBy,
            updateDate: dataFarmCert.updateDate,
            updateByName: dataFarmCert.updateByName,
        })
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => handleClose()}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่ม/แก้ไขประเภทมาตรฐานฟาร์ม</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={4} md={6} sm={12} xs={12} >
                                    <InputText type="text" title="ชื่อมาตรฐาน" idName="name" value={form.name} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, name: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("name")}
                                        invalid={invalid.name}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                        star={true}
                                        selectValue={statusSelectValue} optionsList={statusList}
                                        handleChange={(value) => {
                                            setStatusSelectValue(value)
                                            setForm({ ...form, statusId: value.value, checkShow: true })
                                        }}
                                        handleInvalid={() => removeInvalid("status")} invalid={invalid.status}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={12} sm={12}>
                                    <table>
                                        <tr>
                                            <td>
                                                <div className="text-gray font12">
                                                    วันที่บันทึกข้อมูล
                                                </div>
                                            </td>
                                            <td>
                                                <div className="text-gray font12">
                                                    {form.createDate ? helper.momentDateTime(form.createDate, "th", "short") + " " + helper.getTime(form.createDate, "th") : '-'}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <div className="text-gray font12">
                                                    {form.createByName || '-'}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="text-gray font12">
                                                    วันที่ปรับปรุงข้อมูล
                                                </div>
                                            </td>
                                            <td>
                                                <div className="text-gray font12">
                                                    {form.updateDate ? helper.momentDateTime(form.updateDate, "th", "short") + " " + helper.getTime(form.updateDate, "th") : '-'}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <div className="text-gray font12">
                                                    {form.updateByName || '-'}
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalFarmCertificate
