import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import DataTable from "./DataTable";
import BoxCard from "./BoxCard";
import DataTableNodata from "./DataTableNodata";
import * as API from "../utils/apis";
import { loading } from "../redux/actions/loadingAction";
import { logout } from "../redux/actions/logoutAction";
import { useMasterData } from "../components/hooks/useMasterData";

function ModalOutbreakSearch({ show, close, save }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const initialForm = {
    chooseObId: "",
    chooseObCode: "",
    provinceId: "",
    districtId: "",
    outbreakCode: "",
    subDistrictId: "",
    villageId: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: true,
      },
      {
        sortBy: "farmId",
        name: "OutBreak",
        sortFlg: true,
      },
      {
        sortBy: "firstName",
        name: "ชนิดโรค",
        sortFlg: true,
      },
      {
        sortBy: "homeNo",
        name: "จำนวนฟาร์ม",
        sortFlg: true,
      },
      {
        sortBy: "provinceId",
        name: "จังหวัด",
        sortFlg: true,
      },
      {
        sortBy: "animalId",
        name: "อำเภอ",
        sortFlg: true,
      },
      {
        sortBy: "tombolId",
        name: "ตำบล",
        sortFlg: true,
      },
      {
        sortBy: "mooId",
        name: "หมู่บ้าน",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listOutbreak, setListOutbreak] = useState([]);

  const initialFormJson = {
    diseaseType: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  useMasterData("province", provinceList, setProvinceList);

  useEffect(async () => {
    await getProvince();
    await getMasterJson();
  }, []);

  useEffect(async () => {
    await getOutbreakObCode();
  }, [form.limit, form.page, form.sortBy, form.sortDir]);

  const getMasterJson = async () => {
    const params = await ["diseaseType"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status === 200) {
        setFormJson({
          ...formJson,
          diseaseType: data.diseaseType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getOutbreakObCode = async () => {
    const params = await {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",

      obCode: form.outbreakCode ? form.outbreakCode : "",
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.districtId ? form.districtId : "",
      mooId: form.subDistrictId ? form.subDistrictId : "",
    };

    dispatch(loading(true));
    try {
      const response = await API.getOutbreakObCode(params);
      const data = await response.data;
      if (response.status === 200) {
        dispatch(loading(false));
        await setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        await setListOutbreak(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, provinceId: value.value, zipcode: "" });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, districtId: value.value, zipcode: "" });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value, zipcode: value.zipcode });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const handleClose = () => {
    close();
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setVillageSelectValue([]);
    setSubDistrictSelectValue([]);
  };

  const toSave = async () => {
    await save({ obCode: form.chooseObCode, obId: form.chooseObId });
    handleClose();
  };

  const toSearch = async () => {
    await getOutbreakObCode();
  };

  const buttonSearch = () => {
    return (
      <button className="btn btn-success" onClick={() => toSearch()}>
        ค้นหา
      </button>
    );
  };

  const renderTableOutbreak = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>
            <label htmlFor={`ob_id${i}`} className="normal mb-0 cursor-p">
              {item.obCode}
            </label>
          </td>
          <td>
            <label htmlFor={`ob_id${i}`} className="normal mb-0 cursor-p">
              {item.diseaseTypeId
                ? formJson.diseaseType[item.diseaseTypeId]
                : "-"}
            </label>
          </td>
          <td>
            <label htmlFor={`ob_id${i}`} className="normal mb-0 cursor-p">
              {item.farmAmt ? item.farmAmt : "-"}
            </label>
          </td>
          <td>
            <label htmlFor={`ob_id${i}`} className="normal mb-0 cursor-p">
              {item.provinceName ? item.provinceName : "-"}
            </label>
          </td>
          <td>
            <label htmlFor={`ob_id${i}`} className="normal mb-0 cursor-p">
              {item.amphurName ? item.amphurName : "-"}
            </label>
          </td>
          <td>
            <label htmlFor={`ob_id${i}`} className="normal mb-0 cursor-p">
              {item.tambonName ? item.tambonName : "-"}
            </label>
          </td>
          <td>
            <label htmlFor={`ob_id${i}`} className="normal mb-0 cursor-p">
              {item.mooName ? item.mooName : "-"}
            </label>
          </td>
          <td className="text-center">
            <Form.Check
              key={i}
              className="m-0 p-0"
              type="radio"
              name="ob_id"
              id={`ob_id${i}`}
              value={item.id}
              checked={parseInt(form.chooseObId) === parseInt(item.id)}
              onChange={(e) => {
                setForm({
                  ...form,
                  chooseObId: e.target.value,
                  chooseObCode: item.obCode,
                });
              }}
            />
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={9} />;
    }
  };

  const getFilter = (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header closeButton className="pb-2 underline">
        <Modal.Title>
          <h5>
            <strong>ค้นหา Outbreak</strong>
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <BoxCard
          title="ตัวกรอง"
          classLabel=""
          classLabel=""
          headRight={buttonSearch()}
        >
          <Row className="mb-2 pl-2 ">
            <Col lg={2} md={6} sm={12} xs={12}>
              <InputText
                type="text"
                title="เลข Outbreak ID"
                idName="outbreakCode"
                value={form.outbreakCode}
                star={false}
                placeholder="โปรดระบุ"
                classLabel="normal"
                handleChange={(value) =>
                  setForm({ ...form, outbreakCode: value })
                }
              />
            </Col>
            <Col lg={2} md={6} sm={12} xs={12}>
              <InputSelect
                title="จังหวัด"
                star={false}
                placeholder="กรุณาเลือก"
                idName="province"
                classLabel="normal"
                selectValue={provinceSelectValue}
                optionsList={provinceList}
                handleChange={(value) => selectValueProvince(value)}
              />
            </Col>

            <Col lg={2} md={6} sm={12} xs={12}>
              <InputSelect
                title="อำเภอ"
                star={false}
                placeholder="กรุณาเลือก"
                idName="title"
                classLabel="normal"
                selectValue={districtSelectValue}
                optionsList={districtList}
                handleChange={(value) => selectValueDistrict(value)}
              />
            </Col>

            <Col lg={2} md={6} sm={12} xs={12}>
              <InputSelect
                title="ตำบล"
                star={false}
                placeholder="กรุณาเลือก"
                idName="subDistrict"
                classLabel="normal"
                selectValue={subDistrictSelectValue}
                optionsList={subDistrictList}
                handleChange={(value) => selectValueSubDistrict(value)}
              />
            </Col>

            <Col lg={2} md={6} sm={12} xs={12}>
              <InputSelect
                title="หมู่ที่"
                star={false}
                placeholder="กรุณาเลือก"
                idName="villageId"
                classLabel="normal"
                selectValue={villageSelectValue}
                optionsList={villageList}
                handleChange={(value) => {
                  setVillageSelectValue([value]);
                  setForm({ ...form, villageId: value.value });
                }}
              />
            </Col>
          </Row>
        </BoxCard>

        <BoxCard classNameBox="mt-3" title="รายการค้นหา Outbreak">
          <DataTable
            headColumns={form.headTitle}
            totalPages={form.totalPages}
            page={form.page}
            limit={form.limit}
            sortDir={form.sortDir}
            sortBy={form.sortBy}
            getLimit={(limit) => getFilter("limit", limit)}
            getFilter={(sortBy, sortDir) =>
              getFilter("filter", sortBy, sortDir)
            }
            getPage={(page) => getFilter("page", page)}
            totalItems={form.totalElements}
            numberOfItems={form.numberOfElements}
          >
            {renderTableOutbreak(listOutbreak)}
          </DataTable>
        </BoxCard>
        <div className="text-right py-2">
          <button
            className="btn btn-danger px-4 mr-2"
            onClick={() => handleClose()}
          >
            ยกเลิก
          </button>
          <button className="btn btn-success px-4" onClick={() => toSave()}>
            บันทึก
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalOutbreakSearch;
