import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import * as API from "../../../utils/apis";
import * as helper from "../../../utils/helper";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import InputMask from "../../../components/InputMask";
import Breadcrumb from "../../../components/Breadcrumb";
import InputText from "../../../components/InputText";
import UploadImageFile from "../../../components/UploadImageFile";
import InputDatePicker from "../../../components/InputDatePicker";
import ModalOrganizes from "../../../components/Modal.Organizes";
import { useMasterData } from "../../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function SaveUser(props) {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "footer"]);
  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/user/users/users", name: "ผู้ใช้งาน" },
    { path: "active", name: "บันทึกข้อมูลผู้ใช้งาน" },
  ];

  const initialForm = {
    profileImg: "",
    avatarFile: "",
    avatarName: "",
    userTypeId: "",
    userGroupId: "",
    positionId: "",
    orgId: "",
    orgName: "",
    titleId: "",
    pid: "",
    firstName: "",
    lastName: "",
    tel: "",
    email: "",
    password: "",
    cardImg: "",
    passwordConfirm: "",
    birthdate: "",
    mooName: "",
    dlaId: "",
    dlaName: "",
    userViewId: "",

    provinceId: "",
    amphurId: "",
    tambonId: "",
    mooId: "",
    address: "",
    road: "",
    soi: "",
    zipcode: "",

    checkNewCardImg: false,
    checkNewProfile: false,
    chooseSelect: false,
  };

  const [form, setForm] = useState(initialForm);

  const initInvalidMsg = {
    userTypeId: "",
    userGroupId: "",
    positionId: "",
    orgId: "",
    titleId: "",
    pid: "",
    firstName: "",
    lastName: "",
    tel: "",
    email: "",
    password: "",
    cardImg: "",
    passwordConfirm: "",
    birthdate: "",
    mooName: "",
    dlaId: "",
    dlaName: "",
    userViewId: "",
  };

  const [invalid, setInvalid] = useState(initInvalidMsg);
  const [userTypeSelectValue, setUserTypeSelectValue] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);

  const [userGroupSelectValue, setUserGroupSelectValue] = useState([]);
  const [userGroupList, setUserGroupList] = useState([]);

  const [titleSelectValue, setTitleSelectValue] = useState([]);
  const [titleList, setTitleList] = useState([]);

  const [userViewSelectValue, setUserViewSelectValue] = useState([]);
  const [userViewList, setUserViewList] = useState([]);

  const [dlaIdSelectValue, setDlaIdSelectValue] = useState([]);
  const [dlaIdList, setDlaIdList] = useState([]);

  const [positionSelectValue, setPositionSelectValue] = useState([]);
  const [positionList, setPositionList] = useState([]);

  const [isFarmer, setIsFarmer] = useState(false);

  // หน่วยงาน
  const [organizesList, setOrganizesList] = useState([]);

  const initShowOrgModal = false;
  const [showOrgModal, setShowOrgModal] = useState(initShowOrgModal);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  // =================

  useMasterData("province", provinceList, setProvinceList);

  useEffect(async () => {
    await getProvince();
    await getUserType();
    await getTitle();
    await getUserGroup();
    await getUserView();
    await getOrganizes();
    await getDlas();
    await getPositions();
  }, []);

  useEffect(async () => {
    if (location.pathname.includes("edit") && !form.chooseSelect) {
      await getUsersById(props.match.params.id);
    }
  }, [
    location.pathname,
    form.provinceId,
    form.amphurId,
    form.tambonId,
    form.mooId,
    positionList,
  ]);

  const getUserType = async () => {
    try {
      const response = await API.getUserType("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let userTypesArray = [];
        data.content.forEach((element) => {
          userTypesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setUserTypeList(userTypesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTitle = async () => {
    try {
      const response = await API.getTitle(1, "");
      const data = await response?.data;
      if (response.status === 200) {
        let titlesArray = [];
        data.content.forEach((element) => {
          titlesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setTitleList(titlesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPositions = async () => {
    try {
      const response = await API.getPositions(1, "");
      const data = await response?.data;
      if (response.status === 200) {
        let positionsArray = [];
        data.content.forEach((element) => {
          positionsArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setPositionList(positionsArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOrganizes = async () => {
    try {
      const response = await API.getOrganizes("", "", "", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let organizesArray = [];
        data.content.forEach((element) => {
          organizesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setOrganizesList(organizesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserGroup = async () => {
    try {
      const response = await API.getUserGroup("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let userGroupsArray = [];
        data.content.forEach((element) => {
          userGroupsArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setUserGroupList(userGroupsArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserView = async () => {
    try {
      const response = await API.getUserView("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let userViewArray = [];
        data.content.forEach((element) => {
          userViewArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setUserViewList(userViewArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDlas = async () => {
    try {
      const response = await API.getDLAS("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let dlaIdArray = [];
        data.content.forEach((element) => {
          dlaIdArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDlaIdList(dlaIdArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setDisableForm = (value) => {
    if (value === 1) {
      setIsFarmer(true);
    } else {
      setIsFarmer(false);
    }
  };

  const getUsersById = async (id) => {
    let dataUsers;
    try {
      const response = await API.getUsersById(id);
      const dataRes = await response?.data;
      if (response.status === 200) {
        if (dataRes) {
          dataUsers = dataRes;
        }
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataUsers) {
      if (dataUsers.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value === dataUsers.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length === 0) {
          await getDistricts(dataUsers.provinceId);
        }
      }

      if (dataUsers.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value === dataUsers.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length === 0) {
          await getSubDistricts(dataUsers.amphurId);
        }
      }

      if (dataUsers.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value === dataUsers.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length === 0) {
          await getVillage(dataUsers.tambonId);
        }
      }

      if (dataUsers.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value === dataUsers.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataUsers.titleId) {
        let titleSelect = await titleList.find(
          (item) => item.value === dataUsers.titleId
        );
        setTitleSelectValue(titleSelect);
      }

      if (dataUsers.positionId) {
        let positionSelect = await positionList.find(
          (item) => item.value === dataUsers.positionId
        );
        setPositionSelectValue(positionSelect);
      }

      if (dataUsers.userGroupId) {
        let userGroupSelect = await userGroupList.find(
          (item) => item.value === dataUsers.userGroupId
        );
        setUserGroupSelectValue(userGroupSelect);
      }

      if (dataUsers.userTypeId) {
        let userTypeSelect = await userTypeList.find(
          (item) => item.value === dataUsers.userTypeId
        );
        setUserTypeSelectValue(userTypeSelect);
      }

      if (dataUsers.userViewId) {
        let userViewSelect = await userViewList.find(
          (item) => item.value === dataUsers.userViewId
        );
        setUserViewSelectValue(userViewSelect);
      }

      if (dataUsers.dlaId) {
        let dlaSelect = await dlaIdList.find(
          (item) => item.value === dataUsers.dlaId
        );
        setDlaIdSelectValue(dlaSelect);
      }

      setForm({
        ...form,
        userTypeId: dataUsers.userTypeId,
        profileImg: dataUsers.profileImgUrl,
        pid: dataUsers.pid,
        titleId: dataUsers.titleId,
        firstName: dataUsers.firstName,
        lastName: dataUsers.lastName,
        birthdate: dataUsers.birthdate,
        tel: dataUsers.tel,
        email: dataUsers.email,
        cardImg: dataUsers.evidenceUrl,
        positionId: dataUsers.positionId,
        orgId: dataUsers.orgId,
        orgName: dataUsers.orgName,
        userGroupId: dataUsers.userGroupId,
        userViewId: dataUsers.userViewId,
        provinceId: dataUsers.provinceId,
        amphurId: dataUsers.amphurId,
        tambonId: dataUsers.tambonId,
        mooId: dataUsers.mooId,
        address: dataUsers.address,
        soi: dataUsers.soi,
        road: dataUsers.road,
        zipcode: dataUsers.postal,
        dlaId: dataUsers.dlaId,
        dlaName: dataUsers.dlaName,
      });

      setDisableForm(form.userTypeId);
    }
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if (form.userTypeId === "") {
      addInvalid("userTypeId", "กรุณาเลือกประเภทผู้ใช้");
      validated = false;
    }
    if (form.pid === "") {
      addInvalid("pid", "โปรดระบุหมายเลขประจำตัว");
      validated = false;
    }

    if (!helper.chkDigitPid(form.pid)) {
      addInvalid("pid", "กรุณาระบุเลขประจำตัวให้ถูกต้อง");
      validated = false;
    }

    if (form.titleId === "") {
      addInvalid("titleId", "กรุณาเลือกคำนำหน้าชื่อ");
      validated = false;
    }

    if (form.firstName === "") {
      addInvalid("firstName", "โปรดระบุชื่อ");
      validated = false;
    }

    if (form.lastName === "") {
      addInvalid("lastName", "โปรดระบุนามสกุล");
      validated = false;
    }

    if (form.tel === "") {
      addInvalid("tel", "โปรดระบุหมายเลขโทรศัพท์มือถือ");
      validated = false;
    }

    if (form.email === "") {
      addInvalid("email", "โปรดระบุอีเมล");
      validated = false;
    }

    if (form.positionId === "" && !isFarmer) {
      addInvalid("positionId", "โปรดระบุตำแหน่ง");
      validated = false;
    }

    if (form.orgId === "" && !isFarmer) {
      addInvalid("orgId", "กรุณาเลือกหน่วยงานที่สังกัด");
      validated = false;
    }

    if (form.userGroupId === "" && !isFarmer) {
      addInvalid("userGroupId", "กรุณาเลือกกลุ่มผู้ใช้งาน");
      validated = false;
    }

    if (form.cardImg === "") {
      addInvalid("cardImg", "กรุณาแนบรูปถ่ายบัตรประชาชนหรือบัตรราชการ");
      validated = false;
    }

    if (form.userViewId === "" && !isFarmer) {
      addInvalid("userViewId", "กรุณาเลือกสิทธิการดูข้อมูล");
      validated = false;
    }

    if (location.pathname.includes("/data-manage/user/edit")) {
      if (form.password != "") {
        if (form.password.length < 8 || form.password.length > 45) {
          addInvalid("password", "โปรดระบุรหัสผ่านอย่างน้อย 8 ตัวอักษร");
          validated = false;
        }

        if (
          form.passwordConfirm.length < 8 ||
          form.passwordConfirm.length > 45
        ) {
          addInvalid("passwordConfirm", "โปรดระบุรหัสผ่านอย่างน้อย 8 ตัวอักษร");
          validated = false;
        }

        if (form.password != form.passwordConfirm) {
          addInvalid("passwordConfirm", "กรุณากรอกรหัสผ่านให้ตรงกัน");
          validated = false;
        }
      }
    } else {
      if (
        form.password === "" ||
        form.password === undefined ||
        form.password.length < 8 ||
        form.password.length > 45
      ) {
        addInvalid("password", "โปรดระบุรหัสผ่านอย่างน้อย 8 ตัวอักษร");
        validated = false;
      }
      if (
        form.passwordConfirm === "" ||
        form.passwordConfirm === undefined ||
        form.passwordConfirm.length < 8 ||
        form.passwordConfirm.length > 45
      ) {
        addInvalid("passwordConfirm", "โปรดระบุรหัสผ่านอย่างน้อย 8 ตัวอักษร");
        validated = false;
      }
      if (form.password != form.passwordConfirm) {
        addInvalid("passwordConfirm", "กรุณากรอกรหัสผ่านให้ตรงกัน");
        validated = false;
      }
    }
    return validated;
  };

  const saveAddUsers = async () => {
    if (validate()) {
      dispatch(loading(true));
      const formData = new FormData();
      formData.append("userTypeId", form.userTypeId);
      if (form.checkNewProfile) {
        formData.append("profileImg", form.profileImg || "");
      }
      formData.append("titleId", form.titleId);
      formData.append("firstName", form.firstName);
      formData.append("lastName", form.lastName);
      formData.append("pid", form.pid);
      if (form.birthdate) {
        formData.append("birthdate", helper.getDate(form.birthdate));
      }
      formData.append("tel", form.tel);
      formData.append("email", form.email);
      if (form.password && form.password) {
        formData.append("password", form.password);
        formData.append("passwordConfirm", form.passwordConfirm);
      }
      if (form.checkNewCardImg) {
        formData.append("cardImg", form.cardImg || "");
      }
      formData.append("positionId", form.positionId || "");
      formData.append("orgId", form.orgId || "");
      formData.append("userGroupId", form.userGroupId || "");
      formData.append("userViewId", form.userViewId || "");

      formData.append("provinceId", form.provinceId || "");
      formData.append("amphurId", form.amphurId || "");
      formData.append("tambonId", form.tambonId || "");
      formData.append("mooId", form.mooId || "");
      formData.append("address", form.address || "");
      formData.append("soi", form.soi || "");
      formData.append("road", form.road || "");
      formData.append("postal", form.zipcode || "");
      formData.append("dlaId", form.dlaId || "");
      formData.append("dlaName", form.dlaName || "");

      let response;
      MySwal.fire({
        text: `ต้องการบันทึก`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (value) => {
        if (value.isConfirmed) {
          if (
            location.pathname.includes("/data-manage/users/users/save-user")
          ) {
            try {
              response = await API.saveUsers(formData);
              if (response.status === 200) {
                dispatch(loading(false));
                MySwal.fire({
                  text: "บันทึกข้อมูลผู้ใช้งานสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ตกลง",
                }).then((result) => {
                  if (result.value) {
                    history.push("/data-manage/users/users");
                  }
                });
              }
            } catch (error) {
              dispatch(loading(false));
              if (error.response.status === 401) {
                console.log(error.response);
                dispatch(logout({ history }));
              }
              const mgeError = error.response.data.errors[0];
              MySwal.fire({
                text: mgeError,
                icon: "warning",
                confirmButtonText: "ตกลง",
              });
            }
          } else {
            try {
              response = await API.updateUsers(props.match.params.id, formData);
              if (response.status === 200) {
                dispatch(loading(false));
                MySwal.fire({
                  text: "แก้ไขข้อมูลผู้ใช้งานสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ตกลง",
                }).then((result) => {
                  if (result.value) {
                    history.push("/data-manage/users/users");
                  }
                });
              }
            } catch (error) {
              dispatch(loading(false));
              if (error.response.status === 401) {
                console.log(error.response);
                dispatch(logout({ history }));
              }
            }
          }
        } else {
          dispatch(loading(false));
        }
      });
    }
  };

  const setOrganizes = (data) => {
    removeInvalid("orgId");
    setForm({ ...form, orgId: data.value, orgName: data.label });
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      zipcode: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      amphurId: value.value,
      zipcode: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      tambonId: value.value,
      zipcode: value.zipcode,
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  return (
    <div>
      <div className="px-4 py-2 bg-white d-flex justify-content-between align-items-center">
        <Breadcrumb data={breadcrumbPath} />
        <div>
          <button
            className="btn px-4 btn-sm btn-danger mr-2"
            onClick={() => history.push("/data-manage/users/users")}
          >
            ยกเลิก
          </button>
          <button
            className="btn px-4 btn-sm btn-success"
            onClick={() => saveAddUsers()}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes("edit")
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="mt-3 my-3" title="1. ข้อมูลทั่วไป">
            <div className="border mt-2 my-2">
              <div className="mt-4 my-4 mr-4 ml-4">
                <Row>
                  <Col lg={1} md={2} sm={3}>
                    <p className="mb-0">
                      ประเภทผู้ใช้<span className="text-danger"> *</span> :
                    </p>
                  </Col>
                  <Col lg={2} md={4} sm={6}>
                    <InputSelect
                      star={false}
                      placeholder="กรุณาเลือก"
                      idName="userType"
                      classLabel="normal"
                      selectValue={userTypeSelectValue}
                      optionsList={userTypeList}
                      handleChange={(value) => {
                        setDisableForm(value.value);
                        setForm({ ...form, userTypeId: value.value });
                        setUserTypeSelectValue([value]);
                      }}
                      handleInvalid={() => removeInvalid("userTypeId")}
                      invalid={invalid.userTypeId}
                    />
                  </Col>
                </Row>

                <Row className="mb-0 mt-2">
                  <Col lg={1} md={2} sm={3}>
                    <p className="mb-0 mt-2">รูปโปรไฟล์ :</p>
                  </Col>
                  <Col lg={3} md={4} sm={6}>
                    <UploadImageFile
                      title=" "
                      classLabel="normal mt-2"
                      star={false}
                      type="profile"
                      id="profile"
                      position="left"
                      onChange={(value) =>
                        setForm({
                          ...form,
                          profileImg: value,
                          checkNewProfile: true,
                        })
                      }
                      files={
                        form.profileImg == null || form.profileImg == ""
                          ? ""
                          : form.profileImg
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-0 mt-3">
                  <Col lg={2} md={3} sm={6}>
                    <InputText
                      type="text"
                      title="หมายเลขประจำตัว"
                      star={true}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      maxLength="13"
                      value={form.pid}
                      handleChange={(value) => {
                        if (/^[0-9]*$/.test(value)) {
                          setForm({ ...form, pid: value });
                        }
                      }}
                      handleInvalid={() => removeInvalid("pid")}
                      invalid={invalid.pid}
                    />
                  </Col>

                  <Col lg={2} md={3} sm={6}>
                    <InputSelect
                      title="คำนำหน้าชื่อ"
                      star={true}
                      placeholder="กรุณาเลือก"
                      idName="title"
                      classLabel="normal"
                      selectValue={titleSelectValue}
                      optionsList={titleList}
                      handleChange={(value) => {
                        setForm({ ...form, titleId: value.value });
                        setTitleSelectValue([value]);
                      }}
                      handleInvalid={() => removeInvalid("titleId")}
                      invalid={invalid.titleId}
                    />
                  </Col>

                  <Col lg={2} md={3} sm={6}>
                    <InputText
                      type="text"
                      title="ชื่อ"
                      star={true}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      value={form.firstName}
                      handleChange={(value) =>
                        setForm({ ...form, firstName: value })
                      }
                      handleInvalid={() => removeInvalid("firstName")}
                      invalid={invalid.firstName}
                    />
                  </Col>

                  <Col lg={2} md={3} sm={6}>
                    <InputText
                      type="text"
                      title="สกุล"
                      star={true}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      value={form.lastName}
                      handleChange={(value) =>
                        setForm({ ...form, lastName: value })
                      }
                      handleInvalid={() => removeInvalid("lastName")}
                      invalid={invalid.lastName}
                    />
                  </Col>

                  <Col lg={2} md={3} sm={6}>
                    <InputDatePicker
                      title="วัน/เดือน/ปีเกิด"
                      classLabel="normal"
                      placeholder="วัน/เดือน/ปี"
                      value={form.birthdate}
                      star={false}
                      handleChange={(value) =>
                        setForm({ ...form, birthdate: value })
                      }
                    />
                  </Col>
                </Row>

                <Row className="mb-0 mt-3">
                  <Col lg={2} md={3} sm={6}>
                    <InputText
                      type="tel"
                      title="โทรศัพท์มือถือ"
                      idName="phone"
                      star={true}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      maxLength="10"
                      value={form.tel}
                      handleChange={(value) => {
                        if (/^[0-9]*$/.test(value)) {
                          setForm({ ...form, tel: value });
                        }
                      }}
                      handleInvalid={() => removeInvalid("tel")}
                      invalid={invalid.tel}
                    />
                  </Col>

                  <Col lg={2} md={3} sm={6}>
                    <InputText
                      type="text"
                      title="อีเมล"
                      star={true}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      value={form.email}
                      handleChange={(value) =>
                        setForm({ ...form, email: value })
                      }
                      handleInvalid={() => removeInvalid("email")}
                      invalid={invalid.email}
                    />
                  </Col>

                  <Col lg={2} md={3} sm={6}>
                    <InputText
                      type="password"
                      title="รหัสผ่าน"
                      star={false}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      value={form.password}
                      handleChange={(value) =>
                        setForm({ ...form, password: value })
                      }
                      handleInvalid={() => removeInvalid("password")}
                      invalid={invalid.password}
                    />
                  </Col>

                  <Col lg={2} md={3} sm={6}>
                    <InputText
                      type="password"
                      title="ยืนยันรหัสผ่าน"
                      star={false}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      value={form.passwordConfirm}
                      handleChange={(value) =>
                        setForm({ ...form, passwordConfirm: value })
                      }
                      handleInvalid={() => removeInvalid("passwordConfirm")}
                      invalid={invalid.passwordConfirm}
                    />
                  </Col>
                </Row>

                {/* <Row className="mb-0 mt-3">
                                    <Col lg={1} md={2} sm={3}>
                                        <p className="mb-0">การเชื่อมโยง: </p>
                                    </Col>

                                    <Col lg={1} md={2} sm={3}>
                                        <Form.Check
                                            type="checkbox"
                                            name="selectAllTab2"
                                            id="selectAllTab2"
                                            label="Gmail"
                                        // onChange={}
                                        // checked={}
                                        />
                                    </Col>

                                    <Col lg={1} md={2} sm={3}>
                                        <Form.Check
                                            type="checkbox"
                                            name="selectAllTab2"
                                            id="selectAllTab2"
                                            label="Facebook"
                                        // onChange={}
                                        // checked={}
                                        />
                                    </Col>

                                    <Col lg={1} md={2} sm={3}>
                                        <Form.Check
                                            type="checkbox"
                                            name="selectAllTab2"
                                            id="selectAllTab2"
                                            label="Line"
                                        // onChange={}
                                        // checked={}
                                        />
                                    </Col>
                                </Row> */}

                <Row className="mb-0 mt-3">
                  <Col lg={3} md={5} sm={3}>
                    <p className="mb-0 mt-2">
                      รูปถ่ายบัตรประชาชน หรือบัตรราชการ{" "}
                      <span className="text-danger">* </span>:
                    </p>
                  </Col>

                  <Col lg={9} md={7} sm={12} xs={12}>
                    <UploadImageFile
                      title=" "
                      classLabel="normal mt-2"
                      star={false}
                      type="img"
                      id="img"
                      position="left"
                      onChange={(value) =>
                        setForm({
                          ...form,
                          cardImg: value,
                          checkNewCardImg: true,
                        })
                      }
                      files={
                        form.cardImg == null || form.cardImg == ""
                          ? ""
                          : form.cardImg
                      }
                      handleInvalid={() => removeInvalid("cardImg")}
                      invalid={invalid.cardImg}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="mt-4">
              <p className="mb-0 bold">* สำหรับเจ้าหน้าที่</p>
            </div>
            <div className="border my-3">
              <div className="mt-4 my-4 mr-4 ml-4">
                <Row>
                  <Col lg={2} md={3} sm={6}>
                    <InputSelect
                      title="ตำแหน่ง"
                      star={true}
                      placeholder="กรุณาเลือก"
                      idName="positionId"
                      classLabel="normal"
                      selectValue={positionSelectValue}
                      optionsList={positionList}
                      disabled={isFarmer || form.userTypeId === ""}
                      handleChange={(value) => {
                        setForm({ ...form, positionId: value.value });
                        setPositionSelectValue([value]);
                      }}
                      handleInvalid={() => removeInvalid("positionId")}
                      invalid={invalid.positionId}
                    />
                  </Col>

                  <Col lg={2} md={3} sm={6}>
                    <label className="mb-0 normal">
                      หน่วยงานที่สังกัด <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex align-items-center">
                      {form.orgName ? (
                        <>
                          <button
                            className="btn btn-primary pt-2 px-3"
                            onClick={() => setShowOrgModal(true)}
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                          <div className="ml-2">{form.orgName}</div>
                        </>
                      ) : (
                        <button
                          className="btn btn-primary p-2 d-flex align-items-center "
                          onClick={() => setShowOrgModal(true)}
                          disabled={isFarmer || form.userTypeId === ""}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                          <p className="mb-0 ml-2">{t("search")}</p>
                        </button>
                      )}
                    </div>
                    {invalid.orgId ? (
                      <div className="invalid-error">{invalid.orgId}</div>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col lg={2} md={3} sm={6}>
                    <InputSelect
                      title="กลุ่มผู้ใช้งาน"
                      star={true}
                      placeholder="กรุณาเลือก"
                      idName="userGroupId"
                      classLabel="normal"
                      selectValue={userGroupSelectValue}
                      optionsList={userGroupList}
                      disabled={isFarmer || form.userTypeId === ""}
                      handleChange={(value) => {
                        setForm({ ...form, userGroupId: value.value });
                        setUserGroupSelectValue([value]);
                      }}
                      handleInvalid={() => removeInvalid("userGroupId")}
                      invalid={invalid.userGroupId}
                    />
                  </Col>
                  <Col lg={2} md={3} sm={6}>
                    <InputSelect
                      title="สิทธิการดูข้อมูล"
                      star={true}
                      placeholder="กรุณาเลือก"
                      idName="userViewId"
                      classLabel="normal"
                      selectValue={userViewSelectValue}
                      optionsList={userViewList}
                      disabled={isFarmer || form.userTypeId === ""}
                      handleChange={(value) => {
                        setForm({ ...form, userViewId: value.value });
                        setUserViewSelectValue(value);
                      }}
                      handleInvalid={() => removeInvalid("userViewId")}
                      invalid={invalid.userViewId}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </BoxCard>

          <BoxCard classNameBox="mt-4 my-4" title="2. ที่อยู่ตามทะเบียนบ้าน">
            <div className="mt-4 my-4 mr-4 ml-4">
              <Row>
                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => {
                      selectValueProvince(value);
                    }}
                  />
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputSelect
                    title="เขต/อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => {
                      selectValueDistrict(value);
                    }}
                  />
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputSelect
                    title="แขวง/ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => {
                      selectValueSubDistrict(value);
                    }}
                  />
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputSelect
                    title="หมู่ที่/หมู่บ้าน"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({
                        ...form,
                        mooId: value.value,
                        chooseSelect: true,
                      });
                    }}
                  />
                </Col>

                <Col lg={2} md={3} sm={6}>
                  <InputMask
                    type="text"
                    title="บ้านเลขที่"
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    value={form.address}
                    handleChange={(value) => {
                      if (/^[0-9/]*$/.test(value)) {
                        setForm({ ...form, address: value });
                      }
                    }}
                  />
                </Col>

                <Col lg={2} md={3} sm={6}>
                  <InputText
                    type="text"
                    title="ตรอก/ซอย"
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    value={form.soi}
                    handleChange={(value) => setForm({ ...form, soi: value })}
                  />
                </Col>
              </Row>
              <Row className="mb-0 mt-3">
                <Col lg={2} md={3} sm={6}>
                  <InputText
                    type="text"
                    title="ถนน"
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    value={form.road}
                    handleChange={(value) => setForm({ ...form, road: value })}
                  />
                </Col>
                <Col lg={2} md={3} sm={6}>
                  <InputText
                    type="text"
                    title="รหัสไปรษณีย์"
                    idName="zipcode"
                    value={form.zipcode}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled={true}
                    handleChange={(value) =>
                      setForm({ ...form, zipcode: value })
                    }
                  />
                </Col>
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="องค์กรปกครองส่วนท้องถิ่น"
                    star={false}
                    placeholder="กรุณาเลือก"
                    classLabel="normal"
                    selectValue={dlaIdSelectValue}
                    optionsList={dlaIdList}
                    handleChange={(value) => {
                      setForm({ ...form, dlaId: value.value });
                      setDlaIdSelectValue(value);
                    }}
                  />
                </Col>
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    title="ชื่อองค์กรปกครองส่วนท้องถิ่น"
                    star={false}
                    classLabel="normal"
                    placeholder="โปรดระบุ"
                    value={form.dlaName}
                    handleChange={(value) => {
                      setForm({ ...form, dlaName: value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>
        </div>
        {showOrgModal && (
          <ModalOrganizes
            show={showOrgModal}
            close={() => setShowOrgModal(false)}
            toSaveOrg={(data) => setOrganizes(data)}
          />
        )}
      </div>
    </div>
  );
}

export default SaveUser;
