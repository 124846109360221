import React from "react";
import { Line } from 'react-chartjs-2';

function LineChart({ dataGraph, name }) {
    const backgroundColor = [
        'rgb(255, 0, 0)',
        'rgb(255, 153, 0)',
        'rgb(255,229,5)',
        'rgb(0, 255, 0)',
        'rgb(0, 255, 255)',
    ]
    const borderColor = [
        'rgba(255, 0, 0, 0.5)',
        'rgba(255, 153, 0, 0.5)',
        'rgba(255, 255, 0, 0.5)',
        'rgba(0, 255, 0, 0.5)',
        'rgba(0, 255, 255, 0.5)',
    ]
    let arrayGraph = Object.values(dataGraph)
    let dataSetArray = []
    let emptyData = [0, 0, 0, 0, 0]
    let emptyDataSet = []
    let arrayLabel = Object.keys(dataGraph)
    emptyData.map((item, index) => {
        emptyDataSet.push({
            label: index + 1,
            data: item,
        })
    })
    arrayGraph.map((item, index) => {
        dataSetArray.push({
            label: arrayLabel[index],
            data: Object.values(item),
            fill: false,
            backgroundColor: backgroundColor[index],
            borderColor: borderColor[index],
            tension: 0.5,
        })
    })

    const data = {
        labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
        datasets: dataSetArray.length > 0 ? dataSetArray : emptyDataSet,
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };
    return (
        <div id="img-graph">
            <h6 className="mt-2 mb-0 font14">{name}</h6>
            <Line data={data} options={options} />
        </div>
    );
}

export default LineChart;
