import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";

function ModalSymptom({ show, close, save, mode, idEdit }) {
    const user = useSelector(state => state.login.result);
    const initialForm = {
        symptom_id: "",
        symptom_name: "",
        symptom_code: "",
        animalId: "",
        status: "",
        createDate: "",
        updateDate: "",

        checkShow: false
    };
    const initInvalidMsg = {
        symptom_name: "",
        symptom_code: "",
        animalId: "",
        status: "",
    };
    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];
    const initAnimalIdSelectValue = [];
    const selectAnimalIdList = [];

    const initStatusSelectValue = [];

    const dispatch = useDispatch();
    const history = useHistory();

    const [form, setForm] = useState(initialForm);
    const [invalid, setInvalid] = useState(initInvalidMsg);
    const [animalIdSelectValue, setAnimalIdSelectValue] = useState(initAnimalIdSelectValue)
    const [animalIdList, setAnimalList] = useState(selectAnimalIdList)
    const [statusSelectValue, setStatusSelectValue] = useState(initStatusSelectValue);
    const [statusList, setStatusList] = useState(selectStatusList);

    useEffect(async () => {
        if (mode === "add") {
            await setForm(initialForm)
            setStatusSelectValue([])
            setAnimalIdSelectValue([])
        }
    }, [mode])

    useEffect(async () => {
        if (mode === "edit" && !form.checkShow) {
            await getSymptomById(idEdit)
        }
    }, [mode, idEdit, show, form.symptom_name, form.symptom_code, form.status, form.animalId])

    useEffect(async () => {
        await getAnimal()
    }, [])

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;

        if (form.symptom_name === "") {
            addInvalid("symptom_name", "โปรดระบุอาการป่วย");
            validated = false;
        }

        if (form.symptom_code === "") {
            addInvalid("symptom_code", "โปรดระบุรหัส");
            validated = false;
        }

        if (form.animalId === "") {
            addInvalid("animalId", "กรุณาเลือกชนิดสัตว์");
            validated = false;
        }

        if (form.status === "") {
            addInvalid("status", "กรุณาเลือกสถานะ");
            validated = false;
        }

        return validated;
    };

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setStatusSelectValue([])
        setAnimalIdSelectValue([])
    }

    const toConfirm = async () => {
        if (validate()) {
            await save({ symptom: form, mode: mode });
            handleClose();
        }
    };

    const getAnimal = async () => {
        try {
            const response = await API.getAnimal("", 1);
            const data = await response.data;
            if (response.status === 200) {
                let animalArray = [];
                data.content.forEach(element => {
                    animalArray.push({
                        value: element.id, label: element.name,
                    });
                });
                await setAnimalList(animalArray);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getSymptomById = async (id) => {
        let dataSy;
        try {
            const response = await API.getSymptomById(id);
            const data = await response.data;
            if (response.status === 200) {
                dataSy = data;
            }
        } catch (error) {
            dispatch(loading(false))
            if (error.response && error.response.status === 401) {
                dispatch(logout({ history }))
            }
            console.log(error)
        }

        if (dataSy.statusId) {
            let statusSelect = await statusList.find(item => item.value === dataSy.statusId);
            setStatusSelectValue(statusSelect);
        }

        if (dataSy.animalTypeId) {
            let animalIdSelect = await animalIdList.find(item => item.value === dataSy.animalTypeId);
            setAnimalIdSelectValue(animalIdSelect);
        }

        setForm({
            ...form,
            symptom_id: dataSy.id,
            symptom_name: dataSy.name,
            symptom_code: dataSy.code,
            animalId: dataSy.animalTypeId,
            status: dataSy.statusId,
            createDate: dataSy.createDate,
            updateDate: dataSy.updateDate

        })
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => { handleClose() }}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่มอาการป่วย</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="อาการป่วย" idName="symptom_name" value={form.symptom_name} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, symptom_name: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("symptom_name")}
                                        invalid={invalid.symptom_name}
                                    />
                                </Col>

                                <Col lg={4} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="รหัส" idName="symptom_code" value={form.symptom_code} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, symptom_code: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("symptom_code")}
                                        invalid={invalid.symptom_code}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="ชนิดสัตว์" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                        selectValue={animalIdSelectValue} optionsList={animalIdList}
                                        handleSelectValue={(value) => setAnimalIdSelectValue([{ value: value.value, label: value.label },])}
                                        handleChange={(value) => setForm({ ...form, animalId: value.value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("animalId")}
                                        invalid={invalid.animalId}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                        selectValue={statusSelectValue} optionsList={statusList}
                                        handleSelectValue={(value) => setStatusSelectValue([{ value: value.value, label: value.label },])}
                                        handleChange={(value) => setForm({ ...form, status: value.value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("status")} invalid={invalid.status}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mr-4">วันที่บันทึกข้อมูล</p>
                                        <p>{form.createDate ? helper.momentDate(form.createDate, "th", "short") : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                                        <p className="mr-3" >วันที่ปรับปรุงข้อมูล</p>
                                        <p>{form.updateDate ? helper.momentDate(form.updateDate, "th", "short") : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalSymptom
