import * as actionTypes from "./masterDataTypes";

export const addProvinceList = (payload) => {
  return { type: actionTypes.PROVINCE, payload };
};

export const checkProvinceList = (payload) => {
  return { type: actionTypes.PROVINCE_CHECK, payload };
};

export const addZoneLivestockList = (payload) => {
  return { type: actionTypes.ZONE_LIVESTOCK, payload };
};

export const checkZoneLivestockList = (payload) => {
  return { type: actionTypes.ZONE_LIVESTOCK_CHECK, payload };
};
