import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import DataTable from "../../components/DataTable";
import InputSelect from "../../components/InputSelect";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InputDatePicker from "../../components/InputDatePicker";
import Breadcrumb from "../../components/Breadcrumb";
import ModalConfirm from "../../components/Modal.Confirm";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";
import { loading } from "../../redux/actions/loadingAction";
import { logout } from "../../redux/actions/logoutAction";
import { Row, Col } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import DataTableNodata from "../../components/DataTableNodata";
import Swal from "sweetalert2";
import {
  getDiseaseInformNotify,
  getDiseaseReportNotify,
} from "../../redux/actions/notifyAction";
import {
  useMasterData,
  useOptionsListYears,
  useCurrentYear,
} from "../../components/hooks/useMasterData";
import ButtonReportLink from "../../components/ButtonReportLink";

const MySwal = withReactContent(Swal);

function Disease_Report() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const diseaseInformNotify = useSelector((state) => state.diseaseInformNotify);
  const statusDisease = useSelector((state) => state.statusDisease);

  const initialForm = {
    reportid: "",
    name: "",
    provinceId: "",
    districtId: "",
    date_found_disease: "",
    report_date: "",
    todate: "",
    todate_re: "",
    subDistrictId: "",
    animal: "",
    status: "",

    diseaseId: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    searchFlg: false,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
      },
      {
        sortBy: "infCode",
        name: "รหัสการแจ้งโรค",
        sortFlg: true,
      },
      {
        sortBy: "fullname",
        name: "ชื่อผู้แจ้ง",
        sortFlg: true,
      },
      {
        sortBy: "informByName",
        name: "แหล่งที่มา",
        sortFlg: true,
      },
      {
        sortBy: "foundDiseaseDate",
        name: "วันที่พบโรค",
        sortFlg: true,
      },
      {
        sortBy: "createDate",
        name: "วันที่แจ้งโรค",
        sortFlg: true,
      },
      {
        sortBy: "animalTypeName",
        name: "ชนิดสัตว์",
        sortFlg: true,
      },
      {
        sortBy: "symptomName",
        name: "อาการ",
        sortFlg: true,
      },
      {
        sortBy: "address",
        name: "สถานที่พบโรค",
        sortFlg: true,
      },
      {
        sortBy: "statusId",
        name: "สถานะการดำเนินการ",
        sortFlg: true,
      },
      {
        sortBy: "acceptDate",
        name: "วันที่ดำเนินงาน",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listReportDisease, setListReportDisease] = useState([]);

  const initialFormJson = {
    animalId: {},
    province: {},
    district: {},
    subDistrict: {},
    village: {},
    symptom: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initShowConfirm = false;
  const [showConfirm, setShowConfirm] = useState(initShowConfirm);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  // select รหัสการแจ้งโรค /ชนิดสัตว์ /สถานะการดำเนินการ
  const [ReportidSelectValue, setReportidSelectValue] = useState([]);
  const [ReportidList, setReportidList] = useState([]);

  const [AnimalSelectValue, setAnimalSelectValue] = useState([]);
  const [AnimalList, setAnimalList] = useState([]);

  const [StatusSelectValue, setStatusSelectValue] = useState([]);
  const [StatusList, setStatusList] = useState([]);

  const [ChannelList, setChannelList] = useState([]);

  // =================
  const [isClearSearch, setIsClearSearch] = useState(false);

  useMasterData("province", provinceList, setProvinceList);
  const optionsListYear = useOptionsListYears();
  const currentYearValue = useCurrentYear();
  const [yearFilterList, setYearFilterList] = useState(currentYearValue);

  useEffect(() => {
    if (diseaseInformNotify.result !== null) {
      dispatch(getDiseaseInformNotify({ notify: "" }));
      dispatch(getDiseaseReportNotify({ notify: "" }));
    } else {
      dispatch(getDiseaseInformNotify({ notify: "" }));
      dispatch(getDiseaseReportNotify({ notify: "" }));
    }

    getMasterJson();
    getStatus();
    getProvince();
    getAnimal();
    getInform();
  }, []);

  useEffect(() => {
    getReportDisease();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getReportDisease = async () => {
    const params = await {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",
      infCode: form.reportid ? form.reportid : "",
      person: form.name ? form.name : "",
      foundDateBegin: form.date_found_disease
        ? helper.getDate(form.date_found_disease)
        : "",
      foundDateEnd: form.todate ? helper.getDate(form.todate) : "",
      createDateBegin: form.report_date ? helper.getDate(form.report_date) : "",
      createDateEnd: form.todate_re ? helper.getDate(form.todate_re) : "",
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
      animalTypeIds: form.animal ? form.animal : "",
      statusId: form.status ? form.status : "",
      onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
    };

    dispatch(loading(true));
    try {
      const response = await API.getReportDisease(params);

      const data = await response.data;
      // console.log("params---getReportDisease--🐳🐳", params, response);

      if (response.status === 200) {
        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListReportDisease(data.content);

        if (!form.searchFlg) {
          let reportArray = [];
          data.content.forEach((element) => {
            reportArray.push({
              value: element.infCode,
              label: element.infCode,
            });
          });

          setReportidList(reportArray);
        }
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getMasterJson = async () => {
    const params = [
      "animalType",
      "province",
      "amphur",
      "tambon",
      "village",
      "symptom",
    ];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;

      if (response.status === 200) {
        setFormJson({
          ...formJson,
          animalId: data.animalType,
          province: data.province,
          district: data.amphur,
          subDistrict: data.tambon,
          village: data.village,
          symptom: data.symptom,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = async () => {
    try {
      const response = await API.getStatus("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let statusArray = [];
        data.content.forEach((element) => {
          statusArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setStatusList(statusArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getInform = async () => {
    try {
      const response = await API.getInform("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let channelArray = [];
        data.content.forEach((element) => {
          channelArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setChannelList(channelArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", "1");
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;

      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;

      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteReportDisease = async (id, id_disease) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบการแจ้งโรคที่ ${id_disease}`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteReportDisease(id);
          if (response.status == 200) {
            await getReportDisease();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([value]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, provinceId: value.value, zipcode: "" });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([value]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, districtId: value.value, zipcode: "" });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([value]);
    setForm({ ...form, subDistrictId: value.value, zipcode: value.zipcode });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusDisease && statusDisease.length > 0) {
      statusDisease.map((item, index) => {
        if (item.id === idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color, color: item.fontColor }}
                className="box-status font14 px-3 text-center "
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "title", name: "แจ้งพบ - สงสัยโรคระบาดสัตว์" },
    { path: "active", name: "รายการแจ้งพบ - สงสัยโรคระบาดสัตว์" },
  ];

  const toSearch = () => {
    setIsClearSearch(!isClearSearch);
    setForm({ ...form, searchFlg: true, page: 1 });
  };

  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setReportidSelectValue([]);
    setAnimalSelectValue([]);
    setStatusSelectValue([]);
    setDistrictList([]);
    setSubDistrictList([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <ButtonReportLink path={"disease"} params={{
          infCode: form.reportid ? form.reportid : "",
          person: form.name ? form.name : "",
          foundDateBegin: form.date_found_disease
            ? helper.getDate(form.date_found_disease)
            : "",
          foundDateEnd: form.todate ? helper.getDate(form.todate) : "",
          createDateBegin: form.report_date ? helper.getDate(form.report_date) : "",
          createDateEnd: form.todate_re ? helper.getDate(form.todate_re) : "",
          provinceId: form.provinceId ? form.provinceId : "",
          amphurId: form.districtId ? form.districtId : "",
          tambonId: form.subDistrictId ? form.subDistrictId : "",
          animalTypeIds: form.animal ? form.animal : "",
          statusId: form.status ? form.status : "",
          onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
        }}/>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const buttonAdd = () => {
    return (
      <button
        className="btn btn-info text-white"
        disabled={user?.userGroup.mcreate === "n"}
        onClick={() =>
          history.push(`/passive_surv/disease_report/savedisease_report`)
        }
      >
        <i className="fas fa-plus mr-1 "></i>เพิ่มการแจ้งโรค
      </button>
    );
  };

  const viewDetails = (id) => {
    history.push(`/passive_surv/disease_report/edit/${id}`);
  };

  const saveOutbreak = () => {
    history.push(`/passive_surv/outbreak/from-disease/${form.diseaseId}`);
  };

  const renderTablereport = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item.infCode ? item.infCode : "-"}</td>
          <td>{item.fullname ? item.fullname : "-"}</td>
          <td>{item.informByName ? item.informByName : "-"}</td>
          <td>{helper.momentDate(item.foundDiseaseDate, "th", "short")}</td>
          <td>{helper.momentDate(item.createDate, "th", "short")}</td>
          <td>{item.animalTypeName ? item.animalTypeName : "-"}</td>
          <td>{item.symptomName ? item.symptomName : "-"}</td>
          <td>
            {`${item.address} ต.${item.tambonName} อ.${item.amphurName} จ.${item.provinceName}`}
          </td>
          <td>{getStatusColor(item.statusId)}</td>
          <td>{helper.momentDate(item.acceptDate, "th", "short")}</td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => deleteReportDisease(item.id, item.infCode)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
              <button
                className="btn btn-ouk mr-2 cursor-p"
                disabled={user?.userGroup.mcreate === "n"}
                onClick={() => {
                  setShowConfirm(true);
                  setForm({ ...form, diseaseId: item.id });
                }}
              >
                <a className="text-white font12 mb-3 ">+Outbreak</a>
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="12" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ปี"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="year"
                    classLabel="normal"
                    selectValue={yearFilterList}
                    optionsList={optionsListYear}
                    handleChange={(value) => setYearFilterList(value)}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    title="รหัสการแจ้งโรค"
                    star={false}
                    placeholder="โปรดระบุ"
                    idName="title"
                    classLabel="normal"
                    value={form.reportid}
                    handleChange={(value) =>
                      setForm({ ...form, reportid: value })
                    }
                  />
                </Col>

                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ผู้แจ้งโรค"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="ชื่อ/นามสุกล/บัตรประชาชน/เบอร์โทรศัพท์"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่พบโรค"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.date_found_disease}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, date_found_disease: value })
                    }
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.todate}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, todate: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่แจ้งโรค"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.report_date}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, report_date: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.todate_re}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, todate_re: value })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={AnimalSelectValue}
                    optionsList={AnimalList}
                    handleChange={(value) => {
                      setAnimalSelectValue([value]);
                      setForm({ ...form, animal: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะการดำเนินการ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={StatusSelectValue}
                    optionsList={StatusList}
                    handleChange={(value) => {
                      setStatusSelectValue([value]);
                      setForm({ ...form, status: value.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการแจ้งพบ - สงสัยโรคระบาดสัตว์"
            headRight={buttonAdd()}
           
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTablereport(listReportDisease)}
            </DataTable>
          </BoxCard>
        </div>
      </div>

      <ModalConfirm
        show={showConfirm}
        close={() => setShowConfirm(false)}
        size="sm"
        confirm={() => saveOutbreak()}
        text={`ต้องการสร้าง "Outbreak" หรือไม่`}
      />
    </div>
  );
}

export default Disease_Report;
