import React from 'react'
import Select from 'react-select';
import { Form } from 'react-bootstrap';

function InputSelect({ title, star, classLabel, placeholder, idName, selectValue, optionsList, handleChange, handleSelectValue,
    handleInvalid, invalid, classFormGroup, mode, disabled }) {
    return (
        <Form.Group className={classFormGroup}>
            {title && <Form.Label className={`${classLabel} mb-1`}>{title} <span className="text-danger">{star ? "*" : ""}</span></Form.Label>}
            <Select
                menuPlacement="auto"
                placeholder={placeholder}
                id={idName}
                name={idName}
                value={selectValue}
                options={optionsList}
                onChange={(value) => {
                    handleChange(value)
                    if (handleSelectValue) {
                        handleSelectValue(value)
                    }
                    if (handleInvalid) {
                        handleInvalid()
                    }
                }}
                styles={
                    {
                        control: (styles, state) => ({
                            ...styles,
                            fontFamily: "Kanit",
                            backgroundColor: disabled ? '#e9ecef' : '#fff',
                            borderColor: state.isFocused ? '#80bdff' : invalid ? '#dc3545' : '#ced4da',
                            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                        }),
                        container: (styles, state) => ({
                            ...styles,
                            width: mode == "filter" ? "115%" : "100%",
                        }),
                        menuPortal: styles => ({ ...styles, zIndex: 9999 }),
                        menu: base => ({
                            ...base,
                            fontFamily: "Kanit"
                        })
                    }
                }
                menuPortalTarget={document.body}
                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral50: '#969AA1',  // Placeholder color
                    },
                })}
                components={{
                    IndicatorSeparator: () => null
                }}
                isSearchable={disabled ? false : true}
                isDisabled={disabled ? true : false}
            />
            {invalid ? <div className="invalid-error">{invalid}</div> : ""}

            <style jsx="true" global="true">{`
                .css-107lb6w-singleValue {
                    color: #495057 !important;
                }
                .css-cixz0v-placeholder {
                    font-size: 14px !important;
                }
                .css-dyr6gj-container {
                    font-family: "Kanit", serif !important;
                }
            `}</style>
        </Form.Group>
    )
}

export default InputSelect
