import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";

import DataTableNodata from '../DataTableNodata';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


function ModalTableFarmBuffer({ show, close, farmsBufferZoneReport }) {
    const user = useSelector(state => state.login.result);

    const [titleAnimal, setTitleAnimal] = useState([]);
    const fileName = 'B99RPT04-farmsBufferZoneReport_' + helper.getDate(new Date());
    const [animalList, setAnimalList] = useState([])
    const [titleDistances, setTitleDistances] = useState([]);

    useEffect(async () => {
        if (farmsBufferZoneReport && farmsBufferZoneReport.length > 0) {
            //  console.log("farmsBufferZoneReport", farmsBufferZoneReport);
            getAnimal()
        }
    }, [farmsBufferZoneReport])

    const handleClose = () => {
        close()
    }

    const getAnimal = async () => {
        try {
            const response = await API.getAnimal("", 1);
            const data = await response.data;
            if (response.status === 200) {
                let animalArray = [];
                data.content.forEach(element => {
                    animalArray.push({
                        value: element.id, label: element.name,
                    });
                });
                getTitleAnimal(animalArray)
                setAnimalList(animalArray);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAnimalName = (id, listAnimal) => {
        let name = ""
        if (listAnimal && listAnimal.length > 0) {
            listAnimal.filter(animal => {
                if (animal.value === Number(id)) {
                    name = animal.label;
                }
            })
        }
        return name;
    }



    const regexNumber = (number) => {
        let regex = /^[0-9]+$/i;
        return regex.test(number);
    }

    const getTitleAnimal = (listAnimal) => {
        if (farmsBufferZoneReport && farmsBufferZoneReport.length > 0) {
            farmsBufferZoneReport.map((item, index) => {
                return Object.keys(item)
            })
                .map((item, index, array) => {
                    if (index === 0) {
                        let arrayAnimal = []
                        item.map((itemI, i) => {
                            if (regexNumber(itemI)) {
                                if (getAnimalName(itemI, listAnimal)) {
                                    arrayAnimal.push({
                                        animalTypeId: itemI,
                                        animalTypeName: getAnimalName(itemI, listAnimal)
                                    })
                                }
                                setTitleAnimal(arrayAnimal)
                            }
                        })
                    }
                })
        }
    }


    const convertListToExcelTable = (objArray) => {
        let excelTable = [];
        objArray.map((element, index) => {
            let moo = "หมู่/หมู่บ้าน"
            excelTable.push({
                ลำดับ: index + 1,
                ชื่อฟาร์ม: element.farmName,
                ชื่อ: element.firstName,
                นามสกุล: element.lastName,
                เบอร์โทร: element.phone,
                บ้านเลขที่: element.homeNo,
            })
            excelTable[index][moo] = element.mooName || "-";
            excelTable[index]["จังหวัด"] = element.provinceName || "-";
            excelTable[index]["อำเภอ"] = element.amphurName || "-";
            excelTable[index]["ตำบล"] = element.tambonName || "-";
            if (titleAnimal && titleAnimal.length > 0) {
                titleAnimal.map((itemA, a) => (
                    excelTable[index][`${itemA.animalTypeName}`] = element[Number(itemA.animalTypeId)]
                ))
            }
            excelTable[index]["แจ้งโรคล่าสุด"] = element.informDate ? helper.getDate(element.informDate) : "-";
            excelTable[index]["โรคที่แจ้ง"] = element.diseaseTypeName || "-";
            excelTable[index]["ละติจูด"] = element.latitude || "-";
            excelTable[index]["ลองจิจูด"] = element.longitude || "-";
            excelTable[index]["ระยะห่างจากจุด buffer(กิโลเมตร)"] = element.distances || "-";

        })
        return excelTable;
    }


    const convertToExcel = (objArray) => {
        const workSheet = XLSX.utils.json_to_sheet(objArray);
        const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
        return XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
    }

    const downloadRecord = () => {
        let type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const excelBuffer = convertToExcel(convertListToExcelTable(farmsBufferZoneReport))
        const data = new Blob([excelBuffer], { type: type });
        FileSaver.saveAs(data, fileName + '.xlsx');
    }

    const renderTablefarmsBufferZoneReport = (data) => {
        if (data && data.length > 0) {
            return data.map((item, i) => (
                <tr key={i} className="text-center">
                    <td>{i + 1}</td>
                    <td>{item.farmName || "-"}</td>
                    <td>{item.firstName || "-"}</td>
                    <td>{item.lastName || "-"}</td>
                    <td>{item.phone || "-"}</td>
                    <td>{item.homeNo || "-"}</td>
                    <td>{item.mooName || "-"}</td>
                    <td>{item.provinceName || "-"}</td>
                    <td>{item.amphurName || "-"}</td>
                    <td>{item.tambonName || "-"}</td>
                    {titleAnimal && titleAnimal.length > 0 &&
                        titleAnimal.map((itemA, a) => (
                            <td key={a} scope="col">{item[Number(itemA.animalTypeId)]}</td>
                        ))
                    }
                    <td>{item.informDate ? helper.getDate(item.informDate) : "-"}</td>
                    <td>{item.diseaseTypeName || "-"}</td>
                    <td>{item.latitude || "-"}</td>
                    <td>{item.longitude || "-"}</td>
                    <td>{item.distances || "-"}</td>
                </tr>
            ))
        } else {
            return <DataTableNodata colSpan="51" />
        }
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => handleClose()}
            centered
        >
            <Modal.Header closeButton className="pb-2 underline">
                <Modal.Title className="mt-2">
                    <h5><strong>รายชื่อฟาร์มในรัศมี</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="d-flex justify-content-end">
                    <button className="btn btn-report" onClick={() => downloadRecord()} disabled={user?.userGroup.mexport === "n"}>
                        <a className="text-white">
                            <img className="nav-icon" src={"/svg/report.svg"} alt="export-icon" /> ออกรายงาน
                        </a>
                    </button>
                </div>
                <div className="table-responsive my-2">
                    <table className="table table-striped table-bordered my-2 ">
                        <thead>
                            <tr className="text-center">
                                <th scope="col">ลำดับ</th>
                                <th scope="col">ชื่อฟาร์ม</th>
                                <th scope="col">ชื่อ</th>
                                <th scope="col">นามสกุล</th>
                                <th scope="col">เบอร์โทร</th>
                                <th scope="col">บ้านเลขที่</th>
                                <th scope="col">หมู่/หมู่บ้าน</th>
                                <th scope="col">จังหวัด</th>
                                <th scope="col">อำเภอ</th>
                                <th scope="col">ตำบล</th>
                                {titleAnimal && titleAnimal.length > 0 &&
                                    titleAnimal.map((item, i) => (
                                        <th key={i} scope="col">{item.animalTypeName}</th>
                                    ))
                                }
                                <th scope="col">แจ้งโรคล่าสุด</th>
                                <th scope="col">โรคที่แจ้ง</th>
                                <th scope="col">ละติจูด</th>
                                <th scope="col">ลองจิจูด</th>
                                <th scope="col">ระยะห่างจากจุด buffer (กิโลเมตร)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTablefarmsBufferZoneReport(farmsBufferZoneReport)}
                        </tbody>
                    </table>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default ModalTableFarmBuffer
