import React, { useState, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import BoxHead from "../../components/BoxHead";
import InputText from "../../components/InputText";
import { Row, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "../../components/InputMask";
import InputSelect from "../../components/InputSelect";
import ModalOrganizes from "../../components/Modal.Organizes";
import { useHistory } from "react-router-dom";
import InputGroupText from "../../components/InputGroupText";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import * as API from "../../utils/apis";
import * as helper from "../../utils/helper";
import { logout } from "../../redux/actions/logoutAction";
import BoxCard from "../../components/BoxCard";
import InputDatePicker from "../../components/InputDatePicker";
import UploadImage from "../../components/UploadImage";
import DataTableNodata from "../../components/DataTableNodata";
import AddEpidemicZone from "../../components/EpidemicZone/AddEpidemicZone";
import ModalOutbreakSearch from "../../components/Modal.OutbreakSearch";
import ModalFarmSearch from "../../components/Modal.FarmSearch";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

let delZone = [];
let delAnimals = [];
let delFile = [];

function Epidemic_Zone_Save(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation(["common", "footer"]);
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);

  const initialForm = {
    dataAllById: {},

    farmId: "",
    outbreakId: "",
    outbreakCode: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    caseCode: "",
    homeNo: "",
    phone: "",
    name: "",
    orgId: "",
    orgName: "",

    data_file: [],
    del_file: [],
    checkAddFile: false,
    checkAddAnimal: false,
    modeEditAnm: false,

    newEpidZone: false,
    checkAddZone: false,

    data_animals: [],
    del_animals: [],
    del_outbreak: [],

    statusId: "",
    annouceTypeId: "",
    startDate: "",
    stopDate: "",
    diseaseTypeId: "",
    orgIdForm: "",
    orgNameForm: "",
    annouceName: "",
    annoucePosition: "",
    annouceCode: "",

    delGroupZone: [],
    groupZone: [],
    newGroupZone: [], // จาก component เลือกแผนที่

    //ใบรับรอง
    numberOutbreak: "",
    outbreakTable: [],

    mode: "",
  };
  const [form, setForm] = useState(initialForm);

  const initialTableForm = {
    dataAllById: {},
    save: true,
    farmId: "",
    outbreakId: "",
    outbreakCode: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    caseCode: "",
    homeNo: "",
    phone: "",
    name: "",
    orgId: "",
    orgName: "",
    indexOb: "",
    statusId: "",
  };
  const [formTable, setFormTable] = useState(initialTableForm);

  const initialFormJson = {
    animalId: {},
    diseaseTypeId: {},
    husbandry: {},
    animalPurpose: {},
    vaccineType: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initInvalidMsg = {
    annouceName: "",
    orgIdForm: "",
    startDate: "",
    animalTypeId: "",
    diseaseTypeId: "",
    annouceTypeId: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  const [diseaseSelectValue, setDiseaseSelectValue] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);

  const [annouceTypeSelectValue, setAnnouceTypeSelectValue] = useState([]);
  const [annouceTypeList, setAnnouceTypeList] = useState([]);

  const [showCaseIdSearch, setShowCaseIdSearch] = useState(false);

  const [showOrgModal, setShowOrgModal] = useState(false);

  const [showOrgModalForm, setShowOrgModalForm] = useState(false);

  const [showOutbreakSearch, setShowOutbreakSearch] = useState(false);
  const [showOutbreakTableSearch, setShowOutbreakSearchTable] = useState(false);

  const initShowFarmSearch = false;
  const [showFarmSearch, setShowFarmSearch] = useState(initShowFarmSearch);

  const [zoneActionType, setZoneActionType] = useState("add");
  const [zoneDataEdit, setZoneDataEdit] = useState();

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const [statusList, setStatusList] = useState([
    { value: 1, label: "ควบคุมโรค" },
    { value: 2, label: "เฝ้าระวัง" },
    { value: 3, label: "โรคสงบ" },
  ]);

  const [addOutBreak, setAddOutBreak] = useState([]);

  const checkAcctionPageEdit = location.pathname.includes("edit");

  const initialFormFile = {
    url: "",
    file: "",
    detail_file: "",
    file_type: "",
    date_file: "",
    fileName: "",
  };
  const [formFile, setFormFile] = useState(initialFormFile);

  const initFormAnimal = {
    animalTypeId: "",
    animalTypeName: "",
  };
  const [formAnm, setFormAnm] = useState(initFormAnimal);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [deleteOutbreak, setDeleteOutbreak] = useState([]);

  useMasterData("province", provinceList, setProvinceList);

  useEffect(() => {
    getProvince();
    getAnimal();
    getDiseaseType();
    getMasterJson();
    getAnnouce();
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes("save") &&
      form.mode == "add" &&
      form.outbreakId
    ) {
      getOutbreakById(form.outbreakId, "ObAll");
    } else if (location.pathname.includes("edit")) {
      getEpidemicById(props.match.params.id);
    } else if (location.pathname.includes("save") && props.match.params.id) {
      getOutbreakById(props.match.params.id, "ObAll");
    } else if (
      location.pathname.includes("save") &&
      form.mode == "farmById" &&
      form.farmId
    ) {
      getFarmById(form.farmId);
    }
  }, [
    user,
    form.mode,
    form.farmId,
    form.outbreakId,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
  ]);

  useEffect(() => {
    if (formTable.outbreakId) {
      getOutbreakById(formTable.outbreakId, "Table");
    }
  }, [
    formTable.provinceId,
    formTable.districtId,
    formTable.subDistrictId,
    formTable.villageId,
    formTable.outbreakId,
  ]);

  const setDataArea = async (lists) => {
    if (lists.length)
      lists.map((item) => {
        let province = getDataAddressName(provinceList, item.provinceId);
        let amphur = getDataAddressName(districtList, item.amphurId);
        let tambon = getDataAddressName(subDistrictList, item.tambonId);
        let moo = getDataAddressName(villageList, item.mooId);

        item.provinceName = province;
        item.amphurName = amphur;
        item.tambonName = tambon;
        item.mooName = moo;
        return item;
      });
    console.log("lists", lists);
    return lists;
  };

  const setDataAnimal = async (lists) => {
    if (lists.length)
      lists.map((item) => {
        let animalTypeName = getAnimalList(animalList, item.animalTypeId);
        item.animalTypeName = animalTypeName;
        return item;
      });
    return lists;
  };

  const getDataAddressName = (lists = [], id) => {
    let address = lists.find((item) => item.value === id);
    return address?.label;
  };

  const getDataAddress = async (lists = [], id) => {
    let address = await lists.find((item) => item.value === id);
    return address;
  };

  const getAnimalList = (lists = [], id) => {
    let animal = lists.find((item) => item.value === id);
    return animal?.label;
  };

  const getMasterJson = async () => {
    const params = await [
      "animalType",
      "diseaseType",
      "husbandry",
      "animalPurpose",
      "vaccineType",
    ];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          animalId: data.animalType,
          diseaseTypeId: data.diseaseType,
          husbandry: data.husbandry,
          animalPurpose: data.animalPurpose,
          vaccineType: data.vaccineType,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEpidemicById = async (id) => {
    let dataRes;
    try {
      const response = await API.getEpidemicById(id);
      if (response.status === 200) {
        dataRes = response.data;
        console.log("dataRes", dataRes);
        if (dataRes) {
          const dataById = dataRes;
          if (dataById?.fm01?.provinceId) {
            let province = await getDataAddress(
              provinceList,
              dataById?.fm01?.provinceId
            );
            setProvinceSelectValue(province);
            if (districtList.length === 0) {
              await getDistricts(dataById.fm01.provinceId);
            }
          } else if (dataById?.farm?.provinceId) {
            let province = await getDataAddress(
              provinceList,
              dataById?.farm?.provinceId
            );
            setProvinceSelectValue(province);
            if (districtList.length === 0) {
              await getDistricts(dataById.farm.provinceId);
            }
          }

          if (dataById?.fm01?.amphurId) {
            let distric = await getDataAddress(
              districtList,
              dataById?.fm01?.amphurId
            );
            setDistrictSelectValue(distric);
            if (subDistrictList.length === 0) {
              await getSubDistricts(dataById.fm01.amphurId);
            }
          } else if (dataById?.farm?.amphurId) {
            let distric = await getDataAddress(
              districtList,
              dataById?.farm?.amphurId
            );
            setDistrictSelectValue(distric);
            if (subDistrictList.length === 0) {
              await getSubDistricts(dataById.farm.amphurId);
            }
          }

          if (dataById?.fm01?.tambonId) {
            let subDistric = await getDataAddress(
              subDistrictList,
              dataById?.fm01?.tambonId
            );
            setSubDistrictSelectValue(subDistric);
            if (villageList.length === 0) {
              await getVillage(dataById.fm01.tambonId);
            }
          } else if (dataById?.farm?.tambonId) {
            let subDistric = await getDataAddress(
              subDistrictList,
              dataById?.farm?.tambonId
            );
            setSubDistrictSelectValue(subDistric);
            if (villageList.length === 0) {
              await getVillage(dataById.farm.tambonId);
            }
          }

          if (dataById?.fm01?.mooId) {
            let village = await getDataAddress(
              villageList,
              dataById?.fm01?.mooId
            );
            setVillageSelectValue(village);
          } else if (dataById?.farm?.mooId) {
            let village = await getDataAddress(
              villageList,
              dataById?.farm?.mooId
            );
            setVillageSelectValue(village);
          }

          if (dataById?.annouce?.statusId) {
            let statusSelect = await statusList.find(
              (item) => item.value === dataById.annouce.statusId
            );
            setStatusSelectValue(statusSelect);
          }

          if (dataById?.annouce?.annouceTypeId) {
            let annouceTypeSelect = await annouceTypeList.find(
              (item) => item.value === dataById.annouce.annouceTypeId
            );
            setAnnouceTypeSelectValue(annouceTypeSelect);
          }

          if (dataById?.annouce?.diseaseTypeId) {
            let diseaseSelect = await diseaseList.find(
              (item) => item.value === dataById.annouce.diseaseTypeId
            );
            setDiseaseSelectValue(diseaseSelect);
          }

          let outbreakData = [];
          let dataAnnFm01 = dataRes.annouceFm01;
          if (dataAnnFm01 && dataAnnFm01.length > 0) {
            dataAnnFm01.map(async (item, i) => {
              outbreakData.push({
                id: item.id ? item.id : "",
                outbreakCode: dataRes.outbreaks[i]?.obCode,
                obId: item.obId,
                farmId: item.farmId,
                name: item.ownerName,
                homeNo: item.address,
                province: dataRes.provinces[i]?.name,
                district: dataRes.amphurs[i]?.name,
                subDistrict: dataRes.tambons[i]?.name,
                village: dataRes.villages[i]?.name,
                save: false,
              });
            });
            setAddOutBreak(outbreakData);
          }

          setForm({
            ...form,
            mode: dataById?.fm01 ? "edit" : "farmById",
            dataAllById: dataById,
            outbreakCode: dataById?.outbreak?.obCode || "",
            farmId: dataById?.farm?.id || dataById?.fm01?.farmId,
            caseCode: dataById?.fm01?.caseCode || "",

            provinceId:
              dataById?.fm01?.provinceId || dataById?.farm?.provinceId,
            districtId: dataById?.fm01?.amphurId || dataById?.farm?.amphurId,
            subDistrictId: dataById?.fm01?.tambonId || dataById?.farm?.tambonId,
            villageId: dataById?.fm01?.mooId || dataById?.farm?.mooId,
            homeNo: dataById?.fm01?.address || dataById?.farm?.homeNo,
            phone: dataById?.fm01?.ownerTel || dataById?.farmer?.mobile,
            name:
              dataById?.fm01?.ownerName ||
              `${dataById.farmer.firstName} ${dataById.farmer.lastName}`,
            orgId: dataById?.fm01?.orgId || "",
            orgName: dataById?.fm01?.orgName || "",
            statusId: dataById.annouce.statusId,

            // info
            startDate: dataById.annouce.startDate,
            stopDate: dataById.annouce.stopDate,
            annouceCode: dataById.annouce.annouceCode,
            annouceName: dataById.annouce.annouceName,
            annoucePosition: dataById.annouce.annoucePosition,
            diseaseTypeId: dataById?.annouce?.diseaseTypeId,
            annouceTypeId: dataById?.annouce?.annouceTypeId,
            orgIdForm: dataById?.org?.id,
            orgNameForm: dataById?.org?.name,

            // areas
            groupZone: await setDataArea(dataById.annouceArea),

            // type animal
            data_animals: await setDataAnimal(dataById.annouceAnimals || []),

            //file
            data_file: dataById.annouceFiles || [],
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getOutbreakById = async (id, type) => {
    let dataRes;
    try {
      const response = await API.getOutbreakById(id);
      if (response.status === 200) {
        dataRes = response.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;
      if (type == "ObAll") {
        if (dataById.fm01 && dataById.fm01.provinceId) {
          let provinceSelect = await provinceList.find(
            (item) => item.value === dataById.fm01.provinceId
          );
          setProvinceSelectValue(provinceSelect);

          if (districtList.length === 0) {
            await getDistricts(dataById.fm01.provinceId);
          }
        }

        if (dataById.fm01 && dataById.fm01.amphurId) {
          let districtSelect = await districtList.find(
            (item) => item.value === dataById.fm01.amphurId
          );
          setDistrictSelectValue(districtSelect);
          if (subDistrictList.length === 0) {
            await getSubDistricts(dataById.fm01.amphurId);
          }
        }

        if (dataById.fm01 && dataById.fm01.tambonId) {
          let subDistrictSelect = await subDistrictList.find(
            (item) => item.value === dataById.fm01.tambonId
          );
          setSubDistrictSelectValue(subDistrictSelect);

          if (villageList.length === 0) {
            await getVillage(dataById.fm01.tambonId);
          }
        }

        if (dataById.fm01 && dataById.fm01.mooId) {
          let villageSelect = await villageList.find(
            (item) => item.value === dataById.fm01.mooId
          );
          setVillageSelectValue(villageSelect);
        }

        if (dataById?.fm01?.statusId) {
          let statusSelect = await statusList.find(
            (item) => item.value === dataById.fm01.statusId
          );
          setStatusSelectValue(statusSelect);
        }

        setForm({
          ...form,
          mode: "add",
          dataAllById: dataById,
          farmId: dataById.fm01.farmId,
          caseCode: dataById.fm01.caseCode,
          outbreakCode: dataById.outbreak.obCode,

          provinceId: dataById.fm01.provinceId,
          districtId: dataById.fm01.amphurId,
          subDistrictId: dataById.fm01.tambonId,
          villageId: dataById.fm01.mooId,
          homeNo: dataById.fm01.address,
          name: dataById.fm01.ownerName,
          orgId: dataById.fm01.orgId,
          orgName: dataById.fm01.orgName,
          statusId: dataById.fm01.statusId,
        });
      }

      if (type == "Table") {
        addOutBreak[formTable.indexOb].save = true;
        addOutBreak[formTable.indexOb].outbreakCode = dataById.outbreak.obCode;
        addOutBreak[formTable.indexOb].farmId = dataById.fm01.farmId;
        addOutBreak[formTable.indexOb].name = dataById.fm01.ownerName;
        addOutBreak[formTable.indexOb].homeNo = dataById.fm01.address;
        addOutBreak[formTable.indexOb].provinceId = dataById.fm01.provinceId;
        addOutBreak[formTable.indexOb].districtId = dataById.fm01.amphurId;
        addOutBreak[formTable.indexOb].subDistrictId = dataById.fm01.tambonId;
        addOutBreak[formTable.indexOb].villageId = dataById.fm01.mooId;
        addOutBreak[formTable.indexOb].obId = dataById.outbreak.id;
        addOutBreak[formTable.indexOb].province =
          dataById.fm01Response.provinces.name;
        addOutBreak[formTable.indexOb].district =
          dataById.fm01Response.amphurs.name;
        addOutBreak[formTable.indexOb].subDistrict =
          dataById.fm01Response.tambons.name;
        addOutBreak[formTable.indexOb].village =
          dataById.fm01Response.village.name;
        setAddOutBreak([...addOutBreak]);
      }
    }
  };

  const getFarmById = async (id) => {
    let dataRes;
    try {
      const response = await API.getFarmRegistrationById(id);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.farm && dataById.farm.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.farm.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.farm.provinceId);
        }
      }

      if (dataById.farm && dataById.farm.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.farm.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.farm.amphurId);
        }
      }

      if (dataById.farm && dataById.farm.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.farm.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.farm.tambonId);
        }
      }

      if (dataById.farm && dataById.farm.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.farm.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      const houseArray = [];
      let vaccineArray;
      if (dataById.barn && dataById.barn.length > 0) {
        dataById.barn.map((barns, b) => {
          houseArray.push({
            barnId: barns.id,
            name: barns.name,
            desc: [],
            animals: barns.animals.map((animal, a) => {
              if (barns.id == animal.barnId) {
                if (animal.vaccine && animal.vaccine.length > 0) {
                  vaccineArray = animal.vaccine.map((vaccine, v) => {
                    return {
                      diseaseTypeName:
                        formJson.diseaseTypeId[vaccine.diseaseTypeId],
                      diseaseTypeId: vaccine.diseaseTypeId,
                      last_date: vaccine.vaccineDate,
                      lotNo: vaccine.lotNo,
                      operator: vaccine.injectBy,
                      other_vaccine: vaccine.injectOther,
                      had_disease: vaccine.diseaseHistory,
                      vaccineTypeId: vaccine.vaccineTypeId,
                      vaccineTypeName:
                        formJson.vaccineType[vaccine.vaccineTypeId],
                    };
                  });
                }

                return {
                  animalTypeName: formJson.animalId[animal.animalTypeId],
                  animalTypeId: animal.animalTypeId,
                  animalId: animal.animalCode,
                  date_dead: animal.deadDate,
                  status_id: animal.statusId,
                  cause_death_type: animal.deadCause,
                  cause_death_type_other: animal.deadReason,
                  name: animal.name,
                  genderType: animal.sex,
                  gender:
                    animal.sex === "m"
                      ? "ตัวผู้"
                      : animal.sex === "f"
                      ? "ตัวเมีย"
                      : "ไม่ทราบ",
                  house_buiding: barns.name,
                  age_year: animal.ageYear,
                  age_month: animal.ageMonth,
                  no_nid: animal.nid,
                  no_microchip: animal.microchip,
                  breed: animal.species,
                  purpose_raising: animal.purposeId,
                  purpose_name: formJson.animalPurpose[animal.purposeId],
                  no_nid: animal.nidCode,
                  no_animal_ear: animal.nid,
                  style_raising: animal.husbandryId,
                  style_raising_name: formJson.husbandry[animal.husbandryId],
                  is_vaccine: animal.vaccinationStatus,
                  choose_disease: vaccineArray,
                };
              }
            }),
          });
        });
      }

      let arrayHouseList = [];
      if (houseArray && houseArray.length > 0) {
        houseArray.forEach((house) =>
          arrayHouseList.push({ value: house.barnId, label: house.name })
        );
      }

      setForm({
        ...form,
        dataAllById: dataById,
        mode: "farmById",
        farmId: dataById.farm.id,
        outbreakCode: "",
        caseCode: "",
        provinceId: dataById.farm.provinceId,
        districtId: dataById.farm.amphurId,
        subDistrictId: dataById.farm.tambonId,
        villageId: dataById.farm.mooId,
        homeno: dataById.farm.homeNo,
        // orgId: dataById.farm.orgId,
        // orgName: dataById.farm.orgName,
        phone: dataById.farmer.mobile,
        name: `${dataById.farmer.firstName} ${dataById.farmer.lastName}`,

        step1: {
          provinceId: dataById.farm.provinceId,
          districtId: dataById.farm.amphurId,
          subDistrictId: dataById.farm.tambonId,
          villageId: dataById.farm.mooId,
          homeno: dataById.farm.homeNo,
          localGovernId: dataById.farm.dlaId,
          governmentName: dataById.farm.dlaName,
        },

        step2: {
          house: houseArray,
          houseList: arrayHouseList,
          res_animals: [],
          list_animals: [],
        },
      });
    }
  };

  const checkOutbreakCode = (list, obCode) => {
    let check = false;
    let checkArry = [];
    if (list && list.length > 0) {
      list.map((item, i) => {
        if (Number(item.outbreakCode) !== Number(obCode)) {
          check = true;
          checkArry.push(check);
        } else {
          check = false;
          checkArry.push(check);
        }
      });
    }

    if (checkArry && checkArry.length > 0 && checkArry.includes(false)) {
      MySwal.fire({
        html: (
          <>
            เลข Outbreak นี้ได้ถูกเลือกไว้แล้ว <br /> กรุณาเลือก Outbreak ใหม่
          </>
        ),
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
      return false;
    } else {
      return true;
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnnouce = async () => {
    try {
      const response = await API.getAnnouce("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let annouceArray = [];
        data.content.forEach((element) => {
          annouceArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnnouceTypeList(annouceArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async (idAnimal) => {
    try {
      const response = await API.getDiseaseType(idAnimal);
      const data = await response.data;
      if (response.status === 200) {
        let diseaseTypeArray = [];
        data.content.forEach((element) => {
          diseaseTypeArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseaseList(diseaseTypeArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", "1");
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        await data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, "1");
      const data = response.data;
      // console.log("getDistricts", response);
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, "1");
      const data = await response.data;
      // console.log("getSubDistricts", response);
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, districtId: value.value });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = (type, item = {}) => {
    let validated = true;

    if (type === "checkDuplicate") {
      //checkDup
      let observer = false;
      let groupData = form.data_animals;
      groupData.forEach((element) => {
        observer = observer || element.animalTypeId == item.animalTypeId;
      });

      if (observer) {
        addInvalid("animalTypeId", "รายการชนิดสัตว์ซ้ำ");
        validated = false;
      }

      if (formAnm.animalTypeId === "") {
        addInvalid("animalTypeId", "กรุณาเลือกชนิดสัตว์");
        validated = false;
      }
    }

    if (type === "checkAll") {
      if (form.statusId === "") {
        addInvalid("statusId", "กรุณาเลือกสถานะโรคระบาด");
        validated = false;
      }

      if (form.stopDate === "") {
        addInvalid("stopDate", "กรุณาเลือกวันที่สิ้นสุดประกาศ");
        validated = false;
      }

      if (form.annouceTypeId === "") {
        addInvalid("annouceTypeId", "กรุณาเลือกประเภทประกาศ");
        validated = false;
      }

      if (form.startDate === "") {
        addInvalid("startDate", "กรุณาเลือกวันที่ประกาศ");
        validated = false;
      }

      if (form.diseaseTypeId === "") {
        addInvalid("diseaseTypeId", "กรุณาเลือกชนิดโรคระบาด");
        validated = false;
      }

      if (form.orgIdForm === "") {
        addInvalid("orgIdForm", "กรุณาเลือกหน่วยงาน");
        validated = false;
      }

      if (form.annouceName === "") {
        addInvalid("annouceName", "กรุณาเลือกชนิดโรคระบาด");
        validated = false;
      }
    }

    return validated;
  };

  const setOrganizes = (data) => {
    setForm({ ...form, orgId: data.value, orgName: data.label });
  };

  const setOrganizesForm = (data) => {
    setForm({ ...form, orgIdForm: data.value, orgNameForm: data.label });
    removeInvalid("orgIdForm");
  };

  const saveGroupZoneToForm = (data) => {
    setForm({ ...form, groupZone: data, checkAddZone: true });
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "/passive_surv/epidemic_zone", name: "ประกาศเขตโรคระบาด" },
    { path: "active", name: "เพิ่มรายการข้อมูลการประกาศเขตโรคระบาดสัตว์" },
  ];

  const toAddFile = () => {
    setForm({ ...form, checkAddFile: true });
    setFormFile({
      ...formFile,
      date_file: Date.now(),
      detail_file: "",
      file: "",
      fileName: "",
    });
  };

  const removeImage = (index, item) => {
    form.data_file.splice(index, 1);
    if (item.id) {
      delFile.push(item.id);
    }
    setForm({ ...form, data_file: form.data_file, del_file: delFile });
  };

  const addNewEpidZone = () => {
    setForm({ ...form, newEpidZone: true });
    setZoneActionType("add");
  };

  const toSaveEpidemicZone = async () => {
    if (!form.outbreakCode) {
      MySwal.fire({
        text: "กรุณาเลือกเลข Outbreak ID",
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
    } else if (validate("checkAll")) {
      const dataById = form.dataAllById;

      let data_animal = [];
      if (form.data_animals && form.data_animals.length > 0) {
        form.data_animals.map((item, i) => {
          data_animal.push({
            id: item.id ? item.id : "",
            animalTypeId: item.animalTypeId,
          });
        });
      }

      let del_animals = [];
      if (form.del_animals && form.del_animals.length > 0) {
        form.del_animals.map((item, i) => {
          del_animals.push(item.id);
        });
      }

      let dataOutbreak = [];
      if (addOutBreak && addOutBreak.length > 0) {
        addOutBreak.map((item, i) => {
          dataOutbreak.push({
            id: item.id ? item.id : "",
            obId: item.obId,
          });
        });
      }

      let del_outbreak = [];
      if (form.del_outbreak && form.del_outbreak.length > 0) {
        form.del_outbreak.map((item, i) => {
          del_outbreak.push(item.id);
        });
      }

      let data_annouceArea = [];
      if (form.groupZone && form.groupZone.length > 0) {
        form.groupZone.map((item, i) => {
          data_annouceArea.push({
            id: item.id ? item.id : "",
            provinceId: item.provinceId,
            amphurId: item.amphurId,
            tambonId: item.tambonId,
            mooId: item.mooId,
            latitude: item.latitude,
            longitude: item.longitude,
            radius: item.radius,
          });
        });
      }

      let del_annouceArea = [];
      if (form.delGroupZone && form.delGroupZone.length > 0) {
        form.delGroupZone.map((item, i) => {
          del_annouceArea.push(item.id);
        });
      }

      let data_annouceFiles = [];
      if (form.data_file && form.data_file.length > 0) {
        form.data_file.map((item, i) => {
          data_annouceFiles.push({
            id: item.id ? item.id : "",
            name: item.fileName,
            data: item.file,
            description: item.detail_file,
          });
        });
      }

      console.log("form.mode--🐳", form.mode);
      console.log("dataById--💀💀", dataById);

      let data;
      if (form.mode === "farmById") {
        data = {
          annouce: {
            id: dataById?.annouce?.id || "",
            statusId: form.statusId,
            annouceTypeId: form.annouceTypeId
              ? form.annouceTypeId
              : dataById.annouce.annouceTypeId,
            startDate: form.startDate,
            stopDate: form.stopDate,
            diseaseTypeId: form.diseaseTypeId,
            orgId: form.orgIdForm,
            annouceName: form.annouceName,
            annoucePosition: form.annoucePosition,
            annouceCode: form.annouceCode,
            statusView: dataById.annouce?.statusView
              ? dataById.annouce?.statusView
              : 2,
            farmId: form.farmId,
          },
          annouceAnimals: data_animal,
          deleteAnimals: form.del_animals || [],
          annouceOutbreaks: dataOutbreak,
          deleteOutbreaks: deleteOutbreak,
          annouceArea: data_annouceArea,
          deleteArea: form.delGroupZone || [],
          annouceFiles: data_annouceFiles,
          deleteFiles: form.del_file || [],
        };
      } else {
        data = {
          outbreak: {
            obCode: dataById.outbreak.obCode,
          },
          fm01: {
            caseCode: dataById.fm01.caseCode,
            address: dataById.fm01.address,
            amphurId: dataById.fm01.amphurId,
            animalAround5km: dataById.fm01.animalAround5km,
            animalTypeId: dataById.fm01.animalTypeId,
            diseaseTypeId: dataById.fm01.diseaseTypeId,
            dlaId: dataById.fm01.dlaId,
            dlaName: dataById.fm01.dlaNam,
            farmId: dataById.fm01.farmId,
            firstAnimSymptomDate: dataById.fm01.firstAnimSymptomDate,
            foundDiseaseDate: dataById.fm01.foundDiseaseDate,
            humanDeadAmt: dataById.fm01.humanDeadAmt,
            humanSickAmt: dataById.fm01.humanSickAmt,
            humanTouchAmt: dataById.fm01.humanTouchAmt,
            informBy: dataById.fm01.informBy,
            informOther: dataById.fm01.informOther,
            informId: dataById.informId,
            mooId: dataById.fm01.mooId,
            obId: dataById.fm01.obId,
            orgId: dataById.fm01.orgId,
            ownerName: dataById.fm01.ownerName,
            ownerTel: dataById.fm01.ownerTel,
            provinceId: dataById.fm01.provinceId,
            statusId: form.statusId,
            symptomId: dataById.fm01.symptomId,
            tambonId: dataById.fm01.tambonId,
          },
          annouce: {
            id: dataById?.annouce?.id || "",
            statusId: form.statusId,
            annouceTypeId: form.annouceTypeId
              ? form.annouceTypeId
              : dataById.annouce.annouceTypeId,
            startDate: form.startDate,
            stopDate: form.stopDate,
            diseaseTypeId: form.diseaseTypeId,
            orgId: form.orgId,
            annouceName: form.annouceName,
            annoucePosition: form.annoucePosition,
            annouceCode: form.annouceCode,
            statusView: dataById.annouce?.statusView
              ? dataById.annouce?.statusView
              : 2,
            farmId: form.farmId,
          },
          annouceOutbreaks: dataOutbreak,
          deleteOutbreaks: deleteOutbreak,
          annouceAnimals: data_animal,
          deleteAnimals: form.del_animals || [],
          annouceArea: data_annouceArea,
          deleteArea: form.delGroupZone || [],
          annouceFiles: data_annouceFiles,
          deleteFiles: form.del_file || [],
        };
      }

      //console.log("data save", data);
      //return;
      try {
        const response = await API.addEpidemicZone(data);

        if (response.status === 200) {
          MySwal.fire({
            text: location.pathname.includes("save")
              ? `เพิ่ม "ประกาศเขตโรคระบาดสัตว์" สำเร็จ`
              : `แก้ไข "ประกาศเขตโรคระบาดสัตว์" สำเร็จ`,
            icon: "success",
            confirmButtonText: "ตกลง",
          }).then(async (result) => {
            history.push(`/passive_surv/epidemic_zone`);
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch(logout({ history }));
        } else {
          MySwal.fire({
            text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
            icon: "warning",
            confirmButtonText: "ตกลง",
          });
        }
        console.log("error", error);
      }
    }
    // }
  };

  const toAddOutbreak = () => {
    const data = addOutBreak.map((item) => {
      return { ...item, edit: true };
    });
    setAddOutBreak([...data, initialTableForm]);
    // setAddOutBreak([...addOutBreak, initialForm])
  };

  const checkAddOutbreakNew = () => {
    return addOutBreak.find((item) => {
      return item.save == true;
    });
  };

  const buttonAddOutbreak = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddOutbreak()}
          disabled={checkAddOutbreakNew()}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const buttonAddEpidZone = () => {
    return (
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-add text-white"
          onClick={() => addNewEpidZone()}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มรายการ
        </button>
      </div>
    );
  };

  const buttonAddDoc = () => {
    return (
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddFile()}
          disabled={form.checkAddFile}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const fileSave = (data) => {
    let arrayFile = [...form.data_file];
    arrayFile.push({
      file: data.file,
      fileName: data.fileName,
      detail_file: data.detail_file,
      date_file: Date.now(),
    });
    setForm({ ...form, data_file: arrayFile, checkAddFile: false });
    //toSave()
  };

  const renderTableFile = (data, index) => {
    let name = data.fileName ? data.fileName : data.name ? data.name : "-";
    let description = data.detail_file
      ? data.detail_file
      : data.description
      ? data.description
      : "-";

    return (
      <tr key={index}>
        <td className="order-width">{index + 1}</td>
        <td>
          <span
            className={data.id ? "link" : ""}
            onClick={() => {
              data.id
                ? window.open(data.url, "_blank").focus()
                : console.log("");
            }}
          >
            {name}
          </span>
        </td>
        <td>{description || "-"}</td>
        <td>{helper.momentDateTh(data.date_file)}</td>
        <td className="text-center">
          <button
            className="btn btn-del "
            onClick={() => removeImage(index, data)}
          >
            <img
              className="img-fluid icon-btn "
              src="/svg/btn-del.svg"
              alt="del"
            />
          </button>
        </td>
      </tr>
    );
  };

  const renderAddFile = () => {
    return (
      <>
        <tr>
          <td className="order-width">
            {form.data_file && form.data_file.length + 1}
          </td>
          <td>
            <UploadImage
              title=" "
              classLabel="normal mt-2"
              star={false}
              type="fileName"
              typeSave="base64"
              onChange={(name, file) =>
                setFormFile({ ...formFile, file: file, fileName: name })
              }
              id="imgfile"
              position="left"
              files={
                formFile.fileName === null || formFile.fileName === ""
                  ? ""
                  : formFile.fileName
              }
            />
          </td>
          <td>
            <InputText
              classFormGroup="mb-0 "
              type="text"
              idName="count"
              value={formFile.detail_file}
              star={false}
              placeholder="โปรดระบุ"
              handleChange={(value) =>
                setFormFile({ ...formFile, detail_file: value })
              }
            />
          </td>

          <td className="text-center">
            {helper.momentDateTh(formFile.date_file)}
          </td>
          <td className="text-center">
            <button
              className="btn btn-success  pt-1 px-2"
              onClick={async () => {
                fileSave(formFile);
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </td>
        </tr>
      </>
    );
  };

  const toAddAnimal = () => {
    setForm({ ...form, checkAddAnimal: true });
    setAnimalSelectValue([]);
  };

  const saveAnimal = (data, type) => {
    let checkPass = validate("checkDuplicate", data);
    if (checkPass === true) {
      if (type === "add") {
        form.data_animals.push({
          animalTypeId: data.animalTypeId,
          animalTypeName: data.animalTypeName,
          checkEditAnm: false,
        });
        setForm({
          ...form,
          data_animals: form.data_animals,
          checkAddAnimal: false,
          modeEditAnm: false,
        });
      } else if (type === "edit") {
        data.checkEditAnm = false;
        data.animalTypeId = formAnm.animalTypeId;
        data.animalTypeName = formAnm.animalTypeName;

        setForm({ ...form, checkAddAnimal: false, modeEditAnm: false });
      }
    }
  };

  const removeAnimal = (index, item) => {
    form.data_animals.splice(index, 1);
    if (item.id) {
      delAnimals.push(item.id);
    }
    setForm({ ...form, del_animals: delAnimals });
  };

  const editAnimal = (item) => {
    item.checkEditAnm = true;
    setFormAnm({
      ...formAnm,
      animalTypeId: item.animalTypeId,
      animalTypeName: item.animalTypeName,
    });
    setForm({ ...form, modeEditAnm: true });
  };

  const renderAnimal = (data) => {
    if (data && data.length > 0) {
      return data.map((item, i) => (
        <div key={i} className="d-flex mb-2">
          <div className="w-100">
            {item.checkEditAnm ? (
              <InputSelect
                star={false}
                placeholder="กรุณาเลือก"
                idName="animal"
                classFormGroup="mb-0"
                selectValue={animalSelectValue}
                optionsList={animalList}
                handleChange={(value) => {
                  setAnimalSelectValue([value]);
                  setFormAnm({
                    ...formAnm,
                    animalTypeId: value.value,
                    animalTypeName: value.label,
                  });
                }}
                handleInvalid={() => removeInvalid("animalTypeId")}
                invalid={invalid.animalTypeId}
              />
            ) : (
              <InputText
                classFormGroup="mb-0"
                placeholder="โปรดระบุ"
                star={false}
                value={item.animalTypeName}
                disabled
              />
            )}
          </div>
          {item.checkEditAnm ? (
            <div>
              <button
                className="btn btn-success ml-2 px-2"
                onClick={async () => {
                  saveAnimal(item, "edit");
                }}
              >
                <img
                  className="img-fluid icon-check"
                  src="/svg/btn-check.svg"
                  alt="check"
                />
              </button>
            </div>
          ) : (
            <>
              <button
                className="btn btn-add ml-2"
                onClick={() => editAnimal(item)}
                disabled={form.modeEditAnm}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>

              <button
                className="btn btn-del ml-2"
                onClick={() => removeAnimal(i, item)}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </div>
      ));
    }
  };

  const renderNewAnimal = () => {
    return (
      <div className="d-flex mb-2">
        <div className="w-100">
          <InputSelect
            star={false}
            placeholder="กรุณาเลือก"
            idName="animal"
            classFormGroup="mb-0"
            selectValue={animalSelectValue}
            optionsList={animalList}
            handleChange={(value) => {
              setAnimalSelectValue([value]);
              setFormAnm({
                ...formAnm,
                animalTypeId: value.value,
                animalTypeName: value.label,
              });
            }}
            handleInvalid={() => removeInvalid("animalTypeId")}
            invalid={invalid.animalTypeId}
          />
        </div>
        <div>
          <button
            className="btn btn-success ml-2 px-2"
            onClick={async () => {
              saveAnimal(formAnm, "add");
            }}
          >
            <img
              className="img-fluid icon-check"
              src="/svg/btn-check.svg"
              alt="check"
            />
          </button>
        </div>
      </div>
    );
  };

  const renderOutbreak = (item, i) => {
    return (
      <tr key={i}>
        <td>{i + 1}</td>
        <td>
          {item.save ? (
            <div
              onClick={() => {
                setShowOutbreakSearchTable(true);
                setFormTable({ ...formTable, indexOb: i });
              }}
            >
              <InputGroupText
                classFormGroup="mb-0 w-100"
                type="text"
                imgSrc={"/svg/search.svg"}
                imgAlt="search-icon"
                idName={`outbreakCode${i}`}
                value={item.outbreakCode}
                star={false}
                placeholder="ค้นหา"
                classLabel="normal"
                handleChange={(value) => {
                  item.outbreakCode = value;
                  setAddOutBreak([...addOutBreak]);
                }}
              />
            </div>
          ) : (
            <span className="text-nowrap">{item.outbreakCode}</span>
          )}
        </td>
        <td>
          <span className="text-nowrap">{item.save ? null : item.farmId}</span>
        </td>
        <td>
          <span className="text-nowrap">{item.save ? null : item.name}</span>
        </td>
        <td>
          <span className="text-nowrap">{item.save ? null : item.homeNo}</span>
        </td>
        <td>
          <span className="text-nowrap">
            {item.save ? null : item.province}
          </span>
        </td>
        <td>
          <span className="text-nowrap">
            {item.save ? null : item.district}
          </span>
        </td>
        <td>
          <span className="text-nowrap">
            {item.save ? null : item.subDistrict}
          </span>
        </td>
        <td>
          <span className="text-nowrap">{item.save ? null : item.village}</span>
        </td>
        <td className="text-center">
          {item.save ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={() => {
                item.save = false;
                const data = addOutBreak.map((item) => {
                  return { ...item, edit: false };
                });
                setAddOutBreak([...data]);
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => {
                  item.save = true;
                  setAddOutBreak([...addOutBreak]);
                }}
                disabled={item.edit}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button
                className="btn btn-del "
                onClick={() => {
                  const data = [...deleteOutbreak];
                  data.push(addOutBreak[i].id);
                  setDeleteOutbreak(data);

                  addOutBreak.splice(i, 1);
                  setAddOutBreak([...addOutBreak]);
                }}
                disabled={item.edit}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  const editZone = (item, index) => {
    item.positionZoneEdit = index;
    setZoneDataEdit(item);
    setZoneActionType("edit");
    setForm({ ...form, newEpidZone: true });
  };

  const delDataZone = (item, index) => {
    MySwal.fire({
      html: (
        <>
          ยืนยันการลบข้อมูล <br />{" "}
          <span className="font14">
            เมื่อยืนยันระบบจะทำการลบพื้นที่เขตโรคระบาดสัตว์นี้
          </span>
        </>
      ),
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then((result) => {
      if (result.value) {
        form.groupZone.splice(index, 1);
        if (item.id) {
          delZone.push(item.id);
        }
        setForm({ ...form, delGroupZone: delZone });
      }
    });
  };

  const renderTableZone = (data) => {
    return data.map((item, i) => (
      <tr key={i}>
        <td>{i + 1}</td>
        <td>{item.provinceName || "-"}</td>
        <td>{item.amphurName || "-"}</td>
        <td>{item.tambonName || "-"}</td>
        <td>{item.mooName || "-"}</td>
        <td>
          <>
            <button
              className="btn btn-add mr-2"
              onClick={() => editZone(item, i)}
            >
              <img
                className="img-fluid icon-btn"
                src="/svg/btn-edit.svg"
                alt="edit"
              />
            </button>
            <button
              className="btn btn-del "
              onClick={() => delDataZone(item, i)}
            >
              <img
                className="img-fluid icon-btn "
                src="/svg/btn-del.svg"
                alt="del"
              />
            </button>
          </>
        </td>
      </tr>
    ));
  };

  const toCancel = () => {
    history.push("/passive_surv/epidemic_zone");
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2 ">
          <button className="btn btn-danger" onClick={() => toCancel()}>
            ยกเลิก
          </button>
        </div>
        <div className="p-2 ">
          <button
            className="btn btn-success"
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`passive_surv/epidemic_zone/edit`)
            }
            onClick={() => toSaveEpidemicZone()}
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxHead title="ตัวกรอง" classLabel="">
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="เลข Outbreak ID"
                    idName="outbreakCode"
                    value={form.outbreakCode}
                    star={true}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, outbreakCode: value })
                    }
                  />
                </Col>

                <Col lg={1} md={6} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    <button
                      className="btn btn-add "
                      onClick={() => setShowOutbreakSearch(true)}
                      disabled={checkAcctionPageEdit}
                    >
                      <img
                        className="img-fluid icon-btn "
                        src="/svg/btn-edit.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="เลข case ID"
                    idName="caseCode"
                    value={form.caseCode}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, caseCode: value })
                    }
                  />
                </Col>

                <Col lg={1} md={6} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    <button
                      className="btn btn-add "
                      onClick={() => setShowCaseIdSearch(true)}
                      disabled={checkAcctionPageEdit}
                    >
                      <img
                        className="img-fluid icon-btn "
                        src="/svg/btn-edit.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <label className="mb-0 normal">หน่วยงานที่แจ้งโรค </label>
                  <div className="d-flex align-items-center">
                    {form.orgName ? (
                      <>
                        <button
                          className="btn btn-primary pt-2 px-3"
                          onClick={() => setShowOrgModal(true)}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="ml-2">{form.orgName}</div>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary p-2 d-flex align-items-center"
                        onClick={() => setShowOrgModal(true)}
                        disabled={checkAcctionPageEdit}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        <p className="mb-0 ml-2">{t("search")}</p>
                      </button>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={6} sm={6} xs={6}>
                  <div onClick={() => setShowFarmSearch(true)}>
                    <InputGroupText
                      type="text"
                      title="รหัสฟาร์ม"
                      imgSrc={"/svg/search.svg"}
                      imgAlt="search-icon"
                      idName="farmId"
                      value={form.farmId}
                      star={false}
                      placeholder="ค้นหาฟาร์ม"
                      classLabel="normal"
                      handleChange={(value) =>
                        setForm({ ...form, farmId: value })
                      }
                    />
                  </div>
                </Col>

                <Col lg={2} md={6} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    <button
                      className="btn btn-darkblue w-100"
                      onClick={() =>
                        history.push("/farm_registration/save-data")
                      }
                      disabled={checkAcctionPageEdit}
                    >
                      <img
                        className="mr-2 mb-1 "
                        src={"/svg/menu_5.svg"}
                        alt="menu-icon"
                      />
                      ขึ้นทะเบียนฟาร์ม
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ชื่อเจ้าของสัตว์"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>

                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputMask
                    mask="99-9999-9999"
                    title="หมายเลขโทรศัพท์"
                    idName="phone"
                    value={form.phone}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, phone: value.replace(/-/g, "") })
                    }
                    disabled
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="บ้านเลขที่"
                    idName="homeno"
                    value={form.homeNo}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, homeno: value })
                    }
                    disabled
                  />
                </Col>

                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                    disabled
                  />
                </Col>

                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => selectValueDistrict(value)}
                    disabled
                  />
                </Col>

                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => selectValueSubDistrict(value)}
                    disabled
                  />
                </Col>

                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputSelect
                    title="หมู่ที่"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="villageId"
                    classLabel="normal"
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({ ...form, villageId: value.value });
                    }}
                    disabled
                  />
                </Col>
              </Row>
            </div>
          </BoxHead>

          {!form.newEpidZone && (
            <div className="mt-4">
              <BoxCard
                title="Outbreak เพิ่มเติม"
                classNameBox=""
                headRight={buttonAddOutbreak()}
              >
                <div className="">
                  <table className="table table-striped sameWidth table-bordered my-2 ">
                    <thead>
                      <tr className="text-center">
                        <th scope="col">ลำดับ</th>
                        <th scope="col">เลข Outbreak</th>
                        <th scope="col">รหัสฟาร์ม</th>
                        <th scope="col">ชื่อเจ้าของฟาร์ม</th>
                        <th scope="col">บ้านเลขที่</th>
                        <th scope="col">จังหวัด</th>
                        <th scope="col">อำเภอ</th>
                        <th scope="col">ตำบล</th>
                        <th scope="col">หมู่ที่</th>
                        <th scope="col">จัดการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {addOutBreak?.length > 0 ? (
                        addOutBreak.map((item, i) => {
                          return renderOutbreak(item, i);
                        })
                      ) : (
                        <DataTableNodata colSpan={10} />
                      )}
                    </tbody>
                  </table>
                </div>
              </BoxCard>

              <BoxCard classNameBox="mt-4" title="ข้อมูลทั่วไป">
                <Row>
                  <Col lg={2} md={6} sm={12} xs={12}>
                    <InputSelect
                      idName="statusId"
                      title="สถานะโรคระบาด"
                      star={true}
                      classLabel="normal"
                      placeholder="กรุณาเลือก"
                      selectValue={statusSelectValue}
                      optionsList={statusList}
                      handleChange={(value) => {
                        setStatusSelectValue([value]);
                        setForm({ ...form, statusId: value.value });
                      }}
                      handleInvalid={() => removeInvalid("statusId")}
                      invalid={invalid.statusId}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={2} md={6} sm={12} xs={12}>
                    <InputSelect
                      idName="annouceTypeId"
                      title="ประเภทประกาศ"
                      star={true}
                      classLabel="normal"
                      placeholder="กรุณาเลือก"
                      selectValue={annouceTypeSelectValue}
                      optionsList={annouceTypeList}
                      handleChange={(value) => {
                        setAnnouceTypeSelectValue([value]);
                        setForm({ ...form, annouceTypeId: value.value });
                      }}
                      handleInvalid={() => removeInvalid("annouceTypeId")}
                      invalid={invalid.annouceTypeId}
                    />
                  </Col>

                  <Col lg={2} md={6} sm={12} xs={12}>
                    <InputDatePicker
                      idName="startDate"
                      title="วันที่ประกาศ"
                      classLabel="normal"
                      placeholder="วัน/เดือน/ปี"
                      maxDate={true}
                      star={true}
                      value={form.startDate}
                      handleChange={(value) =>
                        setForm({ ...form, startDate: value })
                      }
                      handleInvalid={() => removeInvalid("startDate")}
                      invalid={invalid.startDate}
                    />
                  </Col>

                  <Col lg={2} md={6} sm={12} xs={12}>
                    <InputSelect
                      title="ชนิดโรคระบาด"
                      classLabel="normal"
                      placeholder="กรุณาเลือก"
                      star={true}
                      idName="diseaseTypeId"
                      selectValue={diseaseSelectValue}
                      optionsList={diseaseList}
                      handleChange={(value) => {
                        setDiseaseSelectValue([value]);
                        setForm({ ...form, diseaseTypeId: value.value });
                      }}
                      handleInvalid={() => removeInvalid("diseaseTypeId")}
                      invalid={invalid.diseaseTypeId}
                    />
                  </Col>

                  <Col lg={2} md={6} sm={12} xs={12}>
                    <label className="mb-0 normal">
                      หน่วยงานที่ประกาศ <span className="text-danger">*</span>{" "}
                    </label>
                    <div className="d-flex align-items-center">
                      {form.orgNameForm ? (
                        <>
                          <button
                            className="btn btn-primary pt-2 px-3"
                            onClick={() => setShowOrgModalForm(true)}
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                          <div className="ml-2">{form.orgNameForm}</div>
                        </>
                      ) : (
                        <button
                          className="btn btn-primary p-2 d-flex align-items-center"
                          onClick={() => setShowOrgModalForm(true)}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                          <p className="mb-0 ml-2">{t("search")}</p>
                        </button>
                      )}
                    </div>
                    {invalid.orgIdForm ? (
                      <div className="invalid-error">{invalid.orgIdForm}</div>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col lg={2} md={6} sm={12} xs={12}>
                    <InputText
                      title="ชื่อผู้ประกาศ"
                      classLabel="normal"
                      placeholder="โปรดระบุ"
                      star={true}
                      idName="annouceName"
                      value={form.annouceName}
                      handleChange={(value) =>
                        setForm({ ...form, annouceName: value })
                      }
                      handleInvalid={() => removeInvalid("annouceName")}
                      invalid={invalid.annouceName}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={2} md={6} sm={12} xs={12}>
                    <InputText
                      title="ตำแหน่งผู้ประกาศ"
                      classLabel="normal"
                      placeholder="โปรดระบุ"
                      star={false}
                      idName="annoucePosition"
                      value={form.annoucePosition}
                      handleChange={(value) =>
                        setForm({ ...form, annoucePosition: value })
                      }
                    />
                  </Col>

                  <Col lg={2} md={6} sm={12} xs={12}>
                    <InputDatePicker
                      title="วันที่สิ้นสุดประกาศ"
                      classLabel="normal"
                      placeholder="วัน/เดือน/ปี"
                      star={true}
                      idName="stopDate"
                      value={form.stopDate}
                      // maxDate={form.startDate}
                      minDate={form.startDate}
                      maxDate={"afterSelectDate"}
                      handleChange={(value) =>
                        setForm({ ...form, stopDate: value })
                      }
                      handleInvalid={() => removeInvalid("stopDate")}
                      invalid={invalid.stopDate}
                    />
                  </Col>

                  <Col lg={2} md={6} sm={12} xs={12}>
                    <InputText
                      title="เลขที่หนังสือ"
                      classLabel="normal"
                      placeholder="โปรดระบุ"
                      star={false}
                      idName="annouceCode"
                      value={form.annouceCode}
                      handleChange={(value) =>
                        setForm({ ...form, annouceCode: value })
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col
                    lg={1}
                    md={6}
                    sm={12}
                    xs={12}
                    className="d-flex align-items-center"
                  >
                    <p className="bold mb-0">ชนิดสัตว์: </p>
                  </Col>
                  <Col lg={2} md={6} sm={12} xs={12}>
                    <button
                      className="btn btn-add text-white"
                      onClick={() => toAddAnimal()}
                      disabled={
                        !form.checkAddAnimal && form.modeEditAnm ? true : false
                      }
                    >
                      <i className="fas fa-plus mr-1"></i> เพิ่ม
                    </button>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col lg={1} md={6} sm={12} xs={12}></Col>
                  <Col lg={4} md={6} sm={12} xs={12}>
                    {form.data_animals &&
                      form.data_animals.length > 0 &&
                      renderAnimal(form.data_animals)}
                    {form.checkAddAnimal && renderNewAnimal()}
                  </Col>
                </Row>
              </BoxCard>

              <BoxCard
                classNameBox="mt-4"
                title="พื้นที่เขตโรคระบาดสัตว์"
                headRight={buttonAddEpidZone()}
              >
                <Table className="table table-striped table-bordered my-2">
                  <thead className="text-center">
                    <tr>
                      <th scope="col" className="order-width">
                        ลำดับ
                      </th>
                      <th scope="col">หมู่ที่</th>
                      <th scope="col">จังหวัด</th>
                      <th scope="col">อำเภอ</th>
                      <th scope="col">ตำบล</th>
                      <th scope="col">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {form.groupZone && form.groupZone.length > 0 ? (
                      renderTableZone(form.groupZone)
                    ) : (
                      <DataTableNodata colSpan={6} />
                    )}
                  </tbody>
                </Table>
              </BoxCard>

              <BoxCard
                classNameBox="mt-4"
                title="เอกสารแนบ"
                headRight={buttonAddDoc()}
              >
                <Table className="table table-striped table-bordered my-2 ">
                  <thead className="text-center">
                    <tr>
                      <th scope="col" className="order-width">
                        ลำดับ
                      </th>
                      <th scope="col">ชื่อไฟล์</th>
                      <th scope="col">คำอธิบาย</th>
                      <th scope="col">วันที่แนบ</th>
                      <th scope="col">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.data_file && form.data_file.length > 0
                      ? form.data_file.map((item, i) => {
                          return renderTableFile(item, i);
                        })
                      : null}
                    {form.checkAddFile && renderAddFile(form.data_file)}
                    {form.data_file &&
                      form.data_file.length === 0 &&
                      !form.checkAddFile && <DataTableNodata colSpan={6} />}
                  </tbody>
                </Table>
              </BoxCard>
            </div>
          )}

          {form.newEpidZone ? (
            <div className="mt-4">
              <AddEpidemicZone
                save={(data) => saveGroupZoneToForm(data)}
                formData={form}
                dataEdit={zoneDataEdit}
                zoneActionType={zoneActionType}
                back={(flg) => setForm({ ...form, newEpidZone: flg })}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {showOutbreakSearch && (
        <ModalOutbreakSearch
          show={showOutbreakSearch}
          mode={form.modeOutbreak}
          close={() => setShowOutbreakSearch(false)}
          save={(value) =>
            setForm({
              ...form,
              outbreakCode: value.obCode,
              outbreakId: value.obId,
              mode: "add",
            })
          }
        />
      )}
      {showOutbreakTableSearch && (
        <ModalOutbreakSearch
          show={showOutbreakTableSearch}
          mode={formTable.modeOutbreak}
          close={() => setShowOutbreakSearchTable(false)}
          save={(value) => {
            if (checkOutbreakCode(addOutBreak, value.obCode)) {
              setFormTable({
                ...formTable,
                outbreakCode: value.obCode,
                outbreakId: value.obId,
                indexOb: formTable.indexOb,
              });
            }
          }}
        />
      )}

      {showFarmSearch && (
        <ModalFarmSearch
          show={showFarmSearch}
          mode={form.modeFarmSearch}
          close={() => setShowFarmSearch(false)}
          save={(value) => {
            setForm({ ...form, farmId: value, mode: "farmById" });
          }}
        />
      )}
      {showOrgModal && (
        <ModalOrganizes
          show={showOrgModal}
          close={() => setShowOrgModal(false)}
          toSaveOrg={(data) => setOrganizes(data)}
        />
      )}

      {showOrgModalForm && (
        <ModalOrganizes
          show={showOrgModalForm}
          close={() => setShowOrgModalForm(false)}
          toSaveOrg={(data) => setOrganizesForm(data)}
        />
      )}
    </div>
  );
}

export default Epidemic_Zone_Save;
