import * as actionTypes from "../actions/loadingTypes";

export const initialState = [
    {
        id: 1,
        color: "#3FC071",
        status: "ใช้งาน"
    },
    {
        id: 2,
        color: "#FF482F",
        status: "ไม่ใช้งาน"
    },
]

export default (state = initialState) => {  
    return state;
};