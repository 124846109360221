import React, { useState, useEffect, useCallback } from "react";
import Pagination from "./Pagination";
import InputSelect from "./InputSelect";
import TableInfo from "./DataTableInfo";

function DataTable(props) {
  // function DataTable({ headTitle, typeTable, isLimit, getLimit, isPage, pageAll, getThisPage,
  //     isSortBy, isSortDir, getFilter, headerClass, totalItems, numberOfItems, children }) {
  const {
    headColumns,
    headerClass,
    dataColumns,
    children,
    classNameTb,
    isClear,
    id,
    reference,
  } = props;
  const initialForm = {
    sortBy: "id",
    sortDir: "ASC",
    checkFlg: true,
  };
  const [form, setForm] = useState(initialForm);

  const initLimitSelectValue = [{ value: 10, label: 10 }];
  const [limitSelectValue, setLimitSelectValue] =
    useState(initLimitSelectValue);
  const limitValueList = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 75, label: 75 },
    { value: 100, label: 100 },
  ];
  const [limitList, setLimitList] = useState(limitValueList);

  useEffect(async () => {
    mapSorting();
  }, []);

  useEffect(() => {
    setLimitSelectValue(initLimitSelectValue);
  }, [isClear]);

  const mapSorting = () => {
    headColumns.map((item) => {
      if (item.sortBy == props.sortBy) {
        setForm({ ...form, sortBy: props.sortBy, sortDir: props.sortDir });
      }
    });
  };

  const setSorting = (sortBy) => {
    // console.log("check sorting", headColumns, props.sortDir, sortBy);
    if (sortBy == props.sortBy && props.sortDir == "ASC") {
      props.getFilter(sortBy, "DESC");
      setForm({ ...form, sortBy: sortBy, sortDir: "DESC" });
    } else if (sortBy == props.sortBy && props.sortDir == "DESC") {
      props.getFilter(sortBy, "ASC");
      setForm({ ...form, sortBy: sortBy, sortDir: "ASC" });
    } else {
      props.getFilter(sortBy, "ASC");
      setForm({ ...form, sortBy: sortBy, sortDir: "ASC" });
    }
  };

  const getCurPage = async (cPage) => {
    props.getPage(cPage);
  };

  const setHeaderColumns = () => {
    if (props.typeTable == "colSpan") {
      return (
        <>
          <tr>
            {headColumns?.length > 0 &&
              headColumns.map((head, h) => {
                return (
                  <React.Fragment key={h}>
                    <th
                      scope="col"
                      style={{ width: head.width }}
                      className={
                        head.sortFlg
                          ? `cursor-p ${headerClass ? headerClass : ""}`
                          : `${headerClass ? headerClass : ""}`
                      }
                      colSpan={head.colSpan ? head.colSpan : ""}
                      rowSpan={head.rowSpan ? head.rowSpan : ""}
                      onClick={() => {
                        if (head.sortFlg) {
                          setSorting(head.sortBy);
                        }
                      }}
                    >
                      <div
                        className={
                          head.sortFlg
                            ? "d-flex align-items-center justify-content-center"
                            : "d-flex justify-content-center"
                        }
                      >
                        {head.name}
                        {head.sortFlg ? (
                          props.sortBy == head.sortBy ? (
                            form.sortDir == "ASC" ? (
                              <img
                                src={"/images/filter_down.png"}
                                className="ml-1 i-filter"
                              />
                            ) : form.sortDir == "DESC" ? (
                              <img
                                src={"/images/filter_up.png"}
                                className="ml-1 i-filter"
                              />
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                  </React.Fragment>
                );
              })}
          </tr>
          <tr>
            {headColumns?.length > 0 &&
              headColumns.map((head, h) => {
                if (head?.childHead?.length > 0) {
                  return head.childHead.map((child, c) => (
                    <th
                      key={c}
                      scope="col"
                      style={{ width: head.width }}
                      className={
                        child.sortFlg
                          ? `cursor-p ${headerClass ? headerClass : ""}`
                          : `${headerClass ? headerClass : ""}`
                      }
                      colSpan={child.colSpan ? child.colSpan : ""}
                      rowSpan={child.rowSpan ? child.rowSpan : ""}
                      onClick={() => {
                        if (child.sortFlg) {
                          setSorting(child.sortBy);
                        }
                      }}
                    >
                      <div
                        className={
                          child.sortFlg
                            ? "d-flex align-items-center justify-content-center"
                            : "d-flex justify-content-center"
                        }
                      >
                        {child.name}
                        {child.sortFlg ? (
                          props.sortBy == child.sortBy ? (
                            form.sortDir == "ASC" ? (
                              <img
                                src={"/images/filter_down.png"}
                                className="ml-1 i-filter"
                              />
                            ) : form.sortDir == "DESC" ? (
                              <img
                                src={"/images/filter_up.png"}
                                className="ml-1 i-filter"
                              />
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                  ));
                }
              })}
          </tr>
          <tr>
            {headColumns &&
              headColumns.length > 0 &&
              headColumns.map((head, h) => {
                if (head.childHead && head.childHead.length > 0) {
                  return head.childHead.map((child, c) => {
                    if (child?.childHeadDetail?.length > 0) {
                      return child?.childHeadDetail.map((detail, d) => {
                        return (
                          <th
                            key={d}
                            scope="col"
                            style={{ width: head.width }}
                            className={
                              detail.sortFlg
                                ? `cursor-p ${headerClass ? headerClass : ""}`
                                : `${headerClass ? headerClass : ""}`
                            }
                            onClick={() => {
                              if (detail.sortFlg) {
                                setSorting(detail.sortBy);
                              }
                            }}
                          >
                            <div
                              className={
                                detail.sortFlg
                                  ? "d-flex align-items-center justify-content-center"
                                  : "d-flex justify-content-center"
                              }
                            >
                              {detail.name}
                              {detail.sortFlg ? (
                                props.sortBy == detail.sortBy ? (
                                  form.sortDir == "ASC" ? (
                                    <img
                                      src={"/images/filter_down.png"}
                                      className="ml-1 i-filter"
                                    />
                                  ) : form.sortDir == "DESC" ? (
                                    <img
                                      src={"/images/filter_up.png"}
                                      className="ml-1 i-filter"
                                    />
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </th>
                        );
                      });
                    }
                  });
                }
              })}
          </tr>
        </>
      );
    } else {
      return (
        <tr>
          {headColumns &&
            headColumns.length > 0 &&
            headColumns.map((head, h) => {
              return (
                <th
                  key={h}
                  scope="col"
                  style={{ width: head.width ? head.width : "auto" }}
                  className={
                    head.sortFlg ? `cursor-p ${headerClass}` : `${headerClass}`
                  }
                  onClick={() => {
                    if (head.sortFlg) {
                      setSorting(head.sortBy);
                    }
                  }}
                >
                  <div
                    className={
                      head.sortFlg
                        ? "d-flex align-items-center justify-content-center"
                        : "d-flex justify-content-center"
                    }
                  >
                    {head.name}
                    {head.sortFlg ? (
                      props.sortBy == head.sortBy ? (
                        form.sortDir == "ASC" ? (
                          <img
                            src={"/images/filter_down.png"}
                            className="ml-1 i-filter"
                          />
                        ) : form.sortDir == "DESC" ? (
                          <img
                            src={"/images/filter_up.png"}
                            className="ml-1 i-filter"
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </th>
              );
            })}
        </tr>
      );
    }
  };

  const setDataColumns = () => {
    if (dataColumns.length > 0) {
      return dataColumns.map((item, i) => {
        return (
          <tr key={i} className={item.class}>
            {renderColumns(dataColumns[i].render(item, i))}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td scope="col" colSpan={12} className="text-center font14">
            {"No Data"}
          </td>
        </tr>
      );
    }
  };

  const renderColumns = (data) => {
    return data.map((col, j) => {
      if (typeof col === "object" && col.props && col.$$typeof) {
        return (
          <td key={j} className={`${col.class ? col.class : "text-center"}`}>
            {col}
          </td>
        );
      } else if (typeof col === "object") {
        return (
          <td key={j} className={`${col.class ? col.class : "text-center"}`}>
            {col.value}
          </td>
        );
      } else {
        return <td key={j}>{col}</td>;
      }
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center font12 mt-2">
        <div className="d-flex align-items-center">
          <span className="mr-2">แสดง</span>
          <InputSelect
            star={false}
            placeholder={props.limit}
            mode="filter"
            idName="limit"
            classFormGroup="mb-0"
            selectValue={limitSelectValue}
            optionsList={limitList}
            handleSelectValue={(value) => setLimitSelectValue([value])}
            handleChange={(value) => props.getLimit(value.value)}
          />
          <span className="ml-3">รายการ</span>
        </div>
      </div>
      <div className="table-responsive">
        <table
          className={
            classNameTb
              ? `${classNameTb} table table-striped table-bordered my-3`
              : "table table-striped table-bordered my-3"
          }
          id={id}
          ref={reference}
        >
          <thead>{headColumns && setHeaderColumns()}</thead>
          <tbody>
            {/* {(dataColumns) && setDataColumns()} */}
            {!dataColumns && children}
          </tbody>
        </table>
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <div className="font14 text-gray">
          <TableInfo
            page={props.page}
            limit={props.limit}
            itemCount={props.numberOfItems}
            totalItem={props.totalItems}
          />
        </div>
        <div className="pt-3">
          <Pagination
            page={props.page}
            totalPage={props.totalPages}
            getPage={(cPage) => getCurPage(cPage)}
          />
        </div>
      </div>

      <style jsx="true" global="true">
        {`
          .i-filter {
            width: 16px;
            height: 16px;
          }
        `}
      </style>
    </div>
  );
}

export default DataTable;
