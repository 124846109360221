import React, { useState, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BoxCard from "../../components/BoxCard";
import InputMask from "../../components/InputMask";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import { Row, Col, Form, Table } from "react-bootstrap";
import DataTableNodata from "../../components/DataTableNodata";
import * as API from "../../utils/apis";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function Risk_assessment_Item_save(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);

  const initialForm = {
    formId: "",
    groupId: "",
    orderIndex: "",
    // questNo: "",
    ansTypeId: "1",
    name: "",
    remark: "",
    suggestion: "",
    statusId: "",

    checkAddDisease: false,

    dataDisease: [],
    dataQuestion: [],

    checkAddQuestion: false,

    chooseSelect: false,

    modeEditTable1: false,
    modeEditTable2: false,
    mode: "",
  };
  const [form, setForm] = useState(initialForm);

  const initialFormDisease = {
    evFormDisease: "",
    weight: "",
  };
  const [formDisease, setFormDisease] = useState(initialFormDisease);

  const initialFormQuestion = {
    nameQuestion: "",
    score: "",
  };
  const [formQuestion, setFormQuestion] = useState(initialFormQuestion);

  const initialFormJson = {
    diseaseTypeId: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initInvalidMsg = {
    evFormDisease: "",
    weight: "",
    nameQuestion: "",
    score: "",
    formId: "",
    groupId: "",
    orderIndex: "",
    // questNo: "",
    ansTypeId: "",
    name: "",
    remark: "",
    suggestion: "",
    statusId: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  // Select สถานะ
  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    {
      value: 1,
      label: "ใช้งาน",
    },
    {
      value: 2,
      label: "ไม่ใช้งาน",
    },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  // ชุดประเมินความเสี่ยง
  const [riskSelectValue, setRiskSelectValue] = useState([]);
  const [riskList, setRiskList] = useState([]);

  // Select กลุ่ม
  const [groupSelectValue, setGroupSelectValue] = useState([]);
  const [groupList, setGroupList] = useState([]);

  // Select ชนิดโรค
  const [diseaseTypeSelectValue, setDiseaseTypeSelectValue] = useState([]);
  const [diseaseTypeList, setDiseaseTypeList] = useState([]);

  useEffect(() => {
    getMasterJson();
    getDiseaseList();
    getGroupData();
  }, []);

  useEffect(async () => {
    // เวลาเรากดมาแก้ไขแล้วpastเป็นedit ให้ทำการเปลี่ยนchooseSelect เป็นtrue
    if (location.pathname.includes("edit") && !form.chooseSelect) {
      getAssessmentById(props.match.params.id);
    }
    if (location.pathname.includes("save") && props.match.params.id) {
      getAddQuestion(props.match.params.id);
    }
    // ตัวแปรกำหนดไว้ให้เวลาที่เราเข้าหน้าedit ให้ระบบทำการโหลดข้อมูลตามตัวแปรอีกครั้ง
  }, [user, location.pathname, form.formId, form.groupId]);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/assess_risk/list-assess-risk", name: "ประเมินความเสี่ยง" },
    { path: "active", name: "บันทึกคำถาม" },
  ];

  // getByIdเวลาที่เราต้องการแก้ไขให้ระบบcall apiจากid เผื่อนำค่าที่บันทึกมาgetลงtext
  const getAssessmentById = async (id) => {
    dispatch(loading(true));
    let dataRisk;
    try {
      const response = await API.getAssessmentById(id);
      if (response.status == 200) {
        dataRisk = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
    //   console.log("dataRisk", dataRisk)

    if (dataRisk) {
      const dataById = dataRisk;
      // console.log("riskSelectValue", riskList);

      if (dataById.quest && dataById.quest.formId) {
        let riskSelectValue = await riskList.find(
          (item) => item.value == dataById.quest.formId
        );
        // getByIdDisease กำหนดเผื่อให้เวลาที่เราเข้าหน้า edit เว็บจะทำการเรียกทั้ง ชุดประเมินความเสี่ยงและเรียกชนิดโรคของชุดประเมินนั้น
        setRiskSelectValue(riskSelectValue);
        getByIdDisease(dataById.quest.formId);
      }

      if (dataById.quest && dataById.quest.groupId) {
        let groupSelectValue = await groupList.find(
          (item) => item.value == dataById.quest.groupId
        );
        setGroupSelectValue(groupSelectValue);
      }

      if (dataById.quest && dataById.quest.statusId) {
        let statusSelectValue = await selectStatusList.find(
          (item) => item.value == dataById.quest.statusId
        );
        setStatusSelectValue(statusSelectValue);
      }

      const listWight = [];
      if (dataById.ansWeight && dataById.ansWeight.length > 0) {
        dataById.ansWeight.map((item, i) => {
          listWight.push({
            evFormDisease: item.diseaseTypeId,
            weight: item.weight,
          });
        });
        // console.log("listWight", listWight);
      }

      const listAns = [];
      if (dataById.ansDtl && dataById.ansDtl.length > 0) {
        dataById.ansDtl.map((item, i) => {
          listAns.push({
            nameQuestion: item.name,
            score: item.score,
          });
        });
        // console.log("listAns", listAns);
      }

      setForm({
        ...form,
        mode: "edit",
        formId: dataById.quest.formId,
        groupId: dataById.quest.groupId,
        orderIndex: dataById.quest.orderIndex,
        // questNo: dataById.quest.questNo,
        ansTypeId: dataById.quest.ansTypeId,
        name: dataById.quest.name,
        remark: dataById.quest.remark,
        suggestion: dataById.quest.suggestion,
        statusId: dataById.quest.statusId,

        dataDisease: listWight,
        dataQuestion: listAns,
      });
    }
  };

  const getAddQuestion = async (id) => {
    dispatch(loading(true));
    let dataRisk;
    try {
      const response = await API.getAssessById(id);
      if (response.status == 200) {
        dataRisk = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
    //   console.log("dataRisk", dataRisk)

    if (dataRisk) {
      const dataById = dataRisk;

      if (
        dataById.evFormDtl &&
        dataById.evFormDtl.id &&
        dataById.evFormDtl.name
      ) {
        setRiskSelectValue({
          value: dataById.evFormDtl.id,
          label: dataById.evFormDtl.name,
        });
        getByIdDisease(dataById.evFormDtl.id);
      }

      setForm({
        ...form,
        mode: "saveById",
        formId: dataById.evFormDtl.id,
      });
    }
  };

  const getDiseaseList = async () => {
    try {
      const response = await API.getDiseaseList();
      const data = await response.data;
      if (response.status == 200) {
        let diseaseListArray = [];
        data.content.forEach((element) => {
          diseaseListArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setRiskList(diseaseListArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ใช้เวลาที่เราจะ select ข้อมูลมาแล้วต้องการเจาะจงว่าอยากให้โชว์ข้อมูลเฉพาะidนั้นๆ
  const compareDissessId = (list, value) => {
    let check = false;
    list.forEach((items, b) => {
      if (items === value) {
        check = true;
      }
    });
    return check;
  };

  const getDisease = async (listDissess) => {
    try {
      const response = await API.getDisease();
      const data = await response.data;
      // console.log("getDisease-response-🐦🐦", response);
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          if (compareDissessId(listDissess, element.id)) {
            diseaseArray.push({
              value: element.diseaseTypeId,
              label: element.diseaseTypeName,
            });
          }
        });
        await setDiseaseTypeList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGroupData = async () => {
    try {
      const response = await API.getGroupData();
      const data = await response.data;
      if (response.status == 200) {
        let groupArray = [];
        data.content.forEach((element) => {
          groupArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setGroupList(groupArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getByIdDisease = async (id) => {
    try {
      const response = await API.getAssessById(id);
      const data = await response.data;
      if (response.status == 200) {
        getDisease(data && data.evFormDisease);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectRiskValue = (value) => {
    setRiskSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, formId: value.value, chooseSelect: true });
    getByIdDisease(value.value);
  };

  const getMasterJson = async () => {
    const params = await ["diseaseType"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      // console.log("getMasterJson--response-😍", response);
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          diseaseTypeId: data.diseaseType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const toSaveQuestion = () => {
    if (validateForm()) {
      const dataRisk = form;

      const listDisease = [];
      if (dataRisk.dataDisease && dataRisk.dataDisease.length > 0) {
        dataRisk.dataDisease.map((item, index) => {
          if (item.id) {
          } else {
            listDisease.push({
              diseaseTypeId: item.evFormDisease,
              weight: item.weight,
            });
          }
        });
      }

      const listAns = [];
      if (dataRisk.dataQuestion && dataRisk.dataQuestion.length > 0) {
        dataRisk.dataQuestion.map((item, index) => {
          if (item.id) {
          } else {
            listAns.push({
              name: item.nameQuestion,
              score: item.score,
              flgOther: "n",
            });
          }
        });
      }

      const data = {
        quest: {
          formId: form.formId,
          groupId: form.groupId,
          orderIndex: form.orderIndex,
          // "questNo": form.questNo,
          ansTypeId: form.ansTypeId,
          name: form.name,
          remark: form.remark,
          suggestion: form.suggestion,
          statusId: form.statusId,
        },
        ansWeight: listDisease,
        ansDtl: listAns,
      };
      // console.log("data", data);
      MySwal.fire({
        text: `ต้องการบันทึกใช่หรือไม่`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (value) => {
        if (value.isConfirmed) {
          try {
            const response = location.pathname.includes("save")
              ? await API.addRiskAssessment(data)
              : await API.updateRiskAssessment(props.match.params.id, data);
            if (response.status == 200) {
              MySwal.fire({
                text: location.pathname.includes("save")
                  ? `สร้าง "บันทึกข้อมูล" สำเร็จ`
                  : `แก้ไข "บันทึกการข้อมูล" สำเร็จ`,
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then(async (result) => {
                history.push(`/assess_risk/risk-assessment-items`);
              });
            }
          } catch (error) {
            dispatch(loading(false));
            console.log("error", error);

            const mgeError = error?.response?.data.errors[0];
            if (mgeError.includes("rollback-only")) {
              MySwal.fire({
                html: (
                  <>
                    ไม่สามารถแก้ไขคำถามนี้ได้ <br />{" "}
                    เนื่องจากคำถามนี้ได้ถูกใช้ไปแล้ว
                  </>
                ),
                icon: "warning",
                confirmButtonText: "ตกลง",
              });
            }
            if (error.response && error.response.status == 401) {
              dispatch(logout({ history }));
            } else {
              const textError = error.response?.data?.errors;
              const textAlert = location.pathname.includes("save")
                ? `สร้าง "บันทึกข้อมูล" ไม่สำเร็จ`
                : `แก้ไข "บันทึกข้อมูล" ไม่สำเร็จ`;
              MySwal.fire({
                title: textAlert,
                text: textError,
                icon: "warning",
                confirmButtonText: "ตกลง",
              });
            }
          }
        } else {
          dispatch(loading(false));
        }
      });
    }
  };

  const toDisease = () => {
    setForm({ ...form, checkAddDisease: true });
    setFormDisease({ ...formDisease, evFormDisease: "", weight: "" });
    setDiseaseTypeSelectValue([]);
  };

  const diseaseSave = (data, type) => {
    if (validateFormDisease()) {
      if (type == "add") {
        form.dataDisease.push({
          evFormDisease: formDisease.evFormDisease,
          weight: formDisease.weight,
        });
        setForm({
          ...form,
          dataDisease: form.dataDisease,
          checkAddDisease: false,
          modeEditTable1: false,
        });
      } else if (type == "edit") {
        data.checkEditDisease = false;
        data.evFormDisease = formDisease.evFormDisease;
        data.weight = formDisease.weight;
        setForm({ ...form, checkAddDisease: false, modeEditTable1: false });
      }
    }
  };

  const questionSave = (data, type) => {
    if (validateFormQuestion()) {
      let arrayQuestion = [...form.dataQuestion];
      if (type == "add") {
        arrayQuestion.push({
          nameQuestion: formQuestion.nameQuestion,
          score: formQuestion.score,
        });
        setForm({
          ...form,
          dataQuestion: arrayQuestion,
          checkAddQuestion: false,
          modeEditTable2: false,
        });
      } else if (type == "edit") {
        data.checkEditQuestion = false;
        data.nameQuestion = formQuestion.nameQuestion;
        data.score = formQuestion.score;
        setForm({ ...form, checkAddQuestion: false, modeEditTable2: false });
      }
    }
  };

  const editDisease = (item) => {
    item.checkEditDisease = true;
    setForm({ ...form, modeEditTable1: true });
    setFormDisease({
      ...formDisease,
      evFormDisease: item.evFormDisease,
      weight: item.weight,
    });

    setDiseaseTypeSelectValue([
      {
        value: item.evFormDisease,
        label: formJson.diseaseTypeId[item.evFormDisease],
      },
    ]);
  };

  const DelDissess = (index) => {
    form.dataDisease.splice(index, 1);
    setForm({ ...form, dataDisease: form.dataDisease });
  };

  const editQuestion = (item) => {
    item.checkEditQuestion = true;

    formQuestion["nameQuestion"] = item.nameQuestion;
    formQuestion["score"] = item.score;

    setFormDisease({ ...formQuestion });
    setForm({ ...form, modeEditTable2: true });
  };
  const delQuestion = (index) => {
    form.dataQuestion.splice(index, 1);
    setForm({ ...form, dataQuestion: form.dataQuestion });
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validateForm = () => {
    let validated = true;

    if (form.formId == "") {
      addInvalid("formId", "กรุณาเลือกชุดประเมินความเสี่ยง");
      validated = false;
    }

    if (form.groupId == "") {
      addInvalid("groupId", "กรุณาเลือกกลุ่ม");
      validated = false;
    }

    if (form.orderIndex == "") {
      addInvalid("orderIndex", "กรุณากรอกลำดับการแสดงเป็นตัวเลข");
      validated = false;
    }

    if (form.ansTypeId == "") {
      addInvalid("ansTypeId", "กรุณาเลือกประเภทคำตอบ");
      validated = false;
    }

    if (form.name == "") {
      addInvalid("name", "กรุณากรอกคำถาม");
      validated = false;
    }

    if (form.suggestion == "") {
      addInvalid("suggestion", "กรุณากรอกคำแนะนำลักษณะฟาร์ม");
      validated = false;
    }

    if (form.statusId == "") {
      addInvalid("statusId", "กรุณาเลือกสถานะ");
      validated = false;
    }

    return validated;
  };

  const validateFormDisease = () => {
    let validated = true;
    if (formDisease.evFormDisease == "") {
      addInvalid("evFormDisease", "กรุณากรอกข้อมูล");
      validated = false;
    }

    if (formDisease.weight == "") {
      addInvalid("weight", "กรุณากรอกข้อมูล");
      validated = false;
    }
    return validated;
  };

  const validateFormQuestion = () => {
    let validated = true;
    if (formQuestion.nameQuestion == "") {
      addInvalid("nameQuestion", "กรุณาชื่อ");
      validated = false;
    }

    if (formQuestion.score == "") {
      addInvalid("score", "กรุณากรอกคะแนน");
      validated = false;
    }
    return validated;
  };

  const renderAddDisease = () => {
    return (
      <>
        <tr>
          <td>
            <InputSelect
              star={false}
              placeholder="กรุณาเลือก"
              idName="disease"
              classFormGroup="mb-0"
              selectValue={diseaseTypeSelectValue}
              optionsList={diseaseTypeList}
              handleChange={(value) => {
                setFormDisease({ ...formDisease, evFormDisease: value.value });
                setDiseaseTypeSelectValue([value]);
              }}
              handleInvalid={() => removeInvalid("evFormDisease")}
              invalid={invalid.evFormDisease}
            />
          </td>

          <td>
            <InputText
              type="number"
              idName="weight"
              value={formDisease.weight}
              star={false}
              classFormGroup="mb-0"
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) =>
                setFormDisease({ ...formDisease, weight: value })
              }
              handleInvalid={() => removeInvalid("weight")}
              invalid={invalid.weight}
            />
          </td>

          <td className="text-center">
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                diseaseSave(formDisease, "add");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </td>
        </tr>
      </>
    );
  };

  const renderTableDisease = (data, index) => {
    return (
      <tr key={index}>
        <td className="fitwidth">
          {data.checkEditDisease ? (
            <InputSelect
              star={false}
              placeholder="กรุณาเลือก"
              idName="disease"
              classFormGroup="mb-0"
              selectValue={diseaseTypeSelectValue}
              optionsList={diseaseTypeList}
              handleSelectValue={(value) =>
                setDiseaseTypeSelectValue([
                  { value: value.value, label: value.label },
                ])
              }
              handleChange={(value) => {
                setFormDisease({ ...formDisease, evFormDisease: value.value });
              }}
            />
          ) : data.evFormDisease ? (
            formJson.diseaseTypeId[data.evFormDisease]
          ) : (
            ""
          )}
        </td>

        <td className="fitwidth">
          {data.checkEditDisease ? (
            <InputText
              type="number"
              idName="weight"
              value={formDisease.weight}
              star={false}
              classFormGroup="mb-0"
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) =>
                setFormDisease({ ...formDisease, weight: value })
              }
              handleInvalid={() => removeInvalid("weight")}
              invalid={invalid.weight}
            />
          ) : data.weight ? (
            data.weight
          ) : (
            ""
          )}
        </td>

        <td className="text-center">
          {data.checkEditDisease ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                diseaseSave(data, "edit");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => editDisease(data)}
                disabled={form.modeEditTable1}
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button className="btn btn-del" onClick={() => DelDissess(index)}>
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  const buttonAddQuestion = () => {
    return (
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddQuestion()}
          disabled={
            form.checkAddQuestion
              ? true
              : false || form.modeEditTable2
              ? true
              : false
          }
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มคำตอบ
        </button>
      </div>
    );
  };

  const toAddQuestion = () => {
    setFormQuestion({ ...formQuestion, nameQuestion: "", score: "" });
    setForm({ ...form, checkAddQuestion: true });
  };

  const renderAddQuestion = () => {
    return (
      <>
        <tr>
          <td>{form.dataQuestion.length + 1}</td>
          <td>
            <InputText
              type="text"
              idName="nameQuestion"
              value={formQuestion.nameQuestion}
              star={false}
              classFormGroup="mb-0"
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) =>
                setFormQuestion({ ...formQuestion, nameQuestion: value })
              }
              handleInvalid={() => removeInvalid("nameQuestion")}
              invalid={invalid.nameQuestion}
            />
          </td>
          <td>
            <InputMask
              mask={"9999999"}
              type="text"
              idName="score"
              value={formQuestion.score}
              star={false}
              classFormGroup="mb-0"
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) =>
                setFormQuestion({ ...formQuestion, score: value })
              }
              handleInvalid={() => removeInvalid("score")}
              invalid={invalid.score}
            />
          </td>

          <td className="text-center">
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                questionSave(formQuestion, "add");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </td>
        </tr>
      </>
    );
  };

  const renderTableQuestion = (data, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>

        <td className="fitwidth">
          {data.checkEditQuestion ? (
            <InputText
              type="text"
              idName="nameQuestion"
              value={formQuestion.nameQuestion}
              star={false}
              classFormGroup="mb-0"
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) =>
                setFormQuestion({ ...formQuestion, nameQuestion: value })
              }
              handleInvalid={() => removeInvalid("nameQuestion")}
              invalid={invalid.nameQuestion}
            />
          ) : data.nameQuestion ? (
            data.nameQuestion
          ) : (
            ""
          )}
        </td>

        <td className="fitwidth">
          {data.checkEditQuestion ? (
            <InputMask
              mask={"9999999"}
              type="text"
              idName="score"
              value={formQuestion.score}
              star={false}
              classFormGroup="mb-0"
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) =>
                setFormQuestion({ ...formQuestion, score: value })
              }
              handleInvalid={() => removeInvalid("score")}
              invalid={invalid.score}
            />
          ) : data.score ? (
            data.score
          ) : (
            ""
          )}
        </td>

        <td className="text-center">
          {data.checkEditQuestion ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                questionSave(data, "edit");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => editQuestion(data)}
                disabled={form.modeEditTable2}
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del"
                onClick={() => delQuestion(index)}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <div className="pl-lg-4 pl-md-4 pl-sm-2 bg-white d-flex flex-wrap align-items-center ml30">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-danger"
            onClick={() => history.push("/assess_risk/risk-assessment-items")}
          >
            ยกเลิก
          </button>
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-success"
            onClick={() => {
              toSaveQuestion();
            }}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(
                `assess_risk/risk-assessment-items/edit`
              )
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="1. คำถาม">
            <div>
              <Row className="mb-2 pl-2 ">
                <Col lg={5} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชุดประเมินความเสี่ยง"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="formId"
                    classLabel="normal"
                    selectValue={riskSelectValue}
                    optionsList={riskList}
                    handleChange={(value) => {
                      setForm({ ...form, formId: value.value });
                      selectRiskValue(value);
                    }}
                    handleInvalid={() => removeInvalid("formId")}
                    invalid={invalid.formId}
                    disabled={form.mode === "saveById"}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="กลุ่ม"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="groupId"
                    classLabel="normal"
                    selectValue={groupSelectValue}
                    optionsList={groupList}
                    handleChange={(value) => {
                      setForm({
                        ...form,
                        groupId: value.value,
                        chooseSelect: true,
                      });
                      setGroupSelectValue([
                        { value: value.value, label: value.label },
                      ]);
                    }}
                    handleInvalid={() => removeInvalid("groupId")}
                    invalid={invalid.groupId}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="number"
                    title="ลำดับการแสดง"
                    idName=" orderIndex"
                    value={form.orderIndex}
                    star={true}
                    placeholder="ระบุจำนวน"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, orderIndex: value })
                    }
                    handleInvalid={() => removeInvalid("orderIndex")}
                    invalid={invalid.orderIndex}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2 ">
                <Col
                  lg={2}
                  md={2}
                  sm={12}
                  className="d-flex align-items-center"
                >
                  <p className="mb-0">ประเภทคำตอบ :</p>
                </Col>

                <Col lg={10} md={10} sm={12} className="w-100">
                  <div className="d-flex align-items-center flex-wrap ">
                    <div className="d-flex">
                      <Form.Check
                        inline
                        type="radio"
                        name="ansTypeId"
                        id="ansTypeId1"
                        value={"1"}
                        checked={form.ansTypeId == "1"}
                        label="เลือกได้ข้อเดียว"
                        onChange={(e) => {
                          setForm({ ...form, ansTypeId: e.target.value });
                        }}
                      />
                    </div>

                    <div className="d-flex">
                      <Form.Check
                        inline
                        type="radio"
                        name="ansTypeId"
                        id="ansTypeId2"
                        value={"2"}
                        checked={form.ansTypeId == "2"}
                        label="เลือกได้มากกว่า 1 ข้อ"
                        onChange={(e) => {
                          setForm({ ...form, ansTypeId: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-2 pl-2 ">
                <Col lg={4} md={7} sm={12} xs={12}>
                  <InputText
                    type="text"
                    as="textarea"
                    rows={2}
                    title="คำถาม"
                    idName="name"
                    value={form.name}
                    star={true}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, name: value })}
                    handleInvalid={() => removeInvalid("name")}
                    invalid={invalid.name}
                  />
                </Col>

                <Col lg={4} md={7} sm={12} xs={12}>
                  <InputText
                    type="text"
                    as="textarea"
                    rows={2}
                    title="หมายเหตุ"
                    idName=" remark "
                    value={form.remark}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, remark: value })
                    }
                  />
                </Col>

                <Col lg={4} md={7} sm={12} xs={12}>
                  <InputText
                    type="text"
                    as="textarea"
                    rows={2}
                    title="คำแนะนำลักษณะฟาร์ม"
                    idName="suggestion"
                    value={form.suggestion}
                    star={true}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, suggestion: value })
                    }
                    handleInvalid={() => removeInvalid("suggestion")}
                    invalid={invalid.suggestion}
                  />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col lg={4} xs={6} className="d-flex align-items-center">
                  <p className="bold mb-0">น้ำหนักตามชนิดโรค </p>
                </Col>
                <Col lg={4} xs={6} className="d-flex justify-content-end">
                  <button
                    className="btn btn-add text-white"
                    onClick={() => toDisease()}
                    disabled={
                      form.checkAddDisease
                        ? true
                        : false || form.modeEditTable1
                        ? true
                        : false
                    }
                  >
                    <i className="fas fa-plus mr-1"></i> เพิ่ม
                  </button>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col lg={8} xs={12} className="d-flex justify-content-end">
                  <div className="table-responsive pb-3 ">
                    <Table className="table table-striped table-bordered my-2 ">
                      <thead>
                        <tr>
                          <th scope="col">ชนิดโรค</th>
                          <th scope="col">น้ำหนัก</th>
                          <th className="text-center" scope="col">
                            จัดการ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.dataDisease && form.dataDisease.length > 0
                          ? form.dataDisease.map((item, i) => {
                              return renderTableDisease(item, i);
                            })
                          : null}
                        {form.checkAddDisease &&
                          renderAddDisease(form.dataDisease)}
                        {form.dataDisease &&
                          form.dataDisease.length == 0 &&
                          !form.checkAddDisease && (
                            <DataTableNodata colSpan={3} />
                          )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="2.คำตอบ"
            headRight={buttonAddQuestion()}
          >
            <div className="table-responsive pb-3">
              <table className="table table-striped table-bordered my-2 ">
                <thead>
                  <tr>
                    <th scope="col">ลำดับ</th>
                    <th scope="col">ชื่อ</th>
                    <th scope="col">คะแนน</th>
                    <th className="text-center" scope="col">
                      จัดการ
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {form.dataQuestion && form.dataQuestion.length > 0
                    ? form.dataQuestion.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            {" "}
                            {renderTableQuestion(item, i)}
                          </React.Fragment>
                        );
                      })
                    : null}
                  {form.checkAddQuestion &&
                    renderAddQuestion(form.dataQuestion)}
                  {form.dataQuestion &&
                    form.dataQuestion.length == 0 &&
                    !form.checkAddQuestion && <DataTableNodata colSpan={4} />}
                </tbody>
              </table>
            </div>
          </BoxCard>

          <BoxCard classNameBox="mt-3" title="สถานะ">
            <Col lg={3} md={6} sm={12} xs={12}>
              <InputSelect
                title="สถานะ"
                star={false}
                placeholder="กรุณาเลือก"
                idName="statusId"
                classLabel="normal"
                selectValue={statusSelectValue}
                optionsList={statusList}
                handleSelectValue={(value) =>
                  setStatusSelectValue([
                    { value: value.value, label: value.label },
                  ])
                }
                handleChange={(value) => {
                  setStatusSelectValue([
                    { value: value.value, label: value.label },
                  ]);
                  setForm({ ...form, statusId: value.value });
                }}
                handleInvalid={() => removeInvalid("statusId")}
                invalid={invalid.statusId}
              />
            </Col>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Risk_assessment_Item_save;
