import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import Pdf from "react-to-pdf";
import { useTranslation } from "react-i18next";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";

import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";

import BoxCard from "../../components/BoxCard";
import InputDatePicker from "../../components/InputDatePicker";
import Breadcrumb from "../../components/Breadcrumb";
import InputSelect from "../../components/InputSelect";
import DataTable from "../../components/DataTable";
import DataTableNodata from "../../components/DataTableNodata";

import pdfMake from "pdfmake-thai/build/pdfmake";
import pdfFonts from "pdfmake-thai/build/vfs_fonts";
import { useMasterData } from "../../components/hooks/useMasterData";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Farm_registration_Report() {
  const ref = React.createRef();
  const { t } = useTranslation(["common", "footer"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const initialForm = {
    startDate: "",
    endDate: "",

    id: "",
    farmName: "",
    prefixName: "",
    firstName: "",
    lastName: "",
    zoneName: "",
    provinceName: "",
    amphurName: "",
    tambonName: "",
    mooName: "",
    homeNo: "",
    updatePrefixName: "",
    updateFirstName: "",
    updateLastName: "",
    updateType: "",
    updateDate: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "homeName",
        name: "ชื่อฟาร์ม",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "firstName",
        name: "เจ้าของฟาร์ม",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        name: "ที่อยู่ฟาร์ม",
        sortFlg: false,
        colSpan: 5,
        childHead: [
          {
            sortBy: "1",
            name: "เขต",
            sortFlg: true,
          },
          {
            sortBy: "2",
            name: "จังหวัด",
            sortFlg: true,
          },
          {
            sortBy: "3",
            name: "อำเภอ",
            sortFlg: true,
          },
          {
            sortBy: "3",
            name: "ตำบล",
            sortFlg: true,
          },
          {
            sortBy: "4",
            name: "ที่อยู่",
            sortFlg: true,
          },
        ],
      },
      {
        sortBy: "typeSave",
        name: "ประเภทการบันทึก",
        rowSpan: 2,
        sortFlg: true,
      },
      {
        sortBy: "nameSave",
        name: "ผู้บันทึก",
        rowSpan: 2,
        sortFlg: true,
      },
      {
        sortBy: "date",
        name: "วัน เวลา",
        rowSpan: 2,
        sortFlg: true,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  pdfMake.fonts = {
    Roboto: {
      normal: "Roboto-Regular.ttf",
      bold: "Roboto-Medium.ttf",
      italics: "Roboto-Italic.ttf",
      bolditalics: "Roboto-MediumItalic.ttf",
    },
    THSarabunNew: {
      normal: "THSarabunNew.ttf",
      bold: "THSarabunNew-Bold.ttf",
      italics: "THSarabunNew-Italic.ttf",
      bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
  };

  const [listFarmRegistration, setListFarmRegistration] = useState([]);

  const [isClearSearch, setIsClearSearch] = useState(false);

  // select เขตปศุสัตว์
  const [zoneSelectValue, setZoneSelectValue] = useState([]);
  const [zoneList, setZoneList] = useState([]);

  // จังหวัด
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  // อำเภอ
  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  // select file type to download
  const [fileType, setFileType] = useState("excel");
  const fileName = "G99RPT01-GFMFarmReport_" + helper.getDate(new Date());

  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData("zoneLivestock", zoneList, setZoneList, provinceByZoneId);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/farm_registration", name: "ขึ้นทะเบียนฟาร์ม" },
    { path: "title", name: "รายงาน" },
    { path: "active", name: "การขึ้นทะเบียนฟาร์ม" },
  ];

  useEffect(() => {
    getFarmReportFile();
    getZoneLivestock();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setZoneSelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setDistrictList([]);
    setIsClearSearch(!isClearSearch);
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });

        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueZone = (value) => {
    setZoneSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, zoneName: value.value });
    getProvince(value.value);
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setForm({ ...form, provinceName: value.value });
    getDistricts(value.value);
  };

  const getFarmReportFile = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getFarmReportFile(
        form.startDate ? helper.getDate(form.startDate) : "",
        form.endDate ? helper.getDate(form.endDate) : "",
        form.zoneName,
        form.provinceName,
        form.amphurName,
        form.sortBy,
        form.sortDir,
        form.page,
        form.limit
      );
      const data = await response.data;
      if (response.status == 200) {
        setListFarmRegistration(data.content);
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getFarmToExport = async () => {
    try {
      const response = await API.getFarmReportFile(
        form.startDate ? helper.getDate(form.startDate) : "",
        form.endDate ? helper.getDate(form.endDate) : "",
        form.zoneName,
        form.provinceName,
        form.amphurName,
        form.sortBy,
        form.sortDir,
        form.page,
        form.totalElements
      );
      const data = await response.data;
      if (response.status == 200) {
        let farmReportArray = [];
        data.content.forEach((element) => {
          farmReportArray.push({
            id: element.id,
            farmName: element.farmName,
            prefixName: element.prefixName,
            firstName: element.firstName,
            lastName: element.lastName,
            zoneName: element.zoneName,
            provinceName: element.provinceName,
            amphurName: element.amphurName,
            tambonName: element.tambonName,
            mooName: element.mooName,
            homeNo: element.homeNo,
            updatePrefixName: element.updatePrefixName,
            updateFirstName: element.updateFirstName,
            updateLastName: element.updateLastName,
            updateType: element.updateType,
            updateDate: element.updateDate,
          });
        });
        downloadRecord(farmReportArray, data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const buttonSearch = () => {
    return (
      <>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          {t("clear")}
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const convertListToExcelTable = (objArray) => {
    let excelTable = [];
    objArray.map((element, index) => {
      excelTable.push({
        ลำดับ: index + 1,
        ชื่อฟาร์ม: element.farmName,
        เจ้าของฟาร์ม:
          element.prefixName + " " + element.firstName + " " + element.lastName,
        เขต: element.zoneName,
        จังหวัด: element.provinceName,
        อำเภอ: element.amphurName,
        ตำบล: element.tambonName,
        ที่อยู่: element.homeNo + " " + element.mooName,
        ประเภทการบันทึก: element.updateType,
        ผู้บันทึก:
          element.updatePrefixName ||
          element.updateFirstName ||
          element.updateLastName
            ? element.updatePrefixName +
              " " +
              element.updateFirstName +
              " " +
              element.updateLastName
            : "",
        วันเวลา:
          helper.fullDateTh(element.updateDate) +
          " " +
          helper.getTimeAll(element.createDate, "th"),
      });
    });
    return excelTable;
  };

  const convertToCSV = (objArray) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let headCol = "";
    let dataRow = "";
    let key = Object.keys(array[0]);
    key.forEach((item) => {
      if (headCol === "") {
        headCol = item;
      } else {
        if (item != "") {
          headCol += ",";
          headCol += item;
        }
      }
    });
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") {
          line += ",";
        }
        line += array[i][index];
      }
      dataRow += line + "\r\n";
    }
    return headCol + "\r\n" + dataRow;
  };

  const convertToExcel = (objArray) => {
    const workSheet = XLSX.utils.json_to_sheet(objArray);
    const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    return XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  };

  const printPDF = (fileName, dataListPDf) => {
    const head = [
      { text: "ลำดับ", style: "tableHeader", rowSpan: 2, alignment: "center" },
      {
        text: "ชื่อฟาร์ม",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "เจ้าของฟาร์ม",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "ที่อยู่ฟาร์ม",
        style: "tableHeader",
        colSpan: 5,
        alignment: "center",
      },
      {},
      {},
      {},
      {},
      {
        text: "ประเภทการบันทึก",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "ผู้บันทึก",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "วันเวลา",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
    ];
    const subHead = [
      "",
      "",
      "",
      { text: "เขต", style: "tableHeader", alignment: "center" },
      { text: "จังหวัด", style: "tableHeader", alignment: "center" },
      { text: "อำเภอ", style: "tableHeader", alignment: "center" },
      { text: "ตำบล", style: "tableHeader", alignment: "center" },
      { text: "ที่อยู่", style: "tableHeader", alignment: "center" },
      "",
      "",
      "",
    ];

    let listFarmRegisPdf = [];
    if (dataListPDf && dataListPDf.length > 0) {
      dataListPDf.map((item, i) => {
        let updatation = "";
        if (
          item.updatePrefixName ||
          item.updateFirstName ||
          item.updateLastName
        ) {
          updatation =
            item.updatePrefixName +
            " " +
            item.updateFirstName +
            " " +
            item.updateLastName;
        }
        listFarmRegisPdf.push([
          i + 1,
          item.farmName,
          item.prefixName + " " + item.firstName + " " + item.lastName,
          item.zoneName,
          item.provinceName,
          item.amphurName,
          item.tambonName,
          item.homeNo + " " + item.mooName,
          updatation,
          item.updateType,
          helper.fullDateTh(item.updateDate) +
            "" +
            helper.getTimeAll(item.updateDate, "th"),
        ]);
      });
    }
    let bodyContent = [head, subHead];
    listFarmRegisPdf.map((item, index) => {
      bodyContent.push(listFarmRegisPdf[index]);
    });
    const cellWidhtHeight = [
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
    ];
    const context = {
      content: [
        {
          table: {
            widths: cellWidhtHeight,
            heights: cellWidhtHeight,
            headerRows: 2,
            body: bodyContent,
          },
        },
      ],
      defaultStyle: {
        fontSize: 9,
        font: "THSarabunNew",
      },
      styles: {
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
        },
      },
    };
    pdfMake.createPdf(context).download(fileName + ".pdf");
  };

  const downloadRecord = (dataList, dataListPDf) => {
    let type = "";
    if (fileType === "excel") {
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = convertToExcel(convertListToExcelTable(dataList));
      const data = new Blob([excelBuffer], { type: type });
      FileSaver.saveAs(data, fileName + ".xlsx");
    }
    if (fileType === "csv") {
      type = "text/csv;charset=utf-8";
      const csv = convertToCSV(convertListToExcelTable(dataList));
      const csvData = new Blob([csv], { type: type });
      FileSaver.saveAs(csvData, fileName + ".csv");
    }
    if (fileType === "pdf") {
      printPDF(fileName, dataListPDf);
    }
  };

  const buttonExport = () => {
    return (
      <button
        className="btn btn-report"
        onClick={() => getFarmToExport()}
        disabled={user?.userGroup.mexport === "n"}
      >
        <a className="text-white">
          <img className="nav-icon" src={"/svg/report.svg"} alt="export-icon" />{" "}
          ออกรายงาน
        </a>
      </button>
    );
  };

  const getFilter = (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const checkExport = (value) => {
    if (fileType === value) {
      return true;
    } else {
      return false;
    }
  };

  const renderTableFarm = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td className="text-center">{item.farmName ? item.farmName : "-"}</td>
          <td className="text-center">
            {item.firstName
              ? item.prefixName + " " + item.firstName + " " + item.lastName
              : "-"}
          </td>
          <td className="text-center">{item.zoneName ? item.zoneName : "-"}</td>
          <td className="text-center">
            {item.provinceName ? item.provinceName : "-"}
          </td>
          <td className="text-center">
            {item.amphurName ? item.amphurName : "-"}
          </td>
          <td className="text-center">
            {item.tambonName ? item.tambonName : "-"}
          </td>
          <td className="text-center">
            {item.homeNo} {item.mooName}
          </td>
          <td className="text-center">
            {item.updateType ? item.updateType : "-"}
          </td>
          <td className="text-center">
            {item.updateFirstName
              ? item.updatePrefixName +
                " " +
                item.updateFirstName +
                " " +
                item.updateLastName
              : "-"}{" "}
          </td>
          <td className="text-center">
            {item.updateDate
              ? helper.fullDateTh(item.updateDate, "th", "long") +
                " " +
                helper.getTimeAll(item.updateDate, "th")
              : "-"}
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={12} />;
    }
  };

  return (
    <div>
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 ">
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputDatePicker
                    title="วันที่บันทึกข้อมูล"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.startDate}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, startDate: value })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.endDate}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, endDate: value })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="เขต"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="zoneName"
                    classLabel="normal"
                    selectValue={zoneSelectValue}
                    optionsList={zoneList}
                    handleChange={(value) => {
                      selectValueZone(value);
                    }}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="provinceName"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => {
                      selectValueProvince(value);
                    }}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="amphurName"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => {
                      setDistrictSelectValue(value);
                    }}
                  />
                </Col>
              </Row>

              <div className="mb-2" style={{ fontWeight: "normal" }}>
                เลือกรูปแบบ
              </div>

              <Row>
                <Col lg={1} md={12} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="excel"
                      name="data-export"
                      checked={checkExport("excel")}
                      onChange={() => setFileType("excel")}
                    />{" "}
                    EXCEL
                  </label>
                </Col>

                <Col lg={1} md={12} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="csv"
                      name="data-export"
                      checked={checkExport("csv")}
                      onChange={() => setFileType("csv")}
                    />{" "}
                    CSV
                  </label>
                </Col>

                <Col lg={1} md={12} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="pdf"
                      name="data-export"
                      checked={checkExport("pdf")}
                      onChange={() => setFileType("pdf")}
                    />{" "}
                    PDF
                  </label>
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการการฟาร์ม"
            headRight={buttonExport()}
          >
            <DataTable
              headColumns={form.headTitle}
              typeTable="colSpan"
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              reference={ref}
            >
              {renderTableFarm(listFarmRegistration)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Farm_registration_Report;
