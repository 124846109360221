import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { logout } from "../../../redux/actions/logoutAction";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import InputText from "../../../components/InputText";
import DataTableNodata from "../../../components/DataTableNodata";
import ModalOrganizes from "../../../components/Modal.Organizes";
import { useMasterData } from "../../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function UserViewSave(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);
  const { t } = useTranslation(["common", "footer"]);

  const initialForm = {
    //data to save
    name: "",
    description: "",
    statusId: "",

    orgId: "",
    orgName: "",

    chooseSelect: false,
    checkAddRightView: false,
    modeEditTable: false,
    isOnAdding: true,
    isRightViewAdding: false,
    groupRightView: [],
  };
  const [form, setForm] = useState(initialForm);

  const initialRightView = {
    zoneId: "",
    provinceId: "",
    amphurId: "",
  };
  const [formRightView, setFormRightView] = useState(initialRightView);

  const initialFormJson = {
    zone: {},
    province: {},
    amphur: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initInvalidMsg = {
    name: "",
    zoneId: "",
    provinceId: "",
    amphurId: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const [zoneSelectValue, setZoneSelectValue] = useState([]);
  const [zoneList, setZoneList] = useState([]);

  // select จังหวัด อำเภอ
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  // หน่วยงาน
  const initShowOrgModal = false;
  const [showOrgModal, setShowOrgModal] = useState(initShowOrgModal);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList] = useState(selectStatusList);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/users/user", name: "ผู้ใช้งาน" },
    { path: "active", name: "บันทึกสิทธิการดูข้อมูล" },
  ];
  const [provinceByZoneId, setProvinceByZoneId] = useState(true);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData("zoneLivestock", zoneList, setZoneList, provinceByZoneId);

  useEffect(() => {
    getZone();
    // getProvince();
    getMasterJson();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("edit") && !form.chooseSelect) {
      getUserViewById(props.match.params.id);
    }
  }, [location.pathname, form.provinceId, form.amphurId]);

  const getZone = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);

      const data = await response?.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMasterJson = async () => {
    const params = ["zone", "province", "amphur"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response?.data;
      if (response.status === 200) {
        setFormJson({
          ...formJson,
          zone: data.zone,
          province: data.province,
          amphur: data.amphur,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserViewById = async (id) => {
    let dataUserView;
    try {
      const response = await API.getUserViewById(id);
      const dataRes = await response?.data;
      if (response.status === 200) {
        if (dataRes) {
          dataUserView = dataRes;
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataUserView.statusId) {
      let statusSelect = statusList.find(
        (item) => item.value === dataUserView.statusId
      );
      setStatusSelectValue(statusSelect);
    }

    let groupRightViewArray = [];
    if (dataUserView.viewArea && dataUserView.viewArea.length > 0) {
      dataUserView.viewArea.map((item) => {
        groupRightViewArray.push({
          zoneId: item.zoneId,
          provinceId: item.provinceId,
          amphurId: item.amphurId,
        });
        return 0;
      });
    }

    setForm({
      ...form,
      name: dataUserView.name || "",
      description: dataUserView.description || "",
      statusId: dataUserView.statusId || "",
      orgId: dataUserView.orgId || "",
      orgName: dataUserView.orgName || "",
      groupRightView: groupRightViewArray || "",
    });
  };

  const setOrganizes = (data) => {
    setForm({ ...form, orgId: data.value, orgName: data.label });
  };

  const toAddRightView = () => {
    setForm({ ...form, checkAddRightView: true });
    setZoneSelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setDistrictList([]);
    setFormRightView({
      ...formRightView,
      zoneId: "",
      provinceId: "",
      amphurId: "",
    });
    setForm({
      ...form,
      checkAddRightView: true,
      isOnAdding: false,
      isRightViewAdding: true,
    });
  };

  const saveRightView = (itemRv, type) => {
    let arrayRightView = [...form.groupRightView];
    if (type === "add") {
      arrayRightView.push({
        checkEditRightView: false,
        zoneId: formRightView.zoneId,
        provinceId: formRightView.provinceId,
        amphurId: formRightView.amphurId,
      });
      setForm({
        ...form,
        groupRightView: arrayRightView,
        checkAddRightView: false,
        modeEditTable: false,
      });
    } else if (type === "edit") {
      itemRv.checkEditRightView = false;
      itemRv.zoneId = formRightView.zoneId;
      itemRv.provinceId = formRightView.provinceId;
      itemRv.amphurId = formRightView.amphurId;
      setForm({ ...form, checkAddRightView: false, modeEditTable: false });
    }
  };

  const buttonAddRightView = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddRightView()}
          disabled={form.checkAddRightView ? true : false}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const delRightView = (item, index) => {
    let delGroupRightView = [];
    form.groupRightView.splice(index, 1);
    if (item.id) {
      delGroupRightView.push(item.id);
    }
    setForm({
      ...form,
      groupRightView: form.groupRightView,
      groupRightView: delGroupRightView,
    });
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = (params) => {
    let validated = true;

    if (params === "checkAll") {
      if (form.name === "") {
        addInvalid("name", "โปรดระบุชื่อสิทธิการดูข้อมูล");
        validated = false;
      }
    }

    if (params === "checkTable" || params === "checkAll") {
      if (formRightView.zoneId === "" && form.isRightViewAdding) {
        addInvalid("zoneId", "กรุณาเลือกเขตกรมปศุสัตว์");
        validated = false;
      }
      if (formRightView.provinceId === "" && form.isRightViewAdding) {
        addInvalid("provinceId", "กรุณาเลือกจังหวัด");
        validated = false;
      }
      if (formRightView.amphurId === "" && form.isRightViewAdding) {
        addInvalid("amphurId", "กรุณาเลือกอำเภอ");
        validated = false;
      }
    }

    return validated;
  };

  const editRightView = (item) => {
    item.checkEditRightView = true;
    setForm({ ...form, checkEditRightView: true, modeEditTable: true });
    setFormRightView({
      ...formRightView,
      zoneId: item.zoneId,
      provinceId: item.provinceId,
      amphurId: item.amphurId,
    });

    setZoneSelectValue([
      { value: item.zoneId, label: formJson.zone[item.zoneId] },
    ]);
    setProvinceSelectValue([
      {
        value: item.provinceId,
        label: formJson.province[item.provinceId],
        chooseSelect: true,
      },
    ]);
    setDistrictSelectValue([
      {
        value: item.amphurId,
        label: formJson.amphur[item.amphurId],
        chooseSelect: true,
      },
    ]);
  };
  const renderRightView = (item, index) => {
    return (
      <tr key={index} className="text-center">
        <td>{index + 1}</td>
        <td>
          {item.checkEditRightView ? (
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName="zone"
              classLabel="normal"
              selectValue={zoneSelectValue}
              optionsList={zoneList}
              handleChange={(value) => {
                setZoneSelectValue(value);
                setFormRightView({ ...formRightView, zoneId: value.value });
                getProvince(value.value);
              }}
              handleInvalid={() => removeInvalid("zoneId")}
              invalid={invalid.zoneId}
            />
          ) : item.zoneId >= 0 ? (
            formJson.zone[item.zoneId]
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.checkEditRightView ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="title"
              classLabel="normal"
              selectValue={provinceSelectValue}
              optionsList={provinceList}
              handleChange={(value) => {
                selectValueProvince(value);
              }}
              handleInvalid={() => removeInvalid("provinceId")}
              invalid={invalid.provinceId}
            />
          ) : item.provinceId ? (
            formJson.province[item.provinceId]
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.checkEditRightView ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="title"
              classLabel="normal"
              selectValue={districtSelectValue}
              optionsList={districtList}
              handleChange={(value) => selectValueDistrict(value)}
              handleInvalid={() => removeInvalid("amphurId")}
              invalid={invalid.amphurId}
            />
          ) : item.amphurId ? (
            formJson.amphur[item.amphurId]
          ) : (
            "-"
          )}
        </td>
        <td className="text-center">
          {item.checkEditRightView ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                if (validate("checkTable")) {
                  saveRightView(item, "edit");
                }
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => editRightView(item)}
                disabled={form.modeEditTable}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button
                className="btn btn-del "
                onClick={() => delRightView(item, index)}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  const renderNewRightView = () => {
    return (
      <tr>
        <td className="text-center">{form.groupRightView.length + 1}</td>
        <td className="fitwidth">
          <InputSelect
            classFormGroup="mb-0 w-100"
            star={false}
            placeholder="กรุณาเลือก"
            idName="module"
            classLabel="normal"
            selectValue={zoneSelectValue}
            optionsList={zoneList}
            handleChange={(value) => {
              setZoneSelectValue(value);
              setFormRightView({ ...formRightView, zoneId: value.value });
              getProvince(value.value);
            }}
            handleInvalid={() => removeInvalid("zoneId")}
            invalid={invalid.zoneId}
          />
        </td>
        <td className="fitwidth">
          <InputSelect
            classFormGroup="mb-0 w-100"
            star={false}
            placeholder="กรุณาเลือก"
            idName="title"
            classLabel="normal"
            selectValue={provinceSelectValue}
            optionsList={provinceList}
            handleChange={(value) => {
              selectValueProvince(value);
            }}
            handleInvalid={() => removeInvalid("provinceId")}
            invalid={invalid.provinceId}
          />
        </td>
        <td className="fitwidth">
          <InputSelect
            classFormGroup="mb-0 w-100"
            star={false}
            placeholder="กรุณาเลือก"
            idName="title"
            classLabel="normal"
            selectValue={districtSelectValue}
            optionsList={districtList}
            handleChange={(value) => selectValueDistrict(value)}
            handleInvalid={() => removeInvalid("amphurId")}
            invalid={invalid.amphurId}
          />
        </td>

        <td className="text-center">
          <button
            className="btn btn-success  pt-1 px-2"
            onClick={async () => {
              if (validate("checkTable")) {
                saveRightView(formRightView, "add");
              }
            }}
          >
            <img
              className="img-fluid icon-check"
              src="/svg/btn-check.svg"
              alt="check"
            />
          </button>
        </td>
      </tr>
    );
  };

  const saveAddUserView = async () => {
    console.log("val", validate("checkAll"));
    if (validate("checkAll")) {
      let response;
      let groupRightView = form.groupRightView;
      let userViewObj = [];
      groupRightView.map((item) => {
        userViewObj.push({
          userViewId: user.profile.userViewId,
          zoneId: item.zoneId,
          provinceId: item.provinceId,
          amphurId: item.amphurId,
        });
      });
      let dataUserView = {
        name: form.name,
        description: form.description,
        statusId: form.statusId,
        orgId: form.orgId,
        viewArea: userViewObj,
      };

      MySwal.fire({
        text: `ต้องการบันทึก`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (value) => {
        if (value.isConfirmed) {
          try {
            response = location.pathname.includes("/save-user-view")
              ? await API.saveUserView(dataUserView)
              : await API.updateUserView(props.match.params.id, dataUserView);
            if (response.status === 200) {
              MySwal.fire({
                text: location.pathname.includes("/save-user-view")
                  ? "บันทึกข้อมูลผู้ใช้งานสำเร็จ"
                  : "แก้ไขข้อมูลผู้ใช้งานสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then((result) => {
                if (result.value) {
                  history.push("/data-manage/users/view-user");
                }
              });
            }
          } catch (error) {
            if (error.response.status === 401) {
              console.log(error.response);
              dispatch(logout({ history }));
            }
          }
        }
      });
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setFormRightView({
      ...formRightView,
      provinceId: value.value,
      amphurId: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setFormRightView({
      ...formRightView,
      amphurId: value.value,
      chooseSelect: true,
    });
  };

  return (
    <div className=" ">
      <div className="pl-lg-4 pl-md-4 pl-sm-2 bg-white d-flex flex-wrap align-items-center ml30">
        <div className="mr-auto p-2">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div>
          <button
            className="btn px-4 btn-sm btn-danger mr-2"
            onClick={() => history.push("/data-manage/users/view-user")}
          >
            ยกเลิก
          </button>
          <button
            className="btn px-4 btn-sm btn-success"
            onClick={() => saveAddUserView()}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`data-manage/users/view-user/edit`)
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard classNameBox="" title="1. ข้อมูลทั่วไป">
            <Row>
              <Col lg={2} md={2} sm={6} xs={6}>
                <InputText
                  title="ชื่อสิทธิการดูข้อมูล"
                  star={true}
                  placeholder="โปรดระบุ"
                  idName="userview"
                  classLabel="normal"
                  value={form.name}
                  handleChange={(value) => setForm({ ...form, name: value })}
                  handleInvalid={() => removeInvalid("name")}
                  invalid={invalid.name}
                />
              </Col>

              <Col lg={2} md={2} sm={6} xs={6}>
                <label className="mb-0 normal">หน่วยงาน </label>
                <div className="d-flex align-items-center">
                  {form.orgName ? (
                    <>
                      <button
                        className="btn btn-primary pt-2 px-3"
                        onClick={() => setShowOrgModal(true)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <div className="ml-2">{form.orgName}</div>
                    </>
                  ) : (
                    <button
                      className="btn btn-primary p-2 d-flex align-items-center "
                      onClick={() => setShowOrgModal(true)}
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                      <p className="mb-0 ml-2">{t("search")}</p>
                    </button>
                  )}
                </div>
              </Col>

              <Col lg={8} md={2} sm={6} xs={6}>
                <InputText
                  title="รายละเอียด"
                  star={false}
                  placeholder="โปรดระบุ"
                  idName="userview"
                  classLabel="normal"
                  value={form.description}
                  handleChange={(value) =>
                    setForm({ ...form, description: value })
                  }
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            classNameBox="mt-4 my-4"
            title="2. สิทธิการดูข้อมูลตามจังหวัด"
            headRight={buttonAddRightView()}
          >
            <div className="">
              <table className="table table-striped sameWidth table-bordered my-2 ">
                <thead>
                  <tr className="text-center">
                    <th scope="col">ลำดับ</th>
                    <th scope="col">เขตกรมปศุสัตว์</th>
                    <th scope="col">จังหวัด</th>
                    <th scope="col">อำเภอ/เขต</th>
                    <th scope="col">จัดการ</th>
                  </tr>
                </thead>
                <tbody>
                  {form.groupRightView && form.groupRightView.length > 0
                    ? form.groupRightView.map((item, i) => {
                        return renderRightView(item, i);
                      })
                    : ""}
                  {form.checkAddRightView && renderNewRightView()}
                  {form.groupRightView &&
                    form.groupRightView.length === 0 &&
                    !form.checkAddRightView && <DataTableNodata colSpan={5} />}
                </tbody>
              </table>
            </div>
          </BoxCard>

          <div className="pl-lg-4 pl-md-4 pl-sm-2 bg-white border-card">
            <Row className="mt-3 mb-3">
              <Col lg={3} md={6} sm={6} xs={6}>
                <InputSelect
                  title="สถานะ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleChange={(value) => {
                    setForm({ ...form, statusId: value.value });
                    setStatusSelectValue(value);
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {showOrgModal && (
        <ModalOrganizes
          show={showOrgModal}
          close={() => setShowOrgModal(false)}
          toSaveOrg={(data) => setOrganizes(data)}
        />
      )}
    </div>
  );
}

export default UserViewSave;
