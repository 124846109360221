import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "../../components/DataTable";
import DataTableNodata from "../../components/DataTableNodata";
import ModalOrganizes from "../../components/Modal.Organizes";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import ModalFarmSearch from "../../components/Modal.FarmSearch";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  useMasterData,
  useOptionsListYears,
  useCurrentYear,
} from "../../components/hooks/useMasterData";
import ButtonReportLink from "../../components/ButtonReportLink";

const MySwal = withReactContent(Swal);

function Outbreak() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t, i18n } = useTranslation(["common", "footer"]);
  const statusDisease = useSelector((state) => state.statusDisease);

  const initialForm = {
    codeOb: "",

    orgId: "",
    outbreakId: "",
    farmId: "",
    name: "",
    dateF: "",
    to_dateF: "",
    dateR: "",
    to_dateR: "",
    zoneId: "",
    status: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    diseaseTypeId: "",
    foundDiseaseTypeId: "",
    animalId: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        name: "ลำดับ",
        sortFlg: false,
        rowSpan: 2,
      },
      {
        sortBy: "obCode",
        name: "Outbreak",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "diseaseName",
        name: "ชนิดโรค",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "farmerName",
        name: "ชื่อเจ้าของฟาร์มแรกที่ระบาด",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "farmAmt",
        name: "จำนวนฟาร์ม",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        name: "มาตรการควบคุมโรค",
        sortFlg: false,
        colSpan: 2,
        childHead: [
          {
            sortBy: "1",
            name: "ควบคุมการเคลื่อนย้าย",
            sortFlg: false,
          },
          {
            sortBy: "2",
            name: "เฝ้าระวังและเตือนภัย",
            sortFlg: false,
          },
        ],
      },
      {
        name: "การดำเนินการ",
        sortFlg: false,
        colSpan: 2,
        childHead: [
          {
            sortBy: "3",
            name: "ภาวะการณ์และควบคุมโรค",
            sortFlg: true,
          },
          {
            sortBy: "4",
            name: "การฉีดวัคซีน",
            sortFlg: true,
          },
        ],
      },
      {
        sortBy: "annouceTypeName",
        name: "พื้นที่ประกาศเขตโรคระบาดล่าสุด",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "foundDiseaseDate",
        name: "วันที่พบโรค",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "statusId",
        name: "สถานะโรคระบาด",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "updateDate",
        name: "ปรับปรุงข้อมูลล่าสุด",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        name: "จัดการ",
        sortFlg: false,
        rowSpan: 2,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [showOrgModal, setShowOrgModal] = useState(false);

  // select จังหวัด ตำบล อำเภอ
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  // =================

  // select  หน่วยงานที่แจ้งโรค สถานะการดำเนินการ เขตปศุสัตว์ ชื่อโรคที่สงสัย ชื่อโรคที่พบ ชนิดสัตว์
  const initAgencySelectValue = [];
  const [agencySelectValue, setAgencySelectValue] = useState(
    initAgencySelectValue
  );
  const selectAgencyList = [];
  const [agencyList, setAgencyList] = useState(selectAgencyList);

  const [listOutbreak, setListOutbreak] = useState([]);

  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    {
      value: 1,
      label: "รับแจ้ง",
    },
    {
      value: 2,
      label: "กำลังดำเนินการ",
    },
    {
      value: 3,
      label: "ไม่พบโรค",
    },
    {
      value: 3,
      label: "โรคสงบ",
    },
  ];
  const [StatusList, setStatusList] = useState(selectStatusList);

  const initZoneLivestockSelectValue = [];
  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState(
    initZoneLivestockSelectValue
  );
  const selectZoneLivestockList = [];
  const [zoneLivestockList, setZoneLivestockList] = useState(
    selectZoneLivestockList
  );

  const initDiseaseSuspectedSelectValue = [];
  const [diseaseSuspectedSelectValue, setDiseaseSuspectedSelectValue] =
    useState(initDiseaseSuspectedSelectValue);
  const selectDiseaseSuspectedList = [];
  const [diseaseSuspectedList, setDiseaseSuspectedList] = useState(
    selectDiseaseSuspectedList
  );

  const initDiseaseFoundSelectValue = [];
  const [diseaseFoundSelectValue, setDiseaseFoundSelectValue] = useState(
    initDiseaseFoundSelectValue
  );
  const selectDiseaseFoundList = [];
  const [diseaseFoundList, setDiseaseFoundList] = useState(
    selectDiseaseFoundList
  );

  const initAnimalSelectValue = [];
  const [animalSelectValue, setAnimalSelectValue] = useState(
    initAnimalSelectValue
  );
  const selectAnimalList = [];
  const [animalList, setAnimalList] = useState(selectAnimalList);
  // =================
  const [isClearSearch, setIsClearSearch] = useState(false);
  const [showFarmSearch, setShowFarmSearch] = useState(false);

  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );

  const optionsListYear = useOptionsListYears();
  const currentYearValue = useCurrentYear();
  const [yearFilterList, setYearFilterList] = useState(currentYearValue);

  useEffect(() => {
    // getProvince();
    getZoneLivestock();
    getDiseaseFound();
    getAnimal();
  }, []);

  useEffect(() => {
    getOutbreak();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getOutbreak = async () => {
    const params = await {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",

      orgId: form.orgId ? form.orgId : "",
      obCode: form.outbreakId ? form.outbreakId : "",
      farmId: form.farmId ? form.farmId : "",
      ownerName: form.name ? form.name : "",
      foundDateBegin: form.dateF ? helper.getDate(form.dateF) : "",
      foundDateEnd: form.to_dateF ? helper.getDate(form.to_dateF) : "",
      createDateBegin: form.dateR ? helper.getDate(form.dateR) : "",
      createDateEnd: form.to_dateR ? helper.getDate(form.to_dateR) : "",
      zoneId: form.zoneId ? form.zoneId : "",
      statusId: form.status ? form.status : "",
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
      diseaseTypeId: form.diseaseTypeId ? form.diseaseTypeId : "",
      foundDiseaseTypeId: form.foundDiseaseTypeId
        ? form.foundDiseaseTypeId
        : "",
      animalTypeId: form.animalId ? form.animalId : "",
      onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
    };

    dispatch(loading(true));
    try {
      const response = await API.getOutbreak(params);
      const data = await response.data;
      // console.log("params---getOutbreak--🐳🐳", params, response);

      if (response.status == 200) {
        dispatch(loading(false));
        await setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        await setListOutbreak(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseFound = async () => {
    try {
      const response = await API.getDiseaseType("", "", 1, 100, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseaseFoundList(diseaseArray);
        setDiseaseSuspectedList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, districtId: value.value });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value });
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "title", name: "Outbreak" },
    { path: "active", name: "รายการ Outbreak" },
  ];

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setAgencySelectValue([]);
    setAnimalSelectValue([]);
    setDiseaseSuspectedSelectValue([]);
    setZoneLivestockSelectValue([]);
    setStatusSelectValue([]);
    setDiseaseFoundSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const setOrganizes = (data) => {
    setForm({ ...form, orgId: data.value, orgName: data.label });
  };

  const buttonSearch = () => {
    return (
      <>
        <ButtonReportLink path={"outbreak"} linkSize={6} addIndex params={{
          orgId: form.orgId ? form.orgId : "",
          obCode: form.outbreakId ? form.outbreakId : "",
          farmId: form.farmId ? form.farmId : "",
          ownerName: form.name ? form.name : "",
          foundDateBegin: form.dateF ? helper.getDate(form.dateF) : "",
          foundDateEnd: form.to_dateF ? helper.getDate(form.to_dateF) : "",
          createDateBegin: form.dateR ? helper.getDate(form.dateR) : "",
          createDateEnd: form.to_dateR ? helper.getDate(form.to_dateR) : "",
          zoneId: form.zoneId ? form.zoneId : "",
          statusId: form.status ? form.status : "",
          provinceId: form.provinceId ? form.provinceId : "",
          amphurId: form.districtId ? form.districtId : "",
          tambonId: form.subDistrictId ? form.subDistrictId : "",
          diseaseTypeId: form.diseaseTypeId ? form.diseaseTypeId : "",
          foundDiseaseTypeId: form.foundDiseaseTypeId
            ? form.foundDiseaseTypeId
            : "",
          animalTypeId: form.animalId ? form.animalId : "",
          onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
    }}/>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const buttonAdd = () => {
    return (
      <button
        className="btn btn-info text-white"
        disabled={user?.userGroup.mcreate === "n"}
        onClick={() => history.push(`/passive_surv/outbreak/save`)}
      >
        <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
      </button>
    );
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusDisease && statusDisease.length > 0) {
      statusDisease.map((item, index) => {
        if (item.idOb === idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color, color: item.fontColor }}
                className="box-status font14 px-3 text-center"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getFilter = async (type, isChange1, isChange2) => {
    // console.log("getFilter", type, isChange1, isChange2);
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type == "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type == "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const viewOutbreak = (id) => {
    history.push(`/passive_surv/outbreak/edit/${id}`);
  };

  const deleteOutbreak = (id) => {
    MySwal.fire({
      text: `ยืนยันการลบข้อมูล`,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteOutbreak(id);
          if (response.status === 200) {
            await getOutbreak();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const textTab6 = (text) => {
    return (
      <>
        <span>
          ต้องการเพิ่ม <br />“{text}”<br />
          ตอนนี้หรือไม่?
        </span>
        <br />
        <br />
        <span
          style={{
            display: "flex",
            textAlign: "left",
            fontSize: "10px",
            color: "#979797",
          }}
        >
          หรือเพิ่มภายหลัง ดังนี้ <br />
          1. ไปที่ “เพิ่มข้อมูล” <br />
          2. ไปที่ tab “6.การดำเนินการราย Outbreak”
        </span>
      </>
    );
  };

  const gotoSaveTab6 = (type, text, obId) => {
    MySwal.fire({
      html: textTab6(text),
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
      width: "320px",
    }).then(async (result) => {
      if (result.value) {
        history.push(`/passive_surv/outbreak/edit/${obId}?tab=pills-outbreak`);
      }
    });
  };

  const linkToPage = (url) => {
    history.push(url);
  };

  const duplicate = (id) => {
    MySwal.fire({
      text: `ยืนยันการคัดลอกข้อมูล`,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        history.push(`/passive_surv/outbreak/duplicate/${id}`);
      }
    });
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td className="text-center">
            <>
              <span className="bold">{item.obCode ? item.obCode : "-"}</span>
              {item.asId && (
                <Link
                  to={`/active_surv/action_plan`}
                  className="background-white mb-2"
                  style={{ borderBottom: "1px solid" }}
                >
                  <span> (เชิงรุก)</span>
                </Link>
              )}
              {item.informId && (
                <Link
                  to={`/passive_surv/disease_report`}
                  className="background-white mb-2"
                  style={{ borderBottom: "1px solid" }}
                >
                  <span> (แจ้งโรค)</span>
                </Link>
              )}
            </>
          </td>
          <td>
            {item?.foundDiseaseName ? (
              <>
                <span>{`สงสัยโรค: ${item?.foundDiseaseName}`}</span> <br />
                <span
                  style={{ color: "red" }}
                >{`พบโรค: ${item?.diseaseName}`}</span>
              </>
            ) : (
              "-"
            )}
          </td>
          <td>{item?.farmerName || "-"}</td>
          <td className="text-center">
            {item?.farmAmt ? (
              <>
                <div
                  className="cursor-p"
                  onClick={() =>
                    linkToPage(
                      `/passive_surv/disease_statement?obCode=${item.obCode}`
                    )
                  }
                >
                  <h6 className="link-to">{item?.farmAmt} ฟาร์ม</h6>
                </div>

                <button
                  className="btn btn-add btn-sm text-white"
                  disabled={user?.userGroup.mcreate === "n"}
                  onClick={() => {
                    setShowFarmSearch(true);
                    setForm({ ...form, codeOb: item.obCode });
                  }}
                >
                  <i className="fas fa-plus mr-2 "></i> เพิ่ม
                </button>
              </>
            ) : (
              "-"
            )}
          </td>
          <td className="text-center">
            {item?.movement ? (
              <div
                style={{
                  background: "#25B881",
                  color: "#FFF",
                  borderRadius: "4px",
                  height: "24px",
                  padding: "2px",
                }}
                className="box-status font14 px-2 text-center"
              >
                <span className="mt-1">ดำเนินการแล้ว</span>
              </div>
            ) : (
              <button
                className="btn btn-add text-white btn-sm"
                disabled={user?.userGroup.mcreate === "n"}
                onClick={() =>
                  gotoSaveTab6("movement", "การควบคุมการเคลื่อนย้าย", item.id)
                }
              >
                <i className="fas fa-plus mr-2 "></i> เพิ่ม
              </button>
            )}
          </td>
          <td className="text-center">
            {item?.warning ? (
              <div
                style={{
                  background: "#25B881",
                  color: "#FFF",
                  borderRadius: "4px",
                  height: "24px",
                  padding: "2px",
                }}
                className="box-status font14 px-2 text-center"
              >
                <span className="mt-1">ดำเนินการแล้ว</span>
              </div>
            ) : (
              <button
                className="btn btn-add btn-sm text-white"
                disabled={user?.userGroup.mcreate === "n"}
                onClick={() =>
                  gotoSaveTab6("warning", "เฝ้าระวังและเตือนภัย", item.id)
                }
              >
                <i className="fas fa-plus mr-2 "></i> เพิ่ม
              </button>
            )}
          </td>
          <td className="text-center">
            {item?.diseaseControl ? (
              <div className="d-flex justify-content-center">
                <div
                  className="cursor-p"
                  onClick={() =>
                    linkToPage(
                      `/passive_surv/disease_control?obCode=${item.obCode}`
                    )
                  }
                >
                  <h5 className="link-to">{item?.diseaseControl}</h5>
                </div>
                &nbsp;{` รายการ`}
              </div>
            ) : (
              <button
                className="btn btn-add btn-sm text-white"
                disabled={user?.userGroup.mcreate === "n"}
                onClick={() =>
                  history.push(`/passive_surv/disease_control/save/${item.id}`)
                }
              >
                <i className="fas fa-plus mr-2 "></i> เพิ่ม
              </button>
            )}
          </td>
          <td className="text-center">
            {item?.vaccine ? (
              <div className="d-flex justify-content-center">
                <div
                  className="cursor-p"
                  onClick={() =>
                    linkToPage(`/passive_surv/vaccinate?obCode=${item.obCode}`)
                  }
                >
                  <h5 className="link-to">{item?.vaccine}</h5>
                </div>
                &nbsp; {` รายการ`}
              </div>
            ) : (
              <button
                className="btn btn-add btn-sm text-white"
                disabled={user?.userGroup.mcreate === "n"}
                onClick={() =>
                  history.push(`/passive_surv/vaccinate/save/${item.id}`)
                }
              >
                <i className="fas fa-plus mr-2 "></i> เพิ่ม
              </button>
            )}
          </td>
          <td>
            {item?.annouceTypeName ? (
              <>
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: 600,
                  }}
                >
                  {item?.annouceTypeName || "-"}{" "}
                </span>{" "}
                <br />
                {item?.annouceName || "-"}
                <br />
                {item?.annouceName ? `(${item?.annoucePosition})` : "-"}
                <br />
                {item?.annouceName
                  ? `วันที่เริ่ม:  ${helper.momentDate(
                      item.annouceStartDate,
                      "th",
                      "short"
                    )}`
                  : "-"}
                <br />
                {item?.annouceName
                  ? `วันที่สิ้นสุด:  ${helper.momentDate(
                      item.annouceStopDate,
                      "th",
                      "short"
                    )}`
                  : "-"}
              </>
            ) : (
              <button
                className="btn btn-sm btn-add text-white"
                disabled={user?.userGroup.mcreate === "n"}
                onClick={() =>
                  history.push(`/passive_surv/epidemic_zone/save/${item.id}`)
                }
              >
                <i className="fas fa-plus mr-2 "></i> เพิ่ม
              </button>
            )}
          </td>
          <td>
            {item.annouceStartDate ? (
              <>
                {helper.momentDate(item.annouceStartDate, "th", "short")} <br />
                {helper.getTimeHAndM(item.annouceStartDate, "th")}
              </>
            ) : (
              "-"
            )}
          </td>
          <td>{getStatusColor(item.statusId)}</td>
          <td>
            {item.updateName ? item.updateName : "-"}
            <br />
            <span className="font12">
              {`${helper.momentDate(
                item.updateDate,
                "th",
                "short"
              )}  ${helper.getTimeHAndM(item.updateDate, "th")}`}
            </span>
          </td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewOutbreak(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => deleteOutbreak(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
              <button
                className="btn btn-copy"
                onClick={() => duplicate(item.id)}
                disabled={user?.userGroup.mcopy === "n"}
              >
                <img
                  className=" icon-btn "
                  src="/svg/btn-copy.svg"
                  alt="copy"
                />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="14" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ปี"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="year"
                    classLabel="normal"
                    selectValue={yearFilterList}
                    optionsList={optionsListYear}
                    handleChange={(value) => setYearFilterList(value)}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <label className="mb-0 normal">หน่วยงานที่รายงานโรค </label>
                  <div className="d-flex align-items-center">
                    {form.orgName ? (
                      <>
                        <button
                          className="btn btn-primary pt-2 px-3"
                          onClick={() => setShowOrgModal(true)}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="ml-2">{form.orgName}</div>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary p-2 d-flex align-items-center"
                        onClick={() => setShowOrgModal(true)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        <p className="mb-0 ml-2">{t("search")}</p>
                      </button>
                    )}
                  </div>
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เลข Outbreak ID"
                    idName="outbreakId"
                    value={form.outbreakId}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, outbreakId: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="รหัสฟาร์ม"
                    idName="farmId"
                    value={form.farmId}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, farmId: value })
                    }
                  />
                </Col>

                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เจ้าของสัตว์"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="ชื่อ/นามสุกล/บัตรประชาชน/เบอร์โทรศัพท์"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่พบโรค"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateF}
                    star={false}
                    handleChange={(value) => setForm({ ...form, dateF: value })}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.to_dateF}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, to_dateF: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่รายงานโรค"
                    classLabel="normal"
                    star={false}
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateR}
                    handleChange={(value) => setForm({ ...form, dateR: value })}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.to_dateR}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, to_dateR: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขตปศุสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={zoneLivestockSelectValue}
                    optionsList={zoneLivestockList}
                    handleChange={(value) => {
                      setZoneLivestockSelectValue([value]);
                      setForm({ ...form, zoneId: value.value });
                      getProvince(value.value);
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะการดำเนินการ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={StatusList}
                    handleChange={(value) => {
                      setStatusSelectValue([value]);
                      setForm({ ...form, status: value.value });
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชื่อโรคที่สงสัย"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={diseaseSuspectedSelectValue}
                    optionsList={diseaseSuspectedList}
                    handleChange={(value) => {
                      setDiseaseSuspectedSelectValue([value]);
                      setForm({ ...form, diseaseTypeId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชื่อโรคที่พบ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={diseaseFoundSelectValue}
                    optionsList={diseaseFoundList}
                    handleChange={(value) => {
                      setDiseaseFoundSelectValue([value]);
                      setForm({ ...form, foundDiseaseTypeId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={animalSelectValue}
                    optionsList={animalList}
                    handleChange={(value) => {
                      setAnimalSelectValue([value]);
                      setForm({ ...form, animalId: value.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการ Outbreak"
            headRight={buttonAdd()}

          >
            <DataTable
              headColumns={form.headTitle}
              typeTable="colSpan"
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTable(listOutbreak)}
            </DataTable>
          </BoxCard>
        </div>
      </div>

      {showOrgModal && (
        <ModalOrganizes
          show={showOrgModal}
          close={() => setShowOrgModal(false)}
          toSaveOrg={(data) => setOrganizes(data)}
        />
      )}

      {showFarmSearch && (
        <ModalFarmSearch
          show={showFarmSearch}
          mode={"formOutbreak"}
          obCode={form.codeOb}
          close={() => setShowFarmSearch(false)}
          save={(value) => setForm({ ...form, farmId: value })}
        />
      )}
    </div>
  );
}

export default Outbreak;
