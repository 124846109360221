import { all, fork } from "redux-saga/effects";
import watchLoginSaga from "./loginSaga";
import watchLogoutSaga from "./logoutSaga";
import watchDiseaseNotifySaga from "./diseaseNotifySaga";
import watchMasterDataSaga from "./masterDataSaga";

export default function* rootSaga() {
  yield all([
    fork(watchLoginSaga),
    fork(watchLogoutSaga),
    fork(watchDiseaseNotifySaga),
    fork(watchMasterDataSaga),
  ]);
}
