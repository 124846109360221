import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import * as helper from "../utils/helper";
import * as API from "../utils/apis";

import { useHistory } from "react-router-dom";

import { logout } from "../redux/actions/logoutAction";
import { loading } from "../redux/actions/loadingAction";

import BoxCard from "./BoxCard";
import DataTableNodata from "./DataTableNodata";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

function OutbreakStep3({ caseCode, obCode }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const initialForm = {
    limit: 100,
    page: 1,
  };
  const [form, setForm] = useState(initialForm);
  const [listSample, setListSample] = useState([]);

  useEffect(async () => {
    if (obCode) {
      getSample();
    }
  }, [obCode]);

  const getSample = async () => {
    const params = await {
      sortBy: "id",
      sortDir: "DESC",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",
      caseCode: caseCode ? caseCode : "",
    };

    try {
      const response = await API.getSampleOutbreak(params);
      const data = await response.data;
      if (response?.status === 200) {
        await setListSample(data.content || []);
      }
    } catch (error) {
      if (error?.response && error?.response?.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const toAddTest = () => {
    history.push("/passive_surv/keep-example/save");
  };

  const buttonAddTest = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button className="btn btn-add text-white" onClick={() => toAddTest()}>
          <i className="fas fa-plus mr-1 "></i>เพิ่มการทดสอบโรค
        </button>
      </div>
    );
  };

  const colorStatus = (status) => {
    let color = "";
    switch (status) {
      case "พบโรค":
        color = "#FF482F";
        break;
      case "รอผลการตรวจ":
        color = "#C8CED3";
        break;
      case "ไม่พบโรค":
        color = "#25B881";
        break;
      default:
        color = "#25B881";
        break;
    }
    return color;
  };

  const deleteSample = (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบรายการการทดสอบโรค และผลการตรวจทางห้องปฏิบัติการนี้`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        dispatch(loading(true));
        try {
          const response = await API.deleteSampleOutbreak(id);
          if (response?.status === 200) {
            await getSample();
          }
          dispatch(loading(false));
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const viewDetails = (id) => {
    history.push(`/passive_surv/keep-example/edit/${id}`);
  };

  const renderTableKeepTest = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i}>
          <td className="text-center">{i + 1}</td>
          <td className="text-center">
            {item.keepSampleDate
              ? helper.momentDate(item.keepSampleDate, "th", "short")
              : "-"}
          </td>
          <td className="text-center">{item.labName || "-"}</td>
          <td className="text-center">
            {item.sendSampleDate
              ? helper.momentDate(item.sendSampleDate, "th", "short")
              : "-"}
          </td>
          <td className="text-center">
            <div className="d-flex justify-content-center">
              <div
                style={{ background: colorStatus(item.statusName) }}
                className="box-status text-white font14 px-2 text-center"
              >
                <span>{item.statusName}</span>
              </div>
            </div>
          </td>
          <td className="text-center">{item.diseaseName || "-"}</td>
          <td className="text-center">
            {item.updateDate
              ? helper.momentDate(item.updateDate, "th", "short")
              : "-"}
            <br />
            {item.updateName ? `(${item.updateName})` : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center ">
              <button className="btn btn-add mr-2 ">
                <img
                  className="icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                  onClick={() => viewDetails(item.id)}
                />
              </button>
              <button
                className="btn btn-del"
                onClick={() => deleteSample(item.id)}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={8} />;
    }
  };

  return (
    <div className=" bg-white p-4">
      <BoxCard
        title="รายการการทดสอบโรค (เก็บตัวอย่าง) และผลการตรวจทางห้องปฏิบัติการ"
        classNameBox=""
        headRight={buttonAddTest()}
      >
        <div className="table-responsive">
          <table className="table table-striped table-bordered my-2 ">
            <thead>
              <tr>
                <th className="text-center" scope="col">
                  ลำดับ
                </th>
                <th className="text-center" scope="col">
                  วันที่เก็บตัวอย่าง
                </th>
                <th className="text-center" scope="col">
                  สถานที่ส่งตัวอย่าง
                </th>
                <th className="text-center" scope="col">
                  วันที่ส่งตัวอย่าง
                </th>
                <th className="text-center" scope="col">
                  ผลการทดสอบโรค
                </th>
                <th className="text-center" scope="col">
                  ชนิดโรคที่พบ
                </th>
                <th className="text-center" scope="col">
                  ผู้บันทึกผล
                </th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>{renderTableKeepTest(listSample)}</tbody>
          </table>
        </div>
      </BoxCard>
    </div>
  );
}

export default OutbreakStep3;
