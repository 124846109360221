import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import { useHistory } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTableNodata from "../../../components/DataTableNodata";
import DataTable from "../../../components/DataTable";
import ModalVaccineType from "../../../components/Data_Management/Modal.VaccineType";

const MySwal = withReactContent(Swal);

function Vaccine_Type() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const statusCheck = useSelector((state) => state.statusCheck);
  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/master-data/animal-type", name: "Master Data" },
    { path: "active", name: "ชนิดวัคซีน" },
  ];

  const initialForm = {
    animalId: "",
    vaccineId: "",
    diseaseId: "",
    status: "",

    idEdit: "",
    mode: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        name: "ลำดับ",
        sortFlg: false,
        width: "50px",
      },
      {
        sortBy: "name",
        name: "ชนิดวัคซีน",
        sortFlg: true,
      },
      {
        sortBy: "animalTypeId",
        name: "ชนิดสัตว์",
        sortFlg: true,
      },
      {
        sortBy: "diseaseTypeId",
        name: "ชนิดโรค",
        sortFlg: true,
      },
      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },

      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const initialFormJson = {
    animal: {},
    disease: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const [listVaccine, setListVaccine] = useState([]);

  const initShowVaccine = false;
  const [showVaccine, setShowVaccine] = useState(initShowVaccine);

  const [animalIdSelectValue, setAnimalIdSelectValue] = useState([]);
  const [animalIdList, setAnimalList] = useState([]);

  const [diagnosisSelectValue, setDiagnosisSelectValue] = useState([]);
  const [diagnosisList, setDiagnosisList] = useState([]);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);
  const [isClearSearch, setIsClearSearch] = useState(false);

  useEffect(() => {
    getAnimal();
    getDiseaseType();
    getMasterJson();
  }, []);

  useEffect(() => {
    getVaccineType();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal();
      const data = await response?.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType();
      const data = await response?.data;
      if (response.status === 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiagnosisList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVaccineType = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getVaccineType(
        form.animalId,
        form.diseaseId,
        form.vaccineId,
        form.status,
        form.page,
        form.limit,
        "",
        form.sortBy,
        form.sortDir
      );
      const data = await response?.data;
      if (response.status === 200) {
        let vaccineArray = [];
        data.content.forEach((element) => {
          vaccineArray.push({
            value: element.id,
            label: element.name,
          });
        });

        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListVaccine(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getMasterJson = async () => {
    const params = ["animalType", "diseaseType"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status === 200) {
        setFormJson({
          ...formJson,
          animal: data.animalType,
          disease: data.diseaseType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusCheck && statusCheck.length > 0) {
      statusCheck.map((item) => {
        if (item.id === idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-4 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const buttonAddVaccine = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddVaccine()}
          disabled={user?.userGroup.mcreate === "n"}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มชนิดวัคซีน
        </button>
      </div>
    );
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setAnimalIdSelectValue([]);
    setDiagnosisSelectValue([]);
    setStatusSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const toAddVaccine = () => {
    setForm({ ...form, mode: "add", idEdit: "" });
    setShowVaccine(true);
  };

  const editModalVaccine = (id) => {
    setForm({ ...form, mode: "edit", idEdit: id });
    setShowVaccine(true);
  };

  const delVaccine = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบ `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteVaccineType(id);
          if (response.status === 200) {
            MySwal.fire({
              text: `ลบสำเร็จ `,
              icon: "success",
              confirmButtonText: "ตกลง",
            });
            await getVaccineType();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const addSaveVaccine = (data) => {
    const dataVaccine = {
      name: data.vaccine.vaccine_name,
      animalTypeId: data.vaccine.animalId,
      diseaseTypeId: data.vaccine.diseaseId,
      statusId: data.vaccine.status,
    };

    MySwal.fire({
      text: `ต้องการบันทึก `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response =
            data.mode === "add"
              ? await API.saveVaccineType(dataVaccine)
              : await API.updateVaccineType(
                  data.vaccine.vaccine_id,
                  dataVaccine
                );
          if (response.status === 200) {
            await getVaccineType();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTableVaccine = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td>{item.name}</td>
          <td>{formJson.animal[item.animalTypeId]}</td>
          <td>{formJson.disease[item.diseaseTypeId]}</td>
          <td className="text-center">
            {item.statusId ? getStatusColor(item.statusId) : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editModalVaccine(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delVaccine(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={6} />;
    }
  };

  const selectValueAnimal = (value) => {
    setAnimalIdSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, animalId: value.value, chooseSelect: true });
    setDiagnosisSelectValue([]);
    getDiseaseType(value.value);
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={3} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ชนิดสัตว์"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={animalIdSelectValue}
                  optionsList={animalIdList}
                  handleChange={(value) => {
                    selectValueAnimal(value);
                    setForm({ ...form, animalId: value.value });
                  }}
                />
              </Col>

              <Col lg={3} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ชนิดโรค"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={diagnosisSelectValue}
                  optionsList={diagnosisList}
                  handleChange={(value) => {
                    setDiagnosisSelectValue(value);
                    setForm({ ...form, diseaseId: value.value });
                  }}
                />
              </Col>

              <Col lg={3} md={6} sm={12} xs={12}>
                <InputSelect
                  title="สถานะ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleChange={(value) => {
                    setStatusSelectValue(value);
                    setForm({ ...form, status: value.value });
                  }}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="รายการข้อมูลชนิดวัคซีน"
            classNameBox="mt-4"
            headRight={buttonAddVaccine()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTableVaccine(listVaccine)}
            </DataTable>
          </BoxCard>
          {showVaccine && (
            <ModalVaccineType
              show={showVaccine}
              close={() => setShowVaccine(false)}
              save={(value) => addSaveVaccine(value)}
              mode={form.mode}
              idEdit={form.idEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Vaccine_Type;
