import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  WidthType,
  AlignmentType,
  TextRun,
  ImageRun,
  BorderStyle,
} from "docx";
import { saveAs, readFileSync } from "file-saver";
import BoxCard from "../components/BoxCard";
import Report_Daily_Info from "./Report_Daily_Info";
import * as helper from "../utils/helper";
import moment from "moment";
import { toBlob } from "html-to-image";
import { useDispatch } from "react-redux";
import { loading } from "../redux/actions/loadingAction";

function Report_Daily_Doc(dataAll) {
  const dispatch = useDispatch();
  const initialForm = {
    date_from: "",
    date_to: "",
    diseaseTypeId: "",
    unknownAmt: "",
    plusAmt: "",
    minusAmt: "",
    percent: "",
  };
  const [form, setForm] = useState(initialForm);
  const graphRef = useRef(null);
  const [dataFarmDaily, setDataFarmDaily] = useState([]);
  const [animalTypeDaily, setAnimalTypeDaily] = useState([]);
  const [provinceAlert, setProvinceAlert] = useState([]);
  const [resultDaily, setResultDaily] = useState([]);
  const [graph, setGraph] = useState([]);
  const [imgLogoBuffer, setImgLogoBuffer] = useState([]);
  useEffect(() => {
    setData();
  }, [dataAll]);

  const fileName = "B99RPT08-DailyReport_" + helper.getDate(new Date());
  const monthsFull = helper.monthsFull;

  const getGraphImageBuffer = async () => {
    dispatch(loading(true));
    await toBlob(document.getElementById("img-graph")).then(function (blob) {
      blob.arrayBuffer().then((buffer) => {
        if (buffer.byteLength > 0) {
          getLogoImageBuffer(buffer);
        }
      });
    });
  };

  const getLogoImageBuffer = async (graphBuffer) => {
    await toBlob(document.getElementById("img-logo")).then(function (blob) {
      blob.arrayBuffer().then((buffer) => {
        if (buffer.byteLength > 0) {
          generate(graphBuffer, buffer);
        }
      });
    });
  };

  const buttonExport = () => {
    return (
      <button
        className="btn btn-report "
        onClick={() => {
          getGraphImageBuffer();
        }}
      >
        <a className="text-white">
          <img className="nav-icon" src={"/svg/export.svg"} alt="export-icon" />{" "}
          ออกรายงาน
        </a>
      </button>
    );
  };

  const getDataInfo = (data) => {
    if (data.length > 0) {
      return data.toString();
    } else {
      return "ไม่มีข้อมูล";
    }
  };

  const setData = () => {
    if (Object.keys(dataAll.dataAll).length > 0) {
      let dataFarmDailyArray = [];
      let dataAnimalDailyArray = [];
      let dataProvinceAlertArray = [];

      if (dataAll.dataAll.farm.length > 0) {
        dataAll.dataAll.farm.forEach((element) => {
          dataFarmDailyArray.push({
            id: element.id,
            animalTypeId: element.animalTypeId,
            provinceId: element.provinceId,
            amphurId: element.amphurId,
            tambonId: element.tambonId,
            mooId: element.mooId,
            vaccineAmt: element.vaccineAmt,
            sickAmt: element.sickAmt,
            deadAmt: element.deadAmt,
            feedAmt: element.feedAmt,
            animalTypeName: element.animalTypeName,
            provinceName: element.provinceName,
            amphurName: element.amphurName,
            tambonName: element.tambonName,
            mooName: element.mooName,
            statusName: element.statusName,
            sickDate: element.sickDate,
          });
        });
        setDataFarmDaily(dataFarmDailyArray);
      }

      dataAll.dataAll.animalType.forEach((element) => {
        dataAnimalDailyArray.push({
          animalTypeId: element.animalTypeId,
          reportAmt: element.reportAmt,
          sickAmt: element.sickAmt,
          animalTypeName: element.animalTypeName,
        });
      });
      setAnimalTypeDaily(dataAnimalDailyArray);

      dataAll.dataAll.province.forEach((element) => {
        dataProvinceAlertArray.push(
          `${element.name} (${element.amt1}/${element.amt2}) `
        );
      });
      setProvinceAlert(dataProvinceAlertArray);
      setForm({
        ...form,
        unknownAmt: dataAll.dataAll.result.unknownAmt,
        plusAmt: dataAll.dataAll.result.plusAmt,
        minusAmt: dataAll.dataAll.result.minusAmt,
        percent: dataAll.dataAll.last1Year.percent,
      });
      setResultDaily(
        `ผลบวก (${
          dataAll.dataAll.result.plusAmt ? dataAll.dataAll.result.plusAmt : 0
        }) ผลลบ (${
          dataAll.dataAll.result.minusAmt ? dataAll.dataAll.result.minusAmt : 0
        }) ตรวจไม่ได้ (${
          dataAll.dataAll.result.unknownAmt
            ? dataAll.dataAll.result.unknownAmt
            : 0
        })`
      );
      setGraph(dataAll.dataAll.graph);
    }
  };

  const getPositiveProvince = () => {
    let text = [];
    const prov = dataAll.dataAll.last1Year?.province;
    if (prov && prov.length > 0) {
      prov.map((item, index) => {
        text.push(`${item.name} (${item.amt})`);
      });
    } else {
      text.push("(-)");
    }
    return text.toString();
  };

  const sumSample = () => {
    let label = Object.keys(dataAll.dataAll.graph);
    let lastYearLabel = label[label.length - 2];
    let lastYear = dataAll.dataAll.graph[`${lastYearLabel}`];
    let lastYearValue = Object.values(lastYear);
    return lastYearValue.reduce((a, b) => a + b, 0);
  };

  const getConclusion = (animal) => {
    let animalConclusion = [];
    let countPlusAmt = 0;
    if (animal && animal.length > 0) {
      animal.map((item, index) => {
        countPlusAmt = countPlusAmt + 1;
        animalConclusion.push(`${item.name} (${item.amt}) `);
      });
      return `มีการส่งตัวอย่างทั้งหมด ${sumSample()} ตัวอย่าง พบผลบวกทั้งหมด ${countPlusAmt} ตัวอย่าง (${
        form.percent
      }%) โดย 10 จังหวัดที่มีผลบวกสูงสุดได้แก่ ${getPositiveProvince()}
        ชนิดสัตว์ที่พบโรคได้แก่ ${animalConclusion.toString() || "(-)"}`;
    } else {
      return `   ไม่มีข้อมูล`;
    }
  };

  const font = "TH SarabunPSK";
  const noBorder = {
    top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
  };
  const headerMargin = 750;
  const sideMargin = 500;
  const gap_para = new Paragraph({
    children: [
      new TextRun({
        text: ``,
        font: font,
        size: 28,
      }),
    ],
  });
  const sec1_table = (logoBuffer) => {
    const sec1_table = new Table({
      columnWidths: [12000],
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 12000,
                type: WidthType.DXA,
              },
              borders: {
                left: {
                  style: BorderStyle.SINGLE,
                  size: 10,
                  color: "000000",
                },
                right: {
                  style: BorderStyle.SINGLE,
                  size: 10,
                  color: "000000",
                },
                top: {
                  style: BorderStyle.SINGLE,
                  size: 10,
                  color: "000000",
                },
                bottom: {
                  style: BorderStyle.SINGLE,
                  size: 10,
                  color: "000000",
                },
              },
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new ImageRun({
                      data: logoBuffer,
                      transformation: {
                        width: 100,
                        height: 100,
                      },
                    }),
                  ],
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "กลุ่มระบาดวิทยาทางสัตวแพทย์สํานักควบคุมป้องกันและบําบัดโรคสัตว์กรมปศุสัตว์",
                      bold: true,
                      font: font,
                      size: 36,
                    }),
                  ],
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "โทรศัพท์ 02-6534412 อีเมล vetepidem@dld.go.th",
                      bold: true,
                      font: font,
                      size: 36,
                    }),
                  ],
                }),
                gap_para,
              ],
            }),
          ],
        }),
      ],
    });
    return sec1_table;
  };

  const sec2_para = new Paragraph({
    alignment: AlignmentType.CENTER,
    children: [
      new TextRun({
        text: `สถานการณ์โรคปากและเท้าเปื่อย ประจําวันที่ ${new Date().getDate()} ${
          monthsFull["th"][moment(new Date().getTime()).get("month")]
        } ${(new Date().getFullYear() + 543) % 100}`,
        font: font,
        size: 32,
      }),
    ],
  });

  const sec3_para = new Paragraph({
    alignment: AlignmentType.CENTER,
    children: [
      new TextRun({
        text: `วันที่ ${
          dataAll.dataAll.dateFrom
            ? helper.getDateFormatTH(dataAll.dataAll.dateFroms)
            : "-"
        } ถึง ${
          dataAll.dataAll.dateTo
            ? helper.getDateFormatTH(dataAll.dataAll.dateTo)
            : "-"
        }`,
        font: font,
        size: 32,
      }),
    ],
  });

  const sec4_para = new Paragraph({
    alignment: AlignmentType.CENTER,
    children: [
      new TextRun({
        text: `ตารางแสดงสถานการณ์โรคประจําวัน`,
        font: font,
        size: 32,
      }),
    ],
  });

  const cellNodata = (colSpan = 1, width = 1000) => {
    const emptyRow = new TableRow({
      children: [
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          columnSpan: colSpan,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `ไม่มีข้อมูล`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
      ],
    });
    return emptyRow;
  };

  const renderRowAnimalDaily = (colSpan, width) => {
    const animalTypeTable = [
      sec6_headertable_right[0],
      sec6_headertable_right[1],
    ];
    if (animalTypeDaily.length > 0) {
      animalTypeDaily.map((item, index) => {
        animalTypeTable.push(
          new TableRow({
            children: [
              new TableCell({
                width: {
                  size: width,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: item.animalTypeName,
                        font: font,
                        size: 28,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                width: {
                  size: width,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: item.reportAmt,
                        font: font,
                        size: 28,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                width: {
                  size: width,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: item.sickAmt,
                        font: font,
                        size: 28,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          })
        );
      });
      return animalTypeTable;
    } else {
      animalTypeTable.push(cellNodata(colSpan, width));
      return animalTypeTable;
    }
  };

  const rowFarm = (item, index, width = 500) => {
    const rowData = new TableRow({
      children: [
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: index + 1,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: item.sickDate,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: item.animalTypeName,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: item.provinceName,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: item.amphurName,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: item.tambonName,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: item.mooName,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: item.vaccineAmt,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: item.sickAmt,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: item.deadAmt,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: item.feedAmt,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: width,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: item.statusName,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
      ],
    });
    return rowData;
  };

  const headTableFarm = [
    new TableRow({
      children: [
        new TableCell({
          width: {
            size: 800,
            type: WidthType.DXA,
          },
          rowSpan: 2,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `ลำดับ`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 1200,
            type: WidthType.DXA,
          },
          rowSpan: 2,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `วันที่เริ่มป่วย`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 800,
            type: WidthType.DXA,
          },
          rowSpan: 2,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `ชนิดสัตว์`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 4800,
            type: WidthType.DXA,
          },
          columnSpan: 4,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `สถานที่`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 3600,
            type: WidthType.DXA,
          },
          columnSpan: 4,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `จำนวนสัตว์ (ตัว)`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 800,
            type: WidthType.DXA,
          },
          rowSpan: 2,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `ผลตรวจ`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
      ],
    }),
    new TableRow({
      children: [
        new TableCell({
          width: {
            size: 800,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `จังหวัด`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 800,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `อำเภอ`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 800,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `ตำบล`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 800,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `หมู่บ้าน`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 800,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `ประวัติวัคซีน`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 800,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `ป่วย`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 800,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `ตาย`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 800,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `เสี่ยง`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
      ],
    }),
  ];

  const renderRowFarmDaily = (colSpan, width) => {
    const farm_table = headTableFarm;
    if (dataFarmDaily.length > 0) {
      dataFarmDaily.map((item, index) => {
        farm_table.push(rowFarm(item, index, width));
      });
      return farm_table;
    } else {
      farm_table.push(cellNodata(colSpan, width));
      return farm_table;
    }
  };

  const sec5_table = new Table({
    columnWidths: [12000],
    rows: renderRowFarmDaily(12, 800),
  });

  const subSection6 = (title, detail) => {
    return new Table({
      columnWidths: [6500],
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 30,
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      bold: true,
                      text: title,
                      font: font,
                      size: 28,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 70,
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: detail,
                      font: font,
                      size: 28,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    });
  };

  const sec6_headertable_right = [
    new TableRow({
      children: [
        new TableCell({
          columnSpan: 3,
          width: {
            size: 4500,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `ชนิดสัตว์ที่พบโรค (ตามรายงาน กคร.1 / รก.1) 5 อันดับ`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
      ],
    }),
    new TableRow({
      children: [
        new TableCell({
          width: {
            size: 1500,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `ชนิดสัตว์`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 1500,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `จํานวนครั้งที่รายงาน`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 1500,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  bold: true,
                  text: `จํานวนสัตว์ป่วยสะสม (ตัว)`,
                  font: font,
                  size: 28,
                }),
              ],
            }),
          ],
        }),
      ],
    }),
  ];

  const sec6_left_table = (buffer) => {
    const sec6_table = new Table({
      columnWidths: [12000],
      rows: [
        new TableRow({
          children: [
            new TableCell({
              borders: noBorder,
              width: {
                size: 6500,
                type: WidthType.DXA,
              },
              children: [
                subSection6(
                  "จังหวัดที่สถานะโรคยังไม่สงบ\n(ควบคุม / เฝ้าระวัง)",
                  getDataInfo(provinceAlert)
                ),
                gap_para,
                subSection6(
                  `รายงานผลตรวจ\rทางห้องปฏิบัติการ`,
                  getDataInfo(resultDaily)
                ),
                gap_para,
                graphImage(buffer),
              ],
            }),
            new TableCell({
              borders: noBorder,
              width: {
                size: 500,
                type: WidthType.DXA,
              },
              children: [gap_para],
            }),
            new TableCell({
              borders: noBorder,
              width: {
                size: 4500,
                type: WidthType.DXA,
              },
              children: [
                new Table({
                  columnWidths: [4500],
                  rows: renderRowAnimalDaily(3, 1500),
                }),
              ],
            }),
          ],
        }),
      ],
    });
    return sec6_table;
  };

  const sec7_table = new Table({
    columnWidths: [12000],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 12000,
              type: WidthType.DXA,
            },
            children: [
              gap_para,
              new Paragraph({
                children: [
                  new TextRun({
                    bold: true,
                    text: `   สรุปสถานการณ์ย้อนหลัง 1 ปี`,
                    font: font,
                    size: 28,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${getConclusion(
                      dataAll.dataAll.last1Year?.animalType
                    )} `,
                    font: font,
                    size: 28,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });

  const graphImage = (buffer) => {
    const sec8_img = new Paragraph({
      children: [
        new ImageRun({
          data: buffer,
          transformation: {
            width: 440,
            height: 250,
          },
        }),
      ],
    });
    return sec8_img;
  };

  const generate = (graphBuffer, logoBuffer) => {
    const doc = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: headerMargin,
                right: sideMargin,
                bottom: headerMargin,
                left: sideMargin,
              },
            },
          },
          children: [
            sec1_table(logoBuffer),
            gap_para,
            sec2_para,
            sec3_para,
            sec4_para,
            sec5_table,
            gap_para,
            sec6_left_table(graphBuffer),
            gap_para,
            sec7_table,
          ],
        },
      ],
    });
    dispatch(loading(false));
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${fileName}.docx`);
    });
  };
  return (
    <BoxCard
      title="รายการข้อมูลรายงาน B99RPT08-รายงานภาพรวมรายวัน"
      classNameBox="mt-3"
      headRight={buttonExport()}
    >
      <div>
        <Report_Daily_Info dataAll={dataAll.dataAll} />
      </div>
    </BoxCard>
  );
}

export default Report_Daily_Doc;
