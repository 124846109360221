import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import Breadcrumb from "../../components/Breadcrumb";
import ModalOrganizes from "../../components/Modal.Organizes";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import DataTable from "../../components/DataTable";
import DataTableNodata from "../../components/DataTableNodata";
import ModalStopDate from "../../components/Modal.StopDate";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  useMasterData,
  useOptionsListYears,
  useCurrentYear,
} from "../../components/hooks/useMasterData";
import ButtonReportLink from "../../components/ButtonReportLink";

const MySwal = withReactContent(Swal);

function Disease_Statement() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "footer"]);
  const statusDisease = useSelector((state) => state.statusDisease);
  const user = useSelector((state) => state.login.result);

  const search = useLocation().search;
  const obCode = new URLSearchParams(search).get("obCode");

  const initialForm = {
    farmId: "",
    orgId: "",
    orgName: "",
    outbreakID: obCode ? obCode : "",
    case: "",
    name: "",
    dateF: "",
    to_dateF: "",
    dateR: "",
    to_dateR: "",
    zoneId: "",
    status: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    diseaseTypeId: "",
    foundDiseaseTypeId: "",
    animalId: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
      },
      {
        sortBy: "caseCode",
        name: "Case",
        sortFlg: true,
      },
      {
        sortBy: "id",
        name: "ข้อมูลเกษตรกร/ฟาร์ม",
        sortFlg: false,
      },
      {
        sortBy: "id",
        name: "การรายงานโรค (กคร.1/รก.1)",
        sortFlg: false,
      },
      {
        sortBy: "id",
        name: "สอบสวนโรค (กคร.2)",
        sortFlg: false,
      },
      {
        sortBy: "id",
        name: "ติดตามผลการระบาด (กคร.3 / สคส.1)",
        sortFlg: false,
      },
      {
        sortBy: "id",
        name: "การฉีดวัคซีน (กคร.5)",
        sortFlg: false,
      },
      {
        sortBy: "id",
        name: "การดำเนินมาตรการ (กคร.6)",
        sortFlg: false,
      },
      {
        sortBy: "id",
        name: "การเก็บตัวอย่าง/ผลห้องปฏิบัติการ",
        sortFlg: false,
      },
      {
        sortBy: "id",
        name: "สิ้นสุดโรค",
        sortFlg: false,
      },
      {
        sortBy: "id",
        name: "สถานะการดำเนินการ",
        sortFlg: false,
      },
      {
        sortBy: "id",
        name: "ปรับปรุงข้อมูลล่าสุด",
        sortFlg: false,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const initShowOrgModal = false;
  const [showOrgModal, setShowOrgModal] = useState(initShowOrgModal);

  const [showAlertDate, setShowAlertModal] = useState(false);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  // =================

  // select  หน่วยงานที่แจ้งโรค สถานะการดำเนินการ เขตปศุสัตว์ ชื่อโรคที่สงสัย ชื่อโรคที่พบ ชนิดสัตว์
  const [agencySelectValue, setAgencySelectValue] = useState([]);

  const [statusSelectValue, setStatusSelectValue] = useState([]);

  const selectStatusList = [
    {
      value: 1,
      label: "แจ้งโรค",
    },
    {
      value: 2,
      label: "รับแจ้ง",
    },
    {
      value: 3,
      label: "เฝ้าระวัง",
    },
    {
      value: 4,
      label: "ควบคุมโรค",
    },
    {
      value: 5,
      label: "โรคสงบ",
    },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  const [listDiseaseStatement, setListDiseaseStatement] = useState([]);

  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState([]);
  const [zoneLivestockList, setZoneLivestockList] = useState([]);

  const [diseaseSuspectedSelectValue, setDiseaseSuspectedSelectValue] =
    useState([]);
  const [diseaseSuspectedList, setDiseaseSuspectedList] = useState([]);

  const [diseaseFoundSelectValue, setDiseaseFoundSelectValue] = useState([]);
  const [diseaseFoundList, setDiseaseFoundList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [isClearSearch, setIsClearSearch] = useState(false);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "title", name: "กคร.1/รก.1" },
    { path: "active", name: "การเกิดโรคระบาดสัตว์เบื้องต้น (กคร.1/รก.1)" },
  ];

  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );

  const optionsListYear = useOptionsListYears();
  const currentYearValue = useCurrentYear();
  const [yearFilterList, setYearFilterList] = useState(currentYearValue);

  useEffect(() => {
    // getProvince();
    getAnimal();
    getZoneLivestock();
    getDiseaseType();
  }, []);

  useEffect(() => {
    getDiseaseStatement();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getDiseaseStatement = async () => {
    const params = await {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",
      orgId: form.orgId ? form.orgId : "",
      obCode: form.outbreakID ? form.outbreakID : "",
      caseCode: form.case ? form.case : "",
      farmId: form.farmId ? form.farmId : "",
      ownerName: form.name ? form.name : "",
      foundDateBegin: form.dateF ? helper.getDate(form.dateF) : "",
      foundDateEnd: form.to_dateF ? helper.getDate(form.to_dateF) : "",
      createDateBegin: form.dateR ? helper.getDate(form.dateR) : "",
      createDateEnd: form.to_dateR ? helper.getDate(form.to_dateR) : "",
      zoneId: form.zoneId ? form.zoneId : "",
      statusId: form.status ? form.status : "",
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
      diseaseTypeId: form.diseaseTypeId ? form.diseaseTypeId : "",
      foundDiseaseTypeId: form.foundDiseaseTypeId
        ? form.foundDiseaseTypeId
        : "",
      animalTypeId: form.animalId ? form.animalId : "",
      onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
    };

    dispatch(loading(true));
    try {
      const response = await API.getDiseaseStatement(params);

      const data = await response.data;
      // console.log("params---getDiseaseStatement--🐳🐳", params, response);

      if (response.status == 200) {
        dispatch(loading(false));
        await setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        await setListDiseaseStatement(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType("", "", "1", "100", 1);
      const data = await response.data;
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseaseFoundList(diseaseArray);
        setDiseaseSuspectedList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([value]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([value]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, districtId: value.value });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([value]);
    setForm({ ...form, subDistrictId: value.value });
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setAgencySelectValue([]);
    setAnimalSelectValue([]);
    setDiseaseSuspectedSelectValue([]);
    setZoneLivestockSelectValue([]);
    setStatusSelectValue([]);
    setDiseaseFoundSelectValue([]);
    setSubDistrictSelectValue([]);
    setDistrictSelectValue([]);
    setDistrictList([]);
    setSubDistrictList([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <ButtonReportLink path={"disease_statement"} linkSize={3} addIndex params={
          {
            orgId: form.orgId ? form.orgId : "",
            obCode: form.outbreakID ? form.outbreakID : "",
            caseCode: form.case ? form.case : "",
            farmId: form.farmId ? form.farmId : "",
            ownerName: form.name ? form.name : "",
            foundDateBegin: form.dateF ? helper.getDate(form.dateF) : "",
            foundDateEnd: form.to_dateF ? helper.getDate(form.to_dateF) : "",
            createDateBegin: form.dateR ? helper.getDate(form.dateR) : "",
            createDateEnd: form.to_dateR ? helper.getDate(form.to_dateR) : "",
            zoneId: form.zoneId ? form.zoneId : "",
            statusId: form.status ? form.status : "",
            provinceId: form.provinceId ? form.provinceId : "",
            amphurId: form.districtId ? form.districtId : "",
            tambonId: form.subDistrictId ? form.subDistrictId : "",
            diseaseTypeId: form.diseaseTypeId ? form.diseaseTypeId : "",
            foundDiseaseTypeId: form.foundDiseaseTypeId
              ? form.foundDiseaseTypeId
              : "",
            animalTypeId: form.animalId ? form.animalId : "",
            onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
          }
        }/>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const setOrganizes = (data) => {
    setForm({ ...form, orgId: data.value, orgName: data.label });
  };

  const viewDetails = (id) => {
    history.push(`/passive_surv/disease_statement/edit/${id}`);
  };

  const viewDuplicate = (id) => {
    MySwal.fire({
      text: `ยืนยันการคัดลอกข้อมูล`,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        history.push(`/passive_surv/disease_statement/duplicate/${id}`);
      }
    });
  };

  const deleteDisease = (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบการรายงานโรคนี้`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteDiseaseStatement(id);
          if (response.status == 200) {
            await getDiseaseStatement();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const buttonAdd = () => {
    return (
      <button
        className="btn btn-info text-white"
        disabled={user?.userGroup.mcreate === "n"}
        onClick={() => history.push(`/passive_surv/disease_statement/save`)}
      >
        <i className="fas fa-plus mr-1 "></i>เพิ่มการรายงานโรค
      </button>
    );
  };

  const linkToPage = (url) => {
    history.push(url);
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      await setForm({
        ...form,
        page: isChange1,
      });
    } else if (type == "limit") {
      await setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type == "filter") {
      await setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusDisease && statusDisease.length > 0) {
      statusDisease.map((item, index) => {
        if (item.idOb === idStatus) {
          statusColor = (
            <div
              style={{ background: item.color, color: item.fontColor }}
              className="box-status font14 px-2 text-center"
            >
              <span>{item.status}</span>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const updateDate = async (data) => {
    const dataDate = {
      stopDate: data.date ? helper.getDate(data.date) : "",
    };

    console.log("date", dataDate);

    try {
      const response = await API.updateStopDate(data.idUpdateDate, dataDate);
      if (response.status === 200) {
        MySwal.fire({
          text: "กำหนดข้อมูลสิ้นสุดโรคสำเร็จ",
          icon: "success",
          width: "400px",
          confirmButtonText: "ตกลง",
        });
        await getDiseaseStatement();
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      } else {
        MySwal.fire({
          text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
      }
      console.log("error", error);
    }
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>{item.caseCode ? item.caseCode : "-"}</td>
          <td className="font14 mb-0 text-left">
            {`${item.farmName || "-"}`} <br />
            {`${item.firstName} ${item.lastName} (${item.mobile})`} <br />
            {`${item.homeNo} ต.${item.tambonName} อ.${item.amphurName} จ.${item.provinceName}`}{" "}
            <br />
            {`สงสัยโรค: ${item.diseaseName ? item.diseaseName : "-"}`} <br />
            <p className="font14 mb-0 text-danger">{`พบโรค: ${
              item.foundDiseaseName ? item.foundDiseaseName : "-"
            }`}</p>
          </td>
          <td>
            <p className="font14 mb-0">
              {" "}
              {helper.momentDate(item.createDate, "th", "short")}
            </p>
            <p className="font14 mb-0">{`${item.firstName} ${item.lastName}`}</p>
            <p className="font14 mb-0">{`(${
              item.orgName ? item.orgName : "-"
            })`}</p>
          </td>
          <td>
            {item?.fm02 ? (
              <>
                <div
                  className="cursor-p"
                  onClick={() =>
                    linkToPage(
                      `/passive_surv/disease_investigation?obCode=${item.obCode}`
                    )
                  }
                >
                  <h6 className="link-to">{item?.fm02} รายการ</h6>
                </div>
                <button
                  className="btn btn-add text-white btn-sm"
                  disabled={user?.userGroup.mcreate === "n"}
                  onClick={() =>
                    history.push(
                      `/passive_surv/disease_investigation/save/${item.obId}`
                    )
                  }
                >
                  <i className="fas fa-plus mr-2 "></i> เพิ่ม
                </button>
                {/* <div style={{ background: '#25B881', color: '#FFF', borderRadius: '4px', height: '24px', padding: '2px' }} className="box-status font14 px-2 text-center">
                  <span className="mt-1">ดำเนินการแล้ว</span>
                </div> */}
              </>
            ) : (
              <button
                className="btn btn-add text-white btn-sm"
                disabled={user?.userGroup.mcreate === "n"}
                onClick={() =>
                  history.push(
                    `/passive_surv/disease_investigation/save/${item.obId}`
                  )
                }
              >
                <i className="fas fa-plus mr-2 "></i> เพิ่ม
              </button>
            )}
          </td>
          <td>
            {item?.fm03 ? (
              <>
                <div
                  className="cursor-p"
                  onClick={() =>
                    linkToPage(
                      `/passive_surv/disease_control?obCode=${item.obCode}`
                    )
                  }
                >
                  <h6 className="link-to">{item?.fm03} รายการ</h6>
                </div>
                <button
                  className="btn btn-add text-white btn-sm"
                  disabled={user?.userGroup.mcreate === "n"}
                  onClick={() =>
                    history.push(
                      `/passive_surv/disease_control/save/${item.obId}`
                    )
                  }
                >
                  <i className="fas fa-plus mr-2 "></i> เพิ่ม
                </button>
                {/* <div style={{ background: '#25B881', color: '#FFF', borderRadius: '4px', height: '24px', padding: '2px' }} className="box-status font14 px-2 text-center">
                  <span className="mt-1">ดำเนินการแล้ว</span>
                </div> */}
              </>
            ) : (
              <button
                className="btn btn-add text-white btn-sm"
                disabled={user?.userGroup.mcreate === "n"}
                onClick={() =>
                  history.push(
                    `/passive_surv/disease_control/save/${item.obId}`
                  )
                }
              >
                <i className="fas fa-plus mr-2 "></i> เพิ่ม
              </button>
            )}
          </td>
          <td>
            {item?.fm05 ? (
              <>
                <div
                  className="cursor-p"
                  onClick={() =>
                    linkToPage(`/passive_surv/vaccinate?obCode=${item.obCode}`)
                  }
                >
                  <h6 className="link-to">{item?.fm05} รายการ</h6>
                </div>
                <button
                  className="btn btn-add text-white btn-sm"
                  disabled={user?.userGroup.mcreate === "n"}
                  onClick={() =>
                    history.push(`/passive_surv/vaccinate/save/${item.obId}`)
                  }
                >
                  <i className="fas fa-plus mr-2 "></i> เพิ่ม
                </button>
                {/* <div style={{ background: '#25B881', color: '#FFF', borderRadius: '4px', height: '24px', padding: '2px' }} className="box-status font14 px-2 text-center">
                <span className="mt-1">ดำเนินการแล้ว</span>
              </div> */}
              </>
            ) : (
              <button
                className="btn btn-add text-white btn-sm"
                disabled={user?.userGroup.mcreate === "n"}
                onClick={() =>
                  history.push(`/passive_surv/vaccinate/save/${item.obId}`)
                }
              >
                <i className="fas fa-plus mr-2 "></i> เพิ่ม
              </button>
            )}
          </td>
          <td>
            {item?.fm06 ? (
              <>
                <div
                  className="cursor-p"
                  onClick={() =>
                    linkToPage(
                      `/passive_surv/disease_control?obCode=${item.obCode}`
                    )
                  }
                >
                  <h6 className="link-to">{item?.fm06} รายการ</h6>
                </div>
                <button
                  className="btn btn-add text-white btn-sm"
                  disabled={user?.userGroup.mcreate === "n"}
                  onClick={() =>
                    history.push(
                      `/passive_surv/disease_control/save/${item.obId}`
                    )
                  }
                >
                  <i className="fas fa-plus mr-2 "></i> เพิ่ม
                </button>
                {/* <div style={{ background: '#25B881', color: '#FFF', borderRadius: '4px', height: '24px', padding: '2px' }} className="box-status font14 px-2 text-center">
                  <span className="mt-1">ดำเนินการแล้ว</span>
                </div> */}
              </>
            ) : (
              <button
                className="btn btn-add text-white btn-sm"
                disabled={user?.userGroup.mcreate === "n"}
                onClick={() =>
                  history.push(
                    `/passive_surv/disease_control/save/${item.obId}`
                  )
                }
              >
                <i className="fas fa-plus mr-2 "></i> เพิ่ม
              </button>
            )}
          </td>
          <td>
            {item?.sendSample && item?.receiveSample ? (
              <div className="text-center">
                <div
                  style={{
                    background: "#20639B",
                    color: "#FFF",
                    borderRadius: "4px",
                    height: "24px",
                    padding: "2px",
                  }}
                  className="box-status font14 mb-0 px-2 text-center"
                >
                  <span className="mt-1">ส่งผล</span>
                </div>
                {helper.momentDateTh(item.sendSample, "th", "short")}

                <div
                  style={{
                    background: "#20639B",
                    color: "#FFF",
                    borderRadius: "4px",
                    height: "24px",
                    padding: "2px",
                  }}
                  className="box-status font14 px-2  text-center"
                >
                  <span className="mt-1">รับผล</span>
                </div>
                {helper.momentDateTh(item.receiveSample, "th", "short")}
              </div>
            ) : (
              <button
                className="btn btn-add text-white btn-sm"
                disabled={user?.userGroup.mcreate === "n"}
                onClick={() =>
                  history.push(`/passive_surv/keep-example/save/${item.obId}`)
                }
              >
                <i className="fas fa-plus mr-2 "></i> เพิ่ม
              </button>
            )}
          </td>
          <td>
            <button
              className="btn btn-darkblue text-white btn-sm"
              disabled={user?.userGroup.mcreate === "n"}
              onClick={() => {
                setShowAlertModal(true);
                setForm({ ...form, idUpdateDate: item.id });
              }}
            >
              <img
                className="icon-btn mr-2"
                src="/svg/date-stop.svg"
                atl="menu-icon"
              ></img>{" "}
              กำหนดค่าสิ้นสุดโรค
            </button>
          </td>
          <td>{item.statusId ? getStatusColor(item.statusId) : "-"}</td>
          <td>
            {`${item.updateFirstName} ${item.updateLastName}`} <br />
            <span className="font12">
              {helper.momentDate(item.updateDate, "th", "short")}
            </span>
          </td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => deleteDisease(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
              <button
                className="btn btn-copy mr-2 "
                onClick={() => viewDuplicate(item.id)}
                disabled={user?.userGroup.mcopy === "n"}
              >
                <img className="icon-btn " src="/svg/btn-copy.svg" alt="copy" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="13" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ปี"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="year"
                    classLabel="normal"
                    selectValue={yearFilterList}
                    optionsList={optionsListYear}
                    handleChange={(value) => setYearFilterList(value)}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="รหัสฟาร์ม"
                    idName="farmId"
                    value={form.farmId}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, farmId: value })
                    }
                  />
                </Col>

                <Col lg={3} md={6} sm={12} xs={12} className="d-flex ">
                  <div className="mt-auto mb-3">
                    <button
                      className="btn btn-darkblue "
                      onClick={() =>
                        history.push("/farm_registration/save-data")
                      }
                    >
                      <img
                        className="mr-2 mb-1 "
                        src={"/svg/menu_5.svg"}
                        alt="menu-icon"
                      />
                      ขึ้นทะเบียนฟาร์ม
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <label className="mb-0 normal">หน่วยงานที่แจ้งโรค </label>
                  <div className="d-flex align-items-center">
                    {form.orgName ? (
                      <>
                        <button
                          className="btn btn-primary pt-2 px-3"
                          onClick={() => setShowOrgModal(true)}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="ml-2">{form.orgName}</div>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary p-2 d-flex align-items-center"
                        onClick={() => setShowOrgModal(true)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        <p className="mb-0 ml-2">{t("search")}</p>
                      </button>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เลข Outbreak ID"
                    idName="outbreakID"
                    value={form.outbreakID}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, outbreakID: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เลข case"
                    idName="case"
                    value={form.case}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, case: value })}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เจ้าของสัตว์"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="ชื่อ/นามสุกล/บัตรประชาชน/เบอร์โทรศัพท์"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่พบโรค"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateF}
                    star={false}
                    handleChange={(value) => setForm({ ...form, dateF: value })}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.to_dateF}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, to_dateF: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่รายงานโรค"
                    classLabel="normal"
                    star={false}
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateR}
                    handleChange={(value) => setForm({ ...form, dateR: value })}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.to_dateR}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, to_dateR: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขตปศุสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={zoneLivestockSelectValue}
                    optionsList={zoneLivestockList}
                    handleChange={(value) => {
                      setZoneLivestockSelectValue([value]);
                      setForm({ ...form, zoneId: value.value });
                      getProvince(value.value);
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะการดำเนินการ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={statusList}
                    handleChange={(value) => {
                      setStatusSelectValue([value]);
                      setForm({ ...form, status: value.value });
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => {
                      selectValueProvince(value);
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชื่อโรคที่สงสัย"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={diseaseSuspectedSelectValue}
                    optionsList={diseaseSuspectedList}
                    handleChange={(value) => {
                      setDiseaseSuspectedSelectValue([value]);
                      setForm({ ...form, diseaseTypeId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชื่อโรคที่พบ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={diseaseFoundSelectValue}
                    optionsList={diseaseFoundList}
                    handleChange={(value) => {
                      setDiseaseFoundSelectValue([value]);
                      setForm({ ...form, foundDiseaseTypeId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={animalSelectValue}
                    optionsList={animalList}
                    handleChange={(value) => {
                      setAnimalSelectValue([value]);
                      setForm({ ...form, animalId: value.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายงาน กคร.1/รก.1"
            headRight={buttonAdd()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTable(listDiseaseStatement)}
            </DataTable>
          </BoxCard>
        </div>
      </div>

      {showOrgModal && (
        <ModalOrganizes
          show={showOrgModal}
          close={() => setShowOrgModal(false)}
          toSaveOrg={(data) => setOrganizes(data)}
        />
      )}

      {showAlertDate && (
        <ModalStopDate
          show={showAlertDate}
          close={() => setShowAlertModal(false)}
          save={(value) => updateDate(value)}
          idUpdateDate={form.idUpdateDate}
        />
      )}
    </div>
  );
}

export default Disease_Statement;
