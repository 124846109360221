import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BoxHead from "../../components/BoxHead";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputGroupText from "../../components/InputGroupText";
import InputSelect from "../../components/InputSelect";
import InputMask from "../../components/InputMask";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import * as API from "../../utils/apis";
import withReactContent from "sweetalert2-react-content";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";

import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import ModalFarmSearch from "../../components/Modal.FarmSearch";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function AssessRisk_Result_Save(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t } = useTranslation(["common", "footer"]);

  const initialForm = {
    chooseSelect: false,
    dataAllById: {},
    mode: "",

    formId: "",
    corporate: "",
    idCard: "",
    farmId: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    caseCode: "",
    homeno: "",
    phone: "",
    name: "",
    orgId: "",
    orgName: "",

    ansCheckbox: [],
    ansCheckRadio: [],
  };
  const [form, setForm] = useState(initialForm);

  const initFormQuest = {
    questionAll: [],
  };
  const [formQuest, setFormQuest] = useState(initFormQuest);

  const initShowCaseIdSearch = false;
  const [showCaseIdSearch, setShowCaseIdSearch] =
    useState(initShowCaseIdSearch);

  const initShowOrgModal = false;
  const [showOrgModal, setShowOrgModal] = useState(initShowOrgModal);

  const initShowOutbreakSearch = false;
  const [showOutbreakSearch, setShowOutbreakSearch] = useState(
    initShowOutbreakSearch
  );

  const [riskSelectValue, setRiskSelectValue] = useState([]);
  const [riskList, setRiskList] = useState([]);

  const initShowFarmSearch = false;
  const [showFarmSearch, setShowFarmSearch] = useState(initShowFarmSearch);

  const initialFormJson = {
    animalId: {},
    diseaseTypeId: {},
    husbandry: {},
    animalPurpose: {},
    vaccineType: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  // หน่วยงาน ชนิดโรค
  const [organizesSelectValue, setOrganizesSelectValue] = useState([]);
  const [organizesList, setOrganizesList] = useState([]);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/assess_risk/list-assess-risk", name: "ประเมินความเสี่ยง" },
    { path: "active", name: "บันทึกผลการประเมินความเสี่ยง" },
  ];

  useMasterData("province", provinceList, setProvinceList);

  useEffect(async () => {
    let isMounted = true;

    if (isMounted) {
      await getMasterJson();
      await getProvince();
      await getOrganizes();
      await getAssessRiskKit();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      getAssessRiskResultById(props.match.params.id);
    }
  }, [
    user,
    location.pathname,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
    riskList,
  ]);

  useEffect(async () => {
    if (
      location.pathname.includes("save") &&
      !form.chooseSelect &&
      form.farmId
    ) {
      getFarmById(form.farmId);
    }
  }, [
    user,
    form.farmId,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
  ]);

  const getMasterJson = async () => {
    const params = await [
      "animalType",
      "diseaseType",
      "husbandry",
      "animalPurpose",
      "vaccineType",
    ];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          animalId: data.animalType,
          diseaseTypeId: data.diseaseType,
          husbandry: data.husbandry,
          animalPurpose: data.animalPurpose,
          vaccineType: data.vaccineType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOrganizes = async (id) => {
    try {
      const response = await API.getOrganizes();
      const data = await response.data;
      if (response.status == 200) {
        let organizesArray = [];
        data.content.forEach((element) => {
          organizesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setOrganizesList(organizesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAssessRiskKit = async () => {
    try {
      const response = await API.getAssessRiskKit("", "1");
      const data = await response.data;
      if (response.status == 200) {
        let riskArray = [];
        data.content.forEach((element) => {
          riskArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setRiskList(riskArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getQuestById = async (id, dataById) => {
    try {
      const response = await API.getQuestById(id);
      const data = await response.data;
      // console.log("setQuestList", data);
      if (response.status == 200) {
        let questArray = [];
        let ansCheckboxArray = [];

        if (location.pathname.includes("save")) {
          if (data && data.length > 0) {
            data.map((item, i) => {
              let ansList = [];
              questArray.push({
                qId: item.id,
                qAnsTypeId: item.ansTypeId,
                qName: item.name,
                ans: item.ans,
                orderIndex: item.orderIndex,
                qScore: "",
                ansRadio: "",
              });
            });
          }
          // console.log("listAnsSave", questArray);
          setFormQuest({ ...formQuest, questionAll: questArray });
        } else if (location.pathname.includes("edit")) {
          if (data && data.length > 0) {
            data.map(async (item, i) => {
              //note// ansTypeId: 1 => radio & 2 => checkbox
              if (item.ansTypeId === 2) {
                ansCheckboxArray.push(compareCheckAns(dataById.ans, item.id));
              }

              questArray.push({
                qId: item.id,
                qAnsTypeId: item.ansTypeId,
                qName: item.name,
                ans: item.ans,
                qScore: checkAnsScore(dataById.ans, item.id),
                ansRadio: checkAnsRadio(dataById.ans, item.id),
              });
            });

            setFormQuest({ ...formQuest, questionAll: questArray });
          }

          let ansCheckbox = [];
          let arrayAns = ansCheckboxArray.flat();

          if (arrayAns && arrayAns.length > 0) {
            arrayAns.map((itemA, a) => {
              ansCheckbox.push({
                formQuestId: itemA.formQuestId,
                questAnsId: itemA.questAnsId,
                score: itemA.score,
                other: "",
              });
            });
          }
          // console.log("data question", ansCheckbox);
          // console.log("dataById.ans", dataById.ans);
          // console.log("data question", data);

          setForm({
            ...form,
            formId: dataById.form.formId,
            corporate: dataById.form.corporate,
            idCard: dataById.form.pid,
            farmId: dataById.form.farmId,
            provinceId: dataById.form.provinceId,
            districtId: dataById.form.amphurId,
            subDistrictId: dataById.form.tambonId,
            villageId: dataById.form.mooId,
            caseCode: dataById.form.caseCode,
            phone: dataById.form.tel,
            name: dataById.form.name,
            orgId: dataById.form.orgId,
            orgName: dataById.form.orgName,
            ansCheckbox: ansCheckbox,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const compareCheckAns = (list, qId) => {
    if (list && list.length > 0) {
      return list.filter((item, index) => {
        return item.formQuestId == qId;
      });
    }
  };

  const checkAnsScore = (list, qId) => {
    let score;
    if (list && list.length > 0) {
      list.filter((item, index) => {
        if (item.formQuestId == qId) {
          score = item.score;
        }
      });
    }
    return score;
  };

  const checkAnsRadio = (list, qId) => {
    let questAnsId;
    if (list && list.length > 0) {
      list.filter((item, index) => {
        if (item.formQuestId == qId) {
          // console.log("item.questAnsId", item.questAnsId);
          questAnsId = item.questAnsId;
        }
      });
    }
    return questAnsId;
  };

  const setChooseAnsCheckBox = (e, item, itemA) => {
    let arrayChoose = [];
    if (e.target.checked) {
      if (item.qAnsTypeId == 2) {
        arrayChoose = [
          ...form.ansCheckbox,
          {
            formQuestId: item.qId,
            questAnsId: e.target.id,
            score: itemA.score,
            other: "",
          },
        ];

        setForm({ ...form, ansCheckbox: arrayChoose });
      }
    } else {
      if (form.ansCheckbox.length > 0 && item.qAnsTypeId == 2) {
        form.ansCheckbox.forEach((item, i) => {
          if (item.questAnsId == e.target.id) {
            form.ansCheckbox.splice(i, 1);
          }
        });
        setForm({ ...form, ansCheckbox: form.ansCheckbox });
      }
    }
    // console.log("form.ansCheckbox ", form);
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      zipcode: "",
      districtId: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      districtId: value.value,
      zipcode: "",
      subDistrictId: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      subDistrictId: value.value,
      zipcode: value.zipcode,
      villageId: "",
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const getAssessRiskResultById = async (id) => {
    let dataRes;
    try {
      const response = await API.getAssessRiskResultById(id);
      if (response.status == 200) {
        dataRes = await response.data;
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.form && dataById.form.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.form.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.form.provinceId);
        }
      }

      if (dataById.form && dataById.form.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.form.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.form.amphurId);
        }
      }

      if (dataById.form && dataById.form.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.form.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.form.tambonId);
        }
      }

      if (dataById.form && dataById.form.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.form.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.form && dataById.form.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.form.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      if (dataById.form && dataById.form.formId) {
        let riskSelect = await riskList.find(
          (item) => item.value == dataById.form.formId
        );
        setRiskSelectValue(riskSelect);
        getQuestById(dataById.form.formId, dataById);
      }
    }
  };

  const getFarmById = async (id) => {
    dispatch(loading(true));
    let dataRes;
    try {
      const response = await API.getFarmRegistrationById(id);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.farm && dataById.farm.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.farm.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.farm.provinceId);
        }
      }

      if (dataById.farm && dataById.farm.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.farm.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.farm.amphurId);
        }
      }

      if (dataById.farm && dataById.farm.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.farm.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.farm.tambonId);
        }
      }

      if (dataById.farm && dataById.farm.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.farm.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.farm && dataById.farm.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.farm.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      const houseArray = [];
      let vaccineArray;
      if (dataById.barn && dataById.barn.length > 0) {
        dataById.barn.map((barns, b) => {
          houseArray.push({
            barnId: barns.id,
            name: barns.name,
            desc: [],
            animals: barns.animals.map((animal, a) => {
              if (barns.id == animal.barnId) {
                if (animal.vaccine && animal.vaccine.length > 0) {
                  vaccineArray = animal.vaccine.map((vaccine, v) => {
                    return {
                      diseaseTypeName:
                        formJson.diseaseTypeId[vaccine.diseaseTypeId],
                      diseaseTypeId: vaccine.diseaseTypeId,
                      last_date: vaccine.vaccineDate,
                      lotNo: vaccine.lotNo,
                      operator: vaccine.injectBy,
                      other_vaccine: vaccine.injectOther,
                      had_disease: vaccine.diseaseHistory,
                      vaccineTypeId: vaccine.vaccineTypeId,
                      vaccineTypeName:
                        formJson.vaccineType[vaccine.vaccineTypeId],
                    };
                  });
                }

                return {
                  animalTypeName: formJson.animalId[animal.animalTypeId],
                  animalTypeId: animal.animalTypeId,
                  animalId: animal.animalCode,
                  date_dead: animal.deadDate,
                  status_id: animal.statusId,
                  cause_death_type: animal.deadCause,
                  cause_death_type_other: animal.deadReason,
                  name: animal.name,
                  genderType: animal.sex,
                  gender:
                    animal.sex === "m"
                      ? "ตัวผู้"
                      : animal.sex === "f"
                      ? "ตัวเมีย"
                      : "ไม่ทราบ",
                  house_buiding: barns.name,
                  age_year: animal.ageYear,
                  age_month: animal.ageMonth,
                  no_nid: animal.nid,
                  no_microchip: animal.microchip,
                  breed: animal.species,
                  purpose_raising: animal.purposeId,
                  purpose_name: formJson.animalPurpose[animal.purposeId],
                  no_nid: animal.nidCode,
                  no_animal_ear: animal.nid,
                  style_raising: animal.husbandryId,
                  style_raising_name: formJson.husbandry[animal.husbandryId],
                  is_vaccine: animal.vaccinationStatus,
                  choose_disease: vaccineArray,
                };
              }
            }),
          });
        });
      }

      let arrayHouseList = [];
      if (houseArray && houseArray.length > 0) {
        houseArray.forEach((house) =>
          arrayHouseList.push({ value: house.barnId, label: house.name })
        );
      }

      setForm({
        ...form,
        dataAllById: dataById,

        modeGet: "farmById",
        farmId: dataById.farm.id,

        provinceId: dataById.farm.provinceId,
        districtId: dataById.farm.amphurId,
        subDistrictId: dataById.farm.tambonId,
        villageId: dataById.farm.mooId,
        homeno: dataById.farm.homeNo,
        idCard: dataById.farmer.pid,
        phone: dataById.farmer.mobile,
        name: `${dataById.farmer.firstName} ${dataById.farmer.lastName}`,
        orgId: dataById.farm.orgId,
        orgName: dataById.farm.orgName,

        step1: {
          provinceId: dataById.farm.provinceId,
          districtId: dataById.farm.amphurId,
          subDistrictId: dataById.farm.tambonId,
          villageId: dataById.farm.mooId,
          homeno: dataById.farm.homeNo,
          localGovernId: dataById.farm.dlaId,
          governmentName: dataById.farm.dlaName,
        },

        step2: {
          house: houseArray,
          houseList: arrayHouseList,
          res_animals: [],
          list_animals: [],
        },
      });

      dispatch(loading(false));
    }
  };

  const toCancel = () => {
    history.push("/assess_risk/assess-risk-result");
  };

  const checkAnsCheckbox = (list, qId) => {
    let checkList;

    if (list && list.length > 0) {
      list.map((item, index) => {
        // console.log("item.formQuestId qId", item.formQuestId, qId);
        if (item.formQuestId === qId) {
          checkList = item;
        }
      });
    }
    return checkList;
  };

  const toSaveAssessRisk = async () => {
    let ansArrayRadio = [];
    let ansArrayCheckBox = [];
    let ansAll = [];
    if (formQuest.questionAll && formQuest.questionAll.length > 0) {
      formQuest.questionAll.map((item, i) => {
        if (item.qAnsTypeId == 1) {
          // ansArrayRadio = form.ansCheckRadio
          if (item.ansRadio) {
            ansArrayRadio.push({
              formQuestId: item.qId,
              questAnsId: item.ansRadio,
              score: item.qScore,
              other: "",
            });
          }
        } else if (item.qAnsTypeId == 2) {
          ansArrayCheckBox = form.ansCheckbox;
        }
      });

      ansAll = ansArrayRadio.concat(ansArrayCheckBox);
    }

    const data = {
      form: {
        formId: form.formId,
        farmId: form.farmId,
        pid: form.idCard,
        name: form.name,
        tel: form.phone,
        provinceId: form.provinceId,
        amphurId: form.districtId,
        tambonId: form.subDistrictId,
        mooId: form.villageId,
        sendBy: "w",
      },
      ans: ansAll,
    };

    // console.log("data send", data);
    // return;

    MySwal.fire({
      text: `ต้องการบันทึก`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (value) => {
      if (value.isConfirmed) {
        try {
          const response = location.pathname.includes("save")
            ? await API.saveAssessRiskResult(data)
            : await API.updateAssessRiskResult(props.match.params.id, data);
          // const data = response.data
          if (response.status == 200) {
            MySwal.fire({
              text: location.pathname.includes("save")
                ? `สร้าง "บันทึกผลการประเมินความเสี่ยง" สำเร็จ`
                : `แก้ไข "บันทึกผลการประเมินความเสี่ยง" สำเร็จ`,
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then(async (result) => {
              if (result.value) {
                console.log("response", response);
                if (location.pathname.includes("save")) {
                  const dataRas = response.data.data;
                  history.push(
                    `/assess_risk/assess-risk-result/score/${dataRas.form.id}`
                  );
                } else {
                  history.push(
                    `/assess_risk/assess-risk-result/score/${props.match.params.id}`
                  );
                }
              }
            });
          }
        } catch (error) {
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: location.pathname.includes("save")
                ? `สร้าง "บันทึกผลการประเมินความเสี่ยง" ไม่สำเร็จ`
                : `แก้ไข "บันทึกผลการประเมินความเสี่ยง" ไม่สำเร็จ`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
    // console.log("toSaveAssessRisk ", form);
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2 ">
          <Link to={`/assess_risk/assess-risk-result`}>
            <button className="btn btn-danger" onClick={() => toCancel()}>
              ยกเลิก
            </button>
          </Link>
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-success"
            onClick={() => toSaveAssessRisk()}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`assess_risk/assess-risk-result/edit`)
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxHead title="ตัวกรอง" classLabel="">
            <div className=" ">
              <Row className="mb-2">
                <Col lg={2} md={4} sm={12} xs={12}>
                  <InputSelect
                    title="ชุดประเมินความเสี่ยง"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={riskSelectValue}
                    optionsList={riskList}
                    handleChange={(value) => {
                      setRiskSelectValue([value]);
                      setForm({ ...form, formId: value.value });
                      getQuestById(value.value);
                    }}
                  />
                </Col>
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="รหัสฟาร์ม"
                    imgSrc={"/svg/search.svg"}
                    imgAlt="search-icon"
                    idName="farmId"
                    value={form.farmId}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, farmId: value })
                    }
                  />
                </Col>

                <Col lg={1} md={4} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    <button
                      className="btn btn-success w-100 "
                      onClick={() => setShowFarmSearch(true)}
                    >
                      <img
                        className="mr-2 mb-1"
                        src={"/svg/search2.svg"}
                        alt="menu-icon"
                      />
                      ค้นหาฟาร์ม
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputMask
                    mask="9-9999-99999-99-9"
                    title="บัตรประชาชน"
                    idName="idCard"
                    value={form.idCard}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, idCard: value.replace(/-/g, "") })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ชื่อเจ้าของสัตว์"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputMask
                    mask="99-9999-9999"
                    title="เบอร์โทรศัพท์"
                    idName="phone"
                    value={form.phone}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, phone: value.replace(/-/g, "") })
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="เลขนิติบุคคล"
                    idName="corporate"
                    value={form.corporate}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, corporate: value })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ชื่อองค์กร/หน่วยงาน"
                    idName="orgName"
                    value={form.orgName}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, orgName: value })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    disabled={true}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    disabled={true}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    disabled={true}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="หมู่ที่"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="villageId"
                    classLabel="normal"
                    disabled={true}
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({ ...form, villageId: value.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxHead>
          <BoxCard title="2. รายการประเมิน" classNameBox="mt-3">
            {formQuest.questionAll && formQuest.questionAll.length > 0 ? (
              formQuest.questionAll.map((item, i) => {
                // console.log("formQuest.questionAll item", item);
                return (
                  <Row key={i}>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="d-flex  mt-2 "
                    >
                      <div>
                        <h6 className="mb-0 ">ข้อ {i + 1}</h6>
                      </div>
                      <div className="ml-4">
                        <h6 className="mb-0 ">{item.qName}</h6>
                      </div>
                    </Col>

                    {item.ans.length > 0 &&
                      item.ans.map((itemA, indexA) => {
                        return (
                          <Col
                            key={indexA}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="ml-5 mt-2"
                          >
                            {
                              item.qAnsTypeId == 1 ? (
                                <Form.Check
                                  type="radio"
                                  name={`nameAns${item.qId}`}
                                  id={itemA.id}
                                  value={item.qId}
                                  label={itemA.name}
                                  // checked={!!form.ansCheckRadio.find(checkA => checkA.questAnsId == itemA.id)}
                                  checked={item.ansRadio === itemA.id}
                                  onChange={(e) => {
                                    formQuest.questionAll[i].ansRadio =
                                      itemA.id;
                                    formQuest.questionAll[i].qScore =
                                      itemA.score;
                                    setFormQuest({ ...formQuest });
                                    // console.log(formQuest.questionAll[i]);
                                  }}
                                />
                              ) : (
                                <Form.Check
                                  type="checkbox"
                                  name={`nameAns${itemA.id}`}
                                  id={itemA.id}
                                  checked={
                                    !!form.ansCheckbox.find(
                                      (checkA) => checkA.questAnsId == itemA.id
                                    )
                                  }
                                  label={itemA.name}
                                  onChange={(e) =>
                                    setChooseAnsCheckBox(e, item, itemA)
                                  }
                                />
                              )

                              // <Form.Check
                              //     type="radio"
                              //     name={`nameAns${indexA + 1}`}
                              //     id={`nameAns${indexA + 1}`}
                              //     value={itemA.id}
                              //     checked={!!form.ansCheckbox.find(checkA => checkA.questAnsId == itemA.id)}
                              //     label={itemA.name}
                              //     onChange={(e) => setChooseAnsCheckBoxRadio(e)}
                              // />
                            }
                          </Col>
                        );
                      })}
                  </Row>
                );
              })
            ) : (
              <Col
                lg={12}
                md={12}
                sm={12}
                className="border text-center font14 mt-3 py-3"
              >
                ยังไม่มีข้อมูลรายการประเมิน กรุณาเพิ่มชุดประเมินความเสี่ยง
              </Col>
            )}
          </BoxCard>
        </div>

        {showFarmSearch && (
          <ModalFarmSearch
            show={showFarmSearch}
            mode={form.modeFarmSearch}
            close={() => setShowFarmSearch(false)}
            save={(value) => setForm({ ...form, farmId: value })}
          />
        )}
      </div>
    </div>
  );
}

export default AssessRisk_Result_Save;
