import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { loading } from "../../redux/actions/loadingAction";
import * as helper from "../../utils/helper";

import { Row, Col } from "react-bootstrap";

import BoxCard from "../../components/BoxCard";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "../../components/DataTable";
import * as API from "../../utils/apis";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useMasterData } from "../../components/hooks/useMasterData";

import pdfMake from "pdfmake-thai/build/pdfmake";
import pdfFonts from "pdfmake-thai/build/vfs_fonts";

function GFMReport() {
  const ref = React.createRef();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "footer"]);
  const user = useSelector((state) => state.login.result);

  const [listGFMReport, setGFMReport] = useState([]);
  // select จังหวัด อำเภอ
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  // select เขตปศุสัตว์
  const [zoneLivestockList, setZoneLivestockList] = useState([]);
  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState([]);
  // select file type to download
  const [fileType, setFileType] = useState("excel");
  const fileName = "G99RPT01-GFMFarmReport_" + helper.getDate(new Date());

  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const [isClearSearch, setIsClearSearch] = useState(false);

  const breadcrumbPath = [
    { path: "/", name: "หน้าหลัก" },
    { path: "/gfm", name: "GFM" },
    { path: "/gfm", name: "รายงาน" },
    { path: "active", name: "G99RPT01-ฟาร์ม GFM" },
  ];

  const initialForm = {
    id: "",
    statusId: "",
    remainDay: "",
    provinceId: "",
    amphurId: "",
    tambonId: "",
    mooId: "",
    certificateCode: "",
    address: "",
    soi: "",
    zoneName: "",
    zoneId: "",
    provinceName: "",
    amphurName: "",
    tambonName: "",
    mooName: "",
    farmName: "",
    farmerName: "",
    farmerTypeName: "",
    startDate: "",
    endDate: "",
    statusName: "",
    startDateName: "",
    endDateName: "",
    dateBegin: "",
    dateEnd: "",
    animalAmt: "",
    animalTypeName: "",
    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        name: "ลำดับ",
        width: "50px",
        rowSpan: 2,
      },
      {
        sortBy: "farmName",
        name: "ชื่อฟาร์ม",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "farmerName",
        name: "เจ้าของฟาร์ม",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        name: "สถานที่เก็บข้อมูล",
        sortFlg: false,
        colSpan: 5,
        childHead: [
          {
            sortBy: "zoneName",
            name: "เขต",
            sortFlg: true,
          },
          {
            sortBy: "provinceId",
            name: "จังหวัด",
            sortFlg: true,
          },
          {
            sortBy: "amphurId",
            name: "อำเภอ",
            sortFlg: true,
          },
          {
            sortBy: "tambonId",
            name: "ตำบล",
            sortFlg: true,
          },
          {
            sortBy: "address",
            name: "ที่อยู่",
            sortFlg: true,
          },
        ],
      },
      {
        sortBy: "certificateCode",
        name: "เลขที่ใบรับรอง",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "startDate",
        name: "วันที่รับรอง",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "farmerTypeName",
        name: "ประเภทเกษตกร",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "animalTypeName",
        name: "ชนิดสัตว์",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "animalAmt",
        name: "จำนวนสัตว์",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
        rowSpan: 2,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  pdfMake.fonts = {
    Roboto: {
      normal: "Roboto-Regular.ttf",
      bold: "Roboto-Medium.ttf",
      italics: "Roboto-Italic.ttf",
      bolditalics: "Roboto-MediumItalic.ttf",
    },
    THSarabunNew: {
      normal: "THSarabunNew.ttf",
      bold: "THSarabunNew-Bold.ttf",
      italics: "THSarabunNew-Italic.ttf",
      bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
  };

  useEffect(() => {
    getGFMReport();
    // getProvince();
    getZoneLivestock();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);
  const getGFMReport = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getGFMReport(
        form.dateBegin ? helper.getDate(form.dateBegin) : "",
        form.dateEnd ? helper.getDate(form.dateEnd) : "",
        form.zoneId,
        form.provinceId,
        form.amphurId,
        form.sortBy,
        form.sortDir,
        form.page,
        form.limit
      );
      const data = await response?.data;
      if (response.status == 200) {
        let gfmReportArray = [];
        data.content.forEach((element) => {
          gfmReportArray.push({
            id: element.id,
            statusId: element.statusId,
            remainDay: element.remainDay,
            provinceId: element.provinceId,
            amphurId: element.amphurId,
            tambonId: element.tambonId,
            mooId: element.mooId,
            certificateCode: element.certificateCode,
            address: element.address,
            soi: element.soi,
            zoneName: element.zoneName,
            provinceName: element.provinceName,
            amphurName: element.amphurName,
            tambonName: element.tambonName,
            mooName: element.mooName,
            farmName: element.farmName,
            farmerName: element.farmerName,
            farmerTypeName: element.farmerTypeName,
            startDate: element.startDate,
            endDate: element.endDate,
            statusName: element.statusName,
            startDateName: element.startDateName,
            endDateName: element.endDateName,
            animalAmt: element.animalAmt,
            animalTypeName: element.animalTypeName,
          });
        });
        setGFMReport(gfmReportArray);

        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setZoneLivestockSelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setProvinceList([]);
    setDistrictList([]);
    setStatusSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const getFilter = (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const getStatusColor = (idStatus, checkReturn) => {
    let colorStatus = "";
    let statusName = "";
    if (idStatus) {
      if (idStatus == 1) {
        colorStatus = "#25B881";
        statusName = "รับรอง";
      }
      if (idStatus == 2) {
        colorStatus = "#FFC107";
        statusName = "ต่ออายุ";
      }
      if (idStatus == 3) {
        colorStatus = "#FF482F";
        statusName = "หมดอายุ";
      }

      if (checkReturn === "getName") {
        return statusName;
      } else if (idStatus) {
        return (
          <div
            style={{ background: colorStatus, color: "#fff" }}
            className="box-status font14 px-2 text-center"
          >
            <span>{statusName}</span>
          </div>
        );
      } else {
        return "-";
      }
    } else {
      return <div>-</div>;
    }
  };

  const buttonSearch = () => {
    return (
      <>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const convertListToExcelTable = (objArray) => {
    let excelTable = [];
    objArray.map((element, index) => {
      excelTable.push({
        ลำดับ: index + 1,
        ชื่อฟาร์ม: element.farmName,
        เจ้าของฟาร์ม: element.farmerName,
        เขต: element.zoneName,
        จังหวัด: element.provinceName,
        อำเภอ: element.amphurName,
        ตำบล: element.tambonName,
        ที่อยู่: element.address,
        เลขที่ใบรับรอง: element.certificateCode,
        วันที่รับรอง: element.startDateName,
        ประเภทเกษตกร: element.farmerTypeName,
        ชนิดสัตว์: element.animalTypeName,
        จำนวนสัตว์: element.animalAmt,
        สถานะ: element.statusName,
      });
    });
    return excelTable;
  };

  const convertToCSV = (objArray) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let headCol = "";
    let dataRow = "";
    let key = Object.keys(array[0]);
    key.forEach((item) => {
      if (headCol === "") {
        headCol = item;
      } else {
        if (item != "") {
          headCol += ",";
          headCol += item;
        }
      }
    });
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") {
          line += ",";
        }
        line += array[i][index];
      }
      dataRow += line + "\r\n";
    }
    return headCol + "\r\n" + dataRow;
  };

  const convertToExcel = (objArray) => {
    const workSheet = XLSX.utils.json_to_sheet(objArray);
    const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    return XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  };

  const printPDF = (fileName) => {
    const head = [
      { text: "ลำดับ", style: "tableHeader", rowSpan: 2, alignment: "center" },
      {
        text: "ชื่อฟาร์ม",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "เจ้าของฟาร์ม",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "สถานที่เก็บข้อมูล",
        style: "tableHeader",
        colSpan: 5,
        alignment: "center",
      },
      {},
      {},
      {},
      {},
      {
        text: "เลขที่ใบรับรอง",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "วันที่รับรอง",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "ประเภทเกษตกร",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "ชนิดสัตว์",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "จำนวนสัตว์",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      { text: "สถานะ", style: "tableHeader", rowSpan: 2, alignment: "center" },
    ];
    const subHead = [
      "",
      "",
      "",
      { text: "เขต", style: "tableHeader", alignment: "center" },
      { text: "จังหวัด", style: "tableHeader", alignment: "center" },
      { text: "อำเภอ", style: "tableHeader", alignment: "center" },
      { text: "ตำบล", style: "tableHeader", alignment: "center" },
      { text: "ที่อยู่", style: "tableHeader", alignment: "center" },
      "",
      "",
      "",
      "",
      "",
      "",
    ];

    let listGFMPdf = [];
    if (listGFMReport && listGFMReport.length > 0) {
      listGFMReport.map((item, i) => {
        let updatation = "";
        if (
          item.updatePrefixName ||
          item.updateFirstName ||
          item.updateLastName
        ) {
          updatation =
            item.updatePrefixName +
            " " +
            item.updateFirstName +
            " " +
            item.updateLastName;
        }
        listGFMPdf.push([
          i + 1,
          item.farmName,
          item.farmerName,
          item.zoneName,
          item.provinceName,
          item.amphurName,
          item.tambonName,
          item.address,
          item.certificateCode,
          item.startDateName,
          item.farmerTypeName,
          item.animalTypeName,
          item.animalAmt,
          item.statusId ? getStatusColor(item.statusId, "getName") : "",
        ]);
      });
    }
    let bodyContent = [head, subHead];
    listGFMPdf.map((item, index) => {
      bodyContent.push(listGFMPdf[index]);
    });
    const cellWidhtHeight = [
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
    ];
    const context = {
      content: [
        {
          table: {
            widths: cellWidhtHeight,
            heights: cellWidhtHeight,
            headerRows: 2,
            body: bodyContent,
          },
        },
      ],
      defaultStyle: {
        fontSize: 9,
        font: "THSarabunNew",
      },
      styles: {
        tableHeader: {
          bold: true,
          fontSize: 9,
          color: "black",
        },
      },
    };
    pdfMake.createPdf(context).download(fileName + ".pdf");
  };

  const downloadRecord = () => {
    let type = "";
    if (fileType === "excel") {
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = convertToExcel(
        convertListToExcelTable(listGFMReport)
      );
      const data = new Blob([excelBuffer], { type: type });
      FileSaver.saveAs(data, fileName + ".xlsx");
    }
    if (fileType === "csv") {
      type = "text/csv;charset=utf-8";
      const csv = convertToCSV(convertListToExcelTable(listGFMReport));
      const csvData = new Blob([csv], { type: type });
      FileSaver.saveAs(csvData, fileName + ".csv");
    }
    if (fileType === "pdf") {
      printPDF(fileName);
    }
  };

  const buttonExport = () => {
    return (
      <>
        <button
          className="btn btn-report"
          onClick={() => downloadRecord()}
          disabled={user?.userGroup.mexport === "n"}
        >
          <a className="text-white">
            <img
              className="nav-icon"
              src={"/svg/report.svg"}
              alt="export-icon"
            />{" "}
            ออกรายงาน
          </a>
        </button>
      </>
    );
  };

  const checkExport = (value) => {
    if (fileType === value) {
      return true;
    } else {
      return false;
    }
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td className="text-center">{item.farmName}</td>
          <td className="text-center">{item.farmerName}</td>
          <td className="text-center">{item.zoneName}</td>
          <td className="text-center">{item.provinceName}</td>
          <td className="text-center">{item.amphurName}</td>

          <td className="text-center">{item.tambonName}</td>
          <td className="text-center">{item.address}</td>
          <td className="text-center">{item.certificateCode}</td>
          <td className="text-center">{item.startDateName}</td>
          <td className="text-center">{item.farmerTypeName}</td>
          <td className="text-center">{item.animalTypeName}</td>
          <td className="text-center">{item.animalAmt}</td>

          <td className="text-center">
            {item.statusId ? getStatusColor(item.statusId) : "-"}
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={14} />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่บันทึกข้อมูล"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateBegin}
                    star={false}
                    handleChange={(value) => {
                      let sDate = new Date(value);
                      setForm({ ...form, dateBegin: sDate.getTime() });
                    }}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.dateEnd}
                    star={false}
                    handleChange={(value) => {
                      let eDate = new Date(value);
                      setForm({ ...form, dateEnd: eDate.getTime() });
                    }}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขตปศุสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={zoneLivestockSelectValue}
                    optionsList={zoneLivestockList}
                    handleChange={(value) => {
                      setZoneLivestockSelectValue([value]);
                      setForm({ ...form, zoneId: value.value });
                      getProvince(value.value);
                    }}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => {
                      setDistrictSelectValue(value);
                      setForm({ ...form, amphurId: value.value });
                    }}
                  />
                </Col>
              </Row>

              <div className="mb-2" style={{ fontWeight: "normal" }}>
                เลือกรูปแบบ
              </div>
              <Row>
                <Col lg={1} md={6} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="excel"
                      name="data-export"
                      checked={checkExport("excel")}
                      onChange={() => setFileType("excel")}
                    />{" "}
                    EXCEL
                  </label>
                </Col>

                <Col lg={1} md={6} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="pdf"
                      name="data-export"
                      checked={checkExport("pdf")}
                      onChange={() => setFileType("pdf")}
                    />{" "}
                    PDF
                  </label>
                </Col>

                <Col lg={1} md={6} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="csv"
                      name="data-export"
                      checked={checkExport("csv")}
                      onChange={() => setFileType("csv")}
                    />{" "}
                    CSV
                  </label>
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการข้อมูลรายงาน G99RPT01-ฟาร์ม GFM"
            headRight={buttonExport()}
          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
              id={"render-data-table"}
              reference={ref}
            >
              {renderTable(listGFMReport)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}
export default GFMReport;
