import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../utils/apis";
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import { useMasterData } from "../components/hooks/useMasterData";

function ModalSetArea({ show, close, save, mode, dataAnimal, dataIndex }) {
  const user = useSelector((state) => state.login.result);

  const initialForm = {
    chooseSelect: false,
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    countyId: "",
    countyName: "",
    details: "",
    number: "",
  };
  const [form, setForm] = useState(initialForm);

  const initInvalidMsg = {
    province: "",
    district: "",
    subDistrict: "",
    village1: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  // select เขต
  const [countySelectValue, setCountySelectValue] = useState([]);
  const [countyList, setCountyList] = useState([]);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);
  // =================
  const [provinceByZoneId, setProvinceByZoneId] = useState(true);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData("zoneLivestock", countyList, setCountyList, provinceByZoneId);
  console.log("provinceList==🤟", provinceList, provinceByZoneId);

  const handleClose = () => {
    close();
    setForm(initialForm);
    setCountySelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setVillageSelectValue([]);
    setSubDistrictSelectValue([]);
  };

  const toSave = async () => {
    await save(form, dataIndex);
    handleClose();
  };

  useEffect(async () => {
    // await getProvince()
    await getZoneLivestock();
  }, []);

  useEffect(async () => {
    if ((mode == "edit" || mode == "duplicate") && !form.chooseSelect) {
      await getDataById(dataAnimal);
    }
  }, [
    user,
    mode,
    dataAnimal,
    show,
    countyList,
    villageList,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
  ]);

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          if (element.zoneId == countyId) {
            provincesArray.push({
              value: element.id,
              label: element.name,
            });
          }
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZoneLivestock = async (id) => {
    try {
      const response = await API.getZoneLivestock("", id);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setCountyList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueCounty = (value) => {
    setCountySelectValue([value]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setProvinceList([]);
    setDistrictList([]);
    setSubDistrictList([]);
    setVillageList([]);
    setForm({
      ...form,
      countyId: value.value,
      countyName: value.label,
      provinceId: "",
      zipcode: "",
      districtId: "",
      chooseSelect: true,
    });
    getProvince(value.value);
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([value]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setDistrictList([]);
    setSubDistrictList([]);
    setVillageList([]);
    setForm({
      ...form,
      provinceId: value.value,
      zipcode: "",
      districtId: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([value]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setSubDistrictList([]);
    setVillageList([]);
    setForm({
      ...form,
      districtId: value.value,
      zipcode: "",
      subDistrictId: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([value]);
    setForm({
      ...form,
      subDistrictId: value.value,
      zipcode: value.zipcode,
      villageId: "",
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    setVillageList([]);
    getVillage(value.value);
  };

  const getDataById = async () => {
    if (dataAnimal) {
      if (dataAnimal.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataAnimal.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataAnimal.provinceId);
        }
      }

      if (dataAnimal.districtId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataAnimal.districtId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataAnimal.districtId);
        }
      }

      if (dataAnimal.subDistrictId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataAnimal.subDistrictId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataAnimal.subDistrictId);
        }
      }

      if (dataAnimal.villageId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataAnimal.villageId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataAnimal.countyName) {
        let countySelect = await countyList.find(
          (item) => item.label == dataAnimal.countyName
        );
        setCountySelectValue(countySelect);
      }

      setForm({
        ...form,
        countyId: dataAnimal.countyId,
        countyName: dataAnimal.countyName,
        number: dataAnimal.number,
        details: dataAnimal.details,
        provinceId: dataAnimal.provinceId,
        districtId: dataAnimal.districtId,
        subDistrictId: dataAnimal.subDistrictId,
        villageId: dataAnimal.villageId,
      });
    }
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header closeButton className="pb-2 underline">
        <Modal.Title className="mt-2">
          <h5>
            <strong>กำหนดพื้นที่</strong>
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <InputSelect
              title="เขต"
              star={false}
              placeholder="กรุณาเลือก"
              idName="county"
              classLabel="normal"
              selectValue={countySelectValue}
              optionsList={countyList}
              handleChange={(value) => {
                selectValueCounty(value);
              }}
            />
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <InputSelect
              title="จังหวัด"
              star={false}
              placeholder="กรุณาเลือก"
              idName="province"
              classLabel="normal"
              selectValue={provinceSelectValue}
              optionsList={provinceList}
              handleChange={(value) => selectValueProvince(value)}
            />
          </Col>

          <Col lg={4} md={4} sm={12} xs={12}>
            <InputSelect
              title="อำเภอ/เขต"
              star={false}
              placeholder="กรุณาเลือก"
              idName="title"
              classLabel="normal"
              selectValue={districtSelectValue}
              optionsList={districtList}
              handleChange={(value) => selectValueDistrict(value)}
            />
          </Col>

          <Col lg={4} md={4} sm={12} xs={12}>
            <InputSelect
              title="ตำบล/แขวง"
              star={false}
              placeholder="กรุณาเลือก"
              idName="subDistrict"
              classLabel="normal"
              selectValue={subDistrictSelectValue}
              optionsList={subDistrictList}
              handleChange={(value) => selectValueSubDistrict(value)}
            />
          </Col>
          <Col lg={4} md={4} sm={6} xs={6}>
            <InputSelect
              title="หมู่ที่/หมู่บ้าน"
              star={false}
              placeholder="กรุณาเลือก"
              idName="villageId"
              classLabel="normal"
              selectValue={villageSelectValue}
              optionsList={villageList}
              handleChange={(value) => {
                setVillageSelectValue([value]);
                setForm({ ...form, villageId: value.value });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <InputText
              type="text"
              as="textarea"
              rows={2}
              title="รายละเอียด/หน่วยงาน"
              idName="details"
              value={form.details}
              star={false}
              placeholder="โปรดระบุ"
              classLabel="normal"
              handleChange={(value) => setForm({ ...form, details: value })}
            />
          </Col>

          <Col lg={4} md={4} sm={12} xs={12}>
            <InputText
              type="number"
              title="จำนวนตัวอย่าง"
              idName="number"
              value={form.number}
              star={false}
              placeholder="ระบุจำนวน"
              classLabel="normal"
              handleChange={(value) => setForm({ ...form, number: value })}
            />
          </Col>
        </Row>

        <div className="mr-2 mt-3 mb-2">
          <div className="text-right py-2">
            <button
              className="btn btn-danger px-4 mr-2"
              onClick={() => handleClose()}
            >
              ยกเลิก
            </button>
            <button className="btn btn-success px-4" onClick={() => toSave()}>
              บันทึก
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalSetArea;
