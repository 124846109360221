import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import BoxCard from "../../components/BoxCard";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import Breadcrumb from "../../components/Breadcrumb";

import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";

import Report_Daily_PDF from "../../components/Report_Daily_PDF";
import Report_Daily_Doc from "../../components/Report_Daily_Doc";
import { useDispatch } from "react-redux";
import { loading } from "../../redux/actions/loadingAction";

function Report_daily() {
  const { t } = useTranslation(["common", "footer"]);

  const initialForm = {
    date_from: "",
    date_to: "",
    diseaseTypeId: "",
    diseaseTypeName: "",
  };
  const [form, setForm] = useState(initialForm);

  const [fileType, setFileType] = useState("pdf");
  const fileName = "C03RPT04-DiseaseStatement_" + helper.getDate(new Date());

  const [isClearSearch, setIsClearSearch] = useState(false);

  const [diseaseSelectValue, setDiseaseSelectValue] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);

  const [dataAll, setDataAll] = useState([]);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "title", name: "รายงาน" },
    { path: "active", name: "รายงาน B99RPT08-รายงานภาพรวมรายวัน" },
  ];

  useEffect(() => {
    getDiseaseType();
    setIsClearSearch(false);
  }, [isClearSearch === true]);

  useEffect(() => {}, [dataAll]);

  const getDailyReportById = async (dateFrom, dateTo, diseaseTypeId) => {
    try {
      const response =
        diseaseTypeId === ""
          ? { status: 400, data: "" }
          : await API.getDailyReport(dateFrom, dateTo, diseaseTypeId);
      const data = await response?.data;
      if (response.status === 200) {
        setDataAll({
          ...data,
          dateFrom: form.date_from,
          dateTo: form.date_to,
          diseaseTypeName: form.diseaseTypeName,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType();
      const data = await response?.data;
      if (response.status === 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseaseList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkExport = (value) => {
    if (fileType === value) {
      return true;
    } else {
      return false;
    }
  };

  const clearSearch = () => {
    let hold = fileType;
    if (fileType == "pdf") {
      setFileType("word");
    }
    if (fileType == "word") {
      setFileType("pdf");
    }
    setTimeout(() => {
      setFileType(hold);
    }, 1);

    setForm(initialForm);
    setDataAll([]);
    setDiseaseSelectValue([]);
    setIsClearSearch(true);
  };

  const toSearch = () => {
    // clearSearch()
    let dateForm = "";
    let dateTo = "";
    if (form.date_from) {
      dateForm = helper.getDate(form.date_from);
    }

    if (form.date_to) {
      dateTo = helper.getDate(form.date_to);
    }
    getDailyReportById(dateForm, dateTo, form.diseaseTypeId);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 py-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <Row>
              <Col lg={3} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="วันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.date_from}
                  star={false}
                  handleChange={(value) => {
                    let date_from = new Date(value);
                    setForm({ ...form, date_from: date_from.getTime() });
                  }}
                />
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.date_to}
                  star={false}
                  handleChange={(value) => {
                    let date_to = new Date(value);
                    setForm({ ...form, date_to: date_to.getTime() });
                  }}
                />
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ชนิดโรค"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={diseaseSelectValue}
                  optionsList={diseaseList}
                  handleChange={(value) => {
                    setDiseaseSelectValue([value]);
                    setForm({
                      ...form,
                      diseaseTypeId: value.value,
                      diseaseTypeName: value.label,
                    });
                  }}
                />
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <div className="mb-2 normal">เลือกรูปแบบ</div>
                <div>
                  <label className="mr-2 normal">
                    <input
                      type="radio"
                      value="pdf"
                      name="data-export"
                      checked={checkExport("pdf")}
                      onChange={() => setFileType("pdf")}
                    />{" "}
                    PDF
                  </label>

                  <label className="normal">
                    <input
                      type="radio"
                      value="word"
                      name="data-export"
                      checked={checkExport("word")}
                      onChange={() => setFileType("word")}
                    />{" "}
                    Microsoft Word
                  </label>
                </div>
              </Col>
            </Row>
          </BoxCard>
          {fileType === "pdf" ? (
            <div id="report-daily">
              <Report_Daily_PDF dataAll={dataAll} />
            </div>
          ) : (
            <div>
              <Report_Daily_Doc dataAll={dataAll} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Report_daily;
