import React, { useState, useEffect } from "react";
import InputText from "./InputText";
import BoxCard from "./BoxCard";
import { Row, Col, Card } from "react-bootstrap";
import { useSelector } from "react-redux";

function OutbreakStep5({ save, dataAll, modeGet }) {

  const user = useSelector((state) => state.login.result);

  const initialForm = {
    quarantine: "",
    period: "",
    evidence: "",
    treatment_animal: "",
    bury: "",
    burn: "",
    throw_away: "",
    send_kill: "",
    disinfect: "",
    other_disinfect: "",
    vaccine: "",
    drop_anti_insect: "",
    inject_anti_insect: "",
    deal_anti_insect: "",
    public_relations: "",

    file: "",
    fileName: "",

    data_file: [],
  };

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    if (modeGet == "getDisease" && !form.chooseSelect) {
      getDataById();
    }
    if (modeGet == "getById" && !form.chooseSelect) {
      getDataById();
    }
    if (modeGet == "dsGetById" && !form.chooseSelect) {
      getDataById();
    }
    if (modeGet == "farmById" && !form.chooseSelect) {
      getDataById();
    }
  }, [
    user,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
    form.animalId,
  ]);

  useEffect(() => {
    toSave();
  }, [form, modeGet]);

  const toSave = async () => {
    await save(form);
  };

  const getDataById = async () => {
    if (dataAll) {
      setForm({
        ...form,
        quarantine: dataAll.quarantine,
        period: dataAll.period,
        evidence: dataAll.evidence,
        treatment_animal: dataAll.treatment_animal,
        bury: dataAll.bury,
        burn: dataAll.burn,
        throw_away: dataAll.throw_away,
        send_kill: dataAll.send_kill,
        disinfect: dataAll.disinfect,
        other_disinfect: dataAll.other_disinfect,
        vaccine: dataAll.vaccine,
        drop_anti_insect: dataAll.drop_anti_insect,
        inject_anti_insect: dataAll.inject_anti_insect,
        deal_anti_insect: dataAll.deal_anti_insect,
        public_relations: dataAll.public_relations,
      });
    }
  };

  return (
    <div className=" p-4 bg-white ">
      <BoxCard
        classNameBox=""
        title="5.1 การดำเนินการ Outbreak (รวมการรายงานโรคที่อยู่ใน Outbreak)"
      >
        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className=" mr-2 ">1.</p>
              <div className="ml-3">
                <p>การกักสัตว์ป่วย</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2">
              <div className="d-flex ml-3">
                <p className="mt-2 mr-2">กักสัตว์ (ตัว)</p>
                <InputText
                  disabled
                  type="number"
                  title=""
                  idName="quarantine"
                  value={form.quarantine || 0}
                  star={false}
                  classLabel="normal"
                />
              </div>

              <div className="d-flex ml-3">
                <p className="mt-2 mr-2">ระยะเวลา (วัน)</p>
                <InputText
                  disabled
                  type="number"
                  title=""
                  idName="evidence"
                  value={form.period || 0}
                  star={false}
                  classLabel="normal"
                />
              </div>
            </div>

            <div className="d-flex align-items-center flex-wrap ">
              <div className="ml-3">
                <p className="mb-1">หลักฐานบันทึกสั่งกัก</p>
              </div>
            </div>

            <div className="d-flex align-items-center mb-2">
              <p className="mr-3 none-lg"></p>
              <div className="ml-3 border w-75 font14 text-gray p-2">
                {form.evidence && form.evidence.length > 0
                  ? form.evidence.map((item, i) =>
                    <h6 key={i} className="mb-0 mt-1 link"
                      onClick={() => { item.id ? window.open(item.url, '_blank').focus() : window.open("", '_blank').focus()}}>{`${++i}. ${item.name}`}</h6>
                  )
                  : "ไม่มีไฟล์"}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className="mr-2 ">2.</p>
              <div className="ml-3">
                <p>การรักษาสัตว์ป่วย</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-3">จำนวน (ตัว)</p>
              <InputText
                disabled
                type="number"
                title=""
                idName="treatment_animal"
                value={form.treatment_animal || 0}
                star={false}
                classLabel="normal"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className=" mr-2 ">3.</p>
              <div className="ml-3">
                <p>การทำลายสัตว์ป่วย</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <div className="d-flex flex-wrap mr-3">
                <p className="mt-2 mr-2">ฝังกลบ (ตัว)</p>
                <div className="d-flex ">
                  <p className="mt-2 mr-2">จำนวน</p>
                  <InputText
                    disabled
                    type="number"
                    title=""
                    idName="bury"
                    value={form.bury || 0}
                    star={false}
                    classLabel="normal"
                  />
                </div>
              </div>

              <div className="d-flex flex-wrap">
                <p className="mt-2 mr-5">เผา (ตัว)</p>
                <div className="d-flex ">
                  <p className="mt-2 mr-2">จำนวน</p>
                  <InputText
                    disabled
                    type="number"
                    title=""
                    idName="burn"
                    value={form.burn || 0}
                    star={false}
                    classLabel="normal"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center flex-wrap mt-2 ml-3 ">
              <div className="d-flex flex-wrap mr-3">
                <p className="mt-2 mr-3">คัดทิ้ง (ตัว)</p>
                <div className="d-flex">
                  <p className="mt-2 mr-2">จำนวน</p>
                  <InputText
                    disabled
                    type="number"
                    title=""
                    idName="throw_away"
                    value={form.throw_away || 0}
                    star={false}
                    classLabel="normal"
                  />
                </div>
              </div>

              <div className="d-flex flex-wrap ">
                <p className="mt-2 mr-3">ส่งโรงฆ่า (ตัว)</p>
                <div className="d-flex">
                  <p className="mt-2 mr-2">จำนวน</p>
                  <InputText
                    disabled
                    type="number"
                    title=""
                    idName="send_kill"
                    value={form.send_kill || 0}
                    star={false}
                    classLabel="normal"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center  mt-3">
              <p className=" mr-2 ">4.</p>
              <div className="ml-3">
                <p>การทำความสะอาดและฆ่าเชื้อ</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p>วิธีทำลาย</p>
              <div className="d-flex flex-wrap ml-4">
                <p className="mt-2 mr-2">1.พ่นยาฆ่าเชื้อทำลายเชื้อโรค</p>
                <p className="mt-2 mr-2">จำนวน (ราย)</p>
                <InputText
                  disabled
                  type="number"
                  title=""
                  idName="disinfect"
                  value={form.disinfect || 0}
                  star={false}
                  classLabel="normal"
                />
              </div>
            </div>

            <div className="d-flex align-items-center mt-2 ml-5">
              <div className="d-flex flex-wrap ml-5">
                <p className="mt-2 mr-2">2.อื่นๆ</p>
                <InputText
                  disabled
                  type="text"
                  title=""
                  idName="other_disinfect"
                  value={form.other_disinfect || '-'}
                  star={false}
                  classLabel="normal"
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className=" mr-2 ">5.</p>
              <div className="ml-3">
                <p>การฉีดวัคซีน</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-3">จำนวน (ตัว)</p>
              <InputText
                disabled
                type="number"
                title=""
                idName="vaccine"
                value={form.vaccine || 0}
                star={false}
                classLabel="normal"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className=" mr-2 ">6.</p>
              <div className="ml-3">
                <p>หยดหรือราดยาป้องกันแมลง</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-3">จำนวน (ตัว)</p>
              <InputText
                disabled
                type="number"
                title=""
                idName="drop_anti_insect"
                value={form.drop_anti_insect || 0}
                star={false}
                classLabel="normal"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center  mt-3">
              <p className=" mr-2 ">7.</p>
              <div className="ml-3">
                <p>พ่นยาป้องกันแมลง</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-3"> จำนวน (ตัว)</p>
              <InputText
                disabled
                type="number"
                title=""
                idName="inject_anti_insect"
                value={form.inject_anti_insect || 0}
                star={false}
                classLabel="normal"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center mt-3">
              <p className=" mr-2 ">8.</p>
              <div className="ml-3">
                <p>แจกยากำจัดแมลงแก่เกษตรกร</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-2">จำนวน (ราย)</p>
              <InputText
                disabled
                type="number"
                title=""
                idName="deal_anti_insect"
                value={form.deal_anti_insect || 0}
                star={false}
                classLabel="normal"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={12}>
            <div className="d-flex align-items-center  mt-3">
              <p className=" mr-2 ">9.</p>
              <div className="ml-3">
                <p>ประชาสัมพันให้ความรู้แก่เกษตรกร</p>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={12}>
            <div className="d-flex align-items-center flex-wrap mt-2 ml-3">
              <p className="mr-2"> จำนวน (ราย)</p>
              <InputText
                disabled
                type="number"
                title=""
                idName="public_relations"
                value={form.public_relations || 0}
                star={false}
                classLabel="normal"
              />
            </div>
          </Col>
        </Row>
      </BoxCard>
    </div>
  );
}

export default OutbreakStep5;
