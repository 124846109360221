import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import { Modal, Row, Col } from "react-bootstrap";
import InputText from "../InputText";
import { useDispatch, useSelector } from "react-redux";
import InputSelect from "../InputSelect";
import { useLocation } from "react-router-dom";
import ModalMap from "../Modal.Map";
import * as API from "../../utils/apis";
import { useMasterData } from "../../components/hooks/useMasterData";

function ModalOrganizes({ show, close, save, mode, editForm }) {
  const user = useSelector((state) => state.login.result);
  const initialForm = {
    organizeId: "",
    organizeName: "",
    organizeNameEn: "",
    levelId: "",
    code: "",
    latitude: "",
    longitude: "",
    parentId: "",
    statusId: "",
    zipcode: "",
    zoneId: "",
    page: 1,
    sortDir: "DESC",
    sortBy: "id",

    checkShow: false,
  };
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const initShowMap = false;
  const initOrganizeSelectValue = [];
  const selectProvinceList = [];
  const initProvinceSelectValue = [];
  const initDistrictSelectValue = [];
  const initSubDistrictSelectValue = [];
  const selectDistrictList = [];
  const selectSubDistrictList = [];

  const location = useLocation();

  const [form, setForm] = useState(initialForm);
  const [modalHeader, setModalHeader] = useState("");
  const [invalid, setInvalid] = useState({});
  const [showMap, setShowMap] = useState(initShowMap);
  const [organizeSelectValue, setOrganizeSelectValue] = useState(
    initOrganizeSelectValue
  );
  const selectOrganizeList = [];
  const [organizeList, setOrganizeList] = useState(selectOrganizeList);
  const initZoneLivestockSelectValue = [];
  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState(
    initZoneLivestockSelectValue
  );
  const selectZoneLiveStockList = [];
  const [zoneLivestockList, setZoneLivestockList] = useState(
    selectZoneLiveStockList
  );

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState(
    initProvinceSelectValue
  );
  const [provinceList, setProvinceList] = useState(selectProvinceList);
  const [districtSelectValue, setDistrictSelectValue] = useState(
    initDistrictSelectValue
  );
  const [districtList, setDistrictList] = useState(selectDistrictList);
  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState(
    initSubDistrictSelectValue
  );
  const [subDistrictList, setSubDistrictList] = useState(selectSubDistrictList);

  // levelId
  const selectLevelList = [
    { value: 1, label: "ระดับกรม" },
    { value: 2, label: "ระดับเขตปศุสัตว์" },
    { value: 3, label: "ระดับจังหวัด" },
    { value: 4, label: "ระดับอำเภอ" },
    { value: 5, label: "ระดับตำบล" },
  ];
  const [levelList, setLevelList] = useState(selectLevelList);
  const initLevelIdSelectValue = [];
  const [levelIdSelectValue, setLevelIdSelectValue] = useState(
    initLevelIdSelectValue
  );

  // disable
  const initDisabled = true;
  const initDisableForm = 1;
  const [organizeDisabled, setZoneDisabled] = useState(initDisabled);
  const [provinceDisabled, setProvinceDisabled] = useState(initDisabled);
  const [districtDisabled, setDistrictDisabled] = useState(initDisabled);
  const [subDistrictDisabled, setSubDistrictDisabled] = useState(initDisabled);
  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const [statusList, setStatusList] = useState(selectStatusList);

  const [provinceByZoneId, setProvinceByZoneId] = useState(true);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );

  useEffect(async () => {
    // await getProvince();
    getOrganizes();

    if (mode === "add") {
      getZoneLivestock();
      setModalHeader("เพิ่มข้อมูลหน่วยงาน");
      setDisableForm(initDisableForm);
    }
    if (mode === "edit" && !form.checkShow) {
      setModalHeader("แก้ไขข้อมูลหน่วยงาน");
      if (editForm.levelId && editForm.levelId < 6) {
        let lev = levelList.find((item) => item.value === editForm.levelId);
        setDisableForm(lev.value);
        setLevelIdSelectValue(lev);
      }
      setForm({
        ...form,
        organizeId: editForm.id,
        organizeName: editForm.name,
        organizeNameEn: editForm.nameEn,
        latitude: editForm.latitude,
        longitude: editForm.longitude,
        code: editForm.code,
        levelId: editForm.levelId,
        parentId: editForm.parentId,
        statusId: editForm.statusId,
        zipcode: editForm.zipcode,
        statusId: editForm.statusId,
      });
    }
  }, [mode]);

  useEffect(async () => {
    if (levelIdSelectValue.value > 1) {
      //call getOrganizes(levelId: levelId-1)
      getOrganizes(levelIdSelectValue.value);
    }
  }, [levelIdSelectValue]);

  const validate = () => {
    let validated = true;

    if (form.status === "") {
      addInvalid("status", "กรุณาเลือกสถานะ");
      validated = false;
    }

    return validated;
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const handleClose = () => {
    close();
    setInvalid({});
    setForm(initialForm);
    setDisableForm(initDisableForm);
  };

  const toConfirm = async () => {
    if (validate()) {
      await save({ organize: form, mode: mode });
      handleClose();
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", "1", "", "1", 100);
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
            zoneId: element.zoneId,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
        if (editForm?.code && mode === "edit") {
          if (editForm.levelId > 2) {
            let proveCode = editForm.code;
            let pcode = proveCode.toString().slice(0, 2) + "000000";
            let selectProvince = provincesArray.find(
              (item) => Number(item.value) === Number(pcode)
            );
            setProvinceSelectValue(selectProvince);
            selectValueProvince(selectProvince);
            getZoneLivestock(selectProvince.zoneId);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, "1");
      const data = await response.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);

        if (editForm.code) {
          let code = editForm.code;
          let distCode = code.toString().slice(0, 4) + "0000";
          let districtSelect = districtArray.find(
            (item) => Number(item.value) === Number(distCode)
          );
          setDistrictSelectValue(districtSelect);
          selectValueDistrict(districtSelect);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, "1", "1");
      const data = await response.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
        if (editForm.code && mode === "edit") {
          let code = editForm.code;
          let subDistCode = code.toString();
          let subDistrictSelect = subDistrictArray.find(
            (item) => Number(item.value) === Number(subDistCode)
          );
          setSubDistrictSelectValue(subDistrictSelect);
          selectValueSubDistrict(subDistrictSelect);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOrganizes = async (id = "") => {
    let levelId;
    if (mode === "edit") {
      levelId = editForm.levelId - 1;
    } else {
      levelId = id - 1;
    }
    try {
      const response = await API.getOrganizes(
        levelId,
        form.parentId,
        form.organizeId,
        form.statusId,
        form.page,
        "1000",
        "",
        form.sortBy,
        form.sortDir
      );
      const data = await response.data;
      if (response.status === 200) {
        let organizeArray = [];
        data.content.forEach((element) => {
          organizeArray.push({
            value: element.id,
            label: element.name,
            parentId: element.parentId,
          });
        });
        setOrganizeList(organizeArray);
        if (editForm?.statusId && mode === "edit") {
          let statusSelect = statusList.find(
            (item) => item.value === editForm.statusId
          );
          setStatusSelectValue(statusSelect);
        }
        if (mode === "edit" && editForm.levelId > 1) {
          let selectOrg = organizeArray.find(
            (item) => item.value === editForm.parentId
          );
          setOrganizeSelectValue(selectOrg);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getZoneLivestock = async (zoneId = "") => {
    try {
      const response = await API.getZoneLivestock();
      const data = await response.data;
      if (response.status === 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
        if (mode === "edit") {
          let selectZone = zoneArray.find((item) => item.value === zoneId);
          setZoneLivestockSelectValue(selectZone);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setSubDistrictList([]);
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    getSubDistricts(value.value);
    if (mode === "edit" && subDistrictList) {
      let code = form.code;
      let subDistCode = code.toString();
      let subDistrictSelect = subDistrictList.find(
        (item) => item.value === subDistCode
      );
      if (subDistrictSelect) {
        setSubDistrictSelectValue(subDistrictSelect);
        selectValueSubDistrict(subDistrictSelect);
      }
    }
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
  };
  //Call LevelId
  const selectValueLevel = async (val) => {
    //ถ้าไม่ใช่ระดับ 1 กรมหน่วยงาน
    if (val.value > 1) {
      setLevelIdSelectValue([{ value: val.value, label: val.label }]);
      setOrganizeSelectValue([]);
      setForm({ ...form, levelId: val.value });
      getZoneLivestock();
    }
  };

  const toMap = () => {
    setShowMap(true);
    if (location.pathname.includes("edit")) {
      setForm({ ...form, modeMap: "edit" });
    } else {
      setForm({ ...form, modeMap: "add" });
    }
  };

  const setShowDropDown = (arg1, arg2, arg3, arg4) => {
    //set Disabled state
    setZoneDisabled(arg1);
    setProvinceDisabled(arg2);
    setDistrictDisabled(arg3);
    setSubDistrictDisabled(arg4);

    //clear form
    if (arg1) {
      setOrganizeSelectValue([]);
      setZoneLivestockSelectValue([]);
      setProvinceSelectValue([]);
      setDistrictSelectValue([]);
      setSubDistrictSelectValue([]);
      setForm({ ...form, zoneId: "", code: "" });
    }
    if (arg2) {
      setZoneLivestockSelectValue([]);
      setProvinceSelectValue([]);
      setDistrictSelectValue([]);
      setSubDistrictSelectValue([]);
    }
    if (arg3) {
      setDistrictSelectValue([]);
      setSubDistrictSelectValue([]);
    }
    if (arg4) {
      setSubDistrictSelectValue([]);
    }
  };

  const setDisableForm = (value) => {
    if (value === 1) {
      setShowDropDown(true, true, true, true);
    }
    if (value === 2) {
      setShowDropDown(false, true, true, true);
    }
    if (value === 3) {
      setShowDropDown(false, false, true, true);
    }
    if (value === 4) {
      setShowDropDown(false, false, false, true);
    }
    if (value === 5) {
      setShowDropDown(false, false, false, false);
    }
  };

  const filterProvince = (data) => {
    //check zone
    return data.filter((item) => {
      return item.zoneId === form.zoneId;
    });
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header closeButton className="pb-2 ">
        <Modal.Title className="mt-2">
          <h5>
            <strong>{modalHeader}</strong>
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <div className="">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item tab">
              <a
                className="nav-link active"
                id="data-tab"
                data-toggle="tab"
                href="#data"
                role="tab"
                aria-controls="data"
                aria-selected="true"
              >
                หน่วยงาน
              </a>
            </li>
            <li
              className={
                invalid.status ? "nav-item-invalid tab" : "nav-item tab"
              }
            >
              <a
                className="nav-link"
                id="status-tab"
                data-toggle="tab"
                href="#status"
                role="tab"
                aria-controls="status"
                aria-selected="false"
              >
                สถานะ
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active card-tab"
              id="data"
              role="tabpanel"
              aria-labelledby="data-tab"
            >
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ระดับหน่วยงาน"
                    star={false}
                    placeholder="เลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={levelIdSelectValue}
                    optionsList={levelList}
                    handleSelectValue={(value) => setLevelIdSelectValue(value)}
                    handleChange={(value) => {
                      setDisableForm(value.value);
                      selectValueLevel(value);
                    }}
                    handleInvalid={() => removeInvalid("levelId")}
                    invalid={invalid.levelId}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่อหน่วยงาน"
                    idName="organizeName"
                    value={form.organizeName}
                    star={false}
                    placeholder="กรุณาระบุ"
                    classLabel="normal"
                    handleChange={(value) => {
                      setForm({
                        ...form,
                        organizeName: value,
                        organizeNameEn: value,
                        checkShow: true,
                      });
                    }}
                    handleInvalid={() => removeInvalid("organizeName")}
                    invalid={invalid.organizeName}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชื่อกลุ่มหน่วยงาน"
                    star={false}
                    placeholder="เลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={organizeSelectValue}
                    optionsList={organizeList}
                    disabled={organizeDisabled}
                    handleSelectValue={(value) => setOrganizeSelectValue(value)}
                    handleChange={(value) => {
                      setForm({
                        ...form,
                        parentId: value.parentId,
                        checkShow: true,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชื่อเขตปศุสัตว์"
                    star={false}
                    placeholder="เลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={zoneLivestockSelectValue}
                    optionsList={zoneLivestockList}
                    disabled={organizeDisabled}
                    handleSelectValue={(value) => {
                      setZoneLivestockSelectValue(value);
                      getProvince(value.value);
                    }}
                    handleChange={(value) =>
                      setForm({ ...form, zoneId: value.value, checkShow: true })
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="provinceId"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={filterProvince(provinceList)}
                    handleSelectValue={(value) => selectValueProvince(value)}
                    handleChange={(value) =>
                      setForm({
                        ...form,
                        code: value.value,
                        zipcode: value.zipcode,
                        chooseSelect: true,
                      })
                    }
                    disabled={provinceDisabled}
                  />
                </Col>

                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="districtId"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleSelectValue={(value) => selectValueDistrict(value)}
                    handleChange={(value) =>
                      setForm({
                        ...form,
                        code: value.value,
                        zipcode: value.zipcode,
                        chooseSelect: true,
                      })
                    }
                    disabled={districtDisabled}
                  />
                </Col>

                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrictId"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleSelectValue={(value) => selectValueSubDistrict(value)}
                    handleChange={(value) =>
                      setForm({
                        ...form,
                        code: value.value,
                        zipcode: value.zipcode,
                        chooseSelect: true,
                      })
                    }
                    disabled={subDistrictDisabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <div className="mb-4 pt-3">
                    <p className="mt-4 bold">ค่าพิกัด</p>
                    <p className="mb-0">
                      {form.latitude && `ละติจูด : ${form.latitude}`}
                    </p>
                    <p>{form.longitude && ` ลองจิจูด : ${form.longitude}`}</p>
                    <button
                      className="btn btn-sky d-flex align-items-center"
                      onClick={() => toMap()}
                    >
                      <img
                        className="img-fluid mr-1"
                        src="/svg/pin.svg"
                        alt=""
                      />
                      เลือกแผนที่
                    </button>
                  </div>

                  {showMap && (
                    <ModalMap
                      show={showMap}
                      modeMap={form.modeMap}
                      lat={form.latitude}
                      lon={form.longitude}
                      close={() => setShowMap(false)}
                      save={(value) =>
                        setForm({
                          ...form,
                          latitude: value.latitude,
                          longitude: value.longitude,
                        })
                      }
                    />
                  )}
                </Col>
              </Row>
            </div>
            <div
              className="tab-pane fade card-tab"
              id="status"
              role="tabpanel"
              aria-labelledby="status-tab"
            >
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={statusList}
                    handleSelectValue={(value) =>
                      setStatusSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, status: value.value, checkShow: true })
                    }
                    handleInvalid={() => removeInvalid("status")}
                    invalid={invalid.status}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                    <p className="mr-4">วันที่บันทึกข้อมูล</p>
                    <p>
                      {form.createDate
                        ? helper.momentDate(form.createDate, "th", "short")
                        : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                    <p className="mr-3">วันที่ปรับปรุงข้อมูล</p>
                    <p>
                      {form.updateDate
                        ? helper.momentDate(form.updateDate, "th", "short")
                        : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="mr-2 mt-3 mb-2">
          <div className="text-right py-2">
            <button
              className="btn btn-danger px-4 mr-2"
              onClick={() => handleClose()}
            >
              ยกเลิก
            </button>
            <button
              className="btn btn-success px-4"
              onClick={() => toConfirm()}
              disabled={user?.userGroup.mupdate === "n" && mode === "edit"}
            >
              บันทึก
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalOrganizes;
