import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";

import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";

import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";

import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import InputMask from "../../components/InputMask";
import UploadImage from "../../components/UploadImage";
import ModalMap from "../../components/Modal.Map";
import ModalAddHouse from "../../components/Modal.AddHouse";
import ModalAddAnimal from "../../components/Modal.AddAnimal";
import DataTableNodata from "../../components/DataTableNodata";
import Breadcrumb from "../../components/Breadcrumb";
import { useMasterData } from "../../components/hooks/useMasterData";
import moment from 'moment';
import 'moment/locale/th';

import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

function SaveData(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);
  const history = useHistory();
  const { t, i18n } = useTranslation(["common", "footer"]);

  const initialForm = {
    modeEditTable1: false,
    checkMode: "",
    farmerId: "",
    avatarFile: "",
    avatarName: "",
    idCard: "",
    phone: "",

    status: "",
    titleId: "",
    fname: "",
    lname: "",
    birthday: "",
    email: "",
    farmerType: 1,
    farmerText2: "",
    farmerText3: "",

    province: "",
    district: "",
    subDistrict: "",
    village: "",
    numberh: "",
    alley: "",
    road: "",
    zipcode: "",
    localGovernId: "",
    governmentName: "",

    farmId: "",
    typeFarm: "1",
    typeRaising: "1",
    farmName: "",
    owner: "1",
    feederName: "",
    placeId: "",
    placeName: "",

    latitude: "",
    longitude: "",
    modeMap: "add",

    farmImages: [
      {
        data: "",
        name: "",
      },
      {
        data: "",
        name: "",
      },
      {
        data: "",
        name: "",
      },
    ],
    img1: "",
    img2: "",
    img3: "",

    sameAddress: false,
    syncStatus: "",
    province1: "",
    district1: "",
    subDistrict1: "",
    village1: "",
    numberh1: "",
    alley1: "",
    road1: "",
    zipcode1: "",
    localGovernId1: "",
    governmentName1: "",

    // {t("house_buiding")}
    house: [],
    nameTab: "",
    checkAddData: false,
    checkEdit: false,
    indexNew: "",

    // ข้อมูลสัตว์
    // data_animal: [],
    dataAnimalEdit: "",
    modeAnimal: "add",

    chooseSelect: false,
    recordAmount: 0,
    activeTab: "ภาพรวม",
    activeTabIndex: -1,

    //ข้อมูลใบรับรอง
    numberCertificate: "",
    certificate: [],
    save: true,

    prov: [],
    dist: [],
    subdist: [],
    vill: [],
    prefx: [],
    dla: [],

    strayAnimal: "",
    poultryHaveCheck : false,
  };
  const [form, setForm] = useState(initialForm);

  const initialFormDesc = {
    nameHouse: "",
    animalTypeId: "",
    animalTypeName: "",
    count: "",
    level1Id: "",
    level2Id: "",
    level3Id: "",
    banquetDate: "",
    source: "",
    generation: "",
  };
  const [formDesc, setFormDesc] = useState(initialFormDesc);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  // =================

  const [localGovernSelectValue, setLocalGovernSelectValue] = useState([]);
  const [localGovernList, setLocalGovernList] = useState([]);

  const [placeSelectValue, setPlaceSelectValue] = useState([]);
  const [placeList, setPlaceList] = useState([]);

  const [titleSelectValue, setTitleSelectValue] = useState([]);
  const [titleList, setTitleList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [level1SelectValue, setLevel1SelectValue] = useState([]);
  const [level1List, setLevel1List] = useState([]);

  const [level2SelectValue, setLevel2SelectValue] = useState([]);
  const [level2List, setLevel2List] = useState([]);

  const [level3SelectValue, setLevel3SelectValue] = useState([]);
  const [level3List, setLevel3List] = useState([]);

  const [houseSelectValue, setHouseSelectValue] = useState([]);
  const [houseList, setHouseList] = useState([]);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน สำหรับ ที่อยู่เดียวกัน
  const [provinceSelectValue1, setProvinceSelectValue1] = useState([]);
  const [provinceList1, setProvinceList1] = useState([]);

  const [districtSelectValue1, setDistrictSelectValue1] = useState([]);
  const [districtList1, setDistrictList1] = useState([]);

  const [subDistrictSelectValue1, setSubDistrictSelectValue1] = useState([]);
  const [subDistrictList1, setSubDistrictList1] = useState([]);

  const [villageSelectValue1, setVillageSelectValue1] = useState([]);
  const [villageList1, setVillageList1] = useState([]);

  // =================

  const [localGovernSelectValue1, setLocalGovernSelectValue1] = useState([]);
  const [localGovernList1, setLocalGovernList1] = useState([]);

  const [houseMode, setHouseMode] = useState("");
  const [disableAddHouse, setDisableAddHouse] = useState(false);
  //ใบรับรอง
  const [editMode, setEditMode] = useState(false);
  const [addCertificate, setCertificate] = useState([]);

  const [certificateSelectValue1, setCertificateSelectValue1] = useState([]);
  const [certificateList, setCertificateList] = useState([]);

  const initInvalidMsg = {
    idCard: "",
    province: "",
    district: "",
    phone: "",
    village: "",
    status: "",
    titleId: "",
    fname: "",
    lname: "",
    phone: "",
    birthday: "",
    email: "",
    numberh: "",
    alley: "",
    road: "",
    zipcode: "",
    zipcode1: "",
    // houseDesc
    nameHouse: "",
    animalTypeName: "",
    count: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const initialFormJson = {
    animalId: {},
    diseaseTypeId: {},
    husbandry: {},
    animalPurpose: {},
    vaccineType: {},
    animalParent: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initShowMap = false;
  const [showMap, setShowMap] = useState(initShowMap);

  const initShowAddHouse = false;
  const [showAddHouse, setShowAddHouse] = useState(initShowAddHouse);

  const initShowAddAnimal = false;
  const [showAddAnimal, setShowAddAnimal] = useState(initShowAddAnimal);

  // =========================================== end use state ==================================

  useMasterData("province", provinceList, setProvinceList);

  useEffect(async () => {
    await getFarmCertificate();
    await getMasterJson();
    await getTitle();
    await getLocalGovern();
    await getPlace();
    await getAnimal();
    await getProvince("all");
  }, []);

  useEffect(async () => {
    await getProvince("all");
  }, [
    user,
    form.province,
    form.district,
    form.subDistrict,
    form.village,
    form.province1,
    form.district1,
    form.subDistrict1,
    form.village1,
  ]);
  useEffect(() => {
    console.log('Value changed:', latitude);
    console.log('Value changed:', longitude);
    setForm({
      ...form,
      latitude: latitude,
      longitude: longitude,
    });
  }, [latitude,longitude]);
  useEffect(() => {
    if (location.pathname.includes("edit") && !form.chooseSelect) {
      getFarmRegistrationById(props.match.params.id);
    }
  }, [formJson, certificateList]);

  useEffect(() => {
    if (houseMode == "add") {
      let lastHouse = form.house[form.house.length - 1];
      setHouseSelectValue(lastHouse);
    }
  }, [form.house]);

  const getMasterJson = async () => {
    const params = [
      "animalType",
      "diseaseType",
      "husbandry",
      "animalPurpose",
      "vaccineType",
      "animalTypeLevel",
    ];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status === 200) {
        setFormJson({
          ...formJson,
          animalId: data.animalType,
          diseaseTypeId: data.diseaseType,
          husbandry: data.husbandry,
          animalPurpose: data.animalPurpose,
          vaccineType: data.vaccineType,
          animalParent: data.animalTypeLevel,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getTitle = async () => {
    try {
      const response = await API.getTitle(1, "");
      const data = await response.data;
      if (response.status === 200) {
        let titleArray = [];
        data.content.forEach((element) => {
          titleArray.push({
            value: element.id,
            label: element.name,
          });
        });
        form["prefx"] = titleArray;
        setTitleList(titleArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (type) => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        form["prov"] = provincesArray;
        if (type === "farmer") {
          setProvinceList(provincesArray);
        } else if (type === "farm") {
          setProvinceList1(provincesArray);
        } else {
          setProvinceList(provincesArray);
          setProvinceList1(provincesArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id, type) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        form["dist"] = districtArray;
        if (type == "farmer") {
          setDistrictList(districtArray);
        } else if (type === "farm") {
          setDistrictList1(districtArray);
        } else {
          setDistrictList(districtArray);
          setDistrictList1(districtArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id, type) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      console.log(data.content);
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
            latitude:element.latitude,
            longitude:element.longitude,
          });
        });
        form["subdist"] = subDistrictArray;
        if (type === "farmer") {
          setSubDistrictList(subDistrictArray);
        } else if (type === "farm") {
          setSubDistrictList1(subDistrictArray);
        } else {
          setSubDistrictList(subDistrictArray);
          setSubDistrictList1(subDistrictArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id, type) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        form["vill"] = villageArray;
        if (type === "farmer") {
          setVillageList(villageArray);
        } else if (type === "farm") {
          setVillageList1(villageArray);
        } else {
          setVillageList(villageArray);
          setVillageList1(villageArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLocalGovern = async () => {
    try {
      const response = await API.getLocalGovern("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let localGovernArray = [];
        data.content.forEach((element) => {
          localGovernArray.push({
            value: element.id,
            label: element.name,
          });
        });
        form["dla"] = localGovernArray;
        setLocalGovernList(localGovernArray);
        setLocalGovernList1(localGovernArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPlace = async () => {
    try {
      const response = await API.getPlace("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let placeArray = [];
        data.content.forEach((element) => {
          placeArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setPlaceList(placeArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFarmCertificate = async () => {
    try {
      const response = await API.getFarmCertificate("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let certificateArray = [];
        data.content.forEach((element) => {
          certificateArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setCertificateList(certificateArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimalParent = async (animalId, parent, level) => {
    try {
      const response = await API.getAnimalParent(animalId, parent);
      const data = await response.data;
      if (response.status == 200) {
        let animalLevel1Array = [];
        let animalLevel2Array = [];
        let animalLevel3Array = [];

        if (level === "level1") {
          data.content.forEach((element) => {
            if (element.parent === parent) {
              animalLevel1Array.push({
                value: element.id,
                label: element.name,
              });
            }
          });
          setLevel1List(animalLevel1Array);
        }
        if (level === "level2") {
          data.content.forEach((element) => {
            if (element.parent === parent) {
              animalLevel2Array.push({
                value: element.id,
                label: element.name,
              });
            }
          });
          setLevel2List(animalLevel2Array);
        }
        if (level === "level3") {
          data.content.forEach((element) => {
            if (element.parent === parent) {
              animalLevel3Array.push({
                value: element.id,
                label: element.name,
              });
            }
          });
          setLevel3List(animalLevel3Array);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFarmRegistrationById = async (id) => {
    let data;
    try {
      const response = await API.getFarmRegistrationById(id);
      const dataRes = await response.data;
      console.log(dataRes);
      if (response.status === 200) {
        data = dataRes;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (data) {
      const farmer = data.farmer;
      const farm = data.farm;
      const barn = data.barn;
      const farmImages = data.farmImages;
      const farmerProfileImg = data.farmerProfileImg;
      const farmCertificate = data.farmCertificate;

      // get ส่วนข้อมูลเกษตรกร

      if (provinceList.length === 0) {
        await getProvince("farmer");
      }
      if (farmer.provinceId) {
        let provinceSelect = await form.prov.find(
          (item) => item.value == farmer.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length === 0) {
          await getDistricts(farmer.provinceId, "farmer");
        }
      }

      if (farmer.amphurId) {
        let districtSelect = await form.dist.find(
          (item) => item.value == farmer.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length === 0) {
          await getSubDistricts(farmer.amphurId, "farmer");
        }
      }

      if (farmer.tambonId) {
        let subDistrictSelect = await form.subdist.find(
          (item) => item.value == farmer.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length === 0) {
          await getVillage(farmer.tambonId, "farmer");
        }
      }

      if (farmer.mooId) {
        let villageSelect = await form.vill.find(
          (item) => item.value === farmer.mooId
        );
        setVillageSelectValue(villageSelect, "farmer");
      }

      if (farmer.prefixId) {
        let titleSelect = await form.prefx.find(
          (item) => item.value === farmer.prefixId
        );
        setTitleSelectValue(titleSelect);
      }

      if (farmer.dlaId) {
        let localGovernSelect = form.dla.find(
          (item) => item.value === farmer.dlaId
        );
        setLocalGovernSelectValue(localGovernSelect);
      }

      // get ข้อมูลสถานที่เลี้ยงสัตว์
      if (provinceList1.length === 0) {
        await getProvince("farm");
      }
      if (farm.provinceId) {
        let provinceSelect1 = await form.prov.find(
          (item) => item.value == farm.provinceId
        );
        setProvinceSelectValue1(provinceSelect1);

        if (districtList1.length === 0) {
          await getDistricts(farm.provinceId, "farm");
        }
      }

      if (farm.amphurId) {
        let districtSelect1 = await form.dist.find(
          (item) => item.value === farm.amphurId
        );
        setDistrictSelectValue1(districtSelect1);
        if (subDistrictList1.length === 0) {
          await getSubDistricts(farm.amphurId, "farm");
        }
      }

      if (farm.tambonId) {
        let subDistrictSelect1 = await form.subdist.find(
          (item) => item.value === farm.tambonId
        );
        setSubDistrictSelectValue1(subDistrictSelect1);

        if (villageList1.length === 0) {
          await getVillage(farm.tambonId, "farm");
        }
      }

      if (farm.mooId) {
        let villageSelect1 = await form.vill.find(
          (item) => item.value === farm.mooId
        );
        setVillageSelectValue1(villageSelect1, "farm");
      }

      if (farm.dlaId) {
        let localGovernSelect1 = form.dla.find(
          (item) => item.value === farm.dlaId
        );
        setLocalGovernSelectValue1(localGovernSelect1);
      }

      if (farm.placeTypesId) {
        let placeSelect = await placeList.find(
          (item) => item.value === farm.placeTypesId
        );
        setPlaceSelectValue(placeSelect);
      }

      const houseArray = [];
      let vaccineArray;
      let animalTypeArray;

      if (barn && barn.length > 0) {
        barn.map((barns, b) => {
          houseArray.push({
            name: barns.name,
            desc: barns.animalType.map((type, t) => {
              return {
                animalTypeId: type.animalTypeId,
                animalTypeName: formJson.animalId[type.animalTypeId],
                count: type.amount,
                level1Id: type.level1,
                level2Id: type.level2,
                level3Id: type.level3,
                banquetDate: type.banquetDate,
                source: type.source,
                generation: type.generation,
                checkEdit: false,
              };
            }),
            animals: barns.animals.map((animal, a) => {
              if (barns.id === animal.barnId) {
                if (animal.vaccine && animal.vaccine.length > 0) {
                  vaccineArray = animal.vaccine.map((vaccine, v) => {
                    return {
                      diseaseTypeName:
                        formJson.diseaseTypeId[vaccine.diseaseTypeId],
                      diseaseTypeId: vaccine.diseaseTypeId,
                      last_date: vaccine.vaccineDate,
                      lotNo: vaccine.lotNo,
                      operator: vaccine.injectBy,
                      other_vaccine: vaccine.injectOther,
                      had_disease: vaccine.diseaseHistory,
                      vaccineTypeId: vaccine.vaccineTypeId,
                      vaccineTypeName:
                        formJson.vaccineType[vaccine.vaccineTypeId],
                    };
                  });
                }

                return {
                  fm01Id: animal.fm01Id,
                  animalTypeName: formJson.animalId[animal.animalTypeId],
                  animalTypeId: animal.animalTypeId,
                  animalId: animal.animalCode,
                  date_dead: animal.deadDate,
                  status_id: animal.statusId,
                  cause_death_type: animal.deadCause,
                  cause_death_type_other: animal.deadReason,
                  name: animal.name,
                  genderType: animal.sex,
                  gender:
                    animal.sex === "m"
                      ? "ตัวผู้"
                      : animal.sex === "f"
                      ? "ตัวเมีย"
                      : "ไม่ทราบ",
                  house_buiding: barns.name,
                  age_year: animal.ageYear,
                  age_month: animal.ageMonth,
                  no_nid: animal.nid,
                  no_microchip: animal.microchip,
                  breed: animal.species,
                  purpose_raising: animal.purposeId,
                  purpose_name: formJson.animalPurpose[animal.purposeId],
                  no_nid: animal.nidCode,
                  no_animal_ear: animal.nid,
                  style_raising: animal.husbandryId,
                  style_raising_name: formJson.husbandry[animal.husbandryId],
                  is_vaccine: animal.vaccinationStatus,
                  choose_disease: vaccineArray,
                };
              }
            }),
            fm0102s: barns.fm0102s.map((fm0102, f) => {
              return {
                animalTypeId: fm0102.animalTypeId,
                barnId: fm0102.barnId,
                deadAmt: fm0102.deadAmt,
                fm01Id: fm0102.fm01Id,
                id: fm0102.id,
                sickAmt: fm0102.sickAmt,
                sickDeadAmt: fm0102.sickDeadAmt,
                totalAmt: fm0102.totalAmt,
              };
            }),
          });
        });
      }

      let newBirthDate = new Date(farmer.birthday);

      let certificateData = [];
      if (farmCertificate && farmCertificate.length > 0) {
        farmCertificate.map((item, i) => {
          let certificate = item.farmCertificateId
            ? certificateList.find((itemC) => {
                return itemC.value === item.farmCertificateId;
              })
            : { value: null, label: "-" };

          certificateData.push({
            id: item.id ? item.id : "",
            numberCertificate: item.cerCode,
            certificate: certificate,
            save: false,
          });
        });

        setCertificate(certificateData);
      }

      setForm({
        ...form,
        checkMode: "edit",
        farmerId: farmer.id,
        avatarFile: farmerProfileImg ? farmerProfileImg.data : "",
        avatarName: farmerProfileImg ? farmerProfileImg.name : "",
        idCard: farmer.pid,
        phone: farmer.mobile,

        status: "",
        titleId: farmer.prefixId,
        fname: farmer.firstName,
        lname: farmer.lastName,
        birthday: farmer.birthday ? newBirthDate.getTime() : "",
        email: farmer.email,
        farmerType: farm.farmerTypeId,
        farmerText2: farm.farmerTypeId == 2 ? farm.farmerTypeDesc : "",
        farmerText3: farm.farmerTypeId == 3 ? farm.farmerTypeDesc : "",

        province: farmer.provinceId,
        district: farmer.amphurId,
        subDistrict: farmer.tambonId,
        village: farmer.mooId,
        numberh: farmer.homeNo,
        alley: farmer.soi ? farm.soi : "-",
        road: farmer.road ? farmer.road : "-",
        zipcode: farmer.postCode ? farmer.postCode : "",
        localGovernId: farmer.dlaId,
        governmentName: farmer.dlaName,
        strayAnimal: farmer.strayAnimal,

        farmId: farm.id,
        typeFarm: farm.isFarm ? farm.isFarm : "1",
        typeRaising: farm.isFarmReason ? farm.isFarmReason : "1",
        farmName: farm.farmName,
        owner: farm.notFarmReason,
        feederName: farm.feederName,
        placeId: farm.placeTypesId,
        placeName: farm.placeName,
        poultryHaveCheck: farm.poultryHaveCheck,

        latitude: farm.latitude,
        longitude: farm.longitude,
        modeMap: "edit",

        farmImages: farmImages,
        img1: "",
        img2: "",
        img3: "",

        province1: farm.provinceId,
        district1: farm.amphurId,
        subDistrict1: farm.tambonId,
        village1: farm.mooId,
        numberh1: farm.homeNo,
        alley1: farm.soi,
        road1: farm.road,
        zipcode1: farm.zipcode,
        localGovernId1: farm.dlaId,
        governmentName1: farm.dlaName,
        syncStatus: farm.syncStatus == 2 ? true : false,
        // deal_anti_insect: dataById.fm0103 && dataById.fm0103.medicine == 1 ? true : false,

        // {t("house_buiding")}
        house: houseArray,

        // ข้อมูลสัตว์
        // data_animal: animalArray,
      });

      let arrayHouseList = [];
      if (houseArray && houseArray.length > 0) {
        houseArray.forEach((house) =>
          arrayHouseList.push({ value: house.name, label: house.name })
        );
      }
      setHouseList(arrayHouseList);
    }
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/farm_registration", name: "ขึ้นทะเบียนฟาร์ม" },
    { path: "active", name: "บันทึกข้อมูลฟาร์ม" },
  ];

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      province: value.value,
      zipcode: "",
      chooseSelect: true,
    });
    getDistricts(value.value, "farmer");
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      district: value.value,
      zipcode: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value, "farmer");
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      subDistrict: value.value,
      zipcode: value.zipcode,
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };
  //==================================================================

  const selectValueProvince1 = (value) => {
    setProvinceSelectValue1([{ value: value.value, label: value.label }]);
    setDistrictSelectValue1([]);
    setSubDistrictSelectValue1([]);
    setVillageSelectValue1([]);
    setForm({
      ...form,
      province1: value.value,
      zipcode1: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict1 = (value) => {
    setDistrictSelectValue1([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue1([]);
    setVillageSelectValue1([]);
    setForm({
      ...form,
      district1: value.value,
      zipcode1: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict1 = (value) => {
    console.log('====================================');
    console.log(value);
    console.log('====================================');
    setSubDistrictSelectValue1([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      subDistrict1: value.value,
      zipcode1: value.zipcode,
      chooseSelect: true,
    });
    setLatitude(value.latitude)
    setLongitude(value.longitude)
    setVillageSelectValue1([]);
    getVillage(value.value);
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if  (form.strayAnimal != 1) {
      if (form.idCard == "") {
        addInvalid("idCard", "กรุณาระบุเลขประจำตัวประชาชน");
        validated = false;
      }

      if (form.idCard?.length < 13) {
        addInvalid("idCard", "กรุณาระบุเลขประจำตัวประชาชนให้ครบ 13 หลัก");
        validated = false;
      }

      if (!helper.chkDigitPid(form.idCard)) {
        addInvalid("idCard", "กรุณาระบุเลขประจำตัวประชาชนให้ถูกต้อง");
        validated = false;
      }
    }
    
    if (form.fname == "") {
      addInvalid("fname", "กรุณากรอกชื่อ");
      validated = false;
    }

    if (form.lname == "") {
      addInvalid("lname", "กรุณากรอกนามสกุล");
      validated = false;
    }

    if (titleSelectValue?.length == 0 || form.titleId == "") {
      addInvalid("titleId", "กรุณาเลือกคำนำหน้า");
      validated = false;
    }

    if (form.phone == "") {
      addInvalid("phone", "กรุณากรอกเบอร์โทรศัพท์");
      validated = false;
    }

    if (provinceSelectValue?.length == 0 || form.province == "") {
      addInvalid("province", "กรุณาเลือกจังหวัด");
      validated = false;
    }

    if (districtSelectValue?.length == 0 || form.district == "") {
      addInvalid("district", "กรุณาเลือกอำเภอ");
      validated = false;
    }

    if (subDistrictSelectValue?.length == 0 || form.subDistrict == "") {
      addInvalid("subDistrict", "กรุณาเลือกตำบล");
      validated = false;
    }

    if (form.village == "") {
      addInvalid("village", "กรุณากรอกหมู่บ้าน");
      validated = false;
    }

    if (form.numberh == "") {
      addInvalid("numberh", "กรุณากรอกบ้านเลขที่");
      validated = false;
    }

    if (form.email == "") {
      addInvalid("email", "กรุณากรอกอีเมล");
      validated = false;
    }

    if (!helper.checkEmailFormat(form.email)) {
      addInvalid("email", "กรุณากรอกอีเมลให้ถูกต้อง");
      validated = false;
    }
    if (form.zipcode == "") {
      addInvalid("zipcode", "กรุณากรอกรหัสไปรษณีย์");
      validated = false;
    }
    if (form.zipcode !== "" && form.zipcode?.length !== 5) {
      addInvalid("zipcode", "กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง");
      validated = false;
    }
    if (form.zipcode1 !== "" && form.zipcode1?.length !== 5) {
      addInvalid("zipcode1", "กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง");
      validated = false;
    }

    return validated;
  };

  const toSave = async () => {
    if (validate()) {
      const barns = [];

      form.house.map((item, i) => {
        let animalType;
        let animalsArray;
        if (item.desc && item.desc.length > 0) {
          animalType = item.desc.map((desc, d) => {
            return {
              animalTypeId: desc.animalTypeId,
              amount: desc.count,
              level1: desc.level1Id,
              level2: desc.level2Id,
              level3: desc.level3Id,
              banquetDate: new Date(desc.banquetDate).getTime(),
              source: desc.generation,
              generation: desc.generation,
            };
          });
        } else {
          animalType = [];
        }

        if (item.animals && item.animals.length > 0) {
          animalsArray = item.animals.map((animal, a) => {
            let vaccine;
            if (animal.choose_disease && animal.choose_disease.length > 0) {
              vaccine = animal.choose_disease.map((disease, d) => {
                return {
                  diseaseTypeId: disease.diseaseTypeId,
                  vaccineTypeId: disease.vaccineTypeId,
                  vaccineDate: disease.last_date
                    ? helper.getDate(disease.last_date)
                    : "",
                  lotNo: disease.lotNo,
                  injectBy: disease.operator,
                  injectOther: disease.other_vaccine,
                  diseaseHistory: disease.had_disease,
                };
              });
            }

            return {
              fm01Id: animal.fm01Id,
              animalTypeId: animal.animalTypeId,
              animalCode: animal.animalId,
              statusId: animal.status_id,
              deadDate: animal.date_dead,
              deadCause: animal.cause_death_type,
              deadReason: animal.cause_death_type_other,
              name: animal.name,
              sex: animal.genderType,
              ageYear: animal.age_year,
              ageMonth: animal.age_month,
              nid: animal.no_animal_ear,
              microchip: animal.no_microchip,
              species: animal.breed,
              purposeId: animal.purpose_raising,
              nidCode: animal.no_nid,
              husbandryId: animal.style_raising,
              vaccinationStatus: animal.is_vaccine,
              vaccine: vaccine ? vaccine : [],
            };
          });
        }

        barns.push({
          farmId: form.checkMode == "edit" ? form.farmId : "",
          orderIndex: i + 1,
          name: item.name,
          totalAmt: "",
          animalType: animalType,
          animals: animalsArray ? animalsArray : [],
          fm0102s: location.pathname.includes("save") ? [] : item.fm0102s,
        });
      });

      const dataCertificate = [];
      if (addCertificate && addCertificate.length > 0) {
        addCertificate.map((item, i) => {
          dataCertificate.push({
            id: item.id ? item.id : "",
            farmCertificateId: item.certificate.value,
            cerCode: item.numberCertificate,
          });
        });
      }

      const data = {
        farmer: {
          id: form.farmerId,
          pid: form.idCard,
          prefixId: form.titleId,
          firstName: form.fname,
          lastName: form.lname,
          birthday: form.birthday ? helper.getDate(form.birthday) : "",
          mobile: form.phone,
          email: form.email,
          provinceId: form.province,
          amphurId: form.district,
          tambonId: form.subDistrict,
          mooId: form.village,
          homeNo: form.numberh,
          soi: form.alley,
          road: form.road,
          postCode: form.zipcode,
          statusId: 1,
          dlaId: form.localGovernId,
          dlaName: form.governmentName,
          strayAnimal: form.strayAnimal ? 1 : 0,
          
        },
        farmerProfileImg: {
          name: form.avatarName,
          data: form.avatarFile,
        },
        farm: {
          isFarm: form.typeFarm,
          isFarmReason: form.typeRaising,
          farmerTypeId: form.farmerType,
          farmerTypeDesc:
            form.farmerType == 2
              ? form.farmerText2
              : form.farmerType == 3
              ? form.farmerText3
              : "",
          farmName: form.farmName,
          notFarmReason: form.owner,
          feederName: form.feederName,
          placeTypesId: form.placeId,
          placeName: form.placeName,
          provinceId: form.province1,
          amphurId: form.district1,
          tambonId: form.subDistrict1,
          mooId: form.village1,
          homeNo: form.numberh1,
          soi: form.alley1,
          road: form.road1,
          zipcode: form.zipcode1,
          latitude: form.latitude,
          longitude: form.longitude,
          statusId: 1,
          dlaId: form.localGovernId1,
          dlaName: form.governmentName1,
          syncStatus: form.syncStatus ? 2 : 1,
          poultryHaveCheck: form.poultryHaveCheck,
        },
        farmImages: form.farmImages,
        barns: barns,
        farmCertificate: dataCertificate,
      };
      // return;
      dispatch(loading(true));

      let response;
      MySwal.fire({
        text: `ต้องการบันทึก`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (value) => {
        if (value.isConfirmed) {
          if (location.pathname.includes("save-data")) {
            try {
              response = await API.addFarmRegistration(data);
              if (response.status === 200) {
                dispatch(loading(false));
                MySwal.fire({
                  text: "บันทึกการขึ้นทะเบียนฟาร์มสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ตกลง",
                }).then((result) => {
                  if (result.value) {
                    history.push("/farm_registration");
                  }
                });
              }
            } catch (error) {
              dispatch(loading(false));
              if (error.response.status === 401) {
                console.log(error.response);
                dispatch(logout({ history }));
              } else {
                const textError = error.response?.data?.errors;

                MySwal.fire({
                  title: "บันทึกการขึ้นทะเบียนฟาร์มไม่สำเร็จ",
                  text: textError,
                  icon: "warning",
                  confirmButtonText: "ตกลง",
                });
              }
              console.log("error---->", error);
            }
          } else {
            try {
              response = await API.updateFarmRegistration(
                props.match.params.id,
                data
              );
              if (response.status === 200) {
                dispatch(loading(false));
                MySwal.fire({
                  text: "แก้ไขการขึ้นทะเบียนฟาร์มสำเร็จ",
                  icon: "success",
                  confirmButtonText: "ตกลง",
                }).then((result) => {
                  if (result.value) {
                    history.push("/farm_registration");
                  }
                });
              }
            } catch (error) {
              dispatch(loading(false));
              if (error.response.status === 401) {
                console.log(error.response);
                dispatch(logout({ history }));
              } else {
                const textError = error.response?.data?.errors;
                MySwal.fire({
                  title: "แก้ไขการขึ้นทะเบียนฟาร์มไม่สำเร็จ",
                  text: textError,
                  icon: "warning",
                  confirmButtonText: "ตกลง",
                });
              }
            }
          }
        } else {
          dispatch(loading(false));
        }
      });
    }
  };

  const saveAddresstoForm = async (check) => {
    if (check) {
      if (form.province) {
        let provinceSelect = await provinceList.find(
          (item) => item.value === form.province
        );
        setProvinceSelectValue1(provinceSelect);

        if (districtList.length === 0) {
          await getDistricts(form.province, "all");
        }
      }

      if (form.district) {
        let districtSelect = await districtList.find(
          (item) => item.value === form.district
        );
        setDistrictSelectValue1(districtSelect);
        if (subDistrictList.length === 0) {
          await getSubDistricts(form.district, "all");
        }
      }

      if (form.subDistrict) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value === form.subDistrict
        );
        console.log('====================================');
        console.log(subDistrictSelect);
        console.log('====================================');
        setSubDistrictSelectValue1(subDistrictSelect);
        setLatitude(subDistrictSelect.latitude)
        setLongitude(subDistrictSelect.longitude)

        if (villageList.length === 0) {
          await getVillage(form.subDistrict, "all");
        }
      }

      if (form.village) {
        let villageSelect = await villageList.find(
          (item) => item.value === form.village
        );
        setVillageSelectValue1(villageSelect);
      }

      if (form.localGovernId) {
        let localGovernId = await form.dla.find(
          (item) => item.value === form.localGovernId
        );
        setLocalGovernSelectValue1(localGovernId);
      }

      setForm({
        ...form,
        province1: form.province,
        district1: form.district,
        subDistrict1: form.subDistrict,
        village1: form.village,
        numberh1: form.numberh,
        alley1: form.alley,
        road1: form.road,
        zipcode1: form.zipcode,
        localGovernId1: form.localGovernId,
        governmentName1: form.governmentName,
      });
    } else {
      setProvinceSelectValue1([]);
      setDistrictSelectValue1([]);
      setSubDistrictSelectValue1([]);
      setVillageSelectValue1([]);
      setLocalGovernSelectValue1([]);
      setLatitude('')
      setLongitude('')
      setForm({
        ...form,
        province1: "",
        district1: "",
        subDistrict1: "",
        village1: "",
        numberh1: "",
        alley1: "",
        road1: "",
        zipcode1: "",
        localGovernId1: "",
        governmentName1: "",
      });
    }
  };

  const addHouse = (value) => {
    setHouseMode("add");
    clearFormDescSelectValue();
    let arrayHouse = [...form.house];
    let arrayHouseList = [...houseList];
    arrayHouse.push({ name: value, desc: [], animals: [] });
    arrayHouseList.push({ value: value, label: value });
    setForm({ ...form, house: arrayHouse, checkAddData: true });
    setHouseList(arrayHouseList);
  };

  const cancelHouseAdd = (value) => {
    setShowAddHouse(false);

    if (value == "cancel") {
      setDisableAddHouse(false);
      setHouseMode("");
    }
  };

  const getProfile = async (name, file) => {
    setForm({ ...form, avatarFile: file, avatarName: name });
  };

  const deleteImgProfile = async () => {
    setForm({ ...form, avatarFile: "", avatarName: "" });
  };

  const validateHouse = (name) => {
    let validated = true;

    if (formDesc.nameHouse == "") {
      addInvalid("nameHouse", "กรุณาเลือกโรงเรือน");
      validated = false;
    }

    if (formDesc.animalTypeName == "") {
      addInvalid("animalTypeName", "กรุณาเลือกชนิดสัตว์");
      validated = false;
    }

    if (formDesc.count == "") {
      addInvalid("count", "กรุณาระบุจำนวน");
      validated = false;
    }

    return validated;
  };

  //ใบรับรอง
  const toAddCertificate = () => {
    const data = addCertificate.map((item) => {
      return { ...item, edit: true };
    });
    setCertificate([
      ...data,
      { numberCertificate: "", certificate: [], save: true },
    ]);
    // setCertificate([...addCertificate, initialForm])
  };

  const checkAddCertificateNew = () => {
    return addCertificate.find((item) => {
      return item.save == true;
    });
  };

  const buttonAddCertificate = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddCertificate()}
          disabled={checkAddCertificateNew()}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const saveHouseDesc = async (index, type, itemDesc) => {
    if (validateHouse() || type === "edit") {
      setHouseMode("");
      setDisableAddHouse(false);

      let newBuilding = [...form.house];
      let houseDesc = newBuilding.desc ? [...newBuilding.desc] : [];

      houseDesc.push({
        checkEdit: false,
        animalTypeId: formDesc.animalTypeId,
        animalTypeName: formDesc.animalTypeName,
        count: formDesc.count,
        level1Id: formDesc.level1Id,
        level2Id: formDesc.level2Id,
        level3Id: formDesc.level3Id,
        banquetDate: formDesc.banquetDate,
        generation: formDesc.generation,
        source: formDesc.generation,
      });

      if (type === "single") {
        if (newBuilding[index].desc.length > 0) {
          newBuilding[index].desc.push(houseDesc[0]);
        } else {
          newBuilding[index].desc = houseDesc;
        }
      } else if (type === "all") {
        if (form.house.length > 0) {
          form.house.map((item, i) => {
            if (item.name === form.nameHouse) {
              if (newBuilding[i].desc.length > 0) {
                newBuilding[i].desc.push(houseDesc[0]);
              } else {
                newBuilding[i].desc = houseDesc;
              }
            }
          });
        }
      } else if (type === "edit") {
        itemDesc.checkEdit = false;
        itemDesc.animalTypeId = formDesc.animalTypeId;
        itemDesc.animalTypeName = formDesc.animalTypeName;
        itemDesc.count = formDesc.count;
        itemDesc.level1Id = formDesc.level1Id;
        itemDesc.level2Id = formDesc.level2Id;
        itemDesc.level3Id = formDesc.level3Id;
        itemDesc.banquetDate = formDesc.banquetDate;
        itemDesc.generation = formDesc.generation;
        itemDesc.source = formDesc.source;
        setForm({ ...form });
      }
      setForm({ ...form, checkAddData: false, modeEditTable1: false });
    }
  };

  const buttonGroup = () => {
    return (
      <>
        <button
          className="btn btn-add text-white"
          onClick={() => addHouseButton()}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่ม{t("house_buiding")}
        </button>

        <button
          className="btn btn-add text-white ml-2"
          onClick={() => {
            addDescHouse();
          }}
          disabled={
            (form.house &&
              form.house.length > 0 &&
              !form.checkAddData &&
              formDesc.nameHouse != "") ||
            form.modeEditTable1
              ? false
              : true
          }
        >
          <i className="fas fa-plus mr-1"></i>เพิ่มข้อมูล
        </button>
      </>
    );
  };
  const addHouseButton = () => {
    setShowAddHouse(true);
    setDisableAddHouse(true);
  };

  const buttonAddAnimal = () => {
    return (
      <button
        className="btn btn-add text-white"
        onClick={() => {
          setShowAddAnimal(true);
          setForm({ ...form, modeAnimal: "add" });
        }}
        disabled={form?.house?.length === 0}
      >
        <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูลสัตว์
      </button>
    );
  };

  const clearFormDescSelectValue = () => {
    setHouseSelectValue([]);
    setAnimalSelectValue([]);
    setLevel1SelectValue([]);
    setLevel2SelectValue([]);
    setLevel3SelectValue([]);
    setFormDesc({
      ...formDesc,
      count: "",
      animalTypeName: "",
      count: "",
      level1Id: "",
      level2Id: "",
      level3Id: "",
      banquetDate: "",
      generation: "",
      source: "",
    });
  };

  const addDescHouse = (nameTab) => {
    setHouseMode("addTab");
    setForm({ ...form, checkAddData: true });
    setHouseSelectValue([]);
    setAnimalSelectValue([]);
    setLevel1SelectValue([]);
    setLevel2SelectValue([]);
    setLevel3SelectValue([]);
    setDisableAddHouse(true);
    setFormDesc({
      ...formDesc,
      nameHouse: nameTab,
      animalTypeName: "",
      count: "",
      level1Id: "",
      level2Id: "",
      level3Id: "",
      banquetDate: "",
      generation: "",
      source: "",
    });
  };

  const descAnimalSelect = async (value) => {
    setAnimalSelectValue([{ value: value.value, label: value.label }]);
    setLevel1SelectValue([]);
    setLevel2SelectValue([]);
    setLevel3SelectValue([]);

    await getAnimalParent(form.animalTypeId, value.value, "level1");
  };

  const descLevel1Select = async (value) => {
    setLevel1SelectValue([{ value: value.value, label: value.label }]);
    setLevel2SelectValue([]);
    setLevel3SelectValue([]);

    await getAnimalParent(form.animalTypeId, value.value, "level2");
  };

  const descLevel2Select = async (value) => {
    setLevel2SelectValue([{ value: value.value, label: value.label }]);
    setLevel3SelectValue([]);

    await getAnimalParent(form.animalTypeId, value.value, "level3");
  };

  const delDesc = (root, element, index, name) => {
    MySwal.fire({
      text: "ยืนยันการลบข้อมูล",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then((result) => {
      if (result.value) {
        if (form.house && form.house.length > 0) {
          //choose house
          form.house.map((item, i) => {
            //choose desc
            if (item.name === root.name) {
              //find index
              let dataIndex = item.desc.indexOf(element);
              //delete element in house
              item.desc.splice(dataIndex, 1);
              //if house is empty then delete house
              if (item.desc.length == 0) {
                houseList.splice(index, 1);
                form.house.splice(index, 1);
              }
            }

            setForm({ ...form, house: form.house, checkAddData: false });
            clearFormDescSelectValue();
          });
        }
      }
    });
  };

  const editDesc = async (root, item, index, name) => {
    setHouseMode("edit");
    item.checkEdit = true;
    setForm({ ...form, modeEditTable1: true });
    setFormDesc({
      ...formDesc,
      count: item.count,
      animalTypeId: item.animalTypeId,
      animalTypeName: formJson.animalId[item.animalTypeId],
      count: item.count,
      level1Id: item.level1Id,
      level2Id: item.level2Id,
      level3Id: item.level3Id,
      banquetDate: item.banquetDate,
      generation: item.generation,
      source: item.source,
    });
    setHouseSelectValue([{ value: name, label: name }]);
    setAnimalSelectValue([
      { value: item.animalTypeId, label: formJson.animalId[item.animalTypeId] },
    ]);
    if (item.level1Id) {
      setLevel1SelectValue([
        { value: item.level1Id, label: formJson.animalParent[item.level1Id] },
      ]);
      await getAnimalParent(item.animalTypeId, item.animalTypeId, "level1");
    } else {
      setLevel1SelectValue([]);
    }
    if (item.level2Id) {
      setLevel2SelectValue([
        { value: item.level2Id, label: formJson.animalParent[item.level2Id] },
      ]);
      await getAnimalParent(item.animalTypeId, item.level1Id, "level2");
    } else {
      setLevel2SelectValue([]);
    }
    if (item.level3Id) {
      setLevel3SelectValue([
        { value: item.level3Id, label: formJson.animalParent[item.level3Id] },
      ]);
      await getAnimalParent(item.animalTypeId, item.level2Id, "level3");
    } else {
      setLevel3SelectValue([]);
    }
  };

  //Build All-List  แสดงรายละเอียดทั้งหมดในแต่ละแถบ
  const renderTableForBuildAll = (data, index) => {
    let name = data.name;
    if (data.desc.length > 0) {
      return data.desc.map((item) => {
        return renderDesc(data, item, index, name);
      });
    }
  };
  //Build Single  ภาพรวม เลยเอามาแค่โรงเรือน
  const renderTableForBuildSingle = (data, name) => {
    if (data.desc.length > 0) {
      let indexHouse = form.house.indexOf(data);
      return data.desc.map((item) => {
        return renderDesc(data, item, indexHouse, name);
      });
    }
  };

  const animalEdit = (data, index) => {
    setShowAddAnimal(true);
    setForm({
      ...form,
      modeAnimal: "edit",
      dataAnimalEdit: { data: data, index: index },
    });
  };

  const addAnimal = (data) => {
    if (form.house && form.house.length > 0) {
      form.house.forEach((house, h) => {
        if (house.name === data.house_buiding) {
          house.animals.push(data);
          setForm({ ...form });
        }
      });
    }
  };

  const saveEditAnimal = (data) => {
    if (form.house && form.house.length > 0) {
      form.house.forEach((house, h) => {
        if (house.name === data.house_buiding) {
          house.animals.map((animal, a) => {
            if (a === data.index) {
              // Object.assign เอา dataObject มาวางแทนอันเดิม
              Object.assign(animal, data);
            }
          });
        }
      });
      setForm({ ...form });
    }
  };

  const animalRemove = (index, name) => {
    if (form.house && form.house.length > 0) {
      form.house.map((item, i) => {
        if (item.name === name) {
          item.animals.splice(index, 1);
          setForm({ ...form, house: form.house });
        }
      });
    }
  };

  const renderDesc = (root, item, index, name) => {
    if (form.activeTab === "ภาพรวม") {
      calRecord("set", "overview");
    }
    return (
      <tr key={index} className="">
        <td>
          {item.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 "
              star={false}
              placeholder="กรุณาเลือก"
              idName="nameHouse"
              classLabel="normal"
              selectValue={houseSelectValue}
              optionsList={houseList}
              handleChange={(value) => {
                setHouseSelectValue([value]);
                setFormDesc({ ...formDesc, nameHouse: value.label });
                setForm({ ...form, nameHouse: value.label });
              }}
            />
          ) : (
            <span className="text-nowrap">{name}</span>
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="animalTypeName"
              classLabel="normal"
              selectValue={animalSelectValue}
              optionsList={animalList}
              handleChange={(value) => {
                descAnimalSelect(value);
                setFormDesc({
                  ...formDesc,
                  animalTypeName: value.label,
                  animalTypeId: value.value,
                });
              }}
              handleInvalid={() => removeInvalid("animalTypeName")}
              invalid={invalid.animalTypeName}
            />
          ) : (
            <span className="text-nowrap">
              {item.animalTypeId ? formJson.animalId[item.animalTypeId] : "-"}
            </span>
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputDatePicker
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName="banquetDate"
              classLabel="normal"
              value={formDesc.banquetDate}
              handleChange={(value) => {
                setFormDesc({ ...formDesc, banquetDate: value });
              }}
            />
          ) : (
            <span className="text-nowrap">
              {moment(new Date(item.banquetDate)).local('th').format('DD-MM-YYYY')}
            </span>
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputText
              classFormGroup="mb-0"
              star={false}
              type="text"
              placeholder="ระบุ"
              idName="source"
              classLabel="normal"
              value={formDesc.source}
              handleChange={(value) => {
                setFormDesc({ ...formDesc, source: value });
              }}
            />
          ) : (
            <span className="text-nowrap">
              {item.source}
            </span>
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputText
              classFormGroup="mb-0"
              star={false}
              type="number"
              placeholder="ระบุ"
              idName="generation"
              classLabel="normal"
              value={formDesc.generation}
              handleChange={(value) => {
                setFormDesc({ ...formDesc, generation: value });
              }}
            />
          ) : (
            <span className="text-nowrap">
              {item.generation}
            </span>
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="level1Id"
              classLabel="normal"
              selectValue={level1SelectValue}
              optionsList={level1List}
              handleChange={(value) => {
                setLevel1SelectValue([value]);
                setFormDesc({ ...formDesc, level1Id: value.value });
              }}
            />
          ) : (
            <span className="text-nowrap">
              {item.level1Id ? formJson.animalParent[item.level1Id] : "-"}
            </span>
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="level2Id"
              classLabel="normal"
              selectValue={level2SelectValue}
              optionsList={level2List}
              handleChange={(value) => {
                setLevel2SelectValue([value]);
                setFormDesc({ ...formDesc, level2Id: value.value });
              }}
            />
          ) : (
            <span className="text-nowrap">
              {item.level2Id ? formJson.animalParent[item.level2Id] : "-"}
            </span>
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="level3Id"
              classLabel="normal"
              selectValue={level3SelectValue}
              optionsList={level3List}
              handleChange={(value) => {
                setLevel3SelectValue([value]);
                setFormDesc({ ...formDesc, level3Id: value.value });
              }}
            />
          ) : (
            <span className="text-nowrap">
              {item.level3Id ? formJson.animalParent[item.level3Id] : "-"}
            </span>
          )}
        </td>
        <td>
          {item.checkEdit ? (
            <InputText
              classFormGroup="mb-0"
              type="number"
              idName="count"
              value={formDesc.count}
              star={false}
              placeholder="ระบุจำนวน"
              handleChange={(value) =>
                setFormDesc({ ...formDesc, count: value })
              }
              min="0"
              handleInvalid={() => removeInvalid("count")}
              invalid={invalid.count}
            />
          ) : (
            <span className="text-nowrap">{item.count}</span>
          )}
        </td>
        <td>
          <div className="d-flex justify-content-center">
            {item.checkEdit ? (
              <button
                className="btn btn-success pt-1 px-2"
                onClick={async () => {
                  saveHouseDesc(index, "edit", item);
                }}
              >
                <img
                  className="img-fluid icon-check"
                  src="/svg/btn-check.svg"
                  alt="check"
                />
              </button>
            ) : (
              <>
                <button
                  className="btn btn-add mr-2"
                  onClick={() => editDesc(root, item, index, name)}
                  disabled={form.modeEditTable1 || form.checkAddData}
                >
                  <img
                    className=" icon-btn"
                    src="/svg/btn-edit.svg"
                    alt="edit"
                  />
                </button>
                <button
                  className="btn btn-del mr-2"
                  onClick={() => delDesc(root, item, index, name)}
                >
                  <img className=" icon-btn" src="/svg/btn-del.svg" alt="del" />
                </button>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  };

  const renderNewTable = (index, type) => {
    return (
      <tr className="">
        <td className="border-0 text-left">
          <div className="w-100">
            {type === "single" ? (
              form.nameTab
            ) : (
              <InputSelect
                classFormGroup="mb-0 "
                star={false}
                placeholder="กรุณาเลือก"
                idName="nameHouse"
                classLabel="normal"
                selectValue={houseSelectValue}
                optionsList={houseList}
                handleChange={(value) => {
                  setHouseSelectValue([value]);
                  setFormDesc({ ...formDesc, nameHouse: value.label });
                  setForm({ ...form, nameHouse: value.label });
                }}
                handleInvalid={() => removeInvalid("nameHouse")}
                invalid={invalid.nameHouse}
              />
            )}
          </div>
        </td>
        <td className="">
          <InputSelect
            classFormGroup="mb-0 w-100"
            star={false}
            placeholder="กรุณาเลือก"
            idName="animalTypeName"
            classLabel="normal"
            selectValue={animalSelectValue}
            optionsList={animalList}
            handleChange={(value) => {
              descAnimalSelect(value);
              setFormDesc({
                ...formDesc,
                animalTypeName: value.label,
                animalTypeId: value.value,
              });
            }}
            handleInvalid={() => removeInvalid("animalTypeName")}
            invalid={invalid.animalTypeName}
          />
        </td>
        <td>
          <InputDatePicker
            classFormGroup="mb-0"
            idName="banquetDate"
            value={formDesc.banquetDate}
            star={false}
            placeholder="ระบุ"
            handleChange={(value) => {
              setFormDesc({ ...formDesc, banquetDate: value });
            }}
          />
        </td>
        <td>
          <InputText
            classFormGroup="mb-0"
            type="text"
            idName="source"
            value={formDesc.source}
            star={false}
            placeholder="ระบุ"
            handleChange={(value) => {

                setFormDesc({ ...formDesc, source: value });

            }}
          />
        </td>
        <td>
          <InputText
            classFormGroup="mb-0"
            type="number"
            idName="generation"
            value={formDesc.generation}
            star={false}
            placeholder="ระบุ"
            handleChange={(value) => {
              if (value >= 0) {
                setFormDesc({ ...formDesc, generation: value });
              }
            }}
          />
        </td>
        <td>
          <InputSelect
            classFormGroup="mb-0 w-100"
            star={false}
            placeholder="กรุณาเลือก"
            idName="level1Id"
            classLabel="normal"
            selectValue={level1SelectValue}
            optionsList={level1List}
            handleChange={(value) => {
              descLevel1Select(value);
              setFormDesc({ ...formDesc, level1Id: value.value });
            }}
          />
        </td>
        <td>
          <InputSelect
            classFormGroup="mb-0 w-100"
            star={false}
            placeholder="กรุณาเลือก"
            idName="level2Id"
            classLabel="normal"
            selectValue={level2SelectValue}
            optionsList={level2List}
            handleChange={(value) => {
              descLevel2Select(value);
              setFormDesc({ ...formDesc, level2Id: value.value });
            }}
          />
        </td>
        <td>
          <InputSelect
            classFormGroup="mb-0 w-100"
            star={false}
            placeholder="กรุณาเลือก"
            idName="level3Id"
            classLabel="normal"
            selectValue={level3SelectValue}
            optionsList={level3List}
            handleChange={(value) => {
              setLevel3SelectValue([value]);
              setFormDesc({ ...formDesc, level3Id: value.value });
            }}
          />
        </td>
        <td>
          {/* ภาพรวม */}
          <InputText
            classFormGroup="mb-0"
            type="number"
            idName="count"
            value={formDesc.count}
            star={false}
            placeholder="ระบุจำนวน"
            handleChange={(value) => {
              if (value >= 0) {
                setFormDesc({ ...formDesc, count: value });
              }
            }}
            handleInvalid={() => removeInvalid("count")}
            invalid={invalid.count}
          />
        </td>
        <td className="">
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                saveHouseDesc(index, type);
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </div>
        </td>
      </tr>
    );
  };

  const renderTableAnimal = (data, indexAnm) => {
    return (
      <tr key={indexAnm} className="">
        <td>{data.house_buiding}</td>
        <td>{formJson.animalId[data.animalTypeId]}</td>
        <td>{data.animalId ? data.animalId : "-"}</td>
        <td>{data.name ? data.name : "-"}</td>
        <td>{data.gender ? data.gender : "-"}</td>
        <td>
          {data.age_year && `${data.age_year} ปี`}{" "}
          {data.age_month && `${data.age_month} เดือน`}
        </td>
        <td>
          <div className="d-flex justify-content-center ">
            <button
              className="btn btn-add mr-2"
              onClick={() => animalEdit(data, indexAnm)}
            >
              <img
                className="img-fluid icon-btn"
                src="/svg/btn-edit.svg"
                alt="edit"
              />
            </button>
            <button
              className="btn btn-del mr-2"
              onClick={() => animalRemove(indexAnm, data.house_buiding)}
            >
              <img
                className="img-fluid icon-btn"
                src="/svg/btn-del.svg"
                alt="del"
              />
            </button>
          </div>
        </td>
      </tr>
    );
  };

  const saveFileToForm = (name, file, index) => {
    form.farmImages.map((img, i) => {
      if (i === index) {
        let objFile = {
          data: file,
          name: name,
        };
        Object.assign(img, objFile);
      }
    });
    setForm({ ...form, farmImages: form.farmImages });
  };

  const deleteImg = (index) => {
    form.farmImages.map((img, i) => {
      if (i === index) {
        let objFile = {
          data: "",
          name: "",
        };
        Object.assign(img, objFile);
      }
    });
    setForm({ ...form, farmImages: form.farmImages });
  };

  const toMap = () => {
    setShowMap(true);
    if (location.pathname.includes("edit")) {
      setForm({ ...form, modeMap: "edit" });
    } else {
      setForm({ ...form, modeMap: "add" });
    }
  };

  const calRecord = (mode, type, data) => {
    if (mode && mode == "set") {
      form["recordAmount"] = 0;
    }

    if (type === "tab" && data && data.desc && data.desc.length > 0) {
      data.desc.map((item) => {
        form["recordAmount"] = form.recordAmount + 1;
      });
    } else {
      if (type === "overview") {
        let sum = 0;
        form.house.map((element) => {
          if (element) {
            sum += element.desc.length;
          }
        });
        form["recordAmount"] = sum;
      }
    }
  };

  const checkSelect = (mode, tab) => {
    if (mode) {
      if (mode == "add") {
        let lastHouse = form.house[form.house.length - 1];
        if (lastHouse) {
          form["activeTab"] = lastHouse.name;
          form["activeTabIndex"] = form.house.length - 1;
        }

        if (tab == form.activeTab) {
          return "nav-link active";
        } else {
          return "nav-link disabled";
        }
      } else if (mode == "edit" || mode == "addTab") {
        if (tab == form.activeTab) {
          return "nav-link active";
        } else {
          return "nav-link disabled";
        }
      }
    } else {
      if (form.activeTab == tab) {
        return "nav-link active";
      } else {
        return "nav-link";
      }
    }
  };

  //ใบรับรอง
  const renderCertificate = (item, i) => {
    return (
      <tr key={i} className="text-center">
        <td>{i + 1}</td>
        <td>
          {item.save ? (
            <InputSelect
              classFormGroup="mb-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName={`certificate_${i}`}
              classLabel="normal"
              selectValue={item.certificate}
              optionsList={certificateList}
              handleChange={(value) => {
                item.certificate = value;
                setCertificateSelectValue1(value);
                setCertificate([...addCertificate]);
              }}
            />
          ) : (
            item.certificate?.label
          )}
        </td>
        <td>
          {item.save ? (
            <InputMask
              classFormGroup="mb-0"
              mask="9999999999999999"
              idName={`numberCertificate_${i}`}
              value={item.numberCertificate}
              star={false}
              placeholder="ระบุ"
              classLabel="normal"
              handleChange={(value) => {
                item.numberCertificate = value;
                setCertificate([...addCertificate]);
              }}
            />
          ) : item.numberCertificate ? (
            item.numberCertificate
          ) : (
            "-"
          )}
        </td>

        <td className="text-center">
          {item.save ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={() => {
                item.save = false;
                const data = addCertificate.map((item) => {
                  return { ...item, edit: false };
                });
                setCertificate([...data]);
              }}
              disabled={!item.numberCertificate || !item.certificate}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => {
                  item.save = true;
                  setCertificate([...addCertificate]);
                }}
                disabled={item.edit}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button
                className="btn btn-del "
                onClick={() => {
                  addCertificate.splice(i, 1);
                  setCertificate([...addCertificate]);
                }}
                disabled={item.edit}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  const checkAnimal = (list) => {
    let check = true;
    if (list && list.length > 0) {
      list.map((item, i) => {
        if (item.animals && item.animals.length > 0) {
          check = false;
        }
      });
    }
    return check;
  };

  return (
    <div>
      <div className="px-4 py-2 bg-white d-flex justify-content-between align-items-center">
        <Breadcrumb data={breadcrumbPath} />
        <div>
          <button
            className="btn px-4 btn-sm btn-danger mr-2 cursor-p"
            onClick={() => history.push("/farm_registration")}
          >
            ยกเลิก
          </button>
          <button
            className="btn px-4 btn-sm btn-success cursor-p"
            onClick={() => toSave()}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`/farm_registration/edit`)
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 py-3 container-fixed-footer">
          <BoxCard title="1. ข้อมูลเกษตรกร" classLabel="">
            <div className="mt-2 px-2">
              <p className=" bold">1.1 ข้อมูลทั่วไป</p>
              <hr />
              <Row className="mb-2 ">
                <Col lg={2} md={4} sm={6} xs={6}>
                  <UploadImage
                    title="รูปโปรไฟล์"
                    classLabel="normal"
                    star={false}
                    type="profile"
                    typeSave="base64"
                    onChange={(name, file) => getProfile(name, file)}
                    id="imgfile"
                    position="top"
                    deleteImg={() => deleteImgProfile()}
                    files={
                      form.avatarFile === null || form.avatarFile === ""
                        ? ""
                        : form.avatarFile
                    }
                  />
                </Col>

                <Col lg={3} md={8} sm={7} xs={7}>
                <label className="normal mb-0">รหัสประจำตัวประชาชน</label>
                  <div className="d-flex flex-wrap">
                    <div className="d-flex align-items-center">
                      <Form.Check
                        Inline
                        type="checkbox"
                        name="strayAnimal"
                        id="strayAnimal"
                        checked={form.strayAnimal == 1}
                        value={1}
                        label={"สัตว์จรจัด"}
                        onChange={(e) => {
                          setForm({ ...form, strayAnimal: e.target.checked});
                        }}
                      />
                       <span>&nbsp;&nbsp;</span>
                        <InputMask
                        classFormGroup="mb-0 mr-2"
                          mask="9-9999-99999-99-9"
                          idName="idCard"
                          value={form.idCard}
                          star={false}
                          placeholder="โปรดระบุ"
                          classLabel="normal"
                          disabled={form.strayAnimal == 1}
                          handleChange={(value) =>
                            setForm({ ...form, idCard: value.replace(/-/g, "") })
                          }
                          handleInvalid={() => removeInvalid("idCard")}
                          invalid={invalid.idCard}
                        />
                      </div>
                  </div>
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="คำนำหน้าชื่อ"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="titleId"
                    classLabel="normal"
                    selectValue={titleSelectValue}
                    optionsList={titleList}
                    handleChange={(value) => {
                      setTitleSelectValue([value]);
                      setForm({ ...form, titleId: value.value });
                    }}
                    handleInvalid={() => removeInvalid("titleId")}
                    invalid={invalid.titleId}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ชื่อ"
                    idName="fname"
                    value={form.fname}
                    star={true}
                    placeholder="ชื่อ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, fname: value })}
                    handleInvalid={() => removeInvalid("fname")}
                    invalid={invalid.fname}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="นามสกุล"
                    idName="lname"
                    value={form.lname}
                    star={true}
                    placeholder="นามสกุล"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, lname: value })}
                    handleInvalid={() => removeInvalid("lname")}
                    invalid={invalid.lname}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputDatePicker
                    title="วัน/เดือน/ปีเกิด"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปีเกิด"
                    value={form.birthday}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, birthday: value })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputMask
                    mask="99-9999-9999"
                    title="โทรศัพท์มือถือ"
                    idName="phone"
                    value={form.phone}
                    star={true}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, phone: value.replace(/-/g, "") })
                    }
                    handleInvalid={() => removeInvalid("phone")}
                    invalid={invalid.phone}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="อีเมล"
                    idName="email"
                    value={form.email}
                    star={true}
                    placeholder="อีเมล"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, email: value })}
                    handleInvalid={() => removeInvalid("email")}
                    invalid={invalid.email}
                  />
                </Col>

                <Col lg={8} md={8} sm={6} xs={6}>
                  <label className="normal mb-0">ประเภทเกษตรกร</label>
                  <div className="d-flex flex-wrap">
                    <div className="d-flex align-items-center">
                      <Form.Check
                        inline
                        type="radio"
                        name="farmerType"
                        id="normal"
                        value={1}
                        checked={form.farmerType == 1}
                        label={"ทั่วไป"}
                        onChange={(e) => {
                          setForm({ ...form, farmerType: e.target.value });
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <Form.Check
                        inline
                        type="radio"
                        name="farmerType"
                        id="corporation"
                        value={2}
                        checked={form.farmerType == 2}
                        label={"นิติบุคคล"}
                        onChange={(e) => {
                          setForm({ ...form, farmerType: e.target.value });
                        }}
                      />
                      <InputText
                        classFormGroup="mb-0 mr-2"
                        type="text"
                        idName="text_corporation"
                        value={form.farmerText2}
                        star={false}
                        placeholder="โปรดระบุเลขที่นิติบุคคล"
                        classLabel="normal"
                        disabled={form.farmerType == "2" ? false : true}
                        handleChange={(value) =>
                          setForm({ ...form, farmerText2: value })
                        }
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <Form.Check
                        inline
                        type="radio"
                        name="farmerType"
                        id="organize"
                        value={3}
                        checked={form.farmerType == 3}
                        label={"หน่วยงาน"}
                        onChange={(e) => {
                          setForm({ ...form, farmerType: e.target.value });
                        }}
                      />
                      <InputText
                        classFormGroup="mb-0 w-50"
                        type="text"
                        idName="text_organize"
                        value={form.farmerText3}
                        star={false}
                        placeholder="โปรดระบุ"
                        classLabel="normal"
                        disabled={form.farmerType == "3" ? false : true}
                        handleChange={(value) =>
                          setForm({ ...form, farmerText3: value })
                        }
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <p className="bold  pt-4">1.2 ที่อยู่ตามทะเบียนบ้าน</p>
              <hr />
              <Row>
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => {
                      selectValueProvince(value);
                      setForm({ ...form, province: value.value });
                    }}
                    handleInvalid={() => removeInvalid("province")}
                    invalid={invalid.province}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="เขต/อำเภอ"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="district"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => {
                      selectValueDistrict(value);
                      setForm({ ...form, district: value.value });
                    }}
                    handleInvalid={() => removeInvalid("district")}
                    invalid={invalid.district}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="แขวง/ตำบล"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => {
                      selectValueSubDistrict(value);
                      setForm({ ...form, subDistrict: value.value });
                    }}
                    handleInvalid={() => removeInvalid("subDistrict")}
                    invalid={invalid.subDistrict}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="หมู่บ้าน"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="village"
                    classLabel="normal"
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({
                        ...form,
                        village: value.value,
                        chooseSelect: true,
                      });
                    }}
                    handleInvalid={() => removeInvalid("village")}
                    invalid={invalid.village}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="บ้านเลขที่"
                    idName="numberh"
                    value={form.numberh}
                    star={true}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, numberh: value })
                    }
                    handleInvalid={() => removeInvalid("numberh")}
                    invalid={invalid.numberh}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ตรอก/ซอย"
                    idName="alley"
                    value={form.alley}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, alley: value })}
                    handleInvalid={() => removeInvalid("alley")}
                    invalid={invalid.alley}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ถนน"
                    idName="road"
                    value={form.road}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, road: value })}
                    handleInvalid={() => removeInvalid("road")}
                    invalid={invalid.road}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="รหัสไปรษณีย์"
                    idName="zipcode"
                    value={form.zipcode}
                    star={true}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, zipcode: value })
                    }
                    disabled={false}
                    handleInvalid={() => removeInvalid("zipcode")}
                    invalid={invalid.zipcode}
                  />
                </Col>

                <Col lg={3} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="องค์กรปกครองส่วนท้องถิ่น"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="localGovernId"
                    classLabel="normal"
                    selectValue={localGovernSelectValue}
                    optionsList={localGovernList}
                    handleChange={(value) => {
                      setLocalGovernSelectValue([
                        { value: value.value, label: value.label },
                      ]);
                      setForm({ ...form, localGovernId: value.value });
                    }}
                  />
                </Col>

                <Col lg={3} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ชื่อองค์กรปกครองส่วนท้องถิ่น"
                    idName="governmentName"
                    value={form.governmentName}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, governmentName: value })
                    }
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard classNameBox="mt-3" title="2. ข้อมูลสถานที่เลี้ยงสัตว์">
            <div className="mt-2 px-3">
              <div>
                <div className="d-md-flex">
                  <p className="bold mb-0">2.1 ที่อยู่สถานที่เลี้ยงสัตว์</p>
                  <div
                    className={`${
                      form.syncStatus ? "bg-checkbox-red" : "bg-checkbox-green"
                    } pl-2 ml-4`}
                  >
                    <Form.Check
                      inline
                      type="checkbox"
                      name="syncStatus"
                      id="syncStatus"
                      checked={form.syncStatus}
                      label="ไม่ให้นับรวมสรุปในรายงาน"
                      onChange={(e) => {
                        setForm({ ...form, syncStatus: e.target.checked });
                      }}
                    />
                  </div>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <Form.Check
                        Inline
                        type="checkbox"
                        name="poultry"
                        id="poultry"
                        value={1}
                        checked={form.poultryHaveCheck == true}
                        label={"ผู้ประกอบการสัตว์ปีก"}
                        onChange={(e) => {
                          setForm({ ...form, poultryHaveCheck: e.target.checked});
                        }}
                      />
                </div>
                <hr />

                <Row>
                  <Col lg={1} md={12} sm={6}>
                    <p className="mb-0 bold">
                      รูปแบบการเลี้ยง <span className="text-danger">*</span>
                    </p>
                  </Col>

                  <Col lg={1} md={12} sm={6}>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        name="typeFarm"
                        id="isFarm"
                        value={1}
                        checked={form.typeFarm == 1}
                        label="เป็นฟาร์ม"
                        onChange={(e) => {
                          setForm({ ...form, typeFarm: e.target.value });
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Row>
                      <Col lg={2} md={3} sm={6}>
                        <div
                          className="mb-0 mr-2"
                          disabled={form.typeFarm == 2}
                        >
                          สถานะการเลี้ยง <span className="text-danger">*</span>{" "}
                          :{" "}
                        </div>
                      </Col>
                      <Col lg={9} md={9} sm={6} className="w-100">
                        <div className="d-flex ">
                          <div>
                            <Form.Check
                              inline
                              type="radio"
                              name="typeRaising"
                              id="isRaising"
                              value={1}
                              disabled={form.typeFarm == 2}
                              checked={form.typeRaising == 1}
                              label="เลี้ยงอยู่"
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  typeRaising: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div>
                            <Form.Check
                              inline
                              type="radio"
                              name="typeRaising"
                              id="notRaising"
                              value={2}
                              disabled={form.typeFarm == 2}
                              checked={form.typeRaising == 2}
                              label="เลิกเลี้ยง"
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  typeRaising: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={1} md={3} sm={6}>
                        <div
                          className="pt-sm-3 pt-md-3 pt-lg-3"
                          disabled={form.typeFarm == 2}
                        >
                          ชื่อฟาร์ม :{" "}
                        </div>
                      </Col>
                      <Col lg={2} md={8} sm={6} className="w-100">
                        <div className="pt-sm-3 pt-md-3 pt-lg-3 w-100">
                          <InputText
                            type="text"
                            title=""
                            idName="farmName"
                            disabled={form.typeFarm == 2}
                            value={form.farmName}
                            star={false}
                            placeholder="โปรดระบุ"
                            classLabel="normal"
                            handleChange={(value) =>
                              setForm({ ...form, farmName: value })
                            }
                          />
                        </div>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        className="d-flex justify-content-center py-2"
                      >
                        <BoxCard
                          title="ใบรับรองการผ่านมาตรฐานฟาร์ม"
                          classNameBox="mb-3"
                          headRight={buttonAddCertificate()}
                        >
                          <div className="">
                            <table className="table table-striped sameWidth table-bordered my-2 ">
                              <thead>
                                <tr className="text-center">
                                  <th scope="col">ลำดับ</th>
                                  <th scope="col">ชื่อมาตรฐาน <span className="text-danger">ระบุ*</span></th>
                                  <th scope="col">หมายเลขใบรับรอง <span className="text-danger">ระบุ*</span></th>
                                  <th scope="col">จัดการ</th>
                                </tr>
                              </thead>
                              <tbody>
                                {addCertificate?.length > 0 ? (
                                  addCertificate.map((item, i) => {
                                    return renderCertificate(item, i);
                                  })
                                ) : (
                                  <DataTableNodata colSpan={4} />
                                )}
                              </tbody>
                            </table>
                          </div>
                        </BoxCard>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={1} md={12} sm={6}></Col>
                  <Col lg={1} md={12} sm={6}>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        name="typeFarm"
                        id="notFarm"
                        value={2}
                        checked={form.typeFarm == 2}
                        label="ไม่เป็นฟาร์ม"
                        onChange={(e) => {
                          setForm({ ...form, typeFarm: e.target.value });
                        }}
                      />
                    </div>
                  </Col>

                  <Col>
                    <Row>
                      <Col lg={2} md={3} sm={6}>
                        <div className="">
                          <Form.Check
                            inline
                            type="radio"
                            name="owner"
                            id="isOwner"
                            value={1}
                            disabled={form.typeFarm == 1}
                            checked={form.owner == 1}
                            label="มีเจ้าของ"
                            onChange={(e) => {
                              setForm({ ...form, owner: e.target.value });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-3 mb-3">
                      <Col lg={2} md={3} sm={6}>
                        <Form.Check
                          inline
                          type="radio"
                          name="owner"
                          id="notOwner"
                          value={2}
                          disabled={form.typeFarm == 1}
                          checked={form.owner == 2}
                          label="ไม่มีเจ้าของ (จรจัด)"
                          onChange={(e) => {
                            setForm({ ...form, owner: e.target.value });
                          }}
                        />
                      </Col>

                      <Col lg={1} md={3} sm={12}>
                        <div disabled={form.typeFarm == 1}>
                          ชื่อผู้ให้อาหาร :{" "}
                        </div>
                      </Col>

                      <Col lg={2} md={8} sm={6}>
                        <InputText
                          type="text"
                          title=""
                          disabled={form.typeFarm == 1}
                          idName="feederName"
                          value={form.feederName}
                          star={false}
                          placeholder="โปรดระบุ"
                          classLabel="normal"
                          handleChange={(value) =>
                            setForm({ ...form, feederName: value })
                          }
                        />
                      </Col>

                      <Col lg={2} md={3} sm={12}>
                        <div
                          className="mb-0 ml-4"
                          disabled={form.typeFarm == 1}
                        >
                          ชื่อสถานที่อยู่สัตว์ :{" "}
                        </div>
                        <div
                          className="text-gray mb-0 font12 ml-4"
                          disabled={form.typeFarm == 1}
                        >
                          เช่น โรงเรียน ก วิทยาคม
                        </div>
                      </Col>

                      <Col lg={2} md={4} sm={12}>
                        <InputSelect
                          star={false}
                          placeholder="กรุณาเลือก"
                          idName="placeId"
                          classLabel="normal"
                          disabled={form.typeFarm == 1}
                          selectValue={placeSelectValue}
                          optionsList={placeList}
                          handleChange={(value) => {
                            setPlaceSelectValue([value]);
                            setForm({ ...form, placeId: value.value });
                          }}
                        />
                      </Col>

                      <Col lg={2} md={4} sm={12}>
                        <InputText
                          type="text"
                          title=""
                          idName=""
                          disabled={form.typeFarm == 1}
                          value={form.placeName}
                          star={false}
                          placeholder="โปรดระบุ"
                          classLabel="normal"
                          handleChange={(value) =>
                            setForm({ ...form, placeName: value })
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <div className="mt-2">
                  <p className="mb-0 bold">ที่อยู่ฟาร์ม/สถานที่เลี้ยงสัตว์</p>
                  <Row>
                    <Col lg={2} md={4} sm={6} xs={6} className="mt-4 my-4">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="sameAddress"
                        id="sameAddress"
                        label="ที่อยู่เดียวกับทะเบียนบ้าน"
                        onChange={(e) => {
                          setForm({ ...form, sameAddress: e.target.checked });
                          saveAddresstoForm(e.target.checked);
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={2} md={4} sm={6} xs={6}>
                      <InputSelect
                        title="จังหวัด"
                        star={false}
                        placeholder="กรุณาเลือก"
                        idName="province1"
                        classLabel="normal"
                        selectValue={provinceSelectValue1}
                        optionsList={
                          form.sameAddress ? provinceList : provinceList1
                        }
                        handleChange={(value) => {
                          selectValueProvince1(value);
                          setForm({ ...form, province1: value.value });
                        }}
                      />
                    </Col>

                    <Col lg={2} md={4} sm={6} xs={6}>
                      <InputSelect
                        title="เขต/อำเภอ"
                        star={false}
                        placeholder="กรุณาเลือก"
                        idName="district1"
                        classLabel="normal"
                        selectValue={districtSelectValue1}
                        optionsList={
                          form.sameAddress ? districtList : districtList1
                        }
                        handleChange={(value) => {
                          selectValueDistrict1(value);
                          setForm({ ...form, district1: value.value });
                        }}
                      />
                    </Col>

                    <Col lg={2} md={4} sm={6} xs={6}>
                      <InputSelect
                        title="แขวง/ตำบล"
                        star={false}
                        placeholder="กรุณาเลือก"
                        idName="subDistrict1"
                        classLabel="normal"
                        selectValue={subDistrictSelectValue1}
                        optionsList={
                          form.sameAddress ? subDistrictList : subDistrictList1
                        }
                        handleChange={(value) => {
                          selectValueSubDistrict1(value);
                        
                          setForm({ ...form, subDistrict1: value.value });
                        }}
                      />
                     
                    </Col>

                    <Col lg={2} md={4} sm={6} xs={6}>
                      <InputSelect
                        title="หมู่บ้าน"
                        star={false}
                        placeholder="กรุณาเลือก"
                        idName="village1"
                        classLabel="normal"
                        selectValue={villageSelectValue1}
                        optionsList={
                          form.sameAddress ? villageList : villageList1
                        }
                        handleChange={(value) => {
                          setVillageSelectValue1([value]);
                          setForm({
                            ...form,
                            village1: value.value,
                            chooseSelect: true,
                          });
                        }}
                      />
                    </Col>

                    <Col lg={2} md={4} sm={6} xs={6}>
                      <InputText
                        type="text"
                        title="บ้านเลขที่"
                        idName="numberh1"
                        value={form.numberh1}
                        star={false}
                        placeholder="โปรดระบุ"
                        classLabel="normal"
                        handleChange={(value) =>
                          setForm({ ...form, numberh1: value })
                        }
                      />
                    </Col>

                    <Col lg={2} md={4} sm={6} xs={6}>
                      <InputText
                        type="text"
                        title="ตรอก/ซอย"
                        idName="alley1"
                        value={form.alley1}
                        star={false}
                        placeholder="โปรดระบุ"
                        classLabel="normal"
                        handleChange={(value) =>
                          setForm({ ...form, alley1: value })
                        }
                      />
                    </Col>

                    <Col lg={2} md={4} sm={6} xs={6}>
                      <InputText
                        type="text"
                        title="ถนน"
                        idName="road1"
                        value={form.road1}
                        star={false}
                        placeholder="โปรดระบุ"
                        classLabel="normal"
                        handleChange={(value) =>
                          setForm({ ...form, road1: value })
                        }
                      />
                    </Col>

                    <Col lg={2} md={4} sm={6} xs={6}>
                      <InputText
                        type="text"
                        title="รหัสไปรษณีย์"
                        idName="zipcode1"
                        value={form.zipcode1}
                        star={false}
                        placeholder="โปรดระบุ"
                        classLabel="normal"
                        handleChange={(value) =>
                          setForm({ ...form, zipcode1: value })
                        }
                        // disabled={true}
                        handleInvalid={() => removeInvalid("zipcode1")}
                        invalid={invalid.zipcode1}
                      />
                    </Col>

                    <Col lg={3} md={4} sm={6} xs={6}>
                      <InputSelect
                        title="องค์กรปกครองส่วนท้องถิ่น"
                        star={false}
                        placeholder="กรุณาเลือก"
                        idName="localGovernId1"
                        classLabel="normal"
                        selectValue={localGovernSelectValue1}
                        optionsList={localGovernList1}
                        handleChange={(value) => {
                          setLocalGovernSelectValue1([value]);
                          setForm({ ...form, localGovernId1: value.value });
                        }}
                      />
                    </Col>

                    <Col lg={3} md={4} sm={6} xs={6}>
                      <InputText
                        type="text"
                        title="ชื่อองค์กรปกครองส่วนท้องถิ่น"
                        idName="governmentName1"
                        value={form.governmentName1}
                        star={false}
                        placeholder="โปรดระบุ"
                        classLabel="normal"
                        handleChange={(value) =>
                          setForm({ ...form, governmentName1: value })
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="mb-4 pt-3">
                <p className="mt-4 bold">
                  2.2 ค่าพิกัดที่ตั้งสถานที่เลี้ยงสัตว์/ปลูกพืชอาหารสัตว์
                </p>
                <hr />
                <p className="mb-0">
                  {form.latitude && `ละติจูด : ${form.latitude}`}
                </p>
                <p>{form.longitude && ` ลองจิจูด : ${form.longitude}`}</p>

                <button
                  className="btn btn-sky d-flex align-items-center"
                  onClick={() => toMap()}
                >
                  <img className="img-fluid mr-1" src="/svg/pin.svg" alt="" />
                  เลือกแผนที่
                </button>
              </div>

              <div className="mb-4 pt-3">
                <p className=" mt-4 bold">
                  2.3 รูปสถานที่เลี้ยงสัตว์/ปลูกพืชอาหารสัตว์
                </p>
                <hr />

                {form.farmImages &&
                  form.farmImages.length > 0 &&
                  form.farmImages.map((img, i) => (
                    <React.Fragment key={i}>
                      <UploadImage
                        title={`รูปที่ ${i + 1}`}
                        classLabel="normal mr-3"
                        star={false}
                        type="img"
                        typeSave="base64"
                        onChange={(name, file) => saveFileToForm(name, file, i)}
                        id="imgfile"
                        position="left"
                        deleteImg={() => deleteImg(i)}
                        files={
                          img.data === null || img.data === "" ? "" : img.data
                        }
                      />
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </BoxCard>

          <BoxCard classNameBox="mt-3" title="3. จำนวนปศุสัตว์">
            <BoxCard
              classNameBox="m-2"
              title={t("house_buiding")}
              headRight={buttonGroup()}
            >
              {/**build tab begin*/}
              <ul className="nav nav-tabs mt-2" id="myTab" role="tablist">
                <li className="nav-item tab">
                  <a
                    className={checkSelect(houseMode, "ภาพรวม")}
                    id="overview-tab"
                    data-toggle="tab"
                    href="#overview"
                    role="tab"
                    aria-controls="overview"
                    aria-selected="true"
                    onClick={(value) => {
                      form["activeTab"] = value.target.outerText;
                      form["activeTabIndex"] = -1;
                      calRecord("set", "overview");
                      setFormDesc({ ...formDesc, nameHouse: "", count: "" });
                      setHouseSelectValue([]);
                    }}
                  >
                    ภาพรวม
                  </a>
                </li>

                {form.house &&
                  form.house.length > 0 &&
                  form.house.map((item, k) => (
                    <li className="nav-item tab" key={k + item}>
                      <a
                        className={checkSelect(houseMode, item.name)}
                        id={`house${k + 1}-tab`}
                        data-toggle="tab"
                        href={`#house${k + 1}`}
                        role="tab"
                        aria-controls={`house${k + 1}`}
                        aria-selected="false"
                        onClick={(value) => {
                          form["activeTab"] = value.target.outerText;
                          form["activeTabIndex"] = k;
                          calRecord("set", "tab", item);
                          setFormDesc({
                            ...formDesc,
                            nameHouse: item.name,
                            count: "",
                          });
                          setForm({ ...form, nameTab: item.name });
                          setLevel1SelectValue([]);
                          setLevel2SelectValue([]);
                          setLevel3SelectValue([]);
                          setAnimalSelectValue([]);
                        }}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
              </ul>
              {/**build tab end*/}

              {/**build DATA record begin ===================================================================================*/}
              {/**data overview tab */}
              <div className="tab-content mb-2" id="myTabContent">
                <div
                  className="tab-pane fade show active card-tab"
                  id={form.activeTab}
                  role="tabpanel"
                  aria-labelledby={form.activeTab + "-tab"}
                >
                  <table className="table sameWidth table-striped table-bordered my-2">
                    <thead>
                      <tr className="">
                        <th scope="col">{t("house_buiding")}</th>
                        <th scope="col">ชนิดสัตว์</th>
                        <th scope="col">วันที่ลงเลี้ยง</th>
                        <th scope="col">แหล่งที่มา</th>
                        <th scope="col">รุ่นที่</th>
                        <th scope="col">ระดับ 1</th>
                        <th scope="col">ระดับ 2</th>
                        <th scope="col">ระดับ 3</th>
                        <th scope="col">จำนวน (หน่วย)</th>
                        <th scope="col-2" className="text-center">
                          จัดการ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {form.house &&
                        form.house.length > 0 &&
                        form.house.map((itemH, h) => {
                          if (form.activeTab === "ภาพรวม") {
                            if (itemH.desc && itemH.desc.length > 0) {
                              return (
                                <React.Fragment key={h + itemH + itemH.name}>
                                  {renderTableForBuildAll(itemH, h)}
                                </React.Fragment>
                              );
                            }
                          } else {
                            if (houseMode === "add") {
                              let lastHouse = form.house.length - 1;
                              if (h == lastHouse) {
                                formDesc["nameHouse"] = itemH.name;
                                form["nameTab"] = itemH.name;
                                return renderNewTable(lastHouse, "single");
                              }
                              calRecord("set", "tab", itemH);
                            } else if (
                              houseMode === "addTab" &&
                              form.checkAddData &&
                              h === form.activeTabIndex
                            ) {
                              formDesc["nameHouse"] = itemH.name;
                              form["nameTab"] = itemH.name;
                              return (
                                <React.Fragment
                                  key={h + "-sigletab" + itemH.name}
                                >
                                  {renderTableForBuildSingle(itemH, itemH.name)}
                                  {renderNewTable(h, "single")}
                                  {calRecord("set", "tab", itemH)}
                                </React.Fragment>
                              );
                            } else {
                              if (
                                itemH.desc &&
                                itemH.desc.length > 0 &&
                                itemH.name === form.activeTab
                              ) {
                                return (
                                  <React.Fragment
                                    key={h + itemH.name + form.activeTab}
                                  >
                                    {renderTableForBuildSingle(
                                      itemH,
                                      itemH.name
                                    )}
                                    {calRecord("set", "tab", itemH)}
                                  </React.Fragment>
                                );
                              } else {
                                if (
                                  itemH.desc &&
                                  itemH.desc.length === 0 &&
                                  itemH.name === form.activeTab
                                ) {
                                  return <DataTableNodata colSpan={7} />;
                                }
                              }
                            }
                          }
                        })}

                      {form.house && form.house.length == 0 && (
                        <DataTableNodata colSpan={10} />
                      )}
                    </tbody>
                  </table>
                </div>
                {/**build data record end */}
              </div>
              <h6>
                แสดงข้อมูล{" "}
                {houseMode == "add"
                  ? form.house[form.house.length - 1].desc.length
                  : form.recordAmount}{" "}
                จากทั้งหมด{" "}
                {houseMode == "add"
                  ? form.house[form.house.length - 1].desc.length
                  : form.recordAmount}{" "}
                รายการ
              </h6>
            </BoxCard>

            <BoxCard
              title="ข้อมูลสัตว์"
              classNameBox="mt-3 m-2"
              headRight={buttonAddAnimal()}
            >
              <table className="table sameWidth table-striped table-bordered my-2">
                <thead>
                  <tr className="">
                    <th scope="col">{t("house_buiding")}</th>
                    <th scope="col">ชนิดสัตว์</th>
                    <th scope="col">หมายเลข</th>
                    <th scope="col">ชื่อ</th>
                    <th scope="col">เพศ</th>
                    <th scope="col">อายุ</th>
                    <th scope="col-2" className="text-center">
                      จัดการ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {form.house && form.house.length > 0 && (
                    <>
                      {form.house.map((house, h) => (
                        <React.Fragment key={h}>
                          {house.animals &&
                            house.animals.length > 0 &&
                            house.animals.map((item, i) => {
                              return renderTableAnimal(item, i, h);
                            })}
                        </React.Fragment>
                      ))}
                    </>
                  )}

                  {form.house && form.house.length > 0 ? (
                    <>
                      {checkAnimal(form.house) && (
                        <DataTableNodata colSpan={7} />
                      )}
                    </>
                  ) : (
                    <DataTableNodata colSpan={7} />
                  )}
                </tbody>
              </table>
            </BoxCard>
          </BoxCard>
        </div>

        {showMap && (
          <ModalMap
            show={showMap}
            modeMap={form.modeMap}
            lat={form.latitude}
            lon={form.longitude}
            close={() => setShowMap(false)}
            save={(value) =>
              setForm({
                ...form,
                latitude: value.latitude,
                longitude: value.longitude,
              })
            }
          />
        )}
        <ModalAddHouse
          show={showAddHouse}
          close={(value) => cancelHouseAdd(value)}
          save={(value) => addHouse(value)}
        />
        {showAddAnimal && (
          <ModalAddAnimal
            show={showAddAnimal}
            mode={form.modeAnimal}
            dataEdit={form.dataAnimalEdit}
            close={() => setShowAddAnimal(false)}
            save={(value) => {
              if (form.modeAnimal === "add") {
                addAnimal(value);
              } else {
                saveEditAnimal(value);
              }
            }}
            buidingList={houseList}
          />
        )}
      </div>
    </div>
  );
}
export default SaveData;
