
export const initialState = [
    {
        moduleId: 0,
        moduleName: "Statistic and Overview",
        path: "/statistics",
        icon: "/svg/home_1.svg",
        status: 'active',
        menu: []
    },
    {
        moduleId: 3,
        moduleName: "Passive Surveillance",
        path: "/passive_surv/disease_report",
        icon: "/svg/home_2.svg",
        status: 'active',
        menu: [
            {
                group: 1,
                groupName: "เฝ้าระวังเชิงรับ",
                path: "/passive_surv/report",
                includePath: false,
                icon: "/svg/menu_3.svg",
                submenu: [
                    {id: 9},
                    {id: 10},
                    {id: 11},
                    {id: 12},
                    {id: 13},
                    {id: 14},
                    {id: 15},
                    {id: 16}
                ]
            },
            {
                group: 2,
                groupName: "รายงาน",
                path: "/passive_surv/report",
                includePath: true,
                icon: "/svg/menu_5.svg",
                submenu: [
                    {id: 17},
                    {id: 18},
                    {id: 19},
                    {id: 20},
                    {id: 21},
                    {id: 86},
                ]
            },

        ]
    },
    {
        moduleId: 2,
        moduleName: "Active Surveillance",
        path: "/active_surv/action_plan",
        icon: "/svg/home_3.svg",
        status: 'active',
        menu: [
            {
                group: 1,
                groupName: "เฝ้าระวังเชิงรุก",
                path: "/active_surv/report",
                includePath: false,
                icon: "/svg/menu_6.svg",
                submenu: [
                    {id: 65},
                    {id: 66},
                ]
            },
            {
                group: 2,
                groupName: "รายงาน",
                path: "/active_surv/report",
                includePath: true,
                icon: "/svg/menu_5.svg",
                submenu: [
                    {id: 5},
                    {id: 6},
                    {id: 7},
                    {id: 8}
                ]
            },

        ]
    },
    {
        moduleId: 1,
        moduleName: "Farm Resigration",
        path: "/farm_registration",
        icon: "/svg/home_7.svg",
        status: 'active',
        menu: [
            {
                group: 1,
                groupName: "ขึ้นทะเบียนฟาร์ม",
                path: "/farm_registration/farm_registration_report",
                includePath: false,
                icon: "/svg/menu_2.svg",
                submenu: {id: 63}
            },
            {
                group: 2,
                groupName: "รายงาน",
                path: "/farm_registration/farm_registration_report",
                includePath: true,
                icon: "/svg/menu_5.svg",
                submenu: [
                    {id: 64},
                ]
            },

        ]
    },
    {
        moduleId: 4,
        moduleName: "Risk Assessment",
        path: "/assess_risk/list-assess-risk",
        icon: "/svg/home_4.svg",
        status: 'active',
        menu: [
            {
                group: 1,
                groupName: "ประเมินความเสี่ยง",
                path: "/assess_risk/report/risk",
                includePath: false,
                icon: "/svg/assess_icon.svg",
                submenu: [
                    {id: 22},
                    {id: 23},
                    {id: 24},
                ]
            },
            {
                group: 2,
                groupName: "รายงาน",
                path: "/assess_risk/report/risk",
                includePath: true,
                icon: "/svg/menu_5.svg",
                submenu: [
                    {id: 25},
                ]
            },

        ]
    },
    {
        moduleId: 5,
        moduleName: "GFM",
        path: "/gfm",
        icon: "/svg/home_5.svg",
        status: 'active',
        menu: [
            {
                group: 1,
                groupName: "GFM",
                path: "/gfm/report",
                includePath: false,
                icon: "/svg/gene.svg",
                submenu: 
                    {id: 26},

            },
            {
                group: 2,
                groupName: "รายงาน",
                path: "/gfm/report",
                includePath: true,
                icon: "/svg/menu_5.svg",
                submenu: [
                    {id: 27},
                ]
            },

        ]
    },
    {
        moduleId: 6,
        moduleName: "Configulation",
        path: "/data-manage/web-settings/news",
        icon: "/svg/home_6.svg",
        status: 'active',
        menu: [
            {
                group: 1,
                groupName: "ตั้งค่าเวบไซต์",
                path: "/web-settings",
                includePath: true,
                icon: "/svg/setting.svg",
                submenu: [
                    {id: 28},
                    {id: 29},
                    {id: 30},
                    {id: 31},
                    {id: 32},
                    {id: 33},
                    {id: 34},
                    {id: 35},
                    {id: 73},
                ]
            },
            {
                group: 2,
                groupName: "ผู้ใช้งาน",
                path: "/users",
                includePath: true,
                icon: "/svg/menu-user.svg",
                submenu: [
                    {id: 36},
                    {id: 37},
                    {id: 38},
                ]
            },
            {
                group: 2,
                groupName: "Master Data",
                path: "/master-data",
                includePath: true,
                icon: "/svg/master-data.svg",
                submenu: [
                    {id: 39},
                    {id: 40},
                    {id: 41},
                    {id: 42},
                    {id: 43},
                    {id: 44},
                    {id: 45},
                    {id: 46},
                    {id: 47},
                    {id: 48},
                    {id: 49},
                    {id: 50},
                    {id: 51},
                    {id: 52},
                    {id: 53},
                    {id: 54},
                    {id: 55},
                    {id: 56},
                    {id: 57},
                    {id: 58},
                    {id: 59},
                    {id: 60},
                    {id: 61},
                    {id: 74},
                    {id: 84},
                    {id: 85},
                ]
            },
            {
                group: 3,
                groupName: "รายงาน",
                path: "/users",
                includePath: true,
                icon: "/svg/menu_5.svg",
                submenu: [
                    {id: 75},
                    {id: 76},
                    
                ]
            },
        ]
    },
]

export default (state = initialState) => {  
    return state;
};