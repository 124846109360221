import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col, Table } from 'react-bootstrap';

import * as API from "../../utils/apis";
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";

function ModalMenuSystem({ show, close, save, mode, idEdit }) {
    const user = useSelector(state => state.login.result);
    const initialForm = {
        gfmId: "",
        search: "",
        animalTypeId: "",
        animalTypeName: "",
        minorValue: "",
        smallValue: "",
        mediumValue: "",
        statusId: "",

        minorValue: "",
        smallValue: "",
        mediumValue: "",

        createDate: "",
        updateDate: "",
        createByName: "",
        updateByName: "",

        checkShow: false
    };
    const initInvalidMsg = {
        minorValue: "",
        smallValue: "",
        mediumValue: "",
        animalTypeId: "",
        status: "",
    };
    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];

    const dispatch = useDispatch();
    const history = useHistory();

    const [form, setForm] = useState(initialForm);

    const [invalid, setInvalid] = useState(initInvalidMsg);

    //==========Module
    const [animalTypeSelectValue, setAnimalTypeSelectValue] = useState([])
    const [animalList, setModuleList] = useState([])

    const [statusSelectValue, setStatusSelectValue] = useState([]);
    const [statusList, setStatusList] = useState(selectStatusList);

    useEffect(() => {
        if (mode === "add") {
            setForm(initialForm)
            setStatusSelectValue([])
        }
    }, [mode])

    useEffect(() => {
        if ((form.animalTypeId && form.animalTypeId === "") || form.animalTypeId === "") {
            getAnimalType()
        }
    }, [form.animalTypeId])

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;
        if (form.animalTypeId === "") {
            addInvalid("animalTypeId", "โปรดเลือกชนิดสัตว์");
            validated = false;
        }
        if (form.minorValue === "") {
            addInvalid("minorValue", "โปรดระบุจำนวนขั้นต่ำ");
            validated = false;
        }
        if (form.smallValue === "") {
            addInvalid("smallValue", "โปรดระบุจำนวนขั้นต่ำ");
            validated = false;
        }
        if (form.mediumValue === "") {
            addInvalid("mediumValue", "โปรดระบุจำนวนขั้นต่ำ");
            validated = false;
        }

        if (Number(form.minorValue) > Number(form.smallValue) || Number(form.minorValue) > Number(form.mediumValue)) {
            addInvalid("minorValue", "จำนวนรายย่อย < จำนวนรายเล็ก < จำนวนรายกลาง");
            validated = false;
        }
        if (Number(form.smallValue) < Number(form.minorValue) || Number(form.smallValue) > Number(form.mediumValue)) {
            addInvalid("smallValue", "จำนวนรายย่อย < จำนวนรายเล็ก < จำนวนรายกลาง");
            validated = false;
        }
        if (Number(form.mediumValue) < Number(form.minorValue) || Number(form.mediumValue) < Number(form.smallValue)) {
            addInvalid("mediumValue", "จำนวนรายย่อย < จำนวนรายเล็ก < จำนวนรายกลาง");
            validated = false;
        }





        if (form.statusId === "") {
            addInvalid("status", "โปรดระบุสถานะ");
            validated = false;
        }
        return validated;
    };

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setAnimalTypeSelectValue([])
        setStatusSelectValue([])
    }

    const toConfirm = async () => {
        if (validate()) {
            clearValidate()
            await save({ gfmFarmerType: form, mode: mode });
        }
    };

    const getGFMFarmerTypeById = async (id, animalTypeArray) => {
        let dataGFMFarmerType;
        try {
            const response = await API.getGFMFarmerTypeById(id);
            const data = await response?.data;
            if (response?.status === 200) {
                dataGFMFarmerType = data;
            }
        } catch (error) {
            if (error?.response && error?.response?.status === 401) {
                dispatch(logout({ history }))
            }
            console.log(error)
        }

        if (dataGFMFarmerType.statusId) {
            let statusSelect = statusList.find(item => item.value === dataGFMFarmerType?.statusId);
            setStatusSelectValue(statusSelect);
        }

        if (dataGFMFarmerType.animalTypeId) {
            let animalTypeSelect = animalTypeArray.find((item) => item.value === dataGFMFarmerType?.animalTypeId)
            setAnimalTypeSelectValue(animalTypeSelect)
        }
        setForm({
            ...form,
            gfmId: dataGFMFarmerType.id,
            animalTypeName: dataGFMFarmerType.animalTypeName,
            animalTypeId: dataGFMFarmerType.animalTypeId,
            statusId: dataGFMFarmerType.statusId,

            minorValue: dataGFMFarmerType.minorValue,
            smallValue: dataGFMFarmerType.smallValue,
            mediumValue: dataGFMFarmerType.mediumValue,

            createDate: dataGFMFarmerType.createDate,
            updateDate: dataGFMFarmerType.updateDate,
            createByName: dataGFMFarmerType.createByName,
            updateByName: dataGFMFarmerType.updateByName,
        })
    }

    const getAnimalType = async () => {
        try {
            const response = await API.getAnimal();
            const data = await response?.data;
            if (response.status === 200) {
                let animalTypeArray = [];
                data.content.forEach(element => {
                    animalTypeArray.push({
                        value: element.id, label: element.name,
                    });
                });
                setModuleList(animalTypeArray);

                if (mode === "edit" && !form?.checkShow) {
                    getGFMFarmerTypeById(idEdit, animalTypeArray)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const clearValidate = () => {
        for (const [key, value] of Object.entries(initInvalidMsg)) {
            removeInvalid(key)
        }
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => handleClose()}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่ม/แก้ไขประเภทเกษตกรมาตรฐาน GFM</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="ชนิดสัตว์"
                                        star={true}
                                        placeholder="กรุณาเลือก"
                                        idName="animalTypeId"
                                        classLabel="normal"
                                        selectValue={animalTypeSelectValue}
                                        optionsList={animalList}
                                        handleChange={(value) => {
                                            setAnimalTypeSelectValue(value)
                                            setForm({ ...form, animalTypeId: value.value })
                                        }}
                                        handleInvalid={() => removeInvalid("animalTypeId")}
                                        invalid={invalid.animalTypeId}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="จำนวนน้อยกว่าเท่ากับ(รายย่อย)" idName="minorValue" value={form.minorValue}
                                        star={true}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => {
                                            const pattern = /^\d+$/;
                                            if (pattern.test(value) || !value) {
                                                setForm({ ...form, minorValue: value, checkShow: true })
                                            }
                                        }}
                                        handleInvalid={() => removeInvalid("minorValue")}
                                        invalid={invalid.minorValue}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="จำนวนน้อยกว่าเท่ากับ(รายเล็ก)" idName="smallValue" value={form.smallValue}
                                        star={true}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => {
                                            const pattern = /^\d+$/;
                                            if (pattern.test(value) || !value) {
                                                setForm({ ...form, smallValue: value, checkShow: true })
                                            }
                                        }}
                                        handleInvalid={() => removeInvalid("smallValue")}
                                        invalid={invalid.smallValue}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="จำนวนน้อยกว่าเท่ากับ(รายกลาง)" idName="mediumValue" value={form.mediumValue}
                                        star={true}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => {
                                            const pattern = /^\d+$/;
                                            if (pattern.test(value) || !value) {
                                                setForm({ ...form, mediumValue: value, checkShow: true })
                                            }
                                        }}
                                        handleInvalid={() => removeInvalid("mediumValue")}
                                        invalid={invalid.mediumValue}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="align-items-left text-gray font12">
                                        {"**รายใหญ่คำนวณจากจำนวนมากว่ารายกลาง	"}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                        star={true}
                                        selectValue={statusSelectValue} optionsList={statusList}
                                        handleChange={(value) => {
                                            setStatusSelectValue(value)
                                            setForm({ ...form, statusId: value.value, checkShow: true })
                                        }}
                                        handleInvalid={() => removeInvalid("status")}
                                        invalid={invalid.status}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={12} sm={12}>
                                    <table>
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-left text-gray font12">
                                                    วันที่บันทึกข้อมูล
                                                </div>
                                            </td>
                                            <td>
                                                <div className="align-items-left text-gray font12">
                                                    {form.createDate ? helper.momentDateTime(form.createDate, "th", "short") + " " + helper.getTime(form.createDate, "th") : '-'}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <div className="align-items-left text-gray font12">
                                                    {form.createByName || '-'}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="align-items-left text-gray font12">
                                                    วันที่ปรับปรุงข้อมูล
                                                </div>
                                            </td>
                                            <td>
                                                <div className="align-items-left text-gray font12">
                                                    {form.updateDate ? helper.momentDateTime(form.updateDate, "th", "short") + " " + helper.getTime(form.updateDate, "th") : '-'}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <div className="align-items-left text-gray font12">
                                                    {form.updateByName || '-'}
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </Col>

                            </Row>
                        </div>
                    </div>
                </div>

                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfirm()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalMenuSystem
