import React, { useState, useEffect } from 'react'
import * as helper from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";

function ModalAnalyse({ show, close, save, mode, idEdit }) {
    const user = useSelector(state => state.login.result);
    const initialForm = {
        analyse_id: "",
        analyse_name: "",
        status: "",
        createDate: "",
        updateDate: "",
        checkShow: false
    };
    const initInvalidMsg = {
        analyse_name: "",
        status: "",
    };
    const selectStatusList = [
        { value: 1, label: "ใช้งาน" },
        { value: 2, label: "ไม่ใช้งาน" },
    ];
    const initStatusSelectValue = [];

    const dispatch = useDispatch();
    const history = useHistory();

    const [form, setForm] = useState(initialForm);
    const [invalid, setInvalid] = useState(initInvalidMsg);
    const [statusSelectValue, setStatusSelectValue] = useState(initStatusSelectValue);
    const [statusList, setStatusList] = useState(selectStatusList);

    useEffect(async () => {
        if (mode === "add") {
            await setForm(initialForm)
            setStatusSelectValue([])

        }
    }, [mode])

    useEffect(async () => {
        if (mode === "edit" && !form?.checkShow) {
            await getAnalyseById(idEdit)
        }
    }, [mode, idEdit, show, form?.analyse_name, form?.status])

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const removeInvalid = (element) => {
        invalid[element] = "";
        setInvalid({ ...invalid });
    };

    const validate = () => {
        let validated = true;
        if (form.analyse_name === "") {
            addInvalid("analyse_name", "โปรดระบุชื่อปัจจัย");
            validated = false;
        }
        if (form.status === "") {
            addInvalid("status", "โปรดระบุสถานะ");
            validated = false;
        }
        return validated;
    };

    const handleClose = () => {
        close()
        setInvalid(initInvalidMsg)
        setForm(initialForm)
        setStatusSelectValue([])
    }

    const toConfrim = async () => {
        if (validate()) {
            await save({ analyse: form, mode: mode });
            handleClose();
        }
    };

    const getAnalyseById = async (id) => {
        let dataAnl;
        try {
            const response = await API.getAnalyseById(id);
            const data = await response?.data;
            if (response?.status === 200) {
                dataAnl = data;
            }
        } catch (error) {
            dispatch(loading(false))
            if (error?.response && error?.response?.status == 401) {
                dispatch(logout({ history }))

            }
            console.log(error)
        }

        if (dataAnl.statusId) {
            let statusSelect = await statusList.find(item => item.value === dataAnl?.statusId);
            setStatusSelectValue(statusSelect);
        }

        setForm({
            ...form,
            analyse_id: dataAnl.id,
            analyse_name: dataAnl.name,
            status: dataAnl.statusId,
            createDate: dataAnl.createDate,
            updateDate: dataAnl.updateDate
        })
    }

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => handleClose()}
            centered
        >
            <Modal.Header closeButton className="pb-2 ">
                <Modal.Title className="mt-2">
                    <h5><strong>เพิ่มปัจจัย</strong></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item tab">
                            <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">ข้อมูลพื้นฐาน</a>
                        </li>
                        <li className={invalid.status ? "nav-item-invalid tab" : "nav-item tab"}>
                            <a className="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">สถานะ</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active card-tab" id="data" role="tabpanel" aria-labelledby="data-tab">
                            <Row>
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <InputText type="text" title="ชื่อปัจจัย" idName="analyse_name" value={form.analyse_name} star={false}
                                        placeholder="โปรดระบุ" classLabel="normal"
                                        handleChange={(value) => setForm({ ...form, analyse_name: value, checkShow: true })}
                                        handleInvalid={() => removeInvalid("analyse_name")}
                                        invalid={invalid.analyse_name}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="tab-pane fade card-tab" id="status" role="tabpanel" aria-labelledby="status-tab">
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <InputSelect
                                        title="สถานะ" star={false} placeholder="กรุณาเลือก" idName="title" classLabel="normal"
                                        selectValue={statusSelectValue} optionsList={statusList}
                                        handleChange={(value) => {
                                            setStatusSelectValue(value)
                                            setForm({ ...form, status: value.value, checkShow: true })
                                        }}
                                        handleInvalid={() => removeInvalid("status")} invalid={invalid.status}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                                        <p className="mr-4">วันที่บันทึกข้อมูล</p>
                                        <p>{form.createDate ? helper.momentDate(form.createDate, "th", "short") : '-'}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={6} sm={12} xs={12}>
                                    <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                                        <p className="mr-3" >วันที่ปรับปรุงข้อมูล</p>
                                        <p>{form.updateDate ? helper.momentDate(form.updateDate, "th", "short") : '-'}</p>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </div>
                </div>
                <div className="mr-2 mt-3 mb-2">
                    <div className="text-right py-2">
                        <button className="btn btn-danger px-4 mr-2" onClick={() => handleClose()}>ยกเลิก</button>
                        <button className="btn btn-success px-4" onClick={() => toConfrim()} disabled={user?.userGroup.mupdate === "n" && mode === "edit"}>บันทึก</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalAnalyse
