import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BoxCard from "../../components/BoxCard";
import DataTableNodata from "../../components/DataTableNodata";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import InputMask from "../../components/InputMask";
import Breadcrumb from "../../components/Breadcrumb";
import UploadImageFile from "../../components/UploadImageFile";
import * as API from "../../utils/apis";
import withReactContent from "sweetalert2-react-content";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { Row, Col, Table } from "react-bootstrap";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function Disease_Report_Save(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t, i18n } = useTranslation(["common", "footer"]);

  const initialForm = {
    chooseSelect: false,
    name: "",
    animal_owner_name: "",
    provinceId: "",
    districtId: "",
    phone: "",
    date_found_disease: "",
    subDistrictId: "",
    villageId: "",
    status: "",
    email: "",
    home: "",
    no: "",
    animal: "",
    animal_dead: "",
    animal_sick: "",
    note: "",
    channel: "",
    details: "",
    symptom: "",
    save: "",
    animal_number: "",

    //อาการป่วย
    numberCertificate: "",
    symptom: [],
    save: true,

    // ข้อมูลแนบไฟล์
    data_file: [],
    checkAddFile: false,
    //==============
  };
  const [form, setForm] = useState(initialForm);

  const [symptomDisableList, setSymptomDisableList] = useState([]);

  const initialFormFile = {
    url: "",
    file: "",
    detail_file: "",
    data_file: "",
  };
  const [formFile, setFormFile] = useState(initialFormFile);

  const initInvalidMsg = {
    name: "",
    phone: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    animal_sick: "",
    animal_dead: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  // select ช่องทางการแจ้งโรค /ชนิดสัตว์ /สถานะการดำเนินการ
  const [ChannelSelectValue, setChannelSelectValue] = useState([]);
  const [ChannelList, setChannelList] = useState([]);

  const [AnimalSelectValue, setAnimalSelectValue] = useState([]);
  const [AnimalList, setAnimalList] = useState([]);

  const [StatusSelectValue, setStatusSelectValue] = useState([]);
  const [StatusList, setStatusList] = useState([]);

  const [animalSymptomSelectValue, setAnimalSymptomSelectValue] = useState([]);
  const [animalSymptomList, setAnimalSymptomList] = useState([]);

  const [addSymptoms, setAddSymptoms] = useState([]);
  // =================

  useMasterData("province", provinceList, setProvinceList);

  useEffect(async () => {
    await getStatus();
    await getProvince();
    await getAnimal();
    await getInform();
  }, []);

  useEffect(async () => {
    if (location.pathname.includes("edit") && !form.chooseSelect) {
      getReportDiseaseById(props.match.params.id);
    }
  }, [
    user,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
    ChannelList,
  ]);

  useEffect(async () => {
    if (location.pathname.includes("save")) {
      await getDataReportDisease();
    }
  }, [user]);

  const getDataReportDisease = async () => {
    if (user) {
      setForm({
        ...form,
        name: `${user.profile.firstName} ${user.profile.lastName}`,
        email: user.profile.email,
        phone: user.profile.tel,
      });
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = async () => {
    try {
      const response = await API.getStatus("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let statusArray = [];
        data.content.forEach((element) => {
          statusArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setStatusList(statusArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getInform = async () => {
    try {
      const response = await API.getInform("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let channelArray = [];
        data.content.forEach((element) => {
          channelArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setChannelList(channelArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimalSymptom = async (animalId) => {
    try {
      const response = await API.getAnimalSymptom(animalId, 1);
      const data = await response.data;
      if (response.status == 200) {
        let symptomArray = [];
        data.content.forEach((element) => {
          symptomArray.push({
            value: element.id,
            label: element.name,
          });
        });
        symptomArray.push({
          value: 0,
          label: "อื่นๆ",
        });
        setAnimalSymptomList(symptomArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id);
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReportDiseaseById = async (id) => {
    let dataReport;
    try {
      const response = await API.getReportDiseaseById(id);
      const data = await response.data;
      if (response.status == 200) {
        dataReport = data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataReport) {
      if (dataReport.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataReport.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataReport.provinceId);
        }
      }

      if (dataReport.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataReport.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataReport.amphurId);
        }
      }

      if (dataReport.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataReport.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataReport.tambonId);
        }
      }

      if (dataReport.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataReport.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataReport.animalTypeIds) {
        let animalSelect = await AnimalList.find(
          (item) => item.value == dataReport.animalTypeIds
        );
        setAnimalSelectValue(animalSelect);
        await getAnimalSymptom(dataReport.animalTypeIds);
      }

      if (dataReport.informById) {
        let channelSelect = await ChannelList.find(
          (item) => item.value == dataReport.informById
        );
        setChannelSelectValue(channelSelect);
      }

      if (dataReport.statusId) {
        let statusSelect = await StatusList.find(
          (item) => item.value == dataReport.statusId
        );
        setStatusSelectValue(statusSelect);
      }

      let fileArray = [];
      if (dataReport.informFiles && dataReport.informFiles.length > 0) {
        dataReport.informFiles.map(async (item, i) => {
          fileArray.push({
            url: item.url,
            file: {
              name: item.name,
            },
            detail_file: item.remark,
            data_file: item.createDate,
            id: item.id,
          });
        });
      }

      let symptomData = [];
      let data = dataReport.informSymptoms;
      if (data && data.length > 0) {
        data.map((item, i) => {
          if (item.symptomId == 0) {
            item.symptomName = "อื่นๆ"
          }  
          symptomData.push({
            id: item.id ? item.id : "",
            symptom: { value: item.symptomId, label: item.symptomName, other: item.symptomOther  },
            save: false,
          });
        });
        setAddSymptoms(symptomData);
      }

      setForm({
        ...form,
        name: dataReport.fullname,
        email: dataReport.email,
        phone: dataReport.tel,
        date_found_disease: dataReport.foundDiseaseDate,
        animal_owner_name: dataReport.ownerName,
        no: dataReport.address,
        provinceId: dataReport.provinceId,
        districtId: dataReport.amphurId,
        subDistrictId: dataReport.tambonId,
        villageId: dataReport.mooId,
        animal: dataReport.animalTypeIds,
        animal_dead: dataReport.deadAmt,
        animal_sick: dataReport.sickAmt,
        animal_number: dataReport.animalAmt,
        symptom: dataReport.symptomId,
        note: dataReport.remark,
        channel: dataReport.informById,
        details: dataReport.officerText,
        status: dataReport.statusId,
        data_file: fileArray,
      });
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([value]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      zipcode: "",
      districtId: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([value]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      districtId: value.value,
      zipcode: "",
      subDistrictId: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([value]);
    setForm({
      ...form,
      subDistrictId: value.value,
      zipcode: value.zipcode,
      villageId: "",
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    {
      path: "/passive_surv/disease_report",
      name: "แจ้งพบ - สงสัยโรคระบาดสัตว์",
    },
    { path: "active", name: "บันทึกการแจ้งพบ - สงสัยโรคระบาดสัตว์" },
  ];

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if (form.name == "") {
      addInvalid("name", "กรุณากรอกชื่อ-นามสกุลผู้แจ้ง");
      validated = false;
    }

    if (!helper.checkEmailFormat(form.email.trim())) {
      addInvalid("email", "กรุณากรอกอีเมล์ให้ถูกต้อง");
      validated = false;
    }

    if (form.phone == "") {
      addInvalid("phone", "กรุณากรอกเบอร์โทรศัพท์");
      validated = false;
    }

    if (form.provinceId == "") {
      addInvalid("provinceId", "กรุณาเลือกจังหวัด");
      validated = false;
    }

    if (form.districtId == "") {
      addInvalid("districtId", "กรุณาเลือกอำเภอ");
      validated = false;
    }

    if (form.subDistrictId == "") {
      addInvalid("subDistrictId", "กรุณาเลือกตำบล");
      validated = false;
    }

    if (form.villageId == "") {
      addInvalid("villageId", "กรุณาเลือกหมู่");
      validated = false;
    }

    if (form.name == "") {
      addInvalid("animal_sick", "จำนวนสัตว์ป่วยต้องไม่มากกว่าจำนวนสัตว์ร่วมฝูง");
      validated = false;
    }

    if (form.name == "") {
      addInvalid("animal_dead", "จำนวนสัตว์ตายต้องไม่มากกว่าจำนวนสัตว์ร่วมฝูง");
      validated = false;
    }
    if (form.animal == "") {
      addInvalid("animal", "กรุณาเลือกชนิดสัตว์");
      validated = false;
    }
    if (form.animal_dead == "") {
      addInvalid("animal_dead", "กรุณาระบุจำนวนสัตว์ที่ตาย");
      validated = false;
    }
    if (form.animal_sick == "") {
      addInvalid("animal_sick", "กรุณาระบุจำนวนสัตว์ที่ป่วย");
      validated = false;
    }
    if (form.animal_number == "") {
      addInvalid("animal_number", "กรุณาระบุจำนวนสัตว์ร่วมฝูง");
      validated = false;
    }

    return validated;
  };

  const saveAddDisease = async () => {
    if (validate()) {
      dispatch(loading(true));
      const formData = new FormData();
      formData.append("fullname", form.name || "");
      formData.append("email", form.email || "");
      formData.append("tel", form.phone || "");
      if (form.date_found_disease) {
        formData.append(
          "foundDiseaseDate",
          helper.getDate(form.date_found_disease)
        );
      }
      formData.append("ownerName", form.animal_owner_name || "");
      formData.append("address", form.no || "");
      formData.append("provinceId", form.provinceId || "");
      formData.append("amphurId", form.districtId || "");
      formData.append("tambonId", form.subDistrictId || "");
      formData.append("mooId", form.villageId || "");
      formData.append("animalTypeIds", form.animal || "");
      formData.append("deadAmt", form.animal_dead || "");
      formData.append("sickAmt", form.animal_sick || "");
      formData.append("animalAmt", form.animal_number || "");
      // formData.append("", form.animal_pets || "");
      formData.append("symptomId", form.symptom || "");
      formData.append("remark", form.note || "");
      formData.append("informById", form.channel || "");
      formData.append("officerText", form.details || "");
      formData.append("statusId", form.status || "");

      let url;
      // for (let index = 0; index < form.data_file.length; index++) {
      //   const element = form.data_file[index];
      //   if (element.url) {
      //     const response = await fetch(element.url);
      //     const dataR = await response.blob();
      //     const metadata = {
      //       type: "image/jpeg",
      //     };
      //     url = new File([dataR], element.file.name, metadata);
      //   } else {
      //     url = element.file;
      //   }

      //   formData.append(`informsFiles[${index}]`, url);
      //   formData.append(`informsFilesRemark[${index}]`, element.detail_file);
      // }

      for (let index = 0; index < addSymptoms.length; index++) {
        const element = addSymptoms[index];
        formData.append(`InformsSymptoms[${index}]`, element.symptom.value);
        formData.append(`InformsSymptomsOther[${index}]`, (element.symptom.other !== undefined) ? element.symptom.other : "");
      }

      let response;
      if (location.pathname.includes("disease_report/savedisease_report")) {
        try {
          response = await API.saveReportDisease(formData);
          if (response.status == 200) {
            dispatch(loading(false));
            MySwal.fire({
              text: "แจ้งโรคระบาดสัตว์สำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then((result) => {
              if (result.value) {
                history.push("/passive_surv/disease_report");
              }
            });
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response.status == 401) {
            console.log(error.response);
            dispatch(logout({ history }));
          }
        }
      } else {
        try {
          response = await API.updateReportDisease(
            props.match.params.id,
            formData
          );
          if (response.status == 200) {
            dispatch(loading(false));
            MySwal.fire({
              text: "แก้ไขการแจ้งโรคระบาดสัตว์สำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then((result) => {
              if (result.value) {
                history.push("/passive_surv/disease_report");
              }
            });
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response.status == 401) {
            console.log(error.response);
            dispatch(logout({ history }));
          }
        }
      }
    }
  };

  const toAddFile = () => {
    setForm({ ...form, checkAddFile: true });
    setFormFile({
      ...formFile,
      date_file: Date.now(),
      detail_file: "",
      file: "",
    });
  };

  const DelFile = (index) => {
    form.data_file.splice(index, 1);
    setForm({ ...form, data_file: form.data_file });
  };

  const toCancel = () => {};

  const fileSave = (data) => {
    let arrayFile = [...form.data_file];
    arrayFile.push({
      url: "",
      file: data.file,
      detail_file: data.detail_file,
      date_file: Date.now(),
    });
    setForm({ ...form, data_file: arrayFile, checkAddFile: false });
  };

  const renderTableFile = (data, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          {data.id ? (
            <span
              className="link"
              onClick={() => window.open(data.url, "_blank").focus()}
            >
              {data.file && data.file.name}
            </span>
          ) : (
            data.file.name
          )}
        </td>
        <td>{data.detail_file}</td>
        <td>{helper.getDateFormat(data.date_file)}</td>

        <td className="text-center">
          <button className="btn btn-del " onClick={() => DelFile(index)}>
            <img
              className="img-fluid icon-btn "
              src="/svg/btn-del.svg"
              alt="del"
            />
          </button>
        </td>
      </tr>
    );
  };

  const renderAddFile = () => {
    return (
      <>
        <tr>
          <td>{form.data_file.length + 1}</td>
          <td>
            <UploadImageFile
              title=" "
              classLabel="normal mt-2"
              star={false}
              type="fileName"
              onChange={(value) => setFormFile({ ...formFile, file: value })}
              id="imgfile"
              position="left"
              files={
                formFile.file == null || formFile.file == ""
                  ? ""
                  : formFile.file
              }
            />
          </td>

          <td>
            <InputText
              classFormGroup="mb-0 "
              type="text"
              idName="count"
              value={formFile.detail_file}
              star={false}
              placeholder="โปรดระบุ"
              handleChange={(value) =>
                setFormFile({ ...formFile, detail_file: value })
              }
            />
          </td>

          <td>{helper.getDateFormat(formFile.date_file)}</td>
          <td className="text-center">
            <button
              className="btn btn-success  pt-1 px-2"
              onClick={async () => {
                fileSave(formFile);
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </td>
        </tr>
      </>
    );
  };

  const buttonAddFile = () => {
    return (
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddFile()}
          disabled={form.checkAddFile}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มไฟล์
        </button>
      </div>
    );
  };

  const checkAddSymptomNew = () => {
    return addSymptoms.find((item) => {
      return item.save == true;
    });
  };

  const toAddSymptom = () => {
    const data = addSymptoms.map((item) => {
      return { ...item, edit: true };
    });
    setAddSymptoms([...data, { symptom: [], save: true }]);
  };

  const buttonAddSymptom = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddSymptom()}
          disabled={checkAddSymptomNew() || !form.animal}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const renderSymtomsTable = (item, i) => {
    return (
      <tr key={i} className="">
        <td>{i + 1}</td>
        <td>
          {item.save ? (
            <InputSelect
              classFormGroup="mr-0"
              star={false}
              placeholder="กรุณาเลือก"
              idName={`Symptom_${i}`}
              classLabel="normal"
              selectValue={item.symptom}
              optionsList={animalSymptomList}
              handleChange={(value) => {
                console.log(value);
                item.symptom = value;
                item.symptom.other = ""
                setAnimalSymptomSelectValue(value);
                setAddSymptoms([...addSymptoms]);
                
              }}
            />
          ) : (
            <span className="text-nowrap">{item.symptom?.label}</span>
          )}
          {item.save ? (
            <InputText
              classFormGroup="mr-0"
              disabled={ item.symptom.value == 0 ? false : true }
              star={false}
              value={item.symptom.other}
              placeholder="ระบุ"
              idName={`SymptomOther_${i}`}
              classLabel="normal"
              handleChange={(value) => {
                item.symptom.other = value
                setAddSymptoms([...addSymptoms]);
              }}
            />
          ) : (
            <span className="text-nowrap">{item.symptom.value == 0 ? "(" + item.symptom.other + ")" : "" }</span>
          )}
        </td>
        <td className="text-center">
          {item.save ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={() => {
                item.save = false;
                const data = addSymptoms.map((item) => {
                  return { ...item, edit: false };
                });
                setAddSymptoms([...data]);
              }}
              disabled={(item.symptom.value == 0 && !item.symptom.other)  || (!item.symptom.value &&  item.symptom.value != 0)  ?  true : false }
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => {
                  item.save = true;
                  setAddSymptoms([...addSymptoms]);
                }}
                disabled={item.edit}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button
                className="btn btn-del "
                onClick={() => {
                  addSymptoms.splice(i, 1);
                  setAddSymptoms([...addSymptoms]);
                }}
                disabled={item.edit}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <div className="pl-lg-4 pl-md-4 pl-sm-2 bg-white d-flex flex-wrap align-items-center ml30">
        <div className="mr-auto p-2">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2">
          <button
            className="btn btn-danger"
            onClick={() => history.push(`/passive_surv/disease_report`)}
          >
            ยกเลิก
          </button>

          <button
            className="btn btn-success mx-3"
            onClick={() => saveAddDisease()}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`passive_surv/disease_report/edit`)
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="1. ข้อมูลผู้แจ้งโรคระบาดสัตว์" classLabel="">
            <div className=" ">
              <Row className="mb-2 ">
                <Col lg={2} md={4} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่อ-สกุล ผู้แจ้ง"
                    idName="name"
                    value={form.name}
                    star={true}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, name: value })}
                    handleInvalid={() => removeInvalid("name")}
                    invalid={invalid.name}
                  />
                </Col>

                <Col lg={2} md={4} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="อีเมล"
                    idName="email"
                    value={form.email}
                    star={true}
                    placeholder="อีเมล"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, email: value })}
                    handleInvalid={() => removeInvalid("email")}
                    invalid={invalid.email}
                  />
                </Col>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <InputMask
                    mask="99-9999-9999"
                    title="หมายเลขโทรศัพท์"
                    idName="phone"
                    value={form.phone}
                    star={true}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, phone: value.replace(/-/g, "") })
                    }
                    handleInvalid={() => removeInvalid("phone")}
                    invalid={invalid.phone}
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard classNameBox="mt-3" title="2. จุดที่พบโรค">
            <div className=" ">
              <Row className="mb-2">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่พบโรค"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.date_found_disease}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, date_found_disease: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่อ-สกุลเจ้าของสัตว์"
                    idName="animal_owner_name"
                    value={form.animal_owner_name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, animal_owner_name: value })
                    }
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="เลขที่"
                    idName="no"
                    value={form.no}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, no: value })}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="บ้าน"
                    idName="home"
                    value={form.home}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, home: value })}
                  />
                </Col>
              </Row>

              <Row className="mb-2">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="provinceId"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                    handleInvalid={() => removeInvalid("provinceId")}
                    invalid={invalid.provinceId}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="districtId"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => selectValueDistrict(value)}
                    handleInvalid={() => removeInvalid("districtId")}
                    invalid={invalid.districtId}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ตำบล"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="subDistrictId"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => selectValueSubDistrict(value)}
                    handleInvalid={() => removeInvalid("subDistrictId")}
                    invalid={invalid.subDistrictId}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="หมู่ที่"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="villageId"
                    classLabel="normal"
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({ ...form, villageId: value.value });
                    }}
                    handleInvalid={() => removeInvalid("villageId")}
                    invalid={invalid.villageId}
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard classNameBox="mt-3" title="3. รายละเอียด">
            <div className=" ">
              <Row className="mb-2">
                <Col lg={2} md={4} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดสัตว์"
                    star={true}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={AnimalSelectValue}
                    optionsList={AnimalList}
                    handleChange={(value) => {
                      setAnimalSelectValue([value]);
                      setForm({ ...form, animal: value.value });
                      getAnimalSymptom(value.value);
                      if (!checkAddSymptomNew()) {
                        toAddSymptom()
                      }
                    }}
                    handleInvalid={() => removeInvalid("animal")}
                    invalid={invalid.animal}
                  />
                </Col>

                <Col lg={2} md={4} sm={12} xs={12}>
                  <InputText
                    type="number"
                    title="จำนวนสัตว์ที่ตาย (ตัว)"
                    idName="animal_dead"
                    value={form.animal_dead}
                    star={true}
                    placeholder="โปรดระบุ ไม่เกินจำนวนสัตว์ร่วมฝูง"
                    classLabel="normal"
                    handleChange={(value) => {
                      if (value >= 0) {
                        if  ((parseInt(value) + parseInt(form.animal_sick)) > parseInt(form.animal_number)) {
                          setInvalid(invalid.animal_dead)
                        } else {
                          setForm({ ...form, animal_dead: value });
                        }
                      }
                    }}
                    handleInvalid={() => removeInvalid("animal_dead")}
                    invalid={invalid.animal_dead}
                  />
                </Col>

                <Col lg={2} md={4} sm={12} xs={12}>
                  <InputText
                    type="number"
                    title="จำนวนสัตว์ที่ป่วย (ตัว)"
                    idName="animal_sick"
                    
                    value={form.animal_sick}
                    star={true}
                    placeholder="โปรดระบุ ไม่เกินจำนวนสัตว์ร่วมฝูง"
                    classLabel="normal"
                    handleChange={(value) => {
                      if (value >= 0) {
                        if  ((parseInt(value) + parseInt(form.animal_dead)) > parseInt(form.animal_number)) {
                          setInvalid(invalid.animal_sick)
                        } else {
                          setForm({ ...form, animal_sick: value });
                        }
                        
                      }
                    }}
                    handleInvalid={() => removeInvalid("animal_sick")}
                    invalid={invalid.animal_sick}
                    
                  />
                </Col>

                <Col lg={2} md={4} sm={12} xs={12}>
                  <InputText
                    type="number"
                    title="จำนวนสัตว์ร่วมฝูง (ตัว)"
                    idName="animal_number"
                    value={form.animal_number}
                    star={true}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => {
                      if (value >= 0) {
                        setForm({ ...form, animal_number: value });
                      }
                    }}
                    handleInvalid={() => removeInvalid("animal_number")}
                    invalid={invalid.animal_number}
                  />
                  
                </Col>

                <Col lg={4} md={4} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="หมายเหตุ"
                    idName="note"
                    value={form.note}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, note: value })}
                  />
                </Col>
              </Row>

              <BoxCard
                classNameBox="mt-2"
                title="อาการสัตว์ป่วย"
                headRight={buttonAddSymptom()}
              >
                <div className="">
                  <table className="table table-striped sameWidth table-bordered my-2 ">
                    <thead>
                      <tr className="text-center">
                        <th scope="col">ลำดับ</th>
                        <th scope="col">อาการ <span className="text-danger">*</span> </th>
                        <th scope="col">จัดการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {addSymptoms?.length > 0 ? (
                        addSymptoms.map((item, i) => {
                          return renderSymtomsTable(item, i);
                        })
                      ) : (
                        <DataTableNodata colSpan={3} />
                      )}
                    </tbody>
                  </table>
                </div>
              </BoxCard>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="4. แนบไฟล์"
            headRight={buttonAddFile()}
          >
            <div className="table-responsive pb-3">
              <Table className="table table-striped table-bordered my-2 ">
                <thead>
                  <tr>
                    <th scope="col">ลำดับ</th>
                    <th scope="col">อัปโหลดไฟล์</th>
                    <th scope="col">คำอธิบาย</th>
                    <th scope="col">วันที่อัปโหลด</th>
                    <th className="text-center" scope="col">
                      จัดการ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {form.data_file && form.data_file.length > 0
                    ? form.data_file.map((item, i) => {
                        return renderTableFile(item, i);
                      })
                    : null}
                  {form.checkAddFile && renderAddFile(form.data_file)}
                  {form.data_file &&
                    form.data_file.length == 0 &&
                    !form.checkAddFile && <DataTableNodata colSpan={5} />}
                </tbody>
              </Table>
            </div>
          </BoxCard>

          <BoxCard classNameBox="mt-3" title="5. ส่วนของเจ้าหน้าที่">
            <div className=" ">
              <Row className="mb-2">
                <Col lg={3} md={4} sm={12} xs={12}>
                  <InputSelect
                    title="ช่องทางการแจ้งโรค"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={ChannelSelectValue}
                    optionsList={ChannelList}
                    handleChange={(value) => {
                      setChannelSelectValue([value]);
                      setForm({ ...form, channel: value.value });
                    }}
                  />
                </Col>

                <Col lg={3} md={4} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="รายละเอียด"
                    idName="details"
                    value={form.details}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, details: value })
                    }
                  />
                </Col>

                <Col lg={3} md={4} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะการดำเนินการ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={StatusSelectValue}
                    optionsList={StatusList}
                    handleChange={(value) => {
                      setStatusSelectValue([value]);
                      setForm({ ...form, status: value.value });
                    }}
                    handleInvalid={() => removeInvalid("status")}
                    invalid={invalid.status}
                  />
                </Col>
              </Row>
            </div>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Disease_Report_Save;
