import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { loading } from "../../redux/actions/loadingAction";
import * as helper from "../../utils/helper";
import { Row, Col, Form } from "react-bootstrap";

import pdfMake from "pdfmake-thai/build/pdfmake";
import pdfFonts from "pdfmake-thai/build/vfs_fonts";

import BoxCard from "../../components/BoxCard";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "../../components/DataTable";
import * as API from "../../utils/apis";
import { useMasterData } from "../../components/hooks/useMasterData";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Outbreak_Report() {
  const user = useSelector((state) => state.login.result);
  const ref = React.createRef();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "footer"]);

  const initialForm = {
    diseaseTypeId: "",
    diseaseTypeName: "",

    id: "",
    obCode: "",
    foundDiseaseName: "",
    farmAmt: "",
    movement: "",
    warning: "",
    diseaseControl: "",
    annouceStartDate: "",
    annouceStopDate: "",
    annouceName: "",
    vaccine: "",
    annouceTypeName: "",
    annoucePosition: "",
    provinceId: "",
    amphurId: "",
    tambonId: "",
    mooId: "",
    address: "",
    soi: "",
    zoneName: "",
    zoneId: "",
    provinceName: "",
    amphurName: "",
    tambonName: "",
    mooName: "",

    foundDiseaseDate: "",
    endDate: "",
    startDateName: "",
    endDateName: "",

    foundDateBegin: "",
    foundDateEnd: "",
    createDateBegin: "",
    createDateEnd: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        sortBy: "obCode",
        name: "Outbreak",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "foundDiseaseName",
        name: "สงสัยโรค",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "diseaseTypeName",
        name: "ชนิดโรค",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "farmAmt",
        name: "จำนวนฟาร์ม",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        name: "มาตรการควบคุมโรค",
        sortFlg: false,
        colSpan: 2,
        childHead: [
          {
            sortBy: "movement",
            name: "ควบคุมการเคลื่อนย้าย",
            sortFlg: true,
          },
          {
            sortBy: "warning",
            name: "เฝ้าระวังและเตือนภัย",
            sortFlg: true,
          },
        ],
      },

      {
        name: "รายหมู่บ้าน",
        sortFlg: false,
        colSpan: 2,
        childHead: [
          {
            sortBy: "diseaseControl",
            name: "ภาวะการณ์โรคระบาด (กคร.3 / สคส.1)",
            sortFlg: true,
          },
          {
            sortBy: "vaccine",
            name: "การฉีดวัคซีน (กคร.5)",
            sortFlg: true,
          },
        ],
      },

      {
        sortBy: "annouceTypeName",
        name: "พื้นที่ประกาศเขตโรคระบาด",
        sortFlg: true,
        rowSpan: 2,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listOutbreakReport, setOutbreakReport] = useState([]);

  // select จังหวัด อำเภอ
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  // select เขตปศุสัตว์
  const [zoneLivestockList, setZoneLivestockList] = useState([]);
  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState([]);

  const [fileType, setFileType] = useState("excel");
  const fileName = "B99RPT02-OutbreakReport_" + helper.getDate(new Date());

  const [isClearSearch, setIsClearSearch] = useState(false);

  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );
  // =================

  //  ชนิดสัตว์

  const [diseaseSelectValue, setDiseaseSelectValue] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);
  // =================

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "title", name: "รายงาน" },
    { path: "active", name: "B99RPT02-Outbreak" },
  ];

  pdfMake.fonts = {
    THSarabunNew: {
      normal: "THSarabunNew.ttf",
      bold: "THSarabunNew-Bold.ttf",
      italics: "THSarabunNew-Italic.ttf",
      bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
  };

  useEffect(() => {
    getOutbreakReport();
    // getProvince();
    getZoneLivestock();
    getDiseaseType();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getOutbreakReport = async () => {
    dispatch(loading(true));
    try {
      const params = await {
        sortBy: form.sortBy,
        sortDir: form.sortDir,
        page: form.page,
        limit: 100,
        zoneId: form.zoneId,
        // statusId: "",
        provinceId: form.provinceId,
        amphurId: form.amphurId,
        tambonId: form.tambonId,
        diseaseTypeId: form.diseaseTypeId,
        // foundDiseaseTypeId: "",
        // orgId: "",
        // obCode: "",
        // farmId: "",
        // ownerName: "",
        foundDateBegin: form.foundDateBegin
          ? helper.getDate(form.foundDateBegin)
          : "",
        foundDateEnd: form.foundDateEnd
          ? helper.getDate(form.foundDateEnd)
          : "",
        createDateBegin: form.createDateBegin
          ? helper.getDate(form.createDateBegin)
          : "",
        createDateEnd: form.createDateEnd
          ? helper.getDate(form.createDateEnd)
          : "",
        // animalTypeId: "",
      };
      const response = await API.getOutbreakReport(params);
      const data = await response?.data;
      if (response.status == 200) {
        dispatch(loading(false));
        setOutbreakReport(data.content);
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOutbreakToExport = async () => {
    dispatch(loading(true));
    try {
      const params = await {
        sortBy: form.sortBy,
        sortDir: form.sortDir,
        page: form.page,
        limit: 100,
        zoneId: form.zoneId,
        provinceId: form.provinceId,
        amphurId: form.amphurId,
        tambonId: form.tambonId,
        diseaseTypeId: form.diseaseTypeId,
        foundDateBegin: form.foundDateBegin
          ? helper.getDate(form.foundDateBegin)
          : "",
        foundDateEnd: form.foundDateEnd
          ? helper.getDate(form.foundDateEnd)
          : "",
        createDateBegin: form.createDateBegin
          ? helper.getDate(form.createDateBegin)
          : "",
        createDateEnd: form.createDateEnd
          ? helper.getDate(form.createDateEnd)
          : "",
      };
      // console.log("params-💀-->", params);
      const response = await API.getOutbreakReport(params);
      // console.log("response--🤟🤟->", response);
      const data = await response?.data;
      if (response.status == 200) {
        let outbreakArray = [];
        data.content.forEach((element) => {
          outbreakArray.push({
            id: element.id,
            obCode: element.obCode,
            foundDiseaseName: element.foundDiseaseName,

            provinceId: element.provinceId,
            amphurId: element.amphurId,
            tambonId: element.tambonId,
            mooId: element.mooId,
            address: element.address,
            soi: element.soi,
            zoneName: element.zoneName,
            provinceName: element.provinceName,
            amphurName: element.amphurName,
            tambonName: element.tambonName,
            mooName: element.mooName,
            diseaseName: element.diseaseName,
            farmAmt: element.farmAmt,
            movement: element.movement,
            warning: element.warning,
            diseaseControl: element.diseaseControl,
            vaccine: element.vaccine,
            annouceTypeName: element.annouceTypeName,
            annouceName: element.annouceName,
            annoucePosition: element.annoucePosition,
            annouceStartDate: element.annouceStartDate,
            annouceStopDate: element.annouceStopDate,
            foundDiseaseDate: element.foundDiseaseDate,
          });
        });
        downloadRecord(outbreakArray, data.content);
        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType("", "", "1", "100", 1);
      const data = await response.data;
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseaseList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setZoneLivestockSelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setDistrictList([]);
    setDiseaseSelectValue([]);
    setFileType("excel");
    setIsClearSearch(!isClearSearch);
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const buttonSearch = () => {
    return (
      <>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const buttonExport = () => {
    return (
      <>
        <button
          className="btn btn-report"
          onClick={() => getOutbreakToExport()}
          disabled={user?.userGroup.mexport === "n"}
        >
          <a className="text-white">
            <img
              className="nav-icon"
              src={"/svg/report.svg"}
              alt="export-icon"
            />{" "}
            ออกรายงาน
          </a>
        </button>
      </>
    );
  };

  const convertListToExcelTable = (objArray) => {
    let excelTable = [];
    let dataD1 = "ภาวะการณ์โรคระบาด (กคร.3 / สคส.1)";
    let dataD2 = "การฉีดวัคซีน(กคร.5)";
    let dataD3 = "พื้นที่ประกาศเขตโรคระบาด";
    objArray.map((element, index) => {
      excelTable.push({
        Outbreak: element.obCode,
        สงสัยโรค: element.foundDiseaseName,
        ชนิดโรค: element.diseaseName,
        จำนวนฟาร์ม: element.farmAmt,
        ควบคุมการเคลื่อนย้าย: element.movement
          ? "ดำเนินการแล้ว"
          : "ยังไม่ดำเนินการ",
        เฝ้าระวังและเตือนภัย: element.warning
          ? "ดำเนินการแล้ว"
          : "ยังไม่ดำเนินการ",
      });
      excelTable[index][dataD1] = element.diseaseControl + " รายการ";
      excelTable[index][dataD2] = element.vaccine + " รายการ";
      excelTable[index][dataD3] =
        element.annouceTypeName +
        element.annouceName +
        element.annoucePosition +
        " " +
        "วันที่เริ่ม:" +
        helper.momentDate(element.annouceStartDate, "th", "short") +
        " " +
        "วันที่สิ้นสุด:" +
        helper.momentDate(element.annouceStopDate, "th", "short");
    });
    return excelTable;
  };

  const convertToCSV = (objArray) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let headCol = "";
    let dataRow = "";
    let key = Object.keys(array[0]);
    key.forEach((item) => {
      if (headCol === "") {
        headCol = item;
      } else {
        if (item != "") {
          headCol += ",";
          headCol += item;
        }
      }
    });
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") {
          line += ",";
        }
        line += array[i][index];
      }
      dataRow += line + "\r\n";
    }
    return headCol + "\r\n" + dataRow;
  };

  const convertToExcel = (objArray) => {
    const workSheet = XLSX.utils.json_to_sheet(objArray);
    const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    return XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  };

  const printPDF = (fileName, dataListForPDF) => {
    let listOutbreakPdf = [];
    if (dataListForPDF && dataListForPDF.length > 0) {
      dataListForPDF.map((item, i) => {
        listOutbreakPdf.push([
          item?.obCode || "-",
          item?.foundDiseaseName || "-",
          item?.diseaseName || "-",
          item.farmAmt || "-",
          item.movement ? "ดำเนินการแล้ว" : "ยังไม่ดำเนินการ",
          item.warning ? "ดำเนินการแล้ว" : "ยังไม่ดำเนินการ",
          item.diseaseControl + " รายการ",
          item.vaccine + " รายการ",
          item.annouceTypeName
            ? item.annouceTypeName +
              "\n" +
              item.annouceName +
              "\n" +
              item.annoucePosition +
              "\n" +
              helper.momentDate(item.annouceStartDate, "th", "short") +
              "\n" +
              helper.momentDate(item.annouceStopDate, "th", "short")
            : "ไม่มีพื้นที่ประกาศเขตโรคระบาด",
        ]);
      });
    }

    let bodyArray = [
      [
        {
          text: "Outbreak",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "สงสัยโรค",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "ชนิดโรค",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "ชนิดโรค",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "มาตรการควบคุมโรค",
          style: "tableHeader",
          colSpan: 2,
          alignment: "center",
        },
        {},
        {
          text: "รายหมู่บ้าน",
          style: "tableHeader",
          colSpan: 2,
          alignment: "center",
        },
        {},
        {
          text: "พื้นที่ประกาศเขตโรคระบาด",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
      ],
      [
        {},
        {},
        {},
        {},
        {
          text: "ควบคุมการเคลื่อนย้าย",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "เฝ้าระวังและเตือนภัย",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "ภาวะการณ์โรคระบาด (กคร.3 / สคส.1)",
          style: "tableHeader",
          alignment: "center",
        },
        {
          text: "การฉีดวัคซีน (กคร.5)",
          style: "tableHeader",
          alignment: "center",
        },
        {},
      ],
    ];

    listOutbreakPdf.map((item, i) => {
      bodyArray.push(listOutbreakPdf[i]);
    });

    let docDefinition = {
      pageMargins: [5, 5, 5, 5],
      content: [
        {
          style: "tableExample",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            widths: [
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
            ],
            headerRows: 2,
            body: bodyArray,
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
        font: "THSarabunNew",
      },
    };
    pdfMake.createPdf(docDefinition).download(fileName + ".pdf");
  };

  const downloadRecord = (dataList, dataListForPDF) => {
    let type = "";
    if (fileType === "excel") {
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = convertToExcel(convertListToExcelTable(dataList));
      const data = new Blob([excelBuffer], { type: type });
      FileSaver.saveAs(data, fileName + ".xlsx");
    }
    if (fileType === "csv") {
      type = "text/csv;charset=utf-8";
      const csv = convertToCSV(convertListToExcelTable(dataList));
      const csvData = new Blob([csv], { type: type });
      FileSaver.saveAs(csvData, fileName + ".csv");
    }
    if (fileType === "pdf") {
      printPDF(fileName, dataListForPDF);
    }
  };

  const checkExport = (value) => {
    if (fileType === value) {
      return true;
    } else {
      return false;
    }
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td className="text-center">{item.obCode || "-"}</td>
          <td className="text-center">{item.foundDiseaseName || "-"}</td>
          <td className="text-center">
            {" "}
            <span style={{ color: "red" }}>{item?.diseaseName}</span>
          </td>
          <td className="text-center">{item.farmAmt || "-"}</td>
          <td className="text-center outbrck-wrap">
            {item.movement ? "ดำเนินการแล้ว" : "ยังไม่ดำเนินการ"}
          </td>
          <td className="text-center outbrck-wrap">
            {item.warning ? "ดำเนินการแล้ว" : "ยังไม่ดำเนินการ"}
          </td>
          <td className="text-center outbrck-wrap">
            {item.diseaseControl} รายการ
          </td>
          <td className="text-center">{item.vaccine} รายการ</td>
          <td>
            {item?.annouceTypeName ? (
              <>
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {item?.annouceTypeName || "-"}{" "}
                </span>{" "}
                <br />
                {item?.annouceName || "-"}
                <br />
                {item?.annouceName ? `(${item?.annoucePosition})` : "-"}
                <br />
                {item?.annouceName
                  ? `วันที่เริ่ม:  ${helper.momentDate(
                      item.annouceStartDate,
                      "th",
                      "short"
                    )}`
                  : "-"}
                <br />
                {item?.annouceName
                  ? `วันที่สิ้นสุด:  ${helper.momentDate(
                      item.annouceStopDate,
                      "th",
                      "short"
                    )}`
                  : "-"}
              </>
            ) : (
              "ไม่มีพื้นที่ประกาศเขตโรคระบาด"
            )}
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="12" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่พบโรค"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.foundDateBegin}
                    star={false}
                    handleChange={(value) => {
                      let sDate = new Date(value);
                      setForm({ ...form, foundDateBegin: sDate.getTime() });
                    }}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.foundDateEnd}
                    star={false}
                    handleChange={(value) => {
                      let eDate = new Date(value);
                      setForm({ ...form, foundDateEnd: eDate.getTime() });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชนิดโรค"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={diseaseSelectValue}
                    optionsList={diseaseList}
                    handleChange={(value) => {
                      setDiseaseSelectValue([value]);
                      setForm({ ...form, diseaseTypeId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขตปศุสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={zoneLivestockSelectValue}
                    optionsList={zoneLivestockList}
                    handleChange={(value) => {
                      setZoneLivestockSelectValue([value]);
                      setForm({ ...form, zoneId: value.value });
                      getProvince(value.value);
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => {
                      setDistrictSelectValue(value);
                      setForm({ ...form, amphurId: value.value });
                    }}
                  />
                </Col>
              </Row>

              <div className="mb-2" style={{ fontWeight: "normal" }}>
                เลือกรูปแบบ
              </div>
              <Row>
                <Col lg={1} md={6} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="excel"
                      name="data-export"
                      checked={checkExport("excel")}
                      onChange={() => setFileType("excel")}
                    />{" "}
                    EXCEL
                  </label>
                </Col>

                <Col lg={1} md={6} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="pdf"
                      name="data-export"
                      checked={checkExport("pdf")}
                      onChange={() => setFileType("pdf")}
                    />{" "}
                    PDF
                  </label>
                </Col>

                <Col lg={1} md={6} sm={12} xs={12}>
                  <label style={{ fontWeight: "normal" }}>
                    <input
                      type="radio"
                      value="csv"
                      name="data-export"
                      checked={checkExport("csv")}
                      onChange={() => setFileType("csv")}
                    />{" "}
                    CSV
                  </label>
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการข้อมูลรายงาน B99RPT02-Outbreak"
            headRight={buttonExport()}
          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
              id={"data-table"}
              id={"render-data-table"}
              reference={ref}
            >
              {renderTable(listOutbreakReport)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}
export default Outbreak_Report;
