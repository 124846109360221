import React, { useState, useEffect ,updateState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import withReactContent from "sweetalert2-react-content";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import InputMask from "../../components/InputMask";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "../../components/DataTable";
import DataTableNodata from "../../components/DataTableNodata";
import * as FileSaver from "file-saver";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function Farm_Registration() {
  const { t, i18n } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.result);
  const history = useHistory();
  // console.log("user", user);
  const initialForm = {
    farmId: "",
    recorddate: "",
    todate: "",
    name: "",
    idCard: "",
    phone: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    status: "",
    statusReport: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        name: "ลำดับ",
        sortFlg: false,
        width: "50px",
      },
      {
        sortBy: "id",
        name: "รหัสฟาร์ม",
        sortFlg: true,
      },
      {
        sortBy: "homeNo",
        name: "ที่อยู่ฟาร์ม",
        sortFlg: true,
      },
      {
        sortBy: "firstName",
        name: "เจ้าของฟาร์ม",
        sortFlg: true,
      },
      {
        sortBy: "pid",
        name: "เลขบัตรประชาชน",
        sortFlg: true,
      },
      {
        sortBy: "statusId",
        name: "สถานะเกษตรกร",
        sortFlg: true,
      },
      {
        sortBy: "updateDate",
        name: "วันที่ปรับปรุงข้อมูล",
        sortFlg: true,
      },
      {
        sortBy: "systemName",
        name: "แหล่งที่มาข้อมูล",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const initialFormJson = {
    province: {},
    district: {},
    subDistrict: {},
    zone: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const [listFarmRegistration, setListFarmRegistration] = useState([]);

  const [isClearSearch, setIsClearSearch] = useState(false);

  const inintfarmFilterSelectValue = [];
  const [farmFilterSelectValue, setFarmFilterSelectValue] = useState(
    inintfarmFilterSelectValue
  );
  const [listFarmFilter, setListFarmFilter] = useState([]);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const selectStatusList = [
    { value: 1, label: "ยืนยันข้อมูล" },
    { value: 2, label: "รอการยืนยัน" },
    // { value: 3, label: "กำลังดำเนินการ" },
    // { value: 4, label: "เฝ้าระวังโรค" },
    // { value: 5, label: "รายงานโรค" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  const [statusReportSelectValue, setStatusReportSelectValue] = useState([]);
  const selectStatusReportList = [
    { value: '', label: "ทั้งหมด" },
    { value: 1, label: "แสดง" },
    { value: 2, label: "ไม่แสดง" },
  ];

  const inintZoneFilterSelectValue = [];
  const [zoneFilterSelectValue, setZoneFilterSelectValue] = useState(inintZoneFilterSelectValue);
  const [listZoneFilter, setListZoneFilter] = useState([]);

  const [statusReportList, setStatusReportList] = useState(selectStatusReportList);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  const fileName = "FarmRegisTemplate_" + helper.getDate(new Date());

  // useMasterData("province", provinceList, setProvinceList);

  const initialized = useState(false)

  // =================
  useEffect(() => {

    if (!initialized.current) {
      initialized.current = true
      getMasterJson();
      getProvince();
      // getFarmRegistrationFilter();
      getZone();
    }
    
  }, [user]);

  // useEffect(() => {
  //   getFarmRegistration();
  // }, [form.zone]);

  // useEffect(() => {
  //   getFarmRegistration();
  //   // getMasterJson();
  //   // getProvince("1");
  //       // getFarmRegistration();

  //   // // getFarmRegistrationFilter();
  //   // getFarmRegistration();
  //   // getZone();
  // }, [form.limit, form.page, form.sortBy, form.sortDir]);

  useEffect(() => {
    getFarmRegistration();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getFarmRegistration = async () => {
    if (form.zone === undefined) {
      return
    }
    var idCard = form.idCard
    if  (idCard == null || idCard == "") {
      idCard = null
    }

    var name = form.name
    if  (name == null || name == "") {
      name = null
    }

    var phone = form.phone
    if  (phone == null || phone == "") {
      phone = null
    }
    const params = {
      id: form.farmId ? form.farmId : "",
      updateDateBegin: form.recorddate ? helper.getDate(form.recorddate) : "",
      updateDateEnd: form.todate ? helper.getDate(form.todate) : "",
      name: name,
      pid: idCard,
      mobile: phone,
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
      mooId: form.villageId ? form.villageId : "",
      statusId: form.status ? form.status : "",
      statusReport: form.statusReport ? form.statusReport : "",
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",
      zoneId: form.zone ? form.zone : "",
    };

    dispatch(loading(true));
    try {
      const response = await API.getFarmRegistration(params);
      const data = await response.data;
      console.log(data);
      if (response.status == 200) {
        setListFarmRegistration(data.content);
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        dispatch(loading(false));
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
    const unFarmRegistration = () => {
      console.log('unFarmRegistration')
    }
    return unFarmRegistration
  };

  const getFarmRegistrationFilter = async () => {
    const params = {
      id: form.farmId ? form.farmId : "",
      updateDateBegin: form.recorddate ? helper.getDate(form.recorddate) : "",
      updateDateEnd: form.todate ? helper.getDate(form.todate) : "",
      name: form.name ? form.name : "",
      pid: form.idCard ? form.idCard : "",
      mobile: form.phone ? form.phone : "",
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
      mooId: form.villageId ? form.villageId : "",
      statusId: form.status ? form.status : "",
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      zoneId: form.zone ? form.zone : "",
      page: form.page ? form.page : "",
      limit: 10,
    };

    dispatch(loading(true));
    try {
      const response = await API.getFarmRegistration(params);
      const data = await response.data;
      if (response.status == 200) {
        dispatch(loading(false));
        let registerArray = [];
        data.content.forEach((element) => {
          registerArray.push({
            value: element.id,
            label: element.id,
          });
        });
        await setListFarmFilter(registerArray);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getMasterJson = async () => {
    const params = await ["province", "amphur", "tambon"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          province: data.province,
          district: data.amphur,
          subDistrict: data.tambon,
          zone: data.zone
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getProvince = async (zone) => {
    console.log(zone);
    try {
      const response = await API.getProvince(zone, 1);
      const data = await response.data;
      console.log(data.content);
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZone = async (id) => {
    try {
      const response = await API.getZone();
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        // let userrrr = user
        if  (user != null) {
          let userZone = user.userView.viewArea.zone_id
          let data2 = [];
          userZone.forEach((element) => {
            data.content.forEach((element2) => {
              if (element == element2.id) {
                data2.push({
                  value: element2.id,
                  label: element2.name,
                });
              }
              
            });
            
          });
          data2 = data2.sort((a,b) => (a.value > b.value) ? 1 : -1);
          setListZoneFilter(data2);
          setZoneFilterSelectValue([data2[0]])
          setForm({ ...form, zone: data2[0].value });
          getProvince(data2[0].value);
        } else {
          setListZoneFilter(villageArray);
          setZoneFilterSelectValue([villageArray[0]])
          setForm({ ...form, zone: villageArray[0].value });
          getProvince(villageArray[0].value);
          
        }

        setIsClearSearch(!isClearSearch);
        
        // setZoneFilterSelectValue([villageArray[0]])
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      zipcode: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      districtId: value.value,
      zipcode: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      subDistrictId: value.value,
      zipcode: value.zipcode,
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const getFilter = (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "title", name: "ขึ้นทะเบียนฟาร์ม" },
    { path: "active", name: "รายการฟาร์ม" },
  ];

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setDistrictList([]);
    setSubDistrictList([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setVillageList([]);
    setFarmFilterSelectValue([]);
    setStatusSelectValue([]);
    setStatusReportSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          {t("clear")}
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const toDownload = async () => {
    try {
      const response = await API.getDownloadFarmReg();
      const data = await response.data;
      if (response.status == 200) {
        let url = data.data.url;

        let res = await fetch(url);
        let dt = await res.blob();
        let metadata = {
          type: "xlsx",
        };
        let today = new Date();
        let time =
          "_" +
          today.getHours() +
          "-" +
          today.getMinutes() +
          "-" +
          today.getSeconds();
        let file = new File([dt], fileName + time + ".xlsx", metadata);
        FileSaver.saveAs(file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toUpload = async (file) => {
    try {
      let formData = new FormData();
      formData.append("file", file);

      const response = await API.getUploadFarmReg(formData);
      const data = await response.data;
      if (response.status == 200) {
        MySwal.fire({
          icon: "success",
          type: "success",
          confirmButtonText: "ตกลง",
          text: "บันทึกรายการการฟาร์มสำเร็จ",
        });
      }
    } catch (error) {
      MySwal.fire({
        icon: "warning",
        type: "warning",
        confirmButtonText: "ตกลง",
        text: "กรุณากรอกข้อมูลในแบบฟอร์มให้ถูกต้อง",
      });
      console.log(error);
      // console.log("อัปโหลดไม่สำเร็จ");
    }
  };

  const _handleImageChange = (e) => {
    e.preventDefault();
    const fileTypeArray = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    // let reader = new window.FileReader();
    let file = e.target.files[0];
    // console.log("toUpload", file);

    if (file) {
      if (fileTypeArray.includes(file.type)) {
        toUpload(file);
      } else {
      }
    }
  };

  const onInputClick = (e) => {
    e.target.value = "";
  };

  const buttonAdd = () => {
    return (
      <>
        <button
          className="btn btn-download text-white mr-2 fw-400 cursor-p"
          onClick={() => {
            toDownload();
          }}
          disabled={user?.userGroup.mexport === "n"}
        >
          <img
            className="icon-btn mr-2 mb-1"
            src="/svg/download.svg"
            alt="menu-icon"
          />
          ดาวน์โหลดไฟล์
        </button>

        <label
          className={`btn btn-report text-white mr-2 mt-2 fw-400 ${
            user?.userGroup.mcreate === "n" ? "disabled" : ""
          }`}
          htmlFor="exampleFormControlFile1"
        >
          <img className="mr-2 mb-1" src={"/svg/up.svg"} alt="menu-icon" />
          อัปโหลดข้อมูลฟาร์ม
        </label>
        <input
          type="file"
          className="form-control-file"
          id="exampleFormControlFile1"
          hidden
          disabled={user?.userGroup.mcreate === "n"}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={(e) => {
            _handleImageChange(e);
          }}
          onClick={onInputClick}
        />

        <button
          className="btn btn-info normal fw-400 cursor-p"
          onClick={() => history.push("/farm_registration/save-data")}
          disabled={user?.userGroup.mcreate === "n"}
        >
          <i className="fas fa-plus mr-2"></i>
          เพิ่มฟาร์ม
        </button>
      </>
    );
  };

  const viewDetails = (id) => {
    history.push(`/farm_registration/edit/${id}`);
  };

  const delFarm = (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบรายการฟาร์ม?`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteFarmRegistration(id);
          if (response.status == 200) {
            await getFarmRegistration();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const getStatusColor = (idStatus) => {
    let nameStatus;
    let colorStatus;
    if (idStatus) {
      if (idStatus == 1) {
        colorStatus = "#25B881";
        nameStatus = "ยืนยันข้อมูล";
      }
      if (idStatus == 2) {
        colorStatus = "#FFC107";
        nameStatus = "รอการยืนยัน";
      }
      if (idStatus == 3) {
        colorStatus = "#FFC107";
        nameStatus = "กำลังดำเนินการ";
      }
      if (idStatus == 4) {
        colorStatus = "#F94343";
        nameStatus = "เฝ้าระวังโรค";
      }
      if (idStatus == 5) {
        colorStatus = "#FF7A00";
        nameStatus = "รายงานโรค";
      }

      return (
        <div className="d-flex justify-content-center">
          <div
            className="box-status font14 text-center text-white"
            style={{ background: colorStatus }}
          >
            {nameStatus}
          </div>
        </div>
      );
    }
  };

  const renderTablereportFarm = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>
            {item.id}
          </td>
          <td>
            {`${item.homeNo} ต.${item.tambonName} อ.${item.amphurName} จ.${item.provinceName}`}
          </td>
          <td>
            {item.firstName} {item.lastName}
          </td>
          <td>{item.pid}</td>
          <td>{item.statusId ? getStatusColor(item.statusId) : "-"}</td>
          <td>{helper.momentDate(item.updateDate, "th", "short")}</td>
          <td>{item.systemName ? item.systemName : "-"}</td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delFarm(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={12} />;
    }
  };

  return (
    <div>
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2 ">
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="รหัสฟาร์ม"
                    placeholder="โปรดระบุ"
                    idName="title"
                    classLabel="normal"
                    value={form.farmId}
                    star={false}
                    handleChange={(value) => {
                      const pattern = /^\d+$/;
                      if (pattern.test(value) || !value) {
                        setForm({ ...form, farmId: value });
                      }
                    }}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputDatePicker
                    title="วันที่บันทึกข้อมูลล่าสุด"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.recorddate}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, recorddate: value })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.todate}
                    star={false}
                    handleChange={(value) =>
                      setForm({ ...form, todate: value })
                    }
                  />
                </Col>
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ชื่อ-สกุล เจ้าของฟาร์ม"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputMask
                    mask="9-9999-99999-99-9"
                    title="รหัสประจำตัวประชาชน"
                    idName="idCard"
                    value={form.idCard}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, idCard: value.replace(/-/g, "") })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputMask
                    mask="99-9999-9999"
                    title="โทรศัพท์มือถือ"
                    idName="phone"
                    value={form.phone}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, phone: value.replace(/-/g, "") })
                    }
                  />
                </Col>
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="ปศุสัตว์เขต"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="status"
                    classLabel="normal"
                    selectValue={zoneFilterSelectValue}
                    optionsList={listZoneFilter}
                    handleChange={(value) => {
                      setZoneFilterSelectValue([value]);
                      getProvince(value.value);
                      setForm({ ...form, zone: value.value });
                    }}
                  />
                </Col>
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="provinceId"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => {
                      selectValueProvince(value);
                    }}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="districtId"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrictId"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="หมู่บ้าน"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="villageId"
                    classLabel="normal"
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({ ...form, villageId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="สถานะการเกษตรกร"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="status"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={statusList}
                    handleChange={(value) => {
                      setStatusSelectValue([value]);
                      setForm({ ...form, status: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="สถานะการนับรวมสรุปในรายงาน"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="status"
                    classLabel="normal"
                    selectValue={statusReportSelectValue}
                    optionsList={statusReportList}
                    handleChange={(value) => {
                      setStatusReportSelectValue([value]);
                      setForm({ ...form, statusReport: value.value });
                    }}
                  />
                </Col>
               
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการการฟาร์ม"
            headRight={buttonAdd()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
            >
              {renderTablereportFarm(listFarmRegistration)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default Farm_Registration;
