/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, Form, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputText from "./InputText";
import InputSelect from "./InputSelect";
import { useDispatch } from "react-redux";
import * as API from "../utils/apis";
import DataTableNodata from "./DataTableNodata";
import { useMasterData } from "../components/hooks/useMasterData";

function ModalOrganizes({ show,user, close, toSaveOrg }) {
  const { t } = useTranslation(["common", "footer"]);
  console.log(user);
  const initialForm = {
    search: "",
    limit: "10",
    org_id: "",
    org_name: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
  };
  const [form, setForm] = useState(initialForm);

  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const selectOrganizesList = [];
  const [organizesList, setOrganizesList] = useState(selectOrganizesList);

  const [isClearSearch, setIsClearSearch] = useState(false);

  useMasterData("province", provinceList, setProvinceList);

  useEffect(async () => {
    await getOrganizes();
  }, []);

  useEffect(async () => {
    await getProvince();
  }, [isClearSearch]);

  const getOrganizes = async () => {
    try {
      const response = await API.getOrganizes(
        "",
        "",
        "",
        "",
        "1",
        form.limit,
        form.search,
        "id",
        "ASC",
        form.provinceId,
        form.districtId,
        form.subDistrictId
      );
      const data = await response.data;
      if (response.status === 200) {
        let organizesArray = [];
        data.content.forEach((element) => {
          organizesArray.push({
            value: element.id,
            label: element.name,
            parentId: element.parentId,
          });
        });
        setOrganizesList(organizesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, districtId: value.value });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value });
  };

  const toSave = () => {
    let orgForm = organizesList.find((item) => item.value == form.org_id);
    toSaveOrg(orgForm);
    close();
  };

  const toSearch = (e) => {
    e.preventDefault();
    getOrganizes();
  };

  const handleClose = () => {
    close();
    setForm(initialForm);
  };

  return (
    <Modal
      show={show}
      size="md"
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Body className="p-4">
        <Form onSubmit={toSearch}>
          <Row>
            <Col lg={4} md={6} sm={12} xs={12}>
              <InputSelect
                title="จังหวัด"
                star={false}
                placeholder="กรุณาเลือก"
                idName="province"
                classLabel="bold"
                selectValue={provinceSelectValue}
                optionsList={provinceList}
                handleChange={(value) => selectValueProvince(value)}
              />
            </Col>

            <Col lg={4} md={6} sm={12} xs={12}>
              <InputSelect
                title="อำเภอ"
                star={false}
                placeholder="กรุณาเลือก"
                idName="title"
                classLabel="bold"
                selectValue={districtSelectValue}
                optionsList={districtList}
                handleChange={(value) => selectValueDistrict(value)}
              />
            </Col>

            <Col lg={4} md={6} sm={12} xs={12}>
              <InputSelect
                title="ตำบล"
                star={false}
                placeholder="กรุณาเลือก"
                idName="subDistrict"
                classLabel="bold"
                selectValue={subDistrictSelectValue}
                optionsList={subDistrictList}
                handleChange={(value) => selectValueSubDistrict(value)}
              />
            </Col>
          </Row>
          <div className="mb-4 d-flex">
            <div style={{ width: "90%" }}>
              <InputText
                type="text"
                title={t("ชื่อหน่วยงาน/จังหวัด/อำเภอ")}
                idName="search"
                value={form.search}
                star={false}
                placeholder={t("search")}
                classLabel="bold"
                classFormGroup="mb-0"
                handleChange={(value) => setForm({ ...form, search: value })}
              />
            </div>
            <div className="mt-auto ml-2 d-flex">
              <button type="submit" className="btn btn-primary">
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
              <button
                type="submit"
                className="btn btn-danger ml-2"
                onClick={() => clearSearch()}
              >
                <i className="fas fa-ban" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </Form>

        <table className="table sameWidth table-bordered my-4">
          <thead>
            <tr className="text-center">
              <th scope="col">{t("common:no")}</th>
              <th scope="col">{t("common:org_name")}</th>
              <th scope="col">{t("common:manage")}</th>
            </tr>
          </thead>
          <tbody>
            {organizesList && organizesList.length > 0 ? (
              organizesList.map((item, i) => (
                <tr key={i}>
                  <td className="text-center">{i + 1}</td>
                  <td>
                    <label
                      htmlFor={`org_id${i}`}
                      className="normal mb-0 cursor-p"
                    >
                      {item.label}
                    </label>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <Form.Check
                        key={i}
                        className="m-0 p-0"
                        type="radio"
                        name="org_id"
                        id={`org_id${i}`}
                        value={item.value}
                        checked={parseInt(form.org_id) === parseInt(item.value)}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            org_id: e.target.value,
                            parentId: item.parentId,
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <DataTableNodata colSpan={3} />
            )}
          </tbody>
        </table>

        <div className="mt-4 d-flex justify-content-center">
          <button
            className="btn btn-danger btn-sm px-4 mr-2"
            onClick={() => handleClose()}
          >
            {t("common:cancel")}
          </button>
          <button
            className="btn btn-primary btn-sm px-4"
            disabled={form.org_id ? false : true}
            onClick={() => toSave()}
          >
            {t("common:save")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalOrganizes;
