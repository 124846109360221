/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Banner from "../components/Banner";
import FooterHome from "../components/FooterHome";
import * as API from "../utils/apis";
import {
  addProvinceList,
  addZoneLivestockList,
  checkProvinceList,
  checkZoneLivestockList,
} from "../redux/actions/masterDataAction";

function Home() {
  const user = useSelector((state) => state.login);
  const menu = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation(["common", "footer"]);

  const [userModules, setUserModules] = useState([]);

  useEffect(() => {
    if (user.result) {
      // moduleMapping(user.result.userMenu);
      moduleFilter(user.result.userMenu);
      getProvince();
      getZoneLivestock();
    }
  }, [user]);

  useEffect(() => {
    // console.log("modules", userModules);
  }, [userModules]);

  const moduleMapping = async (data) => {
    let array = [];

    menu.map((item) => {
      data
        .filter((v, i, a) => {
          return a.findIndex((t) => t.moduleId === v.moduleId) === i;
        })
        .map((v, j) => {
          if (item.moduleId == v.moduleId) {
            array.push({ ...v, path: item.path, icon: item.icon });
          }
        });
    });
    setUserModules(array);
  };

  const moduleFilter = async (data) => {
    // console.log("modules", data.filter((v, i, a) => {
    //     return a.findIndex(t => (t.moduleId === v.moduleId))===i;
    // }));
    setUserModules(
      data
        .filter((v, i, a) => {
          return a.findIndex((t) => t.moduleId === v.moduleId) === i;
        })
        .map((v) => v.moduleId)
    );
  };

  const renderModules = () => {
    return userModules.map((module, i) => {
      // console.log("module", module)
      return (
        <Col
          key={i}
          lg={4}
          md={4}
          sm={4}
          xs={12}
          className="d-flex justify-content-center py-2"
        >
          <Link to={module.path} className="text-center">
            <img
              src={module.icon}
              alt={`module_${i}`}
              className="img-fluid icon-title mb-1"
            />
            <div className="carousel-caption text-center">
              <p className="color-white">{`${module.moduleName}`}</p>
            </div>
          </Link>
        </Col>
      );
    });
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let provincesArray = [];
        const viewArea = user.result.userView.viewArea?.province_id;
        if (viewArea) {
          if (viewArea?.length > 0) {
            data.content?.map((element) => {
              viewArea.map((view) => {
                if (element.id === view) {
                  provincesArray.push({
                    value: element.id,
                    label: element.name,
                  });
                }
              });
            });
          }
        }
        dispatch(addProvinceList(provincesArray));
        dispatch(checkProvinceList());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let zoneArray = [];
        const viewArea = user.result.userView.viewArea?.zone_id;
        if (viewArea) {
          if (viewArea?.length > 0) {
            data.content?.map((element) => {
              viewArea.map((view) => {
                if (element.id === view) {
                  zoneArray.push({
                    value: element.id,
                    label: element.name,
                  });
                }
              });
            });
          }
        }
        dispatch(addZoneLivestockList(zoneArray));
        dispatch(checkZoneLivestockList());
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <div className="mt-3">
        <Banner />
      </div>

      <Row className="py-3">
        {/* <Col lg={4} md={6} sm={6} xs={12} className="d-flex justify-content-center py-2">
                    <Link to={`/statistics`} className="text-center">
                        <img src={'/svg/home_1.svg'} alt={"title1"} className="img-fluid icon-title mb-1" />
                        <div className="carousel-caption text-center">
                            <p className="color-white">{t("statistics")}</p>
                        </div>
                    </Link>
                </Col> */}
        {
          // renderModules()
        }
         {userModules?.includes(9) && (
          <Col
            lg={4}
            md={6}
            sm={6}
            xs={12}
            className="d-flex justify-content-center py-2"
          >
            <Link to={`/zero_report/report`} className="text-center">
              <img
                src={"/svg/image 3.svg"}
                alt={"title2"}
                className="img-fluid icon-title mb-1"
              />
              <div className="carousel-caption text-center">
                <p className="color-white">{t("zero_report")}</p>
              </div>
            </Link>
          </Col>
        )}
        {userModules?.includes(3) && (
          <Col
            lg={4}
            md={6}
            sm={6}
            xs={12}
            className="d-flex justify-content-center py-2"
          >
            <Link to={`/passive_surv/disease_report`} className="text-center">
              <img
                src={"/svg/home_2.svg"}
                alt={"title2"}
                className="img-fluid icon-title mb-1"
              />
              <div className="carousel-caption text-center">
                <p className="color-white">{t("passive_surveillance")}</p>
              </div>
            </Link>
          </Col>
        )}
        {userModules?.includes(2) && (
          <Col
            lg={4}
            md={6}
            sm={6}
            xs={12}
            className="d-flex justify-content-center py-2"
          >
            <Link to={`/active_surv/action_plan`} className="text-center">
              <img
                src={"/svg/home_3.svg"}
                alt={"title3"}
                className="img-fluid icon-title mb-1"
              />
              <div className="carousel-caption text-center">
                <p className="color-white">{t("active_surveillance")}</p>
              </div>
            </Link>
          </Col>
        )}
        {userModules?.includes(1) && (
          <Col
            lg={4}
            md={6}
            sm={6}
            xs={12}
            className="d-flex justify-content-center py-2"
          >
            <Link to={`/farm_registration`} className="text-center">
              <img
                src={"/svg/home_7.svg"}
                alt={"title4"}
                className="img-fluid icon-title mb-1"
              />
              <div className="carousel-caption text-center">
                <p className="color-white">{t("farm_registration")}</p>
              </div>
            </Link>
          </Col>
        )}
        {userModules?.includes(4) && (
          <Col
            lg={4}
            md={6}
            sm={6}
            xs={12}
            className="d-flex justify-content-center py-2"
          >
            <Link to={`/assess_risk/list-assess-risk`} className="text-center">
              <img
                src={"/svg/home_4.svg"}
                alt={"title4"}
                className="img-fluid icon-title mb-1"
              />
              <div className="carousel-caption text-center">
                <p className="color-white">{t("assess_risk")}</p>
              </div>
            </Link>
          </Col>
        )}
        {userModules?.includes(5) && (
          <Col
            lg={4}
            md={6}
            sm={6}
            xs={12}
            className="d-flex justify-content-center py-2"
          >
            <Link to={`/gfm`} className="text-center">
              <img
                src={"/svg/home_5.svg"}
                alt={"title5"}
                className="img-fluid icon-title mb-1"
              />
              <div className="carousel-caption text-center">
                <p className="color-white">GFM</p>
              </div>
            </Link>
          </Col>
        )}
        {userModules?.includes(6) && (
          <Col
            lg={4}
            md={6}
            sm={6}
            xs={12}
            className="d-flex justify-content-center py-2"
          >
            <Link to={`/data-manage/web-settings/news`} className="text-center">
              <img
                src={"/svg/home_6.svg"}
                alt={"title6"}
                className="img-fluid icon-title mb-1"
              />
              <div className="carousel-caption text-center">
                <p className="color-white">{t("data_management")}</p>
              </div>
            </Link>
          </Col>
        )}
        {/* <Col
          lg={4}
          md={6}
          sm={6}
          xs={12}
          className="d-flex justify-content-center py-2"
        >
          <Link to={`/home`} className="text-center">
            <img
              src={"/svg/home_8.svg"}
              alt={"title6"}
              className="img-fluid icon-title mb-1"
            />
            <div className="carousel-caption text-center">
              <p className="color-white">{t("online")}</p>
            </div>
          </Link>
        </Col> */}
        <Col
          lg={4}
          md={6}
          sm={6}
          xs={12}
          className="d-flex justify-content-center py-2"
        >
          {/* <div onClick={() => window.open(process.env.REACT_APP_POULTRY_URL, '_blank').focus()} className="cursor-p">
                        <img src={'/svg/home_9.svg'} alt={"title6"} className="img-fluid icon-title mb-1" />
                        <div className="carousel-caption text-center">
                            <p className="color-white">{t("poultry_system")}</p>
                        </div>
                    </div> */}
          <a
            target="_blank"
            rel="opener"
            href={`${process.env.REACT_APP_POULTRY_URL}`}
            className="cursor-p"
          >
            <img
              src={"/svg/home_9.svg"}
              alt={"title6"}
              className="img-fluid icon-title mb-1"
            />
            <div className="carousel-caption text-center">
              <p className="color-white">{t("poultry_system")}</p>
            </div>
          </a>
        </Col>
      </Row>

      <FooterHome />
    </Container>
  );
}

export default Home;
