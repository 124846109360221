import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import BoxCard from "../BoxCard";
import DataTableNodata from "../DataTableNodata";
import InputDatePicker from "../InputDatePicker";
import * as API from "../../utils/apis";
import { Row, Col } from "react-bootstrap";

function ActionPlan_Step1({
  save,
  dataAll,
  modeGet,
  onSave,
  checkOnSave,
  checkSave,
  animalData,
}) {
  console.log("animalData", animalData);
  const user = useSelector((state) => state.login.result);
  const initialForm = {
    namep: "",
    fiscalYear: "",
    // animalTypeId: "",
    details: "",
    objec: "",
    datep: "",
    todate: "",
    statusId: "",

    animal: [],
    save: true,
  };
  const [form, setForm] = useState(initialForm);

  const initInvalidMsg = {
    namep: "",
    fiscalYear: "",
    // animalTypeId: "",
    details: "",
    objec: "",
    datep: "",
    todate: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    {
      value: 1,
      label: "กำลังดำเนินการ",
    },
    {
      value: 2,
      label: "สิ้นสุดโครงการ",
    },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);

  // select ปีงบประมาณ /ชนิดสัตว์ /สถานะการดำเนินการ
  const [fiscalYearSelectValue, setFiscalYearSelectValue] = useState([]);
  const [fiscalYearList, setFiscalYearList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [addAnimal, setAddAnimal] = useState([]);

  useEffect(async () => {
    await getActionPlanYear();
    await getAnimal();
  }, []);

  useEffect(async () => {
    if (modeGet == "getById") {
      await getDataById();
    }
  }, [user, modeGet, animalList]);

  useEffect(() => {
    if (checkSave) {
      toSave();
    }
  }, [checkSave]);

  const toSave = () => {
    if (validate()) {
      save(form, addAnimal);
      checkOnSave(true);
    } else {
      checkOnSave(false);
    }
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if (form.namep === "") {
      addInvalid("namep", "กรุณาเลือกปีงบประมาณ");
      validated = false;
    }

    if (form.fiscalYear === "") {
      addInvalid("fiscalYear", "กรุณาระบุชื่อโครงการ");
      validated = false;
    }

    // if (form.animalTypeId === "") {
    //     addInvalid("animalTypeId", "กรุณาเลือกชนิดสัตว์");
    //     validated = false;
    // }

    if (form.details === "") {
      addInvalid("details", "กรุณาระบุรายละเอียดโครงการ");
      validated = false;
    }

    if (form.objec === "") {
      addInvalid("objec", "กรุณาระบุวัตถุประสงค์");
      validated = false;
    }

    if (form.datep === "") {
      addInvalid("datep", "กรุณาระบุวันที่เริ่มโครงการ");
      validated = false;
    }

    if (form.todate === "") {
      addInvalid("todate", "กรุณาระบุวันสิ้นสุดโครงการ");
      validated = false;
    }
    return validated;
  };

  const toAddAnimal = () => {
    const data = addAnimal.map((item) => {
      return { ...item, edit: true };
    });
    setAddAnimal([...data, { animal: [], save: true }]);
  };

  const buttonAddAnimal = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddAnimal()}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูล
        </button>
      </div>
    );
  };

  const getActionPlanYear = async () => {
    try {
      const response = await API.getActionPlanYear();
      const data = await response.data;
      if (response.status == 200) {
        let yearArray = [];
        data.forEach((element) => {
          yearArray.push({
            value: element,
            label: element,
          });
        });
        await setFiscalYearList(yearArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataById = async () => {
    if (dataAll) {
      if (dataAll.animalTypeId) {
        let animalSelect = await animalList.find(
          (item) => item.value == dataAll.animalTypeId
        );
        setAnimalSelectValue(animalSelect);
      }
      if (dataAll.fiscalYear) {
        let fiscalYearSelect = await fiscalYearList.find(
          (item) => item.value == dataAll.fiscalYear
        );
        setFiscalYearSelectValue(fiscalYearSelect);
      }

      if (dataAll.statusId) {
        let statusIdSelect = await statusList.find(
          (item) => item.value == dataAll.statusId
        );
        setStatusSelectValue(statusIdSelect);
      }
      setAddAnimal(animalData);
      setForm({
        ...form,
        fiscalYear: dataAll.fiscalYear,
        namep: dataAll.namep,
        animalTypeId: dataAll.animalTypeId,
        details: dataAll.details,
        objec: dataAll.objec,
        datep: dataAll.datep,
        todate: dataAll.todate,
        statusId: dataAll.statusId,
      });
    }
  };

  const renderAnimalTable = (item, i) => {
    console.log("item", item);
    return (
      <tr key={i} className="">
        <td>{i + 1}</td>
        <td>
          {item.save ? (
            <InputSelect
              classFormGroup="mb-0"
              title=""
              star={false}
              placeholder="กรุณาเลือก"
              idName={`Animal_${i}`}
              classLabel="normal"
              selectValue={item.animal}
              optionsList={animalList}
              handleChange={(value) => {
                item.animal = value;
                setAnimalSelectValue(value);
                setAddAnimal([...addAnimal]);
              }}
            />
          ) : (
            <span className="text-nowrap">{item.animal?.label}</span>
          )}
        </td>

        <td className="text-center">
          {item.save ? (
            <button
              className="btn btn-success pt-1 px-2"
              onClick={() => {
                item.save = false;
                const data = addAnimal.map((item) => {
                  return { ...item, edit: false };
                });
                setAddAnimal([...data]);
                toSave();
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => {
                  item.save = true;
                  setAddAnimal([...addAnimal]);
                }}
                disabled={item.edit}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button
                className="btn btn-del "
                onClick={() => {
                  addAnimal.splice(i, 1);
                  setAddAnimal([...addAnimal]);
                }}
                disabled={item.edit}
              >
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className="p-4 bg-white">
      <Row className="">
        <Col lg={2} md={7} sm={12} xs={12} className="mt-4  ">
          <InputSelect
            title="ปีงบประมาณ"
            star={true}
            placeholder="กรุณาเลือก"
            idName="title"
            classLabel="normal"
            selectValue={fiscalYearSelectValue}
            optionsList={fiscalYearList}
            handleChange={(value) => {
              setFiscalYearSelectValue([value]);
              setForm({ ...form, fiscalYear: value.value });
            }}
            handleInvalid={() => removeInvalid("fiscalYear")}
            invalid={invalid.fiscalYear}
          />
        </Col>

        <Col lg={6} md={7} sm={12} xs={12} className="mt-4 pl-4">
          <InputText
            type="text"
            title="ชื่อโครงการ"
            idName="namep"
            value={form.namep}
            star={true}
            placeholder="โปรดระบุ"
            classLabel="normal"
            handleChange={(value) => {
              setForm({ ...form, namep: value });
            }}
            handleInvalid={() => removeInvalid("namep")}
            invalid={invalid.namep}
          />
        </Col>
      </Row>
      <Row>
        <BoxCard
          classNameBox="mt-2 w-50"
          title="ชนิดสัตว์"
          headRight={buttonAddAnimal()}
        >
          <div className="">
            <table className="table table-striped sameWidth table-bordered my-2 ">
              <thead>
                <tr className="text-center">
                  <th scope="col">ลำดับ</th>
                  <th scope="col">ชนิดสัตว์</th>
                  <th scope="col">จัดการ</th>
                </tr>
              </thead>
              <tbody>
                {addAnimal && addAnimal?.length > 0 ? (
                  addAnimal.map((item, i) => {
                    return renderAnimalTable(item, i);
                  })
                ) : (
                  <DataTableNodata colSpan={3} />
                )}
              </tbody>
            </table>
          </div>
        </BoxCard>
      </Row>

      <Row className="mt-4 ">
        <Col lg={5} md={7} sm={12} xs={12}>
          <InputText
            type="text"
            as="textarea"
            rows={2}
            title="รายละเอียดโครงการ"
            idName="details"
            value={form.details}
            star={true}
            placeholder="กรุณาระบุ"
            classLabel="normal"
            handleChange={(value) => {
              setForm({ ...form, details: value });
            }}
            handleInvalid={() => removeInvalid("details")}
            invalid={invalid.details}
          />
        </Col>

        <Col lg={5} md={7} sm={12} xs={12} className="pl-4">
          <InputText
            type="text"
            as="textarea"
            rows={2}
            title="วัตถุประสงค์"
            idName="objec"
            value={form.objec}
            star={true}
            placeholder="กรุณาระบุ"
            classLabel="normal"
            handleChange={(value) => {
              setForm({ ...form, objec: value });
            }}
            handleInvalid={() => removeInvalid("objec")}
            invalid={invalid.objec}
          />
        </Col>
      </Row>

      <Row className="mb-2  ">
        <Col lg={2} md={7} sm={12} xs={12}>
          <InputDatePicker
            title="วันที่เริ่มโครงการ"
            classLabel="normal"
            placeholder="วัน/เดือน/ปี"
            value={form.datep}
            star={true}
            handleChange={(value) => {
              setForm({ ...form, datep: value });
            }}
            handleInvalid={() => removeInvalid("datep")}
            invalid={invalid.datep}
            maxDate={true}
          />
        </Col>
        <Col lg={2} md={7} sm={12} xs={12} className="pl-4">
          <InputDatePicker
            title="ถึงวันที่"
            classLabel="normal"
            placeholder="วัน/เดือน/ปี"
            value={form.todate}
            star={true}
            handleChange={(value) => {
              setForm({ ...form, todate: value });
            }}
            handleInvalid={() => removeInvalid("todate")}
            invalid={invalid.todate}
            minDate={form.datep}
            maxDate={"afterSelectDate"}
          />
        </Col>

        <Col lg={2} md={7} sm={12} xs={12}>
          <InputSelect
            title="สถานะ"
            star={false}
            placeholder="กรุณาเลือก"
            idName="title"
            classLabel="normal"
            selectValue={statusSelectValue}
            optionsList={statusList}
            handleChange={(value) => {
              setStatusSelectValue([value]);
              setForm({ ...form, statusId: value.value });
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ActionPlan_Step1;
