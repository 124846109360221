import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import { useHistory } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTableNodata from "../../../components/DataTableNodata";
import DataTable from "../../../components/DataTable";
import ModalSymptom from "../../../components/Data_Management/Modal.Symptom";

const MySwal = withReactContent(Swal);

function Symptom() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const statusCheck = useSelector((state) => state.statusCheck);
  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/master-data/animal-type", name: "Master Data" },
    { path: "active", name: "อาการป่วย" },
  ];

  const initialForm = {
    animalId: "",
    symptomId: "",
    status: "",

    idEdit: "",
    mode: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
        width: "50px",
      },
      {
        sortBy: "name",
        name: "อาการป่วย",
        sortFlg: true,
      },
      {
        sortBy: "animalTypeId",
        name: "ชนิดสัตว์",
        sortFlg: false,
      },
      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const initialFormJson = {
    animal: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const [listSymptom, setListSymptom] = useState([]);

  const [showSymptom, setShowSymptom] = useState(false);

  const [animalIdSelectValue, setAnimalIdSelectValue] = useState([]);
  const [animalIdList, setAnimalList] = useState([]);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);
  const [isClearSearch, setIsClearSearch] = useState(false);

  useEffect(() => {
    getAnimal();
    getMasterJson();
  }, []);

  useEffect(() => {
    getAnimalSymptom();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimalSymptom = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getAnimalSymptom(
        form.animalId,
        form.status,
        form.symptomId,
        form.page,
        form.limit,
        "",
        form.sortBy,
        form.sortDir
      );
      const data = await response?.data;
      if (response.status === 200) {
        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListSymptom(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getMasterJson = async () => {
    const params = ["animalType"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response?.data;
      if (response.status === 200) {
        setFormJson({
          ...formJson,
          animal: data.animalType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusCheck && statusCheck.length > 0) {
      statusCheck.map((item, index) => {
        if (item.id === idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-4 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const clearSearch = () => {
    setForm(initialForm);
    setAnimalIdSelectValue([]);
    setStatusSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonAddSymptom = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddSymptom()}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มอาการป่วย
        </button>
      </div>
    );
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const toAddSymptom = () => {
    setForm({ ...form, mode: "add", idEdit: "" });
    setShowSymptom(true);
  };

  const editModalSymptom = (id) => {
    setForm({ ...form, mode: "edit", idEdit: id });
    setShowSymptom(true);
  };

  const delSymptom = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบ `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteSymptom(id);
          if (response.status === 200) {
            MySwal.fire({
              text: `ลบสำเร็จ `,
              icon: "success",
              confirmButtonText: "ตกลง",
            });
            await getAnimalSymptom();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const addSaveSymptom = (data) => {
    const dataSymptom = {
      name: data.symptom.symptom_name,
      code: data.symptom.symptom_code,
      description: data.symptom.symptom_detail,
      animalTypeId: data.symptom.animalId,
      statusId: data.symptom.status,
    };

    MySwal.fire({
      text: `ต้องการบันทึก `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response =
            data.mode === "add"
              ? await API.saveSymptom(dataSymptom)
              : await API.updateSymptom(data.symptom.symptom_id, dataSymptom);
          if (response.status === 200) {
            await getAnimalSymptom();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTableSymptom = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i}>
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td>{item.name}</td>
          <td>{formJson.animal[item.animalTypeId]}</td>
          <td className="text-center">{getStatusColor(item.statusId)}</td>
          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editModalSymptom(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delSymptom(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={5} />;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ชนิดสัตว์"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={animalIdSelectValue}
                  optionsList={animalIdList}
                  handleChange={(value) => {
                    setAnimalIdSelectValue(value);
                    setForm({ ...form, animalId: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="สถานะ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleChange={(value) => {
                    setStatusSelectValue(value);
                    setForm({ ...form, status: value.value });
                  }}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="รายการข้อมูลอาการป่วย"
            classNameBox="mt-4"
            headRight={buttonAddSymptom()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTableSymptom(listSymptom)}
            </DataTable>
          </BoxCard>
          {showSymptom && (
            <ModalSymptom
              show={showSymptom}
              close={() => setShowSymptom(false)}
              save={(value) => addSaveSymptom(value)}
              mode={form.mode}
              idEdit={form.idEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Symptom;
