import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import * as API from "../../utils/apis";
import DataTableNodata from '../DataTableNodata'
import { useTranslation } from 'react-i18next';
import ModalSetArea from "../Modal.SetArea";
import Table from 'react-bootstrap/Table';

function ActionPlan_Step3({ save, dataAll, modeGet }) {
    const user = useSelector(state => state.login.result)
    const { t } = useTranslation(["common", "footer"]);

    const initialForm = {
        data_setarea: [],
        del_setarea: [],
        modeSetArea: "add",
        dataAnimal: "",
        dataIndex: ""

    };
    const [form, setForm] = useState(initialForm);

    const initialFormJson = {
        province: "",
        amphur: "",
        tambon: "",
        village: "",
        zone: "",
    };
    const [formJson, setFormJson] = useState(initialFormJson);


    const initShowAddSetArea = false;
    const [showAddSetArea, setShowAddSetArea] = useState(initShowAddSetArea)


    useEffect(() => {
        getMasterJson()
    }, [])

    useEffect(async () => {
        if (modeGet == "getById") {
            await getDataById();
        }
    }, [user])

    const getDataById = () => {
        setForm(dataAll)
    }

    useEffect(async () => {
        await toSave()
    }, [form])

    const toSave = () => {
        save(form)
    }

    const getMasterJson = async () => {
        const params = await [
            "province",
            "amphur",
            "tambon",
            "village",
            "zone",
        ];
        try {
            const response = await API.getMasterJson(params);
            const data = await response.data;
            if (response.status == 200) {
                setFormJson({
                    ...formJson,
                    province: data.province,
                    amphur: data.amphur,
                    tambon: data.tambon,
                    village: data.village,
                    zone: data.zone,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const addSetAnimal = (data) => {
        let arraySetArea = [...form.data_setarea];
        arraySetArea.push(data)
        setForm({ ...form, data_setarea: arraySetArea });
    }

    const saveEditAnimal = (data, index) => {
        if (form.data_setarea && form.data_setarea.length > 0) {
            form.data_setarea.map((animal, a) => {
                if (a === index) {
                    Object.assign(animal, data);
                }
            })
            setForm({ ...form });
        }
    }

    const setareaEdit = (item, index) => {
        setShowAddSetArea(true)
        setForm({ ...form, modeSetArea: "edit", dataAnimal: item, dataIndex: index })

    }

    const delSetarea = (index, item) => {
        let delSetarea = []
        form.data_setarea.splice(index, 1);
        if (item.id) {
            delSetarea.push(item.id)
        }
        setForm({ ...form, data_setarea: form.data_setarea, del_setarea: delSetarea });
    }
    
    const setareaDuplicate = (item, index) => {
        setShowAddSetArea(true)
        setForm({ ...form, modeSetArea: "duplicate", dataAnimal: item, dataIndex: index })

    }

    const renderTableSetArea = (data, index) => {
        return (
            <tr className="">
                <td >
                    {index + 1}
                </td>
                <td >
                    {formJson.zone[data.countyId] || '-'}
                </td>
                <td>
                    {formJson.province[data.provinceId] || '-'}
                </td>
                <td >
                    {formJson.amphur[data.districtId] || '-'}
                </td>
                <td >
                    {formJson.tambon[data.subDistrictId] || '-'}
                </td>
                <td >
                    {formJson.village[data.villageId] || '-'}
                </td>
                <td >
                    {data.details || '-'}
                </td>
                <td>
                    {data.number || '-'}
                </td>
                <td className="d-flex justify-content-center">
                    <button className="btn btn-add mr-2 " onClick={() => setareaEdit(data, index)}>
                        <img className="img-fluid icon-btn" src="/svg/btn-edit.svg" alt="edit" />
                    </button>
                    <button className="btn btn-del mr-2 " onClick={() => delSetarea(index, data)}>
                        <img className="img-fluid icon-btn " src="/svg/btn-del.svg" alt="del" />
                    </button>
                    <button className="btn btn-copy mr-2 " onClick={() => setareaDuplicate(data, index)} >
                        <img className="img-fluid icon-btn " src="/svg/btn-copy.svg" alt="copy" />
                    </button>

                </td>
            </tr>
        )
    }

    return (
        <div className=" p-4 bg-white">
            <div className="pt-4 pr-3 pl-4 d-flex justify-content-end ">
                <button className="btn btn-add text-white"
                    onClick={() => {
                        setShowAddSetArea(true)
                        setForm({ ...form, modeSetArea: "add" })
                    }}>

                    <i className="fas fa-plus mr-1 "></i>เพิ่มพื้นที่
                </button>
            </div>

            <div className="table-responsive">
                <Table className="table table-striped table-bordered my-2 ">
                    <thead>
                        <tr>
                            <th scope="col">ลำดับ</th>
                            <th scope="col">เขต</th>
                            <th scope="col">จังหวัด</th>
                            <th scope="col">อำเภอ</th>
                            <th scope="col">ตำบล</th>
                            <th scope="col">หมู่บ้าน</th>
                            <th scope="col">รายละเอียด</th>
                            <th scope="col">จำนวน</th>
                            <th className="text-center" scope="col">จัดการ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {form.data_setarea && form.data_setarea.length > 0 ?
                            form.data_setarea.map((item, i) => {
                               return renderTableSetArea(item, i)
                            })
                            : <DataTableNodata colSpan={9} />
                        }
                    </tbody>
                </Table>
            </div>
            {showAddSetArea &&
                <ModalSetArea show={showAddSetArea} mode={form.modeSetArea} close={() => setShowAddSetArea(false)}
                    dataAnimal={form.dataAnimal} dataIndex={form.dataIndex}
                    save={(value, index) => {
                        if (form.modeSetArea == "add" || form.modeSetArea == "duplicate") {
                            addSetAnimal(value)
                        } else {
                            saveEditAnimal(value, index)
                        }
                    }
                    }
                />
            }

        </div>

    )
}

export default ActionPlan_Step3
