import { all, takeEvery, put } from "redux-saga/effects";
import  * as helper from "../../utils/helper";
import * as actionTypes from "../actions/loginTypes";

export default function* watchLoginSaga() {
  yield all([
    takeEvery(actionTypes.LOG_IN, watchLogin),
    takeEvery(actionTypes.CHECK_LOGIN, watchCheckLogin),
]);
}

function* watchLogin(payload) {
  const {data, history } = payload.payload;
  yield helper.sessionSave("login", data.user);
  yield helper.sessionSave("token", data.token);
  yield helper.setCookie("token", "", -1,  process.env.REACT_APP_COOKIE_DOMAIN);
  yield history.push("/home");

  try {
    yield put({ type: actionTypes.LOG_IN_SUCCESS, payload: JSON.parse(data.user) });
  } catch (e) {
    yield put({ type: actionTypes.LOG_IN_FAILED, payload: e });
  }
}

function* watchCheckLogin() {
  const items = yield helper.sessionGet("login");
  try {
    if (items) {
      yield put({ type: actionTypes.LOG_IN_SUCCESS, payload: JSON.parse(items) });
    }
  } catch (e) {
    yield put({ type: actionTypes.LOG_IN_FAILED, payload: e });
  }
}


