let en = {
  common: {
    "statistics": "สถิติและภาพรวม",
    "passive_surveillance": "เฝ้าระวังเชิงรับ",
    "active_surveillance": "เฝ้าระวังเชิงรุก",
    "farm_registration": "ขึ้นทะเบียนฟาร์ม",
    "assess_risk": "แบบประเมินความเสี่ยง",
    "data_management": "บริหารข้อมูลส่วนกลาง",
    "online": "เว็ปไซต์",
    "poultry_system":"ระบบผลผลิตสัตว์ปีก",

    "filter": "ตัวกรอง",
    "save": "บันทึก",
    "cancel": "ยกเลิก",
    "ok": "ตกลง",
    "no_data": "ไม่มีข้อมูล",
    "search": "ค้นหา",
    "clear": "ล้างข้อมูล",
    "no": "อันดับ",
    "org_name": "ชื่อหน่วยงาน",
    "manage": "จัดการ",

    "reportId": "รหัสการแจ้งโรค",
    "name_full": "ชื่อ/นามสุกล/บัตรประชาชน/เบอร์โทรศัพท์",
    "first_name": "ชื่อ",
    "last_name": "นามสกุล",
    "gender": "เพศ",
    "email": "อีเมล",
    "phone": "หมายเลขโทรศัพท์",


    "province": "จังหวัด",
    "district": "เขต/อำเภอ",
    "subDistrict": "แขวง/ตำบล",
    "village": "หมู่บ้าน",
    "home_no": "บ้านเลขที่",
    "alley": "ตรอก/ซอย",
    "road": "ถนน",
    "zipcode": "รหัสไปรษณีย์",
    "localGovern": "องค์กรปกครองส่วนท้องถิ่น",
    "governmentName": "ชื่อองค์กรปกครองส่วนท้องถิ่น",

    "date_disease": "วันที่พบโรค",
    "date_disease_report": "วันที่รายงานโรค",
    "toDate": "ถึงวันที่",
    "lastDate": "วันที่บันทึกข้อมูลล่าสุด",
    "status_": "สถานะการดำเนินการ",
    "animal_species": "ชนิดสัตว์",
    "please_select": "กรุณาเลือก",
    "please specify": "โปรดระบุ",
    "add_report_disease": "เพิ่มการแจ้งโรค",

    // farm_registration
    "list_farm": "รายการฟาร์ม",
    "save_data_farm": "บันทึกข้อมูลฟาร์ม",
    "save_data_animal": "บันทึกข้อมูลสัตว์รายตัว",
    "data_animal": "ข้อมูลสัตว์",
    "animal_type": "ชนิดสัตว์",
    "animal_id": "รหัสสัตว์",
    "be_alive": "มีชีวิตอยู่ปกติ",
    "sick": "ป่วย",
    "move_out": "ย้ายออก",
    "dead": "ตาย",
    "date_dead": "วันที่ตาย",
    "cause_death": "สาเหตุการตาย",
    "unknown": "ไม่ทราบ",
    "kill": "ทำให้ตาย",
    "die": "ตายเอง",
    "other": "อื่นๆ",
    "house_buiding": "โรงเรือน",
    "age": "อายุ",
    "year": "ปี",
    "month": "เดือน",
    "no_animal_ear": "หมายเลขประจำตัว (เบอร์หู)",
    "no_microchip": "หมายเลขไมโครชิพ",
    "breed": "พันธุ์",
    "purpose_raising": "วัตถุประสงค์ในการเลี้ยง",
    "no_nid": "หมายเลข NID",
    "style_raising": "ลักษณะการเลี้ยง",
    "vaccination": "การได้รับวัคซีน",
    "ever_received": "เคยได้รับ",
    "never_received": "ไม่เคยได้รับ",
    "choose_disease": "เลือกชนิดโรค",
    "last_date": "ครั้งสุดท้ายวันที่",
    "vaccine_operator": "ผู้ดำเนินการฉีดวัคซีน",
    "livestock_officers": "เจ้าหน้าที่กรมปศุสัตว์",
    "farmer": "เกษตรกร",
    "livestock_volunteer": "อาสาปศุสัตว์",
    "animal_had_disease": "สัตว์ตัวนี้เคยเป็นโรค",
    "ever": "เคย",
    "never": "ไม่เคย",

     //Active_Surveillance เพิ่ม
     "fiscal_year":"ปีงบประมาณ",
     "project":"โครงการ",
     "during_day":"ระหว่างวันที่",
     "up_date":"ถึงวันที่",
     "status":"สถานะ",
     "action_plan":"แผนการดำเนินงาน",
     "action_plan_list":"รายการแผนการดำเนินงาน",
     "project_details":"รายละเอียดโครงการ",
     "objective":"วัตถุประสงค์",
     "project_start_date":"วันที่เริ่มโครงการ",
     "end_date":"วันที่สิ้นสุด",
     "number":"จำนวน",
     "area":"พื้นที่",
     "farm_code":"รหัสฟาร์ม",
     "local_government":"องค์กรปกครองส่วนท้องถิ่น",
     "local":"ท้องถิ่น",
     "sample_collection_date":"วันที่เก็บตัวอย่าง",
     "sample_submission_date":"วันที่ส่งตัวอย่าง",
     "send_sample":"วิธีการส่งตัวอย่าง",
     "note":"หมายเหตุ",
     "suspected_disease":"ชนิดโรคที่สงสัย",
     "purpose_submitting_samples":"วัตถุประสงค์ในการส่งตัวอย่าง",
     "where_send_samples":"สถานที่ส่งตัวอย่าง",
     "laboratory_results":"ผลการตรวจทางห้องปฏิบัติการ",
     "farm_inspection_results":"ผลการตรวจรายฟาร์ม",
     "house_name":"ชื่อโรงเรือน",
     "summary":"สรุปผลการตรวจ ",
     "types_diseases ":"ชนิดโรคที่พบ",
     "method_examination":"วิธีการตรวจ",
     "individual_results":"ผลการตรวจรายตัว",
     "sample_number":"หมายเลขตัวอย่าง",
     "farm_data":"ข้อมูลรายฟาร์ม",
     "house":"โรงเรือน",
     "sample_type ":"ชนิดตัวอย่างที่เก็บ",
     "choose_format":"เลือกรูปแบบ",
     "":"ชนิดระดับภูมิคุ้มกัน",
     "type_immunity":"เพิ่ม (ปรับปรุง) รายการข้อมูลการเฝ้าระวังโรค",
     "report":"รายงาน C03RPT01-การบันทึกผล",
     "report_save":"รายการข้อมูลรายงาน C03RPT01-การบันทึกผล",
     "report_test":"รายการข้อมูลรายงาน C03RPT02-ผลการทดสอบโรค",
     "immune_level":"ระดับภูมิคุ้มกันต่อชนิดโรค ปากและเท้าเปื่อย ซีโรไทป์โอ ชนิดสัตว์ โคเนื้อ วิธีการตรวจ NSP",
     "save_report1":"รายงาน",
     
     //ข้อมูลการติดต่อสอบถาม
     "data_contact": "ข้อมูลการติดต่อ",
     "data_contact2": "ข้อมูลที่ต้องการติดต่อ/สอบถาม",
     "title_to_contact": "หัวข้อที่ต้องการติดต่อ",
     "detail_to_contact": "รายละเอียด",
     "pass_img": "รหัสภาพ",
     "send_data": "ส่งข้อมูล",
  },
  footer: {
    "address": "ที่อยู่"
  }
};

export default en;