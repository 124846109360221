/*global google*/
import React, { useEffect, useState } from 'react'
import * as helper from "../../utils/helper";
import thailandJson from "./thailand.json";

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY,
  TILE_SIZE = 256,
  desiredRadiusPerPointInMeters = 5000,
  someLatValue = 35.726332,
  desiredRadiusInMeters = 5000;
let map,
  marker,
  farmBufferCircle,
  farmBufferMarkers = [],
  farmLocatinMarkers = [],
  diseaseCircle = [],
  diseaseMarkers = [],
  farmRiskMarkers = [],
  heatmap,
  heatmapList = [];

export default function MapDiseaseZone({ location = { lat: 14.384072628283658, lng: 100.55795047023376 }, farmBufferZoneLocation = [], bufferZoneRadius = 0, getLocation, getFilterFarmLocation,
  farmLocationReport = [], diseaseZoneRadius = 0, diseaseZoneReport = [], riskLevelIcon = [], farmRiskReport = [], riskAreaReport = [], riskAreaOpacity }) {

  const [locationMap, setLocationMap] = useState(location)

  const iconBase = "/svg/pin_b.svg";
  const iconFarm = "/svg/pin_sky.svg";


  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=visualization,geometry`;
    script.async = true;
    window.document.body.appendChild(script);
    script.addEventListener("load", () => {
      initMap();
    });
    renderRiskLegend();
  }, [])

  useEffect(() => {
    if (map) {
      setBufferZoneRadius()
    }
  }, [location, bufferZoneRadius])

  useEffect(() => {
    if (map) {
      setFarmBufferZoneLocation()
    }
  }, [farmBufferZoneLocation])

  useEffect(() => {
    if (map) {
      setFarmLocation()
    }
  }, [farmLocationReport])

  useEffect(() => {
    if (map) {
      setDiseaseZoneLocation()
    }
  }, [diseaseZoneRadius, diseaseZoneReport])

  useEffect(() => {
    if (map) {
      setFarmRiskLocation()
    }
  }, [farmRiskReport])

  useEffect(() => {
    if (map) {
      setRiskAreaLocation()
    }
  }, [riskAreaReport])

  useEffect(() => {
    if (map) {
      setRiskAreaOpacity()
    }
  }, [riskAreaOpacity])

  const initMap = () => {
    const legend = document.getElementById("heatmap-legend");
    map = new google.maps.Map(document.getElementById("map"), {
      zoom: 10,
      center: locationMap,
      mapTypeId: 'hybrid',
    });
    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
  }

  const setLocation = (val) => {
    getLocation(val)
    setLocationMap(val)
  }

  const setBufferZoneRadius = () => {
    if (google) {
      if (farmBufferCircle) {
        farmBufferCircle.setMap(null);
      }
      if (bufferZoneRadius > 0) {
        if (marker) {
          marker.setPosition(location);
          map.setCenter(location)
        } else {
          marker = new google.maps.Marker({
            map: map,
            position: locationMap,
            draggable: true,
            icon: iconBase
          });

          google.maps.event.addListener(marker, "dragend", function (e) {
            setLocation({
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            });
          });

          map.addListener("click", async (e) => {
            setLocation({
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            });
            addMarker(e.latLng);
          });
        }

        farmBufferCircle = new google.maps.Circle({
          map: map,
          radius: parseInt(bufferZoneRadius) * 1000 || 0,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#000000",
          fillOpacity: 0.2,
        });
        farmBufferCircle.bindTo('center', marker, 'position');
        google.maps.event.addListener(farmBufferCircle, 'click', function (ev) {
          if (farmBufferZoneLocation.length > 0) {
            getFilterFarmLocation(farmBufferZoneLocation)
          }
        });
      }
      if (bufferZoneRadius == 0) {
        if (marker) {
          marker.setMap(null);
          marker = null;
        }
      }
    }
  }

  const addMarker = (location) => {
    if (marker) {
      marker.setPosition(location);
      map.setCenter(location)
    } else {
      marker = new google.maps.Marker({
        position: location,
        map: map
      });
    }
  }

  const setFarmBufferZoneLocation = () => {
    for (let i = 0; i < farmBufferMarkers.length; i++) {
      farmBufferMarkers[i].setMap(null);
    }
    if (farmBufferZoneLocation.length > 0) {
      // const center = circle.getCenter();
      // const circleRadius = circle.getRadius();
      // setFarmFilter(farmBufferZoneLocation.filter(item => {
      //   if (circleContainsLocation(new google.maps.LatLng(Number(item.latitude), Number(item.longitude)), center, circleRadius)) {
      //     return item;
      //   }
      // }))
      let fMarker = [];
      farmBufferZoneLocation.forEach((item, j) => {
        fMarker[j] = new google.maps.Marker({
          position: new google.maps.LatLng(item.latitude, item.longitude),
          map: map,
          icon: iconFarm,
          title: item.farmName ? item.farmName : "ไม่ระบุชื่อ"
        });
        const infowindow = new google.maps.InfoWindow({
          content: item.farmName ? item.farmName : "ไม่ระบุชื่อ",
        });
        fMarker[j].addListener("click", () => {
          infowindow.open({
            anchor: fMarker[j],
            map,
            shouldFocus: false,
          });
        });
        farmBufferMarkers.push(fMarker[j]);
      });

    }
  }

  const setFarmLocation = () => {
    for (let i = 0; i < farmLocatinMarkers.length; i++) {
      farmLocatinMarkers[i].setMap(null);
    }
    if (farmLocationReport.length > 0) {
      let fMarker = [];
      farmLocationReport.forEach((item, j) => {
        fMarker[j] = new google.maps.Marker({
          position: new google.maps.LatLng(item.latitude, item.longitude),
          map: map,
          icon: iconFarm,
          title: item.farmName ? item.farmName : "ไม่ระบุชื่อ"
        });

        const infowindow = new google.maps.InfoWindow({
          content: renderInfoWindow(item, 'farmlocation')
        });
        fMarker[j].addListener("click", () => {
          infowindow.open({
            anchor: fMarker[j],
            map,
            shouldFocus: true,
          });
        });
        farmLocatinMarkers.push(fMarker[j]);
      });

    }
  }

  const renderInfoWindow = (data, type = "") => {
    const tableF = '<table class="table table-bordered"><tbody>';
    const tableL = '</tbody></table>';
    let tableBA = "";
    let tableBB = "";
    let tableBC = "";

    const renderCell = (title, value, lAlign = 'text-center', rAlign = 'text-center') => {
      return `<tr><td class=${lAlign}>${title}</td><td class=${rAlign}>${value}</td></tr>`;
    }

    const renderFarmer = () => {
      const content = [
        { name: "ชื่อฟาร์ม :", value: data.farmName ? data.farmName : "-" },
        { name: "ชื่อเจ้าของ :", value: data.farmerName ? data.farmerName : "-" },
        { name: "โทรศัพท์ :", value: data.phone ? data.phone : "-" },
        { name: "Latitude :", value: data.latitude },
        { name: "Longitude :", value: data.longitude },
      ];
      return content.map((item, i) => {
        return renderCell(item.name, item.value);
      }).join("");
    }

    const renderFarmName = () => {
      const content = [
        { name: "ชื่อฟาร์ม :", value: data.farmName ? data.farmName : "-" },
        { name: "วันที่ทำแบบสอบถาม :", value: data.createDate ? helper.momentDate(data.createDate, 'th') : "-" },
      ];
      return content.map((item, i) => {
        return renderCell(item.name, item.value);
      }).join("");
    }

    const renderAnimal = (animals) => {
      return animals.map((item, i) => {
        return renderCell(item.animalTypeName, item.animalAmt);
      }).join("");
    }

    const renderDisease = (disease) => {
      const d = [
        { name: "โรคที่พบ :", value: disease.diseaseTypeName ? disease.diseaseTypeName : "-" },
        { name: "วันที่พบ :", value: disease.foundDiseaseDate ? helper.momentDate(disease.foundDiseaseDate, 'th') : "-" },
      ];
      return d.map((item, i) => {
        return renderCell(item.name, item.value);
      }).join("");
    }

    const renderRiskLevel = (level) => {
      return level.map((item, i) => {
        const icon = riskLevelIcon.find(el => {
          return el.id == item.riskLevel;
        });
        const icontag = `<img src="${icon.img}" />`
        return renderCell(`${item.diseaseTypeName} ${item.diseaseTypeShortName? "("+item.diseaseTypeShortName+")": "" }`,
          //`${icontag} ${item.score} ${item.riskLevelName}`,
          `${icontag} ${item.riskLevelName}`,
          'text-center',
          'text-left');
      }).join("");
    }

    if (type == "farmlocation") {
      tableBA = renderFarmer();
      tableBB = renderAnimal(data.animalType);

    }
    if (type == "diseaselocation") {
      tableBA = renderFarmer();
      tableBC = renderDisease(data);

    }

    if (type == "farmrisklocation") {
      tableBA = renderFarmName();
      tableBC = renderRiskLevel(data.diseaseScore);
    }

    return tableF + tableBA + tableBB + tableBC + tableL;
  }

  const setDiseaseZoneLocation = () => {
    for (let i = 0; i < diseaseMarkers.length; i++) {
      diseaseMarkers[i].setMap(null);
    }
    for (let j = 0; j < diseaseCircle.length; j++) {
      diseaseCircle[j].setMap(null);
    }
    if (diseaseZoneReport.length > 0) {
      let fMarker = [];
      let dCircle = [];
      diseaseZoneReport.forEach((item, k) => {
        fMarker[k] = new google.maps.Marker({
          position: new google.maps.LatLng(item.latitude, item.longitude),
          map: map,
          icon: iconFarm,
          title: item.farmName ? item.farmName : "ไม่ระบุชื่อ"
        });
        const infowindow = new google.maps.InfoWindow({
          content: renderInfoWindow(item, 'diseaselocation'),
        });
        fMarker[k].addListener("click", () => {
          infowindow.open({
            anchor: fMarker[k],
            map,
            shouldFocus: false,
          });
        });
        diseaseMarkers.push(fMarker[k]);

        if (diseaseZoneRadius > 0) {
          dCircle[k] = new google.maps.Circle({
            map: map,
            radius: parseInt(diseaseZoneRadius) * 1000 || 0,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#000000",
            fillOpacity: 0.2,
            center: new google.maps.LatLng(item.latitude, item.longitude),
          });
          diseaseCircle.push(dCircle[k]);
        }
      });
    }
  }

  const setFarmRiskLocation = () => {
    for (let i = 0; i < farmRiskMarkers.length; i++) {
      farmRiskMarkers[i].setMap(null);
    }

    if (farmRiskReport.length > 0) {
      let fMarker = [];
      farmRiskReport.forEach((item, k) => {
        let riskMarker = riskLevelIcon.find(icon => icon.id == item.avgRiskLevel).img;
        fMarker[k] = new google.maps.Marker({
          position: new google.maps.LatLng(item.latitude, item.longitude),
          map: map,
          icon: riskMarker,
          title: item.farmName ? item.farmName : "ไม่ระบุชื่อ"
        });
        const infowindow = new google.maps.InfoWindow({
          content: renderInfoWindow(item, 'farmrisklocation'),
        });
        fMarker[k].addListener("click", () => {
          infowindow.open({
            anchor: fMarker[k],
            map,
            shouldFocus: false,
          });
        });
        farmRiskMarkers.push(fMarker[k]);

      });
    }
  }

  const setRiskAreaLocation = async () => {
    const legend = document.getElementById("heatmap-legend");
    if (riskAreaReport.length > 0) {
      if (heatmap) {
        // heatmap.setMap(null);
        // map.data.forEach(function(feature) {
        //     map.data.remove(feature);
        // });
      }

      if (!heatmap) {
        // console.log("thailand", thailandJson)
        // map.data.loadGeoJson("/thailand.json");
        map.data.addGeoJson(thailandJson);
        map.data.setStyle({
          fillColor: '#22fd35',
          fillOpacity: 0.1,
          strokeColor: 'white',
          strokeWeight: 0.5,
          strokeOpacity: 0.1
        });
      }

      // const heatmapData = riskAreaReport.map(item=>{
      //   return {location: new google.maps.LatLng(item.latitude, item.longitude), weight: item.riskLevel};
      // });
      // heatmap = new google.maps.visualization.HeatmapLayer({
      //   data: heatmapData,
      //   radius: getNewRadius(),
      //   opacity: riskAreaOpacity / 100,
      //   map: map
      // });

      // map.addListener("zoom_changed", () => {
      // console.log("Zoom: " + map.getZoom(), 
      //  "getNewRadius", getNewRadius(), 
      //  "getHeatmapRadius", getHeatmapRadius());
      // heatmap.set("radius", getNewRadius());
      // });

      // const heatmapJsonData = JSON.stringify({
      //     type: "FeatureCollection",
      //     features: riskAreaReport.map(item=>{
      //       return {
      //         type: "Feature",
      //         properties: {
      //           riskLevel: item.riskLevel,
      //           score: item.score,
      //           statusName: item.statusName
      //         },
      //         geometry: {
      //           type: "Point",
      //           coordinates: [Number(item.latitude), Number(item.longitude)]
      //         }
      //       };
      //     })
      // })
      // console.log("heatmapData", heatmapJsonData);
      // map.data.addGeoJson(JSON.parse(heatmapJsonData));
      // map.data.setStyle(styleFeature)

      // Circle heatmap

      for (let i = 0; i < heatmapList.length; i++) {
        heatmapList[i].setMap(null);
      }

      if (riskAreaReport.length > 0) {
        let hMarker = [];

        riskAreaReport.forEach((item, k) => {
          const risk = riskLevelIcon.find(el => {
            return el.id == item.riskLevel;
          });
          hMarker[k] = new google.maps.Circle({
            strokeColor: "#FFFFFF",
            strokeOpacity: 0.25,
            strokeWeight: 0.5,
            fillColor: risk.color,
            fillOpacity: riskAreaOpacity / 100,
            map,
            center: new google.maps.LatLng(item.latitude, item.longitude),
            radius: 5000,
          });
          heatmapList.push(hMarker[k]);
        });
        heatmap = true;
      }

      if (legend) {
        legend.style.opacity = 1;
      }
    } else {
      // if (heatmap) {
      // heatmap.setMap(null);
      if (legend) {
        legend.style.opacity = 0;
      }
      // }
      for (let i = 0; i < heatmapList.length; i++) {
        heatmapList[i].setMap(null);
      }
      map.data.forEach(function (feature) {
        map.data.remove(feature);
      });
      heatmap = false;
    }
  }


  function styleFeature(feature) {
    const icon = riskLevelIcon.find(el => {
      return el.id == feature.getProperty("riskLevel");
    });
    return {
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        strokeWeight: 0.5,
        strokeColor: "#fff",
        fillColor: icon.color,
        fillOpacity: riskAreaOpacity / 100,
        scale: 5000,
        visible: true
      },
      zIndex: 1200,
    };
  }


  const getHeatmapRadius = () => {
    const metersPerPx = 156543.03392 * Math.cos(someLatValue * Math.PI / 180) / Math.pow(2, map.getZoom());
    return desiredRadiusInMeters / metersPerPx;
  };

  const getNewRadius = () => {
    let numTiles = 1 << map.getZoom();
    let center = map.getCenter();
    let moved = google.maps.geometry.spherical.computeOffset(center, 10000, 90); /*1000 meters to the right*/
    let projection = new MercatorProjection();
    let initCoord = projection.fromLatLngToPoint(center);
    let endCoord = projection.fromLatLngToPoint(moved);
    let initPoint = new google.maps.Point(
      initCoord.x * numTiles,
      initCoord.y * numTiles);
    let endPoint = new google.maps.Point(
      endCoord.x * numTiles,
      endCoord.y * numTiles);
    let pixelsPerMeter = (Math.abs(initPoint.x - endPoint.x)) / 10000.0;
    let totalPixelSize = Math.floor(desiredRadiusPerPointInMeters * pixelsPerMeter);
    // console.log("Total Pixel Size", totalPixelSize);
    return totalPixelSize;
  }

  //Mercator --BEGIN--
  function bound(value, opt_min, opt_max) {
    if (opt_min !== null) value = Math.max(value, opt_min);
    if (opt_max !== null) value = Math.min(value, opt_max);
    return value;
  }

  function degreesToRadians(deg) {
    return deg * (Math.PI / 180);
  }

  function radiansToDegrees(rad) {
    return rad / (Math.PI / 180);
  }

  function MercatorProjection() {
    this.pixelOrigin_ = new google.maps.Point(TILE_SIZE / 2, TILE_SIZE / 2);
    this.pixelsPerLonDegree_ = TILE_SIZE / 360;
    this.pixelsPerLonRadian_ = TILE_SIZE / (2 * Math.PI);
  }

  MercatorProjection.prototype.fromLatLngToPoint = function (latLng, opt_point) {
    let me = this;
    let point = opt_point || new google.maps.Point(0, 0);
    let origin = me.pixelOrigin_;

    point.x = origin.x + latLng.lng() * me.pixelsPerLonDegree_;

    // NOTE(appleton): Truncating to 0.9999 effectively limits latitude to
    // 89.189.  This is about a third of a tile past the edge of the world
    // tile.
    let siny = bound(Math.sin(degreesToRadians(latLng.lat())), - 0.9999, 0.9999);
    point.y = origin.y + 0.5 * Math.log((1 + siny) / (1 - siny)) * -me.pixelsPerLonRadian_;
    return point;
  };

  MercatorProjection.prototype.fromPointToLatLng = function (point) {
    let me = this;
    let origin = me.pixelOrigin_;
    let lng = (point.x - origin.x) / me.pixelsPerLonDegree_;
    let latRadians = (point.y - origin.y) / -me.pixelsPerLonRadian_;
    let lat = radiansToDegrees(2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2);
    return new google.maps.LatLng(lat, lng);
  };

  //Mercator --END--

  const renderRiskLegend = () => {
    const legend = document.getElementById("heatmap-legend");
    riskLevelIcon.filter(item => {
      return item.id != 0;
    }).forEach(item => {
      const div = document.createElement("div");
      div.innerHTML = '<span style="background-color:' + item.color + ';display:block;height:10px;width:20px;"></span> <span>' +
        item.name + '</span>';
      legend.appendChild(div);
    })
    legend.style.opacity = 0;
    if (map) {
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
    }
  }

  const setRiskAreaOpacity = () => {
    if (riskAreaReport.length > 0) {
      if (heatmap) {
        // heatmap.set("opacity", riskAreaOpacity/100);
      }
      setRiskAreaLocation();
    }
  }

  const circleContainsLocation = (point, center, circleRadius) => {
    return (google.maps.geometry.spherical.computeDistanceBetween(point, center) <= circleRadius)
  }

  return (
    <>
      <div id="map" className="h-100"></div>
      <div id="heatmap-legend"><h6>ระดับความเสี่ยง</h6>
      </div>
      <style jsx="true">{`
          #heatmap-legend {
            background: #fff;
            padding: 10px;
            margin: 10px;
            border: 1px solid #000;
          }
          #heatmap-legend h6 {
            margin-top: 0;
          }

          #heatmap-legend div {
            display: flex;
            margin-bottom: .5em;
          }

          #heatmap-legend span:first-child {
            vertical-align: middle;
            margin-right: 1em;
          }
      `}</style>
    </>
  )
}
