import { httpClient } from "./httpClient";
import axios from "axios";

/* ======================== Login ======================== */

export const login = async (formData) => {
  return await httpClient.post(`/login`, formData);
};

export const loginFacebook = async (socialId) => {
  return await httpClient.post(`/login/facebook`, socialId);
};

export const checkFacebook = async (facebookId) => {
  console.log("facebookId", facebookId);
  return await httpClient.get(`/user/facebook?facebookId=${facebookId}`);
};

export const loginGoogle = async (socialId) => {
  return await httpClient.post(`/login/google`, socialId);
};

export const checkGoogle = async (googleId) => {
  return await httpClient.get(`/user/google?googleId=${googleId}`);
};

export const loginLine = async (socialId) => {
  return await httpClient.post(`/login/line`, socialId);
};

export const checkLine = async (lineId) => {
  return await httpClient.get(`/user/line?lineId=${lineId}`);
};

/* ======================== get Profile by Token ======================== */

export const getProfile = async (token) => {
  const getProfile = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      OAuth: "DLD",
    },
  });
  return await getProfile.get(`/user/profile`);
};

// Line API
export const getLineAccessToken = async (code) => {
  const lineOption = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    timeout: 85000,
  };

  // console.log("getLineAccessToken", code);

  const lineData = [
    "grant_type=authorization_code",
    `code=${code}`,
    `redirect_uri=${process.env.NEXT_PUBLIC_LINE_REDIRECT_URL}`,
    `client_id=${process.env.NEXT_PUBLIC_LINE_CLIENT_ID}`,
    `client_secret=${process.env.NEXT_PUBLIC_LINE_CLIENT_SECRET}`,
  ];

  // console.log("lineOption", lineOption);

  const lineApi = axios.create(lineOption);
  return await lineApi.post(
    `https://api.line.me/oauth2/v2.1/token`,
    lineData.join("&")
  );
};

export const getLineInformation = async (token) => {
  const lineOption = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    timeout: 85000,
  };

  // console.log("token", token);

  const lineData = [
    `id_token=${token}`,
    `client_id=${process.env.NEXT_PUBLIC_LINE_CLIENT_ID}`,
    `nonce=${process.env.NEXT_PUBLIC_LINE_NONCE}`,
  ];

  const lineApi = axios.create(lineOption);
  return await lineApi.post(
    `https://api.line.me/oauth2/v2.1/verify`,
    lineData.join("&")
  );
};

/* ======================== Master Data ======================== */

export const getPsSymptoms = async (
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/ps-symptoms?id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getCarrierType = async (
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  statusId = ""
) => {
  return await httpClient.get(
    `/master-data/carrier-type?statusId=${statusId}&id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getMasterJson = async (params) => {
  return await httpClient.get(`/master-json?master=${params}`);
};

export const getTitle = async (
  statusId = "",
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "orderIndex",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/title?statusId=${statusId}&id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getVillage = async (
  id = "",
  tambonId = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "name",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/village?id=${id}&tambonId=${tambonId}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getZone = async () => {
  return await httpClient.get(
    `/master-data/zone?statusId=1`
  );
};

export const getLocalGovern = async (
  id = "",
  statusId = "",
  search = "",
  page = "1",
  limit = "100",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/dlas?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getAnimal = async (
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/animal-type?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getAnimalParent = async (
  animalTypeId = "",
  parent = "",
  page = "1",
  limit = "100",
  id = "",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/animal-parent?id=${id}&animalTypeId=${animalTypeId}&parent=${parent}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getStatus = async (
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/trans-status?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getInform = async (
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/inform-by?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

//=================== Get Inform Report
export const getInformsReport = async (
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  infCode = "",
  person = "",
  foundDateBegin = "",
  foundDateEnd = "",
  createDateBegin = "",
  createDateEnd = "",
  zoneId = "",
  provinceId = "",
  amphurId = "",
  tambonId = "",
  animalTypeIds = "",
  statusId = ""
) => {
  return await httpClient.get(
    `/informs-report?id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}&infCode=${infCode}&person=${person}&foundDateBegin=${foundDateBegin}&foundDateEnd=${foundDateEnd}&createDateBegin=${createDateBegin}&createDateEnd=${createDateEnd}&zoneId=${zoneId}&provinceId=${provinceId}&amphurId=${amphurId}&tambonId=${tambonId}&animalTypeIds=${animalTypeIds}&statusId=${statusId}`
  );
};

export const getPlace = async (
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/place-type?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getDiseaseType = async (
  animalTypeId = "",
  id = "",
  page = "1",
  limit = "100",
  statusId = "",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  isZeroReport = "",
) => {
  return await httpClient.get(
    `/master-data/disease-type?id=${id}&animalTypeId=${animalTypeId}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}&isZeroReport=${isZeroReport}`
  );
};

export const getVaccineType = async (
  animalTypeId = "",
  diseaseTypeId = "",
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/vaccine-type?animalTypeId=${animalTypeId}&diseaseTypeId=${diseaseTypeId}&id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getHusbandry = async (
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/husbandry?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getHusbandryById = async (id) => {
  return await httpClient.get(`/master-data/husbandry/${id}`);
};

export const saveHusbandryType = async (data) => {
  return await httpClient.post(`/master-data/husbandry`, data);
};

export const updateHusbandry = async (id, data) => {
  return await httpClient.put(`/master-data/husbandry/${id}`, data);
};

export const deleteHusbandry = async (id) => {
  return await httpClient.delete(`/master-data/husbandry/${id}`);
};

export const getAnimalPlace = async (
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/place-type?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getAnimalPlaceById = async (id) => {
  return await httpClient.get(`/master-data/place-type/${id}`);
};

export const saveAnimalPlaceType = async (data) => {
  return await httpClient.post(`/master-data/place-type`, data);
};

export const updateAnimalPlace = async (id, data) => {
  return await httpClient.put(`/master-data/place-type/${id}`, data);
};

export const deleteAnimalPlace = async (id) => {
  return await httpClient.delete(`/master-data/place-type/${id}`);
};

export const getAnimalSymptom = async (
  animalTypeIds = "",
  statusId = "",
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/symptom?animalTypeIds=${animalTypeIds}&statusId=${statusId}&id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getActionProtection = async (
  id = "",
  search = "",
  page = "1",
  limit = "100",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/action-protection?id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getAnnouceYear = async () => {
  return await httpClient.get(`/annouce/year`);
};

export const getModule = async (
  id = "",
  search = "",
  page = "1",
  limit = "100",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `master-data/module?id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

//=================== User User-View
export const getUserView = async (
  id = "",
  statusId = "",
  search = "",
  page = "1",
  limit = "100",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `master-data/user-view?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveUserView = async (data) => {
  return await httpClient.post(`/master-data/user-view`, data);
};

export const updateUserView = async (id, data) => {
  return await httpClient.put(`/master-data/user-view/${id}`, data);
};

export const deleteUserView = async (id) => {
  return await httpClient.delete(`/master-data/user-view/${id}`);
};

export const getUserViewById = async (id) => {
  return await httpClient.get(`/master-data/user-view/${id}`);
};
//=================== User User-Group

export const getUserGroup = async (
  id = "",
  statusId = "",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/master-data/user-group/?&id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveUserGroup = async (data) => {
  return await httpClient.post(`/master-data/user-group`, data);
};

export const updateUserGroup = async (id, data) => {
  return await httpClient.put(`/master-data/user-group/${id}`, data);
};

export const deleteUserGroup = async (id) => {
  return await httpClient.delete(`/master-data/user-group/${id}`);
};

export const getUserGroupById = async (id) => {
  return await httpClient.get(`/master-data/user-group/${id}`);
};

//=================== User Users

export const getUsers = async (
  dateBegin = "",
  dateEnd = "",
  provinceId = "",
  amphurId = "",
  tambonId = "",
  mooId = "",
  title = "",
  name = "",
  lastName = "",
  tel = "",
  email = "",
  userGroupId = "",
  userTypeId = "",
  userViewId = "",
  statusId = "",
  search = "",
  page = "1",
  limit = "100",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/users?&dateBegin=${dateBegin}&dateEnd=${dateEnd}&provinceId=${provinceId}&amphurId=${amphurId}&tambonId=${tambonId}&mooId=${mooId}&title=${title}&name=${name}&lastName=${lastName}&tel=${tel}&email=${email}&userGroupId=${userGroupId}&userTypeId=${userTypeId}&userViewId=${userViewId}&statusId=${statusId}&search=${search}&page=${page}&limit=${limit}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};

export const getUserStatus = async (
  id = "",
  statusId = "",
  search = "",
  page = "1",
  limit = "100",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/user-status?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getUserType = async (
  id = "",
  statusId = "",
  search = "",
  page = "1",
  limit = "100",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/user-types?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveUsers = async (data) => {
  return await httpClient.post(`/master-data/users`, data);
};

export const updateUsers = async (id, data) => {
  return await httpClient.put(`/master-data/users/${id}`, data);
};

export const deleteUser = async (id) => {
  return await httpClient.delete(`/master-data/users/${id}`);
};

export const getUsersById = async (id) => {
  return await httpClient.get(`/master-data/users/${id}`);
};

//=================== Web Setting Banners

export const getBanners = async (
  url = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  dateBegin = "",
  dateEnd = ""
) => {
  return await httpClient.get(
    `/website/banners?&url=${url}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}&dateBegin=${dateBegin}&dateEnd=${dateEnd}`
  );
};

export const saveBanners = async (data) => {
  return await httpClient.post(`/website/banners`, data);
};

export const updateBanners = async (id, data) => {
  return await httpClient.put(`/website/banners/${id}`, data);
};

export const deleteBanners = async (id) => {
  return await httpClient.delete(`/website/banners/${id}`);
};

export const getBannersById = async (id) => {
  return await httpClient.get(`/website/banners/${id}`);
};

//=================== Web Setting News

export const getNews = async (
  title = "",
  newCategoryId = "",
  description = "",
  dateBegin = "",
  statusId = "",
  dateEnd = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/news/?&title=${title}&newCategoryId=${newCategoryId}&description=${description}&dateBegin=${dateBegin}&statusId=${statusId}&dateEnd=${dateEnd}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveNews = async (data) => {
  return await httpClient.post(`/news/save-data`, data);
};

export const updateNews = async (id, data) => {
  return await httpClient.post(`/news/save-data/${id}`, data);
};

export const deleteNews = async (id) => {
  return await httpClient.delete(`/news/${id}`);
};

export const getNewsById = async (id) => {
  return await httpClient.get(`/news/${id}`);
};

//=================== Web Setting Document
export const getDocument = async (
  id = "",
  docCategoryId = "",
  statusId = "",
  search = "",
  dateBegin = "",
  dateEnd = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "",
  limit = ""
) => {
  return await httpClient.get(
    `/website/document?&id=${id}&docCategoryId=${docCategoryId}&statusId=${statusId}&search=${search}&dateBegin=${dateBegin}&dateEnd=${dateEnd}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveDocument = async (data) => {
  return await httpClient.post(`/website/document`, data);
};

export const updateDocument = async (id, data) => {
  return await httpClient.put(`/website/document/${id}`, data);
};

export const deleteDocument = async (id) => {
  return await httpClient.delete(`/website/document/${id}`);
};

export const getDocumentById = async (id) => {
  return await httpClient.get(`/website/document/${id}`);
};

//=================== Web Setting Warning

export const getWarning = async (
  statusId = "",
  dateBegin = "",
  dateEnd = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/warning/?statusId=${statusId}&dateBegin=${dateBegin}&dateEnd=${dateEnd}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveWarning = async (data) => {
  return await httpClient.post(`/warning/save-data`, data);
};

export const updateWarning = async (id, data) => {
  return await httpClient.post(`/warning/save-data/${id}`, data);
};

export const deleteWarning = async (id) => {
  return await httpClient.delete(`/warning/${id}`);
};

export const getWarningById = async (id) => {
  return await httpClient.get(`/warning/${id}`);
};

// =================== Web Setting ประกาศเขตโรคระบาด
export const getAnnouceEpidemicZone = async (params) => {
  return await httpClient.get(`/annouce`, { params: params });
};

//=================== Web Setting NewsCategories

export const saveNewsCategories = async (data) => {
  return await httpClient.post(`/website/news-categories`, data);
};

export const getNewsCategories = async (
  id = "",
  search = "",
  statusId = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/website/news-categories?&id=${id}&search=${search}&statusId=${statusId}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const updateNewsCategories = async (id, data) => {
  return await httpClient.put(`/website/news-categories/${id}`, data);
};

export const deleteNewsCategories = async (id) => {
  return await httpClient.delete(`/website/news-categories/${id}`);
};
export const getNewsCategoriesById = async (id) => {
  return await httpClient.get(`/website/news-categories/${id}`);
};

export const getMenu = async (
  id = "",
  tambonId = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  moduleName = ""
) => {
  return await httpClient.get(
    `/master-data/menu?&id=${id}&tambonId=${tambonId}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}&moduleName=${moduleName}`
  );
};

export const getMenuById = async (id) => {
  return await httpClient.get(`/master-data/menu/${id}`);
};

export const saveMenu = async (data) => {
  return await httpClient.post(`/master-data/menu/`, data);
};

export const deleteMenu = async (id) => {
  return await httpClient.delete(`/master-data/menu/${id}`);
};

export const updateMenu = async (id, data) => {
  return await httpClient.put(`/master-data/menu/${id}`, data);
};
//=================== Web Setting contacts

export const saveContact = async (data) => {
  return await httpClient.post(`/website/contacts`, data);
};

export const getContact = async (
  dateBegin = "",
  dateEnd = "",
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/website/contacts?dateBegin=${dateBegin}&dateEnd=${dateEnd}&id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const updateContact = async (id, data) => {
  return await httpClient.put(`/website/contacts/${id}`, data);
};

export const deleteContact = async (id) => {
  return await httpClient.delete(`/website/contacts/${id}`);
};

export const getNewsContactById = async (id) => {
  return await httpClient.get(`/website/contacts/${id}`);
};

//=================== Web Setting Doc Catagories
export const getDoc = async (
  docCatId = "",
  statusId = "",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/website/doc-categories/?&docCatId=${docCatId}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveDoc = async (data) => {
  return await httpClient.post(`/website/doc-categories`, data);
};

export const updateDoc = async (id, data) => {
  return await httpClient.put(`/website/doc-categories/${id}`, data);
};

export const deleteDoc = async (id) => {
  return await httpClient.delete(`/website/doc-categories/${id}`);
};

export const getDocById = async (id) => {
  return await httpClient.get(`/website/doc-categories/${id}`);
};

//=================== Web Setting System
export const getSystem = async (
  id = "",
  statusId = "",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/website/system/?&id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveSystem = async (data) => {
  return await httpClient.post(`/website/system`, data);
};

export const updateSystem = async (id, data) => {
  return await httpClient.put(`/website/system/${id}`, data);
};

export const deleteSystem = async (id) => {
  return await httpClient.delete(`/website/system/${id}`);
};

export const getSystemById = async (id) => {
  return await httpClient.get(`/website/system/${id}`);
};

//=================== Master Data Animal Purpose
export const saveAnimalPurpose = async (data) => {
  return await httpClient.post(`/master-data/animal-purpose`, data);
};

export const getAnimalPurpose = async (
  statusId = "",
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/animal-purpose?statusId=${statusId}&id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const updateAnimalPurpose = async (id, data) => {
  return await httpClient.put(`/master-data/animal-purpose/${id}`, data);
};

export const deleteAnimalPurpose = async (id) => {
  return await httpClient.delete(`/master-data/animal-purpose/${id}`);
};
export const getAnimalPurposeById = async (id) => {
  return await httpClient.get(`/master-data/animal-purpose/${id}`);
};

//=================== Master Data Sample Purpose

export const saveSamplePurpose = async (data) => {
  return await httpClient.post(`/master-data/purpose-sample`, data);
};

export const getSamplePurpose = async (
  statusId = "",
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/purpose-sample?statusId=${statusId}&id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const updateSamplePurpose = async (id, data) => {
  return await httpClient.put(`/master-data/purpose-sample/${id}`, data);
};

export const deleteSamplePurpose = async (id) => {
  return await httpClient.delete(`/master-data/purpose-sample/${id}`);
};

export const getSamplePurposeById = async (id) => {
  return await httpClient.get(`/master-data/purpose-sample/${id}`);
};

//=================== Master Data Vaccine Type
export const saveVaccineType = async (data) => {
  return await httpClient.post(`/master-data/vaccine-type`, data);
};

export const updateVaccineType = async (id, data) => {
  return await httpClient.put(`/master-data/vaccine-type/${id}`, data);
};

export const deleteVaccineType = async (id) => {
  return await httpClient.delete(`/master-data/vaccine-type/${id}`);
};
export const getVaccineTypeById = async (id) => {
  return await httpClient.get(`/master-data/vaccine-type/${id}`);
};
//=================== Master Data Organize
export const getOrganizesForEdit = async (levelId) => {
  return await httpClient.get(
    `/master-data/organizes?id=&levelId=${levelId}&parentId=&statusId=&search=&sortBy=id&sortDir=ASC&page=1&limit=10000`
  );
};

export const saveOrganizes = async (data) => {
  return await httpClient.post(`/master-data/organizes`, data);
};

export const updateOrganizes = async (id, data) => {
  return await httpClient.put(`/master-data/organizes/${id}`, data);
};

export const deleteOrganizes = async (id) => {
  return await httpClient.delete(`/master-data/organizes/${id}`);
};
export const getOrganizesById = async (id) => {
  return await httpClient.get(`/master-data/organizes/${id}`);
};

export const getOrganizes = async (
  levelId = "",
  parentId = "",
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  provinceId = "",
  amphurId = "",
  tambonId = ""
) => {
  return await httpClient.get(
    `/master-data/organizes?levelId=${levelId}&parentId=${parentId}&id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}&provinceId=${provinceId}&amphurId=${amphurId}&tambonId=${tambonId}`
  );
};

//=================== Master Data Geogarphy

export const saveGeography = async (data) => {
  return await httpClient.post(`/master-data/geo`, data);
};

export const getGeography = async (
  id = "",
  statusId = "",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/master-data/geo?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const updateGeography = async (id, data) => {
  return await httpClient.put(`/master-data/geo/${id}`, data);
};

export const deleteGeography = async (id) => {
  return await httpClient.delete(`/master-data/geo/${id}`);
};

export const getGeographyById = async (id) => {
  return await httpClient.get(`/master-data/geo/${id}`);
};

//=================== Master Data ZoneLiveStock
export const getZoneLivestock = async (
  id = "",
  statusId = "",
  search = "",
  page = "1",
  limit = "100",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/zone?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveZoneLivestock = async (data) => {
  return await httpClient.post(`/master-data/zone`, data);
};

export const updateZoneLivestock = async (id, data) => {
  return await httpClient.put(`/master-data/zone/${id}`, data);
};

export const getZoneLivestockById = async (id) => {
  return await httpClient.get(`/master-data/zone/${id}`);
};

export const deleteZoneLivestock = async (id) => {
  return await httpClient.delete(`/master-data/zone/${id}`);
};

//=================== Master Data Annouce
export const getAnnouce = async (
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/annouce?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveAnnouce = async (data) => {
  return await httpClient.post(`/master-data/annouce`, data);
};

export const updateAnnouce = async (id, data) => {
  return await httpClient.put(`/master-data/annouce/${id}`, data);
};

export const getAnnouceById = async (id) => {
  return await httpClient.get(`/master-data/annouce/${id}`);
};

export const deleteAnnouce = async (id) => {
  return await httpClient.delete(`/master-data/annouce/${id}`);
};

//=================== Master Data Analyse
export const saveAnalyse = async (data) => {
  return await httpClient.post(`/master-data/analyse`, data);
};

export const getAnalyse = async (
  statusId = "",
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/analyse?statusId=${statusId}&id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const updateAnalyse = async (id, data) => {
  return await httpClient.put(`/master-data/analyse/${id}`, data);
};

export const deleteAnalyse = async (id) => {
  return await httpClient.delete(`/master-data/analyse/${id}`);
};
export const getAnalyseById = async (id) => {
  return await httpClient.get(`/master-data/analyse/${id}`);
};

//=================== Master Data Animal
export const saveAnimalType = async (data) => {
  return await httpClient.post(`/master-data/animal-type`, data);
};

export const updateAnimalType = async (id, data) => {
  return await httpClient.put(`/master-data/animal-type/${id}`, data);
};

export const getAnimaltypeById = async (id) => {
  return await httpClient.get(`/master-data/animal-type/${id}`);
};

export const getAnimaltype = async (id) => {
  return await httpClient.get(`/master-data/animal-type`);
};

export const deleteAnimaltype = async (id) => {
  return await httpClient.delete(`/master-data/animal-type/${id}`);
};

//=================== Master Data Disease
export const saveDiseaseType = async (data) => {
  return await httpClient.post(`/master-data/disease-type`, data);
};

export const updateDiseaseType = async (id, data) => {
  return await httpClient.put(`/master-data/disease-type/${id}`, data);
};

export const getDiseasetypeById = async (id) => {
  return await httpClient.get(`/master-data/disease-type/${id}`);
};

export const deleteDiseaseType = async (id) => {
  return await httpClient.delete(`/master-data/disease-type/${id}`);
};

//=================== Master Data TestMethod

export const getTestMethod = async (
  statusId = "",
  diseaseTypeIds = "",
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/test-method?statusId=${statusId}&diseaseTypeIds=${diseaseTypeIds}&id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveTestMethod = async (data) => {
  return await httpClient.post(`/master-data/test-method`, data);
};

export const updateTestMethod = async (id, data) => {
  return await httpClient.put(`/master-data/test-method/${id}`, data);
};

export const getTestMethodById = async (id) => {
  return await httpClient.get(`/master-data/test-method/${id}`);
};

export const deleteTestMethod = async (id) => {
  return await httpClient.delete(`/master-data/test-method/${id}`);
};

//=================== Master Data SampleType

export const getSampleType = async (
  statusId = "",
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/ps-sample?statusId=${statusId}&id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveSampleType = async (data) => {
  return await httpClient.post(`/master-data/ps-sample`, data);
};

export const updateSampleType = async (id, data) => {
  return await httpClient.put(`/master-data/ps-sample/${id}`, data);
};

export const getSampleTypeById = async (id) => {
  return await httpClient.get(`/master-data/ps-sample/${id}`);
};

export const deleteSampleType = async (id) => {
  return await httpClient.delete(`/master-data/ps-sample/${id}`);
};

//=================== Master Data Symptom

export const saveSymptom = async (data) => {
  return await httpClient.post(`/master-data/symptom`, data);
};

export const updateSymptom = async (id, data) => {
  return await httpClient.put(`/master-data/symptom/${id}`, data);
};

export const getSymptomById = async (id) => {
  return await httpClient.get(`/master-data/symptom/${id}`);
};

export const deleteSymptom = async (id) => {
  return await httpClient.delete(`/master-data/symptom/${id}`);
};

//=================== Master Data lab

export const getLab = async (
  id = "",
  statusId = "1",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/lab?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveLab = async (data) => {
  return await httpClient.post(`/master-data/lab`, data);
};

export const updateLab = async (id, data) => {
  return await httpClient.put(`/master-data/lab/${id}`, data);
};

export const getGroupLab = async (
  statusId = "",
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/lab-group?statusId=${statusId}&id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getLabById = async (id) => {
  return await httpClient.get(`/master-data/lab/${id}`);
};

export const deleteLab = async (id) => {
  return await httpClient.delete(`/master-data/lab/${id}`);
};

//=================== Master Data positions

export const getPositions = async (
  statusId = "",
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "orderIndex",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/positions?statusId=${statusId}&id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const savePositions = async (data) => {
  return await httpClient.post(`/master-data/positions`, data);
};

export const updatePositions = async (id, data) => {
  return await httpClient.put(`/master-data/positions/${id}`, data);
};

export const getPositionsById = async (id) => {
  return await httpClient.get(`/master-data/positions/${id}`);
};

export const deletePositions = async (id) => {
  return await httpClient.delete(`/master-data/positions/${id}`);
};

//=================== Master Data province

export const getProvince = async (
  zoneId = "",
  statusId = 1,
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "name",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/provinces?zoneId=${zoneId}&statusId=${statusId}&id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getProvinces = async (data) => {
  return await httpClient.get(`/master-data/provinces`, { params: data });
};

export const saveProvince = async (data) => {
  return await httpClient.post(`/master-data/provinces`, data);
};

export const updateProvince = async (id, data) => {
  return await httpClient.put(`/master-data/provinces/${id}`, data);
};

export const getProvinceById = async (id) => {
  return await httpClient.get(`/master-data/provinces/${id}`);
};

export const deleteProvince = async (id) => {
  return await httpClient.delete(`/master-data/provinces/${id}`);
};

//=================== Master Data District

export const getDistricts = async (
  id = "",
  provinceId = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "name",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/amphurs?id=${id}&provinceId=${provinceId}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveDistricts = async (data) => {
  return await httpClient.post(`/master-data/amphurs`, data);
};

export const updateDistricts = async (id, data) => {
  return await httpClient.put(`/master-data/amphurs/${id}`, data);
};

export const getDistrictsById = async (id) => {
  return await httpClient.get(`/master-data/amphurs/${id}`);
};

export const deleteDistricts = async (id) => {
  return await httpClient.delete(`/master-data/amphurs/${id}`);
};

//=================== Master Data Sub-District

export const getSubDistricts = async (
  id = "",
  amphurId = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "name",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/tambons?id=${id}&amphurId=${amphurId}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const saveSubDistricts = async (data) => {
  return await httpClient.post(`/master-data/tambons`, data);
};

export const updateSubDistricts = async (id, data) => {
  return await httpClient.put(`/master-data/tambons/${id}`, data);
};

export const getSubDistrictsById = async (id) => {
  return await httpClient.get(`/master-data/tambons/${id}`);
};

export const deleteSubDistricts = async (id) => {
  return await httpClient.delete(`/master-data/tambons/${id}`);
};

//=================== Master Data Title

export const saveTitle = async (data) => {
  return await httpClient.post(`/master-data/title`, data);
};

export const updateTitle = async (id, data) => {
  return await httpClient.put(`/master-data/title/${id}`, data);
};

export const getTitleById = async (id) => {
  return await httpClient.get(`/master-data/title/${id}`);
};

export const deleteTitle = async (id) => {
  return await httpClient.delete(`/master-data/title/${id}`);
};

//=================== Master Data Sample Send

export const saveSampleSend = async (data) => {
  return await httpClient.post(`/master-data/sample-send`, data);
};

export const getSampleSend = async (
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/sample-send?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const updateSampleSend = async (id, data) => {
  return await httpClient.put(`/master-data/sample-send/${id}`, data);
};

export const getSampleSendById = async (id) => {
  return await httpClient.get(`/master-data/sample-send/${id}`);
};

export const deleteSampleSend = async (id) => {
  return await httpClient.delete(`/master-data/sample-send/${id}`);
};

//=================== Master Data DLAS
export const saveDLAS = async (data) => {
  return await httpClient.post(`/master-data/dlas`, data);
};

export const getDLAS = async (
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/dlas?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const updateDLAS = async (id, data) => {
  return await httpClient.put(`/master-data/dlas/${id}`, data);
};

export const getDLASById = async (id) => {
  return await httpClient.get(`/master-data/dlas/${id}`);
};

export const deleteDLAS = async (id) => {
  return await httpClient.delete(`/master-data/dlas/${id}`);
};

//=================== Master Disease Type

export const getDisease = async (
  id = "",
  statusId = "1",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/risk?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getGroupData = async (
  id = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/ev-group?id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};
//=================== Master Data Risk
export const saveRisk = async (data) => {
  return await httpClient.post(`/master-data/risk`, data);
};
export const getRisk = async (
  statusId = "",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/master-data/risk?statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const updateRisk = async (id, data) => {
  return await httpClient.put(`/master-data/risk/${id}`, data);
};

export const getRiskById = async (id) => {
  return await httpClient.get(`/master-data/risk/${id}`);
};

export const deleteRisk = async (id) => {
  return await httpClient.delete(`/master-data/risk/${id}`);
};

//=================== การแจ้งโรค
export const saveReportDisease = async (formData) => {
  return await httpClient.post(`/inform-cms/add`, formData);
};

export const getReportDisease = async (params) => {
  return await httpClient.get(`/informs/cms`, { params: params });
};

export const getListReportDisease = async (
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100",
  infCode = "",
  person = "",
  foundDateBegin = "",
  foundDateEnd = "",
  createDateBegin = "",
  createDateEnd = "",
  provinceId = "",
  amphurId = "",
  tambonId = "",
  animalTypeIds = "",
  statusId = ""
) => {
  return await httpClient.get(
    `/informs/cms?sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}&infCode=${infCode}&person=${person}&foundDateBegin=${foundDateBegin}&foundDateEnd=${foundDateEnd}&createDateBegin=${createDateBegin}&createDateEnd=${createDateEnd}&provinceId=${provinceId}&amphurId=${amphurId}&tambonId=${tambonId}&animalTypeIds=${animalTypeIds}&statusId=${statusId}`
  );
};

export const updateReportDisease = async (id, formData) => {
  return await httpClient.post(`/inform-cms/update/${id}`, formData);
};

export const getReportDiseaseById = async (id) => {
  return await httpClient.get(`/informs/${id}`);
};

export const deleteReportDisease = async (id) => {
  return await httpClient.delete(`/inform-cms/delete/${id}`);
};

//=================== ขึ้นทะเบียนฟาร์ม
export const getFarmRegistration = async (params) => {
  return await httpClient.get(`/farm/list`, { params: params });
};

export const addFarmRegistration = async (data) => {
  return await httpClient.post(`/farm/add`, data);
};

export const updateFarmRegistration = async (id, data) => {
  return await httpClient.put(`/farm/${id}`, data);
};

export const getFarmRegistrationById = async (id) => {
  return await httpClient.get(`/farm/${id}`);
};

export const deleteFarmRegistration = async (id) => {
  return await httpClient.delete(`/farm/${id}`);
};

export const getAnimalFarmById = async (params) => {
  return await httpClient.get(`/farm/animal-list`, { params: params });
};

export const getFarmReport = async (params) => {
  return await httpClient.get(`/farm/report/list`, { params: params });
};

export const postFarmAddAnimal = async (data) => {
  return await httpClient.post(`/farm/add-animal`, data);
};

export const postFarmEditAnimal = async (data) => {
  return await httpClient.post(`/farm/edit-animal`, data);
};

export const getFarmReportFile = async (
  updateDateBegin = "",
  updateDateEnd = "",
  zoneId = "",
  provinceId = "",
  amphurId = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/farm/report/list?updateDateBegin=${updateDateBegin}&updateDateEnd=${updateDateEnd}&zoneId=${zoneId}&provinceId=${provinceId}&amphurId=${amphurId}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getDownloadFarmReg = async () => {
  return await httpClient.get(`/farm-import/template`);
};

export const getUploadFarmReg = async (data) => {
  return await httpClient.post(`/farm-import/import`, data);
};

//=================== OutBreak
export const getOutbreak = async (params) => {
  return await httpClient.get(`/outbreak/list-page`, { params: params });
};

export const getOutbreakObCode = async (params) => {
  return await httpClient.get(`/outbreak/obCode`, { params: params });
};

export const addNewOutBreak = async (data) => {
  return await httpClient.post(`/outbreak/save`, data);
};

export const getOutbreakById = async (id) => {
  return await httpClient.get(`/outbreak/${id}`);
};

export const getCopyOutbreakById = async (id) => {
  return await httpClient.get(`/outbreak/copy/${id}`);
};

export const deleteOutbreak = async (id) => {
  return await httpClient.delete(`/outbreak/${id}`);
};

export const getOutbreakReport = async (params) => {
  return await httpClient.get(`/outbreak-report`, { params: params });
};

//=================== รายงานโรค
export const getDiseaseStatement = async (params) => {
  return await httpClient.get(`/fm01`, { params: params });
};

export const getDiseaseStatementById = async (id) => {
  return await httpClient.get(`/fm01/${id}`);
};

export const getCopyDiseaseStatementById = async (id) => {
  return await httpClient.get(`/fm01/copy/${id}`);
};

export const addDiseaseStatement = async (data) => {
  return await httpClient.post(`/fm01/save-data`, data);
};

export const deleteDiseaseStatement = async (id) => {
  return await httpClient.delete(`/fm01/${id}`);
};

export const updateStopDate = async (id, data) => {
  return await httpClient.put(`/fm01/${id}/stop-date`, data);
};

//=================== ควบคุมโรค
export const getDiseaseControl = async (params) => {
  return await httpClient.get(`/fm03`, { params: params });
};

export const getDiseaseControlById = async (id) => {
  return await httpClient.get(`/fm03/${id}`);
};

export const addDiseaseControl = async (data) => {
  return await httpClient.post(`/fm03/save-data`, data);
};

export const deleteDiseaseControl = async (id) => {
  return await httpClient.delete(`/fm03/${id}`);
};

export const getCopyDiseaseControlById = async (id) => {
  return await httpClient.get(`/fm03/copy/${id}`);
};

//=================== สอบสวนโรค
export const getDiseaseInvestigation = async (params) => {
  return await httpClient.get(`/fm02`, { params: params });
};
export const deleteDiseaseInvestigation = async (id) => {
  return await httpClient.delete(`/fm02/${id}`);
};

export const addDiseaseInvestigation = async (data) => {
  return await httpClient.post(`/fm02/save-data`, data);
};

export const getDiseaseInvestigationById = async (id) => {
  return await httpClient.get(`/fm02/${id}`);
};

//=================== ฉีดวัคซีน
export const getVaccinate = async (params) => {
  return await httpClient.get(`/fm05`, { params: params });
};
export const deleteVaccinate = async (id) => {
  return await httpClient.delete(`/fm05/${id}`);
};

export const addVaccinate = async (data) => {
  return await httpClient.post(`/fm05/save-data`, data);
};

export const getVaccinateById = async (id) => {
  return await httpClient.get(`/fm05/${id}`);
};

export const getCopyVaccinateById = async (id) => {
  return await httpClient.get(`/fm05/copy/${id}`);
};

//=================== ประการเขตโรคระบาด
export const getEpidemicZone = async (params) => {
  return await httpClient.get(`/annouce`, { params: params });
};

export const deleteEpidemicZone = async (id) => {
  return await httpClient.delete(`/annouce/${id}`);
};

export const addEpidemicZone = async (data) => {
  return await httpClient.post(`/annouce/save-data`, data);
};

export const getEpidemicById = async (id) => {
  return await httpClient.get(`/annouce/${id}`);
};

//=================== รายงาน ประการเขตโรคระบาด

export const getZoneReportFarmAll = async (params) => {
  return await httpClient.get(`/zone-report/farm-location`, { params: params });
};

export const getZoneReportFarmBuffer = async (data) => {
  return await httpClient.post(`/zone-report/farm-buffer`, data);
};

export const getZoneReportFarm = async (params) => {
  return await httpClient.get(`/zone-report/farm`, { params: params });
};

export const getZoneReportDisease = async (params) => {
  return await httpClient.get(`/zone-report/disease`, { params: params });
};

export const getZoneReportFarmRisk = async (params) => {
  return await httpClient.get(`/zone-report/risk`, { params: params });
};

export const getZoneReportRiskArea = async (params) => {
  return await httpClient.get(`/zone-report/area-score`, { params: params });
};

//=================== ตัวอย่างใน Outbreak
export const getSampleOutbreak = async (params) => {
  return await httpClient.get(`/sample`, { params: params });
};
export const deleteSampleOutbreak = async (id) => {
  return await httpClient.delete(`/sample/${id}`);
};

//=================== เก็บตัวอย่าง
export const getSample = async (params) => {
  return await httpClient.get(`/sample`, { params: params });
};
export const deleteSample = async (id) => {
  return await httpClient.delete(`/sample/${id}`);
};

export const addSample = async (data) => {
  return await httpClient.post(`/sample`, data);
};

export const getSampleById = async (id) => {
  return await httpClient.get(`/sample/${id}`);
};

export const getCopySampleById = async (id) => {
  return await httpClient.get(`/sample/copy/${id}`);
};

export const updateSample = async (id, data) => {
  return await httpClient.put(`/sample/${id}`, data);
};

export const getBarnByLims = async (barnId, limsCaseId) => {
  return await httpClient.get(`/lims/${barnId}/${limsCaseId}`);
};

export const getAnimalByLims = async (limsCaseId) => {
  return await httpClient.get(`/lims/animal/${limsCaseId}`);
};

//=================== GFM Farm-Certificate
export const getFarmCertificate = async (
  id = "",
  statusId = "",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `//master-data/farm-certificate?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const deleteFarmCertificate = async (id) => {
  return await httpClient.delete(`/master-data/farm-certificate/${id}`);
};

export const saveFarmCertificate = async (data) => {
  return await httpClient.post(`/master-data/farm-certificate`, data);
};

export const getFarmCertificateById = async (id) => {
  return await httpClient.get(`/master-data/farm-certificate/${id}`);
};

export const updateFarmCertificate = async (id, data) => {
  return await httpClient.put(`/master-data/farm-certificate/${id}`, data);
};

//=================== GFM

//=================== GFM GFM-Report
export const getGFMReport = async (
  dateBegin = "",
  dateEnd = "",
  zoneId = "",
  provinceId = "",
  amphurId = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/gfm-report?dateBegin=${dateBegin}&dateEnd=${dateEnd}&zoneId=${zoneId}&provinceId=${provinceId}&amphurId=${amphurId}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const exportGFMReport = async (
  dateBegin = "",
  dateEnd = "",
  zoneId = "",
  provinceId = "",
  amphurId = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100",
  exportType = ""
) => {
  return await httpClient.get(
    `/gfm-report/export?dateBegin=${dateBegin}&dateEnd=${dateEnd}&zoneId=${zoneId}&provinceId=${provinceId}&amphurId=${amphurId}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}&exportType=${exportType}`
  );
};

//=================== เชิงรุก
export const getActionPlan = async (params) => {
  return await httpClient.get(`/as/plan`, { params: params });
};

export const deleteActionPlan = async (id) => {
  return await httpClient.delete(`/as/plan/${id}`);
};

export const addActionPlan = async (data) => {
  return await httpClient.post(`/as/plan`, data);
};

export const getActionPlanById = async (id) => {
  return await httpClient.get(`/as/plan/${id}`);
};

export const updateActionPlan = async (id, data) => {
  return await httpClient.put(`/as/plan/${id}`, data);
};

export const getActionPlanYear = async () => {
  return await httpClient.get(`/as/plan/year`);
};

export const getActionProject = async () => {
  return await httpClient.get(`/as/plan/project`);
};

//=================== GFM =========================
export const getFarmerType = async (
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/master-data/gfm-farmer-type?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getGFM = async (
  farmName = "",
  farmerName = "",
  certificateCode = "",
  startDateBegin = "",
  startDateEnd = "",
  statusId = "",
  endDateBegin = "",
  endDateEnd = "",
  gfmFarmerTypeId = "",
  gfmFarmerCateTypeId = "",
  provinceId = "",
  amphurId = "",
  tambonId = "",
  farmerType = "",
  page = "1",
  limit = "100",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/gfm/?farmName=${farmName}&farmerName=${farmerName}&farmerType=${farmerType}&certificateCode=${certificateCode}&startDateBegin=${startDateBegin}&startDateEnd=${startDateEnd}&statusId=${statusId}&endDateBegin=${endDateBegin}&endDateEnd=${endDateEnd}&gfmFarmerTypeId=${gfmFarmerTypeId}&gfmFarmerCateTypeId=${gfmFarmerCateTypeId}&provinceId=${provinceId}&amphurId=${amphurId}&tambonId=${tambonId}&page=${page}&limit=${limit}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};

export const saveGFM = async (data) => {
  return await httpClient.post(`/gfm`, data);
};

export const updateGFM = async (id, data) => {
  return await httpClient.put(`/gfm/${id}`, data);
};

export const deleteGFM = async (id) => {
  return await httpClient.delete(`/gfm/${id}`);
};

export const getGFMById = async (id) => {
  return await httpClient.get(`/gfm/${id}`);
};

export const getGfmTemplate = async () => {
  return await httpClient.get(`/gfm/template`);
};

export const importGfmTemplate = async (data) => {
  return await httpClient.post(`/gfm/import`, data);
};

export const checkDuplicateGfm = async (id) => {
  return await httpClient.get(`/gfm/check-duplicate-cert/${id}`);
};

//=================== GFM Farmer Type
export const getGFMFarmerType = async (
  id = "",
  animalId = "",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/master-data/gfm-farmer-type?id=${id}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}&animalId=${animalId}`
  );
};
export const deleteGFMFarmerType = async (id) => {
  return await httpClient.delete(`/master-data/gfm-farmer-type/${id}`);
};

export const saveGFMFarmerType = async (data) => {
  return await httpClient.post(`/master-data/gfm-farmer-type`, data);
};

export const getGFMFarmerTypeById = async (id) => {
  return await httpClient.get(`/master-data/gfm-farmer-type/${id}`);
};

export const updateGFMFarmerType = async (id, data) => {
  return await httpClient.put(`/master-data/gfm-farmer-type/${id}`, data);
};
//=================== ประเมินความเสี่ยง
export const getAssessRiskResult = async (params) => {
  return await httpClient.get(`/risk/result/cms`, { params: params });
};

export const saveAssessRiskResult = async (data) => {
  return await httpClient.post(`/risk/result`, data);
};

export const updateAssessRiskResult = async (id, data) => {
  return await httpClient.put(`/risk/result/${id}`, data);
};

export const getAssessRiskResultById = async (id) => {
  return await httpClient.get(`/risk/result/${id}`);
};

export const getRiskLevelById = async (id) => {
  return await httpClient.get(`/risk/result/level/${id}`);
};

export const getUploadScoreById = async (id, data) => {
  return await httpClient.post(`/risk/result/${id}/upload`, data);
};

export const deleteAssessRiskResult = async (id) => {
  return await httpClient.delete(`/risk/result/${id}`);
};

export const getQuestById = async (id) => {
  return await httpClient.get(`/risk/result/quest/${id}`);
};

export const getAssessRiskKit = async (
  id = "",
  statusId = "",
  page = "1",
  limit = "100",
  search = "",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/risk/ev-form?id=${id}&statusId=${statusId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getAssessRiskKitReport = async (
  formId = "",
  dateBegin = "",
  dateEnd = "",
  zoneId = "",
  provinceId = "",
  amphurId = "",
  tambonId = "",
  sortBy = "id",
  mooId = "",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/risk/result/cms?formId=${formId}&dateBegin=${dateBegin}&dateEnd=${dateEnd}&zoneId=${zoneId}&provinceId=${provinceId}&amphurId=${amphurId}&tambonId=${tambonId}&sortBy=${sortBy}&mooId=${mooId}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

// =================== ประเมินความเสี่ยง
export const getDiseaseList = async (params) => {
  return await httpClient.get(`/risk/ev-form`, { params: params });
};

export const addDiseaseSave = async (data) => {
  return await httpClient.post(`/risk/ev-form`, data);
};

export const getAssessById = async (id) => {
  return await httpClient.get(`/risk/ev-form/${id}`);
};

export const deleteAssess = async (id) => {
  return await httpClient.delete(`/risk/ev-form/${id}`);
};

export const getDownloadScore = async () => {
  return await httpClient.get(`/risk/template`);
};

export const updateAssess = async (id, data) => {
  return await httpClient.put(`/risk/ev-form/${id}`, data);
};

export const getUploadScore = async (data) => {
  return await httpClient.post(`/risk/import`, data);
};

// แบบประเมินความเสี่ยง
export const getRiskAssessment = async (params) => {
  return await httpClient.get(`/risk/quest`, { params: params });
};

export const getAssessmentById = async (id) => {
  return await httpClient.get(`/risk/quest/${id}`);
};

export const deleteAssessment = async (id) => {
  return await httpClient.delete(`/risk/quest/${id}`);
};

export const addRiskAssessment = async (data) => {
  return await httpClient.post(`/risk/quest`, data);
};

export const updateRiskAssessment = async (id, data) => {
  return await httpClient.put(`/risk/quest/${id}`, data);
};

export const getCopyDiseaseInvest = async (id, data) => {
  return await httpClient.get(`/fm02/copy/${id}`, data);
};
//=================================รายงานเชิงรุก

export const getRecordResultReport = async (
  dateBegin = "",
  dateEnd = "",
  zoneId = "",
  provinceId = "",
  amphurId = "",
  year = "",
  projectName = "",
  animalTypeId = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/as/dtl-report?dateBegin=${dateBegin}&dateEnd=${dateEnd}&zoneId=${zoneId}&provinceId=${provinceId}&amphurId=${amphurId}&year=${year}&projectName=${projectName}&animalTypeId=${animalTypeId}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getDiseaseStatementReport = async (
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100",
  zoneId = "",
  statusId = "",
  provinceId = "",
  amphurId = "",
  tambonId = "",
  diseaseTypeId = "",
  foundDiseaseTypeId = "",
  orgId = "",
  obCode = "",
  farmId = "",
  ownerName = "",
  foundDateBegin = "",
  foundDateEnd = "",
  createDateBegin = "",
  createDateEnd = "",
  animalTypeId = ""
) => {
  return await httpClient.get(
    `/fm01-report?orgId=${orgId}&obCode=${obCode}&farmId=${farmId}&ownerName=${ownerName}&foundDateBegin=${foundDateBegin}&foundDateEnd=${foundDateEnd}&createDateBegin=${createDateBegin}&createDateEnd=${createDateEnd}&zoneId=${zoneId}&statusId=${statusId}&provinceId=${provinceId}&amphurId=${amphurId}&tambonId=${tambonId}&diseaseTypeId=${diseaseTypeId}&foundDiseaseTypeId=${foundDiseaseTypeId}&animalTypeId=${animalTypeId}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getDiseaseControlReport = async (
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100",
  zoneId = "",
  statusId = "",
  provinceId = "",
  amphurId = "",
  tambonId = "",
  diseaseTypeId = "",
  foundDiseaseTypeId = "",
  orgId = "",
  obCode = "",
  farmId = "",
  ownerName = "",
  foundDateBegin = "",
  foundDateEnd = "",
  createDateBegin = "",
  createDateEnd = "",
  animalTypeId = "",
  caseCode = ""
) => {
  return await httpClient.get(
    `/fm03-report?orgId=${orgId}&obCode=${obCode}&caseCode=${caseCode}&farmId=${farmId}&ownerName=${ownerName}&foundDateBegin=${foundDateBegin}&foundDateEnd=${foundDateEnd}&createDateBegin=${createDateBegin}&createDateEnd=${createDateEnd}&zoneId=${zoneId}&statusId=${statusId}&provinceId=${provinceId}&amphurId=${amphurId}&tambonId=${tambonId}&diseaseTypeId=${diseaseTypeId}&foundDiseaseTypeId=${foundDiseaseTypeId}&animalTypeId=${animalTypeId}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};
//=======================บันทึกข้อมูล

export const getSaveProject = async (params) => {
  return await httpClient.get(`/as/dtl`, { params: params });
};
export const deleteSaveProject = async (id) => {
  return await httpClient.delete(`/as/dtl/${id}`);
};

export const savekeepSample = async (data) => {
  return await httpClient.post(`/sample/result`, data);
};

export const getkeepSampleById = async (id, sampleId) => {
  return await httpClient.get(`/sample/result/${id}/${sampleId}`);
};

export const updatekeepSample = async (asResultid, id, data) => {
  return await httpClient.put(`/sample/result/${asResultid}/${id}`, data);
};

export const deleteKeepSample = async (id, sampleId) => {
  return await httpClient.delete(`/sample/result/${id}/${sampleId}`);
};

// Get By Id ข้อมูลโครงการ
export const getDTLById = async (id) => {
  return await httpClient.get(`/as/dtl/${id}`);
};

export const getSampleTemplate = async () => {
  return await httpClient.get(`/sample-import/template`);
};

export const importSampleTemplate = async (data) => {
  return await httpClient.post(`/sample-import/import`, data);
};

//รายงานการเข้าใช้งาน งายงานผลการทดสอบ  รายงานระดับภูมิคุ้มกัน  รานงานความชุกตามชนิดสัตว์และพื้นที

export const getUserAccess = async (
  dateBegin = "",
  dateEnd = "",
  zoneId = "",
  provinceId = "",
  amphurId = "",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/user-logs/login?dateBegin=${dateBegin}&dateEnd=${dateEnd}&zoneId=${zoneId}&provinceId=${provinceId}&amphurId=${amphurId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getUserTransaction = async (
  dateBegin = "",
  dateEnd = "",
  zoneId = "",
  provinceId = "",
  amphurId = "",
  search = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/user-logs/transaction?dateBegin=${dateBegin}&dateEnd=${dateEnd}&zoneId=${zoneId}&provinceId=${provinceId}&amphurId=${amphurId}&search=${search}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getTestResultsReport = async (
  dateBegin = "",
  dateEnd = "",
  zoneId = "",
  provinceId = "",
  amphurId = "",
  year = "",
  projectName = "",
  animalTypeId = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/as/sample-report?dateBegin=${dateBegin}&dateEnd=${dateEnd}&zoneId=${zoneId}&provinceId=${provinceId}&amphurId=${amphurId}&year=${year}&projectName=${projectName}&animalTypeId=${animalTypeId}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getImmuneLevelReport = async (
  dateBegin = "",
  dateEnd = "",
  zoneId = "",
  provinceId = "",
  amphurId = "",
  year = "",
  projectName = "",
  animalTypeId = "",
  diseaseTypeId = "",
  sendSampleMethodId = "",
  immType = "O",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/as/imm-report?dateBegin=${dateBegin}&dateEnd=${dateEnd}&zoneId=${zoneId}&provinceId=${provinceId}&amphurId=${amphurId}&year=${year}&projectName=${projectName}&animalTypeId=${animalTypeId}&diseaseTypeId=${diseaseTypeId}&sendSampleMethodId=${sendSampleMethodId}&immType=${immType}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getPrevalenceLevelReport = async (
  dateBegin = "",
  dateEnd = "",
  zoneId = "",
  provinceId = "",
  amphurId = "",
  year = "",
  projectName = "",
  animalTypeId = "",
  diseaseTypeId = "",
  sendSampleMethodId = "",
  sortBy = "id",
  sortDir = "ASC",
  page = "1",
  limit = "100"
) => {
  return await httpClient.get(
    `/as/zone-report?dateBegin=${dateBegin}&dateEnd=${dateEnd}&zoneId=${zoneId}&provinceId=${provinceId}&amphurId=${amphurId}&year=${year}&projectName=${projectName}&animalTypeId=${animalTypeId}&diseaseTypeId=${diseaseTypeId}&sendSampleMethodId=${sendSampleMethodId}&sortBy=${sortBy}&sortDir=${sortDir}&page=${page}&limit=${limit}`
  );
};

export const getDailyReport = async (
  dateBegin = "",
  dateEnd = "",
  diseaseTypeId = ""
) => {
  return await httpClient.get(
    `/daily-report?dateBegin=${dateBegin}&dateEnd=${dateEnd}&diseaseTypeId=${diseaseTypeId}`
  );
};

export const getZeroReport = async (
  zoneId = "",
  provinceId = "",
  dateBegin = "",
  dateEnd = "",
  diseaseTypeId = "",
  page = "1",
  limit = "100",
  sortBy = "id",
  sortDir = "ASC"
) => {
  return await httpClient.get(
    `/zeroreport/?zoneId=${zoneId}&provinceId=${provinceId}&dateBegin=${dateBegin}&dateEnd=${dateEnd}&diseaseTypeId=${diseaseTypeId}&page=${page}&limit=${limit}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};

export const addZeroReport = async (formData) => {
  return await httpClient.post(`/zeroreport/add`, formData);
};

export const updateZeroReport = async (formData) => {
  return await httpClient.put(`/zeroreport/update`, formData);
};

export const deleteZeroReport = async (id) => {
  return await httpClient.delete(`/zeroreport/delete/${id}`);
};

export const getZeroReportDetail = async (id) => {
  return await httpClient.get(`/zeroreport/${id}`);
};
