import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as helper from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Row, Col, Table } from "react-bootstrap";
import InputText from "../InputText";
import InputSelect from "../InputSelect";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import * as API from "../../utils/apis";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

function ModalDiseaseType({ show, close, save, mode, idEdit, listDisease }) {
  const user = useSelector((state) => state.login.result);
  const initialForm = {
    disease_name: "",
    disease_id: "",
    disease_detail: "",
    disease_code: "",
    status: "",
    createDate: "",
    updateDate: "",
    checkShow: false,

    data_animaltype: [],
    checkAnimalType: false,
    checkEdit: false,
    indexNew: "",
  };
  const initialAnimalType = {
    animalTypeId: "",
    animalTypeName: "",
  };
  const initialFormJson = {
    animal: {},
  };
  const initInvalidMsg = {
    disease_name: "",
    disease_detail: "",
    disease_code: "",
    status: "",
    animalTypeId: "",
  };
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const selectAnimalList = [];

  const dispatch = useDispatch();
  const history = useHistory();

  const [form, setForm] = useState(initialForm);
  const [formAnimal, setformAnimal] = useState(initialAnimalType);
  const [formJson, setFormJson] = useState(initialFormJson);
  const [invalid, setInvalid] = useState(initInvalidMsg);
  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState(selectAnimalList);
  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const [statusList, setStatusList] = useState(selectStatusList);
  const [statusZeroReportSelectValue, setStatusZeroReportSelectValue] = useState([]);

  useEffect(async () => {
    if (mode === "add") {
      setForm(initialForm);
      setStatusSelectValue([]);
      setAnimalSelectValue([]);
    }
  }, [mode]);

  useEffect(async () => {
    if (mode === "edit" && !form.checkShow) {
      await getDiseasetypeById(idEdit);
    }
  }, [
    mode,
    idEdit,
    show,
    form.disease_name,
    form.disease_detail,
    form.disease_code,
    form.status,
  ]);

  useEffect(async () => {
    await getMasterJson();
    await getAnimal();
  }, []);

  const toAddAnimalType = () => {
    setForm({ ...form, checkAnimalType: true });
    setAnimalSelectValue([]);
    setformAnimal({
      ...formAnimal,
      animalTypeId: "",
      animalTypeName: "",
    });
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = (type, item = {}) => {
    let validated = true;
    if (type === "checkDuplicate" || type === "checkAll") {
      //checkDup
      let groupData = form.data_animaltype;
      if (groupData?.length > 0) {
        const observer = groupData?.find(
          (element) =>
            item?.animalTypeId !== "" &&
            element.animalTypeId === item.animalTypeId
        );
        if (observer?.animalTypeId && !observer?.checkEdit) {
          addInvalid("animalTypeId", "รายการสัตว์ซ้ำ กรุณาเลือกสัตว์ใหม่");
          validated = false;
        } else {
          validated = true;
        }
      }
    }

    if (type === "checkAll") {
      if (form.disease_name === "") {
        addInvalid("disease_name", "โปรดระบุชื่อโรค");
        validated = false;
      }
      if (form.disease_code === "") {
        addInvalid("disease_code", "โปรดระบุุรหัส");
        validated = false;
      }
      if (form.status === "") {
        addInvalid("status", "กรุณาเลือกสถานะ");
        validated = false;
      }
      // if (form.disease_detail === "") {
      //   addInvalid("disease_detail", "โปรดระบุรายละเอียดของโรค");
      //   validated = false;
      // }
    }

    return validated;
  };

  const handleClose = () => {
    close();
    setInvalid(initInvalidMsg);
    setForm(initialForm);
    setStatusSelectValue([]);
  };

  const toConfirm = async () => {
    if (listDisease?.length > 0 && mode === "add") {
      const checkNameDisease = listDisease.some(
        (i) => i.name == form.disease_name
      );
      if (checkNameDisease) {
        MySwal.fire({
          text: `ชื่อโรคซ้ำ กรุณากรอกใหม่อีกครั้ง!!`,
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
        return;
      }
    }
    if (validate("checkAll")) {
      await save({ disease: form, mode: mode });
      handleClose();
    }
  };

  const getMasterJson = async () => {
    const params = ["animalType"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status === 200) {
        setFormJson({
          ...formJson,
          animal: data.animalType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getDiseasetypeById = async (id) => {
    let dataDis;
    try {
      const response = await API.getDiseasetypeById(id);
      const data = await response.data;
      if (response.status === 200) {
        dataDis = data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataDis.statusId) {
      let statusSelect = await statusList.find(
        (item) => item.value === dataDis.statusId
      );
      setStatusSelectValue(statusSelect);
    }

    if (dataDis.isZeroReport) {
      let statusSelect = await statusList.find(
        (item) => item.value === dataDis.isZeroReport
      );
      setStatusZeroReportSelectValue(statusSelect);
    }

    const animalArray = [];
    if (dataDis.animalIds && dataDis.animalIds.length > 0) {
      dataDis.animalIds.map(async (item, i) => {
        animalArray.push({
          animalTypeName: formJson.animal[item],
          animalTypeId: item,
        });
      });
    }

    setForm({
      ...form,
      disease_name: dataDis.name,
      disease_id: dataDis.id,
      disease_code: dataDis.code,
      disease_detail: dataDis.description,
      status: dataDis.statusId,
      isZeroReport: dataDis.isZeroReport,
      createDate: dataDis.createDate,
      updateDate: dataDis.updateDate,
      data_animaltype: animalArray,
    });
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const delAnima = (index) => {
    form.data_animaltype.splice(index, 1);
    setForm({ ...form, data_animaltype: form.data_animaltype });
  };

  const animalEdit = async (item, index) => {
    item.checkEdit = true;
    setForm({ ...form });
    setformAnimal({
      ...formAnimal,
      animalTypeId: item.animalTypeId,
      animalTypeName: item.animalTypeName,
    });
    setAnimalSelectValue([
      { value: item.animalTypeId, label: item.animalTypeName },
    ]);
  };

  const animalSave = (item, type, index) => {
    let arrayAnimal = [...form.data_animaltype];
    let checkPass = validate("checkDuplicate", item);

    if (checkPass) {
      if (type === "add") {
        arrayAnimal.push({
          checkEdit: false,
          animalTypeId: formAnimal.animalTypeId,
          animalTypeName: formAnimal.animalTypeName,
        });

        setForm({
          ...form,
          data_animaltype: arrayAnimal,
          checkAnimalType: false,
        });
      } else if (type === "edit") {
        item.checkEdit = false;
        item.animalTypeId = formAnimal.animalTypeId;
        item.animalTypeName = formAnimal.animalTypeName;

        const animaltypeNew = [];
        if (form?.data_animaltype?.length > 0) {
          form.data_animaltype.map((data, idx) => {
            if (index === idx) {
              animaltypeNew.push(item);
            } else {
              animaltypeNew.push(data);
            }
          });
        }
        setForm({
          ...form,
          checkAnimalType: false,
          data_animaltype: animaltypeNew,
        });
      }
    }
  };

  const renderTableAnimalType = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td className="fitwidth">
          {item.checkEdit ? (
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="animalTypeName"
              classLabel="normal"
              selectValue={animalSelectValue}
              optionsList={animalList}
              handleSelectValue={(value) => {
                setAnimalSelectValue(value);
              }}
              handleChange={(value) =>
                setformAnimal({
                  ...formAnimal,
                  animalTypeName: value.label,
                  animalTypeId: value.value,
                })
              }
              handleInvalid={() => removeInvalid("animalTypeId")}
              invalid={invalid.animalTypeId}
            />
          ) : item.animalTypeName ? (
            item.animalTypeName
          ) : (
            "-"
          )}
        </td>

        <td className="text-center">
          {item.checkEdit ? (
            <button
              disabled={item?.animalTypeId ? false : true}
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                animalSave(formAnimal, "edit", index);
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          ) : (
            <>
              <button
                className="btn btn-add mr-2"
                onClick={() => animalEdit(item, index)}
              >
                <img
                  className="img-fluid icon-btn"
                  src="/svg/btn-edit.svg"
                  alt="edit"
                />
              </button>
              <button className="btn btn-del " onClick={() => delAnima(index)}>
                <img
                  className="img-fluid icon-btn "
                  src="/svg/btn-del.svg"
                  alt="del"
                />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  const renderAnimalType = () => {
    return (
      <>
        <tr>
          <td>{form.data_animaltype.length + 1}</td>
          <td className="fitwidth">
            <InputSelect
              classFormGroup="mb-0 w-100"
              star={false}
              placeholder="กรุณาเลือก"
              idName="animalTypeName"
              classLabel="normal"
              selectValue={animalSelectValue}
              optionsList={animalList}
              handleSelectValue={(value) => {
                setAnimalSelectValue(value);
              }}
              handleChange={(value) =>
                setformAnimal({
                  ...formAnimal,
                  animalTypeName: value.label,
                  animalTypeId: value.value,
                })
              }
              handleInvalid={() => removeInvalid("animalTypeId")}
              invalid={invalid.animalTypeId}
            />
          </td>
          <td className="text-center">
            <button
              disabled={formAnimal?.animalTypeId ? false : true}
              className="btn btn-success  pt-1 px-2"
              onClick={async () => {
                animalSave(formAnimal, "add");
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </td>
        </tr>
      </>
    );
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header closeButton className="pb-2 ">
        <Modal.Title className="mt-2">
          <h5>
            <strong>เพิ่มชนิดโรค</strong>
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <div className="">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item tab">
              <a
                className="nav-link active"
                id="data-tab"
                data-toggle="tab"
                href="#data"
                role="tab"
                aria-controls="data"
                aria-selected="true"
              >
                ข้อมูลพื้นฐาน
              </a>
            </li>
            <li
              className={
                invalid.status ? "nav-item-invalid tab" : "nav-item tab"
              }
            >
              <a
                className="nav-link"
                id="status-tab"
                data-toggle="tab"
                href="#status"
                role="tab"
                aria-controls="status"
                aria-selected="false"
              >
                สถานะ
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active card-tab"
              id="data"
              role="tabpanel"
              aria-labelledby="data-tab"
            >
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="ชื่อโรค"
                    idName="disease_name"
                    value={form.disease_name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, disease_name: value, checkShow: true })
                    }
                    handleInvalid={() => removeInvalid("disease_name")}
                    invalid={invalid.disease_name}
                  />
                </Col>
                <Col lg={4} md={6} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="รหัสโรค"
                    idName="disease_code"
                    value={form.disease_code}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({ ...form, disease_code: value, checkShow: true })
                    }
                    handleInvalid={() => removeInvalid("disease_code")}
                    invalid={invalid.disease_code}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={10} md={12} sm={12} xs={12}>
                  <InputText
                    type="text"
                    title="รายละเอียด"
                    idName="disease_id"
                    value={form.disease_detail}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    handleChange={(value) =>
                      setForm({
                        ...form,
                        disease_detail: value,
                        checkShow: true,
                      })
                    }
                    handleInvalid={() => removeInvalid("disease_detail")}
                    invalid={invalid.disease_detail}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end ">
                <button
                  className="btn btn-add text-white"
                  onClick={() => toAddAnimalType()}
                  disabled={form.checkAnimalType}
                >
                  <i className="fas fa-plus mr-1 "></i>เพิ่มชนิดสัตว์
                </button>
              </div>
              <div>
                <Table className="table table-striped table-bordered my-2 ">
                  <thead>
                    <tr>
                      <th scope="col">ลำดับ</th>
                      <th scope="col">ชนิดสัตว์</th>
                      <th scope="col">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.data_animaltype && form.data_animaltype.length > 0
                      ? form.data_animaltype.map((item, i) => {
                          return renderTableAnimalType(item, i);
                        })
                      : ""}
                    {form.checkAnimalType &&
                      renderAnimalType(form.data_animaltype)}
                    {form.data_animaltype &&
                      form.data_animaltype.length === 0 &&
                      !form.checkAnimalType && (
                        <td
                          scope="col"
                          colSpan={15}
                          className="text-center font14"
                        >
                          ไม่มีข้อมูล
                        </td>
                      )}
                  </tbody>
                </Table>
              </div>
            </div>
            <div
              className="tab-pane fade card-tab"
              id="status"
              role="tabpanel"
              aria-labelledby="status-tab"
            >
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="สถานะ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusSelectValue}
                    optionsList={statusList}
                    handleSelectValue={(value) =>
                      setStatusSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, status: value.value, checkShow: true })
                    }
                    handleInvalid={() => removeInvalid("status")}
                    invalid={invalid.status}
                  />
                </Col>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="Zero Report"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={statusZeroReportSelectValue}
                    optionsList={statusList}
                    handleSelectValue={(value) =>
                      setStatusZeroReportSelectValue([
                        { value: value.value, label: value.label },
                      ])
                    }
                    handleChange={(value) =>
                      setForm({ ...form, isZeroReport: value.value })
                    }
                    // handleInvalid={() => removeInvalid("status")}
                    // invalid={invalid.status}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <div className="d-flex align-items-center text-gray mb-0 font12 mt-3 ml-2">
                    <p className="mr-4">วันที่บันทึกข้อมูล</p>
                    <p>
                      {form.createDate
                        ? helper.momentDate(form.createDate, "th", "short")
                        : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3} md={6} sm={12} xs={12}>
                  <div className="d-flex align-items-center text-gray mb-0 font12 ml-2">
                    <p className="mr-3">วันที่ปรับปรุงข้อมูล</p>
                    <p>
                      {form.updateDate
                        ? helper.momentDate(form.updateDate, "th", "short")
                        : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="mr-2 mt-3 mb-2">
          <div className="text-right py-2">
            <button
              className="btn btn-danger px-4 mr-2"
              onClick={() => handleClose()}
            >
              ยกเลิก
            </button>
            <button
              className="btn btn-success px-4"
              onClick={() => toConfirm()}
              disabled={
                (user?.userGroup.mupdate === "n" && mode === "edit") ||
                form.checkAnimalType
              }
            >
              บันทึก
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalDiseaseType;
