import React, { useState, useEffect, useRef } from 'react'
import BoxCard from "../components/BoxCard";
import ReactToPrint from 'react-to-print';
import Report_Daily_Info from './Report_Daily_Info';
import * as helper from '../utils/helper';

function Report_Daily_PDF(dataAll) {
    const fileName = 'B99RPT08-DailyReport_' + helper.getDate(new Date())
    const pdfRef = useRef(null)

    const buttonExport = () => {
        return (<ReactToPrint
            trigger={() =>
                <button className="btn btn-report ">
                    <a className="text-white">
                        <img
                            className="nav-icon"
                            src={"/svg/export.svg"}
                            alt="export-icon"
                        /> ออกรายงาน
                    </a>
                </button>
            }
            content={() => pdfRef.current}
            documentTitle={fileName}
        />
        )
    };

    return (
        <BoxCard title="รายการข้อมูลรายงาน B99RPT08-รายงานภาพรวมรายวัน" classNameBox="mt-3" headRight={buttonExport()}>
            <div ref={pdfRef}>
                <Report_Daily_Info dataAll={dataAll.dataAll} />
            </div>
        </BoxCard>
    )
}

export default Report_Daily_PDF
