import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import DataTable from "../../components/DataTable";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import DataTableNodata from "../../components/DataTableNodata";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  useMasterData,
  useOptionsListYears,
  useCurrentYear,
} from "../../components/hooks/useMasterData";
import ButtonReportLink from "../../components/ButtonReportLink";

const MySwal = withReactContent(Swal);

function Epidemic_Zone() {
  const statusDisease = useSelector((state) => state.statusDisease);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation(["common", "footer"]);
  const user = useSelector((state) => state.login.result);

  const initialForm = {
    year: "",
    dateBegin: "",
    dateEnd: "",
    annouceTypeId: "",
    orgId: "",
    annouceCode: "",
    animalTypeId: "",
    diseaseTypeId: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    impression: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        sortBy: "id",
        name: "ลำดับ",
        sortFlg: false,
      },
      {
        sortBy: "orgName",
        name: "หน่วยงานที่ประกาศ",
        sortFlg: false,
      },
      {
        sortBy: "annouceTypeName",
        name: "ประกาศ",
        sortFlg: false,
      },

      {
        sortBy: "animalTypeName",
        name: "ชนิดสัตว์",
        sortFlg: false,
      },
      {
        sortBy: "diseaseTypeName",
        name: "ชนิดโรคระบาด",
        sortFlg: false,
      },
      {
        sortBy: "startDate",
        name: "วันที่ประกาศ",
        sortFlg: false,
      },
      {
        sortBy: "stopDate",
        name: "วันที่สิ้นสุดประกาศ",
        sortFlg: false,
      },
      {
        name: "กคร.",
        sortFlg: false,
      },
      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [listEpidemicZone, setListEpidemicZone] = useState([]);

  const [listAnnouceZone, setListAnnouceZone] = useState([]);
  const [isClearSearch, setIsClearSearch] = useState(false);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [annouceTypeIdSelectValue, setAnnouceTypeIdSelectValue] = useState([]);
  const [annouceTypeIdList, setAnnouceTypeIdList] = useState([]);

  const [yearSelectValue, setYearSelectValue] = useState([]);
  const [yearList, setYearList] = useState([]);

  const [diseaseTypeIdSelectValue, setDiseaseTypeIdSelectValue] = useState([]);
  const [diseaseTypeIdList, setDiseaseTypeIdList] = useState([]);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "title", name: "ประกาศเขตโรคระบาด" },
    { path: "active", name: "รายการประกาศเขตโรคระบาด" },
  ];

  useMasterData("province", provinceList, setProvinceList);
  const optionsListYear = useOptionsListYears();
  const currentYearValue = useCurrentYear();
  const [yearFilterList, setYearFilterList] = useState(currentYearValue);

  useEffect(() => {
    getEpidemicZone();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  useEffect(() => {
    getProvince();
    getAnimal();
    getDiseaseType();
    getAnnouce();
    getAnnouceYear();
  }, []);

  const getEpidemicZone = async () => {
    const params = await {
      sortBy: form.sortBy ? form.sortBy : "",
      sortDir: form.sortDir ? form.sortDir : "",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",
      year: form.year ? form.year : "",
      dateBegin: form.dateBegin ? helper.getDate(form.dateBegin) : "",
      dateEnd: form.dateEnd ? helper.getDate(form.dateEnd) : "",
      annouceTypeId: form.annouceTypeId ? form.annouceTypeId : "",
      orgId: form.orgId ? form.orgId : "",
      annouceCode: form.annouceCode ? form.annouceCode : "",
      animalTypeId: form.animalTypeId ? form.animalTypeId : "",
      diseaseTypeId: form.diseaseTypeId ? form.diseaseTypeId : "",
      provinceId: form.provinceId ? form.provinceId : "",
      amphurId: form.districtId ? form.districtId : "",
      tambonId: form.subDistrictId ? form.subDistrictId : "",
      animalTypeId: form.animalTypeId ? form.animalTypeId : "",
      statusId: form.status ? form.status : "",
      onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
    };

    try {
      const response = await API.getEpidemicZone(params);
      const data = await response.data;
      // console.log("params---getEpidemicZone--🐳🐳", params, response);

      if (response.status === 200) {
        await setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        await setListEpidemicZone(data.content);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType("", "", 1, 100, 1);
      const data = await response.data;
      if (response.status === 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setDiseaseTypeIdList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnnouce = async () => {
    try {
      const response = await API.getAnnouce("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let annouceArray = [];
        data.content.forEach((element) => {
          annouceArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnnouceTypeIdList(annouceArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnnouceYear = async () => {
    try {
      const response = await API.getAnnouceYear();
      const data = await response.data;
      if (response.status === 200) {
        let yearArray = [];
        data.forEach((element) => {
          yearArray.push({
            value: element,
            label: element + 543,
          });
        });
        await setYearList(yearArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, districtId: value.value });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value });
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusDisease && statusDisease.length > 0) {
      statusDisease.map((item, index) => {
        if (item.idOb === idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color, color: item.fontColor }}
                className="box-status font14 px-2 text-center"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setDistrictList([]);
    setSubDistrictList([]);
    setAnimalSelectValue([]);
    setAnnouceTypeIdSelectValue([]);
    setYearSelectValue([]);
    setDiseaseTypeIdSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <ButtonReportLink path={"epidemic_zone"} params={{
          year: form.year ? form.year : "",
          dateBegin: form.dateBegin ? helper.getDate(form.dateBegin) : "",
          dateEnd: form.dateEnd ? helper.getDate(form.dateEnd) : "",
          annouceTypeId: form.annouceTypeId ? form.annouceTypeId : "",
          orgId: form.orgId ? form.orgId : "",
          annouceCode: form.annouceCode ? form.annouceCode : "",
          animalTypeId: form.animalTypeId ? form.animalTypeId : "",
          diseaseTypeId: form.diseaseTypeId ? form.diseaseTypeId : "",
          provinceId: form.provinceId ? form.provinceId : "",
          amphurId: form.districtId ? form.districtId : "",
          tambonId: form.subDistrictId ? form.subDistrictId : "",
          animalTypeId: form.animalTypeId ? form.animalTypeId : "",
          statusId: form.status ? form.status : "",
          onYear: yearFilterList?.value - 543 ?? new Date().getFullYear(),
        }}/>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const buttonAdd = () => {
    return (
      <button
        className="btn btn-info "
        disabled={user?.userGroup.mcreate === "n"}
        onClick={() => history.push("/passive_surv/epidemic_zone/save")}
      >
        <i className="fas fa-plus mr-1 "></i>เพิ่มรายการ
      </button>
    );
  };

  const viewDetails = (id) => {
    history.push(`/passive_surv/epidemic_zone/edit/${id}`);
  };

  const deletEz = (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบแผน/ผลการฉีดวัคซีนป้องกันโรคระบาดสัตว์ (กคร.5)`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteEpidemicZone(id);
          if (response.status === 200) {
            await getEpidemicZone();
          }
        } catch (error) {
          // dispatch(loading(false))
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>
            <p className="bold mb-0">{item.orgName ? item.orgName : "-"}</p>
            <p className="font14 mb-0">({item.obCode})</p>
          </td>
          <td>
            <p className="bold mb-0">
              {" "}
              {item.annouceTypeName ? item.annouceTypeName : "-"}
            </p>
            <p className="font14 mb-0">
              ชื่อผู้ประกาศ : {item.annouceName ? item.annouceName : "-"}
            </p>
            <p className="font14 mb-0">
              ตำแหน่งผู้ประกาศ :{" "}
              {item.annoucePosition ? item.annoucePosition : "-"}
            </p>
          </td>
          <td className="text-center">
            {item.animalTypeName ? item.animalTypeName : "-"}
          </td>
          <td className="text-center">
            {item.diseaseTypeName ? item.diseaseTypeName : "-"}
          </td>
          <td className="text-center">
            {item.startDate
              ? helper.momentDate(item.startDate, "th", "short")
              : "-"}
          </td>
          <td className="text-center">
            {item.stopDate
              ? helper.momentDate(item.stopDate, "th", "short")
              : "-"}
            {item.statusId ? getStatusColor(item.statusId) : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center">
              <button className="btn btn-view d-flex align-items-center">
                <img
                  width="39"
                  height="37"
                  src={"/svg/btn-eye.svg"}
                  alt="view"
                />
              </button>
            </div>
          </td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => deletEz(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="9" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ปี"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="year"
                  classLabel="normal"
                  selectValue={yearFilterList}
                  optionsList={optionsListYear}
                  handleChange={(value) => setYearFilterList(value)}
                />
              </Col>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ปีงบประมาณ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={yearSelectValue}
                  optionsList={yearList}
                  handleChange={(value) => {
                    setYearSelectValue([value]);
                    setForm({ ...form, year: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={6} xs={6}>
                <InputDatePicker
                  title="วันที่ประกาศ"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateBegin}
                  star={false}
                  maxDate={true}
                  handleChange={(value) =>
                    setForm({ ...form, dateBegin: value })
                  }
                />
              </Col>

              <Col mlg={2} md={6} sm={6} xs={6}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.dateEnd}
                  // maxDate={form.dateBegin}
                  minDate={form.dateBegin}
                  maxDate={"afterSelectDate"}
                  star={false}
                  handleChange={(value) => setForm({ ...form, dateEnd: value })}
                />
              </Col>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ประเภทประกาศ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={annouceTypeIdSelectValue}
                  optionsList={annouceTypeIdList}
                  handleChange={(value) => {
                    setAnnouceTypeIdSelectValue([value]);
                    setForm({ ...form, annouceTypeId: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputText
                  type="text"
                  title="หน่วยงานที่ประกาศ"
                  idName="orgId"
                  value={form.orgId}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) =>
                    setForm({ ...form, orgId: value, checkShow: true })
                  }
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputText
                  type="text"
                  title="เลขที่หนังสือ"
                  idName="annouceCode"
                  value={form.annouceCode}
                  star={false}
                  placeholder="โปรดระบุ"
                  classLabel="normal"
                  handleChange={(value) =>
                    setForm({ ...form, annouceCode: value, checkShow: true })
                  }
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ชนิดสัตว์"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={animalSelectValue}
                  optionsList={animalList}
                  handleChange={(value) => {
                    setAnimalSelectValue([value]);
                    setForm({ ...form, animalTypeId: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ชนิดโรคสัตว์"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={diseaseTypeIdSelectValue}
                  optionsList={diseaseTypeIdList}
                  handleChange={(value) => {
                    setDiseaseTypeIdSelectValue([value]);
                    setForm({ ...form, diseaseTypeId: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="จังหวัด"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="province"
                  classLabel="normal"
                  selectValue={provinceSelectValue}
                  optionsList={provinceList}
                  handleChange={(value) => {
                    selectValueProvince(value);
                    setForm({ ...form, provinceId: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="อำเภอ/เขต"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={districtSelectValue}
                  optionsList={districtList}
                  handleChange={(value) => {
                    selectValueDistrict(value);
                    setForm({ ...form, districtId: value.value });
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ตำบล/แขวง"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="subDistrict"
                  classLabel="normal"
                  selectValue={subDistrictSelectValue}
                  optionsList={subDistrictList}
                  handleChange={(value) => {
                    selectValueSubDistrict(value);
                    setForm({ ...form, subDistrictId: value.value });
                  }}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการประกาศเขตโรคระบาด"
            headRight={buttonAdd()}

          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTable(listEpidemicZone)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}
export default Epidemic_Zone;
