import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { logout } from "../../redux/actions/logoutAction";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import * as API from "../../utils/apis";
import * as helper from "../../utils/helper";

import pdfMake from "pdfmake-thai/build/pdfmake";
import pdfFonts from "pdfmake-thai/build/vfs_fonts";

import Breadcrumb from "../../components/Breadcrumb";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import DataTable from "../../components/DataTable";
import DataTableNodata from "../../components/DataTableNodata";
import InputDatePicker from "../../components/InputDatePicker";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function DiseaseInformsReport() {
  const user = useSelector((state) => state.login.result);
  const ref = React.createRef();
  const dispatch = useDispatch();
  const history = useHistory();
  // const { t } = useTranslation(["common", "footer"]);

  const initialForm = {
    person: "",
    infCode: "",
    animalId: "",
    provinceId: "",
    amphurId: "",
    tambonId: "",
    statusId: "",
    foundDateBegin: "",
    foundDateEnd: "",
    createDateBegin: "",
    createDateEnd: "",
    zoneName: "",
    zoneId: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        name: "ลำดับ",
        sortFlg: false,
        width: "50px",
        rowSpan: 2,
      },
      {
        sortBy: "infCode",
        name: "รหัสการแจ้ง",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "informByName",
        name: "แหล่งที่มา",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "fullname",
        name: "ชื่อผู้แจ้ง",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "foundDiseaseDate",
        name: "วันที่พบโรค",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "createDateBegin",
        name: "วันที่แจ้งโรค",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "animalTypeName",
        name: "ชนิดสัตว์",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "symptomName",
        name: "อาการ",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        name: "สถานที่พบโรค",
        sortFlg: false,
        colSpan: 4,
        childHead: [
          {
            sortBy: "provinceId",
            name: "จังหวัด",
            sortFlg: true,
          },
          {
            sortBy: "amphurId",
            name: "อำเภอ",
            sortFlg: true,
          },
          {
            sortBy: "tambonId",
            name: "ตำบล",
            sortFlg: true,
          },
          {
            sortBy: "address",
            name: "ที่อยู่",
            sortFlg: true,
          },
        ],
      },
      // {
      //     sortBy: "address",
      //     name: "สถานที่พบโรค",
      //     sortFlg: true
      // },
      {
        sortBy: "statusId",
        name: "สถานะการดำเนินงาน",
        sortFlg: false,
        rowSpan: 2,
      },
      {
        sortBy: "acceptDate",
        name: "วันที่ดำเนินงาน",
        sortFlg: true,
        rowSpan: 2,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const [informReportList, setInformReportList] = useState([]);

  const [reportIdSelectValue, setReportIdSelectValue] = useState([]);
  const [reportIdList, setReportIdList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [statusSelectValue, setStatusSelectValue] = useState([]);
  const [statusList, setStatusList] = useState([]);

  // select จังหวัด อำเภอ
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  // select file type to download
  const [fileType, setFileType] = useState("excel");
  const fileName = "B99RPT01-DiseaseReport_" + helper.getDate(new Date());

  const [zoneLivestockList, setZoneLivestockList] = useState([]);
  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState([]);

  const [isClearSearch, setIsClearSearch] = useState(false);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "เฝ้าระวังเชิงรับ" },
    { path: "/passive_surv/disease-informs-report", name: "รายงาน" },
    { path: "active", name: "B99RPT01-การแจ้งโรค" },
  ];

  pdfMake.fonts = {
    THSarabunNew: {
      normal: "THSarabunNew.ttf",
      bold: "THSarabunNew-Bold.ttf",
      italics: "THSarabunNew-Italic.ttf",
      bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
  };
  const [provinceByZoneId, setProvinceByZoneId] = useState(true);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );

  useEffect(() => {
    // getProvince();
    getReportDisease();
    getAnimal();
    getStatus();
    getZoneLivestock();
    getInformsReport();
  }, []);

  useEffect(() => {
    getInformsReport();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const getInformsReport = async () => {
    try {
      const response = await API.getInformsReport(
        "",
        form.page,
        form.limit,
        "",
        form.sortBy,
        form.sortDir,
        form.infCode,
        form.person,
        form.foundDateBegin ? helper.getDate(form.foundDateBegin) : "",
        form.foundDateEnd ? helper.getDate(form.foundDateEnd) : "",
        form.createDateBegin ? helper.getDate(form.createDateBegin) : "",
        form.createDateEnd ? helper.getDate(form.createDateEnd) : "",
        form.zoneId,
        form.provinceId,
        form.amphurId,
        form.tambonId,
        form.animalId,
        form.statusId
      );
      const data = await response?.data;
      if (response.status === 200) {
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setInformReportList(data.content);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getInformsReportToExport = async () => {
    try {
      const response = await API.getInformsReport(
        "",
        "1",
        form.totalElements,
        "",
        form.sortBy,
        form.sortDir
      );
      const data = await response?.data;
      if (response.status === 200) {
        let informReportArray = [];
        data.content.forEach((element) => {
          informReportArray.push({
            id: element.id,
            infCode: element.infCode,
            informByName: element.informByName,
            fullname: element.fullname,
            foundDiseaseDate: element.foundDiseaseDate,
            createDate: element.createDate,
            animalTypeName: element.animalTypeName,
            symptomName: element.symptomName,
            address: element.address,
            mooName: element.mooName,
            tambonName: element.tambonName,
            amphurName: element.amphurName,
            provinceName: element.provinceName,
            statusName: element.statusName,
            acceptDate: element.acceptDate,
          });
        });
        downloadRecord(informReportArray, data.content);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const downloadRecord = (dataList, dataListForPDF) => {
    let type = "";
    if (fileType === "excel") {
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = convertToExcel(convertListToExcelTable(dataList));
      const data = new Blob([excelBuffer], { type: type });
      FileSaver.saveAs(data, fileName + ".xlsx");
    }
    if (fileType === "csv") {
      type = "text/csv;charset=utf-8";
      const csv = convertToCSV(convertListToExcelTable(dataList));
      const csvData = new Blob([csv], { type: type });
      FileSaver.saveAs(csvData, fileName + ".csv");
    }
    if (fileType === "pdf") {
      printPDF(fileName, dataListForPDF);
    }
  };

  const getStatus = async () => {
    try {
      const response = await API.getStatus();
      const data = await response.data;
      if (response.status == 200) {
        let statusArray = [];
        data.content.forEach((element) => {
          statusArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setStatusList(statusArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReportDisease = async () => {
    try {
      const response = await API.getListReportDisease();
      const data = await response?.data;
      if (response.status == 200) {
        let reportArray = [];
        data.content.forEach((element) => {
          reportArray.push({
            value: element.infCode,
            label: element.infCode,
          });
        });
        setReportIdList(reportArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", 1);
      const data = await response?.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response?.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response?.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusColor = (idStatus) => {
    let colorStatus, nameStatus;
    if (idStatus) {
      if (idStatus == 1) {
        colorStatus = "#CBCBCB";
        nameStatus = "แจ้งโรค";
      }
      if (idStatus == 2) {
        colorStatus = "#20A8D8";
        nameStatus = "รับแจ้งโรค";
      }
      if (idStatus == 3) {
        colorStatus = "#FFC107";
        nameStatus = "กำลังดำเนินการ";
      }
      if (idStatus == 4) {
        colorStatus = "#F94343";
        nameStatus = "เฝ้าระวังโรค";
      }
      if (idStatus == 5) {
        colorStatus = "#FF7A00";
        nameStatus = "รายงานโรค";
      }
      if (idStatus == 6) {
        colorStatus = "#FFC107";
        nameStatus = "รอผลแลป";
      }
      if (idStatus == 7) {
        colorStatus = "#ED553B";
        nameStatus = "ควบคุมโรค";
      }
      if (idStatus == 8) {
        colorStatus = "#25B881";
        nameStatus = "โรคสงบ";
      }
      if (idStatus == 9) {
        colorStatus = "#25B881";
        nameStatus = "ไม่พบโรค";
      }
      return (
        <div
          style={{ background: colorStatus }}
          className="box-status3 font14 px-2 text-center"
        >
          <span>{nameStatus}</span>
        </div>
      );
    } else {
      return <div>-</div>;
    }
  };

  const convertListToExcelTable = (objArray) => {
    let excelTable = [];
    objArray.map((element, index) => {
      excelTable.push({
        ลำดับ: index + 1,
        รหัสการแจ้ง: element.infCode,
        แหล่งที่มา: element.informByName,
        ชื่อผู้แจ้ง: element.fullname,
        วันที่พบโรค: helper.momentDateTh(element.foundDiseaseDate),
        วันที่แจ้งโรค: helper.momentDateTh(element.createDate),
        ชนิดสัตว์: element.animalTypeName,
        อาการ: element.symptomName,
        จังหวัด: element.provinceName,
        อำเภอ: element.amphurName,
        ตำบล: element.tambonName,
        ที่อยู่: element.address + element.mooName,
        สถานะการดำเนินงาน: element.statusName,
        วันที่ดำเนินงาน: helper.momentDateTh(element.acceptDate),
      });
    });
    return excelTable;
  };

  const convertToCSV = (objArray) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let headCol = "";
    let dataRow = "";
    let key = Object.keys(array[0]);
    key.forEach((item) => {
      if (headCol === "") {
        headCol = item;
      } else {
        if (item != "") {
          headCol += ",";
          headCol += item;
        }
      }
    });
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") {
          line += ",";
        }
        line += array[i][index];
      }
      dataRow += line + "\r\n";
    }
    return headCol + "\r\n" + dataRow;
  };

  const convertToExcel = (objArray) => {
    const workSheet = XLSX.utils.json_to_sheet(objArray);
    const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    return XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  };

  const printPDF = (fileName, dataListForPDF) => {
    let listReportPdf = [];
    if (dataListForPDF && dataListForPDF.length > 0) {
      dataListForPDF.map((item, i) => {
        listReportPdf.push([
          i + 1,
          item?.infCode || "-",
          item?.informByName || "-",
          item?.fullname || "-",
          item.foundDiseaseDate ? helper.getDate(item.foundDiseaseDate) : "-",
          item.createDate ? helper.getDate(item.createDate) : "-",
          item?.animalTypeName || "-",
          item?.symptomName || "-",
          item.provinceName,
          item.amphurName,
          item.tambonName,
          item.address + " " + item.mooName,
          item?.statusName,
          item.acceptDate ? helper.getDate(item.acceptDate) : "",
        ]);
      });
    }

    let bodyArray = [
      [
        {
          text: "ลำดับ",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "รหัสการแจ้ง",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "แหล่งที่มา",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "ชื่อผู้แจ้ง",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "วันที่พบโรค",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "วันที่แจ้งโรค",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "ชนิดสัตว์",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "อาการ",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "สถานที่พบโรค",
          style: "tableHeader",
          alignment: "center",
          colSpan: 4,
        },
        {},
        {},
        {},
        {
          text: "สถานะการดำเนินงาน",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
        {
          text: "วันที่ดำเนินงาน",
          style: "tableHeader",
          alignment: "center",
          rowSpan: 2,
        },
      ],
      [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        { text: "จังหวัด", style: "tableHeader", alignment: "center" },
        { text: "อำเภอ", style: "tableHeader", alignment: "center" },
        { text: "ตำบล", style: "tableHeader", alignment: "center" },
        { text: "ที่อยู่", style: "tableHeader", alignment: "center" },
        {},
        {},
      ],
    ];
    listReportPdf.map((item, i) => {
      bodyArray.push(listReportPdf[i]);
    });

    let docDefinition = {
      pageOrientation: "landscape",
      pageMargins: [5, 5, 5, 5],
      content: [
        {
          style: "tableExample",
          table: {
            widths: [
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
            ],
            headerRows: 1,
            body: bodyArray,
          },
        },
      ],
      defaultStyle: {
        fontSize: 12,
        font: "THSarabunNew",
      },
    };
    pdfMake.createPdf(docDefinition).download(fileName + ".pdf");
  };

  const buttonExport = () => {
    return (
      <>
        <button
          className="btn btn-report"
          onClick={() => getInformsReportToExport()}
          disabled={user?.userGroup.mexport === "n"}
        >
          <a className="text-white">
            <img
              className="nav-icon"
              src={"/svg/report.svg"}
              alt="export-icon"
            />{" "}
            ออกรายงาน
          </a>
        </button>
      </>
    );
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type == "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type == "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const checkExport = (value) => {
    if (fileType === value) {
      return true;
    } else {
      return false;
    }
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setFileType("excel");
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setDistrictList([]);
    setSubDistrictList([]);
    setAnimalSelectValue([]);
    setReportIdSelectValue([]);
    setZoneLivestockSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value, chooseSelect: true });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setForm({ ...form, amphurId: value.value, chooseSelect: true });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, tambonId: value.value, chooseSelect: true });
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i}>
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td className="text-center">{item?.infCode || "-"}</td>
          <td className="text-center">{item?.informByName || "-"}</td>
          <td className="text-left">
            <h6 className="mb-0 mt-1">{item?.fullname || "-"}</h6>
            <h6>{item.tel}</h6>
          </td>
          <td className="text-center">
            {helper.momentDateTh(item?.foundDiseaseDate, "th", "short") || ""}
          </td>
          <td className="text-center ">
            {helper.momentDateTh(item?.createDate, "th", "short") || ""}
          </td>
          <td className="text-center">{item?.animalTypeName || "-"}</td>
          <td className="p-wrap">{item?.symptomName || "-"}</td>
          <td className="text-center">{item.provinceName}</td>
          <td className="text-center">{item.amphurName}</td>
          <td className="text-center">{item.tambonName}</td>
          <td className="text-center">
            {item.address} หมู่ที่ {item.mooName}
          </td>
          <td className="text-center">
            {getStatusColor(item?.statusId) || "-"}
          </td>
          <td className="text-center">
            {helper.momentDateTh(item?.acceptDate, "th", "short") || ""}
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={14} />;
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 mr-auto p-2">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard
            classNameBox=""
            title="ค้นหาข้อมูลฟาร์ม"
            headRight={buttonSearch()}
          >
            <Row>
              <Col lg={2} md={4} sm={6} xs={6}>
                <InputText
                  title="รหัสการแจ้งโรค"
                  star={false}
                  classLabel="normal"
                  value={form.infCode}
                  placeholder="โปรดระบุ"
                  handleChange={(value) => setForm({ ...form, infCode: value })}
                />
              </Col>

              <Col lg={3} md={4} sm={6} xs={6}>
                <InputText
                  title="ผู้แจ้งโรค"
                  star={false}
                  placeholder="ชื่อ/นามสุกล/บัตรประชาชน/เบอร์โทรศัพท์"
                  classLabel="normal"
                  value={form.person}
                  handleChange={(value) => setForm({ ...form, person: value })}
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputDatePicker
                  title="วันที่พบโรค"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  star={false}
                  value={form.foundDateBegin}
                  handleChange={(value) =>
                    setForm({ ...form, foundDateBegin: value })
                  }
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  star={false}
                  value={form.foundDateEnd}
                  handleChange={(value) =>
                    setForm({ ...form, foundDateEnd: value })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col lg={2} md={4} sm={6} xs={6}>
                <InputDatePicker
                  title="วันที่รายงานโรค"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  star={false}
                  value={form.createDateBegin}
                  handleChange={(value) =>
                    setForm({ ...form, createDateBegin: value })
                  }
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputDatePicker
                  title="ถึงวันที่"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  star={false}
                  value={form.createDateEnd}
                  handleChange={(value) =>
                    setForm({ ...form, createDateEnd: value })
                  }
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputSelect
                  title="จังหวัด"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="provinceId"
                  classLabel="normal"
                  selectValue={provinceSelectValue}
                  optionsList={provinceList}
                  handleChange={(value) => selectValueProvince(value)}
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputSelect
                  title="อำเภอ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="districtId"
                  classLabel="normal"
                  selectValue={districtSelectValue}
                  optionsList={districtList}
                  handleChange={(value) => selectValueDistrict(value)}
                />
              </Col>

              <Col lg={2} md={4} sm={6} xs={6}>
                <InputSelect
                  title="ตำบล"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="subDistrictId"
                  classLabel="normal"
                  selectValue={subDistrictSelectValue}
                  optionsList={subDistrictList}
                  handleChange={(value) => selectValueSubDistrict(value)}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="เขตปศุสัตว์"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={zoneLivestockSelectValue}
                  optionsList={zoneLivestockList}
                  handleChange={(value) => {
                    setZoneLivestockSelectValue([value]);
                    setForm({ ...form, zoneId: value.value });
                    getProvince(value.value);
                  }}
                />
              </Col>
              <Col lg={2} md={4} sm={6} xs={6}>
                <InputSelect
                  title="ชนิดสัตว์"
                  star={false}
                  placeholder="กรุณาเลือก"
                  classLabel="normal"
                  selectValue={animalSelectValue}
                  optionsList={animalList}
                  handleChange={(value) => {
                    setAnimalSelectValue(value);
                    setForm({ ...form, animalId: value.value });
                  }}
                />
              </Col>
              <Col lg={2} md={4} sm={6} xs={6}>
                <InputSelect
                  title="สถานะการดำเนินการ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleChange={(value) => {
                    setStatusSelectValue(value);
                    setForm({ ...form, statusId: value.value });
                  }}
                />
              </Col>
              <Col>
                <Col lg={3} md={6} sm={12}>
                  <label style={{ fontWeight: "normal" }}>เลือกรูปแบบ</label>
                </Col>
                <Col lg={9} md={12} sm={12} className="w-100">
                  <div className="d-flex mt-1 ">
                    <label style={{ fontWeight: "normal" }}>
                      <input
                        type="radio"
                        value="excel"
                        name="data-export"
                        checked={checkExport("excel")}
                        onChange={() => setFileType("excel")}
                      />{" "}
                      EXCEL
                    </label>
                    <div className="ml-3">
                      <label style={{ fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="pdf"
                          name="data-export"
                          checked={checkExport("pdf")}
                          onChange={() => setFileType("pdf")}
                        />{" "}
                        PDF
                      </label>
                    </div>

                    <div className="ml-3">
                      <label style={{ fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="csv"
                          name="data-export"
                          checked={checkExport("csv")}
                          onChange={() => setFileType("csv")}
                        />{" "}
                        CSV
                      </label>
                    </div>
                  </div>
                </Col>
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการข้อมูลรายงาน B99RPT01-การแจ้งโรค"
            headRight={buttonExport()}
          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
              id={"data-table"}
              id={"render-data-table"}
              reference={ref}
            >
              {renderTable(informReportList)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default DiseaseInformsReport;
