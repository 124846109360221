import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BoxHead from "../../components/BoxHead";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputGroupText from "../../components/InputGroupText";
import InputSelect from "../../components/InputSelect";
import InputMask from "../../components/InputMask";
import KeepSample_Step1 from "../../components/Save_Project/KeepSample_Step1";
import KeepSample_Step2 from "../../components/Save_Project/KeepSample_Step2";
import InputDatePicker from "../../components/InputDatePicker";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import * as API from "../../utils/apis";
import withReactContent from "sweetalert2-react-content";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import ModalFarmSearch from "../../components/Modal.FarmSearch";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function Keep_Sample_Save(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t, i18n } = useTranslation(["common", "footer"]);
  const { params } = props.match;

  const initialForm = {
    chooseSelect: false,
    dataAllById: {},
    mode: location.pathname.includes("edit") ? "" : "notGetFarmId",
    checkAddData: false,
    farmId: "",
    outbreakId: "",
    outbreakCode: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    caseCode: "",
    homeNo: "",
    phone: "",
    name: "",
    orgId: "",
    orgName: "",
    test_results: "",
    test_results_name: "",
    diseases: "",
    diseasesName: "",
    note: "",
    localGovernId: "",
    governmentName: "",
    project: "",
    fiscalYear: "",

    modeGet: "default",

    step1: {},
    step2: {},

    fm01: {},
  };
  const [form, setForm] = useState(initialForm);

  const initialFormJson = {
    animalId: {},
    diseaseTypeId: {},
    husbandry: {},
    animalPurpose: {},
    vaccineType: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initInvalidMsg = {
    nameHouse: "",
    animalTypeName: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const initShowFarmSearch = false;
  const [showFarmSearch, setShowFarmSearch] = useState(initShowFarmSearch);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);
  // =================

  const [houseSelectValue, setHouseSelectValue] = useState([]);
  const [houseList, setHouseList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const initTestResultsSelectValue = [];
  const [testResultsTypeSelectValue, setTestResultsValue] = useState(
    initTestResultsSelectValue
  );
  const selectTestResultsList = [
    { value: "1", label: "พบโรค" },
    { value: "2", label: "ไม่พบโรค" },
  ];
  const [testResultsList, setTestResultsList] = useState(selectTestResultsList);

  const [diseasesSelectValue, setDiseasesSelectValue] = useState([]);
  const [diseasesList, setDiseasesList] = useState([]);

  const [organizesSelectValue, setOrganizesSelectValue] = useState([]);
  const [organizesList, setOrganizesList] = useState([]);

  const [localGovernSelectValue, setLocalGovernSelectValue] = useState([]);
  const [localGovernList, setLocalGovernList] = useState([]);

  const [resultsSelectValue, setResultsSelectValue] = useState([]);
  const selectResultsList = [
    { value: "1", label: "ตรวจไม่ได้" },
    { value: "2", label: "ผลบวก(+)" },
    { value: "3", label: "ผลลบ(-)" },
  ];
  const [dataResultsList, setResultsList] = useState(selectResultsList);

  const [arrayFiles, setArrayFiles] = useState([]);

  const [immuneOSelectValue, setImmuneOSelectValue] = useState([]);
  const selectImmuneOList = [
    { value: "1", label: "< 40" },
    { value: "2", label: "40" },
    { value: "3", label: "80" },
    { value: "4", label: "160" },
    { value: "5", label: "320" },
    { value: "6", label: "640" },
    { value: "7", label: "1280" },
    { value: "8", label: "2560" },
    { value: "9", label: "5120" },
    { value: "10", label: "> 5120" },
  ];
  const [dataImmuneOList, setImmuneOList] = useState(selectImmuneOList);

  const [immuneASelectValue, setImmuneASelectValue] = useState([]);
  const selectImmuneAList = [
    { value: "1", label: "< 40" },
    { value: "2", label: "40" },
    { value: "3", label: "80" },
    { value: "4", label: "160" },
    { value: "5", label: "320" },
    { value: "6", label: "640" },
    { value: "7", label: "1280" },
    { value: "8", label: "2560" },
    { value: "9", label: "5120" },
    { value: "10", label: "> 5120" },
  ];
  const [dataImmuneAList, setImmuneAList] = useState(selectImmuneOList);

  const [immuneA1SelectValue, setImmuneA1SelectValue] = useState([]);
  const selectImmuneA1List = [
    { value: "1", label: "< 40" },
    { value: "2", label: "40" },
    { value: "3", label: "80" },
    { value: "4", label: "160" },
    { value: "5", label: "320" },
    { value: "6", label: "640" },
    { value: "7", label: "1280" },
    { value: "8", label: "2560" },
    { value: "9", label: "5120" },
    { value: "10", label: "> 5120" },
  ];
  const [dataImmuneA1List, setImmuneA1List] = useState(selectImmuneA1List);

  const [dtl, setDtl] = useState({
    fiscalYear: "",
    project: "",
  });

  const [onSave, setOnSave] = useState(false);
  const [checkSave, setCheckSave] = useState(false);

  useMasterData("province", provinceList, setProvinceList);
  // =================

  useEffect(async () => {
    await getProvince();
    await getMasterJson();
    await getAnimal();
    await getDiseaseType();
    await getLocalGovern();
  }, []);

  useEffect(async () => {
    if (location.pathname.includes("/keep-sample/save/")) {
      await getDTLById(params.id);
    }
    if (
      location.pathname.includes("/keep-sample/save/") &&
      !form.chooseSelect &&
      form.farmId
    ) {
      await getFarmById(form.farmId);
    }
  }, [form.farmId, villageList.length == 0 || diseasesList.length == 0]);

  useEffect(async () => {
    if (location.pathname.includes("/keep-sample/edit/")) {
      await getDTLById(params.id);
      await getkeepSampleById(params.id, params.sampleId);
    }
  }, [
    user,
    location.pathname,
    form.provinceId + form.districtId + form.subDistrictId,
  ]);

  useEffect(() => {
    if (onSave) {
      toSaveKeepEx();
    }
  }, [onSave]);

  const checkOnSave = async (value) => {
    if (value === false) {
      setCheckSave(false);
    } else {
      setOnSave(value);
    }
  };

  const getMasterJson = async () => {
    const params = await [
      "animalType",
      "diseaseType",
      "husbandry",
      "animalPurpose",
      "vaccineType",
    ];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          animalId: data.animalType,
          diseaseTypeId: data.diseaseType,
          husbandry: data.husbandry,
          animalPurpose: data.animalPurpose,
          vaccineType: data.vaccineType,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDiseaseType = async () => {
    try {
      const response = await API.getDiseaseType("", "", "1", "100", 1);
      const data = await response.data;
      if (response.status == 200) {
        let diseaseArray = [];
        data.content.forEach((element) => {
          diseaseArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDiseasesList(diseaseArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLocalGovern = async () => {
    try {
      const response = await API.getLocalGovern("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let localGovernArray = [];
        data.content.forEach((element) => {
          localGovernArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setLocalGovernList(localGovernArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDTLById = async (id) => {
    try {
      const response = await API.getDTLById(id);
      const data = await response?.data;
      if (response.status === 200) {
        setDtl({ ...dtl, fiscalYear: data.year, project: data.projectName });
        form["project"] = data.id;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getFarmById = async (id) => {
    dispatch(loading(true));
    let dataRes;
    try {
      const response = await API.getFarmRegistrationById(id);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.farm && dataById.farm.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.farm.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.farm.provinceId);
        }
      }

      if (dataById.farm && dataById.farm.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.farm.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.farm.amphurId);
        }
      }

      if (dataById.farm && dataById.farm.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.farm.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.farm.tambonId);
        }
      }

      if (dataById.farm && dataById.farm.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.farm.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.farm && dataById.farm.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.farm.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      if (dataById.farm && dataById.farm.dlaId) {
        let localGovernSelect1 = await localGovernList.find(
          (item) => item.value === dataById.farm.dlaId
        );
        setLocalGovernSelectValue(localGovernSelect1);
      }

      const houseArray = [];
      let vaccineArray;
      if (dataById.barn && dataById.barn.length > 0) {
        dataById.barn.map((barns, b) => {
          houseArray.push({
            barnId: barns.id,
            name: barns.name,
            desc: [],
            animals: barns.animals.map((animal, a) => {
              if (barns.id == animal.barnId) {
                if (animal.vaccine && animal.vaccine.length > 0) {
                  vaccineArray = animal.vaccine.map((vaccine, v) => {
                    return {
                      diseaseTypeName:
                        formJson.diseaseTypeId[vaccine.diseaseTypeId],
                      diseaseTypeId: vaccine.diseaseTypeId,
                      last_date: vaccine.vaccineDate,
                      lotNo: vaccine.lotNo,
                      operator: vaccine.injectBy,
                      other_vaccine: vaccine.injectOther,
                      had_disease: vaccine.diseaseHistory,
                      vaccineTypeId: vaccine.vaccineTypeId,
                      vaccineTypeName:
                        formJson.vaccineType[vaccine.vaccineTypeId],
                    };
                  });
                }
                // console.log("genderType", animal);
                return {
                  animalTypeName: formJson.animalId[animal.animalTypeId],
                  animalTypeId: animal.animalTypeId,
                  animalId: animal.animalCode,
                  date_dead: animal.deadDate,
                  status_id: animal.statusId,
                  cause_death_type: animal.deadCause,
                  cause_death_type_other: animal.deadReason,
                  name: animal.name,
                  genderType:
                    animal.sex === "m"
                      ? "ตัวผู้"
                      : animal.sex === "f"
                      ? "ตัวเมีย"
                      : "ไม่ทราบ",
                  sex: animal.sex,
                  house_building: barns.name,
                  age_year: animal.ageYear,
                  age_month: animal.ageMonth,
                  no_nid: animal.nid,
                  no_microchip: animal.microchip,
                  breed: animal.species,
                  purpose_raising: animal.purposeId,
                  purpose_name: formJson.animalPurpose[animal.purposeId],
                  no_nid: animal.nidCode,
                  no_animal_ear: animal.nid,
                  style_raising: animal.husbandryId,
                  style_raising_name: formJson.husbandry[animal.husbandryId],
                  is_vaccine: animal.vaccinationStatus,
                  choose_disease: vaccineArray,
                };
              }
            }),
          });
        });
      }

      let arrayHouseList = [];
      if (houseArray && houseArray.length > 0) {
        houseArray.forEach((house) =>
          arrayHouseList.push({ value: house.barnId, label: house.name })
        );

        setHouseList(arrayHouseList);
      }

      if (location.pathname.includes("/keep-sample/save/")) {
        setForm({
          ...form,
          dataAllById: dataById,

          mode: "add",
          farmId: dataById.farm.id,

          provinceId: dataById.farm.provinceId,
          districtId: dataById.farm.amphurId,
          subDistrictId: dataById.farm.tambonId,
          villageId: dataById.farm.mooId,
          homeNo: dataById.farm.homeNo,
          idCard: dataById.farmer.pid,
          phone: dataById.farmer.mobile,
          name: `${dataById.farmer.firstName} ${dataById.farmer.lastName}`,
          orgId: dataById.farm.orgId,
          orgName: dataById.farm.orgName,
          localGovernId: dataById.farm.dlaId,
          governmentName: dataById.farm.dlaName,

          step1: {
            provinceId: dataById.farm.provinceId,
            districtId: dataById.farm.amphurId,
            subDistrictId: dataById.farm.tambonId,
            villageId: dataById.farm.mooId,
            homeno: dataById.farm.homeNo,
            localGovernId: dataById.farm.dlaId,
            governmentName: dataById.farm.dlaName,
          },

          step2: {
            house: houseArray,
            houseList: arrayHouseList,
            res_animals: [],
            list_animals: [],
          },
        });
      }

      dispatch(loading(false));
    }
  };

  const getkeepSampleById = async (id, sampleId) => {
    let dataRes;
    try {
      const response = await API.getkeepSampleById(id, sampleId);
      if (response.status == 200) {
        dataRes = await response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    let arrayFiles = [];
    if (
      dataRes?.sample?.sampleFiles &&
      dataRes?.sample?.sampleFiles.length > 0
    ) {
      dataRes.sample.sampleFiles.map((item, i) => {
        arrayFiles.push({
          id: item.id,
          data: item.url,
          name: item.name,
          description: item.description,
          date: item.date,
          save: false,
        });
      });
    }
    setArrayFiles(arrayFiles);

    if (dataRes) {
      const dataById = dataRes;
      const sampleById = dataRes.sample;

      if (dataById.farm && dataById.farm.id) {
        getFarmById(dataById.farm.id);
      }

      if (dataById.farm && dataById.farm.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.farm.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.farm.provinceId);
        }
      }

      if (dataById.farm && dataById.farm.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.farm.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.farm.amphurId);
        }
      }

      if (dataById.farm && dataById.farm.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.farm.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.farm.tambonId);
        }
      }

      if (dataById.farm && dataById.farm.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.farm.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.farm && dataById.farm.dlaId) {
        let localGovernSelect1 = await localGovernList.find(
          (item) => item.value == dataById.farm.dlaId
        );
        setLocalGovernSelectValue(localGovernSelect1);
      }

      if (sampleById.sampleTest && sampleById.sampleTest.diseaseTypeId) {
        let diseaseTypeIdSelect = await diseasesList.find(
          (item) => item.value == sampleById.sampleTest.diseaseTypeId
        );
        setDiseasesSelectValue(diseaseTypeIdSelect);
      }
      if (sampleById.sampleTest && sampleById.sampleTest.diseaseTypeId) {
        let diseaseTypeIdSelect = await diseasesList.find(
          (item) => item.value == sampleById.sampleTest.diseaseTypeId
        );
        setDiseasesSelectValue(diseaseTypeIdSelect);
      }

      if (sampleById.sampleTest && sampleById.sampleTest.statusId) {
        let testResultsSelect = await testResultsList.find(
          (item) => item.value == sampleById.sampleTest.statusId
        );
        setTestResultsValue(testResultsSelect);
      }

      if (dataById && sampleById) {
        setForm({
          ...form,
          dataAllById: dataById,

          modeGet: "getById",
          farmId: dataById.farm.id,
          // outbreakId: dataById.outbreak.id,
          // outbreakCode: dataById.outbreak.obCode,
          // caseCode: dataById.farm.caseCode,
          provinceId: dataById.farm.provinceId || "",
          districtId: dataById.farm.amphurId || "",
          subDistrictId: dataById.farm.tambonId || "",
          villageId: dataById.farm.mooId || "",
          homeno: dataById.farm.address || "",
          phone: dataById.farm.ownerTel || "",
          name: dataById.farm.ownerName || "",
          orgId: dataById.farm.orgId || "",
          orgName: dataById.farm.orgName || "",
          localGovernId: dataById.farm.dlaId || "",
          governmentName: dataById.farm.dlaName || "",
          checkAddData: true,
          test_results: sampleById.sampleTest.statusId || "",
          test_results_name: sampleById.sampleTest.test_results_name || "",
          diseases: sampleById.sampleTest.diseaseTypeId || "",
          diseasesName: sampleById.sampleTest.diseasesName || "",
          note: sampleById.sampleTest.remark || "",

          step1: dataRes.sample,

          step2: {
            barn_Step1: sampleById.sampleBarn,
            step1Ex: sampleById.sampleAnimal,
            step2File: sampleById.sampleTestFiles,
          },
        });
      }
    }
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/active_surv/action_plan", name: "การเฝ้าระวังเชิงรุก" },
    { path: "/active_surv/action_plan", name: "แผนการดำเนินงาน" },
    { path: "active", name: "รายการบันทึกข้อมูลตามโครงการ" },
  ];

  const toCancel = () => {
    history.push(
      `/active_surv/save-project/add-disease-surveillance/${params.id}`
    );
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, provinceId: value.value, zipcode: "" });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, districtId: value.value, zipcode: "" });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value, zipcode: value.zipcode });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const toSaveKeepEx = async () => {
    if (form.mode == "notGetFarmId") {
      MySwal.fire({
        text: "กรุณาเลือกเลขฟาร์ม",
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
    } else {
      setCheckSave(true);
    }

    if (onSave) {
      const dataById = form.dataAllById;
      const dataStep1 = form.step1;
      const dataStep2 = form.step2;

      let dataBarnStep1 = JSON.parse(JSON.stringify(dataStep1?.barn));
      if (dataBarnStep1 && dataBarnStep1.length > 0) {
        let brn = [];
        dataBarnStep1.map((itemBarn, indexBarn) => {
          if (
            itemBarn.sampleBarnAnimal &&
            itemBarn.sampleBarnAnimal.length > 0
          ) {
            let anim = [];
            itemBarn.sampleBarnAnimal.map((itemAnimal, indexAnimal) => {
              if (!itemAnimal.name && itemAnimal.name.length <= 0) {
                itemAnimal.name = "ตัวที่ " + (indexAnimal + 1);
              }
              if (
                itemAnimal.sampleBarnAnimalDtl &&
                itemAnimal.sampleBarnAnimalDtl.length > 0
              ) {
                let dtl = [];
                itemAnimal.sampleBarnAnimalDtl.forEach((itemDtl, indexDtl) => {
                  dtl.push({
                    id: itemDtl.id ? itemDtl.id : "",
                    keepDate: helper.getDate(itemDtl.keepDate),
                    sampleAmt: itemDtl.sampleAmt,
                    // sampleBarnAnimalId: itemDtl.sampleBarnAnimalId,
                    sampleKeepTypeId: itemDtl.sampleKeepTypeId,
                    sampleNo: itemDtl.sampleNo,
                  });
                });

                itemAnimal.sampleBarnAnimalDtl = dtl;
              }

              anim.push({
                id: itemAnimal.id ? itemAnimal.id : "",
                name: itemAnimal.name,
                animalCode: itemAnimal.animalCode,
                id: itemAnimal.id ? itemAnimal.id : "",
                immuneA: itemAnimal.immuneA,
                immuneA1: itemAnimal.immuneA1,
                immuneO: itemAnimal.immuneO,
                resultsStatus: itemAnimal.resultsStatus,
                sampleBarnAnimalDtl: itemAnimal.sampleBarnAnimalDtl,
              });
            });
            itemBarn.sampleBarnAnimal = anim;
          }
          brn.push({
            id: itemBarn.id ? itemBarn.id : "",
            animalAmt: itemBarn.animalAmt,
            animalTypeId: itemBarn.animalTypeId,
            barnId: itemBarn.barnId,
            limsCaseId: itemBarn.labId,
            sampleBarnAnimal: itemBarn.sampleBarnAnimal,
          });
        });
        dataBarnStep1 = brn;
      }
      // console.log("dataStep1", dataStep1);
      let data_setAnimal = [];
      if (dataStep1.data_setAnimal && dataStep1.data_setAnimal.length > 0) {
        dataStep1.data_setAnimal.map((item, i) => {
          data_setAnimal.push({
            id: item.id ? item.id : "",
            limsCaseId: item.limsCaseId ? item.limsCaseId : "",
            barnId: item.barnId,
            animalTypeId: item.animalTypeId,
            sampleKeepTypeId: item.sampleKeepTypeId,
            sampleAmt: item.sampleAmt,
            animalName: item.animalName,
            sex: item.sex,
            species: item.species,
            ageYear: String(item.ageYear),
            ageMonth: String(item.ageMonth),
            nid: item.nid,
            microchip: item.microchip,
            resultsStatus: item.resultsStatus,
            immuneO: item.immuneO,
            immuneA: item.immuneA,
            immuneA1: item.immuneA1,
          });
        });
      }

      const sampleFilesStep1 = [];
      if (dataStep1.data_file && dataStep1.data_file.length > 0) {
        dataStep1.data_file.map((item, i) => {
          if (item.id) {
          } else {
            sampleFilesStep1.push({
              name: item.name,
              description: item.description,
              data: item.data,
            });
          }
        });
      }

      const sampleTestFilesStep2 = [];
      if (dataStep2.data_file && dataStep2.data_file.length > 0) {
        dataStep2.data_file.map((item, i) => {
          if (item.id) {
          } else {
            sampleTestFilesStep2.push({
              name: item.name,
              description: item.description,
              data: item.data,
            });
          }
        });
      }

      const delFile = [];
      if (dataStep1.del_file && dataStep1.del_file.length > 0) {
        dataStep1.del_file.map((item) => {
          delFile.push(item);
        });
      }

      const delTestFiles = [];
      if (dataStep2.del_file && dataStep2.del_file.length > 0) {
        dataStep2.del_file.map((item) => {
          delTestFiles.push(item);
        });
      }

      const data = {
        asResult: {
          actionPlanDtlId: form.project,
          farmId: dataById.farm.id,
        },
        sample: {
          keepSampleDate: dataStep1.keepSampleDate
            ? helper.getDate(dataStep1.keepSampleDate)
            : "",
          sendSampleDate: dataStep1.sendSampleDate
            ? helper.getDate(dataStep1.sendSampleDate)
            : "",
          sendSampleMethodId: dataStep1.sendSampleMethodId,
          remark: dataStep1.remark,
          labId: dataStep1.labId,
          purposeSampleId: dataStep1.purposeSampleId,
          diseaseTypeId: dataStep1.diseaseTypeId,
        },
        sampleTest: {
          statusId: form.test_results,
          diseaseTypeId: form.diseases,
          remark: form.note,
        },
        sampleBarn: dataBarnStep1,
        sampleAnimal: data_setAnimal,
        sampleFiles: sampleFilesStep1,
        deleteSampleFiles: delFile,
        sampleTestFiles: sampleTestFilesStep2,
        deleteSampleTestFiles: delTestFiles,
        deleteCaseNo: [],
      };

      MySwal.fire({
        text: `ต้องการบันทึก`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (value) => {
        if (value.isConfirmed) {
          if (location.pathname.includes("keep-sample/edit/")) {
            delete data["asResult"];
            // console.log('data6', data);
          }

          try {
            const response = location.pathname.includes("keep-sample/save")
              ? await API.savekeepSample(data)
              : await API.updatekeepSample(form.project, params.sampleId, data); // 2 id
            if (response.status === 200) {
              MySwal.fire({
                text: location.pathname.includes("keep-sample/save")
                  ? `สร้าง "รายการข้อมูลการเฝ้าระวังโรค" สำเร็จ`
                  : `แก้ไข "รายการข้อมูลการเฝ้าระวังโรค" สำเร็จ`,
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then(async (result) => {
                history.push(
                  `/active_surv/save-project/add-disease-surveillance/${params.id}`
                );
              });
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              dispatch(logout({ history }));
            } else {
              MySwal.fire({
                text: `บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
                icon: "warning",
                confirmButtonText: "ตกลง",
              });
            }
            console.log("error", error);
          }
        }
      });
    }
    // console.log("form7", form);
  };

  return (
    <div>
      <div className="pl-4 bg-white ml30 d-flex">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2 ">
          {/* <Link to={history.push()} > */}
          <button className="btn btn-danger" onClick={() => toCancel()}>
            ยกเลิก
          </button>
          {/* </Link> */}
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-success"
            onClick={() => toSaveKeepEx()}
            disabled={user?.userGroup.mupdate === "n"}
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxHead title="ตัวกรอง" classLabel="">
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ปีงบประมาณ"
                    idName="fiscalYear"
                    value={dtl.fiscalYear}
                    star={false}
                    placeholder=""
                    classLabel="normal"
                    handleChange={(value) =>
                      setDtl({ ...dtl, fiscalYear: value })
                    }
                    disabled={true}
                  />
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="โครงการ"
                    idName="project"
                    value={dtl.project}
                    star={false}
                    placeholder=""
                    classLabel="normal"
                    handleChange={(value) => setDtl({ ...dtl, project: value })}
                    disabled={true}
                  />
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <div onClick={() => setShowFarmSearch(true)}>
                    <InputGroupText
                      type="text"
                      title="รหัสฟาร์ม"
                      imgSrc={"/svg/search.svg"}
                      imgAlt="search-icon"
                      idName="farmId"
                      value={form.farmId}
                      star={false}
                      placeholder="ค้นหาฟาร์ม"
                      classLabel="normal"
                      handleChange={(value) => {
                        setForm({ ...form, farmId: value });
                      }}
                    />
                  </div>
                </Col>

                <Col lg={2} md={2} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    <button
                      className="btn btn-darkblue w-100"
                      onClick={() =>
                        history.push("/farm_registration/save-data")
                      }
                    >
                      <img
                        className="mr-2 mb-1 "
                        src={"/svg/menu_5.svg"}
                        alt="menu-icon"
                      />
                      ขึ้นทะเบียนฟาร์ม
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ชื่อ-สกุลเจ้าของฟาร์ม"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    disabled={true}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    disabled={true}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    disabled={true}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputSelect
                    title="หมู่ที่"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="villageId"
                    classLabel="normal"
                    disabled={true}
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({ ...form, villageId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputSelect
                    title="องค์กรปกครองส่วนท้องถิ่น"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="localGovernId"
                    classLabel="normal"
                    selectValue={localGovernSelectValue}
                    optionsList={localGovernList}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    disabled={true}
                    handleChange={(value) => {
                      setLocalGovernSelectValue([
                        { value: value.value, label: value.label },
                      ]);
                      setForm({ ...form, localGovernId: value.value });
                    }}
                  />
                </Col>

                <Col lg={2} md={2} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ท้องถิ่น"
                    idName="governmentName"
                    value={form.governmentName}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, governmentName: value })
                    }
                  />
                </Col>
              </Row>

              <Row className="py-2 pl-2 bg-gray2">
                <Col lg={2} md={3} sm={6}>
                  <h6 className="mb-0 bold">ผลการพิจารณาผลตรวจ :</h6>
                  <button
                    className="btn btn-darkblue mt-2"
                    onClick={() => history.push("/passive_surv/outbreak/save")}
                    disabled={location.pathname.includes(
                      "save-project/keep-sample/save"
                    )}
                  >
                    + เพิ่ม Outbreak
                  </button>
                </Col>
                <Col lg={2} md={2} sm={6}>
                  <InputSelect
                    classFormGroup="mb-0"
                    title="ผลการตรวจ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    disabled={location.pathname.includes(
                      "save-project/keep-sample/save"
                    )}
                    selectValue={testResultsTypeSelectValue}
                    optionsList={testResultsList}
                    handleChange={(value) => {
                      setForm({
                        ...form,
                        test_results: value.value,
                        test_results_name: value.label,
                      });
                      setTestResultsValue(value);
                    }}
                  />
                </Col>

                <Col lg={2} md={2} sm={6}>
                  <InputSelect
                    classFormGroup="mb-0"
                    title="ชนิดโรคที่พบ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={diseasesSelectValue}
                    optionsList={diseasesList}
                    disabled={
                      location.pathname.includes(
                        "save-project/keep-sample/save"
                      ) || form.test_results === 2
                    }
                    handleChange={(value) => {
                      setForm({
                        ...form,
                        diseases: value.value,
                        diseasesName: value.label,
                      });
                      setDiseasesSelectValue([value]);
                    }}
                  />
                </Col>

                <Col lg={2} md={2} sm={6}>
                  <InputText
                    type="text"
                    title="หมายเหตุ"
                    idName="note"
                    value={form.note}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    classFormGroup="mb-0"
                    disabled={
                      location.pathname.includes(
                        "save-project/keep-sample/save"
                      ) || form.test_results === 2
                    }
                    handleChange={(value) => setForm({ ...form, note: value })}
                  />
                </Col>
              </Row>
            </div>
          </BoxHead>

          <div className="pt-3 container-fixed-footer ">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item ">
                <a
                  className="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  1.การเก็บตัวอย่าง
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  2.ผลการตรวจทางห้องปฏิบัติการ
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {form.modeGet == "default" && (
                  <KeepSample_Step1
                    formData={form}
                    save={(data) => {
                      setForm({ ...form, step1: data });
                    }}
                    modeGet={form.modeGet}
                    diseasesListData={diseasesList}
                    buildingList={houseList}
                    animalListData={animalList}
                    resultsList={dataResultsList}
                    immuneOList={dataImmuneOList}
                    immuneAList={dataImmuneAList}
                    immuneA1List={dataImmuneA1List}
                    checkOnSave={(value) => checkOnSave(value)}
                    checkSave={checkSave}
                  />
                )}
                {form.modeGet == "getById" && (
                  <KeepSample_Step1
                    formData={form}
                    save={(data) => {
                      setForm({ ...form, step1: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step1}
                    diseasesListData={diseasesList}
                    buildingList={houseList}
                    animalListData={animalList}
                    resultsList={dataResultsList}
                    immuneOList={dataImmuneOList}
                    immuneAList={dataImmuneAList}
                    immuneA1List={dataImmuneA1List}
                    arrayFiles={arrayFiles}
                    onSave={onSave}
                    checkOnSave={(value) => checkOnSave(value)}
                    checkSave={checkSave}
                  />
                )}
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                {form.modeGet == "default" && (
                  <KeepSample_Step2
                    save={(data) => {
                      setForm({ ...form, step2: data });
                    }}
                    modeGet={form.modeGet}
                  />
                )}

                {form.modeGet == "getById" && (
                  <KeepSample_Step2
                    save={(data) => {
                      setForm({ ...form, step2: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step2}
                    buildingList={houseList}
                    animalListData={animalList}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalFarmSearch
        show={showFarmSearch}
        mode={form.modeFarmSearch}
        close={() => setShowFarmSearch(false)}
        save={(value) => setForm({ ...form, farmId: value })}
      />
    </div>
  );
}

export default Keep_Sample_Save;
