import React, { useState, useEffect } from 'react'
import MapRadius from '../../components/googleMap/MapRadius'
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";

export default function MapEx() {

  const [radius, setRadius] = useState()
  const [location, setLocation] = useState({ lat: 13.756, lng: 100.501 })

  return (
    <div className="google-map mb-2" style={{ padding: '20px' }}>
      <InputText
        type="text"
        title="กำหนดรัศมี"
        classLabel="normal"
        value={radius}
        handleChange={(value) => {
          const pattern = /^\d+$/;
          if (pattern.test(value) || !value) {
            setRadius(value)
          }
        }}
      />

      <InputSelect classFormGroup="mb-2 " star={false}
        placeholder="กรุณาเลือก" idName="nameHouse" classLabel="normal"
        optionsList={[{
          label: 'test1',
          value: { lat: 11.1, lng: 12.1 }
        }, {
          label: 'test2',
          value: { lat: 13.1, lng: 14.1 }
        }]}
        handleChange={(value) => {
          setLocation({ lat: value.value.lat, lng: value.value.lng })
        }}
      />

      <MapRadius radius={radius} location={location}
        getLocation={(val) => {
          setLocation({ ...val })
        }}>
      </MapRadius>

      <pre>
        {`lat: ${location.lat}, lng: ${location.lng}`}
      </pre>
    </div>
  )
}
