import React, { useState, useEffect } from "react";

function ButtonReportLink({ path, params, linkSize, addIndex, label = "ออกรายงาน" }) {

  const [stringParams, setStringParams] = useState("");

  useEffect(() => {
    setStringParams(setParamsToString())    
  }, [params])

  const setParamsToString = () => {
    const tempArray = [
      `j_username=${process.env.REACT_APP_J_USER}`,
      `j_password=${process.env.REACT_APP_J_PASS}`
    ];
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (params[key]) {
          tempArray.push(encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
        }
      }
    }
    if (tempArray.length > 0) {
      return `?${tempArray.join("&")}`;
    }
      return "";
  }

  const renderOpenLinks = () => {
    if (addIndex) {
      for (let index = 0; index < linkSize; index++) {
        window.open(`${process.env.REACT_APP_WEB_URL}/jasperserver/rest_v2/reports/reports/esurv/${path}_rpt_${index+1}.xlsx${stringParams}`);
      }
    } else {
      window.open(`${process.env.REACT_APP_WEB_URL}/jasperserver/rest_v2/reports/reports/esurv/${path}_rpt.xlsx${stringParams}`);
    }
  }
  
  
  return (
    <button
      className="btn btn-report text-white mr-2"
      onClick={() => {
        renderOpenLinks();
      }}
    >
      {label}
    </button>
  );


}

export default ButtonReportLink;
