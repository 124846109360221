import React, { useState, useEffect } from "react";
import * as helper from "../utils/helper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from "react-router-dom";
import BoxCard from "./BoxCard";
import InputText from "./InputText";
import InputGroupText from "./InputGroupText";
import InputSelect from "./InputSelect";
import OutbreakStep1 from "./OutbreakStep1";
import InputDatePicker from "./InputDatePicker";
import InputMask from "./InputMask";
import Breadcrumb from './Breadcrumb';
import { useTranslation } from 'react-i18next';
import UploadImage from "./UploadImage";
import withReactContent from 'sweetalert2-react-content';
import { Row, Col, } from "react-bootstrap";
import ModalOutbreakSearch from "./Modal.OutbreakSearch";
import ModalFarmSearch from "./Modal.FarmSearch";
import DataTableNodata from "../components/DataTableNodata";
import * as API from "../utils/apis";
import ModalVaccineAnimal from "../components/Modal.VaccineAnimal";
import ModalAnimalByOne from "./Modal.AnimalByOne";
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal)

function OutbreakStep8({ save, dataAll, formData, modeGet, farmId, obId }) {

    const history = useHistory();
    const user = useSelector(state => state.login.result)
    const { t } = useTranslation(["common", "footer"]);

    let houseList = []
    if (formData.step2.house && formData.step2.house.length) {
        formData.step2.house.map(item => {
            let data = {
                value: item.barnId,
                label: item.name
            }
            houseList.push(data)
        })
    }

    const initialForm = {
        checkAddData: false,

        modeEditTable1: false,
        checkEditVacFarm: false,
        house: [],
        houseList: [],
        delBarn: [],

        chooseSelect: false,

        // ข้อมูลสัตว์
        dataAnimalEdit: "",
        modeAnimal: "add",

        animalAll: [], // สัตว์ทั้งหมดในฟาร์มนี้ ไปเช็คกับตอนเลือกจาก modal
        listAnimals: [], // list เพื่อส่งไป api
        res_animals: [], // list เพื่อโชว์
        del_animals: [], // array id สัตว์ที่ลบ

        del_rabies: [],
        checkAddAniaml: false,
        checkEdit: false,
        indexNew: "",
        modeEditTable2: false,

        checkGetData: false,

        idVaccince: ""
    };
    const [form, setForm] = useState(initialForm);

    const initialVaccineFarm = {
        house: "",
        nameHouse: "",
        animalTypeId: "",
        animalTypeName: "",
        vaccineTypeId: "",
        vaccineTypeName: "",
        vaccine_date: "",
        lot_no: "",
        injected_staff: "",
        injected_owner: "",
        number_animal_vaccine: "",
        number_animal_herd: "",
        belly_animal: "",
        more_than: "",
        not_can_catch: "",
        other: "",
        animal_age: "",
    };

    const initialFormDesc = {
        idHouse: "",
        nameHouse: "",
        animalTypeId: "",
        animalTypeName: "",
        vaccineTypeId: "",
        vaccineTypeName: "",
        vaccine_date: "",
        lot_no: "",
        injected_staff: "",
        injected_owner: "",
        number_animal_vaccine: "",
        number_animal_herd: "",
        belly_animal: "",
        more_than: "",
        not_can_catch: "",
        other: "",
        animal_age: "",
    };
    const [formDesc, setFormDesc] = useState(initialFormDesc);

    const initInvalidMsg = {
        nameHouse: "",
        animalTypeName: "",
    };
    const [invalid, setInvalid] = useState(initInvalidMsg);

    const initialFormJson = {
        animalId: {},
        diseaseTypeId: {},
        husbandry: {},
        animalPurpose: {},
        vaccineType: {},
    };
    const [formJson, setFormJson] = useState(initialFormJson);

    const initialAnimal = {
        vaccine_id: "",
        vaccine_name: "",
        lotNo: "",
    };
    const [formAnimal, setFormAnimal] = useState(initialAnimal);

    const [animalList, setAnimalList] = useState([])
    const [houseSelectValue, setHouseSelectValue] = useState([])
    const [animalSelectValue, setAnimalSelectValue] = useState([])
    const [vaccineTypeSelectValue, setVaccineTypeSelectValue] = useState([])
    const [vaccineTypeList, setVaccineTypeList] = useState([])
    const [vaccineList, setVaccineList] = useState([])
    const [vaccineSelectValue, setVaccineSelectValue] = useState([])

    const initShowAnmByOne = false;
    const [showAnmByOne, setShowAnmByOne] = useState(initShowAnmByOne)

    const initShowAnimalVac = false;
    const [showAnimalVac, setShowAnimalVac] = useState(initShowAnimalVac)

    useEffect(() => {
        getAnimal()
    }, [])

    useEffect(() => {
        if (modeGet == "getById") {
            getDataById();
        }
    }, [user, formData.step8.checkGetData])

    useEffect(() => {
        toSave()
    }, [form])

    const getDataById = () => {
        if (formData.step8) {
            setForm({ ...form, house: formData.step8.house, res_animals: formData.step8.res_animals, idVaccince: formData.step8.idVaccince })
        }
    }

    const toSave = () => {
        save(form)
    }

    const getAnimal = async () => {
        try {
            const response = await API.getAnimal("", 1);
            const data = await response.data;
            if (response.status == 200) {
                let animalArray = [];
                data.content.forEach(element => {
                    animalArray.push({
                        value: element.id, label: element.name,
                    });
                });
                await setAnimalList(animalArray);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const buttonAddVaccineFarm = () => {
        return (
            <div className="d-flex justify-content-end">
                <button className="btn btn-add text-white " onClick={() => { viewDetails() }}
                // disabled={(form.house && form.house.length > 0 && !form.checkAddData && !form.modeEditTable1) ? false : true}
                >
                    <i className="fas fa-plus mr-1"></i>เพิ่มชนิดสัตว์
                </button>
            </div>
        );
    };

    const searchAnimalVac = (data) => {
        let arrayFarm = [...form.data_animalVac];
        arrayFarm.push(data)
        setForm({ ...form, data_animalVac: arrayFarm });
    }

    const buttonAddAnimal = () => {
        return (
            <button className="btn btn-add text-white"
                // onClick={() => { setShowAnmByOne(true) }}
                onClick={() => { viewDetails() }}>
                <i className="fas fa-plus mr-1 "></i>เพิ่มชนิดสัตว์
            </button>
        )
    }

    const getVaccineType = async (id, type) => {
        try {
            const response = await API.getVaccineType(id);
            const data = await response.data;
            if (response.status == 200) {
                let vaccineArray1 = [];
                let vaccineArray2 = [];
                if (type == "table1") {
                    data.content.forEach(element => {
                        vaccineArray1.push({
                            value: element.id, label: element.name,
                        });
                    });
                    await setVaccineTypeList(vaccineArray1);

                }
                else {
                    data.content.forEach(element => {
                        vaccineArray2.push({
                            value: element.id, label: element.name,
                        });
                    });
                    await setVaccineList(vaccineArray2);
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const selectAnimal = (value) => {
        setAnimalSelectValue([{ value: value.value, label: value.label }])
        setVaccineTypeSelectValue([])
        setFormDesc({ ...formDesc, animalTypeId: value.value, animalTypeName: value.label })
        getVaccineType(value.value, "table1");
    }

    const compareAnmId = (list, value) => {
        let check = false;
        list.forEach((animal, a) => {
            if (animal.id == value) {
                check = true;
            }
        })
        return check;
    }

    const addAnimalByOne = (data) => {
        if (data.animals && data.animals.length > 0) {
            data.anmChooseList.map((chooseList, c) => {
                if (compareAnmId(data.animals, chooseList)) {
                    data.animals.map((animal, a) => {
                        if (animal.id == chooseList) {
                            form.res_animals.push(animal)
                            setForm({ ...form, listAnimals: data.anmChooseList });
                        }
                    })
                }
            })
        }
    }

    const addInvalid = (element, message) => {
        invalid[element] = message;
        setInvalid({ ...invalid });
    };

    const validateHouse = () => {
        let validated = true;
        if (formDesc.animalTypeName == "") {
            addInvalid("animalTypeName", "กรุณาเลือกชนิดสัตว์");
            validated = false;
        }

        return validated;
    }

    const saveHouseDesc = async (index, type, itemDesc) => {
        if (validateHouse() || type == "edit") {
            let newBuilding = [...form.house]
            let houseDesc = newBuilding.desc ? [...newBuilding.desc] : [];

            houseDesc.push({
                checkEditVacFarm: false,
                nameHouse: formDesc.nameHouse,
                idHouse: formDesc.idHouse,
                animalTypeId: formDesc.animalTypeId,
                animalTypeName: formDesc.animalTypeName,
                vaccineTypeId: formDesc.vaccineTypeId,
                vaccineTypeName: formDesc.vaccineTypeName,
                vaccine_date: formDesc.vaccine_date,
                lot_no: formDesc.lot_no,
                injected_staff: formDesc.injected_staff,
                injected_owner: formDesc.injected_owner,
                number_animal_vaccine: Number(formDesc.injected_staff) + Number(formDesc.injected_owner),
                number_animal_herd: formDesc.number_animal_herd,
                belly_animal: formDesc.belly_animal,
                more_than: formDesc.more_than,
                not_can_catch: formDesc.not_can_catch,
                other: formDesc.other,
                animal_age: formDesc.animal_age,
            })

            if (type == "add") {
                if (form.house.length > 0) {
                    form.house.map((item, i) => {
                        if (item.barnId == formDesc.idHouse) {
                            newBuilding[i].desc.push(houseDesc[0]);
                        }
                    })
                }
            } else if (type == "edit") {
                if (formDesc.idHouse == itemDesc.idHouse) {
                    itemDesc.checkEditVacFarm = false;
                    itemDesc.nameHouse = formDesc.nameHouse;
                    itemDesc.idHouse = formDesc.idHouse;
                    itemDesc.animalTypeId = formDesc.animalTypeId;
                    itemDesc.animalTypeName = formDesc.animalTypeName;
                    itemDesc.vaccineTypeId = formDesc.vaccineTypeId;
                    itemDesc.vaccineTypeName = formDesc.vaccineTypeName;
                    itemDesc.vaccine_date = formDesc.vaccine_date;
                    itemDesc.lot_no = formDesc.lot_no;
                    itemDesc.injected_staff = formDesc.injected_staff;
                    itemDesc.injected_owner = formDesc.injected_owner;
                    itemDesc.number_animal_vaccine = Number(formDesc.injected_staff) + Number(formDesc.injected_owner);
                    itemDesc.number_animal_herd = formDesc.number_animal_herd;
                    itemDesc.belly_animal = formDesc.belly_animal;
                    itemDesc.more_than = formDesc.more_than;
                    itemDesc.not_can_catch = formDesc.not_can_catch;
                    itemDesc.other = formDesc.other;
                    itemDesc.animal_age = formDesc.animal_age;

                    setForm({ ...form })
                } else {
                    if (form.house.length > 0) {
                        form.house.map((item, i) => {
                            item.desc.splice(index, 1);
                            if (item.barnId == formDesc.idHouse) {
                                newBuilding[i].desc.push(houseDesc[0]);
                            }
                        })
                    }
                }

            }
            setForm({ ...form, checkAddData: false, modeEditTable1: false })
            toSave()
        }
    }

    const viewDetails = () => {
        if (form.idVaccince) {
            history.push(`/passive_surv/vaccinate/edit/${form.idVaccince}`)

        } else {
            history.push(`/passive_surv/vaccinate/save/${obId}`)
        }
    }

    const renderDesc = (data) => {
        if (data && data.length > 0) {
            return data.map((item, index) => (
                <tr key={index} className="">
                    <td>
                        {item.checkEditVacFarm ?
                            <InputSelect classFormGroup="mb-0 " star={false}
                                placeholder="กรุณาเลือก" idName="nameHouse" classLabel="normal"
                                selectValue={houseSelectValue} optionsList={houseList}
                                handleChange={(value) => {
                                    setHouseSelectValue([value])
                                    setFormDesc({ ...formDesc, nameHouse: value.label, idHouse: value.value })
                                }}
                            />
                            :
                            item.nameHouse
                        }
                    </td>
                    <td className="fitwidth">
                        {item.checkEditVacFarm ?
                            <InputSelect classFormGroup="mb-0 w-100" star={false}
                                placeholder="กรุณาเลือก" idName="animalTypeName" classLabel="normal"
                                selectValue={animalSelectValue} optionsList={animalList}
                                handleChange={(value) => selectAnimal(value)}
                            />
                            :
                            item.animalTypeName ? item.animalTypeName : '-'}
                    </td>
                    <td className="fitwidth">
                        {item.checkEditVacFarm ?
                            <InputSelect classFormGroup="mb-0 w-100" star={false}
                                placeholder="กรุณาเลือก" idName="vaccineTypeId" classLabel="normal"
                                selectValue={vaccineTypeSelectValue} optionsList={vaccineTypeList}
                                handleChange={(value) => {
                                    setVaccineTypeSelectValue([value])
                                    setFormDesc({ ...formDesc, vaccineTypeName: value.label, vaccineTypeId: value.value })
                                }}
                            />
                            :
                            item.vaccineTypeName ? item.vaccineTypeName : '-'}
                    </td>
                    <td>
                        {item.checkEditVacFarm ?
                            <InputDatePicker classFormGroup="mb-0 w-100" title="" classLabel="normal" placeholder="วัน/เดือน/ปี"
                                value={formDesc.vaccine_date} star={false} handleChange={(value) =>
                                    setFormDesc({ ...formDesc, vaccine_date: value })
                                }
                            />
                            :
                            item.vaccine_date ? helper.getDateFormat(item.vaccine_date) : '-'}
                    </td>
                    <td>
                        {item.checkEditVacFarm ?
                            <InputText classFormGroup="mb-0 w-100" type="text" idName="count"
                                value={formDesc.lot_no} star={false} placeholder="โปรดระบุ"
                                handleChange={(value) => setFormDesc({ ...formDesc, lot_no: value })}
                            />
                            :
                            item.lot_no ? item.lot_no : '-'}
                    </td>
                    <td>
                        {item.checkEditVacFarm ?
                            <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                                value={formDesc.injected_staff} star={false} placeholder="ระบุจำนวน"
                                handleChange={(value) => setFormDesc({ ...formDesc, injected_staff: value })}
                            />
                            :
                            item.injected_staff ? item.injected_staff : '-'}
                    </td>
                    <td>
                        {item.checkEditVacFarm ?
                            <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                                value={formDesc.injected_owner} star={false} placeholder="ระบุจำนวน"
                                handleChange={(value) => setFormDesc({ ...formDesc, injected_owner: value })}
                            />
                            :
                            item.injected_owner ? item.injected_owner : '-'}
                    </td>
                    <td>
                        {item.checkEditVacFarm ?
                            <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                                value={Number(formDesc.injected_staff) + Number(formDesc.injected_owner)} star={false} placeholder="ระบุจำนวน"
                                handleChange={(value) => setFormDesc({ ...formDesc, number_animal_vaccine: value })}
                            />
                            :
                            item.number_animal_vaccine ? item.number_animal_vaccine : '-'}
                    </td>
                    <td>
                        {item.checkEditVacFarm ?
                            <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                                value={formDesc.number_animal_herd} star={false} placeholder="ระบุจำนวน"
                                handleChange={(value) => setFormDesc({ ...formDesc, number_animal_herd: value })}
                            />
                            :
                            item.number_animal_herd ? item.number_animal_herd : '-'}
                    </td>
                    <td>
                        {item.checkEditVacFarm ?
                            <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                                value={formDesc.belly_animal} star={false} placeholder="ระบุจำนวน"
                                handleChange={(value) => setFormDesc({ ...formDesc, belly_animal: value })}
                            />
                            :
                            item.belly_animal ? item.belly_animal : '-'}
                    </td>
                    <td>
                        {item.checkEditVacFarm ?
                            <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                                value={formDesc.more_than} star={false} placeholder="ระบุจำนวน"
                                handleChange={(value) => setFormDesc({ ...formDesc, more_than: value })}
                            />
                            :
                            item.more_than ? item.more_than : '-'}
                    </td>
                    <td>{item.checkEditVacFarm ?
                        <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                            value={formDesc.not_can_catch} star={false} placeholder="ระบุจำนวน"
                            handleChange={(value) => setFormDesc({ ...formDesc, not_can_catch: value })}
                        />
                        :
                        item.not_can_catch ? item.not_can_catch : '-'}
                    </td>
                    <td>
                        {item.checkEditVacFarm ?
                            <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                                value={formDesc.other} star={false} placeholder="ระบุจำนวน"
                                handleChange={(value) => setFormDesc({ ...formDesc, other: value })}
                            />
                            :
                            item.other ? item.other : '-'}
                    </td>
                    <td>
                        {item.checkEditVacFarm ?
                            <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                                value={formDesc.animal_age} star={false} placeholder="ระบุจำนวน"
                                handleChange={(value) => setFormDesc({ ...formDesc, animal_age: value })}
                            />
                            :
                            item.animal_age ? item.animal_age : '-'}
                    </td>
                </tr>
            ))
        }
    }

    const renderNewTable = (index, type) => {
        return (
            <tr className="">
                <td>
                    <InputSelect classFormGroup="mb-0 " star={false}
                        placeholder="กรุณากเลือก" idName="nameHouse" classLabel="normal"
                        selectValue={houseSelectValue} optionsList={houseList}
                        handleChange={(value) => {
                            setHouseSelectValue([value])
                            setFormDesc({ ...formDesc, nameHouse: value.label, idHouse: value.value })
                        }}
                    />
                </td>
                <td className="fitwidth">
                    <InputSelect classFormGroup="mb-0 w-100" star={false}
                        placeholder="กรุณาเลือก" idName="animalTypeName" classLabel="normal"
                        selectValue={animalSelectValue} optionsList={animalList}
                        handleChange={(value) => selectAnimal(value)}
                    />
                </td>

                <td className="fitwidth">
                    <InputSelect classFormGroup="mb-0 w-100" star={false}
                        placeholder="กรุณาเลือก" idName="vaccineTypeName" classLabel="normal"
                        selectValue={vaccineTypeSelectValue} optionsList={vaccineTypeList}
                        handleChange={(value) => {
                            setVaccineTypeSelectValue([value])
                            setFormDesc({ ...formDesc, vaccineTypeName: value.label, vaccineTypeId: value.value })
                        }}
                    />
                </td>

                <td>
                    <InputDatePicker classFormGroup="mb-0 w-100" title="" classLabel="normal" placeholder="วัน/เดือน/ปี"
                        value={formDesc.vaccine_date} star={false} handleChange={(value) =>
                            setFormDesc({ ...formDesc, vaccine_date: value })
                        }
                    />
                </td>

                <td>
                    <InputText classFormGroup="mb-0 w-100" type="text" idName="count"
                        value={formDesc.lot_no} star={false} placeholder="โปรดระบุ"
                        handleChange={(value) => setFormDesc({ ...formDesc, lot_no: value })}
                    />
                </td>

                <td>
                    <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                        value={formDesc.injected_staff} star={false} placeholder="ระบุจำนวน"
                        handleChange={(value) => setFormDesc({ ...formDesc, injected_staff: value })}
                    />
                </td>

                <td>
                    <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                        value={formDesc.injected_owner} star={false} placeholder="ระบุจำนวน"
                        handleChange={(value) => setFormDesc({ ...formDesc, injected_owner: value })}
                    />
                </td>

                <td>
                    <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                        value={Number(formDesc.injected_staff) + Number(formDesc.injected_owner)} placeholder="ระบุจำนวน"
                        handleChange={(value) => setFormDesc({ ...formDesc, number_animal_vaccine: value })}
                    />
                </td>

                <td>
                    <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                        value={formDesc.number_animal_herd} star={false} placeholder="ระบุจำนวน"
                        handleChange={(value) => setFormDesc({ ...formDesc, number_animal_herd: value })}
                    />
                </td>

                <td>
                    <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                        value={formDesc.belly_animal} star={false} placeholder="ระบุจำนวน"
                        handleChange={(value) => setFormDesc({ ...formDesc, belly_animal: value })}
                    />
                </td>

                <td>
                    <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                        value={formDesc.more_than} star={false} placeholder="ระบุจำนวน"
                        handleChange={(value) => setFormDesc({ ...formDesc, more_than: value })}
                    />
                </td>

                <td>
                    <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                        value={formDesc.not_can_catch} star={false} placeholder="ระบุจำนวน"
                        handleChange={(value) => setFormDesc({ ...formDesc, not_can_catch: value })}
                    />
                </td>

                <td>
                    <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                        value={formDesc.other} star={false} placeholder="ระบุจำนวน"
                        handleChange={(value) => setFormDesc({ ...formDesc, other: value })}
                    />
                </td>

                <td>
                    <InputText classFormGroup="mb-0 w-100" type="number" idName="count"
                        value={formDesc.animal_age} star={false} placeholder="ระบุจำนวน"
                        handleChange={(value) => setFormDesc({ ...formDesc, animal_age: value })}
                    />
                </td>

                <td className="">
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-success pt-1 px-2"
                            onClick={async () => {
                                saveHouseDesc(index, type)
                            }}>
                            <img className="img-fluid icon-check" src="/svg/btn-check.svg" alt="check" />
                        </button>
                    </div>
                </td>
            </tr>
        )
    }

    const renderTableAnimal = (data, indexAnm) => {
        return (
            <tr key={indexAnm} className="">
                <td>
                    {data.house_buiding ? data.house_buiding : "-"}
                </td>
                <td>
                    {data.animalTypeName ? data.animalTypeName : "-"}
                </td>
                <td >
                    {data.animalId ? data.animalId : '-'}
                </td>
                <td >
                    {data.name ? data.name : "-"}
                </td>
                <td >
                    {data.gender ? data.gender : '-'}
                </td>
                <td >
                    {data.age_year && `${data.age_year} ปี`} {data.age_month && `${data.age_month} เดือน`}
                </td>

                <td>
                    {data.checkEdit ?
                        <InputSelect classFormGroup="mb-0 w-100" star={false}
                            placeholder="กรุณาเลือก" idName="locationTypeName" classLabel="normal"
                            selectValue={vaccineSelectValue} optionsList={vaccineList}
                            handleChange={(value) => {
                                setVaccineSelectValue([value])
                                setFormAnimal({ ...formAnimal, vaccine_name: value.label, vaccine_id: value.value })
                            }}
                        />
                        :
                        data.vaccineTypeName ? data.vaccineTypeName : "-"
                    }

                </td>

                <td>
                    {data.checkEdit ?
                        <InputText classFormGroup="mb-0 w-100" type="text" idName="count"
                            value={formAnimal.lotNo} star={false} placeholder="กรุณากรอก"
                            handleChange={(value) => setFormAnimal({ ...formAnimal, lotNo: value })}
                        />
                        :
                        data.lotNo
                    }
                </td>
            </tr>
        )
    }

    return (
        <div className=" bg-white p-4">
            <BoxCard classNameBox="" title="8.1 การฉีดวัคซีนรายฟาร์ม" headRight={buttonAddVaccineFarm()}>
                <div className="table-responsive">
                    <table className="table fitwidth table-striped table-bordered font14">
                        <thead>
                            <tr className="text-center" >
                                <th scope="col" rowSpan={2}>โรงเรือน</th>
                                <th scope="col" rowSpan={2}>ชนิดสัตว์</th>
                                <th scope="col" rowSpan={2}>ชนิดวัคซีน</th>
                                <th scope="col" rowSpan={2}>วันที่ฉีดวัคซีน</th>
                                <th scope="col" rowSpan={2}>Lot No.</th>
                                <th scope="col" colSpan={2}>จำนวนสัตว์ที่ฉีดวัคซีน	</th>
                                <th scope="col" rowSpan={2}>รวมจำนวนสัตว์ที่ฉีดวัคซีน</th>
                                <th scope="col" rowSpan={2}>จำนวนทั้งหมด(สัตว์ร่วมฝูง)</th>
                                <th scope="col" colSpan={4}>จำนวนสัตว์ที่ไม่ได้รับวัคซีน (จำนวนตัว)</th>
                                <th scope="col" rowSpan={2}>อายุสัตว์ที่ทำวัคซีน (สัปดาห์)</th>
                                {/* <th scope="col" rowSpan={2}>จัดการ</th> */}
                            </tr>
                            <th scope="col">ฉีดโดย จนท</th>
                            <th scope="col">ฉีดโดยเจ้าของ</th>
                            <th scope="col">ท้อง</th>
                            <th scope="col"> {"<"} 4 เดือน</th>
                            <th scope="col">จับไม่ได้</th>
                            <th scope="col">อื่นๆ</th>
                        </thead>

                        <tbody>
                            {form.house && form.house.length > 0 && form.house.map((itemH, h) => {
                                if (itemH.desc && itemH.desc.length > 0) {
                                    return <React.Fragment key={h}>{renderDesc(itemH.desc, h)}</React.Fragment>
                                }
                            })}

                            {form.checkAddData && renderNewTable("", "add")}
                            {form.house && form.house.length == 0 &&
                                <DataTableNodata colSpan="15" />}
                        </tbody>

                    </table>
                </div>
            </BoxCard>

            <BoxCard title="8.2 การฉีดวัคซีนรายตัว" classNameBox="mt-4" headRight={buttonAddAnimal()}>
                <div className="table-responsive pl-3 pr-3 pb-2 ">
                    <table className="table table-striped table-bordered my-2 ">
                        <thead>
                            <tr className="" >
                                <th scope="col">{t("house_buiding")}</th>
                                <th scope="col">ชนิดสัตว์</th>
                                <th scope="col">หมายเลข</th>
                                <th scope="col">ชื่อ</th>
                                <th scope="col">เพศ</th>
                                <th scope="col">อายุ</th>
                                <th scope="col">ชนิดวัคซีน</th>
                                <th scope="col">Lot No.</th>
                                {/* <th scope="col-2" className="text-center">จัดการ</th> */}
                            </tr>
                        </thead>

                        <tbody>
                            {form.res_animals && form.res_animals.length > 0 ?
                                form.res_animals.map((animal, a) => (
                                    <React.Fragment key={a}>{renderTableAnimal(animal, a)}</React.Fragment>
                                ))
                                :
                                <DataTableNodata colSpan="8" />
                            }
                        </tbody>

                    </table>
                </div>
            </BoxCard>
            <ModalVaccineAnimal show={showAnimalVac} close={() => setShowAnimalVac(false)} save={(value) => searchAnimalVac(value)} />

            {showAnmByOne &&
                <ModalAnimalByOne show={showAnmByOne} close={() => setShowAnmByOne(false)} buidingList={houseList} barnsList={form.house}
                    farmId={farmId} save={(data) => { addAnimalByOne(data) }}
                />
            }
        </div>

    )
}

export default OutbreakStep8
