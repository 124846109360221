import React, { useState, useEffect } from 'react'
import { Modal, Form, Row, Col } from 'react-bootstrap';
import InputDatePicker from "../components/InputDatePicker";

function ModalStopDate({ show, close, save, idUpdateDate }) {

    const initialForm = {
        stopDate: "",
    };
    const [form, setForm] = useState(initialForm);

    const handleClose = () => {
        close()
        setForm(initialForm)
    }

    const toConfirm = async () => {
            await save({ date: form.stopDate, idUpdateDate: idUpdateDate });
            handleClose();
    };

    return (
        <Modal
            show={show}
            size="sm"
            onHide={() => { handleClose() }}
            centered
        >

            <Modal.Header closeButton className="pb-2 underline">
                <Modal.Title className="mt-2">
                    <h6 className="bold">กำหนดข้อมูลสิ้นสุดโรค</h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Col lg={12} md={12} sm={12}>
                    <InputDatePicker
                        title="วันที่สิ้นสุดโรค"
                        classLabel="normal"
                        classFormGroup="mb-0"
                        placeholder="วัน/เดือน/ปี"
                        value={form.stopDate}
                        star={false}
                        handleChange={(value) =>
                            setForm({ ...form, stopDate: value })
                        }
                    />
                    <div style={{ display: 'flex', textAlign: 'left', fontSize: '10px', color: '#979797' }}>
                        การกำหนดวันที่สิ้นสุดโรค หมายถึงสิ้นสุดโรคแล้ว
                    </div>
                </Col>

                <Col className="mt-4">
                    <div className="text-center">
                        <button className="btn btn-danger mr-2" onClick={() => handleClose()}>
                            ยกเลิก
                        </button>
                        <button className="btn btn-success" onClick={() => toConfirm()}>
                            บันทึก
                        </button>
                    </div>
                </Col>
            </Modal.Body>

        </Modal>
    )
}

export default ModalStopDate
