import React, { useState, useEffect } from "react";
import * as helper from "../utils/helper";
import { useDispatch } from "react-redux";
import * as API from "../utils/apis";
import { useHistory } from "react-router-dom";
import BoxCard from "./BoxCard";
import DataTableNodata from "./DataTableNodata";
import { logout } from "../redux/actions/logoutAction";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

function OutbreakStep4({ save, obId, obCode, caseCode }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [listDiseaseControl, setListDiseaseControl] = useState([]);

  const initialForm = {
    limit: 100,
    page: 1,
  };
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    if (obCode) {
      getDiseaseControl();
    }
  }, [obCode]);

  const getDiseaseControl = async () => {
    const params = await {
      sortBy: "id",
      sortDir: "DESC",
      page: form.page ? form.page : "",
      limit: form.limit ? form.limit : "",

      obCode: obCode ? obCode : "",
      // caseCode: caseCode ? caseCode : "",
    };

    try {
      const response = await API.getDiseaseControl(params);
      const data = await response.data;
      if (response.status == 200) {
        await setListDiseaseControl(data.content);
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const viewDetails = (id) => {
    history.push(`/passive_surv/disease_control/edit/${id}`);
  };

  const deleteDisease = (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบรายการควบคุมโรคนี้`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteDiseaseControl(id);
          if (response.status == 200) {
            await getDiseaseControl();
          }
        } catch (error) {
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const toDiseaseControl = () => {
    if (obId) {
      history.push(`/passive_surv/disease_control/save/${obId}`);
    } else {
      MySwal.fire({
        text: "กรุณาเลือก เลข Outbreak ID ก่อน",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      });
    }
  };

  const buttonAddControl = () => {
    return (
      <button className="btn btn-info " onClick={() => toDiseaseControl()}>
        <a className="text-white">
          <i className="fas fa-plus mr-1 "></i>เพิ่มภาวะการณ์และควบคุมโรค
        </a>
      </button>
    );
  };

  const renderTableControl = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td>{form.limit * (form.page - 1) + (i + 1)}</td>
          <td>
            {item.operateDate ? helper.getDateFormat(item.operateDate) : "-"}
          </td>
          <td>{item.animalTypeName}</td>
          <td>{item.totalAmt}</td>
          <td>{item.sickDeadAmt ? item.sickDeadAmt : "0"}</td>
          <td>{item.firstSick ? item.firstSick : "0"}</td>
          <td>{item.sickAmt ? item.sickAmt : "0"}</td>
          <td>{item.recoveryAmt ? item.recoveryAmt : "0"}</td>
          <td>{item.remainSick ? item.remainSick : "0"}</td>
          <td>{item.firstDeadAmt ? item.firstDeadAmt : "0"}</td>
          <td>{item.deadAmt ? item.deadAmt : "0"}</td>
          <td>
            <span>{helper.momentDate(item.updateDate, "th", "short")}</span>
          </td>
          <td>
            <div className="d-flex justify-content-center border-0 ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => viewDetails(item.id)}
              >
                <img className="icon-btn " src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => deleteDisease(item.id)}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
              <button className="btn btn-copy mr-2 ">
                <img className="icon-btn " src="/svg/btn-copy.svg" alt="copy" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="14" />;
    }
  };
  return (
    <div className=" bg-white p-4 ">
      <BoxCard
        classNameBox=""
        title="รายการการควบคุมโรค"
        headRight={buttonAddControl()}
      >
        <div className="table-responsive ">
          <table
            id="tableEvaluate"
            className="table table-striped table-bordered "
          >
            <thead>
              <tr className="text-center font14">
                <th scope="col" rowSpan={2}>
                  ลำดับ
                </th>
                <th scope="col" rowSpan={2}>
                  วันที่ปฏิบัติงาน
                </th>
                <th scope="col" rowSpan={2}>
                  ชนิดสัตว์
                </th>
                <th scope="col" rowSpan={2}>
                  จำนวนสัตว์กลุ่มเสี่ยงในฝูง
                </th>
                <th scope="col" colSpan={5}>
                  จำนวนสัตว์ป่วย (ตัว)
                </th>
                <th scope="col" colSpan={2}>
                  จำนวนสัตว์ป่วย (ตัว)
                </th>
                <th scope="col" rowSpan={2}>
                  วันที่สัตว์ตัวสุดท้ายป่วย
                </th>
                <th scope="col" rowSpan={2}>
                  จัดการ
                </th>
              </tr>
              <th scope="col">ป่วยแรกเกิดโรค</th>
              <th scope="col">ป่วยใหม่ครั้งนี้</th>
              <th scope="col">ป่วยทั้งหมดสะสม</th>
              <th scope="col">หายป่วย</th>
              <th scope="col">ป่วยคงเหลือ</th>
              <th scope="col">ตายแรกเกิดโรค/ตายในสัปดาห์นี้</th>
              <th scope="col">ตายทั้งหมดสะสม</th>
            </thead>
            <tbody>{renderTableControl(listDiseaseControl)}</tbody>
          </table>
        </div>
      </BoxCard>
    </div>
  );
}

export default OutbreakStep4;
