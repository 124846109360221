/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as helper from "../../utils/helper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BoxHead from "../../components/BoxHead";
import InputText from "../../components/InputText";
import InputGroupText from "../../components/InputGroupText";
import InputSelect from "../../components/InputSelect";
import OutbreakStep1 from "../../components/OutbreakStep1";
import OutbreakStep2 from "../../components/OutbreakStep2";
import DiseaseReport_Step3 from "../../components/DiseaseReport_Step3";
import DiseaseReport_Step4 from "../../components/DiseaseReport_Step4";
import OutbreakStep7 from "../../components/OutbreakStep7";
import InputMask from "../../components/InputMask";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import * as API from "../../utils/apis";
import withReactContent from "sweetalert2-react-content";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import ModalOrganizes from "../../components/Modal.Organizes";

import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ModalOutbreakSearch from "../../components/Modal.OutbreakSearch";
import ModalFarmSearch from "../../components/Modal.FarmSearch";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function Save_Disease_Statement(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t } = useTranslation(["common", "footer"]);
  const initialForm = {
    chooseSelect: false,
    dataAllById: {},

    farmId: "",
    outbreakId: "",
    outbreakCode: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    caseCode: "",
    homeno: "",
    phone: "",
    name: "",
    orgId: "",
    orgName: "",
    outbreakoldId: "",
    caseCodeOld: "",
    modeFarmSearch: "",

    modeGet: "default",
    delSymptoms: [],
    addSymptoms: [],
    step1: {},
    step2: {},
    step3: {},
    step4: {},
    step5: {},
  };
  const [form, setForm] = useState(initialForm);

  const initShowCaseIdSearch = false;
  const [showCaseIdSearch, setShowCaseIdSearch] =
    useState(initShowCaseIdSearch);

  const initShowOrgModal = false;
  const [showOrgModal, setShowOrgModal] = useState(initShowOrgModal);

  const initShowOutbreakSearch = false;
  const [showOutbreakSearch, setShowOutbreakSearch] = useState(
    initShowOutbreakSearch
  );

  const initShowFarmSearch = false;
  const [showFarmSearch, setShowFarmSearch] = useState(initShowFarmSearch);

  const initialFormJson = {
    animalId: {},
    diseaseTypeId: {},
    husbandry: {},
    animalPurpose: {},
    vaccineType: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initInvalidMsg = {
    orgId: "",
    farmId: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  useMasterData("province", provinceList, setProvinceList);

  // หน่วยงาน ชนิดโรค
  const [organizesSelectValue, setOrganizesSelectValue] = useState([]);
  const [organizesList, setOrganizesList] = useState([]);

  const [checkEditAnimal, setCheckEditAnimal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(async () => {
    let isMounted = true;

    if (isMounted) {
      await getMasterJson();
      await getProvince();
      await getOrganizes();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(async () => {
    if (
      location.pathname.includes("edit") ||
      location.pathname.includes("duplicate")
    ) {
      getDiseaseStatementById(props.match.params.id);
    }else{
      console.log("setOrganizesSelectValue");
      const response = await API.getOrganizes("", "", "", 1);
      const data = await response.data;
      if (response.status == 200) {
        let organizesArray = [];
        data.content.forEach((element) => {
          organizesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        if (user && user.profile.orgId) {
          let organizesSelect =  organizesArray.find((item) => item.value == user?.profile.orgId);
          console.log(organizesArray);
          console.log(user.profile.orgId);
          console.log(organizesSelect);
          setOrganizes(organizesSelect)
        }
      }
    }
 
  }, [
    user,
    location.pathname,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
  ]);

  useEffect(async () => {
    if (
      location.pathname.includes("save") &&
      !form.chooseSelect &&
      form.farmId
    ) {
      getFarmById(form.farmId);
    } else if (
      location.pathname.includes("save") &&
      props.match.params.farmId
    ) {
      getFarmById(props.match.params.farmId);
    } else if (location.pathname.includes("save") && form.outbreakId) {
      getOutbreakById(form.outbreakId);
    }
  }, [
    user,
    form.outbreakId,
    form.farmId,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
  ]);

  useEffect(() => {
    if (form?.farmId && location.pathname.includes("edit")) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [form.farmId]);

  const getMasterJson = async () => {
    const params = await [
      "animalType",
      "diseaseType",
      "husbandry",
      "animalPurpose",
      "vaccineType",
    ];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;
      if (response.status == 200) {
        setFormJson({
          ...formJson,
          animalId: data.animalType,
          diseaseTypeId: data.diseaseType,
          husbandry: data.husbandry,
          animalPurpose: data.animalPurpose,
          vaccineType: data.vaccineType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };
  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOrganizes = async () => {
    try {
      const response = await API.getOrganizes("", "", "", 1);
      const data = await response.data;
      if (response.status == 200) {
        let organizesArray = [];
        data.content.forEach((element) => {
          organizesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setOrganizesList(organizesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      zipcode: "",
      districtId: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      districtId: value.value,
      zipcode: "",
      subDistrictId: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      subDistrictId: value.value,
      zipcode: value.zipcode,
      villageId: "",
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const checkAnimalType = (list) => {
    let check = false;
    if (list.length > 0) {
      check = true;
    }
    return check;
  };

  const getFarmInDisease = async (farmId, dataById) => {
    let dataRes;
    try {
      const response = await API.getFarmRegistrationById(farmId);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataById.fm01 && dataById.fm01.provinceId) {
      let provinceSelect = await provinceList.find(
        (item) => item.value == dataById.fm01.provinceId
      );
      setProvinceSelectValue(provinceSelect);

      if (districtList.length == 0) {
        await getDistricts(dataById.fm01.provinceId);
      }
    }

    if (dataById.fm01 && dataById.fm01.amphurId) {
      let districtSelect = await districtList.find(
        (item) => item.value == dataById.fm01.amphurId
      );
      setDistrictSelectValue(districtSelect);
      if (subDistrictList.length == 0) {
        await getSubDistricts(dataById.fm01.amphurId);
      }
    }

    if (dataById.fm01 && dataById.fm01.tambonId) {
      let subDistrictSelect = await subDistrictList.find(
        (item) => item.value == dataById.fm01.tambonId
      );
      setSubDistrictSelectValue(subDistrictSelect);

      if (villageList.length == 0) {
        await getVillage(dataById.fm01.tambonId);
      }
    }

    if (dataById.fm01 && dataById.fm01.mooId) {
      let villageSelect = await villageList.find(
        (item) => item.value == dataById.fm01.mooId
      );
      setVillageSelectValue(villageSelect);
    }

    if (dataById.fm01 && dataById.fm01.orgId) {
      let organizesSelect = await organizesList.find(
        (item) => item.value == dataById.fm01.orgId
      );
      setOrganizesSelectValue(organizesSelect);
    }
    const houseArray = [];
    console.log("dataRes", dataRes);
    let vaccineArray;
    if (dataRes && dataRes.barn && dataRes.barn.length > 0) {
      console.log("dataRes.barn", dataRes.barn);
      dataRes.barn.map((barns, b) => {
        houseArray.push({
          barnId: barns.id,
          name: barns.name,
          desc: barns.animalType.map((type, t) => {
            return {
              animalTypeId: type.animalTypeId,
              animalTypeName: formJson.animalId[type.animalTypeId],
              totalAmt: type.amount,
              level1Id: "",
              level2Id: "",
              level3Id: "",
              checkEdit: false,
            };
          }),
          animals: [],
        });
      });
    }
    console.log("houseArray", houseArray);
    let arrayHouseList = [];
    let animalRes = [];
    if (houseArray && houseArray.length > 0) {
      houseArray.forEach((house) =>
        arrayHouseList.push({ value: house.barnId, label: house.name })
      );

      if (dataById.fm0102 && dataById.fm0102.animalResponse) {
        let animal_res = dataById.fm0102.animalResponse;

        if (animal_res && animal_res.length > 0) {
          animal_res.map((animal, a) => {
            animalRes.push({
              id: animal.id,
              fm01Id: animal.fm01Id,
              animalTypeName: formJson.animalId[animal.animalTypeId],
              animalTypeId: animal.animalTypeId,
              animalId: animal.animalCode,
              date_dead: animal.deadDate,
              status_id: animal.statusId,
              cause_death_type: animal.deadCause,
              cause_death_type_other: animal.deadReason,
              name: animal.name,
              genderType: animal.sex,
              gender:
                animal.sex === "m"
                  ? "ตัวผู้"
                  : animal.sex === "f"
                  ? "ตัวเมีย"
                  : "ไม่ทราบ",
              house_buiding: houseArray.map((house) => {
                if (house.barnId == animal.barnId) {
                  return house.name;
                }
              }),
              house_buiding_id: animal.barnId,
              age_year: animal.ageYear,
              age_month: animal.ageMonth,
              no_nid: animal.nid,
              no_microchip: animal.microchip,
              breed: animal.species,
              purpose_raising: animal.purposeId,
              purpose_name: formJson.animalPurpose[animal.purposeId],
              no_nid: animal.nidCode,
              no_animal_ear: animal.nid,
              style_raising: animal.husbandryId,
              style_raising_name: formJson.husbandry[animal.husbandryId],
              is_vaccine: animal.vaccinationStatus,
            });
          });
        }
      }
    }

    let fileStep3 = [];
    if (dataById.detentionFiles && dataById.detentionFiles.length > 0) {
      dataById.detentionFiles.map(async (item, i) => {
        fileStep3.push({
          base64: location.pathname.includes("edit")
            ? item.url
            : await getBase64FromUrl(item.url),
          name: item.name,
          id: item.id,
          url: item.url,
        });
      });
    }

    let fileStep4 = [];
    if (dataById.diseaseFiles && dataById.diseaseFiles.length > 0) {
      dataById.diseaseFiles.map(async (item, i) => {
        fileStep4.push({
          id: item.id,
          file: location.pathname.includes("edit")
            ? item.url
            : await getBase64FromUrl(item.url),
          fileName: item.name,
          detail_file: item.description,
          date_disease: item.date,
          url: item.url,
        });
      });
    }

    let fileStep5 = [];
    if (dataById.otherFiles && dataById.otherFiles.length > 0) {
      dataById.otherFiles.map(async (item, i) => {
        fileStep5.push({
          id: item.id,
          file: location.pathname.includes("edit")
            ? item.url
            : await getBase64FromUrl(item.url),
          name: item.name,
          description: item.description,
          date_file: item.date,
          url: item.url,
        });
      });
    }

    let step1_symptomData = [];
    if (dataById.fm01Symptomss && dataById.fm01Symptomss.length > 0) {
      dataById.fm01Symptomss.map((item, i) => {
        step1_symptomData.push({
          id: item.id ? item.id : "",
          symptom: { value: item.symptomId, label: item.symptomName },
          save: false,
        });
      });
    }
    if (dataById && dataRes) {
      setForm({
        ...form,
        dataAllById: dataById,

        modeGet: "dsGetById",
        farmId: dataById.fm01?.farmId,
        outbreakId: dataById.outbreak?.id,
        outbreakCode: dataById.outbreak?.obCode,
        caseCode: dataById.fm01?.caseCode,
        provinceId: dataById.fm01?.provinceId,
        districtId: dataById.fm01?.amphurId,
        subDistrictId: dataById.fm01?.tambonId,
        villageId: dataById.fm01?.mooId,
        homeno: dataById.fm01?.address,
        phone: dataById.fm01?.ownerTel,
        name: dataById.fm01?.ownerName,
        orgId: dataById.fm01?.orgId,
        orgName: dataById.fm01?.orgName,

        step1: {
          provinceId: dataById.fm01?.provinceId,
          districtId: dataById.fm01?.amphurId,
          subDistrictId: dataById.fm01?.tambonId,
          villageId: dataById.fm01?.mooId,
          homeno: dataById.fm01?.address,
          localGovernId: dataById.fm01?.dlaId,
          governmentName: dataById.fm01?.dlaName,

          animalId: dataById.fm01?.animalTypeId,
          diseaseTypeId: dataById.fm01?.diseaseTypeId,
          animal_sickId: dataById.fm01?.symptomId,
          date_found: dataById.fm01.foundDiseaseDate
            ? dataById.fm01.foundDiseaseDate
            : "",
          date_found_first: dataById.fm01.firstAnimSymptomDate
            ? dataById.fm01.firstAnimSymptomDate
            : "",
          report_from: dataById.fm01.informBy ? dataById.fm01.informBy : "",
          other_text: dataById.fm01.remark ? dataById.fm01.remark : "",

          humanSick: dataById.fm01.humanSickAmt
            ? dataById.fm01.humanSickAmt
            : "",
          humanDead: dataById.fm01.humanDeadAmt
            ? dataById.fm01.humanDeadAmt
            : "",
          humanTouch: dataById.fm01.humanTouchAmt
            ? dataById.fm01.humanTouchAmt
            : "",
          animalAround5km: dataById.fm01.animalAround5km
            ? dataById.fm01.animalAround5km
            : "",
        },
        addSymptoms: step1_symptomData,
        step2: {
          house: houseArray,
          houseList: arrayHouseList,
          res_animals: animalRes,
        },

        step3: {
          detention: (dataById.fm0103 && dataById.fm0103.detention) || "",
          detentionDay: (dataById.fm0103 && dataById.fm0103.detentionDay) || "",
          treatment_animal:
            (dataById.fm0103 && dataById.fm0103.treatment) || "",
          bury: (dataById.fm0103 && dataById.fm0103.destruction1) || "",
          burn: (dataById.fm0103 && dataById.fm0103.destruction2) || "",
          throw_away: (dataById.fm0103 && dataById.fm0103.destruction3) || "",
          send_kill: (dataById.fm0103 && dataById.fm0103.destruction4) || "",
          destruction5: (dataById.fm0103 && dataById.fm0103.destruction5) || "",
          disinfect: (dataById.fm0103 && dataById.fm0103.cleaning) || "",
          other_disinfect:
            (dataById.fm0103 && dataById.fm0103.cleaningOther) || "",
          vaccine: (dataById.fm0103 && dataById.fm0103.vaccination) || "",
          drop_anti_insect:
            (dataById.fm0103 && dataById.fm0103.antiInsect1) || "",
          inject_anti_insect:
            (dataById.fm0103 && dataById.fm0103.antiInsect2) || "",
          deal_anti_insect:
            dataById.fm0103 && dataById.fm0103.medicine == 1 ? true : false,
          public_relations:
            dataById.fm0103 && dataById.fm0103.educate == 1 ? true : false,
          filesPreview: fileStep3,
        },

        step4: {
          data_file: fileStep4,
          checkAddFile: false,
        },

        step5: {
          data_file: fileStep5,
          checkAddFile: false,
        },
      });
    }
  };

  // console.log("form",form);

  const getDiseaseStatementById = async (id) => {
    let dataRes;
    try {
      const response = location.pathname.includes("edit")
        ? await API.getDiseaseStatementById(id)
        : await API.getCopyDiseaseStatementById(id);
      if (response.status == 200) {
        dataRes = await response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.fm01 && dataById.fm01.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.fm01.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.fm01.provinceId);
        }
      }

      if (dataById.fm01 && dataById.fm01.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.fm01.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.fm01.amphurId);
        }
      }

      if (dataById.fm01 && dataById.fm01.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.fm01.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.fm01.tambonId);
        }
      }

      if (dataById.fm01 && dataById.fm01.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.fm01.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.fm01 && dataById.fm01.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.fm01.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      //map data step 2
      const houseArray = [];
      const animalRes = [];

      if (dataById.fm0102 && dataById.fm0102.barns) {
        const barn = dataById.fm0102.barns;
        if (barn && barn.length > 0) {
          barn.map((barns, b) => {
            if (checkAnimalType(barns.animalType)) {
              houseArray.push({
                barnId: barns.id,
                name: barns.name,
                desc: barns.animalType.map((type, t) => {
                  return {
                    id: type.id,
                    animalTypeId: type.animalTypeId,
                    animalTypeName: formJson.animalId[type.animalTypeId],
                    totalAmt: type.totalAmt,
                    sickAmt: type.sickAmt,
                    deadAmt: type.deadAmt,
                    sickDeadAmt: type.sickDeadAmt,
                    checkEdit: false,
                  };
                }),
                animals: [],
              });
            } else {
              getFarmInDisease(dataById.fm01?.farmId, dataById);
            }
          });
        }
      }

      let arrayHouseList = [];
      if (houseArray && houseArray.length > 0) {
        houseArray.forEach((house) =>
          arrayHouseList.push({ value: house.barnId, label: house.name })
        );

        if (dataById.fm0102 && dataById.fm0102.animalResponse) {
          let animal_res = dataById.fm0102.animalResponse;

          if (animal_res && animal_res.length > 0) {
            animal_res.map((animal, a) => {
              animalRes.push({
                id: animal.id,
                fm01Id: animal.fm01Id,
                animalTypeName: formJson.animalId[animal.animalTypeId],
                animalTypeId: animal.animalTypeId,
                animalId: animal.animalCode,
                date_dead: animal.deadDate,
                status_id: animal.statusId,
                cause_death_type: animal.deadCause,
                cause_death_type_other: animal.deadReason,
                name: animal.name,
                genderType: animal.sex,
                gender:
                  animal.sex === "m"
                    ? "ตัวผู้"
                    : animal.sex === "f"
                    ? "ตัวเมีย"
                    : "ไม่ทราบ",
                house_buiding: houseArray.map((house) => {
                  if (house.barnId == animal.barnId) {
                    return house.name;
                  }
                }),
                house_buiding_id: animal.barnId,
                age_year: animal.ageYear,
                age_month: animal.ageMonth,
                no_nid: animal.nid,
                no_microchip: animal.microchip,
                breed: animal.species,
                purpose_raising: animal.purposeId,
                purpose_name: formJson.animalPurpose[animal.purposeId],
                no_nid: animal.nidCode,
                no_animal_ear: animal.nid,
                style_raising: animal.husbandryId,
                style_raising_name: formJson.husbandry[animal.husbandryId],
                is_vaccine: animal.vaccinationStatus,
              });
            });
          }
        }
      }

      let fileStep3 = [];
      if (dataById.detentionFiles && dataById.detentionFiles.length > 0) {
        dataById.detentionFiles.map(async (item, i) => {
          fileStep3.push({
            base64: location.pathname.includes("edit")
              ? item.url
              : await getBase64FromUrl(item.url),
            name: item.name,
            id: item.id,
            url: item.url,
          });
        });
      }

      let fileStep4 = [];
      if (dataById.diseaseFiles && dataById.diseaseFiles.length > 0) {
        dataById.diseaseFiles.map(async (item, i) => {
          fileStep4.push({
            id: item.id,
            file: location.pathname.includes("edit")
              ? item.url
              : await getBase64FromUrl(item.url),
            fileName: item.name,
            detail_file: item.description,
            date_disease: item.date,
            url: item.url,
          });
        });
      }

      let fileStep5 = [];
      if (dataById.otherFiles && dataById.otherFiles.length > 0) {
        dataById.otherFiles.map(async (item, i) => {
          fileStep5.push({
            id: item.id,
            file: location.pathname.includes("edit")
              ? item.url
              : await getBase64FromUrl(item.url),
            name: item.name,
            description: item.description,
            date_file: item.date,
            url: item.url,
          });
        });
      }

      let step1_symptomData = [];
      if (dataById.fm01Symptomss && dataById.fm01Symptomss.length > 0) {
        dataById.fm01Symptomss.map((item, i) => {
          step1_symptomData.push({
            id: item.id ? item.id : "",
            symptom: { value: item.symptomId, label: item.symptomName },
            save: false,
          });
        });
      }

      if (dataById) {
        setForm({
          ...form,
          dataAllById: dataById,

          modeGet: "dsGetById",
          farmId: dataById.fm01?.farmId,
          outbreakId: dataById.outbreak?.id,
          outbreakCode: dataById.outbreak?.obCode,
          caseCode: dataById.fm01?.caseCode,
          provinceId: dataById.fm01?.provinceId,
          districtId: dataById.fm01?.amphurId,
          subDistrictId: dataById.fm01?.tambonId,
          villageId: dataById.fm01?.mooId,
          homeno: dataById.fm01?.address,
          phone: dataById.fm01?.ownerTel,
          name: dataById.fm01?.ownerName,
          orgId: dataById.fm01?.orgId,
          orgName: dataById.fm01?.orgName,
          outbreakoldId: dataById.outbreak?.id,
          caseCodeOld: dataById.fm01?.caseCode,

          step1: {
            provinceId: dataById.fm01?.provinceId,
            districtId: dataById.fm01?.amphurId,
            subDistrictId: dataById.fm01?.tambonId,
            villageId: dataById.fm01?.mooId,
            homeno: dataById.fm01?.address,
            localGovernId: dataById.fm01?.dlaId,
            governmentName: dataById.fm01?.dlaName,

            animalId: dataById.fm01?.animalTypeId,
            diseaseTypeId: dataById.fm01?.diseaseTypeId,
            animal_sickId: dataById.fm01?.symptomId,
            date_found: dataById.fm01.foundDiseaseDate
              ? dataById.fm01.foundDiseaseDate
              : "",
            date_found_first: dataById.fm01.firstAnimSymptomDate
              ? dataById.fm01.firstAnimSymptomDate
              : "",
            report_from: dataById.fm01.informBy ? dataById.fm01.informBy : "",
            other_text: dataById.fm01.remark ? dataById.fm01.remark : "",

            humanSick: dataById.fm01.humanSickAmt
              ? dataById.fm01.humanSickAmt
              : "",
            humanDead: dataById.fm01.humanDeadAmt
              ? dataById.fm01.humanDeadAmt
              : "",
            humanTouch: dataById.fm01.humanTouchAmt
              ? dataById.fm01.humanTouchAmt
              : "",
            animalAround5km: dataById.fm01.animalAround5km
              ? dataById.fm01.animalAround5km
              : "",
          },
          addSymptoms: step1_symptomData,
          step2: {
            house: houseArray,
            houseList: arrayHouseList,
            res_animals: animalRes,
          },

          step3: {
            detention: (dataById.fm0103 && dataById.fm0103.detention) || "",
            detentionDay:
              (dataById.fm0103 && dataById.fm0103.detentionDay) || "",
            treatment_animal:
              (dataById.fm0103 && dataById.fm0103.treatment) || "",
            bury: (dataById.fm0103 && dataById.fm0103.destruction1) || "",
            burn: (dataById.fm0103 && dataById.fm0103.destruction2) || "",
            throw_away: (dataById.fm0103 && dataById.fm0103.destruction3) || "",
            send_kill: (dataById.fm0103 && dataById.fm0103.destruction4) || "",
            destruction5:
              (dataById.fm0103 && dataById.fm0103.destruction5) || "",
            disinfect: (dataById.fm0103 && dataById.fm0103.cleaning) || "",
            other_disinfect:
              (dataById.fm0103 && dataById.fm0103.cleaningOther) || "",
            vaccine: (dataById.fm0103 && dataById.fm0103.vaccination) || "",
            drop_anti_insect:
              (dataById.fm0103 && dataById.fm0103.antiInsect1) || "",
            inject_anti_insect:
              (dataById.fm0103 && dataById.fm0103.antiInsect2) || "",
            deal_anti_insect:
              dataById.fm0103 && dataById.fm0103.medicine == 1 ? true : false,
            public_relations:
              dataById.fm0103 && dataById.fm0103.educate == 1 ? true : false,
            filesPreview: fileStep3,
          },

          step4: {
            data_file: fileStep4,
            checkAddFile: false,
          },

          step5: {
            data_file: fileStep5,
            checkAddFile: false,
          },
        });
      }
      setCheckEditAnimal(false);
    }
  };

  const getFarmById = async (id) => {
    dispatch(loading(true));
    let dataRes;
    try {
      const response = await API.getFarmRegistrationById(id);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.farm && dataById.farm.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.farm.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        // if (districtList.length == 0) {
        await getDistricts(dataById.farm.provinceId);
        // }
      }

      if (dataById.farm && dataById.farm.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.farm.amphurId
        );
        setDistrictSelectValue(districtSelect);
        // if (subDistrictList.length == 0) {
        await getSubDistricts(dataById.farm.amphurId);
        // }
      }

      if (dataById.farm && dataById.farm.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.farm.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        // if (villageList.length == 0) {
        await getVillage(dataById.farm.tambonId);
        // }
      }

      if (dataById.farm && dataById.farm.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.farm.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.farm && dataById.farm.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.farm.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      const houseArray = [];
      let vaccineArray;
      if (dataById.barn && dataById.barn.length > 0) {
        dataById.barn.map((barns, b) => {
          houseArray.push({
            barnId: barns.id,
            name: barns.name,
            desc: barns.animalType.map((type, t) => {
              return {
                animalTypeId: type.animalTypeId,
                animalTypeName: formJson.animalId[type.animalTypeId],
                totalAmt: type.amount,
                level1Id: "",
                level2Id: "",
                level3Id: "",
                checkEdit: false,
              };
            }),
            animals: barns.animals.map((animal, a) => {
              if (barns.id == animal.barnId) {
                if (animal.vaccine && animal.vaccine.length > 0) {
                  vaccineArray = animal.vaccine.map((vaccine, v) => {
                    return {
                      diseaseTypeName:
                        formJson.diseaseTypeId[vaccine.diseaseTypeId],
                      diseaseTypeId: vaccine.diseaseTypeId,
                      last_date: vaccine.vaccineDate,
                      lotNo: vaccine.lotNo,
                      operator: vaccine.injectBy,
                      other_vaccine: vaccine.injectOther,
                      had_disease: vaccine.diseaseHistory,
                      vaccineTypeId: vaccine.vaccineTypeId,
                      vaccineTypeName:
                        formJson.vaccineType[vaccine.vaccineTypeId],
                    };
                  });
                }

                return {
                  animalTypeName: formJson.animalId[animal.animalTypeId],
                  animalTypeId: animal.animalTypeId,
                  animalId: animal.animalCode,
                  date_dead: animal.deadDate,
                  status_id: animal.statusId,
                  cause_death_type: animal.deadCause,
                  cause_death_type_other: animal.deadReason,
                  name: animal.name,
                  genderType: animal.sex,
                  gender:
                    animal.sex === "m"
                      ? "ตัวผู้"
                      : animal.sex === "f"
                      ? "ตัวเมีย"
                      : "ไม่ทราบ",
                  house_buiding: barns.name,
                  age_year: animal.ageYear,
                  age_month: animal.ageMonth,
                  no_nid: animal.nid,
                  no_microchip: animal.microchip,
                  breed: animal.species,
                  purpose_raising: animal.purposeId,
                  purpose_name: formJson.animalPurpose[animal.purposeId],
                  no_nid: animal.nidCode,
                  no_animal_ear: animal.nid,
                  style_raising: animal.husbandryId,
                  style_raising_name: formJson.husbandry[animal.husbandryId],
                  is_vaccine: animal.vaccinationStatus,
                  choose_disease: vaccineArray,
                };
              }
            }),
          });
        });
      }

      let arrayHouseList = [];
      if (houseArray && houseArray.length > 0) {
        houseArray.forEach((house) =>
          arrayHouseList.push({ value: house.barnId, label: house.name })
        );
      }

      setForm({
        ...form,
        dataAllById: dataById,

        modeGet: "farmById",
        farmId: dataById.farm.id,

        provinceId: dataById.farm.provinceId,
        districtId: dataById.farm.amphurId,
        subDistrictId: dataById.farm.tambonId,
        villageId: dataById.farm.mooId,
        homeno: dataById.farm.homeNo,

        phone: dataById.farmer.mobile,
        name: `${dataById.farmer.firstName} ${dataById.farmer.lastName}`,

        step1: {
          provinceId: dataById.farm.provinceId,
          districtId: dataById.farm.amphurId,
          subDistrictId: dataById.farm.tambonId,
          villageId: dataById.farm.mooId,
          homeno: dataById.farm.homeNo,
          localGovernId: dataById.farm.dlaId,
          governmentName: dataById.farm.dlaName,
        },

        step2: {
          house: houseArray,
          houseList: arrayHouseList,
          res_animals: [],
          list_animals: [],
        },
      });

      dispatch(loading(false));
    }
  };

  const getOutbreakById = async (id, type) => {
    let dataRes;
    try {
      const response = await API.getOutbreakById(id);
      if (response.status === 200) {
        dataRes = response.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.fm01 && dataById.fm01.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.fm01.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }
      setForm({
        ...form,
        mode: "add",
        dataAllById: dataById,
        outbreakCode: dataById.outbreak.obCode,
        orgId: dataById.fm01.orgId,
        orgName: dataById.fm01.orgName,
      });
    }
  };

  const toCancel = () => {
    history.push("/passive_surv/disease_statement");
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "/passive_surv/disease_statement", name: "กคร.1/รก.1" },
    { path: "title", name: "บันทึกการเกิดโรคระบาดสัตว์เบื้องต้น" },
  ];

  const setOrganizes = (data) => {
    removeInvalid("orgId");
    setForm({ ...form, orgId: data.value, orgName: data.label });
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validate = () => {
    let validated = true;

    if (form.orgId === "") {
      addInvalid("orgId", "กรุณาเลือกหน่วยงานที่แจ้งโรค");
      validated = false;
    }
    if (form.farmId === "") {
      addInvalid("farmId", "กรุณาเลือกรหัสฟาร์ม");
      validated = false;
    }
    return validated;
  };

  const toSaveDiseaseStatement = async () => {
    if (!form.outbreakCode && !location.pathname.includes("save")) {
      MySwal.fire({
        text: "กรุณาเลือกเลข Outbreak ID",
        icon: "warning",
        confirmButtonText: "ตกลง",
      });
    } else if (validate()) {
      const dataById = form.dataAllById;
      const dataStep1 = form.step1;
      const dataStep2 = form.step2;
      const dataStep3 = form.step3;
      const dataStep4 = form.step4;
      const dataStep5 = form.step5;
      const dataStep1Symptoms = form.addSymptoms;
      const delSymptomsData = form.delSymptoms;

      const symptomsDel = [];
      if (delSymptomsData && delSymptomsData.length > 0) {
        delSymptomsData.map((item) => {
          symptomsDel.push(item);
        });
      }

      const dataSymptoms = [];
      if (dataStep1Symptoms && dataStep1Symptoms.length > 0) {
        dataStep1Symptoms.map((item, i) => {
          dataSymptoms.push({
            id: item.id ? item.id : "",
            symptomId: item.symptom.value,
          });
        });
      }

      const barnsStep2 = [];
      if (dataStep2.house && dataStep2.house.length > 0) {
        dataStep2.house.map((item, i) => {
          let animalType;
          let animalsArray;
          if (item.desc && item.desc.length > 0) {
            animalType = item.desc.map((desc, d) => {
              return {
                id: desc.id ? desc.id : "",
                animalTypeId: desc.animalTypeId,
                totalAmt: desc.totalAmt,
                sickAmt: desc.sickAmt,
                deadAmt: desc.deadAmt,
                sickDeadAmt: desc.sickDeadAmt,
              };
            });
          } else {
            animalType = [];
          }

          if (item.animals && item.animals.length > 0) {
            animalsArray = item.animals.map((animal, a) => {
              let vaccine;
              if (animal.choose_disease && animal.choose_disease.length > 0) {
                vaccine = animal.choose_disease.map((disease, d) => {
                  return {
                    id: "",
                    diseaseTypeId: disease.diseaseTypeId,
                    vaccineTypeId: disease.vaccineTypeId,
                    vaccineDate: disease.last_date
                      ? helper.getDate(disease.last_date)
                      : "",
                    lotNo: disease.lotNo,
                    injectBy: disease.operator,
                    injectOther: disease.other_vaccine,
                    diseaseHistory: disease.had_disease,
                  };
                });
              }

              return {
                id: "",
                animalTypeId: animal.animalTypeId,
                animalCode: animal.animalId,
                statusId: animal.status_id,
                deadDate: animal.date_dead,
                deadCause: animal.cause_death_type,
                deadReason: animal.cause_death_type_other,
                name: animal.name,
                sex: animal.genderType,
                ageYear: animal.age_year,
                ageMonth: animal.age_month,
                nid: animal.no_animal_ear,
                microchip: animal.no_microchip,
                species: animal.breed,
                purposeId: animal.purpose_raising,
                nidCode: animal.no_nid,
                husbandryId: animal.style_raising,
                vaccinationStatus: animal.is_vaccine,
                vaccine: vaccine ? vaccine : [],
              };
            });
          }

          barnsStep2.push({
            id: item.barnId,
            farmId: form.checkMode == "edit" ? form.farmId : "",
            orderIndex: i + 1,
            name: item.name,
            totalAmt: "",
            animalType: animalType,
            animals: animalsArray ? animalsArray : [],
          });
        });
      }

      const listAnimalChoose = [];
      if (dataStep2.res_animals && dataStep2.res_animals.length > 0) {
        dataStep2.res_animals.map((item, i) => {
          listAnimalChoose.push(item.id);
        });
      }

      const fileDataStep3 = [];
      if (dataStep3.filesPreview && dataStep3.filesPreview.length > 0) {
        dataStep3.filesPreview.map((item, i) => {
          if (item.id) {
          } else {
            fileDataStep3.push({
              name: item.name,
              description: "",
              date: "",
              data: item.base64.replace("data:", "").replace(/^.+,/, ""),
              id: item.id ? item.id : "",
            });
          }
        });
      }

      const diseaseFilesStep4 = [];
      if (dataStep4.data_file && dataStep4.data_file.length > 0) {
        dataStep4.data_file.map((item, i) => {
          if (item.id) {
          } else {
            diseaseFilesStep4.push({
              id: item.id ? item.id : "",
              name: item.fileName,
              description: item.detail_file,
              // date: item.date_disease ? helper.getDate(item.date_disease) : "",
              date: item.date_disease
                ? helper.getDate(item.date_disease)
                : location.pathname.includes("duplicate")
                ? helper.getDate(item.date_disease)
                : "",
              data: location.pathname.includes("duplicate")
                ? ""
                : item.file.replace("data:", "").replace(/^.+,/, ""),
            });
          }
        });
      }

      const otherFilesStep5 = [];
      if (dataStep5.data_file && dataStep5.data_file.length > 0) {
        dataStep5.data_file.map((item, i) => {
          if (item.id) {
          } else {
            otherFilesStep5.push({
              id: item.id ? item.id : "",
              name: item.name,
              description: item.description,
              // date: item.date_file ? helper.getDate(item.date_file) : "",
              date: item.date_file
                ? helper.getDate(item.date_file)
                : location.pathname.includes("duplicate")
                ? helper.getDate(item.date_file)
                : "",
              data: location.pathname.includes("duplicate")
                ? ""
                : item.file.replace("data:", "").replace(/^.+,/, ""),
            });
          }
        });
      }

      const delFile = [];
      if (dataStep3.filesDelete && dataStep3.filesDelete.length > 0) {
        dataStep3.filesDelete.map((item) => {
          delFile.push(item);
        });
      }

      if (dataStep4.del_file && dataStep4.del_file.length > 0) {
        dataStep4.del_file.map((item) => {
          delFile.push(item);
        });
      }

      if (dataStep5.del_file && dataStep5.del_file.length > 0) {
        dataStep5.del_file.map((item) => {
          delFile.push(item);
        });
      }

      const delAnimal = [];
      if (dataStep2.del_animals && dataStep2.del_animals.length > 0) {
        dataStep2.del_animals.map((item) => {
          delAnimal.push(item);
        });
      }

      const data = {
        outbreak: {
          obCode:
            form.modeGet == "default" || location.pathname.includes("duplicate")
              ? ""
              : form.outbreakCode,
        },
        fm01: {
          id: location.pathname.includes("edit")
          ? props.match.params.id : null,
          caseCode: form.modeGet == "default" ? "" : form.caseCode,
          address: dataStep1.homeno,
          amphurId: dataStep1.districtId,
          animalAround5km: dataStep1.animalAround5km,
          animalTypeId: dataStep1.animalId,
          diseaseTypeId: dataStep1.diseaseTypeId,
          dlaId: dataStep1.localGovernId,
          dlaName: dataStep1.governmentName,
          farmId: form.modeGet == "default" ? "" : form.farmId,
          firstAnimSymptomDate: dataStep1.date_found_first
            ? helper.getDate(dataStep1.date_found_first)
            : "",
          foundDiseaseDate: dataStep1.date_found
            ? helper.getDate(dataStep1.date_found)
            : "",
          humanDeadAmt: dataStep1.humanDead,
          humanSickAmt: dataStep1.humanSick,
          humanTouchAmt: dataStep1.humanTouch,
          informBy: dataStep1.report_from,
          informOther: dataStep1.other_text,
          informId: null,
          mooId: dataStep1.villageId,
          obId: form.modeGet == "default" ? "" : form.outbreakId,
          orgId: form.modeGet == "default" ? "" : form.orgId,
          ownerName: form.name,
          ownerTel: form.phone,
          provinceId: dataStep1.provinceId,
          statusId: dataStep1.statusId,
          symptomId: dataStep1.animal_sick,
          tambonId: dataStep1.subDistrictId,
        },
        fm01Symptoms: dataSymptoms, //ใช้ fm01Symptoms เข้าเส้นนี้ แต่พอเพิ่มsไปอีกตัวไม่เข้า
        fm0102: {
          barns: barnsStep2,
          animals: listAnimalChoose,
        },
        fm0103: {
          fm01Id: form.modeGet == "default" ? "" : form.farmId,
          detention: dataStep3.detention,
          detentionDay: dataStep3.detentionDay,
          treatment: dataStep3.treatment_animal,
          destruction1: dataStep3.bury,
          destruction2: dataStep3.burn,
          destruction3: dataStep3.throw_away,
          destruction4: dataStep3.send_kill,
          destruction5: dataStep3.destruction5,
          cleaning: dataStep3.disinfect,
          cleaningOther: dataStep3.other_disinfect,
          vaccination: dataStep3.vaccine,
          antiInsect1: dataStep3.drop_anti_insect,
          antiInsect2: dataStep3.inject_anti_insect,
          medicine: dataStep3.deal_anti_insect ? 1 : 2,
          educate: dataStep3.public_relations ? 1 : 2,
        },
        detentionFiles: fileDataStep3,
        diseaseFiles: diseaseFilesStep4,
        otherFiles: otherFilesStep5,
        deleteFiles: delFile,
        deleteAnimals: delAnimal,
        deleteSymptom: symptomsDel,
      };
      // console.log("form",data);
      // return
      MySwal.fire({
        text: `ต้องการบันทึก`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (value) => {
        if (value.isConfirmed) {
          try {
              const response = await API.addDiseaseStatement(data);
                console.log("addDiseaseStatement-data-->", response, data);
                if (response.status == 200) {
                  MySwal.fire({
                    text: location.pathname.includes("save")
                      ? `สร้าง "กคร.1/รก.1" สำเร็จ`
                      : location.pathname.includes("duplicate")
                      ? `คัดลอก "กคร.2" สำเร็จ`
                      : `แก้ไข "กคร.1/รก.1" สำเร็จ`,
                    icon: "success",
                    confirmButtonText: "ตกลง",
                  }).then(async (result) => {
                    history.push(`/passive_surv/disease_statement`);
                  });
                }
          } catch (error) {
            dispatch(loading(false));
            if (error.response && error.response.status == 401) {
              dispatch(logout({ history }));
            } else if (error.response && error.response.status === 400) {
              MySwal.fire({
                text: "บันทึกไม่สำเร็จ กรุณากรอกข้อมูลให้ครบถ้วน",
                icon: "warning",
                confirmButtonText: "ตกลง",
              });
            } else {
              MySwal.fire({
                text: "บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
                icon: "warning",
                confirmButtonText: "ตกลง",
              });
            }
            console.log("error", error, data);
          }
        }
      });
    }
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2 ">
          <Link to={`/passive_surv/disease_statement`}>
            <button className="btn btn-danger" onClick={() => toCancel()}>
              ยกเลิก
            </button>
          </Link>
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-success"
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`passive_surv/disease_statement/edit`)
            }
            onClick={() => toSaveDiseaseStatement()}
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxHead title="ตัวกรอง" classLabel="">
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="d-flex align-items-center">
                    <InputText
                      type="text"
                      title="เลข Outbreak ID"
                      idName="outbreakCode"
                      value={form.outbreakCode}
                      star={true}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      disabled
                      handleChange={(value) =>
                        setForm({ ...form, outbreakCode: value })
                      }
                    />

                    <button
                      className="btn btn-add mt-auto mb-3 ml-2"
                      onClick={() => setShowOutbreakSearch(true)}
                    >
                      <img
                        className=" icon-btn "
                        src="/svg/btn-edit.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                </Col>

                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="d-flex align-items-center">
                    <InputText
                      type="text"
                      title="เลข case ID"
                      idName="caseCode"
                      value={form.caseCode}
                      star={false}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      disabled
                      handleChange={(value) =>
                        setForm({ ...form, caseCode: value })
                      }
                    />

                    {/* <button
                      className="btn btn-add mt-auto mb-3 ml-2 "
                      onClick={() => setShowCaseIdSearch(true)}
                    >
                      <img
                        className="icon-btn "
                        src="/svg/btn-edit.svg"
                        alt="edit"
                      />
                    </button> */}
                  </div>
                </Col>

                <Col lg={2} md={4} sm={12} xs={12}>
                  <label className="mb-0 normal">หน่วยงานที่แจ้งโรค </label>
                  <div className="d-flex align-items-center">
                    {form.orgName ? (
                      <>
                        <button
                          className="btn btn-primary pt-2 px-3"
                          onClick={() => setShowOrgModal(true)}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="ml-2">{form.orgName}</div>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary p-2 d-flex align-items-center"
                        onClick={() => setShowOrgModal(true)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        <p className="mb-0 ml-2">{t("search")}</p>
                      </button>
                    )}
                  </div>
                  {invalid.orgId ? (
                    <div className="invalid-error">{invalid.orgId}</div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={3} sm={6} xs={6}>
                  <div onClick={() => setShowFarmSearch(true)}>
                    <InputGroupText
                      type="text"
                      title="รหัสฟาร์ม"
                      imgSrc={"/svg/search.svg"}
                      imgAlt="search-icon"
                      idName="farmId"
                      value={form.farmId}
                      star={false}
                      placeholder="ค้นหาฟาร์ม"
                      classLabel="normal"
                      handleChange={(value) =>
                        setForm({ ...form, farmId: value })
                      }
                      invalid={invalid.farmId}
                    />
                  </div>
                </Col>

                <Col lg={2} md={3} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    <button
                      className="btn btn-darkblue w-100"
                      onClick={() =>
                        history.push("/farm_registration/save-data")
                      }
                    >
                      <img
                        className="mr-2 mb-1 "
                        src={"/svg/menu_5.svg"}
                        alt="menu-icon"
                      />
                      ขึ้นทะเบียนฟาร์ม
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ชื่อเจ้าของสัตว์"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputMask
                    mask="99-9999-9999"
                    title="หมายเลขโทรศัพท์"
                    idName="phone"
                    value={form.phone}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, phone: value.replace(/-/g, "") })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="บ้านเลขที่"
                    idName="homeno"
                    value={form.homeno}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, homeno: value })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    disabled={true}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    disabled={true}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    disabled={true}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="หมู่ที่"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="villageId"
                    classLabel="normal"
                    disabled={true}
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({ ...form, villageId: value.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxHead>

          <div
            className="pt-3 container-fixed-footer ml-0"
            disabled={location.pathname.includes("save") ? true : false}
          >
            <ul
              className="nav nav-pills mb-3 font14"
              id="pills-tab"
              role="tablist"
            >
              <li
                className="nav-item "
                disabled={
                  location.pathname.includes("from-disease") ||
                  location.pathname.includes("duplicate")
                    ? true
                    : false || disableBtn
                }
              >
                <a
                  className="nav-link active "
                  id="pills-data-tab"
                  data-toggle="pill"
                  href="#pills-data"
                  role="tab"
                  aria-controls="pills-data"
                  aria-selected="true"
                >
                  1.ข้อมูลทั่วไป
                </a>
              </li>
              <li className="nav-item" disabled={disableBtn}>
                <a
                  className="nav-link "
                  id="pills-animal-tab"
                  data-toggle="pill"
                  href="#pills-animal"
                  role="tab"
                  aria-controls="pills-animal"
                  aria-selected="false"
                >
                  2.ข้อมูลสัตว์
                </a>
              </li>
              <li className="nav-item" disabled={disableBtn}>
                <a
                  className="nav-link "
                  id="pills-carryout-tab"
                  data-toggle="pill"
                  href="#pills-carryout"
                  role="tab"
                  aria-controls="pills-carryout"
                  aria-selected="false"
                >
                  3.การรักษาเบื้องต้น
                </a>
              </li>
              <li className="nav-item" disabled={disableBtn}>
                <a
                  className="nav-link "
                  id="pills-disease-tab"
                  data-toggle="pill"
                  href="#pills-disease"
                  role="tab"
                  aria-controls="pills-disease"
                  aria-selected="false"
                >
                  4.ข้อมูลรอยโรค
                </a>
              </li>
              <li className="nav-item" disabled={disableBtn}>
                <a
                  className="nav-link "
                  id="pills-file-tab"
                  data-toggle="pill"
                  href="#pills-file"
                  role="tab"
                  aria-controls="pills-file"
                  aria-selected="false"
                >
                  5.แนบไฟล์
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-data"
                role="tabpanel"
                aria-labelledby="pills-data-tab"
              >
                {form.modeGet == "default" && (
                  <OutbreakStep1
                    save={(data, addSymptoms, delSymptoms) => {
                      setForm({
                        ...form,
                        step1: data,
                        addSymptoms: addSymptoms,
                        delSymptoms: delSymptoms,
                      });
                    }}
                  />
                )}
                {form.modeGet == "dsGetById" ? (
                  <OutbreakStep1
                    save={(data, addSymptoms, delSymptoms) => {
                      setForm({
                        ...form,
                        step1: data,
                        addSymptoms: addSymptoms,
                        delSymptoms: delSymptoms,
                      });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step1}
                    symptomsData={form.addSymptoms}
                  />
                ) : (
                  ""
                )}

                {form.modeGet == "farmById" ? (
                  <OutbreakStep1
                    save={(data, addSymptoms, delSymptoms) => {
                      setForm({
                        ...form,
                        step1: data,
                        addSymptoms: addSymptoms,
                        delSymptoms: delSymptoms,
                      });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step1}
                    symptomsData={form.addSymptoms}
                  />
                ) : (
                  ""
                )}
              </div>

              <div
                className="tab-pane fade"
                id="pills-animal"
                role="tabpanel"
                aria-labelledby="pills-animal-tab"
              >
                <div className="bg-white">
                  {form.modeGet == "default" && (
                    <OutbreakStep2
                      save={(data) => {
                        setForm({ ...form, step2: data });
                      }}
                    />
                  )}
                  {form.modeGet == "dsGetById" ? (
                    <OutbreakStep2
                      save={(data) => {
                        setForm({ ...form, step2: data });
                      }}
                      modeGet={form.modeGet}
                      dataAll={form.step2}
                      farmId={form.farmId}
                      checkEditAnimal={checkEditAnimal}
                      getDiseaseById={() => {
                        setCheckEditAnimal(true);
                        getDiseaseStatementById(props.match.params.id);
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {form.modeGet == "farmById" ? (
                    <OutbreakStep2
                      save={(data) => {
                        setForm({ ...form, step2: data });
                      }}
                      modeGet={form.modeGet}
                      dataAll={form.step2}
                      farmId={form.farmId}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-carryout"
                role="tabpanel"
                aria-labelledby="pills-carryout-tab"
              >
                <div className=" bg-white ">
                  {form.modeGet == "default" && (
                    <DiseaseReport_Step3
                      save={(data) => {
                        setForm({ ...form, step3: data });
                      }}
                    />
                  )}
                  {form.modeGet == "dsGetById" ? (
                    <DiseaseReport_Step3
                      save={(data) => {
                        setForm({ ...form, step3: data });
                      }}
                      modeGet={form.modeGet}
                      dataAll={form.step3}
                    />
                  ) : (
                    ""
                  )}
                  {form.modeGet == "farmById" ? (
                    <DiseaseReport_Step3
                      save={(data) => {
                        setForm({ ...form, step3: data });
                      }}
                      modeGet={form.modeGet}
                      dataAll={form.step3}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-disease"
                role="tabpanel"
                aria-labelledby="pills-disease-tab"
              >
                <div className=" bg-white ">
                  {form.modeGet == "default" && (
                    <DiseaseReport_Step4
                      save={(data) => {
                        setForm({ ...form, step4: data });
                      }}
                    />
                  )}

                  {form.modeGet == "dsGetById" ? (
                    <DiseaseReport_Step4
                      save={(data) => {
                        setForm({ ...form, step4: data });
                      }}
                      modeGet={form.modeGet}
                      dataAll={form.step4}
                    />
                  ) : (
                    ""
                  )}

                  {form.modeGet == "farmById" ? (
                    <DiseaseReport_Step4
                      save={(data) => {
                        setForm({ ...form, step4: data });
                      }}
                      modeGet={form.modeGet}
                      dataAll={form.step4}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-file"
                role="tabpanel"
                aria-labelledby="pills-file-tab"
              >
                {form.modeGet == "default" && (
                  <OutbreakStep7
                    save={(data) => {
                      setForm({ ...form, step5: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step5}
                  />
                )}

                {form.modeGet == "dsGetById" ? (
                  <OutbreakStep7
                    save={(data) => {
                      setForm({ ...form, step5: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step5}
                  />
                ) : (
                  ""
                )}

                {form.modeGet == "farmById" ? (
                  <OutbreakStep7
                    save={(data) => {
                      setForm({ ...form, step5: data });
                    }}
                    modeGet={form.modeGet}
                    dataAll={form.step5}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {/* {showOutbreakSearch &&
          <ModalOutbreakSearch show={showOutbreakSearch} mode={form.modeOutbreak} close={() => setShowOutbreakSearch(false)}
            save={(value) => setForm({ ...form, outbreakCode: value.obCode, outbreakId: value.obId })} />
        } */}
        {showOutbreakSearch && (
          <ModalOutbreakSearch
            show={showOutbreakSearch}
            mode={form.modeOutbreak}
            close={() => setShowOutbreakSearch(false)}
            save={(value) =>
              
              setForm({
                ...form,
                outbreakCode: value.obCode,
                outbreakId: value.obId,
                caseCode: value.obId != form.outbreakoldId ? "" : form.caseCodeOld
              })
            }
          />
        )}
        {showFarmSearch && (
          <ModalFarmSearch
            show={showFarmSearch}
            mode={form.modeFarmSearch}
            close={() => setShowFarmSearch(false)}
            save={(value) => {
              setForm({ ...form, farmId: value });
              removeInvalid("farmId");
            }}
          />
        )}
        {showOrgModal && (
          <ModalOrganizes
            show={showOrgModal}
            user={user.profile}
            close={() => setShowOrgModal(false)}
            toSaveOrg={(data) => setOrganizes(data)}
          />
        )}
      </div>
    </div>
  );
}

export default Save_Disease_Statement;
