import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loading } from "../../../redux/actions/loadingAction";
import { logout } from "../../../redux/actions/logoutAction";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { Row, Col } from "react-bootstrap";

import * as API from "../../../utils/apis";

import BoxCard from "../../../components/BoxCard";
import InputSelect from "../../../components/InputSelect";
import Breadcrumb from "../../../components/Breadcrumb";
import DataTableNodata from "../../../components/DataTableNodata";
import DataTable from "../../../components/DataTable";
import ModalOganizes from "../../../components/Data_Management/Modal.Organizes";
import ModalSeachOrg from "../../../components/Modal.Organizes";
import { useMasterData } from "../../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function Organizes() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t, i18n } = useTranslation(["common", "footer"]);
  const statusCheck = useSelector((state) => state.statusCheck);
  const initialForm = {
    //data
    organizeName: "",
    oganizeId: "",
    parentId: "",
    levelId: "",
    organize: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    status: "",

    formData: null,
    mode: "",

    //page
    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,

    headTitle: [
      {
        name: "ลำดับ",
        sortFlg: false,
        width: "50px",
      },
      {
        sortBy: "levelId",
        name: "ชื่อหน่วยงาน",
        sortFlg: true,
      },
      {
        sortBy: "organize",
        name: "กลุ่มหน่วยงาน",
        sortFlg: false,
      },
      {
        sortBy: "provinceId",
        name: "จังหวัด",
        sortFlg: false,
      },
      {
        sortBy: "statusId",
        name: "สถานะ",
        sortFlg: true,
      },

      {
        name: "จัดการ",
        sortFlg: false,
      },
    ],
  };
  const [form, setForm] = useState(initialForm);

  const initialFormJson = {
    level: {},
    parent: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const [showOrgModal, setShowOrgModal] = useState(false);
  const [listOganizes, setListOganizes] = useState([]);
  const [listOganizesForEdit, setListOganizesForEdit] = useState([]);

  const [organizesList, setOrganizesList] = useState([]);

  const [showOganizes, setShowOganizes] = useState(false);

  // levelId
  const selectLevelList = [
    { value: 1, label: "ระดับกรม" },
    { value: 2, label: "ระดับเขตปศุสัตว์" },
    { value: 3, label: "ระดับจังหวัด" },
    { value: 4, label: "ระดับอำเภอ" },
    { value: 5, label: "ระดับตำบล" },
  ];
  const [levelList, setLevelList] = useState(selectLevelList);
  const initSelectLevel = [];
  const [levelSelectValue, setLevelSelectValue] = useState(initSelectLevel);

  // organize
  const initSelectOrganize = [];
  const [organizeSelectValue, setOrganizeSelectValue] =
    useState(initSelectOrganize);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  useMasterData("province", provinceList, setProvinceList);
  // =================

  const initStatusSelectValue = [];
  const [statusSelectValue, setStatusSelectValue] = useState(
    initStatusSelectValue
  );
  const selectStatusList = [
    { value: 1, label: "ใช้งาน" },
    { value: 2, label: "ไม่ใช้งาน" },
  ];
  const [statusList, setStatusList] = useState(selectStatusList);
  const [isClearSearch, setIsClearSearch] = useState(false);

  useEffect(() => {
    getProvince();
    getMasterJson();
  }, []);

  useEffect(() => {
    getOrganizes();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getOrganizes = async () => {
    try {
      const response = await API.getOrganizes(
        form.levelId,
        form.parentId,
        form.oganizeId,
        form.status,
        form.page,
        form.limit,
        "",
        form.sortBy,
        form.sortDir,
        form.provinceId,
        form.districtId,
        form.subDistrictId
      );
      const data = await response?.data;
      if (response.status === 200) {
        let organizesArray = [];
        data.content.forEach((element, index) => {
          organizesArray.push({
            id: element.id,
            name: element.name,
            organize: element.parentId,
            province: findProvinceName(element.code),
            statusId: element.statusId,
          });
        });
        setOrganizesList(organizesArray);
        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
        setListOganizes(organizesArray);
        setListOganizesForEdit(data.content);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const findProvinceName = (code) => {
    let name = "";
    if (code > 9999999) {
      //splitcode
      let codeString = code.toString().slice(0, 2) + "000000";
      let provObj = provinceList.find(
        (item) => item.value == Number(codeString)
      );
      name = provObj?.label;
      //find
    }
    return name;
  };

  const setOrganizes = (data) => {
    setForm({
      ...form,
      organizeId: data.value,
      organizeName: data.label,
      parentId: data.parentId,
    });
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response?.data;
      if (response.status === 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response?.data;
      if (response.status === 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id);
      const data = await response?.data;
      if (response.status === 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMasterJson = async () => {
    const params = ["level", "parent"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response?.data;
      if (response.status === 200) {
        setFormJson({
          ...formJson,
          level: data.level,
          parent: data.parent,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getStatusColor = (idStatus) => {
    let statusColor;
    if (statusCheck && statusCheck.length > 0) {
      statusCheck.map((item, index) => {
        if (item.id == idStatus) {
          statusColor = (
            <div className="d-flex justify-content-center">
              <div
                style={{ background: item.color }}
                className="box-status font14 px-4 text-center text-white"
              >
                <span>{item.status}</span>
              </div>
            </div>
          );
        }
      });
    }
    return statusColor;
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type === "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type === "limit") {
      setForm({
        ...form,
        page: 1,
        limit: isChange1,
      });
    } else if (type === "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    {
      path: "/data-manage/web-settings/news",
      name: "บริหารจัดการข้อมูลส่วนกลาง",
    },
    { path: "/data-manage/master-data/animal-type", name: "Master Data" },
    { path: "active", name: "หน่วยงานกรมปศุสัตว์" },
  ];

  const buttonAddOganizes = () => {
    return (
      <div className="d-flex justify-content-end ">
        <button
          className="btn btn-add text-white"
          onClick={() => toAddOrganizes()}
          disabled={user?.userGroup.mcreate === "n"}
        >
          <i className="fas fa-plus mr-1 "></i>เพิ่มข้อมูลหน่วยงาน
        </button>
      </div>
    );
  };

  const toSearch = () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setLevelSelectValue([]);
    setStatusSelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const buttonSearch = () => {
    return (
      <>
        <button className="btn btn-danger mr-2" onClick={() => clearSearch()}>
          ล้างข้อมูล
        </button>

        <button className="btn btn-success" onClick={() => toSearch()}>
          ค้นหา
        </button>
      </>
    );
  };

  const toAddOrganizes = () => {
    setForm({ ...form, mode: "add", idEdit: "" });
    setShowOganizes(true);
  };

  const editModalOrganizes = async (id) => {
    let selectOrganize = await listOganizesForEdit.find(
      (item) => item.id == id
    );
    setForm({ ...form, mode: "edit", editForm: selectOrganize });
    setShowOganizes(true);
  };

  const delOrganizes = async (id) => {
    MySwal.fire({
      text: `ยืนยันที่จะลบ `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response = await API.deleteOrganizes(id);
          if (response.status === 200) {
            MySwal.fire({
              text: `ลบสำเร็จ`,
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then(async (result) => {
              await getOrganizes();
            });
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status === 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: `ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const addSaveOrganizes = (data) => {
    const dataOrganize = {
      latitude: data.organize.latitude,
      longitude: data.organize.longitude,
      code: data.organize.code,
      levelId: data.organize.levelId,
      name: data.organize.organizeName,
      nameEn: data.organize.organizeNameEn,
      parentId: data.organize.parentId,
      statusId: data.organize.status,
      zipcode: data.organize.zipcode,
    };

    MySwal.fire({
      text: `ต้องการบันทึก `,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then(async (result) => {
      if (result.value) {
        try {
          const response =
            data.mode == "add"
              ? await API.saveOrganizes(dataOrganize)
              : await API.updateOrganizes(
                  data.organize.organizeId,
                  dataOrganize
                );
          if (response.status == 200) {
            await getOrganizes();
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text: "บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    });
  };

  const renderTableOganizes = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td>{item.name}</td>
          <td className="text-center">{item.organize}</td>
          <td className="text-center">{item?.province || "-"}</td>
          <td className="text-center">
            {item.statusId ? getStatusColor(item.statusId) : "-"}
          </td>
          <td>
            <div className="d-flex justify-content-center ">
              <button
                className="btn btn-add mr-2 "
                onClick={() => editModalOrganizes(item.id)}
                disabled={
                  user?.userGroup.mupdate === "n" &&
                  user?.userGroup.mview === "n"
                }
              >
                <img className="icon-btn" src="/svg/btn-edit.svg" alt="edit" />
              </button>
              <button
                className="btn btn-del mr-2 "
                onClick={() => delOrganizes(item.id)}
                disabled={user?.userGroup.mdelete === "n"}
              >
                <img className="icon-btn " src="/svg/btn-del.svg" alt="del" />
              </button>
            </div>
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan={6} />;
    }
  };

  const selectValueLevel = async (value) => {
    setLevelSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, levelId: value.value, chooseSelect: true });

    dispatch(loading(true));
    try {
      const response = await API.getOrganizes(
        form.levelId - 1,
        form.parentId,
        form.oganizeId,
        form.status,
        form.page,
        "10000",
        "",
        form.sortBy,
        form.sortDir
      );
      const data = await response?.data;
      if (response.status === 200) {
        let oganizesArray = [];
        data.content.forEach((element) => {
          oganizesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setOrganizesList(oganizesArray);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status === 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };
  const selectValueOrganize = (value) => {
    setOrganizeSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, organizeId: value.value, chooseSelect: true });
  };
  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      provinceId: value.value,
      zipcode: "",
      chooseSelect: true,
    });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({
      ...form,
      districtId: value.value,
      zipcode: "",
      chooseSelect: true,
    });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({
      ...form,
      subDistrictId: value.value,
      zipcode: value.zipcode,
      chooseSelect: true,
    });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  return (
    <div className=" ">
      <div className="pl-4 bg-white ml30 d-flex ">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>
      </div>

      <div className="bg-gray-light ">
        <div className="px-4 pt-3 container-fixed-footer ">
          <BoxCard classNameBox="" title="ตัวกรอง" headRight={buttonSearch()}>
            <Row>
              <Col lg={3} md={6} sm={12} xs={12}>
                <InputSelect
                  title="กลุ่มหน่วยงาน"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={levelSelectValue}
                  optionsList={levelList}
                  handleSelectValue={(value) => selectValueLevel(value)}
                  handleChange={(value) => {
                    setForm({ ...form, levelId: value.value });
                  }}
                />
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <label className="mb-0 normal">หน่วยงานที่แจ้งโรค </label>
                <div className="d-flex align-items-center">
                  {form.organizeName ? (
                    <>
                      <button
                        className="btn btn-primary pt-2 px-3"
                        onClick={() => setShowOrgModal(true)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </button>
                      <div className="ml-2">{form.organizeName}</div>
                    </>
                  ) : (
                    <button
                      className="btn btn-primary p-2 d-flex align-items-center"
                      onClick={() => setShowOrgModal(true)}
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                      <p className="mb-0 ml-2">{t("search")}</p>
                    </button>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="จังหวัด"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={provinceSelectValue}
                  optionsList={provinceList}
                  handleChange={(value) => {
                    selectValueProvince(value);
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="อำเภอ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={districtSelectValue}
                  optionsList={districtList}
                  handleChange={(value) => {
                    selectValueDistrict(value);
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="ตำบล"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={subDistrictSelectValue}
                  optionsList={subDistrictList}
                  handleChange={(value) => {
                    selectValueSubDistrict(value);
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="หมู่/หมู่บ้าน"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={villageSelectValue}
                  optionsList={villageList}
                  handleChange={(value) => {
                    setForm({ ...form, villageId: value.value });
                    setVillageSelectValue(value);
                  }}
                />
              </Col>

              <Col lg={2} md={6} sm={12} xs={12}>
                <InputSelect
                  title="สถานะ"
                  star={false}
                  placeholder="กรุณาเลือก"
                  idName="title"
                  classLabel="normal"
                  selectValue={statusSelectValue}
                  optionsList={statusList}
                  handleChange={(value) => {
                    setForm({ ...form, status: value.value });
                    setStatusSelectValue([
                      { value: value.value, label: value.label },
                    ]);
                  }}
                />
              </Col>
            </Row>
          </BoxCard>

          <BoxCard
            title="รายการข้อมูลหน่วยงาน"
            classNameBox="mt-4"
            headRight={buttonAddOganizes()}
          >
            <DataTable
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
            >
              {renderTableOganizes(listOganizes)}
            </DataTable>
          </BoxCard>
          {showOganizes && (
            <ModalOganizes
              show={showOganizes}
              close={() => setShowOganizes(false)}
              save={(value) => addSaveOrganizes(value)}
              mode={form.mode}
              editForm={form.editForm}
            />
          )}

          {showOrgModal && (
            <ModalSeachOrg
              show={showOrgModal}
              close={() => setShowOrgModal(false)}
              toSaveOrg={(data) => setOrganizes(data)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Organizes;
