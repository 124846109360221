import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import { useLocation } from "react-router-dom";
import { Row, Col, Table } from "react-bootstrap";
import { logout } from "../../redux/actions/logoutAction";
import { loading } from "../../redux/actions/loadingAction";
import Swal from "sweetalert2";
import * as helper from "../../utils/helper";
import * as API from "../../utils/apis";

import BoxHead from "../../components/BoxHead";
import BoxCard from "../../components/BoxCard";
import InputText from "../../components/InputText";
import InputGroupText from "../../components/InputGroupText";
import InputSelect from "../../components/InputSelect";
import InputMask from "../../components/InputMask";
import InputDatePicker from "../../components/InputDatePicker";
import Breadcrumb from "../../components/Breadcrumb";
import ModalOrganizes from "../../components/Modal.Organizes";
import DataTableNodata from "../../components/DataTableNodata";
import ModalOutbreakSearch from "../../components/Modal.OutbreakSearch";
import ModalFarmSearch from "../../components/Modal.FarmSearch";
import { useMasterData } from "../../components/hooks/useMasterData";

const MySwal = withReactContent(Swal);

function Disease_Control_Save(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.login.result);
  const { t, i18n } = useTranslation(["common", "footer"]);

  const initialForm = {
    checkAddData: false,
    mode: "add",

    farmId: "",
    outbreakId: "",
    outbreakCode: "",
    provinceId: "",
    districtId: "",
    subDistrictId: "",
    villageId: "",
    caseCode: "",
    homeNo: "",
    phone: "",
    name: "",
    orgId: "",
    orgName: "",

    date_save: "",
    date_anm_sick: "",

    house: [],
    houseList: [],
    delBarn: [],

    keet_test: "",
    destroy_disease: "",
    public: "",
    announcement: "",
    destroy_anm: "",
    immunity: "",
    treatment_anm: "",
    date_q8Qty: "",
    other: "",

    fm01: {},

    isAddingTable: false,
  };
  const [form, setForm] = useState(initialForm);

  const initialFormDesc = {
    idHouse: "",
    nameHouse: "",
    animalTypeId: "",
    animalTypeName: "",
    totalAmt: "",
    sickAmt: "",
    deadAmt: "",
    recoveryAmt: "",
    destroy: "",
    sickDeadAmt: "",
    vehicleNo: "",
    farmOrigin: "",
    farmDestination: "",
  };
  const [formDesc, setFormDesc] = useState(initialFormDesc);

  const initialFormJson = {
    animalId: {},
  };
  const [formJson, setFormJson] = useState(initialFormJson);

  const initInvalidMsg = {
    nameHouse: "",
    nameHouse: "",
    animalTypeName: "",
    totalAmt: "",
    sickAmt: "",
    deadAmt: "",
  };
  const [invalid, setInvalid] = useState(initInvalidMsg);

  const [showCaseIdSearch, setShowCaseIdSearch] = useState(false);

  const [showOrgModal, setShowOrgModal] = useState(false);

  const [showOutbreakSearch, setShowOutbreakSearch] = useState(false);

  const [showFarmSearch, setShowFarmSearch] = useState(false);

  const [checkHouseList, setCheckHouseList] = useState(false);

  const [checkButtonAddData, setCheckButtonAddData] = useState(false);

  // select จังหวัด ตำบล อำเภอ หมู่บ้าน
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictSelectValue, setSubDistrictSelectValue] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [villageSelectValue, setVillageSelectValue] = useState([]);
  const [villageList, setVillageList] = useState([]);

  useMasterData("province", provinceList, setProvinceList);

  // =================

  const [houseSelectValue, setHouseSelectValue] = useState([]);
  const [houseList, setHouseList] = useState([]);

  const [animalSelectValue, setAnimalSelectValue] = useState([]);
  const [animalList, setAnimalList] = useState([]);

  const [organizesSelectValue, setOrganizesSelectValue] = useState([]);
  const [organizesList, setOrganizesList] = useState([]);

  const [checkBtnManage, setCheckBtnManage] = useState(false);
  const [validateBtnManage, setValidateBtnManage] = useState(false);

  // =================

  useEffect(() => {
    getMasterJson();
    getProvince();
    getAnimal();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      getDiseaseControlById(props.match.params.id);
    }
    if (location.pathname.includes("duplicate")) {
      getCopyDiseaseControlById(props.match.params.id);
    }
  }, [
    user,
    location.pathname,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
    checkHouseList,
  ]);

  useEffect(() => {
    if (location.pathname.includes("save") && form.outbreakId) {
      getOutbreakById(form.outbreakId);
    } else if (location.pathname.includes("save") && props.match.params.id) {
      getOutbreakById(props.match.params.id);
    }
  }, [
    user,
    form.outbreakId,
    form.provinceId,
    form.districtId,
    form.subDistrictId,
    form.villageId,
  ]);

  const getMasterJson = async () => {
    const params = await ["animalType"];
    try {
      const response = await API.getMasterJson(params);
      const data = await response.data;

      if (response.status == 200) {
        setFormJson({
          ...formJson,
          animalId: data.animalType,
        });
      }
    } catch (error) {
      dispatch(loading(false));
      console.log(error);
    }
  };

  const getAnimal = async () => {
    try {
      const response = await API.getAnimal("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let animalArray = [];
        data.content.forEach((element) => {
          animalArray.push({
            value: element.id,
            label: element.name,
          });
        });
        await setAnimalList(animalArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async () => {
    try {
      const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;

      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubDistricts = async (id) => {
    try {
      const response = await API.getSubDistricts("", id, 1);
      const data = await response.data;

      if (response.status == 200) {
        let subDistrictArray = [];
        data.content.forEach((element) => {
          subDistrictArray.push({
            value: element.id,
            label: element.name,
            zipcode: element.zipcode,
          });
        });
        setSubDistrictList(subDistrictArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVillage = async (id) => {
    try {
      const response = await API.getVillage("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let villageArray = [];
        data.content.forEach((element) => {
          villageArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setVillageList(villageArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFarmById = async (id) => {
    try {
      const response = await API.getFarmRegistrationById(id);
      const dataRes = await response.data;
      if (response.status == 200) {
        let arrayHouseList = [];
        if (dataRes && dataRes.barn.length > 0) {
          dataRes.barn.forEach((house) =>
            arrayHouseList.push({ value: house.id, label: house.name })
          );
        }
        // console.log("arrayHouseList", arrayHouseList);
        setHouseList(arrayHouseList);
        setCheckHouseList(true);
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
  };

  const getOutbreakById = async (id) => {
    let dataRes;
    try {
      const response = await API.getOutbreakById(id);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.fm01 && dataById.fm01.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.fm01.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.fm01.provinceId);
        }
      }

      if (dataById.fm01 && dataById.fm01.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.fm01.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.fm01.amphurId);
        }
      }

      if (dataById.fm01 && dataById.fm01.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.fm01.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.fm01.tambonId);
        }
      }

      if (dataById.fm01 && dataById.fm01.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.fm01.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.fm01 && dataById.fm01.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.fm01.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      const houseArray = [];
      if (dataById.fm01Response && dataById.fm01Response.fm0102) {
        if (
          dataById.fm01Response.fm0102 &&
          dataById.fm01Response.fm0102.barns.length > 0
        ) {
          let barn = dataById.fm01Response.fm0102.barns;
          barn.map((barns, b) => {
            houseArray.push({
              barnId: barns.id,
              name: barns.name,
              desc: [],
            });
          });
        }
      }

      let arrayHouseList = [];
      if (houseArray && houseArray.length > 0) {
        houseArray.forEach((house) =>
          arrayHouseList.push({ value: house.barnId, label: house.name })
        );
      }
      setHouseList(arrayHouseList);
      setForm({
        ...form,
        mode: "add",
        dataAllById: dataById,
        outbreakCode: dataById.outbreak.obCode,
        farmId: dataById.fm01.farmId,
        caseCode: dataById.fm01.caseCode,

        provinceId: dataById.fm01.provinceId,
        districtId: dataById.fm01.amphurId,
        subDistrictId: dataById.fm01.tambonId,
        villageId: dataById.fm01.mooId,
        homeNo: dataById.fm01.address,

        phone: dataById.fm01.ownerTel,
        name: dataById.fm01.ownerName,
        orgId: dataById.fm01.orgId,
        orgName: dataById.fm01.orgName,

        house: houseArray,

        fm01: dataById.fm01,
        checkAddData: true,
      });
    }
  };

  const compareBarnsId = (list, value) => {
    let check = false;
    list.forEach((barns, b) => {
      if (barns.barnId == value) {
        check = true;
      }
    });
    return check;
  };

  const mapBarnList = (barn, barnId, barnName) => {
    // console.log("mapBarnList", barnId, barnName);
    let barnList = [];
    if (compareBarnsId(barn, barnId)) {
      barn.map((item, i) => {
        if (item.barnId === barnId) {
          barnList.push({
            id: item.id,
            idHouse: item.barnId,
            nameHouse: barnName,
            animalTypeId: item.animalTypeId,
            animalTypeName: formJson.animalId[item.animalTypeId],
            totalAmt: item.totalAmt,
            sickAmt: item.sickAmt,
            deadAmt: item.deadAmt,
            recoveryAmt: item.recoveryAmt,
            destroy: item.destroyAmt,
            sickDeadAmt: item.sickDeadAmt,
            balanceAmt:
              Number(item.totalAmt) -
              Number(item.deadAmt),
            vehicleNo: item.vehicleNo,
            farmOrigin: item.farmOrigin,
            farmDestination: item.farmDestination,
          });
        }
      });
    }
    return barnList;
  };

  const getDiseaseControlById = async (id) => {
    dispatch(loading(true));
    let dataRes;
    try {
      const response = await API.getDiseaseControlById(id);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }

    if (dataRes) {
      const dataById = dataRes;

      if (dataById.fm01 && dataById.fm01.farmId) {
        getFarmById(dataById.fm01.farmId);
      }

      if (dataById.fm01 && dataById.fm01.provinceId) {
        let provinceSelect = await provinceList.find(
          (item) => item.value == dataById.fm01.provinceId
        );
        setProvinceSelectValue(provinceSelect);

        if (districtList.length == 0) {
          await getDistricts(dataById.fm01.provinceId);
        }
      }

      if (dataById.fm01 && dataById.fm01.amphurId) {
        let districtSelect = await districtList.find(
          (item) => item.value == dataById.fm01.amphurId
        );
        setDistrictSelectValue(districtSelect);
        if (subDistrictList.length == 0) {
          await getSubDistricts(dataById.fm01.amphurId);
        }
      }

      if (dataById.fm01 && dataById.fm01.tambonId) {
        let subDistrictSelect = await subDistrictList.find(
          (item) => item.value == dataById.fm01.tambonId
        );
        setSubDistrictSelectValue(subDistrictSelect);

        if (villageList.length == 0) {
          await getVillage(dataById.fm01.tambonId);
        }
      }

      if (dataById.fm01 && dataById.fm01.mooId) {
        let villageSelect = await villageList.find(
          (item) => item.value == dataById.fm01.mooId
        );
        setVillageSelectValue(villageSelect);
      }

      if (dataById.fm01 && dataById.fm01.orgId) {
        let organizesSelect = await organizesList.find(
          (item) => item.value == dataById.fm01.orgId
        );
        setOrganizesSelectValue(organizesSelect);
      }

      const houseArray = [];
      if (dataById.fm03 && dataById.fm03.barns) {
        // console.log("houseList", houseList);

        if (houseList && houseList.length > 0) {
          if (dataById.fm03.barns && dataById.fm03.barns.length > 0) {
            let barn = dataById.fm03.barns;
            houseList.map((barns, b) => {
              houseArray.push({
                barnId: barns.value,
                name: barns.label,
                desc: mapBarnList(barn, barns.value, barns.label),
              });
            });
          }
        }
      }
      // console.log("houseArray", houseArray);

      setForm({
        ...form,
        mode: location.pathname.includes("edit"),
        dataAllById: dataById,
        outbreakCode: dataById.outbreak.obCode,
        farmId: dataById.fm01.farmId,
        caseCode: dataById.fm01.caseCode,

        provinceId: dataById.fm01.provinceId,
        districtId: dataById.fm01.amphurId,
        subDistrictId: dataById.fm01.tambonId,
        villageId: dataById.fm01.mooId,
        homeNo: dataById.fm01.address,

        phone: dataById.fm01.ownerTel,
        name: dataById.fm01.ownerName,
        orgId: dataById.fm01.orgId,
        orgName: dataById.fm01.orgName,

        date_save: dataById.fm03.operateDate,
        date_anm_sick: dataById.fm03.lastDate,
        house: houseArray,

        // fm01: dataById.fm01,
        checkAddData: false,

        keet_test: dataById.fm06.q1Qty,
        destroy_disease: dataById.fm06.q2Qty,
        public: dataById.fm06.q3Qty,
        announcement: dataById.fm06.q4Qty,
        destroy_anm: dataById.fm06.q5Qty,
        immunity: dataById.fm06.q6Qty,
        treatment_anm: dataById.fm06.q7Qty,
        other: dataById.fm06.other,
        date_q8Qty: dataById.fm06.q8Qty,
      });
    }
  };

  const getCopyDiseaseControlById = async (id) => {
    dispatch(loading(true));
    let dataRes;
    try {
      const response = await API.getCopyDiseaseControlById(id);
      if (response.status == 200) {
        dataRes = response.data;
      }
    } catch (error) {
      dispatch(loading(false));
      if (error.response && error.response.status == 401) {
        dispatch(logout({ history }));
      }
      console.log(error);
    }
    if (dataRes) {
      if (dataRes) {
        const dataById = dataRes;

        if (dataById.fm01 && dataById.fm01.farmId) {
          getFarmById(dataById.fm01.farmId);
        }

        if (dataById.fm01 && dataById.fm01.provinceId) {
          let provinceSelect = await provinceList.find(
            (item) => item.value == dataById.fm01.provinceId
          );
          setProvinceSelectValue(provinceSelect);

          if (districtList.length == 0) {
            await getDistricts(dataById.fm01.provinceId);
          }
        }

        if (dataById.fm01 && dataById.fm01.amphurId) {
          let districtSelect = await districtList.find(
            (item) => item.value == dataById.fm01.amphurId
          );
          setDistrictSelectValue(districtSelect);
          if (subDistrictList.length == 0) {
            await getSubDistricts(dataById.fm01.amphurId);
          }
        }

        if (dataById.fm01 && dataById.fm01.tambonId) {
          let subDistrictSelect = await subDistrictList.find(
            (item) => item.value == dataById.fm01.tambonId
          );
          setSubDistrictSelectValue(subDistrictSelect);

          if (villageList.length == 0) {
            await getVillage(dataById.fm01.tambonId);
          }
        }

        if (dataById.fm01 && dataById.fm01.mooId) {
          let villageSelect = await villageList.find(
            (item) => item.value == dataById.fm01.mooId
          );
          setVillageSelectValue(villageSelect);
        }

        if (dataById.fm01 && dataById.fm01.orgId) {
          let organizesSelect = await organizesList.find(
            (item) => item.value == dataById.fm01.orgId
          );
          setOrganizesSelectValue(organizesSelect);
        }

        const houseArray = [];
        if (dataById.fm03 && dataById.fm03.barns) {
          // console.log("houseList", houseList);

          if (houseList && houseList.length > 0) {
            if (dataById.fm03.barns && dataById.fm03.barns.length > 0) {
              let barn = dataById.fm03.barns;
              houseList.map((barns, b) => {
                houseArray.push({
                  barnId: barns.value,
                  name: barns.label,
                  desc: mapBarnList(barn, barns.value, barns.label),
                });
              });
            }
          }
        }
        // console.log("houseArray", houseArray);

        setForm({
          ...form,
          mode: "duplicate",
          dataAllById: dataById,
          outbreakCode: dataById.outbreak.obCode,
          farmId: dataById.fm01.farmId,
          caseCode: dataById.fm01.caseCode,

          provinceId: dataById.fm01.provinceId,
          districtId: dataById.fm01.amphurId,
          subDistrictId: dataById.fm01.tambonId,
          villageId: dataById.fm01.mooId,
          homeNo: dataById.fm01.address,

          phone: dataById.fm01.ownerTel,
          name: dataById.fm01.ownerName,
          orgId: dataById.fm01.orgId,
          orgName: dataById.fm01.orgName,

          date_save: dataById.fm03.operateDate,
          date_anm_sick: dataById.fm03.lastDate,
          house: houseArray,

          // fm01: dataById.fm01,
          checkAddData: false,

          keet_test: dataById.fm06.q1Qty,
          destroy_disease: dataById.fm06.q2Qty,
          public: dataById.fm06.q3Qty,
          announcement: dataById.fm06.q4Qty,
          destroy_anm: dataById.fm06.q5Qty,
          immunity: dataById.fm06.q6Qty,
          treatment_anm: dataById.fm06.q7Qty,
          other: dataById.fm06.other,
          date_q8Qty: dataById.fm06.q8Qty,
        });
      }
    }
  };

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/passive_surv/disease_report", name: "การเฝ้าระวังเชิงรับ" },
    { path: "/passive_surv/disease_control", name: "กคร.3/ สคส.1" },
    {
      path: "title",
      name: "บันทึกภาวการณ์ระบาดของโรค/การควบคุมโรค (กคร.3 /สคส.1)",
    },
  ];

  const toCancel = () => {
    history.push("/passive_surv/disease_control");
  };

  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, provinceId: value.value, zipcode: "" });
    getDistricts(value.value);
  };

  const selectValueDistrict = (value) => {
    setDistrictSelectValue([{ value: value.value, label: value.label }]);
    setSubDistrictSelectValue([]);
    setVillageSelectValue([]);
    setForm({ ...form, districtId: value.value, zipcode: "" });
    getSubDistricts(value.value);
  };

  const selectValueSubDistrict = (value) => {
    setSubDistrictSelectValue([{ value: value.value, label: value.label }]);
    setForm({ ...form, subDistrictId: value.value, zipcode: value.zipcode });
    setVillageSelectValue([]);
    getVillage(value.value);
  };

  const setOrganizes = (data) => {
    setForm({ ...form, orgId: data.value, orgName: data.label });
  };

  const addInvalid = (element, message) => {
    invalid[element] = message;
    setInvalid({ ...invalid });
  };

  const removeInvalid = (element) => {
    invalid[element] = "";
    setInvalid({ ...invalid });
  };

  const validateHouse = () => {
    let validated = true;

    if (formDesc.idHouse === "" && form.checkAddData) {
      addInvalid("idHouse", "กรุณาเลือกโรงเรือน");
      validated = false;
    }

    if (formDesc.animalTypeName === "" && form.checkAddData) {
      addInvalid("animalTypeName", "กรุณาเลือกชนิดสัตว์");
      validated = false;
    }

    return validated;
  };

  const delDesc = (index, idHouse, id) => {
    MySwal.fire({
      text: "ยืนยันการลบข้อมูล",
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then((result) => {
      if (result.value) {
        if (form.house && form.house.length > 0) {
          form.house.map((item, i) => {
            if (item.barnId == idHouse) {
              let delBarn = [];

              item.desc.splice(index, 1);
              if (id) {
                delBarn.push(id);
              }
              setForm({
                ...form,
                house: form.house,
                checkAddData: true,
                delBarn: delBarn,
              });
              setFormDesc(initialFormDesc);
              setHouseSelectValue([]);
              setAnimalSelectValue([]);
            }
          });
        }
      }
    });
  };

  const descEdit = async (item, idHouse, indexH) => {
    item.checkEdit = true;
    setCheckBtnManage(false);
    setValidateBtnManage(true);
    setFormDesc({
      ...formDesc,
      nameHouse: item.nameHouse,
      idHouse: item.idHouse,
      animalTypeId: item.animalTypeId,
      animalTypeName: item.animalTypeName,
      totalAmt: item.totalAmt,
      sickAmt: item.sickAmt,
      deadAmt: item.deadAmt,
      recoveryAmt: item.recoveryAmt,
      destroy: item.destroy,
      sickDeadAmt: item.sickDeadAmt,
      balanceAmt: item.balanceAmt,
      vehicleNo: item.vehicleNo,
      farmOrigin: item.farmOrigin,
      farmDestination: item.farmDestination,
    });
    setAnimalSelectValue([
      { value: item.animalTypeId, label: item.animalTypeName },
    ]);
    setHouseSelectValue([{ value: item.idHouse, label: item.nameHouse }]);

    setForm({ ...form });
  };

  const saveHouseDesc = async (index, type, itemDesc) => {
    if (validateHouse() || type == "edit") {
      let newBuilding = [...form.house];
      let houseDesc = newBuilding.desc ? [...newBuilding.desc] : [];
      setCheckBtnManage(true);
      setValidateBtnManage(false);
      houseDesc.push({
        checkEdit: false,
        nameHouse: formDesc.nameHouse,
        idHouse: formDesc.idHouse,
        animalTypeId: formDesc.animalTypeId,
        animalTypeName: formDesc.animalTypeName,
        totalAmt: formDesc.totalAmt,
        sickAmt: formDesc.sickAmt,
        deadAmt: formDesc.deadAmt,
        recoveryAmt: formDesc.recoveryAmt,
        destroy: formDesc.destroy,
        sickDeadAmt: Number(formDesc.deadAmt) + Number(formDesc.sickAmt),
        balanceAmt:
          Number(formDesc.totalAmt) -
          Number(formDesc.deadAmt),
        vehicleNo: formDesc.vehicleNo,
        farmOrigin: formDesc.farmOrigin,
        farmDestination: formDesc.farmDestination,
      });

      if (type == "add") {
        if (form.house.length > 0) {
          form.house.map((item, i) => {
            if (item.barnId == formDesc.idHouse) {
              newBuilding[i].desc.push(houseDesc[0]);
            }
          });
        }
      } else if (type == "edit") {
        if (formDesc.idHouse == itemDesc.idHouse) {
          setCheckBtnManage(true);
          setValidateBtnManage(false);
          itemDesc.checkEdit = false;
          itemDesc.nameHouse = formDesc.nameHouse;
          itemDesc.idHouse = formDesc.idHouse;
          itemDesc.animalTypeId = formDesc.animalTypeId;
          itemDesc.animalTypeName = formDesc.animalTypeName;
          itemDesc.totalAmt = formDesc.totalAmt;
          itemDesc.sickAmt = formDesc.sickAmt;
          itemDesc.deadAmt = formDesc.deadAmt;
          itemDesc.recoveryAmt = formDesc.recoveryAmt;
          itemDesc.destroy = formDesc.destroy;
          itemDesc.sickDeadAmt =
            Number(formDesc.deadAmt) + Number(formDesc.sickAmt);
          itemDesc.balanceAmt =
            Number(formDesc.totalAmt) -
            Number(formDesc.deadAmt);
          itemDesc.vehicleNo = formDesc.vehicleNo;
          itemDesc.farmOrigin = formDesc.farmOrigin;
          itemDesc.farmDestination = formDesc.farmDestination;

          setForm({ ...form });
        } else {
          if (form.house.length > 0) {
            form.house.map((item, i) => {
              item.desc.splice(index, 1);
              if (item.barnId === formDesc.idHouse) {
                newBuilding[i].desc.push(houseDesc[0]);
              }
            });
          }
        }
      }
      setForm({ ...form, checkAddData: false });
    }
  };

  const addDescHouse = () => {
    setForm({ ...form, checkAddData: true });
    setFormDesc(initialFormDesc);
    setHouseSelectValue([]);
    setAnimalSelectValue([]);
  };

  const toSaveDiseaseControl = async () => {
    if (validateHouse()) {
      if (!checkBtnManage) {
        setValidateBtnManage(true);
        return;
      }
      if (form.outbreakCode == "" && location.pathname.includes("save")) {
        MySwal.fire({
          text: "กรุณาเลือกเลข Outbreak ID",
          icon: "warning",
          confirmButtonText: "ตกลง",
        });
      } else {
        let dataAdd;
        let dataEdit;
        let houseArray = [];

        if (form.house && form.house.length > 0) {
          form.house.map((itemH, h) => {
            if (itemH.desc && itemH.desc.length > 0) {
              itemH.desc.map((item, i) => {
                houseArray.push({
                  id: item.id ? item.id : "",
                  barnId: item.idHouse,
                  animalTypeId: item.animalTypeId,
                  totalAmt: item.totalAmt,
                  sickAmt: item.sickAmt,
                  deadAmt: item.deadAmt,
                  destroyAmt: item.destroy,
                  recoveryAmt: item.recoveryAmt,
                  sickDeadAmt: item.sickDeadAmt,
                  vehicleNo: item.vehicleNo,
                  farmOrigin: item.farmOrigin,
                  farmDestination: item.farmDestination,
                });
              });
            }
          });
        }
        // console.log("mode", form.mode);
        if (form.mode === "add" || form.mode === "duplicate") {
          dataAdd = {
            outbreak: {
              obCode: form.outbreakCode,
            },
            fm01: {
              id: "",
              caseCode: form.caseCode,
              address: form.homeNo,
              amphurId: form.districtId,
              animalAround5km: form.fm01.animalAround5km || "",
              animalTypeId: form.fm01.animalTypeId || "",
              diseaseTypeId: form.fm01.diseaseTypeId || "",
              dlaId: form.fm01.dlaId,
              dlaName: form.fm01.dlaName,
              farmId: form.farmId,
              firstAnimSymptomDate: form.fm01.firstAnimSymptomDate
                ? helper.getDate(form.fm01.firstAnimSymptomDate)
                : "",
              foundDiseaseDate: form.fm01.foundDiseaseDate
                ? helper.getDate(form.fm01.foundDiseaseDate)
                : "",
              humanDeadAmt: form.fm01.humanDeadAmt || "",
              humanSickAmt: form.fm01.humanSickAmt || "",
              humanTouchAmt: form.fm01.humanTouchAmt || "",
              informBy: form.fm01.informBy || "",
              informOther: form.fm01.informOther || "",
              informId: form.fm01.informId || "",
              mooId: form.villageId,
              obId: form.outbreakId,
              orgId: form.orgId,
              ownerName: form.name,
              ownerTel: form.phone,
              provinceId: form.provinceId,
              statusId: 1,
              symptomId: form.fm01.symptomId,
              tambonId: form.subDistrictId,
            },
            fm03: {
              id: "",
              operateDate: form.date_save ? helper.getDate(form.date_save) : "",
              lastDate: form.date_anm_sick
                ? helper.getDate(form.date_anm_sick)
                : "",
              barns: houseArray,
            },
            fm06: {
              id: "",
              q1Qty: form.keet_test,
              q2Qty: form.destroy_disease,
              q3Qty: form.public,
              q4Qty: form.announcement ? helper.getDate(form.announcement) : "",
              q5Qty: form.destroy_anm,
              q6Qty: form.immunity,
              q7Qty: form.treatment_anm,
              other: form.other,
              q8Qty: form.date_q8Qty,
            },
          };
        } else {
          dataEdit = {
            outbreak: {
              id: form.dataAllById.outbreak.id,
              informId: null,
              asId: null,
              farmAmt: 1,
              fm01Id: form.dataAllById.outbreak.fm01Id,
              obCode: form.outbreakCode,
              statusId: 1,
              stdMovementId: null,
              stdWarningId: null,
            },
            fm01: {
              id: form.dataAllById.fm01.id,
              caseCode: form.caseCode,
              address: form.homeNo,
              amphurId: form.districtId,
              animalAround5km: form.fm01.animalAround5km || "",
              animalTypeId: form.fm01.animalTypeId || "",
              diseaseTypeId: form.fm01.diseaseTypeId || "",
              dlaId: form.fm01.dlaId,
              dlaName: form.fm01.dlaName,
              farmId: form.farmId,
              firstAnimSymptomDate: form.fm01.firstAnimSymptomDate
                ? helper.getDate(form.fm01.firstAnimSymptomDate)
                : "",
              foundDiseaseDate: form.fm01.foundDiseaseDate
                ? helper.getDate(form.fm01.foundDiseaseDate)
                : "",
              humanDeadAmt: form.fm01.humanDeadAmt || "",
              humanSickAmt: form.fm01.humanSickAmt || "",
              humanTouchAmt: form.fm01.humanTouchAmt || "",
              informBy: form.fm01.informBy || "",
              informOther: form.fm01.informOther || "",
              informId: form.fm01.informId || "",
              mooId: form.villageId,
              obId: form.outbreakId,
              orgId: form.orgId,
              ownerName: form.name,
              ownerTel: form.phone,
              provinceId: form.provinceId,
              statusId: 1,
              symptomId: form.fm01.symptomId,
              tambonId: form.subDistrictId,
            },
            fm03: {
              id: form.dataAllById.fm03.id,
              createBy: form.dataAllById.fm03.createBy,
              createDate: form.dataAllById.fm03.createDate,
              fm01Id: form.dataAllById.outbreak.fm01Id,
              operateDate: form.date_save ? helper.getDate(form.date_save) : "",
              lastDate: form.date_anm_sick
                ? helper.getDate(form.date_anm_sick)
                : "",
              statusId: 1,
              barns: houseArray,
            },
            fm06: {
              id: form.dataAllById.fm06.id,
              q1Qty: form.keet_test,
              q2Qty: form.destroy_disease,
              q3Qty: form.public,
              q4Qty: form.announcement ? helper.getDate(form.announcement) : "",
              q5Qty: form.destroy_anm,
              q6Qty: form.immunity,
              q7Qty: form.treatment_anm,
              other: form.other,
              q8Qty: form.date_q8Qty,
            },
            deleteBarns: form.delBarn,
          };
        }

        // console.log("data", dataAdd, dataEdit);

        try {
          let response;
          if (form.mode === "add" || form.mode === "duplicate") {
            response = await API.addDiseaseControl(dataAdd);
          } else {
            response = await API.addDiseaseControl(dataEdit);
          }

          if (response.status == 200) {
            MySwal.fire({
              text:
                location.pathname.includes("save") ||
                location.pathname.includes("duplicate")
                  ? `สร้าง "บันทึกภาวการณ์ระบาดของโรค/การควบคุมโรค (กคร.3 /สคส.1)" สำเร็จ`
                  : `แก้ไข "บันทึกภาวการณ์ระบาดของโรค/การควบคุมโรค (กคร.3 /สคส.1)" สำเร็จ`,
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then(async (result) => {
              history.push(`/passive_surv/disease_control`);
            });
          }
        } catch (error) {
          dispatch(loading(false));
          if (error.response && error.response.status == 401) {
            dispatch(logout({ history }));
          } else {
            MySwal.fire({
              text:
                location.pathname.includes("save") ||
                location.pathname.includes("duplicate")
                  ? `สร้าง "บันทึกภาวการณ์ระบาดของโรค/การควบคุมโรค (กคร.3 /สคส.1)" ไม่สำเร็จ`
                  : `แก้ไข "บันทึกภาวการณ์ระบาดของโรค/การควบคุมโรค (กคร.3 /สคส.1)" ไม่สำเร็จ`,
              icon: "warning",
              confirmButtonText: "ตกลง",
            });
          }
          console.log("error", error);
        }
      }
    }
  };

  const renderDesc = (data) => {
    if (data && data.length > 0) {
      return data.map((item, index) => (
        <tr key={index} className="">
          <td>
            {item.checkEdit ? (
              <InputSelect
                classFormGroup="mb-0 "
                star={false}
                placeholder="กรุณาเลือก"
                idName="nameHouse"
                classLabel="normal"
                selectValue={houseSelectValue}
                optionsList={houseList}
                handleChange={(value) => {
                  setHouseSelectValue([value]);
                  setFormDesc({
                    ...formDesc,
                    nameHouse: value.label,
                    idHouse: value.value,
                  });
                }}
                handleInvalid={() => removeInvalid("idHouse")}
                invalid={invalid.idHouse}
              />
            ) : (
              item.nameHouse
            )}
          </td>
          <td>
            {item.checkEdit ? (
              <InputSelect
                classFormGroup="mb-0 w-100"
                star={false}
                placeholder="กรุณาเลือก"
                idName="animalTypeName"
                classLabel="normal"
                selectValue={animalSelectValue}
                optionsList={animalList}
                handleChange={(value) => {
                  setAnimalSelectValue([value]);
                  setFormDesc({
                    ...formDesc,
                    animalTypeName: value.label,
                    animalTypeId: value.value,
                  });
                }}
                handleInvalid={() => removeInvalid("animalTypeName")}
                invalid={invalid.animalTypeName}
              />
            ) : (
              item.animalTypeName
            )}
          </td>
          {/* <td>
            {item.checkEdit ? (
              <InputText
                classFormGroup="mb-0"
                type="text"
                idName="vehicleNo"
                value={formDesc.vehicleNo}
                star={false}
                placeholder="ระบุทะเบียนรถ"
                handleChange={(value) =>
                  setFormDesc({ ...formDesc, vehicleNo: value })
                }
                handleInvalid={() => removeInvalid("vehicleNo")}
                invalid={invalid.destroy}
              />
            ) : (
              item.vehicleNo
            )}
          </td>
          <td>
            {item.checkEdit ? (
              <InputText
                classFormGroup="mb-0"
                type="text"
                idName="farmOrigin"
                value={formDesc.farmOrigin}
                star={false}
                placeholder="ระบุหมายเลขฟาร์มต้นทาง"
                handleChange={(value) =>
                  setFormDesc({ ...formDesc, farmOrigin: value })
                }
                handleInvalid={() => removeInvalid("farmOrigin")}
                invalid={invalid.destroy}
              />
            ) : (
              item.farmOrigin
            )}
          </td>
          <td>
            {item.checkEdit ? (
              <InputText
                classFormGroup="mb-0"
                type="text"
                idName="farmDestination"
                value={formDesc.farmDestination}
                star={false}
                placeholder="ระบุหมายเลขฟาร์มปลายทาง"
                handleChange={(value) =>
                  setFormDesc({ ...formDesc, farmDestination: value })
                }
                handleInvalid={() => removeInvalid("farmDestination")}
                invalid={invalid.destroy}
              />
            ) : (
              item.farmDestination
            )}
          </td> */}
          <td>
            {item.checkEdit ? (
              <InputText
                classFormGroup="mb-0"
                type="number"
                idName="totalAmt"
                value={formDesc.totalAmt}
                star={false}
                placeholder="ระบุจำนวน"
                handleChange={(value) =>
                  setFormDesc({ ...formDesc, totalAmt: value })
                }
                handleInvalid={() => removeInvalid("totalAmt")}
                invalid={invalid.totalAmt}
                min="0"
              />
            ) : (
              item.totalAmt
            )}
          </td>
          <td>
            {item.checkEdit ? (
              <InputText
                classFormGroup="mb-0"
                type="number"
                idName="sickAmt"
                value={formDesc.sickAmt}
                star={false}
                placeholder="ระบุจำนวน"
                handleChange={(value)  => {
                  var sumAll = Number(formDesc.deadAmt || 0) + Number(value || 0);
                  if(sumAll <= formDesc.totalAmt) {
                    setFormDesc({ ...formDesc, sickAmt: value })
                  }
                  
                }}
                handleInvalid={() => removeInvalid("sickAmt")}
                invalid={invalid.sickAmt}
                min="0"
              />
            ) : (
              item.sickAmt
            )}
          </td>
          <td>
            {item.checkEdit ? (
              <InputText
                classFormGroup="mb-0"
                type="number"
                idName="deadAmt"
                value={formDesc.deadAmt}
                star={false}
                placeholder="ระบุจำนวน"
                handleChange={(value) => {
                  var sumAll = Number(formDesc.sickAmt || 0) + Number(value || 0);
                  if(sumAll <= formDesc.totalAmt) {
                    setFormDesc({ ...formDesc, deadAmt: value })
                  }
                }
                  
                }
                handleInvalid={() => removeInvalid("deadAmt")}
                invalid={invalid.deadAmt}
                min="0"
              />
            ) : (
              item.deadAmt
            )}
          </td>
          <td>
            {item.checkEdit ? (
              <InputText
                classFormGroup="mb-0"
                type="number"
                idName="recoveryAmt"
                value={formDesc.recoveryAmt}
                star={false}
                placeholder="ระบุจำนวน"
                handleChange={(value) =>{
                  var sum = Number(value || 0);
                  if (sum <= formDesc.sickAmt) {
                    setFormDesc({ ...formDesc, recoveryAmt: value })
                  }
                }
                }
                handleInvalid={() => removeInvalid("recoveryAmt")}
                invalid={invalid.recoveryAmt}
                min="0"
              />
            ) : (
              item.recoveryAmt
            )}
          </td>
          <td>
            {item.checkEdit ? (
              <InputText
                classFormGroup="mb-0"
                type="number"
                idName="destroy"
                value={formDesc.destroy}
                star={false}
                placeholder="ระบุจำนวน"
                handleChange={(value) =>
                  {
                    var sum = Number(value || 0);
                    if (sum <= formDesc.deadAmt) {
                      setFormDesc({ ...formDesc, destroy: value })
                    }
                }}
                handleInvalid={() => removeInvalid("destroy")}
                invalid={invalid.destroy}
                min="0"
              />
            ) : (
              item.destroy
            )}
          </td>
          <td>
            {item.checkEdit ? (
              <InputText
                classFormGroup="mb-0"
                type="number"
                idName="sickDeadAmt"
                value={Number(formDesc.deadAmt) + Number(formDesc.sickAmt)}
                star={false}
                placeholder="ระบุจำนวน"
                handleChange={(value) => 
                  setFormDesc({ ...formDesc, sickDeadAmt: value })
                }
                disabled
                min="0"
              />
            ) : (
              item.sickDeadAmt
            )}
          </td>
          <td>
            {item.checkEdit ? (
              <InputText
                classFormGroup="mb-0"
                type="number"
                idName="balanceAmt"
                value={
                  Number(formDesc.totalAmt) -
                  Number(formDesc.deadAmt)
                }
                star={false}
                placeholder="ระบุจำนวน"
                handleChange={(value) =>
                  setFormDesc({ ...formDesc, balanceAmt: value })
                }
                disabled
                min="0"
              />
            ) : (
              item.balanceAmt
            )}
          </td>
          <td>
            <div className="d-flex justify-content-center">
              {item.checkEdit ? (
                <button
                  className="btn btn-success pt-1 px-2"
                  onClick={async () => {
                    saveHouseDesc(index, "edit", item);
                    setCheckButtonAddData(false);
                  }}
                >
                  <img
                    className="img-fluid icon-check"
                    src="/svg/btn-check.svg"
                    alt="check"
                  />
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-add mr-2"
                    onClick={() => {
                      descEdit(item, item.idHouse);
                      setCheckButtonAddData(true);
                    }}
                  >
                    <img
                      className="img-fluid icon-btn"
                      src="/svg/btn-edit.svg"
                      alt="edit"
                    />
                  </button>
                  <button
                    className="btn btn-del mr-2"
                    onClick={() => delDesc(index, item.idHouse, item.id)}
                  >
                    <img
                      className="img-fluid icon-btn"
                      src="/svg/btn-del.svg"
                      alt="del"
                    />
                  </button>
                </>
              )}
            </div>
          </td>
        </tr>
      ));
    }
  };

  const renderNewTable = (index, type) => {
    return (
      <tr className="">
        <td>
          <InputSelect
            classFormGroup="mb-0 "
            star={false}
            placeholder="กรุณาเลือก"
            idName="nameHouse"
            classLabel="normal"
            selectValue={houseSelectValue}
            optionsList={houseList}
            handleChange={(value) => {
              setHouseSelectValue([value]);
              setFormDesc({
                ...formDesc,
                nameHouse: value.label,
                idHouse: value.value,
              });
            }}
            handleInvalid={() => removeInvalid("idHouse")}
            invalid={invalid.idHouse}
          />
        </td>
        <td>
          <InputSelect
            classFormGroup="mb-0 w-100"
            star={false}
            placeholder="กรุณาเลือก"
            idName="animalTypeName"
            classLabel="normal"
            selectValue={animalSelectValue}
            optionsList={animalList}
            handleChange={(value) => {
              setAnimalSelectValue([value]);
              setFormDesc({
                ...formDesc,
                animalTypeName: value.label,
                animalTypeId: value.value,
              });
            }}
            handleInvalid={() => removeInvalid("animalTypeName")}
            invalid={invalid.animalTypeName}
          />
        </td>
        {/* <td>
          <InputText
            classFormGroup="mb-0"
            type="text"
            idName="vehicleNo"
            value={formDesc.vehicleNo}
            star={false}
            placeholder="ระบุทะเบียนรถ"
            handleChange={(value) =>
              setFormDesc({ ...formDesc, vehicleNo: value })
            }
            handleInvalid={() => removeInvalid("vehicleNo")}
            invalid={invalid.destroy}
          />
        </td>
        <td>
          <InputText
            classFormGroup="mb-0"
            type="text"
            idName="farmOrigin"
            value={formDesc.farmOrigin}
            star={false}
            placeholder="ระบุหมายเลขฟาร์มต้นทาง"
            handleChange={(value) =>
              setFormDesc({ ...formDesc, farmOrigin: value })
            }
            handleInvalid={() => removeInvalid("farmOrigin")}
            invalid={invalid.destroy}
          />
        </td>
        <td>
          <InputText
            classFormGroup="mb-0"
            type="text"
            idName="farmDestination"
            value={formDesc.farmDestination}
            star={false}
            placeholder="ระบุหมายเลขฟาร์มปลายทาง"
            handleChange={(value) =>
              setFormDesc({ ...formDesc, farmDestination: value })
            }
            handleInvalid={() => removeInvalid("farmDestination")}
            invalid={invalid.destroy}
          />
        </td> */}
        <td>
          <InputText
            classFormGroup="mb-0"
            type="number"
            idName="totalAmt"
            value={formDesc.totalAmt}
            star={false}
            placeholder="ระบุจำนวน"
            handleChange={(value) =>
              setFormDesc({ ...formDesc, totalAmt: value })
            }
            handleInvalid={() => removeInvalid("totalAmt")}
            invalid={invalid.totalAmt}
            min="0"
          />
        </td>
        <td>
          <InputText
            classFormGroup="mb-0"
            type="number"
            idName="sickAmt"
            value={formDesc.sickAmt}
            star={false}
            placeholder="ระบุจำนวน"
            handleChange={(value) => {
              var sumAll = Number(formDesc.deadAmt || 0) + Number(value || 0);
                if(sumAll <= formDesc.totalAmt) {
                  setFormDesc({ ...formDesc, sickAmt: value })
                }
            }}
            handleInvalid={() => removeInvalid("sickAmt")}
            invalid={invalid.sickAmt}
            min="0"
          />
        </td>
        <td>
          <InputText
            classFormGroup="mb-0"
            type="number"
            idName="deadAmt"
            value={formDesc.deadAmt}
            star={false}
            placeholder="ระบุจำนวน"
            handleChange={(value) => {
              
                var sumAll = Number(formDesc.sickAmt || 0) + Number(value || 0);
                if(sumAll <= formDesc.totalAmt) {
                  setFormDesc({ ...formDesc, deadAmt: value })
                }

            }}
            handleInvalid={() => removeInvalid("deadAmt")}
            invalid={invalid.deadAmt}
            min="0"
          />
        </td>
        <td>
          <InputText
            classFormGroup="mb-0"
            type="number"
            idName="recoveryAmt"
            value={formDesc.recoveryAmt}
            star={false}
            placeholder="ระบุจำนวน"
            handleChange={(value) => {
              var sum = Number(value || 0);
              if (sum <= formDesc.sickAmt) {
                setFormDesc({ ...formDesc, recoveryAmt: value })
              }
            }}
            handleInvalid={() => removeInvalid("recoveryAmt")}
            invalid={invalid.recoveryAmt}
            min="0"
          />
        </td>
        <td>
          <InputText
            classFormGroup="mb-0"
            type="number"
            idName="destroy"
            value={formDesc.destroy}
            star={false}
            placeholder="ระบุจำนวน"
            handleChange={(value) => {
              var sum = Number(value || 0);
              if (sum <= formDesc.deadAmt) {
                setFormDesc({ ...formDesc, destroy: value })
              }
            }}
            handleInvalid={() => removeInvalid("destroy")}
            invalid={invalid.destroy}
            min="0"
          />
        </td>
        <td>
          <InputText
            classFormGroup="mb-0"
            type="number"
            idName="sickDeadAmt"
            value={Number(formDesc.deadAmt) + Number(formDesc.sickAmt)}
            star={false}
            placeholder="ระบุจำนวน"
            handleChange={(value) =>
              setFormDesc({ ...formDesc, sickDeadAmt: value })
            }
            disabled
            min="0"
          />
        </td>
        <td>
          <InputText
            classFormGroup="mb-0"
            type="number"
            idName="balanceAmt"
            value={
              Number(formDesc.totalAmt) -
              Number(formDesc.deadAmt)
            }
            star={false}
            placeholder="ระบุจำนวน"
            handleChange={(value) =>
              setFormDesc({ ...formDesc, balanceAmt: value })
            }
            disabled
            min="0"
          />
        </td>
        <td className="">
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-success pt-1 px-2"
              onClick={async () => {
                saveHouseDesc(index, type);
              }}
            >
              <img
                className="img-fluid icon-check"
                src="/svg/btn-check.svg"
                alt="check"
              />
            </button>
          </div>
          {validateBtnManage && (
            <div className="invalid-error">กรุณากดยืนยัน</div>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <div className="pl-4 bg-white ml30 d-flex">
        <div className="mr-auto p-2 ">
          <Breadcrumb data={breadcrumbPath} />
        </div>

        <div className="p-2 ">
          <Link to={`/passive_surv/disease_control`}>
            <button className="btn btn-danger" onClick={() => toCancel()}>
              ยกเลิก
            </button>
          </Link>
        </div>

        <div className="p-2 ">
          <button
            className="btn btn-success"
            onClick={() => toSaveDiseaseControl()}
            disabled={
              user?.userGroup.mupdate === "n" &&
              location.pathname.includes(`/passive_surv/disease_control/edit/`)
            }
          >
            บันทึก
          </button>
        </div>
      </div>

      <div className="bg-gray-light pb-3">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxHead title="ตัวกรอง" classLabel="">
            <div className=" ">
              <Row className="mb-2 pl-2 ">
                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="d-flex align-items-center">
                    <InputText
                      type="text"
                      title="เลข Outbreak ID"
                      idName="outbreakCode"
                      value={form.outbreakCode}
                      star={true}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      disabled
                      handleChange={(value) =>
                        setForm({ ...form, outbreakCode: value })
                      }
                    />
                    <button
                      className="btn btn-add mt-auto mb-3 ml-2"
                      onClick={() => setShowOutbreakSearch(true)}
                    >
                      <img
                        className="icon-btn "
                        src="/svg/btn-edit.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                </Col>

                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="d-flex align-items-center">
                    <InputText
                      type="text"
                      title="เลข case ID"
                      idName="caseCode"
                      value={form.caseCode}
                      star={false}
                      placeholder="โปรดระบุ"
                      classLabel="normal"
                      disabled
                      handleChange={(value) =>
                        setForm({ ...form, caseCode: value })
                      }
                    />
                    <button
                      className="btn btn-add mt-auto mb-3 ml-2 "
                      onClick={() => setShowCaseIdSearch(true)}
                    >
                      <img
                        className="icon-btn "
                        src="/svg/btn-edit.svg"
                        alt="edit"
                      />
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={4} sm={12} xs={12}>
                  <label className="mb-0 normal">หน่วยงานที่แจ้งโรค </label>
                  <div className="d-flex align-items-center">
                    {form.orgName ? (
                      <>
                        <button
                          className="btn btn-primary pt-2 px-3"
                          onClick={() => setShowOrgModal(true)}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="ml-2">{form.orgName}</div>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary p-2 d-flex align-items-center"
                        onClick={() => setShowOrgModal(true)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        <p className="mb-0 ml-2">{t("search")}</p>
                      </button>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="mb-2 pl-2">
                <Col lg={2} md={3} sm={6} xs={6}>
                  <div>
                    <InputGroupText
                      type="text"
                      title="รหัสฟาร์ม"
                      imgSrc={"/svg/search.svg"}
                      imgAlt="search-icon"
                      idName="farmId"
                      value={form.farmId}
                      star={false}
                      placeholder="ค้นหาฟาร์ม"
                      classLabel="normal"
                      handleChange={(value) =>
                        setForm({ ...form, farmId: value })
                      }
                      disabled
                    />
                  </div>
                </Col>

                <Col lg={2} md={3} sm={6} xs={6} className="d-flex ">
                  <div className="mt-auto mb-3 ">
                    <button
                      className="btn btn-darkblue w-100"
                      onClick={() =>
                        history.push("/farm_registration/save-data")
                      }
                    >
                      <img
                        className="mr-2 mb-1 "
                        src={"/svg/menu_5.svg"}
                        alt="menu-icon"
                      />
                      ขึ้นทะเบียนฟาร์ม
                    </button>
                  </div>
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="ชื่อเจ้าของสัตว์"
                    idName="name"
                    value={form.name}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) => setForm({ ...form, name: value })}
                  />
                </Col>

                <Col lg={2} md={3} sm={6} xs={6}>
                  <InputMask
                    mask="99-9999-9999"
                    title="หมายเลขโทรศัพท์"
                    idName="phone"
                    value={form.phone}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, phone: value.replace(/-/g, "") })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-2 pl-2  ">
                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputText
                    type="text"
                    title="บ้านเลขที่"
                    idName="homeNo"
                    value={form.homeNo}
                    star={false}
                    placeholder="โปรดระบุ"
                    classLabel="normal"
                    disabled
                    handleChange={(value) =>
                      setForm({ ...form, homeNo: value })
                    }
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    disabled={true}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    disabled={true}
                    handleChange={(value) => selectValueDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="ตำบล"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="subDistrict"
                    classLabel="normal"
                    selectValue={subDistrictSelectValue}
                    optionsList={subDistrictList}
                    disabled={true}
                    handleChange={(value) => selectValueSubDistrict(value)}
                  />
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <InputSelect
                    title="หมู่ที่"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="villageId"
                    classLabel="normal"
                    disabled={true}
                    selectValue={villageSelectValue}
                    optionsList={villageList}
                    handleChange={(value) => {
                      setVillageSelectValue([value]);
                      setForm({ ...form, villageId: value.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </BoxHead>

          <BoxCard classNameBox="mt-3" title="1. รายงานภาวการณ์ระบาดของโรค">
            <Row className="mt-2">
              <Col lg={3} md={6} sm={12}>
                <InputDatePicker
                  title="วันที่ทำการควบคุมโรค"
                  classLabel="normal"
                  classFormGroup="mb-0"
                  placeholder="วัน/เดือน/ปี"
                  value={form.date_save}
                  star={false}
                  handleChange={(value) =>
                    setForm({ ...form, date_save: value })
                  }
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <InputDatePicker
                  title="วันที่สัตว์ตัวสุดท้ายป่วย"
                  classLabel="normal"
                  classFormGroup="mb-0"
                  placeholder="วัน/เดือน/ปี"
                  value={form.date_anm_sick}
                  star={false}
                  handleChange={(value) =>
                    setForm({ ...form, date_anm_sick: value })
                  }
                />
              </Col>
            </Row>

            <div className="my-2">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-add text-white "
                  onClick={() => {
                    addDescHouse();
                  }}
                  //ถ้าหาข้อมูลในตารางไม่มีค่าให้ซ่อนปุ่มเพิ่มข้อมูล หรือถ้าปุ่มcheckButtonAddDataมีค่าเป็นtrue
                  disabled={
                    !(
                      form.house &&
                      form.house.length > 0 &&
                      !form.checkAddData
                    ) || checkButtonAddData
                  }
                >
                  <i className="fas fa-plus mr-1"></i>เพิ่มข้อมูล
                </button>
              </div>

              <Table className="table sameWidth table-striped table-bordered mt-2">
                <thead>
                  <tr className="">
                    <th scope="col">{t("house_buiding")}</th>
                    <th scope="col">ชนิดสัตว์</th>
                    {/* <th scope="col">หมายเลขทะเบียนรถ</th>
                    <th scope="col">หมายเลขฟาร์มต้นทาง</th>
                    <th scope="col">หมายเลขฟาร์มปลายทาง</th> */}
                    <th scope="col">จำนวนทั้งหมด (สัตว์ร่วมฝูง)</th>
                    <th scope="col">จำนวนสัตว์ป่วยไม่รวมตาย</th>
                    <th scope="col">จำนวนตาย</th>
                    <th scope="col">หายป่วย</th>
                    <th scope="col">ทำลาย</th>
                    <th scope="col">รวมป่วยและตาย</th>
                    <th scope="col">คงเหลือ (ตัว)</th>
                    <th scope="col" className="text-center">
                      จัดการ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {form.house &&
                    form.house.length > 0 &&
                    form.house.map((itemH, h) => {
                      if (itemH.desc && itemH.desc.length > 0) {
                        return renderDesc(itemH.desc, h);
                      }
                    })}

                  {form.checkAddData && renderNewTable("", "add")}

                  {form.house && form.house.length == 0 && (
                    <DataTableNodata colSpan={10} />
                  )}
                </tbody>
              </Table>
            </div>

            <div className="font12 text-gray my-2">
              <p className="mb-0">หมายเหตุ:</p>
              <p className="mb-0"> จำนวนสัตว์ป่วยไม่รวมตาย คือ ตัวอย่างเช่น</p>
              <p className="mb-0"> จำนวนตาย คือ ตัวอย่างเช่น</p>
              <p className="mb-0">หายป่วย คือ ตัวอย่างเช่น</p>
              <p className="mb-0">รวมป่วยและตาย คือ ตัวอย่างเช่น</p>
              <p className="mb-0">
                คงเหลือ (ตัว) คือ จำนวนทั้งหมด - จำนวนตาย - ทำลาย
                (ระบบคำนวณให้อัตโนมัติ)
              </p>
            </div>
          </BoxCard>

          <BoxCard classNameBox="mt-3" title="2. การดำเนินการควบคุมโรคสะสม">
            <div className="py-1">
              <div className="d-flex align-items-center flex-wrap py-1">
                <div className="mr-2">การเก็บตัวอย่างส่งห้องปฏิบัติการ</div>
                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputText
                    type="number"
                    classFormGroup="mb-0"
                    idName="keet_test"
                    value={form.keet_test}
                    star={false}
                    placeholder="ระบุจำนวน"
                    classLabel="normal"
                    min="0"
                    handleChange={(value) =>
                      setForm({ ...form, keet_test: value })
                    }
                  />
                </Col>

                <div className="ml-2">ตัวอย่าง</div>
              </div>

              <div className="d-flex align-items-center flex-wrap mt-2 py-1">
                <div className="mr-2">การทำลายเชื้อโรค</div>
                <InputText
                  type="number"
                  classFormGroup="mb-0"
                  idName="destroy_disease"
                  value={form.destroy_disease}
                  star={false}
                  placeholder="ระบุจำนวน"
                  classLabel="normal"
                  min="0"
                  handleChange={(value) =>
                    setForm({ ...form, destroy_disease: value })
                  }
                />
                <div className="ml-2">ครั้ง</div>
              </div>

              <div className="d-flex align-items-center flex-wrap mt-2 py-1">
                <div className="mr-2">การประชาสัมพันธ์</div>
                <Col lg={2} md={6} sm={6} xs={6}>
                  <InputText
                    type="number"
                    classFormGroup="mb-0"
                    idName="public"
                    value={form.public}
                    star={false}
                    placeholder="ระบุจำนวน"
                    classLabel="normal"
                    min="0"
                    handleChange={(value) =>
                      setForm({ ...form, public: value })
                    }
                  />
                </Col>
                <div className="ml-2">ครั้ง</div>
              </div>

              <div className="d-flex align-items-center flex-wrap mt-2 py-1">
                <div className="mr-2">ประกาศกำหนดเขตโรคระบาดสัตว์</div>
                <InputDatePicker
                  title=""
                  classFormGroup="mb-0"
                  classLabel="normal"
                  placeholder="วัน/เดือน/ปี"
                  value={form.announcement}
                  star={false}
                  handleChange={(value) =>
                    setForm({ ...form, announcement: value })
                  }
                />
              </div>

              <div className="d-flex align-items-center flex-wrap mt-2 py-1">
                <div className="mr-2">การทำลายสัตว์</div>
                <InputText
                  type="number"
                  classFormGroup="mb-0"
                  idName="destroy_anm"
                  value={form.destroy_anm}
                  star={false}
                  placeholder="ระบุจำนวน"
                  classLabel="normal"
                  min="0"
                  handleChange={(value) =>
                    setForm({ ...form, destroy_anm: value })
                  }
                />
                <div className="ml-2">ตัว</div>
              </div>

              <div className="d-flex align-items-center flex-wrap mt-2 py-1">
                <div className="mr-2">การฉีดวัคซีน</div>
                <InputText
                  type="number"
                  classFormGroup="mb-0"
                  idName="immunity"
                  value={form.immunity}
                  star={false}
                  placeholder="ระบุจำนวน"
                  classLabel="normal"
                  min="0"
                  handleChange={(value) =>
                    setForm({ ...form, immunity: value })
                  }
                />
                <div className="ml-2">ตัว</div>
              </div>

              <div className="d-flex align-items-center flex-wrap mt-2 py-1">
                <div className="mr-2">การรักษาสัตว์ป่วย</div>
                <InputText
                  type="number"
                  classFormGroup="mb-0"
                  idName="treatment_anm"
                  value={form.treatment_anm}
                  star={false}
                  placeholder="ระบุจำนวน"
                  classLabel="normal"
                  min="0"
                  handleChange={(value) =>
                    setForm({ ...form, treatment_anm: value })
                  }
                />
                <div className="ml-2">ตัว</div>
              </div>

              <div className="d-flex align-items-center flex-wrap mt-2 py-1">
                <div className="mr-2">อื่นๆ (ระบุ)</div>
                <InputText
                  type="text"
                  classFormGroup="mb-0"
                  idName="other"
                  value={form.other}
                  star={false}
                  placeholder="ระบุ"
                  classLabel="normal"
                  handleChange={(value) => setForm({ ...form, other: value })}
                />
              </div>

              <div className="d-flex align-items-center flex-wrap mt-2 py-1">
                <div className="mr-2">ควบคุมการเคลื่อนย้าย</div>
                <InputText
                  type="number"
                  classFormGroup="mb-0"
                  idName="date_q8Qty"
                  value={form.date_q8Qty}
                  star={false}
                  placeholder="ระบุจำนวน"
                  classLabel="normal"
                  min="0"
                  handleChange={(value) =>
                    setForm({ ...form, date_q8Qty: value })
                  }
                />
                <div className="ml-2">ครั้ง</div>
              </div>
            </div>
          </BoxCard>
        </div>
      </div>

      {showOutbreakSearch && (
        <ModalOutbreakSearch
          show={showOutbreakSearch}
          mode={form.modeOutbreak}
          close={() => setShowOutbreakSearch(false)}
          save={(value) =>
            setForm({
              ...form,
              outbreakCode: value.obCode,
              outbreakId: value.obId,
            })
          }
        />
      )}
      <ModalFarmSearch
        show={showFarmSearch}
        mode={form.modeFarmSearch}
        close={() => setShowFarmSearch(false)}
        save={(value) => setForm({ ...form, farmId: value })}
      />

      {showOrgModal && (
        <ModalOrganizes
          show={showOrgModal}
          close={() => setShowOrgModal(false)}
          toSaveOrg={(data) => setOrganizes(data)}
        />
      )}
    </div>
  );
}

export default Disease_Control_Save;
