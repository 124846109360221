import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { loading } from "../../redux/actions/loadingAction";
import * as helper from "../../utils/helper";

import { Row, Col } from "react-bootstrap";

import BoxCard from "../../components/BoxCard";
import InputSelect from "../../components/InputSelect";
import InputDatePicker from "../../components/InputDatePicker";
import DataTableNodata from "../../components/DataTableNodata";
import Breadcrumb from "../../components/Breadcrumb";
import DataTable from "../../components/DataTable";
import * as API from "../../utils/apis";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useMasterData } from "../../components/hooks/useMasterData";

import pdfMake from "pdfmake-thai/build/pdfmake";

function AssessRisk_Report() {
  const ref = React.createRef();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "footer"]);
  const user = useSelector((state) => state.login.result);
  const [listReport, setReport] = useState([]);

  // select จังหวัด อำเภอ
  const [provinceSelectValue, setProvinceSelectValue] = useState([]);
  const [provinceList, setProvinceList] = useState([]);

  const [districtSelectValue, setDistrictSelectValue] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [zoneLivestockList, setZoneLivestockList] = useState([]);
  const [zoneLivestockSelectValue, setZoneLivestockSelectValue] = useState([]);

  const [riskSelectValue, setRiskSelectValue] = useState([]);
  const [riskList, setRiskList] = useState([]);

  // select file type to download
  const [fileType, setFileType] = useState("excel");
  const fileName = "D99RPT01-AssessRiskReport_" + helper.getDate(new Date());

  const [provinceByZoneId, setProvinceByZoneId] = useState(null);
  useMasterData("province", provinceList, setProvinceList, provinceByZoneId);
  useMasterData(
    "zoneLivestock",
    zoneLivestockList,
    setZoneLivestockList,
    provinceByZoneId
  );

  const [isClearSearch, setIsClearSearch] = useState(false);

  const breadcrumbPath = [
    { path: "/home", name: "หน้าหลัก" },
    { path: "/farm_registration/save-data", name: "ขึ้นทะเบียนฟาร์ม" },
    { path: "/assess_risk/report/risk", name: "รายงาน" },
    { path: "active", name: "E99RPT01-การขึ้นทะเบียนฟาร์ม" },
  ];

  const initialForm = {
    id: "",
    formId: "",
    formName: "",
    provinceId: "",
    amphurId: "",
    startDate: "",
    endDate: "",
    zoneName: "",
    zoneId: "",

    remainDay: "",
    tambonId: "",
    mooId: "",
    scoreForm: "",
    homeNo: "",
    soi: "",
    provinceName: "",
    amphurName: "",
    tambonName: "",
    mooName: "",
    farmName: "",
    farmerName: "",
    updateDate: "",
    statusName: "",
    levelName: "",
    endDateName: "",

    limit: 10,
    page: 1,
    totalPages: 1,
    sortDir: "DESC",
    sortBy: "id",
    totalElements: 0,
    numberOfElements: 0,
    headTitle: [
      {
        name: "ลำดับ",
        width: "50px",
        rowSpan: 2,
      },

      {
        sortBy: "formId",
        name: "ชุดประเมินความเสี่ยง",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "farmName",
        name: "ชื่อฟาร์ม",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "farmerName",
        name: "เจ้าของฟาร์ม",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        name: "ที่อยู่ฟาร์ม",
        sortFlg: false,
        colSpan: 5,
        childHead: [
          {
            sortBy: "zoneName",
            name: "เขต",
            sortFlg: true,
          },
          {
            sortBy: "provinceId",
            name: "จังหวัด",
            sortFlg: true,
          },
          {
            sortBy: "amphurId",
            name: "อำเภอ",
            sortFlg: true,
          },
          {
            sortBy: "tambonId",
            name: "ตำบล",
            sortFlg: true,
          },
          {
            sortBy: "homeNo",
            name: "ที่อยู่",
            sortFlg: true,
          },
        ],
      },
      {
        sortBy: "scoreForm",
        name: "คะแนน",
        sortFlg: true,
        rowSpan: 2,
      },

      {
        sortBy: "levelName",
        name: "แปรผล",
        sortFlg: true,
        rowSpan: 2,
      },
      {
        sortBy: "updateDate",
        name: "วัน เวลา",
        sortFlg: true,
        rowSpan: 2,
      },
    ],
  };

  const [form, setForm] = useState(initialForm);

  pdfMake.fonts = {
    Roboto: {
      normal: "Roboto-Regular.ttf",
      bold: "Roboto-Medium.ttf",
      italics: "Roboto-Italic.ttf",
      bolditalics: "Roboto-MediumItalic.ttf",
    },
    THSarabunNew: {
      normal: "THSarabunNew.ttf",
      bold: "THSarabunNew-Bold.ttf",
      italics: "THSarabunNew-Italic.ttf",
      bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
  };

  useEffect(() => {
    getAssessRiskKitReport();
    // getProvince();
    getAssessRiskKit();
    getZoneLivestock();
  }, [form.limit, form.page, form.sortBy, form.sortDir, isClearSearch]);

  const getAssessRiskKitReport = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getAssessRiskKitReport(
        form.formId,
        form.startDate ? helper.getDate(form.startDate) : "",
        form.endDate ? helper.getDate(form.endDate) : "",
        form.zoneId,
        form.provinceId,
        form.amphurId,
        "",
        form.sortBy,
        "",
        form.sortDir,
        form.page,
        form.limit
      );
      const data = await response?.data;
      if (response.status == 200) {
        setReport(data.content);
        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAssessRiskKitToExport = async () => {
    dispatch(loading(true));
    try {
      const response = await API.getAssessRiskKitReport(
        form.formId,
        form.startDate ? helper.getDate(form.startDate) : "",
        form.endDate ? helper.getDate(form.endDate) : "",
        form.zoneId,
        form.provinceId,
        form.amphurId,
        "",
        form.sortBy,
        "",
        form.sortDir,
        1,
        form.totalElements
      );
      const data = await response?.data;
      if (response.status == 200) {
        let assessReportArray = [];
        data.content.forEach((element) => {
          assessReportArray.push({
            id: element.id,
            formId: element.formId,
            formName: element.formName,
            remainDay: element.remainDay,
            provinceId: element.provinceId,
            amphurId: element.amphurId,
            tambonId: element.tambonId,
            mooId: element.mooId,
            scoreForm: element.scoreForm,
            homeNo: element.homeNo,
            soi: element.soi,
            zoneName: element.zoneName,
            provinceName: element.provinceName,
            amphurName: element.amphurName,
            tambonName: element.tambonName,
            mooName: element.mooName,
            farmName: element.farmName,
            farmerName: element.farmerName,
            updateDate: element.updateDate,
            startDate: element.startDate,
            endDate: element.endDate,
            statusName: element.statusName,
            levelName: element.levelName,
            endDateName: element.endDateName,
          });
        });
        downloadRecord(assessReportArray, data.content);
        dispatch(loading(false));
        setForm({
          ...form,
          totalPages: data.totalPages,
          totalElements: data.totalElements,
          numberOfElements: data.numberOfElements,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAssessRiskKit = async () => {
    try {
      const response = await API.getAssessRiskKit();
      const data = await response.data;
      if (response.status == 200) {
        let riskArray = [];
        data.content.forEach((element) => {
          riskArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setRiskList(riskArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getZoneLivestock = async () => {
    try {
      const response = await API.getZoneLivestock("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let zoneArray = [];
        data.content.forEach((element) => {
          zoneArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setZoneLivestockList(zoneArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvince = async (countyId) => {
    try {
      const req = {
        id: "",
        statusId: 1,
        page: "1",
        limit: "100",
        search: "",
        sortBy: "id",
        sortDir: "ASC",
        zoneId: countyId,
      };
      const response = await API.getProvinces(req);
      // const response = await API.getProvince("", 1);
      const data = await response.data;
      if (response.status == 200) {
        let provincesArray = [];
        data.content.forEach((element) => {
          provincesArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setProvinceByZoneId(provincesArray);
        setProvinceList(provincesArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDistricts = async (id) => {
    try {
      const response = await API.getDistricts("", id, 1);
      const data = await response.data;
      if (response.status == 200) {
        let districtArray = [];
        data.content.forEach((element) => {
          districtArray.push({
            value: element.id,
            label: element.name,
          });
        });
        setDistrictList(districtArray);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const selectValueProvince = (value) => {
    setProvinceSelectValue([{ value: value.value, label: value.label }]);
    setDistrictSelectValue([]);
    setForm({ ...form, provinceId: value.value });
    getDistricts(value.value);
  };

  const toSearch = async () => {
    setForm({ ...form, page: 1 });
    setIsClearSearch(!isClearSearch);
  };

  const clearSearch = () => {
    setForm(initialForm);
    setZoneLivestockSelectValue([]);
    setProvinceSelectValue([]);
    setDistrictSelectValue([]);
    setDistrictList([]);
    setRiskSelectValue([]);
    setIsClearSearch(!isClearSearch);
  };

  const getFilter = async (type, isChange1, isChange2) => {
    if (type == "page") {
      setForm({
        ...form,
        page: isChange1,
      });
    } else if (type == "limit") {
      setForm({
        ...form,
        limit: isChange1,
        page: 1,
      });
    } else if (type == "filter") {
      setForm({
        ...form,
        sortBy: isChange1,
        sortDir: isChange2,
        page: 1,
      });
    }
  };

  const buttonSearch = () => {
    return (
      <>
        <button
          className="btn btn-danger mr-2"
          onClick={() => {
            clearSearch();
            setProvinceByZoneId(null);
          }}
        >
          ล้างข้อมูล
        </button>
        <button className="btn btn-success" onClick={() => toSearch()}>
          {t("search")}
        </button>
      </>
    );
  };

  const convertListToExcelTable = (objArray) => {
    let excelTable = [];
    objArray.map((element, index) => {
      excelTable.push({
        ลำดับ: index + 1,
        ชุดประเมินความเสี่ยง: element.formName,
        ชื่อฟาร์ม: element.farmName,
        เจ้าของฟาร์ม: element.farmerName,
        เขต: element.zoneName,
        จังหวัด: element.provinceName,
        อำเภอ: element.amphurName,
        ตำบล: element.tambonName,
        ที่อยู่: element.homeNo + element.mooName,
        คะแนน: element.scoreForm,
        แปรผล: element.levelName,
        วันเวลา:
          helper.fullDateTh(element.updateDate) +
          " " +
          helper.getTimeAll(element.updateDate, "th"),
      });
    });
    return excelTable;
  };

  const convertToCSV = (objArray) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let headCol = "";
    let dataRow = "";
    let key = Object.keys(array[0]);
    key.forEach((item) => {
      if (headCol === "") {
        headCol = item;
      } else {
        if (item != "") {
          headCol += ",";
          headCol += item;
        }
      }
    });
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") {
          line += ",";
        }
        line += array[i][index];
      }
      dataRow += line + "\r\n";
    }
    return headCol + "\r\n" + dataRow;
  };

  const convertToExcel = (objArray) => {
    const workSheet = XLSX.utils.json_to_sheet(objArray);
    const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    return XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  };

  const printPDF = (fileName, dataListForPDF) => {
    const head = [
      { text: "ลำดับ", style: "tableHeader", rowSpan: 2, alignment: "center" },
      {
        text: "ชุดประเมินความเสี่ยง",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "ชื่อฟาร์ม",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "เจ้าของฟาร์ม",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
      {
        text: "ที่อยู่ฟาร์ม",
        style: "tableHeader",
        colSpan: 5,
        alignment: "center",
      },
      {},
      {},
      {},
      {},
      { text: "คะแนน", style: "tableHeader", rowSpan: 2, alignment: "center" },
      { text: "แปรผล", style: "tableHeader", rowSpan: 2, alignment: "center" },
      {
        text: "วัน เวลา",
        style: "tableHeader",
        rowSpan: 2,
        alignment: "center",
      },
    ];
    const subHead = [
      "",
      "",
      "",
      "",
      { text: "เขต", style: "tableHeader", alignment: "center" },
      { text: "จังหวัด", style: "tableHeader", alignment: "center" },
      { text: "อำเภอ", style: "tableHeader", alignment: "center" },
      { text: "ตำบล", style: "tableHeader", alignment: "center" },
      { text: "ที่อยู่", style: "tableHeader", alignment: "center" },
      "",
      "",
      "",
    ];

    let listRiskPdf = [];
    if (dataListForPDF && dataListForPDF.length > 0) {
      dataListForPDF.map((item, i) => {
        listRiskPdf.push([
          i + 1,
          item.formName,
          item.farmName,
          item.farmerName,
          item.zoneName,
          item.provinceName,
          item.amphurName,
          item.tambonName,
          item.homeNo + " " + item.mooName,
          item.scoreForm,
          item.levelName,
          helper.fullDateTh(item.updateDate) +
            " " +
            helper.getTimeAll(item.updateDate, "th"),
        ]);
      });
    }
    let bodyContent = [head, subHead];
    listRiskPdf.map((item, index) => {
      bodyContent.push(listRiskPdf[index]);
    });
    const cellWidhtHeight = [
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
      "auto",
    ];
    const context = {
      content: [
        {
          table: {
            widths: cellWidhtHeight,
            heights: cellWidhtHeight,
            headerRows: 2,
            body: bodyContent,
          },
        },
      ],
      defaultStyle: {
        fontSize: 9,
        font: "THSarabunNew",
      },
      styles: {
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
        },
      },
    };
    pdfMake.createPdf(context).download(fileName + ".pdf");
  };

  const downloadRecord = (dataList, dataListForPDF) => {
    let type = "";
    if (fileType === "excel") {
      type =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = convertToExcel(convertListToExcelTable(dataList));
      const data = new Blob([excelBuffer], { type: type });
      FileSaver.saveAs(data, fileName + ".xlsx");
    }
    if (fileType === "csv") {
      type = "text/csv;charset=utf-8";
      const csv = convertToCSV(convertListToExcelTable(dataList));
      const csvData = new Blob([csv], { type: type });
      FileSaver.saveAs(csvData, fileName + ".csv");
    }

    if (fileType === "pdf") {
      printPDF(fileName, dataListForPDF);
    }
  };

  const buttonExport = () => {
    return (
      <>
        <button
          className="btn btn-report"
          onClick={() => getAssessRiskKitToExport()}
          disabled={user?.userGroup.mexport === "n"}
        >
          <a className="text-white">
            <img
              className="nav-icon"
              src={"/svg/report.svg"}
              alt="export-icon"
            />{" "}
            ออกรายงาน
          </a>
        </button>
      </>
    );
  };

  const checkExport = (value) => {
    if (fileType === value) {
      return true;
    } else {
      return false;
    }
  };

  const renderTable = (data) => {
    if (data.length > 0) {
      return data.map((item, i) => (
        <tr key={i} className="font14 fitwidth">
          <td className="text-center">
            {form.limit * (form.page - 1) + (i + 1)}
          </td>
          <td className="text-center">{item.formName}</td>
          <td className="text-center">{item.farmName}</td>
          <td className="text-center">{item.farmerName}</td>
          <td className="text-center">{item.zoneName}</td>
          <td className="text-center">{item.provinceName}</td>
          <td className="text-center">{item.amphurName}</td>
          <td className="text-center">{item.tambonName}</td>
          <td className="text-center">
            {item.homeNo} หมู่ที่ {item.mooName}
          </td>
          <td className="text-center">{item.scoreForm}</td>
          <td className="text-center">{item.levelName}</td>
          <td className="text-center">
            {item.updateDate
              ? helper.momentDate(item.updateDate, "th", "short")
              : "-"}{" "}
            {item.updateDate ? helper.getTime(item.updateDate, "th") : "-"}
          </td>
        </tr>
      ));
    } else {
      return <DataTableNodata colSpan="12" />;
    }
  };

  return (
    <div className="">
      <div className="pl-4 bg-white ml30">
        <Breadcrumb data={breadcrumbPath} />
      </div>

      <div className="bg-gray-light">
        <div className="px-4 pt-3 container-fixed-footer">
          <BoxCard title="ตัวกรอง" classLabel="" headRight={buttonSearch()}>
            <div className=" ">
              <Row className="mb-2">
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="วันที่บันทึกข้อมูล"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.startDate}
                    star={false}
                    handleChange={(value) => {
                      // let sDate = new Date(value)
                      setForm({ ...form, startDate: value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputDatePicker
                    title="ถึงวันที่"
                    classLabel="normal"
                    placeholder="วัน/เดือน/ปี"
                    value={form.endDate}
                    star={false}
                    handleChange={(value) => {
                      // let eDate = new Date(value)
                      setForm({ ...form, endDate: value });
                    }}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="ชุดประเมินความเสี่ยง"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={riskSelectValue}
                    optionsList={riskList}
                    handleChange={(value) => {
                      setRiskSelectValue([value]);
                      setForm({ ...form, formId: value.value });
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="เขตปศุสัตว์"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={zoneLivestockSelectValue}
                    optionsList={zoneLivestockList}
                    handleChange={(value) => {
                      setZoneLivestockSelectValue([value]);
                      setForm({ ...form, zoneId: value.value });
                      getProvince(value.value);
                    }}
                  />
                </Col>
                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="จังหวัด"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="province"
                    classLabel="normal"
                    selectValue={provinceSelectValue}
                    optionsList={provinceList}
                    handleChange={(value) => selectValueProvince(value)}
                  />
                </Col>

                <Col lg={2} md={6} sm={12} xs={12}>
                  <InputSelect
                    title="อำเภอ"
                    star={false}
                    placeholder="กรุณาเลือก"
                    idName="title"
                    classLabel="normal"
                    selectValue={districtSelectValue}
                    optionsList={districtList}
                    handleChange={(value) => {
                      setDistrictSelectValue(value);
                      setForm({ ...form, amphurId: value.value });
                    }}
                  />
                </Col>

                <Col>
                  <Col lg={3} md={6} sm={12}>
                    <label style={{ fontWeight: "normal" }}>เลือกรูปแบบ</label>
                  </Col>
                  <Col lg={9} md={9} sm={12} className="w-100">
                    <div className="d-flex mt-2 ">
                      <label style={{ fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="excel"
                          name="data-export"
                          checked={checkExport("excel")}
                          onChange={() => setFileType("excel")}
                        />{" "}
                        EXCEL
                      </label>
                      <div className="ml-2">
                        <label style={{ fontWeight: "normal" }}>
                          <input
                            type="radio"
                            value="pdf"
                            name="data-export"
                            checked={checkExport("pdf")}
                            onChange={() => setFileType("pdf")}
                          />{" "}
                          PDF
                        </label>
                      </div>

                      <div className="ml-2">
                        <label style={{ fontWeight: "normal" }}>
                          <input
                            type="radio"
                            value="csv"
                            name="data-export"
                            checked={checkExport("csv")}
                            onChange={() => setFileType("csv")}
                          />{" "}
                          CSV
                        </label>
                      </div>
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>
          </BoxCard>

          <BoxCard
            classNameBox="mt-3"
            title="รายการข้อมูลรายงาน D99RPT01-ประเมินความเสี่ยงฟาร์ม"
            headRight={buttonExport()}
          >
            <DataTable
              typeTable="colSpan"
              headColumns={form.headTitle}
              totalPages={form.totalPages}
              page={form.page}
              limit={form.limit}
              sortDir={form.sortDir}
              sortBy={form.sortBy}
              getLimit={(limit) => getFilter("limit", limit)}
              getFilter={(sortBy, sortDir) =>
                getFilter("filter", sortBy, sortDir)
              }
              getPage={(page) => getFilter("page", page)}
              totalItems={form.totalElements}
              numberOfItems={form.numberOfElements}
              isClear={isClearSearch}
              id={"data-table"}
              id={"render-data-table"}
              reference={ref}
            >
              {renderTable(listReport)}
            </DataTable>
          </BoxCard>
        </div>
      </div>
    </div>
  );
}

export default AssessRisk_Report;
